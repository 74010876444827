import { Spinner2 as StaticSpinner } from '@styled-icons/icomoon/Spinner2'
import styled, { keyframes } from 'styled-components'

const spin = keyframes`
from {
  transform: rotate(0deg)
}
to {
  transform: rotate(360deg);
}
`

const Spinner = styled(StaticSpinner)`
  animation: ${spin} 1000ms infinite;
`

export default Spinner
