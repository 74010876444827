import React, { useEffect } from 'react'

type BlockKeyDownPropagationProps = {
  children?: React.ReactNode
}

// Wrap your component with this componenent to stop propgating key-down events further up the DOM tree.
// This was made in the first place to make sure Kive Search doesn't catch key-down events from more "important" components
// OBS: Use with care (as it blocks all key-down events)
const BlockKeyDownPropagation = ({
  children,
}: BlockKeyDownPropagationProps) => {
  useEffect(() => {
    const blockEventPropagation = (e: Event) => {
      e.stopPropagation()
    }
    document.addEventListener('keydown', blockEventPropagation, true)
    return () => {
      document.removeEventListener('keydown', blockEventPropagation, true)
    }
  }, [])

  return <>{children}</>
}

export default BlockKeyDownPropagation
