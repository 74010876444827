import * as Dialog from '@radix-ui/react-dialog'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { PersonPlus } from 'styled-icons/bootstrap'
import { Link, Twitter } from 'styled-icons/feather'

import Spinner from 'components/common/Spinner'
import useToastMessages from 'components/toast/useToastMessages'
import { StyledButtonMedium } from 'features/editor/ButtonMedium'
import { useSendEditSessionInviteMutation } from 'generated/graphql'
import { trackCanvasShareIntended } from 'helpers/tracking/tracking'
import useIsMobileDevice from 'hooks/useIsMobileDevice'
import { RootState } from 'store'

type InviteToSessionButtonArgs = {
  editSessionId: string
}

export const InviteToSessionDialog = ({
  editSessionId,
}: InviteToSessionButtonArgs) => {
  const [sessionLink, setSessionLink] = useState('')
  const { uid, email, displayName } = useSelector(
    (state: RootState) => state.firebase.auth
  )
  const isMobileDevice = useIsMobileDevice()
  const [sendEditSessionInviteMutation, { loading, error }] =
    useSendEditSessionInviteMutation()

  const { reportSuccess } = useToastMessages()

  const handleOnOpenChange = async (isOpening: boolean) => {
    if (!email || !displayName || !uid || !isOpening) return
    const { data } = await sendEditSessionInviteMutation({
      variables: {
        input: {
          editSessionId,
          from: {
            email,
            name: displayName,
            userId: uid,
          },
          to: null,
          message: null,
        },
      },
    })
    setSessionLink(
      `${window.location.origin}/session-invitation/${data?.sendEditSessionInvite.id}`
    )
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(sessionLink)
    reportSuccess('Link copied to clipboard!')
    trackCanvasShareIntended({
      editSessionId,
      shareMedium: 'COPY_LINK',
      sharePurpose: 'ADD_MEMBER',
    })
  }

  const StyledDialog = styled(Dialog.Content)`
    ${() => css`
      border: 1px solid #222222;
      align-items: flex-start;
      background-color: rgb(13, 13, 13);
      border-radius: 16px;
      display: flex;
      flex: 0 0 auto;
      flex-flow: column nowrap;
      gap: 16px;
      height: min-content;
      overflow: hidden;
      padding: 16px;
      position: fixed;
      right: 16px;
      top: 60px;
      max-width: 500px;
      will-change: transform;
      z-index: 10;
    `}
  `

  const StyledOverlay = styled(Dialog.Overlay)`
    ${() => css`
      background-color: rgba(0, 0, 0, 0.5);
      position: fixed;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
    `}
  `

  const StyledText = styled('p')`
    ${() => css`
      color: white;
      font-size: 18px;
      font-family: UniversalSans;
    `}
  `

  const StyledLinkContainer = styled('div')`
    ${() => css`
      color: rgb(92, 92, 92);
      font-family: UniversalSans;
      border: 1px solid #222222;
      place-content: center space-between;
      align-items: center;
      background-color: rgb(20, 20, 20);
      border-radius: 8px;
      display: flex;
      flex: 0 0 auto;
      flex-flow: row nowrap;
      height: min-content;
      overflow: hidden;
      padding: 5px 5px 5px 16px;
      position: relative;
      width: 100%;
      will-change: transform;
    `}
  `

  const StyledLinkMobileContainer = styled('div')`
    ${() => css`
      color: rgb(92, 92, 92);
      font-family: UniversalSans;
      align-items: center;
      border-radius: 8px;
      display: flex;
      flex: 0 0 auto;
      max-width: 100%:
      flex-flow: row nowrap;
      height: min-content;
      overflow: hidden;
    `}
  `

  const LinkContainer = isMobileDevice
    ? StyledLinkMobileContainer
    : StyledLinkContainer
  return (
    <Dialog.Root onOpenChange={handleOnOpenChange}>
      <Dialog.Trigger asChild>
        <StyledButtonMedium hasText disabled={!!error}>
          Add people
          <PersonPlus width={16} />
        </StyledButtonMedium>
      </Dialog.Trigger>
      <Dialog.Portal>
        <StyledOverlay />
        <StyledDialog>
          <StyledText>Add people to this room</StyledText>
          {loading ? (
            <Spinner css="height: 20px; color: rgb(89, 48, 229)" />
          ) : (
            <LinkContainer>
              {isMobileDevice ? '' : sessionLink}
              <StyledButtonMedium hasText onClick={copyToClipboard}>
                {isMobileDevice ? 'Copy link' : 'Copy'}
                <Link size={14} color="white" href="aaa" />
              </StyledButtonMedium>
              <div style={{ marginRight: 7 }} />
              <a
                href={`https://twitter.com/intent/tweet?url=Join my AI creation room on @kiveai?\n${sessionLink}`}
                target="_blank"
                rel="noreferrer"
              >
                <StyledButtonMedium
                  hasText
                  onClick={() => {
                    trackCanvasShareIntended({
                      editSessionId,
                      shareMedium: 'TWITTER',
                      sharePurpose: 'ADD_MEMBER',
                    })
                  }}
                >
                  Share
                  <Twitter size={14} color="white" />
                </StyledButtonMedium>
              </a>
            </LinkContainer>
          )}
        </StyledDialog>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
