import projectsBoardsImage1 from 'assets/img/guides/projects-boards-guide/projects-boards-1.jpg'
import projectsBoardsImage2 from 'assets/img/guides/projects-boards-guide/projects-boards-2.jpg'
import projectsBoardsImage3 from 'assets/img/guides/projects-boards-guide/projects-boards-3.jpg'
import { Spacer } from 'components/common/Spacer'
import { Text } from 'components/common/Text'

import { StyledContentList } from './contentStyles'

export const contentProjectsBoards = {
  contentHeight: 225,
  steps: [
    {
      coverUrl: projectsBoardsImage1,
      content: (
        <div>
          <Text size="lg" color="neutral.0">
            Sort your ideas into projects & boards
          </Text>
          <Spacer factor={1} />
          <Text size="sm" color="neutral.2">
            Boards help you visualize and present your ideas.{' '}
          </Text>
          <StyledContentList>
            <li>Save multiple items directly to a board from your library.</li>
            <li>
              You can rearrange items in a boards, add a board cover and add a
              descriptive text.
            </li>
            <li>A project can have several boards.</li>
            <li>
              See some example boards{' '}
              <a
                href="https://kive.ai/p/lKnG8mOc7Rvv0VKgPddx/jjVWejVwqCjFGyB7TEHY"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
            </li>
          </StyledContentList>
        </div>
      ),
    },
    {
      coverUrl: projectsBoardsImage2,
      content: (
        <div>
          <Text size="lg" color="neutral.0">
            Upload directly to a board
          </Text>
          <Spacer factor={1} />
          <Text size="sm" color="neutral.2">
            Add images to your board from your own library or import from
            Discover.{' '}
          </Text>
          <StyledContentList>
            <li>You can drag and drop files directly into a board.</li>
            <li>
              The items will also be uploaded to your library and automatically
              tagged.
            </li>
          </StyledContentList>
        </div>
      ),
    },
    {
      coverUrl: projectsBoardsImage3,
      content: (
        <div>
          <Text size="lg" color="neutral.0">
            Add multiple items to a board
          </Text>
          <Spacer factor={1} />
          <Text size="sm" color="neutral.2">
            To add multiple items to a board in one go, click and drag to make a
            selection and hit the “Save all to board” button that appears in the
            header.
          </Text>
        </div>
      ),
    },
  ],
}
