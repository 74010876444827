import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import { RedirectWithParams } from 'components/common/RedirectWithParams'
import { GuideCanvas } from 'features/editor/GuideCanvas'
import { PublicBanner } from 'features/editor/PublicBanner'
import { SignupModal } from 'features/editor/components/SignupModal/SignupModal'
import { useCreateEditSessionMutation, useUserQuery } from 'generated/graphql'
import { useMount } from 'hooks/useMount'
import { RootState } from 'store'

import Loading from '../../Loading'

type CreateEditSessionSignupProps = {
  authed: boolean
}

export const CreateEditSessionSignup = ({
  authed,
}: CreateEditSessionSignupProps) => {
  const navigate = useNavigate()
  const [showGuide, setShowGuide] = useState(false)

  useMount(() => {
    if (authed) {
      navigate('/canvas/create')
    }
  })

  return (
    <>
      <PublicBanner handleSignupClick={() => {}} />
      <SignupModal
        isOpen
        close={() => {}}
        onSignup={() => {
          setShowGuide(true)
        }}
        onLogin={() => {
          navigate('/canvas/create')
        }}
      />
      <GuideCanvas
        show={showGuide}
        onClose={() => {
          navigate('/canvas/create')
        }}
      />
    </>
  )
}

export const CreateEditSession = () => {
  const navigate = useNavigate()
  const userId = useSelector((state: RootState) => state.firebase.auth.uid)
  const { data: userData } = useUserQuery({
    variables: {
      uid: userId,
    },
    skip: !userId,
  })
  const [createEditSessionMutation] = useCreateEditSessionMutation()

  useEffect(() => {
    const createSession = async () => {
      if (!userId || !userData || userData?.user?.frozenAccount) {
        return
      }

      const { data } = await createEditSessionMutation({
        variables: {
          input: {
            userId,
            workspaceId: null,
            baseImageUrl: null,
            backUrl: null,
          },
        },
      })

      const sessionId = data?.createEditSession.id
      if (!sessionId) {
        return
      }

      navigate(`/canvas/${sessionId}`, { replace: true })
    }

    createSession()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, userData])

  if (!userId) {
    return <RedirectWithParams to="/canvas/signup" />
  } else if (userData?.user?.frozenAccount) {
    return <RedirectWithParams to="/frozen-account" />
  }

  return <Loading />
}
