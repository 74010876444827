import React, { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'

function usePortal(id: string) {
  // @ts-ignore
  const rootElemRef = useRef(document.createElement('div'))

  useEffect(function setupElement() {
    // Look for existing target dom element to append to
    const parentElem = document.querySelector(`#${id}`)
    if (!parentElem) {
      return console.error('Missing document node for portal')
    }
    // Add the detached element to the parent
    parentElem?.appendChild(rootElemRef.current)
    // This function is run on unmount
    return function removeElement() {
      rootElemRef?.current.remove()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return rootElemRef.current
}

type PortalProps = {
  children: React.ReactNode
  id: string
}

export const Portal = ({ id, children }: PortalProps) => {
  const target = usePortal(id)
  return createPortal(children, target)
}
