import { ApolloError } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'

import useToastMessages from 'components/toast/useToastMessages'
import useUpgradeNavigation from 'components/upgrade/useUpgradeNavigation'
import { useWorkspacePlan } from 'components/upload/useUploadWorkspaces'
import {
  useSaveDiscoveryItemToBoardMutation,
  useSaveDiscoveryItemToWorkspaceMutation,
} from 'generated/graphql'
import { trackUploadPlanQuotaHit } from 'helpers/tracking/tracking'
import { RootState } from 'store'
import { SaveStatus, removeSave, setSave } from 'store/publicItemsSaves'
import { publicItemsSaveSatusSelector } from 'store/selectors'

const getIsDuplicateError = (error: ApolloError) => {
  return error.graphQLErrors?.[0]?.extensions?.status === 'DUPLICATE_EXISTS'
}

type UseSaveItemFromDiscoverArgs = {
  itemId: string
  activeWorkspaceId: string
  activeWorkspaceUrl: string
  isBoardItem?: boolean
}

type HandleSaveToBoardArgs = {
  boardId: string
  projectId: string
  boardTitle: string
}

type UseSaveItemFromDiscoverResponse = {
  saveStatus: SaveStatus
  handleSaveToWorkspace: () => Promise<string | null>
  handleSaveToBoard: (args: HandleSaveToBoardArgs) => Promise<string | null>
}

const useSaveItemFromDiscover = ({
  itemId,
  activeWorkspaceId,
  activeWorkspaceUrl,
  isBoardItem,
}: UseSaveItemFromDiscoverArgs): UseSaveItemFromDiscoverResponse => {
  const { reportError, reportSuccess } = useToastMessages()
  const dispatch = useDispatch()

  const { remainingUploads, workspacePlan } =
    useWorkspacePlan(activeWorkspaceId)
  const { open: openUpgradePanel } = useUpgradeNavigation()

  const [saveDiscoveryItemToWorkspace] =
    useSaveDiscoveryItemToWorkspaceMutation()

  const [saveDiscoveryItemToBoard] = useSaveDiscoveryItemToBoardMutation()

  const saveStatus = useSelector((state: RootState) =>
    publicItemsSaveSatusSelector(state, itemId)
  )

  const handleUploadQuotaHit = () => {
    openUpgradePanel('items')
    trackUploadPlanQuotaHit({
      uploadType: 'discover',
      nbrOfItemsLeftOnPlan: remainingUploads,
      workspacePlanName: workspacePlan?.presentation?.name ?? null,
    })
    return {
      status: 'FAILED' as const,
    }
  }

  const saveToLibrary = async (): Promise<{
    status?: 'ALREADY_EXISTS' | 'FAILED'
    id?: string
  }> => {
    if (remainingUploads <= 0) {
      return handleUploadQuotaHit()
    }
    try {
      const { data } = await saveDiscoveryItemToWorkspace({
        variables: {
          workspaceId: activeWorkspaceId,
          itemId,
          isBoardItem,
        },
      })

      return {
        id: data!.saveDiscoveryItemToWorkspace.id,
      }
    } catch (error) {
      const isDuplicate = getIsDuplicateError(error)
      if (isDuplicate) {
        return {
          status: 'ALREADY_EXISTS',
        }
      }
      return {
        status: 'FAILED',
      }
    }
  }

  const saveToBoard = async ({
    boardId,
  }: {
    boardId: string
  }): Promise<{
    status?: 'FAILED'
    id?: string
  }> => {
    if (remainingUploads <= 0) {
      return handleUploadQuotaHit()
    }
    try {
      const { data } = await saveDiscoveryItemToBoard({
        variables: {
          workspaceId: activeWorkspaceId,
          itemId,
          boardId,
          isBoardItem,
        },
      })

      return {
        id: data!.saveDiscoveryItemToBoard.id,
      }
    } catch (error) {
      return {
        status: 'FAILED',
      }
    }
  }

  const handleSaveToBoard = async ({
    boardId,
    projectId,
    boardTitle,
  }: HandleSaveToBoardArgs) => {
    dispatch(setSave({ id: itemId, status: 'SAVED' }))
    const { status, id } = await saveToBoard({ boardId })
    if (status === 'FAILED') {
      dispatch(removeSave({ id: itemId }))
      reportError('We could not save the item')
      return null
    }
    reportSuccess(
      'Saved to board:',
      {
        linkTo: `/${activeWorkspaceUrl}/p/${projectId}/${boardId}`,
        linkText: `${boardTitle}`,
      },
      4000
    )
    return id!
  }

  const handleSaveToWorkspace = async () => {
    dispatch(setSave({ id: itemId, status: 'SAVED' }))
    const { status, id } = await saveToLibrary()
    if (status === 'ALREADY_EXISTS') {
      dispatch(setSave({ id: itemId, status: 'EXISTS' }))
      reportError('The item already exists in your workspace')
      return null
    }
    if (status === 'FAILED') {
      dispatch(removeSave({ id: itemId }))
      reportError('We could not save the item')
      return null
    }
    reportSuccess(
      'Saved to workspace:',
      {
        linkTo: `/${activeWorkspaceUrl}/library/items?item=${id}`,
        linkText: 'Go to item',
      },
      4000
    )
    return id!
  }

  return { saveStatus, handleSaveToWorkspace, handleSaveToBoard }
}

export default useSaveItemFromDiscover
