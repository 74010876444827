import { DBBilling } from '@kive/shared-types'
import {
  configureStore,
  createListenerMiddleware,
  isAnyOf,
} from '@reduxjs/toolkit'
import { FirebaseReducer, firebaseReducer } from 'react-redux-firebase'
import { AnyAction, CombinedState, combineReducers } from 'redux'
import { firestoreReducer } from 'redux-firestore'

import content from 'store/content/reducer'
import {
  Counter,
  DBBoard,
  DBItemRef,
  DBPlans,
  DBReaction,
  DBSearchableItem,
  DBUploadSession,
  DBUser,
  DBUserPrivate,
  DBWorkspace,
} from 'types/db'

import { ContentState } from './content/types'
import itemSearch, { ItemSearchState } from './itemSearch'
import multiSelect, { MultiSelectState } from './multiSelect'
import publicItemsSaves, { PublicItemsSavesState } from './publicItemsSaves'
import ui, { UIState, loadUIState, saveUIState, setLibrarySubView } from './ui'

export type WithId<T> = T & { id: string }
type FirestoreData<T> = Record<string, T>
type FirestoreOrdered<T> = T[]

interface FirestoreState {
  data: {
    userPrivate?: DBUserPrivate
    users?: FirestoreData<DBUser>
    workspaces?: FirestoreData<DBWorkspace>
    uploadSessions?: FirestoreData<DBUploadSession>
    searchableItems?: FirestoreData<DBSearchableItem>
    itemRefs?: FirestoreData<DBItemRef>
    boards?: FirestoreData<DBBoard>
    recentBoards?: FirestoreData<DBBoard>
    mostRecentBoard?: FirestoreData<DBBoard>
    activeBoards?: FirestoreData<DBBoard>
    usage?: DBUserPrivate['usage']
    plans?: DBPlans
    billing?: FirestoreData<DBBilling>
    defaultPlans?: DBPlans
    workspaceItemCounter?: Counter
    ownReactions?: FirestoreData<DBReaction>
    reactions?: FirestoreData<DBReaction>
    adminWorkspaces?: FirestoreData<DBWorkspace>
    memberWorkspaces?: FirestoreData<DBWorkspace>
    guestWorkspaces?: FirestoreData<DBWorkspace>
    [id: string]: any
  }
  ordered: {
    boards?: FirestoreOrdered<WithId<DBBoard>>
    recentBoards?: FirestoreOrdered<WithId<DBBoard>>
    mostRecentBoard?: FirestoreOrdered<WithId<DBBoard>>
    activeBoards?: FirestoreOrdered<WithId<DBBoard>>
    ownReactions?: FirestoreOrdered<WithId<DBReaction>>
    reactions?: FirestoreOrdered<WithId<DBReaction>>
    [id: string]: any
  }

  errors: {
    byQuery: {
      [queryId: string]: any
    }
    allIds: any[]
  }
  [id: string]: any
}

interface State {
  firebase: FirebaseReducer.Reducer
  firestore: FirestoreState
  ui: UIState
  content: ContentState
  multiSelect: MultiSelectState
  itemSearch: ItemSearchState
  publicItemsSaves: PublicItemsSavesState
}

const appReducer = combineReducers<State>({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  ui,
  content,
  multiSelect,
  itemSearch,
  publicItemsSaves,
})

const rootReducer = (
  state: CombinedState<State> | undefined,
  action: AnyAction
) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}

const listenerMiddleware = createListenerMiddleware()
listenerMiddleware.startListening({
  matcher: isAnyOf(setLibrarySubView),
  effect: () => {
    saveUIState(store.getState().ui)
  },
})

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState: {
    ui: loadUIState(),
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false, // we have to temporary put this here to make uploads work
      serializableCheck: {
        ignoreState: true, // when we remove firestore and firebase from redux we can disable this
        ignoreActions: true, // when we remove firestore and firebase from redux we can disable this
      },
    }).prepend(listenerMiddleware.middleware),
})

export type RootState = ReturnType<typeof rootReducer>
