import { DBItemThumbVersion } from 'types/db'

import getTypeCategory from './getTypeCategory'

const getThumbsVersion = (
  thumbs: DBItemThumbVersion[] | undefined,
  type?: 'image' | 'gif'
) => {
  if (!thumbs) return null
  if (type === 'gif') {
    return thumbs.find((thumb) => thumb.type === 'gif')
  }
  return thumbs.find((thumb) => getTypeCategory(thumb.type) === 'image')
}

export default getThumbsVersion
