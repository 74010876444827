import { getAlpha } from './getAlpha'

const getDevicePixelRatioSize = (scale: number) => {
  switch (devicePixelRatio) {
    case 1:
      return (512 / 2) * scale
    case 2:
      return (512 / 1) * scale
    case 3:
      return (512 / 0.67) * scale
    default:
      return (512 / 1) * scale
  }
}
export function getCutout(
  canvas: CanvasImageSource,
  positionX: number,
  positionY: number,
  scale: number
) {
  const offScreenCanvas = document.createElement('canvas')
  offScreenCanvas.width = 512
  offScreenCanvas.height = 512
  const offScreenContext = offScreenCanvas.getContext('2d')
  if (!offScreenContext) return null
  offScreenContext.drawImage(
    canvas,
    positionX,
    positionY,
    getDevicePixelRatioSize(scale),
    getDevicePixelRatioSize(scale),
    0,
    0,
    512,
    512
  )

  const imageDataString = offScreenCanvas.toDataURL('image/jpeg')

  // Get the alpha
  const imageData = offScreenContext.getImageData(0, 0, 512, 512)
  const updatedImageData = getAlpha(imageData)
  offScreenContext.putImageData(updatedImageData, 0, 0)
  const maskDataString = offScreenCanvas.toDataURL('image/jpeg')

  // Put the image back
  offScreenContext.putImageData(imageData, 0, 0)

  return {
    imageDataString,
    maskDataString,
  }
}
