import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import Loading from 'components/Loading'
import { UserProjectsQuery, useUserProjectsQuery } from 'generated/graphql'
import { RootState } from 'store'

import SelectCommonListItem from './SelectCommonListItem'

export type OnSelectProjectArgs = {
  projectId: string
  projectTitle: string
  projectWorkspaceId: string
}

interface FindProjectProps {
  projectInputValue: string
  onSelectProject: (args: OnSelectProjectArgs) => void
  accessLevel: 'reader' | 'writer'
}

const FindProject: React.FC<FindProjectProps> = ({
  projectInputValue,
  onSelectProject,
  accessLevel,
}) => {
  const activeWorkspaceId = useSelector(
    (state: RootState) => state.content.activeWorkspace!.id
  )

  const accessLevelPayload = accessLevel.toUpperCase() as Uppercase<
    typeof accessLevel
  >

  const { data, loading } = useUserProjectsQuery({
    variables: {
      workspaceId: activeWorkspaceId,
      accessLevel: accessLevelPayload,
    },
    fetchPolicy: 'cache-and-network',
  })
  const projects = data?.projects.projects || []

  // OBS: Projects does not support search atm so we filter on the client
  const [filteredProjects, setFilteredProjects] = useState<
    UserProjectsQuery['projects']['projects']
  >([])

  const sortProjects = ({
    passedProjects,
  }: {
    passedProjects: UserProjectsQuery['projects']['projects']
  }) => {
    return [...passedProjects].sort(
      (a, b) =>
        new Date(b.updatedAt || new Date()).getTime() -
        new Date(a.updatedAt || new Date()).getTime() // DESC
    )
  }

  useEffect(() => {
    // if (accessLevel === 'writer') return
    if (!projectInputValue) {
      return setFilteredProjects(projects)
    }
    setFilteredProjects((prevProjects) =>
      prevProjects.filter(
        (project) =>
          project.title
            ?.toLowerCase()
            .indexOf(projectInputValue.toLowerCase()) !== -1
      )
    )
  }, [projectInputValue, projects.length])

  return (
    <StyledFindProjectWrapper>
      {loading && filteredProjects.length === 0 && <Loading />}
      {sortProjects({ passedProjects: filteredProjects }).map((project) => (
        <SelectCommonListItem
          key={project.id}
          id={project.id}
          title={project.title}
          thumbUrl={project.thumbUrls[0]}
          onAction={() =>
            onSelectProject({
              projectId: project.id,
              projectTitle: project.title,
              projectWorkspaceId: project.workspaceId,
            })
          }
        />
      ))}
    </StyledFindProjectWrapper>
  )
}

export default FindProject

const StyledFindProjectWrapper = styled.div`
  height: auto;
  max-height: 280px;
  overflow-y: auto;
  margin-top: 8px;
`
