import { clearSessionPersistence } from 'clientPersistence/uploadSessionsDb'
import React, { useEffect } from 'react'
import { ConnectedComponent, connect, useSelector } from 'react-redux'
import { ReduxFirestoreQueries, firestoreConnect } from 'react-redux-firebase'
import { useNavigate } from 'react-router-dom'
import { compose } from 'redux'

import useUploadSessions from 'components/upload/useUploadSessions'
import type { RootState } from 'store'
import type { DBUploadSession } from 'types/db'

import {
  UploadSessionsRemoteUploadPayload,
  UploadSessionsRemoteUploadState,
  useUploadSessionsRemoteUpload,
} from '../upload/useUploadSessionsRemoteUpload'
import UploadItemComplete from './UploadItemComplete'
import MagicTagging from './UploadItemMagicTagging'
import UploadProgress from './UploadItemUploading'
import {
  ItemContainer,
  ItemList,
  ItemListContainer,
  Loading,
} from './UploadListingStyles'

const enableUploadPersistance =
  process.env.REACT_APP_UPLOAD_PERSISTANCE?.toLowerCase() === 'true'

const getSessionStatus = (activeSession?: DBUploadSession) => {
  if (!activeSession) return 'none'
  if (activeSession.isDeleting) return 'deleting'
  if (!activeSession.hasFinishedUploading) return 'uploading'
  if (!activeSession.hasFinishedAnalyzing) return 'magicTagging'
  return 'done'
}

interface UploadSessionListItemOwnProps {
  sessionId: string
  dismiss: () => void
  bytesTransferred: number
  totalUploadSize: number
  droppedFileCount: number
  uploadComplete: boolean
  hasDBSession: boolean
  dismissed: boolean | null
}

interface UploadSessionListItemPropsFromState {
  activeSession: DBUploadSession
}

interface UploadSessionListItemProps
  extends UploadSessionListItemOwnProps,
    UploadSessionListItemPropsFromState {}

const UploadSessionListItem: React.FC<UploadSessionListItemProps> = ({
  sessionId,
  activeSession,
  dismiss,
  bytesTransferred,
  totalUploadSize,
  droppedFileCount,
  uploadComplete,
}) => {
  const navigate = useNavigate()
  const gotoUploadSession = () => {
    navigate(`/${activeSession.workspaceId}/library/upload-events/${sessionId}`)
  }

  // Clear upload session from indexeddb when analyzing is finished
  useEffect(() => {
    if (activeSession?.hasFinishedAnalyzing && enableUploadPersistance) {
      clearSessionPersistence(sessionId)
    }
  }, [activeSession?.hasFinishedAnalyzing, sessionId])

  // Map uploadsession status to ui-state
  const status = getSessionStatus(activeSession)

  if (status === 'deleting') return null

  // video frames upload creates its upload session after upload so we defalt back to bytesTransferred greater than 0 to support that
  const isUploadingLocally = bytesTransferred > 0 && !uploadComplete

  const remoteUploadComponent = {
    uploading: (
      <UploadProgress
        bytesTransferred={bytesTransferred}
        totalUploadSize={totalUploadSize}
        dismiss={dismiss}
      />
    ),
    magicTagging: <MagicTagging dismiss={dismiss} />,
    done: (
      <UploadItemComplete
        dismiss={dismiss}
        gotoUploadSession={gotoUploadSession}
      />
    ),
    none: (
      <>
        <Loading />
        <div>Preparing to upload {droppedFileCount} files...</div>
      </>
    ),
  }
  return (
    <ItemContainer>
      {isUploadingLocally ? (
        <UploadProgress
          bytesTransferred={bytesTransferred}
          totalUploadSize={totalUploadSize}
          dismiss={dismiss}
        />
      ) : (
        remoteUploadComponent[status]
      )}
    </ItemContainer>
  )
}

const mapStateToProps = (
  state: RootState,
  ownProps: UploadSessionListItemOwnProps
) => {
  const activeSession =
    state.firestore.data.uploadSessions?.[ownProps.sessionId]
  return {
    activeSession,
  }
}

const ConnectedItem = compose(
  connect(mapStateToProps),
  firestoreConnect((props: UploadSessionListItemProps) => {
    const sessionId = props.sessionId
    if (!sessionId || !props.hasDBSession) return []

    const queries: ReduxFirestoreQueries = [
      {
        collection: 'uploadSessions',
        doc: sessionId,
      },
    ]
    return queries
  })
)(UploadSessionListItem) as ConnectedComponent<
  React.ComponentType<UploadSessionListItemOwnProps>,
  UploadSessionListItemOwnProps
>

const getRemoteUploadSessionStatus = (
  uploadSessions: UploadSessionsRemoteUploadPayload
) => {
  if ('isLocal' in uploadSessions) return 'isLocal'
  if (uploadSessions.remoteUpload?.isExtractingFrames) return 'extractingFrames'
  if (uploadSessions.isDeleting) return 'deleting'
  if (uploadSessions.shouldDelete) return 'deleting'
  if (uploadSessions.isCompleted) return 'done'
  if (!uploadSessions.hasFinishedUploading) return 'uploading'
  if (!uploadSessions.hasFinishedAnalyzing) return 'magicTagging'
  return 'none'
}

const UploadSessionRemoteUploadListItem = ({
  uploadSession,
  onDismiss,
}: {
  uploadSession: UploadSessionsRemoteUploadPayload
  onDismiss: () => void
}) => {
  const navigate = useNavigate()

  const gotoUploadSession = (upSession: UploadSessionsRemoteUploadState) => {
    navigate(`/${upSession.workspaceId}/library/upload-events/${upSession.id}`)
  }

  const status = getRemoteUploadSessionStatus(uploadSession)

  if (status === 'deleting') return null
  if (status === 'none') return null

  const getRemoteUploadComponent = () => {
    if (status === 'isLocal') {
      return (
        <>
          <Loading />
          <div>{'isLocal' in uploadSession && uploadSession.status}</div>
        </>
      )
    }
    if ('isLocal' in uploadSession) return

    if (status === 'extractingFrames') {
      return (
        <>
          <Loading />
          <div>Extracting from video...</div>
        </>
      )
    }

    if (status === 'uploading') {
      return (
        <UploadProgress
          bytesTransferred={uploadSession.remoteUpload!.uploadedBytes}
          totalUploadSize={uploadSession.remoteUpload!.totalBytes}
          dismiss={onDismiss}
        />
      )
    }
    if (status === 'magicTagging') {
      return <MagicTagging dismiss={onDismiss} />
    }
    if (status === 'done') {
      return (
        <UploadItemComplete
          dismiss={onDismiss}
          gotoUploadSession={() => gotoUploadSession(uploadSession)}
        />
      )
    }
  }
  return <ItemContainer>{getRemoteUploadComponent()}</ItemContainer>
}

const UploadSessionList: React.FC<{ hidden?: boolean }> = ({ hidden }) => {
  const { sessions } = useUploadSessions()
  const { remoteUploadSessions, handleRemoteUploadSessionDismiss } =
    useUploadSessionsRemoteUpload()

  const isUploadViewVisible = useSelector(
    (state: RootState) => state.content.isUploadVisible
  )
  const siderSplitValue = useSelector(
    (state: RootState) => state.ui.siderSplitValue
  )

  const shouldDisplaySessions = !isUploadViewVisible && !hidden

  return (
    <ItemListContainer left={siderSplitValue}>
      <ItemList>
        <>
          {shouldDisplaySessions && (
            <>
              {sessions.map((session) => {
                if (session.dismissed) return null
                return (
                  <ConnectedItem
                    key={session.id}
                    sessionId={session.id}
                    {...session}
                  />
                )
              })}
              {Object.entries(remoteUploadSessions).map(
                ([key, remoteUploadSession]) => {
                  if (remoteUploadSession.dismissed) return null
                  return (
                    <UploadSessionRemoteUploadListItem
                      key={key}
                      onDismiss={() => handleRemoteUploadSessionDismiss(key)}
                      uploadSession={remoteUploadSession}
                    />
                  )
                }
              )}
            </>
          )}
        </>
      </ItemList>
    </ItemListContainer>
  )
}

export default UploadSessionList
