// @ts-nocheck
import { FC, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { useLocation, useNavigate } from 'react-router-dom'
import styled, { css, useTheme } from 'styled-components'

import { API } from 'api'
import Button from 'components/common/Button'
import Modal from 'components/common/Modal'
import { Text } from 'components/common/Text'
import { trackWorkspaceDeleted } from 'helpers/tracking/tracking'
import { validateAllowedDomain } from 'helpers/validateAllowedDomain'
import { workspacesSelector } from 'store/selectors'

import callCloudFunction from '../../../helpers/callCloudFunction'
import { createWorkspaceUrlValidator } from '../../../helpers/workspaceUtils'
import { Label } from '../../common/Form'
import Spinner from '../../common/Spinner'
import useToastMessages from '../../toast/useToastMessages'
import {
  Heading,
  HeadingSecondary,
  Input,
  InputNeutral,
  SettingsContent,
} from '../SettingsStyles'
import ThumbnailUpload from '../ThumbnailUpload'
import useSettingNavigation from '../useSettingsNavigation'

const StyledDangerZoneBox = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.redLighter};
    padding: 16px;
    border-radius: ${theme.borderRadius.sm};
  `}
`

const StyledButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledModalHeading = styled.h1`
  ${({ theme }) => css`
    font-weight: ${theme.fontWeight.bold};
    margin-bottom: 12px;
    font-size: ${theme.fontSizes.lg};
    color: ${theme.colors.text.neutral[1]};
  `}
`

const StyledModalText = styled.p`
  color: ${({ theme }) => theme.colors.text.neutral[1]};
  margin-bottom: 16px;
`

const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  div:last-child {
    margin: 0 0 0 4px;
  }
  div:first-child {
    margin: 0 4px 0 0;
  }
`

const StyledButtonWrapper = styled.div`
  width: 50%;
`

const WorkspaceDeleteModal = ({ isOpen, close, workspaceTitle, onDelete }) => {
  const [confirmationInputText, setConfirmationInputText] = useState()
  return (
    <Modal isOpen={isOpen} close={close}>
      <Modal.ContentWrapper>
        <StyledModalHeading>
          Are you sure you want to delete the workspace?
        </StyledModalHeading>
        <StyledModalText>
          It will be gone forever. Type{' '}
          <span style={{ fontWeight: 'bold' }}>{workspaceTitle}</span> to
          confirm.
        </StyledModalText>
        <InputNeutral
          name="deleteConfirmation"
          onChange={(e) => setConfirmationInputText(e.target.value)}
          autoComplete="off"
          required
          style={{ marginBottom: 16 }}
        />
        <StyledButtonsWrapper>
          <StyledButtonWrapper>
            <Button
              bold
              fullWidth
              variant="danger"
              onClick={onDelete}
              disabled={confirmationInputText !== workspaceTitle}
            >
              Delete
            </Button>
          </StyledButtonWrapper>
          <StyledButtonWrapper>
            <Button bold fullWidth onClick={close}>
              Close
            </Button>
          </StyledButtonWrapper>
        </StyledButtonsWrapper>
      </Modal.ContentWrapper>
    </Modal>
  )
}

type WorkspaceSettingsProps = {
  workspace: any
  firestore: any
}

const WorkspaceSettings: FC<WorkspaceSettingsProps> = ({
  workspace,
  firestore,
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const navigate = useNavigate()
  const { pathname, search } = useLocation()
  const { close: closeSettings } = useSettingNavigation()
  const { reportSuccess } = useToastMessages()
  const theme = useTheme()

  const workspaces = useSelector(workspacesSelector) || {}

  const updateWorkspaceIcon = (file: any) => {
    const formData = new FormData()
    formData.append('image', file, file.name)
    return callCloudFunction(
      `addWorkspaceIcon?workspaceId=${workspace.id}`,
      formData
    )
  }

  const {
    register,
    reset,
    handleSubmit,
    formState: { isDirty, isSubmitting, errors },
  } = useForm({
    mode: 'onChange',
    submitFocusError: true,
  })

  useEffect(() => {
    reset({
      workspaceUrl: workspace.url,
      workspaceTitle: workspace.title,
      allowedEmailDomains: workspace.allowedEmailDomains?.join(','),
    })
  }, [reset, workspace.title, workspace.url, workspace.allowedEmailDomains])

  const validateWorkspaceUrl = useMemo(
    () => createWorkspaceUrlValidator(firestore, workspace),
    [firestore, workspace]
  )

  const validateAllowedDomains = async (value) => {
    const domains = value.split(',')
    const result = await Promise.all(
      domains.map((d) => d.trim()).map(validateAllowedDomain)
    )
    return result.filter((r) => r)[0]
  }

  const onSubmit = async (values) => {
    const allowedEmailDomains =
      values.allowedEmailDomains?.split(',').map((d) => d.trim()) ?? []

    await firestore.update(
      { collection: 'workspaces', doc: workspace.id },
      {
        url: values.workspaceUrl,
        title: values.workspaceTitle,
        allowedEmailDomains,
      }
    )
    navigate(
      `${pathname.replace(workspace.url, values.workspaceUrl)}${search}`,
      { replace: true }
    )
    reportSuccess('Workspace data updated')
  }

  const redirectToOtherWorkspace = () => {
    const otherWorkspaceEntry = Object.entries(workspaces).find(
      ([id]) => id !== workspace.id
    )

    if (!otherWorkspaceEntry) {
      navigate(`/setup/workspace`)
      return
    }

    const [, otherWorkspace] = otherWorkspaceEntry

    navigate(`/${otherWorkspace.url}`)
  }

  const buttonsDisabled =
    !isDirty ||
    isSubmitting ||
    Object.values(errors).some((error) => error != null)

  return (
    <>
      <WorkspaceDeleteModal
        isOpen={isDeleteModalOpen}
        close={() => setIsDeleteModalOpen(false)}
        workspaceTitle={workspace.url}
        onDelete={() => {
          API.deleteWorkspace(workspace.id)
          firestore
            .collection('workspaces')
            .doc(workspace.id)
            .update({ isDeleting: true })
          trackWorkspaceDeleted({
            workspaceId: workspace.id,
            workspaceTitle: workspace.title,
            workspaceUrl: workspace.url,
          })
          redirectToOtherWorkspace()
        }}
      />
      <SettingsContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <Heading noMarginTop>Workspace settings</Heading>
        <HeadingSecondary style={{ marginBottom: 12 }}>Name</HeadingSecondary>
        <Input name="workspaceTitle" {...register('workspaceTitle')} required />
        <Label>
          You can use your name or the name of your team. Keep it simple.
        </Label>
        <HeadingSecondary>Icon</HeadingSecondary>
        <ThumbnailUpload
          src={workspace.icon}
          onFilePick={updateWorkspaceIcon}
        />
        <Label>
          Upload an image or to represent your workspace. It will show in your
          sidebar and notifications.
          {/* TODO: Emoji selector. Upload an image or pick an emoji. It will show in your sidebar and
        notifications. */}
        </Label>
        <HeadingSecondary>Domain</HeadingSecondary>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span
            style={{
              fontSize: theme.fontSizes.sm,
              color: theme.colors.text.neutral[0],
              marginRight: 4,
            }}
          >
            {window.location.hostname}/
          </span>
          <div style={{ flex: 1 }}>
            <Input
              name="workspaceUrl"
              {...register('workspaceUrl', { validate: validateWorkspaceUrl })}
            />
          </div>
        </div>
        <span style={{ color: theme.colors.accent[0] }}>
          {errors.workspaceUrl?.message}
        </span>
        <HeadingSecondary>Allowed email domains</HeadingSecondary>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ flex: 1 }}>
            <Input
              name="allowedEmailDomains"
              {...register('allowedEmailDomains', {
                validate: validateAllowedDomains,
              })}
            />
          </div>
        </div>
        <div style={{ color: theme.colors.accent[0] }}>
          {errors.allowedEmailDomains?.message}
        </div>
        <Label>
          When anyone with an email address from these domains joins Kive, they
          can automatically join this workspace. If you&apos;re on a paid plan,
          this will increase your bill.
        </Label>
        <br />
        <br />
        <div style={{ display: 'flex', marginBottom: 48 }}>
          <Button
            type="submit"
            variant="primary"
            style={{ width: 150, marginRight: 16 }}
            disabled={buttonsDisabled}
          >
            {isSubmitting ? <Spinner size={14} /> : 'Save'}
          </Button>
          <Button
            subdued
            type="button"
            style={{ width: 150 }}
            // disabled={buttonsDisabled}
            onClick={closeSettings}
          >
            Cancel
          </Button>
        </div>
        <HeadingSecondary style={{ marginTop: 0 }}>
          Danger zone
        </HeadingSecondary>
        <StyledDangerZoneBox>
          <StyledButtonRow>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text size="sm" color="neutral.1" bold>
                Delete workspace
              </Text>
              <Text size="sm" color="neutral.2">
                This will permanently delete your workspace and all its content.
                <br />
                If the workspace is on a paid plan, the subscription will be
                canceled.
              </Text>
            </div>
            <Button
              type="button"
              variant="slight-danger"
              onClick={() => setIsDeleteModalOpen(true)}
            >
              Delete workspace
            </Button>
          </StyledButtonRow>
        </StyledDangerZoneBox>
        <br />
        <br />
      </SettingsContent>
    </>
  )
}
export default firestoreConnect()(WorkspaceSettings)
