const track = (
  type: string,
  context?: Record<string, string | boolean | number | undefined | null>
) => {
  window.analytics.track(type, context)
}

export const trackFaceSearchOptionClicked = (context: {
  optionGroup: string
  optionItem: string
}) => {
  track('Face Search Option Clicked', context)
}

export const trackColorSearchChangedColor = (context: { hex: string }) => {
  track('Color Search Changed Color', context)
}

export const trackItemColorClicked = () => {
  track('Item Color Clicked')
}

export const trackSearchCategoriesExpanded = () => {
  track('Search Categories Expanded')
}

export const trackSearchFreeTextUpdated = (context: {
  freeTextQuery: string
}) => {
  track('Search Free Text Updated', context)
}

export const trackSearchCategoryClicked = (context: {
  categoryTitle: string
}) => {
  track('Search Category Clicked', context)
}

export const trackSearchTagClicked = (context: {
  label: string
  id: string
}) => {
  track('Search Tag Clicked', context)
}

export const trackMultiSelectItemsDownloaded = (context: {
  nbrOfItems: number
  workspaceId: string
}) => {
  track('Multi Select Items Downloaded', context)
}

export const trackMultiSelectItemsSavedToBoard = (context: {
  nbrOfItems: number
  workspaceId: string
}) => {
  track('Multi Select Items Saved To Board', context)
}

export const trackMultiSelectItemsDeleted = (context: {
  nbrOfItems: number
  workspaceId: string
}) => {
  track('Multi Select Items Deleted', context)
}

export const trackMultiSelectItemsAddedTag = (context: {
  nbrOfItems: number
  workspaceId: string
}) => {
  track('Multi Select Items Added Tag', context)
}

export const trackMultiSelectModeActivated = () => {
  track('Multi Select Mode Activated')
}
