import { LiveObject } from '@liveblocks/client'
import { ClientSideSuspense } from '@liveblocks/react'
import { client } from 'apollo'
import Konva from 'konva/lib/Core'
import { useEffect, useRef, useState } from 'react'
import { Layer, Stage, Text } from 'react-konva'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

import useToastMessages from 'components/toast/useToastMessages'
import {
  RoomContext,
  useHistory,
  useMutation,
  useUpdateMyPresence,
} from 'config/liveblocks.config'
import { useOutpaintMutation, useUserQuery } from 'generated/graphql'
import callCloudFunction from 'helpers/callCloudFunction'
import {
  trackOutpaintAccepted,
  trackOutpaintCreated,
} from 'helpers/tracking/tracking'
import { RootState } from 'store'

import { AddImage } from './AddImage'
import Controls from './Controls'
import { CursorsOthers } from './CursorsOthers'
import { EraseCursor } from './EraseCursor'
import { QueuedImageProps } from './FileUploader'
import GenerationFrame from './GenerationFrame'
import { GenerationFramesOthers } from './GenerationFramesOthers'
import { LensType } from './Lenses'
import {
  OutpaintShapesRenderer,
  useCanvasBounds,
} from './OutpaintShapesRenderer'
import { PublicBanner } from './PublicBanner'
import { SignupModal } from './components/SignupModal/SignupModal'
import { getCutout } from './helpers/getCutout'
import { useDownloadModule } from './hooks/useDownloadModule'
import { useErasingModule } from './hooks/useErasingModule'
import { useGesturesModule } from './hooks/useGesturesModule'
import { useZoomModule } from './hooks/useZoomModule'
import { Tool } from './types'

type Props = {
  editSessionId: string
}

export const Outpaint = ({ editSessionId }: Props) => {
  const navigate = useNavigate()
  const { updateCanvasBounds } = useCanvasBounds()

  const [showGuide, setShowGuide] = useState(false)
  const userId = useSelector((state: RootState) => state.firebase.auth.uid)
  const [showSignupModal, setShowSignupModal] = useState(false)
  const [hasOutput, setHasOutput] = useState(false)
  const [canUndo, setCanUndo] = useState(false)
  const [canRedo, setCanRedo] = useState(false)
  const [activeLens, setActiveLens] = useState<null | LensType>(null)
  const [eraserSize, setEraserSize] = useState(50)
  const [imageQueue, setImageQueue] = useState<QueuedImageProps[]>([])
  const [currentTool, setCurrentTool] = useState<Tool>('generationFrame')
  const [prevTool, setPrevTool] = useState<Tool>()
  const [inputIsFocused, setInputIsFocused] = useState(false)
  const [generationFramePosition, setGenerationFramePosition] = useState({
    x: 200,
    y: 100,
  })
  const [canvasPosition, setCanvasPosition] = useState({
    x: 0,
    y: 0,
  })

  const canvasRef = useRef<any>(null)
  const stageRef = useRef<any>(null)
  const mainRef = useRef<any>(null)
  const thumbnailRef = useRef<any>(null)

  const history = useHistory()
  const updateMyPresence = useUpdateMyPresence()
  const { reportError } = useToastMessages()

  const { data: userData, refetch: userRefetch } = useUserQuery({
    variables: {
      uid: userId,
    },
    skip: !userId,
  })
  const [outpaint, { data, loading }] = useOutpaintMutation()

  const setTool = (tool: Tool, previousTool?: Tool) => {
    setCurrentTool(tool)
    setPrevTool(previousTool)
  }

  useEffect(() => {
    if (!userId) {
      return
    }

    userRefetch({
      uid: userId,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === ' ' && !inputIsFocused) {
        // Don't propagate space's when using space to pan
        if (currentTool === 'pan' && prevTool) {
          e.preventDefault()
          return
        }

        if (
          (currentTool === 'erasor' ||
            currentTool === 'generationFrame' ||
            currentTool === 'select') &&
          !prevTool
        ) {
          e.preventDefault()
          setTool('pan', currentTool)
          setInputIsFocused(false)
        }
      }
    }

    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === ' ') {
        if (currentTool === 'pan' && prevTool) {
          e.preventDefault()
          setTool(prevTool)
          setInputIsFocused(false)
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('keyup', handleKeyUp)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('keyup', handleKeyUp)
    }
  }, [currentTool, prevTool, inputIsFocused])

  useEffect(() => {
    updateMyPresence({
      photoURL: userData?.user.photoURL,
      name: userData?.user.displayName,
    })
  }, [updateMyPresence, userData?.user.photoURL, userData?.user.displayName])

  const { currentScale, zoom } = useZoomModule({
    stageRef,
    setCanvasPosition,
  })

  useEffect(() => {
    if (userData?.user.frozenAccount) {
      navigate('/frozen-account')
    }
  }, [userData?.user.frozenAccount, navigate])

  useEffect(() => {
    if (imageQueue.length) {
      setTool('select')
    } else {
      setTool('pan')
    }
  }, [imageQueue.length])

  const updateUndoRedo = () => {
    setCanUndo(history.canUndo())
    setCanRedo(history.canRedo())
  }

  const {
    isDownloadSelectionActive,
    activateDownloadSelection,
    cancelDownloadSelection,
    isDraggingDownloadSelection,
    downloadSelectionCanvasComponent,
    downloadSelectionOptionsComponent,
  } = useDownloadModule({
    stageRef,
    canvasRef,
    scale: currentScale,
    editSessionId,
  })

  useEffect(() => {
    if (isDownloadSelectionActive) {
      setTool('select')
    } else {
      setTool('pan')
    }
  }, [isDownloadSelectionActive])

  const { updateEraserCursorPosition } = useErasingModule({
    isErasing: currentTool === 'erasor',
    eraserSize,
    stageRef,
    onNewHistory: updateUndoRedo,
  })

  useGesturesModule({
    stageRef,
    targetRef: mainRef,
    zoom,
    setCanvasPosition,
    updateEraserCursorPosition,
    currentTool,
    inputIsFocused,
  })

  const handleSubmit = async (prompt: string) => {
    setTool('pan')
    if (!canvasRef || !canvasRef.current) {
      return
    }
    const canvas = canvasRef.current.getCanvas()._canvas
    const cutout = getCutout(
      canvas,
      (generationFramePosition.x * currentScale + canvasPosition.x) *
        window.devicePixelRatio,
      (generationFramePosition.y * currentScale + canvasPosition.y) *
        window.devicePixelRatio,
      currentScale
    )
    if (!cutout) {
      throw new Error('Get cutout failed')
    }
    const { imageDataString, maskDataString } = cutout
    let generationFramePrompt = prompt
    if (activeLens?.name) {
      generationFramePrompt = `"${prompt}":::lens:::${activeLens?.name}`
    }
    updateMyPresence({
      generationFrameStatus: 'GENERATING',
      generationFramePrompt,
    })

    let joinedPrompt = prompt
    if (activeLens?.value) joinedPrompt = `${prompt}, ${activeLens.value}`

    try {
      const res = await outpaint({
        variables: {
          prompt: joinedPrompt,
          imageData: imageDataString,
          maskData: maskDataString,
          editSessionId,
        },
      })

      trackOutpaintCreated({
        prompt,
        lens: activeLens?.value,
        editSessionId,
      })
      if (!res.data) {
        throw new Error('Missing data')
      }
      setHasOutput(true)
      updateMyPresence({
        generationFrameStatus: 'HAS_OUTPUT',
        generationFrameAlternatives: res.data.outpaint,
      })

      await client.refetchQueries({
        include: ['creditsUser'],
      })
    } catch (error) {
      if (error?.message?.includes('NSFW')) {
        reportError(`Error: ${error.message}`)
      } else {
        reportError(
          'Error generating image. Try again or pick a new generation spot'
        )
      }
      updateMyPresence({
        generationFrameStatus: 'PLACED',
      })
    }
  }

  const finishPlacing = ({
    newPosition,
  }: {
    newPosition: { x: number; y: number }
  }) => {
    setGenerationFramePosition({ ...newPosition })
    updateMyPresence({
      generationFrameStatus: 'PLACED',
    })

    setTool('pan')
  }

  const addShape = useMutation(({ storage }, shapeObject) => {
    const shapeId = Date.now().toString() // FIXME: Better id?
    const shapes = storage.get('shapes')
    if (shapes && shapes instanceof LiveObject) {
      shapes.set(shapeId, new LiveObject(shapeObject))
    }

    updateUndoRedo()
    return shapeId
  }, [])

  const addImageToCanvas = (
    id: string,
    imageUrl: string | null,
    x: number,
    y: number,
    w: number,
    h: number
  ) => {
    addShape({
      type: 'image',
      url: imageUrl,
      height: h,
      width: w,
      x,
      y,
      order: Math.floor(new Date().getTime() / 1000),
      globalCompositeOperation: 'source-over',
    })
    setImageQueue((prev) => {
      return prev.filter((image) => image.id !== id)
    })
  }

  const cancelImage = (id: string) => {
    setImageQueue((prev) => prev.filter((image) => image.id !== id))
  }

  const updateThumbnail = () => {
    const THUMBNAIL_WIDTH = 1920
    const THUMBNAIL_HEIGHT = 1080
    const THUMBNAIL_MARGIN = 50

    // Calculate the outer bounds of all the layers in the canvas
    const canvasBounds = updateCanvasBounds()

    const offScreenCanvas = document.createElement('canvas')
    offScreenCanvas.width = THUMBNAIL_WIDTH
    offScreenCanvas.height = THUMBNAIL_HEIGHT

    if (!thumbnailRef.current) {
      thumbnailRef.current = new Konva.Stage({
        container: 'thumbnail-container',
        width: THUMBNAIL_WIDTH,
        height: THUMBNAIL_HEIGHT,
      })
    }

    const thumbnailStage = thumbnailRef.current

    const previewLayer = canvasRef.current.clone({ listening: false })
    thumbnailStage.destroy()
    thumbnailStage.add(previewLayer)
    thumbnailStage.scale({ x: 1, y: 1 })
    thumbnailStage.position({ x: 0, y: 0 })

    thumbnailStage.toImage({
      x: canvasBounds.x,
      y: canvasBounds.y,
      width: canvasBounds.width,
      height: canvasBounds.height,
      callback(canvasAsImage: any) {
        const offScreenContext = offScreenCanvas.getContext('2d')
        if (!offScreenContext) {
          thumbnailStage.destroy()
          return null
        }

        // Landscape mode
        const aspect = canvasAsImage.width / canvasAsImage.height
        let marginLeft = THUMBNAIL_MARGIN
        let resizedWidth = offScreenCanvas.width - marginLeft * 2
        let resizedHeight = resizedWidth / aspect
        let marginTop = (offScreenCanvas.height - resizedHeight) / 2

        // Portrait mode
        if (aspect < THUMBNAIL_WIDTH / THUMBNAIL_HEIGHT) {
          marginTop = THUMBNAIL_MARGIN
          resizedHeight = offScreenCanvas.height - marginTop * 2
          resizedWidth = resizedHeight * aspect
          marginLeft = (offScreenCanvas.width - resizedWidth) / 2
        }

        offScreenContext.drawImage(
          canvasAsImage,
          0,
          0,
          canvasAsImage.width,
          canvasAsImage.height,
          marginLeft,
          marginTop,
          resizedWidth,
          resizedHeight
        )

        offScreenCanvas.toBlob((blob) => {
          if (!blob) {
            thumbnailStage.destroy()
            return
          }

          const formData = new FormData()
          formData.append('editSessionId', editSessionId)
          formData.append('image', blob, 'thumbnail.png')

          callCloudFunction('updateEditSessionThumbnail', formData)

          thumbnailStage.destroy()
        })
      },
    })
  }

  const acceptOutput = (outputIndex: number) => {
    addShape({
      type: 'image',
      url: data?.outpaint?.[outputIndex],
      height: 512 / 2,
      width: 512 / 2,
      x: generationFramePosition.x,
      y: generationFramePosition.y,
      order: Math.floor(new Date().getTime() / 1000),
      globalCompositeOperation: 'destination-over',
    })
    setHasOutput(false)
    updateMyPresence({
      generationFrameStatus: 'SEEKING',
    })
    trackOutpaintAccepted({
      editSessionId,
      imageUrl: data?.outpaint?.[outputIndex],
      outputIndex,
    })
    setTimeout(() => updateThumbnail(), 2500)
  }

  const handleDownloadButtonClick = () => {
    if (!isDownloadSelectionActive) {
      activateDownloadSelection()
      setTool('pan')
      return null
    }
    return cancelDownloadSelection()
  }

  const handleOnGenerationFrameClick = () => {
    setTool('generationFrame')
    cancelDownloadSelection()
  }

  const handleOnHandToolClick = () => {
    setTool('pan')
    cancelDownloadSelection()
  }

  const handleOnEraserClick = () => {
    setTool('erasor')
    cancelDownloadSelection()
  }

  const handleOnSelectToolClick = () => {
    setTool('select')
    cancelDownloadSelection()
  }

  const onDragStage = (event: { evt: React.MouseEvent<HTMLElement> }) => {
    event.evt.preventDefault()
    if (stageRef.current !== null) {
      const stage = stageRef.current
      const newPosition = {
        x: stage.x(),
        y: stage.y(),
      }
      setCanvasPosition(newPosition)
    }
  }

  const getCursorVariant = () => {
    if (isDraggingDownloadSelection) return 'grabbing'
    switch (currentTool) {
      case 'generationFrame':
        return 'grabbing'
      case 'erasor':
        return 'none'
      case 'pan':
        return 'grab'
      default:
        return 'pointer'
    }
  }

  return (
    <>
      <div
        ref={mainRef}
        onPointerMove={(e) => {
          const mouseY = e.clientY
          const mouseX = e.clientX
          updateMyPresence({
            cursor: {
              x: (mouseX - stageRef.current?.x()) / currentScale,
              y: (mouseY - stageRef.current?.y()) / currentScale,
            },
          })
          updateEraserCursorPosition({
            mouseX,
            mouseY,
            scale: currentScale,
          })
        }}
        onPointerLeave={() => updateMyPresence({ cursor: null })}
        style={{
          cursor: getCursorVariant(),
          width: '100vw',
          height: '100vh',
          overflow: 'hidden',
          position: 'fixed',
          top: 0,
          touchAction: 'none',
        }}
      >
        <CursorsOthers stageRef={stageRef} scale={currentScale} />

        <Controls
          eraserSize={eraserSize}
          setEraserSize={setEraserSize}
          isPlacingGenerationFrame={currentTool === 'generationFrame'}
          isPlacingImages={imageQueue.length > 0}
          isErasing={currentTool === 'erasor'}
          isLoading={loading}
          hasOutput={hasOutput}
          handleSubmit={handleSubmit}
          activeLens={activeLens}
          setActiveLens={setActiveLens}
          disableControls={hasOutput || imageQueue.length > 0}
          onGenerationFrameClick={handleOnGenerationFrameClick}
          onEraserClick={handleOnEraserClick}
          onHandToolClick={handleOnHandToolClick}
          onDownloadClick={handleDownloadButtonClick}
          onSelectToolClick={handleOnSelectToolClick}
          isDownloadSelectionActive={isDownloadSelectionActive}
          isUsingHandTool={currentTool === 'pan'}
          isUsingSelectTool={currentTool === 'select'}
          canUndo={canUndo}
          canRedo={canRedo}
          inputIsFocused={inputIsFocused}
          setInputIsFocused={setInputIsFocused}
          showGuide={showGuide}
          onRedo={() => {
            if (history.canRedo()) {
              history.redo()
              updateUndoRedo()
            }
          }}
          onUndo={() => {
            if (history.canUndo()) {
              history.undo()
              updateUndoRedo()
            }
          }}
          onZoom={(increaseScale: boolean) =>
            zoom(
              { x: window.innerWidth / 2, y: window.innerHeight / 2 },
              increaseScale,
              1.25
            )
          }
          editSessionId={editSessionId}
          setImageQueue={setImageQueue}
        />
        <RoomContext.Consumer>
          {(value) => (
            <StyledStage
              ref={stageRef}
              width={window.innerWidth}
              height={window.innerHeight}
              onDragMove={onDragStage}
            >
              <RoomContext.Provider value={value}>
                <Layer ref={canvasRef}>
                  <ClientSideSuspense
                    fallback={
                      <Text
                        text="Loading editor..."
                        fontSize={22}
                        align="center"
                        width={window.innerWidth}
                        height={window.innerHeight}
                        verticalAlign="middle"
                        fill="white"
                      />
                    }
                  >
                    {() => <OutpaintShapesRenderer />}
                  </ClientSideSuspense>
                </Layer>
                {downloadSelectionCanvasComponent}
              </RoomContext.Provider>
            </StyledStage>
          )}
        </RoomContext.Consumer>
        {downloadSelectionOptionsComponent}
        <GenerationFrame
          isPlacing={currentTool === 'generationFrame'}
          finishPlacing={finishPlacing}
          isLoading={loading}
          isErasing={currentTool === 'erasor'}
          hasOutput={hasOutput}
          output={[...(data?.outpaint ?? [])]}
          onAccept={(outputIndex: number) => acceptOutput(outputIndex)}
          onCancel={() => {
            setHasOutput(false)
            updateMyPresence({
              generationFrameStatus: 'SEEKING',
            })
          }}
          scale={currentScale}
          canvasPosition={canvasPosition}
        />
        <GenerationFramesOthers stageRef={stageRef} scale={currentScale} />
        <AddImage
          imageQueue={imageQueue}
          scale={currentScale}
          canvasPosition={canvasPosition}
          addImageToCanvas={addImageToCanvas}
          cancel={cancelImage}
          editSessionId={editSessionId}
          disableDrag={currentTool === 'pan'}
        />
        <EraseCursor
          isErasing={currentTool === 'erasor'}
          eraserSize={eraserSize}
          scale={currentScale}
        />
        <PublicBanner handleSignupClick={() => setShowSignupModal(true)} />
        <SignupModal
          onSignup={() => {
            setShowGuide(true)
            userRefetch()
          }}
          isOpen={showSignupModal}
          close={() => setShowSignupModal(false)}
        />
      </div>
      <StyledThumbnailStage id="thumbnail-container" />
    </>
  )
}

const StyledThumbnailStage = styled.div`
  display: none;
  visibility: hidden;
`

const StyledStage = styled(Stage)`
  > div {
    z-index: 1;
  }
`
