import styled, { css } from 'styled-components'

export const ItemCard = styled.div<{ showBorder: boolean }>`
  ${({ theme, showBorder }) => css`
    background-color: ${theme.colors.black};
    opacity: 1;
    border-radius: ${theme.borderRadius.lg};
    background-size: cover;
    background-position: center;
    cursor: pointer;
    position: relative;
    background-origin: border-box;
    border: ${showBorder
      ? `${theme.border.medium} ${theme.colors.accent[2]}`
      : `${theme.border.medium} transparent`};
    transition: 0.15s border ease;
  `}
`
