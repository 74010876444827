import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'

import { RootState } from 'store'

const useCurrentUserListeners = () => {
  const userId = useSelector((state: RootState) => state.firebase.auth.uid)
  useFirestoreConnect(
    userId
      ? [
          {
            collection: 'users',
            doc: userId,
          },
          {
            collection: 'users',
            doc: userId,
            subcollections: [{ collection: 'private' }],
            storeAs: 'userPrivate',
          },
        ]
      : []
  )
}

export { useCurrentUserListeners }
