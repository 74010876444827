import { stringify } from 'query-string'
import React, { useCallback, useEffect, useRef } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import styled, { css } from 'styled-components'

import Loading from 'components/Loading'
import useQueryString from 'hooks/useQueryString'
import { useGrowthBookFeature } from 'hooks/useSyncGrowthbook'

import ImageSearchItems from './ImageSearchGrid'
import { ItemError } from './ItemError'
import PublicItem from './PublicItem'
import SimilarItems from './SimilarItems'
import { ItemData, useGetItemData } from './hooks/useGetItemData'
import { useIncrementItemViews } from './hooks/useIncrementItemViews'
import { useTrackItemViewed } from './hooks/useTrackItemViewed'
import useUpdateUserRecentTags from './hooks/useUpdateUserRecentTags'

interface PublicItemViewContentProps {
  item?: ItemData
  scrollNode: React.MutableRefObject<HTMLDivElement | null>
  isCloseable?: boolean
  closeItemView: () => void
  inDiscover?: boolean
  trackingSessionId?: string
}

const PublicItemViewContent: React.FC<PublicItemViewContentProps> = ({
  item,
  scrollNode,
  isCloseable,
  closeItemView,
  inDiscover,
  trackingSessionId,
}) => {
  const newImageSearchFeature = useGrowthBookFeature(
    'similar-items-image-search'
  )

  if (item) {
    return (
      <>
        <PublicItem
          item={item}
          isCloseable={isCloseable}
          onCloseItemView={closeItemView}
          inDiscover={inDiscover}
          trackingSessionId={trackingSessionId}
        />
        {newImageSearchFeature.on && inDiscover && item && (
          <ImageSearchItems
            itemId={'itemId' in item ? item.itemId : item.id}
            scrollNode={scrollNode}
            isDiscoveryItem
          />
        )}
        {newImageSearchFeature.off && inDiscover && item && (
          <SimilarItems
            itemId={'itemId' in item ? item.itemId : item.id}
            scrollNode={scrollNode}
            isDiscoveryItem
          />
        )}
      </>
    )
  }
  return <Loading />
}

interface PublicItemViewProps {
  isBoardItem?: boolean
  isCloseable?: boolean
  inDiscover?: boolean
  trackingSessionId?: string
}

const PublicItemView: React.FC<PublicItemViewProps> = ({
  isBoardItem,
  isCloseable,
  inDiscover,
  trackingSessionId,
}) => {
  const scrollNode = useRef(null)
  const params = useParams()
  const { item: queryItemId } = useQueryString<{ item: string }>()
  const navigate = useNavigate()
  const location = useLocation()

  const itemId = params.itemId || queryItemId

  const { data, error } = useGetItemData({
    itemId,
    isBoardItem: Boolean(isBoardItem),
  })

  const itemData: ItemData | undefined =
    data && ('workspaceItem' in data ? data.workspaceItem : data?.boardItem)

  useUpdateUserRecentTags({ itemData })
  useIncrementItemViews({ itemData })
  useTrackItemViewed({
    itemData,
    trackingSessionId,
    inDiscover,
    isPublicItem: true,
  })

  const query = useQueryString<{
    item: string
    itemSpace: string
    isDiscoverItem?: string
  }>()

  const { item: _item, itemSpace, isDiscoverItem, ...backQuery } = query
  const backQueryString = stringify(backQuery, { arrayFormat: 'comma' })
  const backPath = `${location.pathname}?${backQueryString}`

  const closeItemView = useCallback(() => {
    if (!isCloseable) {
      return
    }
    navigate(backPath)
    // Focus the grid so arrow navigation will work
    const newFocusElement = document.getElementById('grid')
    if (newFocusElement) newFocusElement.focus()
  }, [backPath, navigate, isCloseable])

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!isCloseable) return
      if (itemId && !e.key.startsWith('Arrow')) {
        e.stopPropagation()
      }
      if (e.key === ' ') {
        closeItemView()
        e.preventDefault()
      }
      if (e.key === 'Escape') {
        closeItemView()
        e.preventDefault()
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [backPath, closeItemView, itemId, isCloseable])

  if (error) {
    return (
      <ItemError
        error={error}
        onGoBack={isCloseable ? closeItemView : undefined}
      />
    )
  }

  return (
    <StyledFilledContainer
      ref={scrollNode}
      onClick={isCloseable ? closeItemView : undefined}
    >
      <PublicItemViewContent
        item={itemData}
        scrollNode={scrollNode}
        isCloseable={isCloseable}
        closeItemView={closeItemView}
        inDiscover={inDiscover}
        trackingSessionId={trackingSessionId}
      />
    </StyledFilledContainer>
  )
}

export default PublicItemView

const StyledFilledContainer = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.background[0]};
    position: fixed;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 52;
    width: 100%;
    min-height: 100vh;
    font-size: ${theme.fontSizes.base};
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    scrollbar-gutter: stable;
  `}
`
