import uniqBy from 'lodash/uniqBy'

import {
  useJoinableWorkspacesAllowedDomainQuery,
  useJoinableWorkspacesPendingInviteQuery,
} from 'generated/graphql'

export const useJoinableWorkspacesQueries = () => {
  const {
    data: allowedDomainData,
    loading: allowedDomainLoading,
    error: allowedDomainError,
  } = useJoinableWorkspacesAllowedDomainQuery({
    fetchPolicy: 'network-only',
  })
  const {
    data: pendingInviteData,
    loading: pendingInviteLoading,
    error: pendingInviteLoadingError,
  } = useJoinableWorkspacesPendingInviteQuery({
    fetchPolicy: 'network-only',
  })

  return {
    loading: allowedDomainLoading || pendingInviteLoading,
    error: allowedDomainError || pendingInviteLoadingError,
    // We filter uniques as a rare case could be that a user has an invitation and at the same time his domain is allowed
    data: uniqBy(
      [
        ...(pendingInviteData?.joinableWorkspacesPendingInvite ?? []),
        ...(allowedDomainData?.joinableWorkspacesAllowedDomain ?? []),
      ],
      'id'
    ),
  }
}
