import { useSelector } from 'react-redux'

import { RootState } from 'store'

export const useActiveWorkspace = () => {
  const activeWorkspace = useSelector(
    (state: RootState) => state.content.activeWorkspace
  )
  return activeWorkspace
}
