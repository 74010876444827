import React from 'react'
import styled, { css } from 'styled-components'

import StopPropagation from 'helpers/StopPropagation'
import { trackItemOriginalOpened } from 'helpers/tracking/tracking'
import useQueryString from 'hooks/useQueryString'

import ItemColors, { ColorsContainer } from './ItemColors'
import ItemSider from './ItemSider'
import ProgressiveImage from './ProgressiveImage'
import { getMediaVariant } from './getItemVersions'
import { ItemData } from './hooks/useGetItemData'

const ItemBox = styled.div`
  ${({ theme }) => css`
    width: 100%;
    border: 1px solid ${theme.colors.background[4]};
    border-radius: 16px;
    display: flex;
    overflow: hidden;
    cursor: default;
  `}
`

const ImageWrapper = styled.div`
  flex: 1 1 0;
  position: relative;
  cursor: pointer;
  :hover ${ColorsContainer} {
    opacity: 1;
  }
`

interface ItemPanelProps {
  item: ItemData
}

const ItemPanel: React.FC<ItemPanelProps> = ({ item }) => {
  const { itemSpace: spaceKey } = useQueryString<{ itemSpace: string }>()

  const handleImageClick = (url: string) => {
    trackItemOriginalOpened({
      originalUrl: url,
      itemId: item.id,
      itemSpacePath: spaceKey,
      boardId: 'boardId' in item ? item.boardId : undefined,
      projectId: 'projectId' in item ? item.projectId : undefined,
      workspaceId: item.workspaceId,
    })
    window.open(url, '_blank')
  }

  const largeMediaVariant = getMediaVariant({
    mediaVariants: item.mediaVariants,
    variant: 'large',
    mediaType: item.mediaType,
  })!
  const thumbMediaVariant = getMediaVariant({
    mediaVariants: item.mediaVariants,
    variant: 'thumb',
    mediaType: 'IMAGE',
  })!
  return (
    <ItemBox onClick={(e) => e.stopPropagation()}>
      <ImageWrapper onClick={() => handleImageClick(largeMediaVariant.url)}>
        <StopPropagation>
          <ItemColors colors={item.colors} />
        </StopPropagation>
        <ProgressiveImage
          imgUrl={largeMediaVariant.url}
          thumbUrl={thumbMediaVariant.url}
          withLoadingIndicator={item.mediaType === 'GIF'}
          style={{ maxHeight: '60vh' }}
        />
      </ImageWrapper>
      <ItemSider item={item} />
    </ItemBox>
  )
}

export default ItemPanel
