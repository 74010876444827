import React from 'react'
import { useSelector } from 'react-redux'
import { useFirestore } from 'react-redux-firebase'
import styled, { css } from 'styled-components'
import { BooleanParam, useQueryParam, withDefault } from 'use-query-params'

import Modal from 'components/common/Modal'
import useCurrentUser from 'hooks/useCurrentUser'

import { RootState } from '../../store'
import NominationPanel from '../nomination/NominationPanel'

export const useNominationPanel = () => {
  const firestore = useFirestore()
  const user = useCurrentUser()

  const [nominationPanelIsOpen, setNominationPanelIsOpen] = useQueryParam(
    'nominationPanel',
    withDefault(BooleanParam, false)
  )

  const openNominationPanel = () => {
    setNominationPanelIsOpen(true)
    firestore.collection('users').doc(user.uid).update({
      referralPanelTriggeredAt: firestore.FieldValue.serverTimestamp(),
    })
  }

  const closeNominationPanel = () => {
    setNominationPanelIsOpen(undefined)
  }

  return {
    isOpen: nominationPanelIsOpen,
    open: openNominationPanel,
    close: closeNominationPanel,
  }
}

const SIDER_MAX_SPIT = 160

export const NominateButton = () => {
  const siderSplitValue = useSelector(
    (state: RootState) => state.ui.siderSplitValue
  )

  const { isOpen, open, close } = useNominationPanel()

  return (
    <>
      <Modal height="auto" width="800px" isOpen={isOpen} close={close}>
        <NominationPanel />
      </Modal>
      <InviteButton
        onClick={open}
        data-intercom-target="Referral Button" // Used for Intercom product tours
      >
        {siderSplitValue > SIDER_MAX_SPIT ? (
          <>
            Invite to Kive <span css="font-size:14px">💰</span>
          </>
        ) : (
          '🎁'
        )}
      </InviteButton>
    </>
  )
}

const InviteButton = styled.button`
  ${({ theme }) => css`
    outline: none;
    font-size: ${theme.fontSizes.sm};
    color: white;
    background-color: ${theme.colors.background[2]};
    border: 0;
    padding: 16px 16px;
    cursor: pointer;
    white-space: nowrap;
    width: 100%;
    padding-left: 20px;
    text-align: left;
    :hover {
      background-color: ${theme.colors.accent[2]};
    }
  `}
`
