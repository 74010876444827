import React from 'react'
import { Link, useLocation } from 'react-router-dom'

type LinkWithParamsProps = {
  to: string
  className?: string
  children: React.ReactNode
}

const LinkWithParams = (props: LinkWithParamsProps) => {
  const { search } = useLocation()
  return (
    <Link className={props.className} to={props.to + search}>
      {props.children}
    </Link>
  )
}

export { LinkWithParams }
