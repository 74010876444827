import { QueuedImageProps } from './FileUploader'
import { QueueImage } from './QueueImage'

export const AddImage = ({
  imageQueue,
  scale = 1,
  canvasPosition = { x: 0, y: 0 },
  addImageToCanvas,
  cancel,
  editSessionId,
  disableDrag,
}: {
  imageQueue: QueuedImageProps[]
  scale: number
  canvasPosition?: {
    x: number
    y: number
  }
  addImageToCanvas: (
    id: string,
    imageUrl: string | null,
    x: number,
    y: number,
    w: number,
    h: number
  ) => void
  cancel: (id: string) => void
  editSessionId: string
  disableDrag: boolean
}) => {
  return (
    <>
      {imageQueue.map((image) => (
        <QueueImage
          key={image.id}
          scale={scale}
          canvasPosition={canvasPosition}
          addImageToCanvas={addImageToCanvas}
          cancel={cancel}
          image={image}
          editSessionId={editSessionId}
          disableDrag={disableDrag}
        />
      ))}
    </>
  )
}
