import { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { toggleCommandPalette } from '../../store/ui'
import CommandPalette from './CommandPalette'

class CommandPaletteContainer extends Component {
  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown = (e) => {
    // CMD key commands
    if (this.props.commandPaletteOpen) return

    if (e.metaKey) {
      if (e.keyCode === 75) {
        // 'k'
        this.props.toggleCommandPalette()
        e.stopPropagation()
      }
    }
  }

  render() {
    if (!this.props.commandPaletteOpen) return null
    return <CommandPalette />
  }
}

const mapStateToProps = (state) => ({
  commandPaletteOpen: state.ui.commandPaletteOpen,
})

const mapDispatchToProps = (dispatch) => ({
  toggleCommandPalette: () => {
    dispatch(toggleCommandPalette())
  },
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CommandPaletteContainer
)
