import { ArrowBack } from '@styled-icons/material/ArrowBack'
import { ArrowForward } from '@styled-icons/material/ArrowForward'
import React from 'react'
import styled, { css } from 'styled-components'

import IconButton from 'components/common/IconButton'
import StopPropagation from 'helpers/StopPropagation'
import { trackItemOriginalOpened } from 'helpers/tracking/tracking'

import ProgressiveImage from './ProgressiveImage'
import { getMediaVariant } from './getItemVersions'
import { ItemData } from './hooks/useGetItemData'

const ItemBox = styled.div`
  ${({ theme }) => css`
    width: 100%;
    border: 1px solid ${theme.colors.background[4]};
    display: flex;
    overflow: hidden;
    cursor: default;
  `}
`

const ImageWrapper = styled.div`
  flex: 1 1 0;
  position: relative;
  cursor: pointer;
`

interface ItemPanelMobileProps {
  item: ItemData
  isPublic?: boolean
  handleMoveItemFocus?: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    direction: 'right' | 'left'
  ) => void
}

const ItemPanelMobile: React.FC<ItemPanelMobileProps> = ({
  item,
  isPublic,
  handleMoveItemFocus,
}) => {
  const handleImageClick = (url: string) => {
    trackItemOriginalOpened({
      originalUrl: url,
      itemId: item.id,
      boardId: 'boardId' in item ? item.boardId : undefined,
      projectId: 'projectId' in item ? item.projectId : undefined,
      workspaceId: item.workspaceId,
    })
    window.open(url, '_blank')
  }

  const largeMediaVariant = getMediaVariant({
    mediaVariants: item.mediaVariants,
    variant: 'large',
    mediaType: item.mediaType,
  })!
  const thumbMediaVariant = getMediaVariant({
    mediaVariants: item.mediaVariants,
    variant: 'thumb',
    mediaType: 'IMAGE',
  })!
  return (
    <ItemBox onClick={(e) => e.stopPropagation()}>
      <ImageWrapper onClick={() => handleImageClick(largeMediaVariant.url)}>
        <ProgressiveImage
          imgUrl={largeMediaVariant.url}
          thumbUrl={thumbMediaVariant.url}
          withLoadingIndicator={item.mediaType === 'GIF'}
          style={{ maxHeight: '70vh' }}
        />
        {!isPublic && (
          <StopPropagation>
            <div css="position:absolute;left:8px;top:8px;">
              <IconButton
                Icon={ArrowBack}
                onClick={(e) => handleMoveItemFocus?.(e, 'left')}
              />
            </div>
            <div css="position:absolute;right:8px;top:8px;">
              <IconButton
                Icon={ArrowForward}
                onClick={(e) => handleMoveItemFocus?.(e, 'right')}
              />
            </div>
          </StopPropagation>
        )}
      </ImageWrapper>
    </ItemBox>
  )
}

export default ItemPanelMobile
