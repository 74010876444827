import { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { Link, useOutletContext, useParams } from 'react-router-dom'
import styled from 'styled-components'

import Loading from 'components/Loading'
import Button from 'components/common/Button'
import Flex from 'components/common/Flex'
import { Margin } from 'components/common/Margin'
import { Text } from 'components/common/Text'
import DiscoverGridItem from 'components/discover/DiscoverGridItem'
import Grid from 'components/grid/Grid'
import { GridColumnAdditioner } from 'components/grid/GridColumnAdditioner'
import { GridItemProps } from 'components/grid/griditem/GridItem'
import { useDiscoverIdeasForBoardQuery } from 'generated/graphql'
import { useActiveWorkspace } from 'hooks/useActiveWorkspace'
import useCurrentUser from 'hooks/useCurrentUser'

const StyledContainer = styled.div`
  padding: 0 16px 0;
`

export const DiscoverBoardIdeas = () => {
  const [, setRerender] = useState(false)
  const currentUser = useCurrentUser()
  const activeWorkspace = useActiveWorkspace()!
  const { boardId } = useParams()

  const { wrapperRef, trackingSessionId } = useOutletContext<{
    wrapperRef: HTMLDivElement | null
    trackingSessionId: string
    boardId: string
  }>()

  const { data, loading, fetchMore } = useDiscoverIdeasForBoardQuery({
    variables: { first: 30, boardId: boardId as string },
    fetchPolicy: 'cache-and-network',
  })

  const pageInfo = data?.discoverIdeasForBoard.pageInfo
  const nextCursor = pageInfo?.nextCursor
  const items = data?.discoverIdeasForBoard.items ?? []
  const board = data?.discoverIdeasForBoard
  const hasMore = Boolean(nextCursor) && items.length > 0 && !loading

  useEffect(() => {
    // There is a conclict with our Masonory grid and Inifnite scroll
    // which makes the inifinite scroll to NOT fetch more if the grid content is small enough to not trigger the "fetch more threshold"
    // forcing a rerender solves it. Don't ask me why
    setTimeout(() => {
      setRerender((prev) => !prev)
    }, 0)
  }, [items.length])

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        cursor: nextCursor,
      },
    })
  }

  if (!boardId) {
    return (
      <StyledContainer>
        <Text size="xl" as="h1" center color="neutral.0">
          This board doesn&apos;t seem to exist 😢
        </Text>
        <Margin y={26} />
        <Link to="/">
          <Button variant="primary">Back to Kive</Button>
        </Link>
      </StyledContainer>
    )
  }

  return (
    <StyledContainer>
      <Flex py={2}>
        <Link
          to={`/${activeWorkspace.url}/p/${board?.projectId}/${board?.boardId}`}
        >
          <Text size="base" color="neutral.0" bold>
            Ideas for your &quot;{data?.discoverIdeasForBoard.title}&quot; board
          </Text>
        </Link>
      </Flex>
      {wrapperRef && (
        <InfiniteScroll
          pageStart={0}
          threshold={1000}
          useWindow={false}
          getScrollParent={() => wrapperRef}
          loadMore={handleLoadMore}
          hasMore={!loading && hasMore}
          loader={
            <div className="loader" key={0}>
              <Loading fullscreen />
            </div>
          }
        >
          <GridColumnAdditioner
            parentWrapper={wrapperRef}
            isHidden={items.length === 0}
          >
            {(gridColumnAdditionOption) => (
              <Grid
                scrollElement={wrapperRef}
                isMultiSelectDisabled
                withFadeInAnimation
                gridColumnAdditionOption={gridColumnAdditionOption}
                gridItems={items}
                customGridItem={(gridItemProps: GridItemProps) => (
                  <DiscoverGridItem
                    {...gridItemProps}
                    currentUserId={currentUser.uid}
                    trackingSessionId={trackingSessionId}
                  />
                )}
              />
            )}
          </GridColumnAdditioner>
        </InfiniteScroll>
      )}
      {loading && !items.length && (
        <div className="loader" key={0}>
          <Loading fullscreen />
        </div>
      )}
    </StyledContainer>
  )
}
