import * as HoverCard from '@radix-ui/react-hover-card'
import React from 'react'
import styled, { keyframes } from 'styled-components'

const pulseAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(255,255,255,0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255,255,255,0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255,255,255,0);
  }
`

const StyledContent = styled(HoverCard.Content)`
  borderradius: 6;
  padding: 20;
  width: 300;
  padding: 12px;
  color: ${({ theme }) => theme.colors.gray.light[0]};
  border: 1px solid ${({ theme }) => theme.colors.gray.dark[6]};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  background: ${({ theme }) => theme.colors.background[4]};
  animation: ${pulseAnimation} 2s infinite;
`

const StyledArrow = styled(HoverCard.Arrow)`
  fill: ${({ theme }) => theme.colors.gray.dark[6]};
`

interface GuideCardProps {
  open: boolean
  content: string | React.ReactNode
  side?: 'top' | 'right' | 'bottom' | 'left'
}

export const GuideCard = ({
  open,
  content,
  side,
  children,
}: React.PropsWithChildren<GuideCardProps>) => {
  return (
    <HoverCard.Root open={open}>
      <HoverCard.Trigger>{children}</HoverCard.Trigger>
      <StyledContent side={side}>
        {content}
        <StyledArrow />
      </StyledContent>
    </HoverCard.Root>
  )
}
