import { KeyboardReturn } from '@styled-icons/material/KeyboardReturn'
import React from 'react'
import styled, { useTheme } from 'styled-components'

import { Spacer } from 'components/common/Spacer'
import { Text } from 'components/common/Text'

import { Navigatable, useFocus } from '../keyboardnav/useKeyboardNavigation'
import { SelectBoardAndProjectSubmitButton } from './SelectBoardAndProjectSubmitButton'

interface InnerProps {
  onAction: () => void
  children: React.ReactNode
}

const Inner: React.FC<InnerProps> = ({ children, onAction }) => {
  const hasFocus = useFocus()
  return (
    <Content hasFocus={hasFocus} onClick={onAction}>
      <TextContainer ellipsis size="sm">
        {children}
      </TextContainer>
      <Spacer factor={1} axis="x" />
      <SelectBoardAndProjectSubmitButton
        style={{
          visibility: hasFocus ? 'visible' : 'hidden',
        }}
      >
        <KeyboardReturn />
      </SelectBoardAndProjectSubmitButton>
    </Content>
  )
}

interface SelectBoardAndProjectFooterProps {
  onAction: () => void
  children: React.ReactNode
}

const SelectBoardAndProjectFooter: React.FC<
  SelectBoardAndProjectFooterProps
> = ({ children, onAction }) => {
  const theme = useTheme()
  const wrapperStyle = {
    display: 'block',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    borderTop: `${theme.border.thin} ${theme.colors.discreet}`,
    borderBottomRightRadius: theme.borderRadius.lg,
    overflow: 'hidden',
  }
  const commands = [
    {
      conditions: {
        key: 'Enter',
      },
      action: onAction,
    },
  ]
  return (
    <Navigatable style={wrapperStyle} commands={commands}>
      <Inner onAction={onAction}>{children}</Inner>
    </Navigatable>
  )
}

const Content = styled.div<{ hasFocus: boolean }>(({ theme, hasFocus }) => ({
  height: '50px',
  width: '100%',
  background: hasFocus
    ? theme.colors.background[1]
    : theme.colors.background[4],
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: hasFocus ? theme.colors.text.neutral[1] : theme.colors.text.neutral[2],
  fontSize: theme.fontSizes.sm,
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  padding: '0px 12px',
}))

const TextContainer = styled(Text)`
  text-align: left;
`

export default SelectBoardAndProjectFooter
