import React, { useEffect, useState } from 'react'
import AutosizeInput from 'react-input-autosize'
import { useFirestore } from 'react-redux-firebase'
import 'react-tippy/dist/tippy.css'
import { useTheme } from 'styled-components'

import { Tooltip } from 'components/common/Tooltip/Tooltip'
import { getRandomEmoji } from 'components/common/emoji/EmojiPicker'
import useToastMessages from 'components/toast/useToastMessages'
import useIsMobile from 'hooks/useIsMobile'

import EmojiEditable from '../common/emoji/EmojiEditable'
import { HoverButton, TitleContainer } from './BoardStyles'

interface BoardTitleProps {
  boardId: string
  editable: boolean
  title: string
  iconCode: string | null
  projectId: string
  hasBoardCover: boolean
  shouldShowAddIconButton: boolean
}

const BoardTitle = (props: BoardTitleProps) => {
  const { reportError } = useToastMessages()
  const isMobile = useIsMobile()
  const theme = useTheme()
  const firestore = useFirestore()

  const [editedText, setEditedText] = useState(props.title)

  useEffect(() => {
    // we re-init edit-text if we switch boards
    setEditedText(props.title)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.boardId])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.currentTarget.querySelector('input')?.blur()
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedText(e.target.value)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // to not trigger other active key-commands
    event.stopPropagation()
  }

  const handleBlur = async () => {
    await handleUpdateBoardTitle()
  }

  const handleUpdateBoardTitle = async () => {
    const newTitle = editedText
    const previousTitle = props.title

    if (newTitle === previousTitle) return null // if there were no changes, don't proceed.

    if (!newTitle) {
      setEditedText(previousTitle)
      return reportError('Your board name must have at least one character')
    }

    const itemUpdates = {
      title: newTitle,
      updatedAt: firestore.FieldValue.serverTimestamp(),
    }

    const {
      docs: [doc],
    } = await firestore
      .collection('boards')
      .where('projectId', '==', props.projectId)
      .where('title', '==', itemUpdates.title)
      .limit(1)
      .get()

    if (doc?.exists) {
      setEditedText(previousTitle)
      return reportError('You already have a board with that title')
    }
    return firestore.update(
      { collection: 'boards', doc: props.boardId },
      itemUpdates
    )
  }

  const handleUpdateBoardEmoji = (code: string | null) => {
    const itemUpdates = {
      iconCode: code,
      updatedAt: firestore.FieldValue.serverTimestamp(),
    }

    firestore.update({ collection: 'boards', doc: props.boardId }, itemUpdates)
  }

  const addIcon = () => {
    firestore.update(
      { collection: 'boards', doc: props.boardId },
      {
        iconCode: getRandomEmoji(),
      }
    )
  }

  return (
    <TitleContainer>
      {props.iconCode && (
        <EmojiEditable
          disabled={!props.editable}
          code={props.iconCode}
          shouldShowActions
          handleUpdateEmoji={handleUpdateBoardEmoji}
          large
          inputStyle={{
            fontSize: theme.fontSizes.xl,
            borderRadius: theme.borderRadius.lg,
            border: 'none',
            boxShadow: 'none',
            padding: '4px 16px',
            textAlign: 'left',
            outline: 'none',
            color: `${
              props.hasBoardCover
                ? theme.colors.white
                : theme.colors.text.neutral[0]
            }`,
            background: 'none',
          }}
        />
      )}
      <form
        css="display:flex;align-items:center"
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        {props.editable &&
          props.iconCode === null &&
          (props.shouldShowAddIconButton || isMobile) && (
            <HoverButton
              style={{
                top: '18px',
                position: 'absolute',
                marginLeft: '12px',
              }}
              onClick={addIcon}
            >
              Add icon
            </HoverButton>
          )}
        <Tooltip
          isDisabled={!props.editable}
          delay={[1500, 0]}
          title="Click to edit title"
          position="top"
        >
          <AutosizeInput
            disabled={!props.editable}
            value={editedText}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            inputStyle={{
              fontSize: theme.fontSizes.xl,
              borderRadius: theme.borderRadius.lg,
              display: 'block',
              border: 'none',
              boxShadow: 'none',
              padding: '0px 16px',
              textAlign: 'center',
              outline: 'none',
              color: props.hasBoardCover
                ? theme.colors.white
                : theme.colors.text.neutral[0],
              background: 'none',
              maxWidth: '70vw',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              opacity: 1,
            }}
          />
        </Tooltip>
      </form>
    </TitleContainer>
  )
}

export default BoardTitle
