import { StyledIcon } from '@styled-icons/styled-icon'
import React from 'react'
import styled, { DefaultTheme, css, useTheme } from 'styled-components'

import Spinner from './Spinner'

export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'darkSecondary'
  | 'danger'
  | 'slight-danger'
  | 'success'

const getVariantColors = (
  theme: DefaultTheme,
  variant?: ButtonVariant,
  disabled?: boolean
) => {
  if (disabled) {
    return {
      base: {
        background: theme.colors.background[5],
        color: theme.colors.text.neutral[3],
      },
      hover: {
        background: theme.colors.background[5],
      },
    }
  }
  if (variant === 'primary') {
    return {
      base: {
        background: theme.colors.accent[2],
        color: theme.colors.white,
      },
      hover: {
        background: theme.colors.accent[1],
        color: theme.colors.white,
      },
    }
  }
  if (variant === 'success') {
    return {
      base: {
        background: theme.colors.accent[3],
        color: theme.colors.black,
      },
      hover: {
        background: theme.colors.accent[1],
      },
    }
  }
  if (variant === 'secondary') {
    return {
      base: {
        background: theme.colors.background[5],
        color: theme.colors.text.neutral[0],
      },
      hover: {
        background: theme.colors.accent[2],
      },
    }
  }
  if (variant === 'darkSecondary') {
    return {
      base: {
        background: theme.colors.background[5],
        color: theme.colors.text.neutral[0],
      },
      hover: {
        background: theme.colors.accent[2],
        color: theme.colors.white,
      },
    }
  }
  if (variant === 'slight-danger') {
    return {
      base: {
        background: theme.colors.background[5],
        color: theme.colors.danger,
      },
      hover: {
        background: theme.colors.danger,
        color: theme.colors.text.neutral[0],
      },
    }
  }
  if (variant === 'danger') {
    return {
      base: {
        background: theme.colors.danger,
        color: theme.colors.text.neutral[0],
      },
      hover: {
        background: theme.colors.dangerHighlight,
        color: theme.colors.text.neutral[0],
      },
    }
  }
  return {
    base: {
      background: theme.colors.background[4],
      color: theme.colors.text.neutral[0],
    },
    hover: {
      background: theme.colors.background[5],
    },
  }
}

interface StyledButtonBaseProps {
  variant?: ButtonVariant
  bold?: boolean
  fullWidth?: boolean
  disabled?: boolean
  isCompact?: boolean

  // if we wanna convert the button to an a-tag
  as?: React.ElementType
  href?: string
  target?: string
}

export const StyledButtonBase = styled.button<StyledButtonBaseProps>`
  ${({ variant, bold, fullWidth, isCompact, disabled, theme }) => {
    const { base, hover } = getVariantColors(theme, variant, disabled)
    return css`
      height: ${isCompact ? 36 : 44}px;
      font-size: ${theme.fontSizes.sm};
      ${bold ? `font-weight: ${theme.fontWeight.bold};` : ''}
      ${fullWidth ? `width: 100%;` : ''}
      background: ${base.background};
      color: ${base.color};
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-radius: ${theme.borderRadius.default};
      border: none;
      padding: 14px ${isCompact ? 22 : 20}px;
      white-space: nowrap;
      max-width: 100%;
      &:hover {
        ${hover?.background ? `background: ${hover.background}` : ''};
        ${hover?.color ? `color: ${hover.color}` : ''};
        cursor: ${disabled ? 'not-allowed' : 'pointer'};
      }
      &:focus {
        outline: none;
      }
      transition: background 0.2s ease, color 0.2s ease;
    `
  }}
`

interface ButtonProps extends StyledButtonBaseProps {
  Icon?: StyledIcon
  size?: number
  isLoading?: boolean
}

const Button: React.FC<
  ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ Icon, size, isLoading, children, disabled, ...rest }) => {
  const theme = useTheme()
  const disabledOrLoading = isLoading || disabled
  return (
    <StyledButtonBase disabled={disabledOrLoading} {...rest}>
      {isLoading ? (
        <>
          <div css={children ? 'margin-right: 8px;' : undefined}>
            <Spinner size={theme.fontSizes.sm} />
          </div>
          {children}
        </>
      ) : (
        <>
          {Icon && (
            <div css={children ? 'margin-right: 8px;' : undefined}>
              <Icon style={{ height: size || 16 }} />
            </div>
          )}
          {children}
        </>
      )}
    </StyledButtonBase>
  )
}

export default Button
