import React from 'react'
import { Link, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { Margin } from 'components/common/Margin'
import { Text } from 'components/common/Text'
import mapToPalette from 'helpers/mapToPalette'

import { useSearchContext } from './SearchContext'
import { SearchGrid } from './SearchGrid'
import { getParsedFreeText } from './helpers/getParsedFreeText'

interface SearchResultsPublicItemsProps {
  wrapperElement: HTMLDivElement
  displayQuery: string
}

const SearchResultsPublicItems: React.FC<SearchResultsPublicItemsProps> = ({
  wrapperElement,
  displayQuery,
}) => {
  const { workspaceUrl } = useParams()

  const { freeText, tags, color, face, filename, fileFormat } =
    useSearchContext()

  const tagIds = tags.map((tag) => tag.id)
  const colors = color ? [color] : []
  const mappedColors = colors.map((c) => mapToPalette(c))

  const isSearching = Boolean(
    freeText ||
      color ||
      tagIds.length > 0 ||
      face.length > 0 ||
      filename ||
      fileFormat
  )

  const [parsedFreeText] = getParsedFreeText(freeText)

  return (
    <StyledSearchResultsContainer>
      {isSearching && (
        <SearchGrid
          scrollNode={wrapperElement}
          searchQuery={parsedFreeText}
          filterTags={tagIds}
          filterColors={mappedColors}
          filterFaceTags={face}
          filterFilename={filename}
          filterFileFormat={fileFormat}
          searchPublic
          emptyContent={
            <>
              <Text size="base">
                No search results for &quot;{displayQuery}&quot;, try searching
                for something else.
              </Text>
              <Margin y={12} />
              <Text size="sm">
                Do you want to contribute? Try making your items public. <br />
                View{' '}
                <Link
                  css="text-decoration:underline;color:currentColor"
                  to={`/${workspaceUrl}/profile`}
                >
                  your public items
                </Link>{' '}
                or{' '}
                <a
                  css="text-decoration:underline;color:currentColor"
                  href="http://help.kive.ai/en/articles/5681828-making-items-public"
                  target="_blank"
                  rel="noreferrer"
                >
                  read documentation
                </a>
                .
              </Text>
            </>
          }
        />
      )}
    </StyledSearchResultsContainer>
  )
}

const StyledSearchResultsContainer = styled.div`
  padding: 0 16px;
`

export default SearchResultsPublicItems
