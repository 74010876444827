import { ThreeDotsVertical as Dots } from '@styled-icons/bootstrap/ThreeDotsVertical'
import { Notifications } from '@styled-icons/ionicons-outline/Notifications'
import { motion } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useFirestore } from 'react-redux-firebase'
import styled, { DefaultTheme, css } from 'styled-components'

import Loading from 'components/Loading'
import ClickOutside from 'components/common/ClickOutside'
import Dropdown from 'components/common/Dropdown'
import IconButton from 'components/common/IconButton'
import { Text } from 'components/common/Text'
import {
  useMarkAllNotificationsAsReadMutation,
  useWorkspaceNotificationsSubscription,
} from 'generated/graphql'
import { reportError } from 'helpers/logging'
import useCurrentUser from 'hooks/useCurrentUser'
import useIsMobile from 'hooks/useIsMobile'
import { RootState } from 'store'

import WorkspaceNotification from './WorkspaceNotification'

const WorkspaceNotifications = () => {
  const isMobile = useIsMobile()
  const wrapperRef = useRef(null)
  const activeWorkspaceId = useSelector(
    (state: RootState) => state.content.activeWorkspace!.id
  )

  const firestore = useFirestore()

  const [markAllNotificationsAsReadMutation] =
    useMarkAllNotificationsAsReadMutation()
  const { unseenNotifications, uid } = useCurrentUser()

  const [isNotificationsVisible, setIsNotificationsVisible] = useState(false)

  const { data, loading, error } = useWorkspaceNotificationsSubscription({
    variables: { workspaceId: activeWorkspaceId },
    skip: !isNotificationsVisible,
  })

  useEffect(() => {
    // we report error to sentry from client until we support subscription error logs in backend
    if (error?.graphQLErrors?.[0]) {
      reportError(error?.graphQLErrors?.[0])
    }
  }, [error])

  const unseenNotificationsCount =
    (unseenNotifications?.GLOBAL ?? 0) +
    (unseenNotifications?.[activeWorkspaceId] ?? 0)

  const markNotificationsAsSeen = () => {
    firestore
      .collection('users')
      .doc(uid)
      .update({
        [`unseenNotifications.${activeWorkspaceId}`]: 0,
        'unseenNotifications.GLOBAL': 0,
        notificationsLastSeenAt: firestore.FieldValue.serverTimestamp(),
      })
  }

  useEffect(() => {
    // if notification panel is open and we have unseen notifications we mark them as seen
    if (isNotificationsVisible && unseenNotificationsCount > 0) {
      markNotificationsAsSeen()
    }
  }, [isNotificationsVisible, unseenNotificationsCount])

  const openNotifications = () => {
    setIsNotificationsVisible(true)
  }

  const closeNotifications = () => {
    setIsNotificationsVisible(false)
  }

  const renderContent = () => {
    if (loading) {
      return <Loading />
    }
    if (error) {
      return (
        <Text color="neutral.1" css="padding: 36px 0px" center size="sm">
          🧐 Something strange happend, try again!
        </Text>
      )
    }
    if (!data) return null

    if (data.workspaceNotifications.length === 0) {
      return (
        <Text css="padding: 36px 0px" center size="base">
          You have no notifications just yet. <br />
          Come back later 👾
        </Text>
      )
    }
    return (
      <div>
        {data.workspaceNotifications.map((notification) => (
          <WorkspaceNotification
            key={notification.id}
            notification={notification}
            onNotificationClick={closeNotifications}
          />
        ))}
      </div>
    )
  }

  const unseenNotificationsTitle =
    unseenNotificationsCount > 9 ? '9+' : unseenNotificationsCount

  return (
    <div css="position:relative;user-select: none;" ref={wrapperRef}>
      {unseenNotificationsCount > 0 && !isNotificationsVisible && (
        <UnseenBadge>{unseenNotificationsTitle}</UnseenBadge>
      )}
      <IconButton
        Icon={Notifications}
        iconSize={isMobile ? 12 : 18}
        onClick={
          isNotificationsVisible ? closeNotifications : openNotifications
        }
        css={`
          ${({ theme }: { theme: DefaultTheme }) =>
            css`
              color: ${theme.colors.text.neutral[0]};
            `}
        `}
      />
      {isNotificationsVisible && (
        <ClickOutside
          ignoreRef={wrapperRef}
          handleClickOutside={closeNotifications}
        >
          <NotificationsWrapper
            transition={{ type: 'tween', duration: 0.15 }}
            initial={{ opacity: 0, y: -10, height: 0 }}
            animate={{ opacity: 1, y: 0, height: 'auto' }}
          >
            <div css="padding: 8px 18px;display:flex; align-items: center">
              <Text
                size="sm"
                bold
                color="neutral.0"
                css="width:100%;display:flex"
              >
                Notifications
              </Text>
              <IconButton Icon={Dots} noBackground menuWidth={160} menuNoSpace>
                <Dropdown.Item
                  onClick={() =>
                    markAllNotificationsAsReadMutation({
                      variables: { workspaceId: activeWorkspaceId },
                    })
                  }
                >
                  Mark all as read
                </Dropdown.Item>
              </IconButton>
            </div>
            <NotificationsList>{renderContent()}</NotificationsList>
          </NotificationsWrapper>
        </ClickOutside>
      )}
    </div>
  )
}

export default WorkspaceNotifications

const NotificationsWrapper = styled(motion.div)`
  ${({ theme }) => css`
    background: ${theme.colors.background[4]};
    border-radius: ${theme.borderRadius.default};
    box-shadow: ${theme.shadow.minimal};
    border: ${theme.border.thin} ${theme.colors.discreet};
    position: absolute;
    width: 350px;
    z-index: 10;
    right: 0;
    top: 60px;
    overflow: hidden;
    @media screen and (max-width: ${theme.breakpoints.md}px) {
      width: 278px;
    }
  `}
`

const NotificationsList = styled.div`
  min-height: 220px;
  max-height: 450px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 0px 8px 8px 8px;
`

const UnseenBadge = styled.div`
  ${({ theme }) => css`
    background: #ff4833;
    color: ${theme.colors.text.neutral[0]};
    position: absolute;
    z-index: 10;
    font-size: ${theme.fontSizes.xs};
    font-weight: bold;
    border-radius: ${theme.borderRadius.full};
    padding: 0 5px;
    height: 17px;
    min-width: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    right: -5px;
    top: -4px;
  `}
`
