import { storage } from 'config/firebase'

export const uploadToStorage = async ({
  dataUrl,
  editSessionId,
}: {
  dataUrl: string
  editSessionId: string
}) => {
  const storageRef = storage.ref()

  const contentType = dataUrl
    .slice(0, dataUrl.indexOf(';'))
    .replace('data:', '')

  const extension = dataUrl.substring(
    'data:image/'.length,
    dataUrl.indexOf(';base64')
  )

  const id = Math.floor(Math.random() * 100000)

  const uploadRef = storageRef.child(
    `canvas-uploads/${editSessionId}/${id}.${extension}`
  )

  const metadata = {
    contentType,
  }

  const uploadTask = await uploadRef.putString(dataUrl, 'data_url', metadata)
  return uploadTask.ref.getDownloadURL()
}
