const notAvailable = 'Workspace URL is not available'
const FORBIDDEN_WORKSPACE_URLS = [
  'setup',
  'privacy',
  'jobs',
  'demo',
  'logout',
  'waitinglist',
  'item',
  'board',
  'project',
  'share',
  'open',
  'view',
  'extension',
  'help',
  'info',
  '404',
  'pricing',
  'billing',
  'product',
  'login',
  'r',
]

export const createWorkspaceUrlValidator =
  (firestore, currentWorkspace) => async (workspaceUrl) => {
    if (workspaceUrl === currentWorkspace?.url) return true
    if (workspaceUrl.length <= 4) {
      return 'Use 5 characters or more'
    }
    if (!workspaceUrl.match(/^([a-z0-9-]+)*$/)) {
      return 'You can use a-z, 0-9 and -'
    }

    if (FORBIDDEN_WORKSPACE_URLS.includes(workspaceUrl)) {
      return notAvailable
    }

    // Check it's not already taken
    const snapshot = await firestore.get({
      collection: 'workspacesPublic',
      where: ['url', '==', workspaceUrl],
    })

    if (snapshot.size > 0) {
      return notAvailable
    }
  }
