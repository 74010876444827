import { Instagram } from '@styled-icons/bootstrap/Instagram'
import { Tags } from '@styled-icons/bootstrap/Tags'
import { Check } from '@styled-icons/boxicons-regular/Check'
import { Download } from '@styled-icons/boxicons-regular/Download'
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline'
import { Plus } from '@styled-icons/foundation/Plus'
import { Trash } from '@styled-icons/ionicons-outline/Trash'
import { client } from 'apollo'
import { AnimatePresence, motion } from 'framer-motion'
import * as queryString from 'query-string'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useMatch } from 'react-router-dom'
import { Tooltip } from 'react-tippy'
import 'react-tippy/dist/tippy.css'
import styled, { css } from 'styled-components'

import Button from 'components/common/Button'
import ClickOutside from 'components/common/ClickOutside'
import { Margin } from 'components/common/Margin'
import Modal from 'components/common/Modal'
import PermissionWrapper from 'components/common/PermissionWrapper'
import Spinner from 'components/common/Spinner'
import { Text } from 'components/common/Text'
import SaveToBoard from 'components/itemsavetoboard/SaveToBoard'
import ItemTagEditable from 'components/itemtags/ItemTagEditable'
import { TagProps, getTagIdForNewTag } from 'components/itemtags/ItemTags'
import useToastMessages from 'components/toast/useToastMessages'
import { db } from 'config/firebase'
import { useAddWorkspaceItemTagMutation } from 'generated/graphql'
import BlockKeyDownPropagation from 'helpers/BlockKeyDownPropagation'
import { deleteBoardItem } from 'helpers/boardItemOperations'
import { downloadItems } from 'helpers/downloadItem'
import {
  trackMultiSelectItemsAddedTag,
  trackMultiSelectItemsDeleted,
  trackMultiSelectItemsDownloaded,
  trackMultiSelectItemsSavedToBoard,
} from 'helpers/tracking'
import useBoardPermissionsById from 'hooks/useBoardPermissionById'
import { useIsInProjectView } from 'hooks/useIsInProjectView'
import useIsMobile from 'hooks/useIsMobile'
import useWorkspacePermissions from 'hooks/useWorkspacePermissions'
import { RootState } from 'store'
import { setActiveExport, setSocialAssetModalOpen } from 'store/content/actions'
import { removeItemEverywhere } from 'store/itemSearch'
import {
  FetchingState,
  reset,
  setAddTagStatus,
  setDeleteStatus,
  setDownloadStatus,
  setIsAddTagMenuVisible,
  setIsDeleteConfirmModalVisible,
  setIsSaveToBoardMenuVisible,
} from 'store/multiSelect'
import { isInMultiSelectModeSelector } from 'store/selectors'

import { useSelectAllCurrentBoardItems } from './useSelectAllCurrentBoardItems'

const SaveToBoardIconsMap = {
  IDLE: Plus,
  START: Plus,
  LOADING: Spinner,
  DONE: Check,
}

const AddTagsIconsMap = {
  IDLE: Tags,
  START: Tags,
  LOADING: Spinner,
  DONE: Check,
}

const DownloadIconsMap = {
  IDLE: Download,
  START: Download,
  LOADING: Spinner,
  DONE: Check,
}

const DeleteIconsMap = {
  IDLE: Trash,
  START: Trash,
  LOADING: Spinner,
  DONE: Check,
}

const shouldDisableButton = (status: FetchingState) => {
  return ['START', 'LOADING', 'DONE'].includes(status)
}

const MultiSelectActionbar: React.FC = () => {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()
  const { reportError, reportSuccess } = useToastMessages()
  const location = useLocation()
  // if user navigate to or from a project we reset the multi-select items
  const isInProjectView = useIsInProjectView(() => {
    dispatch(reset())
  })

  const boardId = useMatch('/:workspaceSlug/p/:projectId/:boardId')?.params
    .boardId

  const handleSelectAllCurrentItemsInBoard =
    useSelectAllCurrentBoardItems(boardId)

  const { REMOVE_BOARD_ITEM: allowedToRemoveBoardItem } =
    useBoardPermissionsById(boardId || '', ['REMOVE_BOARD_ITEM'])

  const userAllowedTo = useWorkspacePermissions([
    'SAVE_TO_BOARD',
    'DELETE_ITEM',
    'ADD_ITEM_TAG',
    'CREATE_SOCIAL_ASSETS',
  ])

  const [addWorkspaceItemTagMutation] = useAddWorkspaceItemTagMutation()

  const canDeleteOrRemoveItem = boardId
    ? allowedToRemoveBoardItem
    : userAllowedTo.DELETE_ITEM

  const parsedUrlSearchQuery = queryString.parse(location.search, {
    arrayFormat: 'comma',
  })
  const inItemView = !!parsedUrlSearchQuery.item

  const inMultiSelectMode = useSelector(isInMultiSelectModeSelector)

  const selectedItems = useSelector(
    (state: RootState) => state.multiSelect.selectedItems
  )

  const downloadStatus = useSelector(
    (state: RootState) => state.multiSelect.downloadStatus
  )

  const addTagStatus = useSelector(
    (state: RootState) => state.multiSelect.addTagStatus
  )
  const isAddTagMenuVisible = useSelector(
    (state: RootState) => state.multiSelect.isAddTagMenuVisible
  )

  const deleteStatus = useSelector(
    (state: RootState) => state.multiSelect.deleteStatus
  )
  const isDeleteConfirmModalVisible = useSelector(
    (state: RootState) => state.multiSelect.isDeleteConfirmModalVisible
  )

  const saveToBoardStatus = useSelector(
    (state: RootState) => state.multiSelect.saveToBoardStatus
  )
  const isSaveToBoardMenuVisible = useSelector(
    (state: RootState) => state.multiSelect.isSaveToBoardMenuVisible
  )

  const siderSplitValue = useSelector(
    (state: RootState) => state.ui.siderSplitValue
  )

  const workspaceId = useSelector(
    (state: RootState) => state.content.activeWorkspace!.id
  )

  const handleDownloadItems = useCallback(async () => {
    dispatch(setDownloadStatus('LOADING'))
    const items = Object.values(selectedItems)
    await downloadItems({ items, trackingContext: { workspaceId, boardId } })
    dispatch(setDownloadStatus('DONE'))
    trackMultiSelectItemsDownloaded({
      nbrOfItems: items.length,
      workspaceId,
    })
  }, [dispatch, selectedItems, workspaceId])

  const handleDeleteItems = useCallback(async () => {
    dispatch(setDeleteStatus('LOADING'))
    dispatch(setIsDeleteConfirmModalVisible(false))
    const batch = db.batch()
    const items = Object.values(selectedItems)
    items.forEach((item) => {
      if ('boardId' in item) {
        deleteBoardItem(item.id, item.boardId) // TODO: Add support for delete multiple board items with different board ids
      } else {
        const docRef = db
          .collection(`workspaces/${item.workspaceId}/items`)
          .doc(item.id)
        batch.update(docRef, { shouldDelete: true, shouldDeleteTags: true })
      }
    })
    try {
      await batch.commit()
      // on success we remove the item from all search results in redux
      items.forEach((item) => {
        dispatch(removeItemEverywhere({ id: item.id })) // we remove the item from all search results in redux
        client.cache.evict({ id: `WorkspaceItem:${item.id}` }) // we also try to remove it from graphql cache
      })
      client.cache.gc()
      trackMultiSelectItemsDeleted({
        workspaceId,
        nbrOfItems: items.length,
      })
    } catch (error) {
      reportError("We couldn't delete the selected items", error)
    }
    dispatch(setDeleteStatus('DONE'))
  }, [dispatch, reportError, selectedItems, workspaceId])

  const handleAddTag = async (tag: TagProps) => {
    dispatch(setAddTagStatus('LOADING'))
    dispatch(setIsAddTagMenuVisible(false))
    const items = Object.values(selectedItems)
    const res = await Promise.allSettled(
      items.map(async (item) => {
        await addWorkspaceItemTagMutation({
          variables: {
            workspaceItemId: 'boardId' in item ? item.itemId : item.id,
            tagDescription: tag.description,
            tagId: getTagIdForNewTag(tag),
          },
          context: {
            batch: true, // we do not have a bulk mutation so we batch these togather
          },
        })
      })
    )
    const rejectedItemsCount = res.filter(
      ({ status }) => status === 'rejected'
    ).length

    if (res.length !== rejectedItemsCount) {
      reportSuccess(`Added tag "${tag.description}" to items`)
    }
    if (rejectedItemsCount > 0) {
      reportError(`${rejectedItemsCount} items wasn't updated`)
    }
    trackMultiSelectItemsAddedTag({
      workspaceId,
      nbrOfItems: items.length,
    })

    dispatch(setAddTagStatus('DONE'))
  }

  const handleGenerateAssets = useCallback(async () => {
    const itemIds = Object.values(selectedItems).map((item) =>
      'boardId' in item ? item.itemId : item.id
    )
    dispatch(setActiveExport({ itemIds }))
    dispatch(setSocialAssetModalOpen(true))
  }, [dispatch, selectedItems])

  // download selected items can be started by setting download status to "START"
  useEffect(() => {
    if (downloadStatus === 'START') {
      handleDownloadItems()
    }
  }, [downloadStatus, handleDownloadItems])

  // delete selected items can be started by setting delete status to "START"
  useEffect(() => {
    if (deleteStatus === 'START') {
      // Only supported from project/boards
      if (isInProjectView) {
        handleDeleteItems()
      } else {
        dispatch(setIsDeleteConfirmModalVisible(true))
      }
    }
  }, [dispatch, deleteStatus, handleDeleteItems, isInProjectView])

  // on DONE actions we reset the multiple items after 1 second
  useEffect(() => {
    if ([saveToBoardStatus, downloadStatus, deleteStatus].includes('DONE')) {
      setTimeout(() => {
        dispatch(reset())
      }, 1000)
    }
  }, [dispatch, saveToBoardStatus, downloadStatus, deleteStatus])

  // we treat addTagStatus differently on "Done" status, as the user might wanna add more tags to the selected items
  useEffect(() => {
    if ([addTagStatus].includes('DONE')) {
      setTimeout(() => {
        dispatch(setAddTagStatus('IDLE'))
      }, 1000)
    }
  }, [dispatch, addTagStatus])

  const nbrOfItemsSelected = Object.keys(selectedItems).length

  const SaveToBoardButton = SaveToBoardIconsMap[saveToBoardStatus]
  const DownloadButton = DownloadIconsMap[downloadStatus]
  const DeleteButton = DeleteIconsMap[deleteStatus]
  const AddTagButton = AddTagsIconsMap[addTagStatus]

  return (
    <>
      <AnimatePresence initial={false}>
        {inMultiSelectMode && (
          <Wrapper
            siderSplitValue={siderSplitValue}
            stretch={inItemView}
            initial={{ y: -60 }}
            animate={{ y: 0 }}
            exit={{ y: -60 }}
            transition={{ type: 'tween', ease: 'easeOut', duration: 0.2 }}
          >
            <div css="display:flex; align-items:center;margin-right: auto">
              <ItemsGroup>
                <Text color="neutral.0" size="sm">
                  {nbrOfItemsSelected} item{nbrOfItemsSelected !== 1 ? 's' : ''}{' '}
                  {isMobile ? '' : 'selected'}
                </Text>
                <ResetButton onClick={() => dispatch(reset())} />
              </ItemsGroup>
              {boardId && (
                <>
                  <Margin x={12} />
                  <Text
                    onClick={handleSelectAllCurrentItemsInBoard}
                    color="neutral.0"
                    link
                    size="sm"
                  >
                    Select all
                  </Text>
                </>
              )}
            </div>

            <ActionGroup>
              <ButtonTooltip
                css="margin-left:8px"
                title="Download all"
                disabled={shouldDisableButton(downloadStatus)}
              >
                <StyledIconButton
                  disabled={shouldDisableButton(downloadStatus)}
                  onClick={() => dispatch(setDownloadStatus('START'))}
                >
                  <motion.div
                    key={downloadStatus}
                    initial={{ y: -15, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: 15, opacity: 0 }}
                  >
                    <DownloadButton style={{ height: '20px' }} />
                  </motion.div>
                </StyledIconButton>
              </ButtonTooltip>
              <PermissionWrapper
                feature="Save items to board"
                isAllowed={Boolean(userAllowedTo.SAVE_TO_BOARD)}
              >
                {(isAllowed) => (
                  <ButtonTooltip
                    css="margin-left:8px"
                    title="Save all to board"
                    disabled={
                      shouldDisableButton(saveToBoardStatus) || !isAllowed
                    }
                  >
                    <StyledIconButton
                      disabled={
                        shouldDisableButton(saveToBoardStatus) || !isAllowed
                      }
                      onClick={() => {
                        dispatch(setIsSaveToBoardMenuVisible(true))
                      }}
                    >
                      <motion.div
                        key={saveToBoardStatus}
                        initial={{ y: -15, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: 15, opacity: 0 }}
                      >
                        <SaveToBoardButton style={{ height: '20px' }} />
                      </motion.div>
                    </StyledIconButton>
                  </ButtonTooltip>
                )}
              </PermissionWrapper>
              <PermissionWrapper
                feature="Add tag to items"
                isAllowed={Boolean(userAllowedTo.ADD_ITEM_TAG)}
              >
                {(isAllowed) => (
                  <ButtonTooltip
                    css="margin-left:8px"
                    title="Add tag to all"
                    disabled={shouldDisableButton(addTagStatus) || !isAllowed}
                  >
                    <StyledIconButton
                      disabled={shouldDisableButton(addTagStatus) || !isAllowed}
                      onClick={() => {
                        dispatch(setIsAddTagMenuVisible(true))
                      }}
                    >
                      <motion.div
                        key={addTagStatus}
                        initial={{ y: -15, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: 15, opacity: 0 }}
                      >
                        <AddTagButton style={{ height: '20px' }} />
                      </motion.div>
                    </StyledIconButton>
                  </ButtonTooltip>
                )}
              </PermissionWrapper>
              <PermissionWrapper
                feature="generate assets"
                isAllowed={Boolean(userAllowedTo.CREATE_SOCIAL_ASSETS)}
              >
                {(isAllowed) => (
                  <ButtonTooltip
                    css="margin-left:8px"
                    title="Generate assets"
                    disabled={!isAllowed}
                  >
                    <StyledIconButton
                      disabled={!isAllowed}
                      onClick={handleGenerateAssets}
                    >
                      <Instagram style={{ height: '20px' }} />
                    </StyledIconButton>
                  </ButtonTooltip>
                )}
              </PermissionWrapper>
              {canDeleteOrRemoveItem && (
                <ButtonTooltip
                  css="margin-left:8px"
                  title={
                    isInProjectView ? 'Remove all from board' : 'Delete all'
                  }
                  disabled={shouldDisableButton(deleteStatus)}
                >
                  <StyledIconButton
                    disabled={shouldDisableButton(deleteStatus)}
                    onClick={() =>
                      dispatch(setIsDeleteConfirmModalVisible(true))
                    }
                  >
                    <motion.div
                      key={deleteStatus}
                      initial={{ y: -15, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: 15, opacity: 0 }}
                    >
                      <DeleteButton style={{ height: '20px' }} />
                    </motion.div>
                  </StyledIconButton>
                </ButtonTooltip>
              )}

              {isSaveToBoardMenuVisible && (
                <BlockKeyDownPropagation>
                  <motion.div
                    initial={{ x: 15, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: 15, opacity: 0 }}
                    css="position:absolute;top:60px;right:0px;width:300px"
                  >
                    <div>
                      <SaveToBoard
                        workspaceItemIds={Object.values(selectedItems).map(
                          (selectedItem) =>
                            'boardId' in selectedItem
                              ? selectedItem.itemId
                              : selectedItem.id
                        )}
                        onMenuClose={() =>
                          dispatch(setIsSaveToBoardMenuVisible(false))
                        }
                        onSavedItemsToBoard={(items) => {
                          trackMultiSelectItemsSavedToBoard({
                            workspaceId,
                            nbrOfItems: items.length,
                          })
                        }}
                      />
                    </div>
                  </motion.div>
                </BlockKeyDownPropagation>
              )}
              {isAddTagMenuVisible && (
                <BlockKeyDownPropagation>
                  <ClickOutside
                    handleClickOutside={() =>
                      dispatch(setIsAddTagMenuVisible(false))
                    }
                  >
                    <AddTagMenuWrapper
                      initial={{ x: 15, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      exit={{ x: 15, opacity: 0 }}
                    >
                      <Text size="sm" color="neutral.1" bold>
                        Add tag to selected items
                      </Text>
                      <ItemTagEditable
                        addTag={handleAddTag}
                        activeTagIds={[]}
                        onCancelEditing={() =>
                          dispatch(setIsAddTagMenuVisible(false))
                        }
                      />
                    </AddTagMenuWrapper>
                  </ClickOutside>
                </BlockKeyDownPropagation>
              )}
            </ActionGroup>
          </Wrapper>
        )}
      </AnimatePresence>
      <DeleteModalConfirm
        isModalOpen={isDeleteConfirmModalVisible}
        onDeleteConfirm={handleDeleteItems}
        handleCloseModal={() => dispatch(setIsDeleteConfirmModalVisible(false))}
        isInProjectView={isInProjectView}
      />
    </>
  )
}

export default MultiSelectActionbar

interface WrapperProps {
  siderSplitValue: number
  stretch: boolean
}

const Wrapper = styled(motion.div)<WrapperProps>`
  height: 60px;
  position: fixed;
  top: 0;
  width: ${({ siderSplitValue, stretch }) =>
    stretch ? '100%' : `calc(100% - ${siderSplitValue}px)`};
  left: ${({ siderSplitValue, stretch }) =>
    stretch ? 0 : `${siderSplitValue}px`};
  background: ${({ theme }) => theme.colors.accent[2]};
  align-items: center;
  padding: 0px 16px;
  z-index: 55;
  display: flex;
`

const ItemsGroup = styled.div`
  padding: 0px 12px;
  height: 38px;
  display: flex;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.default};
  border: ${({ theme }) =>
    `${theme.border.thin} ${theme.colors.gray.medium[9]}`};
`

const ActionGroup = styled.div`
  display: flex;
`

const ResetButton = styled(CloseOutline)`
  height: 24px;
  color: ${({ theme }) => theme.colors.text.neutral[0]};
  cursor: pointer;
  margin-left: 10px;
  margin-top: 1px;
`

const StyledIconButton = styled(Button)`
  ${({ theme }) => css`
    background: transparent;
    border: ${theme.border.thin} ${theme.colors.gray.medium[9]};
    border-radius: ${theme.borderRadius.default};
    padding: 0px;
    height: 38px;
    width: 38px;
  `}
`

const ButtonTooltip: React.FC<{
  title: string
  disabled: boolean
  className?: string
  children: React.ReactNode
}> = ({ title, children, disabled, className }) => {
  return (
    // @ts-ignore
    <Tooltip
      duration={100}
      animation="scale"
      size="small"
      arrow
      distance={8}
      delay={100}
      position="bottom"
      title={title}
      disabled={disabled}
      className={className}
      style={{ display: 'block' }}
    >
      {children}
    </Tooltip>
  )
}

interface DeleteModalConfirmProps {
  isModalOpen: boolean
  handleCloseModal: () => void
  onDeleteConfirm: () => void
  isInProjectView: boolean
}

const DeleteModalConfirm: React.FC<DeleteModalConfirmProps> = (props) => {
  return (
    <Modal
      height="auto"
      width="560px"
      isOpen={props.isModalOpen}
      close={props.handleCloseModal}
    >
      <DeleteModalWrapper>
        <DeleteModalHeading>
          {props.isInProjectView
            ? 'Are you sure you want to remove all selected items?'
            : 'Are you sure you want to delete all selected items?'}
        </DeleteModalHeading>
        <DeleteModalSubHeading>You can&apos;t undo this!</DeleteModalSubHeading>
        <div css="display:flex">
          <Button onClick={props.handleCloseModal}>Cancel</Button>
          <Button
            variant="primary"
            onClick={props.onDeleteConfirm}
            css="margin-left:12px"
          >
            {props.isInProjectView ? 'Remove items from board' : 'Delete items'}
          </Button>
        </div>
      </DeleteModalWrapper>
    </Modal>
  )
}

const DeleteModalWrapper = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const DeleteModalHeading = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.lg};
    color: white;
    margin-bottom: 8px;
  `}
`

const DeleteModalSubHeading = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.base};
    color: ${theme.colors.gray.medium[9]};
    margin-bottom: 24px;
  `}
`

const AddTagMenuWrapper = styled(motion.div)`
  ${({ theme }) => css`
    position: absolute;
    top: 60px;
    right: 0px;
    width: 300px;
    padding: 12px;
    background: ${theme.colors.background[4]};
  `}
`
