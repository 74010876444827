import { Hide } from '@styled-icons/boxicons-regular/Hide'
import isEqual from 'lodash/isEqual'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'

import IconButton from 'components/common/IconButton'
import GridItem, { GridItemProps } from 'components/grid/griditem/GridItem'
import { Wrapper as GridOverlayWrapper } from 'components/grid/griditem/GridItemOverlayStyles'
import StopPropagation from 'helpers/StopPropagation'
import { getItemMediaType } from 'helpers/getItemMediaType'
import useWorkspacePermissions from 'hooks/useWorkspacePermissions'
import { RootState } from 'store'

import { DiscoverSaveItem } from './DiscoverSaveItem'
import { useAdminHideFromDiscovery } from './useAdminHideFromDiscovery'

interface DiscoverGridItemProps extends GridItemProps {
  currentUserId: string
  trackingSessionId?: string
}

const DiscoverGridItem: React.FC<DiscoverGridItemProps> = ({
  currentUserId,
  trackingSessionId,
  ...originalProps
}) => {
  const activeWorkspaceId = useSelector(
    (store: RootState) => store.content.activeWorkspace?.id
  )

  const userAllowedTo = useWorkspacePermissions(['UPLOAD'])

  const allowedToSave = Boolean(userAllowedTo.UPLOAD && activeWorkspaceId)

  const isGif = getItemMediaType(originalProps.item) === 'gif'

  return (
    <GridItem
      {...originalProps}
      isDiscoverItem
      customOverlay={() => (
        <GridOverlay
          noOverlayShadow={isGif}
          allowedToSave={allowedToSave}
          itemId={originalProps.item.id}
          itemWorkspaceId={originalProps.item.workspaceId}
        />
      )}
    />
  )
}

interface GridOverlayProps {
  allowedToSave: boolean
  itemId: string
  itemWorkspaceId: string
  noOverlayShadow: boolean
}

const GridOverlay: React.FC<GridOverlayProps> = ({
  allowedToSave,
  itemId,
  itemWorkspaceId,
  noOverlayShadow,
}) => {
  const { isAllowed, run } = useAdminHideFromDiscovery(itemId, itemWorkspaceId)

  return (
    <StopPropagation>
      <GridOverlayWrapper noOverlayShadow={noOverlayShadow}>
        {isAllowed && (
          <IconButton
            Icon={Hide}
            size={35}
            wrapperStyles={{ position: 'absolute' }}
            style={{ pointerEvents: 'auto' }}
            onClick={run}
          />
        )}
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {allowedToSave && <DiscoverSaveItem itemId={itemId} />}
        </div>
      </GridOverlayWrapper>
    </StopPropagation>
  )
}

export default memo(DiscoverGridItem, isEqual)
