import React from 'react'

import { icons } from '../../styles/shared'

const AirIcon = ({ children, style, ...props }) => {
  if (!props.type) return null
  const src = icons[props.type]
  if (!src) return null

  return (
    <div
      className="icon"
      style={{
        display: 'inline-block',
        backgroundImage: `url(${src})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        ...props,
      }}
    />
  )
}

export default AirIcon
