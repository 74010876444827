import { ArrowRightShort } from '@styled-icons/bootstrap'
import { AnimatePresence, motion } from 'framer-motion'
import { Fragment, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { useLocation, useNavigate } from 'react-router'
import { compose } from 'redux'

import { Margin } from 'components/common/Margin'
import Spinner from 'components/common/Spinner'
import { useSendWorkspaceInvitationMutation } from 'generated/graphql'
import {
  trackSetupInvitesCompleted,
  trackSetupInvitesUpdated,
} from 'helpers/tracking/tracking'
import useIsMobile from 'hooks/useIsMobile'
import useWorkspacePermissions from 'hooks/useWorkspacePermissions'
import { workspacesSelector } from 'store/selectors'

import { db } from '../../config/firebase'
import { setActiveWorkspaceByUrl } from '../../store/content/actions'
import Loading from '../Loading'
import { Button } from './SetupCommon'
import SetupGoBack from './SetupGoBack'
import {
  Center,
  ContentContainer,
  DashedButton,
  ErrorMessage,
  Footer,
  Heading,
  InputStyled,
  MobileButton,
  Row,
  RowDescription,
  SkipText,
} from './SetupStyles'

const SetupInvite = (props) => {
  const location = useLocation()
  const navigate = useNavigate()
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm()
  const [errorMessage, setErrorMessage] = useState(null)
  const [isCompleting, setIsCompleting] = useState(false)
  const [emailFields, setEmailFields] = useState([0, 1])
  const [isUpdatingInvites, setIsUpdatingInvites] = useState(false)

  const trackingSetupSessionId = new URLSearchParams(location.search).get(
    'sessionId'
  )

  const [sendWorkspaceInvitation] = useSendWorkspaceInvitationMutation()

  const userAllowedTo = useWorkspacePermissions(['ADD_WORKSPACE_MEMBER'])

  const isMobile = useIsMobile()

  // Sets the email fields to the members list from activeWorkspace
  useEffect(() => {
    if (props.activeWorkspace) {
      if (props.activeWorkspace.members.length > 0) {
        setIsUpdatingInvites(true)
        props.activeWorkspace.members.forEach((member, i) => {
          setEmailFields((arr) => [...arr, arr.length])
          setTimeout(() => {
            document.getElementsByName(`email_${i}`)[0].value = member
          }, 1)
        })
      }
    }
  }, [props.activeWorkspace])

  function addEmailField() {
    setEmailFields(emailFields.concat(emailFields.length))
  }

  // Set active workspace
  if (props.workspaces && !props.activeWorkspace) {
    // Get url of first available workspace
    const firstWorkspaceUrl = Object.values(props.workspaces)[0]
      ? Object.values(props.workspaces)[0].url
      : null

    // Set active workspace
    props.setActiveWorkspaceByUrl(props.workspaces, firstWorkspaceUrl)
  }

  function skip() {
    // Create onboarding update
    const onboardingRef = db
      .collection('users')
      .doc(props.uid)
      .collection('private')
      .doc('onboarding')

    // Define update
    const onboardingUpdates = {
      completedInvite: true,
      updatedAt: props.firestore.FieldValue.serverTimestamp(),
    }

    // Set update
    onboardingRef
      .set(onboardingUpdates, { merge: true })
      .then(navigateToNextSetupStep)
      .catch((e) => {
        setErrorMessage(e.message)
        setIsCompleting(false)
        console.log('Error: ', e)
      })
  }

  useEffect(() => {
    if (userAllowedTo.ADD_WORKSPACE_MEMBER === false) {
      skip()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAllowedTo.ADD_WORKSPACE_MEMBER]) // we only want to depend on permission changes here

  const navigateToNextSetupStep = () => {
    navigate(`/${props.activeWorkspace.url}/upload${location.search}`)
  }

  const onSubmit = (values) => {
    // Remove empty fields
    setIsCompleting(true)

    const nonEmpty = Object.keys(values).filter((key) => values[key].length > 0)

    if (nonEmpty.length === 0) {
      skip()
      return
    }

    // Error check
    if (nonEmpty.every((key) => !errors[key])) {
      const emails = nonEmpty
        .map((key) => values[key]) // Get email
        .map((email) => email.toLowerCase().trim()) // Enforce lowercase and no trailing spaces

      // Filter out duplicates
      const emailsNoDuplicates = emails.filter(
        (item, index) => emails.indexOf(item) === index
      )

      emailsNoDuplicates.map((email) => {
        return sendWorkspaceInvitation({
          variables: {
            email,
            workspaceId: props.activeWorkspace.id,
            inviteSource: 'workspaceSetup',
          },
        })
      })

      if (isUpdatingInvites) {
        trackSetupInvitesUpdated({
          invitesEmails: emails,
          invitesCount: emails.length,
          trackingSetupSessionId,
        })
      } else {
        trackSetupInvitesCompleted({
          invitesEmails: emails,
          invitesCount: emails.length,
          trackingSetupSessionId,
        })
      }

      // Create onboarding update
      const onboardingRef = db
        .collection('users')
        .doc(props.uid)
        .collection('private')
        .doc('onboarding')
      const onboardingUpdates = {
        completedInvite: true,
        updatedAt: props.firestore.FieldValue.serverTimestamp(),
      }

      // Commit batch and redirect on finish
      onboardingRef
        .update(onboardingUpdates)
        .then(navigateToNextSetupStep)
        .catch((e) => {
          setErrorMessage(e.message)
          setIsCompleting(false)
          console.log('Error: ', e)
        })
    }
  }

  if (!props.activeWorkspace) return <Loading />
  return (
    <ContentContainer
      isMobile={isMobile}
      as="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <SetupGoBack uid={props.uid} previousSetup="completedWorkspace" />
      {/* Remove previously created workspace */}
      <Heading isMobile={isMobile} style={{ marginTop: isMobile ? 34 : 0 }}>
        <b>Invite your team</b>{' '}
        {!isMobile && <>to {props.activeWorkspace.title}</>}
      </Heading>
      <Row
        isMobile={isMobile}
        style={{
          marginTop: isMobile ? 0 : 32,
          display: 'grid',
          justifyItems: 'center',
        }}
      >
        <RowDescription
          isMobile={isMobile}
          style={{ width: isMobile ? 320 : 400 }}
        >
          Sharing assets with team members or selected collaborators is one of
          the big ideas in Kive. In a connected team, everyone becomes better
          and has access to more&nbsp;ideas.
        </RowDescription>
      </Row>
      <Row isMobile={isMobile}>
        {emailFields.map((email, index) => (
          <Fragment key={email}>
            <InputStyled
              placeholder="Enter team member email"
              style={{
                width: isMobile ? '100%' : 400,
                margin: isMobile ? 10 : 4,
                height: isMobile ? 50 : 'auto',
              }}
              name={`email_${index}`}
              {...register(`email_${index}`, {
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: 'invalid email address',
                },
              })}
            />
            {errors[`email_${index}`] && (
              <ErrorMessage>{errors[`email_${index}`].message}</ErrorMessage>
            )}
          </Fragment>
        ))}

        <DashedButton isMobile={isMobile} onClick={addEmailField}>
          Add email field
        </DashedButton>
      </Row>
      {isMobile && <Margin y={125} />}

      <Footer isMobile={isMobile}>
        {!isMobile && (
          <Button
            htmlType="submit"
            disabled={errors.firstName || errors.lastName}
            style={{ width: 400, marginLeft: 'calc(50% - 200px)' }}
            icon={isCompleting ? 'loading' : 'next'}
          >
            Invite and continue
          </Button>
        )}

        <Center>
          <SkipText
            style={{
              marginTop: isMobile ? 10 : 0,
              position: isMobile ? 'relative' : 'absolute',
            }}
            isMobile={isMobile}
            onClick={skip}
          >
            I&apos;ll invite my team later. Skip this.
          </SkipText>
        </Center>

        {isMobile && (
          <>
            <MobileButton disabled={errors.firstName || errors.lastName}>
              {isCompleting ? (
                <Spinner css="height: 24px" />
              ) : (
                <>
                  <motion.div layout>Invite and continue</motion.div>
                  <AnimatePresence>
                    {!(errors.firstName || errors.lastName) && (
                      <motion.div
                        style={{ textAlign: 'left' }}
                        layout
                        initial={{ x: -10, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: -10, opacity: 0 }}
                      >
                        <ArrowRightShort size={28} />
                      </motion.div>
                    )}
                  </AnimatePresence>
                </>
              )}
            </MobileButton>
          </>
        )}
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </Footer>
    </ContentContainer>
  )
}

SetupInvite.defaultProps = {
  activeWorkspace: {},
}

const mapStateToProps = (state, ownProps) => ({
  uid: state.firebase.auth.uid,
  displayName: state.firebase.auth.displayName,
  currentUserEmail: state.firebase.auth.email,
  workspaces: workspacesSelector(state),
  activeWorkspace: state.content.activeWorkspace,
})

const mapDispatchToProps = (dispatch) => ({
  setActiveWorkspaceByUrl: (workspaces, url) => {
    dispatch(setActiveWorkspaceByUrl(workspaces, url))
  },
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    if (!props.email || props.email.split('@').length < 1) return []
    return [
      {
        collection: 'workspaces',
        where: ['admins', 'array-contains', props.email],
      },
    ]
  })
)(SetupInvite)
