import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isLoaded } from 'react-redux-firebase'
import { Outlet, useMatch } from 'react-router-dom'
import { StringParam, useQueryParams } from 'use-query-params'

import Flex from 'components/common/Flex'
import useIsMobile from 'hooks/useIsMobile'
import { setLibrarySubView } from 'store/ui'

import { trackLibraryOpened } from '../../helpers/tracking/tracking'
import ToSwitch from '../common/ToSwitch'
import ItemView from '../item/ItemView'
import SearchCreation from '../search/SearchCreation'
import { LibraryEmptyView } from './LibraryEmptyView'
import { Container, Main } from './LibraryStyles'

function Library() {
  const isMobile = useIsMobile()
  const dispatch = useDispatch()
  const tabIdMatch = useMatch('/:workspaceSlug/library/:tabId/*')
  const [{ item: itemId }] = useQueryParams({
    item: StringParam,
  })
  const tabId = tabIdMatch?.params?.tabId

  const [mainRef, setMainRef] = useState()

  const activeWorkspace = useSelector((state) => state.content.activeWorkspace)
  const previousSubView = useSelector((state) => state.ui.librarySubView)

  const workspaceItemCount = useSelector(
    (state) => state.firestore.data.workspaceItemCounter?.merged_count || 0
  )

  const workspaceItemCounterIsLoaded = useSelector((state) =>
    isLoaded(state.firestore.data.workspaceItemCounter)
  )

  useEffect(() => {
    trackLibraryOpened()
  }, [])

  useEffect(() => {
    if (tabId && tabId !== previousSubView) {
      dispatch(setLibrarySubView(tabId))
    }
  }, [tabId, dispatch, previousSubView])

  const renderContent = () => {
    if (!workspaceItemCounterIsLoaded || !mainRef) {
      return null
    }
    if (workspaceItemCount === 0) {
      return <LibraryEmptyView />
    }
    return (
      <Container>
        <Flex
          justifyContent="center"
          css={isMobile ? 'margin: 16px 6px 16px' : 'margin: 16px 16px 16px'}
        >
          <ToSwitch
            defaultActive={tabId}
            mode="url"
            links={[
              {
                text: 'Magic Boards',
                id: 'magic-boards',
                path: 'magic-boards',
              },
              {
                text: 'Upload Events',
                id: 'upload-events',
                path: 'upload-events',
              },
              {
                text: 'All Items',
                id: 'items',
                path: 'items',
              },
            ]}
          />
        </Flex>
        <Outlet context={{ scrollNode: mainRef }} />
      </Container>
    )
  }

  return (
    <>
      <SearchCreation
        placeholder={
          isMobile
            ? 'Search workspace items'
            : `Type to search ${workspaceItemCount} ${activeWorkspace.title} uploads`
        }
        shouldDisplayUploadButton
        shouldDisplayNotifications
      />
      <Main
        style={{ position: 'relative' }}
        hasHeader
        ref={(ref) => setMainRef(ref)}
        data-intercom-target="Library Main" // Used for Intercom product tours
      >
        {renderContent()}
      </Main>
      {itemId && <ItemView />}
    </>
  )
}

export default Library
