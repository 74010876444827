import { ButtonVariant } from '../../../common/Button'

type GetButtonTextArgs = {
  price: number
}
export const getButtonText = ({
  price,
}: GetButtonTextArgs): {
  text: string
  disabled: boolean
  variant: ButtonVariant
} => {
  if (price && price > 0) {
    return {
      text: 'Start free trial',
      disabled: false,
      variant: 'primary',
    }
  }
  return {
    text: 'Current',
    disabled: true,
    variant: 'primary',
  }
}
