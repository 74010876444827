export const CurrentPlanTexts = {
  en: {
    section_title: 'Current plan',
    price_templates: {
      has_price: '[[price]] per [[year]]',
      free: 'Free',
    },
    buttons: {
      change_plan: 'Change plan',
    },
  },
}
