import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'
import { browserName, isMobile } from 'react-device-detect'
import { useSelector } from 'react-redux'
import { Navigate, useRoutes } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

import { FrozenAccount } from 'components/authentication/FrozenAccount'
import { OtpLogin } from 'components/authentication/OtpLogin'
import BoardView from 'components/board/BoardView'
import PublicBoard from 'components/board/PublicBoard'
import { RedirectWithParams } from 'components/common/RedirectWithParams'
import { CreateAssetsView } from 'components/createAssets/CreateAssetsView'
import DiscoverView from 'components/discover/DiscoverView'
import {
  CreateEditSession,
  CreateEditSessionSignup,
} from 'components/edit-sessions/create-edit-session/CreateEditSession'
import { EditSessionLobby } from 'components/edit-sessions/edit-session-lobby'
import JoinWorkspace from 'components/joinworkspace/JoinWorkspace'
import JoinWorkspaces from 'components/joinworkspace/JoinWorkspaces'
import CreateKiveWrappedPanel from 'components/kiveWrapped/KiveWrappedPanel'
import { AllItems } from 'components/library/AllItems/AllItems'
import Library from 'components/library/Library'
import { MagicBoard } from 'components/library/MagicBoard/MagicBoard'
import { MagicBoards } from 'components/library/MagicBoards/MagicBoards'
import UploadSession from 'components/library/UploadSession/UploadSession'
import UploadSessions from 'components/library/UploadSessions/UploadSessions'
import NominationView from 'components/nomination/NominationView'
import ProjectCreateBoard from 'components/project/ProjectCreateBoard'
import Dashboard from 'components/projects/Dashboard'
import Maintenance from 'components/protected/Maintenance'
import SSOHandler from 'components/protected/SSOHandler'
import { ProjectAddBoardView } from 'components/publicProject/ProjectAddBoardView'
import Setup from 'components/setup/Setup'
import SetupInvite from 'components/setup/SetupInvite'
import SetupProfile from 'components/setup/SetupProfile'
import SetupWorkspace from 'components/setup/SetupWorkspace'
import VideoFrameExtractor from 'components/videoFrameExtractor/VideoFrameExtractor'
import { WorkspaceWithMenuView } from 'components/workspace/WorkspaceWithMenuView'
import { DiscoverBoardIdeas } from 'features/discover/DiscoverBoardIdeas'
import { DiscoverBoardView } from 'features/discover/DiscoverBoardView'
import { DiscoverBoards } from 'features/discover/DiscoverBoards'
import { DiscoverHome } from 'features/discover/DiscoverHome'
import { DiscoverItemsForYou } from 'features/discover/DiscoverItemsForYou'
import { DiscoverNewItems } from 'features/discover/DiscoverNewItems'
import { Home } from 'features/home/Home'
import { useUserQuery } from 'generated/graphql'
import { Logout } from 'helpers/Logout'
import { useGuest } from 'hooks/useGuest'
import { useGrowthBookFeature } from 'hooks/useSyncGrowthbook'
import useSyncGrowthbookUser from 'hooks/useSyncGrowthbookUser'
import { RootState } from 'store'

import Loading from '../components/Loading'
import OpenFavoriteBoard from '../components/board/OpenFavoriteBoard'
import ChromeExtensionQuickStart from '../components/chromeExtensionQuickstart/ChromeExtensionQuickStart'
import { EditSessionInvitation } from '../components/edit-session-invitations'
import { EditSession } from '../components/edit-sessions'
import ProfileView from '../components/profile/ProfileView'
import { SearchRecommendationsComponent } from '../components/search/SearchCreation'
import Workspace from '../components/workspace/Workspace'
import { firebaseAuth } from '../config/firebase'
import {
  AppPage,
  DemoPage,
  InitPage,
  LandingPage,
  LoginForgotPasswordPage,
  LoginPage,
  PricingPage,
  PrivacyPage,
  ProductPage,
  ProjectViewPage,
  PublicBoardPage,
  PublicItemViewPage,
  PublicProjectPage,
  RequestInvitePage,
  SetupUploadsPage,
  SignupPage,
  TermsPage,
  VideoPage,
  WaitingListPage,
} from './route-declarations'

const loadableComponents = [
  PrivacyPage,
  TermsPage,
  PricingPage,
  ProductPage,
  DemoPage,
  VideoPage,
  LoginPage,
  SignupPage,
  LoginForgotPasswordPage,
  RequestInvitePage,
  WaitingListPage,
  InitPage,
  AppPage,
  SearchRecommendationsComponent,
  ProjectViewPage,
  SetupUploadsPage,
  LandingPage,
  PublicItemViewPage,
  PublicBoardPage,
  PublicProjectPage,
]

type CustomRouteProps = {
  children: JSX.Element
  authed: boolean
}

function PrivateRoute({ authed, children }: CustomRouteProps) {
  const userId = useSelector((state: RootState) => state.firebase.auth.uid)
  const { data: userData } = useUserQuery({
    variables: {
      uid: userId,
    },
    skip: !userId,
  })

  if (userData?.user?.frozenAccount) {
    return <RedirectWithParams to="/frozen-account" />
  }

  if (authed) {
    return children
  }
  return <RedirectWithParams to="/" />
}

function PublicRoute({ authed, children }: CustomRouteProps) {
  if (authed) {
    return <RedirectWithParams to="/app" />
  }
  return children
}

// we preload lazy pages after 5 seconds.
// with lazy routes user gets faster initial view but could get crashes when we deploy new versions. This proabably solves this.
const preloadPages = () => {
  setTimeout(() => {
    loadableComponents.forEach((loadableComponent) => {
      loadableComponent.preload()
    })
  }, 5000)
}

export const Routing = () => {
  const [authed, setAuthed] = useState(false)
  const [loading, setLoading] = useState(true)

  const { setGuestId, guest } = useGuest()
  const { setGrowthbookAttributes } = useSyncGrowthbookUser()

  const discoverBoardIdeasFeature = useGrowthBookFeature('discover-board-ideas')
  const newDiscoverFeature = useGrowthBookFeature('discover-2.0')
  const homePageFeature = useGrowthBookFeature('home-page')

  // prettier-ignore
  const routes = useRoutes([
    { path: '/privacy', element: <PrivacyPage /> },
    { path: '/terms', element: <TermsPage /> },
    /* { path: '/pricing', element: <PricingPage /> }, */
    { path: '/product', element: <ProductPage /> },
    { path: '/extension', element: <ChromeExtensionQuickStart /> },
    { path: '/demo', element: <DemoPage /> },
    { path: '/video/:videoId', element: <VideoPage /> },
    { path: '/logout', element: <Logout /> },
    { path: '/b/:boardId', element: <PublicBoardPage /> },
    { path: '/i/:itemId', element: <PublicItemViewPage /> },
    { path: '/bi/:itemId', element: <PublicItemViewPage isBoardItem /> },
    { path: '/SSO-mobile/*', element: <SSOHandler /> },
    {
      path: '/p/:projectId/*',
      element: <PublicProjectPage />,
      children: [
        { path: 'add', element: <ProjectAddBoardView /> },
        { path: ':boardId', element: <PublicBoard /> }
      ]
    },
    { path: '/join/workspaces', element: (<PrivateRoute authed={authed}><JoinWorkspaces /></PrivateRoute>) },
    { path: '/join/:workspaceUrl', element: (<PrivateRoute authed={authed}><JoinWorkspace /></PrivateRoute>) },
    { path: '/', element: (<PublicRoute authed={authed}><LandingPage /></PublicRoute>) },
    { path: '/login', element: (<PublicRoute authed={authed}><LoginPage /></PublicRoute>) },
    { path: '/signup', element: (<PublicRoute authed={authed}><SignupPage /></PublicRoute>) },
    { path: '/activate/:word', element: (<PublicRoute authed={authed}><OtpLogin /></PublicRoute>) },
    { path: '/forgot', element: (<PublicRoute authed={authed}><LoginForgotPasswordPage /></PublicRoute>) },
    {
      path: '/r',
      children: [
        { index: true, element: (<PublicRoute authed={authed}><RequestInvitePage /></PublicRoute>) },
        { path: ':referralId', element: (<PublicRoute authed={authed}><RequestInvitePage /></PublicRoute>) },
      ]
    },
    { path: '/waitinglist', element: (<PublicRoute authed={authed}><WaitingListPage /></PublicRoute>) },
    { path: '/u/:userHandle', element: (<PrivateRoute authed={authed}><ProfileView /></PrivateRoute>) },
    { path: '/init', element: (<PrivateRoute authed={authed}><InitPage /></PrivateRoute>) },
    {/* A proxy route which redirect to either project board or public board */},
    { path: '/open-favorite-board/:boardId', element: (<PrivateRoute authed={authed}><OpenFavoriteBoard /></PrivateRoute>) },
    { path: '/canvas/signup', element: <CreateEditSessionSignup authed={authed} /> },
    { path: '/canvas/create', element: <CreateEditSession /> },
    { path: '/canvas/lobby', element: <EditSessionLobby /> },
    { path: '/canvas/:sessionId', element: <EditSession /> },
    {
        path: 'session-invitation/:invitationId',
        element: <EditSessionInvitation />,
    },
    { path: '/frozen-account', element: <FrozenAccount authed={authed} />},
    //
    // Main entry to the application
    //
    {
      path: '*',
      element: (<PrivateRoute authed={authed}><AppPage /></PrivateRoute>),
      children: [
        { path: 'maintenance', element: <Maintenance /> },
        {
          path: 'setup/*',
          element: <Setup />,
          children: [
            { path: 'profile', element: <SetupProfile /> },
            { path: 'workspace', element: <SetupWorkspace /> },
            { path: 'invite', element: <SetupInvite /> },
          ]
        },
        {
          path: ':workspaceUrl/*',
          element: <Workspace />,
          children: [
            { path: 'upload', element: <SetupUploadsPage /> },
            {
              path: 'p/:projectId/*',
              element: <ProjectViewPage />,
              children: [
                { path: 'add', element: <ProjectCreateBoard /> },
                { path: ':boardId', element: <BoardView /> },
              ]
            },
            {
              path: '*',
              element: <WorkspaceWithMenuView />,
              children: [
                ...(newDiscoverFeature.on ? [
                  { path: 'discover/*', element: <DiscoverHome />, children: [
                    { path: 'for-you', element: <DiscoverItemsForYou /> },
                    { path: 'new', element: <DiscoverNewItems /> },
                    { path: 'boards', element: <DiscoverBoards /> },
                    { path: 'boards/:boardId', element: <DiscoverBoardView /> },
                    ...(discoverBoardIdeasFeature.on ? [{ path: 'board-ideas/:boardId', element: <DiscoverBoardIdeas /> }]: []),
                  ] },
                ] : [
                  { path: 'discover', element:  <DiscoverView /> },
                ]),
                { path: 'profile', element: <ProfileView /> },
                { path: 'projects', element: <Dashboard /> },
                { path: 'frame-extractor', element: <VideoFrameExtractor /> },
                { path: 'create-asset', element: <CreateAssetsView /> },
                { path: 'kive-wrapped', element: <CreateKiveWrappedPanel /> },
                { path: 'nominate', element: <NominationView /> },
                { path: 'kive-wrapped', element: <CreateKiveWrappedPanel /> },
                {
                  path: 'library/*',
                  element: <Library />,
                  children: [
                    {
                      path: 'magic-boards/*',
                      element: <MagicBoards />,
                      children: [
                        { path: ':tagId', element: <MagicBoard /> }
                      ]
                    },
                    {
                      path: 'upload-events/*',
                      element: <UploadSessions />,
                      children: [
                        { path: ':uploadSessionId', element: <UploadSession /> },
                        { path: ':uploadSessionId/items', element: <UploadSession /> } // Deprecated: safe to remove?
                      ]
                    },
                    { path: 'items', element: <AllItems /> },
                    { index: true, element: <RedirectWithParams to="magic-boards" />}
                  ]
                },
                { index: true, element: homePageFeature.on ? <Home /> : <Navigate replace to="library" /> },
              ]
            },

          ]
        },
      ],
    },
  ])

  useEffect(() => {
    ;(window as any).dataLayer = (window as any).dataLayer || []

    preloadPages()

    const removeListener = firebaseAuth().onAuthStateChanged(async (user) => {
      if (user) {
        setAuthed(true)
        setLoading(false)
        try {
          console.log('Setting cfw-auth-state cookie')
          Cookies.set('cfw-auth-state', 'true', { expires: 365 })
        } catch (error) {
          console.log(
            `Could not set cfw-auth-state cookie due to ${error.message}`
          )
        }
      } else {
        setAuthed(false)
        setLoading(false)
        try {
          console.log('Removing cfw-auth-state cookie')
          Cookies.remove('cfw-auth-state')
        } catch (error) {
          console.log(
            `Could not remove cfw-auth-state cookie due to ${error.message}`
          )
        }
        // we store a anonymous user id for the not signed in users,
        // which we use to e.g. enable download requests for them
        if (!guest.uid) {
          setGuestId(`guest:${uuidv4()}`)
        }

        setGrowthbookAttributes({
          id: guest.uid,
          loggedIn: false,
          isMobile,
          browserName,
        })
      }
    })
    return () => removeListener()
    // eslint-disable-next-line
  }, [])

  if (loading) {
    return <Loading fullscreen />
  }
  return routes
}
