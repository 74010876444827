import { StyledIcon } from '@styled-icons/styled-icon'
import React, { useState } from 'react'
import styled, { useTheme } from 'styled-components'

import Dropdown from 'components/common/Dropdown'
import Spinner from 'components/common/Spinner'

import { StyledButtonBase } from './Button'
import { DropdownOptions } from './Dropdown/Dropdown'

const IconWrapper = styled.div`
  margin-right: 8px;
`

const SpinnerWrapper = styled.div`
  margin-right: 10px;
`

const ButtonWrapper = styled.div`
  display: flex;
  position: relative;
`

interface ButtonIconProps {
  Icon?: StyledIcon
}

const ButtonIcon: React.FC<ButtonIconProps> = ({ Icon }) => {
  if (!Icon) {
    return null
  }
  return (
    <IconWrapper>
      <Icon style={{ height: 16 }} />
    </IconWrapper>
  )
}

interface ButtonProps {
  title: string
  Icon?: StyledIcon
  isLoading?: boolean
  dropdownOptions?: DropdownOptions
}

const ButtonHoverDropdown: React.FC<
  ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ title, Icon, isLoading, dropdownOptions, children, ...rest }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const theme = useTheme()

  return (
    <ButtonWrapper
      onMouseEnter={() => setIsDropdownOpen(true)}
      onMouseLeave={() => setIsDropdownOpen(false)}
    >
      <StyledButtonBase {...rest}>
        {isLoading ? (
          <SpinnerWrapper>
            <Spinner size={theme.fontSizes.sm} />
          </SpinnerWrapper>
        ) : (
          <ButtonIcon Icon={Icon} />
        )}
        {title}
      </StyledButtonBase>
      <Dropdown isOpen={isDropdownOpen} options={dropdownOptions}>
        {children}
      </Dropdown>
    </ButtonWrapper>
  )
}

export default ButtonHoverDropdown
