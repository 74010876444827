import { createClient } from '@liveblocks/client'
import { createRoomContext } from '@liveblocks/react'

const client = createClient({
  publicApiKey: process.env.REACT_APP_LIVEBLOCKS_PUBLIC_API_KEY,
})

type GenerationFrameStatus = 'SEEKING' | 'PLACED' | 'GENERATING' | 'HAS_OUTPUT'
export type Presence = {
  cursor: { x: number; y: number } | null
  name?: string
  photoURL?: string | null
  generationFrameStatus: GenerationFrameStatus
  generationFrameCoords: {
    x: number
    y: number
  } | null
  generationFramePrompt?: string
  generationFrameAlternatives?: string[]
  generationFrameAlternativesActiveIndex?: number
  isTyping?: boolean
}

export const {
  suspense: {
    RoomProvider,
    useOthers,
    useUpdateMyPresence,
    useStorage,
    useMutation,
    useBatch,
    useHistory,
    useRoom,
    RoomContext,
    useErrorListener,
    useBroadcastEvent,
    useEventListener,
  },
} = createRoomContext<Presence>(client)
