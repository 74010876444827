import { Reference } from '@apollo/client'
import { Cross } from '@styled-icons/entypo/Cross'
import React, { useState } from 'react'
import styled from 'styled-components'

import Button from 'components/common/Button'
import useToastMessages from 'components/toast/useToastMessages'
import { useRemoveItemCommentMutation } from 'generated/graphql'

interface InlineConfirmRemoveItemCommentProps {
  itemCommentId: string
  itemId: string
  boardId: string | null
}

const InlineConfirmRemoveItemComment: React.FC<
  InlineConfirmRemoveItemCommentProps
> = (props) => {
  const [isConfirmVisible, setIsConfirmVisible] = useState(false)
  const { reportError } = useToastMessages()

  const [removeBoardItemCommentMutation] = useRemoveItemCommentMutation({
    variables: {
      itemCommentId: props.itemCommentId,
      boardId: props.boardId,
    },
    update: (cache) => {
      cache.modify({
        fields: {
          itemComments(existingItemComments, { readField, storeFieldName }) {
            if (storeFieldName !== `itemComments:${props.itemId}`) {
              return existingItemComments
            }
            const newItemComments = existingItemComments.itemComments.filter(
              (itemCommentRef: Reference) => {
                return props.itemCommentId !== readField('id', itemCommentRef)
              }
            )
            return {
              ...existingItemComments,
              itemComments: newItemComments,
            }
          },
        },
      })
      cache.modify({
        id: props.boardId
          ? `BoardItem:${props.itemId}`
          : `WorkspaceItem:${props.itemId}`,
        fields: {
          commentCount(existingCount) {
            return existingCount - 1
          },
        },
      })
    },
    optimisticResponse: {
      removeItemComment: {
        __typename: 'RequestStatus',
        status: 'SUCCESS',
      },
    },
    onError: () => {
      reportError(`Oh, we couldn't remove the comment. Please try again.`)
    },
  })

  const handleRemoveClick = () => {
    setIsConfirmVisible(true)
  }

  return (
    <>
      {isConfirmVisible ? (
        <StyledInlineRemoveButton
          onMouseLeave={() => setIsConfirmVisible(false)}
          variant="danger"
          isCompact
          onClick={() => removeBoardItemCommentMutation()}
        >
          Remove
        </StyledInlineRemoveButton>
      ) : (
        <StyledCross onClick={handleRemoveClick} />
      )}
    </>
  )
}

export default InlineConfirmRemoveItemComment

const StyledCross = styled(Cross)`
  height: 20px;
  width: 20px;
  border-radius: 8px;
  padding: 2px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text.neutral[3]};
  &:hover {
    background: ${({ theme }) => theme.colors.background[4]};
  }
`

const StyledInlineRemoveButton = styled(Button)`
  height: 22px;
  padding: 12px 12px;
  font-size: 12px;
  margin-left: 12px;
`
