import { client } from 'apollo'
import React, { useCallback, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { useSelector } from 'react-redux'

import Loading from 'components/Loading'
import DiscoverGridItem from 'components/discover/DiscoverGridItem'
import Grid from 'components/grid/Grid'
import { GridColumnAdditioner } from 'components/grid/GridColumnAdditioner'
import { GridItemProps } from 'components/grid/griditem/GridItem'
import { useSearchWorkspaceItemsQuery } from 'generated/graphql'
import { useActiveWorkspace } from 'hooks/useActiveWorkspace'
import { RootState } from 'store'

type SearchGridProps = {
  scrollNode: HTMLDivElement
  searchQuery: string
  filterTags?: string[]
  filterWorkspaces?: string[]
  filterUploadSessions?: string[]
  filterColors?: string[]
  filterFaceTags?: string[]
  filterFolders?: string[]
  filterFilename?: string
  filterFileFormat?: string
  customGridClassname?: string
  emptyContent?: JSX.Element
  searchPublic?: boolean
  isGridColumnAdditionerHidden?: boolean
}

export const SearchGrid: React.FC<SearchGridProps> = ({
  scrollNode,
  searchQuery,
  filterTags,
  filterWorkspaces,
  filterUploadSessions,
  filterColors,
  filterFaceTags,
  filterFolders,
  filterFilename,
  filterFileFormat,
  emptyContent,
  customGridClassname,
  searchPublic,
  isGridColumnAdditionerHidden,
}) => {
  const [, setRerender] = useState(false)
  const workspaceId = useActiveWorkspace()?.id

  const { data, loading, fetchMore } = useSearchWorkspaceItemsQuery({
    variables: {
      query: searchQuery,
      first: 20,
      scope: searchPublic ? 'PUBLIC' : 'WORKSPACE',
      filters: {
        workspaceIds: filterWorkspaces || null,
        hiddenHits: null,
        uploadSessionIds: filterUploadSessions || null,
        colors: filterColors || null,
        tagIds: filterTags || null,
        faces: filterFaceTags || null,
        folders: filterFolders || null,
        name: filterFilename || null,
        mediaType: filterFileFormat
          ? (filterFileFormat.toLocaleUpperCase() as 'IMAGE' | 'GIF')
          : null,
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    context: {
      headers: {
        'x-experiment-context': JSON.stringify({
          workspaceId,
        }),
      },
    },
  })

  const pageInfo = data?.searchWorkspaceItems.pageInfo
  const nextCursor = pageInfo?.nextCursor
  const searchResults = data?.searchWorkspaceItems.workspaceItems ?? []
  const hasMore = Boolean(nextCursor) && searchResults.length > 0 && !loading

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        cursor: nextCursor,
      },
    })
  }

  const onDeleteItem = useCallback((itemId: string) => {
    client.cache.evict({ id: `WorkspaceItem:${itemId}` })
    client.cache.gc()
  }, [])

  const currentUserId = useSelector(
    (store: RootState) => store.firebase.auth.uid
  )

  useEffect(() => {
    // There is a conclict with our Masonory grid and Inifnite scroll
    // which makes the inifinite scroll to NOT fetch more if the grid content is small enough to not trigger the "fetch more threshold"
    // forcing a rerender solves it. Don't ask me why
    setTimeout(() => {
      setRerender((prev) => !prev)
    }, 0)
  }, [searchResults.length])

  if (loading && !data) {
    return <Loading />
  }

  if (searchResults.length === 0 && !loading) {
    return <div style={{ width: '100%' }}>{emptyContent}</div>
  }

  return (
    <div style={{ width: '100%' }}>
      <InfiniteScroll
        pageStart={0}
        threshold={1000}
        useWindow={false}
        getScrollParent={() => scrollNode}
        loadMore={handleLoadMore}
        hasMore={hasMore}
      >
        <GridColumnAdditioner
          parentWrapper={scrollNode}
          isHidden={isGridColumnAdditionerHidden}
        >
          {(gridColumnAdditionOption) => {
            if (searchPublic) {
              return (
                <Grid
                  customGridClassname={customGridClassname}
                  scrollElement={scrollNode}
                  gridItems={searchResults}
                  isMultiSelectDisabled
                  gridColumnAdditionOption={gridColumnAdditionOption}
                  customGridItem={(props: GridItemProps) => (
                    <DiscoverGridItem
                      {...props}
                      currentUserId={currentUserId}
                    />
                  )}
                />
              )
            }
            return (
              <Grid
                customGridClassname={customGridClassname}
                gridColumnAdditionOption={gridColumnAdditionOption}
                scrollElement={scrollNode}
                gridItems={searchResults}
                onDelete={onDeleteItem}
              />
            )
          }}
        </GridColumnAdditioner>
        {loading && (
          <div className="loader" key={0}>
            <Loading fullscreen />
          </div>
        )}
      </InfiniteScroll>
    </div>
  )
}
