import { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'

import Loading from 'components/Loading'
import { BoardPreviewCard } from 'components/board-preview-card/BoardPreviewCard'
import Flex from 'components/common/Flex'
import { Text } from 'components/common/Text'
import { usePublicBoardsQuery } from 'generated/graphql'
import { RootState } from 'store'

export const ProfileBoards = ({
  userId,
  isCurrentUserProfile,
  userDisplayName,
  scrollNode,
}: {
  userId: string
  isCurrentUserProfile: boolean
  userDisplayName: string
  scrollNode: HTMLElement
}) => {
  const [, setRerender] = useState(false)
  const location = useLocation()
  const currentWorkspace = useSelector(
    (state: RootState) => state.content.activeWorkspace
  )

  const { data, loading, fetchMore } = usePublicBoardsQuery({
    variables: {
      first: 30,
      createdBy: userId,
    },
    fetchPolicy: 'cache-and-network',
    context: {
      batch: true,
    },
  })

  const boards = data?.publicBoards.boards || []
  const pageInfo = data?.publicBoards.pageInfo
  const nextCursor = pageInfo?.nextCursor

  const hasMore = Boolean(nextCursor) && boards.length > 0 && !loading
  const handleLoadMore = () => {
    fetchMore({
      variables: {
        cursor: nextCursor,
      },
    })
  }

  useEffect(() => {
    // There is a conclict with our Masonory grid and Inifnite scroll
    // which makes the inifinite scroll to NOT fetch more if the grid content is small enough to not trigger the "fetch more threshold"
    // forcing a rerender solves it. Don't ask me why
    setTimeout(() => {
      setRerender((prev) => !prev)
    }, 0)
  }, [boards.length])

  if (boards.length === 0 && !loading) {
    return (
      <Text size="base" color="neutral.1">
        {`🤷‍♂️  It doesn't look like ${
          isCurrentUserProfile ? 'you have' : `${userDisplayName} has`
        }  any public boards yet`}
      </Text>
    )
  }

  return (
    <InfiniteScroll
      pageStart={0}
      threshold={1000}
      useWindow={false}
      getScrollParent={() => scrollNode}
      loadMore={handleLoadMore}
      hasMore={!loading && hasMore}
      loader={
        <div className="loader" key={0}>
          <Loading fullscreen />
        </div>
      }
    >
      <Flex flexWrap="wrap" flexGap={3}>
        {boards.map((board) => {
          return (
            <Link
              to={
                isCurrentUserProfile
                  ? `/${currentWorkspace?.url}/p/${board.project.id}/${board.id}`
                  : `/${currentWorkspace?.url}/discover/boards/${board.id}`
              }
              state={{ prevPathname: location.pathname }}
            >
              <BoardPreviewCard
                title={board.title}
                projectTitle={board.project.title}
                count={board.itemCount}
                thumbUrls={board.thumbUrls}
              />
            </Link>
          )
        })}
      </Flex>
    </InfiniteScroll>
  )
}
