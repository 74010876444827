import isNil from 'lodash/isNil'
import omitBy from 'lodash/omitBy'

export const removeNullAndUndefinedFromObject = ({
  object,
}: {
  object: Record<any, any>
}) => {
  return omitBy(object, isNil)
}
