import { parse } from 'query-string'
import { useLocation } from 'react-router-dom'

function useQueryString<
  Params extends { [K in keyof Params]?: string | string[] } = Record<
    string,
    string | string[]
  >
>() {
  const searchString = parse(useLocation().search, {
    arrayFormat: 'comma',
  }) as Params
  return searchString
}

export default useQueryString
