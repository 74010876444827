import { App } from 'App'
import { render } from 'react-dom'

import 'helpers/wdyr'

import { unregister as unregisterServiceWorker } from './registerServiceWorker'
import './styles/app.css'

render(<App />, document.getElementById('root'))
unregisterServiceWorker()
