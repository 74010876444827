import React from 'react'
import styled, { DefaultTheme, css } from 'styled-components'

import FlexGrid from 'components/common/FlexGrid'
import { Text } from 'components/common/Text'
import { capitalizeFirstLetter } from 'helpers/formatting'
import { trackSearchTagClicked } from 'helpers/tracking'

import { useSearchContext } from './SearchContext'

const GRID_BREAKPOINTS: {
  breakpoint: keyof DefaultTheme['breakpoints']
  nColumns: number
}[] = [
  {
    breakpoint: 'xs',
    nColumns: 1,
  },
  {
    breakpoint: 'sm',
    nColumns: 1,
  },
  {
    breakpoint: 'md',
    nColumns: 2,
  },
  {
    breakpoint: 'lg',
    nColumns: 3,
  },
  {
    breakpoint: 'xl',
    nColumns: 3,
  },
  {
    breakpoint: '2xl',
    nColumns: 4,
  },
]

type SearchTag = {
  id: string
  tagId: string
  description: string
  thumbUrl: string | null
}

interface SearchTagsProps {
  tags: SearchTag[]
}

const SearchTags: React.FC<SearchTagsProps> = ({ tags }) => {
  const { addTag, updateFreeText, setIsSearchPanelOpen } = useSearchContext()

  // TODO: add subtitle from knowledge graph
  const subtitle = false

  return (
    <>
      <FlexGrid breakpoints={GRID_BREAKPOINTS} gap={8}>
        {tags.map((tag) => {
          return (
            <TagCard
              key={tag.tagId}
              onClick={() => {
                addTag(tag.description, tag.tagId)
                updateFreeText('')
                setIsSearchPanelOpen(false)
                trackSearchTagClicked({ label: tag.description, id: tag.tagId })
              }}
            >
              <TagThumbnail src={tag.thumbUrl || ''} />
              <TextContainer>
                <Text
                  size="base"
                  color="neutral.0"
                  bold
                  css="max-height:70px;overflow:hidden;"
                >
                  {capitalizeFirstLetter(tag.description)}
                </Text>
                {subtitle && (
                  <Text size="base" color="neutral.2">
                    {subtitle}
                  </Text>
                )}
              </TextContainer>
            </TagCard>
          )
        })}
      </FlexGrid>
    </>
  )
}

export default SearchTags

const TagCard = styled(FlexGrid.Card)`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    padding: 8px;
    margin-bottom: 8px;
    border-radius: ${theme.borderRadius.lg};
    background-color: ${theme.colors.background[4]};

    &:hover {
      cursor: pointer;
      padding: 7px;
      background-color: ${theme.colors.background[3]};
      border: ${theme.border.thin} ${theme.colors.accent[1]};
    }

    transition: 100ms ease background-color;
  `}
`

const TagThumbnail = styled.div<{ src: string }>`
  ${({ theme, src }) => css`
    height: 64px;
    width: 64px;
    flex-shrink: 0;
    border-radius: ${theme.borderRadius.default};
    background-color: ${theme.colors.background[5]};
    ${src && `background-image: url(${src});`}
    background-size: cover;
    background-position: center;
    margin-right: 16px;
  `}
`

const TextContainer = styled.div`
  display: flex;
  align-items: center;
`
