import { Check } from '@styled-icons/boxicons-regular/Check'
import styled, { css } from 'styled-components'
import 'styled-components/macro'

import { Text } from 'components/common/Text'

const itemSize = 32
const paddingSize = 6

export const ActionRow = styled.div<{ justify?: string; align?: string }>`
  ${({ justify, align }) => css`
    pointer-events: none;
    display: flex;
    align-items: ${align || 'center'};
    justify-content: ${justify || 'flex-end'};
  `}
`

export const ActionButton = styled.button(({ theme, disabled }) => ({
  pointerEvents: 'auto',
  position: 'relative',
  borderRadius: theme.borderRadius.default,
  padding: paddingSize,
  width: itemSize,
  height: itemSize,
  outline: 'none',
  cursor: disabled ? 'not-allowed' : 'pointer',
  overflow: 'hidden',
  background: disabled ? theme.colors.background[5] : theme.colors.accent[2],
  color: disabled ? theme.colors.text.neutral[4] : theme.colors.white,
  border: 'none',
  ':hover': {
    background: disabled ? theme.colors.background[5] : theme.colors.accent[1],
  },
  ':active': {
    background: disabled ? theme.colors.background[5] : theme.colors.accent[1],
  },
  margin: 4,
}))

export const ActionButtonTransparent = styled(ActionButton)(({ theme }) => ({
  background: 'transparent',
  ':hover': {
    background: theme.colors.accent[2],
  },
  ':active': {
    color: 'transparent',
  },
}))

interface WrapperProps {
  blur?: boolean
  clickable?: boolean
  noOverlayShadow?: boolean
}

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, blur, clickable, noOverlayShadow }) => css`
    position: absolute;
    height: 100%;
    bottom: 0;
    left: 0;
    width: 100%;
    pointer-events: ${clickable ? 'auto' : 'none'};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    backdrop-filter: ${blur ? 'blur(3px)' : 'none'};
    padding: 6px;
    border-radius: ${theme.borderRadius.lg};
    background-color: ${noOverlayShadow ? 'transparent' : '#00000050'};
    transition: 0.15s background-color ease;
  `}
`

export const StyledLastBoardAction = styled(Text)`
  ${({ theme }) => css`
    padding: 0px;
    pointer-events: auto;
    cursor: pointer;
    color: ${theme.colors.gray.light[2]};
    text-shadow: 0px 1px 2px ${theme.colors.gray.dark[5]};
    display: flex;
    align-items: center;
    &:hover {
      color: ${theme.colors.white};
    }
    &:active {
      color: ${theme.colors.accent[2]};
    }
    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 105px;
    }
  `}
`

export const CheckMark = styled(Check)`
  height: 20px;
  color: ${({ theme }) => theme.colors.accent[3]};
`
