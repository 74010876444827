import { ChevronDown } from '@styled-icons/bootstrap/ChevronDown'
import { StyledIcon } from '@styled-icons/styled-icon'
import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import useClickOutside from 'hooks/useClickOutside'

import Dropdown from './Dropdown'
import { Text } from './Text'

export interface TextSelectOption {
  icon?: StyledIcon
  text: string
  key: string
  link?: string
}

interface TextSelectProps {
  options: TextSelectOption[]
  activeOption: TextSelectOption
  onOptionClick: (option: TextSelectOption) => void
  className?: string
  isDisabled?: boolean
}

export const TextSelect: React.FC<TextSelectProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  const ref = useClickOutside(() => setIsOpen(false))
  const ActiveIcon = props.activeOption.icon
  return (
    <>
      <StyledTextActiveTrigger
        ref={ref}
        onClick={() => (props.isDisabled ? null : setIsOpen((prev) => !prev))}
        isOpen={isOpen}
        className={props.className}
      >
        <Text size="sm">
          {ActiveIcon && (
            <ActiveIcon style={{ height: 16, marginRight: '12px' }} />
          )}
          {props.activeOption.text}
        </Text>
        {!props.isDisabled && <ChevronDown css="height:16px;" />}
        <Dropdown options={{ stretch: true }} isOpen={isOpen}>
          {props.options.map((option) => {
            return (
              <Dropdown.Item
                key={option.key}
                title={option.text}
                Icon={option.icon}
                isCompact
                onClick={() => props.onOptionClick(option)}
              />
            )
          })}
        </Dropdown>
      </StyledTextActiveTrigger>
    </>
  )
}

const StyledTextActiveTrigger = styled.div<{ isOpen: boolean }>`
  ${({ theme, isOpen }) => css`
    position: relative;
    cursor: pointer;
    padding: 4px 12px;
    border-radius: ${theme.borderRadius.default};
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${theme.colors.background[3]};
    &:hover {
      background: ${theme.colors.background[4]};
    }
    ${isOpen &&
    css`
      background: ${theme.colors.background[4]};
    `}
  `}
`
