import * as queryString from 'query-string'
import { useEffect } from 'react'
import { FullStoryAPI } from 'react-fullstory'
import { connect } from 'react-redux'
import { firestoreConnect, isLoaded } from 'react-redux-firebase'
import {
  Navigate,
  Outlet,
  useLocation,
  useMatch,
  useParams,
} from 'react-router'
import { compose } from 'redux'

import Loading from 'components/Loading'
import MultiSelectActionbar from 'components/multiselect/MultiSelectActionbar'
import { SearchContextProvider } from 'components/search/SearchContext'
import Uploader from 'components/upload/Uploader'
import { UploadSessionsRemoteUploadProvider } from 'components/upload/useUploadSessionsRemoteUpload'
import useWorkspacePermissions from 'hooks/useWorkspacePermissions'
import { workspacesSelector } from 'store/selectors'

import { db } from '../../config/firebase'
import {
  setActiveWorkspace,
  setActiveWorkspaceByUrl,
} from '../../store/content/actions'
import CommandPaletteContainer from '../commandPalette/CommandPaletteContainer'
import { CreateAssetsModal } from '../createAssets/CreateAssetsPanel'
import Downloads from '../downloads/Downloads'
import Settings from '../settings/Settings'
import Upgrade from '../upgrade/Upgrade'
import UploadSessionList from '../uploadsListing/UploadSessionList'
import { Main } from './WorkspaceStyles'

//
// FIXME: Refactor this component + convert to typescript!
//

document.body.style.backgroundColor = 'rgb(12,12,12)'
document.body.style.color = 'rgba(0,0,0,1)'

const Workspace = (props) => {
  const location = useLocation()
  const params = useParams()
  const inSetupUploadView = !!useMatch('/:workspaceUrl/upload')
  const inVideoFrameExtractorView = !!useMatch('/:workspaceUrl/frame-extractor')
  const userAllowedTo = useWorkspacePermissions(['UPLOAD'])

  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'PRODUCTION') {
      FullStoryAPI('identify', props.uid, {
        displayName: props.displayName,
        email: props.email,
      })
    }
  }, [props.displayName, props.email, props.uid])

  useEffect(() => {
    // Add workspaceId as general property on all tracking calls. TO DO - consolidate where trackingContext is set

    window.trackingContext = {
      ...window.trackingContext,
      workspaceId: props.activeWorkspace?.id,
    }
  }, [props.activeWorkspace?.id])

  // This is a use-case when signing up with google login
  if (!props.hasDBUser && props.currentUserIsLoaded) {
    console.log('redirecting to init', props)
    return <Navigate replace to={`/init${location.search}`} />
  }

  // If any onboarding parts are unfinished ...
  if (
    props.onboarding &&
    // ignore completedUploads as the upload setup was moved to workspace
    Object.values({ ...props.onboarding, completedUploads: undefined }).some(
      (onboardingPart) => onboardingPart === false
    ) &&
    !props.requestingPrivate
  ) {
    // And we're not just waiting for a request
    // Redirect to setup
    return <Navigate replace to={`/setup${location.search}`} />
  }

  const workspaceSpecifiedInUrl =
    params.workspaceUrl &&
    !['app', 'workspaces', 'init'].includes(params.workspaceUrl)

  // if we have no available workspaces we redirect to workspace setup
  if (
    props.onboarding &&
    !props.requestingWorkspaces &&
    !props.activeWorkspace &&
    !workspaceSpecifiedInUrl &&
    props.workspaces &&
    Object.values(props.workspaces).length === 0
  ) {
    const searchParams = location.search
      ? `${location.search}&new=true`
      : '?new=true'
    console.log('user has no workspace, redirecting to workspace setup', props)
    return <Navigate replace to={`/setup/workspace${searchParams}`} />
  }

  // Wait for workspaces to get populated
  if (!props.workspaces || Object.values(props.workspaces).length === 0) {
    return <Loading fullscreen />
  }

  const { redirectUrl } = queryString.parse(location.search)
  // if we have a redirect url we redirect to that url. If user are on the way to Upload Setup we ignore this.
  if (redirectUrl && !location.pathname.includes('/upload')) {
    const url = new URL(redirectUrl)
    return <Navigate replace to={`${url.pathname}${url.search}`} />
  }

  const workspaceFromUrl = (url) => {
    const id = props.workspaces[url]
      ? url
      : Object.keys(props.workspaces).filter(
          (id_) => props.workspaces[id_].url === params.workspaceUrl
        )[0]
    const workspace = id ? { ...props.workspaces[id], ...{ id } } : null
    return workspace
  }

  // If we should redirect, do it right away (to avoid infinite update loops)

  // If there are no workspaces with access ...
  if (
    props.onboarding &&
    props.workspaces &&
    Object.keys(props.workspaces).length === 0 &&
    !props.requestingWorkspaces
  ) {
    // ... reset onboarding
    db.collection('users')
      .doc(props.uid)
      .collection('private')
      .doc('onboarding')
      .set({ completedWorkspace: false }, { merge: true })
  }

  // If we can't find an active workspace, redirect to a real workspace
  if (
    props.workspaces &&
    !props.requestingPrivate &&
    !props.requestingWorkspaces &&
    (params.workspaceUrl === 'app' || params.workspaceUrl === 'workspaces')
  ) {
    const redirectTo =
      // If there's a last active workspace...
      props.lastActiveWorkspace &&
      // ..and user still has access to it...
      Object.keys(props.workspaces).some(
        (workspaceId) => workspaceId === props.lastActiveWorkspace
      )
        ? // ...redirect to that one.
          props.lastActiveWorkspace
        : // ...otherwise, redirect to first available workspace.
          Object.values(props.workspaces)[0].url
    const replaceWorkspaces = location.pathname
      .split('workspaces')
      .join(redirectTo)

    const prefix = '/app'
    const newPathname = replaceWorkspaces.startsWith(prefix)
      ? replaceWorkspaces.replace(prefix, `/${redirectTo}`)
      : replaceWorkspaces

    const search = location.search
    return <Navigate replace to={newPathname + search} />
  }

  // If workspaceUrl is in fact a workspace id, get the url and redirect
  if (props.workspaces?.[params.workspaceUrl]) {
    const newPath =
      location.pathname
        .split(params.workspaceUrl)
        .join(props.workspaces[params.workspaceUrl].url) + location.search
    console.log('Redirecting from id', newPath)
    return <Navigate replace to={newPath} />
  }

  // Init or change active workspace
  if (
    props.workspaces &&
    !props.requestingWorkspaces &&
    (!props.activeWorkspace ||
      params.workspaceUrl !== props.activeWorkspace.url)
  ) {
    // Get the workspace from current url
    const workspace = workspaceFromUrl(params.workspaceUrl)

    // If url is invalid redirect to root
    if (!workspace) {
      return <Navigate replace to="/" />
    }

    // // Update redux
    props.setActiveWorkspace(workspace)

    // Update users lastActiveWorkspace in DB
    const ref = `users/${props.uid}/private/usage`
    props.firestore.set(
      ref,
      {
        lastActiveWorkspace: workspace.id,
        updatedAt: props.firestore.FieldValue.serverTimestamp(),
      },
      { merge: true }
    )
  }

  if (!props.activeWorkspace) {
    return <Loading fullscreen />
  }

  const shouldIgnoreGlobalUploader =
    !userAllowedTo.UPLOAD || inVideoFrameExtractorView || inSetupUploadView

  // Setup Upload and Video Frame Extractor have its own custom uploader
  const UploadWrapper = shouldIgnoreGlobalUploader ? 'div' : Uploader

  return (
    <>
      <UploadSessionsRemoteUploadProvider>
        <UploadSessionList hidden={inSetupUploadView} />
        <Main>
          <SearchContextProvider>
            <UploadWrapper>
              <Outlet />
            </UploadWrapper>
            <Downloads />
            <CommandPaletteContainer />
            <Settings />
            <Upgrade />
            <CreateAssetsModal />
            <MultiSelectActionbar />
          </SearchContextProvider>
        </Main>
      </UploadSessionsRemoteUploadProvider>
    </>
  )
}

const mapStateToProps = (state) => {
  // Check if firestore has a request out
  // Identifier is the query or part of the query, ie. workspaces?where=admins:array-contains:olof@kive/ai
  const isRequesting = (identifier) =>
    Object.keys(state.firestore.status.requesting)
      .filter((key) => key.indexOf(identifier) !== -1)
      .filter((key) => state.firestore.status.requesting[key]).length > 0

  const requestingPrivate = isRequesting('userPrivate')
  const requestingWorkspaces = [
    isRequesting('adminWorkspaces'),
    isRequesting('memberWorkspaces'),
    isRequesting('guestWorkspaces'),
  ].some(Boolean)

  const currentUser = state.firestore.data.users?.[state.firebase.auth.uid]
  return {
    requestingPrivate,
    requestingWorkspaces,
    userOnboardingIsLoaded: isLoaded(
      state.firestore.data.userPrivate?.onboarding
    ),
    onboarding: state.firestore.data.userPrivate?.onboarding,
    lastActiveWorkspace:
      state.firestore.data.userPrivate?.usage?.lastActiveWorkspace,
    uid: state.firebase.auth.uid,
    displayName: state.firebase.auth.displayName,
    email: state.firebase.auth.email,
    workspaces: workspacesSelector(state),
    activeWorkspace: state.content.activeWorkspace,
    siderSplitValue: state.ui.siderSplitValue,
    hasDBUser: Boolean(currentUser),
    currentUserIsLoaded: isLoaded(currentUser),
  }
}

const mapDispatchToProps = (dispatch) => ({
  setActiveWorkspaceByUrl: (workspaces, url) => {
    dispatch(setActiveWorkspaceByUrl(workspaces, url))
  },
  setActiveWorkspace: (workspace) => {
    dispatch(setActiveWorkspace(workspace))
  },
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    if (!props.activeWorkspace) {
      return []
    }
    const queries = [
      {
        collection: 'workspaces',
        doc: props.activeWorkspace.id,
        subcollections: [
          {
            collection: 'counters',
            doc: 'itemCounter',
          },
        ],
        storeAs: 'workspaceItemCounter',
      },
    ]

    return queries
  })
)(Workspace)
