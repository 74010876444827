import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import useToastMessages from 'components/toast/useToastMessages'
import {
  ItemReactionsQuery,
  useAddItemReactionMutation,
  useItemReactionsQuery,
  useRemoveItemReactionMutation,
} from 'generated/graphql'
import { useCurrentUserOrGuest } from 'hooks/useCurrentUserOrGuest'
import { Guest } from 'hooks/useGuest'
import { DBUser } from 'types/db'

import ItemReactionsBox from './ItemReactionsBox'
import {
  onAddItemReactionCacheUpdate,
  onRemoveItemReactionCacheUpdate,
} from './itemReactionsCacheUpdates'

type ItemReactionsPayload = ItemReactionsQuery['itemReactions']
type ItemReactionAuthor = ItemReactionsPayload[0]['itemReactions'][0]['author']

const getOptimisticAuthor = (
  userOrGuest: DBUser | Guest
): ItemReactionAuthor => {
  if ('email' in userOrGuest) {
    return {
      __typename: 'User',
      id: userOrGuest.uid,
      displayName: userOrGuest.displayName,
    }
  }
  return {
    __typename: 'Guest',
    id: userOrGuest.uid,
    displayName: userOrGuest.displayName ?? '',
  }
}

interface ItemReactionsProps {
  parentId: string // item id or board item id
  boardId: string | null
  pollInterval?: number
  isTransparent?: boolean
  className?: string
  defaultHideUnsetReactions?: boolean
  isSmall?: boolean
  withHideUnsetReactionsToggle?: boolean
}

const ItemReactions: React.FC<ItemReactionsProps> = ({
  parentId,
  boardId,
  pollInterval,
  isTransparent,
  defaultHideUnsetReactions,
  withHideUnsetReactionsToggle,
  className,
  isSmall,
}) => {
  const { reportError } = useToastMessages()

  const currentUserOrGuest = useCurrentUserOrGuest()
  const [hideUnsetReactions, setHideUnsetReactions] = useState(
    Boolean(defaultHideUnsetReactions)
  )

  const [addItemReactionMutation] = useAddItemReactionMutation()
  const [removeItemReactionMutation] = useRemoveItemReactionMutation()

  const { data } = useItemReactionsQuery({
    variables: {
      parentId,
      boardId,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    pollInterval: pollInterval || 10000,
    context: {
      batch: true,
    },
  })

  const itemReactions = data?.itemReactions

  const handleToggleReaction = (reaction: string) => {
    const currentItemReactionGroup = itemReactions?.find(
      (iReaction) => iReaction.reaction === reaction
    )
    const currentUserOrGuestItemReaction =
      currentItemReactionGroup?.itemReactions.find(
        ({ author }) => author?.id === currentUserOrGuest.uid
      )

    // to prevent removal of reaction before it is fully inserted
    if (currentUserOrGuestItemReaction?.id.startsWith('optimistic:')) return

    if (currentUserOrGuestItemReaction) {
      return removeItemReactionMutation({
        variables: {
          itemReactionId: currentUserOrGuestItemReaction.id,
          boardId,
        },
        update: (cache) => {
          onRemoveItemReactionCacheUpdate(
            cache,
            currentUserOrGuestItemReaction.id,
            currentItemReactionGroup
          )
        },
        optimisticResponse: {
          removeItemReaction: {
            __typename: 'RequestStatus',
            status: 'SUCCESS',
          },
        },
        onError: () => {
          reportError(`Oh, we couldn't remove the reaction. Please try again.`)
        },
      })
    }

    return addItemReactionMutation({
      variables: {
        reaction,
        parentId,
        boardId,
      },
      update: (cache, payload) => {
        onAddItemReactionCacheUpdate(cache, payload, parentId)
      },
      optimisticResponse: {
        addItemReaction: {
          __typename: 'ItemReaction',
          id: `optimistic:${uuidv4()}`,
          reaction,
          createdAt: new Date().toISOString(),
          author: getOptimisticAuthor(currentUserOrGuest),
        },
      },
      onError: (error) => {
        const isForbidden =
          error.graphQLErrors?.[0]?.extensions?.code === 'FORBIDDEN'
        if (isForbidden) {
          return reportError(`Oh, you are not allowed to react to this item.`)
        }
        return reportError(
          `Oh, we couldn't add the reaction. Please try again.`
        )
      },
    })
  }

  const handleToggleHideUnsetReactions = () => {
    setHideUnsetReactions((prev) => !prev)
  }

  return (
    <div css="display:flex">
      <ItemReactionsBox
        reactions={itemReactions}
        handleToggleReaction={handleToggleReaction}
        isTransparent={isTransparent}
        className={className}
        hideUnsetReactions={hideUnsetReactions}
        onToggleHideUnsetReactions={handleToggleHideUnsetReactions}
        isSmall={isSmall}
        withHideUnsetReactionsToggle={withHideUnsetReactionsToggle}
      />
    </div>
  )
}

export default ItemReactions
