import FileSaver from 'file-saver'

import callCloudFunction from 'helpers/callCloudFunction'

interface DownloadAssetsInput {
  itemIds?: string[]
  boardId?: string
  uploadSessionId?: string
  useTemplates?: string[]
  settings?: {
    width: number
    height: number
    colorsWidth?: number
    gutterSize?: number
    includeFooter?: boolean
    limit?: number
  }
}

export async function downloadSocialAssets({
  itemIds,
  boardId,
  uploadSessionId,
  useTemplates = ['PALETTE_STILL'],
  settings = { width: 1080, height: 1920 },
}: DownloadAssetsInput) {
  const { signedUrls } = await callCloudFunction('generateExports', {
    boardId,
    uploadSessionId,
    itemIds,
    useTemplates,
    settings,
  })

  await Promise.all(
    signedUrls.map(async (signedUrl: string) => {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        xhr.responseType = 'blob'
        xhr.onload = () => {
          const blob = xhr.response
          const fileName = signedUrl?.split('/').pop()?.split('?')?.[0]
          FileSaver.saveAs(blob, fileName)
          resolve(signedUrl)
        }
        xhr.onerror = (err) => {
          reject(err)
        }
        xhr.open('GET', signedUrl)
        xhr.send()
      })
    })
  )
}
