import { getTypesenseThumbs } from 'helpers/getThumbs'
import { TItem } from 'types/typesense'

import type { ItemData } from '../useGetItemData'

export const convertTypesenseItemToWorkspaceItem = ({
  typesenseItem,
}: {
  typesenseItem: TItem
}): ItemData => {
  return {
    __typename: 'WorkspaceItem',
    id: typesenseItem.id,
    accessLevel: typesenseItem.accessLevel,
    aspectRatio: typesenseItem.aspectRatio,
    commentCount: 0,
    createdAt: new Date(typesenseItem.createdAt * 1000).toISOString(),
    colors: [],
    createdBy: null,
    itemSourceInfo: {
      type: 'UPLOAD',
      videoExtractInfo: null,
      extensionImportInfo: null,
    },
    mediaType: typesenseItem.originalType === 'gif' ? 'GIF' : 'IMAGE',
    openCount: 0,
    mediaVariants: [
      ...getThumbMediaVariants({ typesenseItem }),
      {
        url: typesenseItem.imgUrl,
        type: 'jpg',
        variant: 'large',
      },
      {
        url: typesenseItem.imgUrl, // this is wrong, as we do not have any large gifs in typesense this is a temp solution
        type: 'gif',
        variant: 'large',
      },
    ],
    original: {
      path: '',
      info: {
        type: typesenseItem.originalType === 'gif' ? 'gif' : 'jpg',
      },
    },
    tags: [],
    title: typesenseItem.originName ?? '',
    uploadSessionId: typesenseItem.uploadSessionId,
    userRole: 'READER',
    workspaceId: typesenseItem.workspaceId,
    webUrl: '',
    updatedAt: '',
  }
}

const getThumbMediaVariants = ({ typesenseItem }: { typesenseItem: TItem }) => {
  if (typesenseItem.thumbs) {
    return (
      getTypesenseThumbs(typesenseItem.thumbs || [])?.map((h) => ({
        url: h.url,
        type: h.type,
        variant: 'thumb',
      })) ?? []
    )
  }
  return [
    {
      url: typesenseItem.thumbUrl || '',
      type: 'jpg',
      variant: 'thumb',
    },
  ]
}
