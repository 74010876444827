/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { WorkspaceItemGridFieldsFragment } from 'generated/graphql'
import { trackMultiSelectModeActivated } from 'helpers/tracking'
import { ItemRefWithId } from 'types/custom'
import { TItem } from 'types/typesense'

type SelectedItem = WorkspaceItemGridFieldsFragment | ItemRefWithId | TItem
export type FetchingState = 'IDLE' | 'START' | 'LOADING' | 'DONE'
export interface MultiSelectState {
  selectedItems: Record<string, SelectedItem>
  isSaveToBoardMenuVisible: boolean
  isDeleteConfirmModalVisible: boolean
  isAddTagMenuVisible: boolean
  saveToBoardStatus: FetchingState
  downloadStatus: FetchingState
  deleteStatus: FetchingState
  addTagStatus: FetchingState
}

// Define the initial state using that type
const initialState: MultiSelectState = {
  selectedItems: {},
  isSaveToBoardMenuVisible: false,
  isDeleteConfirmModalVisible: false,
  isAddTagMenuVisible: false,
  saveToBoardStatus: 'IDLE',
  downloadStatus: 'IDLE',
  deleteStatus: 'IDLE',
  addTagStatus: 'IDLE',
}

const multiSelectSlice = createSlice({
  name: 'multiSelect',
  initialState,
  reducers: {
    selectItems: (
      state,
      { payload }: PayloadAction<{ id: string; item: SelectedItem }[]>
    ) => {
      if (Object.keys(state.selectedItems).length === 0) {
        trackMultiSelectModeActivated()
      }
      payload.forEach((itemPayload) => {
        if (!state.selectedItems[itemPayload.id]) {
          state.selectedItems[itemPayload.id] = itemPayload.item
        }
      })
    },
    unselectItems: (
      state,
      { payload }: PayloadAction<{ id: string; item: SelectedItem }[]>
    ) => {
      payload.forEach((itemPayload) => {
        if (state.selectedItems[itemPayload.id]) {
          delete state.selectedItems[itemPayload.id]
        }
      })
    },
    selectItem: (
      state,
      { payload }: PayloadAction<{ id: string; item: SelectedItem }>
    ) => {
      if (Object.keys(state.selectedItems).length === 0) {
        trackMultiSelectModeActivated()
      }
      if (!state.selectedItems[payload.id]) {
        state.selectedItems[payload.id] = payload.item
      }
    },
    unselectItem: (
      state,
      { payload }: PayloadAction<{ id: string; item: SelectedItem }>
    ) => {
      if (state.selectedItems[payload.id]) {
        delete state.selectedItems[payload.id]
      }
    },
    toggleItem: (
      state,
      { payload }: PayloadAction<{ id: string; item: SelectedItem }>
    ) => {
      if (Object.keys(state.selectedItems).length === 0) {
        trackMultiSelectModeActivated()
      }
      if (state.selectedItems[payload.id]) {
        delete state.selectedItems[payload.id]
      } else {
        state.selectedItems[payload.id] = payload.item
      }
    },
    reset: (state) => {
      state.selectedItems = {}

      state.isSaveToBoardMenuVisible = false

      state.saveToBoardStatus = 'IDLE'
      state.downloadStatus = 'IDLE'
      state.deleteStatus = 'IDLE'
      state.addTagStatus = 'IDLE'
    },

    setIsSaveToBoardMenuVisible: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isSaveToBoardMenuVisible = payload
    },
    setIsDeleteConfirmModalVisible: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isDeleteConfirmModalVisible = payload
    },
    setIsAddTagMenuVisible: (state, { payload }: PayloadAction<boolean>) => {
      state.isAddTagMenuVisible = payload
    },

    setSaveToBoardStatus: (
      state,
      { payload }: PayloadAction<FetchingState>
    ) => {
      state.saveToBoardStatus = payload
    },
    setDownloadStatus: (state, { payload }: PayloadAction<FetchingState>) => {
      state.downloadStatus = payload
    },
    setDeleteStatus: (state, { payload }: PayloadAction<FetchingState>) => {
      state.deleteStatus = payload
    },
    setAddTagStatus: (state, { payload }: PayloadAction<FetchingState>) => {
      state.addTagStatus = payload
    },
  },
})

export const {
  reset,
  selectItem,
  unselectItem,
  toggleItem,
  selectItems,
  setIsSaveToBoardMenuVisible,
  setSaveToBoardStatus,
  setDownloadStatus,
  setDeleteStatus,
  setIsDeleteConfirmModalVisible,
  setAddTagStatus,
  setIsAddTagMenuVisible,
} = multiSelectSlice.actions

export default multiSelectSlice.reducer
