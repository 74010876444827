import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled, { DefaultTheme, css } from 'styled-components'

import { Margin } from 'components/common/Margin'
import { Text } from 'components/common/Text'
import { TextSelect, TextSelectOption } from 'components/common/TextSelect'
import { trackDiscoverModeChanged } from 'helpers/tracking/tracking'
import useIsMobile from 'hooks/useIsMobile'

import { LearnMoreButton } from './LearnMoreButton'

const options: TextSelectOption[] = [
  {
    key: 'FOR_YOU',
    text: 'For you',
    link: 'for-you',
  },
  {
    key: 'NEW',
    text: 'New',
    link: 'new',
  },
]

const StyledDiscoverInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 56px;
  justify-content: space-between;
  top: 0;
  position: sticky;
  z-index: 1;
  background: ${({ theme }) => theme.colors.background[0]};
`

export const SubPageHeader: React.FunctionComponent<{
  title: string
  option: 'NEW' | 'FOR_YOU'
  trackingSessionId: string
  children?: React.ReactNode
}> = ({ title, option, trackingSessionId, children }) => {
  const navigate = useNavigate()
  const isMobile = useIsMobile()
  const { workspaceUrl } = useParams() as { workspaceUrl: string }

  const getOption = (modeKey: string): TextSelectOption => {
    return options.find((o) => o.key === modeKey)!
  }
  const discoveryMode = getOption(option)

  const handleDiscoveryModeChange = (o: TextSelectOption) => {
    trackDiscoverModeChanged({
      trackingSessionId,
      discoveryMode: o.key,
    })
    navigate(`/${workspaceUrl}/discover/${o.link}`)
  }

  return (
    <StyledDiscoverInfoWrapper>
      {!isMobile && (
        <div css="display:flex;align-items:center;">
          <Text size="base" color="neutral.0" bold>
            {title}
          </Text>
          <Margin x={12} />
          <LearnMoreButton guideId="discover" size="large" />
        </div>
      )}

      <div css="display:flex;align-items:center;">
        <TextSelect
          css={`
            ${({ theme }: { theme: DefaultTheme }) =>
              css`
                color: ${theme.colors.text.neutral[0]};
                width: 140px;
                height: 32px;
                user-select: none;
              `}
          `}
          options={options}
          activeOption={discoveryMode}
          onOptionClick={handleDiscoveryModeChange}
        />
        {children}
      </div>
    </StyledDiscoverInfoWrapper>
  )
}
