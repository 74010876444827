import React, { useRef } from 'react'
import Skeleton from 'react-loading-skeleton'
import styled, { css } from 'styled-components'

import Spinner from 'components/common/Spinner'
import { Tooltip } from 'components/common/Tooltip/Tooltip'
import UserAvatar from 'components/common/UserAvatar'
import useToastMessages from 'components/toast/useToastMessages'
import { useUploadProfilePictureMutation } from 'generated/graphql'

const MAX_FILE_SIZE = 10000000 // bytes

type ProfileAvatarProps = {
  profilePhotoUrl?: string | null
  profileDisplayName?: string | null
  isLoadingUser: boolean
  isEditable: boolean
}

export const ProfileAvatar = (props: ProfileAvatarProps) => {
  const thumbnailInputRef = useRef<HTMLInputElement>(null)
  const { reportError } = useToastMessages()

  const [uploadProfilePicture, { loading }] = useUploadProfilePictureMutation()

  const handleEditProfileThumbnail = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0]
    if (!file) return
    if (file.size > MAX_FILE_SIZE) {
      return reportError(
        `Please select an image smaller than ${MAX_FILE_SIZE / 1000000} mb`
      )
    }
    uploadProfilePicture({
      variables: {
        file,
      },
    })
  }

  if (props.isLoadingUser) {
    return <Skeleton circle style={{ height: 100, width: 100 }} />
  }
  return (
    <>
      <Tooltip
        isDisabled={!props.isEditable}
        title="Edit thumbnail"
        position="bottom"
        style={{ display: 'inline-block' }}
      >
        <UserAvatarWrapper
          isClickable={props.isEditable}
          onClick={
            props.isEditable
              ? () => thumbnailInputRef.current?.click()
              : undefined
          }
        >
          {loading && <Spinner css="position:absolute;z-index:1" size={28} />}
          <UserAvatar
            displayName={props.profileDisplayName ?? ''}
            photoUrl={props.profilePhotoUrl}
            size={128}
            css={`
              object-fit: cover;
              filter: ${loading ? 'blur(2px) brightness(0.7)' : 'none'};
            `}
          />
        </UserAvatarWrapper>
      </Tooltip>
      <input
        type="file"
        accept="image/*"
        ref={thumbnailInputRef}
        onChange={handleEditProfileThumbnail}
        style={{ display: 'none' }}
      />
    </>
  )
}

const UserAvatarWrapper = styled.div<{
  isClickable: boolean
}>`
  ${({ isClickable, theme }) => css`
    transition: 0.1s all ease;
    border: 6px solid ${theme.colors.background[1]};
    width: 128px;
    height: 128px;
    border-radius: ${theme.borderRadius.full};
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${theme.colors.accent[2]};
    ${isClickable &&
    css`
      cursor: pointer;
      &:hover {
        border: 6px solid ${theme.colors.accent[2]};
        background: ${theme.colors.accent[2]};
      }
    `}
  `}
`
