import { FC } from 'react'

import SwitchToggle from '../../common/SwitchToggle'
import { SubscriptionInterval } from '../types'
import {
  StyledSubscriptionIntervalPrefix,
  StyledSubscriptionIntervalPrefixNumber,
  StyledSubscriptionIntervalWrapper,
} from './ComparePlansStyles'

type Props = {
  subscriptionInterval: SubscriptionInterval
  setSubscriptionInterval: (interval: SubscriptionInterval) => void
}
export const SubscriptionIntervalToggle: FC<Props> = ({
  setSubscriptionInterval,
  subscriptionInterval,
}) => {
  return (
    <StyledSubscriptionIntervalWrapper>
      <StyledSubscriptionIntervalPrefix>
        Yearly billing{' '}
        <StyledSubscriptionIntervalPrefixNumber>
          -15%
        </StyledSubscriptionIntervalPrefixNumber>
      </StyledSubscriptionIntervalPrefix>
      <SwitchToggle
        isOn={subscriptionInterval === 'yearly'}
        onToggle={() => {
          if (subscriptionInterval === 'yearly') {
            setSubscriptionInterval('monthly')
          } else {
            setSubscriptionInterval('yearly')
          }
        }}
      />
    </StyledSubscriptionIntervalWrapper>
  )
}
