import { Camera as CameraAdd } from '@styled-icons/bootstrap/Camera'
import { motion } from 'framer-motion'
import { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import styled, { css, useTheme } from 'styled-components'
import { v4 as uuid } from 'uuid'

import { LoaderIcon } from 'components/Loading'
import Button from 'components/common/Button'
import Flex from 'components/common/Flex'
import { Field } from 'components/common/Form'
import { Spacer } from 'components/common/Spacer'
import { Text } from 'components/common/Text'
import {
  useAddWorkspaceMutation,
  useSetupUserMutation,
  useUploadProfilePictureMutation,
} from 'generated/graphql'
import { capitalizeFirstLetter } from 'helpers/formatting'

const MAX_FILE_SIZE = 10000000 // bytes

const profileImageAnimationStates = {
  isHover: { opacity: 1, transition: { duration: 0.1 } },
  isNotHover: { opacity: 0, transition: { duration: 0.1 } },
}

const StyledProfileImage = styled.div<{ imageUrl?: string }>`
  ${({ theme, imageUrl }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 16px;
    background-color: ${theme.colors.background};
    background-size: cover;
    background-image: url(${imageUrl});
    cursor: pointer;
    border: ${`${theme.border.thin} ${theme.colors.background[5]}`};
    border-style: ${imageUrl ? 'solid' : 'dashed'};
    &:hover {
      border: ${`${theme.border.thin} ${theme.colors.accent[2]}`};
    }
  `}
`

const StyledProfileImageOverlay = styled(motion.div)`
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgb(14, 14, 14, 0.5);
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ErrorMessage = styled.div(({ theme }) => ({
  fontSize: 13,
  fontWeight: theme.fontWeight.medium,
  color: '#f33',
  padding: 16,
}))

type SetupUserProps = {
  onDone: () => void
}

export const SetupUser = ({ onDone }: SetupUserProps) => {
  const theme = useTheme()
  const inputRef = useRef<any>()
  const [imageUrl, setImageUrl] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const [profileImageHover, setProfileImageHover] = useState(false)
  const [timesReadPrivacyPolicy, setTimesReadPrivacyPolicy] = useState(0)
  const [timesReadTermsOfService, setTimesReadTermsOfService] = useState(0)

  const [uploadProfilePicture, { loading: uploadProfilePicureIsLoading }] =
    useUploadProfilePictureMutation()

  const [setupUserMutation] = useSetupUserMutation()
  const [addWorkspaceMutation] = useAddWorkspaceMutation()

  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
    },
  })

  const onFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file) return

    if (file.size > MAX_FILE_SIZE) {
      return reportError(
        `Please select an image smaller than ${MAX_FILE_SIZE / 1000000} mb`
      )
    }
    try {
      const user = await uploadProfilePicture({
        variables: {
          file,
        },
      })
      const imgUrl = user.data?.uploadProfilePicture.photoURL || ''
      setImageUrl(imgUrl)
    } catch (error) {
      setErrorMessage(error)
    }
  }

  const onSubmit = async (values: any, e: any) => {
    e.preventDefault()
    setIsLoading(true)

    const formErrors = []

    if (!values.firstName.length) {
      formErrors.push({
        field: 'firstName',
        error: { message: 'Enter first name' },
      })
    }

    if (!values.lastName.length) {
      formErrors.push({
        field: 'lastName',
        error: { message: 'Enter last name' },
      })
    }

    if (formErrors.length) {
      formErrors.map(({ field, error }) =>
        setError(field as 'firstName' | 'lastName', error)
      )
      return setIsLoading(false)
    }

    const firstName = values.firstName.trim()
    const lastName = values.lastName.trim()
    try {
      const user = await setupUserMutation({
        variables: {
          firstName,
          lastName,
          jobTitle: '',
          timesReadPrivacyPolicy,
          timesReadTermsOfService,
        },
      })

      const displayName =
        user.data?.setupUser.displayName || `${firstName} ${lastName}`
      const workspaceTitle = `${capitalizeFirstLetter(displayName)} Workspace`
      const workspaceUrl = `${displayName
        .replace(' ', '-')
        .replace(/[^\w\s]/gi, '')}-${uuid()}`
      await addWorkspaceMutation({
        variables: { title: workspaceTitle, url: workspaceUrl },
      })
      onDone()
    } catch (error) {
      setErrorMessage(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Flex justifyContent="center">
        <Text size="2xl" bold color="neutral.0">
          Profile setup
        </Text>
      </Flex>
      <Spacer factor={6} />

      <input
        type="file"
        ref={inputRef}
        onChange={onFileSelect}
        style={{ display: 'none' }}
      />

      <Flex justifyContent="center">
        <StyledProfileImage
          imageUrl={imageUrl}
          onClick={() => inputRef.current?.click()}
          onMouseEnter={() => setProfileImageHover(true)}
          onMouseLeave={() => setProfileImageHover(false)}
        >
          <StyledProfileImageOverlay
            variants={profileImageAnimationStates}
            animate={
              !imageUrl || (profileImageHover && !uploadProfilePicureIsLoading)
                ? 'isHover'
                : 'isNotHover'
            }
          >
            <CameraAdd
              size={40}
              style={{
                color: profileImageHover
                  ? theme.colors.gray.light[5]
                  : theme.colors.gray.light[0],
              }}
            />
          </StyledProfileImageOverlay>
          {uploadProfilePicureIsLoading && (
            <StyledProfileImageOverlay>
              <div style={{ height: 50, width: 50, color: '#ffffffd5' }}>
                <LoaderIcon />
              </div>
            </StyledProfileImageOverlay>
          )}
        </StyledProfileImage>
      </Flex>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex justifyContent="center">
          <Field
            name="firstName"
            register={register}
            placeholder="First name"
            type="text"
            centered={false}
            errors={errors}
            required
          />
          <Spacer factor={2} axis="x" />

          <Field
            name="lastName"
            register={register}
            placeholder="Last name"
            type="text"
            centered={false}
            errors={errors}
            required
          />
        </Flex>

        <Flex justifyContent="center">
          <Text size="sm" color="neutral.2">
            Please take a moment to read our{' '}
            <a
              href="/privacy"
              target="_blank"
              rel="noreferrer"
              style={{ color: theme.colors.text.neutral[0] }}
              onClick={() =>
                setTimesReadPrivacyPolicy((previousCount) => previousCount + 1)
              }
            >
              Privacy Policy
            </a>{' '}
            and{' '}
            <a
              href="/terms"
              target="_blank"
              rel="noreferrer"
              style={{ color: theme.colors.text.neutral[0] }}
              onClick={() =>
                setTimesReadTermsOfService((previousCount) => previousCount + 1)
              }
            >
              Terms of Service
            </a>
          </Text>
        </Flex>
        <Spacer factor={2} />
        <Flex justifyContent="end">
          <Button
            variant="primary"
            disabled={false}
            isLoading={isLoading}
            type="submit"
            fullWidth
          >
            Agree and finish
          </Button>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </Flex>
      </form>
    </>
  )
}
