import React from 'react'
import styled from 'styled-components'

import Flex from 'components/common/Flex'
import { Margin } from 'components/common/Margin'
import useIsMobile from 'hooks/useIsMobile'

import ItemUserInfo from './ItemUserInfo'
import { ItemData } from './hooks/useGetItemData'
import ItemComments from './itemComments/ItemComments'
import ItemReactions from './itemReactions/ItemReactions'

const InfoContainer = styled.div`
  padding: 16px 16px;
  cursor: default;
`

const Desktop = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  min-height: 44px;
`

interface ItemInfoProps {
  item: ItemData
}

const ItemInfo: React.FC<ItemInfoProps> = ({ item }) => {
  const boardId = 'boardId' in item ? item.boardId : null
  const isMobile = useIsMobile()

  return (
    <InfoContainer onClick={(e) => e.stopPropagation()}>
      {isMobile && (
        <>
          <ItemReactions parentId={item.id} boardId={boardId} />
          <Margin y={12} />
          <ItemUserInfo
            createdByUser={item.createdBy}
            createdAt={item.createdAt}
            uploadSessionId={item.uploadSessionId}
            workspaceId={item.workspaceId}
            itemSourceInfo={item.itemSourceInfo}
          />
          <ItemComments
            itemId={item.id}
            boardId={boardId}
            createdById={item.createdBy?.id || ''}
            totalCommentCount={item.commentCount}
          />
        </>
      )}
      {!isMobile && (
        <Desktop>
          <Flex flexDirection="column">
            <ItemReactions parentId={item.id} boardId={boardId} />
            <ItemComments
              itemId={item.id}
              boardId={boardId}
              createdById={item.createdBy?.id || ''}
              totalCommentCount={item.commentCount}
            />
          </Flex>
          <ItemUserInfo
            createdByUser={item.createdBy}
            createdAt={item.createdAt}
            uploadSessionId={item.uploadSessionId}
            workspaceId={item.workspaceId}
            itemSourceInfo={item.itemSourceInfo}
          />
        </Desktop>
      )}
    </InfoContainer>
  )
}

export default ItemInfo
