import { memo } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { UploadEventPreviewCard } from 'components/upload-event-preview-card/UploadEventPreviewCard'

const getTitle = (
  title: string,
  isUploading?: boolean,
  isAnalyzing?: boolean,
  isExtractingFrames?: boolean,
  titleOverride?: string
) => {
  if (isExtractingFrames) return 'Extracting from video ...'
  if (isUploading) return 'Uploading ...'
  if (isAnalyzing) return 'Analyzing ...'
  return titleOverride ?? title
}

const getInfoText = (
  tagCount: number,
  isUploading?: boolean,
  isAnalyzing?: boolean,
  isExtractingFrames?: boolean,
  progressFiles?: string
) => {
  if (isExtractingFrames) return ''
  if (isUploading) return progressFiles
  if (isAnalyzing) return 'Adding magic tags'
  return `${tagCount} magic pins`
}

interface UploadSessionCardProps {
  id: string
  title: string
  titleOverride?: string
  createdAt?: number
  hasFinishedAnalyzing: boolean
  hasFinishedUploading: boolean
  isCreatingMagicBoards: boolean
  remoteUpload?: { isExtractingFrames?: boolean }
  cover: any
  size: number
  tagCount: number
  createdBy: string
  uploadProgressPercentage?: number
  uploadProgressFiles?: string
}

function UploadSessionCard(props: UploadSessionCardProps) {
  const isExtractingFrames = Boolean(props.remoteUpload?.isExtractingFrames)
  const isUploading = !isExtractingFrames && !props.hasFinishedUploading
  const isAnalyzing =
    (props.isCreatingMagicBoards || !props.hasFinishedAnalyzing) && !isUploading
  const isProcessing = isAnalyzing || isUploading || isExtractingFrames

  const title = getTitle(
    props.title,
    isUploading,
    isAnalyzing,
    isExtractingFrames,
    props.titleOverride
  )

  const progressPercentage =
    isUploading && typeof props.uploadProgressPercentage === 'number'
      ? `${props.uploadProgressPercentage}%`
      : ''

  const progressFiles =
    isUploading && props.uploadProgressFiles ? props.uploadProgressFiles : ''

  const infoTextOne = isUploading ? progressPercentage : `${props.size} uploads`

  return (
    <Link
      style={{ pointerEvents: isProcessing ? 'none' : 'auto' }}
      to={props.id}
    >
      <StyledCardSizing>
        <UploadEventPreviewCard
          title={title}
          createdBy={props.createdBy}
          createdAt={props.createdAt}
          infoTextOne={isAnalyzing ? undefined : infoTextOne}
          infoTextTwo={getInfoText(
            props.tagCount,
            isUploading,
            isAnalyzing,
            isExtractingFrames,
            progressFiles
          )}
          isCreatingMagicBoards={props.isCreatingMagicBoards}
          thumbUrl={props.cover?.thumbUrl ?? ''}
          isProcessing={isProcessing}
        />
      </StyledCardSizing>
    </Link>
  )
}

const StyledCardSizing = styled.div`
  display: inline-block;
  min-width: 320px;
  width: calc(20% - 24px);
  margin: 8px;
  @media (max-width: 2000px) {
    width: calc(25% - 24px);
  }
  @media (max-width: 1700px) {
    width: calc(33.3% - 24px);
  }
  @media (max-width: 1300px) {
    min-width: 220px;
    max-width: 480px;
    width: calc(50% - 24px);
  }
  @media (max-width: 600px) {
    max-width: 600;
    width: calc(100% - 24px);
  }
`

export default memo(UploadSessionCard)
