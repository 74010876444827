import {
  clearSessionPersistence,
  filesForSession,
  listSessions,
  persistUploadSession,
} from 'clientPersistence/uploadSessionsDb'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { v4 as uuid } from 'uuid'

import { db, firebase } from 'config/firebase'
import { reInitializeBoardItemsPositionsIfNeeded } from 'helpers/boardItemOperations'
import { reportError } from 'helpers/logging'
import { trackUploadSessionCreated } from 'helpers/tracking/tracking'
import { debugLog } from 'helpers/utilityFunctions'

import { createFileUploader, enableUploadPersistance } from './fileHelpers'
import type { CustomFile, UploadSession } from './types'
import { UploadOptions } from './types'

interface ExtendedUploadSession extends UploadSession {
  bytesTransferred: number
  dismiss: () => void
  id: string
}

type CustomFileWithIds = CustomFile & {
  itemId?: string | null
  id?: string
}

interface UploadSessionContextState {
  newSession: (
    droppedFiles: CustomFile[],
    boardId: string | undefined,
    workspaceId: string,
    projectId: string | undefined,
    onUploadStart: () => void,
    onUploadComplete: ({
      completedUploads,
    }: {
      completedUploads: number
    }) => void,
    uploadOptions: UploadOptions,
    uploadFlowTrackingId: string,
    tags?: { id: string; description: string }[]
  ) => void
  sessions: ExtendedUploadSession[]
  updateSession: (
    sessionId: string,
    data:
      | Partial<UploadSession>
      | ((uploadSession: UploadSession) => Partial<UploadSession>)
  ) => any
}

const initialState = {
  newSession: (
    _droppedFiles,
    _boardId,
    _workspaceId,
    _projectId,
    _onUploadStart,
    _onUploadComplete
  ) => null,
  sessions: [],
  updateSession: (sessionId, data) => null,
} as UploadSessionContextState

const UploadSessionContext = createContext(initialState)

interface Props {
  uid: string
  children: JSX.Element
}

export const UploadSessionProvider: React.FC<Props> = ({ uid, children }) => {
  const [sessions, setSessions] = useState<{
    [sessionId: string]: UploadSession
  }>({})
  // Update session state data, load dismissed status from localstorage.
  const updateSession = useCallback(
    (
      sessionId: string,
      data:
        | Partial<UploadSession>
        | ((uploadSession: UploadSession) => Partial<UploadSession>)
    ) => {
      setSessions((currentSessions) => {
        const result: Partial<UploadSession> =
          typeof data === 'function' ? data(currentSessions[sessionId]) : data

        const dismissedCacheKey = `uploadSessionDismiss.${sessionId}`
        if (result.dismissed != null) {
          localStorage.setItem(
            dismissedCacheKey,
            JSON.stringify(result.dismissed)
          )
        }

        const localStorageDismissed = localStorage.getItem(dismissedCacheKey)

        if (!result.dismissed) {
          result.dismissed = localStorageDismissed
            ? JSON.parse(localStorageDismissed)
            : null
        }

        return {
          ...currentSessions,
          [sessionId]: {
            ...currentSessions[sessionId],
            ...result,
          },
        }
      })
    },
    []
  )

  // Start uploading and tracking session reference
  const startSession = useCallback(
    async ({
      sessionRef,
      pendingFiles,
      uploadedFiles = [],
      workspaceId,
      projectId,
      boardId,
      uploadOptions,
      tags,
    }: {
      sessionRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
      pendingFiles: CustomFileWithIds[]
      uploadedFiles?: CustomFileWithIds[]
      workspaceId: string
      projectId?: string
      boardId?: string
      uploadOptions: UploadOptions
      tags?: { id: string; description: string }[]
    }) => {
      // Local functions for this session
      const uploadFile = createFileUploader(
        workspaceId,
        boardId,
        projectId,
        updateSession,
        uid,
        uploadOptions,
        tags
      )
      // Recursive batch upload of files
      const uploadBatch = async (
        files: CustomFileWithIds[],
        i: number,
        batchSize: number,
        sessionRef_: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>,
        uploadFailed = 0
      ): Promise<{ uploadFailed: number }> => {
        debugLog('Creating new batch ', i, batchSize, sessionRef_.id)

        // Get slice of files
        const batch = files.slice(i, i + batchSize)

        // Upload files in batch and wait for all to succeed/fail
        await Promise.all(
          batch.map((file) =>
            uploadFile(file, sessionRef_).catch(() => {
              // Disabling this is a temporary fix
              // eslint-disable-next-line no-param-reassign
              uploadFailed += 1
            })
          )
        )

        // Start next batch
        const nextIndex = i + batchSize
        if (files.length > nextIndex) {
          const nextBatchSize =
            files.length > nextIndex + batchSize
              ? batchSize
              : files.length - nextIndex
          return uploadBatch(
            files,
            nextIndex,
            nextBatchSize,
            sessionRef_,
            uploadFailed
          )
        }
        // If all batches are done, return
        return { uploadFailed }
      }

      const totalFileCount = pendingFiles.length + uploadedFiles.length

      // Lock this session to this tab/window by continously updating localstorage lock.
      // This prevents other tabs from aqcuring it from indexeddb and start uploading them.
      const lockInterval = setInterval(
        () =>
          localStorage.setItem(
            `uploadSessionLock.${sessionRef.id}`,
            new Date().valueOf().toString()
          ),
        2500
      )

      // Every 10 seconds, ping the Firestore doc to keep it alive
      const keepAliveInterval = setInterval(() => {
        db.collection('uploadSessions')
          .doc(sessionRef.id)
          .update({
            lastPing: firebase.firestore.FieldValue.serverTimestamp(),
          })
          .catch((err) =>
            reportError(
              new Error(`Failed to ping upload session. Msg: ${err.message}`)
            )
          )
      }, 10000)

      updateSession(sessionRef.id, {
        droppedFileCount: totalFileCount,
        uploadComplete: false,
        completedUploads: uploadedFiles.length,
        bytesTransferredByFile: uploadedFiles.reduce<{
          [itemId: string]: number
        }>((acc, file) => {
          if (!file.itemId) {
            throw new Error('updateSession: file missing itemId')
          }
          acc[file.itemId] = file.size
          return acc
        }, {}),
        totalUploadSize: [...pendingFiles, ...uploadedFiles].reduce(
          (totalSize, { size }) => totalSize + size,
          0
        ),
        hasDBSession: true,
      })

      // Upload files in batches
      const result = await uploadBatch(pendingFiles, 0, 10, sessionRef, 0)

      if (boardId) {
        reInitializeBoardItemsPositionsIfNeeded(boardId)
      }

      // When done, update uploadSession
      await sessionRef.update({
        size: firebase.firestore.FieldValue.increment(result.uploadFailed * -1),
        hasFinishedUploading: true,
        finishedUploadingAt: firebase.firestore.FieldValue.serverTimestamp(),
      })

      clearInterval(lockInterval)
      clearInterval(keepAliveInterval)

      // typeof onUploadComplete === 'function' &&
      //   onUploadComplete({ completedUploads: size })

      updateSession(sessionRef.id, { uploadComplete: true })
    },
    [uid, updateSession]
  )

  // Load DB sessions, but only acquire them if not locked in other tab, to not start multiple uploads of the same files.
  useEffect(() => {
    if (!enableUploadPersistance || !uid) {
      return
    }
    const timeout = setTimeout(
      () =>
        // Load file-sessions from db after five seconds.
        listSessions().then((sessionList) => {
          sessionList
            .filter((session) => {
              const uploadSessionLock = localStorage.getItem(
                `uploadSessionLock.${session.id}`
              )

              const previousLock = uploadSessionLock
                ? parseInt(uploadSessionLock, 10)
                : null

              // A lock exists and has not timed out.
              if (previousLock && new Date().valueOf() < previousLock + 5000) {
                return false
              }
              return true
            })
            .forEach(async (acquiredSession) => {
              // Start all sessions which loaded and are not locked.
              const sessionRef = db
                .collection('uploadSessions')
                .doc(acquiredSession.id)

              const sessionDoc = await sessionRef.get().catch(() => null) // upload session could have been deleted
              if (
                !sessionDoc ||
                !sessionDoc.exists ||
                sessionDoc.data()?.hasFinishedUploading === true
              ) {
                await clearSessionPersistence(acquiredSession.id)
                return
              }
              const sessionFiles = await filesForSession(acquiredSession.id)
              const uploadedFiles: CustomFileWithIds[] = []
              const pendingFiles: CustomFileWithIds[] = []
              sessionFiles.forEach((entry) => {
                // we must mutate original file here to make it work
                // TODO: Find a better solution that mutating the entry
                // eslint-disable-next-line no-param-reassign
                const newFile = entry.file as CustomFileWithIds
                newFile.id = entry.fileId
                // eslint-disable-next-line no-param-reassign
                newFile.itemId = entry.itemId
                if (entry.itemId != null) {
                  uploadedFiles.push(newFile)
                } else {
                  pendingFiles.push(newFile)
                }
              })
              // If there are no files pending upload for this session. Remove the files.
              if (pendingFiles.length === 0) {
                await clearSessionPersistence(acquiredSession.id)
                return
              }
              const sessionDocData = sessionDoc.data()
              if (!sessionDocData) {
                throw new Error('UploadSessionProvider: session data missing')
              }
              await startSession({
                sessionRef,
                workspaceId: sessionDocData.workspaceId,
                boardId: sessionDocData.boardId,
                projectId: sessionDocData.projectId,
                uploadedFiles,
                pendingFiles,
                uploadOptions: sessionDocData.uploadOptions,
              })
            })
        }),
      5000
    )
    return () => clearTimeout(timeout)
  }, [startSession, uid])

  const newSession = useCallback(
    async (
      droppedFiles: CustomFile[],
      boardId: string | undefined,
      workspaceId: string,
      projectId: string | undefined,
      onUploadStart: () => void,
      onUploadComplete: ({
        completedUploads,
      }: {
        completedUploads: number
      }) => void,
      uploadOptions: UploadOptions,
      uploadFlowTrackingId: string,
      tags?: { id: string; description: string }[]
    ) => {
      // Processing of session upload
      if (typeof onUploadStart === 'function') {
        onUploadStart()
      }

      // Create session ref
      const sessionRef = db.collection('uploadSessions').doc() // aka upload events

      const sessionDoc = {
        size: droppedFiles.length,
        uploaded: 0,
        analyzed: 0,
        processingFailed: 0,
        boardId: boardId || null,
        projectId: projectId || null,
        workspaceId: workspaceId || null,
        hasFinishedUploading: false,
        hasFinishedAnalyzing: false,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        createdBy: uid,
        lastPing: firebase.firestore.FieldValue.serverTimestamp(),
        uploadOptions,
      }

      await sessionRef.set(sessionDoc)

      // Segment Event –– Start Upload
      trackUploadSessionCreated({
        size: droppedFiles.length,
        boardId,
        projectId,
        workspaceId,
        uploadSessionId: sessionRef.id,
        uploadFlowId: uploadFlowTrackingId,
      })

      // Try saving session to indexedDb
      if (enableUploadPersistance) {
        droppedFiles.forEach((file) => {
          // Disabling this is a temporary fix
          // eslint-disable-next-line no-param-reassign
          file.id = uuid()
        })
        try {
          await persistUploadSession(
            sessionRef.id,
            droppedFiles,
            workspaceId,
            projectId,
            boardId
          )
        } catch (err) {
          reportError(err)
        }
      }
      await startSession({
        sessionRef,
        pendingFiles: droppedFiles,
        workspaceId,
        boardId,
        projectId,
        uploadOptions,
        tags,
      })

      if (typeof onUploadComplete === 'function') {
        onUploadComplete({ completedUploads: droppedFiles.length })
      }
    },
    [startSession, uid]
  )

  const sessionList = useMemo(
    () =>
      Object.entries(sessions).map(([id, session]) => ({
        id,
        ...session,
        bytesTransferred: Object.values(session.bytesTransferredByFile).reduce(
          (acc, fileBytes) => acc + fileBytes,
          0
        ),
        dismiss: () => {
          updateSession(id, { dismissed: true })
        },
      })),
    [sessions, updateSession]
  )

  const isUploading = useMemo(() => {
    return Object.values(sessionList).some((session) => {
      return session.bytesTransferred > 0 && !session.uploadComplete
    })
  }, [sessionList])

  useEffect(() => {
    if (isUploading) {
      const existingUnloadHandler = window.onbeforeunload
      window.onbeforeunload = (event: BeforeUnloadEvent) => {
        // eslint-disable-next-line no-param-reassign
        event.returnValue = 'Upload in progress, closing will abort upload'
        return 'Upload in progress, closing will abort upload'
      }
      return () => {
        window.onbeforeunload = existingUnloadHandler
      }
    }
  }, [isUploading])

  const contextValue = useMemo(
    () => ({ sessions: sessionList, newSession, updateSession }),
    [sessionList, newSession, updateSession]
  )
  return (
    <UploadSessionContext.Provider value={contextValue}>
      {children}
    </UploadSessionContext.Provider>
  )
}

const useUploadSessions = (): UploadSessionContextState =>
  useContext(UploadSessionContext)

export default useUploadSessions
