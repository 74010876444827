import { BoardCreatedContext } from './schemas/BoardCreated'
import { BoardDeletedContext } from './schemas/BoardDeleted'
import { BoardFilterChangedContext } from './schemas/BoardFilterChanged'
import { BoardItemMovedContext } from './schemas/BoardItemMoved'
import { BoardItemRerrangedContext } from './schemas/BoardItemRearranged'
import { BoardItemRemovedContext } from './schemas/BoardItemRemoved'
import { BoardSharingPanelOpenedContext } from './schemas/BoardSharingPanelOpened'
import { BoardSharingPanelShareIntendedContext } from './schemas/BoardSharingPanelShareIntended'
import { BoardViewedContext } from './schemas/BoardViewed'
import { CanvasDownloadedContext } from './schemas/Canvas Downloaded'
import { CanvasShareIntendedContext } from './schemas/CanvasShareIntended'
import { ExtensionButtonClickedContext } from './schemas/ChromeExtensionButtonClicked'
import { CreatePDFContext } from './schemas/CreatePDF'
import { DiscoveryFeedRefreshedContext } from './schemas/DiscoveryFeedRefreshed'
import { DiscoveryModeChangedContext } from './schemas/DiscoveryModeChanged'
import { DiscoveryOpenedContext } from './schemas/DiscoveryOpened'
import { EditSessionViewedContext } from './schemas/Edit Session Viewed'
import { ExperimentViewedContext } from './schemas/ExperimentViwedContext'
import { GridItemClickedContext } from './schemas/GridItemClicked'
import { ItemCopiedContext } from './schemas/ItemCopied'
import { ItemDownloadedContext } from './schemas/ItemDownloaded'
import { ItemFileNameChangedContext } from './schemas/ItemFileNameChanged'
import { ItemLinkCopiedContext } from './schemas/ItemLinkCopied'
import { ItemOriginalOpenedContext } from './schemas/ItemOriginalOpened'
import { ItemPageOpenedContext } from './schemas/ItemPageOpened'
import { ItemSourceClickedContext } from './schemas/ItemSourceClicked'
import { ItemTagClickedContext } from './schemas/ItemTagClicked'
import { ItemViewedContext } from './schemas/ItemViewed'
import { KiveWrappedLinkCopiedContext } from './schemas/KiveWrappedLinkCopied'
import { KiveWrappedPanelOpenedContext } from './schemas/KiveWrappedPanelOpened'
import { KiveWrappedVideoDownloadedContext } from './schemas/KiveWrappedVideoDownloaded'
import { KnowledgeGraphRelatedTagClickedContext } from './schemas/KnowledgeGraphRelatedTagClicked'
import { LibraryOpenedContext } from './schemas/LibraryOpened'
import { MagicBoardClickedContext } from './schemas/MagicBoardClicked'
import { NominationPanelClosedContext } from './schemas/NominationPanelClosed'
import { NominationPanelOpenedContext } from './schemas/NominationPanelOpened'
import { NominationPanelVimeoSuggestionsLoadedContext } from './schemas/NominationPanelVimeoSuggestionsLoaded'
import { OutpaintAcceptedContext } from './schemas/Outpaint Accepted'
import { OutpaintCreatedContext } from './schemas/Outpaint Created'
import { PersonalInviteLinkShareIntendedContext } from './schemas/PersonalInviteLinkShareIntended'
import { ProfileOpenedContext } from './schemas/ProfileOpened'
import { ProjectCreatedContext } from './schemas/ProjectCreated'
import { ProjectPublicViewedContext } from './schemas/ProjectPublicViewed'
import { ProjectSharingPanelShareIntendedContext } from './schemas/ProjectSharingPanelShareIntended'
import { SearchSessionCreatedContext } from './schemas/SearchSessionCreated'
import { SetupCompletedContext } from './schemas/SetupCompleted'
import { SetupInvitesContext } from './schemas/SetupInvites'
import { SetupProfileContext } from './schemas/SetupProfile'
import { SetupSessionFinishedContext } from './schemas/SetupSessionFinished'
import { SetupStartedContext } from './schemas/SetupStarted'
import { SetupStepSkippedContext } from './schemas/SetupUploadStepSkipped'
import { SetupVideoExtractModalContext } from './schemas/SetupVideoExtractOpened'
import { SetupWorkspaceContext } from './schemas/SetupWorkspace'
import { SetupWorkspaceJoinedContext } from './schemas/SetupWorkspaceJoined'
import { SocialAssetCreationFinishedContext } from './schemas/SocialAssetCreationFinished'
import { SocialAssetCreationOpenedContext } from './schemas/SocialAssetCreationOpened'
import { SocialAssetCreationStartedContext } from './schemas/SocialAssetCreationStarted'
import { UpgradePanelOpenedContext } from './schemas/UpgradePanelOpened'
import { UploadFilesContext } from './schemas/UploadFiles'
import { UploadFlowCanceled } from './schemas/UploadFlowCanceled'
import { UploadFlowItemsAdded } from './schemas/UploadFlowItemsAdded'
import { UploadFlowItemsRemoved } from './schemas/UploadFlowItemsRemoved'
import { UploadFlowItemsSkipped } from './schemas/UploadFlowItemsSkipped'
import { UploadFlowStarted } from './schemas/UploadFlowStarted'
import { UploadFlowWorkspaceChanged } from './schemas/UploadFlowWorkspaceChanged'
import { UploadMaxBytesLimitHit } from './schemas/UploadMaxBytesLimitHit'
import { UploadMaxFilesLimitHit } from './schemas/UploadMaxFilesLimitHit'
import { UploadOptionSelected } from './schemas/UploadOptionSelected'
import { UploadPlanQuotaHit } from './schemas/UploadPlanQuotaHit'
import { UploadSessionCreated } from './schemas/UploadSessionCreated'
import { UploadSessionSourceClickedContext } from './schemas/UploadSessionSourceClicked'
import { VideoExtractionQuotaHit } from './schemas/VideoExtractionQuotaHit'
import { VideoExtractionUploadCompleted } from './schemas/VideoExtractionUploadCompleted'
import { VideoExtractionUploadStarted } from './schemas/VideoExtractionUploadStarted'
import { VideoExtractionVideoInvalidated } from './schemas/VideoExtractionVideoInvalidated'
import { VideoExtractionVideoSelected } from './schemas/VideoExtractionVideoSelected'
import { VideoLinkExtractionFailed } from './schemas/VideoLinkExtractionFailed'
import { VideoLinkExtractionStarted } from './schemas/VideoLinkExtractionStarted'
import { WaitingListEntryApprovedContext } from './schemas/WaitingListEntryApproved'
import { WaitingListEntryCompletedContext } from './schemas/WaitingListEntryCompleted'
import { WaitingListEntryCreatedContext } from './schemas/WaitingListEntryCreated'
import { WorkspaceDeletedContext } from './schemas/WorkspaceDeleted'

const getUserProperties = (preLogin = false) => {
  if (preLogin === true) {
    return {
      uid: null,
      jobTitle: null,
      has_completed_signup: false,
      manuallyOnboarded: false,
    }
  }
  const trackingContext = (window as any).trackingContext
  return {
    uid: trackingContext?.uid as string,
    jobTitle: trackingContext?.jobTitle as string,
    has_completed_signup: trackingContext?.has_completed_signup as boolean,
    manuallyOnboarded: trackingContext?.manuallyOnboarded as boolean,
  }
}

const getWorkspaceProperties = () => {
  const trackingContext = (window as any).trackingContext
  return {
    workspaceId: trackingContext?.workspaceId,
    workspacePlan: trackingContext?.workspacePlan,
    g_workspace_id: trackingContext?.workspaceId,
  }
}

const getUtmParameterProperties = () => {
  const url = new URL(window.location.href)
  return {
    utmSource: url.searchParams.get('utm_source'),
    utmCampaign: url.searchParams.get('utm_campaign'),
    utmMedium: url.searchParams.get('utm_medium'),
    utmContent: url.searchParams.get('utm_content'),
  }
}

const getUrlProperties = () => {
  return {
    href: window.location.href,
  }
}

const getScreenProperties = () => {
  return {
    screenWidth: window.screen.width,
    screenHeight: window.screen.height,
  }
}

const getGeneralProperties = (preLogin = false) => {
  const userProperties = getUserProperties(preLogin)
  const workspaceProperties = getWorkspaceProperties()
  const utmParameterProperties = getUtmParameterProperties()
  const screenProperties = getScreenProperties()
  const urlProperties = getUrlProperties()

  return {
    ...userProperties,
    ...workspaceProperties,
    ...utmParameterProperties,
    ...screenProperties,
    ...urlProperties,
  }
}

interface GeneralProperties {
  email?: string | null | undefined
  uid: string | null
  jobTitle: string | null
  // eslint-disable-next-line @typescript-eslint/naming-convention
  has_completed_signup: boolean
  utmSource: string | null
  utmCampaign: string | null
  utmMedium: string | null
  utmContent: string | null
  screenWidth: number
  screenHeight: number
  manuallyOnboarded: boolean
  workspaceId: string | undefined
  workspacePlan: string | undefined
  href: string | undefined
}

const debug = false

const trackWithGeneralProperties = (
  type: string,
  context: any,
  preLogin = false,
  traits = {}
) => {
  const generalProperties: GeneralProperties = getGeneralProperties(preLogin)

  const contextWithGeneralProperties = { ...context, ...generalProperties }

  if (debug && process.env.REACT_APP_ENV !== 'PRODUCTION') {
    console.log(type, context)
  } else {
    window.analytics.track(type, contextWithGeneralProperties, {
      context: { traits },
    })
  }
}

export const trackPage = (pathName: string) => {
  let email
  try {
    const userTraits = window.localStorage.getItem('ajs_user_traits')
    if (userTraits !== null) {
      const traits = JSON.parse(userTraits)
      email = traits?.email
    }
  } catch (err) {
    console.error('Error parsing userTraits')
  }

  window.analytics.page(
    pathName,
    {},
    {
      context: { traits: { email } },
    }
  )
}

export const trackBoardCreated = (context: BoardCreatedContext) => {
  trackWithGeneralProperties('Board Created', context)
}

export const trackBoardViewed = (context: BoardViewedContext) => {
  trackWithGeneralProperties('Board Viewed', context)
}

export const trackBoardDeleted = (context: BoardDeletedContext) => {
  trackWithGeneralProperties('Board Deleted', context)
}

export const trackBoardItemMoved = (context: BoardItemMovedContext) => {
  trackWithGeneralProperties('Board Item Moved', context)
}

export const trackBoardItemRearranged = (
  context: BoardItemRerrangedContext
) => {
  trackWithGeneralProperties('Board Item Rearranged', context)
}

export const trackBoardItemRemoved = (context: BoardItemRemovedContext) => {
  trackWithGeneralProperties('Board Item Removed', context)
}

export const trackBoardSharingPanelOpened = (
  context: BoardSharingPanelOpenedContext
) => {
  trackWithGeneralProperties('Board Sharing Panel Opened', context)
}

export const trackBoardSharingPanelShareIntended = (
  context: BoardSharingPanelShareIntendedContext
) => {
  trackWithGeneralProperties('Board Sharing Panel Share Intended', context)
}

export const trackProjectSharingPanelShareIntended = (
  context: ProjectSharingPanelShareIntendedContext
) => {
  trackWithGeneralProperties('Project Sharing Panel Share Intended', context)
}

export const trackProjectPublictViewed = (
  context: ProjectPublicViewedContext
) => {
  trackWithGeneralProperties('Project Public Viewed', context)
}

export const trackItemOriginalOpened = (context: ItemOriginalOpenedContext) => {
  trackWithGeneralProperties('Item Original Opened', context)
}

export const trackKnowledgeGraphRelatedTagClicked = (
  context: KnowledgeGraphRelatedTagClickedContext
) => {
  trackWithGeneralProperties('Knowledge Graph Related Tag Clicked', context)
}

export const trackProfileOpened = (context: ProfileOpenedContext) => {
  trackWithGeneralProperties('Profile Opened', context)
}

export const trackDiscoveryOpened = (context: DiscoveryOpenedContext) => {
  trackWithGeneralProperties('Discovery Opened', context)
}

export const trackDiscoveryFeedRefreshed = (
  context: DiscoveryFeedRefreshedContext
) => {
  trackWithGeneralProperties('Discovery Feed Refreshed', context)
}

export const trackProjectCreated = (context: ProjectCreatedContext) => {
  trackWithGeneralProperties('Project Created', context)
}

export const trackNominationPanelOpened = (
  context: NominationPanelOpenedContext
) => {
  trackWithGeneralProperties('Nomination Panel Opened', context)
}

export const trackNominationPanelClosed = (
  context: NominationPanelClosedContext
) => {
  trackWithGeneralProperties('Nomination Panel Closed', context)
}

export const trackNominationPanelVimeoSuggestionsLoaded = (
  context: NominationPanelVimeoSuggestionsLoadedContext
) => {
  trackWithGeneralProperties(
    'Nomination Panel Vimeo Suggestions Loaded',
    context
  )
}

export const trackUpgradePanelOpened = (context: UpgradePanelOpenedContext) => {
  trackWithGeneralProperties('Upgrade Panel Opened', context)
}

export const trackUploadSessionCreated = (context: UploadSessionCreated) => {
  trackWithGeneralProperties('Upload Session Created', context)
}

export const trackWorkspaceDeleted = (context: WorkspaceDeletedContext) => {
  trackWithGeneralProperties('Workspace Deleted', context)
}

export const trackSetupCompleted = (context: SetupCompletedContext) => {
  trackWithGeneralProperties('Setup Completed', context)
}

export const trackItemDownloaded = (context: ItemDownloadedContext) => {
  trackWithGeneralProperties('Item Downloaded', context)
}

export const trackItemPageOpened = (context: ItemPageOpenedContext) => {
  trackWithGeneralProperties('Item Page Opened', context)
}

export const trackItemFileNameChanged = (
  context: ItemFileNameChangedContext
) => {
  trackWithGeneralProperties('Item File Name Changed', context)
}

export const trackItemLinkCopied = (context: ItemLinkCopiedContext) => {
  trackWithGeneralProperties('Item Link Copied', context)
}

export const trackUploadFlowStarted = (context: UploadFlowStarted) => {
  trackWithGeneralProperties('Upload Flow Started', context)
}

export const trackUploadPlanQuotaHit = (context: UploadPlanQuotaHit) => {
  trackWithGeneralProperties('Upload Plan Quota Hit', context)
}

export const trackUploadMaxBytesLimitHit = (
  context: UploadMaxBytesLimitHit
) => {
  trackWithGeneralProperties('Upload Max Bytes Limit Hit', context)
}

export const trackUploadMaxFilesLimitHit = (
  context: UploadMaxFilesLimitHit
) => {
  trackWithGeneralProperties('Upload Max Files Limit Hit', context)
}

export const trackUploadFlowItemsAdded = (context: UploadFlowItemsAdded) => {
  trackWithGeneralProperties('Upload Flow Items Added', context)
}

export const trackUploadFlowItemsSkipped = (
  context: UploadFlowItemsSkipped
) => {
  trackWithGeneralProperties('Upload Flow Items Skipped', context)
}

export const trackUploadFlowCanceled = (context: UploadFlowCanceled) => {
  trackWithGeneralProperties('Upload Flow Canceled', context)
}

export const trackUploadFlowItemsRemoved = (
  context: UploadFlowItemsRemoved
) => {
  trackWithGeneralProperties('Upload Flow Items Removed', context)
}

export const trackUploadFlowWorkspaceChanged = (
  context: UploadFlowWorkspaceChanged
) => {
  trackWithGeneralProperties('Upload Flow Workspace Changed', context)
}

export const trackUploadFilesDragged = (context?: UploadFilesContext) => {
  trackWithGeneralProperties('Upload Files Dragged', context)
}

export const trackUploadFilesPicked = (context?: UploadFilesContext) => {
  trackWithGeneralProperties('Upload Files Picked', context)
}

export const trackVideoExtractionVideoSelected = (
  context: VideoExtractionVideoSelected
) => {
  trackWithGeneralProperties('Video Extraction Video Selected', context)
}

export const trackVideoExtractionUploadStarted = (
  context: VideoExtractionUploadStarted
) => {
  trackWithGeneralProperties('Video Extraction Upload Started', context)
}

export const trackVideoLinkExtractionStarted = (
  context: VideoLinkExtractionStarted
) => {
  trackWithGeneralProperties('Video Link Extraction Started', context)
}

export const trackVideoLinkExtractionFailed = (
  context: VideoLinkExtractionFailed
) => {
  trackWithGeneralProperties('Video Link Extraction Failed', context)
}

export const trackVideoExtractionUploadCompleted = (
  context: VideoExtractionUploadCompleted
) => {
  trackWithGeneralProperties('Video Extraction Upload Completed', context)
}

export const trackVideoExtractionVideoInvalidated = (
  context: VideoExtractionVideoInvalidated
) => {
  trackWithGeneralProperties('Video Extraction Video Invalidated', context)
}

export const trackVideoExtractionQuotaHit = (
  context: VideoExtractionQuotaHit
) => {
  trackWithGeneralProperties('Video Extraction Quota Hit', context)
}

export const trackExtensionButtonClicked = (
  context: ExtensionButtonClickedContext
) => {
  trackWithGeneralProperties('Extension Button Clicked', context)
}

export const trackSocialAssetCreationOpened = (
  context: SocialAssetCreationOpenedContext
) => {
  trackWithGeneralProperties('Social Asset Creation Opened', context)
}

export const trackSocialAssetCreationStarted = (
  context: SocialAssetCreationStartedContext
) => {
  trackWithGeneralProperties('Social Asset Creation Started', context)
}

export const trackSocialAssetCreationFinished = (
  context: SocialAssetCreationFinishedContext
) => {
  trackWithGeneralProperties('Social Asset Creation Finished', context)
}

export const trackSearchSessionCreated = (
  context: SearchSessionCreatedContext
) => {
  trackWithGeneralProperties('Search Session Created', context)
}

export const trackOutpaintCreated = (context: OutpaintCreatedContext) => {
  trackWithGeneralProperties('Outpaint Created', context)
}

export const trackOutpaintAccepted = (context: OutpaintAcceptedContext) => {
  trackWithGeneralProperties('Outpaint Accepted', context)
}
export const trackEditSessionViewed = (context: EditSessionViewedContext) => {
  trackWithGeneralProperties('Edit Session Viewed', context)
}

export const trackCanvasShareIntended = (
  context: CanvasShareIntendedContext
) => {
  trackWithGeneralProperties('Canvas Share Intended', context)
}

export const trackCanvasDownloaded = (context: CanvasDownloadedContext) => {
  trackWithGeneralProperties('Canvas Downloaded', context)
}

// Pre Login Tracking
export const trackWaitingListEntryCreated = (
  context: WaitingListEntryCreatedContext,
  preLogin = true,
  traits = {}
) => {
  trackWithGeneralProperties(
    'Waiting List Entry Created',
    context,
    preLogin,
    traits
  )
}

// Pre Login Tracking
export const trackWaitingListEntryCompleted = (
  context: WaitingListEntryCompletedContext,
  preLogin = true
) => {
  trackWithGeneralProperties('Waiting List Entry Completed', context, preLogin)
}

// Pre Login Tracking
export const trackWaitingListEntryApproved = (
  context: WaitingListEntryApprovedContext,
  preLogin = true
) => {
  trackWithGeneralProperties('Waiting List Entry Approved', context, preLogin)
}

export const trackPersonalInviteLinkShareIntended = (
  context: PersonalInviteLinkShareIntendedContext,
  preLogin = true
) => {
  trackWithGeneralProperties(
    'Personal Invite Link Share Intended',
    context,
    preLogin
  )
}

// Setup flow
export const trackSetupStarted = (context: SetupStartedContext) => {
  trackWithGeneralProperties('Setup Started', context)
}

export const trackSetupProfileCompleted = (context: SetupProfileContext) => {
  trackWithGeneralProperties('Setup Profile Completed', context)
}

export const trackSetupWorkspaceCreated = (context: SetupWorkspaceContext) => {
  trackWithGeneralProperties('Setup Workspace Created', context)
}

export const trackSetupWorkspaceUpdated = (context: SetupWorkspaceContext) => {
  trackWithGeneralProperties('Setup Workspace Updated', context)
}

export const trackSetupWorkspaceJoined = (
  context: SetupWorkspaceJoinedContext
) => {
  trackWithGeneralProperties('Setup Workspace Joined', context)
}

export const trackSetupInvitesCompleted = (context: SetupInvitesContext) => {
  trackWithGeneralProperties('Setup Invites Completed', context)
}

export const trackSetupInvitesUpdated = (context: SetupInvitesContext) => {
  trackWithGeneralProperties('Setup Invites Updated', context)
}

export const trackSetupVideoExtractOpened = (
  context: SetupVideoExtractModalContext
) => {
  trackWithGeneralProperties('Setup Upload Video Extract Opened', context)
}

export const trackSetupVideoExtractClosed = (
  context: SetupVideoExtractModalContext
) => {
  trackWithGeneralProperties('Setup Upload Video Extract Closed', context)
}

export const trackSetupSessionFinished = (
  context: SetupSessionFinishedContext
) => {
  trackWithGeneralProperties('Setup Session Finished', context)
}

export const trackUploadWithGoogleDriveInterest = () => {
  trackWithGeneralProperties('Upload With Google Drive Interest', {})
}

export const trackDiscoverModeChanged = (
  context: DiscoveryModeChangedContext
) => {
  trackWithGeneralProperties('Discovery Mode Changed', context)
}

export const trackItemCopied = (context: ItemCopiedContext) => {
  trackWithGeneralProperties('Item Copied', context)
}

export const trackUploadOptionSelected = (context: UploadOptionSelected) => {
  trackWithGeneralProperties('Upload Option Selected', context)
}

export const trackMagicBoardClicked = (context: MagicBoardClickedContext) => {
  trackWithGeneralProperties('Magic Board Clicked', context)
}

export const trackGridItemClicked = (context: GridItemClickedContext) => {
  trackWithGeneralProperties('Grid Item Clicked', context)
}

export const trackItemTagClicked = (context: ItemTagClickedContext) => {
  trackWithGeneralProperties('Item Tag Clicked', context)
}

export const trackKiveWrappedPanelOpened = (
  context: KiveWrappedPanelOpenedContext
) => {
  trackWithGeneralProperties('Kive Wrapped Panel Opened', context)
}

export const trackKiveWrappedVideoDownloaded = (
  context: KiveWrappedVideoDownloadedContext
) => {
  trackWithGeneralProperties('Kive Wrapped Video Downloaded', context)
}

export const trackKiveWrappedLinkCopied = (
  context: KiveWrappedLinkCopiedContext
) => {
  trackWithGeneralProperties('Kive Wrapped Link Copied', context)
}

export const trackCreatePDFStarted = (context: CreatePDFContext) => {
  trackWithGeneralProperties('Create PDF Started', context)
}

export const trackCreatePDFFailed = (context: CreatePDFContext) => {
  trackWithGeneralProperties('Create PDF Failed', context)
}

export const trackCreatePDFDownloaded = (context: CreatePDFContext) => {
  trackWithGeneralProperties('Create PDF Downloaded', context)
}

export const trackItemViewed = (context: ItemViewedContext) => {
  trackWithGeneralProperties('Item Viewed', context)
}

export const trackPublicItemViewed = (context: ItemViewedContext) => {
  trackWithGeneralProperties('Public Item Viewed', context)
}

export const trackLibraryOpened = (context: LibraryOpenedContext) => {
  trackWithGeneralProperties('Library Opened', context)
}

export const trackSetupUploadStepSkipped = (
  context: SetupStepSkippedContext
) => {
  trackWithGeneralProperties('Setup Upload Step Skipped', context)
}

export const trackSetupTemplateStepSkipped = (
  context: SetupStepSkippedContext
) => {
  trackWithGeneralProperties('Setup Template Step Skipped', context)
}

export const trackBoardFilterChanged = (context: BoardFilterChangedContext) => {
  trackWithGeneralProperties('Board Filter Changed', context)
}

export const trackItemSourceClicked = (context: ItemSourceClickedContext) => {
  trackWithGeneralProperties('Item Source Clicked', context)
}

export const trackUploadSessionSourceClicked = (
  context: UploadSessionSourceClickedContext
) => {
  trackWithGeneralProperties('Upload Session Source Clicked', context)
}

export const trackExperimentViewed = (context: ExperimentViewedContext) => {
  trackWithGeneralProperties('Experiment Viewed', context)
}

export const trackHomeTaskClicked = (context: { taskId: string }) => {
  trackWithGeneralProperties('Home Task Clicked', context)
}

export const trackHomeTaskCompleted = (context: { taskId: string }) => {
  trackWithGeneralProperties('Home Task Completed', context)
}

export const trackHomeLearningCardClicked = (context: { category: string }) => {
  trackWithGeneralProperties('Home Learning Card Clicked', context)
}

export const trackHomeLearningCardHidden = (context: { category: string }) => {
  trackWithGeneralProperties('Home Learning Card Hidden', context)
}

export const trackHomeLearningCardRead = (context: { category: string }) => {
  trackWithGeneralProperties('Home Learning Card Read', context)
}

export const trackHomeRecentBoardClicked = () => {
  trackWithGeneralProperties('Home Recent Board Clicked', {})
}

export const trackHomeDiscoverItemClicked = () => {
  trackWithGeneralProperties('Home Discover Item Clicked', {})
}

export const trackHomeMagicBoardClicked = () => {
  trackWithGeneralProperties('Home Magic Board Clicked', {})
}

export const trackHomeRecentUploadEventClicked = () => {
  trackWithGeneralProperties('Home Recent Upload Event Clicked', {})
}

export const trackHomeSearchComboClicked = () => {
  trackWithGeneralProperties('Home Search Combo Clicked', {})
}

export const trackHomePageViewed = () => {
  trackWithGeneralProperties('Home Page Viewed', {})
}

export const trackDiscoveryLearnMoreClicked = (context: {
  trackingSessionId: string
}) => {
  trackWithGeneralProperties('Discovery Learn More Clicked', context)
}

export const trackDiscoveryTagForYouClicked = (context: {
  trackingSessionId: string
}) => {
  trackWithGeneralProperties('Discovery Tag For You Clicked', context)
}

export const trackDiscoveryTopContributorClicked = (context: {
  trackingSessionId: string
}) => {
  trackWithGeneralProperties('Discovery Top Contributor Clicked', context)
}

export const trackDiscoveryPublicBoardClicked = (context: {
  trackingSessionId: string
}) => {
  trackWithGeneralProperties('Discovery Public Board Clicked', context)
}

export const trackDiscoveryMorePublicBoardsClicked = (context: {
  trackingSessionId: string
}) => {
  trackWithGeneralProperties('Discovery More Public Boards Clicked', context)
}
