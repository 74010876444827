import { CheckmarkCircle2Outline as Checkmark } from '@styled-icons/evaicons-outline/CheckmarkCircle2Outline'
import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { match } from '../../helpers/utilityFunctions'

export const ToastItem = ({ children, ...props }) => {
  return (
    <ToastItemStyled {...props}>
      {match(props.type, {
        error: <ErrorIcon />,
        success: <SuccessIcon />,
      })}
      {children}
      {props.options?.linkTo && (
        <StyledLink to={props.options.linkTo}>
          {props.options.linkText}
        </StyledLink>
      )}
    </ToastItemStyled>
  )
}

const StyledLink = styled(Link)`
  ${({ theme }) => css`
    color: ${theme.colors.text.neutral[0]};
    margin-left: 8px;
    text-decoration: underline;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
    &:hover {
      color: ${theme.colors.accent[2]};
    }
  `}
`

const SuccessIcon = styled(Checkmark)`
  ${({ theme }) => css`
    color: ${theme.colors.success};
    height: 20px;
    margin-right: 8px;
    flex-shrink: 0;
  `}
`

const ErrorIcon = styled(Checkmark)`
  ${({ theme }) => css`
    color: ${theme.colors.error};
    height: 20px;
    margin-right: 8px;
    flex-shrink: 0;
  `}
`

const ToastItemStyled = styled(motion.li).attrs({
  initial: { y: 0, opacity: 0 },
  animate: { y: -8, opacity: 1 },
  exit: { y: 0, opacity: 0 },
  transition: { duration: 0.2 },
})`
  ${({ theme, type }) => css`
    background: ${theme.colors.background[5]};
    border-radius: ${theme.borderRadius.lg};
    display: block;
    list-style: none;
    border: ${theme.border.thin} ${theme.colors.discreet};
    box-shadow: ${theme.shadow.thin};
    color: ${theme.colors.text.neutral[0]};
    text-align: center;
    max-width: 320px;
    margin: 8px 0;
    padding: 8px 8px;
    pointer-events: auto;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
  `}
`

export const ToastContainer = ({ children, ...props }) => (
  <ToastList {...props}>
    <AnimatePresence>{children}</AnimatePresence>
  </ToastList>
)

const ToastList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  bottom: 0;
  margin: 20px auto;
  padding: 0;
  pointer-events: none;
  z-index: 5000;
  width: 100%;
`
