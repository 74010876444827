import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import styled, { css } from 'styled-components'

import useClickOutside from '../../hooks/useClickOutside'
import useKeyupListener from '../../hooks/useKeyupListener'

/**
 * @param {{ show: boolean; onClose: () => void, children: JSX.Element }} props
 */
export default function Dropdown({
  xTranslate = '-50%',
  left = '50%',
  top = '25px',
  position = 'absolute',
  ...props
}) {
  const dialogRef = useClickOutside(props.show && props.onClose)
  useKeyupListener('Escape', props.show && props.onClose)
  const Wrapper =
    position === 'absolute' || position === 'relative'
      ? Fixture
      : React.Fragment
  return (
    <Wrapper style={{ display: props.show ? 'block' : 'none' }}>
      <AnimatePresence>
        {props.show && (
          <Dialog
            ref={dialogRef}
            xTranslate={xTranslate}
            left={left}
            position={position}
            top={top}
          >
            {props.children}
          </Dialog>
        )}
      </AnimatePresence>
    </Wrapper>
  )
}

const Fixture = styled.div`
  position: relative;
`

const Dialog = styled(motion.dialog).attrs((props) => ({
  initial: { y: 30, x: props.xTranslate, opacity: 0 },
  animate: { y: 0, x: props.xTranslate, opacity: 1 },
  exit: { y: 30, x: props.xTranslate, opacity: 0 },
  transition: { duration: 0.05 },
}))`
  ${({ theme, position, xTranslate, left, top }) => css`
    position: ${position};
    transform: translateX(${xTranslate});
    left: ${left};
    top: ${top};
    border: ${`${theme.border.thin} ${theme.colors.discreet}`};
    border-radius: 12px;
    background: ${theme.colors.background[3]};
    padding: 8px;
    z-index: 10;
    display: block;
  `}
`
export const DropdownList = styled.ul`
  margin: 0;
  padding: 0;
  min-width: ${({ minWidth = '140px' }) => minWidth};
`

export const DropdownListItem = styled.li`
  ${({ theme, role }) => css`
    display: grid;
    grid-template-columns: 1fr auto;
    text-align: left;
    list-style: none;
    align-items: center;
    color: ${theme.colors.text.neutral[1]};
    font-weight: ${theme.fontWeight.bold};
    width: 100%;
    padding: 4px 4px 8px 4px;
    cursor: pointer;
    :last-child {
      border-bottom: none;
    }
    :hover {
      color: ${role === 'irreversible'
        ? theme.colors.redLighter
        : theme.colors.text.neutral[0]};
    }
  `}
`
