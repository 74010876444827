import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { trackItemColorClicked } from 'helpers/tracking'
import { useActiveWorkspace } from 'hooks/useActiveWorkspace'

import useToastMessages from '../toast/useToastMessages'
import { ItemData } from './hooks/useGetItemData'

export const ColorsContainer = styled.div((_) => ({
  position: 'absolute',
  left: 16,
  top: 16,
  opacity: 0,
}))

const ColorDot = styled.div(({ color }) => ({
  width: 24,
  height: 24,
  margin: 0,
  borderRadius: '50%',
  display: 'inline-block',
  background: color,
  transition: 'transform ease 0.1s',
  ':hover': {
    transform: 'scale(1.2)',
    cursor: 'pointer',
  },
}))

const StyledInfo = styled.div(({ theme, color }) => ({
  width: 76,
  textAlign: 'center',
  marginLeft: 8,
  padding: '2px 8px',
  borderRadius: theme.borderRadius.default,
  fontSize: theme.fontSizes.sm,
  display: 'inline-block',
  overflow: 'hidden',
  background: theme.colors.background[3],
  color: theme.colors.text.neutral[0],
  boxShadow: `inset 0 0 0 1px ${color}`,
  ':hover': {
    background: color,
    color: theme.colors.text.neutral[0],
  },
}))

const ColorCard = styled.div(({ theme }) => ({
  height: 24,
  margin: '8px 0px',
  width: 24,
  borderRadius: theme.borderRadius.default,
  position: 'relative',
  ':hover': {
    width: 'auto',
  },
}))

const ColorInfo = ({
  color,
  onClick,
}: {
  color: string
  onClick: () => void
}) => {
  const [mouseOver, setMouseOver] = useState(false)
  return (
    <StyledInfo
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
      onClick={onClick}
      color={color}
    >
      {mouseOver ? 'Copy' : color}
    </StyledInfo>
  )
}

const Color = ({
  color,
  handleInfoClick,
  handleDotClick,
}: {
  color: string
  handleInfoClick: () => void
  handleDotClick: () => void
}) => {
  const [mouseOver, setMouseOver] = useState(false)
  return (
    <ColorCard
      color={color}
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
    >
      <ColorDot onClick={handleDotClick} color={color} />
      {mouseOver && <ColorInfo onClick={handleInfoClick} color={color} />}
    </ColorCard>
  )
}

interface ItemColorsProps {
  colors: ItemData['colors']
  inDiscover?: boolean
}

const ItemColors: React.FC<ItemColorsProps> = ({ colors, inDiscover }) => {
  const { reportSuccess } = useToastMessages()
  const navigate = useNavigate()
  const activeWorkspace = useActiveWorkspace()

  function handleDotClick(color: string) {
    if (!activeWorkspace?.url) {
      return
    }
    const color_ = encodeURIComponent(color)
    trackItemColorClicked()
    navigate(
      `/${activeWorkspace?.url}/${
        inDiscover ? 'discover' : 'library'
      }?color=${color_}&search=true`
    )
  }

  function handleInfoClick(color: string) {
    navigator.clipboard.writeText(color)
    reportSuccess('Hex code copied to clipboard')
  }

  return (
    <ColorsContainer>
      {colors.map((color) => (
        <Color
          handleInfoClick={() => handleInfoClick(color.hex)}
          handleDotClick={() => handleDotClick(color.hex)}
          color={color.hex}
          key={color.id}
        />
      ))}
    </ColorsContainer>
  )
}

export default ItemColors
