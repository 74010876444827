import { fromEvent } from 'file-selector'
import React, { useEffect, useState } from 'react'
import Dropzone, { DropEvent } from 'react-dropzone'
import { useDispatch, useSelector } from 'react-redux'
import { useMatch } from 'react-router-dom'

import { saveToBoard } from 'components/itemsavetoboard/saveToBoardHandlers'
import { useUploadSessionsRemoteUpload } from 'components/upload/useUploadSessionsRemoteUpload'
import { useUserTasks } from 'features/home/setup-tasks-row/useUserTasks'
import {
  trackUploadFilesDragged,
  trackUploadFilesPicked,
} from 'helpers/tracking/tracking'
import { RootState } from 'store'
import { setIsUploadVisible, setLastUploadDrop } from 'store/content/actions'

import DroppedFiles from './DroppedFiles'
import UploadSession, { UploadSessionRemoteUpload } from './UploadSession'
import { FullScreen, Heading } from './UploadStyles'
import type {
  CustomFile,
  UploadOptions,
  UploadSourceView,
  UploadStatus,
} from './types'
import useUploadSessions from './useUploadSessions'

interface UploaderProps {
  onUploadStart?: () => void
  onUploadComplete?: ({
    completedUploads,
  }: {
    completedUploads: number
  }) => void
  showUploadUI?: boolean
  completeUploadsSetup?: () => void
  isInSetup?: boolean
  children:
    | React.ReactNode
    | ((args: { openUploadView: () => void }) => React.ReactNode)
}

const Uploader: React.FC<UploaderProps> = ({
  showUploadUI,
  onUploadStart = () => null,
  onUploadComplete = () => null,
  completeUploadsSetup = () => null,
  isInSetup,
  children,
}) => {
  const match = useMatch('/:workspaceSlug/p/:projectId/:boardId')
  const { boardId, projectId } = match?.params ?? {}
  const { sessions, newSession } = useUploadSessions()
  const { remoteUploadSessions } = useUploadSessionsRemoteUpload()
  const dispatch = useDispatch()
  const [uploadIsConstrained, setUploadIsConstrained] = useState<boolean>(false)
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>('IDLE')
  const { completeUserTask } = useUserTasks()

  const [uploadSourceView, setUploadSourceView] =
    useState<UploadSourceView>(null)

  // eslint-disable-next-line
  const trackingSetupSessionId = new URLSearchParams(location.search).get(
    'sessionId'
  )

  const lastDrop = useSelector(
    (state: RootState) => state.content.lastUploadDrop
  )

  const isUploadVisible = useSelector(
    (state: RootState) => state.content.isUploadVisible
  )

  const setLastDrop = (files: CustomFile[]) => {
    dispatch(setLastUploadDrop(files))
  }

  const setIsUploadViewVisible = (isVisible: boolean) => {
    dispatch(setIsUploadVisible(isVisible))
  }

  const createNewSession = (
    files: CustomFile[],
    workspaceId: string,
    uploadOptions: UploadOptions,
    uploadFlowTrackingId: string,
    tags?: { id: string; description: string }[]
  ) => {
    completeUserTask('upload')

    newSession(
      files,
      boardId,
      workspaceId,
      projectId,
      onUploadStart,
      onUploadComplete,
      uploadOptions,
      uploadFlowTrackingId,
      tags
    )
  }

  const handleUploadItemsToBoard = (itemIds: string[], workspaceId: string) => {
    if (boardId && projectId) {
      saveToBoard(itemIds, boardId, workspaceId)
    }
  }

  const onDrop = async (newDroppedFiles: CustomFile[]) => {
    if (newDroppedFiles.length > 0) {
      setUploadSourceView('desktop')
      setLastDrop(newDroppedFiles)
      setIsUploadViewVisible(true)
    }
  }

  // a way of showing upload status when we drag files.
  // specially good when dragging large folder/many files, which takes time to load before "onDrop" triggers
  const handleGetFilesFromEvent = async (event: DropEvent) => {
    const receivedFilePickerFiles =
      ((event.target as HTMLInputElement)?.files?.length ?? 0) > 0
    const receivedDraggedFiles =
      ((event as DragEvent).dataTransfer?.files?.length ?? 0) > 0

    if (receivedDraggedFiles) {
      setUploadStatus('LOADING_FILES')
      if (trackingSetupSessionId) {
        trackUploadFilesDragged({ trackingSetupSessionId })
      } else {
        trackUploadFilesDragged()
      }
    }
    if (receivedFilePickerFiles) {
      if (trackingSetupSessionId) {
        trackUploadFilesPicked({ trackingSetupSessionId })
      } else {
        trackUploadFilesPicked()
      }
    }
    return fromEvent(event as Event)
  }

  const session = sessions?.[sessions?.length - 1]
  const sessionRemoteUpload = Object.values(remoteUploadSessions)[0]

  const showDroppedFilesComponent = uploadStatus !== 'IDLE' || isUploadVisible

  useEffect(() => {
    const callback = async (event: ClipboardEvent) => {
      if (event.clipboardData?.files) {
        const images = Array.from(event.clipboardData.files)
          .filter((file: File) => file.type.includes('image'))
          .map((image: File) => {
            const file = image
            // @ts-ignore
            file.path = file.name
            return file
          })

        if (images.length) {
          onDrop(images)
        }
      }
    }

    document.addEventListener('paste', callback)

    return () => {
      document.removeEventListener('paste', callback)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Dropzone
      noClick
      onDrop={onDrop}
      disabled={
        uploadIsConstrained || ![null, 'desktop'].includes(uploadSourceView)
      }
      getFilesFromEvent={handleGetFilesFromEvent}
    >
      {({ getRootProps, getInputProps, isDragActive, open, inputRef }) => {
        return (
          <div
            css="width: 100%; height: 100%;"
            {...getRootProps({ className: 'dropzone' })}
          >
            <input ref={inputRef} {...getInputProps()} />
            {showDroppedFilesComponent && (
              <DroppedFiles
                isInSetup={isInSetup}
                lastDrop={lastDrop}
                setLastDrop={setLastDrop}
                closeView={() => setIsUploadViewVisible(false)}
                setUploadIsConstrained={setUploadIsConstrained}
                createNewSession={createNewSession}
                boardId={boardId}
                projectId={projectId}
                uploadItemsToBoard={handleUploadItemsToBoard}
                openFilePicker={open}
                uploadStatus={uploadStatus}
                setUploadStatus={setUploadStatus}
                uploadSourceView={uploadSourceView}
                setUploadSourceView={setUploadSourceView}
              />
            )}
            {isDragActive ? (
              <FullScreen>
                <Heading>Drop it like it&apos;s 🔥 </Heading>
              </FullScreen>
            ) : (
              <>
                {session && !session.dismissed && showUploadUI && (
                  <UploadSession
                    sessionId={session.id}
                    closeSession={completeUploadsSetup}
                    trackingSetupSessionId={trackingSetupSessionId}
                    localSession={session}
                  />
                )}
                {sessionRemoteUpload &&
                  !sessionRemoteUpload.dismissed &&
                  showUploadUI && (
                    <UploadSessionRemoteUpload
                      uploadSession={sessionRemoteUpload}
                      trackingSetupSessionId={trackingSetupSessionId}
                      closeSession={completeUploadsSetup}
                    />
                  )}
              </>
            )}
            {typeof children === 'function'
              ? children({
                  openUploadView: () => setIsUploadViewVisible(true),
                })
              : children}
          </div>
        )
      }}
    </Dropzone>
  )
}

export default Uploader
