import { Link45deg } from '@styled-icons/bootstrap/Link45deg'
import { Share } from '@styled-icons/boxicons-regular/Share'
import { Settings } from '@styled-icons/ionicons-outline/Settings'
import { IosShare as ShareIos } from '@styled-icons/material-outlined/IosShare'
import qs from 'query-string'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFirestore } from 'react-redux-firebase'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { ArrowLeftShort } from 'styled-icons/bootstrap'

import { KiveLogo } from 'assets/svg/KiveLogo'
import { Head } from 'components/boardcards/BoardCardStyles'
import Button from 'components/common/Button'
import ClickOutside from 'components/common/ClickOutside'
import Flex from 'components/common/Flex'
import { Margin } from 'components/common/Margin'
import { Text } from 'components/common/Text'
import EmojiEditable from 'components/common/emoji/EmojiEditable'
import DownloadButton from 'components/downloads/DownloadButton'
import useToastMessages from 'components/toast/useToastMessages'
import useBoardPermissions from 'hooks/useBoardPermissions'
import useIsMobile from 'hooks/useIsMobile'
import useQueryString from 'hooks/useQueryString'
import { RootState } from 'store'
import { setTheme } from 'store/content/actions'
import { DBBoard } from 'types/db'

import BackToProjectButton from './BackToProjectButton'
import BoardMenu from './BoardMenu'
import FavoriteBoardButton from './FavoriteBoardButton'

type BoardProps = {
  title: string
  iconCode: string | null
  board: DBBoard
  boardId: string
  onShareBoardClick: () => void
  isDiscover?: boolean
}

const PublicBoardToolbar = ({
  title,
  iconCode,
  board,
  boardId,
  onShareBoardClick,
  isDiscover,
}: BoardProps) => {
  const { reportSuccess } = useToastMessages()
  const firestore = useFirestore()
  const userAllowedTo = useBoardPermissions(board, [
    'EDIT_BOARD',
    'EDIT_BOARD_USER_PERMISSIONS',
  ])
  const auth = useSelector((state: RootState) => state.firebase.auth)
  const dispatch = useDispatch()
  const isMobile = useIsMobile()
  const queryParams = useQueryString<{ reactionFilters?: string | string[] }>()
  const location = useLocation()
  const navigate = useNavigate()

  const locationState = location.state as { prevPathname?: string }
  const prevPathnameRef = useRef(locationState?.prevPathname)

  const params = useParams() as { projectId: string }
  const isDisplayedInProjectView = Boolean(params.projectId)

  const [settingsClicked, setSettingsClicked] = useState(false)

  const theme = useSelector((state: RootState) =>
    state.content.theme ? state.content.theme : 'darkTheme'
  )

  const shouldDisplayDownloadButton =
    board.presentationSettings?.allowDownloads ?? true

  const enableLinkAccess = () => {
    const searchParams = qs.stringify(
      {
        reactionFilters: queryParams.reactionFilters,
      },
      { arrayFormat: 'comma' }
    )
    const shareableLink = `${window.location.origin}/b/${boardId}${
      searchParams ? `?${searchParams}` : ''
    }`
    navigator.clipboard.writeText(shareableLink)
    reportSuccess('Shareable link copied to clipboard!')

    if (!board.linkAccess && userAllowedTo.EDIT_BOARD_USER_PERMISSIONS) {
      firestore.update(
        { collection: 'boards', doc: boardId },
        {
          linkAccess: 'reader',
        }
      )
    }
  }

  const handleThemeChange = (option: 'darkTheme' | 'lightTheme') => {
    dispatch(setTheme(option))
    firestore.update(
      { collection: 'boards', doc: boardId },
      {
        'presentationSettings.theme': option,
      }
    )
  }

  const handleDownloadButtonChange = (option: boolean) => {
    firestore.update(
      { collection: 'boards', doc: boardId },
      {
        'presentationSettings.allowDownloads': option,
      }
    )
  }

  const handleUpdateBoardEmoji = (code: string | null) => {
    // Conditional
    const itemUpdates = {
      iconCode: code,
      updatedAt: firestore.FieldValue.serverTimestamp(),
    }

    firestore.update({ collection: 'boards', doc: boardId }, itemUpdates)
  }

  // Changes the theme for the entire app, and then back to workspace theme on unmount (changing url)
  useEffect(() => {
    if (board.presentationSettings?.theme) {
      dispatch(setTheme(board.presentationSettings.theme))
    }
    return () => {
      const workspaceTheme = 'darkTheme'
      dispatch(setTheme(workspaceTheme))
    }
  }, [board.presentationSettings?.theme])

  return (
    <>
      <StyledWrapper>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'auto auto auto auto auto',
            columnGap: '15px',
            alignItems: 'center',
          }}
        >
          {!isDiscover && !auth.isEmpty && !isDisplayedInProjectView && (
            <BackToProjectButton
              projectId={board.projectId}
              workspaceId={board.workspaceId}
              boardId={boardId}
              theme={theme}
            />
          )}
          {isDiscover && !auth.isEmpty && !isDisplayedInProjectView && (
            <Button
              size={24}
              Icon={ArrowLeftShort}
              variant={theme === 'darkTheme' ? 'secondary' : 'darkSecondary'}
              onClick={() =>
                prevPathnameRef.current
                  ? navigate(prevPathnameRef.current)
                  : navigate(-1)
              }
            />
          )}

          {/* we make room for project hamburger menu */}
          {isDisplayedInProjectView && isMobile && <Margin x={42} />}

          <div style={{ marginLeft: isMobile ? '-15px' : 0 }}>
            <Head>
              {iconCode && (
                <EmojiEditable
                  code={iconCode}
                  boardId={boardId}
                  handleUpdateEmoji={handleUpdateBoardEmoji}
                  shouldShowActions
                  disabled={!userAllowedTo.EDIT_BOARD}
                />
              )}
              <Text
                nowrap
                style={{
                  marginLeft: iconCode ? 10 : 0,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: isMobile ? '100px' : 'none',
                }}
                color="neutral.0"
                size="sm"
                bold
              >
                {title}
              </Text>
            </Head>
          </div>
          <div>
            {userAllowedTo.EDIT_BOARD && (
              <StyledSettingsButtonContainer
                onClick={() => setSettingsClicked(() => !settingsClicked)}
                settingsClicked={settingsClicked}
              >
                <Settings size={24} />
              </StyledSettingsButtonContainer>
            )}
            {settingsClicked && (
              <ClickOutside
                handleClickOutside={() =>
                  setSettingsClicked(() => !settingsClicked)
                }
              >
                <StyledSettingsMenu>
                  <StyledSettingsMenuLabel>Color</StyledSettingsMenuLabel>
                  <StyledSettingsMenuOption
                    onClick={() => handleThemeChange('darkTheme')}
                    active={theme === 'darkTheme'}
                  >
                    Dark
                  </StyledSettingsMenuOption>
                  <StyledSettingsMenuOption
                    onClick={() => handleThemeChange('lightTheme')}
                    active={theme === 'lightTheme'}
                  >
                    Light
                  </StyledSettingsMenuOption>

                  <div>
                    <StyledSettingsMenuLabel>
                      Allow downloads
                    </StyledSettingsMenuLabel>
                    <StyledSlider
                      active={shouldDisplayDownloadButton}
                      onClick={() =>
                        handleDownloadButtonChange(!shouldDisplayDownloadButton)
                      }
                      isLightMode={theme === 'lightTheme'}
                    >
                      <StyledSliderBall
                        isLightMode={theme === 'lightTheme'}
                        active={shouldDisplayDownloadButton}
                      />
                    </StyledSlider>
                  </div>
                </StyledSettingsMenu>
              </ClickOutside>
            )}
          </div>
        </div>

        <Flex flexGap={3} alignItems="center">
          {(() => {
            if (isMobile) {
              return (
                <>
                  {!auth.isEmpty && (
                    <StyledLink onClick={onShareBoardClick}>
                      <ShareIos style={{ height: 24 }} />
                    </StyledLink>
                  )}
                  <BoardMenu
                    isPublic
                    boardId={boardId}
                    board={board}
                    withDownloadButton={shouldDisplayDownloadButton}
                  />
                </>
              )
            }
            // desktop + un-authed
            if (auth.isEmpty) {
              return (
                <>
                  <FavoriteBoardButton boardId={boardId} />
                  {shouldDisplayDownloadButton && <DownloadButton />}
                  <Link
                    to={`/signup?fromPublicFeature=true&boardId=${boardId}`}
                    css="display:flex"
                  >
                    <KiveLogo
                      color={theme === 'lightTheme' ? '#202020' : '#ffffff'}
                      height={20}
                      width={20}
                    />
                    <Margin x={12} />
                    <Text color="neutral.0" size="sm" bold>
                      Kive
                    </Text>
                  </Link>
                  <BoardMenu
                    isPublic
                    boardId={boardId}
                    board={board}
                    withDownloadButton={false}
                  />
                </>
              )
            }
            // desktop and authed
            return (
              <>
                <StyledLink onClick={onShareBoardClick}>
                  <Share css="height: 18px" />
                  <Margin x={12} />
                  <Text size="sm">Share</Text>
                </StyledLink>
                <StyledLink onClick={enableLinkAccess}>
                  <Link45deg css="height: 18px" />
                  <Margin x={12} />
                  <Text size="sm">Copy link</Text>
                </StyledLink>
                <FavoriteBoardButton boardId={boardId} />
                <BoardMenu
                  isPublic
                  boardId={boardId}
                  board={board}
                  withDownloadButton={shouldDisplayDownloadButton}
                />
              </>
            )
          })()}
        </Flex>
      </StyledWrapper>
    </>
  )
}

export default PublicBoardToolbar

const StyledWrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 18px;
    background-color: ${theme.colors.background[2]};
    border-top: 1px solid ${theme.colors.background[5]};
    border-bottom: 1px solid ${theme.colors.background[5]};
  `}
`

const StyledSettingsButtonContainer = styled.div<{ settingsClicked: boolean }>`
  ${({ theme, settingsClicked }) => css`
    color: ${settingsClicked
      ? theme.colors.accent[2]
      : theme.colors.text.neutral[0]};
    margin-left: -5px;
    &:hover {
      cursor: pointer;
      color: ${theme.colors.accent[2]};
    }
  `}
`

const StyledSettingsMenu = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 70px;
    left: 20px;
    width: 210px;
    z-index: 1000;
    padding: 0px 16px;
    border: ${`${theme.border.thin} ${theme.colors.discreet}`};
    border-radius: ${theme.borderRadius.lg};
    margin-left: -5px;
    background-color: ${theme.colors.background[1]};
  `}
`

const StyledSettingsMenuLabel = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.xs};
    color: ${theme.colors.text.neutral[2]};
    margin-top: 20px;
    margin-bottom: 8px;
  `}
`

const StyledSettingsMenuOption = styled.div<{ active: boolean }>`
  ${({ theme, active }) => css`
    display: inline;
    width: fit-content;
    font-size: ${theme.fontSizes.base};
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    color: ${active
      ? theme.colors.text.neutral[6]
      : theme.colors.gray.medium[5]};
    line-height: 1em;
    border-bottom: solid 1px
      ${active ? theme.colors.text.neutral[6] : theme.colors.background[1]};
    &:hover {
      color: ${active
        ? theme.colors.text.neutral[6]
        : theme.colors.text.neutral[1]};
      border-bottom: solid 1px
        ${active ? theme.colors.text.neutral[6] : theme.colors.text.neutral[1]};
    }
  `}
`

const StyledSlider = styled.div<{
  active: boolean
  isLightMode?: boolean
}>`
  ${({ theme, active, isLightMode }) => css`
    padding: 4px;
    width: 80px;
    border-radius: 20px;
    ${isLightMode
      ? css`
          background: ${active
            ? theme.colors.accent[2]
            : theme.colors.gray.medium[6]};
        `
      : css`
          background: ${active
            ? theme.colors.accent[2]
            : theme.colors.gray.dark[5]};
        `}
    margin-bottom: 16px;
  `}
`

const StyledSliderBall = styled.div<{
  active: boolean
  isLightMode?: boolean
}>`
  ${({ theme, active, isLightMode }) => css`
    border-radius: 50%;
    transition: all ease-in-out 0.1s;
    ${isLightMode
      ? css`
          background: ${active
            ? theme.colors.white
            : theme.colors.gray.light[3]};
        `
      : css`
          background: ${active
            ? theme.colors.gray.light[5]
            : theme.colors.gray.medium[0]};
        `}
    height: 24px;
    width: 24px;
    transform: ${active ? 'translateX(48px)' : ''};
  `}
`

const StyledLink = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    color: ${theme.colors.text.neutral[0]};
    cursor: pointer;
    &:hover {
      color: ${theme.colors.accent[2]};
    }
  `}
`
