import React from 'react'

import { Margin } from 'components/common/Margin'
import { Text } from 'components/common/Text'
import { TextSelect, TextSelectOption } from 'components/common/TextSelect'
import UserAvatar from 'components/common/UserAvatar'
import useCurrentUser from 'hooks/useCurrentUser'
import useGetUserByEmail from 'hooks/useGetUserByEmail'

interface UserAccessListItemProps {
  email: string
  options: TextSelectOption[]
  activeOption: TextSelectOption
  onOptionClick: (option: TextSelectOption) => void
  canEdit: boolean
  className?: string
}

const UserAccessListItem: React.FC<UserAccessListItemProps> = (props) => {
  const currentUser = useCurrentUser()
  const isCurrentUser = currentUser?.email === props.email
  const externalUser = useGetUserByEmail(props.email)
  const user = isCurrentUser ? currentUser : externalUser
  return (
    <div
      className={props.className}
      css="display:flex;align-items:center;padding:6px"
    >
      <UserAvatar
        size={36}
        isSquared
        photoUrl={user.photoURL}
        displayName={user.displayName}
      />
      <Margin x={18} />
      <div css="display:flex;flex-direction:column;flex:1">
        <Text size="sm" color="neutral.1">
          {isCurrentUser ? `${user.displayName} (you)` : user.displayName}
        </Text>
        <Text size="sm" color="neutral.3">
          {props.email}
        </Text>
      </div>
      <div>
        {isCurrentUser || !props.canEdit ? (
          <Text size="sm" css="padding: 8px 14px;">
            {props.activeOption.text}
          </Text>
        ) : (
          <TextSelect
            css="width:140px"
            options={props.options}
            activeOption={props.activeOption}
            onOptionClick={props.onOptionClick}
          />
        )}
      </div>
    </div>
  )
}

export default UserAccessListItem
