import { useState } from 'react'
import styled from 'styled-components'

import Flex from 'components/common/Flex'
import Modal from 'components/common/Modal'
import useIsMobileDevice from 'hooks/useIsMobileDevice'

import { Login } from './Login'
import { SetupUser } from './SetupUser'
import { Signup } from './Signup'

type SignupModalProps = {
  isOpen: boolean
  close: () => void
  onSignup: () => void
  onLogin?: () => void
}

export const SignupModal = ({
  isOpen,
  close,
  onSignup,
  onLogin,
}: SignupModalProps) => {
  const [loginView, setLoginView] = useState(false)
  const [setupUser, setSetupUser] = useState(false)
  const isMobileDevice = useIsMobileDevice()
  const StyledModalWrapper = styled(Flex)`
    min-width: ${isMobileDevice ? '100%;' : '550px;'};
    padding: ${isMobileDevice ? '20px;' : '0px;'};
  `

  const StyledWrapper = styled(Flex)`
    width: 320px;
  `

  const onFinishSignup = () => {
    onSignup()
    close()
  }

  const onFinishLogin = () => {
    onLogin?.()
    close()
  }

  return (
    <Modal
      wrapperCss={isMobileDevice ? 'top: -100px;' : ''}
      isOpen={isOpen}
      close={close}
      closeOnOverlayClick={!setupUser}
    >
      <StyledModalWrapper justifyContent="center">
        <StyledWrapper flexDirection="column" py={3}>
          {setupUser && <SetupUser onDone={onFinishSignup} />}
          {loginView && !setupUser && (
            <Login
              onSwitch={() => setLoginView(false)}
              onLogin={onFinishLogin}
              onSignup={() => setSetupUser(true)}
            />
          )}
          {!loginView && !setupUser && (
            <Signup
              onSwitch={() => setLoginView(true)}
              onSignup={() => setSetupUser(true)}
              onLogin={onFinishLogin}
            />
          )}
        </StyledWrapper>
      </StyledModalWrapper>
    </Modal>
  )
}
