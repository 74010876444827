import { ApolloQueryResult } from '@apollo/client'
import { useSelector } from 'react-redux'
import { Diamond } from 'styled-icons/ionicons-outline'

import imgRoomPowerUp from '../../../assets/img/ai_canvas/room_power.png'
import Loading from '../../../components/Loading'
import Modal from '../../../components/common/Modal'
import UserAvatar from '../../../components/common/UserAvatar'
import { useBroadcastEvent } from '../../../config/liveblocks.config'
import {
  CreditsUserQuery,
  EditSessionQuery,
  useAddEditSessionPowerUpMutation,
  useRemoveEditSessionPowerUpMutation,
} from '../../../generated/graphql'
import { reportError as reportSentryError } from '../../../helpers/logging'
import { RootState } from '../../../store'
import { StyledButtonMedium } from '../ButtonMedium'
import { cursorColors } from '../CursorsOthers'
import { LoadingSwitchToggle } from './LoadingSwitchToggle'
import {
  StyledAvatarsContainer,
  StyledCreditsButtonContainer,
  StyledImage,
  StyledImageContainer,
  StyledModalDescription,
  StyledModalHeader,
  StyledPowerByContainer,
  StyledPoweredByHeader,
  StyledToggleContainer,
  StyledToggleHeader,
} from './PowerUpModalStyles'

type Props = {
  showBuyCreditsModal: boolean
  setShowBuyCreditsModal: (show: boolean) => void
  showPowerUpModal: boolean
  setShowPowerUpModal: (show: boolean) => void
  editSessionData: EditSessionQuery | undefined
  editSessionLoading: boolean
  refetchEditSession: () => Promise<ApolloQueryResult<EditSessionQuery>>
  userCreditData: CreditsUserQuery | undefined
}

export const PowerUpModal = ({
  showPowerUpModal,
  setShowPowerUpModal,
  editSessionData,
  editSessionLoading,
  refetchEditSession,
  userCreditData,
  setShowBuyCreditsModal,
  showBuyCreditsModal,
}: Props) => {
  const broadcast = useBroadcastEvent()
  const auth = useSelector((state: RootState) => state.firebase.auth)
  const [
    addEditSessionPowerUpMutation,
    { loading: isAddingEditSessionPowerUp },
  ] = useAddEditSessionPowerUpMutation()
  const [
    removeEditSessionPowerUpMutation,
    { loading: isRemovingEditSessionPowerUp },
  ] = useRemoveEditSessionPowerUpMutation()

  const handleToggle = async (state: 'on' | 'off') => {
    try {
      console.log(`Toggling power up ${state}`)
      if (
        state === 'on' &&
        !isAddingEditSessionPowerUp &&
        editSessionData?.editSession?.id
      ) {
        const { data: addPowerUp } = await addEditSessionPowerUpMutation({
          variables: {
            input: {
              editSessionId: editSessionData.editSession?.id,
            },
          },
        })
        console.log('addPowerUp', addPowerUp)
        broadcast({
          type: 'POWER_UP',
          by: auth.displayName,
          emoji: '⬆️',
        } as never)
        await refetchEditSession()
      }
      if (
        state === 'off' &&
        !isRemovingEditSessionPowerUp &&
        editSessionData?.editSession?.id
      ) {
        const { data: removePowerUp } = await removeEditSessionPowerUpMutation({
          variables: {
            input: {
              editSessionId: editSessionData.editSession?.id,
            },
          },
        })
        console.log('removePowerUp', removePowerUp)
        broadcast({
          type: 'POWER_DOWN',
          by: auth.displayName,
          emoji: '⬇️',
        } as never)
        await refetchEditSession()
      }
    } catch (error) {
      reportSentryError(error)
    }
  }

  const powerUpMembers = [
    ...(editSessionData?.editSession?.powerUp?.poweredBy ?? []),
  ]
  const currentUserHasPowered = powerUpMembers.includes(auth.uid)
  const enrichedMembers = [
    ...(editSessionData?.editSession?.powerUp?.enrichedPowerUpMembers ?? []),
  ]
  const numCredits = Number(
    userCreditData?.user?.generationRequests?.availableCredits
  )
  return (
    <Modal isOpen={showPowerUpModal} close={() => setShowPowerUpModal(false)}>
      <Modal.ContentWrapper
        style={{
          textAlign: 'left',
          alignItems: 'start',
        }}
      >
        {editSessionLoading ? <Loading /> : null}
        {!editSessionLoading && editSessionData ? (
          <>
            <StyledModalHeader>Room power</StyledModalHeader>
            <StyledImageContainer>
              <StyledImage src={imgRoomPowerUp} alt="lightning in room" />
            </StyledImageContainer>
            <StyledModalDescription>
              By powering a room you sponsor participants generations with your
              credit so that everyone in the room can create, and will be
              credited with a badge on the top of the canvas.
            </StyledModalDescription>
            {enrichedMembers.length > 0 ? (
              <StyledPowerByContainer>
                <StyledPoweredByHeader>
                  This room is currently powered by
                </StyledPoweredByHeader>
                <StyledAvatarsContainer>
                  {enrichedMembers.map(({ imageUrl, name }, i) => (
                    <div
                      key={imageUrl}
                      style={{
                        width: 24,
                      }}
                    >
                      <UserAvatar
                        photoUrl={
                          imageUrl && imageUrl !== 'undefined' ? imageUrl : null
                        }
                        displayName={name}
                        size={32}
                        color={cursorColors[i % cursorColors.length]}
                      />
                    </div>
                  ))}
                </StyledAvatarsContainer>
              </StyledPowerByContainer>
            ) : null}
            <StyledToggleContainer>
              <StyledToggleHeader>Power this room</StyledToggleHeader>
              <StyledCreditsButtonContainer>
                {numCredits && numCredits > 0 ? (
                  <StyledButtonMedium
                    onClick={() => setShowBuyCreditsModal(!showBuyCreditsModal)}
                    hasText
                  >
                    <Diamond width={16} />
                    {numCredits} credits left
                  </StyledButtonMedium>
                ) : null}
                {!numCredits || numCredits === 0 ? (
                  <StyledButtonMedium
                    onClick={() => setShowBuyCreditsModal(!showBuyCreditsModal)}
                    hasText
                  >
                    <Diamond width={16} />
                    Buy credits
                  </StyledButtonMedium>
                ) : null}
              </StyledCreditsButtonContainer>
              <LoadingSwitchToggle
                isDisabled={
                  isAddingEditSessionPowerUp ||
                  isRemovingEditSessionPowerUp ||
                  (!currentUserHasPowered && numCredits === 0)
                }
                isLoading={
                  isAddingEditSessionPowerUp || isRemovingEditSessionPowerUp
                }
                isOn={currentUserHasPowered}
                onToggle={() => {
                  if (currentUserHasPowered) {
                    handleToggle('off')
                  } else {
                    handleToggle('on')
                  }
                }}
              />
            </StyledToggleContainer>
          </>
        ) : null}
      </Modal.ContentWrapper>
    </Modal>
  )
}
