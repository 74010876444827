export const optionGroups = [
  {
    title: 'Gender',
    type: 'single',
    items: [
      {
        title: '👦',
        description: 'Male',
        value: 'male',
      },
      {
        title: '👧',
        description: 'Female',
        value: 'female',
      },
    ],
  },
  {
    title: 'Shot type',
    type: 'single',
    items: [
      {
        title: '👁️',
        description: 'Extreme Close Up',
        value: 'xcu',
      },
      {
        title: '👀',
        description: 'Close Up',
        value: 'cu',
      },
      {
        title: '👩',
        description: 'Medium Close Up',
        value: 'mcu',
      },
      {
        title: '🤷‍',
        description: 'Medium shot',
        value: 'ms',
      },
      {
        title: '🕺',
        description: 'Full Shot',
        value: 'fs',
      },
    ],
  },

  {
    title: 'Emotions',
    type: 'single',
    items: [
      {
        title: '😊',
        description: 'Happy',
        value: 'happy',
      },
      {
        title: '😔',
        description: 'Sad',
        value: 'sad',
      },
      {
        title: '😲',
        description: 'Surprised',
        value: 'surprised',
      },
      {
        title: '🤮',
        description: 'Disgusted',
        value: 'disgusted',
      },
      {
        title: '😌',
        description: 'Calm',
        value: 'calm',
      },
      {
        title: '😕',
        description: 'Confused',
        value: 'confused',
      },
      {
        title: '😡',
        description: 'Angry',
        value: 'angry',
      },
    ],
  },

  {
    title: 'Age',
    type: 'single',
    items: [
      {
        title: '👵',
        description: 'Senior',
        value: 'ageRange:60-100',
      },
      {
        title: '👩',
        description: 'Adult',
        value: 'ageRange:30-60',
      },
      {
        title: '👧',
        description: 'Young Adult',
        value: 'ageRange:20-30',
      },
      {
        title: '🧒',
        description: 'Teenager',
        value: 'ageRange:10-20',
      },
      {
        title: '👶',
        description: 'Child',
        value: 'ageRange:3-10',
      },
      {
        title: '🍼',
        description: 'Baby',
        value: 'ageRange:0-3',
      },
    ],
  },
  {
    title: 'Head Rotation Y-axis',
    type: 'single',
    items: [
      {
        title: '0°',
        description: '0° on y-axis',
        value: 'roty:0',
      },
      {
        title: '45°',
        description: '45° on y-axis',
        value: 'roty:45',
      },
      {
        title: '90°',
        description: '90° on y-axis',
        value: 'roty:90',
      },
    ],
  },
  {
    title: 'Head Rotation X-axis',
    type: 'single',
    items: [
      {
        title: '0°',
        description: '0° on x-axis',
        value: 'rotx:0',
      },
      {
        title: '45°',
        description: '45° on x-axis',
        value: 'rotx:45',
      },
      {
        title: '90°',
        description: '90° on x-axis',
        value: 'rotx:90',
      },
    ],
  },
  {
    title: 'Head Rotation Z-axis',
    type: 'single',
    items: [
      {
        title: '0°',
        description: '0° on z-axis',
        value: 'rotz:0',
      },
      {
        title: '45°',
        description: '45° on z-axis',
        value: 'rotz:45',
      },
      {
        title: '90°',
        description: '90° on z-axis',
        value: 'rotz:90',
      },
    ],
  },
]
