import { Grid1x2 } from '@styled-icons/bootstrap/Grid1x2'
import { Layer } from '@styled-icons/boxicons-regular/Layer'
import { Document } from '@styled-icons/ionicons-outline/Document'
import { Resize } from '@styled-icons/ionicons-sharp/Resize'
import { LibraryAdd } from '@styled-icons/material-outlined/LibraryAdd'
import { Palette } from '@styled-icons/material-outlined/Palette'
import { Splitscreen } from '@styled-icons/material-outlined/Splitscreen'
import { Layout5 } from '@styled-icons/remix-line/Layout5'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { v4 as uuid } from 'uuid'

import flip from 'assets/img/socialAssetsPreview_flip.gif'
import grid from 'assets/img/socialAssetsPreview_grid.jpg'
import palette from 'assets/img/socialAssetsPreview_palette.jpg'
import scroll from 'assets/img/socialAssetsPreview_scroll.gif'
import split from 'assets/img/socialAssetsPreview_split.jpg'
import { BetaBadgeLarge } from 'components/common/BetaBadge'
import Button from 'components/common/Button'
import { Margin } from 'components/common/Margin'
import Modal from 'components/common/Modal'
import { Text } from 'components/common/Text'
import SelectBoardAndProject from 'components/selectBoardAndProject/SelectBoardAndProject'
import { RootState } from 'store'

import {
  trackSocialAssetCreationFinished,
  trackSocialAssetCreationStarted,
} from '../../helpers/tracking/tracking'
import {
  setActiveExport,
  setSocialAssetModalOpen,
} from '../../store/content/actions'
import useToastMessages from '../toast/useToastMessages'
import { downloadSocialAssets } from './generateAssets'

const formats = [
  { name: 'Story', width: 1080, height: 1920 },
  { name: 'Post', width: 1080, height: 1350 },
  { name: 'Square', width: 1080, height: 1080 },
  { name: 'Big Square', width: 4000, height: 4000 },
]
const templates = [
  {
    displayName: 'Grid',
    functionName: 'GRID_STILL',
    icon: <Grid1x2 />,
    hoverImage: grid,
  },
  {
    displayName: 'Split',
    functionName: 'SPLIT_SCREEN_STILL',
    icon: <Splitscreen />,
    hoverImage: split,
  },
  {
    displayName: 'Palette',
    functionName: 'PALETTE_STILL',
    icon: <Palette />,
    hoverImage: palette,
  },
  {
    displayName: 'Scroll',
    functionName: 'SCROLL_VIDEO',
    icon: <Splitscreen />,
    hoverImage: scroll,
  },
  {
    displayName: 'Flip',
    functionName: 'FLIP_VIDEO',
    icon: <Layer />,
    hoverImage: flip,
  },
  {
    displayName: 'Flip Auto',
    functionName: 'FLIP_VIDEO_CONTAIN',
    icon: <Layer />,
    hoverImage: flip,
  },
]

export const CreateAssetsPanel: React.FC = () => {
  const dispatch = useDispatch()
  const [isSelectBoardOpen, setIsSelectBoardOpen] = useState(false)
  const [sourceText, setSourceText] = useState('')
  const [brandingOn, setBrandingOn] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [activeFormat, setActiveFormat] = useState(formats[0])
  const [activeTemplates, setActiveTemplates] = useState<string[]>([
    templates[0].functionName,
  ])

  const activeExport = useSelector(
    (state: RootState) => state.content.activeExport
  )
  const canRemoveBranding = useSelector(
    (state: RootState) =>
      state.firestore.data.userPrivate?.featureAccess
        ?.createSocialAssetsWithoutBranding
  )

  useEffect(() => {
    let sourceTextToUpdate = 'Select a source'
    if (activeExport?.itemIds) {
      setSourceText(`${activeExport.itemIds.length} items`)
    }
    if (activeExport?.boardTitle) {
      sourceTextToUpdate = `Board ${activeExport.boardTitle}`
    }
    if (activeExport?.uploadSessionId) sourceTextToUpdate = 'Upload event'
    setSourceText(sourceTextToUpdate)
  }, [activeExport])

  const { reportSuccess, reportError } = useToastMessages()
  const handleCreate = async () => {
    const creationId = uuid()
    trackSocialAssetCreationStarted({
      activeFormatWitdh: activeFormat.width,
      activeFormatHeight: activeFormat.height,
      includeBranding: brandingOn,
      activeTemplates,
      nrTemplates: activeTemplates.length,
      sourceText,
      creationId,
    })
    setIsLoading(true)
    try {
      await downloadSocialAssets({
        ...activeExport,
        useTemplates: activeTemplates,
        settings: {
          width: activeFormat.width,
          height: activeFormat.height,
          includeFooter: brandingOn,
        },
      })
      setIsLoading(false)
      trackSocialAssetCreationFinished({
        status: 'SUCCESS',
        creationId,
      })
      reportSuccess('Successfully generated assets!')
    } catch (e) {
      setIsLoading(false)
      trackSocialAssetCreationFinished({
        status: 'FAILURE',
        creationId,
      })
      reportError('Could not generate assets.', e)
    }
  }

  const isFormatsAndTemplateDisabled = !activeExport
  return (
    <>
      <Wrapper>
        <div style={{ position: 'absolute', right: 24 }}>
          <BetaBadgeLarge />
        </div>
        <Text size="lg" color="neutral.0">
          Export
        </Text>

        <Margin y={8} />
        <Text size="sm" color="neutral.1">
          Generate assets for social media from your items
        </Text>
        <Margin y={16} />
        <Heading text="Source" icon={<Document />} />
        <Text
          size="base"
          color={isFormatsAndTemplateDisabled ? 'accent.2' : 'neutral.1'}
          style={{
            cursor: 'pointer',
          }}
          onClick={() => setIsSelectBoardOpen(true)}
        >
          {sourceText}
        </Text>
        <Modal
          isOpen={isSelectBoardOpen}
          width="300px"
          close={() => setIsSelectBoardOpen(false)}
        >
          <SelectBoardAndProject
            headline="Select source board"
            onMenuClose={() => {
              setIsSelectBoardOpen(false)
            }}
            selectType="SELECT_BOARD"
            boardAccessLevel="reader"
            onSelectBoard={(data) => {
              setSourceText(`${data.boardTitle}`)
              dispatch(
                setActiveExport({
                  boardId: data.boardId,
                  boardTitle: data.boardTitle,
                })
              )
              setIsSelectBoardOpen(false)
            }}
          />
        </Modal>
        <Heading text="Format" icon={<Resize />} />
        <Margin y={8} />
        {formats.map((format) => (
          <FormatCard
            isDisabled={isFormatsAndTemplateDisabled}
            handleClick={() => setActiveFormat(format)}
            isSelected={activeFormat.name === format.name}
            name={format.name}
            width={format.width}
            height={format.height}
          />
        ))}
        <Margin y={8} />
        <Heading text="Template" icon={<Layout5 />} />
        <Text size="sm" color="neutral.4">
          Select one or more.
        </Text>
        <Margin y={8} />
        {templates.map((template) => (
          <TemplateCard
            isDisabled={isFormatsAndTemplateDisabled}
            icon={template.icon}
            hoverImage={template.hoverImage}
            name={template.displayName}
            isSelected={activeTemplates.includes(template.functionName)}
            handleClick={() => {
              const functionName = template.functionName
              // Select new template
              const index = activeTemplates.indexOf(functionName)
              if (index === -1) {
                return setActiveTemplates([...activeTemplates, functionName])
              }
              // Deselect template
              setActiveTemplates(activeTemplates.filter((el, i) => i !== index))
            }}
          />
        ))}
        {canRemoveBranding && (
          <BrandingSwitch
            brandingOn={brandingOn}
            onClick={() => setBrandingOn(!brandingOn)}
          >
            {brandingOn ? 'Branding on' : 'Branding off'}
          </BrandingSwitch>
        )}
        <Margin y={32} />
        <Button
          disabled={activeTemplates.length === 0 || !activeExport}
          variant="primary"
          isLoading={isLoading}
          Icon={LibraryAdd}
          onClick={handleCreate}
        >
          {activeTemplates.length > 1 ? (
            <>
              {isLoading
                ? `Creating ${activeTemplates.length} assets`
                : `Create ${activeTemplates.length} assets`}
            </>
          ) : (
            <> {isLoading ? 'Creating asset' : 'Create asset'}</>
          )}
        </Button>
      </Wrapper>
    </>
  )
}

export const CreateAssetsModal: React.FC = () => {
  const dispatch = useDispatch()
  const isCreateSocialAssetsModalOpen = useSelector(
    (state: RootState) => state.content.isSocialAssetModalOpen
  )

  const handleCloseModal = () => {
    dispatch(setActiveExport())
    dispatch(setSocialAssetModalOpen(false))
  }
  return (
    <Modal
      height="auto"
      width="800px"
      isOpen={isCreateSocialAssetsModalOpen}
      close={handleCloseModal}
    >
      <CreateAssetsPanel />
    </Modal>
  )
}

interface FormatCardInput {
  name: string
  width: number
  height: number
  isSelected: boolean
  isDisabled: boolean
  handleClick: React.MouseEventHandler<HTMLDivElement>
}
const FormatCard = ({
  name,
  width,
  height,
  isSelected,
  handleClick,
  isDisabled,
}: FormatCardInput) => {
  return (
    <Card isSelected={isSelected} onClick={handleClick} isDisabled={isDisabled}>
      <FormatIcon aspect={width / height} />
      <Margin y={8} />
      <Text size="xs">{name}</Text>
      <Text size="2xs" color="neutral.4">
        {width} x {height}
      </Text>
    </Card>
  )
}

interface TemplateCardInput {
  name: string
  icon: React.ReactNode
  isSelected: boolean
  isDisabled: boolean
  handleClick: React.MouseEventHandler<HTMLDivElement>
  hoverImage?: any
}
const TemplateCard = ({
  icon,
  name,
  isSelected,
  handleClick,
  hoverImage,
  isDisabled,
}: TemplateCardInput) => {
  return (
    <Card
      hoverImage={hoverImage}
      isSelected={isSelected}
      onClick={handleClick}
      isDisabled={isDisabled}
    >
      <div style={{ width: 20, height: 20 }}>{icon}</div>
      <Margin y={8} />
      <Text size="sm">{name}</Text>
    </Card>
  )
}

const Heading = ({ icon, text }: { icon: React.ReactNode; text: string }) => {
  return (
    <>
      <Margin y={16} />
      <Text size="sm" color="neutral.3">
        {text}
        <div style={{ width: 14, display: 'inline-block', marginLeft: 4 }}>
          {icon}
        </div>
      </Text>
    </>
  )
}

const BrandingSwitch = styled.div<{
  brandingOn: boolean
}>`
  ${({ theme, brandingOn }) => css`
    margin-top: 16px;
    width: auto;
    cursor: pointer;
    color: ${brandingOn
      ? theme.colors.text.neutral[1]
      : theme.colors.text.neutral[4]};
    &:hover {
      text-decoration: underline;
    }
  `}
`

const Card = styled.div<{
  isSelected?: boolean
  hoverImage?: any
  isDisabled: boolean
}>`
  ${({ theme, isSelected, hoverImage, isDisabled }) => css`
    padding: 8px;
    background-color: ${theme.colors.background[2]};
    width: 96px;
    height: 120px;
    border-radius: ${theme.borderRadius.default};
    border: 1px solid ${isSelected ? theme.colors.accent[2] : 'transparent'};
    color: ${isSelected
      ? theme.colors.accent[2]
      : theme.colors.text.neutral[3]};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin: 0px 8px 8px 0px;
    position: relative;
    &:hover {
      color: ${theme.colors.white};
      cursor: pointer;
      background-image: ${hoverImage ? `url(${hoverImage})` : 'none'};
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
    }
    @media (max-width: 500px) {
      width: calc(25% - 4px);
      height: 96px;
      padding: 4px;
      margin: 0px 4px 4px 0px;
    }
    ${isDisabled &&
    `
     border-color:  transparent;
     color: ${theme.colors.text.neutral[3]};
     &:hover {
      color: inherit;
      cursor: not-allowed;
      background-image: none;
     }
    `}
  `}
`

const FormatIcon = styled.div<{
  aspect: number
}>`
  ${({ theme, aspect }) => css`
    background-color: ${theme.colors.background[2]};
    width: ${aspect * 32}px;
    height: 32px;
    border-radius: ${theme.borderRadius.sm};
    border: 2px solid;
  `}
`

const Wrapper = styled.div`
  padding: 32px;
  @media (max-width: 500px) {
    padding: 16px;
  }
`
