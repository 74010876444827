import { motion } from 'framer-motion'
import styled, { css, useTheme } from 'styled-components'

import { AnimatedCard } from 'components/library/Common'
import { CardImage, CardTitle, Overlay } from 'components/library/LibraryStyles'
import { timeAgo } from 'helpers/utilityFunctions'
import useGetUser from 'hooks/useGetUser'

const UserName = ({ userId }: { userId: string }) => {
  const user = useGetUser(userId)
  return <b>{user?.displayName}</b>
}

const infoTransitions = {
  inital: {
    opacity: 0,
    y: 20,
  },
  hover: {
    opacity: 1,
    y: 0,
  },
}

const overlayTransitions = {
  inital: {
    opacity: 0.4,
  },
  hover: {
    opacity: 0,
  },
}

const transition = {
  ease: 'easeIn',
  duration: 0.1,
}

type UploadEventPreviewCardProps = {
  title: string
  infoTextOne?: string
  infoTextTwo?: string
  createdBy: string
  createdAt?: string | number
  thumbUrl?: string
  isCreatingMagicBoards: boolean
  isProcessing: boolean
}

export const UploadEventPreviewCard = (props: UploadEventPreviewCardProps) => {
  const theme = useTheme()

  return (
    <StyledUploadEventPreviewCardWrapper whileHover="hover" initial="inital">
      <AnimatedCard processing={props.isProcessing}>
        <CardImage src={props.thumbUrl ?? ''} />
        <CardTitle
          color={theme.colors.accent[0]}
          $processing={props.isCreatingMagicBoards}
        >
          {props.title}
        </CardTitle>
        <Overlay variants={overlayTransitions} transition={transition} />
        <StyledInfo variants={infoTransitions} transition={transition}>
          <StyledColumn>
            <StyledInfoItem>
              {props.infoTextOne && <b>{props.infoTextOne}</b>}
            </StyledInfoItem>
            <StyledInfoItem thin>{props.infoTextTwo}</StyledInfoItem>
          </StyledColumn>

          <StyledColumn style={{ alignItems: 'end' }}>
            <StyledInfoItem>
              <UserName userId={props.createdBy} />
            </StyledInfoItem>
            <StyledInfoItem thin>
              {timeAgo(props.createdAt || Date.now())}
            </StyledInfoItem>
          </StyledColumn>
        </StyledInfo>
      </AnimatedCard>
    </StyledUploadEventPreviewCardWrapper>
  )
}

const StyledUploadEventPreviewCardWrapper = styled(motion.div)(
  () => css`
    width: 100%;
    overflow: hidden;
    text-align: center;
    :hover ${CardImage} {
      transform: scale(
        1.05
      ) !important; // safari has some issues with animation so we handle this one manually
    }
  `
)

const StyledInfo = styled(motion.div)`
  ${({ theme }) => css`
    padding: 0px 12px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    color: white;
    align-items: center;
    width: 100%;
    font-weight: ${theme.fontWeight.normal};
    font-size: ${theme.fontSizes.sm};
    position: absolute;
    bottom: 0;
    text-shadow: 0px 1px 2px rgb(0 0 0 / 56%);
    height: 56px;
    @media (max-width: 1300px) {
      fontsize: ${theme.fontSizes.sm};
    }
    @media (max-width: 768px) {
      font-size: ${theme.fontSizes.xs};
      padding: 8px 16px;
    }
  `}
`

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
`

const StyledInfoItem = styled.div<{ thin?: boolean }>`
  display: flex;
  align-items: center;

  ${({ theme, thin }) =>
    thin &&
    css`
      font-weight: ${theme.fontWeight.light};
    `}
`
