import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { v4 as uuidv4 } from 'uuid'

import { reportError as logError } from '../../helpers/logging'
import { HoverReplace } from '../common/HoverReplace'
import { ToastContainer, ToastItem } from './ToastStyles'

export const permissionInfoText = (feature) =>
  `You don't have permission to ${feature}`

export const ToastContext = createContext({
  /**
   * Display a success message to the user
   * @param {string | JSX.Element} message
   * @param {{linkTo?: string, linkText?: string}=} options
   * @param {number=} timeout
   * @return {null}
   */
  reportSuccess(message, options, timeout) {
    return null
  },

  /**
   * Display an error message to the user and logs the error
   * @param {string} errorMessage
   * @param {any} [error]
   * @param {number=} timeout
   * @return {null}
   */
  reportError(errorMessage, error, timeout) {
    return null
  },
})

export default function ToastMessages({ children }) {
  const [messages, setMessages] = useState([])
  const addMessage = useCallback((message) => {
    const id = uuidv4()
    setMessages((prev) => [...prev, { ...message, id }])
    // Timeout message removal
    setTimeout(() => {
      setMessages((prev) => prev.filter((m) => m.id !== id))
    }, message.timeout ?? 10000)
  }, [])

  // make sure we have at the most 5 toast messages
  useEffect(() => {
    if (messages.length > 5) {
      setMessages((prev) => prev.slice(1))
    }
  }, [messages.length])

  const reportError = useCallback(
    (errorMessage, error, timeout) => {
      // Report error up here, making sure it happens.
      if (error) {
        logError(error)
      }
      if (errorMessage) {
        addMessage({
          type: 'error',
          message: errorMessage,
          timeout: timeout || 3000,
        })
      }
    },
    [addMessage]
  )
  const reportSuccess = useCallback(
    (message, options, timeout) => {
      addMessage({
        type: 'success',
        message,
        options,
        timeout: timeout || 3000,
      })
    },
    [addMessage]
  )
  return (
    <ToastContext.Provider
      value={useMemo(
        () => ({ reportError, reportSuccess }),
        [reportError, reportSuccess]
      )}
    >
      <ToastContainer>
        {messages.map((item) => (
          <ToastItem
            key={item.id}
            type={item.type}
            options={item.options}
            onClick={() =>
              setMessages((prev) => [
                ...prev.filter((listMsg) => listMsg.id !== item.id),
              ])
            }
          >
            {item.options?.linkTo ? (
              item.message
            ) : (
              <HoverReplace
                style={{ justifyItems: 'center', alignItems: 'center' }}
                onHover="Hide"
              >
                {item.message}
              </HoverReplace>
            )}
          </ToastItem>
        ))}
      </ToastContainer>
      {children}
    </ToastContext.Provider>
  )
}
