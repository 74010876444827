import React from 'react'
import ReactPlayer from 'react-player/lazy'

import imageOne from 'assets/img/extensionQuickStart1.jpg'
import imageThree from 'assets/img/extensionQuickstart_2.jpg'
import video from 'assets/video/ChromeExtensionSaveMultiple2.mp4'
import { Text } from 'components/common/Text'

import ChromeExtensionQuickStartBox from './ChromeExtensionQuickStartBox'
import {
  CardContainer,
  Container,
  Heading,
  HeadingSmall,
  InfoCard,
  VisualBlock,
} from './ChromeExtensionQuickStartStyles'

const ChromeExtensionQuickStart = () => {
  return (
    <Container>
      <ChromeExtensionQuickStartBox />
      <Heading>How to</Heading>
      <HeadingSmall>use the Kive extension</HeadingSmall>
      <CardContainer>
        <InfoCard>
          <Text size="sm" color="neutral.3">
            1
          </Text>
          <VisualBlock image={imageOne} />
          <Text size="base" color="neutral.0">
            Pin the extension, then click the Kive icon in the upper right
            corner to start.
          </Text>
        </InfoCard>
        <InfoCard>
          <Text size="sm" color="neutral.3">
            2
          </Text>
          <VisualBlock>
            <ReactPlayer
              url={video}
              playing
              playsinline
              loop
              muted
              height="100%"
              width="100%"
              config={{
                file: {
                  attributes: {
                    autoPlay: true,
                    muted: true,
                  },
                },
              }}
            />
          </VisualBlock>
          <Text size="base" color="neutral.0">
            Click <i>Select items</i> to save multiple images from a page, or
            hover an image on the web and click <i>Save</i> to add to your
            workspace in Kive.
          </Text>
        </InfoCard>
        <InfoCard>
          <Text size="sm" color="neutral.3">
            3
          </Text>
          <VisualBlock image={imageThree} />

          <Text size="base" color="neutral.0">
            Go to kive.ai to access, search and share all your saved items.
          </Text>
        </InfoCard>
      </CardContainer>
    </Container>
  )
}

export default ChromeExtensionQuickStart
