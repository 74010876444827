import { Favorite } from '@styled-icons/material-outlined/Favorite'
import { FavoriteBorder } from '@styled-icons/material/FavoriteBorder'
import { StyledIcon } from '@styled-icons/styled-icon'
import { motion } from 'framer-motion'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useFirestore } from 'react-redux-firebase'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import Button from 'components/common/Button'
import { Margin } from 'components/common/Margin'
import Modal from 'components/common/Modal'
import { Text } from 'components/common/Text'
import { Tooltip } from 'components/common/Tooltip/Tooltip'
import { RootState } from 'store'

interface FavoriteBoardButtonProps {
  boardId: string
  children?: ({
    handleFavoriteClick,
    isFavoured,
    Icon,
  }: {
    handleFavoriteClick: () => void
    isFavoured: boolean
    Icon: StyledIcon
  }) => React.ReactNode
}

const FavoriteBoardButton: React.FC<FavoriteBoardButtonProps> = (props) => {
  const firestore = useFirestore()
  const [isAuthModalVisible, setIsAuthModalVisible] = useState(false)

  const auth = useSelector((state: RootState) => state.firebase.auth)

  const favoriteBoards = useSelector(
    (state: RootState) =>
      state.firestore.data.userPrivate?.favorites?.boards || []
  )

  const isFavoured = favoriteBoards.includes(props.boardId)

  const handleFavoriteClick = () => {
    if (auth.isEmpty) {
      return setIsAuthModalVisible(true)
    }

    const userFavoritesRef = firestore
      .collection('users')
      .doc(auth.uid)
      .collection('private')
      .doc('favorites')

    userFavoritesRef.set(
      {
        boards: isFavoured
          ? firestore.FieldValue.arrayRemove(props.boardId)
          : firestore.FieldValue.arrayUnion(props.boardId),
      },
      { merge: true }
    )
  }

  const FavoriteIcon = isFavoured ? Favorite : FavoriteBorder
  return (
    <div>
      {typeof props.children === 'function' ? (
        props.children({ handleFavoriteClick, isFavoured, Icon: FavoriteIcon })
      ) : (
        <Tooltip
          distance={8}
          position="bottom"
          title={
            isFavoured
              ? 'Remove board from your favorite list'
              : 'Add this board to your favorite list'
          }
          isDisabled={auth.isEmpty}
        >
          <StyledLink onClick={handleFavoriteClick}>
            <motion.div whileTap={{ scale: 1.5 }}>
              <FavoriteIcon style={{ height: '18px' }} />
            </motion.div>
            {auth.isEmpty && (
              <>
                <Margin x={12} />
                <Text size="sm">Save this board</Text>
              </>
            )}
          </StyledLink>
        </Tooltip>
      )}

      <Modal
        isOpen={isAuthModalVisible}
        close={() => setIsAuthModalVisible(false)}
      >
        <Modal.ContentWrapper>
          <Text size="lg" as="h2" color="neutral.0">
            You must be signed in to save this board
          </Text>
          <Margin y={16} />
          <div css="display: flex">
            <Link
              to={`/signup?fromPublicFeature=true&boardId=${props.boardId}&redirectUrl=${window.location.href}`}
            >
              <Button variant="primary">Sign up</Button>
            </Link>
            <Margin x={16} />
            <Button onClick={() => setIsAuthModalVisible(false)}>Close</Button>
          </div>
        </Modal.ContentWrapper>
      </Modal>
    </div>
  )
}

export default FavoriteBoardButton

const StyledLink = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    color: ${theme.colors.text.neutral[0]};
    cursor: pointer;
    padding: 6px;
    &:hover {
      color: ${theme.colors.accent[2]};
    }
  `}
`
