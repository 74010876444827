import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

const spring = {
  type: 'spring',
  stiffness: 500,
  damping: 30,
}

type SwitchToggleProps = {
  isOn: boolean
  onToggle: () => void
}

export const SwitchToggle = (props: SwitchToggleProps) => {
  return (
    <StyledSwitch isOn={props.isOn} onClick={props.onToggle}>
      <StyledMotionHandle isOn={props.isOn} layout transition={spring} />
    </StyledSwitch>
  )
}

const StyledSwitch = styled.div<{ isOn: boolean }>`
  ${({ theme, isOn }) => css`
    width: 24px;
    height: 15px;
    background-color: ${isOn
      ? theme.colors.accent[2]
      : theme.colors.gray.medium[1]};
    display: flex;
    justify-content: flex-start;
    border-radius: 25px;
    padding: 1.5px;
    cursor: pointer;
    ${isOn &&
    css`
      justify-content: flex-end;
    `}
  `}
`

const StyledMotionHandle = styled(motion.div)<{ isOn: boolean }>`
  ${({ theme, isOn }) => css`
    width: 12px;
    height: 12px;
    background-color: ${isOn
      ? theme.colors.white
      : theme.colors.gray.medium[6]};
    border-radius: 20px;
  `}
`
