import * as Clipboard from 'copy-image-clipboard'

import type { Item } from 'components/grid/griditem/GridItem'
import getItemSizeUrl from 'helpers/getItemSizeUrl'

import { getItemMediaUrl } from './getItemMediaUrl'

export const copyImageToClipboard = (focusedItem: Item) => {
  if ('isTypesenseItem' in focusedItem) {
    return Clipboard.copyImageToClipboard(focusedItem.imgUrl)
  }
  if ('__typename' in focusedItem) {
    return Clipboard.copyImageToClipboard(
      getItemMediaUrl({
        item: focusedItem,
        variant: 'large',
        mediaType: 'image',
      })!
    )
  }

  return Clipboard.copyImageToClipboard(
    getItemSizeUrl(focusedItem, 1600, 'jpg') as string
  )
}
