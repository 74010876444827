import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline'
import { Eye } from '@styled-icons/ionicons-solid/Eye'
import { ArrowDownward } from '@styled-icons/material/ArrowDownward'
import { ArrowForward } from '@styled-icons/material/ArrowForward'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { KiveLogo } from 'assets/svg/KiveLogo'
import Button from 'components/common/Button'
import ButtonHoverDropdown from 'components/common/ButtonHoverDropdown'
import Dropdown from 'components/common/Dropdown'
import Flex from 'components/common/Flex'
import IconButton from 'components/common/IconButton'
import { Text } from 'components/common/Text'
import { DiscoverSaveItem } from 'components/discover/DiscoverSaveItem'
import { MediaVariants } from 'generated/graphql'
import StopPropagation from 'helpers/StopPropagation'
import downloadItem from 'helpers/downloadItem'
import useGetWorkspace from 'hooks/useGetWorkspace'
import useWorkspacePermissions from 'hooks/useWorkspacePermissions'
import { RootState } from 'store'
import { hideOnMobile } from 'styles/shared'

import {
  getMediaVariant,
  getMediaVariantsForMediaType,
  getTitleFromMediaVariant,
} from './getItemVersions'
import { ItemData } from './hooks/useGetItemData'

const StyledActionBar = styled.div`
  height: 76px;
  display: flex;
  align-items: center;
  padding: 0 48px;
  @media (max-width: 500px) {
    padding: 0 16px;
  }
`

const StyledActionMain = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const StyledButtonWrapper = styled.div`
  margin: 0 8px;
`

const StyledDesktopButtonWrapper = styled.div`
  margin: 0 8px;
  ${hideOnMobile}
`

const StyledDesktopLink = styled(Link)`
  ${hideOnMobile}
`

const StyledTextWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.text.neutral[0]};
  margin: 0 8px;
  @media (max-width: 500px) {
    display: none;
  }
`

const StyledIconWrapper = styled.div`
  margin: 0 8px;
`

interface CTAButtonProps {
  isLoggedIn: boolean
  workspaceItemUrl?: string
  signupItemQueryString: string
}

const CTAButton: React.FC<CTAButtonProps> = ({
  isLoggedIn,
  workspaceItemUrl,
  signupItemQueryString,
}) => {
  if (isLoggedIn && workspaceItemUrl) {
    return (
      <StyledDesktopLink to={workspaceItemUrl}>
        <Button variant="primary">View item in workspace</Button>
      </StyledDesktopLink>
    )
  }
  if (!isLoggedIn) {
    return (
      <Link to={`/signup?fromPublicFeature=true&${signupItemQueryString}`}>
        <Button variant="primary" Icon={ArrowForward}>
          Get Kive for free
        </Button>
      </Link>
    )
  }
  return null
}

interface PublicItemActionBarTopProps {
  item: ItemData
  isCloseable?: false
  downloadable: boolean
  saveable: boolean
  trackingSessionId?: string
}
interface PublicItemActionBarTopCloseableProps {
  item: ItemData
  isCloseable: true
  onCloseItemView: () => void
  downloadable: boolean
  saveable: boolean
  trackingSessionId?: string
}

const PublicItemActionBarTop: React.FC<
  PublicItemActionBarTopProps | PublicItemActionBarTopCloseableProps
> = (props) => {
  const { item } = props
  const [isDownloading, setIsDownloading] = useState(false)
  const activeWorkspaceId = useSelector(
    (state: RootState) => state.content.activeWorkspace?.id as string
  )
  const isLoggedIn = useSelector(
    (state: RootState) => !!state.firebase.auth.uid
  )
  const { workspace } = useGetWorkspace(item.workspaceId)

  const isBoardItem = 'boardId' in item

  const workspaceItemUrl =
    workspace && isBoardItem
      ? `/${workspace.url}/p/${item.projectId}/${item.boardId}?item=${item.id}&itemSpace=projects`
      : workspace &&
        `/${workspace.url}/library/items?item=${item.id}&itemSpace=uploads`

  const params = useParams<{ boardId: string; projectId: string }>()

  const downloadContext = {
    itemId: item?.id as string,
    boardId: params.boardId,
    projectId: params.projectId,
    workspaceId: activeWorkspaceId,
  }

  const handleDownloadMediaVariant = async (mediaVariant: MediaVariants) => {
    setIsDownloading(true)
    await downloadItem(
      { publicUrl: mediaVariant.url },
      item.title || 'image',
      downloadContext
    )
    setIsDownloading(false)
  }

  const handleDownloadDefault = () => {
    const mediaVariantToDownload = getMediaVariant({
      mediaVariants: item.mediaVariants,
      variant: 'large',
      mediaType: item.mediaType,
    })
    if (!mediaVariantToDownload) {
      return console.error('Media variant was not found')
    }
    return handleDownloadMediaVariant(mediaVariantToDownload)
  }

  const signupItemQueryString = isBoardItem
    ? `boardItemId=${item?.id}`
    : `itemId=${item?.id}`

  return (
    <StyledActionBar>
      {props.isCloseable ? (
        <IconButton Icon={CloseOutline} onClick={props.onCloseItemView} />
      ) : (
        <Link to="/">
          <KiveLogo color="#fff" height={30} />
        </Link>
      )}
      <StyledActionMain>
        <StopPropagation>
          <Flex alignItems="center">
            {props.saveable && item?.id && item?.createdBy && (
              <SavePublicItemButton
                itemId={item.id}
                isBoardItem={isBoardItem}
              />
            )}
            {/* TODO: add views */}
            {(item?.openCount ?? 0) > 0 && (
              <StyledTextWrapper>
                <StyledIconWrapper>
                  <Eye style={{ height: 16 }} />
                </StyledIconWrapper>
                <Text size="sm">
                  {item!.openCount === 1
                    ? '1 view'
                    : `${item!.openCount} views`}
                </Text>
              </StyledTextWrapper>
            )}
            <StyledDesktopButtonWrapper>
              {props.downloadable && (
                <ButtonHoverDropdown
                  onClick={handleDownloadDefault}
                  title="Download"
                  Icon={ArrowDownward}
                  isLoading={isDownloading}
                >
                  {getMediaVariantsForMediaType({
                    mediaVariants: item.mediaVariants,
                    mediaType: item.mediaType,
                  }).map((mediaVariant) => {
                    const title = getTitleFromMediaVariant({
                      variant: mediaVariant.variant,
                      mediaType: item.mediaType,
                    })
                    if (!title) return null
                    return (
                      <Dropdown.Item
                        key={mediaVariant.variant}
                        title={title}
                        onClick={() => {
                          handleDownloadMediaVariant(mediaVariant)
                        }}
                      />
                    )
                  })}
                </ButtonHoverDropdown>
              )}
            </StyledDesktopButtonWrapper>
            <StyledButtonWrapper style={{ marginRight: 0 }}>
              <CTAButton
                isLoggedIn={isLoggedIn}
                workspaceItemUrl={workspaceItemUrl}
                signupItemQueryString={signupItemQueryString}
              />
            </StyledButtonWrapper>
          </Flex>
        </StopPropagation>
      </StyledActionMain>
    </StyledActionBar>
  )
}

export default PublicItemActionBarTop

interface SavePublicItemButtonProps {
  itemId: string
  isBoardItem?: boolean
}

const SavePublicItemButton: React.FC<SavePublicItemButtonProps> = ({
  itemId,
  isBoardItem,
}) => {
  const activeWorkspace = useSelector(
    (store: RootState) => store.content.activeWorkspace!
  )
  const userAllowedTo = useWorkspacePermissions(['UPLOAD'])

  const allowedToSave = userAllowedTo.UPLOAD && activeWorkspace.id

  if (!allowedToSave) return null
  return (
    <DiscoverSaveItem itemId={itemId} isBoardItem={isBoardItem} size="large" />
  )
}
