import { Facebook } from '@styled-icons/bootstrap/Facebook'
import { Instagram } from '@styled-icons/bootstrap/Instagram'
import { QuestionCircle } from '@styled-icons/bootstrap/QuestionCircle'
import { Share } from '@styled-icons/bootstrap/Share'
import { Twitter } from '@styled-icons/boxicons-logos/Twitter'
import { UserPlus } from '@styled-icons/boxicons-regular/UserPlus'
import { Email } from '@styled-icons/material-outlined/Email'
import { motion } from 'framer-motion'
import qs from 'query-string'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFirestore } from 'react-redux-firebase'
import styled, { css } from 'styled-components'
import { v4 as uuid } from 'uuid'

import Button from 'components/common/Button'
import Flex from 'components/common/Flex'
import { Margin } from 'components/common/Margin'
import Modal from 'components/common/Modal'
import SwitchToggle from 'components/common/SwitchToggle'
import { Text } from 'components/common/Text'
import { TextSelect, TextSelectOption } from 'components/common/TextSelect'
import { Tooltip } from 'components/common/Tooltip/Tooltip'
import useToastMessages from 'components/toast/useToastMessages'
import useBoardPermissions from 'hooks/useBoardPermissions'
import useQueryString from 'hooks/useQueryString'
import { RootState } from 'store'
import { setActiveExport, setSocialAssetModalOpen } from 'store/content/actions'
import { DBBoard } from 'types/db'

import {
  trackBoardSharingPanelOpened,
  trackBoardSharingPanelShareIntended,
} from '../../helpers/tracking/tracking'
import UserAccessListItem from './UserAccessListItem'
import { linkOptions, userOptions } from './sharingOptions'

interface UserEmailAndRole {
  email: string
  role: string
}

interface BoardSharingPanelProps {
  board: DBBoard
  boardId: string
  handleClose: () => void
}

const BoardSharingPanel: React.FC<BoardSharingPanelProps> = (props) => {
  const [boardSharingPanelSessionId, setBoardSharingPanelSessionId] = useState<
    string | null
  >(null)

  const firestore = useFirestore()
  const dispatch = useDispatch()
  const userAllowedTo = useBoardPermissions(props.board, [
    'EDIT_BOARD_USER_PERMISSIONS',
    'CREATE_SOCIAL_ASSETS',
  ])
  const { reportSuccess } = useToastMessages()
  const [shareView, setShareView] = useState<'default' | 'social' | 'invite'>(
    'default'
  )
  const queryParams = useQueryString<{ reactionFilters?: string | string[] }>()

  const isLightTheme = useSelector(
    (state: RootState) => state.content.theme === 'lightTheme'
  )

  useEffect(() => {
    const boardSharingPanelSessionId_ = uuid()
    setBoardSharingPanelSessionId(boardSharingPanelSessionId_)
    trackBoardSharingPanelOpened({
      boardSharingPanelSessionId: boardSharingPanelSessionId_,
    })
  }, [])

  const usersByRoleGroup: Record<string, string[]> = {
    owners: props.board.owners || [],
    writers: props.board.writers || [],
    commenters: props.board.commenters || [],
    readers: props.board.readers || [],
  }

  const shareableLink = `${window.location.origin}/b/${props.boardId}`

  const handleRemoveUser = (user: UserEmailAndRole) => {
    const roleGroup = `${user.role}s`
    firestore.update(
      { collection: 'boards', doc: props.boardId },
      {
        [roleGroup]: firestore.FieldValue.arrayRemove(user.email),
      }
    )
  }

  const handleUserAccessOptionClick = (
    option: TextSelectOption,
    user: UserEmailAndRole
  ) => {
    if (option.key === 'remove') {
      return handleRemoveUser(user)
    }

    const oldRoleGroup = `${user.role}s`
    const newRoleGroup = `${option.key}s`

    if (newRoleGroup === oldRoleGroup) return

    firestore.update(
      { collection: 'boards', doc: props.boardId },
      {
        [newRoleGroup]: firestore.FieldValue.arrayUnion(user.email),
        [oldRoleGroup]: firestore.FieldValue.arrayRemove(user.email),
      }
    )
  }

  const copyPublicBoardLink = async () => {
    const searchParams = qs.stringify(
      {
        reactionFilters: queryParams.reactionFilters,
      },
      { arrayFormat: 'comma' }
    )

    navigator.clipboard.writeText(
      `${shareableLink}${searchParams ? `?${searchParams}` : ''}`
    )

    const doc = await firestore.collection('boards').doc(props.boardId).get()
    const { linkAccess } = doc.data() as DBBoard

    if (linkAccess === undefined) {
      firestore.update(
        { collection: 'boards', doc: props.boardId },
        { linkAccess: 'reader' }
      )
    }

    reportSuccess(
      <div>
        Shareable link copied to clipboard! <br />
      </div>
    )
  }

  const handleLinkAccessOptionClick = (option: TextSelectOption) => {
    const linkAccess =
      option.key === 'disabled' ? firestore.FieldValue.delete() : option.key
    firestore.update(
      { collection: 'boards', doc: props.boardId },
      { linkAccess }
    )
  }

  const handleMakePublicToggle = () => {
    const publicAccess = props.board.publicAccess
      ? firestore.FieldValue.delete()
      : 'reader'
    const firstMadePublicAt =
      props.board.firstMadePublicAt || firestore.FieldValue.serverTimestamp()
    firestore.update(
      { collection: 'boards', doc: props.boardId },
      { publicAccess, firstMadePublicAt }
    )
  }

  const usersWithRoles: UserEmailAndRole[] = []
  Object.keys(usersByRoleGroup).forEach((roleGroup) => {
    usersByRoleGroup[roleGroup].forEach((userEmail: string) =>
      usersWithRoles.push({
        email: userEmail,
        role: roleGroup.substring(0, roleGroup.length - 1),
      })
    )
  })

  usersWithRoles.sort((a, b) => {
    if (a.email > b.email) return -1
    if (a.email < b.email) return 1
    return 0
  })

  const boardLinkAccess = props.board.linkAccess || 'disabled'
  const publicAccess = props.board.publicAccess

  return (
    <Modal motionLayout isOpen close={props.handleClose} width="480px">
      <StyledShareNavigation $isLightTheme={isLightTheme} layout>
        <div css="display:flex">
          <StyledTextLink
            onClick={() => setShareView('default')}
            isActive={shareView === 'default'}
            size="base"
            bold
          >
            <UserPlus height="20px" css="margin-top:-2px;margin-right:10px" />
            Share
          </StyledTextLink>
          <Margin x={28} />
          <StyledTextLink
            onClick={() => setShareView('social')}
            isActive={shareView === 'social'}
            size="base"
            bold
          >
            <Share height="16px" css="margin-right:12px" />
            Social
          </StyledTextLink>
        </div>
      </StyledShareNavigation>

      <StyledShareView
        key={shareView}
        layout
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
        {shareView === 'default' && (
          <>
            <Text size="sm" color="neutral.0">
              People
            </Text>
            <StyledUserList>
              {usersWithRoles.map((user) => (
                <UserAccessListItem
                  key={user.email}
                  email={user.email}
                  options={userOptions}
                  activeOption={userOptions.find((o) => o.key === user.role)!}
                  onOptionClick={(option) =>
                    handleUserAccessOptionClick(option, user)
                  }
                  canEdit={Boolean(userAllowedTo.EDIT_BOARD_USER_PERMISSIONS)}
                />
              ))}
            </StyledUserList>
          </>
        )}
        {shareView === 'social' && (
          <>
            {userAllowedTo.CREATE_SOCIAL_ASSETS && (
              <>
                <Text size="sm" color="neutral.0">
                  Generate assets for
                </Text>
                <Margin y={6} />
                <Button
                  Icon={Instagram}
                  fullWidth
                  variant="darkSecondary"
                  onClick={() => {
                    dispatch(
                      setActiveExport({
                        boardId: props.boardId,
                      })
                    )
                    dispatch(setSocialAssetModalOpen(true))
                    trackBoardSharingPanelShareIntended({
                      boardSharingPanelSessionId,
                      shareType: 'instagramSocialAssets',
                      linkaAccessLevel: boardLinkAccess,
                    })
                  }}
                >
                  Instagram
                </Button>
                <Margin y={24} />
              </>
            )}
            <Text size="sm" color="neutral.0">
              Share link to
            </Text>
            <Margin y={6} />
            <Button
              as="a"
              href={`https://twitter.com/intent/tweet?text=Check%20out%20my%20board%20${props.board.title}%20on%20@getkive!&url=${shareableLink}`}
              target="_blank"
              Icon={Twitter}
              fullWidth
              variant="darkSecondary"
              onClick={() =>
                trackBoardSharingPanelShareIntended({
                  boardSharingPanelSessionId,
                  shareType: 'twitter',
                  linkaAccessLevel: boardLinkAccess,
                })
              }
            >
              Twitter
            </Button>
            <Margin y={6} />
            <Button
              as="a"
              href={`https://www.facebook.com/sharer/sharer.php?u=${shareableLink}`}
              target="_blank"
              Icon={Facebook}
              fullWidth
              variant="darkSecondary"
              onClick={() =>
                trackBoardSharingPanelShareIntended({
                  boardSharingPanelSessionId,
                  shareType: 'facebook',
                  linkaAccessLevel: boardLinkAccess,
                })
              }
            >
              Facebook
            </Button>
            <Margin y={6} />
            <Button
              as="a"
              href={`mailto:?subject=Link to view ${props.board.title} on Kive&body=Hey!%0D%0A%0D%0AHere's my board ${props.board.title} (${shareableLink}) on Kive (https://kive.ai/). You can view it in your browser`}
              Icon={Email}
              fullWidth
              variant="darkSecondary"
              onClick={() =>
                trackBoardSharingPanelShareIntended({
                  boardSharingPanelSessionId,
                  shareType: 'email',
                  linkaAccessLevel: boardLinkAccess,
                })
              }
            >
              Email
            </Button>
          </>
        )}
      </StyledShareView>

      <StyledLinkAccessView
        $isLightTheme={isLightTheme}
        layout
        transition={{ duration: 0.3 }}
      >
        <Text size="sm" color="neutral.0">
          Link access
          <Tooltip
            interactive
            position="bottom"
            html={
              <StyledDiscoverInfoPopup>
                <Text size="sm" color="neutral.0">
                  Enable link access to share your board with anyone. People who
                  get the link can access this board, but not anything else in
                  your project or workspace.
                </Text>
              </StyledDiscoverInfoPopup>
            }
          >
            <QuestionCircle
              color="gray"
              style={{ marginLeft: '5px' }}
              height="16px"
            />
          </Tooltip>
        </Text>
        <Margin y={6} />
        <div css="display:flex">
          <TextSelect
            css="flex:1"
            options={linkOptions}
            activeOption={linkOptions.find((o) => o.key === boardLinkAccess)!}
            onOptionClick={handleLinkAccessOptionClick}
            isDisabled={!userAllowedTo.EDIT_BOARD_USER_PERMISSIONS}
          />
          <Margin x={12} />
          <Button
            variant="darkSecondary"
            isCompact
            onClick={() => {
              copyPublicBoardLink()
              trackBoardSharingPanelShareIntended({
                boardSharingPanelSessionId,
                shareType: 'linkCopied',
                linkaAccessLevel: boardLinkAccess,
              })
            }}
          >
            Copy link
          </Button>
        </div>
      </StyledLinkAccessView>
      <StyledMakePubicView
        $isLightTheme={isLightTheme}
        layout
        transition={{ duration: 0.3 }}
      >
        <Flex justifyContent="space-between">
          <Text size="sm" color="neutral.0">
            Make public
            <Tooltip
              interactive
              position="bottom"
              html={
                <StyledDiscoverInfoPopup>
                  <Text size="sm" color="neutral.0">
                    Anyone on Kive can view this board. You get credit for
                    sharing it with the community.
                  </Text>
                </StyledDiscoverInfoPopup>
              }
            >
              <QuestionCircle
                color="gray"
                style={{ marginLeft: '5px' }}
                height="16px"
              />
            </Tooltip>
          </Text>
          <SwitchToggle
            isOn={!!publicAccess}
            onToggle={handleMakePublicToggle}
            isDisabled={!userAllowedTo.EDIT_BOARD_USER_PERMISSIONS}
          />
        </Flex>
        <Text size="xs" color="discreet">
          Share your inspiration with the community to inspire others
        </Text>
        <Margin y={6} />
      </StyledMakePubicView>
    </Modal>
  )
}

export default BoardSharingPanel

const StyledDiscoverInfoPopup = styled.div`
  display: flex;
  max-width: 90vw;
`

const StyledShareNavigation = styled(motion.div)<{ $isLightTheme: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid
    ${({ theme, $isLightTheme }) =>
      $isLightTheme
        ? theme.colors.gray.light[0]
        : theme.colors.text.neutral[5]};
  padding: 24px 32px;
`

const StyledShareView = styled(motion.div)`
  padding: 24px 32px;
`

const StyledTextLink = styled(Text)<{ isActive: boolean }>`
  ${({ theme, isActive }) => css`
    cursor: pointer;
    display: flex;
    align-items: center;
    color: ${isActive
      ? theme.colors.text.neutral[0]
      : theme.colors.text.neutral[3]};
    &:hover {
      color: ${theme.colors.accent[2]};
    }
  `}
`

const StyledUserList = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledLinkAccessView = styled(motion.div)<{ $isLightTheme: boolean }>`
  border-top: 1px solid
    ${({ theme, $isLightTheme }) =>
      $isLightTheme
        ? theme.colors.gray.light[0]
        : theme.colors.text.neutral[5]};
  padding: 24px 18px;
`

const StyledMakePubicView = styled(motion.div)<{ $isLightTheme: boolean }>`
  border-top: 1px solid
    ${({ theme, $isLightTheme }) =>
      $isLightTheme
        ? theme.colors.gray.light[0]
        : theme.colors.text.neutral[5]};
  padding: 24px 18px;
`
