import styled, { css } from 'styled-components'

import { SubscriptionInterval } from '../../types'

export const StyledContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-auto-columns: 25%;
  min-width: 700px;
`

export const StyledRow = styled.div<{
  noPad?: boolean
  row: () => number
  stretch?: number
  columnIndex?: number
}>`
  ${({ theme, noPad, row, stretch, columnIndex }) => css`
    padding: 4px ${noPad ? 0 : 16}px;
    grid-row-start: ${row()};
    grid-column-start: ${Number(columnIndex) + 1};
    ${stretch && row() && { gridRowEnd: row() + stretch }}
    border-left: ${noPad || columnIndex === 0
      ? 'none'
      : `${theme.border.thin} ${theme.colors.discreet}`};
  `}
`
export const StyledFeaturesHeading = styled.p`
  ${({ theme }) => css`
    margin-top: 10px;
    font-size: ${theme.fontSizes.xs};
    color: ${theme.colors.text.neutral[3]};
  `}
`

type PlanHeaderProps = {
  color?: string
  name: string
  text: string
  price: string
  priceUnit: string
  interval: SubscriptionInterval
}

export const PlanHeader = ({
  color,
  name,
  text,
  price,
  priceUnit,
  interval,
}: PlanHeaderProps) => {
  const normalizedPriceUnit = getNormalizedPriceUnit(priceUnit)
  return (
    <StyledPlanHeaderWrapper>
      <hgroup>
        <StyledPlanMainHeading color={color}>{name}</StyledPlanMainHeading>
        <StyledPlanSubHeading>{text}</StyledPlanSubHeading>
      </hgroup>
      <StyledPlanPriceRow>
        <StyledPlanPriceAmount>
          {price === 'Free' ? '$0' : price}
        </StyledPlanPriceAmount>
        {normalizedPriceUnit && (
          <StyledPlanPriceUnit>
            {normalizedPriceUnit.toLowerCase()}
          </StyledPlanPriceUnit>
        )}
        {price !== 'Free' ? (
          <StyledPriceSubline>Billed {interval}</StyledPriceSubline>
        ) : null}
      </StyledPlanPriceRow>
    </StyledPlanHeaderWrapper>
  )
}

const StyledPlanMainHeading = styled.h2`
  ${({ theme, color }) => css`
    color: ${color ?? theme.colors.text.neutral[1]};
    font-size: ${theme.fontSizes.lg};
    font-weight: ${theme.fontWeight.bold};
    margin: 0;
  `}
`

const StyledPlanSubHeading = styled.h3`
  ${({ theme }) => css`
    color: ${theme.colors.text.neutral[2]};
    font-weight: ${theme.fontWeight.normal};
    font-size: ${theme.fontSizes.base};
    margin: 0;
  `}
`

const StyledPlanPriceRow = styled.div``

const StyledPlanPriceAmount = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.text.neutral[1]};
    font-size: ${theme.fontSizes.base};
    font-weight: ${theme.fontWeight.bold};
  `}
`
const StyledPlanPriceUnit = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.xs};
    color: ${theme.colors.text.neutral[2]};
    opacity: 0.8;
    font-weight: ${theme.fontWeight.bold};
  `}
`

const StyledPlanHeaderWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    row-gap: 1rem;
    flex-direction: column;
    padding-top: 16px;
    padding-bottom: 16px;
  `}
`

const getNormalizedPriceUnit = (priceUnit: string): string | null => {
  if (!priceUnit) return null

  if (/^\s/.test(priceUnit)) return priceUnit.toLowerCase()

  return ` ${priceUnit}`.toLowerCase()
}

const StyledPriceSubline = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.xs};
    color: ${theme.colors.text.neutral[3]};
  `}
`
