import { stringify } from 'query-string'
import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import styled, { css } from 'styled-components'

import useQueryString from 'hooks/useQueryString'
import { useGrowthBookFeature } from 'hooks/useSyncGrowthbook'
import { RootState } from 'store'

import ImageSearchItems from './ImageSearchGrid'
import Item from './Item'
import { ItemError } from './ItemError'
import SimilarItems from './SimilarItems'
import { ItemData, useGetItemData } from './hooks/useGetItemData'
import { useIncrementItemViews } from './hooks/useIncrementItemViews'
import { useTrackItemViewed } from './hooks/useTrackItemViewed'
import useUpdateUserRecentTags from './hooks/useUpdateUserRecentTags'

const ItemView = () => {
  const newImageSearchFeature = useGrowthBookFeature(
    'similar-items-image-search'
  )
  const scrollNode = useRef<HTMLDivElement | null>(null)
  const navigate = useNavigate()
  const location = useLocation()

  const focusedItemId = useSelector(
    (state: RootState) => state.content.focusedItem?.id
  )

  const query = useQueryString<{
    item: string
    itemSpace: string
    isDiscoverItem?: string
  }>()

  const {
    item: itemId,
    itemSpace: spaceKey,
    isDiscoverItem,
    ...backQuery
  } = query
  const backQueryString = stringify(backQuery, { arrayFormat: 'comma' })
  const backPath = `${location.pathname}?${backQueryString}`

  const { data, error } = useGetItemData({
    itemId,
    isBoardItem: spaceKey === 'projects',
  })

  const itemData: ItemData | undefined =
    data && ('workspaceItem' in data ? data.workspaceItem : data.boardItem)

  useUpdateUserRecentTags({ itemData })
  useIncrementItemViews({ itemData })
  useTrackItemViewed({ itemData })

  useEffect(() => {
    if (scrollNode.current) {
      // we make sure to scroll to top when navigating between items - https://linear.app/kiveai/issue/WEB-188/automatically-scrolls-down-in-item-view-if-enter-item-view-from
      scrollNode.current.scrollTo({ top: 0, left: 0 })
    }
  }, [itemId])

  const handleCloseItemView = () => {
    navigate(backPath)
    // Focus the grid so arrow navigation will work
    const newFocusElement = document.getElementById('grid')
    if (newFocusElement) newFocusElement.focus({ preventScroll: true })
  }

  useEffect(() => {
    const closeItemView = () => {
      navigate(backPath)
      // Focus the grid so arrow navigation will work
      const newFocusElement = document.getElementById('grid')
      if (newFocusElement) newFocusElement.focus({ preventScroll: true })
    }
    const handleKeyDown = (e: KeyboardEvent) => {
      if (itemId && !e.key.startsWith('Arrow')) {
        e.stopPropagation()
      }
      if (e.key === ' ') {
        if (focusedItemId && focusedItemId !== itemId) {
          return
        }
        closeItemView()
        e.preventDefault()
      }
      if (e.key === 'Escape') {
        closeItemView()
        e.preventDefault()
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [backPath, navigate, itemId, focusedItemId])

  if (error) {
    return <ItemError error={error} onGoBack={handleCloseItemView} />
  }

  return (
    <FilledContainer ref={scrollNode} onClick={handleCloseItemView}>
      <Item item={itemData} onCloseItemView={handleCloseItemView} />
      {newImageSearchFeature.on && itemData && (
        <ImageSearchItems
          itemId={
            'workspaceItemId' in itemData
              ? itemData.workspaceItemId
              : itemData.id
          }
          scrollNode={scrollNode}
          isDiscoveryItem={false}
        />
      )}
      {newImageSearchFeature.off && itemData && (
        <SimilarItems
          itemId={
            'workspaceItemId' in itemData
              ? itemData.workspaceItemId
              : itemData.id
          }
          scrollNode={scrollNode}
          isDiscoveryItem={false}
        />
      )}
    </FilledContainer>
  )
}

export default ItemView

const FilledContainer = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.background[0]};
    position: fixed;
    height: 100vh;
    overflow-y: scroll;
    top: 0;
    left: 0;
    z-index: 50;
    width: 100%;
    min-height: 100vh;
    font-size: ${theme.fontSizes.base};
    cursor: zoom-out;
    display: flex;
    flex-direction: column;
  `}
`
