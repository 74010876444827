import React from 'react'
import { useSelector } from 'react-redux'
import styled, { css, useTheme } from 'styled-components'

import { Margin } from 'components/common/Margin'
import { Text } from 'components/common/Text'
import { ProgressBar } from 'components/nomination/NominationPanel'
import useUpgradeNavigation from 'components/upgrade/useUpgradeNavigation'
import { useWorkspacePlan } from 'components/upload/useUploadWorkspaces'
import { useActiveWorkspace } from 'hooks/useActiveWorkspace'
import { RootState } from 'store'

const DISPLAY_UPGRADE_LINK_PERCENTAGE_THRESHOLD = 75

const UpgradeNotificationLink = () => {
  const activeWorkspace = useActiveWorkspace()!
  const activeWorkspaceId = activeWorkspace.id
  const currentUserEmail = useSelector(
    (state: RootState) => state.firebase.auth.email!
  )

  const isWorkspaceAdmin =
    'adminEmails' in activeWorkspace
      ? activeWorkspace.adminEmails.includes(currentUserEmail)
      : activeWorkspace.admins.includes(currentUserEmail)

  const siderSplitValue = useSelector(
    (state: RootState) => state.ui.siderSplitValue
  )

  const { open: openUpgradePanel } = useUpgradeNavigation()
  const theme = useTheme()

  const { workspacePlan, remainingUploads } = useWorkspacePlan(
    activeWorkspaceId!
  )

  const handleUpgradeClick = () => {
    openUpgradePanel('items')
  }

  if (
    !workspacePlan ||
    !isWorkspaceAdmin ||
    remainingUploads === Infinity ||
    siderSplitValue < 160
  ) {
    return null
  }

  const workspacePlanLimit = workspacePlan.limits.items

  const percentageUsed = Math.ceil(
    100 - (remainingUploads / workspacePlanLimit) * 100
  )

  const isCloseToLimit =
    percentageUsed >= DISPLAY_UPGRADE_LINK_PERCENTAGE_THRESHOLD

  if (!isCloseToLimit) return null

  const percentageToDisplay = percentageUsed >= 100 ? 100 : percentageUsed

  return (
    <StyledUpgrateNotificationButton onClick={handleUpgradeClick}>
      <Text size="xs" color="neutral.0" bold>
        Upgrade to get more
      </Text>
      <Margin y={2} />
      <Text size="xs" color="neutral.1" css="line-height:15px">
        Your workspace has used {workspacePlanLimit - remainingUploads} of its{' '}
        {workspacePlanLimit.toLocaleString()} item limit ({percentageToDisplay}
        %)
      </Text>
      <Margin y={8} />
      <ProgressBar
        step={percentageToDisplay}
        progressColor={
          percentageToDisplay > 95 ? theme.colors.accent[0] : undefined
        }
        transparentStep={100}
        css="height: 6px"
      />
      <Margin y={8} />
      <Text size="xs" color="neutral.1" link css="text-decoration: underline;">
        Upgrade
      </Text>
    </StyledUpgrateNotificationButton>
  )
}

export default UpgradeNotificationLink

const StyledUpgrateNotificationButton = styled.div`
  ${({ theme }) => css`
    padding: 16px 20px;
    border-top: 1px solid ${theme.colors.background[5]};
    display: flex;
    flex-direction: column;
    cursor: pointer;
  `}
`
