/* eslint-disable @typescript-eslint/naming-convention */
import { UploadCloud } from '@styled-icons/feather/UploadCloud'
import { StyledIcon } from '@styled-icons/styled-icon'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useMatch } from 'react-router'

import Button from 'components/common/Button'
import PermissionWrapper from 'components/common/PermissionWrapper'
import useBoardPermissionsById from 'hooks/useBoardPermissionById'
import useIsMobile from 'hooks/useIsMobile'
import useWorkspacePermissions from 'hooks/useWorkspacePermissions'
import { setIsUploadVisible } from 'store/content/actions'

interface SearchUploadProps {
  text?: string
  Icon?: StyledIcon
}

const SearchUpload: React.FC<SearchUploadProps> = ({ text, Icon }) => {
  const isMobile = useIsMobile()
  const boardId = useMatch('/:workspaceSlug/p/:projectId/:boardId')?.params
    ?.boardId
  const { ADD_BOARD_ITEM: userAllowedToAddBoardItem } = useBoardPermissionsById(
    boardId || '',
    ['ADD_BOARD_ITEM']
  )
  const dispatch = useDispatch()
  const userAllowedTo = useWorkspacePermissions(['UPLOAD'])

  const handleOpenUploadView = () => {
    dispatch(setIsUploadVisible(true))
  }

  const allowedToUpload = boardId
    ? userAllowedTo.UPLOAD && userAllowedToAddBoardItem
    : userAllowedTo.UPLOAD

  const defaultText = boardId ? 'Upload to board' : 'Upload'
  return (
    <PermissionWrapper feature="Upload" isAllowed={Boolean(allowedToUpload)}>
      <Button
        data-intercom-target="Search Bar Upload Button" // Used for Intercom product tours
        variant="primary"
        Icon={Icon || UploadCloud}
        onClick={handleOpenUploadView}
        isCompact={isMobile}
        css="height: 100%"
      >
        {isMobile ? null : text || defaultText}
      </Button>
    </PermissionWrapper>
  )
}

export default SearchUpload
