import { motion } from 'framer-motion'
import { memo, useMemo } from 'react'
import { XYCoord, useDragLayer } from 'react-dnd'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import { Text } from 'components/common/Text'
import { Item } from 'components/grid/griditem/GridItem'
import GridItemTypes from 'components/grid/griditem/GridItemTypes'
import { getThumbUrl } from 'helpers/getThumbs'
import { RootState } from 'store'

function getItemStyles(
  initialOffset: XYCoord | null,
  currentOffset: XYCoord | null,
  itemOffset: XYCoord
) {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    }
  }

  const transform = `translate(${currentOffset.x - itemOffset.x}px, ${
    currentOffset.y - itemOffset.y
  }px)`
  return {
    transform,
  }
}

const previewWidth = 150
const sidebarWidth = 270

const CustomDragLayer = memo(() => {
  const selectedItems = useSelector(
    (state: RootState) => state.multiSelect.selectedItems
  )

  const { itemType, isDragging, item, initialOffset, currentOffset, isOver } =
    useDragLayer((monitor) => ({
      item: monitor.getItem() as Item | undefined,
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getClientOffset(),
      isDragging: monitor.isDragging(),
      isOver: (() => {
        const myHaxMonitor = monitor as any
        const [id] = myHaxMonitor.getTargetIds()
        return myHaxMonitor.isOverTarget(id)
      })(),
    }))

  const otherSelectedItems = useMemo(() => {
    return Object.values(selectedItems).filter(
      (selectedItem) => selectedItem.id !== item?.id
    )
  }, [selectedItems, item?.id])

  if (!isDragging || !item) {
    return null
  }

  if (itemType !== GridItemTypes.BOX) return null

  const previewHeight = Math.floor(previewWidth / item.aspectRatio)
  const itemOffset = {
    x: previewWidth / 2,
    y: previewHeight / 2,
  }
  const isOverSidebar = currentOffset && currentOffset.x < sidebarWidth
  const shouldIndicateBoardDrop = isOverSidebar && isOver

  const thumb = getThumbUrl(item, 'image')
  return (
    <LayerStyles>
      <div style={getItemStyles(initialOffset, currentOffset, itemOffset)}>
        <motion.div
          style={{
            width: previewWidth,
            height: previewWidth,
            position: 'relative',
          }}
          transition={{ rotate: { repeat: Infinity, duration: 0.4 } }}
          animate={
            shouldIndicateBoardDrop
              ? {
                  rotate: [0, 2, 0, -2, 0],
                  scale: 0.8,
                }
              : undefined
          }
        >
          <StyledDragPreview
            imagePreviewUrl={thumb}
            previewHeight={previewHeight}
          />
          {shouldIndicateBoardDrop && otherSelectedItems.length > 0 && (
            <ItemCountTag>
              <Text size="sm" color="neutral.0">
                +{otherSelectedItems.length}{' '}
                {otherSelectedItems.length === 1 ? 'item' : 'items'}
              </Text>
            </ItemCountTag>
          )}
        </motion.div>
      </div>
    </LayerStyles>
  )
})

export default CustomDragLayer

const LayerStyles = styled.div`
  position: fixed;
  pointer-events: none;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`

const ItemCountTag = styled.div`
  ${({ theme }) => css`
    position: absolute;
    padding: 0px 6px;
    border-radius: ${theme.borderRadius.full};
    background: ${theme.colors.accent[2]};
    display: flex;
    align-items: center;
    height: 30px;
    top: -15px;
    right: 0;
  `}
`

interface StyledDragPreviewProps {
  previewHeight: number
  previewWidth: number
  imagePreviewUrl: string
}

const StyledDragPreview = memo(styled.div<StyledDragPreviewProps>`
  ${({ imagePreviewUrl, previewHeight, theme }) => {
    return css`
      background-image: url(${imagePreviewUrl});
      background-size: cover;
      border-radius: ${theme.borderRadius.default};
      height: ${previewHeight}px;
      width: ${previewWidth}px;
    `
  }}
`)
