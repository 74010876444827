import { PerfectCursor } from 'perfect-cursors'
import { useCallback, useLayoutEffect, useState } from 'react'

export function usePerfectCursor(
  cb: (point: number[]) => void,
  point?: number[]
) {
  const [pc] = useState(() => new PerfectCursor(cb))

  useLayoutEffect(() => {
    if (point) pc.addPoint(point)
    return () => pc.dispose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pc])

  const onPointChange = useCallback(
    (updatedPoint: number[]) => {
      pc.addPoint(updatedPoint)
    },
    [pc]
  )

  return onPointChange
}
