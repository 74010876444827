import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import styled, { css } from 'styled-components'

const AvatarStyled = styled.div(({ theme, src, size }) => ({
  backgroundColor: theme.colors.background[1],
  backgroundImage: `url(${src})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  borderRadius: '50%',
  marginRight: 16,
  minWidth: { large: 42, medium: 32, small: 24 }[size],
  minHeight: { large: 42, medium: 32, small: 24 }[size],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: theme.fontSizes.sm,
  fontWeight: theme.fontWeight.bold,
  '@media (max-width: 768px)': {
    display: 'none',
  },
}))

const UserContainer = styled.div(() => ({
  height: 60,
  padding: 8,
  overflow: 'hidden',
}))

const Column = styled.div(() => ({
  height: '100%',
  display: 'inline-flex',
  flexDirection: 'column',
  justifyContent: 'center',
  verticalAlign: 'top',
}))

const Name = styled.span`
  ${({ theme }) => css`
    display: block;
    color: ${theme.colors.text.neutral[0]};
    font-size: ${theme.fontSizes.sm};
  `}
`

const Email = styled.span`
  ${({ theme }) => css`
    display: block;
    color: ${theme.colors.text.neutral[2]};
    font-size: ${theme.fontSizes.sm};
  `}
`

function Avatar({ size, user }) {
  const { photoURL = '', firstName = '', lastName = '' } = user
  const initials = firstName.substring(0, 1) + lastName.substring(0, 1)
  return (
    <AvatarStyled size={size} src={photoURL}>
      {!photoURL && initials}
    </AvatarStyled>
  )
}

function UserRepresentation({ user, type, includeEmail, size = 'large' }) {
  if (!user) return null
  if (type === 'name') return user.displayName
  return !user ? (
    'Someone'
  ) : (
    <UserContainer>
      <Column>
        <Avatar size={size} user={user} />
      </Column>
      <Column>
        <Name>{user.displayName}</Name>
        {includeEmail && <Email>{user.email}</Email>}
      </Column>
    </UserContainer>
  )
}

const mapStateToProps = (state, ownProps) => {
  let user = null

  if (ownProps.uid && state.firestore.data.users) {
    user = state.firestore.data.users?.[ownProps.uid]
  } else if (
    ownProps.email &&
    state.firestore.data[`usersByEmail_${ownProps.email}`]
  ) {
    user = Object.values(
      state.firestore.data[`usersByEmail_${ownProps.email}`]
    )?.[0]
  }

  return {
    user,
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    if (props.uid) {
      return [
        {
          collection: 'users',
          doc: props.uid,
        },
      ]
    } else if (props.email) {
      return [
        {
          collection: 'users',
          where: [['email', '==', props.email]],
          limit: 1,
          storeAs: `usersByEmail_${props.email}`,
        },
      ]
    }
    return []
  })
)(UserRepresentation)
