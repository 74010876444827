// @ts-check
import { useEffect, useState } from 'react'

/**
 * Track value with specified value
 * @template TValue
 * @param {TValue} value
 * @param {number} delay
 * @return {TValue} trailing value
 */
export default function useTrailingValue(value, delay) {
  const [trailingValue, setTrailingValue] = useState(value)
  useEffect(() => {
    const trail = setTimeout(() => setTrailingValue(value), delay)
    return () => clearTimeout(trail)
  }, [value, delay])
  return trailingValue
}
