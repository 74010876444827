import produce from 'immer'

import {
  useUpdateUserLearningGuidesMutation,
  useUserLearningGuidesQuery,
} from 'generated/graphql'

import { HomeGuideId } from './guidesInfo'

export const useUserLearningGuides = () => {
  const [updateUserLearningGuidesMutation] =
    useUpdateUserLearningGuidesMutation()

  const { data } = useUserLearningGuidesQuery({
    context: {
      batch: true,
    },
  })
  const userLearningGuides = data?.userLearningGuides as
    | undefined
    | Record<HomeGuideId, any>

  const peformUpdate = async ({
    id,
    field,
  }: {
    id: HomeGuideId
    field: 'isHidden' | 'isRead'
  }) => {
    if (!userLearningGuides) return null

    if (userLearningGuides[id]?.[field]) return null

    const optimisticResponseData = produce(userLearningGuides, (draft) => {
      draft[id][field] = true
    })

    try {
      const { errors } = await updateUserLearningGuidesMutation({
        variables: {
          userLearningGuidesUpdate: {
            [id]: {
              [field]: true,
            },
          },
        },
        optimisticResponse: {
          updateUserLearningGuides: optimisticResponseData,
        },
        update: (cache, newData) => {
          const updatedUserLearningGuides =
            newData.data?.updateUserLearningGuides

          if (!updatedUserLearningGuides) return

          cache.modify({
            fields: {
              userLearningGuides() {
                return updatedUserLearningGuides
              },
            },
          })
        },
      })
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      if (errors) throw errors
    } catch (error) {
      reportError(error)
    }
  }

  const markLearningGuideAsRead = async (id: HomeGuideId) => {
    peformUpdate({ id, field: 'isRead' })
  }

  const hideLearningGuide = async (id: HomeGuideId) => {
    peformUpdate({ id, field: 'isHidden' })
  }

  return {
    userLearningGuides,
    hideLearningGuide,
    markLearningGuideAsRead,
  }
}
