import React from 'react'
import styled, { css } from 'styled-components'

import Button from 'components/common/Button'
import { DBPlan } from 'types/db'

type DowngradeViewFirstProps = {
  onCloseClick: () => void
  activePlan: DBPlan
  onConfirm: () => void
}

export const DowngradeViewFirst = ({
  onCloseClick,
  activePlan,
  onConfirm,
}: DowngradeViewFirstProps) => {
  return (
    <>
      <ModalHeading>Are you sure you want to downgrade your plan?</ModalHeading>
      <ModalText>
        You will no longer have access to {activePlan?.presentation?.name}{' '}
        features.
      </ModalText>
      <ButtonsWrapper>
        <ButtonWrapper>
          <Button bold fullWidth variant="danger" onClick={onConfirm}>
            Proceed
          </Button>
        </ButtonWrapper>
        <ButtonWrapper>
          <Button bold fullWidth onClick={onCloseClick}>
            Cancel
          </Button>
        </ButtonWrapper>
      </ButtonsWrapper>
    </>
  )
}

export const ModalHeading = styled.h1`
  ${({ theme }) => css`
    font-weight: ${theme.fontWeight.bold};
    margin-bottom: 12px;
    font-size: ${theme.fontSizes.lg};
    color: ${theme.colors.text.neutral[1]};
  `}
`

export const ModalText = styled.p`
  color: ${({ theme }) => theme.colors.text.neutral[1]};
  margin-bottom: 16px;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  div:last-child {
    margin: 0 0 0 4px;
  }
  div:first-child {
    margin: 0 4px 0 0;
  }
`

export const ButtonWrapper = styled.div`
  width: 50%;
`
