// @ts-nocheck
import { openDB } from 'idb'
import type { DBSchema, IDBPDatabase, IDBPObjectStore } from 'idb'

import { reportError } from 'helpers/logging'

import type { CustomFile } from '../components/upload/types'

// TODO: Time to remove this file?

interface FilesFields {
  file: CustomFile
  itemId: string | null
  itemRefId: string | null
  session: string
  fileId?: string
}

interface SessionsFields {
  id: string
  workspaceId: string
  projectId?: string
  boardId?: string
}

interface Stores {
  files: {
    key: string
    value: FilesFields
    indexes: {
      filesForSession: string
      fileItemId: string
      fileItemRefId: string
    }
  }
  sessions: {
    key: string
    value: SessionsFields
    indexes: never
  }
}

interface DatabaseSchema extends DBSchema, Stores {}

enum FileIndex {
  FILES_FOR_SESSION = 'filesForSession',
  FILE_ITEM_ID = 'fileItemId',
  FILE_ITEM_REF_ID = 'fileItemRefId',
}

enum Store {
  FILES_STORE = 'files',
  SESSIONS_STORE = 'sessions',
}

let dbLoadPromise: Promise<IDBPDatabase<DatabaseSchema>> | null = null

const loadDb = (): Promise<IDBPDatabase<DatabaseSchema>> => {
  dbLoadPromise =
    dbLoadPromise ??
    openDB('uploadSessions', 2, {
      upgrade: async (db, _ver, _oldVer, transaction) => {
        const createObjectStoreIfNotExists = (storeName: keyof Stores) =>
          db.objectStoreNames.contains(storeName)
            ? transaction.objectStore(storeName)
            : db.createObjectStore(storeName)

        const createIndexIfNotExists = (
          store: IDBPObjectStore<
            DatabaseSchema,
            (keyof Stores)[],
            keyof Stores
          >,
          indexName: keyof Stores['files']['indexes'],
          indexProp: string
        ) =>
          !store.indexNames.contains(indexName) &&
          store?.createIndex?.(indexName, indexProp)

        createObjectStoreIfNotExists(Store.SESSIONS_STORE)
        const dbFileStore = createObjectStoreIfNotExists(Store.FILES_STORE)
        createIndexIfNotExists(
          dbFileStore,
          FileIndex.FILES_FOR_SESSION,
          'session'
        )
        createIndexIfNotExists(dbFileStore, FileIndex.FILE_ITEM_ID, 'itemId')
        createIndexIfNotExists(
          dbFileStore,
          FileIndex.FILE_ITEM_REF_ID,
          'itemRefId'
        )
      },
    })
  return dbLoadPromise
}

// Save upload session to local storage
export const persistUploadSession = async (
  sessionId: string,
  files: CustomFile[],
  workspaceId: string,
  projectId?: string,
  boardId?: string
): Promise<void> => {
  try {
    const db = await loadDb()
    const fileSave = db.transaction(Store.FILES_STORE, 'readwrite')
    await Promise.all(
      files.map((file) =>
        fileSave.store.put(
          {
            file,
            itemId: null,
            itemRefId: null,
            session: sessionId,
            fileId: file.id,
          },
          file.id
        )
      )
    )
    await db.put(
      Store.SESSIONS_STORE,
      { id: sessionId, workspaceId, projectId, boardId },
      sessionId
    )
  } catch (error) {
    reportError(error)
  }
}

export const persistFileIds = async (
  file: CustomFile,
  itemId: string,
  itemRefId = null
): Promise<void> => {
  try {
    const db = await loadDb()
    const key = file.id
    if (!key) {
      throw new Error('persistFileIds: file id missing')
    }
    const prev = await db.get(Store.FILES_STORE, key)
    if (!prev) {
      console.warn('No previous record to update')
      return
    }
    await db.put(Store.FILES_STORE, { ...prev, itemId, itemRefId }, key)
  } catch (error) {
    reportError(error)
  }
}

export const clearSessionPersistence = async (
  sessionId: string
): Promise<void> => {
  try {
    const db = await loadDb()
    let fileCursor = await db
      .transaction(Store.FILES_STORE, 'readwrite')
      .store.index(FileIndex.FILES_FOR_SESSION)
      // @ts-ignore this does not seem to be correctly typed, we need to look further into it
      .openCursor(sessionId)
    while (fileCursor != null) {
      // eslint-disable-next-line no-await-in-loop
      await fileCursor.delete()
      // eslint-disable-next-line no-await-in-loop
      fileCursor = await fileCursor.continue()
    }
    await db.delete(Store.SESSIONS_STORE, sessionId)
  } catch (error) {
    reportError(error)
  }
}

export const filesForSession = async (
  sessionId: string
): Promise<FilesFields[]> => {
  try {
    const db = await loadDb()
    const result = []
    let cursor = await db
      .transaction(Store.FILES_STORE)
      .store.index(FileIndex.FILES_FOR_SESSION)
      // @ts-ignore this does not seem to be correctly typed, we need to look further into it
      .openCursor(sessionId)
    while (cursor != null) {
      result.push(cursor.value)
      // eslint-disable-next-line no-await-in-loop
      cursor = await cursor.continue()
    }
    return result
  } catch (error) {
    reportError(error)
    return []
  }
}

export const listSessions = async (): Promise<
  { id: string; workspaceId: string }[]
> => {
  try {
    const db = await loadDb()
    return await db.getAll(Store.SESSIONS_STORE).then((sessions) =>
      sessions.map((session) => {
        return { id: session.id, workspaceId: session.workspaceId }
      })
    )
  } catch (error) {
    reportError(error)
    return []
  }
}
