import React, { Component } from 'react'

export default class ClickOutside extends Component {
  componentDidMount() {
    document.addEventListener('mousedown', this.handlePickerClick, false)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handlePickerClick, false)
  }

  handlePickerClick = (e) => {
    if (
      this.node.contains(e.target) ||
      this.props.ignoreRef?.current.contains(e.target)
    ) {
      return
    }
    this.props.handleClickOutside()
  }

  render() {
    return (
      <div
        style={this.props.style}
        ref={(node) => {
          this.node = node
        }}
      >
        {this.props.children}
      </div>
    )
  }
}
