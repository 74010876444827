import { useState } from 'react'
import OtpInput from 'react-otp-input'
import { useParams } from 'react-router-dom'
import { useTheme } from 'styled-components'

import Loading from 'components/Loading'
import Flex from 'components/common/Flex'
import { Text } from 'components/common/Text'
import LandingHeader from 'components/landing/LandingHeader'
import { useUserLoginOtpExchangeMutation } from 'generated/graphql'
import { loginWithOTP } from 'helpers/auth'
import { reportError } from 'helpers/logging'
import { getCustomErrorStatus } from 'helpers/parseGraphqlErrors'

import { Heading, Page } from './AuthenticationStyles'

export const OtpLogin = () => {
  const theme = useTheme()
  const { word } = useParams() as { word: string }

  const [code, setCode] = useState('')
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const [userLoginOtpExchangeMutation] = useUserLoginOtpExchangeMutation()

  const otpLogin = async ({
    passedWord,
    passedCode,
  }: {
    passedWord: string
    passedCode: number
  }) => {
    setIsLoading(true)
    try {
      const { data, errors } = await userLoginOtpExchangeMutation({
        variables: {
          code: passedCode,
          word: passedWord,
        },
      })
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      if (errors) throw errors

      await loginWithOTP({
        token: data!.userLoginOTPExchange.token,
      })
    } catch (mutationError) {
      const errorStatus = getCustomErrorStatus(mutationError)
      if (errorStatus === 'OTP_EXPIRED') {
        setError('The code has expired. Generate a new one.')
      } else if (errorStatus === 'WRONG_COMBINATION') {
        setError('Wrong combination. Check your link and code.')
      } else {
        setError('Something strange happened. We could not sign you in.')
        reportError(mutationError)
      }
      setCode('')
    }
    setIsLoading(false)
  }

  const handleInputChange = async (updatedCode: string) => {
    setCode(updatedCode)
    setError(null)
    if (updatedCode.length !== 6) return
    otpLogin({ passedCode: Number(updatedCode), passedWord: word })
  }

  return (
    <>
      <LandingHeader isNotFixed={false} isPure />
      <Page>
        <Heading>Log in</Heading>
        {isLoading && <Loading />}
        {!isLoading && (
          <Flex alignItems="center" flexDirection="column">
            <Text color="neutral.2" size="sm">
              Enter the 6-digit passcode below
            </Text>
            <OtpInput
              value={code}
              onChange={handleInputChange}
              numInputs={6}
              shouldAutoFocus
              isInputNum
              inputStyle={{
                height: '50px',
                width: '40px',
                margin: '8px',
                background: theme.colors.background[5],
                border: 0,
                color: theme.colors.text.neutral[0],
                borderRadius: theme.borderRadius.default,
              }}
              focusStyle={{
                border: `${theme.border.medium} ${theme.colors.accent[2]}`,
                outline: 0,
              }}
            />
            {error && (
              <Text color="accent.0" size="sm">
                {error}
              </Text>
            )}
          </Flex>
        )}
      </Page>
    </>
  )
}
