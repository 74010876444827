import { useOthers } from 'config/liveblocks.config'

import UserAvatar from '../../components/common/UserAvatar'
import { cursorColors } from './CursorsOthers'

export const UserAvatarsPanel = () => {
  const others = useOthers().filter((o) => o.presence.name)
  if (others.length === 0) return null
  return (
    <div style={{ position: 'relative', display: 'flex', marginRight: 8 }}>
      {others.map(({ connectionId, presence }, i) =>
        presence.name ? (
          <div
            key={connectionId}
            style={{
              width: 24,
            }}
          >
            <UserAvatar
              photoUrl={presence?.photoURL?.toString()}
              displayName={presence.name.toString()}
              size={32}
              color={cursorColors[i % cursorColors.length]}
              withBorder={!!presence.cursor}
            />
          </div>
        ) : null
      )}
    </div>
  )
}
