import { useSelector } from 'react-redux'
import {
  ReduxFirestoreQuerySetting,
  useFirestoreConnect,
} from 'react-redux-firebase'

import { getWhereUserHasAccess } from 'helpers/queries'
import { RootState, WithId } from 'store'
import { DBBoard, WorkspaceAccessLevel } from 'types/db'

type UseBoardSearchArgs = {
  input?: string
  accessLevel: WorkspaceAccessLevel
}

const useBoardSearch = ({ input, accessLevel }: UseBoardSearchArgs) => {
  const uid = useSelector((state: RootState) => state.firebase.auth.uid!)
  const email = useSelector((state: RootState) => state.firebase.auth.email!)

  const activeWorkspaceId = useSelector(
    (state: RootState) => state.content.activeWorkspace!.id
  )

  const getQuery = (): ReduxFirestoreQuerySetting[] => {
    if (!activeWorkspaceId || !email) {
      return []
    }
    if (input) {
      return [
        {
          collection: 'boards',
          where: [
            getWhereUserHasAccess(uid, accessLevel),
            ['suggestedFor', 'array-contains', input.toLowerCase()],
            ['workspaceId', '==', activeWorkspaceId],
          ],
          limit: 8,
          storeAs: `boards_search_${activeWorkspaceId}`,
        },
      ]
    }
    return []
  }

  useFirestoreConnect(getQuery())

  const boards: WithId<DBBoard>[] = useSelector(
    (state: RootState) =>
      state.firestore.ordered[`boards_search_${activeWorkspaceId}`] || []
  )

  return boards
}

export { useBoardSearch }
