import collaborationImage1 from 'assets/img/guides/collaboration-guide/collaboration-1.jpg'
import collaborationImage2 from 'assets/img/guides/collaboration-guide/collaboration-2.jpg'
import collaborationImage3 from 'assets/img/guides/collaboration-guide/collaboration-3.jpg'
import publicBoardsImage1 from 'assets/img/guides/public-boards-guide/public-boards-1.jpg'
import { Spacer } from 'components/common/Spacer'
import { Text } from 'components/common/Text'

import { StyledContentList } from './contentStyles'

export const contentCollaboration = {
  contentHeight: 225,
  steps: [
    {
      coverUrl: collaborationImage1,
      content: (
        <div>
          <Text size="lg" color="neutral.0">
            Workspace basic
          </Text>
          <Spacer factor={1} />
          <Text size="sm" color="neutral.2">
            Workspaces can be private or shared with a team.
          </Text>
          <StyledContentList>
            <li>You can be a member of multiple workspaces</li>
            <li>
              Click the dropdown menu in the top left corner to see the list of
              workspaces available to you
            </li>
            <li>
              To invite team members or collaborators to a workspace, go to
              Settings ➡️ Members ➡️ Add members
            </li>
          </StyledContentList>
        </div>
      ),
    },
    {
      coverUrl: collaborationImage2,
      content: (
        <div>
          <Text size="lg" color="neutral.0">
            Sharing projects & boards
          </Text>
          <Spacer factor={1} />
          <Text size="sm" color="neutral.2">
            You can share entire projects or individual boards with anyone by
            turning on public link access. Just switch on link access by picking
            an access level in the sharing panel
          </Text>
          <br />
          <Text size="sm" color="neutral.2">
            🔐 Privacy note: all workspaces are private by default and only
            accessible to members of the workspace.
          </Text>
        </div>
      ),
    },
    {
      coverUrl: publicBoardsImage1,
      content: (
        <div>
          <Text size="lg" color="neutral.0">
            Publishing a board to Discover
          </Text>
          <Spacer factor={1} />
          <Text size="sm" color="neutral.2">
            Public boards in Discover are curated visual collections that have
            been shared by members of the Kive community. To publish a board in
            Discover:
          </Text>
          <StyledContentList as="ol">
            <li>Go to projects and open a selected board</li>
            <li>Click &quot;Share&quot;</li>
            <li>
              Click the toggle next to &quot;Make public&quot; so it turns
              purple
            </li>
          </StyledContentList>
          <Text size="sm" color="neutral.2">
            Your selected board will now be visible in Discover. You can also
            find all your public boards in the 👤 Profile section.
          </Text>
        </div>
      ),
    },
    {
      coverUrl: collaborationImage3,
      content: (
        <div>
          <Text size="lg" color="neutral.0">
            Get instant feedback on your projects
          </Text>
          <Spacer factor={1} />
          <Text size="sm" color="neutral.2">
            Setting the link access to &quot;Anyone with the link can react and
            comment&quot; will enable others to:
          </Text>
          <StyledContentList>
            <li>Leave comments and react with emojis </li>
            <li>Download individual items or the entire board</li>
          </StyledContentList>
        </div>
      ),
    },
  ],
}
