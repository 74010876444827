import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

const spring = {
  type: 'spring',
  stiffness: 500,
  damping: 30,
}

type SwitchToggleProps = {
  isOn: boolean
  onToggle: () => void
  isDisabled?: boolean
}

const SwitchToggle = (props: SwitchToggleProps) => {
  const onClick = !props.isDisabled ? props.onToggle : undefined
  return (
    <StyledSwitch
      isOn={props.isOn}
      onClick={onClick}
      isDisabled={props.isDisabled}
    >
      <StyledMotionHandle
        isOn={props.isOn}
        layout
        transition={spring}
        isDisabled={props.isDisabled}
      />
    </StyledSwitch>
  )
}

export default SwitchToggle

const StyledSwitch = styled.div<{ isOn: boolean; isDisabled?: boolean }>`
  ${({ theme, isOn, isDisabled }) => css`
    width: 48px;
    height: 30px;
    background-color: ${isOn && !isDisabled
      ? theme.colors.accent[2]
      : theme.colors.gray.medium[1]};
    display: flex;
    justify-content: flex-start;
    border-radius: 50px;
    padding: 3px;
    cursor: pointer;
    ${isOn &&
    css`
      justify-content: flex-end;
    `}
  `}
`

const StyledMotionHandle = styled(motion.div)<{
  isOn: boolean
  isDisabled?: boolean
}>`
  ${({ theme, isOn, isDisabled }) => css`
    width: 24px;
    height: 24px;
    background-color: ${isOn && !isDisabled
      ? theme.colors.white
      : theme.colors.gray.medium[6]};
    border-radius: 40px;
  `}
`
