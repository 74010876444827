import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import styled from 'styled-components'

import { GuideCanvas } from 'features/editor/GuideCanvas'
import { PublicBanner } from 'features/editor/PublicBanner'
import { SignupModal } from 'features/editor/components/SignupModal/SignupModal'
import { useMount } from 'hooks/useMount'

import { useRespondToEditSessionInviteMutation } from '../../../generated/graphql'
import { RootState } from '../../../store'
import Loading from '../../Loading'

export const EditSessionInvitation = () => {
  const [showGuide, setShowGuide] = useState(false)
  const [showSignup, setShowSignup] = useState(false)
  const [signupDone, setSignupDone] = useState(false)
  const { invitationId } = useParams()
  const navigate = useNavigate()
  const { uid, email, displayName } = useSelector(
    (state: RootState) => state.firebase.auth
  )
  const [respondToEditSessionInviteMutation] =
    useRespondToEditSessionInviteMutation()

  console.log('invitationId', invitationId)

  useMount(() => {
    const respondToInvite = async () => {
      if (!invitationId || !uid) {
        return
      }

      const responderEmail = email ?? 'anonymous'
      const responderName = displayName ?? 'Anonymous'
      const responderUid = uid ?? 'anonymous'

      const { data, errors, context } =
        await respondToEditSessionInviteMutation({
          variables: {
            input: {
              editSessionInviteId: invitationId,
              response: 'accept',
              responder: {
                email: responderEmail,
                name: responderName,
                userId: responderUid,
              },
            },
          },
        })
      const sessionId = data?.respondToEditSessionInvite.editSessionId
      console.log('respondToInvite data', data)
      console.log('respondToInvite errors', errors)
      console.log('respondToInvite context', context)

      navigate(`/canvas/${sessionId}`, { replace: true })
    }

    if (uid) respondToInvite()
    else setShowSignup(true)
  })

  useEffect(() => {
    const respondToInvite = async () => {
      if (!invitationId || !uid) {
        return
      }

      const responderEmail = email ?? 'anonymous'
      const responderName = displayName ?? 'Anonymous'
      const responderUid = uid ?? 'anonymous'

      const { data, errors, context } =
        await respondToEditSessionInviteMutation({
          variables: {
            input: {
              editSessionInviteId: invitationId,
              response: 'accept',
              responder: {
                email: responderEmail,
                name: responderName,
                userId: responderUid,
              },
            },
          },
        })
      const sessionId = data?.respondToEditSessionInvite.editSessionId
      console.log('respondToInvite data', data)
      console.log('respondToInvite errors', errors)
      console.log('respondToInvite context', context)

      navigate(`/canvas/${sessionId}`, { replace: true })
    }

    if (signupDone) {
      setShowSignup(false)
      respondToInvite()
    }
    // eslint-disable-next-line
  }, [signupDone])

  if (showSignup) {
    return (
      <>
        <PublicBanner handleSignupClick={() => {}} />
        <SignupModal
          isOpen
          close={() => {}}
          onSignup={() => setShowGuide(true)}
          onLogin={() => setSignupDone(true)}
        />
        <GuideCanvas show={showGuide} onClose={() => setSignupDone(true)} />
      </>
    )
  }

  return (
    <StyledWrapper>
      <Loading />
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`
