import { Plus } from '@styled-icons/bootstrap/Plus'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router'
import { compose } from 'redux'
import styled, { css } from 'styled-components'

import useCurrentUser from 'hooks/useCurrentUser'
import { workspacesSelector } from 'store/selectors'

export const WorkspaceSelectorStyled = styled.div`
  ${({ theme }) => css`
    min-width: 60px;
    width: 60px;
    border-right: ${`${theme.border.thin} ${theme.colors.discreet}`};
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 110;
    position: absolute;
    background-color: ${theme.colors.background[0]};
    max-height: 100vh;
    min-height: 100vh;
    transform: translateX(-60px);
    transition: 0.2s transform ease;
  `}
`

export const WorkspaceCard = styled.div(
  ({ icon, theme, active, style, hasUnseenNotifications }) => ({
    width: 'calc(100% - 20px)',
    minWidth: 40,
    height: 40,
    padding: 10,
    margin: 10,
    fontWeight: theme.fontWeight.bold,
    fontSize: 10,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    color: active ? theme.colors.text.neutral[0] : theme.colors.gray.medium[6],
    background: theme.colors.background[5],
    backgroundImage: `url(${icon})`,
    backgroundSize: '110%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    cursor: 'pointer',
    border: '2px solid',
    borderColor: active ? theme.colors.gray.dark[8] : 'transparent',
    transition: 'border-color 0.1s',
    borderRadius: theme.borderRadius.default,
    ...style,
    ':hover': {
      borderColor: theme.colors.accent[2],
    },
    ':active': {
      color: `${theme.shadow.thin}`,
      background: !icon && theme.colors.background[4],
    },
    ...(hasUnseenNotifications && {
      ':after': {
        content: '""',
        width: '8px',
        height: '14px',
        background: '#ff4833',
        position: 'absolute',
        left: '-16px',
        borderRadius: '5px',
      },
    }),
  })
)

export const HoverSection = styled.div`
  position: absolute;
  width: 24px;
  height: 100vh;
  :hover ${WorkspaceSelectorStyled} {
    transform: translateX(0);
  }
  z-index: 2;
`

function WorkspaceSelector(props) {
  const { unseenNotifications } = useCurrentUser()
  const navigate = useNavigate()

  const availableWorkspaceIds = Object.entries(props.workspaces).reduce(
    (acc, [workspaceId, workspace]) => {
      if (workspace.isDeleting) {
        return acc
      }
      return [...acc, workspaceId]
    },
    []
  )

  return (
    <HoverSection>
      <WorkspaceSelectorStyled>
        {availableWorkspaceIds.map((workspaceId) => {
          const workspace = props.workspaces[workspaceId]
          const hasUnseenNotifications =
            (unseenNotifications?.[workspaceId] ?? 0) > 0
          return (
            <WorkspaceCard
              icon={workspace.icon}
              key={workspaceId}
              active={workspaceId === props.activeWorkspace.id}
              onClick={() => navigate(`/${workspace.url}`)}
              hasUnseenNotifications={hasUnseenNotifications}
            >
              {!workspace.icon && workspace.title.substring(0, 2).toUpperCase()}
            </WorkspaceCard>
          )
        })}
        <WorkspaceCard onClick={() => navigate('/setup/workspace?new=true')}>
          <Plus />
        </WorkspaceCard>
      </WorkspaceSelectorStyled>
    </HoverSection>
  )
}

const mapStateToProps = (state) => ({
  workspaces: workspacesSelector(state) || {},
  activeWorkspace: state.content.activeWorkspace,
})

export default compose(connect(mapStateToProps))(WorkspaceSelector)
