import styled, { css } from 'styled-components'

export const StyledImageContainer = styled.div`
  padding-bottom: 1rem;
`
export const StyledImage = styled.img`
  ${({ theme }) => css`
    object-fit: cover;
    width: 300px;
    height: 150px;
    flex: 1;
    display: block;
    pointer-events: none;
    border-radius: ${theme.borderRadius.default};
  `}
`

export const StyledModalHeader = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    font-family: ${theme.fontFamily.primary};
    font-size: ${theme.fontSizes.lg};
    font-weight: ${theme.fontWeight.bold};
    line-height: ${theme.lineHeight.tight};
  `}
`
export const StyledModalDescription = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    font-family: ${theme.fontFamily.primary};
    font-size: ${theme.fontSizes.sm};
    font-weight: ${theme.fontWeight.normal};
    line-height: ${theme.lineHeight.tight};
    padding-bottom: 1.5rem;
    width: 300px;
  `}
`
export const StyledPowerByContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 0.5rem;
  padding-bottom: 1.5rem;
`
export const StyledPoweredByHeader = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    font-family: ${theme.fontFamily.primary};
    font-size: ${theme.fontSizes.base};
    font-weight: ${theme.fontWeight.normal};
    line-height: ${theme.lineHeight.tight};
    padding-bottom: 0.75rem;
  `}
`

export const StyledToggleHeader = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    font-family: ${theme.fontFamily.primary};
    font-size: ${theme.fontSizes.base};
    font-weight: ${theme.fontWeight.normal};
    line-height: ${theme.lineHeight.tight};
    padding-bottom: 0.75rem;
  `}
`
export const StyledToggleContainer = styled.div`
  padding-top: 0.5rem;
`
export const StyledCreditsButtonContainer = styled.div`
  padding-bottom: 0.75rem;
`
export const StyledAvatarsContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
  `}
`
