import React from 'react'
import { Theme, Tooltip as TippyTooltip } from 'react-tippy'
import 'react-tippy/dist/tippy.css'

import { Text } from '../Text'

type TooltipProps = {
  children: JSX.Element | React.ReactNode
  isDisabled?: boolean
  html?: TippyTooltip['props']['html']
  title?: string
  open?: TippyTooltip['props']['open']
  arrow?: TippyTooltip['props']['arrow']
  distance?: TippyTooltip['props']['distance']
  position?: TippyTooltip['props']['position']
  delay?: [number, number]
  style?: React.CSSProperties
  className?: string
  trigger?: TippyTooltip['props']['trigger']
  interactive?: boolean
  unmountHTMLWhenHide?: TippyTooltip['props']['unmountHTMLWhenHide']
  theme?: TippyTooltip['props']['theme'] | string
  // react tippy leaves a browser default tooltip to "null".
  // So instead of using its "disabled" prop, we don't render it at all if this shouldHardDisable is true
  shouldHardDisable?: boolean
  popperOptions?: any // refer to popper.js popperOptions configs
}

export const Tooltip = (props: TooltipProps) => {
  const renderTippyTooltip = () => (
    <TippyTooltip
      duration={100}
      theme={props.theme as Theme}
      animation="fade"
      size="small"
      arrow={props.arrow ?? true}
      distance={props.distance}
      position={props.position}
      interactive={props.interactive}
      useContext
      // @ts-ignore
      delay={props.delay}
      disabled={props.isDisabled}
      html={props.title ? <Text size="xs">{props.title}</Text> : props.html}
      style={props.style}
      className={props.className}
      popperOptions={props.popperOptions}
      unmountHTMLWhenHide={props.unmountHTMLWhenHide}
      trigger={props.trigger}
      open={props.open}
    >
      {props.children}
    </TippyTooltip>
  )

  return (
    <>
      {props.shouldHardDisable && props.isDisabled
        ? props.children
        : renderTippyTooltip()}
    </>
  )
}
