import React, { createContext, useContext, useMemo } from 'react'

import { useLocalStorage } from './useLocalStorage'

export interface Guest {
  uid: string
  displayName: string | undefined
}

interface GuestUserContextValue {
  guest: Guest
  setGuestDisplayName: React.Dispatch<React.SetStateAction<string>>
  setGuestId: React.Dispatch<React.SetStateAction<string>>
}

const initialState: GuestUserContextValue = {
  guest: {
    uid: '',
    displayName: '',
  },
  setGuestDisplayName: () => undefined,
  setGuestId: () => undefined,
}

const GuestUserContext = createContext(initialState)

type GuestUserProviderProps = {
  children: React.ReactNode
}

const GuestUserProvider = ({ children }: GuestUserProviderProps) => {
  const [guestDisplayName, setGuestDisplayName] = useLocalStorage(
    'GUEST_DISPLAY_NAME',
    ''
  )

  const [guestId, setGuestId] = useLocalStorage('GUEST_UID', '')

  const contextValue = useMemo(
    () => ({
      guest: { uid: guestId, displayName: guestDisplayName },
      setGuestId,
      setGuestDisplayName,
    }),
    [guestId, setGuestId, guestDisplayName, setGuestDisplayName]
  )

  return (
    <GuestUserContext.Provider value={contextValue}>
      {children}
    </GuestUserContext.Provider>
  )
}

const useGuest = () => useContext(GuestUserContext)

export { useGuest, GuestUserProvider }
