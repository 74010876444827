import { QuestionCircle } from '@styled-icons/bootstrap/QuestionCircle'
import React from 'react'
import styled, { css, useTheme } from 'styled-components'

import { Margin } from 'components/common/Margin'
import { Text } from 'components/common/Text'
import { Tooltip } from 'components/common/Tooltip/Tooltip'

interface SetupInfoTextProps {
  infoText: string
  tipText: string
  trackingEventOpened?: () => void
  trackingEventClosed?: () => void
}

const SetupInfoText: React.FC<SetupInfoTextProps> = ({
  infoText,
  tipText,
  trackingEventOpened,
  trackingEventClosed,
}) => {
  const theme = useTheme()

  return (
    <Wrapper>
      <Text size="sm" color="neutral.4">
        {infoText}
      </Text>

      <Margin x={6} />

      <div
        onMouseEnter={() => {
          if (trackingEventOpened) {
            trackingEventOpened()
          }
        }}
        onMouseLeave={() => {
          if (trackingEventClosed) {
            trackingEventClosed()
          }
        }}
      >
        <Tooltip title={tipText} position="bottom">
          <QuestionCircle
            height="20px"
            style={{ color: theme.colors.text.neutral[2] }}
          />
        </Tooltip>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    margin-top: 20px;
    height: 30px;
    padding: 0px 22px;
  `}
`

export default SetupInfoText
