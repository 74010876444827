import { Image } from 'react-konva'
import useImage from 'use-image'

type Props = {
  url: string
  x: number
  y: number
  width: number | 'auto'
  height: number | 'auto'
  globalCompositeOperation: string
}
export const KiveImage = ({
  url,
  x,
  y,
  width,
  height,
  globalCompositeOperation,
}: Props) => {
  const [image] = useImage(url, 'anonymous')
  if (!image) return null
  const newWidth = width === 'auto' ? image.width / 2 : width
  const newHeight = height === 'auto' ? image.height / 2 : height
  return (
    <Image
      globalCompositeOperation={globalCompositeOperation}
      image={image}
      width={newWidth}
      height={newHeight}
      x={x}
      y={y}
    />
  )
}
