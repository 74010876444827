import { motion } from 'framer-motion'
import React from 'react'
import styled, { css } from 'styled-components'

const imageTransitions = {
  inital: {
    scale: 1,
  },
  hover: {
    scale: 1.05,
  },
}

const transition = {
  ease: 'easeIn',
  duration: 0.1,
}

function capitalizeWords(string: string) {
  return string
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

type MagicBoardCardProps = {
  description: string
  thumbUrl?: string | null
  onClick?: () => void
}

export const TagCard: React.FC<MagicBoardCardProps> = ({
  description,
  thumbUrl,
  onClick,
}) => {
  return (
    <StyledCardSizing onClick={onClick} whileHover="hover" initial="inital">
      <StyledBorderWrapper>
        <StyledCardImage
          variants={imageTransitions}
          transition={transition}
          src={thumbUrl || ''}
        />
        <StyledTitle>{capitalizeWords(description)}</StyledTitle>
      </StyledBorderWrapper>
    </StyledCardSizing>
  )
}

const StyledCardSizing = styled(motion.div)`
  text-align: center;
  width: 220px;
`

const StyledBorderWrapper = styled(motion.div)`
  ${({ theme }) => css`
    background: ${theme.colors.background[3]};
    border-radius: ${theme.borderRadius.lg};
    overflow: hidden;
    border: 1px solid transparent;
    transition: border 0.1s;
    position: relative;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    :hover {
      border: 1px solid ${theme.colors.gray.dark[6]};
    }
  `}
`

const StyledCardImage = styled(motion.div).attrs<{ src: string }>((props) => ({
  style: { backgroundImage: `url(${props.src})` },
}))<{ src: string }>`
  background-color: black;
  background-size: cover;
  background-position: center;
  width: auto;
  padding: 24px;
  position: relative;
  overflow: hidden;
  height: 200px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.1s all ease-in;
  height: 108px;
`

const StyledTitle = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.lg};
    font-weight: ${theme.fontWeight.bold};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    color: ${theme.colors.white};
    z-index: 1;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    line-height: 108px;
    padding: 0 24px;
    text-shadow: 0px 1px 2px rgb(0 0 0 / 56%);
    @media (max-width: 768px) : {
      font-size: ${theme.fontSizes.base};
      line-height: 120px;
    }
  `}
`
