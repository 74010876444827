import { KeyboardArrowDown } from '@styled-icons/material/KeyboardArrowDown'
import React from 'react'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import styled, { css } from 'styled-components'

import Button from 'components/common/Button'
import useToastMessages from 'components/toast/useToastMessages'
import { useSaveWorkspaceItemsToBoardMutation } from 'generated/graphql'
import {
  getCustomErrorStatus,
  getGraphqlErrorCode,
} from 'helpers/parseGraphqlErrors'
import { getMostRecentBoardQueries } from 'helpers/queries'
import { RootState } from 'store'

interface SaveToBoardMiniProps {
  workspaceItemId: string
  onMenuOpenClick: (view: 'SELECT_BOARD' | 'SET_BOARD_TITLE') => void
}

const SaveToBoardMini: React.FC<SaveToBoardMiniProps> = ({
  workspaceItemId,
  onMenuOpenClick,
}) => {
  const activeWorkspaceId: string | undefined = useSelector(
    (state: RootState) => state.content.activeWorkspace?.id
  )
  const activeWorkspaceUrl: string | undefined = useSelector(
    (state: RootState) => state.content.activeWorkspace?.url
  )
  const email = useSelector((state: RootState) => state.firebase.auth.email)

  if (!activeWorkspaceId) {
    throw new Error('SaveToBoardMini: no active workspace id available')
  }
  if (!email) {
    throw new Error('SaveToBoardMini: no email available')
  }

  const [saveWorkspaceItemsToBoard, { loading }] =
    useSaveWorkspaceItemsToBoardMutation()

  useFirestoreConnect(() => getMostRecentBoardQueries(activeWorkspaceId, email))

  const mostRecentBoard = useSelector(
    (state: RootState) => state.firestore.ordered.mostRecentBoard?.[0]
  )
  const { reportSuccess, reportError } = useToastMessages()

  const { title = null } = mostRecentBoard ?? {}

  const handleSave = async () => {
    if (!mostRecentBoard) return

    try {
      await saveWorkspaceItemsToBoard({
        variables: {
          workspaceId: activeWorkspaceId,
          boardId: mostRecentBoard.id,
          workspaceItemIds: [workspaceItemId],
        },
      })
      reportSuccess(
        'Added to board:',
        {
          linkTo: `/${activeWorkspaceUrl}/p/${mostRecentBoard.projectId}/${mostRecentBoard.id}`,
          linkText: `${mostRecentBoard.title}`,
        },
        5000
      )
    } catch (error) {
      const code = getGraphqlErrorCode(error)
      const status = getCustomErrorStatus(error)
      if (status === 'ALREADY_EXISTS') {
        return reportError(
          `The item already exists in "${mostRecentBoard.title}"`
        )
      }
      if (code === 'FORBIDDEN') {
        return reportError(
          `You do not have permission to save items to "${mostRecentBoard.title}"`
        )
      }
      return reportError('We could not save the item to the board...')
    }
  }

  return (
    <div style={{ width: '100%', display: 'flex' }}>
      {!mostRecentBoard ? (
        <Button
          onClick={() => onMenuOpenClick('SET_BOARD_TITLE')}
          style={{ width: '100%' }}
        >
          Save to new board
        </Button>
      ) : (
        <>
          <SaveToBoardButtonNew
            fullWidth
            onClick={() => onMenuOpenClick('SELECT_BOARD')}
          >
            {mostRecentBoard.title}
            <KeyboardArrowDown
              style={{ width: 24, marginRight: 12, float: 'right' }}
            />
          </SaveToBoardButtonNew>

          <SaveButton
            isLoading={loading}
            variant="primary"
            disabled={title === null}
            onClick={handleSave}
          >
            Save
          </SaveButton>
        </>
      )}
    </div>
  )
}

export default SaveToBoardMini

const SaveToBoardButtonNew = styled(Button)`
  ${({ theme }) => css`
    color: ${theme.colors.text.neutral[3]};
    white-space: normal;
    text-align: left;
    padding: 0px 12px;
    justify-content: space-between;
    &:hover {
      color: ${theme.colors.text.neutral[0]};
    }
  `}
`

const SaveButton = styled(Button)`
  border-radius: 0px 12px 12px 0px;
  width: 80px;
`
