import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  isEmpty,
  isLoaded,
  useFirestore,
  useFirestoreConnect,
} from 'react-redux-firebase'
import { Link } from 'react-router-dom'

import Button from 'components/common/Button'
import { Margin } from 'components/common/Margin'
import Modal from 'components/common/Modal'
import { Text } from 'components/common/Text'
import { RootState } from 'store'

import MenuSection from './MenuSection'
import { FavoriteNavItemStyled, NavItemStyled, StyledCross } from './MenuStyles'

const FavoritesMenu = ({ siderSplitValue }: { siderSplitValue: number }) => {
  const favoriteBoards = useSelector(
    (state: RootState) =>
      state.firestore.data.userPrivate?.favorites?.boards || []
  )

  if (favoriteBoards.length === 0) return null

  // we don't support favorites in the menu for mobiles yet
  if (siderSplitValue < 160) return null

  return (
    <MenuSection
      siderSplitValue={siderSplitValue}
      title="Favorites"
      withChildrenToggle
    >
      {favoriteBoards.map((boardId) => (
        <FavoriteBoard
          key={boardId}
          boardId={boardId}
          siderSplitValue={siderSplitValue}
        />
      ))}
    </MenuSection>
  )
}

export default FavoritesMenu

const FavoriteBoard = ({
  siderSplitValue,
  boardId,
}: {
  siderSplitValue: number
  boardId: string
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const firestore = useFirestore()
  const currentUserId = useSelector(
    (state: RootState) => state.firebase.auth.uid
  )

  const favoriteBoard = useSelector(
    (state: RootState) => state.firestore.data[`favoriteBoard_${boardId}`]
  )

  useFirestoreConnect([
    {
      collection: 'boards',
      doc: boardId,
      storeAs: `favoriteBoard_${boardId}`,
    },
  ])

  const removeBoardFromFavorites = () => {
    setIsModalVisible(false)

    const userFavoritesRef = firestore
      .collection('users')
      .doc(currentUserId)
      .collection('private')
      .doc('favorites')

    userFavoritesRef.set(
      {
        boards: firestore.FieldValue.arrayRemove(boardId),
      },
      { merge: true }
    )
  }

  if (!isLoaded(favoriteBoard)) return null
  if (isEmpty(favoriteBoard)) return null

  return (
    <>
      <FavoriteNavItem
        path={`/open-favorite-board/${boardId}`}
        title={favoriteBoard.title}
        leftIconComponent={favoriteBoard.iconCode || '🤍'}
        rightIconComponent={
          <StyledCross onClick={() => setIsModalVisible(true)} />
        }
        isActive={false}
        siderSplitValue={siderSplitValue}
      />
      <Modal isOpen={isModalVisible} close={() => setIsModalVisible(false)}>
        <Modal.ContentWrapper>
          <Text size="lg" as="h2" color="neutral.0">
            Are you sure you want to remove <br />{' '}
            <em>{favoriteBoard.title}</em> from your favorites?
          </Text>
          <Margin y={16} />
          <div css="display: flex">
            <Button variant="primary" onClick={removeBoardFromFavorites}>
              Remove
            </Button>
            <Margin x={16} />
            <Button onClick={() => setIsModalVisible(false)}>Cancel</Button>
          </div>
        </Modal.ContentWrapper>
      </Modal>
    </>
  )
}

interface FavoriteNavItemProps {
  isActive: boolean
  siderSplitValue: number
  title: string
  path: string
  leftIconComponent: JSX.Element
  rightIconComponent: JSX.Element
}

const FavoriteNavItem = ({
  isActive,
  siderSplitValue,
  title,
  path,
  leftIconComponent,
  rightIconComponent,
}: FavoriteNavItemProps) => {
  return (
    <FavoriteNavItemStyled>
      <Link
        to={path}
        style={{ width: rightIconComponent ? 'calc(100% - 32px)' : 'auto' }}
      >
        <NavItemStyled siderSplitValue={siderSplitValue} active={isActive}>
          {leftIconComponent}
          <Margin x={16} />
          <Text
            css="overflow: hidden;text-overflow: ellipsis;"
            nowrap
            size="sm"
            color={isActive ? 'accent.1' : 'neutral.1'}
          >
            {title}
          </Text>
        </NavItemStyled>
      </Link>

      {rightIconComponent}
    </FavoriteNavItemStyled>
  )
}
