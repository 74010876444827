import { get } from 'lodash'
import React, { useState } from 'react'
import { useTheme } from 'styled-components'

import {
  BillingSubscriptionQuery,
  useActivePlansQuery,
  useCreateBillingPortalSessionMutation,
} from '../../../../generated/graphql'
import { reportError as reportSentryError } from '../../../../helpers/logging'
import Loading from '../../../Loading'
import Button from '../../../common/Button'
import { SubscriptionInterval } from '../../types'
import { AnnualUpsell } from '../AnnualUpsell'
import { AnnualUpsellSmallScreens } from '../AnnualUpsellSmallScreens'
import {
  PlanHeader,
  StyledContainer,
  StyledFeatureRow,
  StyledFeaturesHeading,
  StyledRow,
} from '../ComparePlansStyles'
import { FeatureLine } from '../FeatureLine'
import { FeatureSubline } from '../FeatureSubline'
import { getButtonText } from './get-button-text'

type PlansGridProps = {
  transformPlanPrice?: (_: any) => string
  subscriptionInterval: SubscriptionInterval
  subscription: BillingSubscriptionQuery | undefined
  workspaceId: string
  setSubscriptionInterval: (interval: SubscriptionInterval) => void
  handleDowngradeClick: (props: {
    planId: string
    subscriptionId: string
    priceId: string
  }) => void
}

export function PlansGrid({
  transformPlanPrice,
  subscriptionInterval,
  subscription,
  workspaceId,
  setSubscriptionInterval,
  handleDowngradeClick,
}: PlansGridProps) {
  const [planActionIsLoading, setPlanActionIsLoading] = useState<string>()
  const { data: activePlans, loading: loadingActivePlans } =
    useActivePlansQuery()
  const [
    createBillingPortalSessionMutation,
    { loading: isCreatingBillingPortalSession, error: billingPortalError },
  ] = useCreateBillingPortalSessionMutation()
  const theme = useTheme()
  const productColors = [
    undefined,
    theme.colors.accent[3],
    theme.colors.accent[2],
    theme.colors.accent[1],
  ]

  const createBillingPortalSessionAndRedirect = async ({
    subscriptionId,
    priceId,
    planId,
  }: {
    subscriptionId: string
    priceId: string
    planId: string
  }) => {
    if (isCreatingBillingPortalSession || billingPortalError) return
    try {
      const searchParams = new URLSearchParams(window.location.search)
      searchParams.append('toPlan', `${planId}`)
      const returnUrl = new URL(
        `${window.location.origin}${window.location.pathname}${
          Array.from(searchParams.entries()).length > 0
            ? `?${searchParams.toString()}`
            : ''
        }`
      )
      console.log(
        `Creating billing portal session with the following payload`,
        {
          variables: {
            workspaceId,
            returnUrl: returnUrl.href,
          },
        }
      )
      const { data } = await createBillingPortalSessionMutation({
        variables: {
          workspaceId,
          returnUrl: returnUrl.href,
        },
      })
      const url = data?.createBillingPortalSession?.url
      if (!url) {
        return
      }
      window.location.assign(
        `${url}/subscriptions/${subscriptionId}/preview/${priceId}`
      )
    } catch (error) {
      reportSentryError(error)
    }
  }

  const sortedPlans = [...(activePlans?.plans ?? [])].sort(
    (a, b) => Number(a.order) - Number(b.order)
  )

  return (
    <>
      {loadingActivePlans ? <Loading /> : null}
      {!loadingActivePlans ? (
        <StyledContainer>
          {sortedPlans.map((plan, index) => {
            const previousPlan = index === 0 ? null : sortedPlans[index - 1]
            const FEATURE_HEADING_TEMPLATE = `Everything in {{planName}} plus`
            const FEATURE_HEADING_DEFAULT = `Key features`
            const featureHeading = previousPlan
              ? FEATURE_HEADING_TEMPLATE.replace(
                  '{{planName}}',
                  previousPlan.presentation.name
                )
              : FEATURE_HEADING_DEFAULT
            const interval = get(
              plan.presentation.intervals,
              subscriptionInterval
            )

            if (!interval) return null

            const actionButton = getButtonText({
              interval,
              subscriptionItems:
                subscription?.billingSubscription?.subscriptionItems,
              subscriptionInterval,
              currentPlanId: `${subscription?.billingSubscription?.plan?.id}`,
              iteratedPlanId: `${plan.id}`,
              status: `${subscription?.billingSubscription?.plan?.status}`,
            })

            let rowNumber = 0
            const getNextRowNumber = () => {
              rowNumber += 1
              return rowNumber
            }

            let discount = 0
            let price = 0
            if (subscriptionInterval === 'monthly') {
              const monthly = get(plan.presentation.intervals, 'monthly')
              const monthlyPrice =
                (Number(monthly?.priceActual?.unitAmount) / 100) * 12

              const yearly = get(plan.presentation.intervals, 'yearly')
              const yearlyPrice = Number(yearly?.priceActual?.unitAmount) / 100

              discount = monthlyPrice - yearlyPrice
              price = yearlyPrice
            }

            return (
              <React.Fragment key={plan.id}>
                <StyledRow row={() => getNextRowNumber()} columnIndex={index}>
                  <PlanHeader
                    color={productColors[index]}
                    name={`${interval.priceActual?.product.name}`}
                    oldPrice={`${interval.priceActual?.priceString}`}
                    newPrice={`${
                      transformPlanPrice
                        ? transformPlanPrice(interval.priceActual?.priceString)
                        : interval.priceActual?.priceString
                    }`}
                    interval={subscriptionInterval}
                    priceUnit={`${interval.priceUnit}`}
                    text={`${interval.priceActual?.product.description}`}
                    actionButton={actionButton}
                  />
                </StyledRow>
                {subscriptionInterval === 'monthly' ? (
                  <StyledRow row={() => getNextRowNumber()} columnIndex={index}>
                    <AnnualUpsell
                      setSubscriptionInterval={setSubscriptionInterval}
                      subscriptionInterval={subscriptionInterval}
                      discount={discount}
                      price={price}
                    />
                    <AnnualUpsellSmallScreens
                      setSubscriptionInterval={setSubscriptionInterval}
                      subscriptionInterval={subscriptionInterval}
                      discount={discount}
                      price={price}
                    />
                  </StyledRow>
                ) : null}
                <StyledRow row={() => getNextRowNumber()} columnIndex={index}>
                  <Button
                    variant={actionButton.variant}
                    disabled={
                      isCreatingBillingPortalSession ||
                      !!billingPortalError ||
                      actionButton.disabled ||
                      planActionIsLoading === plan.id
                    }
                    isCompact
                    isLoading={planActionIsLoading === plan.id}
                    onClick={async () => {
                      setPlanActionIsLoading(plan.id)
                      if (actionButton.text === 'Downgrade') {
                        handleDowngradeClick({
                          priceId: `${interval.priceId}`,
                          subscriptionId: `${subscription?.billingSubscription?.id}`,
                          planId: `${plan.id}`,
                        })
                        setPlanActionIsLoading(undefined)
                      } else {
                        await createBillingPortalSessionAndRedirect({
                          priceId: `${interval.priceId}`,
                          subscriptionId: `${subscription?.billingSubscription?.id}`,
                          planId: `${plan.id}`,
                        })
                        setPlanActionIsLoading(undefined)
                      }
                    }}
                  >
                    {actionButton.text}
                  </Button>
                </StyledRow>
                <StyledRow row={() => getNextRowNumber()} columnIndex={index}>
                  <StyledFeaturesHeading>
                    {featureHeading}
                  </StyledFeaturesHeading>
                </StyledRow>
                <StyledRow row={() => getNextRowNumber()} columnIndex={index}>
                  {plan.features.map((feature, idx) => {
                    if (!feature) return null
                    let lineContent = feature.template
                    if (feature.replace_key && plan.limits) {
                      lineContent = lineContent.replace(
                        '{{limit}}',
                        new Intl.NumberFormat('en-US').format(
                          Number(get(plan.limits, feature.replace_key))
                        )
                      )
                    }
                    return (
                      <StyledFeatureRow
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${feature.__typename}-${plan.id}-${idx}`}
                      >
                        <FeatureLine>{lineContent}</FeatureLine>
                        {feature.subline ? (
                          <FeatureSubline>{feature.subline}</FeatureSubline>
                        ) : null}
                      </StyledFeatureRow>
                    )
                  })}
                </StyledRow>
              </React.Fragment>
            )
          })}
        </StyledContainer>
      ) : null}
    </>
  )
}
