import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { Input as CommonInput } from 'components/common/Form'
import { Margin } from 'components/common/Margin'
import Spinner from 'components/common/Spinner'
import { Text } from 'components/common/Text'
import { db } from 'config/firebase'
import { RootState } from 'store'

interface VimeoUsernameFormProps {
  onSubmitStarted: () => void
  onSubmitEnded: () => void
}

const VimeoUsernameForm = (props: VimeoUsernameFormProps) => {
  const currentUserId = useSelector(
    (state: RootState) => state.firebase.auth.uid
  )
  const [username, setUsername] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setIsLoading(true)
    props.onSubmitStarted()
    try {
      event.preventDefault()
      const trimmed = username.trim()
      const cleanUsername = trimmed.replace('@', '')
      if (!cleanUsername) return null
      // we use "db" here to avoid optimistic response
      await db.collection('users').doc(currentUserId).update({
        vimeoUserId: cleanUsername,
      })
      setTimeout(() => {
        props.onSubmitEnded() // solves issue where vimeoUserId is not yet present when fetching suggestions in backend
      }, 1000)
    } catch (error) {
      setIsLoading(false)
      props.onSubmitEnded()
    }
  }
  if (isLoading) {
    return <Spinner css="height:24px" />
  }

  return (
    <form onSubmit={handleFormSubmit}>
      <Text size="sm" color="neutral.3">
        Using Vimeo? Add your Vimeo username for suggestions
      </Text>
      <Margin y={4} />
      <Input
        placeholder="@lindh"
        value={username}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setUsername(event.target.value)
        }
      />
    </form>
  )
}

export default VimeoUsernameForm

const Input = styled(CommonInput)`
  background-color: ${(props) => props.theme.colors.background[4]};
  border-color: transparent;
  ::placeholder {
    color: ${(props) => props.theme.colors.text.neutral[2]};
    opacity: 0.7;
  }
`
