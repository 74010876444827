import { DBBoard } from 'types/db'

import useBoardUserRole, { BoardRoles } from './useBoardUserRole'

export type BoardPermissions =
  | 'EDIT_BOARD'
  | 'DELETE_BOARD'
  | 'REMOVE_BOARD_ITEM'
  | 'ADD_BOARD_ITEM' // also requires that the user has workspace permission to upload
  | 'ADD_BOARD_ITEM_REACTION'
  | 'CREATE_SOCIAL_ASSETS'
  | 'EDIT_BOARD_USER_PERMISSIONS'

export const boardPermissions: Record<BoardRoles, BoardPermissions[]> = {
  OWNER: [
    'EDIT_BOARD',
    'DELETE_BOARD',
    'REMOVE_BOARD_ITEM',
    'ADD_BOARD_ITEM',
    'ADD_BOARD_ITEM_REACTION',
    'CREATE_SOCIAL_ASSETS',
    'EDIT_BOARD_USER_PERMISSIONS',
  ],
  WRITER: [
    'EDIT_BOARD',
    'REMOVE_BOARD_ITEM',
    'ADD_BOARD_ITEM',
    'ADD_BOARD_ITEM_REACTION',
    'CREATE_SOCIAL_ASSETS',
  ],
  COMMENTER: ['ADD_BOARD_ITEM_REACTION', 'CREATE_SOCIAL_ASSETS'],
  READER: ['CREATE_SOCIAL_ASSETS'],
}

const useBoardPermissions = (
  board: DBBoard | undefined,
  askedPermissions: BoardPermissions[]
): Record<BoardPermissions, boolean | undefined> => {
  const userBoardRole = useBoardUserRole(board)
  const currentUserPermissions = userBoardRole
    ? boardPermissions[userBoardRole]
    : []
  return askedPermissions.reduce((accPermissions, askedPermission) => {
    return {
      ...accPermissions,
      [askedPermission]: currentUserPermissions.includes(askedPermission),
    }
  }, {} as Record<BoardPermissions, boolean | undefined>)
}

export default useBoardPermissions
