import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { ArrowLeft } from 'styled-icons/bootstrap'

import { StyledButtonMedium } from '../../../features/editor/ButtonMedium'
import { EditSession, useEditSessionsQuery } from '../../../generated/graphql'
import { RootState } from '../../../store'
import Loading from '../../Loading'
import {
  StyledBackButtonContainer,
  StyledRoomCard,
  StyledRoomCardContainer,
  StyledRoomCardContent,
  StyledRoomCardContentText,
  StyledRoomCardContentTimestamp,
  StyledRoomCardGrid,
  StyledRoomImage,
  StyledRoomImageContainer,
  StyledRoomJoinButton,
  StyledRoomJoinButtonContainer,
  StyledRoomTypeTag,
  StyledRoomTypeTagContainer,
  StyledRoomTypeTagRow,
  StyledShowMoreButtonContainer,
} from './EditSessionLobbyStyles'

type Tab = 'trending' | 'previous'
export const EditSessionLobby = () => {
  const [selectedTab, setSelectedTab] = useState<Tab>('previous')
  const [numCardsToShow, setNumCardsToShow] = useState(8)
  const navigate = useNavigate()

  const { isEmpty } = useSelector((state: RootState) => state.firebase.auth)
  const { data: editSessionsData, loading: editSessionsLoading } =
    useEditSessionsQuery({
      variables: {
        input: {
          first: 50,
          cursor: null,
        },
      },
      fetchPolicy: 'cache-and-network',
      skip: isEmpty,
    })

  useEffect(() => {
    if (isEmpty) {
      navigate('/login')
    }
  }, [isEmpty, navigate])

  if (editSessionsLoading) {
    return <Loading />
  }

  if (
    !editSessionsData?.previousEditSessions ||
    !editSessionsData?.liveEditSessions
  ) {
    return null
  }

  const { editSessions: liveEditSessions } = editSessionsData.liveEditSessions
  const { editSessions: previousEditSessions } =
    editSessionsData.previousEditSessions

  let sessionsToRender = []
  switch (selectedTab) {
    case 'trending':
      sessionsToRender = liveEditSessions
      break
    case 'previous':
      sessionsToRender = previousEditSessions
      break
    default:
      sessionsToRender = [] as EditSession[]
      break
  }

  return (
    <>
      <Helmet>
        <meta property="og:title" content="Kive AI Canvas Lobby" />
        <meta
          property="og:description"
          content="Create with AI in multiplayer."
        />
      </Helmet>
      <StyledBackButtonContainer>
        <StyledButtonMedium onClick={() => navigate(`/app`)}>
          <ArrowLeft width={20} />
        </StyledButtonMedium>
      </StyledBackButtonContainer>
      <StyledRoomTypeTagRow>
        <StyledRoomTypeTagContainer>
          <StyledRoomTypeTag
            active={selectedTab === 'trending'}
            onClick={() => setSelectedTab('trending')}
          >
            Trending rooms
          </StyledRoomTypeTag>
          <StyledRoomTypeTag
            active={selectedTab === 'previous'}
            onClick={() => setSelectedTab('previous')}
          >
            Previous rooms
          </StyledRoomTypeTag>
        </StyledRoomTypeTagContainer>
      </StyledRoomTypeTagRow>
      <StyledRoomCardGrid>
        {sessionsToRender.slice(0, numCardsToShow).map((editSession) => {
          const ogImageQuery = editSession.updatedAt
            ? `?v=${editSession.updatedAt}`
            : ''

          const updatedAt = new Date(editSession.updatedAt * 1000)
          const now = new Date()

          const msecDiff = now.getTime() - updatedAt.getTime()
          const dd = Math.floor(msecDiff / 1000 / 60 / 60 / 24)
          const hh = Math.floor(msecDiff / 1000 / 60 / 60)
          const mm = Math.floor(msecDiff / 1000 / 60)

          let timestamp = ''
          if (mm && mm > 0) {
            timestamp = `${mm}m ago`
          }
          if (hh && hh > 0) {
            timestamp = `${hh}h ago`
          }
          if (dd && dd > 0) {
            timestamp = `${dd} day${dd > 1 ? 's' : ''} ago`
          }

          console.log(
            `${editSession.id} editSession.thumbnailUrl=${editSession.thumbnailUrl}`
          )

          return (
            <StyledRoomCard
              key={editSession.id}
              onClick={() => navigate(`/canvas/${editSession.id}`)}
            >
              <StyledRoomCardContainer>
                <StyledRoomImageContainer>
                  <StyledRoomImage
                    src={`${
                      editSession.thumbnailUrl ??
                      `https://kive.site/og-images/canvas/${editSession.id}${ogImageQuery}`
                    }`}
                    alt=""
                  />
                  <StyledRoomJoinButtonContainer>
                    <StyledRoomJoinButton
                      onClick={() => navigate(`/canvas/${editSession.id}`)}
                    >
                      Join room
                    </StyledRoomJoinButton>
                  </StyledRoomJoinButtonContainer>
                </StyledRoomImageContainer>
                <StyledRoomCardContent>
                  <StyledRoomCardContentText>
                    {editSession.members.length} creator
                    {editSession.members.length === 0 ? '' : 's'}
                  </StyledRoomCardContentText>
                  <StyledRoomCardContentTimestamp>
                    {timestamp}
                  </StyledRoomCardContentTimestamp>
                </StyledRoomCardContent>
              </StyledRoomCardContainer>
            </StyledRoomCard>
          )
        })}
      </StyledRoomCardGrid>
      {sessionsToRender.length > numCardsToShow ? (
        <StyledShowMoreButtonContainer>
          <StyledButtonMedium
            hasText
            onClick={() =>
              setNumCardsToShow(
                numCardsToShow +
                  (sessionsToRender.length - numCardsToShow <= 7
                    ? sessionsToRender.length - numCardsToShow
                    : 8)
              )
            }
          >
            Show more
          </StyledButtonMedium>
        </StyledShowMoreButtonContainer>
      ) : null}
    </>
  )
}
