import { useCallback } from 'react'

import getSupportedFileExtensions from 'helpers/getSupportedFileExtensions'
import { reportError } from 'helpers/logging'

import { db } from '../../config/firebase'
import { getExtensionByFileName } from './fileHelpers'
import type { CustomDropboxFile, CustomFile, MappedFiles } from './types'

interface Params {
  workspaceId: string
  droppedFiles: MappedFiles
}

const MIN_FILE_SIZE = 5000 // 5 kb
export const MAX_FILE_SIZE = 50000000 // 50 mb

const useFileValidation = ({
  workspaceId,
  droppedFiles,
}: Params): {
  existsInDb: (
    file: CustomFile
  ) => Promise<{ isValid: boolean; duplicateItemId?: string }>
  isUniqueDrop: (file: CustomFile | CustomDropboxFile) => boolean
  isValidSizeAndType: (
    file: CustomFile | CustomDropboxFile,
    workspaceId: string
  ) => boolean
  batchRunFunction: (
    fileArray: Record<string, CustomFile>,
    startAt: number,
    validated: {
      file: CustomFile
      response: any
      key: string
    }[],
    batchSize: number,
    itemFunction: (file: CustomFile) => any,
    setProgress: React.Dispatch<React.SetStateAction<number>>
  ) => Promise<
    {
      file: CustomFile
      response: any
      key: string
    }[]
  >
} => {
  const supportedExtensions = getSupportedFileExtensions()

  const isUniqueDrop = useCallback(
    // Filter out identical drops
    (file: CustomFile | CustomDropboxFile) => {
      const isUnique = Object.values(droppedFiles).every((f) => {
        if ('isDropboxFile' in file || 'isDropboxFile' in f) {
          return !(f.id === file.id)
        }
        return !(f.size === file.size && f.path === file.path)
      })
      return isUnique
    },
    [droppedFiles]
  )

  const isValidSizeAndType = useCallback(
    /*
    Filter out small files and files with wrong format
    Min size 5 kb
    Formats jpg and png
    */
    (file: CustomFile | CustomDropboxFile, uuid = '') => {
      let isFileSizeValid = false
      let isFileSupported = false

      if ('isDropboxFile' in file) {
        const extensionByFileName = getExtensionByFileName(file.name)
        const extension = extensionByFileName?.toLowerCase() || ''
        isFileSizeValid = file.bytes > MIN_FILE_SIZE
        isFileSizeValid = file.bytes < MAX_FILE_SIZE
        isFileSupported = supportedExtensions.includes(extension)
        if (!isFileSizeValid || !isFileSupported) {
          reportError({
            isFileSizeValid,
            isFileSupported,
            workspaceId: uuid,
            file,
          })
        }
        return isFileSizeValid && isFileSupported
      }
      const extension = file.type.replace('image/', '').toLowerCase()
      isFileSizeValid = file.size > MIN_FILE_SIZE
      isFileSizeValid = file.size < MAX_FILE_SIZE
      isFileSupported = supportedExtensions.includes(extension)

      if (!isFileSizeValid || !isFileSupported) {
        reportError({
          isFileSizeValid,
          isFileSupported,
          extension,
          workspaceId: uuid,
          file,
        })
      }
      return isFileSizeValid && isFileSupported
    },

    []
  )

  const existsInDb = useCallback(
    async (file: CustomFile) => {
      // Check against db
      const existsInDbRes = await db
        .collection('workspaces')
        .doc(workspaceId)
        .collection('items')
        .where('workspaceId', '==', workspaceId)
        .where('origin.lastModified', '==', file.lastModified)
        .where('origin.size', '==', file.size)
        .get()

      const alreadyExistingItemId = existsInDbRes.docs?.[0]?.id

      const hashedDuplicatesRes = await db
        .collection('workspaces')
        .doc(workspaceId)
        .collection('items')
        .where('workspaceId', '==', workspaceId)
        .where(
          `origin.localDuplicates.${file.size}_${file.lastModified}`,
          '==',
          true
        )
        .get()

      const alreadyExistingLocalCheckItemId = hashedDuplicatesRes.docs?.[0]?.id

      const duplicateId =
        alreadyExistingItemId || alreadyExistingLocalCheckItemId

      return {
        isValid: !duplicateId,
        duplicateItemId: duplicateId,
      }
    },
    [workspaceId]
  )

  // Run async functions in batches, with progress callback
  const batchRunFunction = async (
    mappedFiles: Record<string, CustomFile>,
    startAt: number,
    validated: { file: CustomFile; response: any; key: string }[],
    batchSize: number,
    itemFunction: (file: CustomFile) => any,
    setProgress: React.Dispatch<React.SetStateAction<number>>
  ): Promise<{ file: CustomFile; response: any; key: string }[]> => {
    const endAt = startAt + batchSize

    const mappedFilesArray = Object.entries(mappedFiles)

    const batch = mappedFilesArray.slice(startAt, endAt)
    const result = await Promise.all(
      batch.map(async ([key, file], i) => {
        const response = await itemFunction(file)
        setProgress((startAt + i) / mappedFilesArray.length)
        return { response, file, key }
      })
    )

    const validatedUpdate = [...validated, ...result]
    if (endAt >= mappedFilesArray.length) return validatedUpdate
    return batchRunFunction(
      mappedFiles,
      endAt,
      validatedUpdate,
      batchSize,
      itemFunction,
      setProgress
    )
  }

  return { existsInDb, isUniqueDrop, isValidSizeAndType, batchRunFunction }
}

export default useFileValidation
