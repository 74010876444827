import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import Button from 'components/common/Button'
import { Input } from 'components/common/Form'
import { Margin } from 'components/common/Margin'
import { Spacer } from 'components/common/Spacer'
import Spinner from 'components/common/Spinner'
import { Text } from 'components/common/Text'
import useClickOutside from 'hooks/useClickOutside'
import useKeypress from 'hooks/useKeyPress'

import { KeyboardNavigationProvider } from '../keyboardnav/useKeyboardNavigation'
import FindBoard, { OnSelectBoardArgs } from './FindBoard'
import FindProject, { OnSelectProjectArgs } from './FindProject'
import SelectBoardAndProjectFooter from './SelectBoardAndProjectFooter'
import { useCreateBoardAndProject } from './useCreateBoardAndProject'

type View =
  | 'SELECT_BOARD'
  | 'SET_BOARD_TITLE'
  | 'SELECT_PROJECT'
  | 'SET_PROJECT_TITLE'

type SelectBoardAndProjectCommonProps = {
  onMenuClose: () => void
  headline: string
  defaultSelectView?: View
  isCreateNewBoardHidden?: boolean
  isCreateNewProjectHidden?: boolean
  boardAccessLevel?: 'writer' | 'reader'
  projectAccessLevel?: 'writer' | 'reader'
}

type SelectBoardProps = SelectBoardAndProjectCommonProps & {
  onSelectBoard: (args: {
    boardId: string
    boardTitle: string
    projectId: string
  }) => void
  selectType: 'SELECT_BOARD'
}

type SelectProjectProps = SelectBoardAndProjectCommonProps & {
  onSelectProject: (args: { projectId: string; projectTitle: string }) => void
  selectType: 'SELECT_PROJECT'
}

type SelectBoardAndProjectProps = SelectBoardProps | SelectProjectProps

const SelectBoardAndProject = (props: SelectBoardAndProjectProps) => {
  const [view, setView] = useState<View>(
    props.defaultSelectView || props.selectType
  )

  const [boardInputValue, setBoardInputValue] = useState('')
  const [projectInputValue, setProjectInputValue] = useState('')

  const { isLoading, createBoardOnly, createProjectAndBoard } =
    useCreateBoardAndProject({
      projectInputValue,
      boardInputValue,
    })

  useKeypress(['Escape'], props.onMenuClose, {
    override: true,
    stopPropagation: true,
  })

  const node = useClickOutside(props.onMenuClose)

  const handleSelectProject = async (project: OnSelectProjectArgs) => {
    if (props.selectType === 'SELECT_BOARD') {
      const res = await createBoardOnly({
        projectId: project.projectId,
        workspaceId: project.projectWorkspaceId,
      })

      if ('error' in res) return
      const { boardId, boardTitle, projectId } = res
      return props.onSelectBoard({ boardId, boardTitle, projectId })
    }
    if (props.selectType === 'SELECT_PROJECT') {
      return props.onSelectProject({
        projectId: project.projectId,
        projectTitle: project.projectTitle,
      })
    }
  }

  const handleSelectBoard = ({ board }: OnSelectBoardArgs) => {
    if (props.selectType === 'SELECT_BOARD') {
      return props.onSelectBoard({
        boardId: board.id,
        boardTitle: board.title,
        projectId: board.projectId,
      })
    }
  }

  const handleSubmitProjectCreation = async () => {
    const res = await createProjectAndBoard({
      shouldCreateBoard: props.selectType === 'SELECT_BOARD',
    })

    if ('error' in res) return

    const { boardId, boardTitle, projectId, projectTitle } = res

    if (boardId && boardTitle && props.selectType === 'SELECT_BOARD') {
      return props.onSelectBoard({
        boardId,
        boardTitle,
        projectId,
      })
    }
    if (props.selectType === 'SELECT_PROJECT') {
      return props.onSelectProject({ projectId, projectTitle })
    }
  }

  const initiateBoardCreation = () => {
    if (boardInputValue.length > 0) {
      setView('SELECT_PROJECT')
    } else {
      setView('SET_BOARD_TITLE')
    }
  }

  const initiateProjectCreation = async () => {
    if (projectInputValue.length > 0) {
      handleSubmitProjectCreation()
    } else {
      setView('SET_PROJECT_TITLE')
    }
  }

  const views: Record<View, JSX.Element> = {
    SELECT_BOARD: (
      <KeyboardNavigationProvider>
        <Text css="display:flex" size="sm" color="neutral.0" bold>
          {props.headline}
        </Text>
        <Margin y={6} />
        <StyledInput
          autoFocus
          placeholder="Search boards"
          value={boardInputValue}
          onChange={(e) => setBoardInputValue(e.target.value)}
        />
        <FindBoard
          boardInputValue={boardInputValue}
          onSelectBoard={handleSelectBoard}
          accessLevel={props.boardAccessLevel || 'writer'}
        />
        {!props.isCreateNewBoardHidden && (
          <>
            <Spacer factor={6} />
            <SelectBoardAndProjectFooter onAction={initiateBoardCreation}>
              {boardInputValue
                ? `Create new board "${boardInputValue}"`
                : 'Or create new board'}
            </SelectBoardAndProjectFooter>
          </>
        )}
      </KeyboardNavigationProvider>
    ),
    SET_BOARD_TITLE: (
      <>
        <form
          onSubmit={(event) => {
            event.preventDefault()
            setView('SELECT_PROJECT')
          }}
        >
          <StyledInput
            value={boardInputValue}
            autoFocus
            required
            placeholder="Enter new board name"
            onChange={(e) => setBoardInputValue(e.target.value)}
          />
          <Margin y={12} />
          <Button variant="primary" fullWidth type="submit">
            Set board title
          </Button>
        </form>
      </>
    ),
    SELECT_PROJECT: (
      <KeyboardNavigationProvider>
        <Text css="text-align:left" size="sm" color="neutral.0" bold>
          {props.selectType === 'SELECT_PROJECT'
            ? props.headline
            : `Select project for ${`"${boardInputValue}"`}`}
        </Text>
        <Margin y={6} />
        {isLoading ? (
          <div css="height: 80px; text-align:center">
            <Spinner color="white" size={24} />
          </div>
        ) : (
          <>
            <StyledInput
              value={projectInputValue}
              autoFocus
              placeholder="Search projects"
              onChange={(e) => setProjectInputValue(e.target.value)}
            />
            <FindProject
              projectInputValue={projectInputValue}
              onSelectProject={handleSelectProject}
              accessLevel={props.projectAccessLevel || 'writer'}
            />
          </>
        )}
        {!props.isCreateNewProjectHidden && (
          <>
            <Spacer factor={6} />
            <SelectBoardAndProjectFooter onAction={initiateProjectCreation}>
              {projectInputValue
                ? `Create new project "${projectInputValue}"`
                : 'Or create new project'}
            </SelectBoardAndProjectFooter>
          </>
        )}
      </KeyboardNavigationProvider>
    ),
    SET_PROJECT_TITLE: (
      <form
        onSubmit={(event) => {
          event.preventDefault()
          handleSubmitProjectCreation()
        }}
      >
        <StyledInput
          value={projectInputValue}
          autoFocus
          required
          placeholder="Enter new project name"
          onChange={(e) => setProjectInputValue(e.target.value)}
        />
        <Margin y={12} />
        <Button variant="primary" isLoading={isLoading} fullWidth type="submit">
          {props.selectType === 'SELECT_BOARD'
            ? 'Create board and project'
            : 'Create project'}
        </Button>
      </form>
    ),
  }

  return (
    <SelectBoardAndProjectWrapper ref={node}>
      <div css="padding: 12px">{views[view]}</div>
    </SelectBoardAndProjectWrapper>
  )
}

const SelectBoardAndProjectWrapper = styled.div`
  ${({ theme }) => css`
    z-index: 10;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: ${theme.colors.background[3]};
    font-size: ${theme.fontSizes.xs};
    position: relative;
  `}
`

const StyledInput = styled(Input)`
  ${({ theme }) => css`
    border: 0;
    height: 36px;
    background: ${theme.colors.background[5]};
    :hover,
    :focus {
      border: 0;
      background: ${theme.colors.background[5]};
    }
  `}
`

export default SelectBoardAndProject
