import { LiveObject } from '@liveblocks/client'
import { ClientSideSuspense } from '@liveblocks/react'
import { debounce } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { unstable_batchedUpdates } from 'react-dom'
import styled from 'styled-components'

import Loading from 'components/Loading'
import Flex from 'components/common/Flex'
import { Text } from 'components/common/Text'
import { trackEditSessionViewed } from 'helpers/tracking/tracking'
import useIsMobileDevice from 'hooks/useIsMobileDevice'
import { useMount } from 'hooks/useMount'

import {
  RoomProvider,
  useErrorListener,
  useRoom,
} from '../../config/liveblocks.config'
import { MobileOutpaint } from './MobileOutPaint'
import { Outpaint } from './Outpaint'

type Props = {
  roomId?: string
  startImageUrl?: string
  remountEditor: () => void
}

// The component acts as a waiting room if the room is full
const SuspenseComponent = (
  props: React.PropsWithChildren<{ remountEditor: () => void }>
) => {
  const [isConnected, setIsConnected] = useState(true)
  const room = useRoom()

  // eslint-disable-next-line
  const checkIsConnected = useCallback(
    debounce((event) => {
      if (event === 'open') {
        // Hack to refresh the component since liveblocks don't seem
        // to do this it self
        props.remountEditor()
      }
    }, 5000),
    []
  )

  useErrorListener((error) => {
    // Error code 4005 means that the room is ful
    // Threfore, the user is not connected to the session
    // @ts-ignore
    if (error.code === 4005) {
      setIsConnected(false)
    }
  })

  useEffect(() => {
    if (isConnected) return

    // If the user is not conncted to the session we want to know
    // whenever the connection changes (since liveblocks retries every 2000ms).
    // A debounced function is used since liveblocks sends connection updates
    // on each retry with the these states:
    // * Failing retry states:   "authenticating" -> "connecting" -> "open" -> "failed"
    // * Succeding retry states: "authenticating" -> "connecting" -> "open"
    const unsubsribe = room.events.connection.subscribe((event) => {
      checkIsConnected(event)
    })
    return () => unsubsribe()
    // eslint-disable-next-line
  }, [isConnected])

  if (!isConnected) {
    return (
      <StyledSuspenseWrapper>
        <Flex flexDirection="column" alignItems="center">
          <Text size="2xl" color="neutral.0">
            The canvas is currently full
          </Text>
          <Text size="xl" color="neutral.0">
            We will let you in as soon as there is an open spot.
          </Text>
          <Loading />
        </Flex>
      </StyledSuspenseWrapper>
    )
  }

  return (
    <StyledSuspenseWrapper>
      <Loading />
    </StyledSuspenseWrapper>
  )
}

export function Editor({ roomId, startImageUrl, remountEditor }: Props) {
  const safeRoomId = roomId ?? 'random-thing8'

  useMount(() => {
    trackEditSessionViewed({ editSessionId: safeRoomId })
    window?.Intercom?.('update', {
      hide_default_launcher: true,
    })
  })
  const isMobile = useIsMobileDevice()

  return (
    <RoomProvider
      unstable_batchedUpdates={unstable_batchedUpdates} // Remove as soon as we've upgraded to React 18!!!!
      initialPresence={{
        cursor: null,
        generationFrameStatus: 'SEEKING', // finding? fix?
        generationFrameCoords: null,
      }}
      initialStorage={{
        shapes: startImageUrl
          ? new LiveObject({
              'random-id': new LiveObject({
                type: 'image',
                url: startImageUrl,
                height: 'auto',
                width: 'auto',
                x: 412, // TODO: Fix so that it is center
                y: 212, // TODO: Fix so that it is center
                order: Math.floor(new Date().getTime() / 1000),
              }),
            })
          : new LiveObject(),
      }}
      id={safeRoomId}
    >
      <ClientSideSuspense
        fallback={<SuspenseComponent remountEditor={remountEditor} />}
      >
        {() =>
          isMobile ? (
            <MobileOutpaint editSessionId={safeRoomId} />
          ) : (
            <Outpaint editSessionId={safeRoomId} />
          )
        }
      </ClientSideSuspense>
    </RoomProvider>
  )
}

const StyledSuspenseWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`
