import { ArrowRightShort } from '@styled-icons/bootstrap'
import { ImageAdd } from '@styled-icons/boxicons-regular/ImageAdd'
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { connect, useSelector } from 'react-redux'
import { useFirestore } from 'react-redux-firebase'
import { useLocation } from 'react-router'
import { compose } from 'redux'
import { useTheme } from 'styled-components'

import Flex from 'components/common/Flex'
import { Margin } from 'components/common/Margin'
import Spinner from 'components/common/Spinner'
import SwitchToggle from 'components/common/SwitchToggle'
import { Text } from 'components/common/Text'
import useToastMessages from 'components/toast/useToastMessages'
import {
  trackSetupWorkspaceCreated,
  trackSetupWorkspaceUpdated,
} from 'helpers/tracking/tracking'
import { validateAllowedDomain } from 'helpers/validateAllowedDomain'
import useCurrentUser from 'hooks/useCurrentUser'
import useIsMobile from 'hooks/useIsMobile'
import { workspacesSelector } from 'store/selectors'

import { db } from '../../config/firebase'
import callCloudFunction from '../../helpers/callCloudFunction'
import { createWorkspaceUrlValidator } from '../../helpers/workspaceUtils'
import { Field, FieldWithPrefix, Input, InputError } from '../common/Form'
import SelectT, { SelectOption } from '../common/Select'
import { Button } from './SetupCommon'
import {
  Center,
  Content,
  DashedButton,
  Footer,
  MobileButton,
  Row,
  RowDescription,
  WorkspaceIcon,
  WorkspaceIconOverlay,
} from './SetupStyles'

const workspaceIconAnimationStates = {
  isHover: { opacity: 1, transition: { duration: 0.1 } },
  isNotHover: { opacity: 0, transition: { duration: 0.1 } },
}

function SetupWorkspaceCreateNew(props) {
  const firestore = useFirestore()
  const { reportError } = useToastMessages()
  const location = useLocation()
  const isMobile = useIsMobile()
  const currentUser = useCurrentUser()
  const theme = useTheme()
  const [workspaceIcon, setWorkspaceIcon] = useState(null)
  const [workspaceIconData, setWorkspaceIconData] = useState(null)
  const [workspaceIconHover, setWorkspaceIconHover] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [domainSuggestion, setDomainSuggestion] = useState(null)

  const [withTeam, setWithTeam] = useState(
    new URLSearchParams(location.search).get('withTeam') === 'true'
  )
  const workspaces = useSelector(workspacesSelector) || {}
  const workspace = workspaces[props.workspaceId]

  // Workspace animation control
  const inputRef = useRef()

  const validateWorkspaceUrl = useMemo(
    () => createWorkspaceUrlValidator(firestore, null),
    [firestore]
  )

  // Workspace icon logic
  const handleNewWorkspaceIcon = (e) => {
    const file = e.target.files[0]
    if (file == null) {
      return
    }
    if (!file.type.startsWith('image') || file.size > 10000000) {
      reportError('ONLY IMAGES under 10 MB!!')
      return
    }

    const formData = new FormData()
    formData.append('image', file, file.name)
    setWorkspaceIconData(formData)

    setWorkspaceIcon(URL.createObjectURL(e.target.files[0]))
  }

  const names = currentUser?.displayName
    ? currentUser.displayName.split(' ')
    : ['']
  const firstName = names.slice(0, names.length - 1).join(' ')

  const initialWorkspaceName = currentUser?.displayName
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
    .padEnd(5, '-')

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      workspaceTitle: `${firstName}${
        firstName.endsWith('s') ? '' : `'s`
      } Workspace`,
      workspaceUrl: initialWorkspaceName,
      allowedEmailDomains: [],
    },
  })
  const {
    fields: allowedEmailDomains,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'allowedEmailDomains',
  })

  const userEmailDomainAllowedIndex = allowedEmailDomains.findIndex(
    (allowedEmailDomain) => allowedEmailDomain.value === domainSuggestion
  )
  const userEmailDomainIsAllowed = userEmailDomainAllowedIndex >= 0

  const workspaceUrlLookup = async (suffix) => {
    const urlSuggestion = `${initialWorkspaceName}${suffix ? `-${suffix}` : ''}`
    if ((await validateWorkspaceUrl(urlSuggestion)) == null) {
      return urlSuggestion
    }
    return workspaceUrlLookup((suffix ?? 0) + 1)
  }

  useEffect(() => {
    if (!props.workspaceId) {
      return workspaceUrlLookup().then((workspaceUrl) => {
        setValue('workspaceUrl', workspaceUrl)
        setValue(
          'workspaceTitle',
          `${firstName}${firstName.endsWith('s') ? '' : `'s`} Workspace`
        )
      })
    }

    if (workspace) {
      setValue('workspaceUrl', workspace.url)
      setValue('workspaceTitle', workspace.title)
      setWorkspaceIcon(workspace.icon)
      workspace.allowedEmailDomains?.forEach((allowedEmailDomain) => {
        append({ value: allowedEmailDomain })
      })
      return
    }
  }, [])

  useEffect(() => {
    if (domainSuggestion && !userEmailDomainIsAllowed) {
      append({ value: domainSuggestion })
    }
  }, [domainSuggestion])

  useEffect(() => {
    const userEmailDomain = props.email?.split('@')?.[1]
    if (!userEmailDomain) return
    validateAllowedDomain(userEmailDomain).then((error) => {
      if (error) return
      setDomainSuggestion(userEmailDomain)
    })
  }, [props.email])

  const onSubmit = (values) => {
    setIsSubmitting(true)
    const allowedEmailDomains_ =
      values.allowedEmailDomains && withTeam
        ? [
            ...new Set(
              values.allowedEmailDomains
                .filter((item) => item.value && item.value.length > 0)
                .map((item) => item.value)
            ),
          ]
        : null

    const workspaceItem = {
      title: values.workspaceTitle,
      url: values.workspaceUrl.toLowerCase(),
      allowedEmailDomains: allowedEmailDomains_?.length
        ? allowedEmailDomains_
        : null,
      admins: [props.email],
      guests: [],
      members: workspaces[props.workspaceId]?.members
        ? workspaces[props.workspaceId].members
        : [],
      uploadsCount: 0,
      createdBy: props.uid,
      createdAt: firestore.FieldValue.serverTimestamp(),
      updatedAt: firestore.FieldValue.serverTimestamp(),
    }
    const onboardingUpdates = {
      completedWorkspace: true,
      updatedAt: firestore.FieldValue.serverTimestamp(),
    }

    const batch = db.batch()

    const workspaceRef = props.workspaceId
      ? db.collection('workspaces').doc(props.workspaceId)
      : db.collection('workspaces').doc()
    batch.set(workspaceRef, workspaceItem)

    workspaceItem.id = workspaceRef.id

    const onboardingRef = db
      .collection('users')
      .doc(props.uid)
      .collection('private')
      .doc('onboarding')
    batch.set(onboardingRef, onboardingUpdates, { merge: true })

    batch
      .commit()
      .then(() => {
        // Set the workspace icon
        if (workspaceIcon) {
          callCloudFunction(
            `addWorkspaceIcon?workspaceId=${workspaceRef.id}`,
            workspaceIconData
          )
        }

        // Segment Event –– Track Workspace Created
        if (workspace) {
          trackSetupWorkspaceUpdated({
            workspaceId: workspaceRef.id,
            workspaceTitle: values.workspaceTitle,
            workspaceUrl: values.workspaceUrl.toLowerCase(),
            allowedEmailDomains: values.allowedEmailDomains,
            withTeam,
            trackingSetupSessionId: props.trackingSetupSessionId,
          })
        } else {
          trackSetupWorkspaceCreated({
            workspaceId: workspaceRef.id,
            workspaceTitle: values.workspaceTitle,
            workspaceUrl: values.workspaceUrl.toLowerCase(),
            allowedEmailDomains: values.allowedEmailDomains,
            withTeam,
            trackingSetupSessionId: props.trackingSetupSessionId,
          })
        }
        setIsSubmitting(false)
        props.handleCreateNewWorkspace(workspaceItem, withTeam)
      })
      .catch((error) => {
        setIsSubmitting(false)
        console.error('Error: ', error)
      })
  }

  const handleWorkspaceOptions = (selected) => {
    return selected[0] === 'team' ? setWithTeam(true) : setWithTeam(false)
  }

  return (
    <>
      <Content isMobile={isMobile} style={{ width: isMobile ? '100%' : 400 }}>
        <form onSubmit={handleSubmit(onSubmit)} id="new-workspace">
          <SelectT
            mustHaveSelected
            defaultVal={withTeam ? 1 : 0}
            isMobile={isMobile}
            onChange={(selected) => handleWorkspaceOptions(selected)}
          >
            <SelectOption value="solo" emojiCode=":wolf_face:">
              I&apos;m riding solo
            </SelectOption>
            <SelectOption value="team" emojiCode="👯">
              I&apos;m with a team
            </SelectOption>
          </SelectT>
          <input
            type="file"
            ref={inputRef}
            onChange={handleNewWorkspaceIcon}
            style={{ display: 'none' }}
          />
          {isMobile ? (
            <Margin y={42} />
          ) : (
            <WorkspaceIcon
              isMobile={isMobile}
              imageUrl={workspaceIcon}
              onClick={() => inputRef.current?.click()}
              onMouseEnter={() => setWorkspaceIconHover(true)}
              onMouseLeave={() => setWorkspaceIconHover(false)}
            >
              <WorkspaceIconOverlay
                variants={workspaceIconAnimationStates}
                animate={
                  workspaceIconHover || !workspaceIcon
                    ? 'isHover'
                    : 'isNotHover'
                }
              >
                <ImageAdd
                  size={30}
                  style={{
                    color: workspaceIconHover
                      ? theme.colors.accent[2]
                      : theme.colors.gray.dark[7],
                  }}
                />
              </WorkspaceIconOverlay>
            </WorkspaceIcon>
          )}
          <Row narrow={!isMobile}>
            <Field
              style={{
                width: isMobile ? 'calc(100vw - 34px)' : '',
              }}
              required
              fieldSetProps={{ style: { textAlign: 'left' } }}
              name="workspaceTitle"
              register={register}
              formValidationOptions={{
                required: 'Workspace title is required',
              }}
              errors={errors}
              placeholder={`i.e. ${firstName}'s Workspace or Company X`}
            />
          </Row>
          <Row>
            <FieldWithPrefix
              isMobile={isMobile}
              required
              prefix="kive.ai/"
              pattern="([a-z0-9-]+)*"
              name="workspaceUrl"
              register={register}
              formValidationOptions={
                props.workspaceId
                  ? {
                      required: 'Workspace url is required',
                    }
                  : {
                      required: 'Workspace url is required',
                      validate: validateWorkspaceUrl,
                    }
              }
              errors={errors}
            />
            <RowDescription css="margin-top:4px">
              Your unique workspace url
            </RowDescription>
          </Row>
          {withTeam && (
            <Row>
              <Margin y={16} />
              <Text size="sm" bold color="neutral.0" css="text-align:start">
                Auto-join
              </Text>
              <Margin y={2} />
              <Text size="xs" color="neutral.3" css="text-align:start">
                Allow anyone with a specified email domain to join your
                workspace
              </Text>
              <Margin y={12} />
              {domainSuggestion && (
                <>
                  <Flex alignItems="center">
                    <SwitchToggle
                      isOn={userEmailDomainIsAllowed}
                      onToggle={() => {
                        if (userEmailDomainIsAllowed) {
                          remove(userEmailDomainAllowedIndex)
                        } else {
                          append({ value: domainSuggestion })
                        }
                      }}
                    />
                    <Margin x={12} />
                    <Text
                      size="xs"
                      color={
                        userEmailDomainIsAllowed ? 'neutral.0' : 'neutral.3'
                      }
                    >
                      {userEmailDomainIsAllowed
                        ? `Anyone with @${domainSuggestion} email can join`
                        : 'Only people you invite can join'}
                    </Text>
                  </Flex>
                  <Margin y={16} />
                </>
              )}
              {allowedEmailDomains.map((allowedDomain, index) => {
                const error = errors.allowedEmailDomains?.[index]
                return (
                  <Fragment key={allowedDomain.id}>
                    <Input
                      name={`allowedEmailDomains.[${index}].value`}
                      defaultValue={allowedDomain.value}
                      placeholder="Enter email domain..."
                      {...register(`allowedEmailDomains.[${index}].value`, {
                        validate: validateAllowedDomain,
                      })}
                      style={{ marginBottom: 8, fontSize: '16px' }}
                      hidden={allowedDomain.value === domainSuggestion}
                    />
                    {error && (
                      <InputError style={{ textAlign: 'left' }}>
                        {error.value.message}
                      </InputError>
                    )}
                  </Fragment>
                )
              })}
              <DashedButton
                style={{ marginLeft: 0 }}
                isMobile={isMobile}
                onClick={() => append({ value: '' })}
              >
                Add another domain
              </DashedButton>
            </Row>
          )}
        </form>
      </Content>

      <Footer>
        <Center>
          {!isMobile && (
            <Button
              form="new-workspace"
              type="submit"
              disabled={isSubmitting || !!Object.keys(errors).length}
              icon={isSubmitting ? 'loading' : 'next'}
            >
              Create and continue
            </Button>
          )}
        </Center>
      </Footer>
      {isMobile && (
        <>
          <Margin y={60} />
          <MobileButton
            form="new-workspace"
            type="submit"
            disabled={isSubmitting || !!Object.keys(errors).length}
            style={{
              fontSize: theme.fontSizes.base,
              position: 'fixed',
              left: 0,
              bottom: 0,
            }}
          >
            {isSubmitting ? (
              <Spinner css="height: 24px" />
            ) : (
              <span>
                Create and continue <ArrowRightShort size={28} />
              </span>
            )}
          </MobileButton>
        </>
      )}
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    email: state.firebase.auth.email,
    userEmailDomain:
      state.firebase.auth.email &&
      state.firebase.auth.email.split('@').length > 0
        ? state.firebase.auth.email.split('@')[1]
        : null,
    uid: state.firebase.auth.uid,
  }
}

export default compose(connect(mapStateToProps))(SetupWorkspaceCreateNew)
