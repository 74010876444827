import BoardView from 'components/board/BoardView'
import Downloads from 'components/downloads/Downloads'

export const DiscoverBoardView = () => {
  return (
    <>
      <Downloads />
      <BoardView public isDiscover />
    </>
  )
}
