import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled, { css } from 'styled-components'

import systemImage from 'assets/svg/kive_logo_white_on_black_small.svg'
import { Margin } from 'components/common/Margin'
import { Text } from 'components/common/Text'
import UserAvatar from 'components/common/UserAvatar'
import {
  WorkspaceNotificationsSubscription,
  useMarkNotificationAsReadMutation,
} from 'generated/graphql'
import { timeAgo } from 'helpers/utilityFunctions'

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

interface WorkspaceNotificationProps {
  notification: WorkspaceNotificationsSubscription['workspaceNotifications'][0]
  onNotificationClick: () => void
}

const WorkspaceNotification: React.FC<WorkspaceNotificationProps> = ({
  notification,
  onNotificationClick,
}) => {
  const navigate = useNavigate()
  const [markNotificationAsReadMutation] = useMarkNotificationAsReadMutation()

  const handleClick = () => {
    markNotificationAsReadMutation({
      variables: { notificationId: notification.id },
    })
    onNotificationClick()

    if (notification.linkPath) {
      navigate(notification.linkPath)
    }
  }

  return (
    <StyledWrapper onClick={handleClick}>
      <div css="align-self: flex-start;">
        <UserAvatar
          size={35}
          photoUrl={
            notification.fromSystem
              ? systemImage
              : notification.author?.photoURL
          }
          displayName={
            notification.fromSystem ? 'kive' : notification.author?.displayName
          }
        />
      </div>

      <Margin x={12} />
      <div css="display:flex;flex-direction:column;text-align:left;flex:1;">
        <Text size="sm" color="neutral.1" css="line-height: 16px;">
          {notification.title}
        </Text>
        <Margin y={4} />
        <Text size="xs" bold color="accent.2">
          {capitalizeFirstLetter(timeAgo(notification.createdAt))}
        </Text>
      </div>
      <Margin x={12} />
      <div css="width: 10px;">{!notification.isRead && <UnreadDot />}</div>
    </StyledWrapper>
  )
}

export default WorkspaceNotification

const StyledWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: ${theme.borderRadius.default};
    padding: 8px;
    &:hover {
      background: ${theme.colors.background[5]};
    }
  `}
`

const UnreadDot = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.accent[2]};
`
