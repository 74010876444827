import { motion } from 'framer-motion'
import React from 'react'
import { useTheme } from 'styled-components'

import { HoverReplace } from 'components/common/HoverReplace'

import { FadeFromBelow, ItemAction, Loading } from './UploadListingStyles'

interface Props {
  dismiss: () => void
}

const UploadItemMagicTagging: React.FC<Props> = ({ dismiss }) => {
  const theme = useTheme()
  return (
    <>
      <div style={{ flex: 0, position: 'relative' }}>
        <Loading />
      </div>
      <div style={{ flex: 1, textAlign: 'center', position: 'relative' }}>
        <FadeFromBelow key="initial">
          <motion.span
            animate={{
              color: [
                theme.colors.accent[0],
                theme.colors.accent[1],
                theme.colors.accent[2],
                theme.colors.accent[0],
              ],
            }}
            transition={{ repeat: Infinity }}
          >
            Analysing your upload...
          </motion.span>
        </FadeFromBelow>
      </div>
      <HoverReplace
        style={{ flex: 0 }}
        onHover={<ItemAction onClick={dismiss}>Hide</ItemAction>}
      />
    </>
  )
}

export default UploadItemMagicTagging
