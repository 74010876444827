import { reportError } from 'helpers/logging'

type GetNextItemFromItemArgs = {
  fromItemId: string
  direction: 'right' | 'left' | 'up' | 'down'
  gridClassName: string
}

export const getNextItemFromItem = ({
  fromItemId,
  direction,
  gridClassName,
}: GetNextItemFromItemArgs) => {
  const gridContainer = document.querySelector(`.${gridClassName}`)

  if (!gridContainer) return

  if (gridContainer.id !== 'grid') {
    return reportError(
      new Error(
        `Tried to get the grid element, but another element was found. element-id: ${gridContainer.id}`
      )
    )
  }

  const gridItems = gridContainer.getElementsByClassName('gridItem')

  let focusedItemNode = null
  Object.keys(gridItems).forEach((itemKey) => {
    if (gridItems[Number(itemKey)].id === `gridItem-${fromItemId}`) {
      focusedItemNode = gridItems[Number(itemKey)]
    }
  })

  if (!focusedItemNode) return null

  const focusedItemNodeRect = (
    focusedItemNode as Element
  ).getBoundingClientRect()
  const gridContainerRect = gridContainer.getBoundingClientRect()

  // Wrap around edges – when focus reaches end, start again on opposite side
  if (
    direction === 'right' &&
    focusedItemNodeRect.right + focusedItemNodeRect.width >
      gridContainerRect.right
  ) {
    focusedItemNodeRect.x = gridContainerRect.x - focusedItemNodeRect.width
    focusedItemNodeRect.y += focusedItemNodeRect.height
  }
  if (
    direction === 'left' &&
    focusedItemNodeRect.x - focusedItemNodeRect.width < gridContainerRect.x
  ) {
    focusedItemNodeRect.x = gridContainerRect.right + focusedItemNodeRect.width
    focusedItemNodeRect.y -= focusedItemNodeRect.height
  }

  const filtered = Object.values(gridItems || {})
    // .filter((item, index) => {
    //   if(index < pos + 20 && index > pos - 20) return true
    //   return false
    // })
    .filter((item) => {
      const rect = item.getBoundingClientRect()
      if (direction === 'up') {
        if (rect.x !== focusedItemNodeRect.x) return false
        if (rect.y >= focusedItemNodeRect.y) return false
      }
      if (direction === 'down') {
        if (rect.x !== focusedItemNodeRect.x) return false
        if (rect.y <= focusedItemNodeRect.y) return false
      }
      if (direction === 'right') {
        if (rect.x <= focusedItemNodeRect.x) return false
      }
      if (direction === 'left') {
        if (rect.x >= focusedItemNodeRect.x) return false
      }
      return true
    })
    .sort((itemA, itemB) => {
      if (direction === 'right' || direction === 'left') {
        const rectA = itemA.getBoundingClientRect()
        const rectB = itemB.getBoundingClientRect()
        const xDelta =
          Math.abs(rectA.x - focusedItemNodeRect.x) -
          Math.abs(rectB.x - focusedItemNodeRect.x)
        if (xDelta !== 0) return xDelta
        // If the elements differ on x value, sort on x

        const yDelta =
          Math.abs(rectA.y - focusedItemNodeRect.y) -
          Math.abs(rectB.y - focusedItemNodeRect.y)
        return yDelta // otherwise, use y value to sort
      }
      if (direction === 'up' || direction === 'down') {
        return (
          Math.abs(itemA.getBoundingClientRect().y - focusedItemNodeRect.y) -
          Math.abs(itemB.getBoundingClientRect().y - focusedItemNodeRect.y)
        )
      }
      return 0
    })

  if (filtered.length === 0) return
  return filtered[0]
}
