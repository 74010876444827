import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const Heading = styled.div(({ theme }) => ({
  textAlign: 'center',
  paddingTop: 0,
  paddingBottom: 20,
  fontFamily: theme.fontFamily.primary,
  fontSize: theme.fontSizes['2xl'],
  color: theme.colors.text.neutral[0],
  fontWeight: 'bold',
  cursor: 'pointer',
  '@media (max-width: 768px)': {
    lineHeight: '36px',
    fontSize: theme.fontSizes.xl,
  },
}))

export const Page = styled.div(({ theme }) => ({
  display: 'flex',
  color: theme.colors.text.neutral[0],
  backgroundColor: theme.colors.gray.dark['-1'],
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  fontFamily: theme.fontFamily.primary,
  fontWeight: theme.fontWeight.normal,
  fontSize: theme.fontSizes.base,
  width: '100vw',
  minHeight: '100vh',
}))

export const InfoSection = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
}))

export const InfoHeader = styled.p(({ theme }) => ({
  fontSize: theme.fontSizes.xl,
  color: theme.colors.accent[2],
  fontWeight: 'bold',
}))

export const InfoLink = styled(Link)`
  ${({ theme }) => css`
    color: ${theme.colors.text.neutral[0]};
    text-decoration: underline;
    font-size: ${theme.fontSizes.sm};
  `}
`

export const InfoSubtext = styled.p(({ theme }) => ({
  color: theme.colors.gray.light[0],
  fontSize: theme.fontSizes.sm,
}))

export const LineBreak = styled.div`
  width: 100%;
  text-align: center;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 10px;
    background: red;
    background: ${({ theme }) => theme.colors.gray.dark[6]};
    height: 1px;
  }
`

export const LineBreakText = styled.span`
  position: relative;
  background: ${({ theme }) => theme.colors.background[0]};
  color: ${({ theme }) => theme.colors.text.neutral[2]};
  padding: 0 15px;
`

export const InfoParagraph = styled.p({ fontSize: 14, margin: '16px 0' })
