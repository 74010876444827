import React from 'react'
import styled, { useTheme } from 'styled-components'

export const Input = styled.input(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.colors.background[3],
  color: theme.colors.text.neutral[0],
  padding: 12,
  height: 40,
  borderRadius: theme.borderRadius.default,
  border: `${theme.border.thin} ${theme.colors.gray.dark[5]}`,
  outline: 'none',
  '::placeholder': {
    color: theme.colors.text.neutral[3],
  },
  ':hover': {
    border: `1px solid ${theme.colors.accent[2]}`,
  },
  ':focus': {
    color: theme.colors.text.neutral[0],
    border: `1px solid ${theme.colors.accent[2]}`,
    backgroundColor: theme.colors.background[4],
  },
  '@media (max-width: 768px)': {
    fontSize: '16px',
  },
}))

export const InputError = styled.span`
  color: red;
  display: block;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  margin-left: 12px;
`

/** @type {AppStyledComponent<"fieldset", {hasError?: boolean}>} */
export const Fieldset = styled.fieldset`
  border: none;
  margin: 16px 0 ${({ hasError }) => (hasError ? 0 : 16)}px 0;
`

export const Label = styled.label`
  margin-top: 8px;
  color: ${({ theme }) => theme.colors.text.neutral[2]};
`

export const FieldWithPrefix = ({
  prepended,
  name,
  register,
  errors,
  prefix,
  formValidationOptions = {},
  ...props
}) => {
  const theme = useTheme()

  const error = errors[name]?.message
  return (
    <Fieldset
      style={{
        width: props.isMobile ? 'calc(100vw - 34px)' : 400,
        alignItems: 'flex-start',
        margin: 0,
      }}
    >
      <span
        style={{
          fontSize: theme.fontSizes.sm,
          color: theme.colors.gray.medium[1],
          marginRight: 4,
          marginTop: 10,
          position: 'absolute',
          left: 10,
        }}
      >
        {prefix}
      </span>
      <div style={{ flex: 1, textAlign: 'left' }}>
        <Input
          style={{ paddingLeft: 56 }}
          name={name}
          {...register(name, formValidationOptions)}
          centered={false}
          hasError={error != null}
          {...props}
        />
        {error && <InputError>{error}</InputError>}
      </div>
    </Fieldset>
  )
}

export const Field = ({
  name,
  register,
  errors,
  fieldSetProps = null,
  formValidationOptions = {},
  ...props
}) => {
  const error = errors[name]?.message
  return (
    <Fieldset hasError={error != null} {...fieldSetProps}>
      <Input
        name={name}
        {...register(name, formValidationOptions)}
        centered={false}
        hasError={error != null}
        {...props}
      />
      {error && <InputError>{error}</InputError>}
    </Fieldset>
  )
}
