const ARROW_LEFT = 'ArrowLeft'
const ARROW_RIGHT = 'ArrowRight'
const ARROW_UP = 'ArrowUp'
const ARROW_DOWN = 'ArrowDown'

export function isArrowKey(key) {
  return [ARROW_LEFT, ARROW_RIGHT, ARROW_UP, ARROW_DOWN].includes(key)
}

export function isArrowHorizontal(key) {
  return [ARROW_LEFT, ARROW_RIGHT].includes(key)
}

export function isArrowVertical(key) {
  return [ARROW_UP, ARROW_DOWN].includes(key)
}

export function isArrowLeft(key) {
  return key === ARROW_LEFT
}
export function isArrowRight(key) {
  return key === ARROW_RIGHT
}
export function isArrowUp(key) {
  return key === ARROW_UP
}
export function isArrowDown(key) {
  return key === ARROW_DOWN
}
