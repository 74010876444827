import { QuestionCircle } from '@styled-icons/bootstrap/QuestionCircle'
import { useState } from 'react'
import styled from 'styled-components'

import { GuideModal } from 'components/guide-modal/GuideModal'

const StyledTooltip = styled.div`
  height: 32px;
  width: 32px;
  cursor: pointer;
  align-items: center;
  display: flex !important;
  justify-content: center;
  border-radius: ${({ theme }) => theme.borderRadius.default};
  background: ${({ theme }) => theme.colors.background[4]};
  &:hover {
    background: ${({ theme }) => theme.colors.accent[2]};
  }
`

const StyledIcon = styled(QuestionCircle)`
  height: 16px;
  color: white;
`

export const LearnMoreButton = () => {
  const [showModal, setShowModal] = useState(false)
  return (
    <StyledTooltip onClick={() => setShowModal(true)}>
      <StyledIcon />
      {showModal && (
        <GuideModal
          id="projectsPage"
          onDone={() => setShowModal(false)}
          onClose={() => setShowModal(false)}
        />
      )}
    </StyledTooltip>
  )
}
