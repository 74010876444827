import { MutableRefObject, useState } from 'react'

const ZOOM_SCALE = 1.03
const ZOOM_SCALE_MAX = 8
const ZOOM_SCALE_MIN = 0.15

type UseZoomModuleProps = {
  stageRef: MutableRefObject<any>
  setCanvasPosition: (args: { x: number; y: number }) => void
}

export const useZoomModule = (props: UseZoomModuleProps) => {
  const [currentScale, setCurrentScale] = useState(1)

  const zoom = (
    point: { x: number; y: number },
    increaseScale: boolean,
    step = 1
  ) => {
    if (props.stageRef.current !== null) {
      const stage = props.stageRef.current
      const oldScale = stage.scaleX()
      const mousePointTo = {
        x: (point.x - stage.x()) / oldScale,
        y: (point.y - stage.y()) / oldScale,
      }
      const newScale = increaseScale
        ? oldScale * ZOOM_SCALE * step
        : oldScale / (ZOOM_SCALE * step)

      if (newScale > ZOOM_SCALE_MAX) return currentScale
      if (newScale < ZOOM_SCALE_MIN) return currentScale

      stage.scale({ x: newScale, y: newScale })
      const newPos = {
        x: point.x - mousePointTo.x * newScale,
        y: point.y - mousePointTo.y * newScale,
      }
      stage.position(newPos)
      stage.batchDraw()
      setCurrentScale(newScale)
      props.setCanvasPosition(newPos)

      return newScale
    }

    return currentScale
  }

  return { zoom, currentScale }
}
