import { motion } from 'framer-motion'
import React from 'react'
import styled, { useTheme } from 'styled-components'

const Container = styled.div(({ theme }) => ({
  width: '100%',
  height: '100vh',
  background: theme.colors.background.landing,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: theme.fontSizes['3xl'],
  fontWeight: theme.fontWeight.bold,
  textAlign: 'center',
}))

const Image = styled.div(() => ({
  width: '50%',
  height: '50%',
  position: 'absolute',
  zIndex: 0,
  backgroundImage:
    'url(https://media3.giphy.com/media/aRK67eTsU5UqY/giphy.gif)',
  backgroundSize: 'contain',
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
}))

function Maintenance() {
  const theme = useTheme()

  return (
    <Container>
      <Image />
      <motion.div
        style={{ zIndex: 1 }}
        animate={{
          color: [
            theme.colors.accent[0],
            theme.colors.accent[1],
            theme.colors.accent[2],
            theme.colors.accent[0],
          ],
        }}
        transition={{ repeat: Infinity }}
      >
        Out to get an upgrade ... will be back in a minute
      </motion.div>
    </Container>
  )
}

export default Maintenance
