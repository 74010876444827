import React from 'react'
import styled, { css } from 'styled-components'

const StyledAvatar = styled.img<{ isSquared?: boolean }>`
  ${({ theme, isSquared }) => css`
    border-radius: ${isSquared
      ? theme.borderRadius.sm
      : theme.borderRadius.full};
    /* background: transparent; */
    height: 25px;
    width: 25px;
    overflow: hidden;
    background: red;
  `}
`

const StyledAvatarPlaceholder = styled.div<{ isSquared?: boolean }>`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${theme.fontSizes.sm};
    font-weight: ${theme.fontWeight.bold};
    border-radius: 999px;
    background: #d8d8d8;
    color: ${theme.colors.gray.dark[4]};
    flex-shrink: 0;
  `}
`

interface UserAvatarProps {
  photoUrl?: string | null
  displayName?: string
  size?: number
  isSquared?: boolean
  className?: string
  color?: string
  withBorder?: boolean
}

const UserAvatar: React.FC<UserAvatarProps> = ({
  photoUrl,
  displayName,
  size,
  isSquared,
  className,
  color,
  withBorder,
}) => {
  const height = size || 25
  const width = size || 25

  if (photoUrl) {
    return (
      <StyledAvatar
        className={className}
        isSquared={isSquared}
        style={{
          height,
          width,
          border: withBorder ? `2px solid ${color}` : 'none',
        }}
        src={photoUrl}
      />
    )
  }
  if (displayName) {
    return (
      <StyledAvatarPlaceholder
        className={className}
        isSquared={isSquared}
        style={{ height, width, background: color }}
      >
        {displayName.split(' ')[0][0]}
        {displayName.split(' ')[1]?.[0]}
      </StyledAvatarPlaceholder>
    )
  }
  return (
    <StyledAvatarPlaceholder
      className={className}
      isSquared={isSquared}
      style={{ height, width, background: color }}
    />
  )
}

export default UserAvatar
