import { QuestionCircle } from '@styled-icons/bootstrap/QuestionCircle'
import { useState } from 'react'
import styled from 'styled-components'

import {
  GuideModal,
  guideIdToContentMapper,
} from 'components/guide-modal/GuideModal'
import {
  StyledButtonMedium,
  StyledButtonSmall,
} from 'features/editor/ButtonMedium'

type Size = 'small' | 'large'

const StyledIcon = styled(QuestionCircle)<{ size: Size }>`
  height: ${({ size }) => (size === 'large' ? '16px' : '12px')};
  color: white;
`

const Button = ({
  size,
  onClick,
  children,
  buttonText,
}: {
  size: string
  onClick: (e: any) => void
  children: any
  buttonText?: string
}) =>
  size === 'large' ? (
    <StyledButtonMedium hasText={!!buttonText} onClick={onClick}>
      {children}
    </StyledButtonMedium>
  ) : (
    <StyledButtonSmall hasText={!!buttonText} onClick={onClick}>
      {children}
    </StyledButtonSmall>
  )

export const LearnMoreButton = ({
  guideId,
  size,
  onClick,
  buttonText,
}: {
  guideId: keyof typeof guideIdToContentMapper
  size: Size
  onClick?: () => void
  buttonText?: string
}) => {
  const [showModal, setShowModal] = useState(false)
  return (
    <Button
      size={size}
      onClick={() => {
        if (onClick) onClick()
        setShowModal(true)
      }}
      buttonText={buttonText}
    >
      {buttonText}
      <StyledIcon size={size} />
      {showModal && (
        <GuideModal
          id={guideId}
          onDone={() => setShowModal(false)}
          onClose={() => setShowModal(false)}
        />
      )}
    </Button>
  )
}
