import { ThreeDotsVertical as Dots } from '@styled-icons/bootstrap/ThreeDotsVertical'
import { Download } from '@styled-icons/boxicons-regular/Download'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFirestore } from 'react-redux-firebase'
import { Link, useNavigate } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { BoxArrowInRight, Instagram, Trash } from 'styled-icons/bootstrap'

import Button from 'components/common/Button'
import Dropdown from 'components/common/Dropdown'
import IconButton from 'components/common/IconButton'
import { Margin } from 'components/common/Margin'
import Modal from 'components/common/Modal'
import PermissionWrapper from 'components/common/PermissionWrapper'
import Spinner from 'components/common/Spinner'
import { Text } from 'components/common/Text'
import DownloadButton from 'components/downloads/DownloadButton'
import useToastMessages from 'components/toast/useToastMessages'
import {
  trackBoardDeleted,
  trackCreatePDFDownloaded,
  trackCreatePDFFailed,
  trackCreatePDFStarted,
} from 'helpers/tracking/tracking'
import useBoardPermissions from 'hooks/useBoardPermissions'
import useIsMobile from 'hooks/useIsMobile'
import { RootState } from 'store'
import { setActiveExport, setSocialAssetModalOpen } from 'store/content/actions'
import { DBBoard } from 'types/db'

import { trackSocialAssetCreationOpened } from '../../helpers/tracking/tracking'
import { downloadSocialAssets } from '../createAssets/generateAssets'
import FavoriteBoardButton from './FavoriteBoardButton'

interface BoardMenuProps {
  board: DBBoard
  boardId: string
  withDownloadButton?: boolean
  isPublic?: boolean
}

const BoardMenu: React.FC<BoardMenuProps> = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const firestore = useFirestore()
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const [isCreatingPDF, setIsCreatingPDF] = useState(false)
  const { reportSuccess, reportError } = useToastMessages()

  const auth = useSelector((state: RootState) => state.firebase.auth)
  const isMobile = useIsMobile()

  const userAllowedTo = useBoardPermissions(props.board, [
    'EDIT_BOARD',
    'DELETE_BOARD',
    'CREATE_SOCIAL_ASSETS',
  ])

  const handleDeleteBoard = async () => {
    const newLocation = props.isPublic
      ? `/p/${props.board.projectId}`
      : `/${props.board.workspaceId}/p/${props.board.projectId}`
    await firestore.delete({ collection: 'boards', doc: props.boardId })
    trackBoardDeleted({
      boardId: props.boardId,
      projectId: props.board.projectId,
      workspaceId: props.board.workspaceId,
    })
    navigate(newLocation, { replace: true })
  }

  return (
    <>
      <IconButton
        Icon={Dots}
        menuNoSpace
        menuWidth={200}
        menuFixedBottom={isMobile}
        fullWidth={isMobile}
        noBackground
        css={css`
          color: ${({ theme }) => css`
            ${theme.colors.text.neutral[0]}
          `};
          &:hover {
            color: ${({ theme }) => css`
              ${theme.colors.text.neutral[0]}
            `};
          }
        `}
      >
        {!auth.isEmpty && (
          <PermissionWrapper
            css="display:block!important"
            feature="create social assets"
            isAllowed={!!userAllowedTo.CREATE_SOCIAL_ASSETS}
          >
            <StyledDropdownItem
              Icon={Instagram}
              title="Create social assets"
              onClick={() => {
                trackSocialAssetCreationOpened({ source: 'BOARD' })
                dispatch(
                  setActiveExport({
                    boardId: props.boardId,
                    boardTitle: props.board.title,
                  })
                )
                dispatch(setSocialAssetModalOpen(true))
              }}
            />
          </PermissionWrapper>
        )}
        {isMobile && (
          <FavoriteBoardButton boardId={props.boardId}>
            {({ handleFavoriteClick, Icon, isFavoured }) => {
              return (
                <StyledDropdownItem
                  Icon={Icon}
                  title={
                    // eslint-disable-next-line no-nested-ternary
                    auth.isEmpty
                      ? 'Save this board'
                      : isFavoured
                      ? 'Remove board from your favorites'
                      : 'Add board to your favorites'
                  }
                  onClick={(e) => {
                    e.stopPropagation()
                    handleFavoriteClick()
                  }}
                />
              )
            }}
          </FavoriteBoardButton>
        )}
        {props.withDownloadButton && (
          <>
            <StyledDropdownItem
              Icon={isCreatingPDF ? Spinner : Download}
              title="Download PDF"
              onClick={async () => {
                reportSuccess('Creating PDF ...')
                setIsCreatingPDF(true)
                trackCreatePDFStarted({
                  boardId: props.boardId,
                  projectId: props.board.projectId,
                  workspaceId: props.board.workspaceId,
                })
                downloadSocialAssets({
                  boardId: props.boardId,
                  useTemplates: ['PDF'],
                  settings: {
                    width: 1920,
                    height: 1080,
                    limit: props.board.savedItemCount,
                  },
                })
                  .then(() => {
                    setIsCreatingPDF(false)
                    trackCreatePDFDownloaded({
                      boardId: props.boardId,
                      projectId: props.board.projectId,
                      workspaceId: props.board.workspaceId,
                    })
                  })
                  .catch((e) => {
                    reportError('Something went wrong :(')
                    setIsCreatingPDF(false)
                    trackCreatePDFFailed({
                      boardId: props.boardId,
                      projectId: props.board.projectId,
                      workspaceId: props.board.workspaceId,
                    })
                  })
              }}
            />

            <DownloadButton>
              {({ isCreating, isFresh, url, onCreateDownloadRequest }: any) => {
                return (
                  <StyledDropdownItem
                    Icon={isCreating ? Spinner : Download}
                    title="Download board"
                    onClick={() => {
                      if (isCreating) return
                      if (isFresh) {
                        return window.open(url, '_blank')
                      }
                      onCreateDownloadRequest()
                    }}
                  />
                )
              }}
            </DownloadButton>
          </>
        )}
        {userAllowedTo.DELETE_BOARD && (
          <StyledDropdownItem
            Icon={Trash}
            title="Delete board"
            onClick={() => setIsDeleteModalVisible(true)}
          />
        )}
        {auth.isEmpty && props.isPublic && (
          <>
            <Link to="/product">
              <StyledDropdownItem>
                🧠 <Margin x={12} />
                What is Kive?
              </StyledDropdownItem>
            </Link>
            <Link
              to={`/signup?fromPublicFeature=true&boardId=${props.boardId}`}
            >
              <StyledDropdownItem Icon={BoxArrowInRight} title="Sign up" />
            </Link>
          </>
        )}
      </IconButton>
      <Modal
        isOpen={isDeleteModalVisible}
        close={() => setIsDeleteModalVisible(false)}
      >
        <Modal.ContentWrapper>
          <Text size="lg" as="h2" color="neutral.0">
            Are you sure you want to delete this board?
          </Text>
          <Margin y={16} />
          <div css="display: flex">
            <Button variant="primary" onClick={handleDeleteBoard}>
              Delete
            </Button>
            <Margin x={16} />
            <Button onClick={() => setIsDeleteModalVisible(false)}>
              Cancel
            </Button>
          </div>
        </Modal.ContentWrapper>
      </Modal>
    </>
  )
}

export default BoardMenu

const StyledDropdownItem = styled(Dropdown.Item)`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.sm};
    color: ${theme.colors.text.neutral[0]} !important;
    &:hover {
      color: ${theme.colors.accent[2]} !important;
    }
  `}
`
