import { get } from 'lodash'
import { Fragment, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from 'styled-components'

import {
  CheckoutInput,
  CreateCheckoutSessionMutationVariables,
  useActivePlansQuery,
  useCreateCheckoutSessionMutation,
} from '../../../../generated/graphql'
import { reportError as reportSentryError } from '../../../../helpers/logging'
import { RootState } from '../../../../store'
import Loading from '../../../Loading'
import Button from '../../../common/Button'
import { SubscriptionInterval } from '../../types'
import useSettingNavigation from '../../useSettingsNavigation'
import { AnnualUpsell } from '../AnnualUpsell'
import { AnnualUpsellSmallScreens } from '../AnnualUpsellSmallScreens'
import { StyledFeatureRow } from '../ComparePlansStyles'
import { FeatureLine } from '../FeatureLine'
import { FeatureSubline } from '../FeatureSubline'
import {
  PlanHeader,
  StyledContainer,
  StyledFeaturesHeading,
  StyledRow,
} from './PlansGridNoSubscriptionStyles'
import { getButtonText } from './get-button-text'

type PlansGridProps = {
  workspaceId: string
  subscriptionInterval: SubscriptionInterval
  setSubscriptionInterval: (interval: SubscriptionInterval) => void
}

export function PlansGridNoSubscription({
  workspaceId,
  subscriptionInterval,
  setSubscriptionInterval,
}: PlansGridProps) {
  const [planActionIsLoading, setPlanActionIsLoading] = useState<string>()
  const { data: activePlans, loading: loadingActivePlans } =
    useActivePlansQuery()
  const auth = useSelector((state: RootState) => state.firebase.auth)
  const [
    createCheckoutSessionMutation,
    { loading: isCreatingCheckoutSession, error: checkoutError },
  ] = useCreateCheckoutSessionMutation()
  const theme = useTheme()
  const productColors = [
    undefined,
    theme.colors.accent[3],
    theme.colors.accent[2],
    theme.colors.accent[1],
  ]
  const { sectionUrl } = useSettingNavigation()

  console.log('workspaceId', workspaceId)

  const createCheckoutSessionAndRedirect = async (args: {
    lineItems: CreateCheckoutSessionMutationVariables['input']['lineItems']
    internalPlanId: CreateCheckoutSessionMutationVariables['input']['internalPlanId']
    interval: CreateCheckoutSessionMutationVariables['input']['interval']
  }) => {
    if (isCreatingCheckoutSession || checkoutError) return
    if (!auth.email) return
    try {
      const searchParams = new URLSearchParams()
      searchParams.append('toPlan', `${args.internalPlanId}`)
      const returnUrl = new URL(
        `${window.location.origin}${window.location.pathname}`
      )
      const successUrl = new URL(
        `${returnUrl.href}?${new URLSearchParams([
          ...Array.from(searchParams.entries()),
          ...Array.from(new URLSearchParams(sectionUrl('checkout_success'))),
        ])}`
      )
      const cancelUrl = new URL(
        `${returnUrl.href}?${new URLSearchParams([
          ...Array.from(searchParams.entries()),
          ...Array.from(new URLSearchParams(sectionUrl('checkout_cancel'))),
        ])}`
      )
      const input: CheckoutInput = {
        workspaceId,
        customerEmail: auth.email,
        lineItems: args.lineItems,
        redirects: {
          successUrl: `${successUrl}&session_id={CHECKOUT_SESSION_ID}`,
          cancelUrl: `${cancelUrl}`,
        },
        internalPlanId: args.internalPlanId,
        interval: args.interval,
      }
      console.log(`Creating Checkout session with the following payload`, {
        variables: {
          input,
        },
      })
      const { data } = await createCheckoutSessionMutation({
        variables: {
          input,
        },
      })
      const url = data?.createCheckoutSession?.url
      if (!url) {
        return
      }
      window.location.assign(url)
    } catch (error) {
      reportSentryError(error)
    }
  }

  const sortedPlans = [...(activePlans?.plans ?? [])].sort(
    (a, b) => Number(a.order) - Number(b.order)
  )

  return (
    <>
      {loadingActivePlans ? <Loading /> : null}
      {!loadingActivePlans ? (
        <StyledContainer>
          {sortedPlans.map((plan, index) => {
            const previousPlan = index === 0 ? null : sortedPlans[index - 1]
            const FEATURE_HEADING_TEMPLATE = `Everything in {{planName}} plus`
            const FEATURE_HEADING_DEFAULT = `Key features`
            const featureHeading = previousPlan
              ? FEATURE_HEADING_TEMPLATE.replace(
                  '{{planName}}',
                  previousPlan.presentation.name
                )
              : FEATURE_HEADING_DEFAULT
            const interval = get(
              plan.presentation.intervals,
              subscriptionInterval
            )

            if (!interval) return null

            let rowNumber = 0
            const getNextRowNumber = () => {
              rowNumber += 1
              return rowNumber
            }

            let discount = 0
            let price = 0
            if (subscriptionInterval === 'yearly') {
              const monthly = get(plan.presentation.intervals, 'monthly')
              const monthlyPrice =
                Number(monthly?.priceActual?.unitAmount) / 100
              price = monthlyPrice
            }
            if (subscriptionInterval === 'monthly') {
              const monthly = get(plan.presentation.intervals, 'monthly')
              const monthlyPrice =
                (Number(monthly?.priceActual?.unitAmount) / 100) * 12

              const yearly = get(plan.presentation.intervals, 'yearly')
              const yearlyPrice = Number(yearly?.priceActual?.unitAmount) / 100

              discount = monthlyPrice - yearlyPrice
              price = yearlyPrice
            }

            const actionButton = getButtonText({
              price,
            })

            return (
              <Fragment key={`${plan.id}-${subscriptionInterval}`}>
                <StyledRow row={() => getNextRowNumber()} columnIndex={index}>
                  <PlanHeader
                    color={productColors[index]}
                    name={`${interval.priceActual?.product.name}`}
                    price={`${interval.priceActual?.priceString}`}
                    interval={subscriptionInterval}
                    priceUnit={`${interval.priceUnit}`}
                    text={`${interval.priceActual?.product.description}`}
                  />
                </StyledRow>
                {subscriptionInterval === 'monthly' ? (
                  <StyledRow row={() => getNextRowNumber()} columnIndex={index}>
                    <AnnualUpsell
                      setSubscriptionInterval={setSubscriptionInterval}
                      subscriptionInterval={subscriptionInterval}
                      discount={discount}
                      price={price}
                    />
                    <AnnualUpsellSmallScreens
                      setSubscriptionInterval={setSubscriptionInterval}
                      subscriptionInterval={subscriptionInterval}
                      discount={discount}
                      price={price}
                    />
                  </StyledRow>
                ) : null}
                <StyledRow row={() => getNextRowNumber()} columnIndex={index}>
                  <Button
                    variant={actionButton.variant}
                    disabled={actionButton.disabled}
                    isCompact
                    isLoading={planActionIsLoading === plan.id}
                    onClick={async () => {
                      setPlanActionIsLoading(plan.id)
                      await createCheckoutSessionAndRedirect({
                        lineItems: [
                          {
                            priceId: `${interval.priceActual?.id}`,
                            quantity: 1,
                          },
                        ],
                        internalPlanId: plan.id,
                        interval: subscriptionInterval,
                      })
                      setPlanActionIsLoading(undefined)
                    }}
                    style={{
                      width: '100%',
                    }}
                  >
                    {actionButton.text}
                  </Button>
                </StyledRow>
                <StyledRow row={() => getNextRowNumber()} columnIndex={index}>
                  <StyledFeaturesHeading>
                    {featureHeading}
                  </StyledFeaturesHeading>
                </StyledRow>
                <StyledRow row={() => getNextRowNumber()} columnIndex={index}>
                  {plan.features.map((feature, idx) => {
                    if (!feature) return null
                    let lineContent = feature.template
                    if (feature.replace_key && plan.limits) {
                      lineContent = lineContent.replace(
                        '{{limit}}',
                        new Intl.NumberFormat('en-US').format(
                          Number(get(plan.limits, feature.replace_key))
                        )
                      )
                    }
                    return (
                      <StyledFeatureRow
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${feature.__typename}-${plan.id}-${idx}`}
                      >
                        <FeatureLine>{lineContent}</FeatureLine>
                        {feature.subline ? (
                          <FeatureSubline>{feature.subline}</FeatureSubline>
                        ) : null}
                      </StyledFeatureRow>
                    )
                  })}
                </StyledRow>
              </Fragment>
            )
          })}
        </StyledContainer>
      ) : null}
    </>
  )
}
