import { ArrowBack } from '@styled-icons/material/ArrowBack'
import React from 'react'
import styled from 'styled-components'

import IconButton from 'components/common/IconButton'
import StopPropagation from 'helpers/StopPropagation'

const Margin = styled.div`
  width: 64px;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

interface ItemActionBarLeftProps {
  handleMoveItemFocus: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    direction: 'right' | 'left'
  ) => void
}

const ItemActionBarLeft: React.FC<ItemActionBarLeftProps> = ({
  handleMoveItemFocus,
}) => (
  <Margin>
    <StopPropagation>
      <IconButton
        Icon={ArrowBack}
        onClick={(e) => handleMoveItemFocus(e, 'left')}
      />
    </StopPropagation>
  </Margin>
)

export default ItemActionBarLeft
