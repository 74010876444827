import { useGesture } from '@use-gesture/react'
import { MutableRefObject, useEffect } from 'react'

import { detectTrackPad } from '../helpers/detectTrackPad'
import { Tool } from '../types'

type UseGesturesModuleProps = {
  targetRef: MutableRefObject<any>
  stageRef: MutableRefObject<any>
  zoom: (
    point: { x: number; y: number },
    increaseScale: boolean,
    steps: number
  ) => number
  updateEraserCursorPosition: (args: {
    mouseX: number
    mouseY: number
    scale: number
  }) => void
  setCanvasPosition: (args: { x: number; y: number }) => void
  currentTool: Tool
  inputIsFocused: boolean
}

export const useGesturesModule = (props: UseGesturesModuleProps) => {
  const preventDefaultGestures = (event: Event) => {
    if ((event.target as any).classList?.contains('__ignore-gestures')) {
      return null
    }
    event.preventDefault()
  }

  // we prevent native browser behaviour
  useEffect(() => {
    document.addEventListener('gesturestart', preventDefaultGestures)
    document.addEventListener('gesturechange', preventDefaultGestures)
    document.addEventListener('wheel', preventDefaultGestures, {
      passive: false,
    })
    return () => {
      document.removeEventListener('gesturestart', preventDefaultGestures)
      document.removeEventListener('gesturechange', preventDefaultGestures)
      document.removeEventListener('wheel', preventDefaultGestures)
    }
  }, [])

  useGesture(
    {
      onPinch: (state) => {
        // zoom by pinching
        if (!state.active) return

        const [x, y] = state.origin
        const point = { x, y }
        const increaseScale = state.delta[0] > 0
        const speed = Math.abs(state.velocity[0] * 10)
        const newScale = props.zoom(point, increaseScale, 1 + speed)
        props.updateEraserCursorPosition({
          mouseX: point.x,
          mouseY: point.y,
          scale: newScale,
        })
      },
      onWheel: (state) => {
        if ((state.target as any).classList?.contains('__ignore-gestures')) {
          return null
        }
        if (state.pinching) return null

        // Trackpad wheel panning
        if (detectTrackPad(state.event) && !state.metaKey) {
          const deltaY = state.delta[1] * -1
          const deltaX = state.delta[0] * -1
          const stage = props.stageRef.current
          const mousePointTo = {
            x: deltaX + stage.x(),
            y: deltaY + stage.y(),
          }
          stage.position(mousePointTo)
          props.setCanvasPosition(mousePointTo)
          return null
        }
        // Mouse wheel scrool zoom
        const point = { x: state.event.pageX, y: state.event.pageY }
        const speed = state.velocity[1] / 100 // we lower the speed a bit
        const newScale = props.zoom(point, state.event.deltaY < 0, 1 + speed)
        props.updateEraserCursorPosition({
          mouseX: point.x,
          mouseY: point.y,
          scale: newScale,
        })
      },
      onDrag: (state) => {
        const typesToIgnore = ['keyup', 'keydown'] // to prevent panning while moving cursor with keys in prompt input
        if (
          props.currentTool === 'pan' &&
          !typesToIgnore.includes(state.type) &&
          !props.inputIsFocused // prevent panning while selecting text in input while drag selcting the text
        ) {
          const deltaY = state.delta[1]
          const deltaX = state.delta[0]
          const stage = props.stageRef.current
          const mousePointTo = {
            x: deltaX + stage.x(),
            y: deltaY + stage.y(),
          }
          stage.position(mousePointTo)
          props.setCanvasPosition(mousePointTo)
        }
      },
    },
    {
      target: props.targetRef,
    }
  )
}
