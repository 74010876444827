import { client } from 'apollo'
import React, { useCallback, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'

import Loading from 'components/Loading'
import { Text } from 'components/common/Text'
import DiscoverGridItem from 'components/discover/DiscoverGridItem'
import Grid from 'components/grid/Grid'
import { GridColumnAdditioner } from 'components/grid/GridColumnAdditioner'
import { GridItemProps } from 'components/grid/griditem/GridItem'
import { usePublicWorkspaceItemsByUserQuery } from 'generated/graphql'

interface ProfileItemsProps {
  scrollNode: HTMLDivElement
  userId: string
  isExternalUser: boolean
  currentUserId: string
  userDisplayName: string
}

export const ProfileItems: React.FC<ProfileItemsProps> = ({
  scrollNode,
  userId,
  isExternalUser,
  currentUserId,
  userDisplayName,
}) => {
  const [, setRerender] = useState(false)

  const { data, loading, fetchMore } = usePublicWorkspaceItemsByUserQuery({
    variables: {
      userId,
      first: 30,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  })

  const pageInfo = data?.publicWorkspaceItemsByUser.pageInfo
  const nextCursor = pageInfo?.nextCursor
  const workspaceItems = data?.publicWorkspaceItemsByUser.workspaceItems ?? []
  const hasMore = Boolean(nextCursor) && workspaceItems.length > 0 && !loading

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        cursor: nextCursor,
      },
    })
  }

  useEffect(() => {
    // There is a conclict with our Masonory grid and Inifnite scroll
    // which makes the inifinite scroll to NOT fetch more if the grid content is small enough to not trigger the "fetch more threshold"
    // forcing a rerender solves it. Don't ask me why
    setTimeout(() => {
      setRerender((prev) => !prev)
    }, 0)
  }, [workspaceItems.length])

  const onDeleteItem = useCallback((itemId: string) => {
    client.cache.evict({ id: `WorkspaceItem:${itemId}` })
    client.cache.gc()
  }, [])

  if (loading && !data) {
    return <Loading />
  }

  if (workspaceItems.length === 0 && !loading) {
    return (
      <Text size="base" color="neutral.1">
        {`🤷‍♂️  It doesn't look like ${
          isExternalUser ? `${userDisplayName} has` : 'you have'
        }  any public items yet`}
      </Text>
    )
  }

  return (
    <InfiniteScroll
      pageStart={0}
      threshold={1000}
      useWindow={false}
      getScrollParent={() => scrollNode}
      loadMore={handleLoadMore}
      hasMore={hasMore}
    >
      <GridColumnAdditioner parentWrapper={scrollNode}>
        {(gridColumnAdditionOption) => {
          return (
            <Grid
              gridColumnAdditionOption={gridColumnAdditionOption}
              scrollElement={scrollNode}
              gridItems={workspaceItems}
              withFadeInAnimation
              isMultiSelectDisabled
              onDelete={isExternalUser ? undefined : onDeleteItem}
              leftAligned
              {...(isExternalUser && {
                customGridItem: (props: GridItemProps) => (
                  <DiscoverGridItem {...props} currentUserId={currentUserId} />
                ),
              })}
            />
          )
        }}
      </GridColumnAdditioner>
      {loading && (
        <div className="loader" key={0}>
          <Loading fullscreen />
        </div>
      )}
    </InfiniteScroll>
  )
}
