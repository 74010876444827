import { useEffect, useRef } from 'react'

interface KeypressOptions {
  override?: boolean
  stopPropagation?: boolean
  preventDefault?: boolean
  disabled?: boolean
}

const metaPrefix = 'cmd:'
const shiftPrefix = 'shift:'

type KeyboardMetaCombination = `${typeof metaPrefix}${KeyboardKey}`
type KeyboardMetaShiftCombination =
  `${typeof metaPrefix}${typeof shiftPrefix}${KeyboardKey}`

type Key = KeyboardKey | KeyboardMetaCombination | KeyboardMetaShiftCombination

type GetCombinationArgs = {
  event: CustomKeyboardEvent
  key: Key
}
const getShouldTrigger = ({ event, key }: GetCombinationArgs) => {
  const isMetaCombination = key.startsWith(metaPrefix)
  const isMetaShiftCombination = key.startsWith(`${metaPrefix}${shiftPrefix}`)
  if (isMetaShiftCombination && event.metaKey && event.shiftKey) {
    return key.replace(`${metaPrefix}${shiftPrefix}`, '') === event.key
  }
  if (isMetaCombination && event.metaKey && !event.shiftKey) {
    return key.replace(metaPrefix, '') === event.key
  }
  return key === event.key
}

function useKeypress<ReturnKeyType>(
  keys: Array<Key>,
  handler: (event: CustomKeyboardEvent & { key: ReturnKeyType }) => void,
  options?: KeypressOptions
) {
  const shouldOverrideOtherKeyevents = options?.override ?? false
  const eventListenerRef = useRef<(event: CustomKeyboardEvent) => void>()

  useEffect(() => {
    if (options?.disabled) return
    eventListenerRef.current = (event: CustomKeyboardEvent) => {
      const shouldTriggerHandler = keys.some((key) => {
        return getShouldTrigger({ event, key })
      })
      if (shouldTriggerHandler) {
        handler?.(event as CustomKeyboardEvent & { key: ReturnKeyType })
        if (options?.stopPropagation) {
          event.stopPropagation()
        }
        if (options?.preventDefault) {
          event.preventDefault()
        }
      }
    }
  }, [
    keys,
    handler,
    options?.stopPropagation,
    options?.preventDefault,
    options?.disabled,
  ])

  useEffect(() => {
    if (options?.disabled) return
    const eventListener = (event: KeyboardEvent) => {
      eventListenerRef.current?.(event as CustomKeyboardEvent)
    }
    window.addEventListener(
      'keydown',
      eventListener,
      shouldOverrideOtherKeyevents
    )
    return () => {
      window.removeEventListener(
        'keydown',
        eventListener,
        shouldOverrideOtherKeyevents
      )
    }
  }, [shouldOverrideOtherKeyevents, options?.disabled])
}

export default useKeypress

type ModifierKeys =
  | 'Alt'
  | 'AltGraph'
  | 'CapsLock'
  | 'Control'
  | 'Fn'
  | 'FnLock'
  | 'Hyper'
  | 'Meta'
  | 'NumLock'
  | 'ScrollLock'
  | 'Shift'
  | 'Super'
  | 'Symbol'
  | 'SymbolLock'
  | 'Escape'
type WhitespaceKeys = 'Enter' | 'Tab' | ' '
type NavigationKeys =
  | 'ArrowDown'
  | 'ArrowLeft'
  | 'ArrowRight'
  | 'ArrowUp'
  | 'End'
  | 'Home'
  | 'PageDown'
  | 'PageUp'
type FunctionKeys =
  | 'F1'
  | 'F2'
  | 'F3'
  | 'F4'
  | 'F5'
  | 'F6'
  | 'F7'
  | 'F8'
  | 'F9'
  | 'F10'
  | 'F11'
  | 'F12'
  | 'F13'
  | 'F14'
  | 'F15'
  | 'F16'
  | 'F17'
  | 'F18'
  | 'F19'
  | 'F20'
  | 'Soft1'
  | 'Soft2'
  | 'Soft3'
  | 'Soft4'
type NumericKeypadKeys =
  | 'Decimal'
  | 'Key11'
  | 'Key12'
  | 'Multiply'
  | 'Add'
  | '-'
  | '+'
  | 'Clear'
  | 'Divide'
  | 'Subtract'
  | 'Separator'
  | '0'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'

type AlphabetKeys = 'a' | 's' | 'd' | 'f' | 'z' // TODO: Add more

type KeyboardKey =
  | ModifierKeys
  | WhitespaceKeys
  | NavigationKeys
  | FunctionKeys
  | NumericKeypadKeys
  | AlphabetKeys

type CustomKeyboardEvent = KeyboardEvent & {
  readonly key: KeyboardKey
}
