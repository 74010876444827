import { Close } from '@styled-icons/material-rounded/Close'
import React, { FC, MouseEventHandler, useState } from 'react'
import styled, { css } from 'styled-components'

import Button from 'components/common/Button'
import { Spacer } from 'components/common/Spacer'

import { capitalizeFirstLetter } from '../../helpers/utilityFunctions'
import { Text } from '../common/Text'
import ItemTagEditable from '../itemtags/ItemTagEditable'
import { IconButton } from '../itemtags/ItemTagStyles'
import { TagProps } from '../itemtags/ItemTags'

type Props = {
  onSubmit: (tags: { id: string; description: string }[]) => void
  onCancel?: () => void
  onClose: () => void
  placeholder?: string
}

const getTagIdForNewTag = (tag: TagProps) => {
  if ('tagId' in tag) {
    return tag.tagId
  }
  if ('id' in tag) {
    return tag.id
  }
  return tag.description
}

const noop = () => undefined

export const AddTags: FC<Props> = (props) => {
  const { onSubmit, onCancel, placeholder, onClose } = props
  const [tagsSelected, setTagsSelected] = useState<
    { id: string; description: string }[]
  >([])

  const handleAddTag = (_tag: TagProps) => {
    const tag = {
      id: getTagIdForNewTag(_tag),
      description: _tag.description,
    }
    const _tagsSelected = [...tagsSelected]
    if (_tagsSelected.find(({ id }) => id === tag.id)) {
      return
    }
    _tagsSelected.push(tag)
    setTagsSelected(_tagsSelected)
  }

  const handleDeleteTag = (tag: TagProps) => {
    setTagsSelected(
      tagsSelected.filter((t) => t.description !== tag.description)
    )
  }

  const reset = () => {
    setTagsSelected([])
    onClose()
  }

  const handleSubmit: MouseEventHandler = (e) => {
    e.preventDefault()
    onSubmit(tagsSelected)
    reset()
  }

  return (
    <div>
      {tagsSelected.length > 0 && (
        <>
          <Text
            color="neutral.3"
            size="sm"
            css={`
              padding-bottom: 4px;
              text-align: left;
            `}
          >
            Adding tags to upload
          </Text>
          <SelectedTagWrapper>
            {tagsSelected.map((tag) => (
              <SelectedTag tag={tag} onDelete={handleDeleteTag} key={tag.id} />
            ))}
          </SelectedTagWrapper>
          <Spacer factor={2} />
        </>
      )}

      <Text
        color="neutral.3"
        size="sm"
        css="padding-bottom: 4px; text-align: left"
      >
        Select tags
      </Text>
      <ItemTagEditable
        addTag={handleAddTag}
        activeTagIds={tagsSelected.map((tag) => tag.id || '')}
        onCancelEditing={onCancel || noop}
        placeholder={placeholder || 'Add tags'}
      />
      <Spacer factor={2} />
      <Button
        onClick={handleSubmit}
        variant="primary"
        fullWidth
        disabled={tagsSelected.length === 0}
      >
        Select tags
      </Button>
    </div>
  )
}

type SelectedTagProps = {
  onDelete: (tag: TagProps) => void
  tag: TagProps
}

const SelectedTag: FC<SelectedTagProps> = ({ tag, onDelete }) => {
  return (
    <TagWrapper>
      <div css="margin-right: 4px">
        {capitalizeFirstLetter(tag.description)}
      </div>
      <IconButton onClick={() => onDelete(tag)}>
        <Close style={{ height: 16 }} />
      </IconButton>
    </TagWrapper>
  )
}

const SelectedTagWrapper = styled.div`
  max-width: 100%;
  display: flex;
  text-align: left;
  flex-wrap: wrap;
`

const TagWrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.background[4]};
    user-select: none;
    height: 44px;
    margin-top: 10px;
    margin-right: 12px;
    text-align: left;
    padding: 8px;
    border-radius: ${theme.borderRadius.default};
    font-size: ${theme.fontSizes.base};
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    color: ${theme.colors.text.neutral[3]};
    border: ${theme.border.thin} transparent;
    :hover {
      color: ${theme.colors.text.neutral[2]};
      background: ${theme.colors.background[5]};
    }
    :hover ${IconButton} {
      display: flex;
    }
  `};
`
