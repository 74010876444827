import loadable from '@loadable/component'
import { Search as SearchIcon } from '@styled-icons/bootstrap/Search'
import { ChevronUp } from '@styled-icons/boxicons-regular/ChevronUp'
import { ArrowBack } from '@styled-icons/material/ArrowBack'
import throttle from 'lodash/throttle'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import styled, { css } from 'styled-components'
import { v4 as uuid } from 'uuid'

import IconButton from 'components/common/IconButton'
import { Margin } from 'components/common/Margin'
import WorkspaceNotifications from 'components/notifications/WorkspaceNotifications'
import { getRandomHexColor } from 'helpers/color'
import { capitalizeFirstLetter } from 'helpers/formatting'
import { safeImport } from 'helpers/safeImport'
import { trackSearchSessionCreated } from 'helpers/tracking/tracking'
import useClickOutside from 'hooks/useClickOutside'
import useQueryString from 'hooks/useQueryString'
import { RootState } from 'store'
import { DBSearchCategory } from 'types/db'

import ColorSearch from './ColorSearch'
import FaceSearch from './FaceSearch'
import { SearchCategoryPublicTags } from './SearchCategoryPublicTags'
import SearchCategoryTags from './SearchCategoryTags'
import { SearchMode, useSearchContext } from './SearchContext'
import SearchResultsItems from './SearchResultsItems'
import SearchResultsPublicItems from './SearchResultsPublicItems'
import SearchResultsTags from './SearchResultsTags'
import {
  HalfCircleButton,
  Main,
  SearchBar,
  SearchBarContainer,
  SearchInput,
} from './SearchStyles'
import SearchTag from './SearchTag'
import SearchUpload from './SearchUpload'
import { parseFreeText } from './helpers/getParsedFreeText'

export const SearchRecommendationsComponent = loadable(() =>
  safeImport(
    () =>
      import(
        /* webpackChunkName: "SearchRecommendations" */ './SearchRecommendations'
      )
  )
)

interface ActiveViewProps {
  searchPublic?: boolean
}

const SearchPanel: React.FC<ActiveViewProps> = ({ searchPublic }) => {
  const { searchMode } = useSearchContext()
  if (searchMode === SearchMode.FACE) {
    return <FaceSearch />
  }
  if (searchMode === SearchMode.COLOR) {
    return <ColorSearch />
  }
  if (searchMode === SearchMode.TAG) {
    // we disable tags for now in public search (discover)
    if (searchPublic) return null
    return <SearchResultsTags />
  }
  if (searchMode === SearchMode.CATEGORY) {
    if (searchPublic) return <SearchCategoryPublicTags />
    return <SearchCategoryTags />
  }

  return <SearchRecommendationsComponent />
}

interface SearchCreationProps {
  placeholder: string
  searchPublic?: boolean
  shouldDisplayUploadButton?: boolean
  shouldDisplayNotifications?: boolean
  leftSideContent?: React.ReactElement
  rightSideContent?: React.ReactElement
}

const SearchCreation: React.FC<SearchCreationProps> = ({
  placeholder,
  searchPublic,
  shouldDisplayUploadButton,
  shouldDisplayNotifications,
  leftSideContent,
  rightSideContent,
}) => {
  const [searchTerms, setSearchTerms] = useState<
    {
      id: string
      label: string
      handleClick: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
      handleClose: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    }[]
  >([])

  const [isSearchInputFocused, setIsSearchInputFocused] = useState(false)
  const activeElement = useRef(document.activeElement)
  const searchInputRef = useRef<HTMLInputElement | null>(null)
  const [wrapperElement, setWrapperElement] = useState<HTMLDivElement | null>(
    null
  )

  const {
    freeText,
    displayFreeText,
    tags,
    color,
    category,
    face,
    isSearching: isSearchViewOpen,
    isSearchPanelOpen,
    setSearchMode,
    searchMode,
    updateFreeText,
    removeTag,
    updateColor,
    updateCategory,
    updateIsSearching,
    resetSearch,
    removeFaceTag,
    setIsSearchPanelOpen,
    addFolder,
    removeFolder,
    folders,
    addFilename,
    removeFilename,
    filename,
    fileFormat,
    addFileFormat,
    removeFileFormat,
    setSearchSessionId,
  } = useSearchContext()

  const { item: itemId } = useQueryString<{ item: string }>()

  useFirestoreConnect([
    {
      collection: 'searchCategories',
      orderBy: ['title', 'desc'],
    },
  ])

  const siderSplitValue = useSelector(
    (state: RootState) => state.ui.siderSplitValue
  )

  const categoryTitle = useSelector((state: RootState) => {
    if (category === null) {
      return null
    }
    const searchCategory = state.firestore.data.searchCategories?.[
      category
    ] as DBSearchCategory
    return searchCategory?.title ?? null
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setIsSearchPanelOpenThrottled = useCallback(
    throttle(setIsSearchPanelOpen, 200),
    []
  )

  // Remove the rightmost search term and trigger its close handler
  const removeLastSearchTerm = useCallback(() => {
    setSearchTerms((prev) => {
      const newSearchTerms = [...prev]
      const lastSearchTerm = newSearchTerms.pop()
      if (newSearchTerms.length <= 1) {
        setSearchMode(SearchMode.DEFAULT)
      }
      lastSearchTerm?.handleClose()
      return newSearchTerms
    })
  }, [setSearchMode])

  const activateSearch = useCallback(() => {
    SearchRecommendationsComponent.preload() // by preloading here Searchrecommendations will be shown much faster
    setIsSearchPanelOpenThrottled(true)
    updateIsSearching(true)
    if (freeText !== '') {
      setSearchMode(SearchMode.TAG)
    } else {
      setSearchMode(SearchMode.DEFAULT)
    }
    if (searchInputRef?.current) {
      searchInputRef.current.focus()
    }
    if (wrapperElement) {
      wrapperElement.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }, [
    freeText,
    setIsSearchPanelOpenThrottled,
    setSearchMode,
    updateIsSearching,
    wrapperElement,
  ])

  const deactivateSearch = useCallback(() => {
    if (searchInputRef?.current) {
      searchInputRef.current.blur()
    }
    resetSearch()
    setIsSearchPanelOpen(false)
  }, [resetSearch, setIsSearchPanelOpen])

  useEffect(() => {
    // When the query updates, update the search terms to be displayed in the search bar
    const updateSearchTerms = () => {
      const categorySearches =
        category !== null && categoryTitle !== null
          ? [
              {
                id: categoryTitle,
                label: `/${categoryTitle}`,
                handleClick: () => setSearchMode(SearchMode.CATEGORY),
                handleClose: () => updateCategory(null),
              },
            ]
          : []

      const colorSearches =
        color !== null
          ? [
              {
                id: color,
                label: color,
                handleClick: (
                  e?: React.MouseEvent<HTMLDivElement, MouseEvent>
                ) => {
                  e?.stopPropagation()
                  setIsSearchPanelOpen(true)
                  updateIsSearching(true)
                  setSearchMode(SearchMode.COLOR)
                },
                handleClose: (
                  e?: React.MouseEvent<HTMLDivElement, MouseEvent>
                ) => {
                  e?.stopPropagation()
                  updateColor(null)
                  setIsSearchPanelOpen(true)
                },
              },
            ]
          : []

      const tagSearches = tags.map((tag) => ({
        id: tag.id,
        label: capitalizeFirstLetter(tag.label),
        handleClick: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          e?.stopPropagation()
          removeTag(tag.id)
          updateFreeText(tag.label)
          setSearchMode(SearchMode.TAG)
          searchInputRef?.current?.focus()
        },
        handleClose: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          e?.stopPropagation()
          setIsSearchPanelOpen(true)
          removeTag(tag.id)
        },
      }))

      const faceSearches = face.map((tag) => ({
        id: tag,
        label: `Face(${tag})`,
        handleClick: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          e?.stopPropagation()
          setSearchMode(SearchMode.FACE)
        },
        handleClose: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          e?.stopPropagation()
          setIsSearchPanelOpen(true)
          removeFaceTag(tag)
        },
      }))

      const folderSearches = folders.map((folder) => ({
        id: folder,
        label: `📁 ${folder}`,
        handleClick: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          e?.stopPropagation()
          removeFolder(folder)
          updateFreeText(`folder:${folder}`)
          setIsSearchPanelOpen(true)
          searchInputRef?.current?.focus()
        },
        handleClose: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          e?.stopPropagation()
          setIsSearchPanelOpen(true)
          removeFolder(folder)
        },
      }))

      const filenameSearches = filename
        ? [
            {
              id: filename,
              label: `📄 ${filename}`,
              handleClick: (
                e?: React.MouseEvent<HTMLDivElement, MouseEvent>
              ) => {
                e?.stopPropagation()
                removeFilename(filename)
                updateFreeText(`file:${filename}`)
                setIsSearchPanelOpen(true)
                searchInputRef?.current?.focus()
              },
              handleClose: (
                e?: React.MouseEvent<HTMLDivElement, MouseEvent>
              ) => {
                e?.stopPropagation()
                setIsSearchPanelOpen(true)
                removeFilename(filename)
              },
            },
          ]
        : []

      const fileFormatSearches = fileFormat
        ? [
            {
              id: fileFormat,
              label: fileFormat.toUpperCase(),
              handleClick: (
                e?: React.MouseEvent<HTMLDivElement, MouseEvent>
              ) => {
                e?.stopPropagation()
                removeFilename(fileFormat)
                updateFreeText(`fileFormat:gif`)
                setIsSearchPanelOpen(true)
                searchInputRef?.current?.focus()
              },
              handleClose: (
                e?: React.MouseEvent<HTMLDivElement, MouseEvent>
              ) => {
                e?.stopPropagation()
                setIsSearchPanelOpen(true)
                removeFileFormat(fileFormat)
              },
            },
          ]
        : []

      if (categorySearches.length > 0) {
        setSearchMode(SearchMode.CATEGORY)
      }
      setSearchTerms([
        ...categorySearches,
        ...colorSearches,
        ...tagSearches,
        ...faceSearches,
        ...folderSearches,
        ...filenameSearches,
        ...fileFormatSearches,
      ])
    }
    updateSearchTerms()
  }, [
    category,
    categoryTitle,
    color,
    face,
    folders,
    filename,
    fileFormat,
    removeFaceTag,
    removeTag,
    setIsSearchPanelOpen,
    setSearchMode,
    tags,
    updateCategory,
    updateColor,
    updateFreeText,
    updateIsSearching,
    removeFolder,
    removeFilename,
    removeFileFormat,
  ])

  // Keyboard handlers
  useEffect(() => {
    // const isSearchInputFocused =
    //   activeElement.current === searchInputRef.current
    const isBodyFocused = activeElement.current === document.body
    const isGridFocused = activeElement.current?.className === 'grid'
    const shouldCaptureKeyDown =
      (isSearchInputFocused || isBodyFocused) &&
      !isGridFocused &&
      isSearchViewOpen

    if (!shouldCaptureKeyDown) {
      return
    }

    const getEventHandler = (key: string) => {
      switch (key) {
        case 'Escape':
          return () => deactivateSearch()
        /**
         * Free text query is present: do nothing
         * No query is present: do nothing
         * One search term is present: remove the search term and show the default search view
         * Multiple search terms are present and no free text query is present: remove the rightmost search term
         */
        case 'Backspace':
          return () => {
            if (displayFreeText.length === 0) {
              if (searchTerms.length <= 1) {
                setSearchMode(SearchMode.DEFAULT)
                setIsSearchPanelOpen(true)
              }
              removeLastSearchTerm()
            }
          }
        case 'Enter':
          return () => {
            const isQueryEmpty =
              displayFreeText === '' && searchTerms.length === 0
            if (isQueryEmpty) return

            setIsSearchPanelOpen(false)

            const { folder, file, format } = parseFreeText(displayFreeText)

            if (folder) {
              addFolder(folder)
              updateFreeText('')
            }

            if (file) {
              addFilename(file)
              updateFreeText('')
            }

            if (format) {
              addFileFormat(format)
              updateFreeText('')
            }
          }
        default:
          // Focus the input field if user starts typing
          if (key.length === 1 && key !== ' ') {
            return () => {
              searchInputRef.current?.focus()
            }
          }
      }
    }

    const handleKeyDown = (e: KeyboardEvent) => {
      if (itemId) return // if the item view is open we don't wanna act on keydown events
      const eventHandler = getEventHandler(e.key)
      if (!eventHandler) {
        return
      }
      eventHandler()
      e.stopPropagation()
    }

    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [
    deactivateSearch,
    displayFreeText,
    freeText,
    isSearchViewOpen,
    removeLastSearchTerm,
    searchTerms.length,
    setIsSearchPanelOpen,
    setIsSearchPanelOpenThrottled,
    setSearchMode,
    updateFreeText,
    itemId,
    addFolder,
    addFilename,
    addFileFormat,
    isSearchInputFocused,
  ])

  // Activate color search on #
  useEffect(() => {
    if (displayFreeText === '#') {
      setSearchMode(SearchMode.COLOR)
      resetSearch()
      updateColor(getRandomHexColor())
    }
  }, [displayFreeText, resetSearch, setSearchMode, updateColor])

  useEffect(() => {
    if (
      displayFreeText.length === 0 &&
      searchTerms.length === 0 &&
      isSearchViewOpen
    ) {
      const searchSessionId = uuid()
      setSearchSessionId(searchSessionId)
      trackSearchSessionCreated({ searchSessionId })
    }
  }, [displayFreeText.length, searchTerms.length, isSearchViewOpen])

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateFreeText(e.target.value)
      setIsSearchPanelOpenThrottled(true)
      updateIsSearching(true)
    },
    [setIsSearchPanelOpenThrottled, updateFreeText, updateIsSearching]
  )

  const handleMouseEnterSearchBar = () => {
    if (searchInputRef.current !== document.activeElement) {
      if (searchInputRef?.current) {
        searchInputRef.current.focus()
      }
    }
  }

  const updateWrapperElement = (ref: HTMLDivElement | null) => {
    if (ref) {
      setWrapperElement(ref)
    }
  }

  const clickOutsideRef = useClickOutside(
    (e: any) => {
      if (isSearchPanelOpen && !itemId) {
        // we have experienced issues with this click outside as it does not work properly with multi-select actionbar, modals and fixed elements
        // so we are manually checking that the click is on our first split pane as that is the only click outside we wanna listen for
        const pane1Element = document.querySelector('.Pane1')
        if (pane1Element?.contains(e.target)) {
          deactivateSearch()
        }
      }
    },
    {
      overrideClickListeners: false,
    }
  )

  const displaySearchTerms = searchTerms
    .map((searchTerm) => searchTerm.label)
    .join(', ')

  return (
    <>
      <Main
        ref={clickOutsideRef}
        full={isSearchViewOpen}
        siderSplitValue={siderSplitValue}
      >
        <SearchBarContainer>
          {leftSideContent && leftSideContent}
          <SearchBar
            onMouseEnter={handleMouseEnterSearchBar}
            onClick={activateSearch}
            data-intercom-target="Search Bar Input Container" // Used for Intercom product tours
            id="search_bar"
          >
            <StyledSearchIcon />
            {searchTerms.map(({ id, label, handleClick, handleClose }) => (
              <SearchTag
                key={id}
                id={id}
                label={label}
                onClick={handleClick}
                onClose={handleClose}
              />
            ))}
            <SearchInput
              id="searchInput"
              onFocus={() => setIsSearchInputFocused(true)}
              onBlur={() => setIsSearchInputFocused(false)}
              active={isSearchPanelOpen}
              placeholder={placeholder}
              value={displayFreeText}
              onChange={handleSearchChange}
              onClick={activateSearch}
              autoComplete="off"
              ref={searchInputRef}
              type="text"
            />
          </SearchBar>

          {isSearchViewOpen && isSearchPanelOpen && (
            <HalfCircleButton onClick={deactivateSearch}>
              <ChevronUp style={{ marginTop: -2, height: 28 }} />
            </HalfCircleButton>
          )}
          {shouldDisplayNotifications && (
            <>
              <Margin x={12} />
              <WorkspaceNotifications />
            </>
          )}
          {shouldDisplayUploadButton && (
            <>
              <Margin x={12} />
              <SearchUpload />
            </>
          )}
          {rightSideContent && rightSideContent}
        </SearchBarContainer>
        {isSearchViewOpen && (
          <GridContainer ref={updateWrapperElement}>
            {isSearchPanelOpen && (
              <>
                {searchMode !== SearchMode.DEFAULT && (
                  <IconButton
                    Icon={ArrowBack}
                    style={{ marginLeft: 8 }}
                    onClick={() => {
                      setSearchMode(SearchMode.DEFAULT)
                      updateCategory(null)
                    }}
                  />
                )}
                <SearchPanel searchPublic={searchPublic} />
              </>
            )}

            {wrapperElement &&
              (searchPublic ? (
                <SearchResultsPublicItems
                  wrapperElement={wrapperElement}
                  displayQuery={`${displaySearchTerms}${
                    freeText && displaySearchTerms ? `, ${freeText}` : freeText
                  }`}
                />
              ) : (
                <SearchResultsItems
                  wrapperElement={wrapperElement}
                  isSearchPanelOpen={isSearchPanelOpen}
                  displayQuery={`${displaySearchTerms}${
                    freeText && displaySearchTerms ? `, ${freeText}` : freeText
                  }`}
                />
              ))}
          </GridContainer>
        )}
      </Main>
      <div style={{ height: '60px' }} />
    </>
  )
}

const GridContainer = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: calc(100vh - 60px);
  height: 100%;
  padding: 16px 0;
`

const StyledSearchIcon = styled(SearchIcon)`
  ${({ theme }) => css`
    height: 18px;
    color: ${theme.colors.text.neutral[0]};
    padding-left: 18px;
    padding-right: 4px;
    @media (max-width: 800px) {
      display: none;
    }
  `}
`

export default SearchCreation
