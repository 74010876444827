import { useEffect, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet } from 'react-router'
import SplitPane from 'react-split-pane'

import { RootState } from 'store'
import { setSiderSplitValue } from 'store/ui'

import { debounce } from '../../helpers/utilityFunctions'
import Menu from '../menu/Menu'

export const WorkspaceWithMenuView = () => {
  const dispatch = useDispatch()

  const siderSplitValue = useSelector(
    (state: RootState) => state.ui.siderSplitValue
  )

  const updateSiderSplitValue = (newValue: number) => {
    dispatch(setSiderSplitValue(newValue))
  }

  const handleResize = debounce(() => {
    if (window.innerWidth <= 900) updateSiderSplitValue(60)
    if (window.innerWidth > 900) updateSiderSplitValue(210)
  }, 10)

  useLayoutEffect(() => {
    updateSiderSplitValue(window.innerWidth <= 900 ? 60 : 210)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  return (
    <>
      {/* @ts-ignore */}
      <SplitPane
        onChange={updateSiderSplitValue}
        split="vertical"
        minSize={60}
        maxSize={300}
        size={siderSplitValue}
      >
        <Menu />
        <Outlet />
      </SplitPane>
    </>
  )
}
