import Gift from 'iconoir-react/dist/Gift'
import Heart from 'iconoir-react/dist/Heart'
import Trophy from 'iconoir-react/dist/Trophy'
import Skeleton from 'react-loading-skeleton'
import { Link, useLocation } from 'react-router-dom'
import styled, { css } from 'styled-components'

import Flex from 'components/common/Flex'
import { Spacer } from 'components/common/Spacer'
import { Text } from 'components/common/Text'
import UserAvatar from 'components/common/UserAvatar'
import { timeAgo } from 'helpers/utilityFunctions'
import useIsMobile from 'hooks/useIsMobile'

type ProfileInfoProps = {
  isLoadingUser: boolean
  profilePublicItemsCount?: number | null
  profileDiscoverExportCount?: number | null
  profileRank?: number | null
  profileBio?: string | null
  profileCreatedAt?: string | null
  nominatedByUser?: {
    displayName: string
    handle: string
    photoURL?: string | null
  } | null
}

export const ProfileInfo = (props: ProfileInfoProps) => {
  const isMobile = useIsMobile()
  const location = useLocation()

  return (
    <Flex flexDirection={isMobile ? 'column-reverse' : 'row'}>
      <StyledFlex flexDirection="column" justifyContent="space-between">
        <Text size="sm" color="neutral.3" css="max-width: 460px;">
          {props.isLoadingUser ? <Skeleton width={200} /> : props.profileBio}
        </Text>
        <Spacer axis="y" factor={3} />
        <Flex>
          {props.isLoadingUser ? (
            <Skeleton circle style={{ height: 26, width: 26 }} />
          ) : (
            props.nominatedByUser && (
              <Link
                css="display:flex"
                state={{ prevPathname: location.pathname }}
                to={`/u/${props.nominatedByUser.handle}`}
              >
                <UserAvatar
                  displayName={props.nominatedByUser.displayName}
                  photoUrl={props.nominatedByUser.photoURL}
                  size={26}
                />
              </Link>
            )
          )}

          {props.nominatedByUser && <Spacer axis="x" factor={2} />}
          <div>
            <Text size="sm" color="neutral.3">
              {props.isLoadingUser ? (
                <Skeleton width={100} />
              ) : (
                `Joined ${timeAgo(props.profileCreatedAt)}`
              )}
            </Text>
            {props.isLoadingUser ? (
              <Skeleton width={60} />
            ) : (
              props.nominatedByUser && (
                <StyledUserInfoLink
                  state={{ prevPathname: location.pathname }}
                  to={`/u/${props.nominatedByUser.handle}`}
                >
                  <Text size="sm">
                    {props.nominatedByUser.displayName &&
                      `Nominated by ${props.nominatedByUser.displayName}`}
                  </Text>
                </StyledUserInfoLink>
              )
            )}
          </div>
        </Flex>
      </StyledFlex>
      {isMobile && <Spacer axis="y" factor={3} />}
      <StyledFlex flexDirection="column" justifyContent="end">
        {props.isLoadingUser ? (
          <Skeleton width={250} />
        ) : (
          props.profileRank && (
            <Flex alignItems="center">
              <StyledTrophyIcon />
              <Spacer axis="x" factor={1} />
              <Text size="sm" color="neutral.0">
                #{props.profileRank} popular contributor
              </Text>
            </Flex>
          )
        )}
        {props.isLoadingUser ? (
          <Skeleton width={250} />
        ) : (
          <Flex alignItems="center">
            <StyledGiftIcon />
            <Spacer axis="x" factor={1} />
            <Text size="sm" color="neutral.0">
              {props.profilePublicItemsCount} public items
            </Text>
          </Flex>
        )}
        {props.isLoadingUser ? (
          <Skeleton width={250} />
        ) : (
          <Flex alignItems="center">
            <StyledHeartIcon />
            <Spacer axis="x" factor={1} />
            <Text size="sm" color="neutral.0">
              {props.profileDiscoverExportCount || 0} re-saved public items
            </Text>
          </Flex>
        )}
      </StyledFlex>
    </Flex>
  )
}

const StyledFlex = styled(Flex)`
  width: 100%;
`

const StyledUserInfoLink = styled(Link)(
  ({ theme }) => css`
    color: ${theme.colors.text.neutral[3]};
    transition: 0.1s color ease;
    :hover {
      color: ${theme.colors.accent[2]};
    }
  `
)

const StyledGiftIcon = styled(Gift)(
  ({ theme }) => css`
    height: 20px;
    width: 20px;
    color: ${theme.colors.accent[3]};
    padding-bottom: 3px;
  `
)

const StyledTrophyIcon = styled(Trophy)(
  ({ theme }) => css`
    height: 20px;
    width: 20px;
    color: ${theme.colors.accent[1]};
    padding-bottom: 3px;
  `
)

const StyledHeartIcon = styled(Heart)(
  ({ theme }) => css`
    height: 20px;
    width: 20px;
    color: ${theme.colors.accent[0]};
    padding-bottom: 3px;
  `
)
