import styled, { css } from 'styled-components'

const Card = styled.div`
  ${({ theme, dropOver, isActive }) =>
    dropOver &&
    !isActive &&
    css`
      background-color: ${theme.colors.background[1]};
      color: ${theme.colors.text.neutral[0]};
      border: ${theme.border.thin} ${theme.colors.accent[2]};
    `}
  ${({ theme, isActive, dropOver }) => css`
    display: inline-block;
    position: relative;
    padding: 2;
    border-radius: ${theme.borderRadius.default};
    font-size: ${theme.fontSizes.sm};
    line-height: ${theme.lineHeight.tight};
    margin-top: 8px;
    letter-spacing: ${theme.letterSpacing.normal};
    color: ${!dropOver && theme.colors.text.neutral[3]};
    background-color: ${isActive && theme.colors.background[4]};
    border: ${(!dropOver || isActive) && `${theme.border.thin} transparent`};
    cursor: pointer;
    width: 100%;
    :hover {
      background-color: ${theme.colors.background[3]};
      color: ${theme.colors.text.neutral[0]};
    }
    :active {
      background-color: ${theme.colors.background[4]};
      color: ${theme.colors.accent[2]};
    }
  `}
`

const Head = styled.div((props) => ({
  display: 'inline-flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: 4,
  width: 'calc(100% - 8px)',
}))

const Title = styled.div(({ theme, isActive }) => ({
  flex: '1 1 0',
  margin: 8,
  overflowWrap: 'break-word',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  color: isActive && theme.colors.accent[2],
}))

const Count = styled.div((props) => ({
  padding: 'inherit', // so the absolute tag won't ignore card padding
  float: 'right',
}))

const Tag = styled.div(({ theme, isActive }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: theme.fontSizes.sm,
  padding: 12,
  backgroundColor: isActive
    ? theme.colors.accent[2]
    : theme.colors.background[5],
  color: isActive && theme.colors.background[1],
  borderRadius: theme.borderRadius.lg,
  margin: 2,
}))

export { Card, Head, Tag, Count, Title }
