import { Send } from '@styled-icons/boxicons-solid/Send'
import uniqBy from 'lodash/uniqBy'
import React, { forwardRef, useState } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import styled, { css } from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

import Button from 'components/common/Button'
import { Margin } from 'components/common/Margin'
import UserAvatar from 'components/common/UserAvatar'
import useToastMessages from 'components/toast/useToastMessages'
import {
  ItemCommentBasicFieldsFragmentDoc,
  ItemCommentsQuery,
  useAddItemCommentMutation,
} from 'generated/graphql'
import { Guest } from 'hooks/useGuest'
import useIsMobile from 'hooks/useIsMobile'
import { DBUser } from 'types/db'

const getOptimisticAuthor = (
  userOrGuest: DBUser | Guest
): ItemCommentsQuery['itemComments']['itemComments'][0]['author'] => {
  if ('email' in userOrGuest) {
    return {
      __typename: 'User',
      id: userOrGuest.uid,
      displayName: userOrGuest.displayName,
      photoURL: userOrGuest.photoURL,
    }
  }
  return {
    __typename: 'Guest',
    id: userOrGuest.uid,
    displayName: userOrGuest.displayName!,
  }
}

interface ItemCommentInputProps {
  userOrGuest: DBUser | Guest
  boardId: string | null
  itemId: string
}

const ItemCommentInput = forwardRef<HTMLTextAreaElement, ItemCommentInputProps>(
  (props, inputRef) => {
    const [commentTextInput, setCommentTextInput] = useState('')
    const { reportError } = useToastMessages()
    const isMobile = useIsMobile()

    const [addItemCommentMutation] = useAddItemCommentMutation()

    const handleAddItemComment = () => {
      if (!commentTextInput) return

      addItemCommentMutation({
        variables: {
          parentId: props.itemId,
          text: commentTextInput,
          boardId: props.boardId,
        },
        update: (cache, payload) => {
          cache.modify({
            fields: {
              itemComments: (existingItemComments, { storeFieldName }) => {
                if (storeFieldName !== `itemComments:${props.itemId}`) {
                  return existingItemComments
                }
                const newComment = payload.data?.addItemComment
                const ref = cache.writeFragment({
                  data: newComment,
                  fragment: ItemCommentBasicFieldsFragmentDoc,
                })
                const newItemComments = uniqBy(
                  [ref, ...existingItemComments.itemComments],
                  '__ref'
                )
                return {
                  ...existingItemComments,
                  itemComments: newItemComments,
                }
              },
            },
          })
          cache.modify({
            id: props.boardId
              ? `BoardItem:${props.itemId}`
              : `WorkspaceItem:${props.itemId}`,
            fields: {
              commentCount(existingCount) {
                return existingCount + 1
              },
            },
          })
        },
        optimisticResponse: {
          addItemComment: {
            __typename: 'ItemComment',
            id: uuidv4(),
            text: commentTextInput,
            createdAt: new Date().toISOString(),
            author: getOptimisticAuthor(props.userOrGuest),
          },
        },
        onError: (error) => {
          const isForbidden =
            error.graphQLErrors?.[0].extensions?.code === 'FORBIDDEN'
          if (isForbidden) {
            return reportError(
              `Oh, you are not allowed to comment on this item.`
            )
          }
          reportError(`Oh, we couldn't send the comment. Please try again.`)
        },
      })
      setCommentTextInput('')
    }

    return (
      <div css="display:flex;align-items:flex-start">
        <UserAvatar
          photoUrl={
            'email' in props.userOrGuest ? props.userOrGuest.photoURL : null
          }
          displayName={props.userOrGuest.displayName}
          size={32}
        />
        <Margin x={12} />
        <StyledTextarea
          ref={inputRef}
          placeholder="Write a comment"
          value={commentTextInput}
          maxRows={8}
          onKeyDown={(event) => {
            if (event.key === 'Escape') return

            // we stop propagations to ignore global shortcuts
            event.stopPropagation()
            event.nativeEvent.stopImmediatePropagation()
            if (event.key === 'Enter' && event.shiftKey === false) {
              event.preventDefault()
              handleAddItemComment()
            }
          }}
          style={{ height: 30 }}
          onChange={(event) => {
            setCommentTextInput(event.target.value)
          }}
        />
        {isMobile && commentTextInput.length !== 0 && (
          <>
            <Margin x={12} />
            <Button isCompact Icon={Send} variant="primary" />
          </>
        )}
      </div>
    )
  }
)

export default ItemCommentInput

const StyledTextarea = styled(TextareaAutosize)`
  ${({ theme }) => css`
    background-color: ${theme.colors.background[4]};
    color: ${theme.colors.text.neutral[0]};
    border-color: transparent;
    font-size: ${theme.fontSizes.sm};
    width: 100%;
    padding: 9px;
    border-radius: ${theme.borderRadius.default};
    outline: none;
    border: 1px solid transparent;
    resize: none;
    font-family: inherit;
    &:hover {
      border: 1px solid ${theme.colors.accent[2]};
    }
    &:focus {
      border: 1px solid ${theme.colors.accent[2]};
    }
    ::placeholder {
      color: ${theme.colors.text.neutral[2]};
      opacity: 0.7;
    }
  `}
`
