import { Download } from '@styled-icons/boxicons-regular/Download'
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import { Margin } from 'components/common/Margin'
import Spinner from 'components/common/Spinner'
import { Text } from 'components/common/Text'
import { useSelectAllCurrentBoardItems } from 'components/multiselect/useSelectAllCurrentBoardItems'
import { downloadItems } from 'helpers/downloadItem'
import { RootState } from 'store'
import { reset, setDownloadStatus } from 'store/multiSelect'
import { isInMultiSelectModeSelector } from 'store/selectors'

const PublicBoardCoverBar = ({ boardId }: { boardId: string }) => {
  const hasActiveFilters = false
  const handleSelectAllCurrentBoardItems =
    useSelectAllCurrentBoardItems(boardId)
  const inMultiSelectMode = useSelector(isInMultiSelectModeSelector)
  const isLightMode = useSelector(
    (state: RootState) => state.content.theme === 'lightTheme'
  )

  const selectedItems = useSelector(
    (state: RootState) => state.multiSelect.selectedItems
  )

  const downloadStatus = useSelector(
    (state: RootState) => state.multiSelect.downloadStatus
  )

  const dispatch = useDispatch()

  const handleDownloadItems = async () => {
    dispatch(setDownloadStatus('LOADING'))
    const items = Object.values(selectedItems)

    await downloadItems({ items, trackingContext: { boardId } })
    dispatch(setDownloadStatus('IDLE'))
  }

  // download selected items can be started by setting download status to "START"
  useEffect(() => {
    if (downloadStatus === 'START') {
      handleDownloadItems()
    }
  }, [downloadStatus])

  const nbrOfSelectedItems = Object.keys(selectedItems).length
  const shouldDisplayComponent = hasActiveFilters || inMultiSelectMode

  if (!shouldDisplayComponent) return null

  return (
    <Wrapper>
      <div>{hasActiveFilters && <div>Active filters: xxx</div>}</div>
      <div>
        {inMultiSelectMode && (
          <MultiSelectActions>
            <ItemsSelected isLightMode={isLightMode}>
              <Text nowrap color="neutral.0" size="sm">
                {nbrOfSelectedItems} items selected
              </Text>
              <ResetMultiSelectButton onClick={() => dispatch(reset())} />
            </ItemsSelected>
            <Margin x={12} />
            <Text
              onClick={handleSelectAllCurrentBoardItems}
              color="neutral.0"
              link
              size="sm"
            >
              Select all
            </Text>
            <Margin x={24} />
            <DownloadSelection
              style={{ whiteSpace: 'nowrap' }}
              onClick={handleDownloadItems}
            >
              {downloadStatus === 'LOADING' ? (
                <Spinner css="height: 20px" />
              ) : (
                <Download css="height: 20px" />
              )}
              <Margin x={8} />

              <Text color="neutral.0" size="sm">
                Download selection
              </Text>
            </DownloadSelection>
          </MultiSelectActions>
        )}
      </div>
    </Wrapper>
  )
}

export default PublicBoardCoverBar

const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 18px;
    margin-top: 8px;
    background-color: ${theme.colors.background[4]};
    border-top: 1px solid ${theme.colors.background[5]};
    border-bottom: 1px solid ${theme.colors.background[5]};
  `}
`

const MultiSelectActions = styled.div`
  display: flex;
  align-items: center;
`

const ItemsSelected = styled.div<{ isLightMode: boolean }>`
  ${({ theme, isLightMode }) => css`
    border-radius: ${theme.borderRadius.full};
    background-color: ${isLightMode
      ? theme.colors.gray.light[1]
      : theme.colors.gray.dark[7]};
    padding: 0px 12px;
    height: 32px;
    display: flex;
    align-items: center;
  `}
`

const ResetMultiSelectButton = styled(CloseOutline)`
  height: 24px;
  color: ${({ theme }) => theme.colors.text.neutral[0]};
  cursor: pointer;
  margin-left: 10px;
  margin-top: 1px;
`

const DownloadSelection = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    color: ${theme.colors.text.neutral[0]};
    cursor: pointer;
    border-radius: ${theme.borderRadius.full};
    padding: 0px 12px;
    height: 32px;
    &:hover {
      background: ${theme.colors.accent[2]};
      color: ${theme.colors.white};
    }
  `}
`
