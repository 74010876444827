import { StyledIcon } from '@styled-icons/styled-icon'
import React from 'react'
import styled, { css } from 'styled-components'

const Container = styled.div<{ isActive: boolean }>`
  ${({ isActive, theme }) => css`
    padding: 16px;
    display: flex;
    align-items: center;
    background: ${theme.colors.background[3]};
    color: ${isActive
      ? theme.colors.text.neutral[0]
      : theme.colors.text.neutral[2]};

    &:hover {
      color: ${theme.colors.text.neutral[0]};
      cursor: pointer;
    }
  `}
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
`

const Title = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.base};
  margin-bottom: 0;
`

const Subtitle = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  margin-bottom: 0;
`

const SelectMarker = styled.div<{ isActive: boolean }>`
  ${({ isActive, theme }) => css`
    border: 1px solid
      ${isActive ? theme.colors.text.neutral[0] : theme.colors.text.neutral[2]};
    border-radius: ${theme.borderRadius.full};
    height: 20px;
    width: 20px;
    padding: 2px;
  `}
`

const SelectMarkerFill = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.text.neutral[0]};
    border-radius: ${theme.borderRadius.full};
    width: 100%;
    height: 100%;
  `}
`

const SelectWrapper = styled.div`
  margin-left: 8px;
`

interface DropdownItemProps {
  title: string
  description: string
  Icon: StyledIcon
  isActive: boolean
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const DropdownItemLarge: React.FC<DropdownItemProps> = ({
  title,
  description,
  Icon,
  isActive,
  onClick,
}) => (
  <Container isActive={isActive} onClick={onClick}>
    <IconWrapper>
      <Icon style={{ height: 16 }} />
    </IconWrapper>
    <TextWrapper>
      <Title>{title}</Title>
      <Subtitle>{description}</Subtitle>
    </TextWrapper>
    <SelectWrapper>
      <SelectMarker isActive={isActive}>
        {isActive && <SelectMarkerFill />}
      </SelectMarker>
    </SelectWrapper>
  </Container>
)

export default DropdownItemLarge
