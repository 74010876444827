import React from 'react'

import Modal from 'components/common/Modal'

type DowngradeModalChromeProps = {
  isOpen: boolean
  onCloseClick: () => void
  children: React.ReactNode
}

export const DowngradeModalChrome = ({
  isOpen,
  onCloseClick,
  children,
}: DowngradeModalChromeProps) => {
  return (
    <Modal isOpen={isOpen} close={onCloseClick}>
      <Modal.ContentWrapper>{children}</Modal.ContentWrapper>
    </Modal>
  )
}
