import { ArrowLeftShort } from '@styled-icons/bootstrap/ArrowLeftShort'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled, { css } from 'styled-components'

import Button from 'components/common/Button'
import { Margin } from 'components/common/Margin'
import Spinner from 'components/common/Spinner'
import { Text } from 'components/common/Text'
import useToastMessages from 'components/toast/useToastMessages'
import { useJoinWorkspaceMutation } from 'generated/graphql'

import { useJoinableWorkspacesQueries } from './useJoinableWorkspacesQueries'

const JoinWorkspaces = () => {
  const navigate = useNavigate()
  const {
    data,
    loading: queryIsLoading,
    error: errorFromQuery,
  } = useJoinableWorkspacesQueries()

  const navigateToHome = () => {
    navigate('/app')
  }

  if (queryIsLoading) {
    return (
      <JoinWorkspacesWrapper css="justify-content:center">
        <Spinner height="40px" color="white" />
      </JoinWorkspacesWrapper>
    )
  }

  if (errorFromQuery) {
    return (
      <JoinWorkspacesWrapper css="justify-content:center">
        <Text size="lg" color="neutral.0">
          🧐 Oh no, something strange happend...
        </Text>
        <Margin y={10} />
        <Button variant="primary" onClick={navigateToHome}>
          Go back to my workspaces
        </Button>
      </JoinWorkspacesWrapper>
    )
  }

  if (data.length === 0) {
    return (
      <JoinWorkspacesWrapper css="justify-content:center">
        <Text size="lg" color="neutral.0" center>
          You do not have any workspaces to join at the moment. <br /> Come back
          later 👇
        </Text>
        <Margin y={10} />
        <Button variant="primary" onClick={navigateToHome}>
          Go back to my workspaces
        </Button>
      </JoinWorkspacesWrapper>
    )
  }

  return (
    <JoinWorkspacesWrapper>
      <JoinWorkspacesContainer>
        <Text color="neutral.0" size="xl" bold>
          Your joinable workspaces
        </Text>
        <ArrowLeftShort
          css="height:45px; padding:5px; cursor: pointer;position: absolute; left: 5px; top: 5px;"
          color="#868686"
          onClick={navigateToHome}
        />

        <Margin y={20} />
        <WorkspacesCardsWrapper>
          {data.map((workspace) => (
            <WorkspaceCard key={workspace.id} workspace={workspace} />
          ))}
        </WorkspacesCardsWrapper>
      </JoinWorkspacesContainer>
    </JoinWorkspacesWrapper>
  )
}

export default JoinWorkspaces

interface WorkspaceCardProps {
  workspace: ReturnType<typeof useJoinableWorkspacesQueries>['data'][0]
}

const WorkspaceCard: React.FC<WorkspaceCardProps> = ({ workspace }) => {
  const navigate = useNavigate()
  const { reportError } = useToastMessages()
  const [isJoined, setIsJoined] = useState(false)

  const [joinWorkspaceMutation, { loading: mutationIsLoading }] =
    useJoinWorkspaceMutation()

  const joinWorkspace = async (workspaceId: string) => {
    try {
      await joinWorkspaceMutation({
        variables: { id: workspaceId },
      })
      setIsJoined(true)
    } catch (error) {
      reportError('Oh no, you could not join the workspace')
    }
  }
  return (
    <WorkspaceCardWrapper>
      <div css="display:flex; align-items:center">
        {workspace.iconUrl && <WorkspaceIconImage src={workspace.iconUrl} />}
        <Text
          color="neutral.0"
          size="lg"
          bold
          css="white-space: nowrap; text-overflow: ellipsis; overflow: hidden"
        >
          {workspace.title}
        </Text>
      </div>
      <Margin y={18} />
      {workspace.thumbUrls.length > 0 && (
        <>
          <WorkpaceThumb thumbUrls={workspace.thumbUrls} />
          <Margin y={18} />
        </>
      )}
      <Text color="neutral.1" size="base">
        {isJoined
          ? '✅  You are now part of the workspace!'
          : 'You can join this workspace!'}
      </Text>
      <Margin y={18} />
      {isJoined ? (
        <Button
          css="width: 160px;"
          variant="success"
          onClick={() => navigate(`/${workspace.url}`)}
        >
          Go to workspace!
        </Button>
      ) : (
        <Button
          css="width: 160px;"
          isLoading={mutationIsLoading}
          disabled={mutationIsLoading}
          variant="primary"
          onClick={() => joinWorkspace(workspace.id)}
        >
          Join workspace
        </Button>
      )}
    </WorkspaceCardWrapper>
  )
}

export const WorkpaceThumb = ({ thumbUrls }: { thumbUrls: string[] }) => {
  return (
    <WorkpaceThumbWrapper>
      <WorkspaceThumbRow>
        <WorkspaceThumbImagePart src={thumbUrls[0]} alt="thumbImage1" />
        <WorkspaceThumbImagePart src={thumbUrls[1]} alt="thumbImage2 " />
      </WorkspaceThumbRow>
      <WorkspaceThumbRow>
        <WorkspaceThumbImagePart src={thumbUrls[2]} alt="thumbImage3" />
        <WorkspaceThumbImagePart src={thumbUrls[3]} alt="thumbImage4" />
      </WorkspaceThumbRow>
    </WorkpaceThumbWrapper>
  )
}

const CARD_WIDTH = 340
const CARD_MARGIN = 12
const MAX_CARDS_PER_ROW = 4
const WRAPPER_MAX_WIDTH = (CARD_WIDTH + CARD_MARGIN * 2) * MAX_CARDS_PER_ROW

const JoinWorkspacesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 36px 0px;
  height: 100vh;
`

const JoinWorkspacesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const WorkspacesCardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  max-width: ${WRAPPER_MAX_WIDTH}px;
  justify-content: center;
`

export const WorkspaceCardWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: ${CARD_WIDTH}px;
    padding: 18px;
    border-radius: ${theme.borderRadius.default};
    background-color: ${theme.colors.background[4]};
    margin: ${CARD_MARGIN}px;
  `}
`

export const WorkspaceIconImage = styled.img`
  ${({ theme }) => css`
    border-radius: ${theme.borderRadius.default};
    width: 60px;
    height: 60px;
    margin-right: 12px;
  `}
`
const WorkpaceThumbWrapper = styled.div`
  ${({ theme }) => css`
    border-radius: ${theme.borderRadius.default};
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 280px;
  `}
`
const WorkspaceThumbRow = styled.div`
  display: flex;
  height: 50%;
`

const WorkspaceThumbImagePart = styled.img`
  width: 50%;
  object-fit: cover;
`
