/* eslint-disable no-nested-ternary */
import { Download } from '@styled-icons/boxicons-regular/Download'
import { Eraser } from '@styled-icons/boxicons-regular/Eraser'
import { TabAdd } from '@styled-icons/fluentui-system-regular/TabAdd'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import {
  ArrowClockwise,
  ArrowCounterclockwise,
  ArrowLeft,
  Plus,
} from 'styled-icons/bootstrap'
import { DiscordAlt } from 'styled-icons/boxicons-logos'
import { Minus } from 'styled-icons/boxicons-regular'
import { Cursor, HandRight } from 'styled-icons/fluentui-system-regular'

import Flex from 'components/common/Flex'
import { Tooltip } from 'components/tooltip/Tooltip'
import {
  useBroadcastEvent,
  useEventListener,
  useStorage,
} from 'config/liveblocks.config'
import { useCreditsUserQuery, useEditSessionQuery } from 'generated/graphql'
import { useInterval } from 'hooks/useInterval'
import useKeypress from 'hooks/useKeyPress'
import { RootState } from 'store'

import { StyledButtonMedium } from './ButtonMedium'
import { EraserSlider } from './EraserSlider'
import { FileUploader, QueuedImageProps } from './FileUploader'
import { GuideCanvas } from './GuideCanvas'
import { LensType, Lenses } from './Lenses'
import { PromptInput } from './PromptInput'
import { UserAvatarsPanel } from './UserAvatarsPanel'
import { BuyCreditsModal } from './buy-credits-modal/BuyCreditsModal'
import { InviteToSessionDialog } from './invite-to-session/action'
import { PowerUpComboButton } from './power-up-combo-button/PowerUpComboButton'
import { PowerUpModal } from './power-up-modal/PowerUpModal'

const StyledButtonContainer = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  padding: 16px;
  z-index: 10;
  gap: 8px;
`
type ControlProps = {
  onGenerationFrameClick: () => void
  isPlacingGenerationFrame: boolean
  isPlacingImages: boolean
  isErasing: boolean
  isUsingHandTool: boolean
  isLoading: boolean
  hasOutput: boolean
  setActiveLens: (newLens: null | LensType) => void
  activeLens: null | LensType
  disableControls: boolean
  onUndo: () => void
  onRedo: () => void
  onDownloadClick: () => void
  isDownloadSelectionActive: boolean
  setEraserSize: (value: number) => void
  eraserSize: number
  onEraserClick: () => void
  onHandToolClick: () => void
  editSessionId: string
  onZoom: (increaseScale: boolean) => void
  handleSubmit: (prompt: string) => void
  setImageQueue: (props: QueuedImageProps[]) => void
  isUsingSelectTool: boolean
  onSelectToolClick: () => void
  canUndo: boolean
  canRedo: boolean
  inputIsFocused: boolean
  setInputIsFocused: (isFocused: boolean) => void
  showGuide: boolean
}

export default function Controls({
  onGenerationFrameClick,
  onEraserClick,
  isPlacingGenerationFrame,
  isPlacingImages,
  isErasing,
  hasOutput,
  setActiveLens,
  activeLens,
  disableControls,
  isLoading,
  onUndo,
  onRedo,
  setEraserSize,
  eraserSize,
  onDownloadClick,
  editSessionId,
  onZoom,
  handleSubmit,
  onHandToolClick,
  isDownloadSelectionActive,
  isUsingHandTool,
  setImageQueue,
  isUsingSelectTool,
  onSelectToolClick,
  canUndo,
  canRedo,
  inputIsFocused,
  setInputIsFocused,
  showGuide,
}: ControlProps) {
  const broadcast = useBroadcastEvent()
  const [searchParams, setSearchParams] = useSearchParams()
  const [pollingCount, setPollingCount] = useState(0)
  const [mouseIsOverLenses, setMouseIsOverLenses] = useState(false)
  const [showBuyCreditsModal, setShowBuyCreditsModal] = useState(false)
  const [showPowerUpModal, setShowPowerUpModal] = useState(false)

  const navigate = useNavigate()

  const isCanvasEmpty = useStorage((root) =>
    root.shapes ? Object.keys(root.shapes).length === 0 : true
  )

  const { isEmpty, uid } = useSelector(
    (state: RootState) => state.firebase.auth
  )

  const {
    data: userCreditData,
    loading: userCreditLoading,
    refetch: userCreditRefetch,
  } = useCreditsUserQuery({
    variables: {
      uid,
    },
    skip: isEmpty,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'network-only',
  })

  useKeypress(['cmd:z'], onUndo, { preventDefault: true })
  useKeypress(['cmd:shift:z'], onRedo, {
    preventDefault: true,
  })

  const checkoutSuccess = useMemo(
    () => searchParams.get('checkout_success') ?? null,
    [searchParams]
  )
  const sessionId = useMemo(
    () => searchParams.get('session_id') ?? null,
    [searchParams]
  )
  const oldCount = useMemo(
    () => Number(searchParams.get('old_count')) ?? 0,
    [searchParams]
  )
  useInterval(
    async () => {
      setPollingCount((prev) => prev + 1)
      const updatedCredits = await userCreditRefetch()
      const numCredits =
        Number(
          updatedCredits.data?.user?.generationRequests?.availableCredits
        ) ?? 0

      if (numCredits > oldCount) {
        console.log(`numCredits=${numCredits} > oldCount=${oldCount}`)
        searchParams.delete('checkout_success')
        searchParams.delete('session_id')
        searchParams.delete('old_count')
        setSearchParams(searchParams)
      }
    },
    pollingCount <= 10 && checkoutSuccess === 'true' && sessionId ? 2500 : null
  )

  const {
    data: editSessionData,
    loading: editSessionLoading,
    refetch: refetchEditSession,
  } = useEditSessionQuery({
    variables: {
      id: `${editSessionId}`,
    },
    fetchPolicy: 'cache-and-network',
    skip: !editSessionId,
  })
  useEventListener(({ event }: { event: any }) => {
    if (event.type === 'POWER_UP' || event.type === 'POWER_DOWN') {
      refetchEditSession()
    }
    if (event.type === 'USED_CREDIT' && event.uid !== uid) {
      userCreditRefetch()
    }
  })

  const handleBackClick = () => {
    const backUrl =
      !editSessionLoading && editSessionData?.editSession?.context?.backUrl
    if (backUrl) {
      const url = new URL(backUrl)
      const routifiedPath = `${url.pathname}${url.search}`
      navigate(routifiedPath)
    } else {
      window.location.href = 'https://kive.ai/canvas'
    }
  }

  if (isEmpty) {
    return null
  }

  const promptSubmitHandler = (prompt: string) => {
    const availableCredits = Number(
      userCreditData?.user?.generationRequests?.availableCredits
    )

    if (availableCredits > 0) {
      if (editSessionData?.editSession?.powerUp?.isPowered) {
        broadcast({
          type: 'USED_CREDIT',
          uid,
        } as never)
      }
      return handleSubmit(prompt)
    }
    setShowBuyCreditsModal(true)
  }

  return (
    <div onMouseDown={(e) => e.stopPropagation()}>
      <EraserSlider
        setEraserSize={setEraserSize}
        eraserSize={eraserSize}
        isHidden={!isErasing}
      />
      <StyledButtonContainer
        style={{
          top: 0,
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <StyledButtonMedium onClick={handleBackClick}>
          <ArrowLeft width={20} />
        </StyledButtonMedium>

        <div style={{ display: 'flex', gap: 16 }}>
          <PowerUpComboButton
            disableControls={disableControls}
            isLoading={isLoading}
            userCreditData={userCreditData}
            setShowBuyCreditsModal={setShowBuyCreditsModal}
            showBuyCreditsModal={showBuyCreditsModal}
            showPowerUpModal={showPowerUpModal}
            setShowPowerUpModal={setShowPowerUpModal}
            editSession={editSessionData}
          />
          <UserAvatarsPanel />
          <InviteToSessionDialog editSessionId={editSessionId} />
        </div>
      </StyledButtonContainer>
      <StyledButtonContainer
        style={{
          top: `calc(50% - 50px)`,
          height: 100,
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Tooltip tooltip="Place frame" side="right">
          <StyledButtonMedium
            disabled={disableControls || isLoading}
            isSelected={isPlacingGenerationFrame}
            onClick={onGenerationFrameClick}
          >
            <TabAdd width={20} />
          </StyledButtonMedium>
        </Tooltip>
        <Tooltip tooltip="Erase" side="right">
          <StyledButtonMedium
            disabled={disableControls || isLoading}
            isSelected={isErasing}
            onClick={onEraserClick}
          >
            <Eraser width={20} />
          </StyledButtonMedium>
        </Tooltip>
        <Tooltip tooltip="Pan (hold space)" side="right">
          <StyledButtonMedium
            isSelected={isUsingHandTool}
            onClick={onHandToolClick}
          >
            <HandRight width={20} />
          </StyledButtonMedium>
        </Tooltip>
        <Tooltip tooltip="Select" side="right">
          <StyledButtonMedium
            isSelected={isUsingSelectTool}
            onClick={onSelectToolClick}
          >
            <Cursor width={20} />
          </StyledButtonMedium>
        </Tooltip>
        <FileUploader
          disabled={disableControls}
          setImageQueue={setImageQueue}
        />
      </StyledButtonContainer>
      <StyledButtonContainer
        style={{
          top: `calc(50% - 50px)`,
          right: 0,
          height: 100,
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Tooltip tooltip="Zoom in" side="left">
          <StyledButtonMedium
            onClick={() => {
              // Zoom in
              onZoom(true)
            }}
          >
            <Plus width={20} />
          </StyledButtonMedium>
        </Tooltip>
        <Tooltip tooltip="Zoom out" side="left">
          <StyledButtonMedium
            onClick={() => {
              // Zoom out
              onZoom(false)
            }}
          >
            <Minus width={20} />
          </StyledButtonMedium>
        </Tooltip>
        <Tooltip tooltip="Download" side="left">
          <StyledButtonMedium
            onClick={onDownloadClick}
            isSelected={isDownloadSelectionActive}
            disabled={disableControls || isLoading}
          >
            <Download width={20} />
          </StyledButtonMedium>
        </Tooltip>
      </StyledButtonContainer>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          position: 'fixed',
          bottom: 0,
          zIndex: 10,
        }}
      >
        <StyledButtonContainer
          style={{
            bottom:
              (mouseIsOverLenses || inputIsFocused || activeLens !== null) &&
              !(
                isPlacingGenerationFrame ||
                isPlacingImages ||
                isDownloadSelectionActive
              )
                ? 96
                : 0,
            transition: 'bottom .5s',
            display: 'flex',
          }}
        >
          <StyledButtonMedium
            onClick={onUndo}
            disabled={disableControls || isLoading || !canUndo || isCanvasEmpty}
            hasText
          >
            <ArrowCounterclockwise width={16} />
            Undo
          </StyledButtonMedium>
          <StyledButtonMedium
            onClick={onRedo}
            disabled={disableControls || isLoading || !canRedo}
            hasText
          >
            Redo
            <ArrowClockwise width={16} />
          </StyledButtonMedium>
        </StyledButtonContainer>

        <StyledButtonContainer
          style={{
            bottom:
              (mouseIsOverLenses || inputIsFocused || activeLens !== null) &&
              !(
                isPlacingGenerationFrame ||
                isPlacingImages ||
                isDownloadSelectionActive
              )
                ? 96
                : 0,
            right: 0,
            transition: 'bottom .5s',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Flex justifyContent="end">
            {/* eslint-disable-next-line */}
            <a
              href="https://www.producthunt.com/posts/ai-canvas?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-ai&#0045;canvas"
              target="_blank"
            >
              {/* eslint-disable-next-line */}
              <img
                src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=367468&theme=dark"
                alt="AI&#0032;Canvas - Multiplayer&#0032;image&#0032;generation&#0032;canvas | Product Hunt"
                style={{ width: '250px', height: '54px' }}
                width="250"
                height="54"
              />
            </a>
          </Flex>
          <Flex justifyContent="end" flexGap={2}>
            <StyledButtonMedium
              onClick={() => window.open('https://discord.gg/Z8hCKQjqn3')}
              hasText
            >
              Join Discord
              <DiscordAlt width={16} />
            </StyledButtonMedium>
            <GuideCanvas show={showGuide} />
          </Flex>
        </StyledButtonContainer>

        <PromptInput
          activeLensId={activeLens?.id}
          submit={promptSubmitHandler}
          hidden={
            isPlacingGenerationFrame ||
            isPlacingImages ||
            isDownloadSelectionActive
          }
          isLoading={isLoading || editSessionLoading}
          setInputIsFocused={setInputIsFocused}
          isExpanded={
            inputIsFocused || mouseIsOverLenses || activeLens !== null
          }
          disabled={hasOutput}
        />

        <Lenses
          activeLens={activeLens}
          setActiveLens={setActiveLens}
          setMouseIsOverLenses={setMouseIsOverLenses}
          show={
            !(
              isPlacingGenerationFrame ||
              isPlacingImages ||
              isDownloadSelectionActive
            ) &&
            (inputIsFocused || mouseIsOverLenses || activeLens !== null)
          }
        />
      </div>
      <BuyCreditsModal
        showBuyCreditsModal={showBuyCreditsModal}
        setShowBuyCreditsModal={setShowBuyCreditsModal}
        editSessionId={editSessionId}
        userCreditData={userCreditData}
        userCreditLoading={userCreditLoading}
      />
      <PowerUpModal
        showBuyCreditsModal={showBuyCreditsModal}
        setShowBuyCreditsModal={setShowBuyCreditsModal}
        showPowerUpModal={showPowerUpModal}
        setShowPowerUpModal={setShowPowerUpModal}
        editSessionData={editSessionData}
        editSessionLoading={editSessionLoading}
        refetchEditSession={refetchEditSession}
        userCreditData={userCreditData}
      />
    </div>
  )
}
