import styled, { css } from 'styled-components'

import magicOrgPromoImage from 'assets/img/magic-org.webp'
import QrCodeIosApp from 'assets/img/qr-ios-app.png'
import Flex from 'components/common/Flex'
import { Spacer } from 'components/common/Spacer'
import { Text } from 'components/common/Text'
import useIsMobile from 'hooks/useIsMobile'

export const QrCodeView = () => {
  const isMobile = useIsMobile()
  return (
    <StyledQrCodeViewWrapper>
      <Flex css="flex: 1 1 0;width:0">
        <Flex p={2} flexDirection="column">
          <Text size="lg" color="neutral.0">
            Install the iOS App
          </Text>
          <Spacer factor={1} />
          <Text size="sm" color="neutral.2">
            Scan the code to open the Kive app in the App Store.
          </Text>
          <Spacer factor={3} />
          <StyledQrCodeImage src={QrCodeIosApp} />
        </Flex>
      </Flex>
      {!isMobile && (
        <StyledPromoImage css="flex: 1 1 0;width:0" src={magicOrgPromoImage} />
      )}
    </StyledQrCodeViewWrapper>
  )
}

const StyledQrCodeViewWrapper = styled(Flex)(
  ({ theme }) => css`
    overflow: hidden;
    border-radius: ${theme.borderRadius.lg};
  `
)
const StyledPromoImage = styled.img`
  flex: 1 1 0;
  width: 0;
  object-fit: cover;
`

const StyledQrCodeImage = styled.img``
