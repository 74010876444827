import styled from 'styled-components'

export const Box = styled.div(({ theme }) => ({
  background: theme.colors.background[3],
  color: theme.colors.text.neutral[3],
  width: 600,
  zIndex: 1000,
  borderRadius: theme.borderRadius.default,
  overflow: 'hidden',
}))

export const Positioner = styled.div((props) => ({
  position: 'fixed',
  zIndex: 101,
  left: 'calc(50% - 250px)',
  top: 'calc(50% - 200px)',
}))

export const Mask = styled.div((props) => ({
  background: 'rgba(0,0,0,.5)',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  position: 'fixed',
  zIndex: 100,
}))

export const IconContainer = styled.div((props) => ({
  width: 20,
  height: 20,
  // borderRadius:'50%',
  // border:`1px solid ${theme.colors.background[5]}`,
  display: 'inline-block',
}))

export const Heading = styled.div(({ theme }) => ({
  marginLeft: 16,
  fontSize: theme.fontSizes.sm,
  display: 'inline-block',
}))

export const Menu = styled.div(({ theme }) => ({
  // flex:'1 1 0',
  maxHeight: 240,
  background: theme.colors.background[3],
  overflow: 'scroll',
}))

export const MenuItemInner = styled.div(({ theme, active, style }) => ({
  color: active && theme.colors.text.neutral[0],
  padding: '16px 32px',
  background: active && theme.colors.background[4],
  cursor: 'pointer',
  ...style,
}))

export const Input = styled.input(({ theme, style }) => ({
  color: theme.colors.text.neutral[0],
  fontSize: theme.fontSizes.lg,
  padding: '16px 32px',
  outline: 'none',
  border: 'none',
  background: theme.colors.background[3],
  ...style,
}))

export const ShortcutIndicator = styled.div(({ theme }) => ({
  fontSize: theme.fontSizes.xs,
  padding: '2px 8px',
  margin: 2,
  borderRadius: theme.borderRadius.default,
  background: theme.colors.background[5],
}))

export const ShortcutIndicatorContainer = styled.div((props) => ({
  float: 'right',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
}))
