import styled, { css } from 'styled-components'

export const StyledCurrentPlanWrapper = styled.div`
  ${({ theme, color }) => css`
    display: flex;
    row-gap: 0.5rem;
    flex-direction: column;
    padding-bottom: 2rem;
  `}
`
export const StyledSectionHeading = styled.h2`
  ${({ theme }) => css`
    color: ${theme.colors.accent[1]};
    font-size: ${theme.fontSizes.lg};
    font-weight: ${theme.fontWeight.bold};
    width: 100%;
    border-color: ${theme.colors.discreet};
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-radius: 0.125rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  `}
`
export const StyledSubscriptionContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    row-gap: 1rem;
    flex-direction: column;
  `}
`
export const StyledSubscriptionItemContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    row-gap: 0.25rem;
    flex-direction: column;
    align-self: start;
  `}
`
export const StyledSubscriptionName = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes.lg};
    font-weight: ${theme.fontWeight.extrabold};
    display: flex;
    gap: 0.5rem;
    align-items: center;
  `}
`
export const StyledSubscriptionPrice = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes.base};
    font-weight: ${theme.fontWeight.medium};
  `}
`
export const StyledLegacyLabel = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    background-color: ${theme.colors.background[3]};
    font-size: ${theme.fontSizes.sm};
    font-weight: ${theme.fontWeight.bold};
    border-color: ${theme.colors.discreet};
    border-style: solid;
    border-width: 1px;
    border-radius: 0.25rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  `}
`

export const StyledInvoiceInformationContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    row-gap: 0.25rem;
    flex-direction: column;
  `}
`
export const StyledInvoiceInformation = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.sm};
    font-weight: ${theme.fontWeight.normal};
  `}
`
export const StyledLatestInvoiceLinksContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 0.5rem;
    align-items: center;
  `}
`
export const StyledLatestInvoiceLink = styled.a`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.sm};
    font-weight: ${theme.fontWeight.normal};
  `}
`
export const StyledLatestInvoicePdf = styled.a`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.sm};
    font-weight: ${theme.fontWeight.normal};
  `}
`

export const StyledContainer = styled.div`
  ${({ theme }) => css`
    display: grid;
    gap: 1rem;
    @media screen and (min-width: 1800px) {
      grid-template-columns: 1fr 1fr;
      justify-content: space-between;
    }
    @media screen and (max-width: 1800px) {
      grid-template-columns: 1fr;
      justify-content: start;
    }
  `}
`
