const componentToHex = (c: number) => {
  const hex = c.toString(16)
  return hex.length === 1 ? `0${hex}` : hex
}

const rgbToHex = (r: number, g: number, b: number) => {
  return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`
}

const getRandomRgbColor = () => ({
  r: Math.floor(Math.random() * 255),
  g: Math.floor(Math.random() * 255),
  b: Math.floor(Math.random() * 255),
})

export const getRandomHexColor = () => {
  const { r, g, b } = getRandomRgbColor()
  return rgbToHex(r, g, b)
}
