import throttle from 'lodash/throttle'
import { useEffect, useState } from 'react'
import { DefaultTheme, useTheme } from 'styled-components'

import { BASE_THEME } from 'styles/theme'

const getScreenSize = (
  width: number,
  breakpoints: DefaultTheme['breakpoints']
) => {
  if (width > breakpoints['2xl'] - 1) return '2xl'
  if (width > breakpoints.xl - 1) return 'xl'
  if (width > breakpoints.lg - 1) return 'lg'
  if (width > breakpoints.md - 1) return 'md'
  if (width > breakpoints.sm - 1) return 'sm'
  if (width > breakpoints.xs - 1) return 'xs'
  return 'xs'
}

const useScreenSize = () => {
  const theme = useTheme()
  const { breakpoints } = theme

  const [screenSize, setScreenSize] = useState<
    keyof typeof BASE_THEME['breakpoints']
  >(() => getScreenSize(window.innerWidth, breakpoints))

  useEffect(() => {
    const updateScreenSize = throttle(() => {
      const newScreenSize = getScreenSize(window.innerWidth, breakpoints)
      setScreenSize(newScreenSize)
    }, 100)
    updateScreenSize()
    window.addEventListener('resize', updateScreenSize)
    return () => window.removeEventListener('resize', updateScreenSize)
  }, [breakpoints])

  return screenSize
}

export default useScreenSize
