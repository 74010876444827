/* eslint-disable @typescript-eslint/naming-convention */
import { StyledIcon } from '@styled-icons/styled-icon'
import React from 'react'
import styled, { css, useTheme } from 'styled-components'

const Container = styled.div<{ isCompact?: boolean; disabled?: boolean }>`
  ${({ theme, isCompact, disabled }) => css`
    padding: ${isCompact ? '8px 12px' : '16px'};
    display: flex;
    align-items: center;
    background: ${theme.colors.background[5]};
    color: ${theme.colors.text.neutral[2]};
    font-size: ${theme.fontSizes.sm};
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
    &:hover {
      color: ${theme.colors.text.neutral[0]};
    }
  `}
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
`

const Title = styled.p`
  margin-bottom: 0;
`

interface DropdownItemProps {
  title?: string
  Icon?: StyledIcon
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  style?: React.CSSProperties
  isCompact?: boolean
  disabled?: boolean
  info?: string
  children?: React.ReactNode
}

const DropdownItem: React.FC<DropdownItemProps> = ({
  title,
  Icon,
  onClick,
  style,
  children,
  isCompact,
  disabled,
  info,
  ...rest
}) => {
  const theme = useTheme()
  return (
    <Container
      disabled={disabled}
      onClick={disabled ? undefined : onClick}
      style={style}
      isCompact={isCompact}
      {...rest}
    >
      {Icon && (
        <IconWrapper>
          <Icon style={{ height: 16 }} />
        </IconWrapper>
      )}
      {children || (
        <TextWrapper>
          <Title>
            {title}
            {info && (
              <span
                style={{ color: theme.colors.text.discreet, marginLeft: 10 }}
              >
                {info}
              </span>
            )}
          </Title>
        </TextWrapper>
      )}
    </Container>
  )
}

export default DropdownItem
