/**
 * Report error to be logged correctly
 * @param {any} err
 */
export const reportError = (err) => {
  if (
    process.env.NODE_ENV === 'production' &&
    'Sentry' in window &&
    typeof window.Sentry.captureException === 'function'
  ) {
    window.Sentry.captureException(err)
    return
  }
  console.error(err)
}
