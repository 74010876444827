import styled from 'styled-components'

type StyledGradientButtonProps = {
  hasText?: boolean
  isSelected?: boolean
}

export const StyledGradientButton = styled.button<StyledGradientButtonProps>`
  height: 32px;
  width: ${(props) => (props.hasText ? 'auto' : '32px')};
  gap: 16px;
  cursor: pointer;
  align-items: center;
  display: flex !important;
  justify-content: center;
  color: ${({ theme, disabled, isSelected }) =>
    // eslint-disable-next-line
    disabled ? theme.colors.gray.dark[8] : isSelected ? 'black' : 'white'};
  padding: ${(props) => (props.hasText ? '8px 12px' : '6px')};
  border: 1px solid ${({ theme }) => theme.colors.gray.dark[6]};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  user-select: none;
  &:hover {
    color: ${({ theme, disabled }) =>
      disabled ? theme.colors.gray.dark[8] : 'white'};
    background: ${({ theme, disabled }) =>
      disabled ? theme.colors.background[4] : theme.colors.accent[2]};
  }
  &:active {
    color: ${({ theme, disabled }) =>
      disabled ? theme.colors.gray.dark[8] : 'black'};
    background: ${({ theme, disabled }) =>
      disabled ? theme.colors.background[4] : theme.colors.white};
  }

  border: solid 1.5px transparent;
  background-image: linear-gradient(rgb(24, 24, 24, 0), rgb(24, 24, 24, 0)),
    linear-gradient(115deg, #5930e5, #7a7aff, #ff80c1);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 0.5px 1000px 1px rgb(24, 24, 24) inset;
`
