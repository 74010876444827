import { Spacer } from 'components/common/Spacer'
import DiscoverGridItem from 'components/discover/DiscoverGridItem'
import { HighlightRow } from 'components/highlight-row/HighlightRow'
import { UserInfo } from 'features/discover/components/UserInfo'
import { useDiscoverItemsForYouQuery } from 'generated/graphql'
import { trackHomeDiscoverItemClicked } from 'helpers/tracking/tracking'
import useCurrentUser from 'hooks/useCurrentUser'
import useIsMobile from 'hooks/useIsMobile'
import { useGrowthBookFeature } from 'hooks/useSyncGrowthbook'

export const DiscoverItemsForYouRow = () => {
  const newDiscoverFeature = useGrowthBookFeature('discover-2.0')
  const isMobile = useIsMobile()

  const currentUser = useCurrentUser()
  const { data } = useDiscoverItemsForYouQuery({
    variables: { first: 10, withCreatedBy: true },
    fetchPolicy: 'cache-and-network',
    context: {
      batch: true,
    },
  })

  const items = data?.discoverItemsForYou.items || []

  if (items.length === 0) return null

  return (
    <>
      <HighlightRow
        title="Discover items for you"
        link={{
          path: newDiscoverFeature.on
            ? 'discover/for-you'
            : 'discover?mode=made-for-you',
          text: isMobile ? 'More' : 'More items for you',
        }}
        listItems={items}
        renderListItem={(workspaceItem, index) => {
          const gridItemProps = {
            height: 140,
            itemRefId: workspaceItem.id,
            item: workspaceItem,
            isMultiSelectDisabled: true,
            gridClassNameId: 'test', // TODO: Fix?
            index,
            disableGridItemOverlay: false,
            inFilterMode: false,
            currentUserId: currentUser.uid,
            onClick: trackHomeDiscoverItemClicked,
          }
          return (
            <>
              <DiscoverGridItem {...gridItemProps} />
              {workspaceItem.createdBy && (
                <UserInfo user={workspaceItem.createdBy} />
              )}
            </>
          )
        }}
      />
      <Spacer factor={5} />
    </>
  )
}
