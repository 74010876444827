import publicBoardsImage1 from 'assets/img/guides/public-boards-guide/public-boards-1.jpg'
import { Spacer } from 'components/common/Spacer'
import { Text } from 'components/common/Text'

import { StyledContentList } from './contentStyles'

export const contentPublicBoards = {
  contentHeight: 225,
  steps: [
    {
      coverUrl: publicBoardsImage1,
      content: (
        <div>
          <Text size="lg" color="neutral.0">
            Publishing a board to Discover
          </Text>
          <Spacer factor={1} />
          <Text size="sm" color="neutral.2">
            Public boards in Discover are curated visual collections that have
            been shared by members of the Kive community. To publish a board in
            Discover:
          </Text>
          <StyledContentList as="ol">
            <li>Go to projects and open a selected board</li>
            <li>Click &quot;Share&quot;</li>
            <li>
              Click the toggle next to &quot;Make public&quot; so it turns
              purple
            </li>
          </StyledContentList>
          <Text size="sm" color="neutral.2">
            Your selected board will now be visible in Discover. You can also
            find all your public boards in the 👤 Profile section.
          </Text>
        </div>
      ),
    },
  ],
}
