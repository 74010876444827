import { LiveList, LiveObject } from '@liveblocks/client'
import throttle from 'lodash/throttle'
import { MutableRefObject, useCallback, useEffect, useRef } from 'react'

import { useBatch, useHistory, useMutation } from 'config/liveblocks.config'

type UseErasingModule = {
  isErasing: boolean
  eraserSize: number
  stageRef: MutableRefObject<any>
  onNewHistory: () => void
}

export const useErasingModule = ({
  isErasing,
  eraserSize,
  stageRef,
  onNewHistory,
}: UseErasingModule) => {
  const batch = useBatch()
  const history = useHistory()

  const erasingId = useRef<any>(null)

  const addShape = useMutation(({ storage }, shapeObject) => {
    const shapeId = Date.now().toString() // FIXME: Better id?
    const shapes = storage.get('shapes')
    if (shapes && shapes instanceof LiveObject) {
      shapes.set(shapeId, new LiveObject(shapeObject))
    }
    return shapeId
  }, [])

  const updateEraserPoints = useMutation(({ storage }, shapeId, xOrYValue) => {
    const shapes = storage.get('shapes')
    if (shapes && shapes instanceof LiveObject) {
      const shape = shapes.get(shapeId)
      if (shape && shape instanceof LiveObject) {
        const points = shape.get('points')
        if (points && points instanceof LiveList) {
          points.push(xOrYValue)
        }
      }
    }
  }, [])

  const handleMouseDown = () => {
    if (stageRef.current !== null) {
      history.pause()
      const stage = stageRef.current
      const scale = stage.scaleX()
      const { x: pointerX, y: pointerY } = stage.getPointerPosition()
      const pos = {
        x: (pointerX - stage.x()) / scale,
        y: (pointerY - stage.y()) / scale,
      }
      const shapeId = addShape({
        points: new LiveList([pos.x, pos.y]),
        type: 'eraser',
        order: Math.floor(new Date().getTime() / 1000),
        size: eraserSize,
      })
      erasingId.current = shapeId
    }
  }

  const handleMouseMove = () => {
    if (!erasingId.current) {
      return
    }
    if (stageRef.current !== null) {
      const stage = stageRef.current
      const scale = stage.scaleX()
      const { x: pointerX, y: pointerY } = stage.getPointerPosition()
      const point = {
        x: (pointerX - stage.x()) / scale,
        y: (pointerY - stage.y()) / scale,
      }
      // Do we have to batch?
      batch(() => {
        updateEraserPoints(erasingId.current, point.x)
        updateEraserPoints(erasingId.current, point.y)
      })
    }
  }
  const throttleMove = useCallback(throttle(handleMouseMove, 10), [])

  const handleMouseUp = () => {
    erasingId.current = null
    history.resume()
    onNewHistory()
  }

  const updateEraserCursorPosition = ({
    mouseX,
    mouseY,
    scale,
  }: {
    mouseX: number
    mouseY: number
    scale: number
  }) => {
    // Hacky solution for now
    /** @TODO Fix any */
    const eraseCursorElement = document.querySelector<any>('.erase-cursor')
    if (!eraseCursorElement) return null
    eraseCursorElement.style.transform = `translate3d(${
      mouseX - (eraserSize / 2) * scale
    }px, ${mouseY - (eraserSize / 2) * scale}px, 0)`
  }

  // FIXME: Clean this up some how
  useEffect(() => {
    if (!isErasing) return
    window.addEventListener('mousedown', handleMouseDown)
    window.addEventListener('mousemove', throttleMove)
    window.addEventListener('mouseup', handleMouseUp)
    return () => {
      window.removeEventListener('mousedown', handleMouseDown)
      window.removeEventListener('mousemove', throttleMove)
      window.removeEventListener('mouseup', handleMouseUp)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErasing, eraserSize])

  return { updateEraserCursorPosition }
}
