import React, { useEffect, useMemo, useState } from 'react'

import useTrailingValue from '../../hooks/useTrailingValue'
import { HoverReplace } from '../common/HoverReplace'
import { ItemAction, Loading } from './UploadListingStyles'

const FADE_DURATION = 200

interface Props {
  bytesTransferred: number
  totalUploadSize: number
  dismiss: () => void
}

const UploadProgress: React.FC<Props> = ({
  bytesTransferred,
  totalUploadSize,
  dismiss,
}) => {
  const [progressDisplay, setProgressDisplay] = useState('percentage')
  const trailingProgress = useTrailingValue(progressDisplay, FADE_DURATION)

  // Alternate between percentage and file completions
  useEffect(() => {
    const progressInterval = setInterval(() => {
      setProgressDisplay((prev) =>
        prev === 'percentage' ? 'MB' : 'percentage'
      )
    }, 1500)
    return () => clearInterval(progressInterval)
  }, [])
  const progress = useMemo(() => {
    switch (trailingProgress) {
      case 'percentage': {
        return `${Math.round((bytesTransferred / totalUploadSize) * 100)}%`
      }
      case 'MB': {
        return totalUploadSize > 1000000000
          ? `${Math.round(bytesTransferred / 100000000) / 10}/${
              Math.ceil(totalUploadSize / 100000000) / 10
            } GB`
          : `${Math.round(bytesTransferred / 1000000)}/${Math.ceil(
              totalUploadSize / 1000000
            )} MB`
      }
      default: {
        return ''
      }
    }
  }, [trailingProgress, bytesTransferred, totalUploadSize])

  return (
    <>
      <Loading />
      <div>Upload in progress</div>
      <HoverReplace
        style={{
          flex: 1,
          textAlign: 'right',
        }}
        onHover={
          <span className="hover-show">
            <ItemAction onClick={dismiss}>Hide</ItemAction>
          </span>
        }
      >
        <span className="hover-hide">
          <span
            style={{
              color: 'white',
              opacity: progressDisplay === trailingProgress ? 1 : 0,
              transition: `opacity ${FADE_DURATION}ms`,
            }}
          >
            {progress}
          </span>
        </span>
      </HoverReplace>
    </>
  )
}

export default UploadProgress
