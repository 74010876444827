import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import styled, { css, keyframes } from 'styled-components'

export const TooltipProvider = TooltipPrimitive.Provider

export const Tooltip = ({
  children,
  tooltip,
  side = 'top',
}: {
  children: React.ReactNode
  tooltip: string
  side: 'top' | 'right' | 'bottom' | 'left'
}) => {
  return (
    <TooltipPrimitive.Root>
      <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
      <TooltipPrimitive.Portal>
        <StyledTooltipContent sideOffset={4} side={side}>
          {tooltip}
          <StyledTooltipArrow />
        </StyledTooltipContent>
      </TooltipPrimitive.Portal>
    </TooltipPrimitive.Root>
  )
}

const StyledTooltipArrow = styled(TooltipPrimitive.Arrow)`
  ${({ theme }) => css`
    fill: ${theme.colors.background[4]};
  `}
`
const StyledSlideUpAndFade = keyframes`
  0% { opacity: 0; transform: translateY(2px); }
  100% { opacity: 1; transform: translateY(0); }
`

const StyledSlideRightAndFade = keyframes`
  0% { opacity: 0; transform: translateX(-2px); }
  100% { opacity: 1; transform: translateX(0); }
`

const StyledSlideDownAndFade = keyframes`
  0% { opacity: 0; transform: translateY(-2px); }
  100% { opacity: 1; transform: translateY(0); }
`

const StyledSlideLeftAndFade = keyframes`
  0% { opacity: 0; transform: translateX(2px); }
  100% { opacity: 1; transform: translateX(0); }
`

const StyledTooltipContent = styled(TooltipPrimitive.Content)`
  ${({ theme }) => css`
    background-color: ${theme.colors.background[4]};
    border-radius: 4px;
    box-shadow: ${theme.shadow.minimal};
    color: ${theme.colors.gray.light[0]};
    font-size: ${theme.fontSizes.xs};
    padding: 4px 8px;
    user-select: none;
    @media (prefers-reduced-motion: no-preference) {
      animation-duration: 400ms;
      animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
      will-change: transform, opacity;
      &[data-state='delayed-open'] {
        &[data-side='top'] {
          animation-name: ${StyledSlideDownAndFade};
        }
        &[data-side='right'] {
          animation-name: ${StyledSlideLeftAndFade};
        }
        &[data-side='bottom'] {
          animation-name: ${StyledSlideUpAndFade};
        }
        &[data-side='left'] {
          animation-name: ${StyledSlideRightAndFade};
        }
      }
    }
  `}
`
