import { ArrowRightShort } from '@styled-icons/bootstrap'

import { LoaderIcon } from '../Loading'
import {
  PaginationContainer,
  PaginationItem,
  StyledButton,
} from './SetupStyles'

const Pagination = ({ activeIndex, length, delay }) => (
  <PaginationContainer>
    <PaginationItem activeIndex={activeIndex} length={length} delay={delay} />
  </PaginationContainer>
)

const Icon = ({ icon }) => {
  switch (icon) {
    case 'loading':
      return <LoaderIcon />
    case 'next':
      return <ArrowRightShort />
    default:
      return icon || null
  }
}

const IconWrapper = ({ icon, marginLeft = 8 }) => (
  <div
    style={{
      width: 30,
      display: icon ? 'inline-block' : 'none',
      marginLeft,
    }}
  >
    <Icon icon={icon} />
  </div>
)

const Button = ({ children, ...props }) => (
  <StyledButton {...props}>
    {children}
    <IconWrapper icon={props.icon} />
  </StyledButton>
)

export { Pagination, Button, IconWrapper }
