import { FC, useState } from 'react'

import callCloudFunction from '../../../../helpers/callCloudFunction'
import Button from '../../../common/Button'
import Spinner from '../../../common/Spinner'
import useToastMessages from '../../../toast/useToastMessages'
import { StyledInfo } from './ApplyCreditStyles'

type ApplyCreditProps = {
  currentCredit: number
  workspaceId: string
}

export const ApplyCredit: FC<ApplyCreditProps> = ({
  currentCredit,
  workspaceId,
}) => {
  const [applyingCredit, setApplyingCredit] = useState(false)
  const { reportSuccess, reportError } = useToastMessages()

  const applyCredit = async (creditToApply: number) => {
    setApplyingCredit(true)
    try {
      const { status, appliedCredit } = await callCloudFunction(
        'applyCreditBalanceToSubscription',
        {
          workspaceId,
          credit: creditToApply,
        }
      )
      if (status === 'WORKSPACE_CREDIT_APPLIED_LIMIT_REACHED') {
        reportError('This workspace has already applied $200 credit')
      }
      if (status === 'SUCCESS') {
        if (appliedCredit !== creditToApply) {
          const userCreditLeft = creditToApply - appliedCredit
          reportSuccess(
            `Workspace reached limit. $${appliedCredit} applied. $${userCreditLeft} left to apply to other workspaces`
          )
        } else {
          reportSuccess('Credit was applied to workspace!')
        }
        /* await updateBillingInfo() */
      }
    } catch (error) {
      reportError(`We couldn't apply credit to the workspace, please try again`)
    }
    setApplyingCredit(false)
  }

  if (!currentCredit) return null
  return (
    <StyledInfo
      title="Credit"
      content={`Apply $${currentCredit} Kive credit on upcoming workspace invoices`}
      action={
        <Button
          isCompact
          variant="primary"
          onClick={() => applyCredit(currentCredit)}
          disabled={applyingCredit}
        >
          {applyingCredit ? <Spinner size={14} /> : 'Apply'}
        </Button>
      }
    />
  )
}
