import { ApolloError } from '@apollo/client'

export const getIsForbiddenError = (error: ApolloError) => {
  return getGraphqlErrorCode(error) === 'FORBIDDEN'
}

export const getGraphqlErrorCode = (error: ApolloError): string => {
  return (error.graphQLErrors?.[0]?.extensions?.code || '') as string
}

export const getCustomErrorStatus = (error: ApolloError) => {
  return error.graphQLErrors?.[0]?.extensions?.status
}
