import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useFirestore } from 'react-redux-firebase'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'

import Loading from 'components/Loading'
import { reportError } from 'helpers/logging'
import useProjectPermissions from 'hooks/useProjectPermissions'
import useWorkspacePermissions from 'hooks/useWorkspacePermissions'
import { RootState } from 'store'
import { DBBoard, DBProject, DBWorkspace } from 'types/db'

// OBS: This component only exist because we want the user the navigate to the project board if permissions allow.
// Else we navigate the user to the public board

const OpenFavoriteBoard = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { boardId } = useParams<{
    boardId: string
  }>()
  const firestore = useFirestore()

  const globalBoard = useSelector(
    (state: RootState) => state.firestore.data[`favoriteBoard_${boardId}`]
  )

  const [project, setProject] = useState<DBProject & { id: string }>()
  const [workspace, setWorkspace] = useState<DBWorkspace>()

  const unmounted = useRef(false)

  useEffect(
    () => () => {
      unmounted.current = true
    },
    []
  )

  const goToPublicBoard = () => {
    // we ignore this action if component is already unmounted
    if (unmounted.current) return
    navigate(`/b/${boardId}`, { replace: true })
  }

  const goBack = () => {
    // we ignore this action if component is already unmounted
    if (unmounted.current) return

    // go to previous path if possible, else go to root
    if (location.key) {
      return navigate(-1)
    }
    return navigate(`/`, { replace: true })
  }

  const getBoardData = async () => {
    // if we are coming from "the app" we already have the board in redux
    if (globalBoard) return globalBoard

    try {
      const doc = await firestore.collection('boards').doc(boardId).get()
      return doc.data() as DBBoard
    } catch (error) {
      if (error.code !== 'permission-denied') {
        reportError(error)
      }
      return null
    }
  }

  const getProjectData = async (projectId: string) => {
    try {
      const doc = await firestore.collection('projects').doc(projectId).get()
      const projectData = doc.data() as DBProject
      return { ...projectData, id: doc.id } // we add the id as it is needed to build the redirect url
    } catch (error) {
      if (error.code !== 'permission-denied') {
        reportError(error)
      }
      return null
    }
  }

  const getWorkspaceData = async (workspaceId: string) => {
    try {
      const doc = await firestore
        .collection('workspaces')
        .doc(workspaceId)
        .get()
      return doc.data() as DBWorkspace
    } catch (error) {
      if (error.code !== 'permission-denied') {
        reportError(error)
      }
      return null
    }
  }

  useEffect(() => {
    const go = async () => {
      const boardData = await getBoardData()
      if (!boardData) {
        return goBack()
      }
      const [projectRes, workspaceRes] = await Promise.all([
        getProjectData(boardData.projectId),
        getWorkspaceData(boardData.workspaceId),
      ])

      if (!projectRes || !workspaceRes) {
        return goToPublicBoard()
      }
      setProject(projectRes)
      setWorkspace(workspaceRes)
    }
    go()
  }, [])

  const { VIEW_PROJECT: allowedToViewProject } = useProjectPermissions(
    project,
    ['VIEW_PROJECT']
  )
  const { VIEW_WORKSPACE: allowedToViewWorkspace } = useWorkspacePermissions(
    ['VIEW_WORKSPACE'],
    workspace
  )

  if (workspace && allowedToViewWorkspace && project && allowedToViewProject) {
    return (
      <Navigate replace to={`/${workspace.url}/p/${project.id}/${boardId}`} />
    )
  }

  return <Loading />
}

export default OpenFavoriteBoard
