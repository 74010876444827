import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'

import { RootState } from 'store'
import { DBUser } from 'types/db'

const useGetUserByEmail = (email?: string) => {
  useFirestoreConnect(
    email
      ? [
          {
            collection: 'users',
            where: ['email', '==', email],
            limit: 1,
            storeAs: `usersByEmail_${email}`,
          },
        ]
      : []
  )

  const user: DBUser = useSelector((state: RootState) =>
    email
      ? Object.values(
          state.firestore.data?.[`usersByEmail_${email}`] || ({} as any)
        )[0] ?? {}
      : ({} as any)
  )

  return user
}

export default useGetUserByEmail
