import { Search } from '@styled-icons/bootstrap/Search'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled, { css } from 'styled-components'

import Flex from 'components/common/Flex'
import IconButton from 'components/common/IconButton'
import Modal from 'components/common/Modal'
import { Spacer } from 'components/common/Spacer'
import { Text } from 'components/common/Text'
import { Tooltip } from 'components/common/Tooltip/Tooltip'
import SelectBoardAndProject from 'components/selectBoardAndProject/SelectBoardAndProject'
import { useGetLastUpdatedWorkspaceBoards } from 'hooks/useLastUpdatedBoards'

interface RecentBoardsProps {
  workspaceUrl: string
}

export const RecentBoards: React.FC<RecentBoardsProps> = ({ workspaceUrl }) => {
  const navigate = useNavigate()
  const lastEditedBoards = useGetLastUpdatedWorkspaceBoards({
    accessLevel: 'reader',
  })

  const [shouldShowSearchBoards, setShouldShowSearchBoards] = useState(false)

  if (lastEditedBoards.length === 0) return null
  return (
    <>
      <Flex alignItems="center">
        <Text size="lg" bold color="neutral.0">
          Recent boards
        </Text>
        <Spacer factor={2} axis="x" />
        <Tooltip title="Search boards">
          <IconButton
            Icon={Search}
            variant="squared"
            size={32}
            onClick={() => setShouldShowSearchBoards(true)}
          />
        </Tooltip>
      </Flex>
      <Spacer factor={2} />
      <Flex flexWrap="wrap" flexGap={1}>
        {lastEditedBoards.slice(0, 5).map((board) => {
          return (
            <BoardCard
              board={board}
              workspaceUrl={workspaceUrl}
              key={board.id}
            />
          )
        })}
      </Flex>
      <Modal isOpen={shouldShowSearchBoards}>
        <StyledSearchBoardWrapper>
          <SelectBoardAndProject
            headline="Jump to board"
            selectType="SELECT_BOARD"
            onSelectBoard={(data) =>
              navigate(`/${workspaceUrl}/p/${data.projectId}/${data.boardId}`)
            }
            onMenuClose={() => setShouldShowSearchBoards(false)}
            isCreateNewBoardHidden
            boardAccessLevel="reader"
          />
        </StyledSearchBoardWrapper>
      </Modal>
    </>
  )
}

interface BoardCardProps {
  board: any
  workspaceUrl: string
}

const BoardCard: React.FC<BoardCardProps> = ({ board, workspaceUrl }) => {
  return (
    <StyledLink to={`/${workspaceUrl}/p/${board.projectId}/${board.id}`}>
      <StyledCard alignItems="center">
        <StyledTitle>
          <StyledEmoji alignItems="center">{board.iconCode}</StyledEmoji>
          <Text size="sm" ellipsis>
            {board.title}
          </Text>
        </StyledTitle>
        <StyledCount>
          <StyledTag>
            {board.savedItemCount ? board.savedItemCount : 0}
          </StyledTag>
        </StyledCount>
      </StyledCard>
    </StyledLink>
  )
}

const StyledLink = styled(Link)`
  max-width: 260px;
  min-width: 200px;
  width: calc(20% - 8px);
`

const StyledSearchBoardWrapper = styled.div`
  width: 300px;
  height: 400px;
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  overflow: hidden;
`

const StyledCard = styled(Flex)`
  ${({ theme }) => css`
    cursor: pointer;
    padding: 12px;
    height: 52px;
    border-radius: ${theme.borderRadius.default};
    transition: border-color 0.15s ease;
    border: ${theme.border.thin} ${theme.colors.background[4]};
    background-color: ${theme.colors.background[2]};
    color: ${theme.colors.text.neutral[2]};
    :hover {
      color: ${theme.colors.text.neutral[0]};
      border: ${theme.border.thin} ${theme.colors.accent[2]};
    }
  `}
`
const StyledEmoji = styled(Flex)`
  font-size: 1rem;
  margin-right: 10px;
`
const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  overflow-x: hidden;
`
const StyledCount = styled.div`
  justify-self: right;
`
const StyledTag = styled.div`
  ${({ theme }) => css`
    padding: 2px 10px;
    background: ${theme.colors.background[5]};
    border-radius: ${theme.borderRadius.default};
  `}
`
