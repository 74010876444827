import { Close } from '@styled-icons/material-rounded/Close'
import React from 'react'
import { useSelector } from 'react-redux'

import { ItemData } from 'components/item/hooks/useGetItemData'
import { RootState } from 'store'

import { capitalizeFirstLetter } from '../../helpers/utilityFunctions'
import { IconButton, Tag, TagDescription } from './ItemTagStyles'

type TagProps = ItemData['tags'][0]

interface ItemTagProps {
  tag: TagProps
  deleteTag?: (tag: TagProps) => void
  isEditable?: boolean
}

const ItemTag: React.FC<ItemTagProps> = ({
  tag,
  deleteTag,
  isEditable = true,
}) => {
  const { description } = tag
  const isLightMode = useSelector(
    (state: RootState) => state.content.theme === 'lightTheme'
  )

  function handleClickDelete(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    deleteTag?.(tag)
    e.stopPropagation()
    e.preventDefault()
  }
  return (
    <Tag isLightMode={isLightMode}>
      <TagDescription>{capitalizeFirstLetter(description)}</TagDescription>
      {deleteTag && isEditable && (
        <IconButton onClick={handleClickDelete}>
          <Close style={{ height: 16 }} />
        </IconButton>
      )}
    </Tag>
  )
}

export default ItemTag
