import React, { Component } from 'react'
import styled from 'styled-components'

import Emoji from './Emoji'
import EmojiPicker from './EmojiPicker'

const EditableCell = styled.div(({ theme, disabled }) => ({
  borderRadius: theme.borderRadius.default,
  padding: 6,
  cursor: 'pointer',
  ':hover': {
    backgroundColor: !disabled && theme.colors.accent[2],
  },
}))

class EmojiEditable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      emojiPickerVisible: false,
    }
  }

  toggleVisibility = (event) => {
    this.setState((prev) => ({ emojiPickerVisible: !prev.emojiPickerVisible }))
  }

  render() {
    return (
      <EditableCell disabled={this.props.disabled}>
        {this.state.emojiPickerVisible && (
          <EmojiPicker
            shouldShowActions={this.props.shouldShowActions}
            handleClose={this.toggleVisibility}
            handleSelectEmoji={this.props.handleUpdateEmoji}
          />
        )}
        <Emoji
          {...this.props}
          onClick={this.props.disabled ? undefined : this.toggleVisibility}
        />
      </EditableCell>
    )
  }
}

export default EmojiEditable
