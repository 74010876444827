// import canvasImage2 from 'assets/img/guides/canvas/erase.jpg'
import canvasVideo5 from 'assets/video/canvas/Build.mp4'
import canvasVideo2 from 'assets/video/canvas/Erase.mp4'
import canvasVideo3 from 'assets/video/canvas/Generate.mp4'
import canvasVideo7 from 'assets/video/canvas/Multiplayer.mp4'
import canvasVideo6 from 'assets/video/canvas/Play.mp4'
import canvasVideo4 from 'assets/video/canvas/Select.mp4'
import canvasVideo1 from 'assets/video/canvas/Upload.mp4'
import { Spacer } from 'components/common/Spacer'
import { Text } from 'components/common/Text'

import { StyledContentList } from './contentStyles'

export const contentCanvas = {
  contentHeight: 160,
  steps: [
    {
      coverUrl: canvasVideo6,
      content: (
        <div>
          <Text size="lg" color="neutral.0">
            AI Canvas in 2 minutes ⏱
          </Text>
          <Spacer factor={1} />
          <Text size="sm" color="neutral.2">
            Follow this quick guide to learn the basics and get started with AI
            Creation.
          </Text>
        </div>
      ),
    },
    {
      coverUrl: canvasVideo1,
      content: (
        <div>
          <Text size="lg" color="neutral.0">
            Start by adding an image 🖼
          </Text>
          <Spacer factor={1} />
          <Text size="sm" color="neutral.2">
            Upload an image and resize it to the size you want.
          </Text>
        </div>
      ),
    },
    {
      coverUrl: canvasVideo2,
      content: (
        <div>
          <Text size="lg" color="neutral.0">
            Erase and place frame ✏️
          </Text>
          <Spacer factor={1} />
          <Text size="sm" color="neutral.2">
            Use the eraser tool to remove the parts of the image you want to
            replace with generated content. Then place the frame over the area
            you want to create in.
          </Text>
          <br />
        </div>
      ),
    },
    {
      coverUrl: canvasVideo3,
      content: (
        <div>
          <Text size="lg" color="neutral.0">
            Using prompts and filters 💬
          </Text>
          <Spacer factor={1} />
          <Text size="sm" color="neutral.2">
            Next, write a prompt – a text that describes what you want in the
            frame. Optionally, you can pick a filter which helps achieving a
            good result without writing overly complex prompts. Hit
            &quot;Generate&quot; to start AI generation.
          </Text>
        </div>
      ),
    },
    {
      coverUrl: canvasVideo4,
      content: (
        <div>
          <Text size="lg" color="neutral.0">
            Pick your favorite 🤌
          </Text>
          <Spacer factor={1} />
          <Text size="sm" color="neutral.2">
            Once generation is done, you can cycle between 4 result options.
            Choose the one you like best and click &quot;Accept&quot;.
          </Text>
        </div>
      ),
    },
    {
      coverUrl: canvasVideo5,
      content: (
        <div>
          <Text size="lg" color="neutral.0">
            Now keep building 🛠
          </Text>
          <Spacer factor={1} />
          <StyledContentList>
            <li>
              Make entirely new images by placing the frame in an empty space.
            </li>
            <li>
              Change out elements of an existing image by erasing and generating
              with the frame over the empty area.
            </li>
            <li>
              Place the frame on the edge of an existing image to expand it.
            </li>
          </StyledContentList>
        </div>
      ),
    },
    {
      coverUrl: canvasVideo7,
      content: (
        <div>
          <Text size="lg" color="neutral.0">
            Go multiplayer! 👾
          </Text>
          <Spacer factor={1} />
          <Text size="sm" color="neutral.2">
            Now that you know the basics, you can invite your friends or team
            mates! Create together, brainstorm ideas, or start epic design
            battles. A room can hold up to 20 people, so click &quot;Add
            people&quot; and start inviting your crew in.
          </Text>
        </div>
      ),
    },
  ],
}
