import { Check } from '@styled-icons/boxicons-regular/Check'
import { Refresh } from '@styled-icons/material/Refresh'
import { motion } from 'framer-motion'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import { Margin } from 'components/common/Margin'
import Spinner from 'components/common/Spinner'
import { Text } from 'components/common/Text'
import { useSuggestedFrameExtractionsQuery } from 'generated/graphql'
import { shuffleArray } from 'helpers/shuffleArray'
import useIsMobile from 'hooks/useIsMobile'
import { RootState } from 'store'

const container = {
  hidden: { opacity: 0, y: 10 },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      staggerChildren: 0.08,
    },
  },
}

const item = {
  hidden: { opacity: 0, y: 10 },
  show: { opacity: 1, y: 0 },
}

const suggestionsCountToDisplay = 6

interface FrameExtractionSuggestionsProps {
  onSuggestionClick: (link: string) => boolean
}

const FrameExtractionSuggestions = ({
  onSuggestionClick,
}: FrameExtractionSuggestionsProps) => {
  const isMobile = useIsMobile()
  const [clickedSuggestions, setClickedSuggestions] = useState<string[]>([])
  const activeWorkspaceId = useSelector(
    (state: RootState) => state.content.activeWorkspace!.id
  )

  const frameExtractionsQuery = useSuggestedFrameExtractionsQuery({
    variables: { workspaceId: activeWorkspaceId },
  })

  const handleClick = (link: string) => {
    const isOk = onSuggestionClick(link)
    if (!isOk) return
    setClickedSuggestions((prev) => [...prev, link])
    setTimeout(() => {
      frameExtractionsQuery.updateQuery((prev) => {
        return {
          suggestedFrameExtractions: prev.suggestedFrameExtractions.filter(
            (suggestion) => suggestion.link !== link
          ),
        }
      })
    }, 700)
  }

  const handleRefreshSuggestions = () => {
    frameExtractionsQuery.updateQuery((prev) => {
      // we shuffle the suggestions and make sure to put the currently showing suggestions last
      const shuffled = [
        ...shuffleArray(
          prev.suggestedFrameExtractions.slice(suggestionsCountToDisplay)
        ),
        ...prev.suggestedFrameExtractions.slice(0, suggestionsCountToDisplay),
      ]
      return {
        suggestedFrameExtractions: shuffled,
      }
    })
  }

  if (frameExtractionsQuery.loading) {
    return <Spinner css="height:24px" />
  }

  const suggestions = frameExtractionsQuery.data?.suggestedFrameExtractions

  if (!suggestions || suggestions.length === 0) return null

  return (
    <>
      <motion.div layout>
        <div css="display:flex">
          <Text size="sm" color="neutral.2">
            Suggestions from Vimeo
          </Text>
          {suggestions.length > suggestionsCountToDisplay && (
            <RefreshIcon onClick={handleRefreshSuggestions}>
              Refresh
            </RefreshIcon>
          )}
        </div>

        <Margin y={12} />
      </motion.div>
      <SuggestionsList
        variants={container}
        initial="hidden"
        animate="show"
        layout
      >
        {suggestions?.slice(0, suggestionsCountToDisplay).map((suggestion) => {
          const isClicked = clickedSuggestions.includes(suggestion.link)
          return (
            <StyledSuggestion layout variants={item} key={suggestion.link}>
              <ThumbContainer
                onClick={() =>
                  isClicked ? undefined : handleClick(suggestion.link)
                }
              >
                <Thumb
                  style={{
                    backgroundImage: `url(${suggestion.thumbnail})`,
                    height: 100,
                  }}
                />
                <HoverOverlay isMobile={isMobile}>
                  <Text size="sm" color="neutral.0">
                    {isClicked ? <CheckMark /> : 'Import'}
                  </Text>
                </HoverOverlay>
              </ThumbContainer>

              <Margin y={8} />
              <Text size="xs" color="neutral.1" ellipsis>
                <VideoHeadline
                  target="_blank"
                  rel="noreferrer"
                  href={suggestion.link}
                >
                  {suggestion.name}
                </VideoHeadline>
              </Text>
              <Text size="xs" color="neutral.3" ellipsis>
                From{' '}
                <VideoCreator
                  target="_blank"
                  rel="noreferrer"
                  href={suggestion.creator.link}
                >
                  {suggestion.creator.name}
                </VideoCreator>
              </Text>
            </StyledSuggestion>
          )
        })}
      </SuggestionsList>
    </>
  )
}

export default FrameExtractionSuggestions

const SuggestionsList = styled(motion.div)`
  ${({ theme }) => css`
    columns: 3;
    column-gap: 12px;
    @media screen and (max-width: ${theme.breakpoints.md}px) {
      columns: 2;
    }
  `}
`

const StyledSuggestion = styled(motion.div)`
  margin-bottom: 12px;
`

const Thumb = styled.div`
  ${({ theme }) => css`
    border-radius: ${theme.borderRadius.default};
    overflow: hidden;
    background-size: cover;
    flex-shrink: 0;
  `}
`

const VideoHeadline = styled.a`
  ${({ theme }) => css`
    color: ${theme.colors.text.neutral[1]};
    :hover {
      text-decoration: underline;
    }
  `}
`

const VideoCreator = styled.a`
  ${({ theme }) => css`
    color: ${theme.colors.text.neutral[3]};
    :hover {
      text-decoration: underline;
    }
  `}
`

const HoverOverlay = styled.div<{ isMobile: boolean }>`
  ${({ isMobile }) => css`
    opacity: ${isMobile ? 1 : 0};
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00000075;
    border-radius: 6px;
    transition: 0.2s opacity ease;
    ${!isMobile &&
    css`
      backdrop-filter: blur(4px);
    `}
  `}
`

const ThumbContainer = styled.div`
  position: relative;
  cursor: pointer;
  :hover ${HoverOverlay} {
    opacity: 1;
  }
`

const CheckMark = styled(Check)`
  height: 28px;
  color: ${({ theme }) => theme.colors.accent[3]};
`

const RefreshIcon = styled(Refresh)`
  ${({ theme }) => css`
    height: 25px;
    color: white;
    margin-left: 8px;
    cursor: pointer;
    background: ${theme.colors.background[5]};
    border-radius: ${theme.borderRadius.default};
    padding: 4px;
    &:hover {
      background: ${theme.colors.accent[2]};
    }
  `}
`
