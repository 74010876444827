import { ChevronDown } from '@styled-icons/bootstrap/ChevronDown'
import { Check } from '@styled-icons/boxicons-regular/Check'
import { BookmarkBorder } from '@styled-icons/material/BookmarkBorder'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import Button from 'components/common/Button'
import Flex from 'components/common/Flex'
import { Spacer } from 'components/common/Spacer'
import { Text } from 'components/common/Text'
import { Tooltip } from 'components/common/Tooltip/Tooltip'
import SelectBoardAndProject from 'components/selectBoardAndProject/SelectBoardAndProject'
import BlockKeyDownPropagation from 'helpers/BlockKeyDownPropagation'
import { RootState } from 'store'

import useSaveItemFromDiscover from './useSaveItemFromDiscover'

type DiscoverSaveItemProps = {
  itemId: string
  size?: 'small' | 'large'
  isBoardItem?: boolean
}

export const DiscoverSaveItem = (props: DiscoverSaveItemProps) => {
  const [shouldDisplaySaveToBoard, setShouldDisplaySaveToBoard] =
    useState(false)

  const activeWorkspace = useSelector(
    (store: RootState) => store.content.activeWorkspace!
  )

  const { saveStatus, handleSaveToWorkspace, handleSaveToBoard } =
    useSaveItemFromDiscover({
      itemId: props.itemId,
      activeWorkspaceId: activeWorkspace.id,
      activeWorkspaceUrl: activeWorkspace.url,
      isBoardItem: props.isBoardItem,
    })

  const openSaveToBoardPopup = () => {
    setShouldDisplaySaveToBoard(true)
  }

  const hideSaveToBoardPopup = () => {
    setShouldDisplaySaveToBoard(false)
  }

  const isSavedToWorkspace = ['SAVED', 'EXISTS'].includes(saveStatus)

  return (
    <Flex p={0.5}>
      <Tooltip
        distance={7}
        delay={[800, 0]}
        isDisabled={isSavedToWorkspace}
        title="Save to workspace"
        shouldHardDisable
      >
        <StyledSaveToWorkspaceButton
          isCompact
          onClick={isSavedToWorkspace ? undefined : handleSaveToWorkspace}
          style={{ pointerEvents: 'auto' }}
          variant="darkSecondary"
          isLarge={props.size === 'large'}
        >
          {isSavedToWorkspace ? (
            <>
              <StyledCheckMark
                style={{ height: props.size === 'large' ? 22 : 16 }}
              />
              <Spacer axis="x" factor={0.5} />
              <Text size={props.size === 'large' ? 'sm' : 'xs'}>Saved</Text>
            </>
          ) : (
            <>
              <BookmarkBorder
                style={{ height: props.size === 'large' ? 20 : 14 }}
              />
              <Spacer axis="x" factor={0.5} />
              <Text size={props.size === 'large' ? 'sm' : 'xs'}>
                {props.size === 'large' ? 'Save to workspace' : 'Save'}
              </Text>
            </>
          )}
        </StyledSaveToWorkspaceButton>
      </Tooltip>

      <StyledLineBreakVertical />
      <Tooltip
        theme="raw"
        interactive
        trigger="manual"
        arrow={false}
        distance={0}
        position="bottom-end"
        open={shouldDisplaySaveToBoard}
        popperOptions={{
          modifiers: {
            preventOverflow: {
              boundariesElement: 'window',
            },
          },
        }}
        html={
          <StyledSelectBoardWrapper>
            <SelectBoardAndProject
              headline="Save to board"
              onMenuClose={hideSaveToBoardPopup}
              selectType="SELECT_BOARD"
              boardAccessLevel="writer"
              onSelectBoard={(data) => {
                hideSaveToBoardPopup()
                handleSaveToBoard(data)
              }}
            />
          </StyledSelectBoardWrapper>
        }
      >
        <Tooltip
          distance={7}
          delay={[800, 0]}
          title="Save to board"
          isDisabled={shouldDisplaySaveToBoard}
          shouldHardDisable
        >
          <StyledOpenSaveToBoardButton
            Icon={ChevronDown}
            isCompact
            size={12}
            onClick={openSaveToBoardPopup}
            style={{ pointerEvents: 'auto' }}
            variant="darkSecondary"
            isLarge={props.size === 'large'}
          />
        </Tooltip>
      </Tooltip>
      {/* We add an unvisible overlay to prevent SelectBoardAndProject popup to close itself */}

      {shouldDisplaySaveToBoard && (
        <BlockKeyDownPropagation>
          <StyledBlockGridClickOverlay />
        </BlockKeyDownPropagation>
      )}
    </Flex>
  )
}

const StyledCheckMark = styled(Check)`
  color: ${({ theme }) => theme.colors.accent[3]};
  margin-bottom: 2px;
`

const StyledSaveToWorkspaceButton = styled(Button)<{ isLarge: boolean }>(
  ({ isLarge }) => css`
    padding: 0px ${isLarge ? '12px' : '8px'};
    height: ${isLarge ? '44px' : '28px'};
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    margin: 0;
  `
)

const StyledOpenSaveToBoardButton = styled(Button)<{ isLarge: boolean }>(
  ({ isLarge }) => css`
    padding: 0px ${isLarge ? '12px' : '8px'};
    height: ${isLarge ? '44px' : '28px'};
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin: 0;
  `
)

const StyledLineBreakVertical = styled.span`
  border-right: 1px solid ${({ theme }) => theme.colors.gray.dark[7]};
`

const StyledSelectBoardWrapper = styled.div`
  width: 260px;
`

const StyledBlockGridClickOverlay = styled.div`
  width: 100vw;
  height: 100%;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  bottom: 0;
  pointer-events: auto;
  background: #00000099;
`
