import styled from 'styled-components'
import { StringParam, useQueryParams, withDefault } from 'use-query-params'

import { capitalizeFirstLetter } from 'helpers/utilityFunctions'

const StyledTabHeader = styled.li<{ active: boolean }>`
  display: inline-block;
  list-style: none;
  margin-right: 32px;
  color: ${({ theme }) => theme.colors.text.discreet};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  cursor: pointer;
  ${({ active, theme }) =>
    active &&
    `
    border-bottom: solid ${theme.colors.white};
    border-width: 1px;
    color: ${theme.colors.text.neutral[0]}
  `}
`

const StyledTabsHeader = styled.ol`
  padding-left: 0;
  border-bottom: solid ${({ theme }) => theme.colors.discreet};
  border-width: 1px;
  padding: 0px 32px 16px 32px;
  margin: 0px -32px 16px -32px;
`

const TabHeader = ({
  activeTab,
  label,
  onClick,
}: {
  activeTab: string
  label: string
  onClick: (label: string) => void
}) => {
  return (
    <StyledTabHeader
      active={activeTab === label}
      onClick={() => onClick(label)}
    >
      {capitalizeFirstLetter(label)}
    </StyledTabHeader>
  )
}

export const Tab: React.FC<{ label: string; children: React.ReactElement }> = ({
  children,
}) => {
  return children
}

export const Tabs: React.FC<{
  initalTab: string
  children: React.ReactElement[]
}> = ({ children, initalTab }) => {
  const [urlQueryParams, setQueryParams] = useQueryParams({
    item: StringParam,
    tab: withDefault(StringParam, initalTab),
  })

  const { tab: currentTab } = urlQueryParams

  const onClickTabItem = (tab: string) => {
    setQueryParams({ tab })
  }

  return (
    <>
      <StyledTabsHeader>
        {children.map(({ props }) => (
          <TabHeader
            key={props.label}
            activeTab={currentTab}
            label={props.label}
            onClick={onClickTabItem}
          />
        ))}
      </StyledTabsHeader>
      {children.find((child) => child.props.label === currentTab)}
    </>
  )
}
