import type { Item } from 'components/grid/griditem/GridItem'

import getTypeCategory from './getTypeCategory'

type GetItemMediaUrlArgs = {
  item: Item
  variant: 'large'
  mediaType: 'image' | 'gif'
}

const variantToMaxWidthMapper = {
  large: 1600,
} as const

export const getItemMediaUrl = ({
  item,
  variant,
  mediaType,
}: GetItemMediaUrlArgs) => {
  if ('isTypesenseItem' in item) {
    if (mediaType === 'gif') {
      // Typesense item payload does not include large gifs today
      return undefined
    }
    if (mediaType === 'image') {
      return item.imgUrl
    }
    return null as never
  }
  if ('__typename' in item) {
    return item.mediaVariants.find(
      (mediaVariant) =>
        mediaVariant.variant === variant &&
        getTypeCategory(mediaVariant.type) === mediaType
    )!.url
  }
  if (item.sizes) {
    const largeSizes = item.sizes.filter(
      (size) => size.maxWidth === variantToMaxWidthMapper[variant]
    )
    if (mediaType === 'gif') {
      const sizeObject = largeSizes.find(
        (largeSize) => largeSize.type === mediaType
      )
      return sizeObject?.url || sizeObject?.publicUrl
    }
    if (mediaType === 'image') {
      const sizeObject = largeSizes.find(
        (largeSize) => getTypeCategory(largeSize.type) === mediaType
      )
      return sizeObject?.url || sizeObject?.publicUrl
    }
    return null as never
  }
  if (item.versions) {
    return item.versions[`jpg-${variantToMaxWidthMapper[variant]}px`].publicUrl
  }
}
