import React, { useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { useNavigate } from 'react-router'
import { compose } from 'redux'

import Button from 'components/common/Button'
import PermissionWrapper from 'components/common/PermissionWrapper'
import useToastMessages from 'components/toast/useToastMessages'
import {
  useLeaveWorkspaceMutation,
  useSendWorkspaceInvitationMutation,
} from 'generated/graphql'
import useWorkspacePermissions from 'hooks/useWorkspacePermissions'
import { workspacesSelector } from 'store/selectors'

// If we're using db from config constants we should use the firebase and firestore from there as well.
import { db, firebase } from '../../../config/firebase'
import Dropdown from '../../common/DropdownOld'
import { Heading, SettingsContent } from '../SettingsStyles'
import useSettingsNavigation from '../useSettingsNavigation'
import InviteForm from './InviteForm'
import {
  MemberActionList,
  MemberActionListItem,
  MemberList,
  MemberListItem,
} from './MemberStyles'

export default function Members({ workspace, activePlan }) {
  const adminEmails = workspace.admins
  const memberEmails = workspace.members
  const guestEmails = workspace.guests
  const currentUserEmail = firebase.auth()?.currentUser?.email

  const [sendWorkspaceInvitation] = useSendWorkspaceInvitationMutation()

  const navigate = useNavigate()
  const { reportError } = useToastMessages()
  const [leaveWorkspaceMutation] = useLeaveWorkspaceMutation()
  const workspaces = useSelector(workspacesSelector) || {}

  const userAllowedTo = useWorkspacePermissions([
    'ADD_WORKSPACE_MEMBER',
    'DISPLAY_WORKSPACE_GUESTS',
    'EDIT_WORKSPACE_MEMBER',
  ])

  const { gotoSection } = useSettingsNavigation()

  const [openInvite, setOpenInvite] = useState(false)

  const redirectToOtherWorkspace = () => {
    const otherWorkspaceEntry = Object.entries(workspaces).find(
      ([id]) => id !== workspace.id
    )

    if (!otherWorkspaceEntry) {
      navigate(`/setup/workspace`)
      return
    }

    const [, otherWorkspace] = otherWorkspaceEntry

    navigate(`/${otherWorkspace.url}`)
  }

  const handleLeaveWorkspace = () => {
    if (adminEmails.includes(currentUserEmail) && adminEmails.length < 2) {
      return reportError(
        'You can not leave the workspace as the only admin. Assign another member to admin before leaving.'
      )
    }
    leaveWorkspaceMutation({ variables: { id: workspace.id } }).catch(() => {
      reportError('Oh no, something went wrong. Unable to leave workspace.')
    })
    redirectToOtherWorkspace()
  }

  const addMemberClick = () => {
    const memberLimit = activePlan.limits.members
    // if already hit quota for current plan
    if (
      memberLimit &&
      memberLimit <= adminEmails.length + memberEmails.length // TODO: Do we wanna add guests length here?
    ) {
      return gotoSection('plans')
    }
    setOpenInvite(true)
  }

  return (
    <SettingsContent>
      <Heading noMarginTop>Members</Heading>
      <div css="display:flex">
        {openInvite ? (
          <InviteForm
            workspace={workspace}
            close={() => setOpenInvite(false)}
            createInvitation={(email, workspaceId) =>
              sendWorkspaceInvitation({
                variables: {
                  email,
                  workspaceId,
                  inviteSource: 'workspaceSettings',
                },
              })
            }
          />
        ) : (
          <PermissionWrapper
            feature="Add members"
            isAllowed={userAllowedTo.ADD_WORKSPACE_MEMBER}
          >
            <Button
              variant="primary"
              style={{ paddingLeft: 64, paddingRight: 64 }}
              onClick={addMemberClick}
            >
              Add members
            </Button>
          </PermissionWrapper>
        )}
      </div>

      <Heading>Current Members</Heading>
      <MemberList>
        {adminEmails.map((email, index) => (
          <Member
            key={`Admin${email}`}
            workspaceId={workspace.id}
            style={index === 0 ? { paddingTop: 0 } : {}}
            email={email}
            isYou={currentUserEmail === email}
            type="Admin"
            allowedToEditMember={userAllowedTo.EDIT_WORKSPACE_MEMBER}
            onLeaveWorkspace={handleLeaveWorkspace}
          />
        ))}
        {memberEmails.map((email, index) => (
          <Member
            key={`Member${email}`}
            workspaceId={workspace.id}
            withTopDivider={index === 0}
            email={email}
            isYou={currentUserEmail === email}
            type="Member"
            allowedToEditMember={userAllowedTo.EDIT_WORKSPACE_MEMBER}
            onLeaveWorkspace={handleLeaveWorkspace}
          />
        ))}
        {guestEmails.map((email, index) =>
          userAllowedTo.DISPLAY_WORKSPACE_GUESTS ||
          currentUserEmail === email ? (
            <Member
              key={`Guest${email}`}
              workspaceId={workspace.id}
              withTopDivider={index === 0}
              email={email}
              isYou={currentUserEmail === email}
              type="Guest"
              allowedToEditMember={userAllowedTo.EDIT_WORKSPACE_MEMBER}
              onLeaveWorkspace={handleLeaveWorkspace}
            />
          ) : null
        )}
        {(workspace.pendingInvitations ?? []).map((pending) => (
          <MemberListItem
            initial={pending?.[0]}
            key={pending}
            name="Pending invitation"
            email={pending}
            buttonText="Revoke"
            allowedToEditMember={userAllowedTo.EDIT_WORKSPACE_MEMBER}
            onClick={() => revokeInvitation(pending, workspace)}
          />
        ))}
      </MemberList>
    </SettingsContent>
  )
}

const MemberRender = ({
  member,
  type,
  workspaceId,
  isYou,
  allowedToEditMember,
  onLeaveWorkspace,
  ...props
}) => {
  const { gotoSection } = useSettingsNavigation()
  const [dropDownVisible, setDropDownVisible] = useState(false)

  if (member == null) {
    return null
  }

  // TODO: Enable the code below when we are ready to show and change users to guests
  // const switchToGuest = () => {
  //   db.collection('workspaces')
  //     .doc(workspaceId)
  //     .update({
  //       ...(type === 'Member' && {
  //         members: firebase.firestore.FieldValue.arrayRemove(member.email),
  //       }),
  //       ...(type === 'Admin' && {
  //         admins: firebase.firestore.FieldValue.arrayRemove(member.email),
  //       }),
  //       guests: firebase.firestore.FieldValue.arrayUnion(member.email),
  //     })
  // }

  const switchToMember = () => {
    db.collection('workspaces')
      .doc(workspaceId)
      .update({
        ...(type === 'Guest' && {
          guests: firebase.firestore.FieldValue.arrayRemove(member.email),
        }),
        ...(type === 'Admin' && {
          admins: firebase.firestore.FieldValue.arrayRemove(member.email),
        }),
        members: firebase.firestore.FieldValue.arrayUnion(member.email),
      })
  }

  const switchToAdmin = () => {
    db.collection('workspaces')
      .doc(workspaceId)
      .update({
        ...(type === 'Member' && {
          members: firebase.firestore.FieldValue.arrayRemove(member.email),
        }),
        ...(type === 'Guest' && {
          guests: firebase.firestore.FieldValue.arrayRemove(member.email),
        }),
        admins: firebase.firestore.FieldValue.arrayUnion(member.email),
      })
  }

  const removeUserFromWorkspace = () => {
    db.collection('workspaces')
      .doc(workspaceId)
      .update({
        admins: firebase.firestore.FieldValue.arrayRemove(member.email),
        guests: firebase.firestore.FieldValue.arrayRemove(member.email),
        members: firebase.firestore.FieldValue.arrayRemove(member.email),
      })
  }

  return (
    <MemberListItem
      {...props}
      key={member.email}
      email={member.email}
      initial={member.displayName?.[0]}
      name={isYou ? 'You' : member.displayName}
      photoURL={member.photoURL}
      allowedToEditMember={isYou ? true : allowedToEditMember}
      buttonText={
        <>
          {type}
          <Dropdown
            show={dropDownVisible}
            onClose={() => setDropDownVisible(false)}
          >
            <MemberActionList>
              {isYou ? (
                <>
                  <MemberActionListItem onClick={() => gotoSection('account')}>
                    Settings
                  </MemberActionListItem>
                  <MemberActionListItem
                    // Not sure what 'irreversible' is
                    // eslint-disable-next-line jsx-a11y/aria-role
                    role="irreversible"
                    onClick={onLeaveWorkspace}
                  >
                    Leave workspace
                  </MemberActionListItem>
                </>
              ) : (
                <>
                  {allowedToEditMember ? (
                    <>
                      {/* TODO: Enable the code below when we are ready to show and change users to guests */}
                      {/* {type !== 'Guest' && (
                    <MemberActionListItem onClick={switchToGuest}>
                      Move to guests
                    </MemberActionListItem>
                  )} */}
                      {type !== 'Member' && (
                        <MemberActionListItem onClick={switchToMember}>
                          Move to members
                        </MemberActionListItem>
                      )}
                      {type !== 'Admin' && (
                        <MemberActionListItem onClick={switchToAdmin}>
                          Move to admins
                        </MemberActionListItem>
                      )}
                      <MemberActionListItem
                        // Not sure what 'irreversible' is
                        // eslint-disable-next-line jsx-a11y/aria-role
                        role="irreversible"
                        onClick={() =>
                          removeUserFromWorkspace(workspaceId, member.email)
                        }
                      >
                        Remove member
                      </MemberActionListItem>
                    </>
                  ) : null}
                </>
              )}
            </MemberActionList>
          </Dropdown>
        </>
      }
      onClick={() => setDropDownVisible(true)}
    />
  )
}

const Member = compose(
  connect((state, props) => {
    const workspaceMember =
      state.firestore.data[`workspaceMember.${props.email.replaceAll('.', '')}`]
    return {
      member: workspaceMember && Object.values(workspaceMember ?? {})?.[0],
    }
  }),
  firestoreConnect((props) =>
    props.email
      ? [
          {
            collection: 'users',
            where: [['email', '==', props.email]],
            // Cannot store value with dots in query
            storeAs: `workspaceMember.${props.email.replaceAll('.', '')}`,
          },
        ]
      : []
  )
)(MemberRender)

const revokeInvitation = async (email, workspace) => {
  const workspaceUpdates = {
    pendingInvitations: firebase.firestore.FieldValue.arrayRemove(email),
    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
  }

  const workspaceRef = db.collection('workspaces').doc(workspace.id)
  return workspaceRef.update(workspaceUpdates)
}
