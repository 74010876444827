import React, { useEffect, useRef, useState } from 'react'
import AutosizeInput from 'react-input-autosize'
import { useTheme } from 'styled-components'

import { Tooltip } from 'components/common/Tooltip/Tooltip'
import {
  WorkspaceItemViewQuery,
  useUpdateWorkspaceItemTitleMutation,
} from 'generated/graphql'
import BlockKeyDownPropagation from 'helpers/BlockKeyDownPropagation'
import { trackItemFileNameChanged } from 'helpers/tracking/tracking'

interface ItemFileNameInputProps {
  item: WorkspaceItemViewQuery['workspaceItem']
}

const ItemFileNameInput: React.FC<ItemFileNameInputProps> = ({ item }) => {
  const theme = useTheme()
  const fileNameInput = useRef<any>(null)

  const [editedFileName, setEditedFileName] = useState<string>('')
  const [isEditingFileName, setIsEditingFileName] = useState(false)
  const [updateWorkspaceItemTitle] = useUpdateWorkspaceItemTitleMutation()

  useEffect(() => {
    if (item.title) {
      setEditedFileName(item.title)
    }
  }, [item.title])

  const handleFileNameSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    fileNameInput.current?.input?.blur()
  }

  const handleFileNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setEditedFileName(e.target.value)
  }

  const handleFileNameBlur = async () => {
    setIsEditingFileName(false)
    if (editedFileName.length === 0) {
      setEditedFileName(item.title || '')
    }
    if (item.title === editedFileName) {
      return null
    }
    await updateWorkspaceItemTitle({
      variables: {
        workspaceItemId: item.id,
        title: editedFileName,
      },
    })
    trackItemFileNameChanged({ itemId: item.id })
  }

  const handleEscapeFileNameInput = (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === 'Escape') {
      fileNameInput.current.input.blur()
      setIsEditingFileName(false)
    }
  }
  return (
    <>
      {isEditingFileName && <BlockKeyDownPropagation />}
      <form
        style={{
          display: 'flex',
          alignItems: 'center',
          overflowWrap: 'break-word',
          marginRight: 'auto',
          overflow: 'hidden',
        }}
        onSubmit={handleFileNameSubmit}
        autoComplete="off"
      >
        <Tooltip title="Click to edit file name" position="bottom">
          <AutosizeInput
            onSelect={() => setIsEditingFileName(true)}
            name="form-field-name"
            placeholder="Click to edit file name"
            className="abc"
            value={editedFileName}
            onChange={handleFileNameChange}
            onKeyUp={handleEscapeFileNameInput}
            onBlur={handleFileNameBlur}
            ref={fileNameInput}
            inputStyle={{
              fontSize: theme.fontSizes.sm,
              borderRadius: theme.borderRadius.lg,
              border: 'none',
              boxShadow: 'none',
              padding: '4px 16px',
              textAlign: 'center',
              outline: 'none',
              color: theme.colors.text.neutral[0],
              background: 'none',
              overflow: 'hidden',
              flex: 1,
              textOverflow: 'ellipsis',
              opacity: 1,
              justifySelf: 'left',
              alignSelf: 'center',
            }}
          />
        </Tooltip>
      </form>
    </>
  )
}

export default ItemFileNameInput
