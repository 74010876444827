import {
  ContentActionTypes,
  ContentState,
  SET_ACTIVE_EXPORT,
  SET_ACTIVE_WORKSPACE,
  SET_FOCUSED_ITEM,
  SET_IS_FOCUSED_ITEM_SPOILER_VISIBLE,
  SET_IS_SOCIAL_ASSET_MODAL_OPEN,
  SET_IS_UPLOAD_VISIBLE,
  SET_LAST_UPLOAD_DROP,
  SET_THEME,
} from './types'

const initialState: ContentState = {
  theme: 'darkTheme',
  lastUploadDrop: [],
  isUploadVisible: false,
  isFocusedItemSpoilerVisible: false,
  activeWorkspace: null,
  isSocialAssetModalOpen: false,
}

export default function content(
  state = initialState,
  action: ContentActionTypes
): ContentState {
  switch (action.type) {
    case SET_THEME:
      return { ...state, theme: action.theme }
    case SET_ACTIVE_WORKSPACE:
      return { ...state, activeWorkspace: action.activeWorkspace }
    case SET_FOCUSED_ITEM:
      return { ...state, focusedItem: action.item }
    case SET_IS_FOCUSED_ITEM_SPOILER_VISIBLE:
      return {
        ...state,
        isFocusedItemSpoilerVisible: action.isFocusedItemSpoilerVisible,
      }
    case SET_ACTIVE_EXPORT:
      return {
        ...state,
        activeExport: action.activeExport,
      }
    case SET_IS_SOCIAL_ASSET_MODAL_OPEN:
      return { ...state, isSocialAssetModalOpen: action.isSocialAssetModalOpen }
    case SET_LAST_UPLOAD_DROP:
      return { ...state, lastUploadDrop: action.files }
    case SET_IS_UPLOAD_VISIBLE:
      return { ...state, isUploadVisible: action.isVisible }
    default:
      return state
  }
}
