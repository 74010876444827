import styled, { css } from 'styled-components'

export const StyledAnnualUpsellContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    row-gap: 0.5rem;
    flex-direction: column;
    align-items: center;
    background: ${theme.colors.discreet};
    border-radius: ${theme.borderRadius.sm};
    padding: 0.5rem;
    @media screen and (min-width: 1800px) {
      display: none;
    }
  `}
`
export const StyledTopRow = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  `}
`
export const StyledAllTextContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  `}
`
export const StyledArgument = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.text.neutral[3]};
    font-size: ${theme.fontSizes['2xs']};
  `}
`
export const StyledDiscountPill = styled.span`
  ${({ theme }) => css`
    background: ${theme.colors.greenLighter};
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    font-size: ${theme.fontSizes['2xs']};
    color: ${theme.colors.text.discreet};
    border-radius: ${theme.borderRadius.sm};
  `}
`
export const StyledPrice = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes['2xs']};
    color: ${theme.colors.text.neutral[3]};
  `}
`
