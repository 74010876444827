import { Trash } from '@styled-icons/ionicons-outline/Trash'
import { EllipsisHorizontal } from '@styled-icons/ionicons-sharp/EllipsisHorizontal'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import Dropdown from 'components/common/Dropdown'
import Flex from 'components/common/Flex'
import IconButton from 'components/common/IconButton'
import { Spacer } from 'components/common/Spacer'
import { Text } from 'components/common/Text'
import UserAvatar from 'components/common/UserAvatar'
import { UserProjectsQuery } from 'generated/graphql'
import { formatDate } from 'helpers/formatDate'
import { projectPermissions } from 'hooks/useProjectPermissions'
import { RootState } from 'store'

import AirModal from '../common/AirModal'

type ProjectCardProps = {
  project: UserProjectsQuery['projects']['projects'][0]
  deleteProject: (projectId: string) => void
}

export const ProjectCard = (props: ProjectCardProps) => {
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)

  const activeWorkspaceUrl = useSelector(
    (state: RootState) => state.content.activeWorkspace!.url
  )

  const canDeleteProject =
    props.project.userRole &&
    projectPermissions[props.project.userRole].includes('DELETE_PROJECT')

  const onDeleteProject = (projectId: string) => {
    setDeleteModalVisible(false)
    props.deleteProject(projectId)
  }

  return (
    <>
      <CardContainer>
        <Link to={`/${activeWorkspaceUrl}/p/${props.project.id}`}>
          <Card flexDirection="column">
            <Cover thumbUrls={props.project.thumbUrls} />

            <Flex flexDirection="column" css="position:relative;height:50px;">
              <StyledAnimatedContent>
                <Flex
                  px={1.5}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Text size="base" color="neutral.0" bold ellipsis>
                    {props.project.title}
                  </Text>
                </Flex>
                <Spacer factor={1} />
                <Flex px={1.5} flexDirection="column">
                  {props.project.createdBy && (
                    <Flex alignItems="center" css="overflow:hidden">
                      <UserAvatar
                        photoUrl={props.project.createdBy.photoURL}
                        displayName={props.project.createdBy.displayName ?? ''}
                        size={18}
                      />
                      <Spacer factor={1} axis="x" />
                      <Text size="xs" color="neutral.1" ellipsis>
                        {props.project.createdBy.displayName}
                      </Text>
                      <Spacer factor={1} axis="x" />
                    </Flex>
                  )}
                  <Spacer factor={1.5} />
                  {props.project.createdAt && (
                    <Text size="xs" color="neutral.2" nowrap>
                      {formatDate(new Date(props.project.createdAt))}
                    </Text>
                  )}
                </Flex>
              </StyledAnimatedContent>
              {canDeleteProject && (
                <StyledProjectCardMenu>
                  <IconButton
                    Icon={EllipsisHorizontal}
                    variant="squared"
                    menuWidth={160}
                    noBackground
                    position="top"
                    size={28}
                    menuNoSpace
                  >
                    <DropdownItem
                      Icon={Trash}
                      title="Delete project"
                      isCompact
                      onClick={(event) => {
                        event.preventDefault()
                        setDeleteModalVisible(true)
                      }}
                    />
                  </IconButton>
                </StyledProjectCardMenu>
              )}
            </Flex>
          </Card>
        </Link>
      </CardContainer>
      <AirModal
        visible={deleteModalVisible}
        headingText="Sure you want to delete this project?"
        detailText="You can't undo this!"
        okText="Delete"
        toggleVisibility={() => setDeleteModalVisible((prev) => !prev)}
        onOk={() => onDeleteProject(props.project.id)}
      />
    </>
  )
}

const Cover = ({ thumbUrls }: { thumbUrls: string[] }) => {
  return (
    <CoverImageContainer>
      {thumbUrls.length > 0 && (
        <CoverRows>
          <CoverRow>
            {thumbUrls[0] ? <CoverImage src={thumbUrls[0]} /> : null}
            {thumbUrls[1] ? <CoverImage src={thumbUrls[1]} /> : null}
          </CoverRow>
          {thumbUrls[2] && (
            <CoverRow>
              {thumbUrls[2] ? <CoverImage src={thumbUrls[2]} /> : null}
              {thumbUrls[3] ? <CoverImage src={thumbUrls[3]} /> : null}
            </CoverRow>
          )}
        </CoverRows>
      )}
      <CoverOverlayFaded />
      <CoverOverlayHover />
    </CoverImageContainer>
  )
}

const StyledProjectCardMenu = styled.div`
  position: absolute;
  right: 12px;
  bottom: 12px;
  opacity: 0;
`

const StyledAnimatedContent = styled.div`
  position: absolute;
  width: 100%;
  top: -12px;
  transition: 0.2s transform ease;
`

const CoverOverlayHover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  transition: 0.2s background ease;
`

const CardContainer = styled.div`
  position: relative;
  max-width: 260px;
  min-width: 200px;
  width: calc(20% - 8px);
  :hover ${StyledProjectCardMenu} {
    opacity: 1;
  }
  :hover ${StyledAnimatedContent} {
    transform: translateY(-28px);
  }
  :hover ${CoverOverlayHover} {
    background: #00000088;
  }
`

const Card = styled(Flex)(({ theme }) => ({
  backgroundColor: theme.colors.black,
  borderRadius: theme.borderRadius.default,
  border: `${theme.border.thin} ${theme.colors.background[4]}`,
  transition: 'border-color 0.15s ease',
  overflow: 'hidden',
  width: '100%',
  ':hover': {
    border: `${theme.border.thin} ${theme.colors.accent[2]}`,
  },
}))

const DropdownItem = styled(Dropdown.Item)`
  &&& {
    font-size: ${({ theme }) => theme.fontSizes.sm};
    color: ${({ theme }) => theme.colors.text.neutral[0]};
    background: ${({ theme }) => theme.colors.background[5]};
    :hover {
      background: ${({ theme }) => theme.colors.gray.dark[6]};
    }
  }
`

const CoverRows = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
`

const CoverRow = styled.div`
  display: inline-flex;
  flex-direction: column;
  flex: 1;
`

const CoverImage = styled.div<{ src: string }>((props) => ({
  backgroundImage: `url(${props.src})`,
  flex: 1,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
}))

const CoverImageContainer = styled.div(({ theme }) => ({
  display: 'inline-flex',
  overflow: 'hidden',
  height: 200,
  width: '100%',
  position: 'relative',
  borderTopRightRadius: theme.borderRadius.default,
  borderTopLeftRadius: theme.borderRadius.default,
}))

const CoverOverlayFaded = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
`
