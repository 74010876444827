import { reportError } from './logging'

export const safeImport = async (importer: () => Promise<any>) => {
  const pageHasAlreadyBeenForceRefreshed = JSON.parse(
    localStorage.getItem('page-has-been-force-refreshed') || 'false'
  )
  try {
    const c = await importer()
    localStorage.setItem('page-has-been-force-refreshed', 'false')
    return c
  } catch (error) {
    if (!pageHasAlreadyBeenForceRefreshed) {
      // Assuming that the user is not on the latest version of the application.
      // Let's refresh the page immediately.
      localStorage.setItem('page-has-been-force-refreshed', 'true')

      // Lets start of by tracking this to see how often this happens
      reportError(
        new Error(
          `Error lazy loading javascript chunk. Name:${error?.name}. Msg:${error?.message}`
        )
      )
      // return window.location.reload() // TODO: Enable this if we wanna hard refresh the browser
    }

    // The page has already been reloaded
    // Assuming that user is already using the latest version of the application.
    // Let's let the application crash and raise the error.
    throw error
  }
}
