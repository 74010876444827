import {
  MutableRefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react'
import { usePageVisibility } from 'react-page-visibility'

import { Text } from 'components/common/Text'
import { usePrevious } from 'hooks/usePrevious'

import typing from '../../assets/img/typing.gif'
import { Presence, useOthers } from '../../config/liveblocks.config'
import { cursorColors } from './CursorsOthers'
import { StyledPreviewImage } from './GenerationFrame'
import { usePerfectCursor } from './hooks/usePerfectCursor'

type GenerationFrameOthersProps = {
  stageRef: MutableRefObject<any>
  scale: number
}

export const GenerationFramesOthers = ({
  stageRef,
  scale,
}: GenerationFrameOthersProps) => {
  const others = useOthers()
  if (!stageRef.current) return null
  return (
    <>
      {others.map(({ connectionId, presence }, i) => {
        if (!presence.generationFrameStatus) return null
        if (!presence.generationFrameCoords?.x) return null
        if (!presence.generationFrameCoords?.y) return null
        if (
          !['SEEKING', 'PLACED', 'GENERATING', 'HAS_OUTPUT'].includes(
            `${presence.generationFrameStatus}`
          )
        ) {
          return null
        }
        return (
          <GenerationFrameOther
            key={connectionId}
            presence={presence}
            index={i}
            stageRef={stageRef}
            scale={scale}
          />
        )
      })}
    </>
  )
}

type GenerationFrameOtherProps = {
  presence: Presence
  index: number
  stageRef: MutableRefObject<any>
  scale: number
}

const GenerationFrameOther = ({
  presence,
  index,
  stageRef,
  scale,
}: GenerationFrameOtherProps) => {
  const x = presence.generationFrameCoords!.x * scale + stageRef.current.x()
  const y = presence.generationFrameCoords!.y * scale + stageRef.current.y()

  return (
    <>
      <GenerationFrameForeground
        x={x}
        y={y}
        presence={presence}
        scale={scale}
        index={index}
        coreX={presence.generationFrameCoords!.x}
        coreY={presence.generationFrameCoords!.y}
      />
      <GenerationFrameBackground
        x={x}
        y={y}
        presence={presence}
        scale={scale}
        coreX={presence.generationFrameCoords!.x}
        coreY={presence.generationFrameCoords!.y}
      />
    </>
  )
}

type GenerationFrameBackgroundProps = {
  scale: number
  x: number
  y: number
  coreX: number
  coreY: number
  presence: Presence
}

const GenerationFrameBackground = ({
  scale,
  x,
  y,
  coreX,
  coreY,
  presence,
}: GenerationFrameBackgroundProps) => {
  const generationFrameOutputRef = useRef<HTMLDivElement | null>(null)

  const isPageVisible = usePageVisibility()

  const previousCoreX = usePrevious(coreX)
  const previousCoreY = usePrevious(coreY)

  const changePosition = useCallback((point: number[]) => {
    const generationFrameElement = generationFrameOutputRef.current
    if (generationFrameElement) {
      generationFrameElement.style.setProperty(
        'transform',
        `translate(${point[0]}px, ${point[1]}px)`
      )
    }
  }, [])

  const onPointMove = usePerfectCursor(changePosition)

  useLayoutEffect(() => {
    // perfect cursor doesn't work well if page is not visible so we disable animations for that use-case
    if (!isPageVisible) {
      return changePosition([x, y])
    }
    // if other user didn't change position we don't wanna do smooth animations (e.g. if zoom or pan)
    if (coreX === previousCoreX && coreY === previousCoreY) {
      changePosition([x, y])
    } else {
      onPointMove([x, y])
    }
  }, [
    onPointMove,
    changePosition,
    x,
    y,
    coreX,
    coreY,
    previousCoreX,
    previousCoreY,
    isPageVisible,
  ])

  return (
    <div
      ref={generationFrameOutputRef}
      style={{
        position: 'absolute',
        zIndex: -1,
        left: 0,
        top: 0,
        width: 256 * scale,
        height: 256 * scale,
      }}
    >
      {presence.generationFrameStatus === 'HAS_OUTPUT' &&
        presence.generationFrameAlternatives?.map((src, srcIndex) => (
          <StyledPreviewImage
            // eslint-disable-next-line react/no-array-index-key
            key={srcIndex}
            src={src}
            isActive={
              (presence.generationFrameAlternativesActiveIndex ?? 0) ===
              srcIndex
            }
            isOwnPreview={false}
          />
        ))}
    </div>
  )
}

type GenerationFrameForegroundProps = {
  scale: number
  x: number
  y: number
  index: number
  coreX: number
  coreY: number
  presence: Presence
}

const GenerationFrameForeground = ({
  scale,
  index,
  x,
  y,
  coreX,
  coreY,
  presence,
}: GenerationFrameForegroundProps) => {
  const generationFrameRef = useRef<HTMLDivElement | null>(null)
  const loadingIndicatorRef = useRef<HTMLDivElement>(null)

  const isPageVisible = usePageVisibility()

  const previousCoreX = usePrevious(coreX)
  const previousCoreY = usePrevious(coreY)
  const otherIsGenerating = presence.generationFrameStatus === 'GENERATING'

  useEffect(() => {
    if (otherIsGenerating && loadingIndicatorRef.current) {
      loadingIndicatorRef.current.style.width = '100%'
      loadingIndicatorRef.current.style.transition = 'width 12s'
    }
    if (!otherIsGenerating && loadingIndicatorRef.current) {
      loadingIndicatorRef.current.style.width = '0'
      loadingIndicatorRef.current.style.transition = 'width 0s'
    }
  }, [otherIsGenerating])

  const changePosition = useCallback((point: number[]) => {
    const generationFrameElement = generationFrameRef.current
    if (generationFrameElement) {
      generationFrameElement.style.setProperty(
        'transform',
        `translate(${point[0]}px, ${point[1]}px)`
      )
    }
  }, [])

  const onPointMove = usePerfectCursor(changePosition)

  useLayoutEffect(() => {
    // perfect cursor doesn't work well if page is not visible so we disable animations for that use-case
    if (!isPageVisible) {
      return changePosition([x, y])
    }
    // if other user didn't change position we don't wanna do smooth animations (e.g. if zoom or pan)
    if (coreX === previousCoreX && coreY === previousCoreY) {
      changePosition([x, y])
    } else {
      onPointMove([x, y])
    }
  }, [
    onPointMove,
    x,
    y,
    coreX,
    previousCoreX,
    changePosition,
    previousCoreY,
    coreY,
    isPageVisible,
  ])

  const lense = presence.generationFramePrompt?.split(':::lens:::')?.[1]

  return (
    <div
      ref={generationFrameRef}
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        width: 256 * scale,
        height: 256 * scale,
        zIndex: 2,
        border: `1px solid ${cursorColors[index % cursorColors.length]}`,
        pointerEvents: 'none',
        padding: 16,
        paddingBottom: 20,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        background: otherIsGenerating ? '#00000070' : 'none',
        backdropFilter: otherIsGenerating ? 'blur(16px)' : 'none',
      }}
    >
      {presence?.isTyping && scale > 0.4 && (
        <div
          style={{
            position: 'absolute',
            bottom: -34 * scale,
            left: 0,
            background: `${cursorColors[index % cursorColors.length]}`,
            borderRadius: 12 * scale,
            width: 32 * scale,
            height: 24 * scale,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img alt="" src={typing} height={10 * scale} />
        </div>
      )}
      {otherIsGenerating && (
        <>
          <Text size="xs" bold color="neutral.0">
            {lense && `${lense} filter`}
          </Text>
          <div
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxHeight: 186,
            }}
          >
            <Text bold size="base" color="neutral.0">
              {presence.generationFramePrompt?.split(':::lens:::')?.[0]}
            </Text>
          </div>
        </>
      )}

      <div
        ref={loadingIndicatorRef}
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          height: 4,
          width: 0,
          background: cursorColors[index % cursorColors.length],
          transition: 'width 8s',
        }}
      />
    </div>
  )
}
