import React, { useState } from 'react'
import { useTheme } from 'styled-components'

import { trackFaceSearchOptionClicked } from 'helpers/tracking'

import { optionGroups } from './FaceSearchOptionGroups'
import {
  Container,
  Heading,
  ItemDetail,
  ItemDetailIcon,
  ItemDetailText,
  OptionGroupItem,
  OptionsContainer,
} from './FaceSearchStyles'
import { useSearchContext } from './SearchContext'

interface OptionGroupProps {
  title: string
  type: string
  items: {
    title: string
    description: string
    value: string
  }[]
  handleMouseEnter: (groupTitle: string, item: string) => void
  handleOptionItemClick: (
    itemValue: string,
    groupTitle: string,
    groupType: string
  ) => void
}

const OptionGroup: React.FC<OptionGroupProps> = ({
  title,
  type,
  items,
  handleMouseEnter,
  handleOptionItemClick,
}) => {
  const theme = useTheme()

  return (
    <div
      style={{
        fontSize: theme.fontSizes.sm,
        fontWeight: parseInt(theme.fontWeight.bold, 10),
        marginBottom: 16,
      }}
    >
      <div>{title}</div>
      <div style={{ marginTop: 8 }}>
        {items.map((item) => (
          <OptionGroupItem
            key={item.title}
            onMouseEnter={() => handleMouseEnter(item.description, item.title)}
            onClick={() => handleOptionItemClick(item.value, title, type)}
          >
            {item.title}
          </OptionGroupItem>
        ))}
      </div>
    </div>
  )
}

const FaceSearch: React.FC = () => {
  const [hoverOptionItem, setHoverOptionItem] = useState<string | null>(null)
  const [hoverOptionItemGroup, setHoverOptionItemGroup] = useState<
    string | null
  >(null)

  const { addFaceTag } = useSearchContext()

  const handleOptionItemClick = (itemValue: string, groupTitle: string) => {
    addFaceTag(itemValue)
    trackFaceSearchOptionClicked({
      optionGroup: groupTitle,
      optionItem: itemValue,
    })
  }

  const handleMouseEnterOptionGroup = (groupTitle: string, item: string) => {
    setHoverOptionItem(item)
    setHoverOptionItemGroup(groupTitle)
  }

  return (
    <Container>
      <Heading>Face</Heading>

      <OptionsContainer>
        {optionGroups.map((group) => (
          <OptionGroup
            key={group.title}
            title={group.title}
            items={group.items}
            type={group.type}
            handleOptionItemClick={handleOptionItemClick}
            handleMouseEnter={handleMouseEnterOptionGroup}
          />
        ))}
      </OptionsContainer>

      {hoverOptionItem && (
        <ItemDetail>
          <ItemDetailIcon>{hoverOptionItem}</ItemDetailIcon>
          <ItemDetailText>{hoverOptionItemGroup}</ItemDetailText>
        </ItemDetail>
      )}
    </Container>
  )
}

export default FaceSearch
