import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import Button from 'components/common/Button'
import Flex from 'components/common/Flex'
import { Input as CommonInput } from 'components/common/Form'
import Spinner from 'components/common/Spinner'
import { useUpdateMyPresence } from 'config/liveblocks.config'

export const MobilePromptInput = ({
  submit,
  hidden,
  isLoading,
  setInputIsFocused,
  isExpanded,
  activeLensId,
  disabled,
}: {
  submit: any
  hidden: boolean
  isLoading: boolean
  setInputIsFocused: (focus: boolean) => void
  isExpanded: boolean
  activeLensId?: string
  disabled?: boolean
}) => {
  const [prompt, setPrompt] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)
  const updateMyPresence = useUpdateMyPresence()

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setInputIsFocused(false)
    submit(prompt)
  }

  useEffect(() => {
    updateMyPresence({
      isTyping: prompt.length > 0,
    })
  }, [prompt, updateMyPresence])
  return (
    <div
      style={{
        // eslint-disable-next-line
        marginLeft: '2.5%',
        alignSelf: 'center',
        bottom: window.innerHeight - 105,
        left: 0,
        position: 'absolute',
        display: 'flex',
        width: '95%',
        zIndex: 100,
      }}
    >
      <form onSubmit={handleSubmit} css="width: 550px">
        <Flex>
          <StyledInput
            ref={inputRef}
            placeholder="Fashion portrait of woman wearing red"
            onChange={(event: any) => setPrompt(event.target.value)}
            onFocus={() => setInputIsFocused(true)}
            onBlur={() => setInputIsFocused(false)}
            value={prompt}
            css="height:44px"
            style={{ borderRadius: '8px 0 0 8px' }}
            disabled={isLoading || disabled}
          />
          <Button
            style={{
              borderRadius: '0 12px 12px 0',
            }}
            type="submit"
            variant="primary"
            disabled={isLoading || disabled}
          >
            {isLoading ? <Spinner size={16} /> : 'Generate'}
          </Button>
        </Flex>
      </form>
    </div>
  )
}

const StyledInput = styled(CommonInput)`
  background-color: ${(props) => props.theme.colors.background[3]};
  border-color: ${(props) => props.theme.colors.background[5]};
  ::placeholder {
    color: ${(props) => props.theme.colors.text.neutral[3]};
    opacity: 0.7;
  }
`
