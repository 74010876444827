import { Cookie } from '@styled-icons/boxicons-solid/Cookie'
import CookieConsent from 'react-cookie-consent'
import { useTheme } from 'styled-components'

import Button from 'components/common/Button'
import Flex from 'components/common/Flex'
import { Text } from 'components/common/Text'

export const CookieConsentBanner = () => {
  const theme = useTheme()
  return (
    <CookieConsent
      location="bottom"
      cookieName="CookieConsent"
      style={{
        background: theme.colors.background[5],
        borderTop: `${theme.border.thin} ${theme.colors.gray.dark[6]}`,
        padding: '12px 8% 12px 8%',
        alignItems: 'center',
      }}
      expires={365}
      contentStyle={{ margin: 0 }}
      ButtonComponent={({ onClick }: any) => (
        <Button
          isCompact
          variant="primary"
          onClick={onClick}
          style={{ marginLeft: '25px' }}
        >
          That&apos;s ok
        </Button>
      )}
    >
      <Flex py={1.5}>
        <Cookie size="22" style={{ marginRight: '8px', flexShrink: 0 }} />
        <Text color="neutral.2" size="sm">
          We use cookies to analyse traffic, improve your experience and for
          marketing purposes. Read our{' '}
          <a style={{ color: theme.colors.text.neutral[1] }} href="/privacy">
            privacy policy
          </a>{' '}
          to learn more.
        </Text>
      </Flex>
    </CookieConsent>
  )
}
