import React from 'react'

type StopPropagationProps = {
  children: React.ReactNode
}

const StopPropagation = ({ children }: StopPropagationProps) => (
  <div
    style={{ display: 'flex', cursor: 'default' }}
    onClick={(e) => e.stopPropagation()}
  >
    {children}
  </div>
)

export default StopPropagation
