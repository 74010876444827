/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { TItem } from 'types/typesense'

export interface ItemSearchState {
  itemSearches: Record<string, { items: TItem[]; resultCount: number }>
}

// Define the initial state using that type
const initialState: ItemSearchState = {
  itemSearches: {},
}

const itemSearchSlice = createSlice({
  name: 'itemSearch',
  initialState,
  reducers: {
    addItems: (
      state,
      {
        payload,
      }: PayloadAction<{ searchId: string; items: TItem[]; override?: boolean }>
    ) => {
      if (!state.itemSearches[payload.searchId]) {
        state.itemSearches[payload.searchId] = { resultCount: 0, items: [] }
      }
      if (payload.override) {
        state.itemSearches[payload.searchId].items = payload.items
      } else {
        state.itemSearches[payload.searchId].items.push(...payload.items)
      }
    },
    removeItem: (
      state,
      { payload }: PayloadAction<{ searchId: string; id: string }>
    ) => {
      state.itemSearches[payload.searchId].items = state.itemSearches[
        payload.searchId
      ].items.filter((item) => item.id !== payload.id)
    },
    removeItemEverywhere: (
      state,
      { payload }: PayloadAction<{ id: string }>
    ) => {
      state.itemSearches = Object.entries(state.itemSearches).reduce<
        ItemSearchState['itemSearches']
      >((acc, [searchId, { items, resultCount }]) => {
        return {
          ...acc,
          [searchId]: {
            items: items.filter((item) => item.id !== payload.id),
            resultCount,
          },
        }
      }, {})
    },

    resetSearchId: (
      state,
      { payload }: PayloadAction<{ searchId: string }>
    ) => {
      delete state.itemSearches[payload.searchId]
    },
    setResultCount: (
      state,
      { payload }: PayloadAction<{ searchId: string; count: number }>
    ) => {
      if (!state.itemSearches[payload.searchId]) {
        state.itemSearches[payload.searchId] = { resultCount: 0, items: [] }
      }
      state.itemSearches[payload.searchId].resultCount = payload.count
    },
  },
})

export const { removeItemEverywhere } = itemSearchSlice.actions

export default itemSearchSlice.reducer
