/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type LibrarySubView = 'magic-boards' | 'upload-events' | 'items'

export interface UIState {
  searchInput: string
  searchActive: boolean
  siderSplitValue: number
  sharingPanelOpen?: boolean
  commandPaletteOpen?: boolean
  librarySubView: LibrarySubView
}

const initialState: UIState = {
  searchInput: '',
  searchActive: false,
  siderSplitValue: 210,
  librarySubView: 'magic-boards',
  commandPaletteOpen: false,
}

const UISlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleCommandPalette: (state) => {
      state.commandPaletteOpen = !state.commandPaletteOpen
    },
    setSearchInput: (state, { payload }: PayloadAction<string>) => {
      state.searchInput = payload
    },
    setSearchActive: (state, { payload }: PayloadAction<boolean>) => {
      state.searchActive = payload
    },
    setSiderSplitValue: (state, { payload }: PayloadAction<number>) => {
      state.siderSplitValue = payload
    },
    setLibrarySubView: (state, { payload }: PayloadAction<LibrarySubView>) => {
      state.librarySubView = payload
    },
  },
})

const KEY = 'UIState'
export function loadUIState() {
  try {
    const serializedState = localStorage.getItem(KEY)
    if (!serializedState) return initialState
    return JSON.parse(serializedState)
  } catch (e) {
    return initialState
  }
}

export async function saveUIState(state: UIState) {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem(KEY, serializedState)
  } catch (e) {
    // Ignore
  }
}

export const {
  toggleCommandPalette,
  setSearchInput,
  setSearchActive,
  setSiderSplitValue,
  setLibrarySubView,
} = UISlice.actions

export default UISlice.reducer
