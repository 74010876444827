import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import SelectBoardAndProject from 'components/selectBoardAndProject/SelectBoardAndProject'
import useToastMessages from 'components/toast/useToastMessages'
import { useSaveWorkspaceItemsToBoardMutation } from 'generated/graphql'
import { maxAwaitFn } from 'helpers/maxAwaitFn'
import {
  getCustomErrorStatus,
  getGraphqlErrorCode,
} from 'helpers/parseGraphqlErrors'
import { RootState } from 'store'
import { setSaveToBoardStatus } from 'store/multiSelect'

interface SaveToBoardProps {
  workspaceItemIds: string[]
  onMenuClose: () => void
  defaultSelectView?: 'SELECT_BOARD' | 'SET_BOARD_TITLE'
  onSavedItemsToBoard?: (workspaceItemIds: string[]) => void
}

const SaveToBoard: React.FC<SaveToBoardProps> = ({
  workspaceItemIds,
  defaultSelectView,
  onMenuClose,
  onSavedItemsToBoard,
}) => {
  const dispatch = useDispatch()
  const { reportSuccess, reportError } = useToastMessages()
  const activeWorkspace = useSelector(
    (state: RootState) => state.content.activeWorkspace!
  )

  const [saveWorkspaceItemsToBoard] = useSaveWorkspaceItemsToBoardMutation()

  async function saveItemsToBoard({
    projectId,
    boardId,
    boardTitle,
  }: {
    projectId: string
    boardId: string
    boardTitle: string
  }) {
    onMenuClose()
    dispatch(setSaveToBoardStatus('LOADING'))

    try {
      await maxAwaitFn({
        asyncFn: saveWorkspaceItemsToBoard({
          variables: {
            workspaceId: activeWorkspace.id,
            boardId,
            workspaceItemIds,
          },
        }),
        limit: 1500,
        limitReturn: undefined,
      })

      dispatch(setSaveToBoardStatus('DONE'))
      onSavedItemsToBoard?.(workspaceItemIds)

      reportSuccess(
        'Added to board:',
        {
          linkTo: `/${activeWorkspace.url}/p/${projectId}/${boardId}`,
          linkText: `${boardTitle}`,
        },
        5000
      )
    } catch (error) {
      dispatch(setSaveToBoardStatus('DONE'))
      const code = getGraphqlErrorCode(error)
      const status = getCustomErrorStatus(error)
      if (status === 'ALREADY_EXISTS') {
        return reportError(`The items already exists in "${boardTitle}"`)
      }
      if (code === 'FORBIDDEN') {
        return reportError(
          `You do not have permission to save items to "${boardTitle}"`
        )
      }
      return reportError('We could not save the item to the board...')
    }
  }

  return (
    <SelectBoardAndProject
      onSelectBoard={saveItemsToBoard}
      onMenuClose={onMenuClose}
      headline="Save to board"
      selectType="SELECT_BOARD"
      defaultSelectView={defaultSelectView}
      boardAccessLevel="writer"
    />
  )
}

export default SaveToBoard
