import { CustomFile } from 'components/upload/types'

const getVideoDuration = async (videoFile: CustomFile): Promise<number> => {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video')
    video.preload = 'metadata'

    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src)
      resolve(video.duration)
    }
    video.onerror = () => {
      reject(new Error('Could not resolve duration from video file'))
    }
    video.src = URL.createObjectURL(videoFile)
  })
}

export { getVideoDuration }
