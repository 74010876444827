import { DBBilling } from '@kive/shared-types'
import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import { useNavigate } from 'react-router-dom'

import { useActiveWorkspace } from 'hooks/useActiveWorkspace'
import { RootState } from 'store'
import { workspacesSelector } from 'store/selectors'
import { WorkspaceWithId } from 'types/custom'
import { DBPlan, DBPlans } from 'types/db'

const getNextWorkspacePlan = (currentPlan?: DBPlan, allPlans?: DBPlans) => {
  if (!currentPlan || !allPlans) return null
  if (currentPlan.presentation.name === 'Free') {
    return Object.values(allPlans ?? {}).find(
      (plan: DBPlan) => plan.presentation.name === 'Pro'
    )!
  }
  if (currentPlan.presentation.name === 'Pro') {
    return Object.values(allPlans ?? {}).find(
      (plan: DBPlan) => plan.presentation.name === 'Team'
    )!
  }
  return null
}

export const useWorkspacePlan = (workspaceId: string) => {
  useFirestoreConnect([
    {
      collection: 'billing',
      doc: workspaceId,
      storeAs: `billing_${workspaceId}`,
    },
    {
      collection: 'plans',
    },
    {
      collection: 'workspaces',
      doc: workspaceId,
      subcollections: [
        {
          collection: 'counters',
          doc: 'itemCounter',
        },
      ],
      storeAs: `workspaceCounters_${workspaceId}`,
    },
  ])
  const workspaceBilling: DBBilling | undefined = useSelector(
    (state: RootState) => state.firestore.data[`billing_${workspaceId}`]
  )

  const workspaceUploadsCount = useSelector(
    (state: RootState) =>
      state.firestore.data[`workspaceCounters_${workspaceId}`]?.merged_count ??
      0
  )

  const plans = useSelector((state: RootState) => state.firestore.data.plans)

  const fallbackPlan: DBPlan = useSelector(
    (state: RootState) =>
      Object.values(state.firestore.data.plans ?? {}).find(
        (plan: DBPlan) => plan.defaultOption
      )!
  )

  const workspaces = useSelector(workspacesSelector) // App.tsx subscribes to all workspaces
  const workspace = workspaces?.[workspaceId]

  const workspacePlan = workspaceBilling
    ? plans?.[workspaceBilling.plan]
    : fallbackPlan

  const additionalItemSpace = workspace?.additionalItemSpace ?? 0

  const workspaceLimit = workspacePlan?.limits?.items ?? Infinity
  const workspaceFrameExtractionLimit =
    workspacePlan?.limits?.frameExtractions ?? Infinity

  const remainingUploads =
    workspaceLimit === Infinity || workspaceBilling?.plan === 'v2_business'
      ? Infinity
      : workspaceLimit - workspaceUploadsCount + additionalItemSpace

  const remainingFrameExtractions =
    workspaceFrameExtractionLimit === Infinity
      ? Infinity
      : workspaceFrameExtractionLimit - (workspace?.frameExtractionCount || 0)

  const nextWorkspacePlan = getNextWorkspacePlan(workspacePlan, plans)

  return {
    remainingUploads,
    remainingFrameExtractions,
    workspacePlan,
    nextWorkspacePlan,
  }
}

function usePrevious<T>(value: T) {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const useUploadWorkspaces = (onSelectedWorkspaceChange: () => void) => {
  const navigate = useNavigate()
  const activeWorkspace = useActiveWorkspace()!

  const prevActiveWorkspace = usePrevious(activeWorkspace)
  const currentUserEmail = useSelector(
    (state: RootState) => state.firebase.auth.email!
  )

  const { remainingUploads, workspacePlan, nextWorkspacePlan } =
    useWorkspacePlan(activeWorkspace.id)

  const workspaces = useSelector(workspacesSelector) || {} // App.tsx subscribes to all workspaces

  useEffect(() => {
    if (prevActiveWorkspace && prevActiveWorkspace.id !== activeWorkspace.id) {
      onSelectedWorkspaceChange()
    }
  }, [activeWorkspace.id, prevActiveWorkspace, onSelectedWorkspaceChange])

  const changeSelectedWorkspace = (newSelectedWorkspace: WorkspaceWithId) => {
    navigate(`/${newSelectedWorkspace.url}`)
  }

  const availableWorkspaces: WorkspaceWithId[] = Object.entries(workspaces)
    .map(([id, workspace]) => ({ id, ...workspace }))
    .filter((workspace) => {
      return (
        workspace.members.includes(currentUserEmail) ||
        workspace.admins.includes(currentUserEmail)
      )
    })

  const workspaceAdminsLength =
    'adminEmails' in activeWorkspace
      ? activeWorkspace.adminEmails.length
      : activeWorkspace.admins.length

  const workspaceMembersLength =
    'memberEmails' in activeWorkspace
      ? activeWorkspace.memberEmails.length
      : activeWorkspace.members.length

  const workspaceGuestsLength =
    'guestEmails' in activeWorkspace
      ? activeWorkspace.guestEmails.length
      : activeWorkspace.guests.length
  const selectedWorkspaceMemberCount =
    workspaceAdminsLength + workspaceMembersLength + workspaceGuestsLength

  return {
    selectedWorkspace: activeWorkspace,
    selectedWorkspaceMemberCount,
    changeSelectedWorkspace,
    availableWorkspaces,
    remainingUploads,
    workspacePlan,
    nextWorkspacePlan,
  }
}

export default useUploadWorkspaces
