import React from 'react'
import styled, { css } from 'styled-components'

type GridItemReactionToggleOverlayProps = {
  onClick: () => void
  right: string
}

const GridItemReactionToggleOverlay = (
  props: GridItemReactionToggleOverlayProps
) => {
  return (
    <StyledItemReactionToggleOverlay>
      <StyledItemReactionToggleIcon right={props.right} onClick={props.onClick}>
        ☺
      </StyledItemReactionToggleIcon>
    </StyledItemReactionToggleOverlay>
  )
}

const StyledItemReactionToggleOverlay = styled.div`
  position: absolute;
  pointer-events: none;
  height: 100%;
  bottom: 0;
  left: 0;
  width: 100%;
`

const StyledItemReactionToggleIcon = styled.div<{ right: string }>`
  ${({ theme, right }) => css`
    font-size: 23px;
    position: absolute;
    bottom: 12px;
    right: ${right};
    pointer-events: auto;
    color: ${theme.colors.gray.light[2]};
    text-shadow: 0px 0px 1px #000000;
    height: 26px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    :hover {
      color: ${theme.colors.accent[2]};
    }
  `}
`

export { GridItemReactionToggleOverlay }
