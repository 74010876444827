import { useCallback, useLayoutEffect, useRef } from 'react'
import { usePageVisibility } from 'react-page-visibility'

import { ReactComponent as MouseCursorIcon } from 'assets/svg/mouse-cursor.svg'
import { usePrevious } from 'hooks/usePrevious'

import { usePerfectCursor } from './hooks/usePerfectCursor'

type CursorProps = {
  x: number
  y: number
  name?: string
  color: string
  coreX: number
  coreY: number
}

export function Cursor({ color, x, y, name, coreX, coreY }: CursorProps) {
  const cursorRef = useRef<HTMLDivElement | null>(null)

  const isPageVisible = usePageVisibility()

  const previousCoreX = usePrevious(coreX)
  const previousCoreY = usePrevious(coreY)

  const changePosition = useCallback((point: number[]) => {
    const cursorElement = cursorRef.current
    if (!cursorElement) return
    cursorElement.style.setProperty(
      'transform',
      `translate(${point[0]}px, ${point[1]}px)`
    )
  }, [])

  const onPointMove = usePerfectCursor(changePosition)

  useLayoutEffect(() => {
    // perfect cursor doesn't work well if page is not visible so we disable animations for that use-case
    if (!isPageVisible) {
      return changePosition([x, y])
    }
    // if other user didn't change position we don't wanna do smooth animations (e.g. if we zoom or pan)
    if (coreX === previousCoreX && coreY === previousCoreY) {
      changePosition([x, y])
    } else {
      onPointMove([x, y])
    }
  }, [
    onPointMove,
    changePosition,
    x,
    y,
    coreX,
    coreY,
    previousCoreX,
    previousCoreY,
    isPageVisible,
  ])

  return (
    <div
      ref={cursorRef}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 2,
      }}
    >
      <div>
        <MouseCursorIcon
          style={{
            fill: color,
            transform: 'rotate(-90deg)',
            height: '18px',
            width: '18px',
          }}
        />
        <div
          style={{
            position: 'relative',
            background: color,
            left: 16,
            top: -12,
            padding: '0px 8px',
            color: '#000',
            borderRadius: 20,
          }}
        >
          {name ?? 'Anonymous'}
        </div>
      </div>
    </div>
  )
}
