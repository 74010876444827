import React from 'react'
import styled, { css } from 'styled-components'

import Header from './DropdownHeader'
import Item from './DropdownItem'
import ItemLarge from './DropdownItemLarge'

interface DropdownMenuWrapperProps {
  isOpen: boolean
  position: DropdownOptions['position']
  noSpace: DropdownOptions['noSpace']
  stretch?: boolean
}

const DropdownMenuWrapper = styled.div<DropdownMenuWrapperProps>`
  position: absolute;
  z-index: 100;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  transform: translateY(${({ isOpen }) => (isOpen ? 0 : '-4px')});
  transition: 0.1s all ease-in-out;
  ${({ stretch }) => stretch && { width: '100%' }};
  ${({ position, noSpace }) => {
    if (position === 'bottom') {
      return css`
        top: 100%;
        right: 0;
        padding-top: ${noSpace ? '0px' : '16px'};
      `
    }
    if (position === 'bottom-left') {
      return css`
        top: 100%;
        left: 0;
        padding-top: ${noSpace ? '0px' : '16px'};
      `
    }
    if (position === 'right') {
      return css`
        left: 100%;
        top: 0;
      `
    }
  }}
`

const DropdownMenu = styled.div<DropdownOptions>`
  ${({ theme, width, height, noSpace, stretch, maxHeight }) => css`
    background: ${theme.colors.background[3]};
    border: 1px solid ${theme.colors.background[4]};
    box-shadow: 1px 1px 5px ${theme.colors.gray.dark[3]};
    z-index: 100;
    border-radius: 8px;
    overflow: hidden;
    width: ${stretch ? '100%' : `${width || 330}px`};
    white-space: normal;
    ${height &&
    css`
      height: ${height}px;
      overflow: auto;
    `}
    ${maxHeight &&
    css`
      max-height: ${maxHeight}px;
      overflow: auto;
    `}
    ${noSpace &&
    css`
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
    `}
  `}
`

export interface DropdownOptions {
  position?: 'bottom' | 'bottom-left' | 'right'
  width?: number
  height?: number
  maxHeight?: number
  noSpace?: boolean
  stretch?: boolean
}

interface DropdownProps {
  isOpen: boolean
  options?: DropdownOptions
  children: React.ReactNode
}

const Dropdown: React.FC<DropdownProps> & {
  Item: typeof Item
  ItemLarge: typeof ItemLarge
  Header: typeof Header
} = ({ isOpen, options, children }) => {
  const {
    width,
    height,
    maxHeight,
    noSpace,
    position = 'bottom',
    stretch,
  } = options || {}
  return (
    <DropdownMenuWrapper
      stretch={stretch}
      noSpace={noSpace}
      isOpen={isOpen}
      position={position}
    >
      <DropdownMenu
        stretch={stretch}
        width={width}
        height={height}
        noSpace={noSpace}
        maxHeight={maxHeight}
      >
        {children}
      </DropdownMenu>
    </DropdownMenuWrapper>
  )
}

Dropdown.Item = Item
Dropdown.ItemLarge = ItemLarge
Dropdown.Header = Header

export default Dropdown
