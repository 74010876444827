import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { useMagicBoardsQuery } from 'generated/graphql'
import useScreenSize from 'hooks/useScreenSize'
import { RootState } from 'store'

import { useSearchContext } from './SearchContext'
import { SearchHeading } from './SearchStyles'
import SearchTags from './SearchTags'

const SearchResultsContainer = styled.div`
  padding: 0 16px;
`

const SearchResultsTags = () => {
  const screenSize = useScreenSize()
  const [displayTagCount, setDisplayTagCount] = useState(6)
  const { freeText } = useSearchContext()

  const activeWorkspaceId = useSelector(
    (state: RootState) => state.content.activeWorkspace!.id
  )

  const { data } = useMagicBoardsQuery({
    variables: {
      workspaceId: activeWorkspaceId,
      first: 8,
      freeTextSearch: freeText,
    },
    fetchPolicy: 'cache-and-network',
    skip: !freeText,
  })

  useEffect(() => {
    switch (screenSize) {
      case 'lg':
        setDisplayTagCount(6)
        break
      case 'xl':
        setDisplayTagCount(6)
        break
      case '2xl':
        setDisplayTagCount(8)
        break
      default:
        setDisplayTagCount(4)
    }
  }, [screenSize])

  const suggestedTags = data?.magicBoards.magicBoards ?? []

  return (
    <SearchResultsContainer>
      {suggestedTags.length > 0 && freeText.length > 0 && (
        <>
          <SearchHeading>Tags</SearchHeading>
          <SearchTags tags={suggestedTags.slice(0, displayTagCount)} />
        </>
      )}
    </SearchResultsContainer>
  )
}

export default SearchResultsTags
