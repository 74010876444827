import React from 'react'
import styled, { CSSProperties } from 'styled-components'

type PaddingProps = {
  p?: number
  px?: number
  py?: number
  pb?: number
  pt?: number
}

type FlexProps = {
  alignItems?: CSSProperties['alignItems']
  alignContent?: CSSProperties['alignContent']
  justifyContent?: CSSProperties['justifyContent']
  flexWrap?: CSSProperties['flexWrap']
  flexBasis?: CSSProperties['flexBasis']
  flexDirection?: CSSProperties['flexDirection']
  className?: string
  flexGap?: number
}

type BaseProps = {
  children: React.ReactNode
  onClick?: () => void
}

const BASE_FACTOR = 8

const Flex: React.FC<FlexProps & PaddingProps & BaseProps> = ({
  alignItems,
  alignContent,
  justifyContent,
  flexWrap,
  flexBasis,
  flexDirection,
  className,
  children,
  flexGap,
  p,
  px,
  py,
  pb,
  pt,
  onClick,
}) => {
  return (
    <StyledFlex
      className={className}
      flexStyles={{
        alignItems,
        alignContent,
        justifyContent,
        flexWrap,
        flexBasis,
        flexDirection,
      }}
      flexGap={flexGap}
      onClick={onClick}
      paddingStyles={{
        ...(typeof p === 'number' && { padding: p * BASE_FACTOR }),
        ...(typeof px === 'number' && {
          paddingLeft: px * BASE_FACTOR,
          paddingRight: px * BASE_FACTOR,
        }),
        ...(typeof py === 'number' && {
          paddingTop: py * BASE_FACTOR,
          paddingBottom: py * BASE_FACTOR,
        }),
        ...(typeof pb === 'number' && { paddingBottom: pb * BASE_FACTOR }),
        ...(typeof pt === 'number' && { paddingTop: pt * BASE_FACTOR }),
      }}
    >
      {children}
    </StyledFlex>
  )
}

type StyledFlexProps = {
  flexStyles: FlexProps
  paddingStyles: {
    padding?: CSSProperties['padding']
    paddingLeft?: CSSProperties['paddingLeft']
    paddingRight?: CSSProperties['paddingRight']
    paddingTop?: CSSProperties['paddingTop']
    paddingBottom?: CSSProperties['paddingBottom']
  }
  flexGap?: number
}

const StyledFlex = styled.div<StyledFlexProps>(
  ({ flexStyles, paddingStyles, flexGap }) => ({
    display: 'flex',
    ...flexStyles,
    ...paddingStyles,
    ...(flexGap && getFlexGapStyles(flexGap)),
  })
)

const getFlexGapStyles = (gap: number) => {
  return {
    marginLeft: -gap * BASE_FACTOR,
    marginTop: -gap * BASE_FACTOR,
    '& > *': {
      marginLeft: gap * BASE_FACTOR,
      marginTop: gap * BASE_FACTOR,
    },
  }
}

export default Flex
