export function getAlpha(imageData: ImageData) {
  // clone the pixel array from original imageData
  const originalArray = imageData.data
  const newArray = new Uint8ClampedArray(originalArray)
  // loop through every pixel and assign values
  for (let i = 0; i < originalArray.length; i += 4) {
    newArray[i + 0] = originalArray[i + 3]
    newArray[i + 1] = originalArray[i + 3]
    newArray[i + 2] = originalArray[i + 3]
  }
  // return a new ImageData object
  return new ImageData(newArray, imageData.width, imageData.height)
}
