import React from 'react'
import styled from 'styled-components'

import StackGrid from './StackGrid'

const ReplaceGrid = styled(StackGrid)`
  &:hover .hover-show {
    opacity: 1;
    pointer-events: auto;
    transition-delay: 175ms;
  }
  &:hover .hover-hide {
    opacity: 0;
    pointer-events: none;
  }
  .hover-show {
    opacity: 0;
    pointer-events: none;
    transition: opacity 175ms;
  }
  .hover-hide {
    transition-delay: 175ms;
    transition: opacity 175ms;
  }
`

type HoverReplaceProps = {
  onHover: JSX.Element
  children?: JSX.Element
  itemStyle?: React.CSSProperties
  style?: React.CSSProperties
}

export const HoverReplace = ({
  onHover,
  children,
  style,
  itemStyle = {},
}: HoverReplaceProps) => (
  <ReplaceGrid style={style}>
    <div className="hover-show" style={itemStyle}>
      {onHover}
    </div>
    <div className="hover-hide" style={itemStyle}>
      {children}
    </div>
  </ReplaceGrid>
)
