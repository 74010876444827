import {
  SelectionEvents,
  SelectionOptions,
  VanillaSelectionArea,
} from '@viselect/react'
import React, { createRef, useEffect } from 'react'

interface SelectionAreaProps extends Partial<SelectionOptions> {
  className?: string
  onBeforeStart?: SelectionEvents['beforestart']
  onStart?: SelectionEvents['start']
  onMove?: SelectionEvents['move']
  onStop?: SelectionEvents['stop']
  setSelectionArea: (selectionArea: Selection) => void
  children: React.ReactNode
}

const SelectionArea = (props: SelectionAreaProps) => {
  const root = createRef<HTMLDivElement>()

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const {
      onBeforeStart,
      onStart,
      onMove,
      onStop,
      setSelectionArea,
      children,
      ...opt
    } = props

    // @ts-ignore
    const selection = new VanillaSelectionArea({
      ...opt,
      behaviour: {
        overlap: 'invert',
        intersect: 'touch',
        startThreshold: 10,
        scrolling: {
          speedDivider: 6,
          manualSpeed: 550,
          startScrollMargins: { x: 0, y: 50 },
        },
      },
    })
    setSelectionArea(selection)

    if (onBeforeStart) {
      selection.on('beforestart', onBeforeStart)
    }
    if (onStart) {
      selection.on('start', onStart)
    }
    if (onMove) {
      selection.on('move', onMove)
    }
    if (onStop) {
      selection.on('stop', onStop)
    }

    return () => selection.destroy()
  }, [])

  return (
    <div ref={root} className={props.className}>
      {props.children}
    </div>
  )
}

export default SelectionArea
