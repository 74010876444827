import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

export const Heading = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.text.neutral[0]};
    font-size: ${theme.fontSizes['2xl']};
    font-weight: ${theme.fontWeight.bold};
  `}
`

export const FullScreen = styled.div<{ isSemiTransparent?: boolean }>(
  ({ theme, style, isSemiTransparent }) => ({
    top: 0,
    left: 0,
    zIndex: 100,
    width: '100%',
    height: '100vh',
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: theme.colors.text.neutral[0],
    background: isSemiTransparent ? '#0c0c0ce3' : theme.colors.background[1],
    ...style,
  })
)

export const UploadAction = styled(motion.div)<{ active?: boolean }>`
  ${({ theme, active }) => css`
    color: ${active ? theme.colors.accent[2] : theme.colors.text.neutral[4]};
    font-size: ${theme.fontSizes.lg};
    margin-bottom: 25px;
    position: relative;
  `}
`
