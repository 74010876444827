import { useSelector } from 'react-redux'

import { RootState } from 'store'
import { DBProject } from 'types/db'

export type ProjectRoles = 'OWNER' | 'WRITER' | 'READER' | 'COMMENTER'

const useProjectUserRole = (project?: DBProject): ProjectRoles | null => {
  const currentUserEmail = useSelector(
    (state: RootState) => state.firebase.auth.email ?? ''
  )

  const currentUserId = useSelector(
    (state: RootState) => state.firebase.auth.uid
  )

  if (!currentUserEmail || !currentUserId || !project) {
    return null
  }

  if (project.owners.includes(currentUserEmail)) {
    return 'OWNER'
  }

  if (project.writers.includes(currentUserEmail)) {
    return 'WRITER'
  }

  if (project.commenters.includes(currentUserEmail)) {
    return 'COMMENTER'
  }

  if (project.readers.includes(currentUserEmail)) {
    return 'READER'
  }

  if (project.aggregatedOwnerAccess?.[currentUserId]) {
    return 'OWNER'
  }

  if (project.aggregatedWriterAccess?.[currentUserId]) {
    return 'WRITER'
  }

  if (project.aggregatedCommenterAccess?.[currentUserId]) {
    return 'COMMENTER'
  }

  if (project.aggregatedReaderAccess?.[currentUserId]) {
    return 'READER'
  }

  return null as never
}

export default useProjectUserRole
