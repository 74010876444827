/* eslint-disable @typescript-eslint/naming-convention */
import frechet from 'frechet'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const palette4 = [
  { rgb: { r: 63, g: 63, b: 63 }, hex: '#3f3f3f' },
  { rgb: { r: 63, g: 63, b: 127 }, hex: '#3f3f7f' },
  { rgb: { r: 63, g: 63, b: 191 }, hex: '#3f3fbf' },
  { rgb: { r: 63, g: 63, b: 255 }, hex: '#3f3fff' },
  { rgb: { r: 63, g: 127, b: 63 }, hex: '#3f7f3f' },
  { rgb: { r: 63, g: 127, b: 127 }, hex: '#3f7f7f' },
  { rgb: { r: 63, g: 127, b: 191 }, hex: '#3f7fbf' },
  { rgb: { r: 63, g: 127, b: 255 }, hex: '#3f7fff' },
  { rgb: { r: 63, g: 191, b: 63 }, hex: '#3fbf3f' },
  { rgb: { r: 63, g: 191, b: 127 }, hex: '#3fbf7f' },
  { rgb: { r: 63, g: 191, b: 191 }, hex: '#3fbfbf' },
  { rgb: { r: 63, g: 191, b: 255 }, hex: '#3fbfff' },
  { rgb: { r: 63, g: 255, b: 63 }, hex: '#3fff3f' },
  { rgb: { r: 63, g: 255, b: 127 }, hex: '#3fff7f' },
  { rgb: { r: 63, g: 255, b: 191 }, hex: '#3fffbf' },
  { rgb: { r: 63, g: 255, b: 255 }, hex: '#3fffff' },
  { rgb: { r: 127, g: 63, b: 63 }, hex: '#7f3f3f' },
  { rgb: { r: 127, g: 63, b: 127 }, hex: '#7f3f7f' },
  { rgb: { r: 127, g: 63, b: 191 }, hex: '#7f3fbf' },
  { rgb: { r: 127, g: 63, b: 255 }, hex: '#7f3fff' },
  { rgb: { r: 127, g: 127, b: 63 }, hex: '#7f7f3f' },
  { rgb: { r: 127, g: 127, b: 127 }, hex: '#7f7f7f' },
  { rgb: { r: 127, g: 127, b: 191 }, hex: '#7f7fbf' },
  { rgb: { r: 127, g: 127, b: 255 }, hex: '#7f7fff' },
  { rgb: { r: 127, g: 191, b: 63 }, hex: '#7fbf3f' },
  { rgb: { r: 127, g: 191, b: 127 }, hex: '#7fbf7f' },
  { rgb: { r: 127, g: 191, b: 191 }, hex: '#7fbfbf' },
  { rgb: { r: 127, g: 191, b: 255 }, hex: '#7fbfff' },
  { rgb: { r: 127, g: 255, b: 63 }, hex: '#7fff3f' },
  { rgb: { r: 127, g: 255, b: 127 }, hex: '#7fff7f' },
  { rgb: { r: 127, g: 255, b: 191 }, hex: '#7fffbf' },
  { rgb: { r: 127, g: 255, b: 255 }, hex: '#7fffff' },
  { rgb: { r: 191, g: 63, b: 63 }, hex: '#bf3f3f' },
  { rgb: { r: 191, g: 63, b: 127 }, hex: '#bf3f7f' },
  { rgb: { r: 191, g: 63, b: 191 }, hex: '#bf3fbf' },
  { rgb: { r: 191, g: 63, b: 255 }, hex: '#bf3fff' },
  { rgb: { r: 191, g: 127, b: 63 }, hex: '#bf7f3f' },
  { rgb: { r: 191, g: 127, b: 127 }, hex: '#bf7f7f' },
  { rgb: { r: 191, g: 127, b: 191 }, hex: '#bf7fbf' },
  { rgb: { r: 191, g: 127, b: 255 }, hex: '#bf7fff' },
  { rgb: { r: 191, g: 191, b: 63 }, hex: '#bfbf3f' },
  { rgb: { r: 191, g: 191, b: 127 }, hex: '#bfbf7f' },
  { rgb: { r: 191, g: 191, b: 191 }, hex: '#bfbfbf' },
  { rgb: { r: 191, g: 191, b: 255 }, hex: '#bfbfff' },
  { rgb: { r: 191, g: 255, b: 63 }, hex: '#bfff3f' },
  { rgb: { r: 191, g: 255, b: 127 }, hex: '#bfff7f' },
  { rgb: { r: 191, g: 255, b: 191 }, hex: '#bfffbf' },
  { rgb: { r: 191, g: 255, b: 255 }, hex: '#bfffff' },
  { rgb: { r: 255, g: 63, b: 63 }, hex: '#ff3f3f' },
  { rgb: { r: 255, g: 63, b: 127 }, hex: '#ff3f7f' },
  { rgb: { r: 255, g: 63, b: 191 }, hex: '#ff3fbf' },
  { rgb: { r: 255, g: 63, b: 255 }, hex: '#ff3fff' },
  { rgb: { r: 255, g: 127, b: 63 }, hex: '#ff7f3f' },
  { rgb: { r: 255, g: 127, b: 127 }, hex: '#ff7f7f' },
  { rgb: { r: 255, g: 127, b: 191 }, hex: '#ff7fbf' },
  { rgb: { r: 255, g: 127, b: 255 }, hex: '#ff7fff' },
  { rgb: { r: 255, g: 191, b: 63 }, hex: '#ffbf3f' },
  { rgb: { r: 255, g: 191, b: 127 }, hex: '#ffbf7f' },
  { rgb: { r: 255, g: 191, b: 191 }, hex: '#ffbfbf' },
  { rgb: { r: 255, g: 191, b: 255 }, hex: '#ffbfff' },
  { rgb: { r: 255, g: 255, b: 63 }, hex: '#ffff3f' },
  { rgb: { r: 255, g: 255, b: 127 }, hex: '#ffff7f' },
  { rgb: { r: 255, g: 255, b: 191 }, hex: '#ffffbf' },
  { rgb: { r: 255, g: 255, b: 255 }, hex: '#ffffff' },
]

const palette6 = [
  { rgb: { r: 42, g: 42, b: 42 }, hex: '#2a2a2a' },
  { rgb: { r: 42, g: 42, b: 85 }, hex: '#2a2a55' },
  { rgb: { r: 42, g: 42, b: 127 }, hex: '#2a2a7f' },
  { rgb: { r: 42, g: 42, b: 170 }, hex: '#2a2aaa' },
  { rgb: { r: 42, g: 42, b: 212 }, hex: '#2a2ad4' },
  { rgb: { r: 42, g: 42, b: 255 }, hex: '#2a2aff' },
  { rgb: { r: 42, g: 85, b: 42 }, hex: '#2a552a' },
  { rgb: { r: 42, g: 85, b: 85 }, hex: '#2a5555' },
  { rgb: { r: 42, g: 85, b: 127 }, hex: '#2a557f' },
  { rgb: { r: 42, g: 85, b: 170 }, hex: '#2a55aa' },
  { rgb: { r: 42, g: 85, b: 212 }, hex: '#2a55d4' },
  { rgb: { r: 42, g: 85, b: 255 }, hex: '#2a55ff' },
  { rgb: { r: 42, g: 127, b: 42 }, hex: '#2a7f2a' },
  { rgb: { r: 42, g: 127, b: 85 }, hex: '#2a7f55' },
  { rgb: { r: 42, g: 127, b: 127 }, hex: '#2a7f7f' },
  { rgb: { r: 42, g: 127, b: 170 }, hex: '#2a7faa' },
  { rgb: { r: 42, g: 127, b: 212 }, hex: '#2a7fd4' },
  { rgb: { r: 42, g: 127, b: 255 }, hex: '#2a7fff' },
  { rgb: { r: 42, g: 170, b: 42 }, hex: '#2aaa2a' },
  { rgb: { r: 42, g: 170, b: 85 }, hex: '#2aaa55' },
  { rgb: { r: 42, g: 170, b: 127 }, hex: '#2aaa7f' },
  { rgb: { r: 42, g: 170, b: 170 }, hex: '#2aaaaa' },
  { rgb: { r: 42, g: 170, b: 212 }, hex: '#2aaad4' },
  { rgb: { r: 42, g: 170, b: 255 }, hex: '#2aaaff' },
  { rgb: { r: 42, g: 212, b: 42 }, hex: '#2ad42a' },
  { rgb: { r: 42, g: 212, b: 85 }, hex: '#2ad455' },
  { rgb: { r: 42, g: 212, b: 127 }, hex: '#2ad47f' },
  { rgb: { r: 42, g: 212, b: 170 }, hex: '#2ad4aa' },
  { rgb: { r: 42, g: 212, b: 212 }, hex: '#2ad4d4' },
  { rgb: { r: 42, g: 212, b: 255 }, hex: '#2ad4ff' },
  { rgb: { r: 42, g: 255, b: 42 }, hex: '#2aff2a' },
  { rgb: { r: 42, g: 255, b: 85 }, hex: '#2aff55' },
  { rgb: { r: 42, g: 255, b: 127 }, hex: '#2aff7f' },
  { rgb: { r: 42, g: 255, b: 170 }, hex: '#2affaa' },
  { rgb: { r: 42, g: 255, b: 212 }, hex: '#2affd4' },
  { rgb: { r: 42, g: 255, b: 255 }, hex: '#2affff' },
  { rgb: { r: 85, g: 42, b: 42 }, hex: '#552a2a' },
  { rgb: { r: 85, g: 42, b: 85 }, hex: '#552a55' },
  { rgb: { r: 85, g: 42, b: 127 }, hex: '#552a7f' },
  { rgb: { r: 85, g: 42, b: 170 }, hex: '#552aaa' },
  { rgb: { r: 85, g: 42, b: 212 }, hex: '#552ad4' },
  { rgb: { r: 85, g: 42, b: 255 }, hex: '#552aff' },
  { rgb: { r: 85, g: 85, b: 42 }, hex: '#55552a' },
  { rgb: { r: 85, g: 85, b: 85 }, hex: '#555555' },
  { rgb: { r: 85, g: 85, b: 127 }, hex: '#55557f' },
  { rgb: { r: 85, g: 85, b: 170 }, hex: '#5555aa' },
  { rgb: { r: 85, g: 85, b: 212 }, hex: '#5555d4' },
  { rgb: { r: 85, g: 85, b: 255 }, hex: '#5555ff' },
  { rgb: { r: 85, g: 127, b: 42 }, hex: '#557f2a' },
  { rgb: { r: 85, g: 127, b: 85 }, hex: '#557f55' },
  { rgb: { r: 85, g: 127, b: 127 }, hex: '#557f7f' },
  { rgb: { r: 85, g: 127, b: 170 }, hex: '#557faa' },
  { rgb: { r: 85, g: 127, b: 212 }, hex: '#557fd4' },
  { rgb: { r: 85, g: 127, b: 255 }, hex: '#557fff' },
  { rgb: { r: 85, g: 170, b: 42 }, hex: '#55aa2a' },
  { rgb: { r: 85, g: 170, b: 85 }, hex: '#55aa55' },
  { rgb: { r: 85, g: 170, b: 127 }, hex: '#55aa7f' },
  { rgb: { r: 85, g: 170, b: 170 }, hex: '#55aaaa' },
  { rgb: { r: 85, g: 170, b: 212 }, hex: '#55aad4' },
  { rgb: { r: 85, g: 170, b: 255 }, hex: '#55aaff' },
  { rgb: { r: 85, g: 212, b: 42 }, hex: '#55d42a' },
  { rgb: { r: 85, g: 212, b: 85 }, hex: '#55d455' },
  { rgb: { r: 85, g: 212, b: 127 }, hex: '#55d47f' },
  { rgb: { r: 85, g: 212, b: 170 }, hex: '#55d4aa' },
  { rgb: { r: 85, g: 212, b: 212 }, hex: '#55d4d4' },
  { rgb: { r: 85, g: 212, b: 255 }, hex: '#55d4ff' },
  { rgb: { r: 85, g: 255, b: 42 }, hex: '#55ff2a' },
  { rgb: { r: 85, g: 255, b: 85 }, hex: '#55ff55' },
  { rgb: { r: 85, g: 255, b: 127 }, hex: '#55ff7f' },
  { rgb: { r: 85, g: 255, b: 170 }, hex: '#55ffaa' },
  { rgb: { r: 85, g: 255, b: 212 }, hex: '#55ffd4' },
  { rgb: { r: 85, g: 255, b: 255 }, hex: '#55ffff' },
  { rgb: { r: 127, g: 42, b: 42 }, hex: '#7f2a2a' },
  { rgb: { r: 127, g: 42, b: 85 }, hex: '#7f2a55' },
  { rgb: { r: 127, g: 42, b: 127 }, hex: '#7f2a7f' },
  { rgb: { r: 127, g: 42, b: 170 }, hex: '#7f2aaa' },
  { rgb: { r: 127, g: 42, b: 212 }, hex: '#7f2ad4' },
  { rgb: { r: 127, g: 42, b: 255 }, hex: '#7f2aff' },
  { rgb: { r: 127, g: 85, b: 42 }, hex: '#7f552a' },
  { rgb: { r: 127, g: 85, b: 85 }, hex: '#7f5555' },
  { rgb: { r: 127, g: 85, b: 127 }, hex: '#7f557f' },
  { rgb: { r: 127, g: 85, b: 170 }, hex: '#7f55aa' },
  { rgb: { r: 127, g: 85, b: 212 }, hex: '#7f55d4' },
  { rgb: { r: 127, g: 85, b: 255 }, hex: '#7f55ff' },
  { rgb: { r: 127, g: 127, b: 42 }, hex: '#7f7f2a' },
  { rgb: { r: 127, g: 127, b: 85 }, hex: '#7f7f55' },
  { rgb: { r: 127, g: 127, b: 127 }, hex: '#7f7f7f' },
  { rgb: { r: 127, g: 127, b: 170 }, hex: '#7f7faa' },
  { rgb: { r: 127, g: 127, b: 212 }, hex: '#7f7fd4' },
  { rgb: { r: 127, g: 127, b: 255 }, hex: '#7f7fff' },
  { rgb: { r: 127, g: 170, b: 42 }, hex: '#7faa2a' },
  { rgb: { r: 127, g: 170, b: 85 }, hex: '#7faa55' },
  { rgb: { r: 127, g: 170, b: 127 }, hex: '#7faa7f' },
  { rgb: { r: 127, g: 170, b: 170 }, hex: '#7faaaa' },
  { rgb: { r: 127, g: 170, b: 212 }, hex: '#7faad4' },
  { rgb: { r: 127, g: 170, b: 255 }, hex: '#7faaff' },
  { rgb: { r: 127, g: 212, b: 42 }, hex: '#7fd42a' },
  { rgb: { r: 127, g: 212, b: 85 }, hex: '#7fd455' },
  { rgb: { r: 127, g: 212, b: 127 }, hex: '#7fd47f' },
  { rgb: { r: 127, g: 212, b: 170 }, hex: '#7fd4aa' },
  { rgb: { r: 127, g: 212, b: 212 }, hex: '#7fd4d4' },
  { rgb: { r: 127, g: 212, b: 255 }, hex: '#7fd4ff' },
  { rgb: { r: 127, g: 255, b: 42 }, hex: '#7fff2a' },
  { rgb: { r: 127, g: 255, b: 85 }, hex: '#7fff55' },
  { rgb: { r: 127, g: 255, b: 127 }, hex: '#7fff7f' },
  { rgb: { r: 127, g: 255, b: 170 }, hex: '#7fffaa' },
  { rgb: { r: 127, g: 255, b: 212 }, hex: '#7fffd4' },
  { rgb: { r: 127, g: 255, b: 255 }, hex: '#7fffff' },
  { rgb: { r: 170, g: 42, b: 42 }, hex: '#aa2a2a' },
  { rgb: { r: 170, g: 42, b: 85 }, hex: '#aa2a55' },
  { rgb: { r: 170, g: 42, b: 127 }, hex: '#aa2a7f' },
  { rgb: { r: 170, g: 42, b: 170 }, hex: '#aa2aaa' },
  { rgb: { r: 170, g: 42, b: 212 }, hex: '#aa2ad4' },
  { rgb: { r: 170, g: 42, b: 255 }, hex: '#aa2aff' },
  { rgb: { r: 170, g: 85, b: 42 }, hex: '#aa552a' },
  { rgb: { r: 170, g: 85, b: 85 }, hex: '#aa5555' },
  { rgb: { r: 170, g: 85, b: 127 }, hex: '#aa557f' },
  { rgb: { r: 170, g: 85, b: 170 }, hex: '#aa55aa' },
  { rgb: { r: 170, g: 85, b: 212 }, hex: '#aa55d4' },
  { rgb: { r: 170, g: 85, b: 255 }, hex: '#aa55ff' },
  { rgb: { r: 170, g: 127, b: 42 }, hex: '#aa7f2a' },
  { rgb: { r: 170, g: 127, b: 85 }, hex: '#aa7f55' },
  { rgb: { r: 170, g: 127, b: 127 }, hex: '#aa7f7f' },
  { rgb: { r: 170, g: 127, b: 170 }, hex: '#aa7faa' },
  { rgb: { r: 170, g: 127, b: 212 }, hex: '#aa7fd4' },
  { rgb: { r: 170, g: 127, b: 255 }, hex: '#aa7fff' },
  { rgb: { r: 170, g: 170, b: 42 }, hex: '#aaaa2a' },
  { rgb: { r: 170, g: 170, b: 85 }, hex: '#aaaa55' },
  { rgb: { r: 170, g: 170, b: 127 }, hex: '#aaaa7f' },
  { rgb: { r: 170, g: 170, b: 170 }, hex: '#aaaaaa' },
  { rgb: { r: 170, g: 170, b: 212 }, hex: '#aaaad4' },
  { rgb: { r: 170, g: 170, b: 255 }, hex: '#aaaaff' },
  { rgb: { r: 170, g: 212, b: 42 }, hex: '#aad42a' },
  { rgb: { r: 170, g: 212, b: 85 }, hex: '#aad455' },
  { rgb: { r: 170, g: 212, b: 127 }, hex: '#aad47f' },
  { rgb: { r: 170, g: 212, b: 170 }, hex: '#aad4aa' },
  { rgb: { r: 170, g: 212, b: 212 }, hex: '#aad4d4' },
  { rgb: { r: 170, g: 212, b: 255 }, hex: '#aad4ff' },
  { rgb: { r: 170, g: 255, b: 42 }, hex: '#aaff2a' },
  { rgb: { r: 170, g: 255, b: 85 }, hex: '#aaff55' },
  { rgb: { r: 170, g: 255, b: 127 }, hex: '#aaff7f' },
  { rgb: { r: 170, g: 255, b: 170 }, hex: '#aaffaa' },
  { rgb: { r: 170, g: 255, b: 212 }, hex: '#aaffd4' },
  { rgb: { r: 170, g: 255, b: 255 }, hex: '#aaffff' },
  { rgb: { r: 212, g: 42, b: 42 }, hex: '#d42a2a' },
  { rgb: { r: 212, g: 42, b: 85 }, hex: '#d42a55' },
  { rgb: { r: 212, g: 42, b: 127 }, hex: '#d42a7f' },
  { rgb: { r: 212, g: 42, b: 170 }, hex: '#d42aaa' },
  { rgb: { r: 212, g: 42, b: 212 }, hex: '#d42ad4' },
  { rgb: { r: 212, g: 42, b: 255 }, hex: '#d42aff' },
  { rgb: { r: 212, g: 85, b: 42 }, hex: '#d4552a' },
  { rgb: { r: 212, g: 85, b: 85 }, hex: '#d45555' },
  { rgb: { r: 212, g: 85, b: 127 }, hex: '#d4557f' },
  { rgb: { r: 212, g: 85, b: 170 }, hex: '#d455aa' },
  { rgb: { r: 212, g: 85, b: 212 }, hex: '#d455d4' },
  { rgb: { r: 212, g: 85, b: 255 }, hex: '#d455ff' },
  { rgb: { r: 212, g: 127, b: 42 }, hex: '#d47f2a' },
  { rgb: { r: 212, g: 127, b: 85 }, hex: '#d47f55' },
  { rgb: { r: 212, g: 127, b: 127 }, hex: '#d47f7f' },
  { rgb: { r: 212, g: 127, b: 170 }, hex: '#d47faa' },
  { rgb: { r: 212, g: 127, b: 212 }, hex: '#d47fd4' },
  { rgb: { r: 212, g: 127, b: 255 }, hex: '#d47fff' },
  { rgb: { r: 212, g: 170, b: 42 }, hex: '#d4aa2a' },
  { rgb: { r: 212, g: 170, b: 85 }, hex: '#d4aa55' },
  { rgb: { r: 212, g: 170, b: 127 }, hex: '#d4aa7f' },
  { rgb: { r: 212, g: 170, b: 170 }, hex: '#d4aaaa' },
  { rgb: { r: 212, g: 170, b: 212 }, hex: '#d4aad4' },
  { rgb: { r: 212, g: 170, b: 255 }, hex: '#d4aaff' },
  { rgb: { r: 212, g: 212, b: 42 }, hex: '#d4d42a' },
  { rgb: { r: 212, g: 212, b: 85 }, hex: '#d4d455' },
  { rgb: { r: 212, g: 212, b: 127 }, hex: '#d4d47f' },
  { rgb: { r: 212, g: 212, b: 170 }, hex: '#d4d4aa' },
  { rgb: { r: 212, g: 212, b: 212 }, hex: '#d4d4d4' },
  { rgb: { r: 212, g: 212, b: 255 }, hex: '#d4d4ff' },
  { rgb: { r: 212, g: 255, b: 42 }, hex: '#d4ff2a' },
  { rgb: { r: 212, g: 255, b: 85 }, hex: '#d4ff55' },
  { rgb: { r: 212, g: 255, b: 127 }, hex: '#d4ff7f' },
  { rgb: { r: 212, g: 255, b: 170 }, hex: '#d4ffaa' },
  { rgb: { r: 212, g: 255, b: 212 }, hex: '#d4ffd4' },
  { rgb: { r: 212, g: 255, b: 255 }, hex: '#d4ffff' },
  { rgb: { r: 255, g: 42, b: 42 }, hex: '#ff2a2a' },
  { rgb: { r: 255, g: 42, b: 85 }, hex: '#ff2a55' },
  { rgb: { r: 255, g: 42, b: 127 }, hex: '#ff2a7f' },
  { rgb: { r: 255, g: 42, b: 170 }, hex: '#ff2aaa' },
  { rgb: { r: 255, g: 42, b: 212 }, hex: '#ff2ad4' },
  { rgb: { r: 255, g: 42, b: 255 }, hex: '#ff2aff' },
  { rgb: { r: 255, g: 85, b: 42 }, hex: '#ff552a' },
  { rgb: { r: 255, g: 85, b: 85 }, hex: '#ff5555' },
  { rgb: { r: 255, g: 85, b: 127 }, hex: '#ff557f' },
  { rgb: { r: 255, g: 85, b: 170 }, hex: '#ff55aa' },
  { rgb: { r: 255, g: 85, b: 212 }, hex: '#ff55d4' },
  { rgb: { r: 255, g: 85, b: 255 }, hex: '#ff55ff' },
  { rgb: { r: 255, g: 127, b: 42 }, hex: '#ff7f2a' },
  { rgb: { r: 255, g: 127, b: 85 }, hex: '#ff7f55' },
  { rgb: { r: 255, g: 127, b: 127 }, hex: '#ff7f7f' },
  { rgb: { r: 255, g: 127, b: 170 }, hex: '#ff7faa' },
  { rgb: { r: 255, g: 127, b: 212 }, hex: '#ff7fd4' },
  { rgb: { r: 255, g: 127, b: 255 }, hex: '#ff7fff' },
  { rgb: { r: 255, g: 170, b: 42 }, hex: '#ffaa2a' },
  { rgb: { r: 255, g: 170, b: 85 }, hex: '#ffaa55' },
  { rgb: { r: 255, g: 170, b: 127 }, hex: '#ffaa7f' },
  { rgb: { r: 255, g: 170, b: 170 }, hex: '#ffaaaa' },
  { rgb: { r: 255, g: 170, b: 212 }, hex: '#ffaad4' },
  { rgb: { r: 255, g: 170, b: 255 }, hex: '#ffaaff' },
  { rgb: { r: 255, g: 212, b: 42 }, hex: '#ffd42a' },
  { rgb: { r: 255, g: 212, b: 85 }, hex: '#ffd455' },
  { rgb: { r: 255, g: 212, b: 127 }, hex: '#ffd47f' },
  { rgb: { r: 255, g: 212, b: 170 }, hex: '#ffd4aa' },
  { rgb: { r: 255, g: 212, b: 212 }, hex: '#ffd4d4' },
  { rgb: { r: 255, g: 212, b: 255 }, hex: '#ffd4ff' },
  { rgb: { r: 255, g: 255, b: 42 }, hex: '#ffff2a' },
  { rgb: { r: 255, g: 255, b: 85 }, hex: '#ffff55' },
  { rgb: { r: 255, g: 255, b: 127 }, hex: '#ffff7f' },
  { rgb: { r: 255, g: 255, b: 170 }, hex: '#ffffaa' },
  { rgb: { r: 255, g: 255, b: 212 }, hex: '#ffffd4' },
  { rgb: { r: 255, g: 255, b: 255 }, hex: '#ffffff' },
]

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const palette8 = [
  { rgb: { r: 31, g: 31, b: 31 }, hex: '#1f1f1f' },
  { rgb: { r: 31, g: 31, b: 63 }, hex: '#1f1f3f' },
  { rgb: { r: 31, g: 31, b: 95 }, hex: '#1f1f5f' },
  { rgb: { r: 31, g: 31, b: 127 }, hex: '#1f1f7f' },
  { rgb: { r: 31, g: 31, b: 159 }, hex: '#1f1f9f' },
  { rgb: { r: 31, g: 31, b: 191 }, hex: '#1f1fbf' },
  { rgb: { r: 31, g: 31, b: 223 }, hex: '#1f1fdf' },
  { rgb: { r: 31, g: 31, b: 255 }, hex: '#1f1fff' },
  { rgb: { r: 31, g: 63, b: 31 }, hex: '#1f3f1f' },
  { rgb: { r: 31, g: 63, b: 63 }, hex: '#1f3f3f' },
  { rgb: { r: 31, g: 63, b: 95 }, hex: '#1f3f5f' },
  { rgb: { r: 31, g: 63, b: 127 }, hex: '#1f3f7f' },
  { rgb: { r: 31, g: 63, b: 159 }, hex: '#1f3f9f' },
  { rgb: { r: 31, g: 63, b: 191 }, hex: '#1f3fbf' },
  { rgb: { r: 31, g: 63, b: 223 }, hex: '#1f3fdf' },
  { rgb: { r: 31, g: 63, b: 255 }, hex: '#1f3fff' },
  { rgb: { r: 31, g: 95, b: 31 }, hex: '#1f5f1f' },
  { rgb: { r: 31, g: 95, b: 63 }, hex: '#1f5f3f' },
  { rgb: { r: 31, g: 95, b: 95 }, hex: '#1f5f5f' },
  { rgb: { r: 31, g: 95, b: 127 }, hex: '#1f5f7f' },
  { rgb: { r: 31, g: 95, b: 159 }, hex: '#1f5f9f' },
  { rgb: { r: 31, g: 95, b: 191 }, hex: '#1f5fbf' },
  { rgb: { r: 31, g: 95, b: 223 }, hex: '#1f5fdf' },
  { rgb: { r: 31, g: 95, b: 255 }, hex: '#1f5fff' },
  { rgb: { r: 31, g: 127, b: 31 }, hex: '#1f7f1f' },
  { rgb: { r: 31, g: 127, b: 63 }, hex: '#1f7f3f' },
  { rgb: { r: 31, g: 127, b: 95 }, hex: '#1f7f5f' },
  { rgb: { r: 31, g: 127, b: 127 }, hex: '#1f7f7f' },
  { rgb: { r: 31, g: 127, b: 159 }, hex: '#1f7f9f' },
  { rgb: { r: 31, g: 127, b: 191 }, hex: '#1f7fbf' },
  { rgb: { r: 31, g: 127, b: 223 }, hex: '#1f7fdf' },
  { rgb: { r: 31, g: 127, b: 255 }, hex: '#1f7fff' },
  { rgb: { r: 31, g: 159, b: 31 }, hex: '#1f9f1f' },
  { rgb: { r: 31, g: 159, b: 63 }, hex: '#1f9f3f' },
  { rgb: { r: 31, g: 159, b: 95 }, hex: '#1f9f5f' },
  { rgb: { r: 31, g: 159, b: 127 }, hex: '#1f9f7f' },
  { rgb: { r: 31, g: 159, b: 159 }, hex: '#1f9f9f' },
  { rgb: { r: 31, g: 159, b: 191 }, hex: '#1f9fbf' },
  { rgb: { r: 31, g: 159, b: 223 }, hex: '#1f9fdf' },
  { rgb: { r: 31, g: 159, b: 255 }, hex: '#1f9fff' },
  { rgb: { r: 31, g: 191, b: 31 }, hex: '#1fbf1f' },
  { rgb: { r: 31, g: 191, b: 63 }, hex: '#1fbf3f' },
  { rgb: { r: 31, g: 191, b: 95 }, hex: '#1fbf5f' },
  { rgb: { r: 31, g: 191, b: 127 }, hex: '#1fbf7f' },
  { rgb: { r: 31, g: 191, b: 159 }, hex: '#1fbf9f' },
  { rgb: { r: 31, g: 191, b: 191 }, hex: '#1fbfbf' },
  { rgb: { r: 31, g: 191, b: 223 }, hex: '#1fbfdf' },
  { rgb: { r: 31, g: 191, b: 255 }, hex: '#1fbfff' },
  { rgb: { r: 31, g: 223, b: 31 }, hex: '#1fdf1f' },
  { rgb: { r: 31, g: 223, b: 63 }, hex: '#1fdf3f' },
  { rgb: { r: 31, g: 223, b: 95 }, hex: '#1fdf5f' },
  { rgb: { r: 31, g: 223, b: 127 }, hex: '#1fdf7f' },
  { rgb: { r: 31, g: 223, b: 159 }, hex: '#1fdf9f' },
  { rgb: { r: 31, g: 223, b: 191 }, hex: '#1fdfbf' },
  { rgb: { r: 31, g: 223, b: 223 }, hex: '#1fdfdf' },
  { rgb: { r: 31, g: 223, b: 255 }, hex: '#1fdfff' },
  { rgb: { r: 31, g: 255, b: 31 }, hex: '#1fff1f' },
  { rgb: { r: 31, g: 255, b: 63 }, hex: '#1fff3f' },
  { rgb: { r: 31, g: 255, b: 95 }, hex: '#1fff5f' },
  { rgb: { r: 31, g: 255, b: 127 }, hex: '#1fff7f' },
  { rgb: { r: 31, g: 255, b: 159 }, hex: '#1fff9f' },
  { rgb: { r: 31, g: 255, b: 191 }, hex: '#1fffbf' },
  { rgb: { r: 31, g: 255, b: 223 }, hex: '#1fffdf' },
  { rgb: { r: 31, g: 255, b: 255 }, hex: '#1fffff' },
  { rgb: { r: 63, g: 31, b: 31 }, hex: '#3f1f1f' },
  { rgb: { r: 63, g: 31, b: 63 }, hex: '#3f1f3f' },
  { rgb: { r: 63, g: 31, b: 95 }, hex: '#3f1f5f' },
  { rgb: { r: 63, g: 31, b: 127 }, hex: '#3f1f7f' },
  { rgb: { r: 63, g: 31, b: 159 }, hex: '#3f1f9f' },
  { rgb: { r: 63, g: 31, b: 191 }, hex: '#3f1fbf' },
  { rgb: { r: 63, g: 31, b: 223 }, hex: '#3f1fdf' },
  { rgb: { r: 63, g: 31, b: 255 }, hex: '#3f1fff' },
  { rgb: { r: 63, g: 63, b: 31 }, hex: '#3f3f1f' },
  { rgb: { r: 63, g: 63, b: 63 }, hex: '#3f3f3f' },
  { rgb: { r: 63, g: 63, b: 95 }, hex: '#3f3f5f' },
  { rgb: { r: 63, g: 63, b: 127 }, hex: '#3f3f7f' },
  { rgb: { r: 63, g: 63, b: 159 }, hex: '#3f3f9f' },
  { rgb: { r: 63, g: 63, b: 191 }, hex: '#3f3fbf' },
  { rgb: { r: 63, g: 63, b: 223 }, hex: '#3f3fdf' },
  { rgb: { r: 63, g: 63, b: 255 }, hex: '#3f3fff' },
  { rgb: { r: 63, g: 95, b: 31 }, hex: '#3f5f1f' },
  { rgb: { r: 63, g: 95, b: 63 }, hex: '#3f5f3f' },
  { rgb: { r: 63, g: 95, b: 95 }, hex: '#3f5f5f' },
  { rgb: { r: 63, g: 95, b: 127 }, hex: '#3f5f7f' },
  { rgb: { r: 63, g: 95, b: 159 }, hex: '#3f5f9f' },
  { rgb: { r: 63, g: 95, b: 191 }, hex: '#3f5fbf' },
  { rgb: { r: 63, g: 95, b: 223 }, hex: '#3f5fdf' },
  { rgb: { r: 63, g: 95, b: 255 }, hex: '#3f5fff' },
  { rgb: { r: 63, g: 127, b: 31 }, hex: '#3f7f1f' },
  { rgb: { r: 63, g: 127, b: 63 }, hex: '#3f7f3f' },
  { rgb: { r: 63, g: 127, b: 95 }, hex: '#3f7f5f' },
  { rgb: { r: 63, g: 127, b: 127 }, hex: '#3f7f7f' },
  { rgb: { r: 63, g: 127, b: 159 }, hex: '#3f7f9f' },
  { rgb: { r: 63, g: 127, b: 191 }, hex: '#3f7fbf' },
  { rgb: { r: 63, g: 127, b: 223 }, hex: '#3f7fdf' },
  { rgb: { r: 63, g: 127, b: 255 }, hex: '#3f7fff' },
  { rgb: { r: 63, g: 159, b: 31 }, hex: '#3f9f1f' },
  { rgb: { r: 63, g: 159, b: 63 }, hex: '#3f9f3f' },
  { rgb: { r: 63, g: 159, b: 95 }, hex: '#3f9f5f' },
  { rgb: { r: 63, g: 159, b: 127 }, hex: '#3f9f7f' },
  { rgb: { r: 63, g: 159, b: 159 }, hex: '#3f9f9f' },
  { rgb: { r: 63, g: 159, b: 191 }, hex: '#3f9fbf' },
  { rgb: { r: 63, g: 159, b: 223 }, hex: '#3f9fdf' },
  { rgb: { r: 63, g: 159, b: 255 }, hex: '#3f9fff' },
  { rgb: { r: 63, g: 191, b: 31 }, hex: '#3fbf1f' },
  { rgb: { r: 63, g: 191, b: 63 }, hex: '#3fbf3f' },
  { rgb: { r: 63, g: 191, b: 95 }, hex: '#3fbf5f' },
  { rgb: { r: 63, g: 191, b: 127 }, hex: '#3fbf7f' },
  { rgb: { r: 63, g: 191, b: 159 }, hex: '#3fbf9f' },
  { rgb: { r: 63, g: 191, b: 191 }, hex: '#3fbfbf' },
  { rgb: { r: 63, g: 191, b: 223 }, hex: '#3fbfdf' },
  { rgb: { r: 63, g: 191, b: 255 }, hex: '#3fbfff' },
  { rgb: { r: 63, g: 223, b: 31 }, hex: '#3fdf1f' },
  { rgb: { r: 63, g: 223, b: 63 }, hex: '#3fdf3f' },
  { rgb: { r: 63, g: 223, b: 95 }, hex: '#3fdf5f' },
  { rgb: { r: 63, g: 223, b: 127 }, hex: '#3fdf7f' },
  { rgb: { r: 63, g: 223, b: 159 }, hex: '#3fdf9f' },
  { rgb: { r: 63, g: 223, b: 191 }, hex: '#3fdfbf' },
  { rgb: { r: 63, g: 223, b: 223 }, hex: '#3fdfdf' },
  { rgb: { r: 63, g: 223, b: 255 }, hex: '#3fdfff' },
  { rgb: { r: 63, g: 255, b: 31 }, hex: '#3fff1f' },
  { rgb: { r: 63, g: 255, b: 63 }, hex: '#3fff3f' },
  { rgb: { r: 63, g: 255, b: 95 }, hex: '#3fff5f' },
  { rgb: { r: 63, g: 255, b: 127 }, hex: '#3fff7f' },
  { rgb: { r: 63, g: 255, b: 159 }, hex: '#3fff9f' },
  { rgb: { r: 63, g: 255, b: 191 }, hex: '#3fffbf' },
  { rgb: { r: 63, g: 255, b: 223 }, hex: '#3fffdf' },
  { rgb: { r: 63, g: 255, b: 255 }, hex: '#3fffff' },
  { rgb: { r: 95, g: 31, b: 31 }, hex: '#5f1f1f' },
  { rgb: { r: 95, g: 31, b: 63 }, hex: '#5f1f3f' },
  { rgb: { r: 95, g: 31, b: 95 }, hex: '#5f1f5f' },
  { rgb: { r: 95, g: 31, b: 127 }, hex: '#5f1f7f' },
  { rgb: { r: 95, g: 31, b: 159 }, hex: '#5f1f9f' },
  { rgb: { r: 95, g: 31, b: 191 }, hex: '#5f1fbf' },
  { rgb: { r: 95, g: 31, b: 223 }, hex: '#5f1fdf' },
  { rgb: { r: 95, g: 31, b: 255 }, hex: '#5f1fff' },
  { rgb: { r: 95, g: 63, b: 31 }, hex: '#5f3f1f' },
  { rgb: { r: 95, g: 63, b: 63 }, hex: '#5f3f3f' },
  { rgb: { r: 95, g: 63, b: 95 }, hex: '#5f3f5f' },
  { rgb: { r: 95, g: 63, b: 127 }, hex: '#5f3f7f' },
  { rgb: { r: 95, g: 63, b: 159 }, hex: '#5f3f9f' },
  { rgb: { r: 95, g: 63, b: 191 }, hex: '#5f3fbf' },
  { rgb: { r: 95, g: 63, b: 223 }, hex: '#5f3fdf' },
  { rgb: { r: 95, g: 63, b: 255 }, hex: '#5f3fff' },
  { rgb: { r: 95, g: 95, b: 31 }, hex: '#5f5f1f' },
  { rgb: { r: 95, g: 95, b: 63 }, hex: '#5f5f3f' },
  { rgb: { r: 95, g: 95, b: 95 }, hex: '#5f5f5f' },
  { rgb: { r: 95, g: 95, b: 127 }, hex: '#5f5f7f' },
  { rgb: { r: 95, g: 95, b: 159 }, hex: '#5f5f9f' },
  { rgb: { r: 95, g: 95, b: 191 }, hex: '#5f5fbf' },
  { rgb: { r: 95, g: 95, b: 223 }, hex: '#5f5fdf' },
  { rgb: { r: 95, g: 95, b: 255 }, hex: '#5f5fff' },
  { rgb: { r: 95, g: 127, b: 31 }, hex: '#5f7f1f' },
  { rgb: { r: 95, g: 127, b: 63 }, hex: '#5f7f3f' },
  { rgb: { r: 95, g: 127, b: 95 }, hex: '#5f7f5f' },
  { rgb: { r: 95, g: 127, b: 127 }, hex: '#5f7f7f' },
  { rgb: { r: 95, g: 127, b: 159 }, hex: '#5f7f9f' },
  { rgb: { r: 95, g: 127, b: 191 }, hex: '#5f7fbf' },
  { rgb: { r: 95, g: 127, b: 223 }, hex: '#5f7fdf' },
  { rgb: { r: 95, g: 127, b: 255 }, hex: '#5f7fff' },
  { rgb: { r: 95, g: 159, b: 31 }, hex: '#5f9f1f' },
  { rgb: { r: 95, g: 159, b: 63 }, hex: '#5f9f3f' },
  { rgb: { r: 95, g: 159, b: 95 }, hex: '#5f9f5f' },
  { rgb: { r: 95, g: 159, b: 127 }, hex: '#5f9f7f' },
  { rgb: { r: 95, g: 159, b: 159 }, hex: '#5f9f9f' },
  { rgb: { r: 95, g: 159, b: 191 }, hex: '#5f9fbf' },
  { rgb: { r: 95, g: 159, b: 223 }, hex: '#5f9fdf' },
  { rgb: { r: 95, g: 159, b: 255 }, hex: '#5f9fff' },
  { rgb: { r: 95, g: 191, b: 31 }, hex: '#5fbf1f' },
  { rgb: { r: 95, g: 191, b: 63 }, hex: '#5fbf3f' },
  { rgb: { r: 95, g: 191, b: 95 }, hex: '#5fbf5f' },
  { rgb: { r: 95, g: 191, b: 127 }, hex: '#5fbf7f' },
  { rgb: { r: 95, g: 191, b: 159 }, hex: '#5fbf9f' },
  { rgb: { r: 95, g: 191, b: 191 }, hex: '#5fbfbf' },
  { rgb: { r: 95, g: 191, b: 223 }, hex: '#5fbfdf' },
  { rgb: { r: 95, g: 191, b: 255 }, hex: '#5fbfff' },
  { rgb: { r: 95, g: 223, b: 31 }, hex: '#5fdf1f' },
  { rgb: { r: 95, g: 223, b: 63 }, hex: '#5fdf3f' },
  { rgb: { r: 95, g: 223, b: 95 }, hex: '#5fdf5f' },
  { rgb: { r: 95, g: 223, b: 127 }, hex: '#5fdf7f' },
  { rgb: { r: 95, g: 223, b: 159 }, hex: '#5fdf9f' },
  { rgb: { r: 95, g: 223, b: 191 }, hex: '#5fdfbf' },
  { rgb: { r: 95, g: 223, b: 223 }, hex: '#5fdfdf' },
  { rgb: { r: 95, g: 223, b: 255 }, hex: '#5fdfff' },
  { rgb: { r: 95, g: 255, b: 31 }, hex: '#5fff1f' },
  { rgb: { r: 95, g: 255, b: 63 }, hex: '#5fff3f' },
  { rgb: { r: 95, g: 255, b: 95 }, hex: '#5fff5f' },
  { rgb: { r: 95, g: 255, b: 127 }, hex: '#5fff7f' },
  { rgb: { r: 95, g: 255, b: 159 }, hex: '#5fff9f' },
  { rgb: { r: 95, g: 255, b: 191 }, hex: '#5fffbf' },
  { rgb: { r: 95, g: 255, b: 223 }, hex: '#5fffdf' },
  { rgb: { r: 95, g: 255, b: 255 }, hex: '#5fffff' },
  { rgb: { r: 127, g: 31, b: 31 }, hex: '#7f1f1f' },
  { rgb: { r: 127, g: 31, b: 63 }, hex: '#7f1f3f' },
  { rgb: { r: 127, g: 31, b: 95 }, hex: '#7f1f5f' },
  { rgb: { r: 127, g: 31, b: 127 }, hex: '#7f1f7f' },
  { rgb: { r: 127, g: 31, b: 159 }, hex: '#7f1f9f' },
  { rgb: { r: 127, g: 31, b: 191 }, hex: '#7f1fbf' },
  { rgb: { r: 127, g: 31, b: 223 }, hex: '#7f1fdf' },
  { rgb: { r: 127, g: 31, b: 255 }, hex: '#7f1fff' },
  { rgb: { r: 127, g: 63, b: 31 }, hex: '#7f3f1f' },
  { rgb: { r: 127, g: 63, b: 63 }, hex: '#7f3f3f' },
  { rgb: { r: 127, g: 63, b: 95 }, hex: '#7f3f5f' },
  { rgb: { r: 127, g: 63, b: 127 }, hex: '#7f3f7f' },
  { rgb: { r: 127, g: 63, b: 159 }, hex: '#7f3f9f' },
  { rgb: { r: 127, g: 63, b: 191 }, hex: '#7f3fbf' },
  { rgb: { r: 127, g: 63, b: 223 }, hex: '#7f3fdf' },
  { rgb: { r: 127, g: 63, b: 255 }, hex: '#7f3fff' },
  { rgb: { r: 127, g: 95, b: 31 }, hex: '#7f5f1f' },
  { rgb: { r: 127, g: 95, b: 63 }, hex: '#7f5f3f' },
  { rgb: { r: 127, g: 95, b: 95 }, hex: '#7f5f5f' },
  { rgb: { r: 127, g: 95, b: 127 }, hex: '#7f5f7f' },
  { rgb: { r: 127, g: 95, b: 159 }, hex: '#7f5f9f' },
  { rgb: { r: 127, g: 95, b: 191 }, hex: '#7f5fbf' },
  { rgb: { r: 127, g: 95, b: 223 }, hex: '#7f5fdf' },
  { rgb: { r: 127, g: 95, b: 255 }, hex: '#7f5fff' },
  { rgb: { r: 127, g: 127, b: 31 }, hex: '#7f7f1f' },
  { rgb: { r: 127, g: 127, b: 63 }, hex: '#7f7f3f' },
  { rgb: { r: 127, g: 127, b: 95 }, hex: '#7f7f5f' },
  { rgb: { r: 127, g: 127, b: 127 }, hex: '#7f7f7f' },
  { rgb: { r: 127, g: 127, b: 159 }, hex: '#7f7f9f' },
  { rgb: { r: 127, g: 127, b: 191 }, hex: '#7f7fbf' },
  { rgb: { r: 127, g: 127, b: 223 }, hex: '#7f7fdf' },
  { rgb: { r: 127, g: 127, b: 255 }, hex: '#7f7fff' },
  { rgb: { r: 127, g: 159, b: 31 }, hex: '#7f9f1f' },
  { rgb: { r: 127, g: 159, b: 63 }, hex: '#7f9f3f' },
  { rgb: { r: 127, g: 159, b: 95 }, hex: '#7f9f5f' },
  { rgb: { r: 127, g: 159, b: 127 }, hex: '#7f9f7f' },
  { rgb: { r: 127, g: 159, b: 159 }, hex: '#7f9f9f' },
  { rgb: { r: 127, g: 159, b: 191 }, hex: '#7f9fbf' },
  { rgb: { r: 127, g: 159, b: 223 }, hex: '#7f9fdf' },
  { rgb: { r: 127, g: 159, b: 255 }, hex: '#7f9fff' },
  { rgb: { r: 127, g: 191, b: 31 }, hex: '#7fbf1f' },
  { rgb: { r: 127, g: 191, b: 63 }, hex: '#7fbf3f' },
  { rgb: { r: 127, g: 191, b: 95 }, hex: '#7fbf5f' },
  { rgb: { r: 127, g: 191, b: 127 }, hex: '#7fbf7f' },
  { rgb: { r: 127, g: 191, b: 159 }, hex: '#7fbf9f' },
  { rgb: { r: 127, g: 191, b: 191 }, hex: '#7fbfbf' },
  { rgb: { r: 127, g: 191, b: 223 }, hex: '#7fbfdf' },
  { rgb: { r: 127, g: 191, b: 255 }, hex: '#7fbfff' },
  { rgb: { r: 127, g: 223, b: 31 }, hex: '#7fdf1f' },
  { rgb: { r: 127, g: 223, b: 63 }, hex: '#7fdf3f' },
  { rgb: { r: 127, g: 223, b: 95 }, hex: '#7fdf5f' },
  { rgb: { r: 127, g: 223, b: 127 }, hex: '#7fdf7f' },
  { rgb: { r: 127, g: 223, b: 159 }, hex: '#7fdf9f' },
  { rgb: { r: 127, g: 223, b: 191 }, hex: '#7fdfbf' },
  { rgb: { r: 127, g: 223, b: 223 }, hex: '#7fdfdf' },
  { rgb: { r: 127, g: 223, b: 255 }, hex: '#7fdfff' },
  { rgb: { r: 127, g: 255, b: 31 }, hex: '#7fff1f' },
  { rgb: { r: 127, g: 255, b: 63 }, hex: '#7fff3f' },
  { rgb: { r: 127, g: 255, b: 95 }, hex: '#7fff5f' },
  { rgb: { r: 127, g: 255, b: 127 }, hex: '#7fff7f' },
  { rgb: { r: 127, g: 255, b: 159 }, hex: '#7fff9f' },
  { rgb: { r: 127, g: 255, b: 191 }, hex: '#7fffbf' },
  { rgb: { r: 127, g: 255, b: 223 }, hex: '#7fffdf' },
  { rgb: { r: 127, g: 255, b: 255 }, hex: '#7fffff' },
  { rgb: { r: 159, g: 31, b: 31 }, hex: '#9f1f1f' },
  { rgb: { r: 159, g: 31, b: 63 }, hex: '#9f1f3f' },
  { rgb: { r: 159, g: 31, b: 95 }, hex: '#9f1f5f' },
  { rgb: { r: 159, g: 31, b: 127 }, hex: '#9f1f7f' },
  { rgb: { r: 159, g: 31, b: 159 }, hex: '#9f1f9f' },
  { rgb: { r: 159, g: 31, b: 191 }, hex: '#9f1fbf' },
  { rgb: { r: 159, g: 31, b: 223 }, hex: '#9f1fdf' },
  { rgb: { r: 159, g: 31, b: 255 }, hex: '#9f1fff' },
  { rgb: { r: 159, g: 63, b: 31 }, hex: '#9f3f1f' },
  { rgb: { r: 159, g: 63, b: 63 }, hex: '#9f3f3f' },
  { rgb: { r: 159, g: 63, b: 95 }, hex: '#9f3f5f' },
  { rgb: { r: 159, g: 63, b: 127 }, hex: '#9f3f7f' },
  { rgb: { r: 159, g: 63, b: 159 }, hex: '#9f3f9f' },
  { rgb: { r: 159, g: 63, b: 191 }, hex: '#9f3fbf' },
  { rgb: { r: 159, g: 63, b: 223 }, hex: '#9f3fdf' },
  { rgb: { r: 159, g: 63, b: 255 }, hex: '#9f3fff' },
  { rgb: { r: 159, g: 95, b: 31 }, hex: '#9f5f1f' },
  { rgb: { r: 159, g: 95, b: 63 }, hex: '#9f5f3f' },
  { rgb: { r: 159, g: 95, b: 95 }, hex: '#9f5f5f' },
  { rgb: { r: 159, g: 95, b: 127 }, hex: '#9f5f7f' },
  { rgb: { r: 159, g: 95, b: 159 }, hex: '#9f5f9f' },
  { rgb: { r: 159, g: 95, b: 191 }, hex: '#9f5fbf' },
  { rgb: { r: 159, g: 95, b: 223 }, hex: '#9f5fdf' },
  { rgb: { r: 159, g: 95, b: 255 }, hex: '#9f5fff' },
  { rgb: { r: 159, g: 127, b: 31 }, hex: '#9f7f1f' },
  { rgb: { r: 159, g: 127, b: 63 }, hex: '#9f7f3f' },
  { rgb: { r: 159, g: 127, b: 95 }, hex: '#9f7f5f' },
  { rgb: { r: 159, g: 127, b: 127 }, hex: '#9f7f7f' },
  { rgb: { r: 159, g: 127, b: 159 }, hex: '#9f7f9f' },
  { rgb: { r: 159, g: 127, b: 191 }, hex: '#9f7fbf' },
  { rgb: { r: 159, g: 127, b: 223 }, hex: '#9f7fdf' },
  { rgb: { r: 159, g: 127, b: 255 }, hex: '#9f7fff' },
  { rgb: { r: 159, g: 159, b: 31 }, hex: '#9f9f1f' },
  { rgb: { r: 159, g: 159, b: 63 }, hex: '#9f9f3f' },
  { rgb: { r: 159, g: 159, b: 95 }, hex: '#9f9f5f' },
  { rgb: { r: 159, g: 159, b: 127 }, hex: '#9f9f7f' },
  { rgb: { r: 159, g: 159, b: 159 }, hex: '#9f9f9f' },
  { rgb: { r: 159, g: 159, b: 191 }, hex: '#9f9fbf' },
  { rgb: { r: 159, g: 159, b: 223 }, hex: '#9f9fdf' },
  { rgb: { r: 159, g: 159, b: 255 }, hex: '#9f9fff' },
  { rgb: { r: 159, g: 191, b: 31 }, hex: '#9fbf1f' },
  { rgb: { r: 159, g: 191, b: 63 }, hex: '#9fbf3f' },
  { rgb: { r: 159, g: 191, b: 95 }, hex: '#9fbf5f' },
  { rgb: { r: 159, g: 191, b: 127 }, hex: '#9fbf7f' },
  { rgb: { r: 159, g: 191, b: 159 }, hex: '#9fbf9f' },
  { rgb: { r: 159, g: 191, b: 191 }, hex: '#9fbfbf' },
  { rgb: { r: 159, g: 191, b: 223 }, hex: '#9fbfdf' },
  { rgb: { r: 159, g: 191, b: 255 }, hex: '#9fbfff' },
  { rgb: { r: 159, g: 223, b: 31 }, hex: '#9fdf1f' },
  { rgb: { r: 159, g: 223, b: 63 }, hex: '#9fdf3f' },
  { rgb: { r: 159, g: 223, b: 95 }, hex: '#9fdf5f' },
  { rgb: { r: 159, g: 223, b: 127 }, hex: '#9fdf7f' },
  { rgb: { r: 159, g: 223, b: 159 }, hex: '#9fdf9f' },
  { rgb: { r: 159, g: 223, b: 191 }, hex: '#9fdfbf' },
  { rgb: { r: 159, g: 223, b: 223 }, hex: '#9fdfdf' },
  { rgb: { r: 159, g: 223, b: 255 }, hex: '#9fdfff' },
  { rgb: { r: 159, g: 255, b: 31 }, hex: '#9fff1f' },
  { rgb: { r: 159, g: 255, b: 63 }, hex: '#9fff3f' },
  { rgb: { r: 159, g: 255, b: 95 }, hex: '#9fff5f' },
  { rgb: { r: 159, g: 255, b: 127 }, hex: '#9fff7f' },
  { rgb: { r: 159, g: 255, b: 159 }, hex: '#9fff9f' },
  { rgb: { r: 159, g: 255, b: 191 }, hex: '#9fffbf' },
  { rgb: { r: 159, g: 255, b: 223 }, hex: '#9fffdf' },
  { rgb: { r: 159, g: 255, b: 255 }, hex: '#9fffff' },
  { rgb: { r: 191, g: 31, b: 31 }, hex: '#bf1f1f' },
  { rgb: { r: 191, g: 31, b: 63 }, hex: '#bf1f3f' },
  { rgb: { r: 191, g: 31, b: 95 }, hex: '#bf1f5f' },
  { rgb: { r: 191, g: 31, b: 127 }, hex: '#bf1f7f' },
  { rgb: { r: 191, g: 31, b: 159 }, hex: '#bf1f9f' },
  { rgb: { r: 191, g: 31, b: 191 }, hex: '#bf1fbf' },
  { rgb: { r: 191, g: 31, b: 223 }, hex: '#bf1fdf' },
  { rgb: { r: 191, g: 31, b: 255 }, hex: '#bf1fff' },
  { rgb: { r: 191, g: 63, b: 31 }, hex: '#bf3f1f' },
  { rgb: { r: 191, g: 63, b: 63 }, hex: '#bf3f3f' },
  { rgb: { r: 191, g: 63, b: 95 }, hex: '#bf3f5f' },
  { rgb: { r: 191, g: 63, b: 127 }, hex: '#bf3f7f' },
  { rgb: { r: 191, g: 63, b: 159 }, hex: '#bf3f9f' },
  { rgb: { r: 191, g: 63, b: 191 }, hex: '#bf3fbf' },
  { rgb: { r: 191, g: 63, b: 223 }, hex: '#bf3fdf' },
  { rgb: { r: 191, g: 63, b: 255 }, hex: '#bf3fff' },
  { rgb: { r: 191, g: 95, b: 31 }, hex: '#bf5f1f' },
  { rgb: { r: 191, g: 95, b: 63 }, hex: '#bf5f3f' },
  { rgb: { r: 191, g: 95, b: 95 }, hex: '#bf5f5f' },
  { rgb: { r: 191, g: 95, b: 127 }, hex: '#bf5f7f' },
  { rgb: { r: 191, g: 95, b: 159 }, hex: '#bf5f9f' },
  { rgb: { r: 191, g: 95, b: 191 }, hex: '#bf5fbf' },
  { rgb: { r: 191, g: 95, b: 223 }, hex: '#bf5fdf' },
  { rgb: { r: 191, g: 95, b: 255 }, hex: '#bf5fff' },
  { rgb: { r: 191, g: 127, b: 31 }, hex: '#bf7f1f' },
  { rgb: { r: 191, g: 127, b: 63 }, hex: '#bf7f3f' },
  { rgb: { r: 191, g: 127, b: 95 }, hex: '#bf7f5f' },
  { rgb: { r: 191, g: 127, b: 127 }, hex: '#bf7f7f' },
  { rgb: { r: 191, g: 127, b: 159 }, hex: '#bf7f9f' },
  { rgb: { r: 191, g: 127, b: 191 }, hex: '#bf7fbf' },
  { rgb: { r: 191, g: 127, b: 223 }, hex: '#bf7fdf' },
  { rgb: { r: 191, g: 127, b: 255 }, hex: '#bf7fff' },
  { rgb: { r: 191, g: 159, b: 31 }, hex: '#bf9f1f' },
  { rgb: { r: 191, g: 159, b: 63 }, hex: '#bf9f3f' },
  { rgb: { r: 191, g: 159, b: 95 }, hex: '#bf9f5f' },
  { rgb: { r: 191, g: 159, b: 127 }, hex: '#bf9f7f' },
  { rgb: { r: 191, g: 159, b: 159 }, hex: '#bf9f9f' },
  { rgb: { r: 191, g: 159, b: 191 }, hex: '#bf9fbf' },
  { rgb: { r: 191, g: 159, b: 223 }, hex: '#bf9fdf' },
  { rgb: { r: 191, g: 159, b: 255 }, hex: '#bf9fff' },
  { rgb: { r: 191, g: 191, b: 31 }, hex: '#bfbf1f' },
  { rgb: { r: 191, g: 191, b: 63 }, hex: '#bfbf3f' },
  { rgb: { r: 191, g: 191, b: 95 }, hex: '#bfbf5f' },
  { rgb: { r: 191, g: 191, b: 127 }, hex: '#bfbf7f' },
  { rgb: { r: 191, g: 191, b: 159 }, hex: '#bfbf9f' },
  { rgb: { r: 191, g: 191, b: 191 }, hex: '#bfbfbf' },
  { rgb: { r: 191, g: 191, b: 223 }, hex: '#bfbfdf' },
  { rgb: { r: 191, g: 191, b: 255 }, hex: '#bfbfff' },
  { rgb: { r: 191, g: 223, b: 31 }, hex: '#bfdf1f' },
  { rgb: { r: 191, g: 223, b: 63 }, hex: '#bfdf3f' },
  { rgb: { r: 191, g: 223, b: 95 }, hex: '#bfdf5f' },
  { rgb: { r: 191, g: 223, b: 127 }, hex: '#bfdf7f' },
  { rgb: { r: 191, g: 223, b: 159 }, hex: '#bfdf9f' },
  { rgb: { r: 191, g: 223, b: 191 }, hex: '#bfdfbf' },
  { rgb: { r: 191, g: 223, b: 223 }, hex: '#bfdfdf' },
  { rgb: { r: 191, g: 223, b: 255 }, hex: '#bfdfff' },
  { rgb: { r: 191, g: 255, b: 31 }, hex: '#bfff1f' },
  { rgb: { r: 191, g: 255, b: 63 }, hex: '#bfff3f' },
  { rgb: { r: 191, g: 255, b: 95 }, hex: '#bfff5f' },
  { rgb: { r: 191, g: 255, b: 127 }, hex: '#bfff7f' },
  { rgb: { r: 191, g: 255, b: 159 }, hex: '#bfff9f' },
  { rgb: { r: 191, g: 255, b: 191 }, hex: '#bfffbf' },
  { rgb: { r: 191, g: 255, b: 223 }, hex: '#bfffdf' },
  { rgb: { r: 191, g: 255, b: 255 }, hex: '#bfffff' },
  { rgb: { r: 223, g: 31, b: 31 }, hex: '#df1f1f' },
  { rgb: { r: 223, g: 31, b: 63 }, hex: '#df1f3f' },
  { rgb: { r: 223, g: 31, b: 95 }, hex: '#df1f5f' },
  { rgb: { r: 223, g: 31, b: 127 }, hex: '#df1f7f' },
  { rgb: { r: 223, g: 31, b: 159 }, hex: '#df1f9f' },
  { rgb: { r: 223, g: 31, b: 191 }, hex: '#df1fbf' },
  { rgb: { r: 223, g: 31, b: 223 }, hex: '#df1fdf' },
  { rgb: { r: 223, g: 31, b: 255 }, hex: '#df1fff' },
  { rgb: { r: 223, g: 63, b: 31 }, hex: '#df3f1f' },
  { rgb: { r: 223, g: 63, b: 63 }, hex: '#df3f3f' },
  { rgb: { r: 223, g: 63, b: 95 }, hex: '#df3f5f' },
  { rgb: { r: 223, g: 63, b: 127 }, hex: '#df3f7f' },
  { rgb: { r: 223, g: 63, b: 159 }, hex: '#df3f9f' },
  { rgb: { r: 223, g: 63, b: 191 }, hex: '#df3fbf' },
  { rgb: { r: 223, g: 63, b: 223 }, hex: '#df3fdf' },
  { rgb: { r: 223, g: 63, b: 255 }, hex: '#df3fff' },
  { rgb: { r: 223, g: 95, b: 31 }, hex: '#df5f1f' },
  { rgb: { r: 223, g: 95, b: 63 }, hex: '#df5f3f' },
  { rgb: { r: 223, g: 95, b: 95 }, hex: '#df5f5f' },
  { rgb: { r: 223, g: 95, b: 127 }, hex: '#df5f7f' },
  { rgb: { r: 223, g: 95, b: 159 }, hex: '#df5f9f' },
  { rgb: { r: 223, g: 95, b: 191 }, hex: '#df5fbf' },
  { rgb: { r: 223, g: 95, b: 223 }, hex: '#df5fdf' },
  { rgb: { r: 223, g: 95, b: 255 }, hex: '#df5fff' },
  { rgb: { r: 223, g: 127, b: 31 }, hex: '#df7f1f' },
  { rgb: { r: 223, g: 127, b: 63 }, hex: '#df7f3f' },
  { rgb: { r: 223, g: 127, b: 95 }, hex: '#df7f5f' },
  { rgb: { r: 223, g: 127, b: 127 }, hex: '#df7f7f' },
  { rgb: { r: 223, g: 127, b: 159 }, hex: '#df7f9f' },
  { rgb: { r: 223, g: 127, b: 191 }, hex: '#df7fbf' },
  { rgb: { r: 223, g: 127, b: 223 }, hex: '#df7fdf' },
  { rgb: { r: 223, g: 127, b: 255 }, hex: '#df7fff' },
  { rgb: { r: 223, g: 159, b: 31 }, hex: '#df9f1f' },
  { rgb: { r: 223, g: 159, b: 63 }, hex: '#df9f3f' },
  { rgb: { r: 223, g: 159, b: 95 }, hex: '#df9f5f' },
  { rgb: { r: 223, g: 159, b: 127 }, hex: '#df9f7f' },
  { rgb: { r: 223, g: 159, b: 159 }, hex: '#df9f9f' },
  { rgb: { r: 223, g: 159, b: 191 }, hex: '#df9fbf' },
  { rgb: { r: 223, g: 159, b: 223 }, hex: '#df9fdf' },
  { rgb: { r: 223, g: 159, b: 255 }, hex: '#df9fff' },
  { rgb: { r: 223, g: 191, b: 31 }, hex: '#dfbf1f' },
  { rgb: { r: 223, g: 191, b: 63 }, hex: '#dfbf3f' },
  { rgb: { r: 223, g: 191, b: 95 }, hex: '#dfbf5f' },
  { rgb: { r: 223, g: 191, b: 127 }, hex: '#dfbf7f' },
  { rgb: { r: 223, g: 191, b: 159 }, hex: '#dfbf9f' },
  { rgb: { r: 223, g: 191, b: 191 }, hex: '#dfbfbf' },
  { rgb: { r: 223, g: 191, b: 223 }, hex: '#dfbfdf' },
  { rgb: { r: 223, g: 191, b: 255 }, hex: '#dfbfff' },
  { rgb: { r: 223, g: 223, b: 31 }, hex: '#dfdf1f' },
  { rgb: { r: 223, g: 223, b: 63 }, hex: '#dfdf3f' },
  { rgb: { r: 223, g: 223, b: 95 }, hex: '#dfdf5f' },
  { rgb: { r: 223, g: 223, b: 127 }, hex: '#dfdf7f' },
  { rgb: { r: 223, g: 223, b: 159 }, hex: '#dfdf9f' },
  { rgb: { r: 223, g: 223, b: 191 }, hex: '#dfdfbf' },
  { rgb: { r: 223, g: 223, b: 223 }, hex: '#dfdfdf' },
  { rgb: { r: 223, g: 223, b: 255 }, hex: '#dfdfff' },
  { rgb: { r: 223, g: 255, b: 31 }, hex: '#dfff1f' },
  { rgb: { r: 223, g: 255, b: 63 }, hex: '#dfff3f' },
  { rgb: { r: 223, g: 255, b: 95 }, hex: '#dfff5f' },
  { rgb: { r: 223, g: 255, b: 127 }, hex: '#dfff7f' },
  { rgb: { r: 223, g: 255, b: 159 }, hex: '#dfff9f' },
  { rgb: { r: 223, g: 255, b: 191 }, hex: '#dfffbf' },
  { rgb: { r: 223, g: 255, b: 223 }, hex: '#dfffdf' },
  { rgb: { r: 223, g: 255, b: 255 }, hex: '#dfffff' },
  { rgb: { r: 255, g: 31, b: 31 }, hex: '#ff1f1f' },
  { rgb: { r: 255, g: 31, b: 63 }, hex: '#ff1f3f' },
  { rgb: { r: 255, g: 31, b: 95 }, hex: '#ff1f5f' },
  { rgb: { r: 255, g: 31, b: 127 }, hex: '#ff1f7f' },
  { rgb: { r: 255, g: 31, b: 159 }, hex: '#ff1f9f' },
  { rgb: { r: 255, g: 31, b: 191 }, hex: '#ff1fbf' },
  { rgb: { r: 255, g: 31, b: 223 }, hex: '#ff1fdf' },
  { rgb: { r: 255, g: 31, b: 255 }, hex: '#ff1fff' },
  { rgb: { r: 255, g: 63, b: 31 }, hex: '#ff3f1f' },
  { rgb: { r: 255, g: 63, b: 63 }, hex: '#ff3f3f' },
  { rgb: { r: 255, g: 63, b: 95 }, hex: '#ff3f5f' },
  { rgb: { r: 255, g: 63, b: 127 }, hex: '#ff3f7f' },
  { rgb: { r: 255, g: 63, b: 159 }, hex: '#ff3f9f' },
  { rgb: { r: 255, g: 63, b: 191 }, hex: '#ff3fbf' },
  { rgb: { r: 255, g: 63, b: 223 }, hex: '#ff3fdf' },
  { rgb: { r: 255, g: 63, b: 255 }, hex: '#ff3fff' },
  { rgb: { r: 255, g: 95, b: 31 }, hex: '#ff5f1f' },
  { rgb: { r: 255, g: 95, b: 63 }, hex: '#ff5f3f' },
  { rgb: { r: 255, g: 95, b: 95 }, hex: '#ff5f5f' },
  { rgb: { r: 255, g: 95, b: 127 }, hex: '#ff5f7f' },
  { rgb: { r: 255, g: 95, b: 159 }, hex: '#ff5f9f' },
  { rgb: { r: 255, g: 95, b: 191 }, hex: '#ff5fbf' },
  { rgb: { r: 255, g: 95, b: 223 }, hex: '#ff5fdf' },
  { rgb: { r: 255, g: 95, b: 255 }, hex: '#ff5fff' },
  { rgb: { r: 255, g: 127, b: 31 }, hex: '#ff7f1f' },
  { rgb: { r: 255, g: 127, b: 63 }, hex: '#ff7f3f' },
  { rgb: { r: 255, g: 127, b: 95 }, hex: '#ff7f5f' },
  { rgb: { r: 255, g: 127, b: 127 }, hex: '#ff7f7f' },
  { rgb: { r: 255, g: 127, b: 159 }, hex: '#ff7f9f' },
  { rgb: { r: 255, g: 127, b: 191 }, hex: '#ff7fbf' },
  { rgb: { r: 255, g: 127, b: 223 }, hex: '#ff7fdf' },
  { rgb: { r: 255, g: 127, b: 255 }, hex: '#ff7fff' },
  { rgb: { r: 255, g: 159, b: 31 }, hex: '#ff9f1f' },
  { rgb: { r: 255, g: 159, b: 63 }, hex: '#ff9f3f' },
  { rgb: { r: 255, g: 159, b: 95 }, hex: '#ff9f5f' },
  { rgb: { r: 255, g: 159, b: 127 }, hex: '#ff9f7f' },
  { rgb: { r: 255, g: 159, b: 159 }, hex: '#ff9f9f' },
  { rgb: { r: 255, g: 159, b: 191 }, hex: '#ff9fbf' },
  { rgb: { r: 255, g: 159, b: 223 }, hex: '#ff9fdf' },
  { rgb: { r: 255, g: 159, b: 255 }, hex: '#ff9fff' },
  { rgb: { r: 255, g: 191, b: 31 }, hex: '#ffbf1f' },
  { rgb: { r: 255, g: 191, b: 63 }, hex: '#ffbf3f' },
  { rgb: { r: 255, g: 191, b: 95 }, hex: '#ffbf5f' },
  { rgb: { r: 255, g: 191, b: 127 }, hex: '#ffbf7f' },
  { rgb: { r: 255, g: 191, b: 159 }, hex: '#ffbf9f' },
  { rgb: { r: 255, g: 191, b: 191 }, hex: '#ffbfbf' },
  { rgb: { r: 255, g: 191, b: 223 }, hex: '#ffbfdf' },
  { rgb: { r: 255, g: 191, b: 255 }, hex: '#ffbfff' },
  { rgb: { r: 255, g: 223, b: 31 }, hex: '#ffdf1f' },
  { rgb: { r: 255, g: 223, b: 63 }, hex: '#ffdf3f' },
  { rgb: { r: 255, g: 223, b: 95 }, hex: '#ffdf5f' },
  { rgb: { r: 255, g: 223, b: 127 }, hex: '#ffdf7f' },
  { rgb: { r: 255, g: 223, b: 159 }, hex: '#ffdf9f' },
  { rgb: { r: 255, g: 223, b: 191 }, hex: '#ffdfbf' },
  { rgb: { r: 255, g: 223, b: 223 }, hex: '#ffdfdf' },
  { rgb: { r: 255, g: 223, b: 255 }, hex: '#ffdfff' },
  { rgb: { r: 255, g: 255, b: 31 }, hex: '#ffff1f' },
  { rgb: { r: 255, g: 255, b: 63 }, hex: '#ffff3f' },
  { rgb: { r: 255, g: 255, b: 95 }, hex: '#ffff5f' },
  { rgb: { r: 255, g: 255, b: 127 }, hex: '#ffff7f' },
  { rgb: { r: 255, g: 255, b: 159 }, hex: '#ffff9f' },
  { rgb: { r: 255, g: 255, b: 191 }, hex: '#ffffbf' },
  { rgb: { r: 255, g: 255, b: 223 }, hex: '#ffffdf' },
  { rgb: { r: 255, g: 255, b: 255 }, hex: '#ffffff' },
]

function closestColors(palette, color, range, maxItems) {
  // Returns the colors in a palette [{rgb:{r,g,b}}] that are 'within range' - the total rgb delta is < range

  function _hexToRGB(h) {
    let r = 0
    let g = 0
    let b = 0

    // 3 digits
    if (h.length === 4) {
      r = `0x${h[1]}${h[1]}`
      g = `0x${h[2]}${h[2]}`
      b = `0x${h[3]}${h[3]}`

      // 6 digits
    } else if (h.length === 7) {
      r = `0x${h[1]}${h[2]}`
      g = `0x${h[3]}${h[4]}`
      b = `0x${h[5]}${h[6]}`
    }

    return { r: +(+r), g: +(+g), b: +(+b) }
  }

  // If it's a hex, convert it
  const rgbColor = typeof color === 'object' ? color : _hexToRGB(color)

  return palette
    .map((entry) => {
      // Get rgb
      const R = parseInt(rgbColor.r, 10)
      const G = parseInt(rgbColor.g, 10)
      const B = parseInt(rgbColor.b, 10)

      const entry_R = entry.rgb.r
      const entry_G = entry.rgb.g
      const entry_B = entry.rgb.b

      // Get frechet score
      const curve1 = [
        [0, R],
        [1, G],
        [2, B],
      ]
      const curve2 = [
        [0, entry_R],
        [1, entry_G],
        [2, entry_B],
      ]

      const frechetScore = frechet(curve1, curve2)

      // Compare internal deltas
      const RG = R - G
      const RB = R - B
      const GB = G - B
      const entry_RG = entry.rgb.r - entry.rgb.g
      const entry_RB = entry.rgb.r - entry.rgb.b
      const entry_GB = entry.rgb.g - entry.rgb.b
      const deltaRG = Math.abs(RG - entry_RG)
      const deltaRB = Math.abs(RB - entry_RB)
      const deltaGB = Math.abs(GB - entry_GB)
      // const internalDelta = deltaRG + deltaRB + deltaGB
      const internalDeltaMax = Math.max(deltaRG, deltaRB, deltaGB)

      return {
        ...entry,
        frechetScore,
        internalDeltaMax,
        // internalDelta,
      }
    })
    .filter((entry) => entry.frechetScore < range)
    .sort((a, b) => {
      const comparison1 = a.internalDeltaMax - b.internalDeltaMax
      if (comparison1 !== 0) return comparison1
      return a.frechetScore - b.frechetScore
    })
}

export default function mapToPalette(color) {
  const closest = closestColors(palette6, color, 50, 1)
  if (closest.length > 0) return closest[0].hex
  return null
}
