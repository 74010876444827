import React, { FC } from 'react'
import styled, { css } from 'styled-components'

import { useBillingSubscriptionQuery } from 'generated/graphql'

import { match } from '../../helpers/utilityFunctions'
import Modal from '../common/Modal'
import { ComparePlans } from '../settings/Compare/ComparePlans'
import SettingsData from '../settings/SettingsData'
import { SettingsContainer } from '../settings/SettingsStyles'
import UserRepresentation from '../user/UserRepresentation'
import useUpgradeNavigation from './useUpgradeNavigation'

const HeadingLarge = styled.div(({ theme }) => ({
  fontSize: theme.fontSizes['2xl'],
  color: theme.colors.text.neutral[0],
  fontWeight: theme.fontWeight.medium,
}))

const StyledTop = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.background[0]};
    padding: 36px 24px 24px 24px;
    border-bottom: ${`${theme.border.thin} ${theme.colors.discreet}`};
  `}
`

const StyledContent = styled.div`
  padding: 24px;
  width: 100%;
`

const StyledInfo = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.base};
    color: ${theme.colors.accent[2]};
  `}
`

function NonAdminContent(props: any) {
  return (
    <StyledContent>
      {props.activeWorkspace?.admins?.map((email: any) => (
        <UserRepresentation key={email} includeEmail email={email} />
      ))}
    </StyledContent>
  )
}

export default function Upgrade() {
  const { section, close } = useUpgradeNavigation()
  return (
    <SettingsData>
      {(props: any, activePlan: any) => (
        <Modal
          height="auto"
          width="1000px"
          isOpen={section != null}
          close={close}
        >
          <StyledTop>
            <StyledInfo>
              Your current plan
              {match(section, {
                items: activePlan?.limits?.items
                  ? ` is limited to ${activePlan?.limits?.items} items.`
                  : ' has no item limits',
                boards: activePlan?.limits?.boards
                  ? ` is limited to ${activePlan?.limits?.boards} boards.`
                  : ' has no boards limits',
                projects: activePlan?.limits?.projects
                  ? ` is limited to ${activePlan?.limits?.projects} projects.`
                  : ' has no projects limits',
                boardItems: activePlan?.limits?.boardItems
                  ? ` is limited to ${activePlan?.limits?.boardItems} items per board.`
                  : ' has no board item limits',
                frameExtraction: ` is limited to ${activePlan?.limits?.frameExtractions} frame extractions.`,
                gifExtraction: ` does not include the feature to extract gifs from video`,
              })}
            </StyledInfo>
            {props.isWorkspaceAdmin ? (
              <HeadingLarge>
                Upgrade now to <strong>power up your creativity </strong>✨
              </HeadingLarge>
            ) : (
              <HeadingLarge>
                Please notify an admin of your workspace.
              </HeadingLarge>
            )}
          </StyledTop>
          <UpgradeSettingsContent
            props={props}
            activePlan={activePlan}
            workspaceId={props.activeWorkspace.id}
          />
        </Modal>
      )}
    </SettingsData>
  )
}

type UpgradeSettingsContentProps = {
  props: any
  activePlan: any
  workspaceId: string
}
const UpgradeSettingsContent: FC<UpgradeSettingsContentProps> = ({
  props,
  activePlan,
  workspaceId,
}) => {
  const { data: subscription, refetch: refetchBillingSubscription } =
    useBillingSubscriptionQuery({
      variables: {
        workspaceId,
      },
      fetchPolicy: 'cache-and-network',
    })
  return (
    <SettingsContainer>
      {props.isWorkspaceAdmin ? (
        <ComparePlans
          {...props}
          standalone
          activePlan={activePlan}
          currentMemberCount={
            (props.activeWorkspace.admins.length ?? 0) +
            (props.activeWorkspace.members.length ?? 0)
          }
          subscription={subscription}
          refetchBillingSubscription={refetchBillingSubscription}
        />
      ) : (
        <NonAdminContent activeWorkspace={props.activeWorkspace} />
      )}
    </SettingsContainer>
  )
}
