import styled, { css } from 'styled-components'

export const SelectBoardAndProjectSubmitButton = styled.button`
  ${({ theme, disabled }) => css`
    width: 24px;
    height: 24px;
    outline: none;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-size: 10px;
    background: ${disabled
      ? theme.colors.background[5]
      : theme.colors.accent[2]};
    color: ${disabled ? theme.colors.gray.dark[6] : theme.colors.white};
    &:hover {
      background: ${disabled
        ? theme.colors.background[5]
        : theme.colors.accent[1]};
    }
    &:active {
      color: ${disabled ? theme.colors.gray.dark[5] : theme.colors.accent[2]};
    }
  `}
`
