import { Location } from 'history'
import * as queryString from 'query-string'
import { NavigateFunction } from 'react-router'

const getExistingQueries = (q: string | (string | null)[] | null) => {
  if (Array.isArray(q)) return q
  if (typeof q === 'string') return [q]
  return []
}

export const submitSearch = ({
  location,
  navigate,
  searchPhrase,
  searchSpaceKey,
}: {
  location: Location
  navigate: NavigateFunction
  searchPhrase: string
  searchSpaceKey: string
}) => {
  const parsed = queryString.parse(location.search, {
    arrayFormat: 'comma',
  })
  const q = parsed.q
  const existingQueries = getExistingQueries(q)
  const updatedQueries = existingQueries.concat(searchPhrase)
  const updatedQueryString = queryString.stringify(
    { ...parsed, ...{ q: updatedQueries, searchSpace: searchSpaceKey } },
    { arrayFormat: 'comma' }
  )
  navigate(`search?${updatedQueryString}`)
}
