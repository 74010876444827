import { useSelector } from 'react-redux'

import { RootState } from 'store'
import { DBUser } from 'types/db'

import useGetUser from './useGetUser'
import { Guest, useGuest } from './useGuest'

const useCurrentUserOrGuest = () => {
  const authedUserId = useSelector(
    (state: RootState) => state.firebase.auth.uid
  )
  const currentUser = useGetUser(authedUserId)

  const { guest } = useGuest()

  const currentUserOrGuest = (authedUserId ? currentUser : guest) as
    | DBUser
    | Guest

  return currentUserOrGuest
}

export { useCurrentUserOrGuest }
