import { Send } from '@styled-icons/feather/Send'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { v4 as uuid } from 'uuid'

import Button from 'components/common/Button'
import { Input } from 'components/common/Form'
import { Margin } from 'components/common/Margin'
import { Text } from 'components/common/Text'
import { useUserTasks } from 'features/home/setup-tasks-row/useUserTasks'
import { useSendKiveInvitationMutation } from 'generated/graphql'
import {
  trackNominationPanelClosed,
  trackNominationPanelOpened,
  trackNominationPanelVimeoSuggestionsLoaded,
} from 'helpers/tracking/tracking'
import useCurrentUser from 'hooks/useCurrentUser'
import useQueryString from 'hooks/useQueryString'
import { RootState } from 'store'

import VimeoSuggestions from '../sharing/VimeoSuggestions'
import useToastMessages from '../toast/useToastMessages'

function validateEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).trim().toLowerCase())
}

const CREDIT_EARNED_LIMIT_OLD = 100 // 100 dollar
const CREDIT_EARNED_LIMIT = 200 // 200 dollar
const CREDIT_AMOUNT_INVITEE = 20 // 20 dollar
const CREDIT_AMOUNT_INVITER_OLD = 10 // 10 dollar
const CREDIT_AMOUNT_INVITER = 20 // 20 dollar

const NominationPanel: React.FC = () => {
  const [inputEmail, setInputEmail] = useState('')
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const { reportSuccess, reportError } = useToastMessages()
  const [sendKiveMutation] = useSendKiveInvitationMutation()
  const currentUser = useCurrentUser()
  const { completeUserTask } = useUserTasks()

  const activeWorkspace = useSelector(
    (state: RootState) => state.content.activeWorkspace!
  )
  const { refSrc } = useQueryString<{ refSrc?: string }>()

  const nominationPanelTrackingId = useRef('')

  useEffect(() => {
    const trackingId = uuid()
    nominationPanelTrackingId.current = trackingId

    trackNominationPanelOpened({
      nominationPanelTrackingId: trackingId,
      workspaceId: activeWorkspace.id,
      hasVimeoUserId: !!currentUser.vimeoUserId,
      creditAmountInvitee: CREDIT_AMOUNT_INVITEE,
      creditAmountInviter: CREDIT_AMOUNT_INVITER,
      referrer: refSrc,
    })

    return () => {
      trackNominationPanelClosed({
        nominationPanelTrackingId: nominationPanelTrackingId.current,
      })
      setError('')
    }
  }, [])

  const handleVimeoSuggestionsLoaded = (nbrOfVimeoSuggestions: number) => {
    trackNominationPanelVimeoSuggestionsLoaded({
      nominationPanelTrackingId: nominationPanelTrackingId.current,
      nbrOfVimeoSuggestions,
    })
  }

  const sendKiveInvite = async ({
    email,
    vimeoId,
  }: {
    email: string
    vimeoId?: string
  }) => {
    const { data } = await sendKiveMutation({
      variables: {
        email,
        vimeoId,
        nominationPanelTrackingId: nominationPanelTrackingId.current,
      },
    })
    const status = data?.sendKiveInvitation.status
    if (status === 'USER_ALREADY_EXISTS') {
      return reportError('The person already has a Kive user')
    }
    if (status === 'INVITATION_ALREADY_EXISTS') {
      return reportError('This person already has a nomination')
    }
    if (status === 'INVITATION_ALREADY_SENT') {
      return reportError(`You've already sent a nomination to this person`)
    }
    completeUserTask('referFriend')
    return reportSuccess('Nomination sent!')
  }

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault()
    const isValid = validateEmail(inputEmail)
    if (!isValid) {
      setError('The email you entered is not valid')
    } else {
      setInputEmail('')
      setError('')
      setIsLoading(true)
      await sendKiveInvite({ email: inputEmail })
      setIsLoading(false)
    }
  }

  const {
    totalCreditEarned = 0,
    invitesSent = 0,
    invitesClaimed = 0,
  } = currentUser
  const creditLeftToUnlock = CREDIT_EARNED_LIMIT - totalCreditEarned
  const creditLeftToUnlockOld = CREDIT_EARNED_LIMIT_OLD - totalCreditEarned
  const transparentProgressStep = invitesSent * CREDIT_AMOUNT_INVITER
  return (
    <Wrapper>
      <Text color="neutral.0" size="xl" bold>
        Nominate and earn credit
      </Text>
      <Margin y={18} />
      <Text size="base">
        You get{' '}
        <span css="text-decoration: line-through">
          ${CREDIT_AMOUNT_INVITER_OLD}
        </span>{' '}
        <span css="color: white">${CREDIT_AMOUNT_INVITER}</span> in credit when
        someone claims your invitation. <br />
        They skip the waitlist and get ${CREDIT_AMOUNT_INVITEE} in credit when
        they use your nomination.
      </Text>
      <Margin y={18} />
      <Text size="sm" color="neutral.4">
        People you nominate here will <u>not</u> get access to your workspace or
        files.
        <br />
        To invite team members to your workspace go to{' '}
        <StyledLink to="?settingsPanel=members">Settings</StyledLink>.
      </Text>
      <Margin y={18} />
      <Text size="lg" color="accent.2" bold>
        <StyledOldLeftToUnlockCreditTitle>
          ${creditLeftToUnlockOld < 0 ? 0 : creditLeftToUnlockOld}
        </StyledOldLeftToUnlockCreditTitle>
        <span css="text-decoration: underline">${creditLeftToUnlock}</span>{' '}
        credit left to unlock.
      </Text>
      <Margin y={8} />
      <ProgressBar
        step={(totalCreditEarned / CREDIT_EARNED_LIMIT) * 100}
        transparentStep={
          transparentProgressStep > CREDIT_EARNED_LIMIT
            ? CREDIT_EARNED_LIMIT
            : transparentProgressStep
        }
      />
      <Margin y={6} />
      {(totalCreditEarned > 0 || invitesSent > 0) && (
        <Text size="sm" color="neutral.4">
          {invitesSent > 0
            ? `${invitesClaimed} of your ${invitesSent} nominations have been claimed.`
            : ''}
          {totalCreditEarned > 0
            ? ` ${totalCreditEarned} credit unlocked.`
            : ''}
        </Text>
      )}
      <Margin y={24} />
      {currentUser.vimeoUserId && (
        <VimeoSuggestions
          userId={currentUser.uid}
          onNominateVimeoSuggestion={sendKiveInvite}
          onVimeoSuggestionsLoaded={handleVimeoSuggestionsLoaded}
        />
      )}
      <form onSubmit={handleSubmit}>
        <FormContent>
          <StyledInput
            value={inputEmail}
            autoFocus
            onChange={
              (e: React.ChangeEvent<HTMLInputElement>) =>
                setInputEmail(e.target.value.toLowerCase().trim()) // Enforce lowercase and no trailing spaces
            }
            placeholder="Enter email"
            type="text"
            name="email"
            required
          />
          <SendButton
            variant="primary"
            onClick={handleSubmit}
            Icon={Send}
            isLoading={isLoading}
          >
            Nominate
          </SendButton>
        </FormContent>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </form>
    </Wrapper>
  )
}

export const ProgressBar = styled.div<{
  step: number
  transparentStep?: number
  progressColor?: string
}>`
  ${({ theme, step, transparentStep, progressColor }) => css`
    width: 100%;
    height: 10px;
    background-color: ${theme.colors.background[3]};
    border-radius: ${theme.borderRadius.full};
    position: relative;
    overflow: hidden;
    &:after {
      content: '';
      position: absolute;
      height: 100%;
      width: ${step}%;
      transition: 0.3s all ease;
      border-radius: ${theme.borderRadius.full};
      background-color: ${progressColor || theme.colors.accent[2]};
    }
    ${transparentStep &&
    css`
      &:before {
        content: '';
        position: absolute;
        height: 100%;
        width: ${transparentStep}%;
        transition: 0.3s all ease;
        border-radius: ${theme.borderRadius.full};
        background-color: ${theme.colors.background[5]};
      }
    `}
  `}
`

const SendButton = styled(Button)`
  ${({ theme }) => css`
    &&& {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      width: 220px;
      height: 48px;
      @media (max-width: ${theme.breakpoints.sm}px) {
        width: 105px;
      }
    }
  `}
`

const StyledInput = styled(Input)`
  ${({ theme }) => css`
    height: 48px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border: 0;
    background: ${theme.colors.gray.dark[5]};
    padding-left: 16px;
    :hover {
      background: ${theme.colors.gray.dark[5]};
    }
  `}
`

const StyledLink = styled(Link)`
  ${({ theme }) => css`
    color: ${theme.colors.text.neutral[4]};
    text-decoration: underline;
  `}
`

const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: 32px;
    @media (max-width: ${theme.breakpoints.sm}px) {
      padding: 16px;
    }
  `}
`

const FormContent = styled.div`
  display: flex;
`

const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.redLighter};
`

const StyledOldLeftToUnlockCreditTitle = styled.span`
  text-decoration: line-through;
  color: ${({ theme }) => theme.colors.text.neutral[4]};
  margin-right: 6px;
`

export default NominationPanel
