import { BookReader } from '@styled-icons/boxicons-regular/BookReader'
import { StyledIcon } from '@styled-icons/styled-icon'

import collaborationCoverImage from 'assets/img/guides/collaboration-guide/collaboration-promo.png'
import discoverCoverImage from 'assets/img/guides/discover-guide/discover-promo.png'
import projectBoardsCoverImage from 'assets/img/guides/projects-boards-guide/projects-boards-promo.png'
import searchCoverImage from 'assets/img/guides/search-guide/search-promo.png'
import uploadCoverImage from 'assets/img/guides/upload-guide/upload-promo.png'

export type HomeGuideId =
  | 'discover'
  | 'upload'
  | 'search'
  | 'projectsBoards'
  | 'collaboration'

export type GuideInfo = {
  id: HomeGuideId
  thumbUrl: string
  // eslint-disable-next-line @typescript-eslint/naming-convention
  IconComponent: StyledIcon
  title: string
  subTitle: string
}

export const guidesInfo: Array<GuideInfo> = [
  {
    id: 'discover',
    thumbUrl: discoverCoverImage,
    IconComponent: BookReader,
    title: 'Discover new inspiration',
    subTitle: 'Inspiration from our community',
  },
  {
    id: 'upload',
    thumbUrl: uploadCoverImage,
    IconComponent: BookReader,
    title: 'Build your library',
    subTitle: 'Explore ways to add items to your library',
  },
  {
    id: 'search',
    thumbUrl: searchCoverImage,
    IconComponent: BookReader,
    title: 'Search for items',
    subTitle: 'The AI will help you find exactly what you are looking for',
  },
  {
    id: 'projectsBoards',
    thumbUrl: projectBoardsCoverImage,
    IconComponent: BookReader,
    title: 'Create projects & boards',
    subTitle: 'Collect your ideas in a board',
  },
  {
    id: 'collaboration',
    thumbUrl: collaborationCoverImage,
    IconComponent: BookReader,
    title: 'Collaborate & share',
    subTitle: 'Set up workspaces and share your ideas',
  },
]
