import { Download } from '@styled-icons/boxicons-regular/Download'
import { PaperPlane } from '@styled-icons/entypo/PaperPlane'
import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player/lazy'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled, { css } from 'styled-components'

import Button from 'components/common/Button'
import { Margin } from 'components/common/Margin'
import Modal from 'components/common/Modal'
import { Text } from 'components/common/Text'
import useToastMessages from 'components/toast/useToastMessages'
import downloadItem from 'helpers/downloadItem'
import {
  trackItemLinkCopied,
  trackKiveWrappedLinkCopied,
  trackKiveWrappedPanelOpened,
  trackKiveWrappedVideoDownloaded,
} from 'helpers/tracking/tracking'
import { RootState } from 'store'

const CreateKiveWrappedPanel: React.FC = () => {
  const { reportSuccess } = useToastMessages()

  const [isDownloading, setIsDownloading] = useState(false)

  const currentUserId = useSelector(
    (state: RootState) => state.firebase.auth.uid
  )

  const currentUser = useSelector(
    (state: RootState) => state.firestore.data.users?.[currentUserId]
  )

  const activeWorkspace = useSelector(
    (state: RootState) => state.content.activeWorkspace
  )

  const navigate = useNavigate()
  const kiveWrappedUrl = currentUser?.kiveWrappedUrl

  useEffect(() => {
    window.Intercom('update', {
      hide_default_launcher: true,
    })

    trackKiveWrappedPanelOpened({
      workspaceId: activeWorkspace?.id,
      wrappedUrl: kiveWrappedUrl,
    })

    if (kiveWrappedUrl === undefined || kiveWrappedUrl === '') {
      navigate(`/app/library`)
    }
  }, [])

  const downloadContext = {
    itemId: 'KIVE_WRAPPED',
    workspaceId: activeWorkspace?.id || 'MISSING_WORKSPACE',
  }

  const handleDownloadClick = async () => {
    trackKiveWrappedVideoDownloaded({
      workspaceId: activeWorkspace?.id,
      wrappedUrl: kiveWrappedUrl,
    })

    setIsDownloading(true)
    await downloadItem(
      { publicUrl: kiveWrappedUrl },
      'Your Kive 2021 Wrapped',
      downloadContext
    )
    setIsDownloading(false)
  }

  const handleCopyLink = () => {
    trackKiveWrappedLinkCopied({
      workspaceId: activeWorkspace?.id,
      wrappedUrl: kiveWrappedUrl,
    })

    navigator.clipboard.writeText(`${kiveWrappedUrl}`)
    reportSuccess('Copied link to clipboard!')

    const context = {
      itemId: 'KIVE_WRAPPED',
      workspaceId: activeWorkspace?.id || 'MISSING_WORKSPACE',
    }
    trackItemLinkCopied(context)
  }

  const closeModal = () => {
    navigate(`/app/library`)
    window.Intercom('update', {
      hide_default_launcher: false,
    })
  }

  return (
    <>
      <Modal height="auto" width="800px" isOpen close={closeModal}>
        <Wrapper>
          <Content>
            <Text size="base" color="neutral.2">
              It&apos;s ...
            </Text>
            <Margin y={22} />
            <Heading>
              Your
              <br />
              Kive
              <br />
              2021
              <br />
              Wrapped!
            </Heading>
            <Margin y={22} />
            <Text size="base" color="neutral.0">
              Hi {currentUser?.firstName}, to sum up your creative year,
              here&apos;s a small video with the images that inspired you most
              in Kive this year!
            </Text>
            <Margin y={16} />
            <Text size="base" color="neutral.2" css="margin-top:auto">
              Enjoy and feel free to share 💜
            </Text>
          </Content>
          <Margin x={32} />
          <VideoContainer>
            <Text size="base" color="neutral.2">
              {`${currentUser?.firstName}${
                currentUser?.firstName.endsWith('s') ? '' : `'s`
              } 2021`}
            </Text>
            <Margin y={10} />
            <ReactPlayer
              onError={(e) => console.log('Playback error', e)}
              style={{ outline: 'none' }}
              width="300px"
              height="auto"
              url={currentUser?.kiveWrappedUrl}
              controls
              muted
              playing
              loop
              playsinline
            />
            <Margin y={8} />
            <Button
              fullWidth
              Icon={PaperPlane}
              variant="secondary"
              onClick={handleCopyLink}
            >
              Copy Link
            </Button>
            <Margin y={8} />
            <Button
              fullWidth
              Icon={Download}
              variant="primary"
              isLoading={isDownloading}
              onClick={handleDownloadClick}
            >
              Download
            </Button>
          </VideoContainer>
        </Wrapper>
      </Modal>
    </>
  )
}

const Heading = styled.div`
  ${({ theme }) => css`
    font-size: 64px;
    line-height: 1.1;
    font-weight: 800;
    color: ${theme.colors.accent[2]};
    @media (max-width: 786px) {
      font-size: 32px;
    }
  `}
`

const Wrapper = styled.div`
  display: flex;
  padding: 32px;
  justify-content: center;
  @media (max-width: 786px) {
    flex-direction: column;
    padding: 16px;
  }
`
const VideoContainer = styled.div`
  @media (max-width: 786px) {
    margin-top: 32px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 786px) {
    /* display: none; */
  }
`

export default CreateKiveWrappedPanel
