import React, { useState } from 'react'
import { ChromePicker, ColorChangeHandler } from 'react-color'
import { useTheme } from 'styled-components'

import { trackColorSearchChangedColor } from 'helpers/tracking'

import { useSearchContext } from './SearchContext'

const ColorSearch: React.FC = () => {
  const theme = useTheme()
  const { color, updateColor } = useSearchContext()
  const [displayColor, setDisplayColor] = useState<string | null>(color)

  const handleChange: ColorChangeHandler = (c) => {
    setDisplayColor(c.hex)
  }

  const handleChangeComplete: ColorChangeHandler = (color_) => {
    updateColor(color_.hex)
    trackColorSearchChangedColor({ hex: color_.hex })
  }

  return (
    <div
      style={{
        borderRadius: theme.borderRadius.default,
        overflow: 'hidden',
        background: 'black',
        width: 'auto',
        display: 'inline-block',
        marginTop: 8,
        marginLeft: 16,
        marginBottom: 16,
      }}
    >
      {displayColor !== null && (
        <ChromePicker
          disableAlpha
          color={displayColor}
          onChange={handleChange}
          onChangeComplete={handleChangeComplete}
        />
      )}
    </div>
  )
}
export default ColorSearch
