import { css } from 'styled-components'

import add from 'assets/svg/addIcon2.svg'
import arrowback from 'assets/svg/arrow_back.svg'
import arrowdown from 'assets/svg/arrowdown_simple.svg'
import arrowup from 'assets/svg/arrowup_simple.svg'
import close from 'assets/svg/close_grey.svg'
import enlarge from 'assets/svg/enlarge.svg'
import heart from 'assets/svg/heartIcon.svg'
import link from 'assets/svg/linkIcon.svg'
import open from 'assets/svg/openIcon4.svg'
import openPage from 'assets/svg/openPageIcon.svg'
import pick from 'assets/svg/pickIcon.svg'
import remove from 'assets/svg/removeIcon.svg'

export const icons = {
  arrowdown,
  arrowup,
  arrowback,
  enlarge,
  add,
  close,
  pick,
  open,
  'open-page': openPage,
  remove,
  heart,
  link,
}

export const hideOnMobile = css`
  @media (max-width: 500px) {
    display: none;
  }
`
