import { Check } from '@styled-icons/boxicons-regular/Check'
import { AnimatePresence, motion } from 'framer-motion'
import { useState } from 'react'
import styled, { css } from 'styled-components'

import Flex from 'components/common/Flex'
import { Spacer } from 'components/common/Spacer'
import { Text } from 'components/common/Text'
import { Tooltip } from 'components/common/Tooltip/Tooltip'
import useIsMobile from 'hooks/useIsMobile'

import { SetupTaskId, SetupTaskInfo } from './setupTasksInfo'

const transition = {
  ease: 'easeIn',
  duration: 0.1,
}

type SetupTaskCardProps = {
  taskInfo: SetupTaskInfo
  id: SetupTaskId
  onClick: (id: SetupTaskId) => void
  onCompleteClick: (id: SetupTaskId) => void
  isCompleted: boolean
}

export const SetupTaskCard = (props: SetupTaskCardProps) => {
  const isMobile = useIsMobile()

  const [shouldShowMarkAsComplete, setShouldShowMarkAsComplete] =
    useState(false)

  const shouldShowCompletionMarker =
    shouldShowMarkAsComplete || props.isCompleted || isMobile

  return (
    <StyledSetupTaskCardWrapper
      onMouseEnter={() => setShouldShowMarkAsComplete(true)}
      onMouseLeave={() => setShouldShowMarkAsComplete(false)}
      onClick={() => props.onClick(props.id)}
    >
      <Flex alignItems="center" css="flex: 1">
        <props.taskInfo.IconComponent size={22} style={{ flexShrink: 0 }} />
        <Spacer factor={1.5} axis="x" />
        <Flex flexDirection="column" css="line-height: 18px; flex: 1">
          <Text
            color={props.isCompleted ? 'neutral.3' : 'neutral.0'}
            size="sm"
            bold
            nowrap
          >
            {props.taskInfo.title}
          </Text>
        </Flex>
        <Spacer factor={1} axis="x" />
      </Flex>
      <AnimatePresence>
        {shouldShowCompletionMarker && (
          <StyledCompletionWrapper
            transition={transition}
            initial={{ opacity: 0, x: 5 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 5 }}
          >
            {props.isCompleted ? (
              <StyledCheckMark size={18} />
            ) : (
              <Tooltip
                title="Mark as complete"
                isDisabled={isMobile}
                popperOptions={{
                  modifiers: {
                    preventOverflow: { enabled: false },
                    hide: { enabled: false },
                  },
                }}
                position="top"
              >
                <StyledSelectionMarkerOuter
                  onClick={(e) => {
                    e.stopPropagation()
                    props.onCompleteClick(props.id)
                  }}
                >
                  <StyledSelectionMarkerInner />
                </StyledSelectionMarkerOuter>
              </Tooltip>
            )}
          </StyledCompletionWrapper>
        )}
      </AnimatePresence>
    </StyledSetupTaskCardWrapper>
  )
}

const StyledSetupTaskCardWrapper = styled.div(
  ({ theme }) => css`
    border-radius: ${theme.borderRadius.lg};
    background: ${theme.colors.background[4]};
    transition: 0.2s background ease;
    display: flex;
    align-items: center;
    padding: 16px;
    min-width: 250px;
    cursor: pointer;
    :hover {
      background: ${theme.colors.background[5]};
    }
  `
)

const StyledCompletionWrapper = styled(motion.div)``

const StyledCheckMark = styled(Check)(
  ({ theme }) => css`
    border-radius: ${theme.borderRadius.full};
    background: ${theme.colors.accent[3]};
    color: ${theme.colors.gray.dark[7]};
  `
)

const StyledSelectionMarkerInner = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.accent[3]};
    border-radius: ${theme.borderRadius.full};
    width: 100%;
    height: 100%;
    transform: scale(0);
    transition: 0.1s transform ease;
  `}
`

const StyledSelectionMarkerOuter = styled.div`
  ${({ theme }) => css`
    z-index: 1;
    height: 18px;
    width: 18px;
    transition: 0.1s background ease;
    background: transparent;
    pointer-events: auto;
    border-radius: ${theme.borderRadius.full};
    border: 2px solid ${theme.colors.gray.light[0]};
    cursor: pointer;
    :hover ${StyledSelectionMarkerInner} {
      transform: scale(0.6);
    }
  `}
`
