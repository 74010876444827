import { WorkspaceBasicFieldsFragment } from 'generated/graphql'
import type { WorkspaceWithId } from 'types/custom'
import type { DBWorkspace } from 'types/db'

import {
  ActiveExport,
  ContentActionTypes,
  SET_ACTIVE_EXPORT,
  SET_ACTIVE_WORKSPACE,
  SET_FOCUSED_ITEM,
  SET_IS_FOCUSED_ITEM_SPOILER_VISIBLE,
  SET_IS_SOCIAL_ASSET_MODAL_OPEN,
  SET_IS_UPLOAD_VISIBLE,
  SET_LAST_UPLOAD_DROP,
  SET_THEME,
} from './types'

export function setTheme(
  theme: 'darkTheme' | 'lightTheme'
): ContentActionTypes {
  return { type: SET_THEME, theme }
}

export function setActiveWorkspaceByUrl(
  workspaces: { [workspaceId: string]: DBWorkspace },
  url: string
): ContentActionTypes {
  // If the url provided is actually a workspaceId, use it as an id, otherwise filter by url
  const activeWorkspaceId = workspaces[url]
    ? url
    : Object.keys(workspaces).filter(
        (workspaceId) => workspaces[workspaceId].url === url
      )[0]
  const activeWorkspace = activeWorkspaceId
    ? { ...workspaces[activeWorkspaceId], ...{ id: activeWorkspaceId } }
    : null

  return { type: SET_ACTIVE_WORKSPACE, activeWorkspace }
}

export function setActiveWorkspace(
  activeWorkspace: WorkspaceWithId | WorkspaceBasicFieldsFragment
): ContentActionTypes {
  return { type: SET_ACTIVE_WORKSPACE, activeWorkspace }
}

export function setFocusedItem(item: any): ContentActionTypes {
  return { type: SET_FOCUSED_ITEM, item }
}

export function setIsFocusedItemSpoilerVisible(
  isFocusedItemSpoilerVisible: boolean
): ContentActionTypes {
  return {
    type: SET_IS_FOCUSED_ITEM_SPOILER_VISIBLE,
    isFocusedItemSpoilerVisible,
  }
}

export function setLastUploadDrop(files: File[]): ContentActionTypes {
  return { type: SET_LAST_UPLOAD_DROP, files }
}

export function setIsUploadVisible(isVisible: boolean): ContentActionTypes {
  return { type: SET_IS_UPLOAD_VISIBLE, isVisible }
}

export function setActiveExport(
  activeExport?: ActiveExport
): ContentActionTypes {
  return { type: SET_ACTIVE_EXPORT, activeExport }
}

export function setSocialAssetModalOpen(
  isSocialAssetModalOpen: boolean
): ContentActionTypes {
  return { type: SET_IS_SOCIAL_ASSET_MODAL_OPEN, isSocialAssetModalOpen }
}
