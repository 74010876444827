import React from 'react'
import ReactPlayer from 'react-player/lazy'
import styled, { css } from 'styled-components'

// @ts-ignore
import callToActionVideo from 'assets/video/Magic_boards.mp4'
import { Margin } from 'components/common/Margin'
import SearchUpload from 'components/search/SearchUpload'
import useIsMobile from 'hooks/useIsMobile'

import { Text } from '../../common/Text'

export const MagicBoardThreshold: React.FC<{ items: any }> = ({ items }) => {
  const isMobile = useIsMobile()
  return (
    <Wrapper>
      <ThresHoldContainer>
        <CallToAction>
          <Text size="lg" color="neutral.0">
            Upload at least 10 items to unlock magic boards
          </Text>
          <Margin y={30} />
          <ReactPlayer
            url={callToActionVideo}
            playing
            playsinline
            loop
            muted
            width={isMobile ? '100%' : 580}
            height={isMobile ? 'auto' : undefined}
            config={{
              file: {
                attributes: {
                  autoPlay: true,
                  muted: true,
                },
              },
            }}
          />
          <Margin y={30} />
          <SearchUpload />
        </CallToAction>
      </ThresHoldContainer>
      <div style={{ pointerEvents: 'none' }}>{items}</div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
`

const ThresHoldContainer = styled.div`
  z-index: 1;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 30px;
  width: 100%;
`

const CallToAction = styled.div`
  ${({ theme }) => css`
    border: ${`${theme.border.thin} ${theme.colors.discreet}`};
    box-shadow: ${theme.shadow.minimal};
    text-align: left;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 32px;
    background: ${theme.colors.background[3]};
    border-radius: ${theme.borderRadius.default};
    @media screen and (max-width: ${theme.breakpoints.sm}px) {
      width: 298px;
      padding: 18px;
    }
  `}
`
