import { useDispatch, useSelector } from 'react-redux'

import { useLastActiveWorkspaceQuery } from 'generated/graphql'
import { useActiveWorkspace } from 'hooks/useActiveWorkspace'
import { RootState } from 'store'
import { setActiveWorkspace } from 'store/content/actions'

export const useInitActiveWorkspaceFromLastActiveWorkspace = ({
  skip,
}: {
  skip: boolean
}) => {
  const dispatch = useDispatch()
  const activeWorkspace = useActiveWorkspace()
  const currentUserId = useSelector(
    (state: RootState) => state.firebase.auth.uid
  )
  useLastActiveWorkspaceQuery({
    skip: !currentUserId || Boolean(activeWorkspace) || skip,
    onCompleted: (res) => {
      const workspace = res.lastActiveWorkspace
      if (workspace) {
        dispatch(setActiveWorkspace(workspace))
      }
    },
  })
}
