import { QuestionCircle } from '@styled-icons/bootstrap/QuestionCircle'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { GuideModal } from 'components/guide-modal/GuideModal'
import {
  StyledButtonMedium,
  StyledButtonSmall,
} from 'features/editor/ButtonMedium'

type Size = 'small' | 'large'

const StyledIcon = styled(QuestionCircle)<{ size: Size }>`
  height: ${({ size }) => (size === 'large' ? '16px' : '12px')};
  color: white;
`

const Button = ({
  size,
  onClick,
  children,
  buttonText,
}: {
  size: string
  onClick: (e: any) => void
  children: any
  buttonText?: string
}) =>
  size === 'large' ? (
    <StyledButtonMedium hasText={!!buttonText} onClick={onClick}>
      {children}
    </StyledButtonMedium>
  ) : (
    <StyledButtonSmall hasText={!!buttonText} onClick={onClick}>
      {children}
    </StyledButtonSmall>
  )

export const GuideCanvas = ({
  show,
  onClose,
}: {
  show: boolean
  onClose?: () => void
}) => {
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    setShowModal(show)
  }, [show])

  const onCloseModal = () => {
    onClose?.()
    setShowModal(false)
  }

  return (
    <Button
      size="large"
      onClick={() => {
        setShowModal(true)
      }}
      buttonText="Guide"
    >
      Guide
      <StyledIcon size="large" />
      {showModal && (
        <GuideModal id="canvas" onDone={onCloseModal} onClose={onCloseModal} />
      )}
    </Button>
  )
}
