import { filter, map } from 'lodash'
import { useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'

const flattenIdTuple = (idTuple, extraProps = {}) =>
  idTuple && { id: idTuple[0], ...idTuple[1], ...extraProps }

function SettingsData({ children, billing, ...props }) {
  // Find active plan for this workspace among plans
  const activePlan = useMemo(() => {
    const planEntries = Object.entries(props.plans ?? {})
    const planEntry = billing?.plan
      ? // Get billing plan matching subscription
        planEntries.find(([id]) => id === billing.plan.replace('v2_', ''))
      : // Customer has no subscription, match default free billing plan
        planEntries.find(([, plan]) => plan.defaultOption === true)
    return flattenIdTuple(planEntry, { cancelsAt: billing?.cancelsAt })
  }, [billing?.plan, billing?.cancelsAt, props.plans])

  const isWorkspaceAdmin = useMemo(
    () => props.isWorkspaceAdmin,
    [props.isWorkspaceAdmin]
  )
  const activeWorkspaceId = useMemo(
    () => props?.activeWorkspace?.id,
    [props?.activeWorkspace?.id]
  )
  const activeWorkspaceTitle = useMemo(
    () => props?.activeWorkspace?.title,
    [props?.activeWorkspace?.title]
  )
  const activeWorkspaceAdmins = useMemo(
    () => props?.activeWorkspace?.admins,
    [props?.activeWorkspace?.admins]
  )
  const activeWorkspaceMembers = useMemo(
    () => props?.activeWorkspace?.members,
    [props?.activeWorkspace?.members]
  )
  const activeWorkspaceAllowedEmailDomains = useMemo(
    () => props?.activeWorkspace?.allowedEmailDomains,
    [props?.activeWorkspace?.allowedEmailDomains]
  )
  const activePlanId = useMemo(() => activePlan?.id, [activePlan?.id])

  useEffect(() => {
    window.trackingContext = {
      ...window.trackingContext,
      plan: activePlan,
    }
  }, [activePlan])

  useEffect(() => {
    // Add workspacePlan as general property on all tracking calls. TO DO - consolidate where trackingContext is set
    window.trackingContext = {
      ...window.trackingContext,
      workspacePlan: activePlanId,
    }
    try {
      if (activeWorkspaceId) {
        const data = {
          g_workspace_id: activeWorkspaceId,
          name: activeWorkspaceTitle,
          admins: activeWorkspaceAdmins,
          members: activeWorkspaceMembers,
          allowedEmailDomains: activeWorkspaceAllowedEmailDomains,
          seats: activeWorkspaceAdmins.length + activeWorkspaceMembers.length,
        }
        if (isWorkspaceAdmin) {
          window.analytics.group(activeWorkspaceId, {
            ...data,
            currentPlan: activePlanId,
          })
        } else {
          window.analytics.group(activeWorkspaceId, data)
        }
      }
    } catch (error) {
      console.warn(`issue setting segment group properties`, error)
    }
  }, [
    isWorkspaceAdmin,
    activeWorkspaceId,
    activeWorkspaceTitle,
    activeWorkspaceAdmins,
    activeWorkspaceMembers,
    activeWorkspaceAllowedEmailDomains,
    activePlanId,
  ])

  return props.activeWorkspace ? children(props, activePlan) : null
}

const mapStateToProps = (state) => {
  const workspaceId = state.content.activeWorkspace?.id

  const billing = state.firestore.data[`billing.${workspaceId}`]
  const settingsWorkspace =
    state.firestore.data[`settingsWorkspace.${workspaceId}`]

  const workspace = settingsWorkspace && {
    id: workspaceId,
    ...settingsWorkspace,
  }

  const plans = filter(
    map(state.firestore.data?.plans, (plan, key) => ({ ...plan, key })),
    (plan) => plan.status === 'active' || plan.status === 'hide'
  ).reduce(
    (sum, plan) => ({ ...sum, [plan.key.replace('v2_', '')]: { ...plan } }),
    {}
  )

  return {
    workspaceId,
    activeWorkspace: workspace,
    isWorkspaceAdmin: workspace?.admins?.includes(state.firebase?.auth?.email),
    billing,
    billingLoaded: billing !== undefined,
    hasBillingInfo: billing?.stripe?.customer != null,
    plans,
    currentCredit:
      state.firestore.data?.users?.[state.firebase.auth?.uid]
        ?.unappliedCredit ?? 0,
  }
}

const mapFireStoreToProps = (props) => {
  if (!props.workspaceId) {
    return []
  }
  return [
    props.isWorkspaceAdmin && {
      collection: 'billing',
      doc: props.workspaceId,
      storeAs: `billing.${props.workspaceId}`,
    },
    {
      collection: 'workspaces',
      doc: props.workspaceId,
      storeAs: `settingsWorkspace.${props.workspaceId}`,
    },
    'plans',
  ].filter((query) => query)
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect(mapFireStoreToProps)
  // @ts-ignore
)(SettingsData)
