import { ApolloProvider } from '@apollo/client'
import { client } from 'apollo'
import { createBrowserHistory } from 'history'
import { parse, stringify } from 'query-string'
import { ReactNode } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import FullStory from 'react-fullstory'
import { Provider, useSelector } from 'react-redux'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom'
import { createFirestoreInstance } from 'redux-firestore'
import { ThemeProvider } from 'styled-components'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'

import { CookieConsentBanner } from 'components/cookie-consent-banner/CookieConsentBanner'
import ToastMessages from 'components/toast/ToastMessages'
import { TooltipProvider } from 'components/tooltip/Tooltip'
import { firebase } from 'config/firebase'
import { trackPage } from 'helpers/tracking/tracking'
import 'helpers/wdyr'
import { GuestUserProvider } from 'hooks/useGuest'
import { SyncGrowthbookProvider } from 'hooks/useSyncGrowthbook'
import { Routing } from 'routing/Routing'
import { RootState, store } from 'store'
import { darkTheme, lightTheme } from 'styles/theme'

require('helpers/elementFocusOptionsPolyfill')

const inProduction = process.env.REACT_APP_ENV === 'PRODUCTION'

const rrfProps = {
  firebase,
  config: {},
  dispatch: store.dispatch,
  createFirestoreInstance,
}

const history = createBrowserHistory({ window })

let prevPath = ''

history.listen(({ location }) => {
  if (location.pathname !== prevPath) {
    trackPage(location.pathname)
  }
  prevPath = location.pathname
})

type ThemingProps = { children: ReactNode }

function Theming({ children }: ThemingProps) {
  const theme = useSelector((state: RootState) =>
    state.content.theme === 'lightTheme' ? lightTheme : darkTheme
  )
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export function App() {
  return (
    <ApolloProvider client={client}>
      <SyncGrowthbookProvider>
        <Provider store={store}>
          <ReactReduxFirebaseProvider {...rrfProps}>
            <GuestUserProvider>
              <Theming>
                <TooltipProvider>
                  <DndProvider backend={HTML5Backend}>
                    {inProduction && <FullStory org="H5PD1" />}
                    <HistoryRouter history={history}>
                      <QueryParamProvider
                        adapter={ReactRouter6Adapter}
                        options={{
                          searchStringToObject: parse,
                          objectToSearchString: stringify,
                        }}
                      >
                        <ToastMessages>
                          <Routing />
                        </ToastMessages>
                      </QueryParamProvider>
                    </HistoryRouter>
                  </DndProvider>
                </TooltipProvider>
                <CookieConsentBanner />
              </Theming>
            </GuestUserProvider>
          </ReactReduxFirebaseProvider>
        </Provider>
      </SyncGrowthbookProvider>
    </ApolloProvider>
  )
}
