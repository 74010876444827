import {
  GrowthBook,
  GrowthBookProvider,
  useFeature,
  useGrowthBook,
} from '@growthbook/growthbook-react'
import { useEffect } from 'react'

import { GROWTHBOOK_URL } from 'config/growthbook'
import { trackExperimentViewed } from 'helpers/tracking/tracking'

type GrowthBookProviderProps = {
  children: React.ReactNode
}

const growthbook = new GrowthBook({
  trackingCallback: (experiment, result) =>
    trackExperimentViewed({
      experimentId: experiment.key,
      variationId: result.variationId,
    }),
})

const SyncGrowthbookProvider = ({ children }: GrowthBookProviderProps) => {
  useEffect(() => {
    const updateGrowthbookFeatures = async () => {
      const response = await fetch(GROWTHBOOK_URL)
      if (!response.ok) {
        return
      }

      const { features } = await response.json()
      if (!features) {
        return
      }
      growthbook.setFeatures(features)
    }

    updateGrowthbookFeatures()
    const updateInterval = setInterval(updateGrowthbookFeatures, 1000 * 60 * 10)

    return () => clearInterval(updateInterval)
  }, [])

  return (
    <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>
  )
}

const useSyncGrowthbook = useGrowthBook
const useGrowthBookFeature = useFeature

export { useSyncGrowthbook, useGrowthBookFeature, SyncGrowthbookProvider }
