import { Cross } from '@styled-icons/entypo/Cross'
import { motion } from 'framer-motion'
import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import Button from 'components/common/Button'
import { Text } from 'components/common/Text'
import { DBPlan } from 'types/db'

import { uploadLimitBytes, uploadLimitFileCount } from './fileHelpers'

const bytesLimitDB = (uploadLimitBytes / 1000000000).toFixed(2)

export const MaxBytesReachedPopup = () => {
  const [isDismissed, setIsDismissed] = useState(false)
  if (isDismissed) return null
  return (
    <UploadPopupWrapper
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
    >
      <Text size="3xl">💪</Text>
      <Text size="lg" bold css="margin-bottom:12px">
        Hey heavy lifter
      </Text>
      <Text size="sm" color="neutral.2">
        You can only upload {bytesLimitDB} GB in one go. Start this upload first
        and upload the rest later.
      </Text>
      <Button
        onClick={() => setIsDismissed(true)}
        fullWidth
        variant="primary"
        css="margin-top:18px"
      >
        Got it
      </Button>
      <LeftArrow />
    </UploadPopupWrapper>
  )
}

export const MaxFileCountReachedPopup = () => {
  const [isDismissed, setIsDismissed] = useState(false)
  if (isDismissed) return null
  return (
    <UploadPopupWrapper
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
    >
      <Text size="3xl">💪</Text>
      <Text size="lg" bold css="margin-bottom:12px">
        Hey heavy lifter
      </Text>
      <Text size="sm" color="neutral.2">
        You can only upload {uploadLimitFileCount.toLocaleString('en')} items in
        one go. Start this upload first and upload the rest later.
      </Text>
      <Button
        onClick={() => setIsDismissed(true)}
        fullWidth
        variant="primary"
        css="margin-top:18px"
      >
        Got it
      </Button>
      <LeftArrow />
    </UploadPopupWrapper>
  )
}

interface WorkspaceLimitReachedPopupProps {
  plan: DBPlan
  nextPlan: DBPlan | null
  onUpgrade: () => void
}

export const WorkspaceLimitReachedPopup: React.FC<
  WorkspaceLimitReachedPopupProps
> = ({ plan, nextPlan, onUpgrade }) => {
  const [isDismissed, setIsDismissed] = useState(false)

  if (!nextPlan || isDismissed) return null

  const nextPlanDescriptions = (nextPlanName: string) => {
    if (nextPlanName === 'Pro') {
      return `Upload up to ${nextPlan.limits.items.toLocaleString(
        'en'
      )} items on the ${nextPlan.presentation.name} plan.`
    }
    if (nextPlanName === 'Team') {
      return `Upload unlimited items on the ${nextPlan.presentation.name} plan.`
    }
  }

  return (
    <UploadPopupWrapper
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
    >
      <DismissIcon onClick={() => setIsDismissed(true)} />
      <Text size="3xl">👀</Text>
      <Text size="lg" bold css="margin-bottom:12px">
        Hey power player
      </Text>
      <Text size="sm" color="neutral.2">
        You can&apos;t upload more than {plan.limits.items.toLocaleString('en')}{' '}
        items on the {plan.presentation.name} plan.{' '}
        {nextPlanDescriptions(nextPlan.presentation.name)}
      </Text>
      <Button
        onClick={onUpgrade}
        fullWidth
        variant="primary"
        css="margin-top:18px"
      >
        Get {nextPlan.presentation.name}
      </Button>
      <LeftArrow />
    </UploadPopupWrapper>
  )
}

const LeftArrow = styled.div`
  ${({ theme }) => css`
    position: absolute;
    bottom: 35px;
    right: calc(100% - 10px);
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
    background-color: ${theme.colors.background[5]};
    @media (max-width: 1000px) {
      display: none;
    }
  `}
`

const UploadPopupWrapper = styled(motion.div)`
  ${({ theme }) => css`
    border-radius: ${theme.borderRadius.lg};
    position: absolute;
    left: 110%;
    bottom: 0;
    width: 250px;
    padding: 18px;
    background-color: ${theme.colors.background[5]};
    @media (max-width: 1000px) {
      left: auto;
      right: 0;
    }
  `}
`

const DismissIcon = styled(Cross)`
  height: 20px;
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
`
