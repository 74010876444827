// @ts-nocheck
import { useBillingSubscriptionQuery } from 'generated/graphql'

import { match } from '../../helpers/utilityFunctions'
import Modal from '../common/Modal'
import Account from './Account/Account'
import { CheckoutCancel } from './CheckoutCancel'
import { CheckoutSuccess } from './CheckoutSuccess'
import { ComparePlans } from './Compare/ComparePlans'
import Members from './Members/Members'
import SettingsData from './SettingsData'
import {
  Disclaimer,
  Heading,
  SettingsContainer,
  Sidebar,
  SidebarLink,
  SidebarLinkMenu,
} from './SettingsStyles'
import WorkspaceSettings from './WorkspaceSettings/WorkspaceSettings'
import useSettingNavigation from './useSettingsNavigation'

export default function Settings() {
  const { section, close } = useSettingNavigation()
  return (
    <Modal isOpen={section != null} close={close} width="90%" height="90%">
      <SettingsData>
        {(props, activePlan) => {
          return <SettingsContent props={props} activePlan={activePlan} />
        }}
      </SettingsData>
    </Modal>
  )
}

type SettingsContentProps = {
  props: any
  activePlan: any
}

const SettingsContent = ({ props, activePlan }: SettingsContentProps) => {
  const { data: subscription, refetch: refetchBillingSubscription } =
    useBillingSubscriptionQuery({
      variables: {
        workspaceId: props.activeWorkspace.id,
      },
      fetchPolicy: 'cache-and-network',
    })
  const { section, sectionUrl } = useSettingNavigation()
  const NavSidebarLink = ({
    to = '',
    children,
  }: {
    to?: string
    children: any
  }) => (
    <SidebarLink to={sectionUrl(to)} active={to === section}>
      {children}
    </SidebarLink>
  )
  return (
    <SettingsContainer>
      <Sidebar>
        <nav>
          <Heading marginTop={false}>You</Heading>
          <SidebarLinkMenu>
            <NavSidebarLink to="account">Settings</NavSidebarLink>
          </SidebarLinkMenu>
          <Heading>Workspace</Heading>
          <SidebarLinkMenu>
            {props.isWorkspaceAdmin && (
              <NavSidebarLink to="settings">Settings</NavSidebarLink>
            )}
            <NavSidebarLink to="members">Members</NavSidebarLink>
            {props.isWorkspaceAdmin && (
              <NavSidebarLink to="plans">Plans</NavSidebarLink>
            )}
          </SidebarLinkMenu>
        </nav>
        {!props.isWorkspaceAdmin && (
          <Disclaimer>
            More workspace settings requires admin priviligies in{' '}
            <span style={{ whiteSpace: 'nowrap' }}>
              {props.activeWorkspace?.title}
            </span>
          </Disclaimer>
        )}
      </Sidebar>
      {match(section, {
        account: <Account />,
        settings: <WorkspaceSettings workspace={props.activeWorkspace} />,
        members: (
          <Members workspace={props.activeWorkspace} activePlan={activePlan} />
        ),
        plans: props.isWorkspaceAdmin ? (
          <ComparePlans
            {...props}
            activePlan={activePlan}
            currentMemberCount={
              (props.activeWorkspace.admins.length ?? 0) +
              (props.activeWorkspace.members.length ?? 0)
            }
            subscription={subscription}
            refetchBillingSubscription={refetchBillingSubscription}
          />
        ) : null,
        checkout_success: props.isWorkspaceAdmin ? (
          <CheckoutSuccess
            refetchBillingSubscription={refetchBillingSubscription}
            subscription={subscription}
          />
        ) : null,
        checkout_cancel: props.isWorkspaceAdmin ? (
          <CheckoutCancel
            refetchBillingSubscription={refetchBillingSubscription}
            subscription={subscription}
          />
        ) : null,
      })}
    </SettingsContainer>
  )
}
