import styled from 'styled-components'

/**
 * @TODO this should be properly typed, with types for theme, style etc
 */
export const HeaderBlockOuter = styled.div<any>((props) => ({
  marginLeft: props.right && 'auto',
  fontSize: '16px',
}))

export const HeaderBlockInner = styled.div(({ theme, style }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  // paddingLeft: '24px',
  padding: '16px 11px',
  cursor: 'pointer',
  height: '60px',
  fontWeight: 'bold',
  color: theme.colors.text.neutral[0],
  transition: '0.1s all ease-in-out',
  ':hover': {
    color: theme.colors.accent[2],
  },
  '@media (max-width: 1300px)': {},
  '@media (max-width: 800px)': {
    padding: '0 8px',
  },
  ...style,
}))

export const HeaderBlockAnchor = styled.a<{ isOnlyLogoVisisble: boolean }>(
  ({ theme, style, isOnlyLogoVisisble }) => ({
    opacity: isOnlyLogoVisisble ? 0 : 1,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontWeight: 'bold',
    color: theme.colors.text.neutral[0],
    transition: '0.1s all ease-in-out',
    ':hover': {
      color: theme.colors.accent[2],
    },
    '@media (max-width: 1300px)': {},
    '@media (max-width: 800px)': {
      padding: '0 8px',
    },
    ...style,
    gap: 16,
  })
)
