import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import { ButtonVariant } from '../../common/Button'
import { SubscriptionInterval } from '../types'

export const StyledFeatureRow = styled.div`
  ${({ theme }) => css`
    padding: 4px 0px;
  `}
`

const StyledPlanMainHeading = styled.h2`
  ${({ theme, color }) => css`
    color: ${color ?? theme.colors.text.neutral[1]};
    font-size: ${theme.fontSizes.lg};
    font-weight: ${theme.fontWeight.bold};
    margin: 0;
  `}
`

const StyledPlanSubHeading = styled.h3`
  ${({ theme }) => css`
    color: ${theme.colors.text.neutral[2]};
    font-weight: ${theme.fontWeight.normal};
    font-size: ${theme.fontSizes.base};
    margin: 0;
  `}
`

const StyledPlanPriceRow = styled.div``

const StyledPlanPriceAmount = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.text.neutral[1]};
    font-size: ${theme.fontSizes.base};
    font-weight: ${theme.fontWeight.bold};
  `}
`
const StyledPlanPriceUnit = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.xs};
    color: ${theme.colors.text.neutral[2]};
    opacity: 0.8;
    font-weight: ${theme.fontWeight.bold};
  `}
`

const StyledCreditInfoText = styled.span`
  ${({ theme }) => css`
    margin-top: 16px;
    font-size: ${theme.fontSizes.xs};
    color: ${theme.colors.text.neutral[3]};
  `}
`

const StyledPlanHeaderWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    row-gap: 1rem;
    flex-direction: column;
    padding-top: 16px;
    padding-bottom: 16px;
  `}
`
type ActionButtonResponse = {
  text: string
  disabled: boolean
  variant: ButtonVariant
}
type PlanHeaderProps = {
  color?: string
  name: string
  text: string
  oldPrice: string
  newPrice: string
  priceUnit: string
  interval: SubscriptionInterval
  actionButton: ActionButtonResponse
}

const getNormalizedPriceUnit = (priceUnit: string): string | null => {
  if (!priceUnit) return null

  if (/^\s/.test(priceUnit)) return priceUnit.toLowerCase()

  return ` ${priceUnit}`.toLowerCase()
}

export const PlanHeader = ({
  color,
  name,
  text,
  oldPrice,
  newPrice,
  priceUnit,
  interval,
  actionButton,
}: PlanHeaderProps) => {
  const normalizedPriceUnit = getNormalizedPriceUnit(priceUnit)
  return (
    <StyledPlanHeaderWrapper>
      <hgroup>
        <StyledPlanMainHeading color={color}>{name}</StyledPlanMainHeading>
        <StyledPlanSubHeading>{text}</StyledPlanSubHeading>
      </hgroup>
      <StyledPlanPriceRow>
        {oldPrice !== newPrice && (
          <span css="text-decoration: line-through; opacity:0.8">
            <StyledPlanPriceAmount>{oldPrice}</StyledPlanPriceAmount>
            {normalizedPriceUnit && (
              <StyledPlanPriceUnit>
                {normalizedPriceUnit.toLowerCase()}
              </StyledPlanPriceUnit>
            )}
            <br />
          </span>
        )}
        <StyledPlanPriceAmount>
          {newPrice === 'Free' ? '$0' : newPrice}
        </StyledPlanPriceAmount>
        {normalizedPriceUnit && (
          <StyledPlanPriceUnit>
            {normalizedPriceUnit.toLowerCase()}
          </StyledPlanPriceUnit>
        )}
        <CreditInfo actionButton={actionButton} />
        {newPrice !== 'Free' ? (
          <StyledPriceSubline>Billed {interval}</StyledPriceSubline>
        ) : null}
      </StyledPlanPriceRow>
    </StyledPlanHeaderWrapper>
  )
}

const StyledPriceSubline = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.xs};
    color: ${theme.colors.text.neutral[3]};
  `}
`

export const StyledContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-auto-columns: 25%;
  min-width: 700px;
`

export const StyledRow = styled.div<{
  noPad?: boolean
  row: () => number
  stretch?: number
  columnIndex?: number
}>`
  ${({ theme, noPad, row, stretch, columnIndex }) => css`
    padding: 4px ${noPad ? 0 : 16}px;
    grid-row-start: ${row()};
    grid-column-start: ${Number(columnIndex) + 1};
    ${stretch && row() && { gridRowEnd: row() + stretch }}
    border-left: ${noPad || columnIndex === 0
      ? 'none'
      : `${theme.border.thin} ${theme.colors.discreet}`};
  `}
`

const CreditInfo = ({
  actionButton,
}: {
  actionButton: ActionButtonResponse
}) => {
  // @ts-ignore
  const currentUserId = useSelector((store) => store.firebase.auth.uid)
  const unappliedCredit = useSelector(
    // @ts-ignore
    (store) => store.firestore.data.users?.[currentUserId]?.unappliedCredit || 0
  )

  if (actionButton.text === 'Choose' && unappliedCredit > 0) {
    return (
      <StyledCreditInfoText>
        <br />
        You can apply your ${unappliedCredit} credit after starting a trial.
      </StyledCreditInfoText>
    )
  }
  return null
}

export const StyledFeaturesHeading = styled.p`
  ${({ theme }) => css`
    margin-top: 10px;
    font-size: ${theme.fontSizes.xs};
    color: ${theme.colors.text.neutral[3]};
  `}
`
export const StyledFeatureCheckIcon = styled.svg`
  ${({ theme }) => css`
    height: 1rem;
    width: 1rem;
  `}
`
export const StyledFeatureLine = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 0.5rem;
    align-items: center;
    color: ${theme.colors.text.neutral[0]};
    font-size: ${theme.fontSizes.sm};
  `}
`
export const StyledFeatureSubline = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.text.neutral[1]};
    font-size: ${theme.fontSizes.xs};
    padding-top: 0.25rem;
    padding-left: 24px;
  `}
`
export const StyledPlansHeading = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    width: 100%;
  `}
`
export const StyledSubscriptionIntervalPrefix = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.text.neutral[3]};
  `}
`
export const StyledSubscriptionIntervalPrefixNumber = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.greenLighter};
  `}
`

export const StyledBanner = styled.div(({ theme }) => ({
  backgroundColor: '#80D998',
  padding: '12px',
  borderRadius: '10px',
  color: theme.colors.black,
  fontWeight: theme.fontWeight.bold,
}))

export const StyledSubscriptionIntervalWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 0.5rem;
    align-items: center;
  `}
`
