import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Outlet, useLocation, useOutlet, useParams } from 'react-router'
import styled, { css } from 'styled-components'
import { StringParam, useQueryParams } from 'use-query-params'
import { v4 as uuid } from 'uuid'

import Flex from 'components/common/Flex'
import { Spacer } from 'components/common/Spacer'
import { Text } from 'components/common/Text'
import { DiscoveryMadeForYou } from 'components/discover/DiscoveryMadeForYou'
import PublicItemView from 'components/item/PublicItemView'
import SearchCreation from 'components/search/SearchCreation'
import {
  trackDiscoveryLearnMoreClicked,
  trackDiscoveryOpened,
} from 'helpers/tracking/tracking'
import useIsMobile from 'hooks/useIsMobile'
import { useGrowthBookFeature } from 'hooks/useSyncGrowthbook'
import { RootState } from 'store'

import { LearnMoreButton } from './components/LearnMoreButton'
import { IdeasForBoardsRow } from './ideas-for-boards-row/IdeasForBoardsRow'
import { PublicBoardsRow } from './public-boards-row/PublicBoardsRow'
import { TagsForYouRow } from './tags-for-you-row/TagsForYouRow'
import { TopContributorsRow } from './top-contributors-row/TopContributorsRow'

export const DiscoverHome = () => {
  const [wrapperRef, setWrapperRef] = useState<HTMLDivElement | null>()
  const isMobile = useIsMobile()
  const location = useLocation()
  const { boardId } = useParams()
  const trackingSessionId = useRef('')
  const outlet = useOutlet()
  const siderSplitValue = useSelector(
    (state: RootState) => state.ui.siderSplitValue
  )
  const currentUserId = useSelector(
    (store: RootState) => store.firebase.auth.uid
  )

  const [{ item: itemId, itemSpace }] = useQueryParams({
    item: StringParam,
    itemSpace: StringParam,
  })
  const discoverBoardIdeasFeature = useGrowthBookFeature('discover-board-ideas')

  useEffect(() => {
    const id = uuid()
    trackingSessionId.current = id
    trackDiscoveryOpened({
      trackingSessionId: id,
    })
  }, [])

  const isInPublicBoard =
    location.pathname.includes('discover/boards') && !!boardId
  // We check itemSpace to make sure that the item is a board item or a discover item
  const isBoardItem = isInPublicBoard && itemSpace === 'projects'

  const HomeContent = (
    <StyledFlex
      flexDirection="column"
      css={isMobile ? 'padding-left:12px' : 'padding-left:24px'}
      py={isMobile ? 2 : 4}
    >
      <Flex flexDirection="row" alignItems="center">
        <Text size="xl" bold color="neutral.0">
          Discover ideas from the community
        </Text>
        <Spacer factor={2} axis="x" />
        <LearnMoreButton
          guideId="discover"
          size="large"
          onClick={() =>
            trackDiscoveryLearnMoreClicked({
              trackingSessionId: trackingSessionId.current,
            })
          }
        />
      </Flex>
      <Spacer factor={5} />
      {discoverBoardIdeasFeature.on && (
        <IdeasForBoardsRow trackingSessionId={trackingSessionId.current} />
      )}
      <PublicBoardsRow trackingSessionId={trackingSessionId.current} />
      <TopContributorsRow trackingSessionId={trackingSessionId.current} />
      <TagsForYouRow trackingSessionId={trackingSessionId.current} />
      {wrapperRef && (
        <>
          <Flex flexDirection="column" css="flex: 1">
            <Text bold size="lg" color="neutral.0" ellipsis>
              For you
            </Text>
          </Flex>
          <Spacer factor={2} />
          <DiscoveryMadeForYou
            scrollElement={wrapperRef}
            currentUserId={currentUserId}
            trackingSessionId={trackingSessionId.current}
          />
        </>
      )}
    </StyledFlex>
  )

  return (
    <>
      {!isInPublicBoard && (
        <SearchCreation
          placeholder="Search public items on Kive"
          shouldDisplayNotifications
          searchPublic
        />
      )}
      <StyledContainer
        ref={(ref) => setWrapperRef(ref)}
        siderSplitValue={siderSplitValue + 1}
        isBoardView={isInPublicBoard}
      >
        {!outlet && HomeContent}
        {outlet && (
          <Outlet
            context={{
              wrapperRef,
              trackingSessionId: trackingSessionId.current,
              boardId,
            }}
          />
        )}
      </StyledContainer>
      {itemId && (
        <PublicItemView
          isCloseable
          inDiscover
          isBoardItem={isBoardItem}
          trackingSessionId={trackingSessionId.current}
        />
      )}
    </>
  )
}

const StyledContainer = styled.div<{
  siderSplitValue: number
  isBoardView: boolean
}>(
  ({ siderSplitValue, isBoardView }) => css`
    overflow: scroll;
    overflow-x: hidden;
    max-height: calc(100vh - ${isBoardView ? '0px' : '60px'});
    min-height: calc(100vh - ${isBoardView ? '0px' : '60px'});
    width: calc(100vw - ${siderSplitValue}px);
  `
)

const StyledFlex = styled(Flex)`
  will-change: transform; // Added to mimic Grid component so that unvisible overlay when save-to-board is displayed is fixed relative to this element
`
