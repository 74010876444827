import { ArrowBack } from '@styled-icons/material/ArrowBack'
import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import { Link, useNavigate } from 'react-router-dom'
import styled, { css } from 'styled-components'

import Button from 'components/common/Button'
import { Margin } from 'components/common/Margin'
import { Text } from 'components/common/Text'
import { capitalizeFirstLetter } from 'helpers/formatting'
import { trackKnowledgeGraphRelatedTagClicked } from 'helpers/tracking/tracking'
import { RootState } from 'store'
import { DBKnowledgeMovie, DBKnowledgePerson } from 'types/db'

import { Overlay } from '../LibraryStyles'

const getUrlsWithLabels = (urls: string[]) =>
  urls.map((url) => {
    let label = 'Website'
    if (url.includes('imdb.com')) label = 'IMDB'
    if (url.includes('twitter.com')) label = 'Twitter'
    if (url.includes('facebook.com')) label = 'Facebook'
    if (url.includes('instagram.com')) label = 'Instagram'
    if (url.includes('itunes')) label = 'iTunes Store'
    if (url.includes('letterboxd')) label = 'Letterboxd'
    return { url, label }
  })

type MagicBoardCoverProps = {
  knowledge: DBKnowledgeMovie | DBKnowledgePerson
  coverUrl?: string | null
  name?: string
}

export const MagicBoardCover: React.FC<MagicBoardCoverProps> = ({
  coverUrl,
  knowledge,
  name,
}) => {
  const navigate = useNavigate()

  const activeWorkspace = useSelector(
    (state: RootState) => state.content.activeWorkspace
  )
  const handleTagClick = (
    tagId: string,
    tagLabel: string,
    tagDescription: string
  ) => {
    trackKnowledgeGraphRelatedTagClicked({
      tagId: knowledge.id,
      tagLabel: knowledge.name,
      tagDescription: knowledge.description,
      relatedTagId: tagId,
      relatedTagLabel: tagLabel,
      relatedTagDescription: tagDescription,
      workspaceId: activeWorkspace?.id,
    })
  }

  return (
    <>
      <ImageContainer src={coverUrl}>
        <InfoContainer>
          <Margin y={60} />
          <div
            css="position:fixed;top:75px"
            onClick={() => {
              navigate(-1)
            }}
          >
            <Button
              Icon={ArrowBack}
              style={{
                borderRadius: 16,
                margin: '0px 8px',
                padding: '8px 16px 8px 16px',
              }}
            />
          </div>
          <Row css="margin-top:auto">
            {knowledge.image && (
              <Column mobilehide>
                <Image src={knowledge.image} />
              </Column>
            )}
            <Column expand>
              {name && <Label>{capitalizeFirstLetter(name)}</Label>}
              {knowledge.description && (
                <DescriptionShort>
                  {capitalizeFirstLetter(knowledge.description)}
                </DescriptionShort>
              )}
              {knowledge.detailedDescription && (
                <DescriptionLong>
                  {knowledge.detailedDescription?.articleBody}
                </DescriptionLong>
              )}
              <ReadMore url={knowledge?.detailedDescription?.url} />
            </Column>
            {knowledge.sameAs && (
              <Column right minWidth={100}>
                {getUrlsWithLabels(knowledge.sameAs).map(({ url, label }) => (
                  <StyledA
                    key={url}
                    href={url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {label}
                  </StyledA>
                ))}
              </Column>
            )}
          </Row>
        </InfoContainer>
        {coverUrl && <Overlay />}
      </ImageContainer>

      {knowledge['@type'] && (
        <RelatedTagsContainerOuter>
          <RelatedTagsContainer>
            {knowledge['@type'] === 'Movie' && (
              <CreatorTags
                onTagClick={handleTagClick}
                creators={knowledge.creator}
              />
            )}
            {knowledge['@type'].indexOf('Person') !== -1 && (
              <CreativeWorksTags
                onTagClick={handleTagClick}
                id={knowledge.id}
              />
            )}
          </RelatedTagsContainer>
        </RelatedTagsContainerOuter>
      )}
    </>
  )
}

const CreativeWorksTags: React.FC<{
  id: string
  onTagClick: (tagId: string, tagLabel: string, tagDescription: string) => void
}> = ({ id, onTagClick }) => {
  useFirestoreConnect(
    id
      ? [
          {
            collection: 'knowledgeGraph',
            where: [`creatorIds`, 'array-contains', id],
            limit: 40,
            storeAs: `knowledgeGraph_${id}_isCreator`,
          },
        ]
      : []
  )
  const creativeWorks = useSelector(
    (state: RootState) =>
      state.firestore.ordered[`knowledgeGraph_${id}_isCreator`]
  )

  return (
    <>
      {creativeWorks?.map((creativeWork: DBKnowledgeMovie) => {
        const match = creativeWork.creator.find((c) => c.id === id)
        const role = match?.description
        return (
          <Fragment key={creativeWork.id}>
            {role ? (
              <CreatorCard
                onTagClick={onTagClick}
                name={creativeWork.name}
                role={role}
                id={creativeWork.id}
              />
            ) : null}
          </Fragment>
        )
      })}
    </>
  )
}

const ReadMore: React.FC<{ url?: string }> = ({ url }) => {
  if (!url) return null
  const urlParsed = new URL(url)
  const urlElements = urlParsed.hostname.split('.')
  const siteName = urlElements[urlElements.length - 2]
  const formattedName =
    siteName === 'imdb' ? 'IMDB' : capitalizeFirstLetter(siteName)
  const text = `Read more on ${formattedName}`
  return (
    <StyledA href={url} target="_blank" rel="noreferrer">
      {text}
    </StyledA>
  )
}

const CreatorTags: React.FC<{
  creators: DBKnowledgePerson[]
  onTagClick: (tagId: string, tagLabel: string, tagDescription: string) => void
}> = ({ creators, onTagClick }) => {
  return (
    <>
      {creators.map((creator, i) => (
        <CreatorCard
          onTagClick={onTagClick}
          name={creator.name}
          role={creator.description}
          id={creator.id}
          key={creator.id}
        />
      ))}
    </>
  )
}

const CreatorCard: React.FC<{
  name: string
  role: string
  id: string
  onTagClick: (tagId: string, tagLabel: string, tagDescription: string) => void
}> = ({ name, role, id, onTagClick }) => {
  const activeWorkspace = useSelector(
    (state: RootState) => state.content.activeWorkspace
  )
  return (
    <CreatorCardContainer
      onClick={() => onTagClick(id, name, role)}
      to={
        activeWorkspace
          ? `/${activeWorkspace?.url}/library/magic-boards/${id}`
          : '/'
      }
    >
      <Text size="sm" color="neutral.1">
        {name}
      </Text>
      {role && (
        <Text size="sm" color="neutral.3">
          {role}
        </Text>
      )}
    </CreatorCardContainer>
  )
}

const Label = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.xl};
    font-weight: ${theme.fontWeight.bold};
    margin-bottom: 16px;
    line-height: 32px;
  `}
`

const DescriptionShort = styled.div`
  ${({ theme }) => css`
    font-weight: ${theme.fontWeight.bold};
    margin-bottom: 8px;
  `}
`

const DescriptionLong = styled.div`
  max-width: 400px;
  margin-bottom: 8px;
  text-align: left;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

interface ColumnProps {
  minWidth?: number
  expand?: boolean
  right?: boolean
  mobilehide?: boolean
}

const Column = styled.div<ColumnProps>`
  ${({ expand, minWidth, right, mobilehide }) => css`
    ${minWidth &&
    css`
      minwidth: ${minWidth}px;
    `}
    ${expand &&
    css`
      flex: 1;
    `}
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: ${right ? 'flex-end' : 'start'};
    margin: 8px;
    @media (max-width: 768px) {
      display: ${mobilehide ? 'none' : 'inline'};
    } ;
  `}
`

const Image = styled.div<{ src: string }>`
  ${({ theme, src }) => css`
    width: 150px;
    padding-bottom: 143.33%;
    border-radius: ${theme.borderRadius.lg};
    background-image: url(${src});
    background-size: cover;
    background-position: center;
    border: ${theme.border.thin} ${theme.colors.discreet};
    @media (max-width: 1308px) {
      width: 120px;
    } ;
  `}
`

const InfoContainer = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    z-index: 2;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 16px;
  `}
`

const ImageContainer = styled.div<{ src?: string | null }>`
  ${({ src }) => css`
    min-height: 220px;
    background-image: url(${src});
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
  `}
`

const RelatedTagsContainerOuter = styled.div`
  ${({ theme }) => css`
    height: 60px;
    position: relative;
    overflow-x: auto;
    background: ${theme.colors.black};
    border-bottom: ${theme.border.thin} ${theme.colors.discreet};
    border-top: ${theme.border.thin} ${theme.colors.discreet};
    ::-webkit-scrollbar {
      display: none;
    }
  `}
`
const RelatedTagsContainer = styled.div`
  position: absolute;
  white-space: nowrap;
  left: 0;
  top: 0;
`

const CreatorCardContainer = styled(Link)`
  ${({ theme }) => css`
    height: 100%;
    display: inline-block;
    padding: 8px 32px;
    cursor: pointer;
    &:hover ${Text} {
      color: ${theme.colors.accent[2]};
    }
  `}
`

const StyledA = styled.a`
  ${({ theme }) => css`
    margin: 8px 0px 0px 0px;
    text-decoration: underline;
    color: ${theme.colors.white};
    &:hover {
      color: ${theme.colors.accent[2]};
    }
    @media (max-width: 768px) {
      margin: 0px 16px 0px 0px;
    } ;
  `}
`
