import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Emoji from 'react-emoji-render'
import styled from 'styled-components'

import { IconWrapper } from 'components/setup/SetupCommon'

const SelectOptionStyled = styled.div(({ selected, isMobile, theme }) => ({
  background: selected
    ? theme.colors.background[3]
    : theme.colors.background[1],
  color: selected ? theme.colors.text.neutral[0] : theme.colors.text.neutral[4],
  borderRadius: theme.borderRadius.default,
  border: selected
    ? `${theme.border.thin} ${theme.colors.accent[2]}`
    : `${theme.border.thin} ${theme.colors.gray.dark[4]}`,
  width: 'calc(50% - 8px)',
  maxWidth: 400,
  height: 44,
  margin: 4,
  overflow: 'hidden',
  padding: 8,
  display: 'inline-flex',
  alignItems: 'center',
  position: 'relative',
  cursor: 'pointer',
  ':hover': {
    color: theme.colors.text.neutral[1],
    background: theme.colors.background[4],
  },
  '@media (max-width: 632px)': {
    width: isMobile ? '100%' : 'calc(100% - 8px)',
    maxWidth: 'calc(100% - 8px)',
  },
}))

const SelectOptionComponent = styled.div(({ theme, style }) => ({
  fontSize: theme.fontSizes.base,
  lineHeight: theme.lineHeight.tight,
  fontWeight: theme.fontWeight.medium,
  margin: '0 8px',
  display: 'inline-block',
  ...style,
}))

function SelectOption({ emojiCode = null, icon, children, ...props }) {
  return (
    <>
      {icon && (
        <SelectOptionComponent style={{ margin: 0 }}>
          <IconWrapper icon={icon} marginLeft={0} />
        </SelectOptionComponent>
      )}
      {emojiCode && (
        <SelectOptionComponent>
          <Emoji style={{ fontSize: 20 }} text={emojiCode} />
        </SelectOptionComponent>
      )}
      {children && <SelectOptionComponent>{children}</SelectOptionComponent>}
    </>
  )
}

SelectOption.propTypes = {
  emojiCode: PropTypes.string,
  icon: PropTypes.element,
}

SelectOption.defaultProps = {
  emojiCode: null,
  icon: null,
}

function Select({
  children,
  onChange,
  multi,
  isMobile,
  defaultVal,
  mustHaveSelected,
  ...props
}) {
  const [selectedOptions, setSelectedOptions] = useState([])
  const [selectedOptionValues, setSelectedOptionValues] = useState([])

  useEffect(() => {
    setSelectedOptions((arr) => [...arr, defaultVal])
  }, [defaultVal])

  function toggle(id, value) {
    let selectedOptionsUpdate = selectedOptions.filter((i) => i !== id)
    let selectedOptionValuesUpdate = selectedOptionValues.filter(
      (v) => v !== value
    )

    const optionWasActive =
      selectedOptionsUpdate.length !== selectedOptions.length

    if (mustHaveSelected && optionWasActive) {
      return
    }

    if (!multi) {
      selectedOptionsUpdate = []
      selectedOptionValuesUpdate = []
    }

    if (!optionWasActive) {
      selectedOptionsUpdate.push(id)
      selectedOptionValuesUpdate.push(value)
    }

    setSelectedOptionValues(selectedOptionValuesUpdate)
    setSelectedOptions(selectedOptionsUpdate)
    onChange(selectedOptionValuesUpdate)
  }

  return (
    <>
      {React.Children.map(children, (item, index) => (
        <SelectOptionStyled
          selected={selectedOptions.includes(index)}
          onClick={() => toggle(index, item.props.value)}
        >
          {item}
        </SelectOptionStyled>
      ))}
    </>
  )
}

Select.propTypes = {
  onChange: PropTypes.func,
}

Select.defaultProps = {
  onChange: () => null,
}

export default Select
export { SelectOption }
