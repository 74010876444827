import React from 'react'
import styled from 'styled-components'

const Main = styled.div(({ theme, style }) => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  fontFamily: theme.fontFamily.primary,
  fontSize: '12px',
  ...style,
}))

const FullscreenBackground = ({ children, ...props }) => (
  <Main {...props}>
    <div
      style={{
        backgroundImage: `url(${props.backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: props.backgroundSize,
        position: 'absolute',
        width: '100%',
        height: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {children}
      </div>
    </div>
  </Main>
)

export default FullscreenBackground
