import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Spacer } from 'components/common/Spacer'
import { HighlightRow } from 'components/highlight-row/HighlightRow'
import { UploadEventPreviewCard } from 'components/upload-event-preview-card/UploadEventPreviewCard'
import { useUploadSessionsFinishedQuery } from 'generated/graphql'
import { trackHomeRecentUploadEventClicked } from 'helpers/tracking/tracking'
import useIsMobile from 'hooks/useIsMobile'

type RecentUploadEventsRowProps = {
  workspaceId: string
}

export const RecentUploadEventsRow = (props: RecentUploadEventsRowProps) => {
  const isMobile = useIsMobile()

  const { data } = useUploadSessionsFinishedQuery({
    variables: {
      workspaceId: props.workspaceId,
      first: 6,
    },
    fetchPolicy: 'cache-and-network',
    context: {
      batch: true,
    },
  })

  const uploadSessions = data?.uploadSessionsFinished.uploadSessions || []

  if (uploadSessions.length === 0) return null

  return (
    <>
      <HighlightRow
        title="Recent upload events"
        link={{
          path: 'library/upload-events',
          text: isMobile ? 'View all' : 'View all upload events',
        }}
        listItems={uploadSessions}
        renderListItem={(uploadSession) => {
          return (
            <Link
              to={`library/upload-events/${uploadSession.id}`}
              onClick={trackHomeRecentUploadEventClicked}
            >
              <StyledWrapper>
                <UploadEventPreviewCard
                  title={uploadSession.title}
                  createdBy={uploadSession.createdBy}
                  createdAt={uploadSession.createdAt}
                  infoTextOne={`${uploadSession.size} uploads`}
                  infoTextTwo={`${uploadSession.tagCount} magic pins`}
                  thumbUrl={uploadSession.thumbUrl}
                  isCreatingMagicBoards={false}
                  isProcessing={false}
                />
              </StyledWrapper>
            </Link>
          )
        }}
      />
      <Spacer factor={5} />
    </>
  )
}

const StyledWrapper = styled.div`
  width: 340px;
`
