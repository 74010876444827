// There is no way of finding out if a wheel event is from a mouse or trackpad
// Don't ask me why this works and how it works
// OBS: Doesn't work in firefox :/
export function detectTrackPad(e: any) {
  const x = e.deltaY * -3
  if (e.wheelDeltaY) {
    if (e.wheelDeltaY === x || e.wheelDeltaY + (x > 0 ? +1 : -1) === x) {
      return true
    }
  } else if (e.deltaMode === 0) {
    return true
  }
  return false
}
