import { useState } from 'react'
import { useNavigate } from 'react-router'

import { useCreateEditSessionMutation } from '../../../generated/graphql'
import Loading from '../../Loading'
import Button from '../../common/Button'
import { ItemData } from '../../item/hooks/useGetItemData'

type Props = {
  item: ItemData
  workspaceId: string
  userId: string
}
export const CreateEditSessionButton = ({
  item,
  userId,
  workspaceId,
}: Props) => {
  const [clicked, setClicked] = useState(false)
  const [createEditSessionMutation, { loading, error }] =
    useCreateEditSessionMutation()
  const navigate = useNavigate()

  const handleClick = async () => {
    setClicked(true)
    console.log('CreateEditSessionButton item', item)
    const largeImage = item.mediaVariants.find((i) => i.variant === 'large')
    const baseImageUrl = largeImage?.url
    console.log('CreateEditSessionButton baseImageUrl', baseImageUrl)

    if (!baseImageUrl) {
      setClicked(false)
      return
    }

    const { data } = await createEditSessionMutation({
      variables: {
        input: {
          workspaceId,
          userId,
          baseImageUrl,
          backUrl: window.location.href,
        },
      },
    })

    console.log('CreateEditSessionButton data', { data })

    const sessionId = data?.createEditSession.id
    console.log('CreateEditSessionButton sessionId', { sessionId })
    if (!sessionId) {
      setClicked(false)
      return
    }

    setClicked(false)
    navigate(`/canvas/${sessionId}`, { replace: true })
  }
  return (
    <Button
      variant="darkSecondary"
      onClick={handleClick}
      disabled={loading || !!error || clicked}
    >
      {loading ?? <Loading />}
      Edit
    </Button>
  )
}
