import { ArrowRightShort } from '@styled-icons/bootstrap'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import styled, { css, useTheme } from 'styled-components'
import { Check } from 'styled-icons/bootstrap'

import ChromeExtensionImage from 'assets/img/chrome_extension_icon.png'
import Button from 'components/common/Button'
import Spinner from 'components/common/Spinner'
import { Pagination } from 'components/setup/SetupCommon'
import SetupInfoText from 'components/setup/SetupInfoText'
import { UploadButton } from 'components/setup/SetupStyles'
import {
  UploadSessionsRemoteUploadPayload,
  UploadSessionsRemoteUploadState,
} from 'components/upload/useUploadSessionsRemoteUpload'
import { trackSetupSessionFinished } from 'helpers/tracking/tracking'
import useIsMobile from 'hooks/useIsMobile'
import type { RootState } from 'store'
import type { DBUploadSession } from 'types/db'

import { FullScreen, UploadAction } from './UploadStyles'
import { UploadSession as UploadSessionType } from './types'

const LoadingTip = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  position: fixed;
  bottom: 40px;
  ${({ theme }) => css`
    color: ${theme.colors.text.neutral[3]};
  `}
`

interface ImportingInfoTypes {
  percentage: number
  completedUploads: number
  fileCount: number
  uploadedMB: string
  totalMB: string
}
interface ActionProps {
  active: boolean
  action: string
  importingInfo?: ImportingInfoTypes
}

const ActionItem: React.FC<ActionProps> = ({
  active,
  action,
  importingInfo,
}) => {
  const theme = useTheme()

  // Import cycling fading info messages
  const [displayFilesUploaded, setDisplayFilesUploaded] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setDisplayFilesUploaded(!displayFilesUploaded)
    }, 4000)
  }, [importingInfo || displayFilesUploaded])

  // Auto tagging cycling fading info messages
  const tags = [
    'Auto tagging',
    'Analysing colors',
    'Finding faces',
    'Watching sunsets',
    'Guessing genres',
    'Looking for freckles',
    'Chasing down cheetahs',
    'Guessing professions',
    'Finding car models',
    'Cataloguing tree types',
    'Counting kisses',
    'Celebrity-spotting',
    'Searching for sports',
    'Scanning for rain storms',
    'Investigating hair styles',
    'Looking for ice-cream',
    'Counting the birds',
    'Looking for landforms',
    'Finding famous film stills',
    'Adding a gazillion tags',
    'Finishing up',
  ]
  const [autoTagsIndex, setAutoTagsIndex] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      if (autoTagsIndex < 5) {
        setAutoTagsIndex(autoTagsIndex + 1)
      } else if (autoTagsIndex === 5) {
        setAutoTagsIndex(0)
      }
    }, 2000)
  }, [action === 'Auto tagging' && tags])

  return (
    <UploadAction active={active}>
      <AnimatePresence>
        {active ? (
          <motion.div
            layout={!(action === 'Auto tagging')}
            style={{ display: 'inline-block' }}
            initial={{ x: -10, opacity: 0 }}
            animate={{ x: 0, opacity: 1, transition: { duration: 0.3 } }}
          >
            <ArrowRightShort size={28} />
          </motion.div>
        ) : (
          <Check size={28} />
        )}
      </AnimatePresence>

      {!importingInfo && !(action === 'Auto tagging') && action}

      {/* Shows when auto tagging */}
      {!importingInfo &&
        action === 'Auto tagging' &&
        active &&
        tags[autoTagsIndex]}
      {!importingInfo && action === 'Auto tagging' && !active && 'Auto tagging'}

      {/* Shows when importing */}
      {importingInfo && (
        <>
          {active
            ? `${action} - ${importingInfo.percentage}%`
            : 'Import finished'}
        </>
      )}
      <br />
      {importingInfo && active && (
        <div style={{ fontSize: theme.fontSizes.sm }}>
          <AnimatePresence>
            {displayFilesUploaded && (
              <motion.div
                style={{
                  position: 'absolute',
                  left: '50%',
                  transform: 'translate(-50%)',
                  width: '100%',
                  textAlign: 'center',
                }}
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: { duration: 1 },
                }}
                exit={{
                  opacity: 0,
                  transition: { duration: 1 },
                }}
              >
                {importingInfo.completedUploads} / {importingInfo.fileCount}{' '}
                files finished
              </motion.div>
            )}
          </AnimatePresence>

          <AnimatePresence>
            {!displayFilesUploaded && (
              <motion.div
                style={{
                  position: 'absolute',
                  left: '50%',
                  transform: 'translate(-50%)',
                  width: '100%',
                  textAlign: 'center',
                }}
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: { duration: 1 },
                }}
                exit={{
                  opacity: 0,
                  transition: { duration: 1 },
                }}
              >
                {importingInfo.uploadedMB} MB / {importingInfo.totalMB} MB
                transferred
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      )}
    </UploadAction>
  )
}

interface MainProps {
  dbSession?: DBUploadSession | UploadSessionsRemoteUploadState
  totalBytesTransferred: number
  totalUploadSize: number
  completedUploads: number
  fileCount: number
  closeSession: (event: React.MouseEvent<Element, MouseEvent>) => void
  trackingSetupSessionId?: string | null
}

const Main: React.FC<MainProps> = ({
  totalBytesTransferred,
  totalUploadSize,
  completedUploads,
  fileCount,
  dbSession,
  closeSession,
  trackingSetupSessionId,
}) => {
  const theme = useTheme()
  const isMobile = useIsMobile()

  const [showArrowAnim, setShowArrowAnim] = useState(false)
  const [hasStartedCreatedMagicBoards, setHasStartedCreatedMagicBoards] =
    useState(false)
  const [uploadSessionIsFinished, setUploadSessionIsFinished] = useState(false)
  const [shouldShowSkipButton, setShouldShowSkipButton] = useState(false)
  const [activeIndex, setActiveIndex] = useState(30)
  const [delay, setDelay] = useState(10)

  useEffect(() => {
    setTimeout(() => {
      setShouldShowSkipButton(true)
    }, 20000)
  }, [])

  const isUploadDone = useMemo(() => {
    if (!dbSession) return false
    if ('isCompleted' in dbSession) {
      return dbSession.isCompleted
    }
    return dbSession.hasFinishedAnalyzing
  }, [dbSession])

  useEffect(() => {
    if (dbSession?.isCreatingMagicBoards) {
      setHasStartedCreatedMagicBoards(true)
    }
  }, [dbSession?.isCreatingMagicBoards])

  useEffect(() => {
    if (isUploadDone) {
      setTimeout(() => {
        setUploadSessionIsFinished(true)
      }, 2000)
    }
  }, [isUploadDone])

  useEffect(() => {
    if (dbSession?.hasFinishedUploading) {
      setActiveIndex(40)
      setTimeout(() => {
        setDelay(120)
        setActiveIndex(90)
      }, 10000)
    }
  }, [dbSession?.hasFinishedUploading])

  if (uploadSessionIsFinished) {
    return (
      <>
        <Pagination activeIndex={3} length={4} delay={1} />
        <div css="display: flex; flex-direction: column; align-items: center;">
          <h1
            style={{
              fontSize: isMobile ? theme.fontSizes['2xl'] : '4.5rem',
              fontWeight: 'normal',
            }}
          >
            Your Kive {isMobile && <br />} <b>is ready for a</b>{' '}
            {!isMobile && '🌪️'}
            {isMobile && (
              <>
                <div
                  style={{
                    fontSize: theme.fontSizes['4xl'],
                    marginTop: 0,
                  }}
                >
                  🌪️
                </div>
              </>
            )}
          </h1>
          {!isMobile ? (
            <Button
              onMouseEnter={() => setShowArrowAnim(true)}
              onMouseLeave={() => setShowArrowAnim(false)}
              variant="primary"
              onClick={(e) => {
                closeSession(e)
                trackSetupSessionFinished({
                  source: 'Uploads',
                  processingSkipped: false,
                  trackingSetupSessionId,
                })
              }}
              style={{ fontSize: theme.fontSizes.base }}
            >
              <motion.div
                initial={false}
                animate={{ x: showArrowAnim ? 2 : 0 }}
              >
                Let&apos;s go!
              </motion.div>
              <motion.div
                initial={false}
                animate={{ x: showArrowAnim ? 10 : 0 }}
              >
                <ArrowRightShort size={28} />
              </motion.div>
            </Button>
          ) : (
            <Button
              onClick={(e) => {
                closeSession(e)
                trackSetupSessionFinished({
                  source: 'Uploads',
                  processingSkipped: false,
                  trackingSetupSessionId,
                })
              }}
              variant="primary"
            >
              Let&apos;s go!
              <ArrowRightShort size={28} />
            </Button>
          )}
        </div>
      </>
    )
  }
  return (
    <>
      <Pagination activeIndex={activeIndex} length={100} delay={delay} />
      {!isMobile && !shouldShowSkipButton && (
        <LoadingTip>
          While we get things ready, install the
          <UploadButton
            onClick={() =>
              window.open(
                'https://chrome.google.com/webstore/detail/save-to-kive/hfkpeknbeaekjalojbgelphkikhkaicg',
                '_blank'
              )
            }
            style={{ display: 'flex', padding: 10 }}
          >
            <img style={{ height: 28 }} src={ChromeExtensionImage} alt="" />{' '}
            <span style={{ marginLeft: 10 }}>Chrome extension</span>
          </UploadButton>
        </LoadingTip>
      )}
      <div>
        <motion.div>
          <ActionItem
            importingInfo={{
              percentage: Math.floor(
                (100 * totalBytesTransferred) / totalUploadSize
              ),
              completedUploads,
              fileCount,
              uploadedMB: (totalBytesTransferred / 1000000).toFixed(2),
              totalMB: (totalUploadSize / 1000000).toFixed(2),
            }}
            active={!dbSession?.hasFinishedUploading}
            action="Importing content"
          />
        </motion.div>
        <AnimatePresence>
          {dbSession?.hasFinishedUploading && (
            <motion.div
              initial={{ y: -10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
            >
              <ActionItem
                active={!hasStartedCreatedMagicBoards}
                action="Auto tagging"
              />
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {dbSession?.hasFinishedUploading && hasStartedCreatedMagicBoards && (
            <motion.div
              initial={{ y: -10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
            >
              <ActionItem
                active={!isUploadDone}
                action="Letting the sorting hat do its magic"
              />
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {isUploadDone && (
            <motion.div
              initial={{ y: -10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
            >
              <ActionItem active action="Enabling ridiculously fast search" />
            </motion.div>
          )}
        </AnimatePresence>
        <SetupInfoText
          infoText="This might take a minute"
          tipText="The time it takes depends on the size of the upload."
        />
      </div>
      <AnimatePresence>
        {shouldShowSkipButton && (
          <motion.div
            initial={{ y: 10 }}
            animate={{ y: [10, -10, 0], transition: { duration: 0.4 } }}
            style={{ position: 'fixed', bottom: 60 }}
          >
            <Button
              onMouseEnter={() => setShowArrowAnim(true)}
              onMouseLeave={() => setShowArrowAnim(false)}
              variant="primary"
              style={{ padding: '0px 6px' }}
              onClick={(e) => {
                closeSession(e)
                trackSetupSessionFinished({
                  trackingSetupSessionId,
                  source: 'Uploads',
                  processingSkipped: true,
                })
              }}
            >
              <motion.div
                initial={false}
                animate={{ x: showArrowAnim ? 2 : 0 }}
              >
                Continue to your empty library in the meantime
              </motion.div>
              <motion.div
                initial={false}
                animate={{ x: showArrowAnim ? 10 : 0 }}
              >
                <ArrowRightShort size={28} />
              </motion.div>
            </Button>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

interface Props {
  sessionId: string
  localSession: UploadSessionType
  closeSession: () => void
  trackingSetupSessionId?: string | null
}

const UploadSession: React.FC<Props> = ({
  sessionId,
  localSession,
  closeSession,
  trackingSetupSessionId,
}) => {
  const dbSession = useSelector(
    (state: RootState) => state.firestore.data.uploadSessions?.[sessionId]
  )

  useFirestoreConnect(
    localSession.hasDBSession
      ? [
          {
            collection: 'uploadSessions',
            doc: sessionId,
          },
        ]
      : []
  )

  return (
    <FullScreen style={{ flexDirection: 'row' }}>
      <Main
        dbSession={dbSession}
        closeSession={closeSession}
        trackingSetupSessionId={trackingSetupSessionId}
        totalBytesTransferred={(localSession as any).bytesTransferred}
        totalUploadSize={localSession.totalUploadSize}
        completedUploads={localSession.completedUploads}
        fileCount={localSession.droppedFileCount}
      />
    </FullScreen>
  )
}

interface UploadSessionRemoteUploadProps {
  uploadSession: UploadSessionsRemoteUploadPayload
  closeSession: () => void
  trackingSetupSessionId?: string | null
}

export const UploadSessionRemoteUpload = ({
  uploadSession,
  closeSession,
  trackingSetupSessionId,
}: UploadSessionRemoteUploadProps) => {
  const theme = useTheme()
  const renderContent = () => {
    /* With remote uploads (e.g dropbox) we won't get upload progress feedback instantly  */
    if ('isLocal' in uploadSession) {
      return (
        <motion.div
          initial={{ y: -10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
        >
          <ActionItem active action={uploadSession.status} />
        </motion.div>
      )
    }
    if (uploadSession.remoteUpload?.isExtractingFrames) {
      return (
        <motion.div
          initial={{ y: -10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
        >
          <ActionItem active action="Extracting from video..." />
          <Spinner height="24px" color={theme.colors.text.neutral[3]} />
        </motion.div>
      )
    }
    return (
      <Main
        dbSession={uploadSession}
        closeSession={closeSession}
        trackingSetupSessionId={trackingSetupSessionId}
        totalBytesTransferred={uploadSession.remoteUpload!.uploadedBytes}
        totalUploadSize={uploadSession.remoteUpload!.totalBytes}
        completedUploads={uploadSession.remoteUpload!.uploadSuccessCount}
        fileCount={uploadSession.remoteUpload!.totalItemsCount}
      />
    )
  }
  return (
    <FullScreen style={{ flexDirection: 'row' }}>{renderContent()}</FullScreen>
  )
}

export { ActionItem, LoadingTip }
export default UploadSession
