import debounce from 'lodash/debounce'
import { stringify } from 'query-string'
import React, { createContext, useCallback, useContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { trackSearchFreeTextUpdated } from 'helpers/tracking'
import useQueryString from 'hooks/useQueryString'

export enum SearchMode {
  DEFAULT,
  TAG,
  COLOR,
  CATEGORY,
  FACE,
}
interface ISearchContext {
  freeText: string
  displayFreeText: string
  searchMode: SearchMode
  tags: { label: string; id: string }[]
  folders: string[]
  filename?: string
  fileFormat?: string
  color: string | null
  category: string | null
  face: string[]
  isSearching: boolean
  isSearchPanelOpen: boolean
  addTag: (label: string, id: string) => void
  removeTag: (tagId: string) => void
  setSearchMode: React.Dispatch<React.SetStateAction<SearchMode>>
  resetSearch: () => void
  updateFreeText: (newFreeText: string) => void
  updateColor: (newColor: string | null) => void
  updateCategory: (categoryId: string | null) => void
  updateIsSearching: (newIsSearching: boolean) => void
  addFaceTag: (faceTag: string) => void
  removeFaceTag: (faceTagId: string) => void
  addFolder: (folderName: string) => void
  removeFolder: (folderName: string) => void
  addFilename: (fileName: string) => void
  removeFilename: (fileName: string) => void
  addFileFormat: (fileFormat: string) => void
  removeFileFormat: (fileFormat: string) => void
  setIsSearchPanelOpen: React.Dispatch<React.SetStateAction<boolean>>
  setSearchSessionId: (searchSessionId: string) => void
}

const initialState = {
  freeText: '',
  displayFreeText: '',
  searchMode: SearchMode.DEFAULT,
  tags: [],
  folders: [],
  filename: undefined,
  fileFormat: undefined,
  color: null,
  category: null,
  isSearching: false,
  face: [],
  isSearchPanelOpen: false,
  addTag: () => null,
  removeTag: () => null,
  setSearchMode: () => null,
  resetSearch: () => null,
  updateFreeText: () => null,
  updateColor: () => null,
  updateCategory: () => null,
  updateIsSearching: () => null,
  addFaceTag: () => null,
  removeFaceTag: () => null,
  setIsSearchPanelOpen: () => null,
  addFolder: () => null,
  removeFolder: () => null,
  addFilename: () => null,
  removeFilename: () => null,
  addFileFormat: () => null,
  removeFileFormat: () => null,
  setSearchSessionId: () => null,
}

const searchContext = createContext<ISearchContext>(initialState)

export const useSearchContext = () => {
  const context = useContext(searchContext)
  if (!context) {
    throw new Error(
      'useSearchContext must be used within the useSearchContext.Provider'
    )
  }
  return context
}

const toArray = (q?: string | string[]) => {
  if (q === undefined) return []
  if (typeof q === 'string') return [q]
  return q
}

export const getEncodedTag = ({ label, id }: { label: string; id: string }) => {
  return `${label}>>${id.replaceAll('/', '-')}`
}

const getDecodedTag = (encodedTag: string) => {
  const [label, id] = encodedTag.split('>>')
  return { label, id: id.replaceAll('-', '/') }
}

const getQueryString = (newQuery: Record<string, any>) =>
  `?${stringify(newQuery, { arrayFormat: 'comma' })}`

type SearchQueryParams = {
  search?: string
  color?: string
  category?: string
  tags?: string | string[]
  face?: string | string[]
  folders?: string[]
  filename?: string
  fileFormat?: string
  searchSessionId?: string
}

export const SearchContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  // This state updates when a new search request should be triggered (it can be debounced/throttled)
  const [freeText, setFreeText] = useState('')
  // This state updates immediately so that we can display changes for the user
  const [displayFreeText, setDisplayFreeText] = useState('')
  const [searchMode, setSearchMode] = useState(SearchMode.DEFAULT)
  const [isSearchPanelOpen, setIsSearchPanelOpen] = useState(false)

  const setFreeTextDebounced = useCallback(
    debounce((newFreeText: string) => {
      setFreeText(newFreeText)
      trackSearchFreeTextUpdated({
        freeTextQuery: newFreeText,
      })
    }, 200),
    []
  )

  const navigate = useNavigate()
  const location = useLocation()

  const query = useQueryString<SearchQueryParams & Record<string, string>>()
  const {
    search: isSearching,
    category,
    color,
    tags,
    face,
    folders = [],
    filename,
    fileFormat,
  } = query

  const updateQueryString = useCallback(
    (newQuery: Record<string, any>) => {
      const newQueryString = getQueryString(newQuery)
      if (location.search === newQueryString) {
        return
      }
      navigate({ search: newQueryString })
    },
    [navigate, location.search]
  )

  const getTags = useCallback(
    () => (tags ? toArray(tags).map((tag) => getDecodedTag(tag)) : []),
    [tags]
  )

  const addTag = useCallback(
    (label: string, id: string) => {
      const tags_ = toArray(tags)
      const isTagAlreadyAdded = tags_.includes(getEncodedTag({ label, id }))
      if (isTagAlreadyAdded) {
        return
      }
      const newQuery = {
        ...query,
        tags: [...tags_, `${label}>>${id}`],
        category: undefined,
      }
      updateQueryString(newQuery)
    },
    [query, tags, updateQueryString]
  )

  const removeTag = useCallback(
    (tagId: string) => {
      const tags_ = getTags()
      const newTags = tags_
        .filter((tag) => tag.id !== tagId)
        .map((tag) => getEncodedTag(tag))
      const newQuery = {
        ...query,
        tags: newTags,
      }
      updateQueryString(newQuery)
    },
    [getTags, query, updateQueryString]
  )

  const addFolder = useCallback(
    (folderName: string) => {
      const newQuery = {
        ...query,
        folders: [...toArray(query.folders), folderName],
      }
      updateQueryString(newQuery)
    },
    [query, updateQueryString]
  )

  const removeFolder = useCallback(
    (folderName: string) => {
      const newQuery = {
        ...query,
        folders: toArray(query.folders).filter(
          (folder) => folder !== folderName
        ),
      }
      updateQueryString(newQuery)
    },
    [query, updateQueryString]
  )

  const addFileFormat = useCallback(
    (fFormat: string) => {
      const newQuery = {
        ...query,
        fileFormat: fFormat,
      }
      updateQueryString(newQuery)
    },
    [query, updateQueryString]
  )

  const removeFileFormat = useCallback(() => {
    const newQuery = {
      ...query,
      fileFormat: undefined,
    }
    updateQueryString(newQuery)
  }, [query, updateQueryString])

  const addFilename = useCallback(
    (fname: string) => {
      const newQuery = {
        ...query,
        filename: fname,
      }
      updateQueryString(newQuery)
    },
    [query, updateQueryString]
  )

  const removeFilename = useCallback(() => {
    const newQuery = {
      ...query,
      filename: undefined,
    }
    updateQueryString(newQuery)
  }, [query, updateQueryString])

  const resetSearch = () => {
    const paramsToReset = {
      ...query,
      search: undefined,
      category: undefined,
      color: undefined,
      tags: undefined,
      face: undefined,
      folders: undefined,
      filename: undefined,
      fileFormat: undefined,
      searchSessionId: undefined,
    }
    updateQueryString(paramsToReset)
    updateFreeText('')
  }

  const updateFreeText = useCallback(
    (newFreeText: string) => {
      setFreeTextDebounced(newFreeText)
      setDisplayFreeText(newFreeText)
      if (newFreeText === '') {
        setSearchMode(SearchMode.DEFAULT)
      } else {
        setSearchMode(SearchMode.TAG)
      }
    },
    [setFreeTextDebounced]
  )

  const updateColor = useCallback(
    (newColor: string | null) => {
      const newQuery = { ...query, color: newColor || undefined }
      updateQueryString(newQuery)
    },
    [query, updateQueryString]
  )

  const updateCategory = useCallback(
    (newCategoryId: string | null) => {
      const newQuery = { ...query, category: newCategoryId || undefined }
      updateQueryString(newQuery)
      if (newCategoryId !== null) {
        setSearchMode(SearchMode.CATEGORY)
        return
      }
      setSearchMode(SearchMode.DEFAULT)
    },
    [query, updateQueryString]
  )

  const updateIsSearching = useCallback(
    (newIsSearching: boolean) => {
      const newQuery = {
        ...query,
        search: newIsSearching || undefined,
      }
      updateQueryString(newQuery)
    },
    [query, updateQueryString]
  )

  const setSearchSessionId = useCallback(
    (searchSessionId: string) => {
      const newQuery = {
        ...query,
        searchSessionId,
      }
      updateQueryString(newQuery)
    },
    [query, updateQueryString]
  )

  const addFaceTag = useCallback(
    (faceTag: string) => {
      const face_ = toArray(face)
      const isTagAlreadyAdded = face_.includes(faceTag)
      if (isTagAlreadyAdded) {
        return
      }
      const newQuery = {
        ...query,
        face: [...face_, faceTag],
      }
      updateQueryString(newQuery)
    },
    [face, query, updateQueryString]
  )

  const removeFaceTag = useCallback(
    (faceTag: string) => {
      const face_ = toArray(face)
      const newQuery = {
        ...query,
        face: face_.filter((tag) => tag !== faceTag),
      }
      updateQueryString(newQuery)
    },
    [face, query, updateQueryString]
  )

  return (
    <searchContext.Provider
      value={{
        freeText,
        displayFreeText,
        searchMode,
        tags: getTags(),
        color: color ?? null,
        category: category ?? null,
        isSearching: isSearching === 'true',
        face: toArray(face),
        isSearchPanelOpen,
        addTag,
        removeTag,
        setSearchMode,
        resetSearch,
        updateFreeText,
        updateColor,
        updateCategory,
        updateIsSearching,
        addFaceTag,
        removeFaceTag,
        setIsSearchPanelOpen,
        folders: toArray(folders),
        addFolder,
        removeFolder,
        addFilename,
        removeFilename,
        filename,
        addFileFormat,
        removeFileFormat,
        fileFormat,
        setSearchSessionId,
      }}
    >
      {children}
    </searchContext.Provider>
  )
}
