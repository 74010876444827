export const preloadImage = (src: string) => {
  return new Promise((resolve) => {
    const img = new Image()
    img.onload = () => {
      resolve(img)
    }
    img.src = src
    // @ts-ignore
    window[src] = img // taken from "If you have some images still reloading after being cached, try to store them in the window object"
  })
}
