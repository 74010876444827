import { ArrowBack } from '@styled-icons/material/ArrowBack'
import { useState } from 'react'
import styled, { css } from 'styled-components'

import previewImageSrc from 'assets/img/kive-mobile-preview.png'
import Loading from 'components/Loading'
import Button from 'components/common/Button'
import Flex from 'components/common/Flex'
import { Spacer } from 'components/common/Spacer'
import { Text } from 'components/common/Text'
import { useCreateUserLoginOtpMutation } from 'generated/graphql'
import { useMount } from 'hooks/useMount'

// OBS
// Continue on mobile app is disable for now

export const SetupMobileStartView = () => {
  const [view, setView] = useState<1 | 2>(1)

  const views = {
    1: <SetupMobileStartOptions onContinue={() => setView(2)} />,
    2: <SetupMobileOTPSignin onGoBack={() => setView(1)} />,
  }
  return views[view]
}

type SetupMobileOTPSigninProps = {
  onGoBack: () => void
}

const SetupMobileOTPSignin = (props: SetupMobileOTPSigninProps) => {
  const [createLoginOtpMutation, { data, error, loading }] =
    useCreateUserLoginOtpMutation()

  useMount(() => {
    createLoginOtpMutation()
  })

  const code = data?.createUserLoginOTP.code
  const word = data?.createUserLoginOTP.word

  const link = `${window.location.host}/activate/${word || '...'}`

  const renderCode = () => {
    if (loading) {
      return <Loading />
    }
    if (error) {
      return (
        <Flex flexDirection="column">
          <Spacer factor={2} />
          <Text size="sm" color="accent.0">
            We couldn&apos;t generate a login code for you.{' '}
          </Text>
          <Spacer factor={1} />
          <Button
            onClick={() => createLoginOtpMutation()}
            isCompact
            variant="primary"
          >
            Try again
          </Button>
        </Flex>
      )
    }
    return <StyledCode>{code}</StyledCode>
  }

  return (
    <Flex
      css="height: 100vh"
      pb={8}
      pt={4}
      px={4}
      flexDirection="column"
      alignItems="center"
    >
      <Button
        Icon={ArrowBack}
        onClick={props.onGoBack}
        css="padding: 8px 16px 8px 16px;border-radius: 16px; align-self: flex-start;"
      />
      <Spacer factor={4} />
      <Text bold color="neutral.0" nowrap size="xl">
        Continue on a computer
      </Text>
      <Spacer factor={4} />
      <StyledNumber>1</StyledNumber>
      <Spacer factor={1} />
      <Text color="neutral.2" size="sm">
        On your desktop or laptop browser go to:
      </Text>
      <Spacer factor={1} />
      <Text center bold color="accent.2" size="sm">
        {link}
      </Text>
      <Spacer factor={4} />
      <StyledNumber>2</StyledNumber>
      <Spacer factor={1} />
      <Text color="neutral.2" size="sm">
        Then enter the 6-digit passcode below
      </Text>
      <Flex>{renderCode()}</Flex>
      {code && (
        <>
          <Spacer factor={2} />
          <Flex>
            <Text color="neutral.3" size="xs">
              Didn&apos;t work?
            </Text>
            <Spacer axis="x" factor={0.5} />
            <Text
              onClick={() =>
                createLoginOtpMutation({
                  variables: {
                    word,
                  },
                })
              }
              css="text-decoration: underline"
              color="neutral.3"
              size="xs"
            >
              Generate a new code
            </Text>
          </Flex>
        </>
      )}
      {/* <Flex css="margin-top: auto">
        <ContinueOnMobileAppButton
          variant="darkSecondary"
          buttonText="Continue on the Kive app instead"
        />
      </Flex> */}
    </Flex>
  )
}

const StyledNumber = styled.div(
  ({ theme }) => css`
    background: ${theme.colors.background[5]};
    border-radius: ${theme.borderRadius.full};
    height: 40px;
    width: 40px;
    color: ${theme.colors.accent[2]};
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: ${theme.fontSizes.lg};
  `
)

const StyledCode = styled.div(
  ({ theme }) => css`
    border-radius: ${theme.borderRadius.default};
    border: ${theme.border.medium} ${theme.colors.accent[2]}50;
    padding: 8px;
    padding-left: 16px;
    height: 48px;
    color: ${theme.colors.accent[2]};
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: ${theme.fontSizes.xl};
    letter-spacing: 12px;
    margin-top: 8px;
  `
)

type SetupMobileStartOptionsProps = {
  onContinue: () => void
}

const SetupMobileStartOptions = (props: SetupMobileStartOptionsProps) => {
  return (
    <Flex py={4} flexDirection="column">
      <img
        alt="kive-mobile-preview"
        height="360px"
        css="align-self: end"
        src={previewImageSrc}
      />
      <Flex flexDirection="column" p={3} alignItems="center">
        <Text bold color="neutral.0" size="xl">
          Let&apos;s get you set up
        </Text>
        <Spacer factor={1} />
        {/* <Text color="neutral.2" size="sm">
          Download the app or start on the web
        </Text> */}
        <Spacer factor={2} />
        {/* <ContinueOnMobileAppButton
          variant="primary"
          buttonText="Continue on the Kive app"
        /> */}
        {/* <Spacer factor={1} /> */}
        <Button variant="primary" onClick={props.onContinue} fullWidth>
          Continue
        </Button>
      </Flex>
    </Flex>
  )
}

// const ContinueOnMobileAppButton = (props: {
//   buttonText: string
//   variant: React.ComponentProps<typeof Button>['variant']
// }) => {
//   return (
//     <Button
//       href="https://apps.apple.com/us/app/kive/id1624028406"
//       fullWidth
//       as="a"
//       variant={props.variant}
//     >
//       {props.buttonText}
//     </Button>
//   )
// }
