/* eslint-disable no-nested-ternary */
import { ApolloQueryResult } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

import { trackUpgradePanelOpened } from 'helpers/tracking/tracking'

import {
  BillingSubscriptionQuery,
  StripeCoupon,
  useAddDiscountCouponToWorkspaceMutation,
  useCreateBillingPortalSessionMutation,
  useCreateCancellationSurveyMutation,
} from '../../../generated/graphql'
import { reportError as reportSentryError } from '../../../helpers/logging'
import { DBPlan, DBWorkspace } from '../../../types/db'
import { CurrentPlan } from '../CurrentPlan'
import { Heading, SettingsContent } from '../SettingsStyles'
import { SubscriptionInterval } from '../types'
import { StyledBanner, StyledPlansHeading } from './ComparePlansStyles'
import { DowngradeModalChrome } from './DowngradeModalChrome'
import { DowngradeViewFirst } from './DowngradeViewFirst'
import { DowngradeViewSecond } from './DowngradeViewSecond'
import { PlansGrid } from './PlansGrid'
import { PlansGridNoSubscription } from './PlansGridNoSubscription'
import { SubscriptionIntervalToggle } from './SubscriptionIntervalToggle'

type PlansProps = {
  activePlan: DBPlan & { cancelsAt?: number; id: string }
  activeWorkspace: DBWorkspace & { id: string }
  standalone: boolean
  subscription: BillingSubscriptionQuery
  refetchBillingSubscription: (variables?: {
    workspaceId: string
  }) => Promise<ApolloQueryResult<BillingSubscriptionQuery>>
  currentCredit: number
}

/**
 * Since we treat firestore as a source of truth for plans and not
 * stripe, this function transforms prices.
 * Iit returns  a function which then could be passed to the plans
 * grid
 *
 * (ノಠ益ಠ)ノ彡┻━┻
 * @param discount
 */
const getDiscountedPriceTransformer = (discount: number | undefined) => {
  const noop = (_: string) => _
  if (!discount) {
    return noop
  }
  return (dBPlanPrice: string): string => {
    const [, dbPlanPrice] = dBPlanPrice.split('$')
    if (!dbPlanPrice) {
      // Free
      return dBPlanPrice
    }
    const dbPlanPriceInNumber = Number(dbPlanPrice)
    const newPrice = dbPlanPriceInNumber * (1 - discount / 100)
    if (newPrice === 0) {
      return 'Free'
    }
    return `$${newPrice.toFixed(2)}`
  }
}

export const ComparePlans = (props: PlansProps) => {
  const {
    activePlan,
    activeWorkspace,
    standalone,
    subscription,
    refetchBillingSubscription,
    currentCredit,
  } = props
  const navigate = useNavigate()
  const [subscriptionInterval, setSubscriptionInterval] =
    useState<SubscriptionInterval>('yearly')
  const [isFirstDowngradeView, setIsFirstDowngradeView] = useState(true)
  const [downgradingPlan, setDowngradingPlan] = useState<{
    planId: string
    subscriptionId: string
    priceId: string
  } | null>(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_upgradePanelSessionId, setUpgradePanelSessionId] = useState<
    string | null
  >(null)

  const [createCancellationSurvey] = useCreateCancellationSurveyMutation()
  const [addCouponToWorkspace] = useAddDiscountCouponToWorkspaceMutation()
  const [
    createBillingPortalSessionMutation,
    { loading: isCreatingBillingPortalSession, error: billingPortalError },
  ] = useCreateBillingPortalSessionMutation()

  const createBillingPortalSessionAndRedirect = async ({
    subscriptionId,
    priceId,
  }: {
    subscriptionId: string
    priceId: string
  }) => {
    if (isCreatingBillingPortalSession || billingPortalError) return
    if (isCreatingBillingPortalSession || billingPortalError) return
    try {
      const returnUrl = window.location.href
      console.log(
        `Creating billing portal session with the following payload`,
        {
          variables: {
            workspaceId: props.activeWorkspace.id,
            returnUrl,
          },
        }
      )
      const { data } = await createBillingPortalSessionMutation({
        variables: {
          workspaceId: props.activeWorkspace.id,
          returnUrl,
        },
      })
      const url = data?.createBillingPortalSession?.url
      if (!url) {
        return
      }
      window.location.assign(
        `${url}/subscriptions/${subscriptionId}/preview/${priceId}`
      )
    } catch (error) {
      reportSentryError(error)
    }
  }

  // If coming back from redirect with query, continue loading until bought plan is activated.
  useEffect(() => {
    const currentUrl = new URL(window.location.href)
    const goingToPlan = currentUrl.searchParams.get('toPlan')
    if (goingToPlan === activePlan?.id) {
      // We loaded the correct plan, remove query
      currentUrl.searchParams.delete('toPlan')
      navigate(`${currentUrl.pathname}${currentUrl.search}`, { replace: true })
    }
  }, [activePlan?.id, navigate, activePlan?.cancelsAt])

  useEffect(() => {
    const currentUrl = new URL(window.location.href)

    // Check to make sure we dont trigger event when coming back from stripe checkout session
    const goingToPlan = currentUrl.searchParams.get('toPlan')
    if (goingToPlan === null) {
      const source = currentUrl.searchParams.get('upgradePanel')
      const upgradePanelSessionId_ = uuid()
      setUpgradePanelSessionId(upgradePanelSessionId_)
      trackUpgradePanelOpened({
        source,
        upgradePanelSessionId: upgradePanelSessionId_,
        section_: null,
      })
    }
  }, [])

  const activeDiscountCoupon =
    subscription?.billingSubscription?.activeDiscount?.coupon

  // If coming back from redirect with query, continue loading until bought plan is activated.
  useEffect(() => {
    const currentUrl = new URL(window.location.href)
    const goingToPlan = currentUrl.searchParams.get('toPlan')
    // if no query clear any loading plans
    if (goingToPlan === activePlan?.id) {
      // We loaded the correct plan, remove query
      currentUrl.searchParams.delete('toPlan')
      navigate(`${currentUrl.pathname}${currentUrl.search}`, { replace: true })
    }
  }, [activePlan?.id, navigate, activePlan?.cancelsAt])

  useEffect(() => {
    const currentUrl = new URL(window.location.href)

    // Check to make sure we dont trigger event when coming back from stripe checkout session
    const goingToPlan = currentUrl.searchParams.get('toPlan')
    if (goingToPlan === null) {
      const source = currentUrl.searchParams.get('upgradePanel')
      const upgradePanelSessionId_ = uuid()
      trackUpgradePanelOpened({
        source,
        upgradePanelSessionId: upgradePanelSessionId_,
        section_: null,
      })
    }
  }, [])

  const handlePlanUpdate = async () =>
    createBillingPortalSessionAndRedirect({
      priceId: `${downgradingPlan?.priceId}`,
      subscriptionId: `${downgradingPlan?.subscriptionId}`,
    })

  const handleDowngradeClose = () => {
    setDowngradingPlan(null)
    setIsFirstDowngradeView(true)
  }

  type HandleDowngradeSubmissionParameters = {
    additionalInfo: string
    pickedReasons: string[]
    canBeContacted: boolean
  }

  const handleDowngradeSubmission = async (
    formData: HandleDowngradeSubmissionParameters
  ) => {
    const { pickedReasons, additionalInfo, canBeContacted } = formData
    await createCancellationSurvey({
      variables: {
        reasons: pickedReasons,
        message: additionalInfo,
        canBeContacted,
      },
    })
  }

  const applyCoupon = async (coupon: StripeCoupon) => {
    try {
      await addCouponToWorkspace({
        variables: {
          couponId: coupon.id,
          workspaceId: activeWorkspace.id,
        },
      })
    } catch (error) {
      // handle error
    }
    await refetchBillingSubscription()
  }

  const handleChurningOfferClick = (churningCoupon: StripeCoupon) => {
    handleDowngradeClose()
    if (churningCoupon) {
      applyCoupon(churningCoupon)
    }
  }

  const handleDowngradeClick = ({
    planId,
    subscriptionId,
    priceId,
  }: {
    planId: string
    subscriptionId: string
    priceId: string
  }) => {
    setDowngradingPlan({ planId, subscriptionId, priceId })
  }

  return (
    <SettingsContent
      noPadding={standalone}
      style={{ paddingLeft: standalone && 16 }}
    >
      {activeDiscountCoupon && (
        <StyledBanner>
          Discount: {activeDiscountCoupon?.name} applied.
        </StyledBanner>
      )}
      <CurrentPlan
        workspaceId={activeWorkspace.id}
        currentCredit={currentCredit}
      />
      <StyledPlansHeading>
        <Heading>
          {standalone
            ? ''
            : subscription?.billingSubscription
            ? 'Compare plans'
            : 'Choose a plan'}
        </Heading>
        <SubscriptionIntervalToggle
          subscriptionInterval={subscriptionInterval}
          setSubscriptionInterval={setSubscriptionInterval}
        />
      </StyledPlansHeading>
      {!subscription || !subscription.billingSubscription ? (
        <PlansGridNoSubscription
          subscriptionInterval={subscriptionInterval}
          setSubscriptionInterval={setSubscriptionInterval}
          workspaceId={activeWorkspace.id}
        />
      ) : null}
      {subscription?.billingSubscription ? (
        <PlansGrid
          transformPlanPrice={getDiscountedPriceTransformer(
            activeDiscountCoupon?.percentOff || 0
          )}
          subscriptionInterval={subscriptionInterval}
          subscription={subscription}
          workspaceId={activeWorkspace.id}
          setSubscriptionInterval={setSubscriptionInterval}
          handleDowngradeClick={handleDowngradeClick}
        />
      ) : null}
      <DowngradeModalChrome
        isOpen={!!downgradingPlan}
        onCloseClick={handleDowngradeClose}
      >
        {isFirstDowngradeView ? (
          <DowngradeViewFirst
            onCloseClick={handleDowngradeClose}
            onConfirm={() => setIsFirstDowngradeView(false)}
            activePlan={activePlan}
          />
        ) : (
          <DowngradeViewSecond
            activeCouponId={activeDiscountCoupon?.id}
            onChurningOfferClick={handleChurningOfferClick}
            onCloseClick={handleDowngradeClose}
            onConfirm={handlePlanUpdate}
            downgradingPlan={downgradingPlan}
            handleDowngradeSubmission={handleDowngradeSubmission}
          />
        )}
      </DowngradeModalChrome>
    </SettingsContent>
  )
}
