import React, { useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { useTheme } from 'styled-components'

import Button from 'components/common/Button'
import { Margin } from 'components/common/Margin'

import { Border } from '../library/Common'
import { Box, Heading, HeadingLarge } from './DownloadsStyles'

function DownloadItem({ downloadRequest, onAction, onCancel }) {
  const [mouseOver, setMouseOver] = useState(false)
  const theme = useTheme()
  const isLightTheme = useSelector(
    (state) => state.content.theme === 'lightTheme'
  )

  const { processed = 0, total = 0 } = downloadRequest.progress
    ? downloadRequest.progress.entries
    : {}

  return (
    <Border shouldAnimate={!downloadRequest.zipItem}>
      <div
        onMouseEnter={() => setMouseOver(true)}
        onMouseLeave={() => setMouseOver(false)}
        style={{
          width: 140,
          height: 140,
          position: 'relative',
          borderRadius: 18,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          background: isLightTheme
            ? theme.colors.gray.light[9]
            : theme.colors.background[1],
        }}
      >
        {mouseOver ? (
          <div
            style={{
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              display: 'flex',
            }}
          >
            {downloadRequest.zipItem && (
              <>
                <Button
                  variant="primary"
                  isCompact
                  onClick={() => onAction(downloadRequest)}
                >
                  Download
                </Button>
                <Margin y={12} />
              </>
            )}
            <Button isCompact onClick={() => onCancel(downloadRequest)}>
              Dismiss
            </Button>
          </div>
        ) : (
          downloadRequest.zipItem && (
            <>
              <Heading
                style={{
                  color: theme.colors.accent[2],
                  fontWeight: theme.fontWeight.semibold,
                }}
              >
                {downloadRequest.title}
              </Heading>
            </>
          )
        )}

        {!downloadRequest.zipItem ? (
          <Heading>
            {processed > 0
              ? `Collecting ${processed}/${total}`
              : 'Preparing ...'}
          </Heading>
        ) : null}
      </div>
    </Border>
  )
}

function Mini({ readyDownloadRequests, onAction, onCancel }) {
  const theme = useTheme()

  const unseenDownloadRequests = readyDownloadRequests.filter((r) => !r.seen)
  const hasDownloadsProcessing = unseenDownloadRequests.some((r) => !r.zipItem)

  return (
    <Box>
      {unseenDownloadRequests.map((downloadRequest) => (
        <DownloadItem
          onCancel={onCancel}
          onAction={onAction}
          downloadRequest={downloadRequest}
          key={downloadRequest.id}
        />
      ))}

      <div
        style={{
          flex: '1 1 0',
          flexDirection: 'column',
          fontWeight: theme.fontWeight.semibold,
          textAlign: 'center',
        }}
      >
        {hasDownloadsProcessing ? (
          <>
            <HeadingLarge>
              Collecting download
              {unseenDownloadRequests.length > 1 ? 's' : ''} ...
            </HeadingLarge>
            <Heading>
              Keep doing your thing. We&apos;ll nudge you when it&apos;s ready.
            </Heading>
          </>
        ) : (
          <HeadingLarge>
            Download
            {unseenDownloadRequests.length > 1 ? 's' : ''} ready!
          </HeadingLarge>
        )}
      </div>
    </Box>
  )
}

function All({ downloadRequests }) {
  return null
}

function Downloads(props) {
  function _handleUserStartedDownload(request) {
    window.open(request.zipItem.url, '_blank')
    const downloadRequestUpdate = {
      seen: true,
      seenAt: props.firebase.firestore.FieldValue.serverTimestamp(),
      userHasStartedDownload: true,
      userStartedDownloadAt:
        props.firebase.firestore.FieldValue.serverTimestamp(),
    }
    props.firestore.update(
      { collection: 'downloadRequests', doc: request.id },
      downloadRequestUpdate
    )
  }

  function _handleCancel(request) {
    const downloadRequestUpdate = {
      seen: true,
      seenAt: props.firebase.firestore.FieldValue.serverTimestamp(),
    }
    props.firestore.update(
      { collection: 'downloadRequests', doc: request.id },
      downloadRequestUpdate
    )
  }

  const unseenDownloadRequests = props.readyDownloadRequests.filter(
    (r) => !r.seen
  )

  return (
    <>
      {unseenDownloadRequests && unseenDownloadRequests.length > 0 ? (
        <Mini
          onAction={_handleUserStartedDownload}
          onCancel={_handleCancel}
          readyDownloadRequests={props.readyDownloadRequests}
        />
      ) : (
        <All downloadRequests={props.readyDownloadRequests} />
      )}
    </>
  )
}

const yesterday = ((d) => new Date(d.setDate(d.getDate() - 1)))(new Date())

const mapStateToProps = (state, ownProps) => {
  return {
    uid: state.firebase.auth.uid,
    readyDownloadRequests: state.firestore.ordered.downloadRequests
      ? state.firestore.ordered.downloadRequests
      : [],
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    const guestId = localStorage.getItem('GUEST_UID')
    const parsedGuestId = guestId ? JSON.parse(guestId) : guestId
    const createdBy = props.uid || parsedGuestId
    return [
      {
        collection: 'downloadRequests',
        where: [
          ['createdBy', '==', createdBy],
          ['userHasStartedDownload', '==', false],
          ['createdAt', '>', yesterday],
        ],
      },
    ]
  })
)(Downloads)
