import { Loader5 } from '@styled-icons/remix-fill/Loader5'
import { motion } from 'framer-motion'
import { FC } from 'react'
import styled, { css, keyframes } from 'styled-components'

const spring = {
  type: 'spring',
  stiffness: 500,
  damping: 30,
}

type LoadingSwitchToggleProps = {
  isOn: boolean
  onToggle: () => void
  isDisabled?: boolean
  isLoading?: boolean
}

export const LoadingSwitchToggle = ({
  isOn,
  onToggle,
  isDisabled,
  isLoading,
}: LoadingSwitchToggleProps) => {
  const onClick = !isDisabled ? onToggle : undefined
  return (
    <StyledSwitch isOn={isOn} onClick={onClick} isDisabled={isDisabled}>
      <StyledMotionHandle
        isOn={isOn}
        layout
        transition={spring}
        isDisabled={isDisabled}
      >
        {isLoading ? <LoaderIcon /> : null}
      </StyledMotionHandle>
    </StyledSwitch>
  )
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const StyledRotate = styled.div`
  animation: ${rotate} 0.5s ease infinite;
`

const Spin: FC<{ children: React.ReactElement }> = ({ children }) => (
  <StyledRotate>{children}</StyledRotate>
)

const LoaderIcon = () => (
  <Spin>
    <Loader5 />
  </Spin>
)

const StyledSwitch = styled.div<{ isOn: boolean; isDisabled?: boolean }>`
  ${({ theme, isOn, isDisabled }) => css`
    width: 48px;
    height: 30px;
    background-color: ${isOn && !isDisabled
      ? theme.colors.accent[2]
      : theme.colors.gray.medium[1]};
    color: ${isOn && !isDisabled
      ? theme.colors.white
      : theme.colors.gray.light[1]};
    display: flex;
    justify-content: flex-start;
    border-radius: 50px;
    padding: 3px;
    cursor: pointer;
    ${isOn &&
    css`
      justify-content: flex-end;
    `}
  `}
`

const StyledMotionHandle = styled(motion.div)<{
  isOn: boolean
  isDisabled?: boolean
}>`
  ${({ theme, isOn, isDisabled }) => css`
    width: 24px;
    height: 24px;
    background-color: ${isOn && !isDisabled
      ? theme.colors.white
      : theme.colors.gray.medium[6]};
    border-radius: 40px;
  `}
`
