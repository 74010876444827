import React from 'react'
import { useSelector } from 'react-redux'
import {
  ReduxFirestoreQuerySetting,
  isEmpty,
  useFirestore,
  useFirestoreConnect,
} from 'react-redux-firebase'

import Loading from 'components/Loading'
import Grid from 'components/grid/Grid'
import { GridColumnAdditioner } from 'components/grid/GridColumnAdditioner'
import { useSelectAllCurrentBoardItems } from 'components/multiselect/useSelectAllCurrentBoardItems'
import { reportError } from 'helpers/logging'
import { trackBoardItemRearranged } from 'helpers/tracking/tracking'
import useKeypress from 'hooks/useKeyPress'
import { RootState, WithId } from 'store'
import {
  boardItemsIsLoadingSelector,
  boardItemsSelector,
} from 'store/selectors'
import { ItemRefWithId } from 'types/custom'
import { DBBoard, DBItemRef } from 'types/db'

import CustomDragLayer from './CustomDragLayer'

const moveElement = (array: any[], from: number, to: number) => {
  const copy = [...array]
  const valueToMove = copy.splice(from, 1)[0]
  copy.splice(to, 0, valueToMove)
  return copy
}

const getBoardItemsQuery = (
  boardItemsReactionFilters: BoardItemsFilters['reactions'],
  boardId: string
): ReduxFirestoreQuerySetting[] => {
  if (boardItemsReactionFilters.length > 0) {
    return boardItemsReactionFilters.map((reaction) => {
      return {
        collection: 'itemRefs',
        where: [
          ['boardId', '==', boardId],
          [`reactionCounters.${reaction}`, '>', 0],
        ],
        storeAs: `boardItems_${boardId}_${reaction}`,
      }
    })
  }
  return [
    {
      collection: 'itemRefs',
      where: [['boardId', '==', boardId]],
      storeAs: `boardItems_${boardId}`,
    },
  ]
}

interface BoardItemsFilters {
  reactions: string[]
}
interface Props {
  scrollNode: any
  boardId: string
  hasCustomItemsPositioning?: boolean
  isPublic?: boolean
  board: DBBoard
  boardItemsFilters: BoardItemsFilters
}

const BoardViewGrid: React.FC<Props> = ({
  scrollNode,
  boardId,
  hasCustomItemsPositioning,
  isPublic,
  board,
  boardItemsFilters,
}) => {
  const firestore = useFirestore()

  const allowDownloads = board.presentationSettings?.allowDownloads ?? true
  // if board is public and "allow downloads"-setting is turned off we disable multi select
  const isMultiSelectDisabled = isPublic && !allowDownloads

  const handleSelectAllCurrentBoardItems =
    useSelectAllCurrentBoardItems(boardId)

  useKeypress(['cmd:a'], handleSelectAllCurrentBoardItems, {
    preventDefault: true,
    disabled: isMultiSelectDisabled,
  })

  useFirestoreConnect(getBoardItemsQuery(boardItemsFilters.reactions, boardId))

  const boardItems: WithId<DBItemRef>[] = useSelector((state: RootState) =>
    boardItemsSelector(state, boardId)
  )
  const boardItemsIsLoading: boolean = useSelector((state: RootState) =>
    boardItemsIsLoadingSelector(state, boardId)
  )
  const empty = isEmpty(boardItems)
  const hasNoBoardItems = empty && !boardItemsIsLoading

  // OBS: This only works as we are fetching all board items in frontend.
  const sortedItems = hasCustomItemsPositioning
    ? boardItems.sort((a, b) => {
        const positionA = a.position
        const positionB = b.position

        if (positionA === undefined) return 1 // we make sure undefineds is put last in the array
        if (positionB === undefined) return -1 // we make sure undefineds is put last in the array

        if (positionA < positionB) {
          return -1
        }
        if (positionA > positionB) {
          return 1
        }
        return 0
      })
    : boardItems

  const initBoardAndItemsForCustomPositioning = (
    fromPosition: number,
    toPosition: number
  ) => {
    console.log('[BoardViewGrid] Initializing board items custom positions', {
      fromPosition,
      toPosition,
    })
    const batch = firestore.batch()
    const newBoardItems = moveElement(sortedItems!, fromPosition, toPosition)
    newBoardItems.forEach((boardItem, index) => {
      const boardItemDoc = firestore.collection('itemRefs').doc(boardItem.id)
      batch.update(boardItemDoc, { position: index })
    })
    const boardDoc = firestore.collection('boards').doc(boardId)
    batch.update(boardDoc, { hasCustomItemsPositioning: true })
    return batch.commit()
  }

  const reorderItems = async ({
    toPosition,
    fromPosition,
    item,
  }: {
    toPosition: number
    fromPosition: number
    item: ItemRefWithId
  }) => {
    console.log('fire', { toPosition, fromPosition, item })
    if (!hasCustomItemsPositioning) {
      // if this is the first time a board item was moved we initialize to board and board items for custom positioning
      return initBoardAndItemsForCustomPositioning(fromPosition, toPosition)
    }
    const movedUp = toPosition < fromPosition
    const movedDown = toPosition > fromPosition

    try {
      const batch = firestore.batch()
      const movedDoc = firestore.collection('itemRefs').doc(item.id)
      if (movedUp) {
        console.log('[BoardViewGrid] Board item was move up', {
          fromPosition,
          toPosition,
        })
        const itemsToIncrement = sortedItems!.slice(toPosition, fromPosition)
        batch.update(movedDoc, { position: toPosition })
        itemsToIncrement.forEach((itemToIncrement) => {
          const itemToIncrementDoc = firestore
            .collection('itemRefs')
            .doc(itemToIncrement.id)
          batch.update(itemToIncrementDoc, {
            position: firestore.FieldValue.increment(1),
          })
        })
        await batch.commit()
      }
      if (movedDown) {
        console.log('[BoardViewGrid] Board item was moved down', {
          fromPosition,
          toPosition,
        })
        const itemsToDecrement = sortedItems!.slice(
          fromPosition + 1,
          toPosition
        )
        batch.update(movedDoc, { position: toPosition - 1 })
        itemsToDecrement.forEach((itemToDecrement) => {
          const itemToDecrementDoc = firestore
            .collection('itemRefs')
            .doc(itemToDecrement.id)
          batch.update(itemToDecrementDoc, {
            position: firestore.FieldValue.increment(-1),
          })
        })
        trackBoardItemRearranged({ itemId: item.id, boardId: item.boardId })

        await batch.commit()
      }
    } catch (error) {
      reportError(
        new Error(
          `Something happend when reordering grid items in board. itemRefId: ${item.id}. Error message: ${error.message}`
        )
      )
    }
  }

  if (boardItemsIsLoading) {
    return <Loading />
  }
  if (hasNoBoardItems) {
    return null // TODO: Add empty message
  }
  return (
    <GridColumnAdditioner parentWrapper={scrollNode}>
      {(gridColumnAdditionOption) => (
        <>
          <Grid
            id="boardSearchItems"
            scrollElement={scrollNode}
            gridItems={sortedItems}
            gridColumnAdditionOption={gridColumnAdditionOption}
            boardId={boardId}
            reorderItems={reorderItems}
            disableGridItemOverlay={isPublic}
            isMultiSelectDisabled={isMultiSelectDisabled}
            inFilterMode={boardItemsFilters.reactions.length > 0}
          />
          <CustomDragLayer />
        </>
      )}
    </GridColumnAdditioner>
  )
}

export default BoardViewGrid
