import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'

import { RootState, WithId } from 'store'
import { DBBoard } from 'types/db'

type UseGetLastUpdatedWorkspaceBoardArgs = {
  accessLevel: 'writer' | 'reader'
}

// OBS: This is used as a proxy for "users last saved to boards" - can we improve the query?
export const useGetLastUpdatedWorkspaceBoards = ({
  accessLevel,
}: UseGetLastUpdatedWorkspaceBoardArgs): WithId<DBBoard>[] => {
  const currentUserId = useSelector(
    (state: RootState) => state.firebase.auth.uid
  )

  const activeWorkspaceId = useSelector(
    (state: RootState) => state.content.activeWorkspace?.id
  )

  const lastUpdatedWorkspaceBoards: WithId<DBBoard>[] = useSelector(
    (state: RootState) =>
      state.firestore.ordered[
        `lastUpdatedWorkspaceBoards_${activeWorkspaceId}`
      ] || []
  )

  const lastUpdatedBoards = lastUpdatedWorkspaceBoards.filter((board) => {
    if (accessLevel === 'reader') {
      return board.aggregatedReaderAccess?.[currentUserId]
    }
    if (accessLevel === 'writer') {
      return board.aggregatedWriterAccess?.[currentUserId]
    }
    return false
  })

  const canFetch = activeWorkspaceId && currentUserId

  useFirestoreConnect(
    canFetch
      ? [
          {
            collection: 'boards',
            where: [['workspaceId', '==', activeWorkspaceId]],
            orderBy: ['updatedAt', 'desc'],
            limit: 6,
            storeAs: `lastUpdatedWorkspaceBoards_${activeWorkspaceId}`,
          },
        ]
      : []
  )

  return lastUpdatedBoards
}
