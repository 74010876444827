import { client } from 'apollo'
import { stringify } from 'query-string'
import React from 'react'
import { useSelector } from 'react-redux'
import { useFirestore } from 'react-redux-firebase'
import { useLocation, useNavigate } from 'react-router-dom'
import styled, { css } from 'styled-components'

import Button from 'components/common/Button'
import Modal from 'components/common/Modal'
import useToastMessages from 'components/toast/useToastMessages'
import { deleteBoardItem } from 'helpers/boardItemOperations'
import useQueryString from 'hooks/useQueryString'
import { RootState } from 'store'

import { ItemData } from './hooks/useGetItemData'

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const ButtonWrapper = styled.div`
  margin: 0 8px;
`

const ModalHeading = styled.h1`
  ${({ theme }) => css`
    font-weight: ${theme.fontWeight.bold};
    margin-bottom: 12px;
    font-size: ${theme.fontSizes.lg};
    color: ${theme.colors.text.neutral[1]};
  `}
`

const ModalText = styled.p`
  color: ${({ theme }) => theme.colors.text.neutral[1]};
  margin-bottom: 16px;
`

interface ItemDeleteModalProps {
  isOpen: boolean
  close: () => void
  item: ItemData
}

const ItemDeleteModal: React.FC<ItemDeleteModalProps> = ({
  isOpen,
  close,
  item,
}) => {
  const firestore = useFirestore()
  const navigate = useNavigate()
  const location = useLocation()
  const query = useQueryString<{ item: string }>()
  const { reportError } = useToastMessages()

  const isBoardItem = 'boardId' in item

  const activeWorkspaceId = useSelector(
    (state: RootState) => state.content.activeWorkspace?.id
  )

  const updatedQueryString = stringify(
    { ...query, open: undefined, item: undefined, itemSpace: undefined },
    { arrayFormat: 'comma' }
  )
  const backPath = `${location.pathname}?${updatedQueryString}`

  const handleDeleteBoardItem = async () => {
    if (!('boardId' in item)) return
    try {
      await deleteBoardItem(item.id, item.boardId)
      navigate(backPath)
    } catch (error) {
      close()
      reportError('We could not remove this board item, try again later', error)
    }
  }

  const handleDeleteItem = async () => {
    // Double check ok to test before updating items
    await firestore.update(
      {
        collection: `workspaces/${activeWorkspaceId}/items`,
        doc: item.id,
      },
      { shouldDelete: true, shouldDeleteTags: true }
    )

    client.cache.evict({ id: `WorkspaceItem:${item.id}` })
    client.cache.gc()

    navigate(backPath)
  }

  return (
    <>
      <Modal width="450px" isOpen={isOpen} close={close}>
        <Modal.ContentWrapper>
          <ModalHeading>
            {isBoardItem
              ? 'Are you sure you want to remove this item from the board?'
              : 'Are you sure you want to delete this item?'}
          </ModalHeading>
          <ModalText>You can&apos;t undo this</ModalText>
          <ButtonsWrapper>
            <ButtonWrapper>
              <Button
                bold
                variant="primary"
                onClick={isBoardItem ? handleDeleteBoardItem : handleDeleteItem}
              >
                Delete
              </Button>
            </ButtonWrapper>
            <ButtonWrapper>
              <Button bold onClick={close}>
                Close
              </Button>
            </ButtonWrapper>
          </ButtonsWrapper>
        </Modal.ContentWrapper>
      </Modal>
    </>
  )
}

export default ItemDeleteModal
