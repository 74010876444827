import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { BooleanParam, StringParam, useQueryParams } from 'use-query-params'

import Flex from 'components/common/Flex'
import { Spacer } from 'components/common/Spacer'
import { Text } from 'components/common/Text'
import ItemView from 'components/item/ItemView'
import PublicItemView from 'components/item/PublicItemView'
import SearchCreation from 'components/search/SearchCreation'
import { useWorkspaceItemCountQuery } from 'generated/graphql'
import { trackHomePageViewed } from 'helpers/tracking/tracking'
import { useActiveWorkspace } from 'hooks/useActiveWorkspace'
import useCurrentUser from 'hooks/useCurrentUser'
import useIsMobile from 'hooks/useIsMobile'
import { useMount } from 'hooks/useMount'
import { RootState } from 'store'

import { DiscoverItemsForYouRow } from './discover-items-for-you-row/DiscoverItemsForYouRow'
import { GuidesRow } from './guides-row/GuidesRow'
import { getGreetingText } from './helpers/getGreetingText'
import { MagicBoardsRow } from './magic-boards-row/MagicBoardsRow'
import { RecentBoardsRow } from './recent-boards-row/RecentBoardsRow'
import { RecentUploadEventsRow } from './recent-upload-events-row/RecentUploadEventsRow'
import { SearchSuggestionsRow } from './search-suggestions-row/SearchSuggestionsRow'
import { SetupTasksRow } from './setup-tasks-row/SetupTasksRow'

export const Home = () => {
  const currentUser = useCurrentUser()
  const isMobile = useIsMobile()
  const activeWorkspace = useActiveWorkspace()!

  const [{ item: itemId, isDiscoverItem }] = useQueryParams({
    item: StringParam,
    isDiscoverItem: BooleanParam,
  })

  const siderSplitValue = useSelector(
    (state: RootState) => state.ui.siderSplitValue
  )

  const { data } = useWorkspaceItemCountQuery({
    variables: { workspaceId: activeWorkspace.id },
    fetchPolicy: 'cache-and-network',
  })
  const workspaceTotalItemsCount = data?.workspace.totalItemsCount ?? 0

  useMount(trackHomePageViewed)

  return (
    <>
      <SearchCreation
        placeholder={
          isMobile
            ? 'Search workspace items'
            : `Type to search ${workspaceTotalItemsCount} ${activeWorkspace.title} items`
        }
        shouldDisplayUploadButton
        shouldDisplayNotifications
      />
      <StyledContainer siderSplitValue={siderSplitValue + 1}>
        <StyledFlex
          flexDirection="column"
          css={isMobile ? 'padding-left:12px' : 'padding-left:24px'}
          py={isMobile ? 2 : 4}
        >
          <Text bold color="neutral.0" size="xl">
            {getGreetingText()}, {currentUser.firstName}
          </Text>
          <Spacer factor={5} />
          <SetupTasksRow />
          <GuidesRow />
          {workspaceTotalItemsCount > 0 && (
            <RecentBoardsRow workspaceId={activeWorkspace.id} />
          )}
          <DiscoverItemsForYouRow />
          {workspaceTotalItemsCount > 0 && (
            <MagicBoardsRow
              workspaceId={activeWorkspace.id}
              workspaceTitle={activeWorkspace.title}
            />
          )}
          {workspaceTotalItemsCount > 0 && (
            <RecentUploadEventsRow workspaceId={activeWorkspace.id} />
          )}
          <SearchSuggestionsRow />
        </StyledFlex>
      </StyledContainer>
      {itemId &&
        (isDiscoverItem ? (
          <PublicItemView isCloseable inDiscover />
        ) : (
          <ItemView />
        ))}
    </>
  )
}

const StyledContainer = styled.div<{ siderSplitValue: number }>(
  ({ siderSplitValue }) => css`
    overflow: scroll;
    overflow-x: hidden;
    max-height: calc(100vh - 60px);
    min-height: calc(100vh - 60px);
    width: calc(100vw - ${siderSplitValue}px);
  `
)

const StyledFlex = styled(Flex)`
  will-change: transform; // Added to mimic Grid component so that unvisible overlay when save-to-board is displayed is fixed relative to this element
`
