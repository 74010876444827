import { Link, useLocation } from 'react-router-dom'

import { Spacer } from 'components/common/Spacer'
import { HighlightRow } from 'components/highlight-row/HighlightRow'
import { useDiscoverTopContributorsQuery } from 'generated/graphql'
import { trackDiscoveryTopContributorClicked } from 'helpers/tracking/tracking'

import { TopContributorCard } from '../components/TopContributorCard'

export const TopContributorsRow = ({
  trackingSessionId,
}: {
  trackingSessionId: string
}) => {
  const location = useLocation()
  const { data, loading } = useDiscoverTopContributorsQuery({
    variables: { first: 10 },
    fetchPolicy: 'cache-and-network',
    context: {
      batch: true,
    },
  })

  const users = data?.discoverTopContributors || []

  return (
    <>
      <HighlightRow
        title="Top contributors"
        listItems={users}
        isLoading={loading && !users.length}
        renderListItem={(user) => {
          return (
            <Link
              to={{
                pathname: `/u/${user.handle}`,
              }}
              state={{ prevPathname: location.pathname }}
              onClick={() =>
                trackDiscoveryTopContributorClicked({ trackingSessionId })
              }
            >
              <TopContributorCard user={user} />
            </Link>
          )
        }}
      />
      <Spacer factor={5} />
    </>
  )
}
