import React from 'react'
import { useSelector } from 'react-redux'
import {
  ReduxFirestoreQuerySetting,
  useFirestoreConnect,
} from 'react-redux-firebase'

import { RootState, WithId } from 'store'
import { DBBoard } from 'types/db'

import SelectCommonListItem from './SelectCommonListItem'

const getQueries = (board: WithId<DBBoard>): ReduxFirestoreQuerySetting[] => {
  if (!board.projectId) return []
  return [
    {
      collection: 'projects',
      doc: board.projectId,
      storeAs: `saveToBoardMenuItemProject_${board.projectId}`,
    },
  ]
}

interface SelectBoardListItemProps {
  board: WithId<DBBoard>
  onAction: ({ id, title }: { id: string; title: string }) => void
}

const SelectBoardListItem: React.FC<SelectBoardListItemProps> = ({
  board,
  onAction,
}) => {
  const queries = getQueries(board)
  useFirestoreConnect(queries)

  const id = board.id
  const title = board.title
  const subtitle: string | undefined = useSelector(
    (state: RootState) =>
      state.firestore.data[`saveToBoardMenuItemProject_${board.projectId}`]
        ?.title
  )
  const iconCode = board?.iconCode

  return (
    <SelectCommonListItem
      id={id}
      title={title}
      subtitle={subtitle}
      iconCode={iconCode}
      onAction={onAction}
    />
  )
}

export default SelectBoardListItem
