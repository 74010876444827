import { client } from 'apollo'
import debounce from 'lodash/debounce'
import React, { useCallback, useState } from 'react'
import { isMobile } from 'react-device-detect'
import InfiniteScroll from 'react-infinite-scroller'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import Loading from 'components/Loading'
import Flex from 'components/common/Flex'
import { Input as CommonInput } from 'components/common/Form'
import DiscoverGridItem from 'components/discover/DiscoverGridItem'
import Grid from 'components/grid/Grid'
import { GridColumnAdditioner } from 'components/grid/GridColumnAdditioner'
import { GridItemProps } from 'components/grid/griditem/GridItem'
import { useImageSearchWorkspaceItemsQuery } from 'generated/graphql'
import StopPropagation from 'helpers/StopPropagation'
import { RootState } from 'store'

const MoreItemsContainer = styled.div`
  width: 100%;
  text-align: center;
  padding: 0 64px;
  height: 100%;
  @media (max-width: 500px) {
    padding: 0 12px;
  }
  @media (max-height: 580px) {
    padding: 0 80px;
  }
`

const HeadingLarge = styled.div`
  ${({ theme }) => css`
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.fontSizes.xl};
    color: ${theme.colors.text.neutral[0]};
    margin-bottom: 16px;
    @media (max-height: 580px) {
      font-size: ${theme.fontSizes.base};
    }
  `}
`

interface ImageSearchProps {
  itemId: string
  scrollNode: React.MutableRefObject<HTMLDivElement | null>
  isDiscoveryItem: boolean
}

const ImageSearchItems: React.FC<ImageSearchProps> = ({
  itemId,
  scrollNode,
  isDiscoveryItem,
}) => {
  const workspaceId = useSelector(
    (state: RootState) => state.content.activeWorkspace?.id
  )

  const [query, setQuery] = useState('')
  const [queryDebounced, setQueryDebounced] = useState('')

  const { data, loading, fetchMore } = useImageSearchWorkspaceItemsQuery({
    variables: {
      query: queryDebounced,
      workspaceItemId: itemId,
      scope: isDiscoveryItem ? 'PUBLIC' : 'WORKSPACE',
      workspaceId: !isDiscoveryItem ? workspaceId : null,
      first: 10,
    },
  })

  const pageInfo = data?.imageSearchWorkspaceItems.pageInfo
  const nextCursor = pageInfo?.nextCursor
  const similarItems = data?.imageSearchWorkspaceItems.workspaceItems ?? []
  const hasMore = Boolean(nextCursor) && similarItems.length > 0 && !loading

  const onQueryChange = (event: React.KeyboardEvent<HTMLInputElement>) => {
    setQuery(event.target.value)
    debounceQueryUpdate(event.target.value)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceQueryUpdate = useCallback(
    debounce((newQuery: string) => {
      setQueryDebounced(newQuery)
    }, 200),
    []
  )

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        cursor: nextCursor,
      },
    })
  }

  const onDeleteItem = useCallback((workspaceItemId: string) => {
    client.cache.evict({ id: `WorkspaceItem:${workspaceItemId}` })
    client.cache.gc()
  }, [])

  const currentUserId = useSelector(
    (store: RootState) => store.firebase.auth.uid
  )

  return (
    <>
      {
        <MoreItemsContainer>
          <Flex justifyContent="center" alignItems="center">
            <StopPropagation>
              <Flex flexGap={4} alignItems="center" justifyContent="center">
                <StyledHeadingLarge>More like this but...</StyledHeadingLarge>
                <StyledInput
                  placeholder="photographic, futuristic"
                  onChange={onQueryChange}
                  value={query}
                  autoFocus={!isMobile}
                />
              </Flex>
            </StopPropagation>
          </Flex>
          <StopPropagation>
            <div style={{ width: '100%' }}>
              <InfiniteScroll
                pageStart={0}
                threshold={1000}
                useWindow={false}
                getScrollParent={() => scrollNode.current}
                loadMore={handleLoadMore}
                hasMore={hasMore}
              >
                <GridColumnAdditioner
                  parentWrapper={scrollNode.current}
                  isHidden
                >
                  {(gridColumnAdditionOption) => {
                    if (isDiscoveryItem) {
                      return (
                        <Grid
                          key={itemId} // we use itemId as key to make sure Grid re-mounts (solves cached grid items positions)
                          customGridClassname="similar-grid"
                          scrollElement={scrollNode.current}
                          gridItems={similarItems}
                          isMultiSelectDisabled
                          gridColumnAdditionOption={gridColumnAdditionOption}
                          customGridItem={(props: GridItemProps) => (
                            <DiscoverGridItem
                              {...props}
                              currentUserId={currentUserId}
                            />
                          )}
                        />
                      )
                    }
                    return (
                      <Grid
                        key={itemId} // we use itemId as key to make sure Grid re-mounts (solves cached grid items positions)
                        customGridClassname="similar-grid"
                        gridColumnAdditionOption={gridColumnAdditionOption}
                        scrollElement={scrollNode.current}
                        gridItems={similarItems}
                        onDelete={onDeleteItem}
                      />
                    )
                  }}
                </GridColumnAdditioner>
                {loading && (
                  <div className="loader" key={0}>
                    <Loading fullscreen />
                  </div>
                )}
                {/* {similarItems.length === 0 && !loading && (
                  <div style={{ width: '100%' }}>
                    {!isDiscoveryItem && emptyContent}
                  </div>
                )} */}
              </InfiniteScroll>
            </div>
          </StopPropagation>
        </MoreItemsContainer>
      }
    </>
  )
}

const StyledHeadingLarge = styled(HeadingLarge)`
  margin-bottom: 0;
  white-space: nowrap;
`

const StyledInput = styled(CommonInput)`
  background-color: ${(props) => props.theme.colors.background[5]};
  border-color: transparent;
  ::placeholder {
    color: ${(props) => props.theme.colors.text.neutral[2]};
    opacity: 0.7;
  }
`

export default ImageSearchItems
