import { PlusLg } from '@styled-icons/bootstrap/PlusLg'
import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

import Loading from 'components/Loading'
import { Spacer } from 'components/common/Spacer'
import { Text } from 'components/common/Text'
import useSaveItemFromDiscover from 'components/discover/useSaveItemFromDiscover'
import { Item } from 'components/grid/griditem/GridItem'
import GridItemTypeIcon from 'components/grid/griditem/GridItemTypeIcon'
import getImageFinishedLoading from 'helpers/getImageFinishedLoading'
import { getItemMediaType } from 'helpers/getItemMediaType'
import { getThumbUrl } from 'helpers/getThumbs'
import { useActiveWorkspace } from 'hooks/useActiveWorkspace'
import useWorkspacePermissions from 'hooks/useWorkspacePermissions'

const MIN_WIDTH = 160

const StyledWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.black};
    border-radius: ${theme.borderRadius.lg};
    overflow: hidden;
    border: ${theme.border.thin} ${theme.colors.background[5]};
    cursor: pointer;
    background-size: cover;
    background-position: center;
    position: relative;
    background-origin: border-box;
  `}
`

const StyledIcon = styled(PlusLg)`
  height: 16px;
  color: white;
`

const StyledHoverWrapper = styled.div<{ focused: boolean }>`
  ${({ focused }) => css`
    position: absolute;
    height: 100%;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: ${focused ? 'rgba(0, 0, 0, 0.3)' : 'transparent'};
    transition: 0.2s background-color ease;
  `}
`

const LoadingWrapper = () => (
  <div
    style={{
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Loading />
  </div>
)

export const IdeaForBoardCard = ({
  item,
  height,
  boardId,
  boardTitle,
  projectId,
  onClick,
}: {
  item: Item
  height: number
  boardId: string
  boardTitle: string
  projectId: string
  onClick: () => void
}) => {
  const [originalThumbTypeHasLoaded, setOriginalThumbTypeHasLoaded] =
    useState(false)
  const [focused, setFocused] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const activeWorkspace = useActiveWorkspace()!
  const userAllowedTo = useWorkspacePermissions(['UPLOAD'])
  const allowedToSave = Boolean(userAllowedTo.UPLOAD && activeWorkspace?.id)

  useEffect(() => {
    const gifThumb = getThumbUrl(item, 'gif')
    if (focused && gifThumb && !originalThumbTypeHasLoaded) {
      getImageFinishedLoading(gifThumb).then((hasLoaded) =>
        setOriginalThumbTypeHasLoaded(hasLoaded)
      )
    }
  }, [focused, originalThumbTypeHasLoaded, item])

  const { handleSaveToBoard } = useSaveItemFromDiscover({
    itemId: item.id,
    activeWorkspaceId: activeWorkspace.id,
    activeWorkspaceUrl: activeWorkspace.url,
  })

  const onFocus = () => setFocused(true)
  const onBlur = () => setFocused(false)

  const itemMediaType = getItemMediaType(item)

  const thumbUrl =
    itemMediaType === 'gif' && focused && originalThumbTypeHasLoaded
      ? getThumbUrl(item, 'gif')
      : getThumbUrl(item, 'image')

  const width =
    MIN_WIDTH > Math.floor(height * item.aspectRatio)
      ? MIN_WIDTH
      : Math.floor(height * item.aspectRatio)

  const saveToBoard = () => {
    if (allowedToSave) {
      setIsLoading(true)
      handleSaveToBoard({ boardId, boardTitle, projectId })
        .then(onClick)
        .finally(() => setIsLoading(false))
    }
  }

  return (
    <StyledWrapper
      style={{
        backgroundImage: `url(${thumbUrl})`,
        height: `${height}px`,
        width: `${width}px`,
      }}
      onMouseEnter={onFocus}
      onMouseLeave={onBlur}
      onClick={saveToBoard}
    >
      {!focused && <GridItemTypeIcon itemType={itemMediaType} />}
      {focused && itemMediaType === 'gif' && !originalThumbTypeHasLoaded && (
        <LoadingWrapper />
      )}
      {allowedToSave && (
        <StyledHoverWrapper focused={focused}>
          {focused && !isLoading && (
            <>
              <StyledIcon />
              <Spacer factor={1} axis="x" />
              <Text size="lg" color="neutral.0">
                Add
              </Text>
            </>
          )}
          {isLoading && <LoadingWrapper />}
        </StyledHoverWrapper>
      )}
    </StyledWrapper>
  )
}
