import { ArrowBack } from '@styled-icons/material/ArrowBack'
import type firebase from 'firebase/compat'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase'
import { useNavigate, useParams } from 'react-router'
import styled from 'styled-components'

import Loading from 'components/Loading'
import Button from 'components/common/Button'
import { Text } from 'components/common/Text'
import { capitalizeFirstLetter } from 'helpers/formatting'
import { trackUploadSessionSourceClicked } from 'helpers/tracking/tracking'
import { RootState } from 'store'

import { timeAgo } from '../../../helpers/utilityFunctions'
import UserRepresentation from '../../user/UserRepresentation'
import { Cover, EditableTitle } from '../Common'
import { Main } from '../LibraryStyles'
import { UploadSessionItems } from './UploadSessionItems'
import { UploadSessionMenu } from './UploadSessionMenu'

function UploadSession() {
  const navigate = useNavigate()
  const firestore = useFirestore()
  const { uploadSessionId } = useParams() as { uploadSessionId: string }

  // @ts-ignore
  const [scrollNode, setScrollNode] = useState(document.window)

  useFirestoreConnect({
    collection: 'uploadSessions',
    doc: uploadSessionId,
  })

  const siderSplitValue = useSelector(
    (state: RootState) => state.ui.siderSplitValue
  )

  const uploadSession = useSelector(
    (state: RootState) => state.firestore.data.uploadSessions?.[uploadSessionId]
  )

  const setTitleOverride = (title: string) => {
    let title_: string | firebase.firestore.FieldValue = title
    if (title === '' || title === uploadSession?.title) {
      title_ = firestore.FieldValue.delete()
    }
    return firestore.update(
      {
        collection: 'uploadSessions',
        doc: uploadSessionId,
      },
      { titleOverride: title_ }
    )
  }

  const handleBackAction = () => {
    navigate(-1)
  }

  if (!uploadSession) {
    return (
      <StyledUploadSessionPopup siderSplitValue={siderSplitValue}>
        <Main>
          <Loading />
        </Main>
      </StyledUploadSessionPopup>
    )
  }

  return (
    <StyledUploadSessionPopup siderSplitValue={siderSplitValue}>
      <Main ref={(ref) => setScrollNode(ref)}>
        <Cover justifyContent="center" src={uploadSession.cover?.coverUrl}>
          <EditableTitle
            initialValue={uploadSession.titleOverride ?? uploadSession.title}
            onSubmit={setTitleOverride}
          />
          <Text size="lg" color="neutral.0">
            {uploadSession.tagCount} magic pins
          </Text>
          <Text size="base" color="neutral.0">
            Created {timeAgo(uploadSession.createdAt.toDate())} by{' '}
            <UserRepresentation type="name" uid={uploadSession.createdBy} />
            {uploadSession.origin?.type === 'frameExtraction' && (
              <Text size="sm" color="neutral.0">
                Extracted from{' '}
                {uploadSession.origin.sourceInfo?.urlType ? (
                  <a
                    target="_blank"
                    css="color: currentcolor; text-decoration:underline"
                    rel="noreferrer"
                    href={uploadSession.origin?.sourceInfo?.url}
                    onClick={() =>
                      trackUploadSessionSourceClicked({
                        sourceUrl: uploadSession.origin?.sourceInfo?.url ?? '',
                        sourceType:
                          uploadSession.origin?.sourceInfo?.urlType ?? '',
                      })
                    }
                  >
                    {capitalizeFirstLetter(
                      uploadSession.origin.sourceInfo.urlType
                    )}{' '}
                    video
                  </a>
                ) : (
                  'Uploaded video'
                )}
              </Text>
            )}
          </Text>
          <BackButton onClick={handleBackAction} Icon={ArrowBack} />
          <UploadSessionMenu
            uploadSessionId={uploadSessionId}
            workspaceId={uploadSession.workspaceId}
            isPublicUpload={
              uploadSession.uploadOptions?.isPublicUpload === true
            }
          />
        </Cover>
        {scrollNode && (
          <UploadSessionItems
            uploadSessionId={uploadSessionId}
            scrollNode={scrollNode}
          />
        )}
      </Main>
    </StyledUploadSessionPopup>
  )
}

const BackButton = styled(Button)`
  position: absolute;
  top: 8px;
  left: 8px;
  padding: 8px 16px 8px 16px;
  border-radius: 16px;
`

const StyledUploadSessionPopup = styled.div<{ siderSplitValue: number }>`
  position: fixed;
  right: 0px;
  left: ${({ siderSplitValue }) => `${siderSplitValue}px`};
  top: 60px;
  background: ${({ theme }) => theme.colors.background[1]};
  z-index: 2;
`

export default UploadSession
