import { Link } from 'react-router-dom'

import { Spacer } from 'components/common/Spacer'
import { HighlightRow } from 'components/highlight-row/HighlightRow'
import { useTagsForYouQuery } from 'generated/graphql'
import { trackDiscoveryTagForYouClicked } from 'helpers/tracking/tracking'

import { TagCard } from '../components/TagCard'

export const TagsForYouRow = ({
  trackingSessionId,
}: {
  trackingSessionId: string
}) => {
  const { data, loading } = useTagsForYouQuery({
    fetchPolicy: 'cache-and-network',
    context: { batch: true },
  })

  const items = data?.tagsForYou || []

  return (
    <>
      <HighlightRow
        title="Tags for you"
        listItems={items}
        isLoading={loading && !items.length}
        renderListItem={(tag) => {
          return (
            <Link
              to={`discover/?search=true&tags=${tag.description}>>${tag.id}`}
              onClick={() =>
                trackDiscoveryTagForYouClicked({ trackingSessionId })
              }
            >
              <TagCard description={tag.description} thumbUrl={tag.thumbUrl} />
            </Link>
          )
        }}
      />
      <Spacer factor={5} />
    </>
  )
}
