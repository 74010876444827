import { Check } from '@styled-icons/boxicons-regular/Check'
import { Close } from '@styled-icons/material-sharp/Close'
import { useState } from 'react'
import styled, { css } from 'styled-components'

import Flex from 'components/common/Flex'
import { Spacer } from 'components/common/Spacer'
import { Text } from 'components/common/Text'
import { Tooltip } from 'components/common/Tooltip/Tooltip'
import { GuideModal } from 'components/guide-modal/GuideModal'
import { trackHomeLearningCardClicked } from 'helpers/tracking/tracking'

import { GuideInfo } from './guidesInfo'
import type { HomeGuideId } from './guidesInfo'

type GuideCardProps = {
  id: HomeGuideId
  guideInfo: GuideInfo
  isRead: boolean
  onHideClick: (id: HomeGuideId) => void
  onIsRead: (id: HomeGuideId) => void
}

export const GuideCard = (props: GuideCardProps) => {
  const [isGuideModalOpen, setIsGuideModalOpen] = useState(false)

  const handleGuideCardClick = () => {
    setIsGuideModalOpen(true)

    trackHomeLearningCardClicked({
      category: props.id,
    })
  }

  const handleGuideModalClose = () => {
    setIsGuideModalOpen(false)
  }

  const handleGuideIsDone = (id: HomeGuideId) => {
    props.onIsRead(id)
    setIsGuideModalOpen(false)
  }

  return (
    <>
      <StyledSetupActionCardWrapper
        flexDirection="column"
        onClick={handleGuideCardClick}
      >
        <StyledActionCardImage src={props.guideInfo.thumbUrl} />
        <Spacer factor={1} />
        <Flex p={1}>
          <props.guideInfo.IconComponent size={18} style={{ flexShrink: 0 }} />
          <Spacer factor={1} axis="x" />
          <Flex flexDirection="column" css="line-height: 18px">
            <Text color="neutral.0" size="sm">
              {props.guideInfo.title}
            </Text>
            <Spacer factor={0.5} />
            {props.isRead ? (
              <Flex alignItems="center">
                <StyledCheckMark size={18} />
                <Spacer axis="x" factor={0.5} />
                <Text color="accent.3" size="sm">
                  Read
                </Text>
              </Flex>
            ) : (
              <StyledSubtitleText color="neutral.3" size="sm">
                {props.guideInfo.subTitle}
              </StyledSubtitleText>
            )}
          </Flex>
        </Flex>
        <StyledCloseButton
          onClick={(e) => {
            e.stopPropagation()
            props.onHideClick(props.id)
          }}
        >
          <Tooltip title="Hide" position="right">
            <Close size={18} color="white" style={{ marginBottom: '2px' }} />
          </Tooltip>
        </StyledCloseButton>
      </StyledSetupActionCardWrapper>
      {isGuideModalOpen && (
        <GuideModal
          onDone={() => handleGuideIsDone(props.id)}
          onClose={handleGuideModalClose}
          id={props.id}
        />
      )}
    </>
  )
}

const StyledSetupActionCardWrapper = styled(Flex)(
  ({ theme }) => css`
    border-radius: ${theme.borderRadius.lg};
    border: ${theme.border.thin} ${theme.colors.background[5]};
    background: ${theme.colors.background[3]};
    transition: 0.2s background ease;
    width: 250px;
    height: 240px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    :hover {
      background: ${theme.colors.background[4]};
    }
    :hover ${StyledActionCardImage} {
      filter: brightness(1);
    }
    :hover ${StyledCloseButton} {
      opacity: 1;
    }
    :hover ${StyledSubtitleText} {
      color: ${theme.colors.text.neutral[0]};
    }
  `
)

const StyledSubtitleText = styled(Text)`
  transition: 0.2s color ease;
`

const StyledCheckMark = styled(Check)(
  ({ theme }) => css`
    color: ${theme.colors.accent[3]};
  `
)

const StyledActionCardImage = styled.img`
  height: 150px;
  object-fit: cover;
  transition: 0.2s filter ease;
  filter: brightness(0.7);
`

const StyledCloseButton = styled.div(
  ({ theme }) => css`
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 5px;
    background-color: ${theme.colors.gray.dark[7]};
    border-radius: ${theme.borderRadius.full};
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s background-color ease;
    opacity: 0;
    @media (max-width: ${theme.breakpoints.sm}px) {
      opacity: 1;
    }
    :hover {
      background-color: ${theme.colors.gray.medium[0]};
    }
  `
)
