import { ItemMediaType, MediaVariants } from 'generated/graphql'
import getTypeCategory from 'helpers/getTypeCategory'
import { DBSearchableItem } from 'types/db'

interface ItemVersion {
  path: string
  url: string
  label: string
  versionKey?: string
  index?: number
}

export const getItemVersions = (item: DBSearchableItem) => {
  const sizes = item.sizes?.map((size) => {
    const { path, url, maxWidth, type } = size
    const sizeTypeCategory = getTypeCategory(type) // Gets the category of the type i.e. "image" instead of "jpg".
    // If the original item type is equal to the size type then proceed.
    if (getTypeCategory(item.original.info.type) === sizeTypeCategory) {
      // Checks the maxWidth to determine what size the item version is.
      if (maxWidth === 1600) {
        return { label: 'Large', path, url }
      } else if (maxWidth === 800) {
        return { label: 'Medium', path, url }
        // Gifs and video have 260 maxWidth for "Small" whilst image has 400.
      } else if (maxWidth === 400 && sizeTypeCategory === 'image') {
        return { label: 'Small', path, url }
      } else if (maxWidth === 260 && sizeTypeCategory !== 'image') {
        return { label: 'Small', path, url }
      } else if (!maxWidth && sizeTypeCategory === 'image') {
        return { label: 'Original as JPG', path, url }
      }
    }
    return {}
  }) as ItemVersion[]
  if (sizes) {
    sizes.push({
      label: 'Original',
      path: item.originalStorageLocation,
      url: '',
    })
  }
  const versions: ItemVersion[] = item.versions
    ? [
        {
          label: 'Small',
          versionKey: 'jpg-400px',
          path: item.versions['jpg-400px']?.path,
          url: item.versions['jpg-400px']?.publicUrl,
        },
        {
          label: 'Medium',
          versionKey: 'jpg-800px',
          path: item.versions['jpg-800px']?.path,
          url: item.versions['jpg-800px']?.publicUrl,
        },
        {
          label: 'Large',
          versionKey: 'jpg-1600px',
          path: item.versions['jpg-1600px']?.path,
          url: item.versions['jpg-1600px']?.publicUrl,
        },
        {
          label: 'Original',
          versionKey: 'Original',
          path: item.originalStorageLocation,
          url: item.versions.Original?.publicUrl,
        },
        {
          label: 'Original as JPG',
          versionKey: 'jpg-original',
          path: item.versions['jpg-original']?.path,
          url: item.versions['jpg-original']?.publicUrl,
        },
      ]
    : sizes

  return versions.filter((version) => version.path)
}

type GetMediaVariantsForMediaTypeArgs = {
  mediaVariants: MediaVariants[]
  mediaType: ItemMediaType
}

export const getMediaVariantsForMediaType = ({
  mediaVariants,
  mediaType,
}: GetMediaVariantsForMediaTypeArgs) => {
  const type = mediaType === 'GIF' ? 'gif' : 'jpg'
  return mediaVariants.filter((mediaVariant) => mediaVariant.type === type)
}

type GetMediaVariantArgs = {
  mediaVariants: MediaVariants[]
  variant: 'thumb' | 'large'
  mediaType: ItemMediaType
}

export const getMediaVariant = ({
  mediaVariants,
  variant,
  mediaType,
}: GetMediaVariantArgs) => {
  const type = mediaType === 'GIF' ? 'gif' : 'jpg'
  return mediaVariants.find(
    (mediaVariant) =>
      mediaVariant.variant === variant && mediaVariant.type === type
  )
}

const gifMediaVariantToTitle: Record<string, string> = {
  thumb: 'Small',
  large: 'Large',
}

type GetTitleFromMediaVariantArgs = {
  variant: string
  mediaType: ItemMediaType
}

export const getTitleFromMediaVariant = ({
  variant,
  mediaType,
}: GetTitleFromMediaVariantArgs) => {
  if (mediaType === 'GIF') {
    return gifMediaVariantToTitle[variant]
  }
  if (mediaType === 'IMAGE') {
    return imageMediaVariantToTitle[variant]
  }
  return null as never
}

const imageMediaVariantToTitle: Record<string, string> = {
  small: 'Small',
  medium: 'Medium',
  large: 'Large',
  original: 'Original as JPG',
}

function swapKeysAndValues(obj: typeof imageMediaVariantToTitle) {
  const swapped = Object.entries(obj).map(([key, value]) => [value, key])

  return Object.fromEntries(swapped)
}

export const titleToMediaVariant = swapKeysAndValues(imageMediaVariantToTitle)
