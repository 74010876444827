import * as RadixSelect from '@radix-ui/react-select'
import { ChevronDown } from '@styled-icons/bootstrap/ChevronDown'
import { Check } from '@styled-icons/boxicons-regular/Check'
import styled, { css } from 'styled-components'

type SelectProps = {
  value: string
  onValueChange: (value: string) => void
  options: Array<{ value: string; description: string }>
}

export const Select = (props: SelectProps) => (
  <RadixSelect.Root value={props.value} onValueChange={props.onValueChange}>
    <StyledTrigger>
      <RadixSelect.Value />
      <StyledTriggerIcon>
        <ChevronDown size="10" />
      </StyledTriggerIcon>
    </StyledTrigger>
    <RadixSelect.Portal>
      <StyledContent>
        <RadixSelect.Viewport style={{ padding: 5 }}>
          {props.options.map((option) => {
            return (
              <StyledItem key={option.value} value={option.value}>
                <RadixSelect.ItemText>
                  {option.description}
                </RadixSelect.ItemText>
                <StyledItemIndicator>
                  <Check size="18" />
                </StyledItemIndicator>
              </StyledItem>
            )
          })}
        </RadixSelect.Viewport>
      </StyledContent>
    </RadixSelect.Portal>
  </RadixSelect.Root>
)

const StyledTrigger = styled(RadixSelect.Trigger)(
  ({ theme }) => css`
    all: unset;
    box-sizing: border-box;
    background: ${theme.colors.background[4]};
    border: 1px solid ${theme.colors.gray.dark[6]};
    border-radius: ${theme.borderRadius.default};
    color: ${theme.colors.gray.light[0]};
    padding: 0px 16px;
    height: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
    user-select: none;
    :hover {
      border: 1px solid ${theme.colors.gray.medium[0]};
    }
  `
)

const StyledItem = styled(RadixSelect.Item)(
  ({ theme }) => css`
    all: unset;
    color: ${theme.colors.gray.light[0]};
    border-radius: ${theme.borderRadius.default};
    height: 25px;
    padding: 0px 35px 0 25px;
    display: flex;
    align-items: center;
    user-select: none;
    &[data-highlighted] {
      color: ${theme.colors.text.neutral[0]};
      background: ${theme.colors.background[5]};
    }
  `
)

const StyledContent = styled(RadixSelect.Content)(
  ({ theme }) => css`
    background: ${theme.colors.background[4]};
    border-radius: ${theme.borderRadius.default};
  `
)

const StyledItemIndicator = styled(RadixSelect.ItemIndicator)(
  () => css`
    position: absolute;
    left: 0;
    width: 25;
    padding-left: 6px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  `
)

const StyledTriggerIcon = styled(RadixSelect.Icon)(
  ({ theme }) => css`
    color: ${theme.colors.gray.light[0]};
  `
)
