import { useSelector } from 'react-redux'

import { Item } from 'components/grid/griditem/GridItem'
import {
  BoardItemViewQuery,
  WorkspaceItemViewQuery,
  useBoardItemViewQuery,
  useWorkspaceItemViewQuery,
} from 'generated/graphql'
import { RootState } from 'store'

import { convertGridItem } from './helpers/convertGridItem'
import { convertItemRefToBoardItem } from './helpers/convertItemRefToBoardItem'
import { convertTypesenseItemToWorkspaceItem } from './helpers/convertTypesenseItemToWorkspaceItem'

type ItemFromStore = Item | undefined

const getPartialCachedItem = ({
  itemFromStore,
}: {
  itemFromStore: ItemFromStore
}) => {
  if (!itemFromStore) return null
  if ('isTypesenseItem' in itemFromStore) {
    return convertTypesenseItemToWorkspaceItem({ typesenseItem: itemFromStore })
  }
  if ('__typename' in itemFromStore) {
    return convertGridItem({ gridItem: itemFromStore })
  }
  return convertItemRefToBoardItem({ boardItem: itemFromStore })
}

export const useGetItemData = ({
  itemId,
  isBoardItem,
}: {
  itemId: string
  isBoardItem: boolean
}) => {
  const itemFromStore: ItemFromStore = useSelector(
    (state: RootState) =>
      state.content?.focusedItem?.id === itemId && state.content?.focusedItem
  )

  const partialCachedItem = getPartialCachedItem({ itemFromStore })

  const workspaceItemRes = useWorkspaceItemViewQuery({
    variables: {
      workspaceItemId: itemId,
    },
    fetchPolicy: 'cache-and-network',
    skip: isBoardItem,
  })
  const boardItemRes = useBoardItemViewQuery({
    variables: {
      boardItemId: itemId,
    },
    fetchPolicy: 'cache-and-network',
    skip: !isBoardItem,
  })

  const res = isBoardItem ? boardItemRes : workspaceItemRes

  // if we don't have the graphql data yet we start using the partial cached item from store
  if (!res.data && partialCachedItem) {
    const data = {
      ...(isBoardItem
        ? {
            boardItem: partialCachedItem,
          }
        : {
            workspaceItem: partialCachedItem,
          }),
    }
    return { ...res, data }
  }
  return res
}

export type ItemData =
  | WorkspaceItemViewQuery['workspaceItem']
  | BoardItemViewQuery['boardItem']
