import { Item } from 'components/grid/griditem/GridItem'
import { DBItemThumbVersion } from 'types/db'

import getThumbsVersion from './getThumbsVersion'
import getTypeCategory from './getTypeCategory'

export const getThumbUrl = (item: Item, type: 'image' | 'gif') => {
  if ('isTypesenseItem' in item) {
    if ((item.thumbs?.length || 0) > 1) {
      const thumbs = getTypesenseThumbs(item.thumbs!)
      return getThumbsVersion(thumbs, type)?.url
    }
    return item.thumbUrl!
  }
  if ('__typename' in item) {
    return item.mediaVariants.find(
      (mediaVariant) =>
        mediaVariant.variant === 'thumb' &&
        getTypeCategory(mediaVariant.type) === type
    )?.url
  }
  if ('thumbs' in item) {
    const thumb = getThumbsVersion(item.thumbs, type)
    return thumb?.url || thumb?.publicUrl // seems like we have some inconsistency in the data
  }
  return item.thumbUrl!
}

export const getTypesenseThumbs = (thumbs: string[]) => {
  if (thumbs && typeof thumbs[0] === 'string') {
    const parsedThumbs = thumbs.map((thumb: string) => {
      const parsed = thumb.split(':')
      const parsedObject = {
        url: decodeURIComponent(parsed[0]),
        type: parsed[1],
        path: parsed[3],
        maxWidth:
          parsed[4] !== 'undefined'
            ? (parseInt(parsed[4], 10) as number)
            : undefined,
        maxHeight:
          parsed[5] !== 'undefined'
            ? (parseInt(parsed[5], 10) as number)
            : undefined,
      }
      return parsedObject as DBItemThumbVersion
    })
    return parsedThumbs
  }
  return undefined
}
