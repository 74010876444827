import { useEffect, useRef } from 'react'
import { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { StringParam, useQueryParams } from 'use-query-params'
import { v4 as uuid } from 'uuid'

import { Spacer } from 'components/common/Spacer'
import { Tab, Tabs } from 'components/common/Tabs'
import ItemView from 'components/item/ItemView'
import PublicItemView from 'components/item/PublicItemView'
import { trackProfileOpened } from 'helpers/tracking/tracking'
import { useInitActiveWorkspaceFromLastActiveWorkspace } from 'hooks/useInitActiveWorkspaceFromLastActiveWorkspace'
import { RootState } from 'store'

import { ProfileBoards } from './ProfileBoards'
import { ProfileCover } from './ProfileCover'
import { ProfileHeader } from './ProfileHeader'
import { ProfileInfo } from './ProfileInfo'
import { ProfileItems } from './ProfileItems'
import { ProfileNavbar } from './ProfileNavbar'
import { useGetUserProfile } from './helpers/useGetUserProfile'

const ProfileView = () => {
  const trackingSessionId = useRef('')
  const wrapperRef = useRef<HTMLDivElement>(null)
  const params = useParams()
  const currentUserId = useSelector(
    (state: RootState) => state.firebase.auth.uid
  )

  const userHandle = params.userHandle

  const [{ item: itemId }] = useQueryParams({
    item: StringParam,
  })

  const { data, loading } = useGetUserProfile({
    userHandle,
    userId: userHandle ? undefined : currentUserId,
  })

  useInitActiveWorkspaceFromLastActiveWorkspace({
    skip: !userHandle, // we wanna skip in workspace profile page
  })

  useEffect(() => {
    const id = uuid()
    trackingSessionId.current = id
    trackProfileOpened({
      trackingSessionId: id,
    })
  }, [])

  const user = data?.userProfile
  const userIsLoading = !user && loading
  const isCurrentUserProfile = Boolean(
    currentUserId && user?.uid === currentUserId
  )

  return (
    <SkeletonTheme baseColor="#141414" highlightColor="#252525">
      <StyledContainer ref={wrapperRef}>
        <ProfileNavbar
          shouldShowBackButton={!!userHandle}
          shouldShowUploadButton={!userHandle}
        />
        <ProfileCover
          coverImageUrl={user?.coverImageUrl}
          isEditable={isCurrentUserProfile}
          isLoadingUser={userIsLoading}
        />

        <StyledInfoWrapper>
          <ProfileHeader
            photoURL={user?.photoURL}
            displayName={user?.displayName}
            isCurrentUserProfile={isCurrentUserProfile}
            userIsLoading={userIsLoading}
            userHandle={user?.handle}
          />
          <Spacer axis="y" factor={2} />
          <ProfileInfo
            profileBio={user?.bio}
            profileCreatedAt={user?.createdAt}
            profilePublicItemsCount={user?.publicItemsCount}
            isLoadingUser={userIsLoading}
            nominatedByUser={user?.nominatedBy}
            profileDiscoverExportCount={user?.discoverExportCount}
            profileRank={user?.rank}
          />
          <Spacer factor={3} axis="y" />
          {wrapperRef.current && user && (
            <Tabs initalTab="items">
              <Tab label="items">
                <ProfileItems
                  userId={user.uid}
                  scrollNode={wrapperRef.current}
                  isExternalUser={!isCurrentUserProfile}
                  currentUserId={currentUserId}
                  userDisplayName={user.displayName}
                />
              </Tab>
              <Tab label="boards">
                <ProfileBoards
                  userId={user.uid}
                  isCurrentUserProfile={isCurrentUserProfile}
                  userDisplayName={user.displayName}
                  scrollNode={wrapperRef.current}
                />
              </Tab>
            </Tabs>
          )}
          {itemId &&
            (isCurrentUserProfile ? (
              <ItemView />
            ) : (
              <PublicItemView
                isCloseable
                inDiscover // might not be correct tracking purposes?
                trackingSessionId={trackingSessionId.current}
              />
            ))}
        </StyledInfoWrapper>
      </StyledContainer>
    </SkeletonTheme>
  )
}

export default ProfileView

const StyledContainer = styled.div`
  max-height: 100vh;
  overflow-y: auto;
  position: relative;
  overflow-x: hidden;
`

const StyledInfoWrapper = styled.div`
  margin: 32px;
  margin-top: -64px;
  position: relative;
`
