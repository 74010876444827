import styled, { DefaultTheme, css } from 'styled-components'

type DefaultColors = DefaultTheme['colors']['text']
type DefaultAccentColors = DefaultTheme['colors']['accent']

type NeutralColors = `neutral.${keyof DefaultColors['neutral']}`
type AccentColors = `accent.${keyof DefaultAccentColors}`

export type PropsFriendlyTextColors =
  | keyof Omit<DefaultColors, 'neutral'>
  | NeutralColors
  | AccentColors

const getColor = (key: PropsFriendlyTextColors, theme: DefaultTheme) => {
  if (key.startsWith('accent')) {
    return key.split('.').reduce((colorsToColor, path) => {
      return (colorsToColor as any)[Number(path) ? Number(path) : path]
    }, theme.colors)
  }
  return key
    .split('.')
    .reduce(
      (colorsToColor, path) =>
        (colorsToColor as any)[Number(path) ? Number(path) : path],
      theme.colors.text
    )
}

// TODO: type color correctly after organizing theme
export const Text = styled.p<{
  size: keyof DefaultTheme['fontSizes']
  nowrap?: boolean
  center?: boolean
  link?: boolean
  bold?: boolean
  color?: PropsFriendlyTextColors
  colorHover?: PropsFriendlyTextColors
  ellipsis?: boolean
  fontFamily?: keyof DefaultTheme['fontFamily']
}>`
  ${({
    size,
    nowrap,
    center,
    link,
    bold,
    theme,
    color,
    colorHover,
    ellipsis,
    fontFamily,
  }) => css`
    font-size: ${theme.fontSizes[size]};
    padding: 0;
    margin: 0;
    ${fontFamily && `font-family: ${theme.fontFamily[fontFamily]};`}
    ${color && `color: ${getColor(color, theme)};`}
    ${nowrap && `white-space: nowrap;`}
    ${center && `text-align: center;`}
    ${bold && `font-weight: ${theme.fontWeight.bold};`}
    ${ellipsis &&
    `white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;`}
    ${link &&
    `
      &:hover {
        text-decoration: underline;
        cursor: pointer;
        ${colorHover && `color: ${getColor(colorHover, theme)};`}
      }
    `}
  `}
`
