import { ApolloQueryResult } from '@apollo/client'
import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router'

import { BillingSubscriptionQuery } from '../../../generated/graphql'
import { wait } from '../../../helpers/utilityFunctions'
import Loading from '../../Loading'
import useSettingNavigation from '../useSettingsNavigation'

type Props = {
  refetchBillingSubscription: (variables?: {
    workspaceId: string
  }) => Promise<ApolloQueryResult<BillingSubscriptionQuery>>
}
export const CheckoutCancel: FC<Props> = ({ refetchBillingSubscription }) => {
  const { sectionUrl } = useSettingNavigation()
  const navigate = useNavigate()
  useEffect(() => {
    const asyncRefetch = async () => {
      await wait(600)
      await refetchBillingSubscription()
      const searchParamsPlans = sectionUrl('plans')
      navigate(searchParamsPlans)
    }
    asyncRefetch()
  }, [refetchBillingSubscription, navigate, sectionUrl])
  return <Loading />
}
