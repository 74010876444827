import { motion } from 'framer-motion'
import React from 'react'
import styled, { css } from 'styled-components'

import { CardImage, CardTitle, Overlay } from '../LibraryStyles'

const infoTransitions = {
  inital: {
    opacity: 0,
  },
  hover: {
    opacity: 1,
  },
}

const imageTransitions = {
  inital: {
    scale: 1,
  },
  hover: {
    scale: 1.05,
  },
}

const overlayTransitions = {
  inital: {
    opacity: 0.4,
  },
  hover: {
    opacity: 0,
  },
}

const titleTransitions = {
  inital: {
    y: 0,
  },
  hover: {
    y: -15,
  },
}

const transition = {
  ease: 'easeIn',
  duration: 0.1,
}

function capitalizeWords(string: string) {
  return string
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

type MagicBoardCardProps = {
  count: number
  description: string
  thumbUrl: string | null
  onClick: () => void
  className?: string
}

export const MagicBoardCard: React.FC<MagicBoardCardProps> = ({
  count,
  description,
  thumbUrl,
  onClick,
  className,
}) => {
  return (
    <StyledCardSizing
      className={className}
      onClick={onClick}
      whileHover="hover"
      initial="inital"
    >
      <StyledBorderWrapper>
        <CardImage
          variants={imageTransitions}
          transition={transition}
          src={thumbUrl || ''}
        />
        <Overlay variants={overlayTransitions} transition={transition} />
        <CardTitle variants={titleTransitions} transition={transition}>
          {capitalizeWords(description)}
        </CardTitle>
        <StyledInfo variants={infoTransitions} transition={transition}>
          {count} Magic Pins
        </StyledInfo>
      </StyledBorderWrapper>
    </StyledCardSizing>
  )
}

const StyledCardSizing = styled(motion.div)`
  text-align: center;
  width: 100%;
`

const StyledInfo = styled(motion.div)`
  ${({ theme }) => css`
    width: 100%;
    text-align: center;
    font-size: ${theme.fontSizes.sm};
    position: absolute;
    bottom: 75px;
    color: white;
    text-shadow: 0px 1px 2px rgb(0 0 0 / 56%);
    z-index: 1;
    @media (max-width: 768px) {
      font-size: ${theme.fontSizes.xs};
      padding: 12px 16px;
      bottom: 30px;
    }
  `}
`

const StyledBorderWrapper = styled(motion.div)`
  ${({ theme }) => css`
    background: ${theme.colors.background[3]};
    border-radius: ${theme.borderRadius.lg};
    overflow: hidden;
    border: 4px solid transparent;
    transition: border 0.1s;
    position: relative;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    :hover {
      border: 4px solid ${theme.colors.gray.dark[6]};
    }
  `}
`
