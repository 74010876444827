import { useEffect, useState } from 'react'
import { useFirestore } from 'react-redux-firebase'

import { reportError } from 'helpers/logging'
import { DBWorkspace } from 'types/db'

const useGetWorkspace = (workspaceId?: string) => {
  const [workspace, setWorkspace] = useState<DBWorkspace>()
  const [error, setError] = useState()

  const firestore = useFirestore()

  useEffect(() => {
    const fetchWorkspace = async () => {
      try {
        const workspaceSnapshot = await firestore.get({
          collection: 'workspaces',
          doc: workspaceId,
        })
        const workspaceData = workspaceSnapshot.data() as DBWorkspace
        setWorkspace(workspaceData)
      } catch (err) {
        if (err.code === 'permission-denied') {
          setError(err.code)
          return
        }
        reportError(err)
      }
    }
    fetchWorkspace()
  }, [firestore, workspaceId])

  return { workspace, error }
}

export default useGetWorkspace
