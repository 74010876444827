import { ArrowRightShort } from '@styled-icons/bootstrap'
import { Camera as CameraAdd } from '@styled-icons/bootstrap/Camera'
import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { useLocation, useNavigate } from 'react-router'
import { compose } from 'redux'
import { useTheme } from 'styled-components'
import { v4 as uuid } from 'uuid'

import { Margin } from 'components/common/Margin'
import Spinner from 'components/common/Spinner'
import useToastMessages from 'components/toast/useToastMessages'
import {
  trackSetupProfileCompleted,
  trackSetupStarted,
} from 'helpers/tracking/tracking'
import useIsMobile from 'hooks/useIsMobile'

import { db, firebase } from '../../config/firebase'
import callCloudFunction from '../../helpers/callCloudFunction'
import { LoaderIcon } from '../Loading'
import SelectT, { SelectOption } from '../common/Select'
import { Button } from './SetupCommon'
import {
  ContentContainer,
  ErrorMessage,
  Footer,
  Heading,
  InputStyled,
  MobileButton,
  ProfileImage,
  ProfileImageOverlay,
  Row,
} from './SetupStyles'

const jobTitles = [
  'director',
  'visual researcher',
  'assistant',
  'producer',
  'executive producer',
  'art director',
  'graphic designer',
  'other designer',
  'stylist',
  'director of photography',
  'make-up artist',
  'other',
]

// Profile image animation control
const profileImageAnimationStates = {
  isHover: { opacity: 1, transition: { duration: 0.1 } },
  isNotHover: { opacity: 0, transition: { duration: 0.1 } },
}

const SetupProfile = (props) => {
  const location = useLocation()
  const navigate = useNavigate()
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm()
  const [errorMessage, setErrorMessage] = useState(null)
  const [triedSubmitting, setTriedSubmitting] = useState(null)
  const [jobTitle, setJobTitle] = useState(() =>
    props.jobTitle ? props.jobTitle : null
  )
  const [isCompleting, setIsCompleting] = useState(false)
  const theme = useTheme()
  const isMobile = useIsMobile()
  const inputRef = useRef()
  const params = new URLSearchParams(location.search)

  const [profileImageHover, setProfileImageHover] = useState(false)
  const [profileImageIsLoading, setProfileImageIsLoading] = useState(false)

  const [hasProfileImage, setHasProfileImage] = useState(false)

  // Privacy policy and terms of service read count
  const [timesReadPrivacyPolicy, setTimesReadPrivacyPolicy] = useState(0)
  const [timesReadTermsOfService, setTimesReadTermsOfService] = useState(0)

  // Profile image
  const [timesUploadedProfileImage, setTimesUploadedProfileImage] = useState(0)

  const [trackingSetupSessionId, setTrackingSetupSessionId] = useState(
    params.get('sessionId')
  )

  useEffect(() => {
    if (props.photoUrl) {
      setHasProfileImage(true)
    }
    if (!trackingSetupSessionId) {
      const newTrackingSetupSessionId = uuid()
      trackSetupStarted({
        trackingSetupSessionId: newTrackingSetupSessionId,
      })
      setTrackingSetupSessionId(newTrackingSetupSessionId)
    }
  }, [])

  const onSubmit = async (values) => {
    setTriedSubmitting(true)

    if (!errors.firstName && !errors.lastName && jobTitle) {
      setIsCompleting(true)

      const { firstName, lastName } = values
      const displayName = `${firstName} ${lastName}`

      const userUpdates = {
        firstName,
        lastName,
        displayName,
        jobTitle,
        timesReadTermsOfService,
        timesReadPrivacyPolicy,
        uid: props.uid,
        updatedAt: props.firestore.FieldValue.serverTimestamp(),
      }

      trackSetupProfileCompleted({
        firstName,
        lastName,
        jobTitle,
        trackingSetupSessionId,
        timesReadTermsOfService,
        timesReadPrivacyPolicy,
        timesUploadedProfileImage,
      })

      const onboardingUpdates = {
        completedProfile: true,
        completedPrivacy: true,
        updatedAt: props.firestore.FieldValue.serverTimestamp(),
        hasCompletedSetupProfileOnce: true,
      }

      const batch = db.batch()

      const userRef = db.collection('users').doc(props.uid)
      batch.set(userRef, userUpdates, { merge: true })

      const onboardingRef = db
        .collection('users')
        .doc(props.uid)
        .collection('private')
        .doc('onboarding')
      batch.set(onboardingRef, onboardingUpdates, { merge: true })

      await batch
        .commit()
        .then(() =>
          props.firebase.auth().currentUser.updateProfile({
            displayName,
          })
        )
        .then(() => {
          if (params.get('sessionId')) {
            navigate(`/setup/workspace${location.search}`)
          } else {
            navigate(
              `/setup/workspace?sessionId=${trackingSetupSessionId}${
                location.search ? `&${location.search.slice(1)}` : ''
              }`
            )
          }
        })
        .catch((e) => {
          setErrorMessage(e)
          setIsCompleting(false)
          console.log('Error: ', e)
        })
    }
  }

  const { reportError } = useToastMessages()

  const onFileSelect = (ev) => {
    /** @type {File} */
    const file = ev.target.files[0]
    if (file == null) {
      return
    }

    if (!file.type.startsWith('image') || file.size > 10000000) {
      reportError('ONLY IMAGES under 10 MB!!')
      return
    }

    const formData = new FormData()
    console.log('Append')
    formData.append('image', file, file.name)
    setProfileImageIsLoading(true)
    setHasProfileImage(true)
    return callCloudFunction('addProfileThumbnail', formData)
      .then(async () => {
        await firebase.reloadAuth()
      })
      .then(() => {
        setTimeout(() => {
          setTimesUploadedProfileImage((previousCount) => previousCount + 1)
          setProfileImageIsLoading(false)
        }, 500)
      })
  }

  return (
    <ContentContainer
      isMobile={isMobile}
      as="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Heading isMobile={isMobile}>
        {isMobile ? 'Profile' : 'Set up your profile'}{' '}
      </Heading>

      {/* ProfileImage Uploader */}
      <input
        type="file"
        ref={inputRef}
        onChange={onFileSelect}
        style={{ display: 'none' }}
      />

      <ProfileImage
        isMobile={isMobile}
        imageUrl={props.photoUrl}
        onClick={() => inputRef.current?.click()}
        onMouseEnter={() => setProfileImageHover(true)}
        onMouseLeave={() => setProfileImageHover(false)}
      >
        <ProfileImageOverlay
          variants={profileImageAnimationStates}
          animate={
            !hasProfileImage || (profileImageHover && !profileImageIsLoading)
              ? 'isHover'
              : 'isNotHover'
          }
        >
          <CameraAdd
            size={40}
            style={{
              color: profileImageHover
                ? theme.colors.gray.light[5]
                : theme.colors.gray.light[0],
            }}
          />
        </ProfileImageOverlay>
        {profileImageIsLoading && (
          <ProfileImageOverlay>
            <div style={{ height: 50, width: 50, color: '#ffffffd5' }}>
              <LoaderIcon />
            </div>
          </ProfileImageOverlay>
        )}
      </ProfileImage>

      <Row
        isMobile={isMobile}
        style={{ marginBottom: errorMessage ? '50px' : '10px' }}
      >
        <InputStyled
          placeholder="First name"
          defaultValue={props.firstName}
          name="firstName"
          {...register('firstName', {
            required: 'Required',
          })}
          style={{ width: isMobile ? '100%' : 'calc(50% - 8px)', margin: 4 }}
        />
        {errors.firstName && (
          <ErrorMessage
            style={{
              width: isMobile ? '100%' : 'calc(50% - 8px)',
              position: isMobile ? 'relative' : 'absolute',
              left: 0,
              textAlign: 'left',
              padding: '0 0 0 10px',
            }}
          >
            {errors.firstName.message}
          </ErrorMessage>
        )}

        <InputStyled
          placeholder="Last name"
          defaultValue={props.lastName}
          name="lastName"
          {...register('lastName', {
            required: 'Required',
          })}
          style={{
            width: isMobile ? '100%' : 'calc(50% - 8px)',
            margin: 4,
          }}
        />
        {errors.lastName && (
          <ErrorMessage
            style={{
              width: isMobile ? '100%' : 'calc(50% - 8px)',
              position: isMobile ? 'relaive' : 'absolute',
              right: 0,
              textAlign: 'left',
              padding: isMobile ? '0 0 0 10px' : 0,
            }}
          >
            {errors.lastName.message}
          </ErrorMessage>
        )}
      </Row>

      <Row isMobile={isMobile}>
        <div
          style={{
            marginBottom: 16,
            marginLeft: isMobile ? 10 : 0,
            fontSize: theme.fontSizes.sm,
            fontWeight: theme.fontWeight.light,
            color: theme.colors.text.neutral[0],
          }}
        >
          Which best describes you?
        </div>

        <SelectT
          defaultVal={jobTitle ? jobTitles.indexOf(jobTitle) : null}
          onChange={(selected) => {
            setJobTitle(selected[0])
          }}
        >
          <SelectOption value="director" emojiCode=":movie_camera:">
            Director
          </SelectOption>
          <SelectOption value="visual researcher" emojiCode=":eye:">
            Visual researcher
          </SelectOption>
          <SelectOption value="assistant" emojiCode=":muscle:">
            Assistant
          </SelectOption>
          <SelectOption value="producer" emojiCode=":moneybag:">
            Producer
          </SelectOption>
          <SelectOption value="executive producer" emojiCode="👩‍🚀">
            Executive Producer
          </SelectOption>
          <SelectOption value="art director" emojiCode=":art:">
            Art Director
          </SelectOption>
          <SelectOption value="graphic designer" emojiCode=":triangular_ruler:">
            Graphic Designer
          </SelectOption>
          <SelectOption value="other designer" emojiCode=":moyai:">
            Other Designer
          </SelectOption>
          <SelectOption value="stylist" emojiCode=":kimono:">
            Stylist
          </SelectOption>
          <SelectOption
            value="director of photography"
            emojiCode=":movie_camera:"
          >
            Director of Photography
          </SelectOption>
          <SelectOption value="make-up artist" emojiCode=":nail_care:">
            Make-up artist
          </SelectOption>
          <SelectOption value="other" emojiCode="🤷">
            Other
          </SelectOption>
        </SelectT>
        {triedSubmitting && !jobTitle && (
          <ErrorMessage>Select something</ErrorMessage>
        )}
      </Row>

      <div
        style={{
          marginTop: 16,
          fontSize: theme.fontSizes.sm,
          color: theme.colors.text.neutral[2],
          fontWeight: theme.fontWeight.light,
          textAlign: isMobile ? 'left' : 'center',
          marginLeft: isMobile ? 20 : 0,
          marginRight: isMobile ? 30 : 0,
        }}
      >
        Please take a moment to read our{' '}
        <a
          href="/privacy"
          target="_blank"
          rel="noreferrer"
          style={{ color: theme.colors.text.neutral[0] }}
          onClick={() =>
            setTimesReadPrivacyPolicy((previousCount) => previousCount + 1)
          }
        >
          Privacy Policy
        </a>{' '}
        and{' '}
        <a
          href="/terms"
          target="_blank"
          rel="noreferrer"
          style={{ color: theme.colors.text.neutral[0] }}
          onClick={() =>
            setTimesReadTermsOfService((previousCount) => previousCount + 1)
          }
        >
          Terms of Service
        </a>
      </div>
      {isMobile && <Margin y={100} />}
      <Footer isMobile={isMobile}>
        {!isMobile ? (
          <Button
            disabled={errors.firstName || errors.lastName || !jobTitle}
            icon={isCompleting ? 'loading' : 'next'}
          >
            Agree and continue
          </Button>
        ) : (
          <MobileButton
            disabled={errors.firstName || errors.lastName || !jobTitle}
          >
            {isCompleting ? (
              <Spinner css="height: 24px" />
            ) : (
              <>
                <motion.div layout>Agree and continue</motion.div>
                <AnimatePresence>
                  {!(errors.firstName || errors.lastName || !jobTitle) && (
                    <motion.div
                      style={{ textAlign: 'left' }}
                      layout
                      initial={{ x: -10, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      exit={{ x: -10, opacity: 0 }}
                    >
                      <ArrowRightShort size={28} />
                    </motion.div>
                  )}
                </AnimatePresence>
              </>
            )}
          </MobileButton>
        )}
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </Footer>
    </ContentContainer>
  )
}

const mapStateToProps = (state) => {
  const user =
    state.firestore.data.users &&
    state.firebase.auth.uid &&
    state.firestore.data.users[state.firebase.auth.uid] &&
    state.firestore.data.users[state.firebase.auth.uid]

  const names = user?.displayName ? user.displayName.split(' ') : ['']
  const lastName = names.pop(0)
  const firstName = names.join(' ')
  const jobTitle = user?.jobTitle

  return {
    uid: state.firebase.auth.uid,
    lastName,
    firstName,
    jobTitle,
    photoUrl: state.firebase.auth.photoURL,
    onboarding: state.firestore.data.user?.onboarding,
  }
}

const mapDispatchToProps = {}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect()
)(SetupProfile)
