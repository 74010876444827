import { motion } from 'framer-motion'
import isEqual from 'lodash/isEqual'
import uniqueId from 'lodash/uniqueId'
import * as queryString from 'query-string'
import React, { Component } from 'react'
import { isFirefox, isSafari } from 'react-device-detect'
import { connect } from 'react-redux'
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  Masonry,
  WindowScroller,
  createMasonryCellPositioner,
} from 'react-virtualized'
import { compose } from 'redux'
import styled from 'styled-components'

import Button from 'components/common/Button'
import { Margin } from 'components/common/Margin'
import Modal from 'components/common/Modal'
import { Text } from 'components/common/Text'
import SelectionArea from 'helpers/SelectionArea'
import { trackItemCopied } from 'helpers/tracking/tracking'
import { withRouter } from 'helpers/withRouter'
import {
  reset,
  selectItem,
  setDeleteStatus,
  setDownloadStatus,
  toggleItem,
  unselectItem,
} from 'store/multiSelect'
import { isInMultiSelectModeSelector } from 'store/selectors'

import { copyImageToClipboard } from '../../helpers/ClipboardHelper'
import { mobilecheck } from '../../helpers/utilityFunctions'
import {
  setFocusedItem,
  setIsFocusedItemSpoilerVisible,
} from '../../store/content/actions'
import { toggleCommandPalette } from '../../store/ui'
import { copyItemLink, download, openItem } from '../item/itemActions'
import { ToastContext } from '../toast/ToastMessages'
import styles from './Grid.css'
import { getNextItemFromItem } from './getNextItemFromItem'
import GridItem from './griditem/GridItem'

const DEFAULT_ITEM_WIDTH = 260

const GUTTER_SIZE = mobilecheck() ? 8 : 16

const GridMain = styled.div(() => ({
  paddingBottom: 100,
}))

class Grid extends Component {
  constructor(props, context) {
    super(props, context)
    this._columnCount = 0

    // Default sizes help Masonry decide how many images to batch-measure
    this._cache = new CellMeasurerCache({
      defaultHeight: 200,
      defaultWidth: DEFAULT_ITEM_WIDTH,
      fixedWidth: true,
    })

    this.gridClassNameId = uniqueId('grid-id-')
    this.isMultiSelectDisabled = props.isMultiSelectDisabled

    // module to multi select items
    this.selectionArea = null

    this.state = {
      columnWidth: DEFAULT_ITEM_WIDTH,
      gutterSize: GUTTER_SIZE,
      overscanByPixels: 1000,
      isDragMultiSelecting: false,
      isConfirmResetMultiSelectModalVisible: false,
    }

    this._cellRenderer = this._cellRenderer.bind(this)
    this._renderAutoSizer = this._renderAutoSizer.bind(this)
    this._renderMasonry = this._renderMasonry.bind(this)
    this._setMasonryRef = this._setMasonryRef.bind(this)
    this._onResize = this._onResize.bind(this)
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.gridItems !== nextProps.gridItems) return true
    if (
      this.props.gridColumnAdditionOption !== nextProps.gridColumnAdditionOption
    ) {
      return true
    }
    if (
      this.state.isConfirmResetMultiSelectModalVisible !==
      nextState.isConfirmResetMultiSelectModalVisible
    ) {
      return true
    }
    if (this.state.isDragMultiSelecting !== nextState.isDragMultiSelecting) {
      return true
    }

    return false
  }

  componentDidUpdate(prevProps) {
    const gridColumnAdditionOptionUpdated = !isEqual(
      this.props.gridColumnAdditionOption,
      prevProps.gridColumnAdditionOption
    )
    const gridItemsWasRemoved =
      this.props.gridItems.length < prevProps.gridItems.length

    const prevIds = prevProps.gridItems.map(({ id }) => id).join(':')
    const newIds = this.props.gridItems
      .slice(0, prevProps.gridItems.length) // we slice it to ignore added items at the end of the list
      .map(({ id }) => id)
      .join(':')
    const newOrder = prevIds !== newIds

    if (gridColumnAdditionOptionUpdated || gridItemsWasRemoved || newOrder) {
      if (this._masonry) {
        this.resetMasonryPositionCache()
      }
    }
  }

  handleBeforeStartDragSelect = ({ event }) => {
    if (this.isMultiSelectDisabled) return false
    // To prevent multi-select triggering if inside Item view and dragging the image
    if (event.target?.nodeName === 'IMG') return false
    // a hack to not allow drag selection start if cursor is on grid item as it conflicts with other drag functionality
    if (event.target.className?.includes?.('gridItem') && !event.shiftKey) {
      return false
    }
  }

  handleStartDragSelect = ({ event }) => {
    this.setState({ isDragMultiSelecting: true })
    // if user doesn't hold shift key while selecting we reset the previous selection
    if (!event.shiftKey) {
      this.props.resetMultiSelectItems()
    }
  }

  handleStopDragSelect = ({ selection }) => {
    selection.clearSelection()
    // we add a quick timeout to move this execution after the onClick event listner on the grid element (handleGridClick)
    setTimeout(() => {
      this.setState({ isDragMultiSelecting: false })
    }, 100)
  }

  handleMoveDragSelect = ({ store: { changed } }) => {
    const getItemFromElement = (element) => {
      const itemId = element.id.replace('gridItem-', '')
      const item = this.props.gridItems.find(
        (gridItem) => gridItem.id === itemId
      )
      return item
    }

    changed.removed.forEach((selectedElement) => {
      const domElement = document.querySelector(`#${selectedElement.id}`)
      // we make sure the element is still in the DOM (thix fixes issues with the virtualized list)
      if (!domElement) return
      const item = getItemFromElement(selectedElement)
      if (item) {
        this.props.multiUnselectItem(item)
      }
    })
    changed.added.forEach((selectedElement) => {
      const item = getItemFromElement(selectedElement)
      if (item) {
        this.props.multiSelectItem(item)
      }
    })
  }

  setIsConfirmResetMultiSelectModalVisible = (option) => {
    this.setState({
      isConfirmResetMultiSelectModalVisible: option,
    })
  }

  // As we stop event propagation on grid items clicks, this will only trigger when "mouse down" in grid gutter
  handleGridClick = () => {
    // we have to make sure the click is not a "multi-select dragging click"
    if (this.state.isDragMultiSelecting) return
    if (this.props.multiSelectItemsCount >= 10) {
      this.setIsConfirmResetMultiSelectModalVisible(true)
    } else {
      this.props.resetMultiSelectItems()
    }
  }

  moveItemFocus2 = (mode) => {
    // Working on a better way to determine next element
    const { id: focusedItemId = null } = this.props.focusedItem

    const nextItemElement = getNextItemFromItem({
      fromItemId: focusedItemId,
      direction: mode.substring(5).toLowerCase(), // converting e.g. 'ArrowDown' to 'down',
      gridClassName: this.gridClassNameId,
    })

    if (!nextItemElement) return

    // If item is out of view, scroll it smoothly
    if (
      nextItemElement.getBoundingClientRect().bottom >
        window.scrollY + window.innerHeight ||
      nextItemElement.getBoundingClientRect().y < window.scrollY
    ) {
      nextItemElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }

    const newItemId = nextItemElement.id.split('-')[1]
    const item = this.props.gridItems.find((i) => i.id === newItemId)
    const newItem = { ...item, id: newItemId }
    this.props.setFocusedItem(newItem)

    const parsed = queryString.parse(this.props.location.search, {
      arrayFormat: 'comma',
    })
    if (parsed.item) {
      const updatedQueryString = queryString.stringify(
        { ...parsed, item: newItemId },
        { arrayFormat: 'comma' }
      )
      this.props.navigate(
        `${this.props.location.pathname}?${updatedQueryString}`
      )
    }
  }

  handleKeyUp = (e) => {
    if (!e.metaKey && e.key === 'q') {
      return this.props.updateIsFocusedItemSpoilerVisible(false)
    }
  }

  handleKeyDown = (e) => {
    const context = {
      itemId: this.props.focusedItem.id,
      boardId: this.props.params.boardId,
      projectId: this.props.params.projectId,
      workspaceId: this.props.activeWorkspace.id,
    }

    if (!e.repeat && !e.metaKey && e.key === 'q') {
      return this.props.updateIsFocusedItemSpoilerVisible(true)
    }

    // TODO: move nisched shortcut commands outisde of Grid component as we probably wanna have different behavior in different views
    const useMultiSelectCommands =
      !this.isMultiSelectDisabled && this.props.isInMultiSelectMode

    // CMD key commands
    if (e.metaKey) {
      if (e.keyCode === 75) {
        // 'k'
        this.props.toggleCommandPalette()
        e.stopPropagation()
      }
      if (e.key === 's') {
        if (this.props.isInMultiSelectMode) {
          this.props.startMultiSelectDownload()
        } else {
          download(this.props.focusedItem, undefined, context)
        }
        e.preventDefault()
      }
      if (e.key === 'c') {
        trackItemCopied({ itemId: this.props.focusedItem.id, location: 'GRID' })
        copyImageToClipboard(this.props.focusedItem)
          .then(() => {
            if (this.props.toastReportSuccess) {
              this.props.toastReportSuccess('Image copied to clipboard!')
            }
          })
          .catch(() => {
            if (this.props.toastReportError) {
              if (isFirefox) {
                this.props.toastReportError(
                  'To enable clipboard support in Firefox, go to about:config and set "dom.events.asyncClipboard.clipboardItem"'
                )
              } else if (isSafari) {
                this.props.toastReportError(
                  'Copy image to clipboard is not supported on Safari'
                )
              } else {
                this.props.toastReportError('Image could not be copied')
              }
            }
          })

        e.preventDefault()
      }
    }

    // Alt key commands
    if (e.altKey) {
      if (e.keyCode === 68) {
        // 'd'
        if (this.props.isInMultiSelectMode) {
          this.props.startMultiSelectDownload()
        } else {
          download(this.props.focusedItem, undefined, context)
        }
        e.preventDefault()
      }
      if (e.keyCode === 67) {
        // / 'd'
        copyItemLink(this.props.focusedItem, null, context)
        e.preventDefault()
      }
    }

    if (['ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown'].includes(e.key)) {
      this.moveItemFocus2(e.key)
      e.preventDefault()
    }

    if (e.key === 'Backspace' && useMultiSelectCommands) {
      this.props.startMultiSelectDeleteItems()
      e.stopPropagation()
    }

    // Space key
    if (e.keyCode === 32) {
      const parsed = queryString.parse(this.props.location.search, {
        arrayFormat: 'comma',
      })
      if (parsed.item) return
      if (useMultiSelectCommands || e.shiftKey) {
        this.props.toggleMultiSelectItem(this.props.focusedItem)
      } else {
        this.openItemView()
      }
      e.preventDefault()
      e.stopPropagation()
    }

    if (e.key === 'Escape' && this.props.isInMultiSelectMode) {
      this.props.resetMultiSelectItems()
      e.preventDefault()
      e.stopPropagation()
    }
  }

  openItemView = () => {
    openItem(
      this.props.focusedItem.id,
      this.props.location,
      this.props.navigate
    )
  }

  resetMasonryPositionCache = () => {
    console.log('[Grid] Resetting masonry grid position cache')
    this._resetCellPositioner()
    this._cache.clearAll()
    this._masonry.clearCellPositions()
  }

  reorderItems = async (...args) => {
    this.props.reorderItems(...args)
  }

  _renderAutoSizer({ height, scrollTop }) {
    this._height = height
    this._scrollTop = scrollTop

    return (
      <AutoSizer
        style={{ width: '1px' }} // fixes auto scroll issue in chrome
        gridItems={this.props.gridItems}
        disableHeight
        height={height}
        onResize={this._onResize}
        scrollTop={this._scrollTop}
      >
        {this._renderMasonry}
      </AutoSizer>
    )
  }

  _renderMasonry({ width }) {
    this._width = width

    this._initCellPositioner()
    const Wrapper = this.props.withFadeInAnimation ? FadeIn : 'div'
    return (
      <Wrapper>
        <Masonry
          style={{ outline: 'none' }}
          gridItems={this.props.gridItems}
          autoHeight
          cellCount={this.props.gridItems.length}
          cellMeasurerCache={this._cache}
          cellPositioner={this._cellPositioner}
          cellRenderer={this._cellRenderer}
          height={this._height ?? 600}
          overscanByPixels={this.state.overscanByPixels}
          ref={this._setMasonryRef}
          scrollTop={this._scrollTop}
          onScroll={() => {
            if (this.state.isDragMultiSelecting) {
              this.selectionArea?.resolveSelectables() // we have to make sure the multi-select area is updating with new items (fixes pagination & virtualization)
            }
          }}
          width={width}
          scrollingResetTimeInterval={10}
        />
      </Wrapper>
    )
  }

  _calculateColumnCount() {
    const { columnWidth, gutterSize } = this.state

    let newColumnCount =
      Math.floor(this._width / (columnWidth + gutterSize)) +
      (this.props.gridColumnAdditionOption || 0)

    const getColumnCount = () => {
      if (newColumnCount <= 2) {
        newColumnCount = 2
      }
      if (newColumnCount <= 2 && this._width > 800) {
        newColumnCount += 1
      }
      if (this._width > 1500) {
        newColumnCount += 1
      }
      return newColumnCount
    }
    this._columnCount = getColumnCount()
    this._columnWidth = Math.floor(
      (this._width - (this._columnCount - 1) * gutterSize) / this._columnCount
    )
  }

  _cellRenderer({ index, key, parent, style }) {
    const itemRefId = this.props.gridItems[index]?.id
    const item = this.props.gridItems[index]

    if (!item) {
      return null
    }

    // If item has itemId (that is if it's a board item), use that instead of the itemRefId, otherwise (it's a search item) use the itemRefId
    const itemId = item.itemId ? item.itemId : itemRefId

    if (!itemId || !item) return null
    const gridItemProps = {
      key: itemRefId,
      width: this._columnWidth,
      itemRefId,
      itemId,
      item,
      onDelete: this.props.onDelete,
      cancelMultiSelectDrag: this.selectionArea?.cancel,
      isMultiSelectDisabled: this.props.isMultiSelectDisabled,
      gridClassNameId: this.gridClassNameId,
      boardId: this.props.boardId,
      reorderItems: this.reorderItems,
      resetMultiSelectItems: this.props.resetMultiSelectItems,
      index,
      disableGridItemOverlay: this.props.disableGridItemOverlay,
      inFilterMode: this.props.inFilterMode,
    }

    return (
      <CellMeasurer cache={this._cache} index={index} key={key} parent={parent}>
        <div
          className={styles.Cell}
          style={{
            ...style,
            width: this._columnWidth,
          }}
        >
          {this.props.customGridItem ? (
            this.props.customGridItem(gridItemProps)
          ) : (
            <GridItem {...gridItemProps} />
          )}
        </div>
      </CellMeasurer>
    )
  }

  _initCellPositioner() {
    if (typeof this._cellPositioner === 'undefined') {
      const { gutterSize } = this.state
      this._calculateColumnCount()
      this._cellPositioner = createMasonryCellPositioner({
        cellMeasurerCache: this._cache,
        columnCount: this._columnCount,
        columnWidth: this._columnWidth,
        spacer: gutterSize,
      })
    }
  }

  _onResize({ width }) {
    this._width = width
    this.resetMasonryPositionCache()
  }

  _resetCellPositioner() {
    this._calculateColumnCount()
    this._cellPositioner.reset({
      columnCount: this._columnCount,
      columnWidth: this._columnWidth,
      spacer: this.state.gutterSize,
    })
  }

  _setMasonryRef(ref) {
    this._masonry = ref
  }

  render() {
    if (!this.props.gridItems || this.props.gridItems.length === 0) return null
    const { overscanByPixels } = this.state
    const gridClassName = `${this.props.customGridClassname || ''} ${
      this.gridClassNameId
    }`
    return (
      <>
        <GridMain
          id="grid"
          style={{ outline: 'none' }}
          className={gridClassName}
          // TODO: fix
          // eslint-disable-next-line jsx-a11y/tabindex-no-positive
          tabIndex="1"
          onKeyDown={this.handleKeyDown}
          onKeyUp={this.handleKeyUp}
          onClick={this.handleGridClick}
          onMouseLeave={
            () => this.props.updateIsFocusedItemSpoilerVisible(false) // if mouse leaves grid we always hide item spoiler
          }
        >
          <SelectionArea
            onBeforeStart={this.handleBeforeStartDragSelect}
            onStart={this.handleStartDragSelect}
            onMove={this.handleMoveDragSelect}
            onStop={this.handleStopDragSelect}
            boundaries={this.props.scrollElement}
            selectables={`.${this.gridClassNameId} .gridItem`}
            features={{ singleTap: { allow: false }, touch: false }}
            setSelectionArea={(selectionArea) => {
              this.selectionArea = selectionArea
            }}
            selectionAreaClass="selection-area"
            selectionContainerClass="selection-container"
          >
            <WindowScroller
              scrollElement={this.props.scrollElement}
              gridItems={this.props.gridItems} // to make sure react virtualized components gets updated
              overscanByPixels={overscanByPixels}
            >
              {this._renderAutoSizer}
            </WindowScroller>
          </SelectionArea>
        </GridMain>
        <Modal
          isOpen={this.state.isConfirmResetMultiSelectModalVisible}
          close={() => this.setIsConfirmResetMultiSelectModalVisible(false)}
        >
          <Modal.ContentWrapper>
            <Text size="lg" as="h2" color="neutral.0">
              Are you sure you want to deselect the selection?
            </Text>
            <Margin y={16} />
            <div css="display: flex">
              <Button
                variant="primary"
                onClick={() => {
                  this.props.resetMultiSelectItems()
                  this.setIsConfirmResetMultiSelectModalVisible(false)
                }}
              >
                Deselect all
              </Button>
              <Margin x={16} />
              <Button
                onClick={() =>
                  this.setIsConfirmResetMultiSelectModalVisible(false)
                }
              >
                Cancel
              </Button>
            </div>
          </Modal.ContentWrapper>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  focusedItem: state.content.focusedItem ? state.content.focusedItem : {},
  isInMultiSelectMode: isInMultiSelectModeSelector(state),
  multiSelectItemsCount: Object.keys(state.multiSelect.selectedItems).length,
  activeWorkspace: state.content.activeWorkspace
    ? state.content.activeWorkspace
    : {},
})

const mapDispatchToProps = (dispatch) => ({
  setFocusedItem: (item) => {
    dispatch(setFocusedItem(item))
  },
  toggleCommandPalette: () => {
    dispatch(toggleCommandPalette())
  },
  toggleMultiSelectItem: (item) => {
    dispatch(toggleItem({ id: item.id, item }))
  },
  multiSelectItem: (item) => {
    dispatch(selectItem({ id: item.id, item }))
  },
  multiUnselectItem: (item) => {
    dispatch(unselectItem({ id: item.id, item }))
  },
  resetMultiSelectItems: () => {
    dispatch(reset())
  },
  startMultiSelectDownload: () => {
    dispatch(setDownloadStatus('START'))
  },
  startMultiSelectDeleteItems: () => {
    dispatch(setDeleteStatus('START'))
  },
  updateIsFocusedItemSpoilerVisible: (isFocusedItemSpoilerVisible) => {
    dispatch(setIsFocusedItemSpoilerVisible(isFocusedItemSpoilerVisible))
  },
})

const GridWithToastContext = (props) => (
  <ToastContext.Consumer>
    {(toastReport) => (
      <Grid
        toastReportError={toastReport.reportError}
        toastReportSuccess={toastReport.reportSuccess}
        {...props}
      />
    )}
  </ToastContext.Consumer>
)

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(GridWithToastContext)

const FadeIn = styled(motion.div).attrs({
  initial: { opacity: 0, y: -10 },
  animate: { opacity: 1, y: 0 },
  transition: { ease: 'easeOut', duration: 0.3 },
})`
  display: inline-block;
`
