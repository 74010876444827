import { Play as Video } from '@styled-icons/bootstrap/Play'
import React from 'react'
import styled, { css } from 'styled-components'

import { Text } from 'components/common/Text'

interface ItemTypeProps {
  itemType?: string
}

const Gif = () => {
  return <Text size="2xs"> GIF </Text>
}

const GridItemTypeIcon: React.FC<ItemTypeProps> = ({ itemType }) => {
  if (itemType === 'gif') {
    return (
      <StyledWrapper>
        <Gif />
      </StyledWrapper>
    )
  }
  if (itemType === 'video') {
    return (
      <StyledWrapper>
        <Video size={22} />
      </StyledWrapper>
    )
  }
  return null
}

const StyledWrapper = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    position: absolute;
    height: 24px;
    width: 32px;
    font-weight: 600;
    letter-spacing: 1px;
    margin: 8px;
    border-radius: ${theme.borderRadius.default};
    display: grid;
    align-items: center;
    justify-items: center;
    background-color: ${theme.colors.gray.dark[6]};
  `}
`

export default GridItemTypeIcon
