export const getGreetingText = () => {
  const hours = new Date().getHours()

  if (hours >= 6 && hours < 11) {
    return 'Good morning'
  }
  if (hours >= 11 && hours < 15) {
    return 'Good day'
  }
  if (hours >= 15 && hours < 18) {
    return 'Good afternoon'
  }
  if (hours >= 18 && hours < 22) {
    return 'Good evening'
  }
  if (hours >= 22 && hours < 6) {
    return 'Good night'
  }
  return 'Hello'
}
