import { Link } from 'react-router-dom'

import { withRouter } from 'helpers/withRouter'

import { HeaderBlockInner, HeaderBlockOuter } from './styles'
import { IHeaderBlock } from './types'

export const HeaderBlock = withRouter((props: IHeaderBlock) => {
  const { isHidden } = props
  return isHidden ? null : (
    <HeaderBlockOuter className={props.className} right={props.right}>
      {props.link && !props.useAnchorLink ? (
        <Link
          to={`${props.link}${
            props.withUrlParams === false ? '' : props.location.search
          }`}
        >
          <HeaderBlockInner style={props.style}>
            {props.children}
          </HeaderBlockInner>
        </Link>
      ) : null}
      {props.link && props.useAnchorLink ? (
        <HeaderBlockInner style={props.style}>
          <a href={props.link}>{props.children}</a>
        </HeaderBlockInner>
      ) : null}
      {!props.link ? (
        <HeaderBlockInner style={props.style}>
          {props.children}
        </HeaderBlockInner>
      ) : null}
    </HeaderBlockOuter>
  )
})
