import React, { Fragment } from 'react'
import styled, { css } from 'styled-components'

import { Margin } from 'components/common/Margin'
import { Tooltip } from 'components/common/Tooltip/Tooltip'
import { ItemReactionsQuery } from 'generated/graphql'

export const allReactionsEmojis = ['👍', '👎', '😍', '👏', '🤔']

interface ItemReactionsBoxProps {
  reactions?: ItemReactionsQuery['itemReactions']
  handleToggleReaction: (reaction: string) => void
  isTransparent?: boolean
  className?: string
  hideUnsetReactions?: boolean
  withHideUnsetReactionsToggle?: boolean
  isSmall?: boolean
  onToggleHideUnsetReactions: () => void
}

const ItemReactionsBox: React.FC<ItemReactionsBoxProps> = ({
  reactions,
  handleToggleReaction,
  hideUnsetReactions,
  isTransparent,
  className,
  isSmall,
  onToggleHideUnsetReactions,
  withHideUnsetReactionsToggle,
}) => {
  const hasAtLeastOneOfEachReactions = allReactionsEmojis.every(
    (reactionEmoji) =>
      Boolean(reactions?.find(({ reaction }) => reaction === reactionEmoji))
  )

  return (
    <ReactionsWrapper className={className} isTransparent={isTransparent}>
      {allReactionsEmojis.map((reactionEmoji, index) => {
        const reactionGroup = reactions?.find(
          ({ reaction }) => reaction === reactionEmoji
        )
        const reactorsDisplayNames = [
          ...new Set(
            reactionGroup?.itemReactions.map(
              (i) => i.author?.displayName || 'Guest'
            )
          ),
        ].join(', ')

        if (hideUnsetReactions && !reactionGroup) {
          return null
        }

        const hasReacted = reactionGroup?.hasReacted ?? false
        const hasReactionCount = reactionGroup?.count || 0
        return (
          <Fragment key={reactionEmoji}>
            <Tooltip
              delay={[200, 0]}
              title={reactorsDisplayNames}
              position="top"
              isDisabled={!reactorsDisplayNames}
            >
              <EmojiButton
                isSelected={hasReacted}
                isSmall={isSmall}
                onClick={() => handleToggleReaction(reactionEmoji)}
              >
                <EmojiWrapper isSmall={isSmall}>
                  {reactionEmoji}
                  {hasReactionCount > 0 && (
                    <StyledReactionCount isSelected={hasReacted}>
                      {hasReactionCount}
                    </StyledReactionCount>
                  )}
                </EmojiWrapper>
              </EmojiButton>
            </Tooltip>
            {allReactionsEmojis.length - 1 !== index && <Margin x={6} />}
          </Fragment>
        )
      })}
      {withHideUnsetReactionsToggle &&
        hideUnsetReactions &&
        !hasAtLeastOneOfEachReactions && (
          <ToggleAllReactionsIcon onClick={onToggleHideUnsetReactions}>
            ☺
          </ToggleAllReactionsIcon>
        )}
    </ReactionsWrapper>
  )
}

const ReactionsWrapper = styled.div<{ isTransparent?: boolean }>`
  ${({ theme, isTransparent }) => css`
    background: ${isTransparent ? 'transparent' : theme.colors.background[4]};
    padding: 6px;
    border-radius: ${theme.borderRadius.lg};
    display: flex;
    align-items: center;
  `}
`

interface EmojiButtonProps {
  isSelected: boolean
  isSmall?: boolean
}

export const EmojiButton = styled.button<EmojiButtonProps>`
  ${({ theme, isSelected, isSmall }) => css`
    border: none;
    padding: 0px 6px;
    height: ${isSmall ? '24px' : '28px'};
    border-radius: ${theme.borderRadius.lg};
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background: inherit;
    transition: 0.2s background ease;
    ${isSelected &&
    css`
      background: ${theme.colors.accent[2]};
    `}
    &:hover {
      background: ${isSelected
        ? theme.colors.accent[2]
        : theme.colors.text.neutral[5]};
    }
  `}
`

const ToggleAllReactionsIcon = styled.div`
  ${({ theme }) => css`
    border: none;
    margin: 0 3px;
    font-size: 21px;
    height: 23px;
    width: 23px;
    border-radius: ${theme.borderRadius.full};
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background: inherit;
    transition: 0.2s background ease;
    color: ${theme.colors.text.neutral[4]};
    &:hover {
      color: ${theme.colors.text.neutral[1]};
    }
  `}
`

const EmojiWrapper = styled.div<{ isSmall?: boolean }>`
  ${({ isSmall }) => css`
    font-size: ${isSmall ? '13px' : '15px'};
    display: flex;
    align-items: center;
  `}
`

const StyledReactionCount = styled.div<{ isSelected: boolean }>`
  ${({ theme, isSelected }) => css`
    color: ${isSelected
      ? theme.colors.gray.light[1]
      : theme.colors.text.neutral[1]};
    font-size: ${theme.fontSizes.xs};
    margin-left: 4px;
  `}
`

export default ItemReactionsBox
