import React from 'react'
import styled, { css } from 'styled-components'

import FlexGrid from 'components/common/FlexGrid'
import { Text } from 'components/common/Text'

const SearchHeading = ({ children }: { children: React.ReactNode }) => (
  <Text as="h2" size="xl" color="neutral.0" style={{ padding: '16px 0' }} bold>
    {children}
  </Text>
)

const SearchInfoCard = styled(FlexGrid.Card)`
  ${({ theme }) => css`
    padding: 16px;
    background: ${theme.colors.background[4]};
    border-radius: ${theme.borderRadius.lg};
    box-sizing: border-box;
    align-items: center;
    &:hover {
      cursor: pointer;
      border: ${theme.border.thin} ${theme.colors.accent[1]};
      padding: 15px;
      background: ${theme.colors.background[3]};
    }
  `}
`

const Main = styled.div<{ full: boolean; siderSplitValue: number }>(
  ({ theme, full, siderSplitValue }) => ({
    height: full ? '100%' : 60,
    position: 'fixed',
    width: `calc(100vw - ${siderSplitValue}px)`,
    zIndex: 10,
    background: theme.colors.background[2],
    '@media (max-width: 700px)': {
      maxWidth: 'calc(100vw - 60px)',
    },
  })
)

const SearchBar = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: inline-flex;
    align-items: center;
    height: 100%;
    padding-left: 4px;
    background: ${theme.colors.background[5]};
    overflow: hidden;
    width: 100%;
    justify-content: start;
    border-radius: ${theme.borderRadius.default};
    cursor: pointer;
  `}
`

const SearchBarContainer = styled.div`
  ${({ theme }) => css`
    padding: 8px;
    height: 60px;
    z-index: 2;
    border-bottom: ${theme.border.thin} ${theme.colors.discreet};
    display: flex;
    align-items: center;
  `}
`

const HalfCircleButton = styled.button(({ theme }) => ({
  zIndex: 5,
  position: 'absolute',
  left: '50%',
  top: '59px',
  width: 56,
  height: 28,
  border: `${theme.border.thin} ${theme.colors.discreet}`,
  background: theme.colors.background[3],
  marginLeft: -28,
  borderRadius: '0 0 32px 32px',
  cursor: 'pointer',
  outline: 'none',
  color: theme.colors.gray.medium[0],
  ':hover': {
    background: theme.colors.background[5],
  },
}))

const SearchInput = styled.input<{ active: boolean }>(({ theme, active }) => ({
  flex: '1 1 0',
  fontSize: theme.fontSizes.sm,
  border: 'none',
  color: active ? theme.colors.text.neutral[0] : 'inherit',
  outline: 'none',
  textAlign: 'left',
  background: 'transparent',
  '::placeholder': {
    color: theme.colors.gray.light[0],
  },
  '@media (min-width: 769px)': {
    marginLeft: 8,
  },
}))

export {
  SearchInfoCard,
  SearchHeading,
  Main,
  SearchBar,
  SearchBarContainer,
  SearchInput,
  HalfCircleButton,
}
