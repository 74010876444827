import { UploadCloud as UploadIcon } from '@styled-icons/feather/UploadCloud'
import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

import Button from 'components/common/Button'
import { Margin } from 'components/common/Margin'

interface VimeoVideoPreviewProps {
  vimeoVideoId?: string
  youtubeVideoId?: string
  vimeoCustomLink?: string
  onExtract: (link?: string, linkIsAlreadyValidated?: boolean) => void
}

const VideoLinkPreview = (props: VimeoVideoPreviewProps) => {
  const [videoPreviewInfo, setVideoPreviewInfo] = useState<any>(null)

  useEffect(() => {
    if (
      !props.vimeoVideoId &&
      !props.youtubeVideoId &&
      !props.vimeoCustomLink
    ) {
      return setVideoPreviewInfo(null)
    }
    fetchVideoInfo({
      vimeoId: props.vimeoVideoId,
      youtubeId: props.youtubeVideoId,
      vimeoCustomLink: props.vimeoCustomLink,
    })
  }, [props.vimeoVideoId, props.youtubeVideoId, props.vimeoCustomLink])

  const fetchVideoInfo = async ({
    vimeoId,
    youtubeId,
    vimeoCustomLink,
  }: {
    vimeoId?: string
    youtubeId?: string
    vimeoCustomLink?: string
  }) => {
    const getUrl = () => {
      if (vimeoId) {
        return `https://vimeo.com/api/oembed.json?url=https://vimeo.com/${vimeoId}`
      }
      if (vimeoCustomLink) {
        return `https://vimeo.com/api/oembed.json?url=${vimeoCustomLink}`
      }
      return `https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v=${youtubeId}&format=json`
    }
    try {
      const url = getUrl()
      const response = await fetch(url)
      if (!response.ok) {
        return setVideoPreviewInfo(null)
      }
      const parsed = await response.json()
      if (parsed.domain_status_code === 403) {
        throw new Error('Could not load this video, try another!')
      }
      setVideoPreviewInfo(parsed)
    } catch (e) {
      setVideoPreviewInfo(null)
    }
  }

  const handleStartImportClick = () => {
    // We create a proper vimeo link from custom vimeo link (link without vimeo id inside) based on the oembed response info
    const newUrlForCustomVimeoLink = props.vimeoCustomLink
      ? `https://vimeo.com/${videoPreviewInfo.video_id}`
      : undefined
    props.onExtract(newUrlForCustomVimeoLink, Boolean(newUrlForCustomVimeoLink))
  }

  if (!videoPreviewInfo) {
    return <motion.div layout css="height:130px" />
  }

  const height = videoPreviewInfo.thumbnail_height
  const width = videoPreviewInfo.thumbnail_width
  const aspectRatio = width / height

  return (
    <motion.div
      initial={{ opacity: 0, y: -10 }}
      layout
      animate={{ opacity: 1, y: 0 }}
    >
      <div css="display:flex">
        <Thumb
          style={{
            backgroundImage: `url(${videoPreviewInfo.thumbnail_url})`,
            width: 130 * aspectRatio,
            height: 130,
          }}
        />

        <Detail>
          <Info>
            <div>
              <VideoHeadline
                target="_blank"
                rel="noreferrer"
                href={`https://vimeo.com/${props.vimeoVideoId}`}
              >
                {videoPreviewInfo.title}
              </VideoHeadline>
              <Margin y={8} />
              From{' '}
              <VideoCreator
                target="_blank"
                rel="noreferrer"
                href={videoPreviewInfo.author_url}
              >
                {videoPreviewInfo.author_name}
              </VideoCreator>
            </div>
            {videoPreviewInfo.duration && (
              <DurationContainer>
                {`${`00${Math.floor(videoPreviewInfo.duration / 60)}`.slice(
                  -2
                )}:${`00${videoPreviewInfo.duration % 60}`.slice(-2)}`}
              </DurationContainer>
            )}
          </Info>

          <Button
            Icon={UploadIcon}
            onClick={handleStartImportClick}
            variant="primary"
            fullWidth
          >
            Start import
          </Button>
        </Detail>
      </div>
    </motion.div>
  )
}

const Thumb = styled.div`
  ${({ theme }) => css`
    border-radius: ${theme.borderRadius.default};
    overflow: hidden;
    background-size: cover;
    flex-shrink: 0;
  `}
`

const Info = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.xs};
    flex: 1;
    line-height: 14px;
    display: flex;
    align-items: self-start;
    justify-content: space-between;
    height: 100%;
    width: 100%;
  `}
`

const Detail = styled.div`
  padding-left: 16px;
  display: flex;
  width: 100%;
  align-items: flex-start;
  flex-direction: column;
`

const DurationContainer = styled.div`
  ${({ theme }) => css`
    padding: 2px 8px;
    border-radius: ${theme.borderRadius.sm};
    font-size: ${theme.fontSizes.xs};
    background-color: ${theme.colors.background[5]};
    color: ${theme.colors.text.neutral[2]};
  `}
`

const VideoHeadline = styled.a`
  ${({ theme }) => css`
    color: ${theme.colors.text.neutral[1]};
    line-height: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    :hover {
      text-decoration: underline;
    }
  `}
`

const VideoCreator = styled.a`
  ${({ theme }) => css`
    color: ${theme.colors.text.neutral[3]};
    :hover {
      text-decoration: underline;
    }
  `}
`

export default VideoLinkPreview
