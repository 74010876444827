import { Command } from '@styled-icons/feather/Command'
import { motion } from 'framer-motion'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTheme } from 'styled-components'

import { withRouter } from 'helpers/withRouter'

import {
  setSearchActive,
  setSearchInput,
  toggleCommandPalette,
} from '../../store/ui'
import {
  Box,
  Heading,
  IconContainer,
  Input,
  Mask,
  Menu,
  MenuItemInner,
  Positioner,
  ShortcutIndicator,
  ShortcutIndicatorContainer,
} from './CommandPaletteStyles'
import { generateCommands } from './Commands'

const MenuItem = (props) => {
  const renderModifier = (modifier) => {
    const modifierDisplays = {
      cmd: '⌘',
      alt: '⌥',
    }
    if (modifierDisplays[modifier]) return modifierDisplays[modifier]
    return modifier
  }

  return (
    <MenuItemInner
      onMouseEnter={props.onMouseEnter}
      active={props.active}
      style={props.style}
      {...props}
    >
      <IconContainer>{props.icon}</IconContainer>
      <Heading>{props.text}</Heading>
      {props.shortcut && (
        <ShortcutIndicatorContainer>
          {props.shortcut.modifier && (
            <ShortcutIndicator>
              {renderModifier(props.shortcut.modifier)}
            </ShortcutIndicator>
          )}
          <ShortcutIndicator>{props.shortcut.key}</ShortcutIndicator>
        </ShortcutIndicatorContainer>
      )}
    </MenuItemInner>
  )
}

class CommandPalette extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      inputText: '',
      activeMenuItem: 0,
      commands: generateCommands({ input: '', ...props }),
    }
    this.inputRef = React.createRef()
    this.menuItemRefs = []
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown, true)
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown, true)
  }

  closePalette = (e, actionReturn) => {
    let newFocusElement = actionReturn
    if (!actionReturn || !actionReturn.focus) {
      newFocusElement = document.getElementById('grid')
    }

    if (newFocusElement) newFocusElement.focus()

    e.preventDefault()
    e.stopPropagation()

    this.props.toggleCommandPalette()
  }

  handleKeyDown = (e) => {
    e.stopPropagation()
    // CMD key commands
    if (e.metaKey) {
      if (e.keyCode === 75) {
        // 'k'
        this.closePalette(e)
      }
    }
    if (e.key === 'ArrowDown') {
      this.setActiveMenuItem(this.limitIndex(this.state.activeMenuItem + 1))
      e.stopPropagation()
    }
    if (e.key === 'ArrowUp') {
      this.setActiveMenuItem(this.limitIndex(this.state.activeMenuItem - 1))
      e.stopPropagation()
    }
    if (e.key === 'Enter') {
      this.performItemAction(e)
    }
    if (e.keyCode === 27) this.closePalette(e)
  }

  performItemAction = (e) => {
    const menuItem = this.state.commands[this.state.activeMenuItem]
    const actionReturn = menuItem.action(this.props)
    this.closePalette(e, actionReturn)
  }

  setActiveMenuItem = (index) => {
    const index_ = typeof index !== 'number' ? 0 : index
    // this.menuItemRefs[index].scrollIntoView({behavior: 'smooth', block: 'center'})
    const el = document.getElementById(`commandPaletteMenuItem-${index_}`)
    el.scrollIntoView({ behavior: 'smooth', block: 'center' })
    this.setState({ activeMenuItem: index_ })
  }

  limitIndex = (index, maxLength) => {
    const maxLength_ =
      maxLength === undefined ? this.state.commands.length : maxLength
    if (maxLength_ === 0) return 0
    if (index % maxLength_ < 0) return maxLength_ - 1
    return index % maxLength_
  }

  handleInputChange = (e) => {
    const inputText = e.target.value
    this.setState((prev) => ({
      inputText,
      commands: generateCommands({ input: inputText, ...this.props }),
      activeMenuItem: this.limitIndex(
        prev.activeMenuItem,
        prev.commands.length
      ),
    }))
  }

  render() {
    console.log(this.state.activeMenuItem)
    return (
      <Mask onClick={this.props.toggleCommandPalette}>
        <Positioner>
          <motion.div initial={{ y: 50 }} animate={{ y: 0 }}>
            <Box>
              <MenuItem
                style={{
                  borderBottom: `1px solid ${this.props.theme.colors.background[5]}`,
                }}
                text="Kive Command"
                icon={<Command />}
              />
              <Input
                ref={this.inputRef}
                autoFocus
                value={this.state.inputText}
                onChange={this.handleInputChange}
              />
              <Menu>
                {this.state.commands.map((command, index) => (
                  <MenuItem
                    id={`commandPaletteMenuItem-${index}`}
                    key={command.text}
                    onMouseEnter={() => this.setActiveMenuItem(index)}
                    onClick={this.performItemAction}
                    active={index === this.state.activeMenuItem}
                    text={command.text}
                    icon={command.icon}
                    shortcut={command.shortcut}
                  />
                ))}
              </Menu>
            </Box>
          </motion.div>
        </Positioner>
      </Mask>
    )
  }
}

const mapStateToProps = (state) => ({
  focusedItem: state.content.focusedItem,
  searchInput: state.ui.searchInput,
})

const mapDispatchToProps = (dispatch) => ({
  toggleCommandPalette: () => {
    dispatch(toggleCommandPalette())
  },
  setSearchInput: (text) => {
    dispatch(setSearchInput(text))
  },
  setSearchActive: (newStatus) => {
    dispatch(setSearchActive(newStatus))
  },
})

export default compose(
  withTheme,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CommandPalette)
