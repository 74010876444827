import styled, { css } from 'styled-components'

import Flex from 'components/common/Flex'
import { Spacer } from 'components/common/Spacer'
import { Text } from 'components/common/Text'

const indexToHeightMap: Record<number, Record<number, number>> = {
  3: {
    0: 35,
    1: 35,
    2: 65,
  },
  2: {
    0: 67.5 + 4, // 4px comes from flexGap / 2
    1: 67.5 + 4, // 4px comes from flexGap / 2
  },
  1: {
    0: 135 + 16, // 16px comes from flexGap * 2
  },
}

type BoardPreviewCardProps = {
  title: string
  projectTitle: string
  count: number
  thumbUrls: string[]
  extra?: React.ReactNode
}

export const BoardPreviewCard = (props: BoardPreviewCardProps) => {
  const thumbUrls = props.thumbUrls.slice(0, 3)
  return (
    <StyledBoardPreviewWrapper p={1} flexDirection="column">
      <Flex flexDirection="column" flexGap={1}>
        {thumbUrls.length === 0 && (
          <StyledImage
            style={{
              height: 135 + 16,
              background: '#1a1a1a',
            }}
          />
        )}
        {thumbUrls.map((thumbUrl, i) => {
          return (
            <StyledImage
              key={thumbUrl}
              style={{
                backgroundImage: `url(${thumbUrl})`,
                height: indexToHeightMap[thumbUrls.length][i],
              }}
            />
          )
        })}
      </Flex>
      <Spacer factor={2} />
      <Flex p={1}>
        <Flex flexDirection="column" css="overflow: hidden">
          <Text size="sm" color="neutral.0" ellipsis>
            {props.title}
          </Text>
          <Text size="sm" color="neutral.3" ellipsis>
            {props.projectTitle}
          </Text>
        </Flex>
        <Spacer axis="x" factor={2} />
        <StyledCount p={1} justifyContent="center" alignItems="center">
          {props.count}
        </StyledCount>
      </Flex>
      {props.extra && <Flex>{props.extra}</Flex>}
    </StyledBoardPreviewWrapper>
  )
}

const StyledBoardPreviewWrapper = styled(Flex)(
  ({ theme }) => css`
    border-radius: ${theme.borderRadius.lg};
    border: ${theme.border.thin} ${theme.colors.background[5]};
    background: ${theme.colors.background[3]};
    transition: 0.2s background ease;
    width: 220px;
    :hover {
      background: ${theme.colors.background[4]};
    }
  `
)

const StyledCount = styled(Flex)(
  ({ theme }) => css`
    border-radius: ${theme.borderRadius.lg};
    background: ${theme.colors.background[5]};
    color: ${theme.colors.text.neutral[0]};
    height: 24px;
    margin-left: auto;
  `
)

const StyledImage = styled.div(
  ({ theme }) => css`
    border-radius: ${theme.borderRadius.default};
    background-size: cover;
    background-position: center;
  `
)
