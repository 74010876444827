import { DBWorkspace } from 'types/db'

import useWorkspaceUserRole, { WorkspaceRoles } from './useWorkspaceUserRole'

type WorkspacePermissions =
  | 'UPLOAD'
  | 'SAVE_TO_BOARD'
  | 'DELETE_ITEM'
  | 'ADD_ITEM_TAG'
  | 'DELETE_ITEM_TAG'
  | 'ADD_ITEM_REACTION'
  | 'ADD_PROJECT'
  | 'ADD_WORKSPACE_MEMBER'
  | 'EDIT_WORKSPACE_MEMBER'
  | 'DISPLAY_WORKSPACE_GUESTS'
  | 'VIEW_WORKSPACE' // right now only used in boards to determine if user can go to a "private board" (project view)
  | 'CREATE_SOCIAL_ASSETS'
  | 'EDIT_ITEM'

const workspacePermissions: Record<WorkspaceRoles, WorkspacePermissions[]> = {
  ADMIN: [
    'UPLOAD',
    'SAVE_TO_BOARD',
    'DELETE_ITEM',
    'ADD_ITEM_TAG',
    'DELETE_ITEM_TAG',
    'ADD_PROJECT',
    'ADD_WORKSPACE_MEMBER',
    'ADD_ITEM_REACTION',
    'EDIT_WORKSPACE_MEMBER',
    'DISPLAY_WORKSPACE_GUESTS',
    'VIEW_WORKSPACE',
    'CREATE_SOCIAL_ASSETS',
    'EDIT_ITEM',
  ],
  MEMBER: [
    'UPLOAD',
    'SAVE_TO_BOARD',
    'DELETE_ITEM',
    'ADD_ITEM_TAG',
    'DELETE_ITEM_TAG',
    'ADD_PROJECT',
    'ADD_ITEM_REACTION',
    'VIEW_WORKSPACE',
    'CREATE_SOCIAL_ASSETS',
    'EDIT_ITEM',
  ],
  GUEST: ['VIEW_WORKSPACE'],
}

const useWorkspacePermissions = (
  askedPermissions: WorkspacePermissions[],
  optionalWorkspace?: DBWorkspace
): Record<WorkspacePermissions, boolean | undefined> => {
  const userRole = useWorkspaceUserRole(optionalWorkspace)
  if (!userRole) return {} as Record<WorkspacePermissions, boolean | undefined>
  const currentUserPermissions = workspacePermissions[userRole] ?? []
  return askedPermissions.reduce((accPermissions, askedPermission) => {
    return {
      ...accPermissions,
      [askedPermission]: currentUserPermissions.includes(askedPermission),
    }
  }, {} as Record<WorkspacePermissions, boolean | undefined>)
}

export default useWorkspacePermissions
