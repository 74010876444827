import * as queryString from 'query-string'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useFirestore } from 'react-redux-firebase'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import Loading from 'components/Loading'
import { getNextItemFromItem } from 'components/grid/getNextItemFromItem'
import ItemReactions from 'components/item/itemReactions/ItemReactions'
import { allReactionsEmojis } from 'components/item/itemReactions/ItemReactionsBox'
import { saveToBoard } from 'components/itemsavetoboard/saveToBoardHandlers'
import { WorkspaceItemGridFieldsFragment } from 'generated/graphql'
import getImageFinishedLoading from 'helpers/getImageFinishedLoading'
import { getItemMediaType } from 'helpers/getItemMediaType'
import { getItemMediaUrl } from 'helpers/getItemMediaUrl'
import { getThumbUrl } from 'helpers/getThumbs'
import { reportError } from 'helpers/logging'
import {
  trackBoardItemMoved,
  trackGridItemClicked,
} from 'helpers/tracking/tracking'
import useBoardPermissionsById from 'hooks/useBoardPermissionById'
import useIsMobile from 'hooks/useIsMobile'
import useWorkspacePermissions from 'hooks/useWorkspacePermissions'
import { RootState } from 'store'
import { ItemRefWithId } from 'types/custom'
import { TItem } from 'types/typesense'

import { setFocusedItem } from '../../../store/content/actions'
import GridItemOverlay, { GridItemOverlayProps } from './GridItemOverlay'
import { GridItemReactionToggleOverlay } from './GridItemReactionToggleOverlay'
import { ItemCard } from './GridItemStyles'
import GridItemTypeIcon from './GridItemTypeIcon'
import GridItemTypes from './GridItemTypes'
import { ItemSpoiler } from './ItemSpoiler'
import MultiSelectOverlay from './MultiSelectOverlay'
import { copyItemRefs, moveItemRefs } from './gridItemOperations'

const getItemSpace = (isBoardItem: boolean, isWebItem: boolean) => {
  if (isBoardItem) return 'projects'
  if (isWebItem) return 'web'
  return 'uploads'
}

const getItemHasReaction = (item: Item) => {
  if (!('reactionCounters' in item)) {
    return false // we don't store reaction in typesense atm
  }
  return Object.entries(item.reactionCounters || {}).some(
    ([emojiIcon, value]) => {
      if (allReactionsEmojis.includes(emojiIcon) && value > 0) {
        return true
      }
      return false
    }
  )
}

const REACTIONS_HEIGHT = 28

export type Item = ItemRefWithId | TItem | WorkspaceItemGridFieldsFragment

export interface GridItemProps {
  item: Item
  index: number
  itemRefId: string
  width?: number
  height?: number
  onDelete?: (itemId: string) => void
  isMultiSelectDisabled?: boolean
  customOverlay?: (props: GridItemOverlayProps) => any
  gridClassNameId: string
  cancelMultiSelectDrag?: () => void
  boardId?: string
  reorderItems?: ({
    toPosition,
    fromPosition,
    item,
  }: {
    toPosition: number
    fromPosition: number
    item: ItemRefWithId
  }) => void
  disableGridItemOverlay?: boolean
  resetMultiSelectItems?: () => void
  inFilterMode?: boolean
  onClick?: () => void
  isDiscoverItem?: boolean
}

const getIsGifOrVideo = (item: Item) => {
  if ('__typename' in item) {
    return ['VIDEO', 'GIF'].includes(item.mediaType)
  }
  return (item.thumbs?.length ?? 0) > 1
}

const GridItem: React.FC<GridItemProps> = (props) => {
  const isGifOrVideo = getIsGifOrVideo(props.item)

  const dragDropRef = useRef<HTMLDivElement>(null)
  const firestore = useFirestore()
  const wrapperRef = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch()
  const location = useLocation()
  const store = useStore()
  const isMobile = useIsMobile()

  const [shouldShowAllReactions, setShouldShowAllReactions] = useState(false)
  const [originalThumbTypeHasLoaded, setOriginalThumbTypeHasLoaded] =
    useState(false)

  const itemHasReactions = getItemHasReaction(props.item)

  const shouldShowReactions =
    (props.boardId && shouldShowAllReactions) || itemHasReactions

  const userAllowedTo = useBoardPermissionsById(
    'boardId' in props.item ? props.item.boardId : '',
    ['EDIT_BOARD']
  )
  const { UPLOAD: userAllowedToUpload } = useWorkspacePermissions(['UPLOAD'])

  const [hoverPosition, setHoverPosition] = useState<
    'LEFT' | 'TOP' | 'RIGHT' | 'BOTTOM' | ''
  >('')
  const activeWorkspace = useSelector(
    (state: RootState) => state.content.activeWorkspace
  )
  const activeWorkspaceId = activeWorkspace?.id

  const hasFocus = useSelector(
    (state: RootState) => state.content.focusedItem?.id === props.itemRefId
  )

  const isFocusedItemSpoilerVisible = useSelector(
    (state: RootState) => state.content.isFocusedItemSpoilerVisible
  )

  const shouldShowSpoiler = hasFocus && isFocusedItemSpoilerVisible

  const isBoardItem = 'boardId' in props.item

  const itemMediaType = getItemMediaType(props.item)

  // largeUrl will be undefined for typesense gif items
  const largeUrl = getItemMediaUrl({
    item: props.item,
    variant: 'large',
    mediaType: itemMediaType,
  })

  const [, dragRef, dragPreview] = useDrag({
    type: GridItemTypes.BOX,
    item: props.item,
    canDrag:
      !props.inFilterMode &&
      (isBoardItem ? userAllowedTo.EDIT_BOARD : userAllowedToUpload),
    end(item, monitor) {
      props.cancelMultiSelectDrag?.() // we cancel multi-select if we start dragging a grid item
      const dropResult = monitor.getDropResult() as {
        boardId?: string
        projectId?: string
        reorder?: true
        toPosition?: number | null
        fromPosition?: number
        dropEffect: 'move' | 'copy'
      }
      if (!dropResult || !item) return

      // functionlity only for board items (itemRefs)
      if (dropResult.reorder) {
        const boardItem = item as ItemRefWithId
        const fromPosition = boardItem.position ?? props.index
        const toPosition = dropResult.toPosition
        if (fromPosition === toPosition) return

        // toPosition could be null if we have problems figuring out the next item to the right/down
        if (toPosition === null || toPosition === undefined) return

        return props.reorderItems?.({
          toPosition,
          fromPosition,
          item: boardItem,
        })
      }

      // if item is not a board item (itemRef) - probably a searchableItem or typesense item
      if (!('boardId' in item)) {
        // in case the dropped item is a typesense item or searchable item (from search view in this case)
        // we filter away board items
        const multiSelectedTypesenseItemsWithoutDropItem = Object.values(
          (store.getState() as RootState).multiSelect.selectedItems
        ).filter(
          (multiSelectedItem) =>
            multiSelectedItem.id !== item.id &&
            !('boardId' in multiSelectedItem)
        ) as (WorkspaceItemGridFieldsFragment | TItem)[]

        return saveToBoard(
          multiSelectedTypesenseItemsWithoutDropItem.length > 0
            ? [
                ...multiSelectedTypesenseItemsWithoutDropItem.map(
                  ({ id }) => id
                ),
                item.id,
              ]
            : [item.id],
          dropResult.boardId!,
          activeWorkspaceId!
        )
      }

      // we only support moving/copying itemRefs/boardItems
      // (multiselected items could potentially be Typesense and searchable items)
      const multiSelectedBoardItemsWithoutDropItem = Object.values(
        (store.getState() as RootState).multiSelect.selectedItems
      ).filter(
        (multiSelectedItem) =>
          multiSelectedItem.id !== item.id && 'boardId' in multiSelectedItem
      ) as ItemRefWithId[]

      if (dropResult.dropEffect === 'move') {
        trackBoardItemMoved({
          itemRef: props.itemRefId,
          itemId: props.item.id,
          toBoardId: dropResult.boardId,
          fromBoardId: item.boardId,
          projectId: dropResult.projectId,
          workspaceId: activeWorkspaceId,
        })
        moveItemRefs(
          multiSelectedBoardItemsWithoutDropItem.length > 0
            ? [...multiSelectedBoardItemsWithoutDropItem, item]
            : [item],
          dropResult.boardId!,
          dropResult.projectId!,
          firestore
        ).then(() => props.resetMultiSelectItems?.())
      }
      if (dropResult.dropEffect === 'copy') {
        return copyItemRefs(
          multiSelectedBoardItemsWithoutDropItem.length > 0
            ? [...multiSelectedBoardItemsWithoutDropItem, item]
            : [item],
          dropResult.boardId!,
          firestore
        )
      }
    },
  })

  const boardItem = props.item as ItemRefWithId
  const currentItemPosition = boardItem.position ?? props.index

  const getNextPosition = () => {
    // We assume that if the hover position is left or top we return the item position that is being hovered
    if (['LEFT', 'TOP'].includes(hoverPosition)) {
      return currentItemPosition
    }

    const positionToDirectionMap = {
      RIGHT: 'right',
      BOTTOM: 'down',
    } as const

    // if the hover position is right or bottom we try to find the next item in that direction
    const nextItemElement = getNextItemFromItem({
      fromItemId: props.item.id,
      direction: positionToDirectionMap[hoverPosition as 'RIGHT' | 'BOTTOM'],
      gridClassName: props.gridClassNameId,
    })

    if (!nextItemElement) {
      reportError(
        new Error(
          'Could not figure out the next item when doing custom positioning in grid'
        )
      )
      return null
    }
    return Number(nextItemElement.getAttribute('data-element-position'))
  }

  // functionlity only for boardItems (itemRefs)
  const [{ isOver, draggingItemId }, dropRef] = useDrop({
    accept: GridItemTypes.BOX,
    canDrop(item: ItemRefWithId) {
      // we make sure we don't drop at the same item and make sure it is a board item
      return isBoardItem && item.id !== props.item.id
    },
    drop() {
      return {
        toPosition: getNextPosition(),
        reorder: true,
      }
    },
    hover(item: ItemRefWithId, monitor) {
      if (item.id === props.item.id) return

      const hoverBoundingRect = dragDropRef.current?.getBoundingClientRect()
      if (!hoverBoundingRect) return
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as any).y - hoverBoundingRect.top
      const hoverClientX = (clientOffset as any).x - hoverBoundingRect.left

      const positionAboveMiddle = hoverClientY < hoverMiddleY
      // const positionBelowMiddle = hoverClientY > hoverMiddleY
      const positionLeftMiddle = hoverClientX < hoverMiddleX
      const positionRightMiddle = hoverClientX > hoverMiddleX

      if (positionLeftMiddle) {
        if (positionAboveMiddle) {
          if (hoverClientY < hoverClientX) {
            setHoverPosition('TOP')
          } else {
            setHoverPosition('LEFT')
          }
        } else if (hoverClientX < hoverBoundingRect.height - hoverClientY) {
          setHoverPosition('LEFT')
        } else {
          setHoverPosition('BOTTOM')
        }
      }
      if (positionRightMiddle) {
        if (positionAboveMiddle) {
          if (hoverClientY < hoverBoundingRect.width - hoverClientX) {
            setHoverPosition('TOP')
          } else {
            setHoverPosition('RIGHT')
          }
        } else if (
          hoverBoundingRect.height - hoverClientY <
          hoverBoundingRect.width - hoverClientX
        ) {
          setHoverPosition('BOTTOM')
        } else {
          setHoverPosition('RIGHT')
        }
      }
    },
    collect(monitor) {
      const item: ItemRefWithId = monitor.getItem()
      return {
        isOver: isBoardItem ? monitor.isOver() : false,
        draggingItemId: isBoardItem ? item?.id : '',
      }
    },
  })

  useEffect(() => {
    if (hasFocus && largeUrl) {
      new Image().src = largeUrl
    }
  }, [hasFocus, largeUrl])

  useEffect(() => {
    const gifThumb = getThumbUrl(props.item, 'gif')
    if (hasFocus && gifThumb && !originalThumbTypeHasLoaded) {
      getImageFinishedLoading(gifThumb).then((hasLoaded) =>
        setOriginalThumbTypeHasLoaded(hasLoaded)
      )
    }
  }, [hasFocus])

  const handleSetFocusedItem = (item: Item) => {
    dispatch(setFocusedItem(item))
  }

  const handleMouseEnter = () => {
    handleSetFocusedItem({ ...props.item, id: props.itemRefId })
    const gridContainer = document.querySelector(
      `.${props.gridClassNameId}`
    ) as HTMLElement | null

    if (!gridContainer) return
    if (gridContainer !== document.activeElement) {
      gridContainer.focus({ preventScroll: true })
    }
  }

  useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true }) // we use a custom drag layer component for drag preview
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // There's a memory leak in the react-dnd library - we can prevent that by manually setting the refs to null
  useLayoutEffect(() => {
    dragRef(dropRef(dragDropRef))
    return () => {
      dragRef(null)
      dropRef(null)
    }
  }, [dragRef, dropRef, dragDropRef])

  const parsedUrlQueryParams = queryString.parse(location.search, {
    arrayFormat: 'comma',
  })

  const getWidthAndHeight = () => {
    if (props.width) {
      const originalImgHeight = Math.floor(props.width / props.item.aspectRatio)
      const imgHeight = shouldShowReactions
        ? originalImgHeight - REACTIONS_HEIGHT
        : originalImgHeight

      return { width: props.width, height: imgHeight }
    }

    if (props.height) {
      const MIN_WIDTH = 160
      const width = Math.floor(props.height * props.item.aspectRatio)
      return {
        height: props.height,
        width: width < MIN_WIDTH ? MIN_WIDTH : width,
      }
    }

    throw Error('props.height or props.width must be specified')
  }

  const { height, width } = getWidthAndHeight()

  const isDraggingOver = isOver && draggingItemId !== props.item.id

  const overlayProps: GridItemOverlayProps = {
    item: props.item,
    workspaceId: activeWorkspaceId!,
    workspaceUrl: activeWorkspace ? activeWorkspace!.url : '',
    itemRefId: props.itemRefId,
    onDelete: props.onDelete,
    boardId: props.boardId,
    noOverlayShadow: isGifOrVideo,
    shouldHideTopOverlay: height < 90,
  }

  const thumbUrl =
    itemMediaType === 'gif' && hasFocus && originalThumbTypeHasLoaded
      ? getThumbUrl(props.item, 'gif')
      : getThumbUrl(props.item, 'image')

  return (
    <>
      <StyledDragDropWrapper
        isDraggingOver={isDraggingOver}
        borderPosition={hoverPosition}
        dropBorderHeight={height}
        dropBorderWidth={width}
        ref={dragDropRef}
      >
        <div ref={wrapperRef} style={{ position: 'relative' }}>
          <MultiSelectOverlay
            item={props.item}
            isDisabled={props.isMultiSelectDisabled}
          >
            {(isInMultiSelectMode, isSelected) => {
              const shouldShowStandardGridOverlay =
                hasFocus &&
                !isInMultiSelectMode &&
                !props.disableGridItemOverlay &&
                activeWorkspaceId &&
                !isMobile

              const shouldShowReactionToggleOverlay =
                hasFocus &&
                props.boardId &&
                !itemHasReactions &&
                !isInMultiSelectMode

              return (
                <>
                  <Link
                    onMouseDown={(event) => {
                      // too prevent shift + drag multi-select conflict
                      if (event.shiftKey) {
                        event.preventDefault()
                      }
                    }}
                    onClick={(event) => {
                      if (isInMultiSelectMode) {
                        return event.preventDefault() // we disable link when we are in multi-select mode
                      }
                      trackGridItemClicked({
                        itemId: props.itemRefId,
                      })
                      props.onClick?.()
                    }}
                    to={{
                      pathname: location.pathname,
                      search: `?${new URLSearchParams({
                        ...parsedUrlQueryParams,
                        item: props.itemRefId,
                        itemSpace: getItemSpace(isBoardItem, false),
                        ...(props.isDiscoverItem && {
                          isDiscoverItem: '1', // used to determine which item view to mount in home page
                        }),
                      }).toString()}`,
                    }}
                  >
                    <ItemCard
                      className="gridItem" // used for multi-select
                      id={`gridItem-${props.itemRefId}`} // used for multi-select
                      data-element-position={currentItemPosition}
                      showBorder={hasFocus || isSelected}
                      style={{
                        backgroundImage: `url(${thumbUrl})`,
                        height: `${height}px`,
                        width: `${width}px`,
                      }}
                      onMouseEnter={handleMouseEnter}
                    >
                      {!hasFocus && isGifOrVideo && (
                        <GridItemTypeIcon itemType={itemMediaType} />
                      )}
                      {hasFocus && !originalThumbTypeHasLoaded && isGifOrVideo && (
                        <div
                          style={{
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Loading />
                        </div>
                      )}
                    </ItemCard>
                  </Link>
                  {shouldShowStandardGridOverlay &&
                    (props.customOverlay ? (
                      props.customOverlay(overlayProps)
                    ) : (
                      <GridItemOverlay {...overlayProps} />
                    ))}
                  {shouldShowReactionToggleOverlay && (
                    <GridItemReactionToggleOverlay
                      right={shouldShowStandardGridOverlay ? '50px' : '15px'}
                      onClick={() => {
                        setShouldShowAllReactions((prev) => !prev)
                      }}
                    />
                  )}

                  {shouldShowSpoiler && (
                    <ItemSpoiler
                      thumbUrl={getThumbUrl(props.item, 'image')!}
                      aspectRatio={props.item.aspectRatio}
                      largeUrl={largeUrl}
                      itemId={props.item.id}
                      itemVariant={itemMediaType}
                    />
                  )}
                </>
              )
            }}
          </MultiSelectOverlay>
          {shouldShowReactions && (
            <>
              <div style={{ height: REACTIONS_HEIGHT }} />
              <StyledGridItemReactionsWrapper style={{ maxWidth: width }}>
                <ItemReactions
                  parentId={props.item.id}
                  boardId={props.boardId!}
                  pollInterval={20000}
                  isTransparent
                  isSmall
                  withHideUnsetReactionsToggle={hasFocus}
                  defaultHideUnsetReactions={!shouldShowAllReactions}
                  css="padding:0"
                />
              </StyledGridItemReactionsWrapper>
            </>
          )}
        </div>
      </StyledDragDropWrapper>
    </>
  )
}

export default GridItem

const StyledDragDropWrapper = styled.div<{
  isDraggingOver: boolean
  dropBorderHeight: number
  dropBorderWidth: number
  borderPosition: 'LEFT' | 'TOP' | 'RIGHT' | 'BOTTOM' | ''
}>`
  ${({
    isDraggingOver,
    dropBorderHeight,
    borderPosition,
    dropBorderWidth,
    theme,
  }) => {
    if (isDraggingOver) {
      if (['LEFT', 'RIGHT'].includes(borderPosition)) {
        const heightElement = dropBorderHeight
        const heightRecommended = 200
        const height =
          heightElement < heightRecommended ? heightElement : heightRecommended
        const topPosition =
          heightElement < heightRecommended ? 0 : heightElement / 2 - 100
        return css`
          &:before {
            content: '';
            height: ${height}px;
            width: 4px;
            background: ${theme.colors.accent[2]};
            position: absolute;
            z-index: 1;
            left: ${borderPosition === 'LEFT' ? '-10px' : 'calc(100% + 6px)'};
            border-radius: ${theme.borderRadius.full};
            top: ${topPosition}px;
          }
        `
      }

      if (['TOP', 'BOTTOM'].includes(borderPosition)) {
        const widthElement = dropBorderWidth
        const widthRecommended = 200
        const width =
          widthElement < widthRecommended ? widthElement : widthRecommended
        const leftPosition =
          widthElement < widthRecommended ? 0 : widthElement / 2 - 100
        return css`
          &:before {
            content: '';
            height: 4px;
            width: ${width}px;
            background: ${theme.colors.accent[2]};
            position: absolute;
            z-index: 1;
            top: ${borderPosition === 'TOP' ? '-10px' : 'calc(100% + 6px)'};
            border-radius: ${theme.borderRadius.full};
            left: ${leftPosition}px;
          }
        `
      }
    }
  }}
`

const StyledGridItemReactionsWrapper = styled.div`
  ${({ theme }) => css`
    position: absolute;
    left: 0px;
    bottom: 0px;
    @media (max-width: ${theme.breakpoints.sm}) {
      overflow-x: scroll;
      ::-webkit-scrollbar {
        display: none;
      }
    }
  `}
`
