import { firebaseAuth, firebaseFunctionsDomain } from '../config/firebase'

/**
 * Normalized method to call cloud functions.
 * @param {string} path Path of function without domain
 * @param {any?} data Optional JSON data to send
 */
export default async function callCloudFunction(path, data) {
  const idToken = await firebaseAuth().currentUser?.getIdToken()
  const response = await fetch(`${firebaseFunctionsDomain}/${path}`, {
    method: data ? 'POST' : 'GET',
    body: data && (data instanceof FormData ? data : JSON.stringify(data)),
    credentials: 'include',
    referrerPolicy: 'strict-origin',
    headers: {
      ...(data && !(data instanceof FormData)
        ? { 'Content-Type': 'application/json' }
        : {}),
      Authorization: idToken && `Bearer ${idToken}`,
    },
  })
  // if we got an http error, throw error
  if (!response.ok) {
    throw Error(
      `Error fetching cloud function "${path}".
        Response status: ${response.status}.
        Response body ${await response.text()}`
    )
  }
  const contentLength = response.headers.get('content-length')
  const contentType = response.headers.get('content-type')
  const isJson = contentType?.includes('application/json')

  if (isJson || parseInt(contentLength, 10) > 0) {
    return response.json()
  }

  const body = await response.text()
  console.log('Did not receive a proper response', {
    contentLength,
    contentType,
    body,
  })
  return undefined
}
