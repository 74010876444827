import { useSelector } from 'react-redux'

import { RootState } from 'store'

// Can only be used inside the app as it assumes we are listening on current users user document
const useCurrentUser = () => {
  const userId = useSelector((state: RootState) => state.firebase.auth.uid)
  const user = useSelector(
    (state: RootState) => state.firestore.data.users?.[userId]
  )
  return user!
}

export default useCurrentUser
