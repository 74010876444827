import useMouse from '@react-hook/mouse-position'
import React, { useCallback, useState } from 'react'
import { Range as ReactRange } from 'react-range'
import styled, { css } from 'styled-components'

import { debounce } from 'helpers/utilityFunctions'
import useIsMobile from 'hooks/useIsMobile'
import useKeypress from 'hooks/useKeyPress'
import { useLocalStorage } from 'hooks/useLocalStorage'

const sliderOptionToColumnAdditionMapper: Record<number, number> = {
  0: 2,
  1: 1,
  2: 0,
  3: -1,
  4: -2,
}

const SLIDER_WIDTH = 140
const MOUSE_POSITION_TRESHOLD = 400

type GridColumnAdditionerProps = {
  children: (gridColumnAdditionOption: number) => JSX.Element
  isHidden?: boolean
  parentWrapper: HTMLElement | null
}

export const GridColumnAdditioner = ({
  children,
  isHidden,
  parentWrapper,
}: GridColumnAdditionerProps) => {
  const isMobile = useIsMobile()

  const [gridColumnAdditionOption, setGridColumnAdditionOption] =
    useLocalStorage('grid-column-addition-option', 2)

  const [show, setShow] = useState(false)

  const debouncedHideSlider = useCallback(
    debounce(() => {
      setShow(false)
    }, 2000),
    []
  )

  const showSliderTemporary = () => {
    setShow(true)
    debouncedHideSlider()
  }

  const zoomIn = () => {
    setGridColumnAdditionOption((prev) => {
      if (prev >= 4) return prev
      showSliderTemporary()
      return prev + 1
    })
  }

  const zoomOut = () => {
    setGridColumnAdditionOption((prev) => {
      if (prev <= 0) return prev
      showSliderTemporary()
      return prev - 1
    })
  }

  useKeypress(['cmd:+'], zoomIn, { preventDefault: true })
  useKeypress(['cmd:-'], zoomOut, { preventDefault: true })

  const { elementHeight, y } = useMouse(parentWrapper, {
    enterDelay: 0,
    leaveDelay: 100,
  })

  const mouseIsCloseToPageEnd =
    elementHeight && y && elementHeight - y < MOUSE_POSITION_TRESHOLD

  const wrapperWidth = parentWrapper?.clientWidth ?? 0
  const extraWidth = window.innerWidth - wrapperWidth - 10 // 10 comes from nowhere, it just works

  const shouldHideSlider = () => {
    if (isMobile || isHidden) {
      return true
    }
    if (show) {
      return false
    }
    if (mouseIsCloseToPageEnd) {
      return false
    }
    return true
  }

  return (
    <>
      <StyledGridColumnAdditionerPositioner
        isHidden={shouldHideSlider()}
        xPosition={wrapperWidth / 2 - SLIDER_WIDTH / 2 + extraWidth}
      >
        <StyledGridColumnAdditionerSlider>
          <ReactRange
            step={1}
            min={0}
            max={4}
            values={[gridColumnAdditionOption]}
            onChange={([newValue]) => setGridColumnAdditionOption(newValue)}
            renderTrack={(renderTrackArgs) => (
              <StyledGridColumnAdditionerTrack {...renderTrackArgs.props}>
                {renderTrackArgs.children}
              </StyledGridColumnAdditionerTrack>
            )}
            renderThumb={(renderThumbArgs) => (
              <StyledGridColumnAdditionerThumbOuter {...renderThumbArgs.props}>
                <StyledGridColumnAdditionerThumbInner />
              </StyledGridColumnAdditionerThumbOuter>
            )}
          />
        </StyledGridColumnAdditionerSlider>
      </StyledGridColumnAdditionerPositioner>

      {children(
        isMobile
          ? 0
          : sliderOptionToColumnAdditionMapper[gridColumnAdditionOption]
      )}
    </>
  )
}

const StyledGridColumnAdditionerPositioner = styled.div<{
  isHidden: boolean
  xPosition: number
}>(
  ({ isHidden, xPosition }) => css`
    position: fixed;
    bottom: 16px;
    z-index: 1;
    left: ${xPosition}px;
    opacity: 1;
    transition: 0.2s opacity ease;
    ${isHidden &&
    css`
      opacity: 0;
    `}
  `
)

const StyledGridColumnAdditionerThumbOuter = styled.div`
  height: 12px;
  width: 12px;
  outline: 0;
  transition: 0.05s transform ease;
`

const StyledGridColumnAdditionerThumbInner = styled.div(
  ({ theme }) => css`
    height: 12px;
    width: 12px;
    border-radius: ${theme.borderRadius.full};
    background: ${theme.colors.gray.medium[7]};
    transition: 0.1s transform ease;
  `
)

const StyledGridColumnAdditionerSlider = styled.div(
  ({ theme }) => css`
    padding: 8px 10px;
    background: ${theme.colors.gray.dark[7]};
    border-radius: ${theme.borderRadius.full};
    border: 1px solid ${theme.colors.gray.medium[0]};
    width: ${SLIDER_WIDTH}px;
    box-shadow: 0px 1px 5px #00000066;
    :hover ${StyledGridColumnAdditionerThumbInner} {
      background: ${theme.colors.white};
      transform: scale(1.3);
    }
  `
)

const StyledGridColumnAdditionerTrack = styled.div(
  ({ theme }) => css`
    height: 8px;
    width: 100%;
    border-radius: ${theme.borderRadius.full};
    background: ${theme.colors.gray.medium[1]};
  `
)
