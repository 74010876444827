import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

import Flex from 'components/common/Flex'
import { Spacer } from 'components/common/Spacer'
import { Text } from 'components/common/Text'
import UserAvatar from 'components/common/UserAvatar'
import { User } from 'generated/graphql'
import { darkTheme } from 'styles/theme'

const imageTransitions = {
  inital: {
    opacity: 0.2,
  },
  hover: {
    opacity: 0.3,
  },
}

const textTransitions = {
  inital: {
    color: darkTheme.colors.text.discreet,
  },
  hover: {
    color: darkTheme.colors.text.neutral[0],
  },
}

const backgroundTransitions = {
  inital: {
    background: darkTheme.colors.gray.dark[2],
  },
  hover: {
    background: darkTheme.colors.gray.dark[3],
  },
}

const transition = {
  ease: 'easeIn',
  duration: 0.2,
}

export const TopContributorCard = ({ user }: { user: Partial<User> }) => {
  return (
    <StyledBorderWrapper
      whileHover="hover"
      initial="inital"
      variants={backgroundTransitions}
      transition={transition}
    >
      <StyledCoverImageWrapper
        variants={imageTransitions}
        transition={transition}
      >
        {user.coverImageUrl && (
          <StyledCoverImage src={user.coverImageUrl as string} />
        )}
      </StyledCoverImageWrapper>
      <StyledUserInfoWrapper>
        <Flex flexDirection="column">
          <StyledUserAvatar
            photoUrl={user.photoURL}
            displayName={user.displayName}
            size={64}
          />
          <Spacer factor={1} />
          <Text size="lg" bold color="neutral.0">
            {user.firstName} {user.lastName}
          </Text>
          <Spacer axis="y" factor={0.5} />
          <motion.div variants={textTransitions} transition={transition}>
            <Text size="sm">{user.displayName}</Text>
          </motion.div>
          <motion.div variants={textTransitions} transition={transition}>
            <Text size="sm">{user.publicItemsCount} items contributed</Text>
          </motion.div>
          <Text size="sm" color="discreet">
            #{user.rank} contributor
          </Text>
        </Flex>
      </StyledUserInfoWrapper>
    </StyledBorderWrapper>
  )
}

const StyledBorderWrapper = styled(motion.div)`
  ${({ theme }) => css`
    border-radius: ${theme.borderRadius.lg};
    overflow: hidden;
    border: ${theme.border.thin} ${theme.colors.background[5]};
  `}
`

const StyledUserInfoWrapper = styled(motion.div)`
  padding: 16px;
  margin-top: -50px;
`

const StyledUserAvatar = styled(UserAvatar)`
  z-index: 1;
`

const StyledCoverImageWrapper = styled(motion.div)`
  ${({ theme }) => css`
    height: 105px;
    width: 240px;
    background: ${theme.colors.gray.dark[6]};
  `}
`

const StyledCoverImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`
