import React from 'react'
import styled, { css } from 'styled-components'

export const BetaBadgeLarge = () => <ContainerLarge>Beta</ContainerLarge>

const ContainerLarge = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.xs};
    border-radius: ${theme.borderRadius.sm};
    padding: 5px 12px;
    border: 1px solid ${theme.colors.accent[2]};
    color: ${theme.colors.accent[2]};
    vertical-align: super;
  `}
`
