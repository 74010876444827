import PropTypes from 'prop-types'
import React from 'react'
import styled, { withTheme } from 'styled-components'

import AirIcon from './AirIcon'

const getBorderRadius = (theme, circular, children) => {
  if (!circular) return theme.borderRadius.default
  if (!children) return theme.borderRadius.full
  return theme.borderRadius.lg
}

// OBS: use new reusable Button (at '/components/Button')

const Button = styled.button(
  ({ theme, circular, primary, disabled, ...props }) => ({
    padding: circular && !props.children ? 0 : '8px 10px 8px 10px',
    borderRadius: getBorderRadius(theme, circular, props.children),
    margin: 4,
    fontSize: theme.fontSizes.sm,

    // Make text unselectable
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    msUserSelect: 'none',
    textAlign: 'center',

    border: 0,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: disabled ? 'not-allowed' : 'pointer',
    background: primary ? theme.colors.accent[2] : theme.colors.background[4],
    ':hover': {
      // eslint-disable-next-line no-nested-ternary
      background: disabled
        ? theme.colors.background[4]
        : primary
        ? theme.colors.accent[1]
        : theme.colors.accent[2],
      color: theme.colors.text.neutral[0],
    },
  })
)

const AirButton = ({
  type,
  onClick,
  id,
  style,
  primary,
  className,
  ic,
  icon,
  iconSize,
  text,
  height,
  color,
  minHeight,
  minWidth,
  children,
  disabled,
}) => (
  <Button
    circular={type === 'circular'}
    onClick={onClick}
    id={id}
    style={{ ...style, height, color, minHeight, minWidth }}
    primary={primary}
    className={className}
    disabled={disabled}
  >
    {ic || null}
    {icon ? <AirIcon type={icon} width={iconSize} height={iconSize} /> : null}
    {(children || text) && (
      <div
        style={{
          display: 'inline-block',
          marginLeft: (icon || ic) && children ? 8 : 0,
        }}
      >
        {text || children}
      </div>
    )}
  </Button>
)

AirButton.propTypes = {
  type: PropTypes.string,
  height: PropTypes.number,
  minHeight: PropTypes.number,
  minWidth: PropTypes.number,
  color: PropTypes.string,
  icon: PropTypes.string,
  iconSize: PropTypes.number,
  onClick: PropTypes.func,
  text: PropTypes.string,
  className: PropTypes.string,
}

AirButton.defaultProps = {
  type: 'regular',
  minHeight: 40,
  minWidth: 40,
  color: '#fff',
  icon: null,
  iconSize: 12,
  onClick: () => null,
  text: null,
  className: '',
}

export default withTheme(AirButton)
