import {
  trackBoardCreated,
  trackProjectCreated,
} from 'helpers/tracking/tracking'

import { getRandomEmoji } from '../common/emoji/EmojiPicker'

const getNextUntitledTitle = async (firestore, projectId, number) => {
  const title = `Untitled ${number}`
  const {
    docs: [doc],
  } = await firestore
    .collection('boards')
    .where('projectId', '==', projectId)
    .where('title', '==', title)
    .get()

  if (doc?.exists) {
    return getNextUntitledTitle(firestore, projectId, number + 1)
  }
  return title
}

export const createBoard = async ({
  email,
  projectId,
  workspaceId,
  uid,
  firestore,
  title,
}) => {
  let title_ = title
  if (!title || title.length < 1) title_ = 'Untitled'

  const emoji = getRandomEmoji()

  const boardItem = {
    title: title_,
    iconCode: emoji,
    owners: [email],
    writers: [],
    commenters: [],
    readers: [],
    itemCount: 0,
    projectId,
    workspaceId,
    createdBy: uid,
    createdAt: firestore.FieldValue.serverTimestamp(),
    updatedAt: firestore.FieldValue.serverTimestamp(),
  }

  const { docs } = await firestore
    .collection('boards')
    .where('projectId', '==', projectId)
    .where('title', '==', title_)
    .get()

  if (docs[0]?.exists) {
    if (title_ === 'Untitled') {
      boardItem.title = await getNextUntitledTitle(firestore, projectId, 2)
    } else {
      return { error: 'DUPLICATE_BOARD_NAME' }
    }
  }

  const projectRes = await firestore.collection('projects').doc(projectId).get()
  const { linkAccess: projectLinkAccess } = projectRes.data() || {}

  if (projectLinkAccess) {
    boardItem.linkAccess = projectLinkAccess
  }

  const ref = await firestore.add(
    { collection: 'boards', showAs: 'activeBoards' },
    boardItem
  )
  trackBoardCreated({
    boardId: ref.id,
    projectId,
    workspaceId,
    title: boardItem.title,
  })
  return { boardId: ref.id, boardTitle: boardItem.title }
}

export const createProject = async ({
  workspaceId,
  email,
  firestore,
  uid,
  title,
  workspaceAccess = 'writer',
}) => {
  let title_ = title
  if (!title || title.length < 1) {
    title_ = 'Untitled'
  }

  const project = {
    title: title_,
    owners: [email],
    writers: [],
    commenters: [],
    readers: [],
    workspaceId,
    workspaceAccess,
    itemCount: 0,
    createdBy: uid,
    updatedBy: uid,
    createdAt: firestore.FieldValue.serverTimestamp(),
    updatedAt: firestore.FieldValue.serverTimestamp(),
  }

  const ref = await firestore.add('projects', project)

  // Segment Event –– Created Project
  trackProjectCreated({
    projectId: ref.id,
    workspaceId,
    title: project.title,
  })

  return { projectId: ref.id, projectTitle: project.title }
}
