import { ArrowLeftShort } from '@styled-icons/bootstrap/ArrowLeftShort'
import { Close } from '@styled-icons/material-sharp/Close'
import { useState } from 'react'
import ReactPlayer from 'react-player/lazy'
import styled, { css } from 'styled-components'

import Button from 'components/common/Button'
import Flex from 'components/common/Flex'
import Modal from 'components/common/Modal'
import { Spacer } from 'components/common/Spacer'
import { Text } from 'components/common/Text'
import { preloadImage } from 'helpers/preloadImage'
import useKeypress from 'hooks/useKeyPress'
import { useMount } from 'hooks/useMount'

import { contentCanvas } from './content/contentCanvas'
import { contentCollaboration } from './content/contentCollaboration'
import { contentDiscovery } from './content/contentDiscovery'
import { contentProjectsBoards } from './content/contentProjectsBoards'
import { contentProjectsPage } from './content/contentProjectsPage'
import { contentPublicBoards } from './content/contentPublicBoards'
import { contentSearch } from './content/contentSearch'
import { contentUpload } from './content/contentUpload'

export const guideIdToContentMapper = {
  discover: contentDiscovery,
  upload: contentUpload,
  search: contentSearch,
  projectsBoards: contentProjectsBoards,
  collaboration: contentCollaboration,
  publicBoards: contentPublicBoards,
  projectsPage: contentProjectsPage, // a mix between "projectsBoards" and "collaboration"
  canvas: contentCanvas,
} as const

const INIT_STEP = 1

type GuideModalProps = {
  onClose: () => void
  onDone: () => void
  id: keyof typeof guideIdToContentMapper
}

const Cover = ({ src }: { src: string }) => {
  if (src.indexOf('.mp4') !== -1) {
    return (
      <ReactPlayer
        url={src}
        playing
        playsinline
        loop
        muted
        height="309px"
        width="100%"
        config={{
          file: {
            attributes: {
              autoPlay: true,
              muted: true,
            },
          },
        }}
      />
    )
  }
  return <StyledCover src={src} />
}

export const GuideModal = (props: GuideModalProps) => {
  const { steps, contentHeight } = guideIdToContentMapper[props.id]
  const [currentStep, setCurrentStep] = useState(INIT_STEP)
  const totalSteps = steps.length
  const isLastStep = currentStep === totalSteps
  const isFirstStep = currentStep === INIT_STEP

  useKeypress(['Escape'], props.onClose, {
    override: true,
  })

  useMount(() => {
    steps.forEach(({ coverUrl }) => {
      preloadImage(coverUrl)
    })
  })

  const handleNextStep = () => {
    setCurrentStep((prev) => {
      const nextStep = prev + 1
      if (nextStep > totalSteps) return prev
      return nextStep
    })
  }

  const handlePreviousStep = () => {
    setCurrentStep((prev) => {
      const prevStep = prev - 1
      if (prevStep < INIT_STEP) return prev
      return prevStep
    })
  }

  const data = steps[currentStep - 1]

  return (
    <Modal isOpen close={props.onClose} width="550px">
      {({ close }) => {
        return (
          <StyledGuideModalWrapper>
            <Cover src={data.coverUrl} />
            <StyledContent
              p={2}
              css={`
                height: ${contentHeight}px;
              `}
              flexDirection="column"
            >
              {data.content}
            </StyledContent>
            <Flex p={2} pt={0} alignItems="flex-end">
              <Text size="sm" color="neutral.1">
                {currentStep} of {totalSteps}
              </Text>
              <Flex css="margin-left: auto">
                {!isFirstStep && (
                  <>
                    <Button
                      Icon={ArrowLeftShort}
                      size={22}
                      variant="darkSecondary"
                      isCompact
                      onClick={handlePreviousStep}
                      css="padding: 8px"
                    />
                    <Spacer axis="x" factor={1} />
                  </>
                )}

                <Button
                  variant="primary"
                  isCompact
                  onClick={isLastStep ? props.onDone : handleNextStep}
                >
                  {isLastStep ? 'Done' : 'Next'}
                </Button>
              </Flex>
            </Flex>
            <StyledCloseButton onClick={close}>
              <Close size={22} color="white" />
            </StyledCloseButton>
          </StyledGuideModalWrapper>
        )
      }}
    </Modal>
  )
}

const StyledGuideModalWrapper = styled.div(
  ({ theme }) => css`
    border-radius: ${theme.borderRadius.lg};
    overflow: hidden;
  `
)

const StyledCover = styled.img(
  ({ theme }) => css`
    object-fit: cover;
    width: 100%;
    height: 280px;
    @media (max-width: ${theme.breakpoints.sm}px) {
      height: 200px;
    }
  `
)

const StyledContent = styled(Flex)(
  ({ theme }) => css`
    height: 225px;
    @media (max-width: ${theme.breakpoints.sm}px) {
      height: auto;
    }
  `
)

const StyledCloseButton = styled.div(
  ({ theme }) => css`
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 5px;
    background-color: ${theme.colors.gray.dark[7]};
    border-radius: ${theme.borderRadius.full};
    height: 28px;
    width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s background-color ease;
    :hover {
      background-color: ${theme.colors.gray.medium[0]};
    }
  `
)
