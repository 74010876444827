import { KeyboardReturn } from '@styled-icons/material/KeyboardReturn'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { useUserRecentlyAddedTagsQuery } from 'generated/graphql'
import useKeypress from 'hooks/useKeyPress'
import { RootState } from 'store'

// eslint-disable-next-line import/no-cycle
import ItemTagRecentlyAddedTags from './ItemTagRecentlyAddedTags'
import { Button, Input, Tag, TagEditableContainer } from './ItemTagStyles'
import ItemTagSuggestions from './ItemTagSuggestions'
// eslint-disable-next-line import/no-cycle
import { TagProps } from './ItemTags'

interface ItemTagEditableProps {
  onCancelEditing: () => void
  addTag: (tag: TagProps) => void
  activeTagIds: string[]
  placeholder?: string
  willOverflowScroll?: boolean
}

const initialFocusTag = { description: '' }
const initialTag = { description: '' }

const ItemTagEditable: React.FC<ItemTagEditableProps> = ({
  onCancelEditing,
  addTag,
  activeTagIds,
  placeholder,
  willOverflowScroll,
}) => {
  const [focusTag, setFocusTag] = useState<TagProps>(initialFocusTag)
  const [tag, setTag] = useState<TagProps>(initialTag)
  const [focusIndex, setFocusIndex] = useState<number>(-1)

  useKeypress(['Escape'], onCancelEditing, {
    override: true,
    stopPropagation: true,
  })

  const currentUserId = useSelector(
    (state: RootState) => state.firebase.auth.uid
  )
  const { data } = useUserRecentlyAddedTagsQuery({
    variables: {
      uid: currentUserId,
    },
    fetchPolicy: 'cache-and-network',
  })
  const recentlyAddedTags = data?.userPrivate.tags?.recentlyAddedTags || []

  function setFocus(newFocusTag = tag, newIndex = -1) {
    setFocusTag(newFocusTag)
    setFocusIndex(newIndex)
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    setFocus({ description: e.currentTarget.value.toLowerCase() })
    setTag({ description: e.currentTarget.value.toLowerCase() })
  }

  function handleSubmit(
    e:
      | React.FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLDivElement, MouseEvent>
  ) {
    addTag(focusTag)
    // Because when we reuse this component, the state is stale after submit.
    setFocus(initialFocusTag)
    setTag(initialTag)
    e.preventDefault()
    e.stopPropagation()
  }

  return (
    <TagEditableContainer>
      <form onSubmit={handleSubmit}>
        <Tag onMouseEnter={() => setFocus()} isEditing>
          <Input
            onChange={handleInputChange}
            value={focusTag.description}
            autoFocus
            placeholder={placeholder || 'Name your tag'}
          />
          {tag.description.length > 0 && focusIndex === -1 && (
            <Button onClick={handleSubmit}>
              <span style={{ marginRight: 8 }}>Create</span>
              <KeyboardReturn style={{ height: 16 }} />
            </Button>
          )}
        </Tag>
        {tag.description.length > 0 && (
          <ItemTagSuggestions
            addTag={addTag}
            setFocus={setFocus}
            focusIndex={focusIndex}
            input={tag.description}
            activeTagIds={activeTagIds}
          />
        )}
        {tag.description.length === 0 && (
          <ItemTagRecentlyAddedTags
            willOverflowScroll={willOverflowScroll}
            addTag={addTag}
            setFocus={setFocus}
            focusIndex={focusIndex}
            activeTagIds={activeTagIds}
            recentlyAddedTags={recentlyAddedTags}
          />
        )}
      </form>
    </TagEditableContainer>
  )
}

export default ItemTagEditable
