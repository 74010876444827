import React, { useEffect, useRef, useState } from 'react'
import { useFirestore, withFirestore } from 'react-redux-firebase'
import { Plus } from 'styled-icons/bootstrap'

import { Tooltip } from 'components/common/Tooltip/Tooltip'
import { AutoSizeTextArea } from 'components/library/Common'

import { AddDescriptionText, DescriptionContainer } from './BoardStyles'

interface BoardDescriptionProps {
  boardId: string
  editable: boolean
  description?: string
  shouldShowActions: boolean
}

const BoardDescription: React.FC<BoardDescriptionProps> = (props) => {
  const [editedText, setEditedText] = useState(props.description || '')
  const [shouldShowDescription, setShouldShowDescription] = useState(
    (props.description?.length ?? 0) > 1
  )
  const container = useRef<HTMLDivElement | null>(null)
  const firestore = useFirestore()

  useEffect(() => {
    // we re-init description states if we switch between boards
    setEditedText(props.description || '')
    setShouldShowDescription((props.description?.length ?? 0) > 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.boardId])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedText(e.target.value)
  }

  const handleBlur = async (e: React.FocusEvent<HTMLInputElement>) => {
    // Show the add description action instead of an empty input
    if (e.target.value.length < 1) {
      setShouldShowDescription(false)
    }
    await handleUpdateBoardDescription()
  }

  const handleUpdateBoardDescription = async () => {
    const newDescription = editedText
    const previousDescription = props.description

    if (newDescription === previousDescription) return null // if there were no changes, don't proceed.

    const itemUpdates = {
      description: newDescription,
      updatedAt: firestore.FieldValue.serverTimestamp(),
    }

    await firestore.update(
      { collection: 'boards', doc: props.boardId },
      itemUpdates
    )
  }

  const addDescription = () => {
    setShouldShowDescription(true)
    setTimeout(() => {
      container.current?.querySelector('textarea')?.focus()
    }, 0)
  }

  return (
    <DescriptionContainer ref={container}>
      {shouldShowDescription && (
        <Tooltip
          isDisabled={!props.editable}
          delay={[1500, 0]}
          title="Click to edit description"
          position="bottom-start"
        >
          <AutoSizeTextArea
            width={500}
            disabled={!props.editable}
            onChange={handleChange}
            onBlur={handleBlur}
            value={editedText}
          />
        </Tooltip>
      )}
      {props.shouldShowActions && props.editable && !shouldShowDescription && (
        <AddDescriptionText onClick={addDescription}>
          <Plus size={28} />
          Add a description for this board
        </AddDescriptionText>
      )}
    </DescriptionContainer>
  )
}

export default withFirestore(BoardDescription)
