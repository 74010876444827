import { useEffect } from 'react'

import {
  trackItemViewed,
  trackPublicItemViewed,
} from 'helpers/tracking/tracking'

import { ItemData } from './useGetItemData'

type UseTrackItemViewedProps = {
  itemData?: ItemData
  inDiscover?: boolean
  trackingSessionId?: string
  isPublicItem?: true
}

export const useTrackItemViewed = ({
  itemData,
  isPublicItem,
  inDiscover,
  trackingSessionId,
}: UseTrackItemViewedProps) => {
  useEffect(() => {
    if (!itemData?.id) {
      return
    }
    const isBoardItem = 'boardId' in itemData

    if (isPublicItem) {
      trackPublicItemViewed({
        itemId: itemData.id,
        boardId: isBoardItem ? itemData.boardId : undefined,
        projectId: isBoardItem ? itemData.projectId : undefined,
        sourceExtension: itemData.original.info?.type,
        trackingSessionId,
        inDiscovery: inDiscover,
      })
    } else {
      trackItemViewed({
        itemId: itemData.id,
        boardId: isBoardItem ? itemData.boardId : undefined,
        projectId: isBoardItem ? itemData.projectId : undefined,
        sourceExtension: itemData.original.info?.type,
      })
    }
  }, [itemData?.id])
}
