import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'

import { firebaseAuth } from 'config/firebase'

const StyledContainer = styled.div(({ theme }) => ({
  width: '100%',
  height: '100vh',
  background: 'black',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
}))

function SSOHandler() {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  useEffect(() => {
    const toPath = searchParams.get('toPath') || ''
    const id = searchParams.get('id') || ''
    const token = searchParams.get('token') || ''
    firebaseAuth()
      .signInWithCustomToken(token)
      .then((res) => {
        return navigate(`/${toPath}/${id}`)
      })
      .catch((err) => {
        return navigate(`/`)
      })
  }, [navigate, searchParams])

  return <StyledContainer />
}

export default SSOHandler
