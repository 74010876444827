import { connect } from 'react-redux'
import { withFirestore } from 'react-redux-firebase'
import { compose, withHandlers } from 'recompose'

import useToastMessages from 'components/toast/useToastMessages'
import { withRouter } from 'helpers/withRouter'

import CreateView from './CreateView'
import { createBoard } from './helpers'

const ProjectCreateBoard = (props) => {
  const { reportError } = useToastMessages()

  const handleCreateBoard = async (title) => {
    const { error, boardId } = await props.createBoard(title)
    if (error === 'DUPLICATE_BOARD_NAME') {
      reportError('You already have a board with that title', error)
    } else {
      props.navigate(
        `/${props.params.workspaceUrl}/p/${props.params.projectId}/${boardId}`
      )
    }
  }

  return <CreateView handleCreate={handleCreateBoard} />
}

const mapStateToProps = (state, ownProps) => {
  return {
    uid: state.firebase.auth.uid,
    email: state.firebase.auth.email,
    activeWorkspace: state.content.activeWorkspace,
  }
}

export default compose(
  connect(mapStateToProps, {}),
  withFirestore,
  withRouter,
  withHandlers({
    createBoard: (props) => (title) =>
      createBoard({
        workspaceId: props.activeWorkspace.id,
        email: props.email,
        firestore: props.firestore,
        projectId: props.params.projectId,
        title,
        uid: props.uid,
      }),
  })
)(ProjectCreateBoard)
