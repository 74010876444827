import { Spacer } from 'components/common/Spacer'
import { HighlightRow } from 'components/highlight-row/HighlightRow'
import {
  trackHomeLearningCardHidden,
  trackHomeLearningCardRead,
} from 'helpers/tracking/tracking'

import { GuideCard } from './GuideCard'
import { GuideInfo, HomeGuideId, guidesInfo } from './guidesInfo'
import { useUserLearningGuides } from './useUserLearningGuides'

export const GuidesRow = () => {
  const { userLearningGuides, markLearningGuideAsRead, hideLearningGuide } =
    useUserLearningGuides()

  const handleHideClick = (id: HomeGuideId) => {
    hideLearningGuide(id)
    trackHomeLearningCardHidden({
      category: id,
    })
  }

  const handleIsRead = (id: HomeGuideId) => {
    markLearningGuideAsRead(id)
    trackHomeLearningCardRead({
      category: id,
    })
  }

  if (!userLearningGuides) return null

  const filteredGuidesInfo: GuideInfo[] = guidesInfo.filter(
    (guideInfo) => !userLearningGuides[guideInfo.id]?.isHidden
  )

  if (filteredGuidesInfo.length === 0) return null

  return (
    <>
      <HighlightRow
        title="Guides"
        listItems={filteredGuidesInfo}
        renderListItem={(guideInfo) => {
          const remoteDataHomeGuide = userLearningGuides[guideInfo.id]

          return (
            <GuideCard
              onHideClick={handleHideClick}
              onIsRead={handleIsRead}
              guideInfo={guideInfo}
              isRead={remoteDataHomeGuide?.isRead}
              id={guideInfo.id}
            />
          )
        }}
      />
      <Spacer factor={5} />
    </>
  )
}
