const getImageFinishedLoading = (imgUrl: string) => {
  // Returns a new promise the the return type boolean
  return new Promise<boolean>((res) => {
    // Creates a new HTMLImageElement instance: https://developer.mozilla.org/en-US/docs/Web/API/HTMLImageElement/Image
    const img = new Image()
    // Sets the instances source to the given image url
    img.src = imgUrl
    // When the image has loaded send the response (resolve) of boolean true
    img.onload = () => res(true)
  })
}

// Usage:
/* 
  await getImageFinishedLoading([the source]).then((res: boolean) => {
    Code that executes whenever the image has loaded
  })
*/

export default getImageFinishedLoading
