import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

// Cover

const ImageContainer = styled.div<{ src: string; height?: number }>`
  ${({ theme, height, src }) => css`
    min-height: ${height ? `${height}px` : '280px'};
    background-image: url(${src});
    background-size: cover;
    background-position: center;
    color: ${theme.colors.pink};
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-fill: 1 1 0;
    font-size: ${theme.fontSizes['3xl']};
    font-weight: ${theme.fontWeight.bold};
    text-align: center;
    @media (max-width: 1300px): {
      min-height: ${height || '240px'};
      font-size: ${theme.fontSizes['2xl']};
    },
    @media (max-width: 768px): {
      font-size: ${theme.fontSizes.xl};
      min-height: height ? height : 200,
    },
  `}
`

const Container = styled.div({
  textAlign: 'center',
})

const Overlay = styled(motion.div)<{ $isLightTheme?: boolean }>`
  ${({ theme, $isLightTheme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      0deg,
      ${$isLightTheme ? 'white' : theme.colors.gray.dark[1]} 0%,
      rgba(0, 0, 0, 0) 100%
    );
  `}
`

const CoverInfo = styled.div({
  zIndex: 2,
  display: 'flex',
  alignItems: 'left',
  justifyContent: 'center',
  flexDirection: 'column',
  marginTop: '30px',
})

const Title = styled.div`
  ${({ theme, color }) => css`
    color: ${color || theme.colors.pink};
    font-size: ${theme.fontSizes['3xl']};
    font-weight: ${theme.fontWeight.bold};
    @media (max-width: 1300px) {
      font-size: ${theme.fontSizes['2xl']};
    }
    @media (max-width: 768px) {
      font-size: ${theme.fontSizes.xl};
      line-height: 38px;
    } ;
  `}
`

const SubTitle = styled.div(({ theme, color }) => ({
  color: color || theme.colors.white,
  fontSize: theme.fontSizes.lg,
  '@media (max-width: 1300px)': {
    fontSize: theme.fontSizes.lg,
  },
  '@media (max-width: 768px)': {
    fontSize: theme.fontSizes.sm,
  },
}))

const Main = styled.div`
  overflow: scroll;
  overflow-x: hidden;
  max-height: calc(100vh - 60px);
  min-height: calc(100vh - 60px);
`

const CardImage = styled(motion.div).attrs<{ src: string }>((props) => ({
  style: { backgroundImage: `url(${props.src})` },
}))<{ src: string }>`
  background-color: black;
  background-size: cover;
  background-position: center;
  width: auto;
  padding: 24px;
  position: relative;
  overflow: hidden;
  height: 200px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.1s all ease-in;
  @media (max-width: 768px) {
    height: 120;
  }
`

const CardTitle = styled(motion.div)<{ $processing?: boolean; color?: string }>`
  ${({ theme, $processing, color }) => css`
    font-size: ${theme.fontSizes.lg};
    font-weight: ${theme.fontWeight.bold};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    color: ${$processing && color ? color : theme.colors.white};
    z-index: 1;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    line-height: 200px;
    padding: 0 24px;
    transition: color .2s ease;
    text-shadow: 0px 1px 2px rgb(0 0 0 / 56%);
    @media (max-width: 768px): {
      font-size: ${theme.fontSizes.base};
      line-height: 120px;
    },
`}
`

const StyledBorderWrapper = styled(motion.div)<{ $shouldAnimate: boolean }>`
  ${({ theme, $shouldAnimate }) => css`
    background: ${theme.colors.background[3]};
    border-radius: ${theme.borderRadius.lg};
    overflow: hidden;
    border: 4px solid transparent;
    transition: border 0.1s;
    position: relative;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    ${!$shouldAnimate &&
    css`
      :hover {
        border: 4px solid ${theme.colors.gray.dark[6]};
      }
    `}
  `}
`

export {
  Main,
  SubTitle,
  Title,
  CoverInfo,
  ImageContainer,
  Overlay,
  Container,
  CardImage,
  CardTitle,
  StyledBorderWrapper,
}
