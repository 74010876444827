import React, { ChangeEvent, useState } from 'react'
import styled, { css } from 'styled-components'

import Button from 'components/common/Button'
import Flex from 'components/common/Flex'
import { Spacer } from 'components/common/Spacer'
import { Text } from 'components/common/Text'

import { StripeCoupon, useCouponQuery } from '../../../generated/graphql'
import {
  ButtonWrapper,
  ButtonsWrapper,
  ModalHeading,
  ModalText,
} from './DowngradeViewFirst'

const REASON_LIST = [
  'Technical reasons',
  'Did not meet my expectations',
  'Switching to another product',
  'Shutting down the company',
  'Not sure how to use Kive',
  'Missing features I need',
  'Other',
]

const PLACEHOLDER_TEXTS_MAP: Record<string, string> = {
  'Did not meet my expectations':
    'Could you please tell us what didn’t meet your expectations? it would be hugely valuable to know how we could improve Kive',
  'Technical reasons':
    'Could you please tell us what the technical reasons were? it would be hugely valuable to know how we could improve Kive',
}

const DEFAULT_PLACEHOLDER_TEXT =
  'Please describe your reason for downgrade to help us improve Kive'

type DowngradeViewSecondProps = {
  onCloseClick: () => void
  onChurningOfferClick: (coupon: StripeCoupon) => void
  downgradingPlan: null | any
  onConfirm: (plan: any) => Promise<void>
  handleDowngradeSubmission: (parameters: any) => Promise<void>
  activeCouponId?: string
}

export const DowngradeViewSecond = ({
  onCloseClick,
  onChurningOfferClick,
  downgradingPlan,
  onConfirm,
  handleDowngradeSubmission,
  activeCouponId,
}: DowngradeViewSecondProps) => {
  const [pickedReasons, setPickedReasons] = useState<string[]>([])
  const [additionalInfo, setAdditionalInfo] = useState('')
  const [canBeContacted, setCanBeContacted] = useState<boolean | null>(null)
  const { data: couponData, loading } = useCouponQuery({
    variables: {
      couponId:
        process.env.REACT_APP_CHURNING_USER_DISCOUNT_COUPON_ID || '1Pa2eP7w',
    },
  })

  const isChurningCouponApplied = couponData?.coupon?.id === activeCouponId

  const canSubmit =
    pickedReasons.length > 0 && typeof canBeContacted === 'boolean'

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    if (canSubmit) {
      onConfirm(downgradingPlan)
      handleDowngradeSubmission({
        additionalInfo,
        pickedReasons,
        canBeContacted,
      })
      onCloseClick()
    }
  }

  const handleCheckboxUpdate = ({
    target: { name },
  }: ChangeEvent<HTMLInputElement>) => {
    if (pickedReasons.includes(name)) {
      setPickedReasons(pickedReasons.filter((element) => element !== name))
    } else {
      setPickedReasons([...pickedReasons, name])
    }
  }

  const handleCanBeContactedChange = ({
    target: { name },
  }: ChangeEvent<HTMLInputElement>) => {
    if (name === 'Yes') {
      setCanBeContacted((prev) => {
        if (prev === true) return null
        return true
      })
    }
    if (name === 'No') {
      setCanBeContacted((prev) => {
        if (prev === false) return null
        return false
      })
    }
  }

  const firstReason = pickedReasons[0]
  const customText = PLACEHOLDER_TEXTS_MAP[firstReason]
  const placeholderText = customText || DEFAULT_PLACEHOLDER_TEXT

  return (
    <FormContainer onSubmit={handleFormSubmit}>
      <ModalHeading>We’re sad to see you go...</ModalHeading>
      {!loading && !isChurningCouponApplied && (
        <>
          <DiscountContainer
            onClick={() => {
              onChurningOfferClick(couponData?.coupon as StripeCoupon)
              onCloseClick()
            }}
          >
            <DiscountText>
              Give us another chance and you&apos;ll get{' '}
              <span css="text-decoration: underline">
                {couponData?.coupon?.name}!
              </span>
            </DiscountText>
          </DiscountContainer>
          <Spacer factor={3} />
        </>
      )}
      <ModalText>
        Please let us know the reason you are downgrading. Every bit of feedback
        helps!
      </ModalText>
      <SubTitle>Reasons</SubTitle>
      <ReasonList>
        {REASON_LIST.map((element, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <CheckBoxWrapper key={index}>
            <label htmlFor={index.toString()}>{element}</label>
            <input
              type="checkbox"
              id={index.toString()}
              name={element}
              onChange={handleCheckboxUpdate}
            />
          </CheckBoxWrapper>
        ))}
      </ReasonList>
      <TextArea
        value={additionalInfo}
        onChange={({ target: { value } }) => setAdditionalInfo(value)}
        rows={8}
        placeholder={placeholderText}
        required
      />
      <Spacer factor={3} />
      <Text size="sm" bold color="neutral.0">
        Would it be OK if we reached out to you to better understand how we
        could improve Kive for you and other creatives in the future?
      </Text>
      <Spacer factor={1} />
      <Flex>
        <Flex>
          <label htmlFor="yes">Yes</label>
          <input
            type="checkbox"
            id="yes"
            name="Yes"
            checked={canBeContacted === true}
            onChange={handleCanBeContactedChange}
          />
        </Flex>
        <Spacer axis="x" factor={2} />
        <Flex>
          <label htmlFor="no">No</label>
          <input
            type="checkbox"
            id="no"
            name="No"
            checked={canBeContacted === false}
            onChange={handleCanBeContactedChange}
          />
        </Flex>
      </Flex>
      <Spacer factor={3} />
      <ButtonsWrapper>
        <ButtonWrapper>
          <Button bold fullWidth onClick={onCloseClick}>
            Cancel
          </Button>
        </ButtonWrapper>
        <ButtonWrapper>
          <Button
            bold
            disabled={!canSubmit}
            type="submit"
            fullWidth
            variant="danger"
          >
            Confirm downgrade
          </Button>
        </ButtonWrapper>
      </ButtonsWrapper>
    </FormContainer>
  )
}

const TextArea = styled('textarea')(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.colors.background[3],
  color: theme.colors.text.neutral[0],
  padding: 12,
  borderRadius: theme.borderRadius.default,
  border: `${theme.border.thin} ${theme.colors.gray.dark[5]}`,
  outline: 'none',
  '::placeholder': {
    color: theme.colors.text.neutral[3],
    fontFamily: 'UniversalSans',
  },
  ':hover': {
    border: `1px solid ${theme.colors.accent[2]}`,
  },
  ':focus': {
    color: theme.colors.text.neutral[0],
    border: `1px solid ${theme.colors.accent[2]}`,
    backgroundColor: theme.colors.background[4],
  },
  '@media (max-width: 768px)': {
    fontSize: '16px',
  },
}))

const SubTitle = styled.h2(({ theme }) => ({
  color: theme.colors.text.neutral[0],
  fontSize: theme.fontSizes.base,
  fontWeight: theme.fontWeight.bold,
}))

const ReasonList = styled.div`
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`
const CheckBoxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  user-select: none;
  padding-left: 12px;
  font-size: 16px;
`
const FormContainer = styled.form`
  text-align: left;
  max-width: 500px;
`
const DiscountContainer = styled.div(
  ({ theme }) => css`
    cursor: pointer;
    background-color: ${theme.colors.accent[1]};
    font-weight: ${theme.fontWeight.bold};
    width: 100%;
    padding: 24px;
    border-radius: ${theme.borderRadius.default};
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  `
)

const DiscountText = styled.p(({ theme }) => ({
  color: theme.colors.text.neutral[0],
}))
