import { Link, useLocation } from 'react-router-dom'

import { BoardPreviewCard } from 'components/board-preview-card/BoardPreviewCard'
import Flex from 'components/common/Flex'
import { Spacer } from 'components/common/Spacer'
import { HighlightRow } from 'components/highlight-row/HighlightRow'
import { usePublicBoardsQuery } from 'generated/graphql'
import {
  trackDiscoveryMorePublicBoardsClicked,
  trackDiscoveryPublicBoardClicked,
} from 'helpers/tracking/tracking'

import { LearnMoreButton } from '../components/LearnMoreButton'
import { UserInfo } from '../components/UserInfo'

export const PublicBoardsRow = ({
  trackingSessionId,
}: {
  trackingSessionId: string
}) => {
  const location = useLocation()
  const { data, loading } = usePublicBoardsQuery({
    variables: {
      first: 10,
    },
    fetchPolicy: 'cache-and-network',
    context: {
      batch: true,
    },
  })

  const boards = data?.publicBoards.boards || []

  return (
    <>
      <HighlightRow
        title={
          <Flex flexDirection="row" alignItems="center">
            Public boards
            <Spacer factor={2} axis="x" />
            <LearnMoreButton guideId="publicBoards" size="small" />
          </Flex>
        }
        link={{ path: 'boards', text: 'More public boards' }}
        listItems={boards}
        isLoading={loading && !boards.length}
        onLinkClick={() =>
          trackDiscoveryMorePublicBoardsClicked({ trackingSessionId })
        }
        renderListItem={(board) => {
          return (
            <Link
              to={`boards/${board.id}`}
              state={{ prevPathname: location.pathname }}
              onClick={() =>
                trackDiscoveryPublicBoardClicked({ trackingSessionId })
              }
            >
              <BoardPreviewCard
                title={board.title}
                projectTitle={board.project.title}
                count={board.itemCount}
                thumbUrls={board.thumbUrls}
                extra={<UserInfo user={board.createdBy} />}
              />
            </Link>
          )
        }}
      />
      <Spacer factor={5} />
    </>
  )
}
