import { Play } from '@styled-icons/bootstrap/Play'
import { Plus } from '@styled-icons/bootstrap/Plus'
import { Share } from '@styled-icons/boxicons-regular/Share'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase'
import { Link, useParams } from 'react-router-dom'
import styled, { css } from 'styled-components'
import {
  DelimitedArrayParam,
  useQueryParam,
  withDefault,
} from 'use-query-params'

import Button from 'components/common/Button'
import IconButton from 'components/common/IconButton'
import { LinkWithParams } from 'components/common/LinkWithParams'
import { Margin } from 'components/common/Margin'
import { Text } from 'components/common/Text'
import EmojiEditable from 'components/common/emoji/EmojiEditable'
import SearchCreation from 'components/search/SearchCreation'
import SearchUpload from 'components/search/SearchUpload'
import BoardSharingPanel from 'components/sharing/BoardSharingPanel'
import useBoardPermissions from 'hooks/useBoardPermissions'
import useIsMobile from 'hooks/useIsMobile'
import useWorkspacePermissions from 'hooks/useWorkspacePermissions'
import { setIsUploadVisible } from 'store/content/actions'

import { db } from '../../config/firebase'
import { trackBoardViewed } from '../../helpers/tracking/tracking'
import { Cover } from '../library/Common'
import BoardDescription from './BoardDescription'
import { BoardFilterOptions } from './BoardFilterOptions'
import BoardMenu from './BoardMenu'
import { HoverButton, Main } from './BoardStyles'
import BoardTitle from './BoardTitle'
import BoardViewGrid from './BoardViewGrid'
import FavoriteBoardButton from './FavoriteBoardButton'
import PublicBoardCoverBar from './PublicBoardCoverBar'
import PublicBoardToolbar from './PublicBoardToolbar'

const BoardView = (props) => {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()
  const firestore = useFirestore()
  const { boardId } = useParams()
  const [scrollNode, setScrollNode] = useState(null)
  const [isBoardSharingPanelOpen, setIsBoardSharingPanelOpen] = useState(false)
  const [shouldShowActions, setShouldShowActions] = useState(false)

  const [reactionFilters] = useQueryParam(
    'reactionFilters',
    withDefault(DelimitedArrayParam, [])
  )

  useFirestoreConnect([
    {
      collection: 'boards',
      doc: boardId,
      storeAs: `activeBoards.${boardId}`,
    },
  ])

  const board = useSelector(
    (state) =>
      state.firestore.data[`activeBoards.${boardId}`] ||
      state.firestore.data.boards?.[boardId]
  )

  const isLoggedIn = useSelector((state) => Boolean(state.firebase.auth?.uid))

  const userAllowedTo = useBoardPermissions(board, [
    'EDIT_BOARD',
    'ADD_BOARD_ITEM',
  ])
  const { UPLOAD: userAllowedToUploadToWorkspace } = useWorkspacePermissions([
    'UPLOAD',
  ])

  const hasNoPermission = useSelector(
    (state) =>
      state.firestore.errors.byQuery?.[`activeBoards.${boardId}`]?.code ===
      'permission-denied'
  )

  const handleOpenUploadView = () => {
    dispatch(setIsUploadVisible(true))
  }

  if (!board) return null

  // Don't show intercom for unauthenticated viewers of board
  if (!isLoggedIn) {
    window.Intercom?.('update', {
      hide_default_launcher: true,
    })
  }

  const handleUpdateBoardEmoji = (code) => {
    firestore.update(
      { collection: 'boards', doc: boardId },
      {
        iconCode: code,
        updatedAt: firestore.FieldValue.serverTimestamp(),
      }
    )
  }

  const removeBoardCover = () => {
    db.collection('boards').doc(boardId).update({
      cover: firestore.FieldValue.delete(),
    })
  }

  const userAllowedToAddBoardItem =
    userAllowedToUploadToWorkspace && userAllowedTo.ADD_BOARD_ITEM

  if (hasNoPermission && !board) {
    return (
      <NoAccessContainer>
        <Text size="xl" as="h1" center color="neutral.0">
          You do not have access to this board 😢
        </Text>
        <Margin y={12} />
        <Text size="base" center color="neutral.1">
          To get access, you could ask a board admin to change the permissions.
        </Text>
        <Margin y={26} />
        <Link to="/">
          <Button variant="primary">Back to Kive</Button>
        </Link>
      </NoAccessContainer>
    )
  }

  if (board.hideFromDiscover) {
    return (
      <NoAccessContainer>
        <Text size="xl" as="h1" center color="neutral.0">
          This board doesn&apos;t seem to exist 😢
        </Text>
        <Margin y={26} />
        <Link to="/">
          <Button variant="primary">Back to Kive</Button>
        </Link>
      </NoAccessContainer>
    )
  }

  return (
    <>
      {!props.public && (
        <>
          {isMobile ? (
            <MobileNavWrapper>
              <Margin x={54} />
              <MobileNavTitleWrapper>
                {board.iconCode && (
                  <EmojiEditable
                    code={board.iconCode}
                    boardId={boardId}
                    handleUpdateEmoji={handleUpdateBoardEmoji}
                    shouldShowActions
                    disabled={!userAllowedTo.EDIT_BOARD}
                  />
                )}
                <Text
                  style={{
                    marginLeft: board.iconCode ? 10 : 0,
                    alignSelf: 'center',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '100px',
                  }}
                  nowrap
                  color="neutral.0"
                  size="sm"
                  bold
                >
                  {board.title}
                </Text>
                <Margin x={24} />
              </MobileNavTitleWrapper>
              <Margin x={24} />
              <StyledNavLink to={`/b/${boardId}`}>
                <Play css="height: 25px" />
              </StyledNavLink>
              <Margin x={24} />
              <BoardMenu
                boardId={boardId}
                board={board}
                noBackground
                withDownloadButton
              />
              <Margin x={10} />
            </MobileNavWrapper>
          ) : (
            <SearchCreation
              placeholder="Search Workspace"
              rightSideContent={
                <>
                  <Margin x={24} />
                  <StyledNavLink to={`/b/${boardId}`}>
                    <Play css="height: 25px" />
                    <Margin x={6} />
                    <Text>Present</Text>
                  </StyledNavLink>
                  <Margin x={36} />
                  <StyledNavButton
                    onClick={() => setIsBoardSharingPanelOpen(true)}
                  >
                    <Share css="height: 18px" />
                    <Margin x={12} />
                    <Text size="sm">Share</Text>
                  </StyledNavButton>
                  <Margin x={36} />
                  <FavoriteBoardButton boardId={boardId} />
                  <Margin x={18} />
                  <BoardMenu
                    boardId={boardId}
                    board={board}
                    noBackground
                    withDownloadButton
                  />
                  <Margin x={36} />
                  <SearchUpload Icon={Plus} text="Add items" />
                </>
              }
            />
          )}
        </>
      )}

      {props.public && (
        <PublicBoardToolbar
          boardId={boardId}
          board={board}
          title={board.title}
          iconCode={board.iconCode}
          onShareBoardClick={() => setIsBoardSharingPanelOpen(true)}
          isDiscover={props.isDiscover}
        />
      )}
      <Main ref={(ref) => setScrollNode(ref)}>
        <div
          onMouseEnter={() => setShouldShowActions(true)}
          onMouseLeave={() => setShouldShowActions(false)}
        >
          <Cover
            justifyContent="left"
            src={board?.cover?.src}
            height={board?.cover?.src ? 200 : 150}
          >
            <BoardTitle
              editable={Boolean(userAllowedTo.EDIT_BOARD)}
              title={board.title}
              shouldShowIcon={board.shouldShowIcon}
              iconCode={board.iconCode}
              boardId={boardId}
              projectId={board.projectId}
              shouldShowAddIconButton={shouldShowActions}
              hasBoardCover={Boolean(board?.cover?.src)}
            />
            <BoardDescription
              shouldShowActions={shouldShowActions}
              editable={Boolean(userAllowedTo.EDIT_BOARD)}
              description={board.description}
              boardId={boardId}
              projectId={board.projectId}
              hasBoardCover={Boolean(board?.cover?.src)}
            />
            {shouldShowActions && 'cover' in board && userAllowedTo.EDIT_BOARD && (
              <HoverButton
                style={{ position: 'absolute', top: 10, right: 10 }}
                onClick={removeBoardCover}
              >
                Remove board cover
              </HoverButton>
            )}
            <Margin y={18} />
            <BoardFilterOptions
              boardId={boardId}
              workspaceId={board.workspaceId}
            />
          </Cover>
        </div>

        {props.public && <PublicBoardCoverBar boardId={boardId} />}
        <GridWrapper>
          {scrollNode && (
            <BoardViewGrid
              scrollNode={scrollNode}
              boardId={boardId}
              hasCustomItemsPositioning={board.hasCustomItemsPositioning}
              isPublic={props.public}
              board={board}
              boardItemsFilters={{ reactions: reactionFilters }}
            />
          )}
        </GridWrapper>
        {isMobile && !props.public && userAllowedToAddBoardItem && (
          <IconButton
            primary
            floating
            center
            bottom={30}
            size={60}
            iconSize={36}
            Icon={Plus}
            onClick={handleOpenUploadView}
          />
        )}
      </Main>
      {isBoardSharingPanelOpen && (
        <BoardSharingPanel
          board={board}
          boardId={boardId}
          handleClose={() => setIsBoardSharingPanelOpen(false)}
        />
      )}
      <TrackAnalyticsBoardViewed
        boardId={boardId}
        isPublic={props.public}
        workspaceId={board.workspaceId}
        projectId={board.projectId}
        createdBy={board.createdBy}
      />
    </>
  )
}

export default BoardView

const GridWrapper = styled.div`
  padding: 16px 16px 0;
`

const StyledNavLink = styled(LinkWithParams)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    color: ${theme.colors.text.neutral[0]};
    &:hover {
      color: ${theme.colors.accent[2]};
    }
  `}
`

const StyledNavButton = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    color: ${theme.colors.text.neutral[0]};
    cursor: pointer;
    &:hover {
      color: ${theme.colors.accent[2]};
    }
  `}
`

const MobileNavWrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
    background-color: ${theme.colors.background[2]};
    border-bottom: 1px solid ${theme.colors.background[5]};
  `}
`

const MobileNavTitleWrapper = styled.div`
  margin-right: auto;
  display: flex;
`

const NoAccessContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const TrackAnalyticsBoardViewed = ({
  boardId,
  isPublic,
  workspaceId,
  projectId,
  createdBy,
}) => {
  useEffect(() => {
    trackBoardViewed({
      boardId,
      isPublic: Boolean(isPublic),
      workspaceId,
      projectId,
      createdBy,
    })
  }, [])
  return null
}
