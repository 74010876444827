import { useUserProfileQuery } from 'generated/graphql'

export const useGetUserProfile = ({
  userHandle,
  userId,
}: {
  userHandle?: string
  userId?: string
}) => {
  const res = useUserProfileQuery({
    variables: { userHandle },
    fetchPolicy: 'cache-and-network',
    skip: !userHandle,
  })

  const res2 = useUserProfileQuery({
    variables: { userId },
    fetchPolicy: 'cache-and-network',
    skip: !userId,
  })

  if (userHandle) {
    return res
  }
  return res2
}
