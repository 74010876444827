import { Environment } from './firebase'

const growthbookUrls = {
  [Environment.Local]:
    'https://cdn.growthbook.io/api/features/key_stag_8e3ea3058c03ae6d',
  [Environment.Test]:
    'https://cdn.growthbook.io/api/features/key_stag_8e3ea3058c03ae6d',
  [Environment.Production]:
    'https://cdn.growthbook.io/api/features/key_prod_6335b11bd604821d',
}

export const GROWTHBOOK_URL = growthbookUrls[process.env.REACT_APP_ENV!]
