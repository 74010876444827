import { Download } from '@styled-icons/boxicons-regular/Download'
import { AnimatePresence, motion } from 'framer-motion'
import { MutableRefObject, useEffect, useRef, useState } from 'react'
import { Layer, Rect, Transformer } from 'react-konva'
import styled, { css, useTheme } from 'styled-components'

import Flex from 'components/common/Flex'
import { trackCanvasDownloaded } from 'helpers/tracking/tracking'
import useKeypress from 'hooks/useKeyPress'
import { usePrevious } from 'hooks/usePrevious'

import { StyledButtonMedium } from '../ButtonMedium'
import { Select } from '../Select'

type DownloadSelectionState = {
  x: number
  y: number
  width: number
  height: number
}

type UseDownloadModuleProps = {
  scale: number
  canvasRef: MutableRefObject<any>
  stageRef: MutableRefObject<any>
  editSessionId?: string
}

export const useDownloadModule = (props: UseDownloadModuleProps) => {
  const theme = useTheme()
  const downloadSelectionRef = useRef<any>(null)
  const downloadSelectionTransformerRef = useRef<any>(null)

  const [downloadSelection, setDownloadSelection] =
    useState<DownloadSelectionState | null>(null)
  const [isDraggingDownloadSelection, setIsDraggingDownloadSelection] =
    useState(false)
  const [isTransformingDownloadSelecton, setIsTransformingDownloadSelection] =
    useState(false)
  const [downloadRatioSetting, setDownloadRatioSetting] = useState('1')

  const handleDownload = () => {
    const ratioSetting = Number(downloadRatioSetting)
    if (!downloadSelection) return null

    trackCanvasDownloaded({
      editSessionId: props.editSessionId,
      downloadRatioSetting,
    })

    const clonedStage = props.stageRef.current.clone({ listening: false })

    clonedStage.height(downloadSelection.height * props.scale)
    clonedStage.width(downloadSelection.width * props.scale)

    clonedStage.position({
      x: downloadSelection.x * props.scale * -1,
      y: downloadSelection.y * props.scale * -1,
    })

    const [downloadSelectionLayer] = clonedStage.find(
      '#download-selection-layer'
    )
    // we remove the download selection layer as we only wanna capture the stored shapes
    downloadSelectionLayer.destroy()

    const url = clonedStage.toDataURL({
      pixelRatio: ratioSetting,
    })

    clonedStage.destroy() // we cleanup the stage clonse

    const a = document.createElement('a')
    a.href = url
    a.download = 'Canvas Image.png'
    a.click()
  }

  const cancelDownloadSelection = () => {
    setDownloadSelection(null)
  }

  const prevDownloadSelection = usePrevious(downloadSelection)

  useEffect(() => {
    if (!prevDownloadSelection && downloadSelection) {
      // we need to attach transformer manually
      downloadSelectionTransformerRef.current.nodes([
        downloadSelectionRef.current,
      ])
      downloadSelectionTransformerRef.current.getLayer().batchDraw()
    }
  }, [downloadSelection, prevDownloadSelection])

  useKeypress(['Escape'], cancelDownloadSelection)

  const activateDownloadSelection = () => {
    // We initialize a rect on the page, placed in the middle of the screen with 60% width and height of the viewport
    const width = (window.innerWidth * 0.6) / props.scale
    const height = (window.innerHeight * 0.6) / props.scale
    const x =
      window.innerWidth / props.scale / 2 -
      width / 2 -
      props.stageRef.current.x() / props.scale
    const y =
      window.innerHeight / props.scale / 2 -
      height / 2 -
      props.stageRef.current.y() / props.scale
    setDownloadSelection({
      x,
      y,
      width,
      height,
    })
  }

  const downloadOptionsY = downloadSelection
    ? downloadSelection.y * props.scale +
      props.stageRef.current.y() +
      downloadSelection.height * props.scale
    : null

  const downloadOptionsX = downloadSelection
    ? downloadSelection.x * props.scale +
      props.stageRef.current.x() +
      (downloadSelection.width / 2) * props.scale -
      110
    : null

  // if the selection gets too close to the prompt input we move it up
  const showOptionsInsideRect = downloadSelection
    ? window.innerHeight - 130 < downloadOptionsY
    : false

  const shouldShowDownloadOptions =
    downloadSelection &&
    !isDraggingDownloadSelection &&
    !isTransformingDownloadSelecton

  return {
    isDownloadSelectionActive: Boolean(downloadSelection),
    activateDownloadSelection,
    cancelDownloadSelection,
    isDraggingDownloadSelection,
    downloadSelectionCanvasComponent: downloadSelection ? (
      <Layer id="download-selection-layer">
        <Rect
          fill={`${theme.colors.accent[2]}20`}
          x={downloadSelection.x}
          y={downloadSelection.y}
          width={downloadSelection.width}
          height={downloadSelection.height}
          draggable
          ref={downloadSelectionRef}
          onDragStart={() => {
            setIsDraggingDownloadSelection(true)
          }}
          onDragEnd={(e: any) => {
            setDownloadSelection((prev) => {
              if (!prev) return prev
              return {
                ...prev,
                x: e.target.x(),
                y: e.target.y(),
              }
            })
            setIsDraggingDownloadSelection(false)
          }}
          onTransformStart={() => {
            setIsTransformingDownloadSelection(true)
          }}
          onTransformEnd={() => {
            setIsTransformingDownloadSelection(false)
            const node = downloadSelectionRef.current
            if (!node) return null
            const scaleX = node.scaleX()
            const scaleY = node.scaleY()
            node.scaleX(1)
            node.scaleY(1)
            setDownloadSelection((prev) => {
              if (!prev) return prev
              return {
                ...prev,
                x: node.x(),
                y: node.y(),
                // set minimal value
                width: Math.max(5, node.width() * scaleX),
                height: Math.max(node.height() * scaleY),
              }
            })
          }}
        />
        <Transformer
          ref={downloadSelectionTransformerRef}
          anchorSize={10}
          anchorStrokeWidth={0}
          anchorFill={theme.colors.accent[2]}
          anchorCornerRadius={10}
          borderDash={[3, 3]}
          keepRatio={false}
          rotateEnabled={false}
          borderStroke={theme.colors.accent[2]}
          boundBoxFunc={(oldBox: any, newBox: any) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox
            }
            return newBox
          }}
        />
      </Layer>
    ) : null,
    downloadSelectionOptionsComponent: (
      <AnimatePresence>
        {shouldShowDownloadOptions && (
          <StyledDownloadOptions2
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1, ease: 'easeIn' }}
            x={downloadOptionsX!}
            y={downloadOptionsY + (showOptionsInsideRect ? -70 : +10)}
          >
            <Flex justifyContent="space-between" css={{ width: '190px' }}>
              <Select
                value={downloadRatioSetting}
                onValueChange={(value) => setDownloadRatioSetting(value)}
                options={[
                  { value: '0.5', description: '0.5x' },
                  { value: '1', description: '1x' },
                  { value: '2', description: '2x' },
                  { value: '3', description: '3x' },
                  { value: '4', description: '4x' },
                  { value: '5', description: '5x' },
                ]}
              />
              <StyledButtonMedium
                style={{
                  background: theme.colors.accent[2],
                  border: 0,
                  color: theme.colors.text.neutral[0],
                }}
                onClick={handleDownload}
                hasText
              >
                <Download width={16} />
                Download
              </StyledButtonMedium>
            </Flex>
          </StyledDownloadOptions2>
        )}
      </AnimatePresence>
    ),
  }
}

type StyledDownloadOptionsProps = {
  x: number
  y: number
}

const StyledDownloadOptions2 = styled(
  motion.div
).attrs<StyledDownloadOptionsProps>(({ x, y }) => ({
  style: {
    transform: `translate3d(${x}px, ${y}px, 0)`,
  },
}))<StyledDownloadOptionsProps>(
  ({ theme }) => css`
    z-index: 8;
    position: fixed;
    left: 0;
    top: 0;
    background: ${theme.colors.background[4]};
    width: 220px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
    border-radius: ${theme.borderRadius.default};
  `
)
