import React from 'react'

import { Tooltip } from './Tooltip/Tooltip'

interface PermissionWrapperProps {
  className?: string
  feature: string
  position?: 'bottom' | 'right' | 'left' | 'top'
  isAllowed: boolean
  deniedMessage?: string
  children: ((isAllowed: boolean) => JSX.Element) | JSX.Element
}

const PermissionWrapper: React.FC<PermissionWrapperProps> = ({
  feature,
  children,
  position = 'bottom',
  isAllowed,
  className,
  deniedMessage = "You don't have permission to",
}) => {
  return (
    <Tooltip
      distance={8}
      position={position}
      title={isAllowed ? undefined : `${deniedMessage} ${feature}`}
      className={className}
      isDisabled={isAllowed}
    >
      {typeof children === 'function'
        ? children(isAllowed)
        : React.cloneElement(children, { disabled: !isAllowed })}
    </Tooltip>
  )
}

export default PermissionWrapper
