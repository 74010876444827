import { client } from 'apollo'

import {
  SaveWorkspaceItemsToBoardDocument,
  SaveWorkspaceItemsToBoardMutation,
  SaveWorkspaceItemsToBoardMutationVariables,
} from 'generated/graphql'
import {
  getCustomErrorStatus,
  getGraphqlErrorCode,
} from 'helpers/parseGraphqlErrors'

export async function saveToBoard(
  workspaceItemIds: string[],
  boardId: string,
  workspaceId: string
) {
  try {
    await client.mutate<
      SaveWorkspaceItemsToBoardMutation,
      SaveWorkspaceItemsToBoardMutationVariables
    >({
      variables: {
        workspaceItemIds,
        workspaceId,
        boardId,
      },
      mutation: SaveWorkspaceItemsToBoardDocument,
    })
    return {
      error: null,
    }
  } catch (error) {
    const code = getGraphqlErrorCode(error)
    const status = getCustomErrorStatus(error)

    return {
      error: status || code || 'UNKNOWN',
    }
  }
}
