import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

import SaveToBoard from 'components/itemsavetoboard/SaveToBoard'
import SaveToBoardMini from 'components/itemsavetoboard/SaveToBoardMini'
import ItemTags from 'components/itemtags/ItemTags'
import BlockKeyDownPropagation from 'helpers/BlockKeyDownPropagation'

import type { ItemData } from './hooks/useGetItemData'

export const ItemSiderContainer = styled.div`
  ${({ theme }) => css`
    width: 320px;
    border-radius: 0 16px 16px 0;
    background: ${theme.colors.background[3]};
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
  `}
`

const MenuContainer = styled.div`
  ${({ theme }) => css`
    width: 320px;
    border-radius: 0 16px 16px 0;
    background: ${theme.colors.background[3]};
  `}
`

const Padder = styled.div`
  padding: 12px;
`

const ScrollContainer = styled.div`
  position: absolute;
  width: 320px;
  padding-right: 6px;
`

interface ItemSiderProps {
  item: ItemData
}

const ItemSider: React.FC<ItemSiderProps> = ({ item }) => {
  const [menuView, setMenuView] = useState<
    'SELECT_BOARD' | 'SET_BOARD_TITLE' | null
  >(null)

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      const inputTypeHasFocus = document.activeElement
        ? Object.values(document.activeElement)[0]?.type === 'input'
        : false

      if (e.key === 'a' && !inputTypeHasFocus && menuView === null) {
        setMenuView('SELECT_BOARD')
        e.preventDefault()
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [menuView])

  const workspaceItemId =
    item.__typename === 'BoardItem' ? item.workspaceItemId : item.id

  return (
    <>
      {menuView ? (
        <BlockKeyDownPropagation>
          <MenuContainer>
            <SaveToBoard
              workspaceItemIds={[workspaceItemId]}
              defaultSelectView={menuView}
              onMenuClose={() => setMenuView(null)}
            />
          </MenuContainer>
        </BlockKeyDownPropagation>
      ) : (
        <ItemSiderContainer>
          <ScrollContainer>
            <Padder>
              <SaveToBoardMini
                workspaceItemId={workspaceItemId}
                onMenuOpenClick={(view: 'SELECT_BOARD' | 'SET_BOARD_TITLE') =>
                  setMenuView(view)
                }
              />
              <ItemTags
                itemId={item.id}
                itemType={item.__typename}
                allowedToEditTags={['OWNER', 'WRITER'].includes(item.userRole)}
                workspaceItemId={workspaceItemId}
                tags={item.tags}
              />
            </Padder>
          </ScrollContainer>
        </ItemSiderContainer>
      )}
    </>
  )
}

export default ItemSider
