import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

import { Label } from '../../common/Form'
import Spinner from '../../common/Spinner'
import useToastMessages from '../../toast/useToastMessages'
import { Button, ErrorMessage, Input } from '../SettingsStyles'
import useSettingNavigation from '../useSettingsNavigation'

export default function InviteForm({ workspace, close, createInvitation }) {
  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
  } = useForm({ defaultValues: { email: '' } })
  const { reportSuccess } = useToastMessages()
  const { sectionUrl } = useSettingNavigation()
  const onSubmit = async (fields) => {
    const cleanEmail = fields.email.toLowerCase().trim() // Enforce lowercase and no trailing spaces
    await createInvitation(cleanEmail, workspace.id)
    reportSuccess(`Invitation sent to ${cleanEmail}`)
    close()
  }

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Input
        type="email"
        required
        align="left"
        {...register('email', {
          pattern: {
            value:
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: 'invalid email address',
          },
        })}
        name="email"
        id="email"
        style={{ width: '100%' }}
        placeholder="Email"
      />
      {errors.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}
      <Label htmlFor="email">
        Enter email address to invite. Adding members to workspace will add one
        seat to your current <Link to={sectionUrl('plans')}>billing plan</Link>.
      </Label>
      <Button
        style={{ margin: '24px 0', minWidth: 200 }}
        disabled={isSubmitting}
      >
        {isSubmitting ? <Spinner size={14} /> : 'Send invite'}
      </Button>
    </form>
  )
}
