import { CustomFile } from 'components/upload/types'
import { WorkspaceBasicFieldsFragment } from 'generated/graphql'
import { WorkspaceWithId } from 'types/custom'

export const SET_THEME = 'SET_THEME'
export const SET_ACTIVE_WORKSPACE = 'SET_ACTIVE_WORKSPACE'
export const SET_FOCUSED_ITEM = 'SET_FOCUSED_ITEM'
export const SET_LAST_UPLOAD_DROP = 'SET_LAST_UPLOAD_DROP'
export const SET_IS_UPLOAD_VISIBLE = 'SET_IS_UPLOAD_VISIBLE'
export const SET_ACTIVE_EXPORT = 'SET_ACTIVE_EXPORT'
export const SET_IS_FOCUSED_ITEM_SPOILER_VISIBLE =
  'SET_IS_FOCUSED_ITEM_SPOILER_VISIBLE'
export const SET_IS_SOCIAL_ASSET_MODAL_OPEN = 'SET_IS_SOCIAL_ASSET_MODAL_OPEN'

interface SetTheme {
  type: typeof SET_THEME
  theme: 'darkTheme' | 'lightTheme'
}
interface SetActiveWorkspaceByUrlAction {
  type: typeof SET_ACTIVE_WORKSPACE
  activeWorkspace: WorkspaceWithId | null
}
interface SetActiveWorkspaceAction {
  type: typeof SET_ACTIVE_WORKSPACE
  activeWorkspace: WorkspaceWithId | WorkspaceBasicFieldsFragment | null
}

interface SetFocusedItemAction {
  type: typeof SET_FOCUSED_ITEM
  item: any
}

interface SetIsFocusedItemSpoilerVisibleAction {
  type: typeof SET_IS_FOCUSED_ITEM_SPOILER_VISIBLE
  isFocusedItemSpoilerVisible: boolean
}

interface SetLastUploadDropAction {
  type: typeof SET_LAST_UPLOAD_DROP
  files: File[]
}

interface SetIsUploadVisible {
  type: typeof SET_IS_UPLOAD_VISIBLE
  isVisible: boolean
}

export interface ActiveExport {
  itemIds?: string[]
  uploadSessionId?: string
  boardId?: string
  boardTitle?: string
}

interface SetActiveExport {
  type: typeof SET_ACTIVE_EXPORT
  activeExport?: ActiveExport
}

interface SetIsSocialAssetModalOpen {
  type: typeof SET_IS_SOCIAL_ASSET_MODAL_OPEN
  isSocialAssetModalOpen: boolean
}

export type ContentActionTypes =
  | SetTheme
  | SetActiveWorkspaceByUrlAction
  | SetActiveWorkspaceAction
  | SetFocusedItemAction
  | SetLastUploadDropAction
  | SetIsUploadVisible
  | SetActiveExport
  | SetIsFocusedItemSpoilerVisibleAction
  | SetIsSocialAssetModalOpen

export interface ContentState {
  theme: 'darkTheme' | 'lightTheme'
  lastUploadDrop: CustomFile[]
  isUploadVisible: boolean
  activeWorkspace: WorkspaceWithId | WorkspaceBasicFieldsFragment | null
  focusedItem?: any
  activeExport?: ActiveExport
  isFocusedItemSpoilerVisible: boolean
  isSocialAssetModalOpen: boolean
}
