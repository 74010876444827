import styled, { DefaultTheme, css } from 'styled-components'

export const Container = styled.div`
  ${({ theme, style }) => css`
    margin: auto;
    padding: 32px;
    padding-top: 120px;
    width: 100%;
    max-width: 1200px;
    color: ${theme.colors.white};
    @media (max-width: 1000px) {
      padding-top: 168px;
    }
  `}
`

export const CardContainer = styled.div`
  ${({ theme, style }) => css`
    display: flex;
    width: 100%;
    @media (max-width: 1000px) {
      flex-direction: column;
    }
  `}
`

export const Heading = styled.div`
  ${({ theme, style }) => css`
    font-family: ${theme.fontFamily.heading};
    font-size: ${theme.fontSizes['4xl']};
    font-weight: ${theme.fontWeight.bold};
    line-height: ${theme.lineHeight.tight};
    color: ${theme.colors.white};
    @media (max-width: 1000px) {
      font-size: ${theme.fontSizes['3xl']};
    }
  `}
`

export const HeadingSmall = styled(Heading)`
  ${({ theme }) => css`
    line-height: ${theme.lineHeight.tight};
    font-size: ${theme.fontSizes['2xl']};
    color: ${theme.colors.gray.light[0]};
  `}
`

export const InfoCard = styled.div`
  ${({ theme, style }) => css`
    flex: 0 1 auto;
    padding-right: 24px;
    margin-top: 32px;
    margin-right: 8px;
    width: 33.3%;
    @media (max-width: 1000px) {
      width: 100%;
    }
  `}
`

export const VisualBlock = styled.div`
  ${({ theme, image }: { image?: string; theme: DefaultTheme }) => css`
    border-radius: ${theme.borderRadius.lg};
    overflow: hidden;
    background-image: url(${image});
    background-size: cover;
    background-position: center center;
    height: 320px;
    width: 320px;
    margin: 16px 0px 16px 0px;
  `}
`

export const FloatingBox = styled.div`
  ${({ theme, style }) => css`
    position: fixed;
    right: 72px;
    top: 32px;
    width: 260px;
    background: ${theme.colors.accent[2]};
    padding: 16px;
    border-radius: ${theme.borderRadius.lg};
    @media (max-width: 1000px) {
      width: calc(100% - 64px);
      right: 32px;
      top: 32px;
    }
  `}
`

export const FloatingBoxRow = styled.div`
  ${({
    align = 'left',
    fill,
  }: {
    align?: 'left' | 'right' | 'center'
    fill?: boolean
  }) => css`
    text-align: ${align};
  `}
`

export const FloatingBoxHeading = styled(Heading)`
  ${({ theme }) => css`
    line-height: ${theme.lineHeight.none};
    font-size: ${theme.fontSizes.lg};
    width: 50%;
    margin-bottom: 8px;
    @media (max-width: 1000px) {
      width: 100%;
    }
  `}
`
