import React from 'react'

interface SvgProps {
  color?: string
  height?: number
  width?: number
}

export const KiveLogo: React.FC<SvgProps> = ({ color, height, width }) => (
  <svg
    width={width ? `${width}px` : undefined}
    height={height ? `${height}px` : undefined}
    style={{ fill: color || '#ffffff' }}
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 1080 1080"
  >
    <g>
      <rect x="809.8" y="-0.3" width="270" height="270" />
    </g>
    <g>
      <rect x="-0.1" width="270" height="1080" />
    </g>
    <path
      d="M697.6,652.1C595.4,550,539.2,414.2,539.2,269.7H269.9c0,447.4,362.6,810,810,810V810.5
	C935.5,810.5,799.7,754.2,697.6,652.1z"
    />
  </svg>
)
