import React, { useRef, useState } from 'react'
import AutosizeInput from 'react-input-autosize'
import { useSelector } from 'react-redux'
import { useTheme } from 'styled-components'

import {
  CoverInfo,
  ImageContainer,
  Overlay,
  StyledBorderWrapper,
} from './LibraryStyles'

export const Cover = ({ children, justifyContent, ...props }) => {
  const isLightTheme = useSelector(
    (state) => state.content.theme === 'lightTheme'
  )
  return (
    <ImageContainer
      style={{ justifyContent, paddingLeft: 32 }}
      height={props.height}
      src={props.src}
    >
      {props.src && <Overlay $isLightTheme={isLightTheme} />}
      <CoverInfo>{children}</CoverInfo>
    </ImageContainer>
  )
}

export const Border = ({ children, highlight, shouldAnimate, ...props }) => {
  const theme = useTheme()

  return (
    <StyledBorderWrapper
      $shouldAnimate={shouldAnimate}
      animate={{
        background: shouldAnimate
          ? [
              theme.colors.accent[0],
              theme.colors.accent[1],
              theme.colors.accent[2],
              theme.colors.accent[0],
            ]
          : theme.colors.background[3],
      }}
      transition={{ repeat: shouldAnimate ? Infinity : 0 }}
    >
      {children}
    </StyledBorderWrapper>
  )
}

export const AnimatedCard = ({ children, ...props }) => (
  <Border shouldAnimate={props.processing}>{children}</Border>
)

export const AutoSizeTextArea = (props) => {
  const theme = useTheme()
  const textAreaRef = useRef(null)

  const handleKeyDown = (e) => {
    // enter
    if (!e.altKey && e.key === 'Enter' && !window.event.shiftKey) {
      e.nativeEvent.stopImmediatePropagation()
      e.target.blur()
    }
    // cmd (⌘) + a
    if ((e.metaKey && e.key === 'a') || (e.ctrlKey && e.key === 'a')) {
      e.nativeEvent.stopImmediatePropagation()
    }
    // alt + enter
    if (e.altKey && e.key === 'Enter') {
      const el = e.currentTarget

      const cursorStart = el.selectionStart
      const cursorEnd = el.selectionEnd
      const inputText = el.value
      const textBeforeCursor = inputText.substring(0, cursorStart)
      const textAfterCursor = inputText.substring(cursorEnd, inputText.length)
      el.value = `${textBeforeCursor}\r\n${textAfterCursor}`
      // Jump down to the next line
      el.selectionStart = cursorStart + 1
      el.selectionEnd = cursorStart + 1

      // Set the height of the textarea to the height of all the lines
      el.style.height = `${e.currentTarget.scrollHeight}px`
    }
  }

  const autoSize = (e) => {
    // eslint-disable-next-line no-param-reassign
    e.style.height = '5px' // Resets it to 5px in case the user removes a row
    // eslint-disable-next-line no-param-reassign
    e.style.height = `${e.scrollHeight}px` // Sets the height to the textareas scrollHeight i.e. the height for each new row with content
  }

  if (textAreaRef.current !== null) {
    autoSize(textAreaRef.current)
  }

  return (
    <textarea
      ref={textAreaRef}
      onChange={(e) => props.onChange(e)}
      onKeyDown={(e) => handleKeyDown(e)}
      onBlur={props.onBlur}
      disabled={props.disabled}
      rows="1"
      value={props.value}
      style={{
        overflow: 'hidden',
        border: 'none',
        outline: 'none',
        resize: 'none',
        width: `${props.width}px`,
        maxWidth: '70vw',
        background: 'none',
        color: 'white',
        fontFamily: theme.fontFamily.primary,
      }}
    />
  )
}

export const EditableTitle = ({ initialValue, onSubmit }) => {
  const [title, setTitle] = useState(initialValue)
  const theme = useTheme()

  return (
    <AutosizeInput
      name="form-field-name"
      className="abc"
      disabled={false}
      value={title}
      onChange={(e) => setTitle(e.target.value)}
      onBlur={(e) => onSubmit(e.target.value)}
      onFocus={() => null}
      inputStyle={{
        fontSize: 'inherit',
        fontWeight: theme.fontWeight.semibold,
        border: 'none',
        textAlign: 'center',
        outline: 'none',
        color: theme.colors.text.neutral[0],
        background: 'none',
        maxWidth: '70vw',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    />
  )
}
