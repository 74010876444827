import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import {
  isArrowDown,
  isArrowHorizontal,
  isArrowKey,
  isArrowLeft,
  isArrowRight,
  isArrowUp,
  isArrowVertical,
} from './arrowKeys'

/* Create a context for keyboard navigation */
const KeyboardNavigationContext = createContext({
  navRefs: [],
  addNavRef: () => null,
  removeNavRef: (ref) => null,
  setFocusedRef: (ref) => null,
})

function useKeyboardNavigation() {
  return useContext(KeyboardNavigationContext)
}

// Create a simple boolean context that Navigatable can send down to its children to inform of whether it is focused
const FocusContext = createContext(false)

export function useFocus() {
  return useContext(FocusContext)
}

export function KeyboardNavigationProvider({ children }) {
  const [navRefs, setNavRefs] = useState([])
  const containerRef = useRef(null)

  /* Add, remove refs and set focus */
  const addNavRef = useCallback(() => {
    const newRef = React.createRef()
    setNavRefs((oldState) => [...oldState, { isFocused: false, ref: newRef }])
    return newRef
  }, [])

  const removeNavRef = useCallback((navRef) => {
    // Not sure if changing to strict equality would break anything
    // eslint-disable-next-line eqeqeq
    setNavRefs((refs) => refs.filter((ref) => navRef != ref))
  }, [])

  const setFocusedRef = useCallback((refToFocus) => {
    setNavRefs((refs) =>
      refs.map((ref) => {
        if (ref.ref === refToFocus) return { isFocused: true, ref: refToFocus }
        return { isFocused: false, ref: ref.ref }
      })
    )
  }, [])

  /* If navRefs change, make sure we have valid ref active
  (this makes sure an item is focused if navigatableItems change) */
  useEffect(() => {
    try {
      const focusedItem = navRefs.find((r) => r.isFocused)
      if (!focusedItem || !focusedItem?.ref.current) {
        const newRef = navRefs.filter((r) => r.ref?.current)[0]?.ref
        if (newRef) {
          setFocusedRef(newRef)
        }
      }
    } catch (e) {
      console.log('Error:', e)
    }
  }, [navRefs])

  /* Handle key events */
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [navRefs, containerRef])

  const handleKeyDown = (e) => {
    if (isArrowKey(e.key)) return moveItemFocus(e.key)
  }

  const moveItemFocus = (key) => {
    const validRefs = navRefs.filter((r) => r.ref?.current)
    const focusedItem = validRefs.find((r) => r.isFocused)
    const focusedItemRef = focusedItem ? focusedItem.ref : validRefs[0]?.ref
    if (!focusedItemRef) return

    const gridContainerRect = containerRef.current.getBoundingClientRect()
    const currentPosition = focusedItemRef.current.getBoundingClientRect()

    let next = getNextRefFromPosition(navRefs, currentPosition, key)

    /* If focused item is an edge item, wrap around edges */
    if (!next) {
      if (isArrowLeft(key)) {
        currentPosition.x = gridContainerRect.x + gridContainerRect.width + 1
      }
      if (isArrowRight(key)) currentPosition.x = gridContainerRect.x - 1
      if (isArrowDown(key)) currentPosition.y = gridContainerRect.y - 1
      if (isArrowUp(key)) {
        currentPosition.y = gridContainerRect.y + gridContainerRect.height + 1
      }
      next = getNextRefFromPosition(navRefs, currentPosition, key)
    }

    if (!next) return

    // Update focus
    next.ref.current.focus()
    setFocusedRef(next.ref)

    // If item is out of view, scroll it smoothly
    next.ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    })
  }

  const getNextRefFromPosition = (navRefs_, currentPosition, key) => {
    // Corridor size is permitted difference in opposite axis coordinate
    const corridorSize = 200

    const nextRefCandidates = navRefs_
      .filter((navRef) => {
        /* Only allow elements in arrow direction */
        if (!navRef.ref.current) return false
        const rect = navRef.ref.current.getBoundingClientRect()
        if (isArrowLeft(key) && rect.x < currentPosition.x) return true
        if (isArrowRight(key) && rect.x > currentPosition.x) return true
        if (isArrowDown(key) && rect.y > currentPosition.y) return true
        if (isArrowUp(key) && rect.y < currentPosition.y) return true
        return false
      })
      .filter((navRef) => {
        /* Only allow elements within corridor */
        const rect = navRef.ref.current.getBoundingClientRect()
        if (
          isArrowHorizontal(key) &&
          Math.abs(rect.y - currentPosition.y) < corridorSize
        ) {
          return true
        }
        if (
          isArrowVertical(key) &&
          Math.abs(rect.x - currentPosition.x) < corridorSize
        ) {
          return true
        }
        return false
      })
      .sort((a, b) => {
        /* Sort candidates by combined x and y offset */
        const aRect = a.ref.current.getBoundingClientRect()
        const bRect = b.ref.current.getBoundingClientRect()
        const aDifX = Math.abs(aRect.x - currentPosition.x)
        const aDifY = Math.abs(aRect.y - currentPosition.y)
        const bDifX = Math.abs(bRect.x - currentPosition.x)
        const bDifY = Math.abs(bRect.y - currentPosition.y)
        return aDifX + aDifY - (bDifX + bDifY)
      })

    // Return the first candidate
    return nextRefCandidates[0]
  }

  const contextValue = useMemo(
    () => ({ navRefs, addNavRef, removeNavRef, setFocusedRef }),
    [navRefs, addNavRef, removeNavRef, setFocusedRef]
  )

  return (
    <div ref={containerRef}>
      <KeyboardNavigationContext.Provider value={contextValue}>
        {children}
      </KeyboardNavigationContext.Provider>
    </div>
  )
}

export function Navigatable({ children, commands, ...props }) {
  const { navRefs, addNavRef, removeNavRef, setFocusedRef } =
    useKeyboardNavigation()
  const [navRef, setNavRef] = useState()

  useEffect(() => {
    setNavRef(addNavRef())
    return () => removeNavRef(navRef)
  }, [])

  const checkActive = () => {
    const refObject = navRefs.find((ref) => ref.ref === navRef)
    return refObject?.isFocused
  }

  /* Handle key events */
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [navRefs, commands])

  const handleKeyDown = (e) => {
    if (commands && checkActive()) {
      const command = commands.find((command_) =>
        Object.keys(command_.conditions).every(
          (cKey) => command_.conditions[cKey] === e[cKey]
        )
      )
      if (command) command.action(e)
    }
  }

  // Check if focused
  const contextValue = useMemo(() => checkActive(), [navRefs])

  return (
    <div
      onMouseEnter={(e) => setFocusedRef(navRef)}
      style={{
        display: 'inline-block',
        ...props.style,
      }}
      ref={navRef}
    >
      <FocusContext.Provider value={contextValue}>
        {children}
      </FocusContext.Provider>
    </div>
  )
}
