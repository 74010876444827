import { CustomFile, UploadSession } from 'components/upload/types'
import { db, firebase, storage } from 'config/firebase'
import {
  trackVideoExtractionUploadCompleted,
  trackVideoExtractionUploadStarted,
} from 'helpers/tracking/tracking'

interface StartSessionArgs {
  videoName: string
  videoFile: CustomFile
  workspaceId: string
  currentUserId: string
  updateLocalSession: (
    sessionId: string,
    data:
      | Partial<UploadSession>
      | ((uploadSession: UploadSession) => Partial<UploadSession>)
  ) => any
  frameExtractionItemType: 'image' | 'gif'
}

const startVideoFrameExtractionSession = async ({
  videoName,
  videoFile,
  workspaceId,
  currentUserId,
  updateLocalSession,
  frameExtractionItemType,
}: StartSessionArgs) => {
  const uploadSessionRef = db.collection('uploadSessions').doc()
  const extension = videoFile.type.split('/')[1]
  const videoPath = `videos-for-frames/${uploadSessionRef.id}.${extension}`
  const storageRef = storage.ref()
  const uploadRef = storageRef.child(videoPath)

  const metadata = {
    contentType: videoFile.type,
    customMetadata: {
      workspaceAccess: 'reader',
      workspaceId, // this is what we will use in firebase security rules
      owner: currentUserId,
    },
  }

  updateLocalSession(uploadSessionRef.id, {
    droppedFileCount: 1,
    uploadComplete: false,
    completedUploads: 0,
    bytesTransferredByFile: {},
    totalUploadSize: videoFile.size,
    videoFrameExtractor: true,
  })

  trackVideoExtractionUploadStarted({
    workspaceId,
    size: videoFile.size,
    name: videoFile.name,
    type: videoFile.type,
  })

  const uploadTask = uploadRef.put(videoFile, metadata)

  uploadTask.on('state_changed', (snapshot) => {
    const transferred = snapshot.bytesTransferred
    updateLocalSession(uploadSessionRef.id, (session) => ({
      bytesTransferredByFile: {
        ...session.bytesTransferredByFile,
        video: transferred,
      },
    }))
  })

  await uploadTask

  trackVideoExtractionUploadCompleted({
    workspaceId,
    uploadSessionId: uploadSessionRef.id,
    size: videoFile.size,
    name: videoFile.name,
    type: videoFile.type,
  })

  await uploadSessionRef.set({
    size: 0,
    uploaded: 0,
    analyzed: 0,
    processingFailed: 0,
    boardId: null,
    projectId: null,
    workspaceId,
    hasFinishedUploading: true,
    hasFinishedAnalyzing: false,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    createdBy: currentUserId,
    lastPing: firebase.firestore.FieldValue.serverTimestamp(),
    titleOverride: `${
      frameExtractionItemType === 'gif' ? 'Gifs' : 'Frames'
    } from ${videoName}`,
    origin: {
      type: 'frameExtraction',
      sourceInfo: {
        storagePath: videoPath,
        title: videoFile.name,
        frameExtractionItemType,
      },
    },
  })

  updateLocalSession(uploadSessionRef.id, {
    completedUploads: 1,
    uploadComplete: true,
    hasDBSession: true,
  })
}

export { startVideoFrameExtractionSession }
