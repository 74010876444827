import { useEffect, useRef } from 'react'
import { useMatch } from 'react-router-dom'

function usePrevious<T>(value: T) {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const useIsInProjectView = (handler?: (isInProjectView: boolean) => void) => {
  const isInProjectView = !!useMatch('/:workspaceSlug/p/:projectId/*')?.params
    ?.projectId

  const previousProject = usePrevious(isInProjectView)

  useEffect(() => {
    if (previousProject !== undefined && previousProject !== isInProjectView) {
      handler?.(isInProjectView)
    }
  }, [isInProjectView, previousProject, handler])

  return isInProjectView
}

export { useIsInProjectView }
