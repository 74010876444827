import { Eye } from '@styled-icons/bootstrap/Eye'
import { PencilSquare } from '@styled-icons/bootstrap/PencilSquare'
import { Like } from '@styled-icons/boxicons-regular/Like'
import { PersonRemoveOutline } from '@styled-icons/evaicons-outline/PersonRemoveOutline'

import { TextSelectOption } from 'components/common/TextSelect'

export const userOptions: TextSelectOption[] = [
  {
    key: 'reader',
    text: 'Can view',
  },
  {
    key: 'commenter',
    text: 'Can comment',
  },
  {
    key: 'writer',
    text: 'Can edit',
  },
  {
    key: 'owner',
    text: 'Owner',
  },
  {
    key: 'remove',
    text: 'Remove user',
  },
]

export const linkOptions: TextSelectOption[] = [
  {
    key: 'reader',
    text: 'Anyone with link can view',
    icon: Eye,
  },
  {
    key: 'commenter',
    text: 'Anyone with link can react & comment',
    icon: Like,
  },
  {
    key: 'writer',
    text: 'Anyone with link can edit',
    icon: PencilSquare,
  },
  {
    key: 'disabled',
    text: 'No link access',
    icon: PersonRemoveOutline,
  },
]
