import { useSelector } from 'react-redux'

import { RootState } from 'store'

import useBoardPermissions, { BoardPermissions } from './useBoardPermissions'

const useBoardPermissionsById = (
  boardId: string,
  askedPermissions: BoardPermissions[]
): Record<BoardPermissions, boolean | undefined> => {
  const board = useSelector(
    (state: RootState) => state.firestore.data[`activeBoards.${boardId}`]
  )
  return useBoardPermissions(board, askedPermissions)
}

export default useBoardPermissionsById
