import { Close } from '@styled-icons/material-sharp/Close'
import Menu4 from 'iconoir-react/dist/Menu'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import styled, { css, useTheme } from 'styled-components'

import ClickOutside from 'components/common/ClickOutside'
import { withRouter } from 'helpers/withRouter'
import useIsMobile from 'hooks/useIsMobile'

import { HeaderBlock } from './HeaderBlock'
import { HeaderBlockAnchor } from './HeaderBlock/styles'
import { CTADiv, GuideVertical, KiveInvertedLogo } from './LandingStyles'

export const TryButton = withRouter((props) => (
  <Link to={{ pathname: '/r', search: props.location.search }}>
    <CTADiv {...props.style} isSmall>
      Get early access
    </CTADiv>
  </Link>
))

const HeaderWrapper = styled.div(
  ({ theme }) => css`
    position: fixed;
    z-index: 10;
    display: flex;
    flex-direction: column;
    width: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: auto;
    text-rendering: optimizeLegibility;
    font-family: ${theme.fontFamily.primary};
    font-weight: 700;
    //box-shadow: 0 1px 0 hsla(0, 0%, 100%, 0.07);
  `
)
const HeaderContainer = styled.div(
  ({ theme, mobile, style }) => css`
    justify-content: space-between;
    font-family: ${theme.fontFamily.primary};
    font-weight: ${theme.fontWeight.medium};
    background-color: ${theme.colors.gray.dark['-1']};
    color: ${theme.colors.text.neutral[0]};
  `
)

const HeaderInner = styled.div((props) => ({
  backgroundColor: props.theme.colors.gray.dark['-1'],
  margin: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: props.center && 'center',
  width: '100%',
  ...props.style,
}))

const Ahref = styled.a(
  ({ theme }) => css`
    color: ${theme.colors.text.neutral[0]};
    transition: 0.1s all ease-in-out;
    :hover {
      color: ${theme.colors.accent[2]};
    }
  `
)

const MobileNavButton = styled.button(
  ({ theme }) => css`
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    background-color: ${theme.colors.gray.dark['-1']};
    color: white;
  `
)

const MobileNav = styled.div(
  ({ theme, isOpen }) => css`
    position: absolute;
    width: 100%;
    left: 0;
    top: ${isOpen ? '60px' : '50px'};
    opacity: ${isOpen ? 1 : 0};
    visibility: ${isOpen ? 'visible' : 'hidden'};
    transition: all 0.1s ease-in-out;
    background-color: ${theme.colors.gray.dark['-1']};
    color: white;
  `
)
const MobileNavItem = styled.a(
  ({ theme }) => css`
    display: block;
    color: white;
    padding: 1.2em 1.2rem 1em;
    box-shadow: 0 1px 0 hsla(0, 0%, 100%, 0.07);
  `
)

const KiveLogoHeaderBlock = ({ theme, isOnlyLogoVisisble }) => (
  <HeaderBlock
    style={{
      paddingLeft: 0,
    }}
  >
    <HeaderBlockAnchor
      isOnlyLogoVisisble={isOnlyLogoVisisble}
      href="/"
      rel="noreferrer"
    >
      <KiveInvertedLogo size={28} />
      Kive
    </HeaderBlockAnchor>
  </HeaderBlock>
)

function Header({ isNotFixed, isPure }) {
  const theme = useTheme()
  const isMobile = useIsMobile()
  const [isMobileNavVisible, setIsMobileNavVisible] = useState(false)

  return (
    <HeaderWrapper
      css={`
        position: ${isNotFixed && isMobile ? 'relative' : 'fixed'};
      `}
    >
      <HeaderContainer>
        <GuideVertical>
          <HeaderInner>
            <KiveLogoHeaderBlock theme={theme} isOnlyLogoVisisble={isMobile} />
            <HeaderBlock isHidden={isMobile || isPure}>
              <Ahref href="/pricing" rel="noreferrer">
                Pricing
              </Ahref>
            </HeaderBlock>

            <HeaderBlock isHidden={isMobile || isPure}>
              <Ahref
                href="https://life.kive.ai"
                target="_blank"
                rel="noreferrer"
              >
                We&apos;re hiring!
              </Ahref>
            </HeaderBlock>
            {isMobile ? (
              <>
                <HeaderBlock
                  right={isMobile || isPure}
                  style={{
                    paddingRight: 0,
                  }}
                />
                <HeaderBlock
                  isHidden={isMobile || isPure}
                  right
                  link="/login"
                  withUrlParams={false}
                >
                  Login
                </HeaderBlock>
              </>
            ) : (
              <>
                <HeaderBlock
                  isHidden={isMobile || isPure}
                  right
                  link="/login"
                  withUrlParams={false}
                >
                  Login
                </HeaderBlock>
                <HeaderBlock
                  isHidden={isPure}
                  right={isMobile}
                  style={{
                    paddingRight: 0,
                  }}
                >
                  <TryButton style={{ width: 'auto', height: 40, opacity: 0 }}>
                    Get access
                  </TryButton>
                </HeaderBlock>
              </>
            )}
            {isMobile ? (
              <ClickOutside
                handleClickOutside={() => setIsMobileNavVisible(false)}
              >
                <HeaderBlock isHidden={isPure}>
                  <MobileNavButton
                    onClick={() => setIsMobileNavVisible(!isMobileNavVisible)}
                  >
                    {isMobileNavVisible ? (
                      <Close height={28} width={28} />
                    ) : (
                      <Menu4 height={28} width={28} />
                    )}
                  </MobileNavButton>
                  <MobileNav isOpen={isMobileNavVisible}>
                    <MobileNavItem href="/login">Login</MobileNavItem>
                    <MobileNavItem href="/pricing">Pricing</MobileNavItem>
                    <MobileNavItem
                      href="https://life.kive.ai"
                      target="_blank"
                      rel="noreferrer"
                    >
                      We&apos;re hiring!
                    </MobileNavItem>
                  </MobileNav>
                </HeaderBlock>
              </ClickOutside>
            ) : null}
          </HeaderInner>
        </GuideVertical>
      </HeaderContainer>
    </HeaderWrapper>
  )
}

export default Header
