import loadScript from 'load-script'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { firebaseAuth, firebaseFunctionsDomain } from 'config/firebase'
import { reportError } from 'helpers/logging'
import { usePrevious } from 'hooks/usePrevious'
import { RootState } from 'store'

import { CustomDropboxFile, DropboxFile } from './types'

// INSPO: https://github.com/sdoomz/react-dropbox-chooser/blob/master/src/react-dropbox-chooser.js

const openPopupWindow = (url: string, windowName: string) => {
  if (!window?.top) {
    reportError(new Error('Window.top not supported'))
    return null
  }
  const width = 750
  const height = 750
  const left = window.top.outerWidth / 2 + window.top.screenX - width / 2
  const top = window.top.outerHeight / 2 + window.top.screenY - height / 2
  return window.open(
    url,
    windowName,
    `width=${width},height=${height},left=${left},top=${top}`
  )
}

const kiveDropboxAuthUrl = `${firebaseFunctionsDomain}/authDropbox`
const kiveDropboxAppKey = 'on1z0mucu1o8yo5' // TODO: Fix prod app key

const DROPBOX_SDK_URL = 'https://www.dropbox.com/static/api/2/dropins.js'
const SCRIPT_ID = 'dropboxjs'

let scriptLoadingStarted = false

interface DropboxPickerProps {
  onPickFiles: (files: CustomDropboxFile[]) => any
  onAuthSuccess: () => void
  cancel?: () => any
  linkType: 'preview' | 'direct'
  multiselect: boolean
  extensions: string[]
  folderselect: boolean
}

const useDropboxPicker = ({
  onPickFiles,
  onAuthSuccess,
  cancel,
  linkType,
  multiselect,
  extensions,
  folderselect,
}: DropboxPickerProps) => {
  const popupIntervalCheck = useRef<NodeJS.Timeout>()
  const [isLoading, setIsLoading] = useState(false)

  const dropbox = useSelector(
    (store: RootState) => store.firestore.data.userPrivate?.apps?.dropbox
  )

  const isConnecting = isLoading || dropbox?.status === 'connecting'
  const isConnected = dropbox?.status === 'connected'
  const previousIsConntected = usePrevious(isConnected)

  useEffect(() => {
    if (previousIsConntected === false && isConnected === true) {
      onAuthSuccess()
    }
  }, [isConnected, previousIsConntected, onAuthSuccess])

  useEffect(() => {
    if (!isDropboxPickerReady() && !scriptLoadingStarted) {
      scriptLoadingStarted = true
      loadScript(
        DROPBOX_SDK_URL,
        {
          attrs: {
            id: SCRIPT_ID,
            'data-app-key': kiveDropboxAppKey,
          },
        },
        (error) => {
          if (error) {
            console.error('Could not dropbox script', error)
          }
        }
      )
    }
  }, [])

  const isDropboxPickerReady = () => {
    return !!(window as any).Dropbox
  }

  const success = (files: DropboxFile[]) => {
    onPickFiles(files.map((file) => ({ ...file, isDropboxFile: true })))
  }

  const launchPicker = () => {
    if (!isDropboxPickerReady()) {
      console.error('Dropbox script was probably not loaded')
      return null
    }

    ;(window as any).Dropbox.choose({
      success,
      cancel,
      linkType,
      multiselect,
      extensions,
      folderselect,
    })
  }

  const launch = async () => {
    if (!isConnected) {
      setIsLoading(true)
      const idToken = await firebaseAuth().currentUser?.getIdToken()
      const windowRef = openPopupWindow(
        `${kiveDropboxAuthUrl}?token=${idToken}`,
        'kive-dropbox-auth'
      )
      popupIntervalCheck.current = setInterval(() => {
        if (!windowRef || windowRef.closed) {
          setIsLoading(false)
          if (popupIntervalCheck.current) {
            clearInterval(popupIntervalCheck.current)
          }
        }
      }, 100)
    } else {
      launchPicker()
    }
  }

  return { isConnected, isConnecting, launch }
}

export { useDropboxPicker }
