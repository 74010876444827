import searchImage1 from 'assets/img/guides/search-guide/search-1.jpg'
import searchImage2 from 'assets/img/guides/search-guide/search-2.jpg'
import searchImage3 from 'assets/img/guides/search-guide/search-3.jpg'
import searchImage4 from 'assets/img/guides/search-guide/search-4.jpg'
import searchImage5 from 'assets/img/guides/search-guide/search-5.jpg'
import searchImage6 from 'assets/img/guides/search-guide/search-6.jpg'
import { Spacer } from 'components/common/Spacer'
import { Text } from 'components/common/Text'

import { StyledContentList } from './contentStyles'

export const contentSearch = {
  contentHeight: 225,
  steps: [
    {
      coverUrl: searchImage1,
      content: (
        <div>
          <Text size="lg" color="neutral.0">
            Free text search
          </Text>
          <Spacer factor={1} />
          <Text size="sm" color="neutral.2">
            Search like you think. Describe what you need in your own words, and
            get as specific as you want.
          </Text>
        </div>
      ),
    },
    {
      coverUrl: searchImage2,
      content: (
        <div>
          <Text size="lg" color="neutral.0">
            Face search
          </Text>
          <Spacer factor={1} />
          <Text size="sm" color="neutral.2">
            Filter your search based on gender, shot type, emotion, age and head
            rotation. Different attributes can be combined for an even more
            granular search result.
          </Text>
        </div>
      ),
    },
    {
      coverUrl: searchImage3,
      content: (
        <div>
          <Text size="lg" color="neutral.0">
            Color search
          </Text>
          <Spacer factor={1} />
          <Text size="sm" color="neutral.2">
            Filter your search based on colors by{' '}
          </Text>
          <StyledContentList>
            <li>Using the color picker</li>
            <li>Entering a specific color code</li>
          </StyledContentList>
        </div>
      ),
    },
    {
      coverUrl: searchImage4,
      content: (
        <div>
          <Text size="lg" color="neutral.0">
            Tag search
          </Text>
          <Spacer factor={1} />
          <Text size="sm" color="neutral.2">
            Find images based on a specific category or theme.{' '}
          </Text>
          <StyledContentList>
            <li>
              Make narrower searches by specifying a distinct tag, or combine
              tags to refine your results even further.
            </li>
            <li>
              Different search types can also be combined to make your search
              even more precise.
            </li>
          </StyledContentList>
        </div>
      ),
    },
    {
      coverUrl: searchImage5,
      content: (
        <div>
          <Text size="lg" color="neutral.0">
            GIF search
          </Text>
          <Spacer factor={1} />
          <Text size="sm" color="neutral.2">
            Filter your search to only include GIFs when you need moving
            content.
          </Text>
        </div>
      ),
    },
    {
      coverUrl: searchImage6,
      content: (
        <div>
          <Text size="lg" color="neutral.0">
            Custom tags
          </Text>
          <Spacer factor={1} />
          <Text size="sm" color="neutral.2">
            Open an item to add your own tags or remove tags you don&apos;t
            want.
          </Text>
        </div>
      ),
    },
  ],
}
