import { SubscriptionInterval } from '../../types'
import { AnnualUpsellToggle } from '../AnnualUpsellToggle'
import {
  StyledAllTextContainer,
  StyledAnnualUpsellContainer,
  StyledArgument,
  StyledArgumentContainer,
  StyledDiscountPill,
  StyledPrice,
} from './AnnualUpsellStyles'
import { AnnualUpsellText } from './AnnualUpsellText'

type Props = {
  subscriptionInterval: SubscriptionInterval
  setSubscriptionInterval: (interval: SubscriptionInterval) => void
  discount: number
  price: number
}

export const AnnualUpsell = ({
  subscriptionInterval,
  setSubscriptionInterval,
  discount,
  price,
}: Props) => {
  const texts = AnnualUpsellText.en
  if (price === 0) return null
  return (
    <StyledAnnualUpsellContainer>
      <AnnualUpsellToggle
        subscriptionInterval={subscriptionInterval}
        setSubscriptionInterval={setSubscriptionInterval}
      />
      <StyledAllTextContainer>
        <StyledArgumentContainer>
          <StyledArgument>{texts.argument}</StyledArgument>
          <StyledDiscountPill>
            {texts.discount.template.replace(
              texts.discount.target,
              `${discount}`
            )}
          </StyledDiscountPill>
        </StyledArgumentContainer>
        <StyledPrice>
          {texts.price.template.replace(texts.price.target, `${price}`)}
        </StyledPrice>
      </StyledAllTextContainer>
    </StyledAnnualUpsellContainer>
  )
}
