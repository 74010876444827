/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export type SaveStatus = 'SAVED' | 'EXISTS' | 'NOT_SAVED'

export interface PublicItemsSavesState {
  saves: Record<string, SaveStatus>
}

const initialState: PublicItemsSavesState = {
  saves: {},
}

const publicItemsSavesSlice = createSlice({
  name: 'publicItemsSaves',
  initialState,
  reducers: {
    setSave: (
      state,
      { payload }: PayloadAction<{ id: string; status: SaveStatus }>
    ) => {
      state.saves[payload.id] = payload.status
    },
    removeSave: (state, { payload }: PayloadAction<{ id: string }>) => {
      delete state.saves[payload.id]
    },
  },
})

export const { setSave, removeSave } = publicItemsSavesSlice.actions

export default publicItemsSavesSlice.reducer
