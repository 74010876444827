import * as queryString from 'query-string'

import { db } from 'config/firebase'
import downloadItem from 'helpers/downloadItem'
import getItemSizeUrl from 'helpers/getItemSizeUrl'
import { trackItemLinkCopied } from 'helpers/tracking/tracking'

import { getItemVersions } from './getItemVersions'

const getItem = async (item) => {
  // we have to fetch all item data as typesense item does not consist of all data and the correct structure
  const res = await db.collection('searchableItems').doc(item.id).get()

  return res.data()
}

export async function download(item, versionName = 'Original', context = {}) {
  let publicUrl
  let internalPath
  let title
  // TODO: find a better way to check if it is an item from typesense
  if ('isTypesenseItem' in item) {
    const itemData = await getItem(item)
    title = itemData?.origin?.name
    if (itemData?.original?.path) {
      internalPath = itemData?.original?.path
    } else {
      publicUrl = getItemSizeUrl(itemData)
    }
  } else if ('__typename' in item) {
    title = item.title
    internalPath = item.original.path
  } else {
    const version = getItemVersions(item)?.find((v) => v.label === versionName)
    internalPath = version.path
    title = item.origin?.name
  }

  return downloadItem(
    {
      internalPath: internalPath || undefined,
      publicUrl: publicUrl || undefined,
    },
    title,
    context
  )
}

export function copyItemLink(item, versionName, context) {
  const context_ = context || {}
  const versionName_ = versionName || 'jpg-original'

  const version = item.versions[versionName_]
  navigator.clipboard.writeText(version.publicUrl)

  // Segment Tracking – Item Link Copied
  trackItemLinkCopied({
    version: version.path,
    versionLabel: version.label,
    ...context_,
  })
}

export function openItem(itemId, location, navigate) {
  const isBoardItem = location.pathname.includes('/p/')
  const parsed = queryString.parse(location.search, { arrayFormat: 'comma' })
  const updatedQuery = {
    ...parsed,
    item: itemId,
    itemSpace: isBoardItem ? 'projects' : 'uploads',
  }
  const updatedQueryString = queryString.stringify(updatedQuery, {
    arrayFormat: 'comma',
  })
  navigate(`${location.pathname}?${updatedQueryString}`)
}
