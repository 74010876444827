import 'firebase/compat/analytics'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'

export enum Environment {
  Local = 'LOCAL',
  Test = 'TEST',
  Production = 'PRODUCTION',
}

const FIREBASE_CONFIGS = {
  [Environment.Local]: {
    apiKey: 'AIzaSyA4lcD2SvzPKSkJN0ncbTugdOH3sKjizu4',
    authDomain: 'togather-test1.firebaseapp.com',
    databaseURL: 'http://localhost:8080',
    projectId: 'togather-test1',
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET_NAME,
    messagingSenderId: '979623825790',
    appId: '1:979623825790:web:a3cbf5334e9bbc7198232f',
    measurementId: 'G-RZS75PTWBH',
  },
  [Environment.Test]: {
    apiKey: 'AIzaSyA4lcD2SvzPKSkJN0ncbTugdOH3sKjizu4',
    authDomain: 'togather-test1.firebaseapp.com',
    databaseURL: 'https://togather-test1.firebaseio.com',
    projectId: 'togather-test1',
    storageBucket: 'togather-test1.appspot.com',
    messagingSenderId: '979623825790',
    appId: '1:979623825790:web:a3cbf5334e9bbc7198232f',
    measurementId: 'G-RZS75PTWBH',
  },
  [Environment.Production]: {
    apiKey: 'AIzaSyBYwZOIHYtiMznRurZI9TtJDhW0b-m97tI',
    authDomain: 'airpict.firebaseapp.com',
    databaseURL: 'https://airpict.firebaseio.com',
    projectId: 'airpict',
    storageBucket: 'airpict.appspot.com',
    messagingSenderId: '917215779217',
    appId: '1:917215779217:web:7d8e823a124fc9f2',
    measurementId: 'G-2WE6YMDNNB',
  },
}

const BASE_URLS = {
  [Environment.Local]: 'http://localhost:5001/togather-test1/us-central1',
  [Environment.Test]: 'https://us-central1-togather-test1.cloudfunctions.net',
  [Environment.Production]: 'https://us-central1-airpict.cloudfunctions.net',
}

if (!process.env.REACT_APP_ENV) {
  console.warn(
    `The application is running against the production environment. Create a .env.local file in the root directory and set REACT_APP_ENV to 'LOCAL' or 'TEST' to use another environment.`
  )
}

const activeEnv = process.env.REACT_APP_ENV
  ? process.env.REACT_APP_ENV
  : Environment.Production
const activeConfig = FIREBASE_CONFIGS[activeEnv]

firebase.initializeApp(activeConfig)

const db = firebase.firestore()
const firebaseAuth = firebase.auth
const storage = firebase.storage()

if (process.env.REACT_APP_ENV === Environment.Local) {
  db.useEmulator('localhost', 8080)
  console.log('connected to emulator')
}
const firebaseFunctionsDomain = BASE_URLS[activeEnv]

export { firebase, firebaseAuth, db, firebaseFunctionsDomain, storage }
