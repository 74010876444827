import { createSelector } from '@reduxjs/toolkit'
import { isLoaded } from 'react-redux-firebase'

import { allReactionsEmojis } from 'components/item/itemReactions/ItemReactionsBox'
import { RootState } from 'store'
import { DBItemRef } from 'types/db'

import { createDeepEqualSelector } from './utils'

const _workspacesSelector = (state: RootState) => {
  const adminWorkspaces = state.firestore.data.adminWorkspaces
  const memberWorkspaces = state.firestore.data.memberWorkspaces
  const guestWorkspaces = state.firestore.data.guestWorkspaces
  const allWorkspaces = {
    ...(guestWorkspaces || {}),
    ...(memberWorkspaces || {}),
    ...(adminWorkspaces || {}),
  }
  const allWorkspacesAreLoaded =
    isLoaded(adminWorkspaces) &&
    isLoaded(memberWorkspaces) &&
    isLoaded(guestWorkspaces)

  if (allWorkspacesAreLoaded) {
    return allWorkspaces
  }
  return null
}
export const boardItemsSelector = createDeepEqualSelector(
  (state: RootState, boardId: string) => {
    return allReactionsEmojis.reduce<Record<string, DBItemRef>>(
      (acc, reaction) => {
        return {
          ...acc,
          ...state.firestore.composite?.[`boardItems_${boardId}_${reaction}`],
        }
      },
      state.firestore.composite?.[`boardItems_${boardId}`] || {}
    )
  },
  (boardItemsData) => {
    const boardItems = Object.entries(boardItemsData ?? {}).map(
      ([id, item]) => ({
        ...item,
        id,
      })
    )
    const filteredItems = boardItems.filter((item) => {
      if (item.shouldDelete) return false
      if (!item.thumbs && !item.thumbUrl) return false // if item has not yet got its thumb we hide it
      return true
    })
    return filteredItems
  }
)

export const boardItemsIsLoadingSelector = createDeepEqualSelector(
  (state: RootState, boardId: string) => {
    const boardItemsDataForReactions = allReactionsEmojis.map((reaction) => {
      return state.firestore.status.requesting?.[
        `boardItems_${boardId}_${reaction}`
      ]
    })
    return [
      state.firestore.status.requesting?.[`boardItems_${boardId}`],
      ...boardItemsDataForReactions,
    ]
  },
  (requestingStatuses) => {
    return requestingStatuses.some(Boolean)
  }
)

export const workspacesSelector = createDeepEqualSelector(
  _workspacesSelector,
  (value) => value
)

export const isInMultiSelectModeSelector = createSelector(
  (state: RootState) => state.multiSelect.selectedItems,
  (selectedItemIds) => Object.keys(selectedItemIds).length > 0
)

export const publicItemsSaveSatusSelector = createSelector(
  (state: RootState, itemId: string) => state.publicItemsSaves.saves[itemId],
  (saveStatus) => saveStatus ?? 'NOT_SAVED'
)
