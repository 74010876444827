import { useEffect, useRef } from 'react'

export default function useClickOutside(
  callback: any,
  options?: { overrideClickListeners?: boolean }
) {
  const callbackRef = useRef<any>() // initialize mutable callback ref
  const innerRef = useRef<HTMLDivElement | null>(null) // returned to client, who sets the "border" element
  const overrideClickListeners = options?.overrideClickListeners ?? true
  // update callback on each render, so second useEffect has most recent callback
  useEffect(() => {
    callbackRef.current = callback
  })

  useEffect(() => {
    function handleClick(e: any) {
      if (
        innerRef.current &&
        callbackRef.current &&
        !innerRef.current?.contains(e.target)
      ) {
        callbackRef.current?.(e)
      }
    }
    document.addEventListener('click', handleClick, overrideClickListeners)
    return () =>
      document.removeEventListener('click', handleClick, overrideClickListeners)
  }, []) // no dependencies -> stable click listener

  return innerRef // convenience for client (doesn't need to init ref himself)
}
