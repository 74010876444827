import { client } from 'apollo'
import React, { useCallback, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css, useTheme } from 'styled-components'

import Loading from 'components/Loading'
import { Text } from 'components/common/Text'
import DiscoverGridItem from 'components/discover/DiscoverGridItem'
import Grid from 'components/grid/Grid'
import { GridColumnAdditioner } from 'components/grid/GridColumnAdditioner'
import { GridItemProps } from 'components/grid/griditem/GridItem'
import { permissionInfoText } from 'components/toast/ToastMessages'
import useToastMessages from 'components/toast/useToastMessages'
import { useSimilarWorkspaceItemsQuery } from 'generated/graphql'
import StopPropagation from 'helpers/StopPropagation'
import useWorkspacePermissions from 'hooks/useWorkspacePermissions'
import { RootState } from 'store'
import { setIsUploadVisible } from 'store/content/actions'

const MoreItemsContainer = styled.div`
  width: 100%;
  text-align: center;
  padding: 0 64px;
  height: 100%;
  @media (max-width: 500px) {
    padding: 0 12px;
  }
  @media (max-height: 580px) {
    padding: 0 80px;
  }
`

const HeadingLarge = styled.div`
  ${({ theme }) => css`
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.fontSizes.xl};
    color: ${theme.colors.text.neutral[0]};
    margin-bottom: 16px;
    @media (max-height: 580px) {
      font-size: ${theme.fontSizes.base};
    }
  `}
`

interface SimilarItemsProps {
  itemId: string
  scrollNode: React.MutableRefObject<HTMLDivElement | null>
  isDiscoveryItem: boolean
}

const SimilarItems: React.FC<SimilarItemsProps> = ({
  itemId,
  scrollNode,
  isDiscoveryItem,
}) => {
  const [, setRerender] = useState(false)
  const { reportError } = useToastMessages()
  const userAllowedTo = useWorkspacePermissions(['UPLOAD'])
  const theme = useTheme()
  const dispatch = useDispatch()
  const workspaceId = useSelector(
    (state: RootState) => state.content.activeWorkspace?.id
  )

  const { data, loading, fetchMore } = useSimilarWorkspaceItemsQuery({
    variables: {
      workspaceItemId: itemId,
      scope: isDiscoveryItem ? 'PUBLIC' : 'WORKSPACE',
      workspaceId: !isDiscoveryItem ? workspaceId : null,
    },
  })

  const pageInfo = data?.similarWorkspaceItems.pageInfo
  const nextCursor = pageInfo?.nextCursor
  const similarItems = data?.similarWorkspaceItems.similarItems ?? []
  const hasMore = Boolean(nextCursor) && similarItems.length > 0 && !loading

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        cursor: nextCursor,
      },
    })
  }

  const onDeleteItem = useCallback((workspaceItemId: string) => {
    client.cache.evict({ id: `WorkspaceItem:${workspaceItemId}` })
    client.cache.gc()
  }, [])

  const currentUserId = useSelector(
    (store: RootState) => store.firebase.auth.uid
  )

  useEffect(() => {
    // There is a conclict with our Masonory grid and Inifnite scroll
    // which makes the inifinite scroll to NOT fetch more if the grid content is small enough to not trigger the "fetch more threshold"
    // forcing a rerender solves it. Don't ask me why
    setTimeout(() => {
      setRerender((prev) => !prev)
    }, 0)
  }, [similarItems.length])

  const emptyContent = (
    <Text size="base" center>
      No similar items found. Try{' '}
      <span
        style={{
          color: theme.colors.accent[1],
          cursor: 'pointer',
        }}
        onClick={() => {
          if (!userAllowedTo.UPLOAD) {
            return reportError(permissionInfoText('Upload'))
          }
          dispatch(setIsUploadVisible(true))
        }}
      >
        uploading more items to your workspace.
      </span>
    </Text>
  )

  return (
    <>
      {scrollNode.current && (
        <MoreItemsContainer>
          <HeadingLarge>More like this</HeadingLarge>
          <StopPropagation>
            <div style={{ width: '100%' }}>
              <InfiniteScroll
                pageStart={0}
                threshold={1000}
                useWindow={false}
                getScrollParent={() => scrollNode.current}
                loadMore={handleLoadMore}
                hasMore={hasMore}
              >
                <GridColumnAdditioner
                  parentWrapper={scrollNode.current}
                  isHidden
                >
                  {(gridColumnAdditionOption) => {
                    if (isDiscoveryItem) {
                      return (
                        <Grid
                          key={itemId} // we use itemId as key to make sure Grid re-mounts (solves cached grid items positions)
                          customGridClassname="similar-grid"
                          scrollElement={scrollNode.current}
                          gridItems={similarItems}
                          isMultiSelectDisabled
                          gridColumnAdditionOption={gridColumnAdditionOption}
                          customGridItem={(props: GridItemProps) => (
                            <DiscoverGridItem
                              {...props}
                              currentUserId={currentUserId}
                            />
                          )}
                        />
                      )
                    }
                    return (
                      <Grid
                        key={itemId} // we use itemId as key to make sure Grid re-mounts (solves cached grid items positions)
                        customGridClassname="similar-grid"
                        gridColumnAdditionOption={gridColumnAdditionOption}
                        scrollElement={scrollNode.current}
                        gridItems={similarItems}
                        onDelete={onDeleteItem}
                      />
                    )
                  }}
                </GridColumnAdditioner>
                {loading && (
                  <div className="loader" key={0}>
                    <Loading fullscreen />
                  </div>
                )}
                {similarItems.length === 0 && !loading && (
                  <div style={{ width: '100%' }}>
                    {!isDiscoveryItem && emptyContent}
                  </div>
                )}
              </InfiniteScroll>
            </div>
          </StopPropagation>
        </MoreItemsContainer>
      )}
    </>
  )
}

export default SimilarItems
