import { Reference } from '@apollo/client'
import { Add } from '@styled-icons/material/Add'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useTheme } from 'styled-components'

import { Text } from 'components/common/Text'
import type { ItemData } from 'components/item/hooks/useGetItemData'
import { permissionInfoText } from 'components/toast/ToastMessages'
import {
  BoardItemTagFieldsFragmentDoc,
  MagicBoard,
  UserRecentTag,
  WorkspaceItemTagFieldsFragmentDoc,
  useAddWorkspaceItemTagMutation,
  useDeleteWorkspaceItemTagMutation,
} from 'generated/graphql'
import BlockKeyDownPropagation from 'helpers/BlockKeyDownPropagation'
import { getCustomErrorStatus } from 'helpers/parseGraphqlErrors'
import { useActiveWorkspace } from 'hooks/useActiveWorkspace'

import { trackItemTagClicked } from '../../helpers/tracking/tracking'
import useToastMessages from '../toast/useToastMessages'
import ItemTag from './ItemTag'
// eslint-disable-next-line import/no-cycle
import ItemTagEditable from './ItemTagEditable'
import { AddTagWrapper, Header, IconButton } from './ItemTagStyles'

type NewTag = {
  description: string
}

export type TagProps = NewTag | UserRecentTag | MagicBoard

export const getTagIdForNewTag = (tag: TagProps) => {
  if ('tagId' in tag) {
    return tag.tagId
  }
  if ('id' in tag) {
    return tag.id
  }
  return null
}

interface Props {
  workspaceItemId: string
  itemId: string
  itemType: ItemData['__typename']
  tags: ItemData['tags']
  allowedToEditTags: boolean
  isEditable?: boolean
  isClickable?: boolean
  inDiscover?: boolean
}

const getFriendlyMagicBoardId = (id: string) =>
  id.split('/').join('-').split('.').join('_')

const ItemTags: React.FC<Props> = ({
  workspaceItemId,
  itemId,
  itemType,
  tags,
  allowedToEditTags,
  isEditable = true,
  isClickable = true,
  inDiscover = false,
}) => {
  const [isAddingTag, setIsAddingTag] = useState(false)
  const { reportSuccess, reportError } = useToastMessages()
  const activeWorkspace = useActiveWorkspace()
  const theme = useTheme()

  const [addWorkspaceItemTagMutation] = useAddWorkspaceItemTagMutation()
  const [deleteWorkspaceItemTagMutation] = useDeleteWorkspaceItemTagMutation()

  async function addTag(tag: TagProps) {
    setIsAddingTag(false)
    try {
      await addWorkspaceItemTagMutation({
        variables: {
          workspaceItemId,
          tagDescription: tag.description,
          tagId: getTagIdForNewTag(tag),
        },
        update: (cache, payload) => {
          cache.modify({
            id: `${itemType}:${itemId}`,
            fields: {
              tags: (existingTags) => {
                const newTag = payload.data?.addWorkspaceItemTag
                if (!newTag) return existingTags

                const typeId =
                  itemType === 'WorkspaceItem'
                    ? 'WorkspaceItemTag'
                    : 'BoardItemTag'
                const ref = cache.writeFragment({
                  id: `${typeId}:${newTag.id}`,
                  data: {
                    __typename: typeId,
                    description: newTag.description,
                    score: 1,
                    tagId: newTag.id,
                    id: newTag.id,
                  },
                  fragment:
                    itemType === 'WorkspaceItem'
                      ? WorkspaceItemTagFieldsFragmentDoc
                      : BoardItemTagFieldsFragmentDoc,
                })
                return [ref, ...existingTags]
              },
            },
          })
        },
        optimisticResponse: {
          addWorkspaceItemTag: {
            __typename: 'ItemTagSimple',
            description: tag.description,
            id: getTagIdForNewTag(tag) || tag.description,
          },
        },
      })
      reportSuccess(`Added tag "${tag.description}" to item`)
    } catch (error) {
      const status = getCustomErrorStatus(error)
      if (status === 'ALREADY_EXISTS') {
        reportError('Item already has this tag')
      } else {
        reportError('Oh, we could not add the tag')
      }
    }
  }

  async function deleteTag(tag: ItemData['tags'][0]) {
    if (!allowedToEditTags) {
      return reportError(permissionInfoText('Delete tag from item'))
    }
    await deleteWorkspaceItemTagMutation({
      variables: {
        workspaceItemId,
        tagId: tag.tagId,
      },
      update: (cache) => {
        cache.modify({
          id: `${itemType}:${itemId}`,
          fields: {
            tags(existingTags, { readField }) {
              const updatedTags = existingTags.filter((tagRef: Reference) => {
                return tag.id !== readField('id', tagRef)
              })
              return updatedTags
            },
          },
        })
      },
      optimisticResponse: {
        deleteWorkspaceItemTag: {
          __typename: 'RequestStatus',
          status: 'SUCCESS',
        },
      },
    })
    reportSuccess(`Deleting tag "${tag.description}" from item...`)
  }

  return (
    <>
      <Header>
        <Text
          as="h3"
          size="lg"
          bold
          style={{ color: theme.colors.text.neutral[0] }}
        >
          Tags
        </Text>
        {isEditable && (
          <IconButton
            onClick={() => {
              if (allowedToEditTags) {
                return setIsAddingTag(true)
              }
              reportError(permissionInfoText('Add tag to item'))
            }}
          >
            <Add style={{ height: 16 }} />
          </IconButton>
        )}
      </Header>
      {isAddingTag && (
        <BlockKeyDownPropagation>
          <AddTagWrapper>
            <ItemTagEditable
              addTag={addTag}
              activeTagIds={tags.map((tag) => tag.id) || []}
              onCancelEditing={() => setIsAddingTag(false)}
            />
          </AddTagWrapper>
        </BlockKeyDownPropagation>
      )}
      {(tags || []).map((tag) => {
        const LinkWrapper = isClickable ? Link : 'div'
        const linkWrapperProps = {
          onClick: () =>
            trackItemTagClicked({
              tagId: tag.id,
              tagDescription: tag.description,
              isEditable,
              isClickable,
              inDiscover,
            }),
          to: inDiscover
            ? `/${activeWorkspace?.url}/discover?search=true&tags=${
                tag.description
              }>>${encodeURIComponent(tag.id!)}`
            : {
                pathname: `/${
                  activeWorkspace?.url
                }/library/magic-boards/${getFriendlyMagicBoardId(tag.id!)}`,
                state: {
                  goBack: true,
                },
              },
        }
        return (
          <LinkWrapper
            key={tag.id}
            {...(isClickable && (linkWrapperProps as any))}
          >
            <ItemTag tag={tag} deleteTag={deleteTag} isEditable={isEditable} />
          </LinkWrapper>
        )
      })}
    </>
  )
}

export default ItemTags
