import { Loader5 } from '@styled-icons/remix-fill/Loader5'
import React from 'react'
import styled, { css, keyframes } from 'styled-components'

const LoaderWrapper = styled.div`
  ${({ theme }) => css`
    width: 42px;
    height: 42px;
    color: ${theme.colors.accent[2]};
  `}
`

function Loading(props) {
  return (
    <div
      style={{
        width: '100%',
        flex: '1 1 0',
        minHeight: 60,
        height: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <LoaderWrapper>
        <LoaderIcon />
      </LoaderWrapper>
    </div>
  )
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Rotate = styled.div`
  animation: ${rotate} 0.5s ease infinite;
`

const Spin = ({ children, style }) => <Rotate>{children}</Rotate>

export const LoaderIcon = (props) => (
  <Spin>
    <Loader5 />
  </Spin>
)

export default Loading
