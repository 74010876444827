import { Component } from 'react'
import styled from 'styled-components'

import bgshape4 from 'assets/svg/bgshape6.svg'

import Button from '../common/Button'
import FullscreenBackground from '../common/FullscreenBackground'

const Heading = styled.div(({ theme }) => ({
  fontSize: theme.fontSizes.base,
  color: theme.colors.text.neutral[0],
}))

const FormContainer = styled.div(({ theme }) => ({
  fontSize: theme.fontSizes.xl,
  textAlign: 'center',
  color: theme.colors.text.neutral[0],
}))

const StyledInput = styled.input(({ theme, width }) => ({
  height: 60,
  width,
  fontSize: theme.fontSizes.xl,
  fontWeight: 'bold',
  border: 'none',
  borderBottom: `6px solid ${theme.colors.text.neutral[0]}`,
  margin: 16,
  textAlign: 'center',
  outline: 'none',
  backgroundColor: 'transparent',
  color: theme.colors.gray.light[9],
}))

class CreateView extends Component {
  constructor(props) {
    super(props)

    this.state = {
      active: false,
      inputValue: '',
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.handleCreate(this.state.inputValue)
  }

  handleChange = (e) => {
    this.setState({ inputValue: e.target.value })
  }

  render() {
    return (
      <FullscreenBackground backgroundImage={bgshape4} backgroundSize="50%">
        <Heading>
          Name <b>your board</b>
        </Heading>
        <FormContainer>
          <form onSubmit={this.handleSubmit} autoComplete="off">
            <StyledInput
              width={this.state.inputValue.length * 22 + 50}
              onChange={this.handleChange}
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}
              value={
                this.state.active ? this.state.inputValue : this.props.value
              }
              type="text"
              autoFocus
            />
          </form>
        </FormContainer>
        <Button
          variant="primary"
          isLoading={this.props.isLoading}
          onClick={() => this.props.handleCreate(this.state.inputValue)}
        >
          Create
        </Button>
      </FullscreenBackground>
    )
  }
}

export default CreateView
