import styled from 'styled-components'

import DropdownItem from '../common/Dropdown/DropdownItem'

export const HeaderStyled = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.background[2],
  height: 60,
  borderBottom: `${theme.border.thin} ${theme.colors.discreet}`,
  display: 'flex',
  position: 'relative',
  fontFamily: theme.fontFamily.primary,
  maxWidth: '100%',
}))

export const DropdownTrigger = styled.div(({ theme, isSmall }) => ({
  padding: 8,
  height: '100%',
  flex: '1 1 0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: isSmall && 'center',
  cursor: 'pointer',
  ':hover': {
    backgroundColor: theme.colors.background[3],
    color: theme.colors.text.neutral[0],
  },
}))

export const Items = styled.div(({ theme, isSmall }) => ({
  display: isSmall && 'none',
  fontSize: theme.fontSizes.sm,
  maxWidth: 'calc(100% - 16px)',
}))

export const WorkspaceMenuItem = styled.div(({ theme }) => ({
  fontFamily: theme.fontFamily.primary,
  fontSize: theme.fontSizes.sm,
  lineHeight: 1,
}))

export const WorkspaceMenuItemText = styled.div(({ theme, bold }) => ({
  margin: '4px 0',
  fontWeight: bold ? theme.fontWeight.bold : theme.fontWeight.normal,
}))

export const WorkspaceTitle = styled.div(({ theme }) => ({
  fontSize: theme.fontSizes.sm,
  color: theme.colors.text.neutral[0],
  overflowWrap: 'break-word',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}))

export const UserContainer = styled.div(({ isSmall }) => ({
  display: isSmall && 'none',
  overflow: 'hidden',
  overflowWrap: 'break-word',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  maxHeight: 20,
}))

export const UserNameContainer = styled.div(({ theme }) => ({
  fontSize: theme.fontSizes.xs,
  display: 'inline-block',
  marginLeft: 4,
}))

export const OnlineIndicator = styled.div(({ theme }) => ({
  width: 5,
  height: 5,
  margin: 2,
  borderRadius: theme.borderRadius.full,
  display: 'inline-block',
  backgroundColor: theme.colors.accent[2],
}))

export const ExpandIndicator = styled.div(({ isSmall }) => ({
  position: 'absolute',
  right: 16,
  height: 24,
  alignItems: 'center',
  display: isSmall ? 'none' : 'flex',
}))

export const BackButtonWrapper = styled.div(({ theme }) => ({
  width: '40px',
  margin: '10px',
  display: 'flex',
  justifyContent: 'center',
  cursor: 'pointer',
  backgroundColor: theme.colors.background[4],
  borderRadius: theme.borderRadius.default,
  ':hover': {
    backgroundColor: theme.colors.accent[2],
    color: theme.colors.text.neutral[0],
  },
}))

export const StyledDropdownItem = styled(DropdownItem)`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  background: none;
`

export const StyledMenuWrapper = styled.div`
  max-height: calc(100vh - 160px);
  overflow-y: auto;
  background: ${({ theme }) => theme.colors.background[4]};
  padding-top: 5px;
`
