import { Search } from '@styled-icons/boxicons-regular/Search'
import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'

import { debounce } from '../../../helpers/utilityFunctions'
import Loading from '../../Loading'
import ClickOutside from '../ClickOutside'
import Emoji from './Emoji'
import {
  ActionButton,
  Actions,
  ActionsItemWrapper,
  ActionsOption,
  Input,
  InputContainer,
  ScrollContainer,
  StyledCell,
  StyledPicker,
} from './EmojiStyles'
import { emojisAll } from './emojis'

const Cell = ({ children, ...props }) => (
  <StyledCell onClick={props.onClick}>
    <Emoji code={props.code} />
  </StyledCell>
)

export function getRandomEmoji() {
  const i = Math.floor(Math.random() * 1000)
  const randomEmoji = emojisAll[i]
  return randomEmoji.text
}

function EmojiPicker({ handleSelectEmoji, handleClose, shouldShowActions }) {
  const [searchInput, setSearchInput] = useState('')
  const [emojis, setEmojis] = useState(emojisAll)
  const [emojisLoaded, setEmojisLoaded] = useState([])
  const [scrollNode, setScrollNode] = useState(null)
  const [hasMore, setHasMore] = useState(true)
  const [startAt, setStartAt] = useState(64)

  useEffect(() => {
    setStartAt(64)
    setHasMore(true)
    updateEmojis()
    loadMore()
    getRandomEmoji()
  }, [searchInput])

  const handlePickerCellClick = (emoji, e) => {
    handleSelectEmoji(emoji.text)
    handleClose()
  }

  const handleChange = (e) => {
    setSearchInput(e.target.value)
  }

  const updateEmojis = () => {
    const filtered =
      searchInput.length === 0
        ? emojisAll
        : emojisAll.filter((emoji) =>
            emoji.tags.some(
              (tag) => tag.indexOf(searchInput.toLowerCase()) !== -1
            )
          )
    setEmojis(filtered)
  }

  const loadMore = debounce(() => {
    const loaded = emojis.slice(0, startAt)
    setEmojisLoaded(loaded)
    if (hasMore && emojis.length <= startAt) {
      setHasMore(false)
    }
    setStartAt(startAt + 32)
  }, 200)

  const renderEmojis = () => {
    const rendered = emojisLoaded.map((emoji) => (
      <Cell
        onClick={() => handlePickerCellClick(emoji)}
        key={emoji.text}
        code={emoji.text}
      />
    ))
    return rendered
  }

  const removeEmoji = () => {
    handleSelectEmoji(null)
  }

  return (
    <ClickOutside handleClickOutside={handleClose}>
      <StyledPicker>
        {shouldShowActions && (
          <Actions>
            <ActionsItemWrapper side="left">
              <ActionsOption>Emoji</ActionsOption>
            </ActionsItemWrapper>
            <ActionsItemWrapper side="right">
              <ActionButton onClick={removeEmoji}>Remove</ActionButton>
            </ActionsItemWrapper>
          </Actions>
        )}
        <InputContainer>
          <Search style={{ height: 16, margin: '0 8px' }} />
          <Input autoFocus onChange={handleChange} value={searchInput} />
        </InputContainer>
        <ScrollContainer ref={(ref) => setScrollNode(ref)}>
          <InfiniteScroll
            pageStart={0}
            threshold={200}
            useWindow={false}
            getScrollParent={() => scrollNode}
            loadMore={loadMore}
            hasMore={hasMore}
            loader={<Loading key="loader" />}
          >
            {renderEmojis()}
          </InfiniteScroll>
        </ScrollContainer>
      </StyledPicker>
    </ClickOutside>
  )
}

export default EmojiPicker
