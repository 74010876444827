import InfiniteScroll from 'react-infinite-scroller'
import { useSelector } from 'react-redux'
import { Outlet, useOutletContext } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { useMagicBoardsQuery } from 'generated/graphql'
import { useActiveWorkspace } from 'hooks/useActiveWorkspace'
import { RootState } from 'store'

import { trackMagicBoardClicked } from '../../../helpers/tracking/tracking'
import Loading from '../../Loading'
import { MagicBoardCard } from './MagicBoardCard'
import { MagicBoardThreshold } from './MagicBoardThreshold'

export const MagicBoards = () => {
  const { scrollNode } = useOutletContext<{ scrollNode: HTMLDivElement }>()

  const workspaceItemsCount = useSelector(
    (state: RootState) =>
      state.firestore.ordered.workspaceItemCounter[0].merged_count
  )
  const activeWorkspace = useActiveWorkspace()!

  const activeWorkspaceId = activeWorkspace.id

  const isUnderItemThreshold = workspaceItemsCount < 10

  const { data, loading, fetchMore } = useMagicBoardsQuery({
    variables: {
      workspaceId: activeWorkspaceId,
      first: 30,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  const magicBoards = data?.magicBoards.magicBoards || []
  const nextCursor = data?.magicBoards.pageInfo.nextCursor

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        cursor: nextCursor,
      },
    })
  }

  const items = magicBoards.map((magicBoard) => {
    return (
      <Link
        style={{ filter: isUnderItemThreshold ? 'blur(6px)' : 'none' }}
        key={magicBoard.id}
        to={magicBoard.id}
      >
        <StyledCardSizing>
          <MagicBoardCard
            description={magicBoard.description}
            count={magicBoard.count}
            thumbUrl={magicBoard.thumbUrl}
            onClick={() => {
              trackMagicBoardClicked({
                tagId: magicBoard.tagId,
                tagDescription: magicBoard.description,
                tagCount: magicBoard.count,
                tagScore: magicBoard.score,
              })
            }}
          />
        </StyledCardSizing>
      </Link>
    )
  })

  const renderContent = () => {
    if (loading && !data) {
      return <Loading />
    }
    if (isUnderItemThreshold) {
      return <MagicBoardThreshold items={items} />
    }
    return (
      <InfiniteScroll
        pageStart={0}
        useWindow={false}
        getScrollParent={() => scrollNode}
        loadMore={handleLoadMore}
        hasMore={Boolean(nextCursor)}
        loader={<Loading key="loading" />}
      >
        {items}
      </InfiniteScroll>
    )
  }

  return (
    <StyledGridWrapper>
      {renderContent()}
      <Outlet />
    </StyledGridWrapper>
  )
}

const StyledGridWrapper = styled.div`
  padding: 0px 16px 0px;
`

const StyledCardSizing = styled.div`
  display: inline-block;
  min-width: 160px;
  margin: 8px;
  width: calc(20% - 16px);
  @media (max-width: 1400px) {
    width: calc(25% - 16px);
  }
  @media (max-width: 1300px) {
    width: calc(33.3% - 16px);
  }
  @media (max-width: 768px) {
    width: calc(50% - 16px);
  }
  @media (max-width: 500px) {
    width: calc(100% - 16px);
  }
`
