import React, { useEffect } from 'react'

import { HoverReplace } from '../common/HoverReplace'
import { ItemAction } from './UploadListingStyles'

interface Props {
  dismiss: () => void
  gotoUploadSession: () => void
}

const UploadItemComplete: React.FC<Props> = ({
  dismiss,
  gotoUploadSession,
}) => {
  // Dismiss session when complete after 5s
  useEffect(() => {
    const dismissTimeout = setTimeout(dismiss, 10000)
    return () => clearTimeout(dismissTimeout)
  }, [dismiss])

  // When pushing view upload, dismiss upload and goto upload
  const viewUploadSession = () => {
    dismiss()
    gotoUploadSession()
  }

  return (
    <HoverReplace
      style={{ flex: 1 }}
      itemStyle={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
      onHover={
        <>
          <ItemAction onClick={viewUploadSession}>View upload</ItemAction>
          <ItemAction onClick={dismiss}>Hide</ItemAction>
        </>
      }
    >
      <div>Upload complete</div>
    </HoverReplace>
  )
}

export default UploadItemComplete
