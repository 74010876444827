import { DBBoard } from 'types/db'

import { db, firebase } from '../config/firebase'
import { reportError } from './logging'

const boardHasCustomItemsPositioning = async (boardId: string) => {
  const boardResponse = await db.collection('boards').doc(boardId).get()
  if (!boardResponse.exists) {
    const error = new Error(
      `About to create board item, but its board does not exist. BoardId: ${boardId}`
    )
    reportError(error)
    throw error
  }
  const { hasCustomItemsPositioning } = boardResponse.data() as DBBoard

  return Boolean(hasCustomItemsPositioning)
}

const sortBoardItemDocsByPositions = (
  boardItemDocs: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[]
) => {
  return [...boardItemDocs].sort((a, b) => {
    // we sort by the positions
    const positionA = a.data().position
    const positionB = b.data().position

    if (positionA === undefined) return 1 // we make sure undefineds is put last in the array
    if (positionB === undefined) return -1 // we make sure undefineds is put last in the array

    if (positionA < positionB) {
      return -1
    }
    if (positionA > positionB) {
      return 1
    }
    return 0
  })
}

// Could be a bit shaky to do this client-side. Consider moving this to backend
const reInitializeBoardItemsPositionsIfNeeded = async (boardId: string) => {
  const hasCustomItemsPositioning = await boardHasCustomItemsPositioning(
    boardId
  )

  if (!hasCustomItemsPositioning) return

  // if the board has hasCustomItemsPositioning=true we have to re-initalize the items positioning when deleting the boardItem
  const boardItemsAfterDelete = await db
    .collection('itemRefs')
    .where('boardId', '==', boardId)
    .get()

  const sortedBoardItemsAfterDelete = sortBoardItemDocsByPositions(
    boardItemsAfterDelete.docs
  )

  const batch = db.batch()

  sortedBoardItemsAfterDelete.forEach((boardItem, index) => {
    const docRef = db.collection('itemRefs').doc(boardItem.id)
    batch.update(docRef, { position: index })
  })

  await batch.commit()
}

const getNewBoardItemPositionIfNeeded = async (boardId: string) => {
  try {
    const hasCustomItemsPositioning = await boardHasCustomItemsPositioning(
      boardId
    )

    if (!hasCustomItemsPositioning) return undefined

    const res = await db
      .collection('itemRefs')
      .where('boardId', '==', boardId)
      .orderBy('position', 'desc')
      .limit(1)
      .get()

    const lastPositionedItemPosition = res.docs[0]?.data()?.position as
      | number
      | undefined

    if (lastPositionedItemPosition === undefined) {
      return 0 // if the board is empty of items we return 0
    }
    return lastPositionedItemPosition + 1
  } catch (error) {
    reportError(
      new Error(
        `Error when finding a new board item position. BoardId: ${boardId}. Error: ${error.message}`
      )
    )
    return undefined
  }
}

const deleteBoardItem = async (boardItemId: string, boardId: string) => {
  await db.collection('itemRefs').doc(boardItemId).delete()
  await reInitializeBoardItemsPositionsIfNeeded(boardId)
}

export {
  deleteBoardItem,
  reInitializeBoardItemsPositionsIfNeeded,
  getNewBoardItemPositionIfNeeded,
}
