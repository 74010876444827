import { useNavigate } from 'react-router'

import { Spacer } from 'components/common/Spacer'
import { HighlightRow } from 'components/highlight-row/HighlightRow'
import { SearchCombo } from 'components/search-combo/SearchCombo'
import {
  DiscoverSearchComboPayload,
  useDiscoverSearchCombosQuery,
} from 'generated/graphql'
import { getSearchUrlParamsFromSearchCombos } from 'helpers/getSearchUrlParamsFromSearchCombo'
import { trackHomeSearchComboClicked } from 'helpers/tracking/tracking'

export const SearchSuggestionsRow = () => {
  const navigate = useNavigate()

  const { data } = useDiscoverSearchCombosQuery({
    variables: {
      count: 3,
    },
    fetchPolicy: 'cache-and-network',
    context: {
      batch: true,
    },
  })

  const handleSearchComboClick = (
    searchComboPayload: DiscoverSearchComboPayload
  ) => {
    trackHomeSearchComboClicked()

    navigate({
      pathname: 'discover',
      search: getSearchUrlParamsFromSearchCombos({
        searchCombos: searchComboPayload.searchCombo,
      }),
    })
  }

  const discoverSearchCombos = data?.discoverSearchCombos ?? []

  if (discoverSearchCombos.length === 0) return null

  return (
    <>
      <HighlightRow
        title="Try search combinations"
        listItems={discoverSearchCombos}
        renderListItem={(discoverSearchCombo) => {
          return (
            <SearchCombo
              onClick={() => handleSearchComboClick(discoverSearchCombo)}
              searchCombo={discoverSearchCombo}
            />
          )
        }}
      />
      <Spacer factor={5} />
    </>
  )
}
