import { useSelector } from 'react-redux'

import useToastMessages from 'components/toast/useToastMessages'
import { useAdmin_HideFromDiscoveryMutation } from 'generated/graphql'
import { RootState } from 'store'

const discoveryAdmins = [
  'alex@kive.ai',
  'lamin@kive.ai',
  'olof@kive.ai',
  'marten@kive.ai',
  'daniel@kive.ai',
  'jessica@kive.ai',
  'amanda@kive.ai',
  'glenn@kive.ai',
  'marcus@kive.ai',
  'martin@kive.ai',
  'joel@kive.ai',
]
const useAdminHideFromDiscovery = (id: string, workspaceId: string) => {
  const { reportError, reportSuccess } = useToastMessages()

  const [hideFromDiscoveryMutation] = useAdmin_HideFromDiscoveryMutation({
    variables: {
      id,
      workspaceId,
    },
  })

  const isAllowed = useSelector((store: RootState) => {
    const email = store.firebase.auth.email
    return email && discoveryAdmins.includes(email)
  })

  const run = async () => {
    if (!isAllowed) return
    // eslint-disable-next-line no-alert
    const shouldProceed = window.confirm(
      'Are you sure you wanna hide item from discovery?'
    )
    if (!shouldProceed) return
    try {
      const res = await hideFromDiscoveryMutation()
      const status = res.data?.admin_hideFromDiscovery.status
      if (status === 'SUCCESS') {
        return reportSuccess('Item was hidden from discovery')
      }
      return reportError('Could not hide item from discovery')
    } catch (error) {
      return reportError('Could not hide item from discovery')
    }
  }

  return { run, isAllowed }
}

export { useAdminHideFromDiscovery }
