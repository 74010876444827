import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { Text } from 'components/common/Text'
import { trackItemTagClicked } from 'helpers/tracking/tracking'
import { useActiveWorkspace } from 'hooks/useActiveWorkspace'

import HorizontalScroll from './HorizontalScroll'
import { ItemData } from './hooks/useGetItemData'

const getFriendlyMagicBoardId = (id: string) =>
  id.split('/').join('-').split('.').join('_')

const Badge = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.background[5]};
    color: ${theme.colors.text.neutral[1]};
    border-radius: 16px;
    padding: 4px 16px;
    margin: 0 4px;
  `}
`

const Strip = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 4px;
  cursor: pointer;
`

interface ItemTagBadgesProps {
  tags: ItemData['tags']
  isPublic?: boolean
  inDiscover?: boolean
}

const ItemTagBadges: React.FC<ItemTagBadgesProps> = ({
  tags,
  isPublic,
  inDiscover,
}) => {
  const activeWorkspace = useActiveWorkspace()

  const isClickable = !isPublic && !!activeWorkspace

  return (
    <HorizontalScroll>
      <Strip onClick={(e) => e.stopPropagation()}>
        {tags.map((tag) => {
          const LinkWrapper = isClickable ? Link : 'div'
          const linkWrapperProps = {
            onClick: () =>
              trackItemTagClicked({
                tagId: tag.id,
                tagDescription: tag.description,
                isEditable: false,
                isClickable,
                inDiscover: Boolean(inDiscover),
              }),
            to: inDiscover
              ? `/${activeWorkspace?.url}/discover?search=true&tags=${
                  tag.description
                }>>${encodeURIComponent(tag.tagId!)}`
              : {
                  pathname: `/${
                    activeWorkspace?.url
                  }library/magic-boards/${getFriendlyMagicBoardId(tag.id!)}`,
                  state: {
                    goBack: true,
                  },
                },
          }
          return (
            <LinkWrapper
              key={tag.id}
              {...(isClickable && (linkWrapperProps as any))}
            >
              <Badge>
                <Text size="sm" nowrap>
                  {tag.description}
                </Text>
              </Badge>
            </LinkWrapper>
          )
        })}
      </Strip>
    </HorizontalScroll>
  )
}

export default ItemTagBadges
