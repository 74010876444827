import { DBSearchableItem } from 'types/db'

import getTypeCategory from './getTypeCategory'

const getItemSizeUrl = (
  item: DBSearchableItem,
  // No width returns original item or 1600px for gif and video.
  width?: number,
  type?: 'gif' | 'jpg' | 'mp4'
) => {
  if (item.versions) {
    return item.versions![width ? `jpg-${width}px` : `jpg-original`]?.publicUrl
  }
  const translatedType =
    getTypeCategory(item.original?.info?.type) === 'image'
      ? 'jpg'
      : getTypeCategory(item.original?.info?.type)
  const originalSizeAstype =
    item.sizes?.find(
      (size: any) =>
        size.type === (type ?? translatedType) &&
        size.maxWidth === (width ?? 1600)
    )?.url || item.sizes?.[0].url

  return originalSizeAstype
}

export default getItemSizeUrl
