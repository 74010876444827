import { useEffect, useRef, useState } from 'react'
import { ArrowLeft, ArrowRight } from 'styled-icons/bootstrap'

import { useUpdateMyPresence } from 'config/liveblocks.config'

import EmptyBackground from '../../assets/img/emptyBackgroundTileDark.png'
import { StyledButtonMedium } from './ButtonMedium'

type StyledPreviewImageProps = {
  src: string
  isActive: boolean
  isOwnPreview: boolean
}
export const StyledPreviewImage = ({
  isActive,
  src,
  isOwnPreview,
}: StyledPreviewImageProps) => (
  <div
    style={{
      backgroundSize: 'cover',
      backgroundImage: `url(${src})`,
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      opacity: isActive ? 1 : 0,
      zIndex: isOwnPreview ? 3 : 1,
    }}
  />
)

type GenerationFrameProps = {
  finishPlacing: (props: { newPosition: { x: number; y: number } }) => void
  output: string[]
  isPlacing: boolean
  isLoading: boolean
  isErasing: boolean
  hasOutput: boolean
  onAccept: (outputIndex: number) => void
  onCancel: () => void
  color?: string
  scale?: number
  canvasPosition?: {
    x: number
    y: number
  }
}

export default function GenerationFrame({
  finishPlacing,
  isPlacing,
  isLoading,
  isErasing,
  hasOutput,
  output,
  onAccept,
  onCancel,
  color = '#5930E5',
  scale = 1,
  canvasPosition = { x: 0, y: 0 },
}: GenerationFrameProps) {
  const [position, setPosition] = useState({
    x: 200,
    y: 100,
  })
  const [outputIndex, setOutputIndex] = useState(0)
  const loadingIndicatorRef = useRef<HTMLDivElement>(null)
  const updateMyPresence = useUpdateMyPresence()
  useEffect(() => {
    const mouseMove = (mouseEvent: MouseEvent) => {
      const { x, y } = mouseEvent
      const newX = (x - canvasPosition.x) / scale - 256 / 2
      const newY = (y - canvasPosition.y) / scale - 256 / 2
      setPosition({ x: newX, y: newY })
      updateMyPresence({
        generationFrameCoords: { x: newX, y: newY },
      })
    }
    if (isPlacing) window.addEventListener('mousemove', mouseMove)
    return () => window.removeEventListener('mousemove', mouseMove)
  }, [isPlacing, updateMyPresence, scale, canvasPosition.x, canvasPosition.y])

  useEffect(() => {
    if (isLoading && loadingIndicatorRef.current) {
      loadingIndicatorRef.current.style.width = '100%'
      loadingIndicatorRef.current.style.transition = 'width 12s'
    }
    if (!isLoading && loadingIndicatorRef.current) {
      loadingIndicatorRef.current.style.width = '0'
      loadingIndicatorRef.current.style.transition = 'width 0s'
    }
  }, [isLoading])

  const incrementOutputIndex = (incrementation: number) => {
    let newIndex = (outputIndex + incrementation) % output.length
    if (newIndex < 0) newIndex = output.length - 1
    setOutputIndex(newIndex)
    updateMyPresence({
      generationFrameAlternativesActiveIndex: newIndex,
    })
  }

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        left: 0,
        top: 0,
        position: 'absolute',
        pointerEvents: isErasing ? 'none' : 'auto',
      }}
      onClick={() => {
        if (!isPlacing) return null
        finishPlacing({
          newPosition: {
            x: position.x,
            y: position.y,
          },
        })
      }}
    >
      <div
        style={{
          position: 'absolute',
          left: position.x * scale + canvasPosition.x,
          top: position.y * scale + canvasPosition.y,
          width: 256 * scale,
          height: 256 * scale,
          border: `1px solid ${color}`,
          zIndex: 4,
        }}
      >
        <div
          ref={loadingIndicatorRef}
          style={{
            position: 'absolute',
            bottom: 0,
            height: 4,
            width: 0,
            background: color,
            transition: 'width 8s',
            zIndex: 4,
          }}
        />
      </div>
      <div
        style={{
          position: 'absolute',
          zIndex: -1,
          left: position.x * scale + canvasPosition.x,
          top: position.y * scale + canvasPosition.y,
          width: 256 * scale,
          height: 256 * scale,
          backgroundImage: `url(${EmptyBackground})`,
        }}
      >
        {hasOutput &&
          output.map((src, index) => (
            <StyledPreviewImage
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              src={src}
              isActive={outputIndex === index}
              isOwnPreview={false}
            />
          ))}
      </div>
      {hasOutput && (
        <div
          style={{
            position: 'absolute',
            width: 256,
            display: 'flex',
            justifyContent: 'center',
            zIndex: 6,
            left: position.x * scale + canvasPosition.x + scale * 128 - 128,
            top: position.y * scale + canvasPosition.y + scale * 256 + 8,
          }}
        >
          <StyledButtonMedium
            style={{ borderRadius: '8px 0 0 8px', borderRight: 0 }}
            onClick={() => incrementOutputIndex(-1)}
          >
            <ArrowLeft />
          </StyledButtonMedium>
          <StyledButtonMedium
            onClick={() => incrementOutputIndex(1)}
            style={{ borderRadius: 0, borderRight: 0 }}
          >
            <ArrowRight />
          </StyledButtonMedium>
          <StyledButtonMedium
            onClick={onCancel}
            style={{ borderRadius: 0, borderRight: 0 }}
            hasText
          >
            Cancel
          </StyledButtonMedium>
          <StyledButtonMedium
            onClick={() => onAccept(outputIndex)}
            style={{ borderRadius: '0px 8px 8px 0' }}
            hasText
          >
            Accept
          </StyledButtonMedium>
        </div>
      )}
    </div>
  )
}
