import { FC } from 'react'

import {
  useBillingSubscriptionQuery,
  useCreateBillingPortalSessionMutation,
} from '../../../generated/graphql'
import { reportError as reportSentryError } from '../../../helpers/logging'
import Loading from '../../Loading'
import Button from '../../common/Button'
import { ApplyCredit } from './ApplyCredit'
import {
  StyledContainer,
  StyledCurrentPlanWrapper,
  StyledInvoiceInformation,
  StyledInvoiceInformationContainer,
  StyledLatestInvoiceLink,
  StyledLatestInvoiceLinksContainer,
  StyledLatestInvoicePdf,
  StyledLegacyLabel,
  StyledSectionHeading,
  StyledSubscriptionContainer,
  StyledSubscriptionItemContainer,
  StyledSubscriptionName,
  StyledSubscriptionPrice,
} from './CurrentPlanStyles'
import { CurrentPlanTexts } from './copy'

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'stripe-pricing-table': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >
    }
  }
}

type Props = {
  workspaceId: string
  currentCredit: number
}
export const CurrentPlan: FC<Props> = ({ workspaceId, currentCredit }) => {
  const { data: subscription, loading: subscriptionLoading } =
    useBillingSubscriptionQuery({
      variables: {
        workspaceId,
      },
    })
  const [
    createBillingPortalSessionMutation,
    { loading: isCreatingBillingPortalSession, error: billingPortalError },
  ] = useCreateBillingPortalSessionMutation()

  const text = CurrentPlanTexts.en

  const createBillingPortalSessionAndRedirect = async () => {
    if (isCreatingBillingPortalSession || billingPortalError) return
    try {
      const returnUrl = window.location.href
      console.log(
        `Creating billing portal session with the following payload`,
        {
          variables: {
            workspaceId,
            returnUrl,
          },
        }
      )
      const { data } = await createBillingPortalSessionMutation({
        variables: {
          workspaceId,
          returnUrl,
        },
      })
      const url = data?.createBillingPortalSession?.url
      if (!url) {
        return
      }
      window.location.assign(url)
    } catch (error) {
      reportSentryError(error)
    }
  }

  if (!subscription?.billingSubscription) return null

  const nextInvoiceDate = new Date(
    Number(subscription.billingSubscription.currentPeriodEnd) * 1000
  )
  return (
    <>
      <StyledCurrentPlanWrapper>
        <StyledSectionHeading>{text.section_title}</StyledSectionHeading>
        {subscriptionLoading ? <Loading /> : null}
        {!subscriptionLoading ? (
          <StyledContainer>
            <StyledSubscriptionContainer>
              {subscription?.billingSubscription?.subscriptionItems?.map(
                (item) => {
                  const priceValue =
                    item.price.unitAmount && item.price.unitAmount > 0
                      ? Number(item.price.unitAmount) / 100
                      : 0
                  const priceString =
                    priceValue > 0
                      ? text.price_templates.has_price
                          .replace(
                            '[[price]]',
                            `${new Intl.NumberFormat('en-US', {
                              style: 'currency',
                              currency: 'usd',
                              currencyDisplay: 'narrowSymbol',
                            }).format(
                              (item.price.unitAmount * item.quantity) / 100
                            )}`
                          )
                          .replace('[[year]]', item.price.recurring.interval)
                      : text.price_templates.free
                  return (
                    <StyledSubscriptionItemContainer key={item.id}>
                      <StyledSubscriptionName>
                        {item.price.product.name}
                        {subscription.billingSubscription?.plan?.status ===
                        'LEGACY' ? (
                          <StyledLegacyLabel>legacy</StyledLegacyLabel>
                        ) : null}
                      </StyledSubscriptionName>
                      <StyledSubscriptionPrice>
                        {priceString}
                      </StyledSubscriptionPrice>
                      <StyledInvoiceInformationContainer>
                        <StyledInvoiceInformation>
                          Subscription
                          {item.quantity > 1
                            ? ` with ${item.quantity} members `
                            : ' '}
                          will renew on{' '}
                          {new Intl.DateTimeFormat(undefined, {
                            dateStyle: 'full',
                          }).format(nextInvoiceDate)}
                        </StyledInvoiceInformation>
                        <StyledLatestInvoiceLinksContainer>
                          Latest invoice{' '}
                          {subscription.billingSubscription
                            ?.latestHostedInvoiceUrl ? (
                            <StyledLatestInvoiceLink
                              href={
                                subscription.billingSubscription
                                  ?.latestHostedInvoiceUrl
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              page
                            </StyledLatestInvoiceLink>
                          ) : null}
                          {subscription.billingSubscription
                            ?.latestHostedInvoicePdf ? (
                            <StyledLatestInvoicePdf
                              href={
                                subscription.billingSubscription
                                  ?.latestHostedInvoicePdf
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              pdf
                            </StyledLatestInvoicePdf>
                          ) : null}
                        </StyledLatestInvoiceLinksContainer>
                      </StyledInvoiceInformationContainer>
                    </StyledSubscriptionItemContainer>
                  )
                }
              )}
              {[...(subscription?.billingSubscription?.subscriptionItems ?? [])]
                .length > 0 ? (
                <Button
                  isCompact
                  onClick={() => createBillingPortalSessionAndRedirect()}
                  variant="primary"
                  disabled={
                    isCreatingBillingPortalSession || !!billingPortalError
                  }
                  style={{
                    alignSelf: 'start',
                  }}
                >
                  Manage my billing
                </Button>
              ) : null}
            </StyledSubscriptionContainer>
            {[...(subscription?.billingSubscription?.subscriptionItems ?? [])]
              .length > 0 ? (
              <ApplyCredit
                workspaceId={workspaceId}
                currentCredit={currentCredit}
              />
            ) : null}
          </StyledContainer>
        ) : null}
      </StyledCurrentPlanWrapper>
    </>
  )
}
