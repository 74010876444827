import React, { useState } from 'react'
import styled from 'styled-components'

import Loading from 'components/Loading'
import useIsMobile from 'hooks/useIsMobile'

import ItemActionBarLeft from './ItemActionBarLeft'
import ItemActionBarRight from './ItemActionBarRight'
import ItemActionBarTop from './ItemActionBarTop'
import ItemColorsScrollable from './ItemColorsScrollable'
import ItemDeleteModal from './ItemDeleteModal'
import ItemInfo from './ItemInfo'
import ItemPanel from './ItemPanel'
import ItemPanelMobile from './ItemPanelMobile'
import ItemTagBadges from './ItemTagBadges'
import { ItemData } from './hooks/useGetItemData'

const ItemContainer = styled.div`
  display: flex;
`

const ItemPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ItemInfoWrapper = styled.div`
  margin: 0 64px;
`

const MobileItemWrapper = styled.div`
  overflow-x: hidden;
`

const handleMoveItemFocus = (
  e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  direction: 'right' | 'left'
) => {
  // Focus the grid, and dispatch a keydown event manually
  // TODO: This is quite clumsy. There should be a global way to move focus?
  // Maybe we add gridItems to redux, and create an action moveFocus(step = -1, addFocus = false)
  const gridElement = document.querySelector<HTMLElement>(
    '#grid:not(.similar-grid)' // we have to make sure we don't find the grid in similar items
  )

  if (!gridElement) {
    throw new Error('moveItemFocus: could not find grid')
  }

  gridElement.focus()
  const arrowKey = { right: 'ArrowRight', left: 'ArrowLeft' }[direction]
  const keyCode = { right: 39, left: 37 }[direction]
  const ArrowLeftEvent = new KeyboardEvent('keydown', {
    bubbles: true,
    cancelable: true,
    code: arrowKey,
    keyCode,
    key: arrowKey,
  })

  gridElement.dispatchEvent(ArrowLeftEvent)
  // Avoid closing the panel
  e.stopPropagation()
}

interface ItemProps {
  item?: ItemData
  onCloseItemView: () => void
}

const Item = React.forwardRef<HTMLDivElement, ItemProps>(
  ({ item, onCloseItemView }, ref) => {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    const hasItemWriteAccess = Boolean(
      item && ['OWNER', 'WRITER'].includes(item.userRole)
    )
    const isMobile = useIsMobile()
    console.log('trigger build')
    return (
      <>
        <Container ref={ref}>
          {item ? (
            <>
              <ItemActionBarTop
                item={item}
                canDeleteItem={hasItemWriteAccess}
                canUpdateItemAccessLevel={hasItemWriteAccess}
                onCloseItemView={onCloseItemView}
                onOpenDeleteModal={() => setIsDeleteModalOpen(true)}
              />
              {isMobile && (
                <MobileItemWrapper>
                  <ItemPanelMobile
                    item={item}
                    handleMoveItemFocus={handleMoveItemFocus}
                  />
                  <ItemColorsScrollable colors={item.colors} />
                  <ItemTagBadges tags={item.tags} />
                  <ItemInfo item={item} />
                </MobileItemWrapper>
              )}
              {!isMobile && (
                <>
                  <ItemContainer>
                    <ItemActionBarLeft
                      handleMoveItemFocus={handleMoveItemFocus}
                    />
                    <ItemPanelContainer>
                      <ItemPanel item={item} />
                    </ItemPanelContainer>
                    <ItemActionBarRight
                      handleMoveItemFocus={handleMoveItemFocus}
                    />
                  </ItemContainer>
                  <ItemInfoWrapper>
                    <ItemInfo item={item} />
                  </ItemInfoWrapper>
                </>
              )}
            </>
          ) : (
            <Loading />
          )}
        </Container>
        {item && (
          <ItemDeleteModal
            item={item}
            isOpen={isDeleteModalOpen}
            close={() => setIsDeleteModalOpen(false)}
          />
        )}
      </>
    )
  }
)

export default Item
