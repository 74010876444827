import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSONObject: any;
  Upload: any;
};

export type AddBoardInput = {
  iconCode: InputMaybe<Scalars['String']>;
  projectId: Scalars['String'];
  title: InputMaybe<Scalars['String']>;
  workspaceId: Scalars['String'];
};

export type AddDiscountCouponToWorkspaceInput = {
  couponId: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type AddEditSessionPowerUpInput = {
  editSessionId: Scalars['ID'];
};

export type AddGoogleAnalyticsIdFromWaitingListIdInput = {
  waitingListId: Scalars['String'];
};

export type AddItemCommentInput = {
  boardId: InputMaybe<Scalars['String']>;
  parentId: Scalars['String'];
  text: Scalars['String'];
};

export type AddItemReactionInput = {
  boardId: InputMaybe<Scalars['String']>;
  parentId: Scalars['String'];
  reaction: Scalars['String'];
};

export type AddPowerUpResponse = {
  __typename?: 'AddPowerUpResponse';
  message: Maybe<Scalars['String']>;
  result: AddPowerUpResult;
};

export type AddPowerUpResult =
  | 'error'
  | 'no_credits'
  | 'ok';

export type AddProjectInput = {
  title: InputMaybe<Scalars['String']>;
  workspaceAccess: InputMaybe<Scalars['String']>;
  workspaceId: Scalars['String'];
};

export type AddPromoCodeInput = {
  promoCode: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type AddRecentBoardInput = {
  boardId: Scalars['String'];
};

export type AddUserIdToGoogleAnalyticsIdInput = {
  userId: Scalars['String'];
  waitingListId: InputMaybe<Scalars['String']>;
};

export type AddUserInput = {
  fromPublicFeatureData: InputMaybe<FromPublicFeatureData>;
  photoURL: InputMaybe<Scalars['String']>;
};

export type AddWaitingListInput = {
  email: Scalars['String'];
  referralId: InputMaybe<Scalars['String']>;
  signupContext: InputMaybe<SignupContext>;
};

export type AddWorkspaceInput = {
  allowedEmailDomains: InputMaybe<Array<Scalars['String']>>;
  title: Scalars['String'];
  url: Scalars['String'];
};

export type AddWorkspaceItemTagInput = {
  tagDescription: Scalars['String'];
  tagId: InputMaybe<Scalars['String']>;
  workspaceItemId: Scalars['String'];
};

export type BillingInterval = {
  __typename?: 'BillingInterval';
  price: Maybe<Scalars['String']>;
  priceActual: Maybe<StripePrice>;
  priceId: Maybe<Scalars['String']>;
  priceUnit: Maybe<Scalars['String']>;
};

export type BillingIntervals = {
  __typename?: 'BillingIntervals';
  monthly: Maybe<BillingInterval>;
  yearly: Maybe<BillingInterval>;
};

export type BillingPortalSession = {
  __typename?: 'BillingPortalSession';
  url: Scalars['String'];
};

export type BillingSubscription = {
  __typename?: 'BillingSubscription';
  activeDiscount: Maybe<StripeDiscount>;
  /** @deprecated Use 'subscriptionItems'. */
  activePrice: Maybe<StripePrice>;
  currentPeriodEnd: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  latestHostedInvoicePdf: Maybe<Scalars['String']>;
  latestHostedInvoiceUrl: Maybe<Scalars['String']>;
  plan: Maybe<Plan>;
  subscriptionItems: Maybe<Array<SubscriptionItem>>;
};

export type BillingSubscriptionInput = {
  workspaceId: Scalars['String'];
};

export type Board = {
  __typename?: 'Board';
  cover: Maybe<BoardCover>;
  createdAt: Scalars['String'];
  createdBy: User;
  description: Maybe<Scalars['String']>;
  firstMadePublicAt: Maybe<Scalars['String']>;
  iconCode: Maybe<Scalars['String']>;
  id: Scalars['String'];
  itemCount: Scalars['Int'];
  items: Array<BoardItem>;
  owners: Array<User>;
  priority: Maybe<Scalars['Int']>;
  project: Project;
  thumbUrls: Array<Scalars['String']>;
  title: Scalars['String'];
  updatedAt: Maybe<Scalars['String']>;
  userRole: Maybe<BoardUserRole>;
  workspaceId: Scalars['String'];
};

export type BoardCover = {
  __typename?: 'BoardCover';
  src: Scalars['String'];
};

export type BoardInput = {
  boardId: Scalars['String'];
};

export type BoardItem = {
  __typename?: 'BoardItem';
  accessLevel: BoardItemAccessLevel;
  aspectRatio: Scalars['Float'];
  boardId: Scalars['String'];
  colors: Array<Color>;
  commentCount: Scalars['Int'];
  createdAt: Scalars['String'];
  createdBy: Maybe<User>;
  id: Scalars['String'];
  itemId: Scalars['String'];
  itemSourceInfo: ItemSourceInfo;
  mediaType: ItemMediaType;
  mediaVariants: Array<MediaVariants>;
  openCount: Scalars['Int'];
  original: ItemOriginal;
  projectId: Scalars['String'];
  tags: Array<BoardItemTag>;
  title: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['String']>;
  uploadSessionId: Scalars['String'];
  userRole: BoardItemUserRole;
  webUrl: Maybe<Scalars['String']>;
  workspaceId: Scalars['String'];
  workspaceItemId: Scalars['String'];
};

export type BoardItemAccessLevel =
  | 'PRIVATE'
  | 'PUBLIC'
  | 'WITH_LINK';

export type BoardItemInput = {
  boardItemId: Scalars['String'];
};

export type BoardItemTag = {
  __typename?: 'BoardItemTag';
  description: Scalars['String'];
  id: Scalars['String'];
  score: Scalars['Float'];
  tagId: Scalars['String'];
};

export type BoardItemUserRole =
  | 'COMMENTER'
  | 'OWNER'
  | 'READER'
  | 'WRITER';

export type BoardItemsInput = {
  boardId: Scalars['String'];
  cursor: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
};

export type BoardItemsPayload = {
  __typename?: 'BoardItemsPayload';
  boardItems: Array<BoardItem>;
  pageInfo: PageInfo;
};

export type BoardLinkAccess =
  | 'commenter'
  | 'reader'
  | 'writer';

export type BoardPriorityInput = {
  boardItemId: Scalars['String'];
  priority: Scalars['Int'];
};

export type BoardPublicAccess =
  | 'commenter'
  | 'reader'
  | 'writer';

export type BoardUserRole =
  | 'COMMENTER'
  | 'OWNER'
  | 'READER'
  | 'WRITER';

export type BoardsInput = {
  cursor: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
  projectId: Scalars['String'];
};

export type BoardsPayload = {
  __typename?: 'BoardsPayload';
  boards: Array<Board>;
  pageInfo: PageInfo;
};

export type ChangeWorkspaceMemberRoleInput = {
  role: WorkspaceMemberRole;
  userEmail: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type CheckoutInput = {
  customerEmail: Scalars['String'];
  internalPlanId: Scalars['String'];
  interval: Scalars['String'];
  lineItems: Array<CheckoutLineItem>;
  redirects: CheckoutRedirects;
  workspaceId: Scalars['String'];
};

export type CheckoutLineItem = {
  priceId: Scalars['String'];
  quantity: Scalars['Int'];
};

export type CheckoutRedirects = {
  cancelUrl: Scalars['String'];
  successUrl: Scalars['String'];
};

export type CheckoutSession = {
  __typename?: 'CheckoutSession';
  url: Scalars['String'];
};

export type CloseEditSessionInput = {
  editSessionId: Scalars['ID'];
};

export type Color = {
  __typename?: 'Color';
  hex: Scalars['String'];
  id: Scalars['String'];
  pixelFraction: Scalars['Float'];
  score: Scalars['Float'];
};

export type CouponInput = {
  couponId: Scalars['String'];
};

export type CreateBillingPortalSessionInput = {
  returnUrl: Scalars['String'];
  workspaceId: Scalars['ID'];
};

export type CreateCancellationSurveyInput = {
  canBeContacted: InputMaybe<Scalars['Boolean']>;
  message: InputMaybe<Scalars['String']>;
  reasons: Array<Scalars['String']>;
};

export type CreateCancellationSurveyResult = {
  __typename?: 'CreateCancellationSurveyResult';
  result: Maybe<Scalars['String']>;
};

export type CreateCustomTokenInput = {
  idToken: Scalars['String'];
};

export type CreateEditSessionInput = {
  backUrl: InputMaybe<Scalars['String']>;
  baseImageUrl: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
  workspaceId: InputMaybe<Scalars['ID']>;
};

export type CreateUserLoginOtpInput = {
  word: InputMaybe<Scalars['String']>;
};

export type CreditPack = {
  __typename?: 'CreditPack';
  creditCount: Scalars['Int'];
  currency: Scalars['String'];
  description: Scalars['String'];
  imageUrl: Scalars['String'];
  priceId: Scalars['String'];
  title: Scalars['String'];
  unitAmountInCents: Scalars['Int'];
};

export type CreditPackCheckoutInput = {
  customerEmail: Scalars['String'];
  editSessionId: InputMaybe<Scalars['ID']>;
  lineItems: Array<CheckoutLineItem>;
  redirects: CheckoutRedirects;
  sourceUrl: Scalars['String'];
};

export type DeleteBoardInput = {
  boardId: Scalars['String'];
  projectId: Scalars['String'];
};

export type DeleteBoardItemInput = {
  boardItemId: Scalars['String'];
};

export type DeleteProjectInput = {
  projectId: Scalars['String'];
};

export type DeleteUserInput = {
  uid: Scalars['String'];
};

export type DeleteWorkspaceInput = {
  workspaceId: Scalars['String'];
};

export type DeleteWorkspaceItemInput = {
  workspaceItemId: Scalars['String'];
};

export type DeleteWorkspaceItemTagInput = {
  tagId: Scalars['String'];
  workspaceItemId: Scalars['String'];
};

export type DiscoverIdeasForBoardInput = {
  boardId: Scalars['String'];
  cursor: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
};

export type DiscoverIdeasForBoardPayload = {
  __typename?: 'DiscoverIdeasForBoardPayload';
  boardId: Scalars['String'];
  items: Array<WorkspaceItem>;
  pageInfo: PageInfo;
  projectId: Scalars['String'];
  title: Scalars['String'];
};

export type DiscoverIdeasForRecentBoardsInput = {
  cursor: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
  workspaceId: Scalars['String'];
};

export type DiscoverItemsForYouInput = {
  cursor: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
};

export type DiscoverItemsForYouPayload = {
  __typename?: 'DiscoverItemsForYouPayload';
  items: Array<WorkspaceItem>;
  pageInfo: PageInfo;
};

export type DiscoverNewItemsInput = {
  cursor: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
};

export type DiscoverNewItemsPayload = {
  __typename?: 'DiscoverNewItemsPayload';
  items: Array<WorkspaceItem>;
  pageInfo: PageInfo;
};

export type DiscoverSearchComboPayload = {
  __typename?: 'DiscoverSearchComboPayload';
  description: Scalars['String'];
  id: Scalars['String'];
  searchCombo: Array<SearchCombo>;
};

export type DiscoverSearchCombosInput = {
  count: Scalars['Int'];
};

export type DiscoverTopContributorsInput = {
  first: Scalars['Int'];
};

export type EditSession = {
  __typename?: 'EditSession';
  context: Maybe<EditSessionContext>;
  createdAt: Scalars['Int'];
  id: Scalars['ID'];
  members: Array<EditSessionMember>;
  powerUp: Maybe<EditSessionPowerUp>;
  startValues: Maybe<EditSessionStartValues>;
  status: EditSessionStatus;
  thumbnailUrl: Maybe<Scalars['String']>;
  updatedAt: Scalars['Int'];
};

export type EditSessionContext = {
  __typename?: 'EditSessionContext';
  backUrl: Maybe<Scalars['String']>;
  workspaceId: Maybe<Scalars['ID']>;
};

export type EditSessionInvite = {
  __typename?: 'EditSessionInvite';
  createdAt: Scalars['Int'];
  editSessionId: Scalars['ID'];
  id: Scalars['ID'];
  message: Maybe<Scalars['String']>;
  receiver: Maybe<EditSessionInviteMember>;
  sender: EditSessionInviteMember;
  status: EditSessionInviteStatus;
  updatedAt: Scalars['Int'];
};

export type EditSessionInviteMember = {
  __typename?: 'EditSessionInviteMember';
  email: Scalars['String'];
  name: Scalars['String'];
  userId: Maybe<Scalars['ID']>;
};

export type EditSessionInviteMemberInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  userId: InputMaybe<Scalars['ID']>;
};

export type EditSessionInviteStatus =
  | 'accepted'
  | 'archived'
  | 'created'
  | 'declined'
  | 'opened';

export type EditSessionMember = {
  __typename?: 'EditSessionMember';
  role: EditSessionMemberRole;
  userId: Scalars['ID'];
};

export type EditSessionMemberRole =
  | 'member'
  | 'owner'
  | 'viewer';

export type EditSessionPowerUp = {
  __typename?: 'EditSessionPowerUp';
  createdAt: Scalars['Int'];
  enrichedPowerUpMembers: Maybe<Array<EnrichedPowerUpMember>>;
  isPowered: Scalars['Boolean'];
  poweredBy: Array<Maybe<Scalars['String']>>;
  updatedAt: Scalars['Int'];
};

export type EditSessionStartValues = {
  __typename?: 'EditSessionStartValues';
  baseImageUrl: Scalars['String'];
};

export type EditSessionStatus =
  | 'active'
  | 'closed';

export type EditSessionsInput = {
  cursor: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
};

export type EditSessionsPayload = {
  __typename?: 'EditSessionsPayload';
  editSessions: Array<EditSession>;
  pageInfo: PageInfo;
};

export type EnrichedPowerUpMember = {
  __typename?: 'EnrichedPowerUpMember';
  imageUrl: Scalars['String'];
  name: Scalars['String'];
  uid: Scalars['String'];
};

export type FromPublicFeatureData = {
  boardId: Scalars['String'];
  boardItemId: Scalars['String'];
  canvas: InputMaybe<Scalars['Boolean']>;
  itemId: Scalars['String'];
};

export type GenerationRequests = {
  __typename?: 'GenerationRequests';
  availableCredits: Scalars['Int'];
  numGenerations: Scalars['Int'];
};

export type GenericNotificationData = {
  __typename?: 'GenericNotificationData';
  id: Scalars['String'];
  type: Scalars['String'];
};

export type GoogleAnalyticsId = {
  __typename?: 'GoogleAnalyticsId';
  id: Maybe<Scalars['String']>;
};

export type GoogleAnalyticsIdInput = {
  userId: InputMaybe<Scalars['String']>;
  waitingListId: InputMaybe<Scalars['String']>;
};

export type Guest = {
  __typename?: 'Guest';
  displayName: Scalars['String'];
  id: Scalars['String'];
};

export type HideFromDiscoveryInput = {
  id: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type ImageSearchWorkspaceItemInput = {
  cursor: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
  query: Scalars['String'];
  scope: SearchWorkspaceItemsScope;
  workspaceId: InputMaybe<Scalars['String']>;
  workspaceItemId: Scalars['String'];
};

export type ImageSearchWorkspaceItemPayload = {
  __typename?: 'ImageSearchWorkspaceItemPayload';
  pageInfo: PageInfo;
  workspaceItems: Array<WorkspaceItem>;
};

export type IncrementBoardItemViewsInput = {
  boardItemId: Scalars['String'];
};

export type IncrementWorkspaceItemViewsInput = {
  workspaceItemId: Scalars['String'];
};

export type InputTag = {
  description: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  isManualTag: InputMaybe<Scalars['Boolean']>;
};

export type ItemComment = {
  __typename?: 'ItemComment';
  author: Maybe<UserOrGuest>;
  createdAt: Scalars['String'];
  id: Scalars['String'];
  text: Scalars['String'];
};

export type ItemCommentsInput = {
  boardId: InputMaybe<Scalars['String']>;
  cursor: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  parentId: Scalars['String'];
};

export type ItemCommentsPayload = {
  __typename?: 'ItemCommentsPayload';
  itemComments: Array<ItemComment>;
  pageInfo: PageInfo;
};

export type ItemExtensionImportInfo = {
  __typename?: 'ItemExtensionImportInfo';
  pageUrl: Maybe<Scalars['String']>;
};

export type ItemExtractedFromInfoUrlType =
  | 'VIMEO'
  | 'YOUTUBE';

export type ItemMediaType =
  | 'GIF'
  | 'IMAGE';

export type ItemOriginal = {
  __typename?: 'ItemOriginal';
  bucketName: Scalars['String'];
  info: Maybe<ItemOriginalInfo>;
  path: Scalars['String'];
};

export type ItemOriginalInfo = {
  __typename?: 'ItemOriginalInfo';
  aspectRatio: Scalars['Float'];
  height: Scalars['Float'];
  type: Scalars['String'];
  width: Scalars['Float'];
};

export type ItemReaction = {
  __typename?: 'ItemReaction';
  author: Maybe<UserOrGuest>;
  createdAt: Scalars['String'];
  id: Scalars['String'];
  reaction: Scalars['String'];
};

export type ItemReactionGroup = {
  __typename?: 'ItemReactionGroup';
  count: Maybe<Scalars['Int']>;
  hasReacted: Scalars['Boolean'];
  id: Scalars['String'];
  itemReactions: Array<ItemReaction>;
  reaction: Scalars['String'];
};

export type ItemReactionsInput = {
  boardId: InputMaybe<Scalars['String']>;
  parentId: Scalars['String'];
};

export type ItemSourceInfo = {
  __typename?: 'ItemSourceInfo';
  extensionImportInfo: Maybe<ItemExtensionImportInfo>;
  type: ItemSourceInfoType;
  videoExtractInfo: Maybe<ItemVideoExtractInfo>;
};

export type ItemSourceInfoType =
  | 'BROWSER_EXTENSION_IMPORT'
  | 'DISCOVER_IMPORT'
  | 'UPLOAD'
  | 'VIDEO_EXTRACT';

export type ItemTagSimple = {
  __typename?: 'ItemTagSimple';
  description: Scalars['String'];
  id: Scalars['String'];
};

export type ItemVideoExtractInfo = {
  __typename?: 'ItemVideoExtractInfo';
  url: Maybe<Scalars['String']>;
  urlType: Maybe<ItemExtractedFromInfoUrlType>;
};

export type KiveAccessContext = {
  __typename?: 'KiveAccessContext';
  hasCompletedWaitingListForm: Maybe<Scalars['Boolean']>;
  invitationId: Maybe<Scalars['String']>;
  userName: Maybe<Scalars['String']>;
  waitingListId: Maybe<Scalars['String']>;
  workspaceId: Maybe<Scalars['String']>;
};

export type KiveAccessPayload = {
  __typename?: 'KiveAccessPayload';
  context: Maybe<KiveAccessContext>;
  hasAccess: Scalars['Boolean'];
  status: KiveAccessStatus;
};

export type KiveAccessStatus =
  | 'APPROVED_WAITINGLIST'
  | 'HAS_ALLOWED_DOMAIN_IN_WORKSPACE'
  | 'HAS_INVITATION'
  | 'HAS_REFERRAL_DIRECT_ACCESS'
  | 'HAS_USER'
  | 'INVALID_EMAIL'
  | 'NO_MATCH'
  | 'UNAPPROVED_WAITINGLIST';

export type MagicBoard = {
  __typename?: 'MagicBoard';
  averageScore: Scalars['Float'];
  count: Scalars['Int'];
  coverUrl: Maybe<Scalars['String']>;
  coverUrls: Array<Scalars['String']>;
  description: Scalars['String'];
  id: Scalars['String'];
  relevance: Scalars['Float'];
  score: Scalars['Float'];
  suggestedFor: Array<Scalars['String']>;
  tagId: Scalars['String'];
  thumbUrl: Maybe<Scalars['String']>;
  thumbUrls: Array<Scalars['String']>;
  uniqueness: Scalars['Float'];
};

export type MagicBoardInput = {
  magicBoardId: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type MagicBoardItemsInput = {
  cursor: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
  magicBoardId: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type MagicBoardItemsPayload = {
  __typename?: 'MagicBoardItemsPayload';
  magicBoardItems: Array<WorkspaceItem>;
  pageInfo: PageInfo;
};

export type MagicBoardsInput = {
  cursor: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
  freeTextSearch: InputMaybe<Scalars['String']>;
  workspaceId: Scalars['String'];
};

export type MagicBoardsPayload = {
  __typename?: 'MagicBoardsPayload';
  magicBoards: Array<MagicBoard>;
  pageInfo: PageInfo;
};

export type MarkNotificationsAsSeenInput = {
  workspaceId: Scalars['String'];
};

export type MediaVariants = {
  __typename?: 'MediaVariants';
  type: Scalars['String'];
  url: Scalars['String'];
  variant: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addBoard: Board;
  addDiscountCouponToWorkspace: RequestStatus;
  addEditSessionPowerUp: AddPowerUpResponse;
  addGoogleAnalyticsIdFromWaitingListId: GoogleAnalyticsId;
  addItemComment: ItemComment;
  addItemReaction: ItemReaction;
  addProject: Project;
  addPromoCodeToWorkspace: BillingSubscription;
  addRecentBoard: RecentBoard;
  addUser: User;
  addUserIdToGoogleAnalyticsId: GoogleAnalyticsId;
  addWaitingListEntry: WaitingListEntry;
  addWorkspace: Workspace;
  addWorkspaceItemTag: ItemTagSimple;
  admin_hideFromDiscovery: RequestStatus;
  changeWorkspaceMemberRole: Workspace;
  closeEditSession: EditSession;
  createBillingPortalSession: BillingPortalSession;
  createCancellationSurvey: RequestStatus;
  createCheckoutSession: CheckoutSession;
  createCreditPackCheckoutSession: CheckoutSession;
  createCustomToken: Scalars['String'];
  createEditSession: EditSession;
  createUserLoginOTP: UserLoginOtpPayload;
  deleteBoard: RequestStatus;
  deleteBoardCover: Board;
  deleteBoardItem: RequestStatus;
  deleteProject: RequestStatus;
  deleteUser: RequestStatus;
  deleteWorkspace: RequestStatus;
  deleteWorkspaceItem: RequestStatus;
  deleteWorkspaceItemTag: RequestStatus;
  incrementBoardItemViews: BoardItem;
  incrementWorkspaceItemViews: WorkspaceItem;
  joinWorkspace: Workspace;
  leaveWorkspace: RequestStatus;
  markAllNotificationsAsRead: RequestStatus;
  markNotificationAsRead: Notification;
  markNotificationsAsSeen: RequestStatus;
  outpaint: Array<Scalars['String']>;
  removeEditSessionPowerUp: RemovePowerUpResponse;
  removeItemComment: RequestStatus;
  removeItemReaction: RequestStatus;
  removeWorkspaceMember: RequestStatus;
  respondToEditSessionInvite: EditSessionInvite;
  saveDiscoveryItemToBoard: BoardItem;
  saveDiscoveryItemToWorkspace: WorkspaceItem;
  saveWorkspaceItemsToBoard: RequestStatus;
  sendEditSessionInvite: EditSessionInvite;
  sendKiveInvitation: RequestStatus;
  sendWorkspaceInvitation: Workspace;
  setPushToken: RequestStatus;
  setupUser: User;
  updateBio: User;
  updateBoardIcon: Board;
  updateBoardItemTitle: BoardItem;
  updateBoardItemsAccessLevel: RequestStatus;
  updateBoardLinkAccess: Board;
  updateBoardTitle: Board;
  updateEmail: User;
  updateFirstName: User;
  updateJobTitle: User;
  updateLastActiveWorkspace: RequestStatus;
  updateLastName: User;
  updateProjectBoardsPriority: RequestStatus;
  updateProjectLinkAccess: Project;
  updateProjectTitle: Project;
  updateProjectWorkspaceAccess: Project;
  updateRecentTags: UserRecentTagPayload;
  updateUserLearningGuides: Scalars['JSONObject'];
  updateUserTasks: Scalars['JSONObject'];
  updateWorkspaceItemTitle: WorkspaceItem;
  updateWorkspaceItemsAccessLevel: RequestStatus;
  updateWorkspaceTitle: Workspace;
  uploadBoardItems: UploadBoardItemsPayload;
  uploadCoverPhoto: User;
  uploadProfilePicture: User;
  uploadWorkspaceItems: UploadWorkspaceItemsPayload;
  upsertBoardCover: Board;
  upsertBoardDescription: Board;
  userLoginOTPExchange: UserLoginOtpExchangePayload;
};


export type MutationAddBoardArgs = {
  input: AddBoardInput;
};


export type MutationAddDiscountCouponToWorkspaceArgs = {
  input?: InputMaybe<AddDiscountCouponToWorkspaceInput>;
};


export type MutationAddEditSessionPowerUpArgs = {
  input: AddEditSessionPowerUpInput;
};


export type MutationAddGoogleAnalyticsIdFromWaitingListIdArgs = {
  waitingListId: Scalars['String'];
};


export type MutationAddItemCommentArgs = {
  input: AddItemCommentInput;
};


export type MutationAddItemReactionArgs = {
  input: AddItemReactionInput;
};


export type MutationAddProjectArgs = {
  input: AddProjectInput;
};


export type MutationAddPromoCodeToWorkspaceArgs = {
  input?: InputMaybe<AddPromoCodeInput>;
};


export type MutationAddRecentBoardArgs = {
  input: AddRecentBoardInput;
};


export type MutationAddUserArgs = {
  input: AddUserInput;
};


export type MutationAddUserIdToGoogleAnalyticsIdArgs = {
  input: AddUserIdToGoogleAnalyticsIdInput;
};


export type MutationAddWaitingListEntryArgs = {
  input: AddWaitingListInput;
};


export type MutationAddWorkspaceArgs = {
  input: AddWorkspaceInput;
};


export type MutationAddWorkspaceItemTagArgs = {
  input: AddWorkspaceItemTagInput;
};


export type MutationAdmin_HideFromDiscoveryArgs = {
  input: HideFromDiscoveryInput;
};


export type MutationChangeWorkspaceMemberRoleArgs = {
  input: ChangeWorkspaceMemberRoleInput;
};


export type MutationCloseEditSessionArgs = {
  input: CloseEditSessionInput;
};


export type MutationCreateBillingPortalSessionArgs = {
  input: CreateBillingPortalSessionInput;
};


export type MutationCreateCancellationSurveyArgs = {
  input: CreateCancellationSurveyInput;
};


export type MutationCreateCheckoutSessionArgs = {
  input: CheckoutInput;
};


export type MutationCreateCreditPackCheckoutSessionArgs = {
  input: CreditPackCheckoutInput;
};


export type MutationCreateCustomTokenArgs = {
  input: CreateCustomTokenInput;
};


export type MutationCreateEditSessionArgs = {
  input: CreateEditSessionInput;
};


export type MutationCreateUserLoginOtpArgs = {
  input: CreateUserLoginOtpInput;
};


export type MutationDeleteBoardArgs = {
  input: DeleteBoardInput;
};


export type MutationDeleteBoardCoverArgs = {
  input: BoardInput;
};


export type MutationDeleteBoardItemArgs = {
  input: DeleteBoardItemInput;
};


export type MutationDeleteProjectArgs = {
  input: DeleteProjectInput;
};


export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};


export type MutationDeleteWorkspaceArgs = {
  input: DeleteWorkspaceInput;
};


export type MutationDeleteWorkspaceItemArgs = {
  input?: InputMaybe<DeleteWorkspaceItemInput>;
};


export type MutationDeleteWorkspaceItemTagArgs = {
  input?: InputMaybe<DeleteWorkspaceItemTagInput>;
};


export type MutationIncrementBoardItemViewsArgs = {
  input?: InputMaybe<IncrementBoardItemViewsInput>;
};


export type MutationIncrementWorkspaceItemViewsArgs = {
  input?: InputMaybe<IncrementWorkspaceItemViewsInput>;
};


export type MutationJoinWorkspaceArgs = {
  id: Scalars['String'];
};


export type MutationLeaveWorkspaceArgs = {
  id: Scalars['String'];
};


export type MutationMarkAllNotificationsAsReadArgs = {
  workspaceId: Scalars['String'];
};


export type MutationMarkNotificationAsReadArgs = {
  notificationId: Scalars['String'];
};


export type MutationMarkNotificationsAsSeenArgs = {
  input: MarkNotificationsAsSeenInput;
};


export type MutationOutpaintArgs = {
  input: OutpaintInput;
};


export type MutationRemoveEditSessionPowerUpArgs = {
  input: RemoveEditSessionPowerUpInput;
};


export type MutationRemoveItemCommentArgs = {
  input: RemoveItemCommentInput;
};


export type MutationRemoveItemReactionArgs = {
  input: RemoveItemReactionInput;
};


export type MutationRemoveWorkspaceMemberArgs = {
  input: RemoveWorkspaceMemberInput;
};


export type MutationRespondToEditSessionInviteArgs = {
  input: RespondToEditSessionInviteInput;
};


export type MutationSaveDiscoveryItemToBoardArgs = {
  input: SaveDiscoveryItemToBoardInput;
};


export type MutationSaveDiscoveryItemToWorkspaceArgs = {
  input: SaveDiscoveryItemToWorkspaceInput;
};


export type MutationSaveWorkspaceItemsToBoardArgs = {
  input: SaveWorkspaceItemsToBoardInput;
};


export type MutationSendEditSessionInviteArgs = {
  input: SendEditSessionInviteInput;
};


export type MutationSendKiveInvitationArgs = {
  input: SendKiveInvitationInput;
};


export type MutationSendWorkspaceInvitationArgs = {
  email: Scalars['String'];
  inviteSource: Scalars['String'];
  workspaceId: Scalars['String'];
};


export type MutationSetPushTokenArgs = {
  input: SetPushTokenInput;
};


export type MutationSetupUserArgs = {
  input: SetupUserInput;
};


export type MutationUpdateBioArgs = {
  input?: InputMaybe<UpdateBioInput>;
};


export type MutationUpdateBoardIconArgs = {
  input: UpdateBoardIconInput;
};


export type MutationUpdateBoardItemTitleArgs = {
  input: UpdateBoardItemTitleInput;
};


export type MutationUpdateBoardItemsAccessLevelArgs = {
  input?: InputMaybe<UpdateBoardItemsAccessLevelInput>;
};


export type MutationUpdateBoardLinkAccessArgs = {
  input: UpdateBoardLinkAccessInput;
};


export type MutationUpdateBoardTitleArgs = {
  input: UpdateBoardTitleInput;
};


export type MutationUpdateEmailArgs = {
  input?: InputMaybe<UpdateEmailInput>;
};


export type MutationUpdateFirstNameArgs = {
  input?: InputMaybe<UpdateFirstNameInput>;
};


export type MutationUpdateJobTitleArgs = {
  input?: InputMaybe<UpdateJobTitleInput>;
};


export type MutationUpdateLastActiveWorkspaceArgs = {
  input: UpdateLastActiveWorkspaceInput;
};


export type MutationUpdateLastNameArgs = {
  input?: InputMaybe<UpdateLastNameInput>;
};


export type MutationUpdateProjectBoardsPriorityArgs = {
  input: UpdateProjectBoardsPriority;
};


export type MutationUpdateProjectLinkAccessArgs = {
  input: UpdateProjectLinkAccessInput;
};


export type MutationUpdateProjectTitleArgs = {
  input: UpdateProjectTitleInput;
};


export type MutationUpdateProjectWorkspaceAccessArgs = {
  input: UpdateProjectWorkspaceAccessInput;
};


export type MutationUpdateRecentTagsArgs = {
  input: UpdateRecentTagsInput;
};


export type MutationUpdateUserLearningGuidesArgs = {
  input: Scalars['JSONObject'];
};


export type MutationUpdateUserTasksArgs = {
  input: Scalars['JSONObject'];
};


export type MutationUpdateWorkspaceItemTitleArgs = {
  input: UpdateWorkspaceItemTitleInput;
};


export type MutationUpdateWorkspaceItemsAccessLevelArgs = {
  input?: InputMaybe<UpdateWorkspaceItemsAccessLevelInput>;
};


export type MutationUpdateWorkspaceTitleArgs = {
  input: UpdateWorkspaceTitleInput;
};


export type MutationUploadBoardItemsArgs = {
  input: UploadBoardItemsInput;
};


export type MutationUploadCoverPhotoArgs = {
  input: UploadCoverPhotoInput;
};


export type MutationUploadProfilePictureArgs = {
  input: UploadProfilePictureInput;
};


export type MutationUploadWorkspaceItemsArgs = {
  input: UploadWorkspaceItemsInput;
};


export type MutationUpsertBoardCoverArgs = {
  input: UpsertBoardCoverInput;
};


export type MutationUpsertBoardDescriptionArgs = {
  input: UpsertBoardDescriptionInput;
};


export type MutationUserLoginOtpExchangeArgs = {
  input: UserLoginOtpExchangeInput;
};

export type Notification = {
  __typename?: 'Notification';
  author: Maybe<User>;
  createdAt: Scalars['String'];
  fromSystem: Scalars['Boolean'];
  genericData: Maybe<GenericNotificationData>;
  id: Scalars['String'];
  isRead: Maybe<Scalars['Boolean']>;
  linkPath: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: Scalars['String'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  nextCursor: Maybe<Scalars['ID']>;
};

export type Plan = {
  __typename?: 'Plan';
  defaultOption: Scalars['Boolean'];
  features: Array<Maybe<PlanFeature>>;
  id: Scalars['ID'];
  limits: PlanLimits;
  order: Maybe<Scalars['Int']>;
  presentation: PlanPresentation;
  status: Maybe<PlanStatus>;
};

export type PlanFeature = {
  __typename?: 'PlanFeature';
  replace_key: Maybe<Scalars['String']>;
  subline: Maybe<Scalars['String']>;
  template: Scalars['String'];
};

export type PlanLimits = {
  __typename?: 'PlanLimits';
  boardItems: Maybe<Scalars['Int']>;
  boards: Maybe<Scalars['Int']>;
  enableCollabWorkspaces: Maybe<Scalars['Boolean']>;
  enableCustomBranding: Maybe<Scalars['Boolean']>;
  enableLinkSharing: Maybe<Scalars['Boolean']>;
  frameExtractions: Maybe<Scalars['Int']>;
  gifExtraction: Maybe<Scalars['Boolean']>;
  guests: Maybe<Scalars['Int']>;
  items: Maybe<Scalars['Int']>;
  projects: Maybe<Scalars['Int']>;
  vatBilling: Maybe<Scalars['Boolean']>;
  videoSupport: Maybe<Scalars['Boolean']>;
};

export type PlanPresentation = {
  __typename?: 'PlanPresentation';
  description: Scalars['String'];
  intervals: Maybe<BillingIntervals>;
  name: Scalars['String'];
  price: Scalars['String'];
  priceUnit: Scalars['String'];
};

export type PlanStatus =
  | 'ACTIVE'
  | 'HIDE'
  | 'LEGACY'
  | 'UNKNOWN';

export type Project = {
  __typename?: 'Project';
  boards: Array<Board>;
  createdAt: Scalars['String'];
  createdBy: Maybe<User>;
  createdById: Scalars['String'];
  id: Scalars['String'];
  linkAccess: Maybe<ProjectLinkAccess>;
  ownerEmails: Array<Scalars['String']>;
  thumbUrls: Array<Scalars['String']>;
  title: Scalars['String'];
  updatedAt: Maybe<Scalars['String']>;
  userRole: Maybe<ProjectUserRole>;
  workspaceAccess: Maybe<ProjectWorkspaceAccess>;
  workspaceId: Scalars['String'];
};

export type ProjectInput = {
  projectId: Scalars['String'];
};

export type ProjectLinkAccess =
  | 'commenter'
  | 'reader'
  | 'writer';

export type ProjectUserRole =
  | 'COMMENTER'
  | 'OWNER'
  | 'READER'
  | 'WRITER';

export type ProjectWorkspaceAccess =
  | 'commenter'
  | 'disabled'
  | 'owner'
  | 'reader'
  | 'writer';

export type ProjectsInput = {
  accessLevel: InputMaybe<ProjectUserRole>;
  cursor: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
  workspaceId: Scalars['String'];
};

export type ProjectsPayload = {
  __typename?: 'ProjectsPayload';
  pageInfo: PageInfo;
  projects: Array<Project>;
};

export type PromoCodeInput = {
  promoCode: Scalars['String'];
};

export type PublicBoardsInput = {
  createdBy: InputMaybe<Scalars['String']>;
  cursor: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
};

export type PublicBoardsPayload = {
  __typename?: 'PublicBoardsPayload';
  boards: Array<Board>;
  pageInfo: PageInfo;
};

export type PublicTagsInput = {
  tagIds: Array<Scalars['String']>;
};

export type PushToken = {
  __typename?: 'PushToken';
  deviceId: Scalars['String'];
  token: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  billingSubscription: Maybe<BillingSubscription>;
  board: Board;
  boardItem: BoardItem;
  boardItems: BoardItemsPayload;
  boards: BoardsPayload;
  coupon: StripeCoupon;
  creditPacks: Array<CreditPack>;
  discoverIdeasForBoard: DiscoverIdeasForBoardPayload;
  discoverIdeasForRecentBoards: Array<DiscoverIdeasForBoardPayload>;
  discoverItemsForYou: DiscoverItemsForYouPayload;
  discoverNewItems: DiscoverNewItemsPayload;
  discoverSearchCombos: Array<DiscoverSearchComboPayload>;
  discoverTopContributors: Array<User>;
  editSession: Maybe<EditSession>;
  editSessionInvite: Maybe<EditSessionInvite>;
  editSessions: EditSessionsPayload;
  imageSearchWorkspaceItems: ImageSearchWorkspaceItemPayload;
  itemComments: ItemCommentsPayload;
  itemReactions: Array<ItemReactionGroup>;
  joinableWorkspacesAllowedDomain: Array<Workspace>;
  joinableWorkspacesPendingInvite: Array<Workspace>;
  lastActiveWorkspace: Maybe<Workspace>;
  magicBoard: MagicBoard;
  magicBoardItems: MagicBoardItemsPayload;
  magicBoards: MagicBoardsPayload;
  plans: Array<Plan>;
  previousEditSessions: EditSessionsPayload;
  project: Project;
  projects: ProjectsPayload;
  promoCode: Maybe<StripePromoCode>;
  publicBoards: PublicBoardsPayload;
  publicTags: Array<Tag>;
  publicWorkspaceItemsByUser: WorkspaceItemsByUserPayload;
  recentBoards: Array<Board>;
  searchCategories: Array<SearchCategory>;
  searchWorkspaceItems: SearchWorkspaceItemsPayload;
  similarWorkspaceItems: SimilarWorkspaceItemsPayload;
  suggestedFrameExtractions: Array<VimeoVideo>;
  tagsForYou: Array<Tag>;
  uploadSessionItems: UploadSessionItemsPayload;
  uploadSessionsFinished: UploadSessionsFinishedPayload;
  user: User;
  userEditSessionCounter: Scalars['Int'];
  userLearningGuides: Scalars['JSONObject'];
  userPrivate: UserPrivate;
  userProfile: User;
  userTasks: Scalars['JSONObject'];
  userTopTags: Array<UserTopTag>;
  validateKiveAccess: KiveAccessPayload;
  vimeoUser: Maybe<VimeoUser>;
  waitingListEntry: WaitingListEntry;
  workspace: Workspace;
  workspaceEditSessionCounter: Scalars['Int'];
  workspaceItem: WorkspaceItem;
  workspaceItems: WorkspaceItemsPayload;
  workspacePublicByUrl: WorkspacePublicResult;
  workspaces: WorkspacesPayload;
  workspacesPublic: Array<Workspace>;
};


export type QueryBillingSubscriptionArgs = {
  input: BillingSubscriptionInput;
};


export type QueryBoardArgs = {
  input: BoardInput;
};


export type QueryBoardItemArgs = {
  input: BoardItemInput;
};


export type QueryBoardItemsArgs = {
  input: BoardItemsInput;
};


export type QueryBoardsArgs = {
  input?: InputMaybe<BoardsInput>;
};


export type QueryCouponArgs = {
  input?: InputMaybe<CouponInput>;
};


export type QueryDiscoverIdeasForBoardArgs = {
  input: DiscoverIdeasForBoardInput;
};


export type QueryDiscoverIdeasForRecentBoardsArgs = {
  input: DiscoverIdeasForRecentBoardsInput;
};


export type QueryDiscoverItemsForYouArgs = {
  input: DiscoverItemsForYouInput;
};


export type QueryDiscoverNewItemsArgs = {
  input: DiscoverNewItemsInput;
};


export type QueryDiscoverSearchCombosArgs = {
  input?: InputMaybe<DiscoverSearchCombosInput>;
};


export type QueryDiscoverTopContributorsArgs = {
  input?: InputMaybe<DiscoverTopContributorsInput>;
};


export type QueryEditSessionArgs = {
  editSessionId: Scalars['ID'];
};


export type QueryEditSessionInviteArgs = {
  editSessionInviteId: Scalars['ID'];
};


export type QueryEditSessionsArgs = {
  input: EditSessionsInput;
};


export type QueryImageSearchWorkspaceItemsArgs = {
  input: ImageSearchWorkspaceItemInput;
};


export type QueryItemCommentsArgs = {
  input: ItemCommentsInput;
};


export type QueryItemReactionsArgs = {
  input: ItemReactionsInput;
};


export type QueryMagicBoardArgs = {
  input: MagicBoardInput;
};


export type QueryMagicBoardItemsArgs = {
  input: MagicBoardItemsInput;
};


export type QueryMagicBoardsArgs = {
  input: MagicBoardsInput;
};


export type QueryPreviousEditSessionsArgs = {
  input: EditSessionsInput;
};


export type QueryProjectArgs = {
  input?: InputMaybe<ProjectInput>;
};


export type QueryProjectsArgs = {
  input?: InputMaybe<ProjectsInput>;
};


export type QueryPromoCodeArgs = {
  input?: InputMaybe<PromoCodeInput>;
};


export type QueryPublicBoardsArgs = {
  input: PublicBoardsInput;
};


export type QueryPublicTagsArgs = {
  input: PublicTagsInput;
};


export type QueryPublicWorkspaceItemsByUserArgs = {
  input: WorkspaceItemsByUserInput;
};


export type QueryRecentBoardsArgs = {
  input: RecentBoardsInput;
};


export type QuerySearchWorkspaceItemsArgs = {
  input?: InputMaybe<SearchWorkspaceItemsInput>;
};


export type QuerySimilarWorkspaceItemsArgs = {
  input: SimilarWorkspaceItemsInput;
};


export type QuerySuggestedFrameExtractionsArgs = {
  workspaceId: Scalars['String'];
};


export type QueryUploadSessionItemsArgs = {
  input: UploadSessionItemsInput;
};


export type QueryUploadSessionsFinishedArgs = {
  input: UploadSessionsFinishedInput;
};


export type QueryUserArgs = {
  uid: Scalars['String'];
};


export type QueryUserEditSessionCounterArgs = {
  userId: Scalars['ID'];
};


export type QueryUserPrivateArgs = {
  uid: Scalars['String'];
};


export type QueryUserProfileArgs = {
  input: UserProfileInput;
};


export type QueryValidateKiveAccessArgs = {
  input: ValidateKiveAccessInput;
};


export type QueryWaitingListEntryArgs = {
  input: WaitingListInput;
};


export type QueryWorkspaceArgs = {
  input: WorkspaceInput;
};


export type QueryWorkspaceEditSessionCounterArgs = {
  workspaceId: Scalars['ID'];
};


export type QueryWorkspaceItemArgs = {
  input: WorkspaceItemInput;
};


export type QueryWorkspaceItemsArgs = {
  input: WorkspaceItemsInput;
};


export type QueryWorkspacePublicByUrlArgs = {
  url: Scalars['String'];
};


export type QueryWorkspacesArgs = {
  input: WorkspacesInput;
};

export type RecentBoard = {
  __typename?: 'RecentBoard';
  boardId: Scalars['String'];
  updatedAt: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type RecentBoardInput = {
  boardId: Scalars['String'];
};

export type RecentBoardsInput = {
  workspaceId: Scalars['String'];
};

export type RecurringInterval =
  | 'day'
  | 'month'
  | 'week'
  | 'year';

export type RemoveEditSessionPowerUpInput = {
  editSessionId: Scalars['ID'];
};

export type RemoveItemCommentInput = {
  boardId: InputMaybe<Scalars['String']>;
  itemCommentId: Scalars['String'];
};

export type RemoveItemReactionInput = {
  boardId: InputMaybe<Scalars['String']>;
  itemReactionId: Scalars['String'];
};

export type RemovePowerUpResponse = {
  __typename?: 'RemovePowerUpResponse';
  message: Maybe<Scalars['String']>;
  result: RemovePowerUpResult;
};

export type RemovePowerUpResult =
  | 'error'
  | 'ok';

export type RemoveWorkspaceMemberInput = {
  email: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type RequestStatus = {
  __typename?: 'RequestStatus';
  status: Scalars['String'];
};

export type RespondToEditSessionInviteInput = {
  editSessionInviteId: Scalars['ID'];
  responder: EditSessionInviteMemberInput;
  response: SessionInviteResponse;
};

export type SaveDiscoveryItemToBoardInput = {
  boardId: Scalars['String'];
  isBoardItem: InputMaybe<Scalars['Boolean']>;
  itemId: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type SaveDiscoveryItemToWorkspaceInput = {
  isBoardItem: InputMaybe<Scalars['Boolean']>;
  itemId: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type SaveWorkspaceItemsToBoardInput = {
  boardId: Scalars['String'];
  workspaceId: Scalars['String'];
  workspaceItemIds: Array<Scalars['String']>;
};

export type SearchCategory = {
  __typename?: 'SearchCategory';
  emojiCode: Scalars['String'];
  id: Scalars['String'];
  searchSuggestions: Array<Scalars['String']>;
  title: Scalars['String'];
};

export type SearchCombo = SearchComboColor | SearchComboFace | SearchComboMediaType | SearchComboTag;

export type SearchComboColor = {
  __typename?: 'SearchComboColor';
  color: Scalars['String'];
  description: Scalars['String'];
};

export type SearchComboFace = {
  __typename?: 'SearchComboFace';
  description: Scalars['String'];
  face: Scalars['String'];
};

export type SearchComboMediaType = {
  __typename?: 'SearchComboMediaType';
  description: Scalars['String'];
  mediaType: ItemMediaType;
};

export type SearchComboTag = {
  __typename?: 'SearchComboTag';
  description: Scalars['String'];
  tagId: Scalars['String'];
};

export type SearchWorkspaceItemsFilterInput = {
  colors: InputMaybe<Array<Scalars['String']>>;
  faces: InputMaybe<Array<Scalars['String']>>;
  folders: InputMaybe<Array<Scalars['String']>>;
  hiddenHits: InputMaybe<Array<Scalars['String']>>;
  mediaType: InputMaybe<ItemMediaType>;
  name: InputMaybe<Scalars['String']>;
  tagIds: InputMaybe<Array<Scalars['String']>>;
  uploadSessionIds: InputMaybe<Array<Scalars['String']>>;
  workspaceIds: InputMaybe<Array<Scalars['String']>>;
};

export type SearchWorkspaceItemsInput = {
  cursor: InputMaybe<Scalars['String']>;
  filters: InputMaybe<SearchWorkspaceItemsFilterInput>;
  first: Scalars['Int'];
  query: InputMaybe<Scalars['String']>;
  scope: SearchWorkspaceItemsScope;
};

export type SearchWorkspaceItemsPayload = {
  __typename?: 'SearchWorkspaceItemsPayload';
  pageInfo: PageInfo;
  workspaceItems: Array<WorkspaceItem>;
};

export type SearchWorkspaceItemsScope =
  | 'PUBLIC'
  | 'WORKSPACE';

export type SendEditSessionInviteInput = {
  editSessionId: Scalars['ID'];
  from: EditSessionInviteMemberInput;
  message: InputMaybe<Scalars['String']>;
  to: InputMaybe<EditSessionInviteMemberInput>;
};

export type SendKiveInvitationInput = {
  email: Scalars['String'];
  nominationPanelTrackingId: InputMaybe<Scalars['String']>;
  vimeoId: InputMaybe<Scalars['String']>;
};

export type SessionInviteResponse =
  | 'accept'
  | 'decline';

export type SetPushTokenInput = {
  deviceId: Scalars['String'];
  token: Scalars['String'];
};

export type SetupUserInput = {
  firstName: Scalars['String'];
  jobTitle: Scalars['String'];
  lastName: Scalars['String'];
  timesReadPrivacyPolicy: Scalars['Int'];
  timesReadTermsOfService: Scalars['Int'];
  timesUploadedProfileImage: InputMaybe<Scalars['Int']>;
};

export type SignupContext = {
  fbclid: InputMaybe<Scalars['String']>;
  gclid: InputMaybe<Scalars['String']>;
};

export type SimilarWorkspaceItemsInput = {
  cursor: InputMaybe<Scalars['String']>;
  scope: SimilarWorkspaceItemsScope;
  workspaceId: InputMaybe<Scalars['String']>;
  workspaceItemId: Scalars['String'];
};

export type SimilarWorkspaceItemsPayload = {
  __typename?: 'SimilarWorkspaceItemsPayload';
  pageInfo: PageInfo;
  similarItems: Array<WorkspaceItem>;
};

export type SimilarWorkspaceItemsScope =
  | 'PUBLIC'
  | 'WORKSPACE';

export type StripeCoupon = {
  __typename?: 'StripeCoupon';
  amountOff: Maybe<Scalars['Int']>;
  created: Scalars['Int'];
  id: Scalars['String'];
  name: Scalars['String'];
  percentOff: Maybe<Scalars['Float']>;
  timesRedeemed: Maybe<Scalars['Int']>;
};

export type StripeDiscount = {
  __typename?: 'StripeDiscount';
  coupon: StripeCoupon;
  id: Scalars['String'];
  promotion_code: Maybe<StripePromoCode>;
};

export type StripePrice = {
  __typename?: 'StripePrice';
  adjustedMonthlyValue: Scalars['Int'];
  currency: Scalars['String'];
  id: Scalars['ID'];
  priceString: Scalars['String'];
  priceValue: Scalars['Int'];
  product: StripeProduct;
  recurring: StripeRecurring;
  taxBehavior: Scalars['String'];
  unitAmount: Scalars['Int'];
};

export type StripeProduct = {
  __typename?: 'StripeProduct';
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  unitLabel: Maybe<Scalars['String']>;
};

export type StripePromoCode = {
  __typename?: 'StripePromoCode';
  active: Maybe<Scalars['Boolean']>;
  code: Maybe<Scalars['String']>;
  coupon: StripeCoupon;
  customer: Maybe<Scalars['String']>;
  expires_at: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['String']>;
  max_redemptions: Maybe<Scalars['Int']>;
  restrictions: StripePromoCodeRestrictions;
  times_redeemed: Maybe<Scalars['Int']>;
};

export type StripePromoCodeRestrictions = {
  __typename?: 'StripePromoCodeRestrictions';
  first_time_transactions: Maybe<Scalars['Boolean']>;
  minimum_amount: Maybe<Scalars['Int']>;
  minimum_amount_currency: Maybe<Scalars['Int']>;
};

export type StripeRecurring = {
  __typename?: 'StripeRecurring';
  aggregateUsage: Scalars['String'];
  interval: RecurringInterval;
  intervalCount: Scalars['Int'];
  usageType: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  uploadSession: UploadSession;
  user: User;
  userPrivate: UserPrivate;
  workspaceNotifications: Array<Notification>;
};


export type SubscriptionUploadSessionArgs = {
  input?: InputMaybe<UploadSessionInput>;
};


export type SubscriptionUserArgs = {
  uid: Scalars['String'];
};


export type SubscriptionUserPrivateArgs = {
  uid: Scalars['String'];
};


export type SubscriptionWorkspaceNotificationsArgs = {
  workspaceId: Scalars['String'];
};

export type SubscriptionItem = {
  __typename?: 'SubscriptionItem';
  id: Scalars['ID'];
  price: StripePrice;
  quantity: Scalars['Int'];
};

export type Tag = {
  __typename?: 'Tag';
  count: Scalars['Int'];
  description: Scalars['String'];
  id: Scalars['String'];
  score: Scalars['Float'];
  tagId: Scalars['String'];
  thumbUrl: Maybe<Scalars['String']>;
};

export type UnseenNotifications = {
  __typename?: 'UnseenNotifications';
  count: Scalars['Int'];
  workspaceId: Scalars['String'];
};

export type UpdateBioInput = {
  bio: Scalars['String'];
};

export type UpdateBoardIconInput = {
  boardId: Scalars['String'];
  iconCode: InputMaybe<Scalars['String']>;
};

export type UpdateBoardItemTitleInput = {
  boardItemId: Scalars['String'];
  title: Scalars['String'];
};

export type UpdateBoardItemsAccessLevelInput = {
  accessLevel: BoardItemAccessLevel;
  boardId: Scalars['String'];
  boardItemIds: Array<Scalars['String']>;
};

export type UpdateBoardLinkAccessInput = {
  boardId: Scalars['String'];
  boardLinkAccess: InputMaybe<BoardLinkAccess>;
};

export type UpdateBoardTitleInput = {
  boardId: Scalars['String'];
  title: Scalars['String'];
};

export type UpdateEmailInput = {
  email: Scalars['String'];
};

export type UpdateFirstNameInput = {
  firstName: Scalars['String'];
};

export type UpdateJobTitleInput = {
  jobTitle: Scalars['String'];
};

export type UpdateLastActiveWorkspaceInput = {
  workspaceId: Scalars['String'];
};

export type UpdateLastNameInput = {
  lastName: Scalars['String'];
};

export type UpdateProjectBoardsPriority = {
  payload: Array<BoardPriorityInput>;
  projectId: Scalars['String'];
};

export type UpdateProjectLinkAccessInput = {
  projectId: Scalars['String'];
  projectLinkAccess: InputMaybe<ProjectLinkAccess>;
};

export type UpdateProjectTitleInput = {
  projectId: Scalars['String'];
  title: Scalars['String'];
};

export type UpdateProjectWorkspaceAccessInput = {
  projectId: Scalars['String'];
  projectWorkspaceAccess: ProjectWorkspaceAccess;
};

export type UpdateRecentTagsInput = {
  tags: Array<UserRecentTagInput>;
};

export type UpdateWorkspaceItemTitleInput = {
  title: Scalars['String'];
  workspaceItemId: Scalars['String'];
};

export type UpdateWorkspaceItemsAccessLevelInput = {
  accessLevel: WorkspaceItemAccessLevel;
  itemIds: InputMaybe<Array<Scalars['String']>>;
  uploadSessionId: InputMaybe<Scalars['String']>;
  workspaceId: Scalars['String'];
};

export type UpdateWorkspaceTitleInput = {
  title: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type UploadBoardItemsInput = {
  boardId: Scalars['String'];
  files: Array<Scalars['Upload']>;
  projectId: Scalars['String'];
  uploadOptions: InputMaybe<UploadOptions>;
  workspaceId: Scalars['String'];
};

export type UploadBoardItemsPayload = {
  __typename?: 'UploadBoardItemsPayload';
  count: Scalars['Int'];
  uploadSessionId: Scalars['String'];
};

export type UploadCoverPhotoInput = {
  file: Scalars['Upload'];
};

export type UploadOptions = {
  isPublicUpload: InputMaybe<Scalars['Boolean']>;
};

export type UploadProfilePictureInput = {
  file: Scalars['Upload'];
};

export type UploadSession = {
  __typename?: 'UploadSession';
  boardId: Maybe<Scalars['String']>;
  coverUrl: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy: Scalars['String'];
  finishedAnalyzingAt: Maybe<Scalars['String']>;
  finishedUploadingAt: Maybe<Scalars['String']>;
  hasFinishedAnalyzing: Scalars['Boolean'];
  hasFinishedUploading: Scalars['Boolean'];
  id: Scalars['String'];
  isCreatingMagicBoards: Scalars['Boolean'];
  processingFailed: Scalars['Int'];
  projectId: Maybe<Scalars['String']>;
  size: Scalars['Int'];
  tagCount: Scalars['Int'];
  thumbUrl: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['String'];
  updatedMagicBoardsAt: Maybe<Scalars['String']>;
  workspaceId: Scalars['String'];
};

export type UploadSessionInput = {
  uploadSessionId: Scalars['String'];
};

export type UploadSessionItemsInput = {
  cursor: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
  uploadSessionId: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type UploadSessionItemsPayload = {
  __typename?: 'UploadSessionItemsPayload';
  pageInfo: PageInfo;
  uploadSessionItems: Array<WorkspaceItem>;
};

export type UploadSessionsFinishedInput = {
  cursor: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
  workspaceId: Scalars['String'];
};

export type UploadSessionsFinishedPayload = {
  __typename?: 'UploadSessionsFinishedPayload';
  pageInfo: PageInfo;
  uploadSessions: Array<UploadSession>;
};

export type UploadWorkspaceItemsInput = {
  files: Array<Scalars['Upload']>;
  uploadOptions: InputMaybe<UploadOptions>;
  workspaceId: Scalars['String'];
};

export type UploadWorkspaceItemsPayload = {
  __typename?: 'UploadWorkspaceItemsPayload';
  count: Scalars['Int'];
  uploadSessionId: Scalars['String'];
};

export type UpsertBoardCoverInput = {
  boardId: Scalars['String'];
  coverSrc: Scalars['String'];
};

export type UpsertBoardDescriptionInput = {
  boardDescription: Scalars['String'];
  boardId: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  bio: Maybe<Scalars['String']>;
  coverImageUrl: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  credit: Maybe<Scalars['Int']>;
  discoverExportCount: Maybe<Scalars['Int']>;
  displayName: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  frozenAccount: Maybe<Scalars['Boolean']>;
  generationRequests: Maybe<GenerationRequests>;
  googleAnalyticsId: Maybe<Scalars['String']>;
  handle: Scalars['String'];
  id: Scalars['String'];
  invitedBy: Maybe<User>;
  jobTitle: Scalars['String'];
  joinedWithoutInvitation: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  nominatedBy: Maybe<User>;
  photoURL: Maybe<Scalars['String']>;
  plans: UserPlans;
  private: UserPrivate;
  publicItemsCount: Scalars['Int'];
  rank: Maybe<Scalars['Int']>;
  referralsLeft: Maybe<Scalars['Int']>;
  uid: Scalars['String'];
  unseenNotifications: Array<Maybe<UnseenNotifications>>;
  usersRefferedCount: Maybe<Scalars['Int']>;
  vimeoUserId: Maybe<Scalars['String']>;
};

export type UserFeatureAccess = {
  __typename?: 'UserFeatureAccess';
  community: Scalars['Boolean'];
  discover: Scalars['Boolean'];
  knowledgeGraph: Scalars['Boolean'];
  profile: Scalars['Boolean'];
  vimeoImport: Scalars['Boolean'];
};

export type UserLoginOtpExchangeInput = {
  code: Scalars['Int'];
  word: Scalars['String'];
};

export type UserLoginOtpExchangePayload = {
  __typename?: 'UserLoginOTPExchangePayload';
  token: Scalars['String'];
};

export type UserLoginOtpPayload = {
  __typename?: 'UserLoginOTPPayload';
  code: Scalars['Int'];
  word: Scalars['String'];
};

export type UserOnboarding = {
  __typename?: 'UserOnboarding';
  completedInvite: Scalars['Boolean'];
  completedPrivacy: Scalars['Boolean'];
  completedProfile: Scalars['Boolean'];
  completedSignup: Scalars['Boolean'];
  completedUploads: Scalars['Boolean'];
  completedWorkspace: Scalars['Boolean'];
};

export type UserOrGuest = Guest | User;

export type UserPlans = {
  __typename?: 'UserPlans';
  isMemberOfProPlan: Scalars['Boolean'];
  isMemberOfTeamPlan: Scalars['Boolean'];
};

export type UserPrivate = {
  __typename?: 'UserPrivate';
  featureAccess: Maybe<UserFeatureAccess>;
  onboarding: Maybe<UserOnboarding>;
  pushTokens: Array<PushToken>;
  tags: Maybe<UserTags>;
  usage: Maybe<UserUsage>;
};

export type UserProfileInput = {
  userHandle: InputMaybe<Scalars['String']>;
  userId: InputMaybe<Scalars['String']>;
};

export type UserRecentTag = {
  __typename?: 'UserRecentTag';
  description: Scalars['String'];
  id: Scalars['String'];
};

export type UserRecentTagInput = {
  description: Scalars['String'];
  id: Scalars['String'];
};

export type UserRecentTagPayload = {
  __typename?: 'UserRecentTagPayload';
  recentTags: Array<UserRecentTag>;
  recentlyAddedTags: Maybe<Array<UserRecentTag>>;
  topTags: Array<UserTopTag>;
};

export type UserTags = {
  __typename?: 'UserTags';
  recentTags: Array<UserRecentTag>;
  recentlyAddedTags: Maybe<Array<UserRecentTag>>;
  topTags: Maybe<Array<UserTopTag>>;
};

export type UserTopTag = {
  __typename?: 'UserTopTag';
  count: Scalars['Int'];
  description: Scalars['String'];
  id: Scalars['String'];
};

export type UserUsage = {
  __typename?: 'UserUsage';
  lastActiveWorkspace: Maybe<Scalars['String']>;
  searchKey: Scalars['String'];
};

export type ValidateKiveAccessInput = {
  email: Scalars['String'];
  referralId: InputMaybe<Scalars['String']>;
};

export type VimeoContact = {
  __typename?: 'VimeoContact';
  email: Scalars['String'];
  img: Maybe<Scalars['String']>;
  name: Scalars['String'];
  vimeoId: Scalars['String'];
};

export type VimeoCreator = {
  __typename?: 'VimeoCreator';
  link: Scalars['String'];
  name: Scalars['String'];
};

export type VimeoUser = {
  __typename?: 'VimeoUser';
  bio: Maybe<Scalars['String']>;
  followerCount: Scalars['Int'];
  followingCount: Scalars['Int'];
  id: Scalars['String'];
  membershipType: Scalars['String'];
  suggestedContacts: Array<VimeoContact>;
  suggestedFrameExtractions: Array<VimeoVideo>;
  vimeoId: Scalars['String'];
};


export type VimeoUserSuggestedFrameExtractionsArgs = {
  workspaceId: Scalars['String'];
};

export type VimeoVideo = {
  __typename?: 'VimeoVideo';
  creator: VimeoCreator;
  duration: Scalars['Int'];
  height: Scalars['Int'];
  link: Scalars['String'];
  name: Maybe<Scalars['String']>;
  thumbnail: Scalars['String'];
  width: Scalars['Int'];
};

export type WaitingListEntry = {
  __typename?: 'WaitingListEntry';
  analyzeIsComplete: Scalars['Boolean'];
  approved: Scalars['Boolean'];
  completedFormAt: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['String'];
  name: Maybe<Scalars['String']>;
  referralId: Maybe<Scalars['String']>;
};

export type WaitingListInput = {
  email: InputMaybe<Scalars['String']>;
  waitingListId: InputMaybe<Scalars['String']>;
};

export type Workspace = {
  __typename?: 'Workspace';
  adminEmails: Array<Scalars['String']>;
  /** @deprecated No longer supported */
  adminIds: Array<Scalars['String']>;
  allowedEmailDomains: Array<Scalars['String']>;
  createdAt: Scalars['String'];
  createdByUserId: Scalars['String'];
  guestEmails: Array<Scalars['String']>;
  /** @deprecated No longer supported */
  guestIds: Array<Scalars['String']>;
  iconUrl: Maybe<Scalars['String']>;
  id: Scalars['String'];
  memberEmails: Array<Scalars['String']>;
  /** @deprecated No longer supported */
  memberIds: Array<Scalars['String']>;
  members: Array<WorkspaceMember>;
  pendingInvitations: Array<Scalars['String']>;
  projects: Array<Project>;
  publicAccessStatus: WorkspacePublicAccessStatus;
  thumbUrls: Array<Scalars['String']>;
  title: Scalars['String'];
  totalItemsCount: Scalars['Int'];
  updatedAt: Maybe<Scalars['String']>;
  updatedByUserId: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type WorkspaceInput = {
  workspaceId: Scalars['String'];
};

export type WorkspaceItem = {
  __typename?: 'WorkspaceItem';
  accessLevel: WorkspaceItemAccessLevel;
  aspectRatio: Scalars['Float'];
  colors: Array<Color>;
  commentCount: Scalars['Int'];
  createdAt: Scalars['String'];
  createdBy: Maybe<User>;
  id: Scalars['String'];
  itemSourceInfo: ItemSourceInfo;
  mediaType: ItemMediaType;
  mediaVariants: Array<MediaVariants>;
  openCount: Scalars['Int'];
  original: ItemOriginal;
  tags: Array<WorkspaceItemTag>;
  title: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['String']>;
  uploadSessionId: Scalars['String'];
  userRole: WorkspaceItemUserRole;
  webUrl: Maybe<Scalars['String']>;
  workspaceId: Scalars['String'];
};

export type WorkspaceItemAccessLevel =
  | 'PRIVATE'
  | 'PUBLIC'
  | 'WITH_LINK';

export type WorkspaceItemInput = {
  workspaceItemId: Scalars['String'];
};

export type WorkspaceItemTag = {
  __typename?: 'WorkspaceItemTag';
  description: Scalars['String'];
  id: Scalars['String'];
  score: Scalars['Float'];
  tagId: Scalars['String'];
};

export type WorkspaceItemUserRole =
  | 'COMMENTER'
  | 'OWNER'
  | 'READER'
  | 'WRITER';

export type WorkspaceItemsByUserInput = {
  cursor: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
  userId: Scalars['String'];
};

export type WorkspaceItemsByUserPayload = {
  __typename?: 'WorkspaceItemsByUserPayload';
  pageInfo: PageInfo;
  workspaceItems: Array<WorkspaceItem>;
};

export type WorkspaceItemsInput = {
  cursor: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
  workspaceId: Scalars['String'];
};

export type WorkspaceItemsPayload = {
  __typename?: 'WorkspaceItemsPayload';
  pageInfo: PageInfo;
  workspaceItems: Array<WorkspaceItem>;
};

export type WorkspaceMember = {
  __typename?: 'WorkspaceMember';
  role: WorkspaceMemberRole;
  user: User;
};

export type WorkspaceMemberRole =
  | 'ADMIN'
  | 'MEMBER';

export type WorkspacePublic = {
  __typename?: 'WorkspacePublic';
  iconUrl: Maybe<Scalars['String']>;
  id: Scalars['String'];
  publicAccessStatus: WorkspacePublicAccessStatus;
  title: Scalars['String'];
  url: Scalars['String'];
};

export type WorkspacePublicAccessStatus =
  | 'OPEN'
  | 'PRIVATE'
  | 'REQUEST_INVITE';

export type WorkspacePublicResult = Workspace | WorkspacePublic;

export type WorkspacesInput = {
  cursor: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
};

export type WorkspacesPayload = {
  __typename?: 'WorkspacesPayload';
  workspaces: Array<Workspace>;
};

export type OutpaintInput = {
  editSessionId: InputMaybe<Scalars['ID']>;
  imageData: Scalars['String'];
  maskData: Scalars['String'];
  prompt: Scalars['String'];
};

export type CouponFragment = { __typename: 'StripeCoupon', id: string, name: string, created: number, percentOff: number | null, timesRedeemed: number | null };

export type PromoCodeFragment = { __typename: 'StripePromoCode', id: string | null, code: string | null, customer: string | null, restrictions: { __typename?: 'StripePromoCodeRestrictions', first_time_transactions: boolean | null, minimum_amount: number | null, minimum_amount_currency: number | null } };

export type BillingSubscriptionFragment = { __typename?: 'BillingSubscription', id: string, activeDiscount: { __typename?: 'StripeDiscount', coupon: { __typename: 'StripeCoupon', id: string, name: string, created: number, percentOff: number | null, timesRedeemed: number | null } } | null, subscriptionItems: Array<{ __typename?: 'SubscriptionItem', id: string, quantity: number, price: { __typename?: 'StripePrice', id: string, unitAmount: number, taxBehavior: string, product: { __typename?: 'StripeProduct', id: string, name: string, description: string | null, unitLabel: string | null }, recurring: { __typename?: 'StripeRecurring', aggregateUsage: string, interval: RecurringInterval, intervalCount: number, usageType: string } } }> | null, plan: { __typename?: 'Plan', id: string, defaultOption: boolean, status: PlanStatus | null, limits: { __typename?: 'PlanLimits', items: number | null, boardItems: number | null, boards: number | null, enableCollabWorkspaces: boolean | null, enableLinkSharing: boolean | null, frameExtractions: number | null, projects: number | null, enableCustomBranding: boolean | null, vatBilling: boolean | null, videoSupport: boolean | null, gifExtraction: boolean | null, guests: number | null }, presentation: { __typename?: 'PlanPresentation', name: string, description: string, price: string, priceUnit: string, intervals: { __typename?: 'BillingIntervals', monthly: { __typename?: 'BillingInterval', price: string | null, priceUnit: string | null, priceId: string | null } | null, yearly: { __typename?: 'BillingInterval', price: string | null, priceUnit: string | null, priceId: string | null } | null } | null }, features: Array<{ __typename?: 'PlanFeature', template: string, subline: string | null, replace_key: string | null } | null> } | null };

export type AddDiscountCouponToWorkspaceMutationVariables = Exact<{
  workspaceId: Scalars['String'];
  couponId: Scalars['String'];
}>;


export type AddDiscountCouponToWorkspaceMutation = { __typename?: 'Mutation', addDiscountCouponToWorkspace: { __typename?: 'RequestStatus', status: string } };

export type AddPromoCodeToWorkspaceMutationVariables = Exact<{
  workspaceId: Scalars['String'];
  promoCode: Scalars['String'];
}>;


export type AddPromoCodeToWorkspaceMutation = { __typename?: 'Mutation', addPromoCodeToWorkspace: { __typename?: 'BillingSubscription', id: string, activeDiscount: { __typename?: 'StripeDiscount', coupon: { __typename: 'StripeCoupon', id: string, name: string, created: number, percentOff: number | null, timesRedeemed: number | null } } | null, subscriptionItems: Array<{ __typename?: 'SubscriptionItem', id: string, quantity: number, price: { __typename?: 'StripePrice', id: string, unitAmount: number, taxBehavior: string, product: { __typename?: 'StripeProduct', id: string, name: string, description: string | null, unitLabel: string | null }, recurring: { __typename?: 'StripeRecurring', aggregateUsage: string, interval: RecurringInterval, intervalCount: number, usageType: string } } }> | null, plan: { __typename?: 'Plan', id: string, defaultOption: boolean, status: PlanStatus | null, limits: { __typename?: 'PlanLimits', items: number | null, boardItems: number | null, boards: number | null, enableCollabWorkspaces: boolean | null, enableLinkSharing: boolean | null, frameExtractions: number | null, projects: number | null, enableCustomBranding: boolean | null, vatBilling: boolean | null, videoSupport: boolean | null, gifExtraction: boolean | null, guests: number | null }, presentation: { __typename?: 'PlanPresentation', name: string, description: string, price: string, priceUnit: string, intervals: { __typename?: 'BillingIntervals', monthly: { __typename?: 'BillingInterval', price: string | null, priceUnit: string | null, priceId: string | null } | null, yearly: { __typename?: 'BillingInterval', price: string | null, priceUnit: string | null, priceId: string | null } | null } | null }, features: Array<{ __typename?: 'PlanFeature', template: string, subline: string | null, replace_key: string | null } | null> } | null } };

export type BillingSubscriptionQueryVariables = Exact<{
  workspaceId: Scalars['String'];
}>;


export type BillingSubscriptionQuery = { __typename?: 'Query', billingSubscription: { __typename?: 'BillingSubscription', currentPeriodEnd: number | null, latestHostedInvoiceUrl: string | null, latestHostedInvoicePdf: string | null, id: string, activeDiscount: { __typename?: 'StripeDiscount', coupon: { __typename: 'StripeCoupon', id: string, name: string, created: number, percentOff: number | null, timesRedeemed: number | null } } | null, subscriptionItems: Array<{ __typename?: 'SubscriptionItem', id: string, quantity: number, price: { __typename?: 'StripePrice', id: string, unitAmount: number, taxBehavior: string, product: { __typename?: 'StripeProduct', id: string, name: string, description: string | null, unitLabel: string | null }, recurring: { __typename?: 'StripeRecurring', aggregateUsage: string, interval: RecurringInterval, intervalCount: number, usageType: string } } }> | null, plan: { __typename?: 'Plan', id: string, defaultOption: boolean, status: PlanStatus | null, limits: { __typename?: 'PlanLimits', items: number | null, boardItems: number | null, boards: number | null, enableCollabWorkspaces: boolean | null, enableLinkSharing: boolean | null, frameExtractions: number | null, projects: number | null, enableCustomBranding: boolean | null, vatBilling: boolean | null, videoSupport: boolean | null, gifExtraction: boolean | null, guests: number | null }, presentation: { __typename?: 'PlanPresentation', name: string, description: string, price: string, priceUnit: string, intervals: { __typename?: 'BillingIntervals', monthly: { __typename?: 'BillingInterval', price: string | null, priceUnit: string | null, priceId: string | null } | null, yearly: { __typename?: 'BillingInterval', price: string | null, priceUnit: string | null, priceId: string | null } | null } | null }, features: Array<{ __typename?: 'PlanFeature', template: string, subline: string | null, replace_key: string | null } | null> } | null } | null };

export type CouponQueryVariables = Exact<{
  couponId: Scalars['String'];
}>;


export type CouponQuery = { __typename?: 'Query', coupon: { __typename: 'StripeCoupon', id: string, name: string, created: number, percentOff: number | null, timesRedeemed: number | null } };

export type PromoCodeQueryVariables = Exact<{
  promoCode: Scalars['String'];
}>;


export type PromoCodeQuery = { __typename?: 'Query', promoCode: { __typename: 'StripePromoCode', id: string | null, code: string | null, customer: string | null, restrictions: { __typename?: 'StripePromoCodeRestrictions', first_time_transactions: boolean | null, minimum_amount: number | null, minimum_amount_currency: number | null } } | null };

export type CreateBillingPortalSessionMutationVariables = Exact<{
  workspaceId: Scalars['ID'];
  returnUrl: Scalars['String'];
}>;


export type CreateBillingPortalSessionMutation = { __typename?: 'Mutation', createBillingPortalSession: { __typename?: 'BillingPortalSession', url: string } };

export type CreateCheckoutSessionMutationVariables = Exact<{
  input: CheckoutInput;
}>;


export type CreateCheckoutSessionMutation = { __typename?: 'Mutation', createCheckoutSession: { __typename?: 'CheckoutSession', url: string } };

export type BoardBasicFieldsFragment = { __typename?: 'Board', id: string, title: string, createdAt: string, updatedAt: string | null, iconCode: string | null, userRole: BoardUserRole | null, itemCount: number, priority: number | null };

export type BoardsQueryVariables = Exact<{
  projectId: Scalars['String'];
  first: Scalars['Int'];
}>;


export type BoardsQuery = { __typename?: 'Query', boards: { __typename?: 'BoardsPayload', boards: Array<{ __typename?: 'Board', id: string, title: string, createdAt: string, updatedAt: string | null, iconCode: string | null, userRole: BoardUserRole | null, itemCount: number, priority: number | null }> } };

export type UpdateBoardIconMutationVariables = Exact<{
  boardId: Scalars['String'];
  iconCode?: InputMaybe<Scalars['String']>;
}>;


export type UpdateBoardIconMutation = { __typename?: 'Mutation', updateBoardIcon: { __typename?: 'Board', id: string, title: string, createdAt: string, updatedAt: string | null, iconCode: string | null, userRole: BoardUserRole | null, itemCount: number, priority: number | null } };

export type UpdateBoardTitleMutationVariables = Exact<{
  boardId: Scalars['String'];
  title: Scalars['String'];
}>;


export type UpdateBoardTitleMutation = { __typename?: 'Mutation', updateBoardTitle: { __typename?: 'Board', id: string, title: string, createdAt: string, updatedAt: string | null, iconCode: string | null, userRole: BoardUserRole | null, itemCount: number, priority: number | null } };

export type AddBoardMutationVariables = Exact<{
  projectId: Scalars['String'];
  workspaceId: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
  iconCode?: InputMaybe<Scalars['String']>;
}>;


export type AddBoardMutation = { __typename?: 'Mutation', addBoard: { __typename?: 'Board', id: string, title: string, createdAt: string, updatedAt: string | null, iconCode: string | null, userRole: BoardUserRole | null, itemCount: number, priority: number | null } };

export type RecentBoardsQueryVariables = Exact<{
  workspaceId: Scalars['String'];
}>;


export type RecentBoardsQuery = { __typename?: 'Query', recentBoards: Array<{ __typename?: 'Board', thumbUrls: Array<string>, id: string, title: string, createdAt: string, updatedAt: string | null, iconCode: string | null, userRole: BoardUserRole | null, itemCount: number, priority: number | null, project: { __typename?: 'Project', id: string, title: string } }> };

export type PublicBoardsQueryVariables = Exact<{
  first: Scalars['Int'];
  cursor?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['String']>;
}>;


export type PublicBoardsQuery = { __typename?: 'Query', publicBoards: { __typename?: 'PublicBoardsPayload', pageInfo: { __typename?: 'PageInfo', nextCursor: string | null }, boards: Array<{ __typename?: 'Board', thumbUrls: Array<string>, firstMadePublicAt: string | null, id: string, title: string, createdAt: string, updatedAt: string | null, iconCode: string | null, userRole: BoardUserRole | null, itemCount: number, priority: number | null, project: { __typename?: 'Project', id: string, title: string }, createdBy: { __typename?: 'User', uid: string, handle: string, photoURL: string | null, displayName: string } }> } };

export type SaveDiscoveryItemToBoardMutationVariables = Exact<{
  itemId: Scalars['String'];
  boardId: Scalars['String'];
  workspaceId: Scalars['String'];
  isBoardItem?: InputMaybe<Scalars['Boolean']>;
}>;


export type SaveDiscoveryItemToBoardMutation = { __typename?: 'Mutation', saveDiscoveryItemToBoard: { __typename?: 'BoardItem', id: string } };

export type IncrementBoardItemViewsMutationVariables = Exact<{
  boardItemId: Scalars['String'];
}>;


export type IncrementBoardItemViewsMutation = { __typename?: 'Mutation', incrementBoardItemViews: { __typename?: 'BoardItem', id: string, openCount: number } };

export type UpdateBoardItemsAccessLevelMutationVariables = Exact<{
  boardItemIds: Array<Scalars['String']> | Scalars['String'];
  boardId: Scalars['String'];
  accessLevel: BoardItemAccessLevel;
}>;


export type UpdateBoardItemsAccessLevelMutation = { __typename?: 'Mutation', updateBoardItemsAccessLevel: { __typename?: 'RequestStatus', status: string } };

export type BoardItemTagFieldsFragment = { __typename?: 'BoardItemTag', id: string, tagId: string, description: string, score: number };

export type BoardItemViewQueryVariables = Exact<{
  boardItemId: Scalars['String'];
}>;


export type BoardItemViewQuery = { __typename?: 'Query', boardItem: { __typename?: 'BoardItem', id: string, itemId: string, workspaceItemId: string, userRole: BoardItemUserRole, createdAt: string, updatedAt: string | null, webUrl: string | null, title: string | null, boardId: string, projectId: string, openCount: number, aspectRatio: number, accessLevel: BoardItemAccessLevel, mediaType: ItemMediaType, commentCount: number, uploadSessionId: string, workspaceId: string, original: { __typename?: 'ItemOriginal', path: string, info: { __typename?: 'ItemOriginalInfo', type: string } | null }, itemSourceInfo: { __typename?: 'ItemSourceInfo', type: ItemSourceInfoType, videoExtractInfo: { __typename?: 'ItemVideoExtractInfo', url: string | null, urlType: ItemExtractedFromInfoUrlType | null } | null, extensionImportInfo: { __typename?: 'ItemExtensionImportInfo', pageUrl: string | null } | null }, createdBy: { __typename?: 'User', id: string, email: string, handle: string, displayName: string, photoURL: string | null } | null, tags: Array<{ __typename?: 'BoardItemTag', id: string, tagId: string, description: string, score: number }>, colors: Array<{ __typename?: 'Color', id: string, pixelFraction: number, hex: string, score: number }>, mediaVariants: Array<{ __typename?: 'MediaVariants', url: string, variant: string, type: string }> } };

export type CreateCreditPackCheckoutSessionMutationVariables = Exact<{
  input: CreditPackCheckoutInput;
}>;


export type CreateCreditPackCheckoutSessionMutation = { __typename?: 'Mutation', createCreditPackCheckoutSession: { __typename?: 'CheckoutSession', url: string } };

export type CreditPacksQueryVariables = Exact<{ [key: string]: never; }>;


export type CreditPacksQuery = { __typename?: 'Query', creditPacks: Array<{ __typename?: 'CreditPack', priceId: string, title: string, description: string, imageUrl: string, creditCount: number, unitAmountInCents: number, currency: string }> };

export type DiscoverNewItemsQueryVariables = Exact<{
  first: Scalars['Int'];
  cursor?: InputMaybe<Scalars['String']>;
  withCreatedBy: Scalars['Boolean'];
}>;


export type DiscoverNewItemsQuery = { __typename?: 'Query', discoverNewItems: { __typename?: 'DiscoverNewItemsPayload', pageInfo: { __typename?: 'PageInfo', nextCursor: string | null }, items: Array<{ __typename: 'WorkspaceItem', id: string, createdAt: string, aspectRatio: number, accessLevel: WorkspaceItemAccessLevel, mediaType: ItemMediaType, workspaceId: string, uploadSessionId: string, title: string | null, createdBy?: { __typename?: 'User', id: string, handle: string, displayName: string, photoURL: string | null } | null, original: { __typename?: 'ItemOriginal', path: string, info: { __typename?: 'ItemOriginalInfo', type: string } | null }, mediaVariants: Array<{ __typename?: 'MediaVariants', url: string, variant: string, type: string }> }> } };

export type DiscoverItemsForYouQueryVariables = Exact<{
  first: Scalars['Int'];
  cursor?: InputMaybe<Scalars['String']>;
  withCreatedBy: Scalars['Boolean'];
}>;


export type DiscoverItemsForYouQuery = { __typename?: 'Query', discoverItemsForYou: { __typename?: 'DiscoverItemsForYouPayload', pageInfo: { __typename?: 'PageInfo', nextCursor: string | null }, items: Array<{ __typename: 'WorkspaceItem', id: string, createdAt: string, aspectRatio: number, accessLevel: WorkspaceItemAccessLevel, mediaType: ItemMediaType, workspaceId: string, uploadSessionId: string, title: string | null, createdBy?: { __typename?: 'User', id: string, handle: string, displayName: string, photoURL: string | null } | null, original: { __typename?: 'ItemOriginal', path: string, info: { __typename?: 'ItemOriginalInfo', type: string } | null }, mediaVariants: Array<{ __typename?: 'MediaVariants', url: string, variant: string, type: string }> }> } };

export type DiscoverTopContributorsQueryVariables = Exact<{
  first: Scalars['Int'];
}>;


export type DiscoverTopContributorsQuery = { __typename?: 'Query', discoverTopContributors: Array<{ __typename?: 'User', id: string, uid: string, firstName: string, lastName: string, displayName: string, handle: string, discoverExportCount: number | null, publicItemsCount: number, photoURL: string | null, coverImageUrl: string | null, rank: number | null }> };

export type DiscoverSearchCombosQueryVariables = Exact<{
  count: Scalars['Int'];
}>;


export type DiscoverSearchCombosQuery = { __typename?: 'Query', discoverSearchCombos: Array<{ __typename?: 'DiscoverSearchComboPayload', id: string, description: string, searchCombo: Array<{ __typename?: 'SearchComboColor', color: string, description: string } | { __typename?: 'SearchComboFace', face: string, description: string } | { __typename?: 'SearchComboMediaType', mediaType: ItemMediaType, description: string } | { __typename?: 'SearchComboTag', tagId: string, description: string }> }> };

export type DiscoverIdeasForRecentBoardsQueryVariables = Exact<{
  workspaceId: Scalars['String'];
  first: Scalars['Int'];
  cursor?: InputMaybe<Scalars['String']>;
}>;


export type DiscoverIdeasForRecentBoardsQuery = { __typename?: 'Query', discoverIdeasForRecentBoards: Array<{ __typename?: 'DiscoverIdeasForBoardPayload', boardId: string, projectId: string, title: string, items: Array<{ __typename: 'WorkspaceItem', id: string, createdAt: string, aspectRatio: number, accessLevel: WorkspaceItemAccessLevel, mediaType: ItemMediaType, workspaceId: string, uploadSessionId: string, title: string | null, original: { __typename?: 'ItemOriginal', path: string, info: { __typename?: 'ItemOriginalInfo', type: string } | null }, mediaVariants: Array<{ __typename?: 'MediaVariants', url: string, variant: string, type: string }> }>, pageInfo: { __typename?: 'PageInfo', nextCursor: string | null } }> };

export type DiscoverIdeasForBoardQueryVariables = Exact<{
  boardId: Scalars['String'];
  first: Scalars['Int'];
  cursor?: InputMaybe<Scalars['String']>;
}>;


export type DiscoverIdeasForBoardQuery = { __typename?: 'Query', discoverIdeasForBoard: { __typename?: 'DiscoverIdeasForBoardPayload', boardId: string, projectId: string, title: string, items: Array<{ __typename: 'WorkspaceItem', id: string, createdAt: string, aspectRatio: number, accessLevel: WorkspaceItemAccessLevel, mediaType: ItemMediaType, workspaceId: string, uploadSessionId: string, title: string | null, original: { __typename?: 'ItemOriginal', path: string, info: { __typename?: 'ItemOriginalInfo', type: string } | null }, mediaVariants: Array<{ __typename?: 'MediaVariants', url: string, variant: string, type: string }> }>, pageInfo: { __typename?: 'PageInfo', nextCursor: string | null } } };

export type CreateEditSessionMutationVariables = Exact<{
  input: CreateEditSessionInput;
}>;


export type CreateEditSessionMutation = { __typename?: 'Mutation', createEditSession: { __typename?: 'EditSession', id: string, status: EditSessionStatus, createdAt: number, updatedAt: number, members: Array<{ __typename?: 'EditSessionMember', userId: string, role: EditSessionMemberRole }>, context: { __typename?: 'EditSessionContext', workspaceId: string | null } | null, startValues: { __typename?: 'EditSessionStartValues', baseImageUrl: string } | null } };

export type AddEditSessionPowerUpMutationVariables = Exact<{
  input: AddEditSessionPowerUpInput;
}>;


export type AddEditSessionPowerUpMutation = { __typename?: 'Mutation', addEditSessionPowerUp: { __typename?: 'AddPowerUpResponse', result: AddPowerUpResult, message: string | null } };

export type RemoveEditSessionPowerUpMutationVariables = Exact<{
  input: RemoveEditSessionPowerUpInput;
}>;


export type RemoveEditSessionPowerUpMutation = { __typename?: 'Mutation', removeEditSessionPowerUp: { __typename?: 'RemovePowerUpResponse', result: RemovePowerUpResult, message: string | null } };

export type EditSessionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type EditSessionQuery = { __typename?: 'Query', editSession: { __typename?: 'EditSession', id: string, status: EditSessionStatus, createdAt: number, updatedAt: number, members: Array<{ __typename?: 'EditSessionMember', userId: string, role: EditSessionMemberRole }>, context: { __typename?: 'EditSessionContext', workspaceId: string | null, backUrl: string | null } | null, startValues: { __typename?: 'EditSessionStartValues', baseImageUrl: string } | null, powerUp: { __typename?: 'EditSessionPowerUp', isPowered: boolean, poweredBy: Array<string | null>, updatedAt: number, createdAt: number, enrichedPowerUpMembers: Array<{ __typename?: 'EnrichedPowerUpMember', uid: string, name: string, imageUrl: string }> | null } | null } | null };

export type UserCounterQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UserCounterQuery = { __typename?: 'Query', userEditSessionCounter: number };

export type WorkspaceCounterQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type WorkspaceCounterQuery = { __typename?: 'Query', workspaceEditSessionCounter: number };

export type EditSessionFragmentFragment = { __typename?: 'EditSession', id: string, status: EditSessionStatus, createdAt: number, updatedAt: number, thumbnailUrl: string | null, members: Array<{ __typename?: 'EditSessionMember', userId: string, role: EditSessionMemberRole }>, context: { __typename?: 'EditSessionContext', workspaceId: string | null, backUrl: string | null } | null, startValues: { __typename?: 'EditSessionStartValues', baseImageUrl: string } | null, powerUp: { __typename?: 'EditSessionPowerUp', isPowered: boolean, poweredBy: Array<string | null>, updatedAt: number, createdAt: number } | null };

export type EditSessionsQueryVariables = Exact<{
  input: EditSessionsInput;
}>;


export type EditSessionsQuery = { __typename?: 'Query', previousEditSessions: { __typename?: 'EditSessionsPayload', pageInfo: { __typename?: 'PageInfo', nextCursor: string | null }, editSessions: Array<{ __typename?: 'EditSession', id: string, status: EditSessionStatus, createdAt: number, updatedAt: number, thumbnailUrl: string | null, members: Array<{ __typename?: 'EditSessionMember', userId: string, role: EditSessionMemberRole }>, context: { __typename?: 'EditSessionContext', workspaceId: string | null, backUrl: string | null } | null, startValues: { __typename?: 'EditSessionStartValues', baseImageUrl: string } | null, powerUp: { __typename?: 'EditSessionPowerUp', isPowered: boolean, poweredBy: Array<string | null>, updatedAt: number, createdAt: number } | null }> }, liveEditSessions: { __typename?: 'EditSessionsPayload', pageInfo: { __typename?: 'PageInfo', nextCursor: string | null }, editSessions: Array<{ __typename?: 'EditSession', id: string, status: EditSessionStatus, createdAt: number, updatedAt: number, thumbnailUrl: string | null, members: Array<{ __typename?: 'EditSessionMember', userId: string, role: EditSessionMemberRole }>, context: { __typename?: 'EditSessionContext', workspaceId: string | null, backUrl: string | null } | null, startValues: { __typename?: 'EditSessionStartValues', baseImageUrl: string } | null, powerUp: { __typename?: 'EditSessionPowerUp', isPowered: boolean, poweredBy: Array<string | null>, updatedAt: number, createdAt: number } | null }> } };

export type EditSessionInviteQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type EditSessionInviteQuery = { __typename?: 'Query', editSessionInvite: { __typename?: 'EditSessionInvite', id: string, editSessionId: string, status: EditSessionInviteStatus, message: string | null, createdAt: number, updatedAt: number, sender: { __typename?: 'EditSessionInviteMember', email: string, name: string, userId: string | null }, receiver: { __typename?: 'EditSessionInviteMember', email: string, name: string, userId: string | null } | null } | null };

export type SendEditSessionInviteMutationVariables = Exact<{
  input: SendEditSessionInviteInput;
}>;


export type SendEditSessionInviteMutation = { __typename?: 'Mutation', sendEditSessionInvite: { __typename?: 'EditSessionInvite', id: string, editSessionId: string, status: EditSessionInviteStatus, message: string | null, createdAt: number, updatedAt: number, sender: { __typename?: 'EditSessionInviteMember', email: string, name: string, userId: string | null }, receiver: { __typename?: 'EditSessionInviteMember', email: string, name: string, userId: string | null } | null } };

export type RespondToEditSessionInviteMutationVariables = Exact<{
  input: RespondToEditSessionInviteInput;
}>;


export type RespondToEditSessionInviteMutation = { __typename?: 'Mutation', respondToEditSessionInvite: { __typename?: 'EditSessionInvite', id: string, editSessionId: string, status: EditSessionInviteStatus, message: string | null, createdAt: number, updatedAt: number, sender: { __typename?: 'EditSessionInviteMember', email: string, name: string, userId: string | null }, receiver: { __typename?: 'EditSessionInviteMember', email: string, name: string, userId: string | null } | null } };

export type AddGoogleAnalyticsIdFromWaitingListIdMutationVariables = Exact<{
  waitingListId: Scalars['String'];
}>;


export type AddGoogleAnalyticsIdFromWaitingListIdMutation = { __typename?: 'Mutation', addGoogleAnalyticsIdFromWaitingListId: { __typename?: 'GoogleAnalyticsId', id: string | null } };

export type AddUserIdToGoogleAnalyticsIdMutationVariables = Exact<{
  userId: Scalars['String'];
  waitingListId?: InputMaybe<Scalars['String']>;
}>;


export type AddUserIdToGoogleAnalyticsIdMutation = { __typename?: 'Mutation', addUserIdToGoogleAnalyticsId: { __typename?: 'GoogleAnalyticsId', id: string | null } };

export type SendKiveInvitationMutationVariables = Exact<{
  email: Scalars['String'];
  vimeoId?: InputMaybe<Scalars['String']>;
  nominationPanelTrackingId?: InputMaybe<Scalars['String']>;
}>;


export type SendKiveInvitationMutation = { __typename?: 'Mutation', sendKiveInvitation: { __typename?: 'RequestStatus', status: string } };

export type Admin_HideFromDiscoveryMutationVariables = Exact<{
  id: Scalars['String'];
  workspaceId: Scalars['String'];
}>;


export type Admin_HideFromDiscoveryMutation = { __typename?: 'Mutation', admin_hideFromDiscovery: { __typename?: 'RequestStatus', status: string } };

export type ItemCommentBasicFieldsFragment = { __typename: 'ItemComment', id: string, createdAt: string, text: string, author: { __typename: 'Guest', id: string, displayName: string } | { __typename: 'User', id: string, displayName: string, photoURL: string | null } | null };

export type ItemCommentsQueryVariables = Exact<{
  parentId: Scalars['String'];
  boardId?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
  cursor?: InputMaybe<Scalars['String']>;
}>;


export type ItemCommentsQuery = { __typename?: 'Query', itemComments: { __typename?: 'ItemCommentsPayload', pageInfo: { __typename?: 'PageInfo', nextCursor: string | null }, itemComments: Array<{ __typename: 'ItemComment', id: string, createdAt: string, text: string, author: { __typename: 'Guest', id: string, displayName: string } | { __typename: 'User', id: string, displayName: string, photoURL: string | null } | null }> } };

export type AddItemCommentMutationVariables = Exact<{
  text: Scalars['String'];
  parentId: Scalars['String'];
  boardId?: InputMaybe<Scalars['String']>;
}>;


export type AddItemCommentMutation = { __typename?: 'Mutation', addItemComment: { __typename: 'ItemComment', id: string, createdAt: string, text: string, author: { __typename: 'Guest', id: string, displayName: string } | { __typename: 'User', id: string, displayName: string, photoURL: string | null } | null } };

export type RemoveItemCommentMutationVariables = Exact<{
  itemCommentId: Scalars['String'];
  boardId?: InputMaybe<Scalars['String']>;
}>;


export type RemoveItemCommentMutation = { __typename?: 'Mutation', removeItemComment: { __typename?: 'RequestStatus', status: string } };

export type ValidateKiveAccessQueryVariables = Exact<{
  email: Scalars['String'];
  referralId?: InputMaybe<Scalars['String']>;
}>;


export type ValidateKiveAccessQuery = { __typename?: 'Query', validateKiveAccess: { __typename?: 'KiveAccessPayload', hasAccess: boolean, status: KiveAccessStatus, context: { __typename?: 'KiveAccessContext', workspaceId: string | null, invitationId: string | null, waitingListId: string | null, hasCompletedWaitingListForm: boolean | null, userName: string | null } | null } };

export type MagicBoardBasicFieldsFragment = { __typename?: 'MagicBoard', id: string, tagId: string, description: string, count: number, score: number };

export type MagicBoardsQueryVariables = Exact<{
  workspaceId: Scalars['String'];
  first: Scalars['Int'];
  cursor?: InputMaybe<Scalars['String']>;
  freeTextSearch?: InputMaybe<Scalars['String']>;
}>;


export type MagicBoardsQuery = { __typename?: 'Query', magicBoards: { __typename?: 'MagicBoardsPayload', pageInfo: { __typename?: 'PageInfo', nextCursor: string | null }, magicBoards: Array<{ __typename?: 'MagicBoard', thumbUrl: string | null, id: string, tagId: string, description: string, count: number, score: number }> } };

export type MagicBoardQueryVariables = Exact<{
  magicBoardId: Scalars['String'];
  workspaceId: Scalars['String'];
}>;


export type MagicBoardQuery = { __typename?: 'Query', magicBoard: { __typename?: 'MagicBoard', coverUrl: string | null, id: string, tagId: string, description: string, count: number, score: number } };

export type MagicBoardSimpleQueryVariables = Exact<{
  magicBoardId: Scalars['String'];
  workspaceId: Scalars['String'];
}>;


export type MagicBoardSimpleQuery = { __typename?: 'Query', magicBoard: { __typename?: 'MagicBoard', thumbUrl: string | null, id: string, tagId: string, description: string, count: number, score: number } };

export type MagicBoardItemsQueryVariables = Exact<{
  workspaceId: Scalars['String'];
  magicBoardId: Scalars['String'];
  first: Scalars['Int'];
  cursor?: InputMaybe<Scalars['String']>;
}>;


export type MagicBoardItemsQuery = { __typename?: 'Query', magicBoardItems: { __typename?: 'MagicBoardItemsPayload', pageInfo: { __typename?: 'PageInfo', nextCursor: string | null }, magicBoardItems: Array<{ __typename: 'WorkspaceItem', id: string, createdAt: string, aspectRatio: number, accessLevel: WorkspaceItemAccessLevel, mediaType: ItemMediaType, workspaceId: string, uploadSessionId: string, title: string | null, original: { __typename?: 'ItemOriginal', path: string, info: { __typename?: 'ItemOriginalInfo', type: string } | null }, mediaVariants: Array<{ __typename?: 'MediaVariants', url: string, variant: string, type: string }> }> } };

export type NotificationBasicFieldsFragment = { __typename: 'Notification', id: string, type: string, isRead: boolean | null, fromSystem: boolean, createdAt: string, title: string, linkPath: string | null, author: { __typename?: 'User', photoURL: string | null, displayName: string } | null };

export type MarkNotificationAsReadMutationVariables = Exact<{
  notificationId: Scalars['String'];
}>;


export type MarkNotificationAsReadMutation = { __typename?: 'Mutation', markNotificationAsRead: { __typename: 'Notification', id: string, type: string, isRead: boolean | null, fromSystem: boolean, createdAt: string, title: string, linkPath: string | null, author: { __typename?: 'User', photoURL: string | null, displayName: string } | null } };

export type MarkAllNotificationsAsReadMutationVariables = Exact<{
  workspaceId: Scalars['String'];
}>;


export type MarkAllNotificationsAsReadMutation = { __typename?: 'Mutation', markAllNotificationsAsRead: { __typename?: 'RequestStatus', status: string } };

export type WorkspaceNotificationsSubscriptionVariables = Exact<{
  workspaceId: Scalars['String'];
}>;


export type WorkspaceNotificationsSubscription = { __typename?: 'Subscription', workspaceNotifications: Array<{ __typename: 'Notification', id: string, type: string, isRead: boolean | null, fromSystem: boolean, createdAt: string, title: string, linkPath: string | null, author: { __typename?: 'User', photoURL: string | null, displayName: string } | null }> };

export type CreateCancellationSurveyMutationVariables = Exact<{
  reasons: Array<Scalars['String']> | Scalars['String'];
  message?: InputMaybe<Scalars['String']>;
  canBeContacted?: InputMaybe<Scalars['Boolean']>;
}>;


export type CreateCancellationSurveyMutation = { __typename?: 'Mutation', createCancellationSurvey: { __typename?: 'RequestStatus', status: string } };

export type ActivePlansQueryVariables = Exact<{ [key: string]: never; }>;


export type ActivePlansQuery = { __typename?: 'Query', plans: Array<{ __typename?: 'Plan', id: string, defaultOption: boolean, status: PlanStatus | null, order: number | null, limits: { __typename?: 'PlanLimits', items: number | null, boardItems: number | null, boards: number | null, enableCollabWorkspaces: boolean | null, enableLinkSharing: boolean | null, frameExtractions: number | null, projects: number | null, enableCustomBranding: boolean | null, vatBilling: boolean | null, videoSupport: boolean | null, gifExtraction: boolean | null, guests: number | null }, presentation: { __typename?: 'PlanPresentation', name: string, description: string, price: string, priceUnit: string, intervals: { __typename?: 'BillingIntervals', monthly: { __typename?: 'BillingInterval', price: string | null, priceUnit: string | null, priceId: string | null, priceActual: { __typename?: 'StripePrice', id: string, unitAmount: number, currency: string, priceValue: number, adjustedMonthlyValue: number, priceString: string, product: { __typename?: 'StripeProduct', name: string, description: string | null }, recurring: { __typename?: 'StripeRecurring', aggregateUsage: string, interval: RecurringInterval, intervalCount: number, usageType: string } } | null } | null, yearly: { __typename?: 'BillingInterval', price: string | null, priceUnit: string | null, priceId: string | null, priceActual: { __typename?: 'StripePrice', id: string, unitAmount: number, currency: string, priceValue: number, adjustedMonthlyValue: number, priceString: string, product: { __typename?: 'StripeProduct', name: string, description: string | null } } | null } | null } | null }, features: Array<{ __typename?: 'PlanFeature', template: string, subline: string | null, replace_key: string | null } | null> }> };

export type ProjectBasicFieldsFragment = { __typename?: 'Project', id: string, title: string, createdAt: string, updatedAt: string | null, createdById: string, linkAccess: ProjectLinkAccess | null, userRole: ProjectUserRole | null, workspaceId: string, workspaceAccess: ProjectWorkspaceAccess | null, ownerEmails: Array<string> };

export type UserProjectsQueryVariables = Exact<{
  workspaceId: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  accessLevel?: InputMaybe<ProjectUserRole>;
}>;


export type UserProjectsQuery = { __typename?: 'Query', projects: { __typename?: 'ProjectsPayload', projects: Array<{ __typename?: 'Project', thumbUrls: Array<string>, id: string, title: string, createdAt: string, updatedAt: string | null, createdById: string, linkAccess: ProjectLinkAccess | null, userRole: ProjectUserRole | null, workspaceId: string, workspaceAccess: ProjectWorkspaceAccess | null, ownerEmails: Array<string>, createdBy: { __typename?: 'User', uid: string, displayName: string, photoURL: string | null } | null }> } };

export type ProjectQueryVariables = Exact<{
  projectId: Scalars['String'];
}>;


export type ProjectQuery = { __typename?: 'Query', project: { __typename?: 'Project', id: string, title: string, createdAt: string, updatedAt: string | null, createdById: string, linkAccess: ProjectLinkAccess | null, userRole: ProjectUserRole | null, workspaceId: string, workspaceAccess: ProjectWorkspaceAccess | null, ownerEmails: Array<string> } };

export type DeleteProjectMutationVariables = Exact<{
  projectId: Scalars['String'];
}>;


export type DeleteProjectMutation = { __typename?: 'Mutation', deleteProject: { __typename?: 'RequestStatus', status: string } };

export type UpdateProjectTitleMutationVariables = Exact<{
  projectId: Scalars['String'];
  title: Scalars['String'];
}>;


export type UpdateProjectTitleMutation = { __typename?: 'Mutation', updateProjectTitle: { __typename?: 'Project', id: string, title: string, createdAt: string, updatedAt: string | null, createdById: string, linkAccess: ProjectLinkAccess | null, userRole: ProjectUserRole | null, workspaceId: string, workspaceAccess: ProjectWorkspaceAccess | null, ownerEmails: Array<string> } };

export type UpdateProjectBoardsPriorityMutationVariables = Exact<{
  projectId: Scalars['String'];
  payload: Array<BoardPriorityInput> | BoardPriorityInput;
}>;


export type UpdateProjectBoardsPriorityMutation = { __typename?: 'Mutation', updateProjectBoardsPriority: { __typename?: 'RequestStatus', status: string } };

export type UpdateProjectLinkAccessMutationVariables = Exact<{
  projectId: Scalars['String'];
  projectLinkAccess?: InputMaybe<ProjectLinkAccess>;
}>;


export type UpdateProjectLinkAccessMutation = { __typename?: 'Mutation', updateProjectLinkAccess: { __typename?: 'Project', id: string, title: string, createdAt: string, updatedAt: string | null, createdById: string, linkAccess: ProjectLinkAccess | null, userRole: ProjectUserRole | null, workspaceId: string, workspaceAccess: ProjectWorkspaceAccess | null, ownerEmails: Array<string> } };

export type UpdateProjectWorkspaceAccessMutationVariables = Exact<{
  projectId: Scalars['String'];
  projectWorkspaceAccess: ProjectWorkspaceAccess;
}>;


export type UpdateProjectWorkspaceAccessMutation = { __typename?: 'Mutation', updateProjectWorkspaceAccess: { __typename?: 'Project', id: string, title: string, createdAt: string, updatedAt: string | null, createdById: string, linkAccess: ProjectLinkAccess | null, userRole: ProjectUserRole | null, workspaceId: string, workspaceAccess: ProjectWorkspaceAccess | null, ownerEmails: Array<string> } };

export type ItemReactionBasicFieldsFragment = { __typename: 'ItemReaction', id: string, reaction: string, createdAt: string, author: { __typename: 'Guest', id: string, displayName: string } | { __typename: 'User', id: string, displayName: string } | null };

export type ItemReactionGroupFieldsFragment = { __typename: 'ItemReactionGroup', id: string, count: number | null, reaction: string, hasReacted: boolean, itemReactions: Array<{ __typename: 'ItemReaction', id: string, reaction: string, createdAt: string, author: { __typename: 'Guest', id: string, displayName: string } | { __typename: 'User', id: string, displayName: string } | null }> };

export type ItemReactionsQueryVariables = Exact<{
  parentId: Scalars['String'];
  boardId?: InputMaybe<Scalars['String']>;
}>;


export type ItemReactionsQuery = { __typename?: 'Query', itemReactions: Array<{ __typename: 'ItemReactionGroup', id: string, count: number | null, reaction: string, hasReacted: boolean, itemReactions: Array<{ __typename: 'ItemReaction', id: string, reaction: string, createdAt: string, author: { __typename: 'Guest', id: string, displayName: string } | { __typename: 'User', id: string, displayName: string } | null }> }> };

export type AddItemReactionMutationVariables = Exact<{
  reaction: Scalars['String'];
  parentId: Scalars['String'];
  boardId?: InputMaybe<Scalars['String']>;
}>;


export type AddItemReactionMutation = { __typename?: 'Mutation', addItemReaction: { __typename: 'ItemReaction', id: string, reaction: string, createdAt: string, author: { __typename: 'Guest', id: string, displayName: string } | { __typename: 'User', id: string, displayName: string } | null } };

export type RemoveItemReactionMutationVariables = Exact<{
  itemReactionId: Scalars['String'];
  boardId?: InputMaybe<Scalars['String']>;
}>;


export type RemoveItemReactionMutation = { __typename?: 'Mutation', removeItemReaction: { __typename?: 'RequestStatus', status: string } };

export type PublicTagsQueryVariables = Exact<{
  tagIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type PublicTagsQuery = { __typename?: 'Query', publicTags: Array<{ __typename?: 'Tag', id: string, tagId: string, description: string, thumbUrl: string | null }> };

export type TagsForYouQueryVariables = Exact<{ [key: string]: never; }>;


export type TagsForYouQuery = { __typename?: 'Query', tagsForYou: Array<{ __typename?: 'Tag', id: string, description: string, thumbUrl: string | null }> };

export type UploadSessionBasicFieldsFragment = { __typename: 'UploadSession', id: string, thumbUrl: string, createdAt: string, createdBy: string, size: number, tagCount: number, title: string };

export type UploadSessionItemsQueryVariables = Exact<{
  workspaceId: Scalars['String'];
  uploadSessionId: Scalars['String'];
  first: Scalars['Int'];
  cursor?: InputMaybe<Scalars['String']>;
}>;


export type UploadSessionItemsQuery = { __typename?: 'Query', uploadSessionItems: { __typename?: 'UploadSessionItemsPayload', pageInfo: { __typename?: 'PageInfo', nextCursor: string | null }, uploadSessionItems: Array<{ __typename: 'WorkspaceItem', id: string, createdAt: string, aspectRatio: number, accessLevel: WorkspaceItemAccessLevel, mediaType: ItemMediaType, workspaceId: string, uploadSessionId: string, title: string | null, original: { __typename?: 'ItemOriginal', path: string, info: { __typename?: 'ItemOriginalInfo', type: string } | null }, mediaVariants: Array<{ __typename?: 'MediaVariants', url: string, variant: string, type: string }> }> } };

export type UploadSessionsFinishedQueryVariables = Exact<{
  workspaceId: Scalars['String'];
  first: Scalars['Int'];
  cursor?: InputMaybe<Scalars['String']>;
}>;


export type UploadSessionsFinishedQuery = { __typename?: 'Query', uploadSessionsFinished: { __typename?: 'UploadSessionsFinishedPayload', pageInfo: { __typename?: 'PageInfo', nextCursor: string | null }, uploadSessions: Array<{ __typename: 'UploadSession', id: string, thumbUrl: string, createdAt: string, createdBy: string, size: number, tagCount: number, title: string }> } };

export type UserBasicFieldsFragment = { __typename: 'User', id: string, uid: string, email: string, firstName: string, lastName: string, displayName: string, bio: string | null, photoURL: string | null, handle: string, createdAt: string, coverImageUrl: string | null, frozenAccount: boolean | null };

export type UserQueryVariables = Exact<{
  uid: Scalars['String'];
}>;


export type UserQuery = { __typename?: 'Query', user: { __typename: 'User', id: string, uid: string, email: string, firstName: string, lastName: string, displayName: string, bio: string | null, photoURL: string | null, handle: string, createdAt: string, coverImageUrl: string | null, frozenAccount: boolean | null } };

export type CreditsUserQueryVariables = Exact<{
  uid: Scalars['String'];
}>;


export type CreditsUserQuery = { __typename?: 'Query', user: { __typename?: 'User', id: string, generationRequests: { __typename?: 'GenerationRequests', availableCredits: number, numGenerations: number } | null } };

export type UserTasksQueryVariables = Exact<{ [key: string]: never; }>;


export type UserTasksQuery = { __typename?: 'Query', userTasks: any };

export type UpdateUserTasksMutationVariables = Exact<{
  userTasksUpdate: Scalars['JSONObject'];
}>;


export type UpdateUserTasksMutation = { __typename?: 'Mutation', updateUserTasks: any };

export type UserLearningGuidesQueryVariables = Exact<{ [key: string]: never; }>;


export type UserLearningGuidesQuery = { __typename?: 'Query', userLearningGuides: any };

export type UpdateUserLearningGuidesMutationVariables = Exact<{
  userLearningGuidesUpdate: Scalars['JSONObject'];
}>;


export type UpdateUserLearningGuidesMutation = { __typename?: 'Mutation', updateUserLearningGuides: any };

export type UserRecentlyAddedTagsQueryVariables = Exact<{
  uid: Scalars['String'];
}>;


export type UserRecentlyAddedTagsQuery = { __typename?: 'Query', userPrivate: { __typename?: 'UserPrivate', tags: { __typename?: 'UserTags', recentlyAddedTags: Array<{ __typename?: 'UserRecentTag', id: string, description: string }> | null } | null } };

export type UserProfileQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']>;
  userHandle?: InputMaybe<Scalars['String']>;
}>;


export type UserProfileQuery = { __typename?: 'Query', userProfile: { __typename: 'User', publicItemsCount: number, discoverExportCount: number | null, rank: number | null, id: string, uid: string, email: string, firstName: string, lastName: string, displayName: string, bio: string | null, photoURL: string | null, handle: string, createdAt: string, coverImageUrl: string | null, frozenAccount: boolean | null, nominatedBy: { __typename?: 'User', uid: string, displayName: string, photoURL: string | null, handle: string } | null } };

export type PublicWorkspaceItemsByUserQueryVariables = Exact<{
  userId: Scalars['String'];
  first: Scalars['Int'];
  cursor?: InputMaybe<Scalars['String']>;
}>;


export type PublicWorkspaceItemsByUserQuery = { __typename?: 'Query', publicWorkspaceItemsByUser: { __typename?: 'WorkspaceItemsByUserPayload', pageInfo: { __typename?: 'PageInfo', nextCursor: string | null }, workspaceItems: Array<{ __typename: 'WorkspaceItem', id: string, createdAt: string, aspectRatio: number, accessLevel: WorkspaceItemAccessLevel, mediaType: ItemMediaType, workspaceId: string, uploadSessionId: string, title: string | null, original: { __typename?: 'ItemOriginal', path: string, info: { __typename?: 'ItemOriginalInfo', type: string } | null }, mediaVariants: Array<{ __typename?: 'MediaVariants', url: string, variant: string, type: string }> }> } };

export type UserTopTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserTopTagsQuery = { __typename?: 'Query', userTopTags: Array<{ __typename?: 'UserTopTag', id: string, count: number, description: string }> };

export type UploadCoverPhotoMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type UploadCoverPhotoMutation = { __typename?: 'Mutation', uploadCoverPhoto: { __typename: 'User', id: string, uid: string, email: string, firstName: string, lastName: string, displayName: string, bio: string | null, photoURL: string | null, handle: string, createdAt: string, coverImageUrl: string | null, frozenAccount: boolean | null } };

export type UploadProfilePictureMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type UploadProfilePictureMutation = { __typename?: 'Mutation', uploadProfilePicture: { __typename: 'User', id: string, uid: string, email: string, firstName: string, lastName: string, displayName: string, bio: string | null, photoURL: string | null, handle: string, createdAt: string, coverImageUrl: string | null, frozenAccount: boolean | null } };

export type UpdateRecentTagsMutationVariables = Exact<{
  tags: Array<UserRecentTagInput> | UserRecentTagInput;
}>;


export type UpdateRecentTagsMutation = { __typename?: 'Mutation', updateRecentTags: { __typename?: 'UserRecentTagPayload', recentTags: Array<{ __typename?: 'UserRecentTag', id: string }> } };

export type CreateUserLoginOtpMutationVariables = Exact<{
  word?: InputMaybe<Scalars['String']>;
}>;


export type CreateUserLoginOtpMutation = { __typename?: 'Mutation', createUserLoginOTP: { __typename?: 'UserLoginOTPPayload', code: number, word: string } };

export type UserLoginOtpExchangeMutationVariables = Exact<{
  code: Scalars['Int'];
  word: Scalars['String'];
}>;


export type UserLoginOtpExchangeMutation = { __typename?: 'Mutation', userLoginOTPExchange: { __typename?: 'UserLoginOTPExchangePayload', token: string } };

export type OutpaintMutationVariables = Exact<{
  prompt: Scalars['String'];
  imageData: Scalars['String'];
  maskData: Scalars['String'];
  editSessionId?: InputMaybe<Scalars['ID']>;
}>;


export type OutpaintMutation = { __typename?: 'Mutation', outpaint: Array<string> };

export type AddUserMutationVariables = Exact<{
  photoURL?: InputMaybe<Scalars['String']>;
  fromPublicFeatureData?: InputMaybe<FromPublicFeatureData>;
}>;


export type AddUserMutation = { __typename?: 'Mutation', addUser: { __typename?: 'User', uid: string } };

export type SetupUserMutationVariables = Exact<{
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  jobTitle: Scalars['String'];
  timesReadTermsOfService: Scalars['Int'];
  timesReadPrivacyPolicy: Scalars['Int'];
  timesUploadedProfileImage?: InputMaybe<Scalars['Int']>;
}>;


export type SetupUserMutation = { __typename?: 'Mutation', setupUser: { __typename: 'User', id: string, uid: string, email: string, firstName: string, lastName: string, displayName: string, bio: string | null, photoURL: string | null, handle: string, createdAt: string, coverImageUrl: string | null, frozenAccount: boolean | null } };

export type SuggestedFrameExtractionsQueryVariables = Exact<{
  workspaceId: Scalars['String'];
}>;


export type SuggestedFrameExtractionsQuery = { __typename?: 'Query', suggestedFrameExtractions: Array<{ __typename?: 'VimeoVideo', link: string, name: string | null, height: number, width: number, duration: number, thumbnail: string, creator: { __typename?: 'VimeoCreator', name: string, link: string } }> };

export type AddWaitingListEntryMutationVariables = Exact<{
  email: Scalars['String'];
  referralId?: InputMaybe<Scalars['String']>;
  signupContext?: InputMaybe<SignupContext>;
}>;


export type AddWaitingListEntryMutation = { __typename?: 'Mutation', addWaitingListEntry: { __typename?: 'WaitingListEntry', id: string, name: string | null, email: string, approved: boolean, analyzeIsComplete: boolean, completedFormAt: string | null, createdAt: string, referralId: string | null } };

export type WorkspaceBasicFieldsFragment = { __typename: 'Workspace', id: string, title: string, url: string, adminEmails: Array<string>, guestEmails: Array<string>, memberEmails: Array<string>, pendingInvitations: Array<string>, allowedEmailDomains: Array<string>, createdByUserId: string, updatedByUserId: string | null, updatedAt: string | null, createdAt: string, publicAccessStatus: WorkspacePublicAccessStatus };

export type WorkspaceThumbFieldsFragment = { __typename: 'Workspace', id: string, title: string, url: string, iconUrl: string | null, thumbUrls: Array<string> };

export type JoinableWorkspacesPendingInviteQueryVariables = Exact<{ [key: string]: never; }>;


export type JoinableWorkspacesPendingInviteQuery = { __typename?: 'Query', joinableWorkspacesPendingInvite: Array<{ __typename: 'Workspace', id: string, title: string, url: string, iconUrl: string | null, thumbUrls: Array<string> }> };

export type JoinableWorkspacesAllowedDomainQueryVariables = Exact<{ [key: string]: never; }>;


export type JoinableWorkspacesAllowedDomainQuery = { __typename?: 'Query', joinableWorkspacesAllowedDomain: Array<{ __typename: 'Workspace', id: string, title: string, url: string, iconUrl: string | null, thumbUrls: Array<string> }> };

export type WorkspacesPublicQueryVariables = Exact<{ [key: string]: never; }>;


export type WorkspacesPublicQuery = { __typename?: 'Query', workspacesPublic: Array<{ __typename: 'Workspace', id: string, title: string, url: string, iconUrl: string | null, thumbUrls: Array<string> }> };

export type WorkspaceQueryVariables = Exact<{
  workspaceId: Scalars['String'];
}>;


export type WorkspaceQuery = { __typename?: 'Query', workspace: { __typename: 'Workspace', id: string, title: string, url: string, adminEmails: Array<string>, guestEmails: Array<string>, memberEmails: Array<string>, pendingInvitations: Array<string>, allowedEmailDomains: Array<string>, createdByUserId: string, updatedByUserId: string | null, updatedAt: string | null, createdAt: string, publicAccessStatus: WorkspacePublicAccessStatus } };

export type LastActiveWorkspaceQueryVariables = Exact<{ [key: string]: never; }>;


export type LastActiveWorkspaceQuery = { __typename?: 'Query', lastActiveWorkspace: { __typename: 'Workspace', id: string, title: string, url: string, adminEmails: Array<string>, guestEmails: Array<string>, memberEmails: Array<string>, pendingInvitations: Array<string>, allowedEmailDomains: Array<string>, createdByUserId: string, updatedByUserId: string | null, updatedAt: string | null, createdAt: string, publicAccessStatus: WorkspacePublicAccessStatus } | null };

export type WorkspaceItemCountQueryVariables = Exact<{
  workspaceId: Scalars['String'];
}>;


export type WorkspaceItemCountQuery = { __typename?: 'Query', workspace: { __typename?: 'Workspace', totalItemsCount: number } };

export type JoinWorkspaceMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type JoinWorkspaceMutation = { __typename?: 'Mutation', joinWorkspace: { __typename: 'Workspace', totalItemsCount: number, id: string, title: string, url: string, adminEmails: Array<string>, guestEmails: Array<string>, memberEmails: Array<string>, pendingInvitations: Array<string>, allowedEmailDomains: Array<string>, createdByUserId: string, updatedByUserId: string | null, updatedAt: string | null, createdAt: string, publicAccessStatus: WorkspacePublicAccessStatus } };

export type LeaveWorkspaceMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type LeaveWorkspaceMutation = { __typename?: 'Mutation', leaveWorkspace: { __typename?: 'RequestStatus', status: string } };

export type SendWorkspaceInvitationMutationVariables = Exact<{
  email: Scalars['String'];
  workspaceId: Scalars['String'];
  inviteSource: Scalars['String'];
}>;


export type SendWorkspaceInvitationMutation = { __typename?: 'Mutation', sendWorkspaceInvitation: { __typename: 'Workspace', id: string, title: string, url: string, adminEmails: Array<string>, guestEmails: Array<string>, memberEmails: Array<string>, pendingInvitations: Array<string>, allowedEmailDomains: Array<string>, createdByUserId: string, updatedByUserId: string | null, updatedAt: string | null, createdAt: string, publicAccessStatus: WorkspacePublicAccessStatus } };

export type AddWorkspaceMutationVariables = Exact<{
  title: Scalars['String'];
  url: Scalars['String'];
}>;


export type AddWorkspaceMutation = { __typename?: 'Mutation', addWorkspace: { __typename: 'Workspace', id: string, title: string, url: string, adminEmails: Array<string>, guestEmails: Array<string>, memberEmails: Array<string>, pendingInvitations: Array<string>, allowedEmailDomains: Array<string>, createdByUserId: string, updatedByUserId: string | null, updatedAt: string | null, createdAt: string, publicAccessStatus: WorkspacePublicAccessStatus } };

export type WorkspaceItemGridFieldsFragment = { __typename: 'WorkspaceItem', id: string, createdAt: string, aspectRatio: number, accessLevel: WorkspaceItemAccessLevel, mediaType: ItemMediaType, workspaceId: string, uploadSessionId: string, title: string | null, original: { __typename?: 'ItemOriginal', path: string, info: { __typename?: 'ItemOriginalInfo', type: string } | null }, mediaVariants: Array<{ __typename?: 'MediaVariants', url: string, variant: string, type: string }> };

export type UpdateWorkspaceItemsAccessLevelMutationVariables = Exact<{
  workspaceId: Scalars['String'];
  itemIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  uploadSessionId?: InputMaybe<Scalars['String']>;
  accessLevel: WorkspaceItemAccessLevel;
}>;


export type UpdateWorkspaceItemsAccessLevelMutation = { __typename?: 'Mutation', updateWorkspaceItemsAccessLevel: { __typename?: 'RequestStatus', status: string } };

export type IncrementWorkspaceItemViewsMutationVariables = Exact<{
  workspaceItemId: Scalars['String'];
}>;


export type IncrementWorkspaceItemViewsMutation = { __typename?: 'Mutation', incrementWorkspaceItemViews: { __typename?: 'WorkspaceItem', id: string, openCount: number } };

export type SaveDiscoveryItemToWorkspaceMutationVariables = Exact<{
  itemId: Scalars['String'];
  workspaceId: Scalars['String'];
  isBoardItem?: InputMaybe<Scalars['Boolean']>;
}>;


export type SaveDiscoveryItemToWorkspaceMutation = { __typename?: 'Mutation', saveDiscoveryItemToWorkspace: { __typename?: 'WorkspaceItem', id: string } };

export type WorkspaceItemQueryVariables = Exact<{
  workspaceItemId: Scalars['String'];
}>;


export type WorkspaceItemQuery = { __typename?: 'Query', workspaceItem: { __typename?: 'WorkspaceItem', id: string, mediaVariants: Array<{ __typename?: 'MediaVariants', url: string, variant: string, type: string }> } };

export type WorkspaceItemTagFieldsFragment = { __typename?: 'WorkspaceItemTag', id: string, tagId: string, description: string, score: number };

export type WorkspaceItemViewQueryVariables = Exact<{
  workspaceItemId: Scalars['String'];
}>;


export type WorkspaceItemViewQuery = { __typename?: 'Query', workspaceItem: { __typename?: 'WorkspaceItem', id: string, userRole: WorkspaceItemUserRole, createdAt: string, updatedAt: string | null, webUrl: string | null, title: string | null, openCount: number, aspectRatio: number, accessLevel: WorkspaceItemAccessLevel, mediaType: ItemMediaType, commentCount: number, uploadSessionId: string, workspaceId: string, original: { __typename?: 'ItemOriginal', path: string, info: { __typename?: 'ItemOriginalInfo', type: string } | null }, itemSourceInfo: { __typename?: 'ItemSourceInfo', type: ItemSourceInfoType, videoExtractInfo: { __typename?: 'ItemVideoExtractInfo', url: string | null, urlType: ItemExtractedFromInfoUrlType | null } | null, extensionImportInfo: { __typename?: 'ItemExtensionImportInfo', pageUrl: string | null } | null }, createdBy: { __typename?: 'User', id: string, email: string, handle: string, displayName: string, photoURL: string | null } | null, tags: Array<{ __typename?: 'WorkspaceItemTag', id: string, tagId: string, description: string, score: number }>, colors: Array<{ __typename?: 'Color', id: string, pixelFraction: number, hex: string, score: number }>, mediaVariants: Array<{ __typename?: 'MediaVariants', url: string, variant: string, type: string }> } };

export type UpdateWorkspaceItemTitleMutationVariables = Exact<{
  workspaceItemId: Scalars['String'];
  title: Scalars['String'];
}>;


export type UpdateWorkspaceItemTitleMutation = { __typename?: 'Mutation', updateWorkspaceItemTitle: { __typename?: 'WorkspaceItem', id: string, title: string | null } };

export type AddWorkspaceItemTagMutationVariables = Exact<{
  workspaceItemId: Scalars['String'];
  tagDescription: Scalars['String'];
  tagId?: InputMaybe<Scalars['String']>;
}>;


export type AddWorkspaceItemTagMutation = { __typename?: 'Mutation', addWorkspaceItemTag: { __typename?: 'ItemTagSimple', id: string, description: string } };

export type DeleteWorkspaceItemTagMutationVariables = Exact<{
  workspaceItemId: Scalars['String'];
  tagId: Scalars['String'];
}>;


export type DeleteWorkspaceItemTagMutation = { __typename?: 'Mutation', deleteWorkspaceItemTag: { __typename?: 'RequestStatus', status: string } };

export type SaveWorkspaceItemsToBoardMutationVariables = Exact<{
  workspaceItemIds: Array<Scalars['String']> | Scalars['String'];
  boardId: Scalars['String'];
  workspaceId: Scalars['String'];
}>;


export type SaveWorkspaceItemsToBoardMutation = { __typename?: 'Mutation', saveWorkspaceItemsToBoard: { __typename?: 'RequestStatus', status: string } };

export type WorkspaceItemsQueryVariables = Exact<{
  workspaceId: Scalars['String'];
  first: Scalars['Int'];
  cursor?: InputMaybe<Scalars['String']>;
}>;


export type WorkspaceItemsQuery = { __typename?: 'Query', workspaceItems: { __typename?: 'WorkspaceItemsPayload', pageInfo: { __typename?: 'PageInfo', nextCursor: string | null }, workspaceItems: Array<{ __typename: 'WorkspaceItem', id: string, createdAt: string, aspectRatio: number, accessLevel: WorkspaceItemAccessLevel, mediaType: ItemMediaType, workspaceId: string, uploadSessionId: string, title: string | null, original: { __typename?: 'ItemOriginal', path: string, info: { __typename?: 'ItemOriginalInfo', type: string } | null }, mediaVariants: Array<{ __typename?: 'MediaVariants', url: string, variant: string, type: string }> }> } };

export type SearchWorkspaceItemsQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
  cursor?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<SearchWorkspaceItemsFilterInput>;
  scope: SearchWorkspaceItemsScope;
}>;


export type SearchWorkspaceItemsQuery = { __typename?: 'Query', searchWorkspaceItems: { __typename?: 'SearchWorkspaceItemsPayload', pageInfo: { __typename?: 'PageInfo', nextCursor: string | null }, workspaceItems: Array<{ __typename: 'WorkspaceItem', id: string, createdAt: string, aspectRatio: number, accessLevel: WorkspaceItemAccessLevel, mediaType: ItemMediaType, workspaceId: string, uploadSessionId: string, title: string | null, original: { __typename?: 'ItemOriginal', path: string, info: { __typename?: 'ItemOriginalInfo', type: string } | null }, mediaVariants: Array<{ __typename?: 'MediaVariants', url: string, variant: string, type: string }> }> } };

export type SimilarWorkspaceItemsQueryVariables = Exact<{
  workspaceItemId: Scalars['String'];
  scope: SimilarWorkspaceItemsScope;
  workspaceId?: InputMaybe<Scalars['String']>;
  cursor?: InputMaybe<Scalars['String']>;
}>;


export type SimilarWorkspaceItemsQuery = { __typename?: 'Query', similarWorkspaceItems: { __typename?: 'SimilarWorkspaceItemsPayload', pageInfo: { __typename?: 'PageInfo', nextCursor: string | null }, similarItems: Array<{ __typename: 'WorkspaceItem', id: string, createdAt: string, aspectRatio: number, accessLevel: WorkspaceItemAccessLevel, mediaType: ItemMediaType, workspaceId: string, uploadSessionId: string, title: string | null, original: { __typename?: 'ItemOriginal', path: string, info: { __typename?: 'ItemOriginalInfo', type: string } | null }, mediaVariants: Array<{ __typename?: 'MediaVariants', url: string, variant: string, type: string }> }> } };

export type ImageSearchWorkspaceItemsQueryVariables = Exact<{
  workspaceItemId: Scalars['String'];
  query: Scalars['String'];
  workspaceId?: InputMaybe<Scalars['String']>;
  scope: SearchWorkspaceItemsScope;
  first: Scalars['Int'];
  cursor?: InputMaybe<Scalars['String']>;
}>;


export type ImageSearchWorkspaceItemsQuery = { __typename?: 'Query', imageSearchWorkspaceItems: { __typename?: 'ImageSearchWorkspaceItemPayload', pageInfo: { __typename?: 'PageInfo', nextCursor: string | null }, workspaceItems: Array<{ __typename: 'WorkspaceItem', id: string, createdAt: string, aspectRatio: number, accessLevel: WorkspaceItemAccessLevel, mediaType: ItemMediaType, workspaceId: string, uploadSessionId: string, title: string | null, original: { __typename?: 'ItemOriginal', path: string, info: { __typename?: 'ItemOriginalInfo', type: string } | null }, mediaVariants: Array<{ __typename?: 'MediaVariants', url: string, variant: string, type: string }> }> } };

export type WorkspacePublicBasicFieldsFragment = { __typename: 'WorkspacePublic', id: string, title: string, url: string, publicAccessStatus: WorkspacePublicAccessStatus, iconUrl: string | null };

export type WorkspacePublicByUrlQueryVariables = Exact<{
  url: Scalars['String'];
}>;


export type WorkspacePublicByUrlQuery = { __typename?: 'Query', workspacePublicByUrl: { __typename: 'Workspace', id: string, title: string, url: string, iconUrl: string | null, thumbUrls: Array<string> } | { __typename: 'WorkspacePublic', id: string, title: string, url: string, publicAccessStatus: WorkspacePublicAccessStatus, iconUrl: string | null } };

export const PromoCodeFragmentDoc = gql`
    fragment PromoCode on StripePromoCode {
  id
  code
  customer
  restrictions {
    first_time_transactions
    minimum_amount
    minimum_amount_currency
  }
  __typename
}
    `;
export const CouponFragmentDoc = gql`
    fragment Coupon on StripeCoupon {
  id
  name
  created
  percentOff
  timesRedeemed
  __typename
}
    `;
export const BillingSubscriptionFragmentDoc = gql`
    fragment BillingSubscription on BillingSubscription {
  id
  activeDiscount {
    coupon {
      ...Coupon
    }
  }
  subscriptionItems {
    id
    quantity
    price {
      id
      unitAmount
      taxBehavior
      product {
        id
        name
        description
        unitLabel
      }
      recurring {
        aggregateUsage
        interval
        intervalCount
        usageType
      }
    }
  }
  plan {
    id
    defaultOption
    status
    limits {
      items
      boardItems
      boards
      enableCollabWorkspaces
      enableLinkSharing
      frameExtractions
      projects
      enableCustomBranding
      vatBilling
      videoSupport
      gifExtraction
      guests
    }
    presentation {
      name
      description
      price
      priceUnit
      intervals {
        monthly {
          price
          priceUnit
          priceId
        }
        yearly {
          price
          priceUnit
          priceId
        }
      }
    }
    features {
      template
      subline
      replace_key
    }
  }
}
    ${CouponFragmentDoc}`;
export const BoardBasicFieldsFragmentDoc = gql`
    fragment BoardBasicFields on Board {
  id
  title
  createdAt
  updatedAt
  iconCode
  userRole
  itemCount
  priority
}
    `;
export const BoardItemTagFieldsFragmentDoc = gql`
    fragment BoardItemTagFields on BoardItemTag {
  id
  tagId
  description
  score
}
    `;
export const EditSessionFragmentFragmentDoc = gql`
    fragment EditSessionFragment on EditSession {
  id
  status
  createdAt
  updatedAt
  thumbnailUrl
  members {
    userId
    role
  }
  context {
    workspaceId
    backUrl
  }
  startValues {
    baseImageUrl
  }
  powerUp {
    isPowered
    poweredBy
    updatedAt
    createdAt
  }
}
    `;
export const ItemCommentBasicFieldsFragmentDoc = gql`
    fragment ItemCommentBasicFields on ItemComment {
  __typename
  id
  createdAt
  text
  author {
    ... on User {
      __typename
      id
      displayName
      photoURL
    }
    ... on Guest {
      __typename
      id
      displayName
    }
  }
}
    `;
export const MagicBoardBasicFieldsFragmentDoc = gql`
    fragment MagicBoardBasicFields on MagicBoard {
  id
  tagId
  description
  count
  score
}
    `;
export const NotificationBasicFieldsFragmentDoc = gql`
    fragment NotificationBasicFields on Notification {
  __typename
  id
  type
  isRead
  author {
    photoURL
    displayName
  }
  fromSystem
  createdAt
  title
  linkPath
}
    `;
export const ProjectBasicFieldsFragmentDoc = gql`
    fragment ProjectBasicFields on Project {
  id
  title
  createdAt
  updatedAt
  createdById
  linkAccess
  userRole
  workspaceId
  workspaceAccess
  ownerEmails
  workspaceId
}
    `;
export const ItemReactionBasicFieldsFragmentDoc = gql`
    fragment ItemReactionBasicFields on ItemReaction {
  __typename
  id
  reaction
  createdAt
  author {
    ... on User {
      __typename
      id
      displayName
    }
    ... on Guest {
      __typename
      id
      displayName
    }
  }
}
    `;
export const ItemReactionGroupFieldsFragmentDoc = gql`
    fragment ItemReactionGroupFields on ItemReactionGroup {
  __typename
  id
  count
  reaction
  hasReacted
  itemReactions {
    ...ItemReactionBasicFields
  }
}
    ${ItemReactionBasicFieldsFragmentDoc}`;
export const UploadSessionBasicFieldsFragmentDoc = gql`
    fragment UploadSessionBasicFields on UploadSession {
  __typename
  id
  thumbUrl
  createdAt
  createdBy
  size
  tagCount
  title
}
    `;
export const UserBasicFieldsFragmentDoc = gql`
    fragment UserBasicFields on User {
  __typename
  id
  uid
  email
  firstName
  lastName
  displayName
  bio
  photoURL
  handle
  createdAt
  coverImageUrl
  frozenAccount
}
    `;
export const WorkspaceBasicFieldsFragmentDoc = gql`
    fragment WorkspaceBasicFields on Workspace {
  __typename
  id
  title
  url
  adminEmails
  guestEmails
  memberEmails
  pendingInvitations
  allowedEmailDomains
  createdByUserId
  updatedByUserId
  updatedAt
  createdAt
  publicAccessStatus
}
    `;
export const WorkspaceThumbFieldsFragmentDoc = gql`
    fragment WorkspaceThumbFields on Workspace {
  __typename
  id
  title
  url
  iconUrl
  thumbUrls
}
    `;
export const WorkspaceItemGridFieldsFragmentDoc = gql`
    fragment WorkspaceItemGridFields on WorkspaceItem {
  __typename
  id
  createdAt
  aspectRatio
  accessLevel
  mediaType
  workspaceId
  uploadSessionId
  title
  original {
    path
    info {
      type
    }
  }
  mediaVariants {
    url
    variant
    type
  }
}
    `;
export const WorkspaceItemTagFieldsFragmentDoc = gql`
    fragment WorkspaceItemTagFields on WorkspaceItemTag {
  id
  tagId
  description
  score
}
    `;
export const WorkspacePublicBasicFieldsFragmentDoc = gql`
    fragment WorkspacePublicBasicFields on WorkspacePublic {
  __typename
  id
  title
  url
  publicAccessStatus
  iconUrl
}
    `;
export const AddDiscountCouponToWorkspaceDocument = gql`
    mutation addDiscountCouponToWorkspace($workspaceId: String!, $couponId: String!) {
  addDiscountCouponToWorkspace(
    input: {workspaceId: $workspaceId, couponId: $couponId}
  ) {
    status
  }
}
    `;
export type AddDiscountCouponToWorkspaceMutationFn = Apollo.MutationFunction<AddDiscountCouponToWorkspaceMutation, AddDiscountCouponToWorkspaceMutationVariables>;

/**
 * __useAddDiscountCouponToWorkspaceMutation__
 *
 * To run a mutation, you first call `useAddDiscountCouponToWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDiscountCouponToWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDiscountCouponToWorkspaceMutation, { data, loading, error }] = useAddDiscountCouponToWorkspaceMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      couponId: // value for 'couponId'
 *   },
 * });
 */
export function useAddDiscountCouponToWorkspaceMutation(baseOptions?: Apollo.MutationHookOptions<AddDiscountCouponToWorkspaceMutation, AddDiscountCouponToWorkspaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDiscountCouponToWorkspaceMutation, AddDiscountCouponToWorkspaceMutationVariables>(AddDiscountCouponToWorkspaceDocument, options);
      }
export type AddDiscountCouponToWorkspaceMutationHookResult = ReturnType<typeof useAddDiscountCouponToWorkspaceMutation>;
export type AddDiscountCouponToWorkspaceMutationResult = Apollo.MutationResult<AddDiscountCouponToWorkspaceMutation>;
export type AddDiscountCouponToWorkspaceMutationOptions = Apollo.BaseMutationOptions<AddDiscountCouponToWorkspaceMutation, AddDiscountCouponToWorkspaceMutationVariables>;
export const AddPromoCodeToWorkspaceDocument = gql`
    mutation addPromoCodeToWorkspace($workspaceId: String!, $promoCode: String!) {
  addPromoCodeToWorkspace(
    input: {workspaceId: $workspaceId, promoCode: $promoCode}
  ) {
    ...BillingSubscription
  }
}
    ${BillingSubscriptionFragmentDoc}`;
export type AddPromoCodeToWorkspaceMutationFn = Apollo.MutationFunction<AddPromoCodeToWorkspaceMutation, AddPromoCodeToWorkspaceMutationVariables>;

/**
 * __useAddPromoCodeToWorkspaceMutation__
 *
 * To run a mutation, you first call `useAddPromoCodeToWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPromoCodeToWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPromoCodeToWorkspaceMutation, { data, loading, error }] = useAddPromoCodeToWorkspaceMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      promoCode: // value for 'promoCode'
 *   },
 * });
 */
export function useAddPromoCodeToWorkspaceMutation(baseOptions?: Apollo.MutationHookOptions<AddPromoCodeToWorkspaceMutation, AddPromoCodeToWorkspaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPromoCodeToWorkspaceMutation, AddPromoCodeToWorkspaceMutationVariables>(AddPromoCodeToWorkspaceDocument, options);
      }
export type AddPromoCodeToWorkspaceMutationHookResult = ReturnType<typeof useAddPromoCodeToWorkspaceMutation>;
export type AddPromoCodeToWorkspaceMutationResult = Apollo.MutationResult<AddPromoCodeToWorkspaceMutation>;
export type AddPromoCodeToWorkspaceMutationOptions = Apollo.BaseMutationOptions<AddPromoCodeToWorkspaceMutation, AddPromoCodeToWorkspaceMutationVariables>;
export const BillingSubscriptionDocument = gql`
    query billingSubscription($workspaceId: String!) {
  billingSubscription(input: {workspaceId: $workspaceId}) {
    ...BillingSubscription
    currentPeriodEnd
    latestHostedInvoiceUrl
    latestHostedInvoicePdf
  }
}
    ${BillingSubscriptionFragmentDoc}`;

/**
 * __useBillingSubscriptionQuery__
 *
 * To run a query within a React component, call `useBillingSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingSubscriptionQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useBillingSubscriptionQuery(baseOptions: Apollo.QueryHookOptions<BillingSubscriptionQuery, BillingSubscriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BillingSubscriptionQuery, BillingSubscriptionQueryVariables>(BillingSubscriptionDocument, options);
      }
export function useBillingSubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BillingSubscriptionQuery, BillingSubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BillingSubscriptionQuery, BillingSubscriptionQueryVariables>(BillingSubscriptionDocument, options);
        }
export type BillingSubscriptionQueryHookResult = ReturnType<typeof useBillingSubscriptionQuery>;
export type BillingSubscriptionLazyQueryHookResult = ReturnType<typeof useBillingSubscriptionLazyQuery>;
export type BillingSubscriptionQueryResult = Apollo.QueryResult<BillingSubscriptionQuery, BillingSubscriptionQueryVariables>;
export const CouponDocument = gql`
    query coupon($couponId: String!) {
  coupon(input: {couponId: $couponId}) {
    ...Coupon
  }
}
    ${CouponFragmentDoc}`;

/**
 * __useCouponQuery__
 *
 * To run a query within a React component, call `useCouponQuery` and pass it any options that fit your needs.
 * When your component renders, `useCouponQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCouponQuery({
 *   variables: {
 *      couponId: // value for 'couponId'
 *   },
 * });
 */
export function useCouponQuery(baseOptions: Apollo.QueryHookOptions<CouponQuery, CouponQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CouponQuery, CouponQueryVariables>(CouponDocument, options);
      }
export function useCouponLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CouponQuery, CouponQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CouponQuery, CouponQueryVariables>(CouponDocument, options);
        }
export type CouponQueryHookResult = ReturnType<typeof useCouponQuery>;
export type CouponLazyQueryHookResult = ReturnType<typeof useCouponLazyQuery>;
export type CouponQueryResult = Apollo.QueryResult<CouponQuery, CouponQueryVariables>;
export const PromoCodeDocument = gql`
    query promoCode($promoCode: String!) {
  promoCode(input: {promoCode: $promoCode}) {
    ...PromoCode
  }
}
    ${PromoCodeFragmentDoc}`;

/**
 * __usePromoCodeQuery__
 *
 * To run a query within a React component, call `usePromoCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromoCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromoCodeQuery({
 *   variables: {
 *      promoCode: // value for 'promoCode'
 *   },
 * });
 */
export function usePromoCodeQuery(baseOptions: Apollo.QueryHookOptions<PromoCodeQuery, PromoCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PromoCodeQuery, PromoCodeQueryVariables>(PromoCodeDocument, options);
      }
export function usePromoCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PromoCodeQuery, PromoCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PromoCodeQuery, PromoCodeQueryVariables>(PromoCodeDocument, options);
        }
export type PromoCodeQueryHookResult = ReturnType<typeof usePromoCodeQuery>;
export type PromoCodeLazyQueryHookResult = ReturnType<typeof usePromoCodeLazyQuery>;
export type PromoCodeQueryResult = Apollo.QueryResult<PromoCodeQuery, PromoCodeQueryVariables>;
export const CreateBillingPortalSessionDocument = gql`
    mutation createBillingPortalSession($workspaceId: ID!, $returnUrl: String!) {
  createBillingPortalSession(
    input: {workspaceId: $workspaceId, returnUrl: $returnUrl}
  ) {
    url
  }
}
    `;
export type CreateBillingPortalSessionMutationFn = Apollo.MutationFunction<CreateBillingPortalSessionMutation, CreateBillingPortalSessionMutationVariables>;

/**
 * __useCreateBillingPortalSessionMutation__
 *
 * To run a mutation, you first call `useCreateBillingPortalSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBillingPortalSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBillingPortalSessionMutation, { data, loading, error }] = useCreateBillingPortalSessionMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      returnUrl: // value for 'returnUrl'
 *   },
 * });
 */
export function useCreateBillingPortalSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateBillingPortalSessionMutation, CreateBillingPortalSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBillingPortalSessionMutation, CreateBillingPortalSessionMutationVariables>(CreateBillingPortalSessionDocument, options);
      }
export type CreateBillingPortalSessionMutationHookResult = ReturnType<typeof useCreateBillingPortalSessionMutation>;
export type CreateBillingPortalSessionMutationResult = Apollo.MutationResult<CreateBillingPortalSessionMutation>;
export type CreateBillingPortalSessionMutationOptions = Apollo.BaseMutationOptions<CreateBillingPortalSessionMutation, CreateBillingPortalSessionMutationVariables>;
export const CreateCheckoutSessionDocument = gql`
    mutation createCheckoutSession($input: CheckoutInput!) {
  createCheckoutSession(input: $input) {
    url
  }
}
    `;
export type CreateCheckoutSessionMutationFn = Apollo.MutationFunction<CreateCheckoutSessionMutation, CreateCheckoutSessionMutationVariables>;

/**
 * __useCreateCheckoutSessionMutation__
 *
 * To run a mutation, you first call `useCreateCheckoutSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCheckoutSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCheckoutSessionMutation, { data, loading, error }] = useCreateCheckoutSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCheckoutSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateCheckoutSessionMutation, CreateCheckoutSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCheckoutSessionMutation, CreateCheckoutSessionMutationVariables>(CreateCheckoutSessionDocument, options);
      }
export type CreateCheckoutSessionMutationHookResult = ReturnType<typeof useCreateCheckoutSessionMutation>;
export type CreateCheckoutSessionMutationResult = Apollo.MutationResult<CreateCheckoutSessionMutation>;
export type CreateCheckoutSessionMutationOptions = Apollo.BaseMutationOptions<CreateCheckoutSessionMutation, CreateCheckoutSessionMutationVariables>;
export const BoardsDocument = gql`
    query boards($projectId: String!, $first: Int!) {
  boards(input: {projectId: $projectId, first: $first}) {
    boards {
      ...BoardBasicFields
    }
  }
}
    ${BoardBasicFieldsFragmentDoc}`;

/**
 * __useBoardsQuery__
 *
 * To run a query within a React component, call `useBoardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBoardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBoardsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useBoardsQuery(baseOptions: Apollo.QueryHookOptions<BoardsQuery, BoardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BoardsQuery, BoardsQueryVariables>(BoardsDocument, options);
      }
export function useBoardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BoardsQuery, BoardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BoardsQuery, BoardsQueryVariables>(BoardsDocument, options);
        }
export type BoardsQueryHookResult = ReturnType<typeof useBoardsQuery>;
export type BoardsLazyQueryHookResult = ReturnType<typeof useBoardsLazyQuery>;
export type BoardsQueryResult = Apollo.QueryResult<BoardsQuery, BoardsQueryVariables>;
export const UpdateBoardIconDocument = gql`
    mutation updateBoardIcon($boardId: String!, $iconCode: String) {
  updateBoardIcon(input: {boardId: $boardId, iconCode: $iconCode}) {
    ...BoardBasicFields
  }
}
    ${BoardBasicFieldsFragmentDoc}`;
export type UpdateBoardIconMutationFn = Apollo.MutationFunction<UpdateBoardIconMutation, UpdateBoardIconMutationVariables>;

/**
 * __useUpdateBoardIconMutation__
 *
 * To run a mutation, you first call `useUpdateBoardIconMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBoardIconMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBoardIconMutation, { data, loading, error }] = useUpdateBoardIconMutation({
 *   variables: {
 *      boardId: // value for 'boardId'
 *      iconCode: // value for 'iconCode'
 *   },
 * });
 */
export function useUpdateBoardIconMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBoardIconMutation, UpdateBoardIconMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBoardIconMutation, UpdateBoardIconMutationVariables>(UpdateBoardIconDocument, options);
      }
export type UpdateBoardIconMutationHookResult = ReturnType<typeof useUpdateBoardIconMutation>;
export type UpdateBoardIconMutationResult = Apollo.MutationResult<UpdateBoardIconMutation>;
export type UpdateBoardIconMutationOptions = Apollo.BaseMutationOptions<UpdateBoardIconMutation, UpdateBoardIconMutationVariables>;
export const UpdateBoardTitleDocument = gql`
    mutation updateBoardTitle($boardId: String!, $title: String!) {
  updateBoardTitle(input: {boardId: $boardId, title: $title}) {
    ...BoardBasicFields
  }
}
    ${BoardBasicFieldsFragmentDoc}`;
export type UpdateBoardTitleMutationFn = Apollo.MutationFunction<UpdateBoardTitleMutation, UpdateBoardTitleMutationVariables>;

/**
 * __useUpdateBoardTitleMutation__
 *
 * To run a mutation, you first call `useUpdateBoardTitleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBoardTitleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBoardTitleMutation, { data, loading, error }] = useUpdateBoardTitleMutation({
 *   variables: {
 *      boardId: // value for 'boardId'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useUpdateBoardTitleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBoardTitleMutation, UpdateBoardTitleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBoardTitleMutation, UpdateBoardTitleMutationVariables>(UpdateBoardTitleDocument, options);
      }
export type UpdateBoardTitleMutationHookResult = ReturnType<typeof useUpdateBoardTitleMutation>;
export type UpdateBoardTitleMutationResult = Apollo.MutationResult<UpdateBoardTitleMutation>;
export type UpdateBoardTitleMutationOptions = Apollo.BaseMutationOptions<UpdateBoardTitleMutation, UpdateBoardTitleMutationVariables>;
export const AddBoardDocument = gql`
    mutation addBoard($projectId: String!, $workspaceId: String!, $title: String, $iconCode: String) {
  addBoard(
    input: {projectId: $projectId, workspaceId: $workspaceId, title: $title, iconCode: $iconCode}
  ) {
    ...BoardBasicFields
  }
}
    ${BoardBasicFieldsFragmentDoc}`;
export type AddBoardMutationFn = Apollo.MutationFunction<AddBoardMutation, AddBoardMutationVariables>;

/**
 * __useAddBoardMutation__
 *
 * To run a mutation, you first call `useAddBoardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBoardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBoardMutation, { data, loading, error }] = useAddBoardMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      workspaceId: // value for 'workspaceId'
 *      title: // value for 'title'
 *      iconCode: // value for 'iconCode'
 *   },
 * });
 */
export function useAddBoardMutation(baseOptions?: Apollo.MutationHookOptions<AddBoardMutation, AddBoardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddBoardMutation, AddBoardMutationVariables>(AddBoardDocument, options);
      }
export type AddBoardMutationHookResult = ReturnType<typeof useAddBoardMutation>;
export type AddBoardMutationResult = Apollo.MutationResult<AddBoardMutation>;
export type AddBoardMutationOptions = Apollo.BaseMutationOptions<AddBoardMutation, AddBoardMutationVariables>;
export const RecentBoardsDocument = gql`
    query recentBoards($workspaceId: String!) {
  recentBoards(input: {workspaceId: $workspaceId}) {
    ...BoardBasicFields
    thumbUrls
    project {
      id
      title
    }
  }
}
    ${BoardBasicFieldsFragmentDoc}`;

/**
 * __useRecentBoardsQuery__
 *
 * To run a query within a React component, call `useRecentBoardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentBoardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentBoardsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useRecentBoardsQuery(baseOptions: Apollo.QueryHookOptions<RecentBoardsQuery, RecentBoardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecentBoardsQuery, RecentBoardsQueryVariables>(RecentBoardsDocument, options);
      }
export function useRecentBoardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecentBoardsQuery, RecentBoardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecentBoardsQuery, RecentBoardsQueryVariables>(RecentBoardsDocument, options);
        }
export type RecentBoardsQueryHookResult = ReturnType<typeof useRecentBoardsQuery>;
export type RecentBoardsLazyQueryHookResult = ReturnType<typeof useRecentBoardsLazyQuery>;
export type RecentBoardsQueryResult = Apollo.QueryResult<RecentBoardsQuery, RecentBoardsQueryVariables>;
export const PublicBoardsDocument = gql`
    query publicBoards($first: Int!, $cursor: String, $createdBy: String) {
  publicBoards(input: {first: $first, cursor: $cursor, createdBy: $createdBy}) {
    pageInfo {
      nextCursor
    }
    boards {
      ...BoardBasicFields
      thumbUrls
      project {
        id
        title
      }
      createdBy {
        uid
        handle
        photoURL
        displayName
      }
      firstMadePublicAt
    }
  }
}
    ${BoardBasicFieldsFragmentDoc}`;

/**
 * __usePublicBoardsQuery__
 *
 * To run a query within a React component, call `usePublicBoardsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicBoardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicBoardsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *      createdBy: // value for 'createdBy'
 *   },
 * });
 */
export function usePublicBoardsQuery(baseOptions: Apollo.QueryHookOptions<PublicBoardsQuery, PublicBoardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicBoardsQuery, PublicBoardsQueryVariables>(PublicBoardsDocument, options);
      }
export function usePublicBoardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicBoardsQuery, PublicBoardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicBoardsQuery, PublicBoardsQueryVariables>(PublicBoardsDocument, options);
        }
export type PublicBoardsQueryHookResult = ReturnType<typeof usePublicBoardsQuery>;
export type PublicBoardsLazyQueryHookResult = ReturnType<typeof usePublicBoardsLazyQuery>;
export type PublicBoardsQueryResult = Apollo.QueryResult<PublicBoardsQuery, PublicBoardsQueryVariables>;
export const SaveDiscoveryItemToBoardDocument = gql`
    mutation saveDiscoveryItemToBoard($itemId: String!, $boardId: String!, $workspaceId: String!, $isBoardItem: Boolean) {
  saveDiscoveryItemToBoard(
    input: {itemId: $itemId, workspaceId: $workspaceId, boardId: $boardId, isBoardItem: $isBoardItem}
  ) {
    id
  }
}
    `;
export type SaveDiscoveryItemToBoardMutationFn = Apollo.MutationFunction<SaveDiscoveryItemToBoardMutation, SaveDiscoveryItemToBoardMutationVariables>;

/**
 * __useSaveDiscoveryItemToBoardMutation__
 *
 * To run a mutation, you first call `useSaveDiscoveryItemToBoardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDiscoveryItemToBoardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDiscoveryItemToBoardMutation, { data, loading, error }] = useSaveDiscoveryItemToBoardMutation({
 *   variables: {
 *      itemId: // value for 'itemId'
 *      boardId: // value for 'boardId'
 *      workspaceId: // value for 'workspaceId'
 *      isBoardItem: // value for 'isBoardItem'
 *   },
 * });
 */
export function useSaveDiscoveryItemToBoardMutation(baseOptions?: Apollo.MutationHookOptions<SaveDiscoveryItemToBoardMutation, SaveDiscoveryItemToBoardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveDiscoveryItemToBoardMutation, SaveDiscoveryItemToBoardMutationVariables>(SaveDiscoveryItemToBoardDocument, options);
      }
export type SaveDiscoveryItemToBoardMutationHookResult = ReturnType<typeof useSaveDiscoveryItemToBoardMutation>;
export type SaveDiscoveryItemToBoardMutationResult = Apollo.MutationResult<SaveDiscoveryItemToBoardMutation>;
export type SaveDiscoveryItemToBoardMutationOptions = Apollo.BaseMutationOptions<SaveDiscoveryItemToBoardMutation, SaveDiscoveryItemToBoardMutationVariables>;
export const IncrementBoardItemViewsDocument = gql`
    mutation incrementBoardItemViews($boardItemId: String!) {
  incrementBoardItemViews(input: {boardItemId: $boardItemId}) {
    id
    openCount
  }
}
    `;
export type IncrementBoardItemViewsMutationFn = Apollo.MutationFunction<IncrementBoardItemViewsMutation, IncrementBoardItemViewsMutationVariables>;

/**
 * __useIncrementBoardItemViewsMutation__
 *
 * To run a mutation, you first call `useIncrementBoardItemViewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIncrementBoardItemViewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [incrementBoardItemViewsMutation, { data, loading, error }] = useIncrementBoardItemViewsMutation({
 *   variables: {
 *      boardItemId: // value for 'boardItemId'
 *   },
 * });
 */
export function useIncrementBoardItemViewsMutation(baseOptions?: Apollo.MutationHookOptions<IncrementBoardItemViewsMutation, IncrementBoardItemViewsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IncrementBoardItemViewsMutation, IncrementBoardItemViewsMutationVariables>(IncrementBoardItemViewsDocument, options);
      }
export type IncrementBoardItemViewsMutationHookResult = ReturnType<typeof useIncrementBoardItemViewsMutation>;
export type IncrementBoardItemViewsMutationResult = Apollo.MutationResult<IncrementBoardItemViewsMutation>;
export type IncrementBoardItemViewsMutationOptions = Apollo.BaseMutationOptions<IncrementBoardItemViewsMutation, IncrementBoardItemViewsMutationVariables>;
export const UpdateBoardItemsAccessLevelDocument = gql`
    mutation updateBoardItemsAccessLevel($boardItemIds: [String!]!, $boardId: String!, $accessLevel: BoardItemAccessLevel!) {
  updateBoardItemsAccessLevel(
    input: {boardItemIds: $boardItemIds, boardId: $boardId, accessLevel: $accessLevel}
  ) {
    status
  }
}
    `;
export type UpdateBoardItemsAccessLevelMutationFn = Apollo.MutationFunction<UpdateBoardItemsAccessLevelMutation, UpdateBoardItemsAccessLevelMutationVariables>;

/**
 * __useUpdateBoardItemsAccessLevelMutation__
 *
 * To run a mutation, you first call `useUpdateBoardItemsAccessLevelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBoardItemsAccessLevelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBoardItemsAccessLevelMutation, { data, loading, error }] = useUpdateBoardItemsAccessLevelMutation({
 *   variables: {
 *      boardItemIds: // value for 'boardItemIds'
 *      boardId: // value for 'boardId'
 *      accessLevel: // value for 'accessLevel'
 *   },
 * });
 */
export function useUpdateBoardItemsAccessLevelMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBoardItemsAccessLevelMutation, UpdateBoardItemsAccessLevelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBoardItemsAccessLevelMutation, UpdateBoardItemsAccessLevelMutationVariables>(UpdateBoardItemsAccessLevelDocument, options);
      }
export type UpdateBoardItemsAccessLevelMutationHookResult = ReturnType<typeof useUpdateBoardItemsAccessLevelMutation>;
export type UpdateBoardItemsAccessLevelMutationResult = Apollo.MutationResult<UpdateBoardItemsAccessLevelMutation>;
export type UpdateBoardItemsAccessLevelMutationOptions = Apollo.BaseMutationOptions<UpdateBoardItemsAccessLevelMutation, UpdateBoardItemsAccessLevelMutationVariables>;
export const BoardItemViewDocument = gql`
    query boardItemView($boardItemId: String!) {
  boardItem(input: {boardItemId: $boardItemId}) {
    id
    itemId
    workspaceItemId
    userRole
    createdAt
    updatedAt
    webUrl
    title
    boardId
    projectId
    openCount
    aspectRatio
    accessLevel
    mediaType
    commentCount
    original {
      path
      info {
        type
      }
    }
    uploadSessionId
    workspaceId
    itemSourceInfo {
      type
      videoExtractInfo {
        url
        urlType
      }
      extensionImportInfo {
        pageUrl
      }
    }
    createdBy {
      id
      email
      handle
      displayName
      photoURL
    }
    tags {
      ...BoardItemTagFields
    }
    colors {
      id
      pixelFraction
      hex
      score
    }
    mediaVariants {
      url
      variant
      type
    }
  }
}
    ${BoardItemTagFieldsFragmentDoc}`;

/**
 * __useBoardItemViewQuery__
 *
 * To run a query within a React component, call `useBoardItemViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useBoardItemViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBoardItemViewQuery({
 *   variables: {
 *      boardItemId: // value for 'boardItemId'
 *   },
 * });
 */
export function useBoardItemViewQuery(baseOptions: Apollo.QueryHookOptions<BoardItemViewQuery, BoardItemViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BoardItemViewQuery, BoardItemViewQueryVariables>(BoardItemViewDocument, options);
      }
export function useBoardItemViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BoardItemViewQuery, BoardItemViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BoardItemViewQuery, BoardItemViewQueryVariables>(BoardItemViewDocument, options);
        }
export type BoardItemViewQueryHookResult = ReturnType<typeof useBoardItemViewQuery>;
export type BoardItemViewLazyQueryHookResult = ReturnType<typeof useBoardItemViewLazyQuery>;
export type BoardItemViewQueryResult = Apollo.QueryResult<BoardItemViewQuery, BoardItemViewQueryVariables>;
export const CreateCreditPackCheckoutSessionDocument = gql`
    mutation createCreditPackCheckoutSession($input: CreditPackCheckoutInput!) {
  createCreditPackCheckoutSession(input: $input) {
    url
  }
}
    `;
export type CreateCreditPackCheckoutSessionMutationFn = Apollo.MutationFunction<CreateCreditPackCheckoutSessionMutation, CreateCreditPackCheckoutSessionMutationVariables>;

/**
 * __useCreateCreditPackCheckoutSessionMutation__
 *
 * To run a mutation, you first call `useCreateCreditPackCheckoutSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCreditPackCheckoutSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCreditPackCheckoutSessionMutation, { data, loading, error }] = useCreateCreditPackCheckoutSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCreditPackCheckoutSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateCreditPackCheckoutSessionMutation, CreateCreditPackCheckoutSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCreditPackCheckoutSessionMutation, CreateCreditPackCheckoutSessionMutationVariables>(CreateCreditPackCheckoutSessionDocument, options);
      }
export type CreateCreditPackCheckoutSessionMutationHookResult = ReturnType<typeof useCreateCreditPackCheckoutSessionMutation>;
export type CreateCreditPackCheckoutSessionMutationResult = Apollo.MutationResult<CreateCreditPackCheckoutSessionMutation>;
export type CreateCreditPackCheckoutSessionMutationOptions = Apollo.BaseMutationOptions<CreateCreditPackCheckoutSessionMutation, CreateCreditPackCheckoutSessionMutationVariables>;
export const CreditPacksDocument = gql`
    query creditPacks {
  creditPacks {
    priceId
    title
    description
    imageUrl
    creditCount
    unitAmountInCents
    currency
  }
}
    `;

/**
 * __useCreditPacksQuery__
 *
 * To run a query within a React component, call `useCreditPacksQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditPacksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditPacksQuery({
 *   variables: {
 *   },
 * });
 */
export function useCreditPacksQuery(baseOptions?: Apollo.QueryHookOptions<CreditPacksQuery, CreditPacksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreditPacksQuery, CreditPacksQueryVariables>(CreditPacksDocument, options);
      }
export function useCreditPacksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreditPacksQuery, CreditPacksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreditPacksQuery, CreditPacksQueryVariables>(CreditPacksDocument, options);
        }
export type CreditPacksQueryHookResult = ReturnType<typeof useCreditPacksQuery>;
export type CreditPacksLazyQueryHookResult = ReturnType<typeof useCreditPacksLazyQuery>;
export type CreditPacksQueryResult = Apollo.QueryResult<CreditPacksQuery, CreditPacksQueryVariables>;
export const DiscoverNewItemsDocument = gql`
    query discoverNewItems($first: Int!, $cursor: String, $withCreatedBy: Boolean!) {
  discoverNewItems(input: {first: $first, cursor: $cursor}) {
    pageInfo {
      nextCursor
    }
    items {
      ...WorkspaceItemGridFields
      createdBy @include(if: $withCreatedBy) {
        id
        handle
        displayName
        photoURL
      }
    }
  }
}
    ${WorkspaceItemGridFieldsFragmentDoc}`;

/**
 * __useDiscoverNewItemsQuery__
 *
 * To run a query within a React component, call `useDiscoverNewItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscoverNewItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscoverNewItemsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *      withCreatedBy: // value for 'withCreatedBy'
 *   },
 * });
 */
export function useDiscoverNewItemsQuery(baseOptions: Apollo.QueryHookOptions<DiscoverNewItemsQuery, DiscoverNewItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DiscoverNewItemsQuery, DiscoverNewItemsQueryVariables>(DiscoverNewItemsDocument, options);
      }
export function useDiscoverNewItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DiscoverNewItemsQuery, DiscoverNewItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DiscoverNewItemsQuery, DiscoverNewItemsQueryVariables>(DiscoverNewItemsDocument, options);
        }
export type DiscoverNewItemsQueryHookResult = ReturnType<typeof useDiscoverNewItemsQuery>;
export type DiscoverNewItemsLazyQueryHookResult = ReturnType<typeof useDiscoverNewItemsLazyQuery>;
export type DiscoverNewItemsQueryResult = Apollo.QueryResult<DiscoverNewItemsQuery, DiscoverNewItemsQueryVariables>;
export const DiscoverItemsForYouDocument = gql`
    query discoverItemsForYou($first: Int!, $cursor: String, $withCreatedBy: Boolean!) {
  discoverItemsForYou(input: {first: $first, cursor: $cursor}) {
    pageInfo {
      nextCursor
    }
    items {
      ...WorkspaceItemGridFields
      createdBy @include(if: $withCreatedBy) {
        id
        handle
        displayName
        photoURL
      }
    }
  }
}
    ${WorkspaceItemGridFieldsFragmentDoc}`;

/**
 * __useDiscoverItemsForYouQuery__
 *
 * To run a query within a React component, call `useDiscoverItemsForYouQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscoverItemsForYouQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscoverItemsForYouQuery({
 *   variables: {
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *      withCreatedBy: // value for 'withCreatedBy'
 *   },
 * });
 */
export function useDiscoverItemsForYouQuery(baseOptions: Apollo.QueryHookOptions<DiscoverItemsForYouQuery, DiscoverItemsForYouQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DiscoverItemsForYouQuery, DiscoverItemsForYouQueryVariables>(DiscoverItemsForYouDocument, options);
      }
export function useDiscoverItemsForYouLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DiscoverItemsForYouQuery, DiscoverItemsForYouQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DiscoverItemsForYouQuery, DiscoverItemsForYouQueryVariables>(DiscoverItemsForYouDocument, options);
        }
export type DiscoverItemsForYouQueryHookResult = ReturnType<typeof useDiscoverItemsForYouQuery>;
export type DiscoverItemsForYouLazyQueryHookResult = ReturnType<typeof useDiscoverItemsForYouLazyQuery>;
export type DiscoverItemsForYouQueryResult = Apollo.QueryResult<DiscoverItemsForYouQuery, DiscoverItemsForYouQueryVariables>;
export const DiscoverTopContributorsDocument = gql`
    query discoverTopContributors($first: Int!) {
  discoverTopContributors(input: {first: $first}) {
    id
    uid
    firstName
    lastName
    displayName
    handle
    discoverExportCount
    publicItemsCount
    photoURL
    coverImageUrl
    rank
  }
}
    `;

/**
 * __useDiscoverTopContributorsQuery__
 *
 * To run a query within a React component, call `useDiscoverTopContributorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscoverTopContributorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscoverTopContributorsQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useDiscoverTopContributorsQuery(baseOptions: Apollo.QueryHookOptions<DiscoverTopContributorsQuery, DiscoverTopContributorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DiscoverTopContributorsQuery, DiscoverTopContributorsQueryVariables>(DiscoverTopContributorsDocument, options);
      }
export function useDiscoverTopContributorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DiscoverTopContributorsQuery, DiscoverTopContributorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DiscoverTopContributorsQuery, DiscoverTopContributorsQueryVariables>(DiscoverTopContributorsDocument, options);
        }
export type DiscoverTopContributorsQueryHookResult = ReturnType<typeof useDiscoverTopContributorsQuery>;
export type DiscoverTopContributorsLazyQueryHookResult = ReturnType<typeof useDiscoverTopContributorsLazyQuery>;
export type DiscoverTopContributorsQueryResult = Apollo.QueryResult<DiscoverTopContributorsQuery, DiscoverTopContributorsQueryVariables>;
export const DiscoverSearchCombosDocument = gql`
    query discoverSearchCombos($count: Int!) {
  discoverSearchCombos(input: {count: $count}) {
    id
    description
    searchCombo {
      ... on SearchComboTag {
        tagId
        description
      }
      ... on SearchComboFace {
        face
        description
      }
      ... on SearchComboColor {
        color
        description
      }
      ... on SearchComboMediaType {
        mediaType
        description
      }
    }
  }
}
    `;

/**
 * __useDiscoverSearchCombosQuery__
 *
 * To run a query within a React component, call `useDiscoverSearchCombosQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscoverSearchCombosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscoverSearchCombosQuery({
 *   variables: {
 *      count: // value for 'count'
 *   },
 * });
 */
export function useDiscoverSearchCombosQuery(baseOptions: Apollo.QueryHookOptions<DiscoverSearchCombosQuery, DiscoverSearchCombosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DiscoverSearchCombosQuery, DiscoverSearchCombosQueryVariables>(DiscoverSearchCombosDocument, options);
      }
export function useDiscoverSearchCombosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DiscoverSearchCombosQuery, DiscoverSearchCombosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DiscoverSearchCombosQuery, DiscoverSearchCombosQueryVariables>(DiscoverSearchCombosDocument, options);
        }
export type DiscoverSearchCombosQueryHookResult = ReturnType<typeof useDiscoverSearchCombosQuery>;
export type DiscoverSearchCombosLazyQueryHookResult = ReturnType<typeof useDiscoverSearchCombosLazyQuery>;
export type DiscoverSearchCombosQueryResult = Apollo.QueryResult<DiscoverSearchCombosQuery, DiscoverSearchCombosQueryVariables>;
export const DiscoverIdeasForRecentBoardsDocument = gql`
    query discoverIdeasForRecentBoards($workspaceId: String!, $first: Int!, $cursor: String) {
  discoverIdeasForRecentBoards(
    input: {workspaceId: $workspaceId, first: $first, cursor: $cursor}
  ) {
    boardId
    projectId
    title
    items {
      ...WorkspaceItemGridFields
    }
    pageInfo {
      nextCursor
    }
  }
}
    ${WorkspaceItemGridFieldsFragmentDoc}`;

/**
 * __useDiscoverIdeasForRecentBoardsQuery__
 *
 * To run a query within a React component, call `useDiscoverIdeasForRecentBoardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscoverIdeasForRecentBoardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscoverIdeasForRecentBoardsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useDiscoverIdeasForRecentBoardsQuery(baseOptions: Apollo.QueryHookOptions<DiscoverIdeasForRecentBoardsQuery, DiscoverIdeasForRecentBoardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DiscoverIdeasForRecentBoardsQuery, DiscoverIdeasForRecentBoardsQueryVariables>(DiscoverIdeasForRecentBoardsDocument, options);
      }
export function useDiscoverIdeasForRecentBoardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DiscoverIdeasForRecentBoardsQuery, DiscoverIdeasForRecentBoardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DiscoverIdeasForRecentBoardsQuery, DiscoverIdeasForRecentBoardsQueryVariables>(DiscoverIdeasForRecentBoardsDocument, options);
        }
export type DiscoverIdeasForRecentBoardsQueryHookResult = ReturnType<typeof useDiscoverIdeasForRecentBoardsQuery>;
export type DiscoverIdeasForRecentBoardsLazyQueryHookResult = ReturnType<typeof useDiscoverIdeasForRecentBoardsLazyQuery>;
export type DiscoverIdeasForRecentBoardsQueryResult = Apollo.QueryResult<DiscoverIdeasForRecentBoardsQuery, DiscoverIdeasForRecentBoardsQueryVariables>;
export const DiscoverIdeasForBoardDocument = gql`
    query discoverIdeasForBoard($boardId: String!, $first: Int!, $cursor: String) {
  discoverIdeasForBoard(
    input: {boardId: $boardId, first: $first, cursor: $cursor}
  ) {
    boardId
    projectId
    title
    items {
      ...WorkspaceItemGridFields
    }
    pageInfo {
      nextCursor
    }
  }
}
    ${WorkspaceItemGridFieldsFragmentDoc}`;

/**
 * __useDiscoverIdeasForBoardQuery__
 *
 * To run a query within a React component, call `useDiscoverIdeasForBoardQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscoverIdeasForBoardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscoverIdeasForBoardQuery({
 *   variables: {
 *      boardId: // value for 'boardId'
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useDiscoverIdeasForBoardQuery(baseOptions: Apollo.QueryHookOptions<DiscoverIdeasForBoardQuery, DiscoverIdeasForBoardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DiscoverIdeasForBoardQuery, DiscoverIdeasForBoardQueryVariables>(DiscoverIdeasForBoardDocument, options);
      }
export function useDiscoverIdeasForBoardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DiscoverIdeasForBoardQuery, DiscoverIdeasForBoardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DiscoverIdeasForBoardQuery, DiscoverIdeasForBoardQueryVariables>(DiscoverIdeasForBoardDocument, options);
        }
export type DiscoverIdeasForBoardQueryHookResult = ReturnType<typeof useDiscoverIdeasForBoardQuery>;
export type DiscoverIdeasForBoardLazyQueryHookResult = ReturnType<typeof useDiscoverIdeasForBoardLazyQuery>;
export type DiscoverIdeasForBoardQueryResult = Apollo.QueryResult<DiscoverIdeasForBoardQuery, DiscoverIdeasForBoardQueryVariables>;
export const CreateEditSessionDocument = gql`
    mutation createEditSession($input: CreateEditSessionInput!) {
  createEditSession(input: $input) {
    id
    status
    createdAt
    updatedAt
    members {
      userId
      role
    }
    context {
      workspaceId
    }
    startValues {
      baseImageUrl
    }
  }
}
    `;
export type CreateEditSessionMutationFn = Apollo.MutationFunction<CreateEditSessionMutation, CreateEditSessionMutationVariables>;

/**
 * __useCreateEditSessionMutation__
 *
 * To run a mutation, you first call `useCreateEditSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEditSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEditSessionMutation, { data, loading, error }] = useCreateEditSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEditSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateEditSessionMutation, CreateEditSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEditSessionMutation, CreateEditSessionMutationVariables>(CreateEditSessionDocument, options);
      }
export type CreateEditSessionMutationHookResult = ReturnType<typeof useCreateEditSessionMutation>;
export type CreateEditSessionMutationResult = Apollo.MutationResult<CreateEditSessionMutation>;
export type CreateEditSessionMutationOptions = Apollo.BaseMutationOptions<CreateEditSessionMutation, CreateEditSessionMutationVariables>;
export const AddEditSessionPowerUpDocument = gql`
    mutation addEditSessionPowerUp($input: AddEditSessionPowerUpInput!) {
  addEditSessionPowerUp(input: $input) {
    result
    message
  }
}
    `;
export type AddEditSessionPowerUpMutationFn = Apollo.MutationFunction<AddEditSessionPowerUpMutation, AddEditSessionPowerUpMutationVariables>;

/**
 * __useAddEditSessionPowerUpMutation__
 *
 * To run a mutation, you first call `useAddEditSessionPowerUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEditSessionPowerUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEditSessionPowerUpMutation, { data, loading, error }] = useAddEditSessionPowerUpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddEditSessionPowerUpMutation(baseOptions?: Apollo.MutationHookOptions<AddEditSessionPowerUpMutation, AddEditSessionPowerUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddEditSessionPowerUpMutation, AddEditSessionPowerUpMutationVariables>(AddEditSessionPowerUpDocument, options);
      }
export type AddEditSessionPowerUpMutationHookResult = ReturnType<typeof useAddEditSessionPowerUpMutation>;
export type AddEditSessionPowerUpMutationResult = Apollo.MutationResult<AddEditSessionPowerUpMutation>;
export type AddEditSessionPowerUpMutationOptions = Apollo.BaseMutationOptions<AddEditSessionPowerUpMutation, AddEditSessionPowerUpMutationVariables>;
export const RemoveEditSessionPowerUpDocument = gql`
    mutation removeEditSessionPowerUp($input: RemoveEditSessionPowerUpInput!) {
  removeEditSessionPowerUp(input: $input) {
    result
    message
  }
}
    `;
export type RemoveEditSessionPowerUpMutationFn = Apollo.MutationFunction<RemoveEditSessionPowerUpMutation, RemoveEditSessionPowerUpMutationVariables>;

/**
 * __useRemoveEditSessionPowerUpMutation__
 *
 * To run a mutation, you first call `useRemoveEditSessionPowerUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEditSessionPowerUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEditSessionPowerUpMutation, { data, loading, error }] = useRemoveEditSessionPowerUpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveEditSessionPowerUpMutation(baseOptions?: Apollo.MutationHookOptions<RemoveEditSessionPowerUpMutation, RemoveEditSessionPowerUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveEditSessionPowerUpMutation, RemoveEditSessionPowerUpMutationVariables>(RemoveEditSessionPowerUpDocument, options);
      }
export type RemoveEditSessionPowerUpMutationHookResult = ReturnType<typeof useRemoveEditSessionPowerUpMutation>;
export type RemoveEditSessionPowerUpMutationResult = Apollo.MutationResult<RemoveEditSessionPowerUpMutation>;
export type RemoveEditSessionPowerUpMutationOptions = Apollo.BaseMutationOptions<RemoveEditSessionPowerUpMutation, RemoveEditSessionPowerUpMutationVariables>;
export const EditSessionDocument = gql`
    query editSession($id: ID!) {
  editSession(editSessionId: $id) {
    id
    status
    createdAt
    updatedAt
    members {
      userId
      role
    }
    context {
      workspaceId
      backUrl
    }
    startValues {
      baseImageUrl
    }
    powerUp {
      isPowered
      poweredBy
      updatedAt
      createdAt
      enrichedPowerUpMembers {
        uid
        name
        imageUrl
      }
    }
  }
}
    `;

/**
 * __useEditSessionQuery__
 *
 * To run a query within a React component, call `useEditSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditSessionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditSessionQuery(baseOptions: Apollo.QueryHookOptions<EditSessionQuery, EditSessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditSessionQuery, EditSessionQueryVariables>(EditSessionDocument, options);
      }
export function useEditSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditSessionQuery, EditSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditSessionQuery, EditSessionQueryVariables>(EditSessionDocument, options);
        }
export type EditSessionQueryHookResult = ReturnType<typeof useEditSessionQuery>;
export type EditSessionLazyQueryHookResult = ReturnType<typeof useEditSessionLazyQuery>;
export type EditSessionQueryResult = Apollo.QueryResult<EditSessionQuery, EditSessionQueryVariables>;
export const UserCounterDocument = gql`
    query userCounter($id: ID!) {
  userEditSessionCounter(userId: $id)
}
    `;

/**
 * __useUserCounterQuery__
 *
 * To run a query within a React component, call `useUserCounterQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCounterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCounterQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserCounterQuery(baseOptions: Apollo.QueryHookOptions<UserCounterQuery, UserCounterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserCounterQuery, UserCounterQueryVariables>(UserCounterDocument, options);
      }
export function useUserCounterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserCounterQuery, UserCounterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserCounterQuery, UserCounterQueryVariables>(UserCounterDocument, options);
        }
export type UserCounterQueryHookResult = ReturnType<typeof useUserCounterQuery>;
export type UserCounterLazyQueryHookResult = ReturnType<typeof useUserCounterLazyQuery>;
export type UserCounterQueryResult = Apollo.QueryResult<UserCounterQuery, UserCounterQueryVariables>;
export const WorkspaceCounterDocument = gql`
    query workspaceCounter($id: ID!) {
  workspaceEditSessionCounter(workspaceId: $id)
}
    `;

/**
 * __useWorkspaceCounterQuery__
 *
 * To run a query within a React component, call `useWorkspaceCounterQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceCounterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceCounterQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWorkspaceCounterQuery(baseOptions: Apollo.QueryHookOptions<WorkspaceCounterQuery, WorkspaceCounterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkspaceCounterQuery, WorkspaceCounterQueryVariables>(WorkspaceCounterDocument, options);
      }
export function useWorkspaceCounterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkspaceCounterQuery, WorkspaceCounterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkspaceCounterQuery, WorkspaceCounterQueryVariables>(WorkspaceCounterDocument, options);
        }
export type WorkspaceCounterQueryHookResult = ReturnType<typeof useWorkspaceCounterQuery>;
export type WorkspaceCounterLazyQueryHookResult = ReturnType<typeof useWorkspaceCounterLazyQuery>;
export type WorkspaceCounterQueryResult = Apollo.QueryResult<WorkspaceCounterQuery, WorkspaceCounterQueryVariables>;
export const EditSessionsDocument = gql`
    query editSessions($input: EditSessionsInput!) {
  previousEditSessions(input: $input) {
    pageInfo {
      nextCursor
    }
    editSessions {
      ...EditSessionFragment
    }
  }
  liveEditSessions: editSessions(input: $input) {
    pageInfo {
      nextCursor
    }
    editSessions {
      ...EditSessionFragment
    }
  }
}
    ${EditSessionFragmentFragmentDoc}`;

/**
 * __useEditSessionsQuery__
 *
 * To run a query within a React component, call `useEditSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditSessionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditSessionsQuery(baseOptions: Apollo.QueryHookOptions<EditSessionsQuery, EditSessionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditSessionsQuery, EditSessionsQueryVariables>(EditSessionsDocument, options);
      }
export function useEditSessionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditSessionsQuery, EditSessionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditSessionsQuery, EditSessionsQueryVariables>(EditSessionsDocument, options);
        }
export type EditSessionsQueryHookResult = ReturnType<typeof useEditSessionsQuery>;
export type EditSessionsLazyQueryHookResult = ReturnType<typeof useEditSessionsLazyQuery>;
export type EditSessionsQueryResult = Apollo.QueryResult<EditSessionsQuery, EditSessionsQueryVariables>;
export const EditSessionInviteDocument = gql`
    query editSessionInvite($id: ID!) {
  editSessionInvite(editSessionInviteId: $id) {
    id
    editSessionId
    status
    message
    createdAt
    updatedAt
    sender {
      email
      name
      userId
    }
    receiver {
      email
      name
      userId
    }
  }
}
    `;

/**
 * __useEditSessionInviteQuery__
 *
 * To run a query within a React component, call `useEditSessionInviteQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditSessionInviteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditSessionInviteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditSessionInviteQuery(baseOptions: Apollo.QueryHookOptions<EditSessionInviteQuery, EditSessionInviteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditSessionInviteQuery, EditSessionInviteQueryVariables>(EditSessionInviteDocument, options);
      }
export function useEditSessionInviteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditSessionInviteQuery, EditSessionInviteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditSessionInviteQuery, EditSessionInviteQueryVariables>(EditSessionInviteDocument, options);
        }
export type EditSessionInviteQueryHookResult = ReturnType<typeof useEditSessionInviteQuery>;
export type EditSessionInviteLazyQueryHookResult = ReturnType<typeof useEditSessionInviteLazyQuery>;
export type EditSessionInviteQueryResult = Apollo.QueryResult<EditSessionInviteQuery, EditSessionInviteQueryVariables>;
export const SendEditSessionInviteDocument = gql`
    mutation sendEditSessionInvite($input: SendEditSessionInviteInput!) {
  sendEditSessionInvite(input: $input) {
    id
    editSessionId
    status
    message
    createdAt
    updatedAt
    sender {
      email
      name
      userId
    }
    receiver {
      email
      name
      userId
    }
  }
}
    `;
export type SendEditSessionInviteMutationFn = Apollo.MutationFunction<SendEditSessionInviteMutation, SendEditSessionInviteMutationVariables>;

/**
 * __useSendEditSessionInviteMutation__
 *
 * To run a mutation, you first call `useSendEditSessionInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEditSessionInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEditSessionInviteMutation, { data, loading, error }] = useSendEditSessionInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendEditSessionInviteMutation(baseOptions?: Apollo.MutationHookOptions<SendEditSessionInviteMutation, SendEditSessionInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendEditSessionInviteMutation, SendEditSessionInviteMutationVariables>(SendEditSessionInviteDocument, options);
      }
export type SendEditSessionInviteMutationHookResult = ReturnType<typeof useSendEditSessionInviteMutation>;
export type SendEditSessionInviteMutationResult = Apollo.MutationResult<SendEditSessionInviteMutation>;
export type SendEditSessionInviteMutationOptions = Apollo.BaseMutationOptions<SendEditSessionInviteMutation, SendEditSessionInviteMutationVariables>;
export const RespondToEditSessionInviteDocument = gql`
    mutation respondToEditSessionInvite($input: RespondToEditSessionInviteInput!) {
  respondToEditSessionInvite(input: $input) {
    id
    editSessionId
    status
    message
    createdAt
    updatedAt
    sender {
      email
      name
      userId
    }
    receiver {
      email
      name
      userId
    }
  }
}
    `;
export type RespondToEditSessionInviteMutationFn = Apollo.MutationFunction<RespondToEditSessionInviteMutation, RespondToEditSessionInviteMutationVariables>;

/**
 * __useRespondToEditSessionInviteMutation__
 *
 * To run a mutation, you first call `useRespondToEditSessionInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRespondToEditSessionInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [respondToEditSessionInviteMutation, { data, loading, error }] = useRespondToEditSessionInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRespondToEditSessionInviteMutation(baseOptions?: Apollo.MutationHookOptions<RespondToEditSessionInviteMutation, RespondToEditSessionInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RespondToEditSessionInviteMutation, RespondToEditSessionInviteMutationVariables>(RespondToEditSessionInviteDocument, options);
      }
export type RespondToEditSessionInviteMutationHookResult = ReturnType<typeof useRespondToEditSessionInviteMutation>;
export type RespondToEditSessionInviteMutationResult = Apollo.MutationResult<RespondToEditSessionInviteMutation>;
export type RespondToEditSessionInviteMutationOptions = Apollo.BaseMutationOptions<RespondToEditSessionInviteMutation, RespondToEditSessionInviteMutationVariables>;
export const AddGoogleAnalyticsIdFromWaitingListIdDocument = gql`
    mutation AddGoogleAnalyticsIdFromWaitingListId($waitingListId: String!) {
  addGoogleAnalyticsIdFromWaitingListId(waitingListId: $waitingListId) {
    id
  }
}
    `;
export type AddGoogleAnalyticsIdFromWaitingListIdMutationFn = Apollo.MutationFunction<AddGoogleAnalyticsIdFromWaitingListIdMutation, AddGoogleAnalyticsIdFromWaitingListIdMutationVariables>;

/**
 * __useAddGoogleAnalyticsIdFromWaitingListIdMutation__
 *
 * To run a mutation, you first call `useAddGoogleAnalyticsIdFromWaitingListIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGoogleAnalyticsIdFromWaitingListIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGoogleAnalyticsIdFromWaitingListIdMutation, { data, loading, error }] = useAddGoogleAnalyticsIdFromWaitingListIdMutation({
 *   variables: {
 *      waitingListId: // value for 'waitingListId'
 *   },
 * });
 */
export function useAddGoogleAnalyticsIdFromWaitingListIdMutation(baseOptions?: Apollo.MutationHookOptions<AddGoogleAnalyticsIdFromWaitingListIdMutation, AddGoogleAnalyticsIdFromWaitingListIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddGoogleAnalyticsIdFromWaitingListIdMutation, AddGoogleAnalyticsIdFromWaitingListIdMutationVariables>(AddGoogleAnalyticsIdFromWaitingListIdDocument, options);
      }
export type AddGoogleAnalyticsIdFromWaitingListIdMutationHookResult = ReturnType<typeof useAddGoogleAnalyticsIdFromWaitingListIdMutation>;
export type AddGoogleAnalyticsIdFromWaitingListIdMutationResult = Apollo.MutationResult<AddGoogleAnalyticsIdFromWaitingListIdMutation>;
export type AddGoogleAnalyticsIdFromWaitingListIdMutationOptions = Apollo.BaseMutationOptions<AddGoogleAnalyticsIdFromWaitingListIdMutation, AddGoogleAnalyticsIdFromWaitingListIdMutationVariables>;
export const AddUserIdToGoogleAnalyticsIdDocument = gql`
    mutation AddUserIdToGoogleAnalyticsId($userId: String!, $waitingListId: String) {
  addUserIdToGoogleAnalyticsId(
    input: {userId: $userId, waitingListId: $waitingListId}
  ) {
    id
  }
}
    `;
export type AddUserIdToGoogleAnalyticsIdMutationFn = Apollo.MutationFunction<AddUserIdToGoogleAnalyticsIdMutation, AddUserIdToGoogleAnalyticsIdMutationVariables>;

/**
 * __useAddUserIdToGoogleAnalyticsIdMutation__
 *
 * To run a mutation, you first call `useAddUserIdToGoogleAnalyticsIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserIdToGoogleAnalyticsIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserIdToGoogleAnalyticsIdMutation, { data, loading, error }] = useAddUserIdToGoogleAnalyticsIdMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      waitingListId: // value for 'waitingListId'
 *   },
 * });
 */
export function useAddUserIdToGoogleAnalyticsIdMutation(baseOptions?: Apollo.MutationHookOptions<AddUserIdToGoogleAnalyticsIdMutation, AddUserIdToGoogleAnalyticsIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserIdToGoogleAnalyticsIdMutation, AddUserIdToGoogleAnalyticsIdMutationVariables>(AddUserIdToGoogleAnalyticsIdDocument, options);
      }
export type AddUserIdToGoogleAnalyticsIdMutationHookResult = ReturnType<typeof useAddUserIdToGoogleAnalyticsIdMutation>;
export type AddUserIdToGoogleAnalyticsIdMutationResult = Apollo.MutationResult<AddUserIdToGoogleAnalyticsIdMutation>;
export type AddUserIdToGoogleAnalyticsIdMutationOptions = Apollo.BaseMutationOptions<AddUserIdToGoogleAnalyticsIdMutation, AddUserIdToGoogleAnalyticsIdMutationVariables>;
export const SendKiveInvitationDocument = gql`
    mutation sendKiveInvitation($email: String!, $vimeoId: String, $nominationPanelTrackingId: String) {
  sendKiveInvitation(
    input: {email: $email, vimeoId: $vimeoId, nominationPanelTrackingId: $nominationPanelTrackingId}
  ) {
    status
  }
}
    `;
export type SendKiveInvitationMutationFn = Apollo.MutationFunction<SendKiveInvitationMutation, SendKiveInvitationMutationVariables>;

/**
 * __useSendKiveInvitationMutation__
 *
 * To run a mutation, you first call `useSendKiveInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendKiveInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendKiveInvitationMutation, { data, loading, error }] = useSendKiveInvitationMutation({
 *   variables: {
 *      email: // value for 'email'
 *      vimeoId: // value for 'vimeoId'
 *      nominationPanelTrackingId: // value for 'nominationPanelTrackingId'
 *   },
 * });
 */
export function useSendKiveInvitationMutation(baseOptions?: Apollo.MutationHookOptions<SendKiveInvitationMutation, SendKiveInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendKiveInvitationMutation, SendKiveInvitationMutationVariables>(SendKiveInvitationDocument, options);
      }
export type SendKiveInvitationMutationHookResult = ReturnType<typeof useSendKiveInvitationMutation>;
export type SendKiveInvitationMutationResult = Apollo.MutationResult<SendKiveInvitationMutation>;
export type SendKiveInvitationMutationOptions = Apollo.BaseMutationOptions<SendKiveInvitationMutation, SendKiveInvitationMutationVariables>;
export const Admin_HideFromDiscoveryDocument = gql`
    mutation admin_hideFromDiscovery($id: String!, $workspaceId: String!) {
  admin_hideFromDiscovery(input: {id: $id, workspaceId: $workspaceId}) {
    status
  }
}
    `;
export type Admin_HideFromDiscoveryMutationFn = Apollo.MutationFunction<Admin_HideFromDiscoveryMutation, Admin_HideFromDiscoveryMutationVariables>;

/**
 * __useAdmin_HideFromDiscoveryMutation__
 *
 * To run a mutation, you first call `useAdmin_HideFromDiscoveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdmin_HideFromDiscoveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminHideFromDiscoveryMutation, { data, loading, error }] = useAdmin_HideFromDiscoveryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useAdmin_HideFromDiscoveryMutation(baseOptions?: Apollo.MutationHookOptions<Admin_HideFromDiscoveryMutation, Admin_HideFromDiscoveryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Admin_HideFromDiscoveryMutation, Admin_HideFromDiscoveryMutationVariables>(Admin_HideFromDiscoveryDocument, options);
      }
export type Admin_HideFromDiscoveryMutationHookResult = ReturnType<typeof useAdmin_HideFromDiscoveryMutation>;
export type Admin_HideFromDiscoveryMutationResult = Apollo.MutationResult<Admin_HideFromDiscoveryMutation>;
export type Admin_HideFromDiscoveryMutationOptions = Apollo.BaseMutationOptions<Admin_HideFromDiscoveryMutation, Admin_HideFromDiscoveryMutationVariables>;
export const ItemCommentsDocument = gql`
    query itemComments($parentId: String!, $boardId: String, $first: Int!, $cursor: String) {
  itemComments(
    input: {parentId: $parentId, boardId: $boardId, first: $first, cursor: $cursor}
  ) {
    pageInfo {
      nextCursor
    }
    itemComments {
      ...ItemCommentBasicFields
    }
  }
}
    ${ItemCommentBasicFieldsFragmentDoc}`;

/**
 * __useItemCommentsQuery__
 *
 * To run a query within a React component, call `useItemCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemCommentsQuery({
 *   variables: {
 *      parentId: // value for 'parentId'
 *      boardId: // value for 'boardId'
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useItemCommentsQuery(baseOptions: Apollo.QueryHookOptions<ItemCommentsQuery, ItemCommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ItemCommentsQuery, ItemCommentsQueryVariables>(ItemCommentsDocument, options);
      }
export function useItemCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ItemCommentsQuery, ItemCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ItemCommentsQuery, ItemCommentsQueryVariables>(ItemCommentsDocument, options);
        }
export type ItemCommentsQueryHookResult = ReturnType<typeof useItemCommentsQuery>;
export type ItemCommentsLazyQueryHookResult = ReturnType<typeof useItemCommentsLazyQuery>;
export type ItemCommentsQueryResult = Apollo.QueryResult<ItemCommentsQuery, ItemCommentsQueryVariables>;
export const AddItemCommentDocument = gql`
    mutation addItemComment($text: String!, $parentId: String!, $boardId: String) {
  addItemComment(input: {text: $text, parentId: $parentId, boardId: $boardId}) {
    ...ItemCommentBasicFields
  }
}
    ${ItemCommentBasicFieldsFragmentDoc}`;
export type AddItemCommentMutationFn = Apollo.MutationFunction<AddItemCommentMutation, AddItemCommentMutationVariables>;

/**
 * __useAddItemCommentMutation__
 *
 * To run a mutation, you first call `useAddItemCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddItemCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addItemCommentMutation, { data, loading, error }] = useAddItemCommentMutation({
 *   variables: {
 *      text: // value for 'text'
 *      parentId: // value for 'parentId'
 *      boardId: // value for 'boardId'
 *   },
 * });
 */
export function useAddItemCommentMutation(baseOptions?: Apollo.MutationHookOptions<AddItemCommentMutation, AddItemCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddItemCommentMutation, AddItemCommentMutationVariables>(AddItemCommentDocument, options);
      }
export type AddItemCommentMutationHookResult = ReturnType<typeof useAddItemCommentMutation>;
export type AddItemCommentMutationResult = Apollo.MutationResult<AddItemCommentMutation>;
export type AddItemCommentMutationOptions = Apollo.BaseMutationOptions<AddItemCommentMutation, AddItemCommentMutationVariables>;
export const RemoveItemCommentDocument = gql`
    mutation removeItemComment($itemCommentId: String!, $boardId: String) {
  removeItemComment(input: {itemCommentId: $itemCommentId, boardId: $boardId}) {
    status
  }
}
    `;
export type RemoveItemCommentMutationFn = Apollo.MutationFunction<RemoveItemCommentMutation, RemoveItemCommentMutationVariables>;

/**
 * __useRemoveItemCommentMutation__
 *
 * To run a mutation, you first call `useRemoveItemCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveItemCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeItemCommentMutation, { data, loading, error }] = useRemoveItemCommentMutation({
 *   variables: {
 *      itemCommentId: // value for 'itemCommentId'
 *      boardId: // value for 'boardId'
 *   },
 * });
 */
export function useRemoveItemCommentMutation(baseOptions?: Apollo.MutationHookOptions<RemoveItemCommentMutation, RemoveItemCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveItemCommentMutation, RemoveItemCommentMutationVariables>(RemoveItemCommentDocument, options);
      }
export type RemoveItemCommentMutationHookResult = ReturnType<typeof useRemoveItemCommentMutation>;
export type RemoveItemCommentMutationResult = Apollo.MutationResult<RemoveItemCommentMutation>;
export type RemoveItemCommentMutationOptions = Apollo.BaseMutationOptions<RemoveItemCommentMutation, RemoveItemCommentMutationVariables>;
export const ValidateKiveAccessDocument = gql`
    query validateKiveAccess($email: String!, $referralId: String) {
  validateKiveAccess(input: {email: $email, referralId: $referralId}) {
    hasAccess
    status
    context {
      workspaceId
      invitationId
      waitingListId
      hasCompletedWaitingListForm
      userName
    }
  }
}
    `;

/**
 * __useValidateKiveAccessQuery__
 *
 * To run a query within a React component, call `useValidateKiveAccessQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateKiveAccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateKiveAccessQuery({
 *   variables: {
 *      email: // value for 'email'
 *      referralId: // value for 'referralId'
 *   },
 * });
 */
export function useValidateKiveAccessQuery(baseOptions: Apollo.QueryHookOptions<ValidateKiveAccessQuery, ValidateKiveAccessQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateKiveAccessQuery, ValidateKiveAccessQueryVariables>(ValidateKiveAccessDocument, options);
      }
export function useValidateKiveAccessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateKiveAccessQuery, ValidateKiveAccessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateKiveAccessQuery, ValidateKiveAccessQueryVariables>(ValidateKiveAccessDocument, options);
        }
export type ValidateKiveAccessQueryHookResult = ReturnType<typeof useValidateKiveAccessQuery>;
export type ValidateKiveAccessLazyQueryHookResult = ReturnType<typeof useValidateKiveAccessLazyQuery>;
export type ValidateKiveAccessQueryResult = Apollo.QueryResult<ValidateKiveAccessQuery, ValidateKiveAccessQueryVariables>;
export const MagicBoardsDocument = gql`
    query magicBoards($workspaceId: String!, $first: Int!, $cursor: String, $freeTextSearch: String) {
  magicBoards(
    input: {workspaceId: $workspaceId, first: $first, cursor: $cursor, freeTextSearch: $freeTextSearch}
  ) {
    pageInfo {
      nextCursor
    }
    magicBoards {
      ...MagicBoardBasicFields
      thumbUrl
    }
  }
}
    ${MagicBoardBasicFieldsFragmentDoc}`;

/**
 * __useMagicBoardsQuery__
 *
 * To run a query within a React component, call `useMagicBoardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMagicBoardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMagicBoardsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *      freeTextSearch: // value for 'freeTextSearch'
 *   },
 * });
 */
export function useMagicBoardsQuery(baseOptions: Apollo.QueryHookOptions<MagicBoardsQuery, MagicBoardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MagicBoardsQuery, MagicBoardsQueryVariables>(MagicBoardsDocument, options);
      }
export function useMagicBoardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MagicBoardsQuery, MagicBoardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MagicBoardsQuery, MagicBoardsQueryVariables>(MagicBoardsDocument, options);
        }
export type MagicBoardsQueryHookResult = ReturnType<typeof useMagicBoardsQuery>;
export type MagicBoardsLazyQueryHookResult = ReturnType<typeof useMagicBoardsLazyQuery>;
export type MagicBoardsQueryResult = Apollo.QueryResult<MagicBoardsQuery, MagicBoardsQueryVariables>;
export const MagicBoardDocument = gql`
    query magicBoard($magicBoardId: String!, $workspaceId: String!) {
  magicBoard(input: {magicBoardId: $magicBoardId, workspaceId: $workspaceId}) {
    ...MagicBoardBasicFields
    coverUrl
  }
}
    ${MagicBoardBasicFieldsFragmentDoc}`;

/**
 * __useMagicBoardQuery__
 *
 * To run a query within a React component, call `useMagicBoardQuery` and pass it any options that fit your needs.
 * When your component renders, `useMagicBoardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMagicBoardQuery({
 *   variables: {
 *      magicBoardId: // value for 'magicBoardId'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useMagicBoardQuery(baseOptions: Apollo.QueryHookOptions<MagicBoardQuery, MagicBoardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MagicBoardQuery, MagicBoardQueryVariables>(MagicBoardDocument, options);
      }
export function useMagicBoardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MagicBoardQuery, MagicBoardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MagicBoardQuery, MagicBoardQueryVariables>(MagicBoardDocument, options);
        }
export type MagicBoardQueryHookResult = ReturnType<typeof useMagicBoardQuery>;
export type MagicBoardLazyQueryHookResult = ReturnType<typeof useMagicBoardLazyQuery>;
export type MagicBoardQueryResult = Apollo.QueryResult<MagicBoardQuery, MagicBoardQueryVariables>;
export const MagicBoardSimpleDocument = gql`
    query magicBoardSimple($magicBoardId: String!, $workspaceId: String!) {
  magicBoard(input: {magicBoardId: $magicBoardId, workspaceId: $workspaceId}) {
    ...MagicBoardBasicFields
    thumbUrl
  }
}
    ${MagicBoardBasicFieldsFragmentDoc}`;

/**
 * __useMagicBoardSimpleQuery__
 *
 * To run a query within a React component, call `useMagicBoardSimpleQuery` and pass it any options that fit your needs.
 * When your component renders, `useMagicBoardSimpleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMagicBoardSimpleQuery({
 *   variables: {
 *      magicBoardId: // value for 'magicBoardId'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useMagicBoardSimpleQuery(baseOptions: Apollo.QueryHookOptions<MagicBoardSimpleQuery, MagicBoardSimpleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MagicBoardSimpleQuery, MagicBoardSimpleQueryVariables>(MagicBoardSimpleDocument, options);
      }
export function useMagicBoardSimpleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MagicBoardSimpleQuery, MagicBoardSimpleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MagicBoardSimpleQuery, MagicBoardSimpleQueryVariables>(MagicBoardSimpleDocument, options);
        }
export type MagicBoardSimpleQueryHookResult = ReturnType<typeof useMagicBoardSimpleQuery>;
export type MagicBoardSimpleLazyQueryHookResult = ReturnType<typeof useMagicBoardSimpleLazyQuery>;
export type MagicBoardSimpleQueryResult = Apollo.QueryResult<MagicBoardSimpleQuery, MagicBoardSimpleQueryVariables>;
export const MagicBoardItemsDocument = gql`
    query magicBoardItems($workspaceId: String!, $magicBoardId: String!, $first: Int!, $cursor: String) {
  magicBoardItems(
    input: {workspaceId: $workspaceId, magicBoardId: $magicBoardId, first: $first, cursor: $cursor}
  ) {
    pageInfo {
      nextCursor
    }
    magicBoardItems {
      ...WorkspaceItemGridFields
    }
  }
}
    ${WorkspaceItemGridFieldsFragmentDoc}`;

/**
 * __useMagicBoardItemsQuery__
 *
 * To run a query within a React component, call `useMagicBoardItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMagicBoardItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMagicBoardItemsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      magicBoardId: // value for 'magicBoardId'
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useMagicBoardItemsQuery(baseOptions: Apollo.QueryHookOptions<MagicBoardItemsQuery, MagicBoardItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MagicBoardItemsQuery, MagicBoardItemsQueryVariables>(MagicBoardItemsDocument, options);
      }
export function useMagicBoardItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MagicBoardItemsQuery, MagicBoardItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MagicBoardItemsQuery, MagicBoardItemsQueryVariables>(MagicBoardItemsDocument, options);
        }
export type MagicBoardItemsQueryHookResult = ReturnType<typeof useMagicBoardItemsQuery>;
export type MagicBoardItemsLazyQueryHookResult = ReturnType<typeof useMagicBoardItemsLazyQuery>;
export type MagicBoardItemsQueryResult = Apollo.QueryResult<MagicBoardItemsQuery, MagicBoardItemsQueryVariables>;
export const MarkNotificationAsReadDocument = gql`
    mutation markNotificationAsRead($notificationId: String!) {
  markNotificationAsRead(notificationId: $notificationId) {
    ...NotificationBasicFields
  }
}
    ${NotificationBasicFieldsFragmentDoc}`;
export type MarkNotificationAsReadMutationFn = Apollo.MutationFunction<MarkNotificationAsReadMutation, MarkNotificationAsReadMutationVariables>;

/**
 * __useMarkNotificationAsReadMutation__
 *
 * To run a mutation, you first call `useMarkNotificationAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationAsReadMutation, { data, loading, error }] = useMarkNotificationAsReadMutation({
 *   variables: {
 *      notificationId: // value for 'notificationId'
 *   },
 * });
 */
export function useMarkNotificationAsReadMutation(baseOptions?: Apollo.MutationHookOptions<MarkNotificationAsReadMutation, MarkNotificationAsReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkNotificationAsReadMutation, MarkNotificationAsReadMutationVariables>(MarkNotificationAsReadDocument, options);
      }
export type MarkNotificationAsReadMutationHookResult = ReturnType<typeof useMarkNotificationAsReadMutation>;
export type MarkNotificationAsReadMutationResult = Apollo.MutationResult<MarkNotificationAsReadMutation>;
export type MarkNotificationAsReadMutationOptions = Apollo.BaseMutationOptions<MarkNotificationAsReadMutation, MarkNotificationAsReadMutationVariables>;
export const MarkAllNotificationsAsReadDocument = gql`
    mutation markAllNotificationsAsRead($workspaceId: String!) {
  markAllNotificationsAsRead(workspaceId: $workspaceId) {
    status
  }
}
    `;
export type MarkAllNotificationsAsReadMutationFn = Apollo.MutationFunction<MarkAllNotificationsAsReadMutation, MarkAllNotificationsAsReadMutationVariables>;

/**
 * __useMarkAllNotificationsAsReadMutation__
 *
 * To run a mutation, you first call `useMarkAllNotificationsAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAllNotificationsAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAllNotificationsAsReadMutation, { data, loading, error }] = useMarkAllNotificationsAsReadMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useMarkAllNotificationsAsReadMutation(baseOptions?: Apollo.MutationHookOptions<MarkAllNotificationsAsReadMutation, MarkAllNotificationsAsReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkAllNotificationsAsReadMutation, MarkAllNotificationsAsReadMutationVariables>(MarkAllNotificationsAsReadDocument, options);
      }
export type MarkAllNotificationsAsReadMutationHookResult = ReturnType<typeof useMarkAllNotificationsAsReadMutation>;
export type MarkAllNotificationsAsReadMutationResult = Apollo.MutationResult<MarkAllNotificationsAsReadMutation>;
export type MarkAllNotificationsAsReadMutationOptions = Apollo.BaseMutationOptions<MarkAllNotificationsAsReadMutation, MarkAllNotificationsAsReadMutationVariables>;
export const WorkspaceNotificationsDocument = gql`
    subscription workspaceNotifications($workspaceId: String!) {
  workspaceNotifications(workspaceId: $workspaceId) {
    ...NotificationBasicFields
  }
}
    ${NotificationBasicFieldsFragmentDoc}`;

/**
 * __useWorkspaceNotificationsSubscription__
 *
 * To run a query within a React component, call `useWorkspaceNotificationsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceNotificationsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceNotificationsSubscription({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useWorkspaceNotificationsSubscription(baseOptions: Apollo.SubscriptionHookOptions<WorkspaceNotificationsSubscription, WorkspaceNotificationsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<WorkspaceNotificationsSubscription, WorkspaceNotificationsSubscriptionVariables>(WorkspaceNotificationsDocument, options);
      }
export type WorkspaceNotificationsSubscriptionHookResult = ReturnType<typeof useWorkspaceNotificationsSubscription>;
export type WorkspaceNotificationsSubscriptionResult = Apollo.SubscriptionResult<WorkspaceNotificationsSubscription>;
export const CreateCancellationSurveyDocument = gql`
    mutation createCancellationSurvey($reasons: [String!]!, $message: String, $canBeContacted: Boolean) {
  createCancellationSurvey(
    input: {reasons: $reasons, message: $message, canBeContacted: $canBeContacted}
  ) {
    status
  }
}
    `;
export type CreateCancellationSurveyMutationFn = Apollo.MutationFunction<CreateCancellationSurveyMutation, CreateCancellationSurveyMutationVariables>;

/**
 * __useCreateCancellationSurveyMutation__
 *
 * To run a mutation, you first call `useCreateCancellationSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCancellationSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCancellationSurveyMutation, { data, loading, error }] = useCreateCancellationSurveyMutation({
 *   variables: {
 *      reasons: // value for 'reasons'
 *      message: // value for 'message'
 *      canBeContacted: // value for 'canBeContacted'
 *   },
 * });
 */
export function useCreateCancellationSurveyMutation(baseOptions?: Apollo.MutationHookOptions<CreateCancellationSurveyMutation, CreateCancellationSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCancellationSurveyMutation, CreateCancellationSurveyMutationVariables>(CreateCancellationSurveyDocument, options);
      }
export type CreateCancellationSurveyMutationHookResult = ReturnType<typeof useCreateCancellationSurveyMutation>;
export type CreateCancellationSurveyMutationResult = Apollo.MutationResult<CreateCancellationSurveyMutation>;
export type CreateCancellationSurveyMutationOptions = Apollo.BaseMutationOptions<CreateCancellationSurveyMutation, CreateCancellationSurveyMutationVariables>;
export const ActivePlansDocument = gql`
    query activePlans {
  plans {
    id
    defaultOption
    status
    order
    limits {
      items
      boardItems
      boards
      enableCollabWorkspaces
      enableLinkSharing
      frameExtractions
      projects
      enableCustomBranding
      vatBilling
      videoSupport
      gifExtraction
      guests
    }
    presentation {
      name
      description
      price
      priceUnit
      intervals {
        monthly {
          price
          priceUnit
          priceId
          priceActual {
            id
            unitAmount
            currency
            product {
              name
              description
            }
            priceValue
            adjustedMonthlyValue
            priceString
            recurring {
              aggregateUsage
              interval
              intervalCount
              usageType
            }
          }
        }
        yearly {
          price
          priceUnit
          priceId
          priceActual {
            id
            unitAmount
            currency
            product {
              name
              description
            }
            priceValue
            adjustedMonthlyValue
            priceString
          }
        }
      }
    }
    features {
      template
      subline
      replace_key
    }
  }
}
    `;

/**
 * __useActivePlansQuery__
 *
 * To run a query within a React component, call `useActivePlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivePlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivePlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useActivePlansQuery(baseOptions?: Apollo.QueryHookOptions<ActivePlansQuery, ActivePlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActivePlansQuery, ActivePlansQueryVariables>(ActivePlansDocument, options);
      }
export function useActivePlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActivePlansQuery, ActivePlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActivePlansQuery, ActivePlansQueryVariables>(ActivePlansDocument, options);
        }
export type ActivePlansQueryHookResult = ReturnType<typeof useActivePlansQuery>;
export type ActivePlansLazyQueryHookResult = ReturnType<typeof useActivePlansLazyQuery>;
export type ActivePlansQueryResult = Apollo.QueryResult<ActivePlansQuery, ActivePlansQueryVariables>;
export const UserProjectsDocument = gql`
    query UserProjects($workspaceId: String!, $first: Int = 500, $accessLevel: ProjectUserRole) {
  projects(
    input: {workspaceId: $workspaceId, first: $first, accessLevel: $accessLevel}
  ) {
    projects {
      ...ProjectBasicFields
      thumbUrls
      createdBy {
        uid
        displayName
        photoURL
      }
    }
  }
}
    ${ProjectBasicFieldsFragmentDoc}`;

/**
 * __useUserProjectsQuery__
 *
 * To run a query within a React component, call `useUserProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProjectsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      first: // value for 'first'
 *      accessLevel: // value for 'accessLevel'
 *   },
 * });
 */
export function useUserProjectsQuery(baseOptions: Apollo.QueryHookOptions<UserProjectsQuery, UserProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserProjectsQuery, UserProjectsQueryVariables>(UserProjectsDocument, options);
      }
export function useUserProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserProjectsQuery, UserProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserProjectsQuery, UserProjectsQueryVariables>(UserProjectsDocument, options);
        }
export type UserProjectsQueryHookResult = ReturnType<typeof useUserProjectsQuery>;
export type UserProjectsLazyQueryHookResult = ReturnType<typeof useUserProjectsLazyQuery>;
export type UserProjectsQueryResult = Apollo.QueryResult<UserProjectsQuery, UserProjectsQueryVariables>;
export const ProjectDocument = gql`
    query project($projectId: String!) {
  project(input: {projectId: $projectId}) {
    ...ProjectBasicFields
  }
}
    ${ProjectBasicFieldsFragmentDoc}`;

/**
 * __useProjectQuery__
 *
 * To run a query within a React component, call `useProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectQuery(baseOptions: Apollo.QueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options);
      }
export function useProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options);
        }
export type ProjectQueryHookResult = ReturnType<typeof useProjectQuery>;
export type ProjectLazyQueryHookResult = ReturnType<typeof useProjectLazyQuery>;
export type ProjectQueryResult = Apollo.QueryResult<ProjectQuery, ProjectQueryVariables>;
export const DeleteProjectDocument = gql`
    mutation deleteProject($projectId: String!) {
  deleteProject(input: {projectId: $projectId}) {
    status
  }
}
    `;
export type DeleteProjectMutationFn = Apollo.MutationFunction<DeleteProjectMutation, DeleteProjectMutationVariables>;

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useDeleteProjectMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProjectMutation, DeleteProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProjectMutation, DeleteProjectMutationVariables>(DeleteProjectDocument, options);
      }
export type DeleteProjectMutationHookResult = ReturnType<typeof useDeleteProjectMutation>;
export type DeleteProjectMutationResult = Apollo.MutationResult<DeleteProjectMutation>;
export type DeleteProjectMutationOptions = Apollo.BaseMutationOptions<DeleteProjectMutation, DeleteProjectMutationVariables>;
export const UpdateProjectTitleDocument = gql`
    mutation updateProjectTitle($projectId: String!, $title: String!) {
  updateProjectTitle(input: {projectId: $projectId, title: $title}) {
    ...ProjectBasicFields
  }
}
    ${ProjectBasicFieldsFragmentDoc}`;
export type UpdateProjectTitleMutationFn = Apollo.MutationFunction<UpdateProjectTitleMutation, UpdateProjectTitleMutationVariables>;

/**
 * __useUpdateProjectTitleMutation__
 *
 * To run a mutation, you first call `useUpdateProjectTitleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectTitleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectTitleMutation, { data, loading, error }] = useUpdateProjectTitleMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useUpdateProjectTitleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectTitleMutation, UpdateProjectTitleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectTitleMutation, UpdateProjectTitleMutationVariables>(UpdateProjectTitleDocument, options);
      }
export type UpdateProjectTitleMutationHookResult = ReturnType<typeof useUpdateProjectTitleMutation>;
export type UpdateProjectTitleMutationResult = Apollo.MutationResult<UpdateProjectTitleMutation>;
export type UpdateProjectTitleMutationOptions = Apollo.BaseMutationOptions<UpdateProjectTitleMutation, UpdateProjectTitleMutationVariables>;
export const UpdateProjectBoardsPriorityDocument = gql`
    mutation updateProjectBoardsPriority($projectId: String!, $payload: [BoardPriorityInput!]!) {
  updateProjectBoardsPriority(input: {projectId: $projectId, payload: $payload}) {
    status
  }
}
    `;
export type UpdateProjectBoardsPriorityMutationFn = Apollo.MutationFunction<UpdateProjectBoardsPriorityMutation, UpdateProjectBoardsPriorityMutationVariables>;

/**
 * __useUpdateProjectBoardsPriorityMutation__
 *
 * To run a mutation, you first call `useUpdateProjectBoardsPriorityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectBoardsPriorityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectBoardsPriorityMutation, { data, loading, error }] = useUpdateProjectBoardsPriorityMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateProjectBoardsPriorityMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectBoardsPriorityMutation, UpdateProjectBoardsPriorityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectBoardsPriorityMutation, UpdateProjectBoardsPriorityMutationVariables>(UpdateProjectBoardsPriorityDocument, options);
      }
export type UpdateProjectBoardsPriorityMutationHookResult = ReturnType<typeof useUpdateProjectBoardsPriorityMutation>;
export type UpdateProjectBoardsPriorityMutationResult = Apollo.MutationResult<UpdateProjectBoardsPriorityMutation>;
export type UpdateProjectBoardsPriorityMutationOptions = Apollo.BaseMutationOptions<UpdateProjectBoardsPriorityMutation, UpdateProjectBoardsPriorityMutationVariables>;
export const UpdateProjectLinkAccessDocument = gql`
    mutation updateProjectLinkAccess($projectId: String!, $projectLinkAccess: ProjectLinkAccess) {
  updateProjectLinkAccess(
    input: {projectId: $projectId, projectLinkAccess: $projectLinkAccess}
  ) {
    ...ProjectBasicFields
  }
}
    ${ProjectBasicFieldsFragmentDoc}`;
export type UpdateProjectLinkAccessMutationFn = Apollo.MutationFunction<UpdateProjectLinkAccessMutation, UpdateProjectLinkAccessMutationVariables>;

/**
 * __useUpdateProjectLinkAccessMutation__
 *
 * To run a mutation, you first call `useUpdateProjectLinkAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectLinkAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectLinkAccessMutation, { data, loading, error }] = useUpdateProjectLinkAccessMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      projectLinkAccess: // value for 'projectLinkAccess'
 *   },
 * });
 */
export function useUpdateProjectLinkAccessMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectLinkAccessMutation, UpdateProjectLinkAccessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectLinkAccessMutation, UpdateProjectLinkAccessMutationVariables>(UpdateProjectLinkAccessDocument, options);
      }
export type UpdateProjectLinkAccessMutationHookResult = ReturnType<typeof useUpdateProjectLinkAccessMutation>;
export type UpdateProjectLinkAccessMutationResult = Apollo.MutationResult<UpdateProjectLinkAccessMutation>;
export type UpdateProjectLinkAccessMutationOptions = Apollo.BaseMutationOptions<UpdateProjectLinkAccessMutation, UpdateProjectLinkAccessMutationVariables>;
export const UpdateProjectWorkspaceAccessDocument = gql`
    mutation updateProjectWorkspaceAccess($projectId: String!, $projectWorkspaceAccess: ProjectWorkspaceAccess!) {
  updateProjectWorkspaceAccess(
    input: {projectId: $projectId, projectWorkspaceAccess: $projectWorkspaceAccess}
  ) {
    ...ProjectBasicFields
  }
}
    ${ProjectBasicFieldsFragmentDoc}`;
export type UpdateProjectWorkspaceAccessMutationFn = Apollo.MutationFunction<UpdateProjectWorkspaceAccessMutation, UpdateProjectWorkspaceAccessMutationVariables>;

/**
 * __useUpdateProjectWorkspaceAccessMutation__
 *
 * To run a mutation, you first call `useUpdateProjectWorkspaceAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectWorkspaceAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectWorkspaceAccessMutation, { data, loading, error }] = useUpdateProjectWorkspaceAccessMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      projectWorkspaceAccess: // value for 'projectWorkspaceAccess'
 *   },
 * });
 */
export function useUpdateProjectWorkspaceAccessMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectWorkspaceAccessMutation, UpdateProjectWorkspaceAccessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectWorkspaceAccessMutation, UpdateProjectWorkspaceAccessMutationVariables>(UpdateProjectWorkspaceAccessDocument, options);
      }
export type UpdateProjectWorkspaceAccessMutationHookResult = ReturnType<typeof useUpdateProjectWorkspaceAccessMutation>;
export type UpdateProjectWorkspaceAccessMutationResult = Apollo.MutationResult<UpdateProjectWorkspaceAccessMutation>;
export type UpdateProjectWorkspaceAccessMutationOptions = Apollo.BaseMutationOptions<UpdateProjectWorkspaceAccessMutation, UpdateProjectWorkspaceAccessMutationVariables>;
export const ItemReactionsDocument = gql`
    query itemReactions($parentId: String!, $boardId: String) {
  itemReactions(input: {parentId: $parentId, boardId: $boardId}) {
    ...ItemReactionGroupFields
  }
}
    ${ItemReactionGroupFieldsFragmentDoc}`;

/**
 * __useItemReactionsQuery__
 *
 * To run a query within a React component, call `useItemReactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemReactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemReactionsQuery({
 *   variables: {
 *      parentId: // value for 'parentId'
 *      boardId: // value for 'boardId'
 *   },
 * });
 */
export function useItemReactionsQuery(baseOptions: Apollo.QueryHookOptions<ItemReactionsQuery, ItemReactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ItemReactionsQuery, ItemReactionsQueryVariables>(ItemReactionsDocument, options);
      }
export function useItemReactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ItemReactionsQuery, ItemReactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ItemReactionsQuery, ItemReactionsQueryVariables>(ItemReactionsDocument, options);
        }
export type ItemReactionsQueryHookResult = ReturnType<typeof useItemReactionsQuery>;
export type ItemReactionsLazyQueryHookResult = ReturnType<typeof useItemReactionsLazyQuery>;
export type ItemReactionsQueryResult = Apollo.QueryResult<ItemReactionsQuery, ItemReactionsQueryVariables>;
export const AddItemReactionDocument = gql`
    mutation addItemReaction($reaction: String!, $parentId: String!, $boardId: String) {
  addItemReaction(
    input: {reaction: $reaction, parentId: $parentId, boardId: $boardId}
  ) {
    ...ItemReactionBasicFields
  }
}
    ${ItemReactionBasicFieldsFragmentDoc}`;
export type AddItemReactionMutationFn = Apollo.MutationFunction<AddItemReactionMutation, AddItemReactionMutationVariables>;

/**
 * __useAddItemReactionMutation__
 *
 * To run a mutation, you first call `useAddItemReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddItemReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addItemReactionMutation, { data, loading, error }] = useAddItemReactionMutation({
 *   variables: {
 *      reaction: // value for 'reaction'
 *      parentId: // value for 'parentId'
 *      boardId: // value for 'boardId'
 *   },
 * });
 */
export function useAddItemReactionMutation(baseOptions?: Apollo.MutationHookOptions<AddItemReactionMutation, AddItemReactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddItemReactionMutation, AddItemReactionMutationVariables>(AddItemReactionDocument, options);
      }
export type AddItemReactionMutationHookResult = ReturnType<typeof useAddItemReactionMutation>;
export type AddItemReactionMutationResult = Apollo.MutationResult<AddItemReactionMutation>;
export type AddItemReactionMutationOptions = Apollo.BaseMutationOptions<AddItemReactionMutation, AddItemReactionMutationVariables>;
export const RemoveItemReactionDocument = gql`
    mutation removeItemReaction($itemReactionId: String!, $boardId: String) {
  removeItemReaction(input: {itemReactionId: $itemReactionId, boardId: $boardId}) {
    status
  }
}
    `;
export type RemoveItemReactionMutationFn = Apollo.MutationFunction<RemoveItemReactionMutation, RemoveItemReactionMutationVariables>;

/**
 * __useRemoveItemReactionMutation__
 *
 * To run a mutation, you first call `useRemoveItemReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveItemReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeItemReactionMutation, { data, loading, error }] = useRemoveItemReactionMutation({
 *   variables: {
 *      itemReactionId: // value for 'itemReactionId'
 *      boardId: // value for 'boardId'
 *   },
 * });
 */
export function useRemoveItemReactionMutation(baseOptions?: Apollo.MutationHookOptions<RemoveItemReactionMutation, RemoveItemReactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveItemReactionMutation, RemoveItemReactionMutationVariables>(RemoveItemReactionDocument, options);
      }
export type RemoveItemReactionMutationHookResult = ReturnType<typeof useRemoveItemReactionMutation>;
export type RemoveItemReactionMutationResult = Apollo.MutationResult<RemoveItemReactionMutation>;
export type RemoveItemReactionMutationOptions = Apollo.BaseMutationOptions<RemoveItemReactionMutation, RemoveItemReactionMutationVariables>;
export const PublicTagsDocument = gql`
    query publicTags($tagIds: [String!]!) {
  publicTags(input: {tagIds: $tagIds}) {
    id
    tagId
    description
    thumbUrl
  }
}
    `;

/**
 * __usePublicTagsQuery__
 *
 * To run a query within a React component, call `usePublicTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicTagsQuery({
 *   variables: {
 *      tagIds: // value for 'tagIds'
 *   },
 * });
 */
export function usePublicTagsQuery(baseOptions: Apollo.QueryHookOptions<PublicTagsQuery, PublicTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicTagsQuery, PublicTagsQueryVariables>(PublicTagsDocument, options);
      }
export function usePublicTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicTagsQuery, PublicTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicTagsQuery, PublicTagsQueryVariables>(PublicTagsDocument, options);
        }
export type PublicTagsQueryHookResult = ReturnType<typeof usePublicTagsQuery>;
export type PublicTagsLazyQueryHookResult = ReturnType<typeof usePublicTagsLazyQuery>;
export type PublicTagsQueryResult = Apollo.QueryResult<PublicTagsQuery, PublicTagsQueryVariables>;
export const TagsForYouDocument = gql`
    query tagsForYou {
  tagsForYou {
    id
    description
    thumbUrl
  }
}
    `;

/**
 * __useTagsForYouQuery__
 *
 * To run a query within a React component, call `useTagsForYouQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsForYouQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsForYouQuery({
 *   variables: {
 *   },
 * });
 */
export function useTagsForYouQuery(baseOptions?: Apollo.QueryHookOptions<TagsForYouQuery, TagsForYouQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TagsForYouQuery, TagsForYouQueryVariables>(TagsForYouDocument, options);
      }
export function useTagsForYouLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagsForYouQuery, TagsForYouQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TagsForYouQuery, TagsForYouQueryVariables>(TagsForYouDocument, options);
        }
export type TagsForYouQueryHookResult = ReturnType<typeof useTagsForYouQuery>;
export type TagsForYouLazyQueryHookResult = ReturnType<typeof useTagsForYouLazyQuery>;
export type TagsForYouQueryResult = Apollo.QueryResult<TagsForYouQuery, TagsForYouQueryVariables>;
export const UploadSessionItemsDocument = gql`
    query uploadSessionItems($workspaceId: String!, $uploadSessionId: String!, $first: Int!, $cursor: String) {
  uploadSessionItems(
    input: {workspaceId: $workspaceId, uploadSessionId: $uploadSessionId, first: $first, cursor: $cursor}
  ) {
    pageInfo {
      nextCursor
    }
    uploadSessionItems {
      ...WorkspaceItemGridFields
    }
  }
}
    ${WorkspaceItemGridFieldsFragmentDoc}`;

/**
 * __useUploadSessionItemsQuery__
 *
 * To run a query within a React component, call `useUploadSessionItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUploadSessionItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUploadSessionItemsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      uploadSessionId: // value for 'uploadSessionId'
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useUploadSessionItemsQuery(baseOptions: Apollo.QueryHookOptions<UploadSessionItemsQuery, UploadSessionItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UploadSessionItemsQuery, UploadSessionItemsQueryVariables>(UploadSessionItemsDocument, options);
      }
export function useUploadSessionItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UploadSessionItemsQuery, UploadSessionItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UploadSessionItemsQuery, UploadSessionItemsQueryVariables>(UploadSessionItemsDocument, options);
        }
export type UploadSessionItemsQueryHookResult = ReturnType<typeof useUploadSessionItemsQuery>;
export type UploadSessionItemsLazyQueryHookResult = ReturnType<typeof useUploadSessionItemsLazyQuery>;
export type UploadSessionItemsQueryResult = Apollo.QueryResult<UploadSessionItemsQuery, UploadSessionItemsQueryVariables>;
export const UploadSessionsFinishedDocument = gql`
    query uploadSessionsFinished($workspaceId: String!, $first: Int!, $cursor: String) {
  uploadSessionsFinished(
    input: {workspaceId: $workspaceId, first: $first, cursor: $cursor}
  ) {
    pageInfo {
      nextCursor
    }
    uploadSessions {
      ...UploadSessionBasicFields
    }
  }
}
    ${UploadSessionBasicFieldsFragmentDoc}`;

/**
 * __useUploadSessionsFinishedQuery__
 *
 * To run a query within a React component, call `useUploadSessionsFinishedQuery` and pass it any options that fit your needs.
 * When your component renders, `useUploadSessionsFinishedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUploadSessionsFinishedQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useUploadSessionsFinishedQuery(baseOptions: Apollo.QueryHookOptions<UploadSessionsFinishedQuery, UploadSessionsFinishedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UploadSessionsFinishedQuery, UploadSessionsFinishedQueryVariables>(UploadSessionsFinishedDocument, options);
      }
export function useUploadSessionsFinishedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UploadSessionsFinishedQuery, UploadSessionsFinishedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UploadSessionsFinishedQuery, UploadSessionsFinishedQueryVariables>(UploadSessionsFinishedDocument, options);
        }
export type UploadSessionsFinishedQueryHookResult = ReturnType<typeof useUploadSessionsFinishedQuery>;
export type UploadSessionsFinishedLazyQueryHookResult = ReturnType<typeof useUploadSessionsFinishedLazyQuery>;
export type UploadSessionsFinishedQueryResult = Apollo.QueryResult<UploadSessionsFinishedQuery, UploadSessionsFinishedQueryVariables>;
export const UserDocument = gql`
    query user($uid: String!) {
  user(uid: $uid) {
    ...UserBasicFields
  }
}
    ${UserBasicFieldsFragmentDoc}`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const CreditsUserDocument = gql`
    query creditsUser($uid: String!) {
  user(uid: $uid) {
    id
    generationRequests {
      availableCredits
      numGenerations
    }
  }
}
    `;

/**
 * __useCreditsUserQuery__
 *
 * To run a query within a React component, call `useCreditsUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditsUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditsUserQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useCreditsUserQuery(baseOptions: Apollo.QueryHookOptions<CreditsUserQuery, CreditsUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreditsUserQuery, CreditsUserQueryVariables>(CreditsUserDocument, options);
      }
export function useCreditsUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreditsUserQuery, CreditsUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreditsUserQuery, CreditsUserQueryVariables>(CreditsUserDocument, options);
        }
export type CreditsUserQueryHookResult = ReturnType<typeof useCreditsUserQuery>;
export type CreditsUserLazyQueryHookResult = ReturnType<typeof useCreditsUserLazyQuery>;
export type CreditsUserQueryResult = Apollo.QueryResult<CreditsUserQuery, CreditsUserQueryVariables>;
export const UserTasksDocument = gql`
    query userTasks {
  userTasks
}
    `;

/**
 * __useUserTasksQuery__
 *
 * To run a query within a React component, call `useUserTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTasksQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserTasksQuery(baseOptions?: Apollo.QueryHookOptions<UserTasksQuery, UserTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserTasksQuery, UserTasksQueryVariables>(UserTasksDocument, options);
      }
export function useUserTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserTasksQuery, UserTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserTasksQuery, UserTasksQueryVariables>(UserTasksDocument, options);
        }
export type UserTasksQueryHookResult = ReturnType<typeof useUserTasksQuery>;
export type UserTasksLazyQueryHookResult = ReturnType<typeof useUserTasksLazyQuery>;
export type UserTasksQueryResult = Apollo.QueryResult<UserTasksQuery, UserTasksQueryVariables>;
export const UpdateUserTasksDocument = gql`
    mutation updateUserTasks($userTasksUpdate: JSONObject!) {
  updateUserTasks(input: $userTasksUpdate)
}
    `;
export type UpdateUserTasksMutationFn = Apollo.MutationFunction<UpdateUserTasksMutation, UpdateUserTasksMutationVariables>;

/**
 * __useUpdateUserTasksMutation__
 *
 * To run a mutation, you first call `useUpdateUserTasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserTasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserTasksMutation, { data, loading, error }] = useUpdateUserTasksMutation({
 *   variables: {
 *      userTasksUpdate: // value for 'userTasksUpdate'
 *   },
 * });
 */
export function useUpdateUserTasksMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserTasksMutation, UpdateUserTasksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserTasksMutation, UpdateUserTasksMutationVariables>(UpdateUserTasksDocument, options);
      }
export type UpdateUserTasksMutationHookResult = ReturnType<typeof useUpdateUserTasksMutation>;
export type UpdateUserTasksMutationResult = Apollo.MutationResult<UpdateUserTasksMutation>;
export type UpdateUserTasksMutationOptions = Apollo.BaseMutationOptions<UpdateUserTasksMutation, UpdateUserTasksMutationVariables>;
export const UserLearningGuidesDocument = gql`
    query userLearningGuides {
  userLearningGuides
}
    `;

/**
 * __useUserLearningGuidesQuery__
 *
 * To run a query within a React component, call `useUserLearningGuidesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserLearningGuidesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserLearningGuidesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserLearningGuidesQuery(baseOptions?: Apollo.QueryHookOptions<UserLearningGuidesQuery, UserLearningGuidesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserLearningGuidesQuery, UserLearningGuidesQueryVariables>(UserLearningGuidesDocument, options);
      }
export function useUserLearningGuidesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserLearningGuidesQuery, UserLearningGuidesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserLearningGuidesQuery, UserLearningGuidesQueryVariables>(UserLearningGuidesDocument, options);
        }
export type UserLearningGuidesQueryHookResult = ReturnType<typeof useUserLearningGuidesQuery>;
export type UserLearningGuidesLazyQueryHookResult = ReturnType<typeof useUserLearningGuidesLazyQuery>;
export type UserLearningGuidesQueryResult = Apollo.QueryResult<UserLearningGuidesQuery, UserLearningGuidesQueryVariables>;
export const UpdateUserLearningGuidesDocument = gql`
    mutation updateUserLearningGuides($userLearningGuidesUpdate: JSONObject!) {
  updateUserLearningGuides(input: $userLearningGuidesUpdate)
}
    `;
export type UpdateUserLearningGuidesMutationFn = Apollo.MutationFunction<UpdateUserLearningGuidesMutation, UpdateUserLearningGuidesMutationVariables>;

/**
 * __useUpdateUserLearningGuidesMutation__
 *
 * To run a mutation, you first call `useUpdateUserLearningGuidesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserLearningGuidesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserLearningGuidesMutation, { data, loading, error }] = useUpdateUserLearningGuidesMutation({
 *   variables: {
 *      userLearningGuidesUpdate: // value for 'userLearningGuidesUpdate'
 *   },
 * });
 */
export function useUpdateUserLearningGuidesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserLearningGuidesMutation, UpdateUserLearningGuidesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserLearningGuidesMutation, UpdateUserLearningGuidesMutationVariables>(UpdateUserLearningGuidesDocument, options);
      }
export type UpdateUserLearningGuidesMutationHookResult = ReturnType<typeof useUpdateUserLearningGuidesMutation>;
export type UpdateUserLearningGuidesMutationResult = Apollo.MutationResult<UpdateUserLearningGuidesMutation>;
export type UpdateUserLearningGuidesMutationOptions = Apollo.BaseMutationOptions<UpdateUserLearningGuidesMutation, UpdateUserLearningGuidesMutationVariables>;
export const UserRecentlyAddedTagsDocument = gql`
    query userRecentlyAddedTags($uid: String!) {
  userPrivate(uid: $uid) {
    tags {
      recentlyAddedTags {
        id
        description
      }
    }
  }
}
    `;

/**
 * __useUserRecentlyAddedTagsQuery__
 *
 * To run a query within a React component, call `useUserRecentlyAddedTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRecentlyAddedTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRecentlyAddedTagsQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useUserRecentlyAddedTagsQuery(baseOptions: Apollo.QueryHookOptions<UserRecentlyAddedTagsQuery, UserRecentlyAddedTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserRecentlyAddedTagsQuery, UserRecentlyAddedTagsQueryVariables>(UserRecentlyAddedTagsDocument, options);
      }
export function useUserRecentlyAddedTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserRecentlyAddedTagsQuery, UserRecentlyAddedTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserRecentlyAddedTagsQuery, UserRecentlyAddedTagsQueryVariables>(UserRecentlyAddedTagsDocument, options);
        }
export type UserRecentlyAddedTagsQueryHookResult = ReturnType<typeof useUserRecentlyAddedTagsQuery>;
export type UserRecentlyAddedTagsLazyQueryHookResult = ReturnType<typeof useUserRecentlyAddedTagsLazyQuery>;
export type UserRecentlyAddedTagsQueryResult = Apollo.QueryResult<UserRecentlyAddedTagsQuery, UserRecentlyAddedTagsQueryVariables>;
export const UserProfileDocument = gql`
    query userProfile($userId: String, $userHandle: String) {
  userProfile(input: {userId: $userId, userHandle: $userHandle}) {
    ...UserBasicFields
    publicItemsCount
    discoverExportCount
    rank
    nominatedBy {
      uid
      displayName
      photoURL
      handle
    }
  }
}
    ${UserBasicFieldsFragmentDoc}`;

/**
 * __useUserProfileQuery__
 *
 * To run a query within a React component, call `useUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      userHandle: // value for 'userHandle'
 *   },
 * });
 */
export function useUserProfileQuery(baseOptions?: Apollo.QueryHookOptions<UserProfileQuery, UserProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserProfileQuery, UserProfileQueryVariables>(UserProfileDocument, options);
      }
export function useUserProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserProfileQuery, UserProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserProfileQuery, UserProfileQueryVariables>(UserProfileDocument, options);
        }
export type UserProfileQueryHookResult = ReturnType<typeof useUserProfileQuery>;
export type UserProfileLazyQueryHookResult = ReturnType<typeof useUserProfileLazyQuery>;
export type UserProfileQueryResult = Apollo.QueryResult<UserProfileQuery, UserProfileQueryVariables>;
export const PublicWorkspaceItemsByUserDocument = gql`
    query publicWorkspaceItemsByUser($userId: String!, $first: Int!, $cursor: String) {
  publicWorkspaceItemsByUser(
    input: {userId: $userId, first: $first, cursor: $cursor}
  ) {
    pageInfo {
      nextCursor
    }
    workspaceItems {
      ...WorkspaceItemGridFields
    }
  }
}
    ${WorkspaceItemGridFieldsFragmentDoc}`;

/**
 * __usePublicWorkspaceItemsByUserQuery__
 *
 * To run a query within a React component, call `usePublicWorkspaceItemsByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicWorkspaceItemsByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicWorkspaceItemsByUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function usePublicWorkspaceItemsByUserQuery(baseOptions: Apollo.QueryHookOptions<PublicWorkspaceItemsByUserQuery, PublicWorkspaceItemsByUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicWorkspaceItemsByUserQuery, PublicWorkspaceItemsByUserQueryVariables>(PublicWorkspaceItemsByUserDocument, options);
      }
export function usePublicWorkspaceItemsByUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicWorkspaceItemsByUserQuery, PublicWorkspaceItemsByUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicWorkspaceItemsByUserQuery, PublicWorkspaceItemsByUserQueryVariables>(PublicWorkspaceItemsByUserDocument, options);
        }
export type PublicWorkspaceItemsByUserQueryHookResult = ReturnType<typeof usePublicWorkspaceItemsByUserQuery>;
export type PublicWorkspaceItemsByUserLazyQueryHookResult = ReturnType<typeof usePublicWorkspaceItemsByUserLazyQuery>;
export type PublicWorkspaceItemsByUserQueryResult = Apollo.QueryResult<PublicWorkspaceItemsByUserQuery, PublicWorkspaceItemsByUserQueryVariables>;
export const UserTopTagsDocument = gql`
    query userTopTags {
  userTopTags {
    id
    count
    description
  }
}
    `;

/**
 * __useUserTopTagsQuery__
 *
 * To run a query within a React component, call `useUserTopTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTopTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTopTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserTopTagsQuery(baseOptions?: Apollo.QueryHookOptions<UserTopTagsQuery, UserTopTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserTopTagsQuery, UserTopTagsQueryVariables>(UserTopTagsDocument, options);
      }
export function useUserTopTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserTopTagsQuery, UserTopTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserTopTagsQuery, UserTopTagsQueryVariables>(UserTopTagsDocument, options);
        }
export type UserTopTagsQueryHookResult = ReturnType<typeof useUserTopTagsQuery>;
export type UserTopTagsLazyQueryHookResult = ReturnType<typeof useUserTopTagsLazyQuery>;
export type UserTopTagsQueryResult = Apollo.QueryResult<UserTopTagsQuery, UserTopTagsQueryVariables>;
export const UploadCoverPhotoDocument = gql`
    mutation uploadCoverPhoto($file: Upload!) {
  uploadCoverPhoto(input: {file: $file}) {
    ...UserBasicFields
  }
}
    ${UserBasicFieldsFragmentDoc}`;
export type UploadCoverPhotoMutationFn = Apollo.MutationFunction<UploadCoverPhotoMutation, UploadCoverPhotoMutationVariables>;

/**
 * __useUploadCoverPhotoMutation__
 *
 * To run a mutation, you first call `useUploadCoverPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadCoverPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadCoverPhotoMutation, { data, loading, error }] = useUploadCoverPhotoMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadCoverPhotoMutation(baseOptions?: Apollo.MutationHookOptions<UploadCoverPhotoMutation, UploadCoverPhotoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadCoverPhotoMutation, UploadCoverPhotoMutationVariables>(UploadCoverPhotoDocument, options);
      }
export type UploadCoverPhotoMutationHookResult = ReturnType<typeof useUploadCoverPhotoMutation>;
export type UploadCoverPhotoMutationResult = Apollo.MutationResult<UploadCoverPhotoMutation>;
export type UploadCoverPhotoMutationOptions = Apollo.BaseMutationOptions<UploadCoverPhotoMutation, UploadCoverPhotoMutationVariables>;
export const UploadProfilePictureDocument = gql`
    mutation uploadProfilePicture($file: Upload!) {
  uploadProfilePicture(input: {file: $file}) {
    ...UserBasicFields
  }
}
    ${UserBasicFieldsFragmentDoc}`;
export type UploadProfilePictureMutationFn = Apollo.MutationFunction<UploadProfilePictureMutation, UploadProfilePictureMutationVariables>;

/**
 * __useUploadProfilePictureMutation__
 *
 * To run a mutation, you first call `useUploadProfilePictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadProfilePictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadProfilePictureMutation, { data, loading, error }] = useUploadProfilePictureMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadProfilePictureMutation(baseOptions?: Apollo.MutationHookOptions<UploadProfilePictureMutation, UploadProfilePictureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadProfilePictureMutation, UploadProfilePictureMutationVariables>(UploadProfilePictureDocument, options);
      }
export type UploadProfilePictureMutationHookResult = ReturnType<typeof useUploadProfilePictureMutation>;
export type UploadProfilePictureMutationResult = Apollo.MutationResult<UploadProfilePictureMutation>;
export type UploadProfilePictureMutationOptions = Apollo.BaseMutationOptions<UploadProfilePictureMutation, UploadProfilePictureMutationVariables>;
export const UpdateRecentTagsDocument = gql`
    mutation updateRecentTags($tags: [UserRecentTagInput!]!) {
  updateRecentTags(input: {tags: $tags}) {
    recentTags {
      id
    }
  }
}
    `;
export type UpdateRecentTagsMutationFn = Apollo.MutationFunction<UpdateRecentTagsMutation, UpdateRecentTagsMutationVariables>;

/**
 * __useUpdateRecentTagsMutation__
 *
 * To run a mutation, you first call `useUpdateRecentTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecentTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecentTagsMutation, { data, loading, error }] = useUpdateRecentTagsMutation({
 *   variables: {
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useUpdateRecentTagsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRecentTagsMutation, UpdateRecentTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRecentTagsMutation, UpdateRecentTagsMutationVariables>(UpdateRecentTagsDocument, options);
      }
export type UpdateRecentTagsMutationHookResult = ReturnType<typeof useUpdateRecentTagsMutation>;
export type UpdateRecentTagsMutationResult = Apollo.MutationResult<UpdateRecentTagsMutation>;
export type UpdateRecentTagsMutationOptions = Apollo.BaseMutationOptions<UpdateRecentTagsMutation, UpdateRecentTagsMutationVariables>;
export const CreateUserLoginOtpDocument = gql`
    mutation createUserLoginOTP($word: String) {
  createUserLoginOTP(input: {word: $word}) {
    code
    word
  }
}
    `;
export type CreateUserLoginOtpMutationFn = Apollo.MutationFunction<CreateUserLoginOtpMutation, CreateUserLoginOtpMutationVariables>;

/**
 * __useCreateUserLoginOtpMutation__
 *
 * To run a mutation, you first call `useCreateUserLoginOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserLoginOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserLoginOtpMutation, { data, loading, error }] = useCreateUserLoginOtpMutation({
 *   variables: {
 *      word: // value for 'word'
 *   },
 * });
 */
export function useCreateUserLoginOtpMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserLoginOtpMutation, CreateUserLoginOtpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserLoginOtpMutation, CreateUserLoginOtpMutationVariables>(CreateUserLoginOtpDocument, options);
      }
export type CreateUserLoginOtpMutationHookResult = ReturnType<typeof useCreateUserLoginOtpMutation>;
export type CreateUserLoginOtpMutationResult = Apollo.MutationResult<CreateUserLoginOtpMutation>;
export type CreateUserLoginOtpMutationOptions = Apollo.BaseMutationOptions<CreateUserLoginOtpMutation, CreateUserLoginOtpMutationVariables>;
export const UserLoginOtpExchangeDocument = gql`
    mutation userLoginOTPExchange($code: Int!, $word: String!) {
  userLoginOTPExchange(input: {code: $code, word: $word}) {
    token
  }
}
    `;
export type UserLoginOtpExchangeMutationFn = Apollo.MutationFunction<UserLoginOtpExchangeMutation, UserLoginOtpExchangeMutationVariables>;

/**
 * __useUserLoginOtpExchangeMutation__
 *
 * To run a mutation, you first call `useUserLoginOtpExchangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserLoginOtpExchangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userLoginOtpExchangeMutation, { data, loading, error }] = useUserLoginOtpExchangeMutation({
 *   variables: {
 *      code: // value for 'code'
 *      word: // value for 'word'
 *   },
 * });
 */
export function useUserLoginOtpExchangeMutation(baseOptions?: Apollo.MutationHookOptions<UserLoginOtpExchangeMutation, UserLoginOtpExchangeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserLoginOtpExchangeMutation, UserLoginOtpExchangeMutationVariables>(UserLoginOtpExchangeDocument, options);
      }
export type UserLoginOtpExchangeMutationHookResult = ReturnType<typeof useUserLoginOtpExchangeMutation>;
export type UserLoginOtpExchangeMutationResult = Apollo.MutationResult<UserLoginOtpExchangeMutation>;
export type UserLoginOtpExchangeMutationOptions = Apollo.BaseMutationOptions<UserLoginOtpExchangeMutation, UserLoginOtpExchangeMutationVariables>;
export const OutpaintDocument = gql`
    mutation outpaint($prompt: String!, $imageData: String!, $maskData: String!, $editSessionId: ID) {
  outpaint(
    input: {prompt: $prompt, imageData: $imageData, maskData: $maskData, editSessionId: $editSessionId}
  )
}
    `;
export type OutpaintMutationFn = Apollo.MutationFunction<OutpaintMutation, OutpaintMutationVariables>;

/**
 * __useOutpaintMutation__
 *
 * To run a mutation, you first call `useOutpaintMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOutpaintMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [outpaintMutation, { data, loading, error }] = useOutpaintMutation({
 *   variables: {
 *      prompt: // value for 'prompt'
 *      imageData: // value for 'imageData'
 *      maskData: // value for 'maskData'
 *      editSessionId: // value for 'editSessionId'
 *   },
 * });
 */
export function useOutpaintMutation(baseOptions?: Apollo.MutationHookOptions<OutpaintMutation, OutpaintMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OutpaintMutation, OutpaintMutationVariables>(OutpaintDocument, options);
      }
export type OutpaintMutationHookResult = ReturnType<typeof useOutpaintMutation>;
export type OutpaintMutationResult = Apollo.MutationResult<OutpaintMutation>;
export type OutpaintMutationOptions = Apollo.BaseMutationOptions<OutpaintMutation, OutpaintMutationVariables>;
export const AddUserDocument = gql`
    mutation addUser($photoURL: String, $fromPublicFeatureData: FromPublicFeatureData) {
  addUser(
    input: {photoURL: $photoURL, fromPublicFeatureData: $fromPublicFeatureData}
  ) {
    uid
  }
}
    `;
export type AddUserMutationFn = Apollo.MutationFunction<AddUserMutation, AddUserMutationVariables>;

/**
 * __useAddUserMutation__
 *
 * To run a mutation, you first call `useAddUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserMutation, { data, loading, error }] = useAddUserMutation({
 *   variables: {
 *      photoURL: // value for 'photoURL'
 *      fromPublicFeatureData: // value for 'fromPublicFeatureData'
 *   },
 * });
 */
export function useAddUserMutation(baseOptions?: Apollo.MutationHookOptions<AddUserMutation, AddUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserMutation, AddUserMutationVariables>(AddUserDocument, options);
      }
export type AddUserMutationHookResult = ReturnType<typeof useAddUserMutation>;
export type AddUserMutationResult = Apollo.MutationResult<AddUserMutation>;
export type AddUserMutationOptions = Apollo.BaseMutationOptions<AddUserMutation, AddUserMutationVariables>;
export const SetupUserDocument = gql`
    mutation setupUser($firstName: String!, $lastName: String!, $jobTitle: String!, $timesReadTermsOfService: Int!, $timesReadPrivacyPolicy: Int!, $timesUploadedProfileImage: Int) {
  setupUser(
    input: {firstName: $firstName, lastName: $lastName, jobTitle: $jobTitle, timesReadTermsOfService: $timesReadTermsOfService, timesReadPrivacyPolicy: $timesReadPrivacyPolicy, timesUploadedProfileImage: $timesUploadedProfileImage}
  ) {
    ...UserBasicFields
  }
}
    ${UserBasicFieldsFragmentDoc}`;
export type SetupUserMutationFn = Apollo.MutationFunction<SetupUserMutation, SetupUserMutationVariables>;

/**
 * __useSetupUserMutation__
 *
 * To run a mutation, you first call `useSetupUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetupUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setupUserMutation, { data, loading, error }] = useSetupUserMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      jobTitle: // value for 'jobTitle'
 *      timesReadTermsOfService: // value for 'timesReadTermsOfService'
 *      timesReadPrivacyPolicy: // value for 'timesReadPrivacyPolicy'
 *      timesUploadedProfileImage: // value for 'timesUploadedProfileImage'
 *   },
 * });
 */
export function useSetupUserMutation(baseOptions?: Apollo.MutationHookOptions<SetupUserMutation, SetupUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetupUserMutation, SetupUserMutationVariables>(SetupUserDocument, options);
      }
export type SetupUserMutationHookResult = ReturnType<typeof useSetupUserMutation>;
export type SetupUserMutationResult = Apollo.MutationResult<SetupUserMutation>;
export type SetupUserMutationOptions = Apollo.BaseMutationOptions<SetupUserMutation, SetupUserMutationVariables>;
export const SuggestedFrameExtractionsDocument = gql`
    query suggestedFrameExtractions($workspaceId: String!) {
  suggestedFrameExtractions(workspaceId: $workspaceId) {
    link
    name
    height
    width
    duration
    thumbnail
    creator {
      name
      link
    }
  }
}
    `;

/**
 * __useSuggestedFrameExtractionsQuery__
 *
 * To run a query within a React component, call `useSuggestedFrameExtractionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestedFrameExtractionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestedFrameExtractionsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useSuggestedFrameExtractionsQuery(baseOptions: Apollo.QueryHookOptions<SuggestedFrameExtractionsQuery, SuggestedFrameExtractionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SuggestedFrameExtractionsQuery, SuggestedFrameExtractionsQueryVariables>(SuggestedFrameExtractionsDocument, options);
      }
export function useSuggestedFrameExtractionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SuggestedFrameExtractionsQuery, SuggestedFrameExtractionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SuggestedFrameExtractionsQuery, SuggestedFrameExtractionsQueryVariables>(SuggestedFrameExtractionsDocument, options);
        }
export type SuggestedFrameExtractionsQueryHookResult = ReturnType<typeof useSuggestedFrameExtractionsQuery>;
export type SuggestedFrameExtractionsLazyQueryHookResult = ReturnType<typeof useSuggestedFrameExtractionsLazyQuery>;
export type SuggestedFrameExtractionsQueryResult = Apollo.QueryResult<SuggestedFrameExtractionsQuery, SuggestedFrameExtractionsQueryVariables>;
export const AddWaitingListEntryDocument = gql`
    mutation addWaitingListEntry($email: String!, $referralId: String, $signupContext: SignupContext) {
  addWaitingListEntry(
    input: {email: $email, referralId: $referralId, signupContext: $signupContext}
  ) {
    id
    name
    email
    approved
    analyzeIsComplete
    completedFormAt
    createdAt
    referralId
  }
}
    `;
export type AddWaitingListEntryMutationFn = Apollo.MutationFunction<AddWaitingListEntryMutation, AddWaitingListEntryMutationVariables>;

/**
 * __useAddWaitingListEntryMutation__
 *
 * To run a mutation, you first call `useAddWaitingListEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWaitingListEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWaitingListEntryMutation, { data, loading, error }] = useAddWaitingListEntryMutation({
 *   variables: {
 *      email: // value for 'email'
 *      referralId: // value for 'referralId'
 *      signupContext: // value for 'signupContext'
 *   },
 * });
 */
export function useAddWaitingListEntryMutation(baseOptions?: Apollo.MutationHookOptions<AddWaitingListEntryMutation, AddWaitingListEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddWaitingListEntryMutation, AddWaitingListEntryMutationVariables>(AddWaitingListEntryDocument, options);
      }
export type AddWaitingListEntryMutationHookResult = ReturnType<typeof useAddWaitingListEntryMutation>;
export type AddWaitingListEntryMutationResult = Apollo.MutationResult<AddWaitingListEntryMutation>;
export type AddWaitingListEntryMutationOptions = Apollo.BaseMutationOptions<AddWaitingListEntryMutation, AddWaitingListEntryMutationVariables>;
export const JoinableWorkspacesPendingInviteDocument = gql`
    query joinableWorkspacesPendingInvite {
  joinableWorkspacesPendingInvite {
    ...WorkspaceThumbFields
  }
}
    ${WorkspaceThumbFieldsFragmentDoc}`;

/**
 * __useJoinableWorkspacesPendingInviteQuery__
 *
 * To run a query within a React component, call `useJoinableWorkspacesPendingInviteQuery` and pass it any options that fit your needs.
 * When your component renders, `useJoinableWorkspacesPendingInviteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJoinableWorkspacesPendingInviteQuery({
 *   variables: {
 *   },
 * });
 */
export function useJoinableWorkspacesPendingInviteQuery(baseOptions?: Apollo.QueryHookOptions<JoinableWorkspacesPendingInviteQuery, JoinableWorkspacesPendingInviteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JoinableWorkspacesPendingInviteQuery, JoinableWorkspacesPendingInviteQueryVariables>(JoinableWorkspacesPendingInviteDocument, options);
      }
export function useJoinableWorkspacesPendingInviteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JoinableWorkspacesPendingInviteQuery, JoinableWorkspacesPendingInviteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JoinableWorkspacesPendingInviteQuery, JoinableWorkspacesPendingInviteQueryVariables>(JoinableWorkspacesPendingInviteDocument, options);
        }
export type JoinableWorkspacesPendingInviteQueryHookResult = ReturnType<typeof useJoinableWorkspacesPendingInviteQuery>;
export type JoinableWorkspacesPendingInviteLazyQueryHookResult = ReturnType<typeof useJoinableWorkspacesPendingInviteLazyQuery>;
export type JoinableWorkspacesPendingInviteQueryResult = Apollo.QueryResult<JoinableWorkspacesPendingInviteQuery, JoinableWorkspacesPendingInviteQueryVariables>;
export const JoinableWorkspacesAllowedDomainDocument = gql`
    query joinableWorkspacesAllowedDomain {
  joinableWorkspacesAllowedDomain {
    ...WorkspaceThumbFields
  }
}
    ${WorkspaceThumbFieldsFragmentDoc}`;

/**
 * __useJoinableWorkspacesAllowedDomainQuery__
 *
 * To run a query within a React component, call `useJoinableWorkspacesAllowedDomainQuery` and pass it any options that fit your needs.
 * When your component renders, `useJoinableWorkspacesAllowedDomainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJoinableWorkspacesAllowedDomainQuery({
 *   variables: {
 *   },
 * });
 */
export function useJoinableWorkspacesAllowedDomainQuery(baseOptions?: Apollo.QueryHookOptions<JoinableWorkspacesAllowedDomainQuery, JoinableWorkspacesAllowedDomainQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JoinableWorkspacesAllowedDomainQuery, JoinableWorkspacesAllowedDomainQueryVariables>(JoinableWorkspacesAllowedDomainDocument, options);
      }
export function useJoinableWorkspacesAllowedDomainLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JoinableWorkspacesAllowedDomainQuery, JoinableWorkspacesAllowedDomainQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JoinableWorkspacesAllowedDomainQuery, JoinableWorkspacesAllowedDomainQueryVariables>(JoinableWorkspacesAllowedDomainDocument, options);
        }
export type JoinableWorkspacesAllowedDomainQueryHookResult = ReturnType<typeof useJoinableWorkspacesAllowedDomainQuery>;
export type JoinableWorkspacesAllowedDomainLazyQueryHookResult = ReturnType<typeof useJoinableWorkspacesAllowedDomainLazyQuery>;
export type JoinableWorkspacesAllowedDomainQueryResult = Apollo.QueryResult<JoinableWorkspacesAllowedDomainQuery, JoinableWorkspacesAllowedDomainQueryVariables>;
export const WorkspacesPublicDocument = gql`
    query workspacesPublic {
  workspacesPublic {
    ...WorkspaceThumbFields
  }
}
    ${WorkspaceThumbFieldsFragmentDoc}`;

/**
 * __useWorkspacesPublicQuery__
 *
 * To run a query within a React component, call `useWorkspacesPublicQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspacesPublicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspacesPublicQuery({
 *   variables: {
 *   },
 * });
 */
export function useWorkspacesPublicQuery(baseOptions?: Apollo.QueryHookOptions<WorkspacesPublicQuery, WorkspacesPublicQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkspacesPublicQuery, WorkspacesPublicQueryVariables>(WorkspacesPublicDocument, options);
      }
export function useWorkspacesPublicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkspacesPublicQuery, WorkspacesPublicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkspacesPublicQuery, WorkspacesPublicQueryVariables>(WorkspacesPublicDocument, options);
        }
export type WorkspacesPublicQueryHookResult = ReturnType<typeof useWorkspacesPublicQuery>;
export type WorkspacesPublicLazyQueryHookResult = ReturnType<typeof useWorkspacesPublicLazyQuery>;
export type WorkspacesPublicQueryResult = Apollo.QueryResult<WorkspacesPublicQuery, WorkspacesPublicQueryVariables>;
export const WorkspaceDocument = gql`
    query workspace($workspaceId: String!) {
  workspace(input: {workspaceId: $workspaceId}) {
    ...WorkspaceBasicFields
  }
}
    ${WorkspaceBasicFieldsFragmentDoc}`;

/**
 * __useWorkspaceQuery__
 *
 * To run a query within a React component, call `useWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useWorkspaceQuery(baseOptions: Apollo.QueryHookOptions<WorkspaceQuery, WorkspaceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkspaceQuery, WorkspaceQueryVariables>(WorkspaceDocument, options);
      }
export function useWorkspaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkspaceQuery, WorkspaceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkspaceQuery, WorkspaceQueryVariables>(WorkspaceDocument, options);
        }
export type WorkspaceQueryHookResult = ReturnType<typeof useWorkspaceQuery>;
export type WorkspaceLazyQueryHookResult = ReturnType<typeof useWorkspaceLazyQuery>;
export type WorkspaceQueryResult = Apollo.QueryResult<WorkspaceQuery, WorkspaceQueryVariables>;
export const LastActiveWorkspaceDocument = gql`
    query lastActiveWorkspace {
  lastActiveWorkspace {
    ...WorkspaceBasicFields
  }
}
    ${WorkspaceBasicFieldsFragmentDoc}`;

/**
 * __useLastActiveWorkspaceQuery__
 *
 * To run a query within a React component, call `useLastActiveWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useLastActiveWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLastActiveWorkspaceQuery({
 *   variables: {
 *   },
 * });
 */
export function useLastActiveWorkspaceQuery(baseOptions?: Apollo.QueryHookOptions<LastActiveWorkspaceQuery, LastActiveWorkspaceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LastActiveWorkspaceQuery, LastActiveWorkspaceQueryVariables>(LastActiveWorkspaceDocument, options);
      }
export function useLastActiveWorkspaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LastActiveWorkspaceQuery, LastActiveWorkspaceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LastActiveWorkspaceQuery, LastActiveWorkspaceQueryVariables>(LastActiveWorkspaceDocument, options);
        }
export type LastActiveWorkspaceQueryHookResult = ReturnType<typeof useLastActiveWorkspaceQuery>;
export type LastActiveWorkspaceLazyQueryHookResult = ReturnType<typeof useLastActiveWorkspaceLazyQuery>;
export type LastActiveWorkspaceQueryResult = Apollo.QueryResult<LastActiveWorkspaceQuery, LastActiveWorkspaceQueryVariables>;
export const WorkspaceItemCountDocument = gql`
    query workspaceItemCount($workspaceId: String!) {
  workspace(input: {workspaceId: $workspaceId}) {
    totalItemsCount
  }
}
    `;

/**
 * __useWorkspaceItemCountQuery__
 *
 * To run a query within a React component, call `useWorkspaceItemCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceItemCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceItemCountQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useWorkspaceItemCountQuery(baseOptions: Apollo.QueryHookOptions<WorkspaceItemCountQuery, WorkspaceItemCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkspaceItemCountQuery, WorkspaceItemCountQueryVariables>(WorkspaceItemCountDocument, options);
      }
export function useWorkspaceItemCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkspaceItemCountQuery, WorkspaceItemCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkspaceItemCountQuery, WorkspaceItemCountQueryVariables>(WorkspaceItemCountDocument, options);
        }
export type WorkspaceItemCountQueryHookResult = ReturnType<typeof useWorkspaceItemCountQuery>;
export type WorkspaceItemCountLazyQueryHookResult = ReturnType<typeof useWorkspaceItemCountLazyQuery>;
export type WorkspaceItemCountQueryResult = Apollo.QueryResult<WorkspaceItemCountQuery, WorkspaceItemCountQueryVariables>;
export const JoinWorkspaceDocument = gql`
    mutation joinWorkspace($id: String!) {
  joinWorkspace(id: $id) {
    ...WorkspaceBasicFields
    totalItemsCount
  }
}
    ${WorkspaceBasicFieldsFragmentDoc}`;
export type JoinWorkspaceMutationFn = Apollo.MutationFunction<JoinWorkspaceMutation, JoinWorkspaceMutationVariables>;

/**
 * __useJoinWorkspaceMutation__
 *
 * To run a mutation, you first call `useJoinWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinWorkspaceMutation, { data, loading, error }] = useJoinWorkspaceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJoinWorkspaceMutation(baseOptions?: Apollo.MutationHookOptions<JoinWorkspaceMutation, JoinWorkspaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JoinWorkspaceMutation, JoinWorkspaceMutationVariables>(JoinWorkspaceDocument, options);
      }
export type JoinWorkspaceMutationHookResult = ReturnType<typeof useJoinWorkspaceMutation>;
export type JoinWorkspaceMutationResult = Apollo.MutationResult<JoinWorkspaceMutation>;
export type JoinWorkspaceMutationOptions = Apollo.BaseMutationOptions<JoinWorkspaceMutation, JoinWorkspaceMutationVariables>;
export const LeaveWorkspaceDocument = gql`
    mutation leaveWorkspace($id: String!) {
  leaveWorkspace(id: $id) {
    status
  }
}
    `;
export type LeaveWorkspaceMutationFn = Apollo.MutationFunction<LeaveWorkspaceMutation, LeaveWorkspaceMutationVariables>;

/**
 * __useLeaveWorkspaceMutation__
 *
 * To run a mutation, you first call `useLeaveWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveWorkspaceMutation, { data, loading, error }] = useLeaveWorkspaceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLeaveWorkspaceMutation(baseOptions?: Apollo.MutationHookOptions<LeaveWorkspaceMutation, LeaveWorkspaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LeaveWorkspaceMutation, LeaveWorkspaceMutationVariables>(LeaveWorkspaceDocument, options);
      }
export type LeaveWorkspaceMutationHookResult = ReturnType<typeof useLeaveWorkspaceMutation>;
export type LeaveWorkspaceMutationResult = Apollo.MutationResult<LeaveWorkspaceMutation>;
export type LeaveWorkspaceMutationOptions = Apollo.BaseMutationOptions<LeaveWorkspaceMutation, LeaveWorkspaceMutationVariables>;
export const SendWorkspaceInvitationDocument = gql`
    mutation sendWorkspaceInvitation($email: String!, $workspaceId: String!, $inviteSource: String!) {
  sendWorkspaceInvitation(
    email: $email
    workspaceId: $workspaceId
    inviteSource: $inviteSource
  ) {
    ...WorkspaceBasicFields
  }
}
    ${WorkspaceBasicFieldsFragmentDoc}`;
export type SendWorkspaceInvitationMutationFn = Apollo.MutationFunction<SendWorkspaceInvitationMutation, SendWorkspaceInvitationMutationVariables>;

/**
 * __useSendWorkspaceInvitationMutation__
 *
 * To run a mutation, you first call `useSendWorkspaceInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendWorkspaceInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendWorkspaceInvitationMutation, { data, loading, error }] = useSendWorkspaceInvitationMutation({
 *   variables: {
 *      email: // value for 'email'
 *      workspaceId: // value for 'workspaceId'
 *      inviteSource: // value for 'inviteSource'
 *   },
 * });
 */
export function useSendWorkspaceInvitationMutation(baseOptions?: Apollo.MutationHookOptions<SendWorkspaceInvitationMutation, SendWorkspaceInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendWorkspaceInvitationMutation, SendWorkspaceInvitationMutationVariables>(SendWorkspaceInvitationDocument, options);
      }
export type SendWorkspaceInvitationMutationHookResult = ReturnType<typeof useSendWorkspaceInvitationMutation>;
export type SendWorkspaceInvitationMutationResult = Apollo.MutationResult<SendWorkspaceInvitationMutation>;
export type SendWorkspaceInvitationMutationOptions = Apollo.BaseMutationOptions<SendWorkspaceInvitationMutation, SendWorkspaceInvitationMutationVariables>;
export const AddWorkspaceDocument = gql`
    mutation addWorkspace($title: String!, $url: String!) {
  addWorkspace(input: {title: $title, url: $url}) {
    ...WorkspaceBasicFields
  }
}
    ${WorkspaceBasicFieldsFragmentDoc}`;
export type AddWorkspaceMutationFn = Apollo.MutationFunction<AddWorkspaceMutation, AddWorkspaceMutationVariables>;

/**
 * __useAddWorkspaceMutation__
 *
 * To run a mutation, you first call `useAddWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWorkspaceMutation, { data, loading, error }] = useAddWorkspaceMutation({
 *   variables: {
 *      title: // value for 'title'
 *      url: // value for 'url'
 *   },
 * });
 */
export function useAddWorkspaceMutation(baseOptions?: Apollo.MutationHookOptions<AddWorkspaceMutation, AddWorkspaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddWorkspaceMutation, AddWorkspaceMutationVariables>(AddWorkspaceDocument, options);
      }
export type AddWorkspaceMutationHookResult = ReturnType<typeof useAddWorkspaceMutation>;
export type AddWorkspaceMutationResult = Apollo.MutationResult<AddWorkspaceMutation>;
export type AddWorkspaceMutationOptions = Apollo.BaseMutationOptions<AddWorkspaceMutation, AddWorkspaceMutationVariables>;
export const UpdateWorkspaceItemsAccessLevelDocument = gql`
    mutation updateWorkspaceItemsAccessLevel($workspaceId: String!, $itemIds: [String!], $uploadSessionId: String, $accessLevel: WorkspaceItemAccessLevel!) {
  updateWorkspaceItemsAccessLevel(
    input: {workspaceId: $workspaceId, uploadSessionId: $uploadSessionId, itemIds: $itemIds, accessLevel: $accessLevel}
  ) {
    status
  }
}
    `;
export type UpdateWorkspaceItemsAccessLevelMutationFn = Apollo.MutationFunction<UpdateWorkspaceItemsAccessLevelMutation, UpdateWorkspaceItemsAccessLevelMutationVariables>;

/**
 * __useUpdateWorkspaceItemsAccessLevelMutation__
 *
 * To run a mutation, you first call `useUpdateWorkspaceItemsAccessLevelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkspaceItemsAccessLevelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkspaceItemsAccessLevelMutation, { data, loading, error }] = useUpdateWorkspaceItemsAccessLevelMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      itemIds: // value for 'itemIds'
 *      uploadSessionId: // value for 'uploadSessionId'
 *      accessLevel: // value for 'accessLevel'
 *   },
 * });
 */
export function useUpdateWorkspaceItemsAccessLevelMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWorkspaceItemsAccessLevelMutation, UpdateWorkspaceItemsAccessLevelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWorkspaceItemsAccessLevelMutation, UpdateWorkspaceItemsAccessLevelMutationVariables>(UpdateWorkspaceItemsAccessLevelDocument, options);
      }
export type UpdateWorkspaceItemsAccessLevelMutationHookResult = ReturnType<typeof useUpdateWorkspaceItemsAccessLevelMutation>;
export type UpdateWorkspaceItemsAccessLevelMutationResult = Apollo.MutationResult<UpdateWorkspaceItemsAccessLevelMutation>;
export type UpdateWorkspaceItemsAccessLevelMutationOptions = Apollo.BaseMutationOptions<UpdateWorkspaceItemsAccessLevelMutation, UpdateWorkspaceItemsAccessLevelMutationVariables>;
export const IncrementWorkspaceItemViewsDocument = gql`
    mutation incrementWorkspaceItemViews($workspaceItemId: String!) {
  incrementWorkspaceItemViews(input: {workspaceItemId: $workspaceItemId}) {
    id
    openCount
  }
}
    `;
export type IncrementWorkspaceItemViewsMutationFn = Apollo.MutationFunction<IncrementWorkspaceItemViewsMutation, IncrementWorkspaceItemViewsMutationVariables>;

/**
 * __useIncrementWorkspaceItemViewsMutation__
 *
 * To run a mutation, you first call `useIncrementWorkspaceItemViewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIncrementWorkspaceItemViewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [incrementWorkspaceItemViewsMutation, { data, loading, error }] = useIncrementWorkspaceItemViewsMutation({
 *   variables: {
 *      workspaceItemId: // value for 'workspaceItemId'
 *   },
 * });
 */
export function useIncrementWorkspaceItemViewsMutation(baseOptions?: Apollo.MutationHookOptions<IncrementWorkspaceItemViewsMutation, IncrementWorkspaceItemViewsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IncrementWorkspaceItemViewsMutation, IncrementWorkspaceItemViewsMutationVariables>(IncrementWorkspaceItemViewsDocument, options);
      }
export type IncrementWorkspaceItemViewsMutationHookResult = ReturnType<typeof useIncrementWorkspaceItemViewsMutation>;
export type IncrementWorkspaceItemViewsMutationResult = Apollo.MutationResult<IncrementWorkspaceItemViewsMutation>;
export type IncrementWorkspaceItemViewsMutationOptions = Apollo.BaseMutationOptions<IncrementWorkspaceItemViewsMutation, IncrementWorkspaceItemViewsMutationVariables>;
export const SaveDiscoveryItemToWorkspaceDocument = gql`
    mutation saveDiscoveryItemToWorkspace($itemId: String!, $workspaceId: String!, $isBoardItem: Boolean) {
  saveDiscoveryItemToWorkspace(
    input: {itemId: $itemId, workspaceId: $workspaceId, isBoardItem: $isBoardItem}
  ) {
    id
  }
}
    `;
export type SaveDiscoveryItemToWorkspaceMutationFn = Apollo.MutationFunction<SaveDiscoveryItemToWorkspaceMutation, SaveDiscoveryItemToWorkspaceMutationVariables>;

/**
 * __useSaveDiscoveryItemToWorkspaceMutation__
 *
 * To run a mutation, you first call `useSaveDiscoveryItemToWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDiscoveryItemToWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDiscoveryItemToWorkspaceMutation, { data, loading, error }] = useSaveDiscoveryItemToWorkspaceMutation({
 *   variables: {
 *      itemId: // value for 'itemId'
 *      workspaceId: // value for 'workspaceId'
 *      isBoardItem: // value for 'isBoardItem'
 *   },
 * });
 */
export function useSaveDiscoveryItemToWorkspaceMutation(baseOptions?: Apollo.MutationHookOptions<SaveDiscoveryItemToWorkspaceMutation, SaveDiscoveryItemToWorkspaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveDiscoveryItemToWorkspaceMutation, SaveDiscoveryItemToWorkspaceMutationVariables>(SaveDiscoveryItemToWorkspaceDocument, options);
      }
export type SaveDiscoveryItemToWorkspaceMutationHookResult = ReturnType<typeof useSaveDiscoveryItemToWorkspaceMutation>;
export type SaveDiscoveryItemToWorkspaceMutationResult = Apollo.MutationResult<SaveDiscoveryItemToWorkspaceMutation>;
export type SaveDiscoveryItemToWorkspaceMutationOptions = Apollo.BaseMutationOptions<SaveDiscoveryItemToWorkspaceMutation, SaveDiscoveryItemToWorkspaceMutationVariables>;
export const WorkspaceItemDocument = gql`
    query workspaceItem($workspaceItemId: String!) {
  workspaceItem(input: {workspaceItemId: $workspaceItemId}) {
    id
    mediaVariants {
      url
      variant
      type
    }
  }
}
    `;

/**
 * __useWorkspaceItemQuery__
 *
 * To run a query within a React component, call `useWorkspaceItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceItemQuery({
 *   variables: {
 *      workspaceItemId: // value for 'workspaceItemId'
 *   },
 * });
 */
export function useWorkspaceItemQuery(baseOptions: Apollo.QueryHookOptions<WorkspaceItemQuery, WorkspaceItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkspaceItemQuery, WorkspaceItemQueryVariables>(WorkspaceItemDocument, options);
      }
export function useWorkspaceItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkspaceItemQuery, WorkspaceItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkspaceItemQuery, WorkspaceItemQueryVariables>(WorkspaceItemDocument, options);
        }
export type WorkspaceItemQueryHookResult = ReturnType<typeof useWorkspaceItemQuery>;
export type WorkspaceItemLazyQueryHookResult = ReturnType<typeof useWorkspaceItemLazyQuery>;
export type WorkspaceItemQueryResult = Apollo.QueryResult<WorkspaceItemQuery, WorkspaceItemQueryVariables>;
export const WorkspaceItemViewDocument = gql`
    query workspaceItemView($workspaceItemId: String!) {
  workspaceItem(input: {workspaceItemId: $workspaceItemId}) {
    id
    userRole
    createdAt
    updatedAt
    webUrl
    title
    openCount
    aspectRatio
    accessLevel
    mediaType
    commentCount
    original {
      path
      info {
        type
      }
    }
    uploadSessionId
    workspaceId
    itemSourceInfo {
      type
      videoExtractInfo {
        url
        urlType
      }
      extensionImportInfo {
        pageUrl
      }
    }
    createdBy {
      id
      email
      handle
      displayName
      photoURL
    }
    tags {
      ...WorkspaceItemTagFields
    }
    colors {
      id
      pixelFraction
      hex
      score
    }
    mediaVariants {
      url
      variant
      type
    }
  }
}
    ${WorkspaceItemTagFieldsFragmentDoc}`;

/**
 * __useWorkspaceItemViewQuery__
 *
 * To run a query within a React component, call `useWorkspaceItemViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceItemViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceItemViewQuery({
 *   variables: {
 *      workspaceItemId: // value for 'workspaceItemId'
 *   },
 * });
 */
export function useWorkspaceItemViewQuery(baseOptions: Apollo.QueryHookOptions<WorkspaceItemViewQuery, WorkspaceItemViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkspaceItemViewQuery, WorkspaceItemViewQueryVariables>(WorkspaceItemViewDocument, options);
      }
export function useWorkspaceItemViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkspaceItemViewQuery, WorkspaceItemViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkspaceItemViewQuery, WorkspaceItemViewQueryVariables>(WorkspaceItemViewDocument, options);
        }
export type WorkspaceItemViewQueryHookResult = ReturnType<typeof useWorkspaceItemViewQuery>;
export type WorkspaceItemViewLazyQueryHookResult = ReturnType<typeof useWorkspaceItemViewLazyQuery>;
export type WorkspaceItemViewQueryResult = Apollo.QueryResult<WorkspaceItemViewQuery, WorkspaceItemViewQueryVariables>;
export const UpdateWorkspaceItemTitleDocument = gql`
    mutation updateWorkspaceItemTitle($workspaceItemId: String!, $title: String!) {
  updateWorkspaceItemTitle(
    input: {workspaceItemId: $workspaceItemId, title: $title}
  ) {
    id
    title
  }
}
    `;
export type UpdateWorkspaceItemTitleMutationFn = Apollo.MutationFunction<UpdateWorkspaceItemTitleMutation, UpdateWorkspaceItemTitleMutationVariables>;

/**
 * __useUpdateWorkspaceItemTitleMutation__
 *
 * To run a mutation, you first call `useUpdateWorkspaceItemTitleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkspaceItemTitleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkspaceItemTitleMutation, { data, loading, error }] = useUpdateWorkspaceItemTitleMutation({
 *   variables: {
 *      workspaceItemId: // value for 'workspaceItemId'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useUpdateWorkspaceItemTitleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWorkspaceItemTitleMutation, UpdateWorkspaceItemTitleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWorkspaceItemTitleMutation, UpdateWorkspaceItemTitleMutationVariables>(UpdateWorkspaceItemTitleDocument, options);
      }
export type UpdateWorkspaceItemTitleMutationHookResult = ReturnType<typeof useUpdateWorkspaceItemTitleMutation>;
export type UpdateWorkspaceItemTitleMutationResult = Apollo.MutationResult<UpdateWorkspaceItemTitleMutation>;
export type UpdateWorkspaceItemTitleMutationOptions = Apollo.BaseMutationOptions<UpdateWorkspaceItemTitleMutation, UpdateWorkspaceItemTitleMutationVariables>;
export const AddWorkspaceItemTagDocument = gql`
    mutation addWorkspaceItemTag($workspaceItemId: String!, $tagDescription: String!, $tagId: String) {
  addWorkspaceItemTag(
    input: {workspaceItemId: $workspaceItemId, tagDescription: $tagDescription, tagId: $tagId}
  ) {
    id
    description
  }
}
    `;
export type AddWorkspaceItemTagMutationFn = Apollo.MutationFunction<AddWorkspaceItemTagMutation, AddWorkspaceItemTagMutationVariables>;

/**
 * __useAddWorkspaceItemTagMutation__
 *
 * To run a mutation, you first call `useAddWorkspaceItemTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWorkspaceItemTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWorkspaceItemTagMutation, { data, loading, error }] = useAddWorkspaceItemTagMutation({
 *   variables: {
 *      workspaceItemId: // value for 'workspaceItemId'
 *      tagDescription: // value for 'tagDescription'
 *      tagId: // value for 'tagId'
 *   },
 * });
 */
export function useAddWorkspaceItemTagMutation(baseOptions?: Apollo.MutationHookOptions<AddWorkspaceItemTagMutation, AddWorkspaceItemTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddWorkspaceItemTagMutation, AddWorkspaceItemTagMutationVariables>(AddWorkspaceItemTagDocument, options);
      }
export type AddWorkspaceItemTagMutationHookResult = ReturnType<typeof useAddWorkspaceItemTagMutation>;
export type AddWorkspaceItemTagMutationResult = Apollo.MutationResult<AddWorkspaceItemTagMutation>;
export type AddWorkspaceItemTagMutationOptions = Apollo.BaseMutationOptions<AddWorkspaceItemTagMutation, AddWorkspaceItemTagMutationVariables>;
export const DeleteWorkspaceItemTagDocument = gql`
    mutation deleteWorkspaceItemTag($workspaceItemId: String!, $tagId: String!) {
  deleteWorkspaceItemTag(
    input: {workspaceItemId: $workspaceItemId, tagId: $tagId}
  ) {
    status
  }
}
    `;
export type DeleteWorkspaceItemTagMutationFn = Apollo.MutationFunction<DeleteWorkspaceItemTagMutation, DeleteWorkspaceItemTagMutationVariables>;

/**
 * __useDeleteWorkspaceItemTagMutation__
 *
 * To run a mutation, you first call `useDeleteWorkspaceItemTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkspaceItemTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkspaceItemTagMutation, { data, loading, error }] = useDeleteWorkspaceItemTagMutation({
 *   variables: {
 *      workspaceItemId: // value for 'workspaceItemId'
 *      tagId: // value for 'tagId'
 *   },
 * });
 */
export function useDeleteWorkspaceItemTagMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWorkspaceItemTagMutation, DeleteWorkspaceItemTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWorkspaceItemTagMutation, DeleteWorkspaceItemTagMutationVariables>(DeleteWorkspaceItemTagDocument, options);
      }
export type DeleteWorkspaceItemTagMutationHookResult = ReturnType<typeof useDeleteWorkspaceItemTagMutation>;
export type DeleteWorkspaceItemTagMutationResult = Apollo.MutationResult<DeleteWorkspaceItemTagMutation>;
export type DeleteWorkspaceItemTagMutationOptions = Apollo.BaseMutationOptions<DeleteWorkspaceItemTagMutation, DeleteWorkspaceItemTagMutationVariables>;
export const SaveWorkspaceItemsToBoardDocument = gql`
    mutation saveWorkspaceItemsToBoard($workspaceItemIds: [String!]!, $boardId: String!, $workspaceId: String!) {
  saveWorkspaceItemsToBoard(
    input: {workspaceItemIds: $workspaceItemIds, boardId: $boardId, workspaceId: $workspaceId}
  ) {
    status
  }
}
    `;
export type SaveWorkspaceItemsToBoardMutationFn = Apollo.MutationFunction<SaveWorkspaceItemsToBoardMutation, SaveWorkspaceItemsToBoardMutationVariables>;

/**
 * __useSaveWorkspaceItemsToBoardMutation__
 *
 * To run a mutation, you first call `useSaveWorkspaceItemsToBoardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveWorkspaceItemsToBoardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveWorkspaceItemsToBoardMutation, { data, loading, error }] = useSaveWorkspaceItemsToBoardMutation({
 *   variables: {
 *      workspaceItemIds: // value for 'workspaceItemIds'
 *      boardId: // value for 'boardId'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useSaveWorkspaceItemsToBoardMutation(baseOptions?: Apollo.MutationHookOptions<SaveWorkspaceItemsToBoardMutation, SaveWorkspaceItemsToBoardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveWorkspaceItemsToBoardMutation, SaveWorkspaceItemsToBoardMutationVariables>(SaveWorkspaceItemsToBoardDocument, options);
      }
export type SaveWorkspaceItemsToBoardMutationHookResult = ReturnType<typeof useSaveWorkspaceItemsToBoardMutation>;
export type SaveWorkspaceItemsToBoardMutationResult = Apollo.MutationResult<SaveWorkspaceItemsToBoardMutation>;
export type SaveWorkspaceItemsToBoardMutationOptions = Apollo.BaseMutationOptions<SaveWorkspaceItemsToBoardMutation, SaveWorkspaceItemsToBoardMutationVariables>;
export const WorkspaceItemsDocument = gql`
    query workspaceItems($workspaceId: String!, $first: Int!, $cursor: String) {
  workspaceItems(
    input: {workspaceId: $workspaceId, first: $first, cursor: $cursor}
  ) {
    pageInfo {
      nextCursor
    }
    workspaceItems {
      ...WorkspaceItemGridFields
    }
  }
}
    ${WorkspaceItemGridFieldsFragmentDoc}`;

/**
 * __useWorkspaceItemsQuery__
 *
 * To run a query within a React component, call `useWorkspaceItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceItemsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useWorkspaceItemsQuery(baseOptions: Apollo.QueryHookOptions<WorkspaceItemsQuery, WorkspaceItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkspaceItemsQuery, WorkspaceItemsQueryVariables>(WorkspaceItemsDocument, options);
      }
export function useWorkspaceItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkspaceItemsQuery, WorkspaceItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkspaceItemsQuery, WorkspaceItemsQueryVariables>(WorkspaceItemsDocument, options);
        }
export type WorkspaceItemsQueryHookResult = ReturnType<typeof useWorkspaceItemsQuery>;
export type WorkspaceItemsLazyQueryHookResult = ReturnType<typeof useWorkspaceItemsLazyQuery>;
export type WorkspaceItemsQueryResult = Apollo.QueryResult<WorkspaceItemsQuery, WorkspaceItemsQueryVariables>;
export const SearchWorkspaceItemsDocument = gql`
    query searchWorkspaceItems($query: String, $first: Int!, $cursor: String, $filters: SearchWorkspaceItemsFilterInput, $scope: SearchWorkspaceItemsScope!) {
  searchWorkspaceItems(
    input: {query: $query, first: $first, cursor: $cursor, filters: $filters, scope: $scope}
  ) {
    pageInfo {
      nextCursor
    }
    workspaceItems {
      ...WorkspaceItemGridFields
    }
  }
}
    ${WorkspaceItemGridFieldsFragmentDoc}`;

/**
 * __useSearchWorkspaceItemsQuery__
 *
 * To run a query within a React component, call `useSearchWorkspaceItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchWorkspaceItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchWorkspaceItemsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *      filters: // value for 'filters'
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function useSearchWorkspaceItemsQuery(baseOptions: Apollo.QueryHookOptions<SearchWorkspaceItemsQuery, SearchWorkspaceItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchWorkspaceItemsQuery, SearchWorkspaceItemsQueryVariables>(SearchWorkspaceItemsDocument, options);
      }
export function useSearchWorkspaceItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchWorkspaceItemsQuery, SearchWorkspaceItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchWorkspaceItemsQuery, SearchWorkspaceItemsQueryVariables>(SearchWorkspaceItemsDocument, options);
        }
export type SearchWorkspaceItemsQueryHookResult = ReturnType<typeof useSearchWorkspaceItemsQuery>;
export type SearchWorkspaceItemsLazyQueryHookResult = ReturnType<typeof useSearchWorkspaceItemsLazyQuery>;
export type SearchWorkspaceItemsQueryResult = Apollo.QueryResult<SearchWorkspaceItemsQuery, SearchWorkspaceItemsQueryVariables>;
export const SimilarWorkspaceItemsDocument = gql`
    query similarWorkspaceItems($workspaceItemId: String!, $scope: SimilarWorkspaceItemsScope!, $workspaceId: String, $cursor: String) {
  similarWorkspaceItems(
    input: {workspaceItemId: $workspaceItemId, scope: $scope, workspaceId: $workspaceId, cursor: $cursor}
  ) {
    pageInfo {
      nextCursor
    }
    similarItems {
      ...WorkspaceItemGridFields
    }
  }
}
    ${WorkspaceItemGridFieldsFragmentDoc}`;

/**
 * __useSimilarWorkspaceItemsQuery__
 *
 * To run a query within a React component, call `useSimilarWorkspaceItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimilarWorkspaceItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimilarWorkspaceItemsQuery({
 *   variables: {
 *      workspaceItemId: // value for 'workspaceItemId'
 *      scope: // value for 'scope'
 *      workspaceId: // value for 'workspaceId'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useSimilarWorkspaceItemsQuery(baseOptions: Apollo.QueryHookOptions<SimilarWorkspaceItemsQuery, SimilarWorkspaceItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SimilarWorkspaceItemsQuery, SimilarWorkspaceItemsQueryVariables>(SimilarWorkspaceItemsDocument, options);
      }
export function useSimilarWorkspaceItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SimilarWorkspaceItemsQuery, SimilarWorkspaceItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SimilarWorkspaceItemsQuery, SimilarWorkspaceItemsQueryVariables>(SimilarWorkspaceItemsDocument, options);
        }
export type SimilarWorkspaceItemsQueryHookResult = ReturnType<typeof useSimilarWorkspaceItemsQuery>;
export type SimilarWorkspaceItemsLazyQueryHookResult = ReturnType<typeof useSimilarWorkspaceItemsLazyQuery>;
export type SimilarWorkspaceItemsQueryResult = Apollo.QueryResult<SimilarWorkspaceItemsQuery, SimilarWorkspaceItemsQueryVariables>;
export const ImageSearchWorkspaceItemsDocument = gql`
    query imageSearchWorkspaceItems($workspaceItemId: String!, $query: String!, $workspaceId: String, $scope: SearchWorkspaceItemsScope!, $first: Int!, $cursor: String) {
  imageSearchWorkspaceItems(
    input: {workspaceItemId: $workspaceItemId, query: $query, workspaceId: $workspaceId, scope: $scope, first: $first, cursor: $cursor}
  ) {
    pageInfo {
      nextCursor
    }
    workspaceItems {
      ...WorkspaceItemGridFields
    }
  }
}
    ${WorkspaceItemGridFieldsFragmentDoc}`;

/**
 * __useImageSearchWorkspaceItemsQuery__
 *
 * To run a query within a React component, call `useImageSearchWorkspaceItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useImageSearchWorkspaceItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImageSearchWorkspaceItemsQuery({
 *   variables: {
 *      workspaceItemId: // value for 'workspaceItemId'
 *      query: // value for 'query'
 *      workspaceId: // value for 'workspaceId'
 *      scope: // value for 'scope'
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useImageSearchWorkspaceItemsQuery(baseOptions: Apollo.QueryHookOptions<ImageSearchWorkspaceItemsQuery, ImageSearchWorkspaceItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ImageSearchWorkspaceItemsQuery, ImageSearchWorkspaceItemsQueryVariables>(ImageSearchWorkspaceItemsDocument, options);
      }
export function useImageSearchWorkspaceItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ImageSearchWorkspaceItemsQuery, ImageSearchWorkspaceItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ImageSearchWorkspaceItemsQuery, ImageSearchWorkspaceItemsQueryVariables>(ImageSearchWorkspaceItemsDocument, options);
        }
export type ImageSearchWorkspaceItemsQueryHookResult = ReturnType<typeof useImageSearchWorkspaceItemsQuery>;
export type ImageSearchWorkspaceItemsLazyQueryHookResult = ReturnType<typeof useImageSearchWorkspaceItemsLazyQuery>;
export type ImageSearchWorkspaceItemsQueryResult = Apollo.QueryResult<ImageSearchWorkspaceItemsQuery, ImageSearchWorkspaceItemsQueryVariables>;
export const WorkspacePublicByUrlDocument = gql`
    query workspacePublicByUrl($url: String!) {
  workspacePublicByUrl(url: $url) {
    ... on WorkspacePublic {
      ...WorkspacePublicBasicFields
    }
    ... on Workspace {
      ...WorkspaceThumbFields
    }
  }
}
    ${WorkspacePublicBasicFieldsFragmentDoc}
${WorkspaceThumbFieldsFragmentDoc}`;

/**
 * __useWorkspacePublicByUrlQuery__
 *
 * To run a query within a React component, call `useWorkspacePublicByUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspacePublicByUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspacePublicByUrlQuery({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useWorkspacePublicByUrlQuery(baseOptions: Apollo.QueryHookOptions<WorkspacePublicByUrlQuery, WorkspacePublicByUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkspacePublicByUrlQuery, WorkspacePublicByUrlQueryVariables>(WorkspacePublicByUrlDocument, options);
      }
export function useWorkspacePublicByUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkspacePublicByUrlQuery, WorkspacePublicByUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkspacePublicByUrlQuery, WorkspacePublicByUrlQueryVariables>(WorkspacePublicByUrlDocument, options);
        }
export type WorkspacePublicByUrlQueryHookResult = ReturnType<typeof useWorkspacePublicByUrlQuery>;
export type WorkspacePublicByUrlLazyQueryHookResult = ReturnType<typeof useWorkspacePublicByUrlLazyQuery>;
export type WorkspacePublicByUrlQueryResult = Apollo.QueryResult<WorkspacePublicByUrlQuery, WorkspacePublicByUrlQueryVariables>;