import { ThreeDotsVertical } from '@styled-icons/bootstrap/ThreeDotsVertical'
import Instagram from 'iconoir-react/dist/Instagram'
import Lock from 'iconoir-react/dist/Lock'
import Trash from 'iconoir-react/dist/Trash'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFirestore } from 'react-redux-firebase'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

import Button from 'components/common/Button'
import Dropdown from 'components/common/Dropdown'
import Flex from 'components/common/Flex'
import IconButton from 'components/common/IconButton'
import Modal from 'components/common/Modal'
import PermissionWrapper from 'components/common/PermissionWrapper'
import { Spacer } from 'components/common/Spacer'
import { Text } from 'components/common/Text'
import { Tooltip } from 'components/common/Tooltip/Tooltip'
import useToastMessages from 'components/toast/useToastMessages'
import { useUpdateWorkspaceItemsAccessLevelMutation } from 'generated/graphql'
import { trackSocialAssetCreationOpened } from 'helpers/tracking/tracking'
import useWorkspacePermissions from 'hooks/useWorkspacePermissions'
import { RootState } from 'store'
import { setActiveExport, setSocialAssetModalOpen } from 'store/content/actions'

type UploadSessionMenuProps = {
  uploadSessionId: string
  workspaceId: string
  isPublicUpload: boolean
}

export const UploadSessionMenu = (props: UploadSessionMenuProps) => {
  const { reportSuccess } = useToastMessages()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const firestore = useFirestore()
  const userIsAllowedTo = useWorkspacePermissions([
    'DELETE_ITEM',
    'EDIT_ITEM',
    'CREATE_SOCIAL_ASSETS',
  ])

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  const activeWorkspace = useSelector(
    (state: RootState) => state.content.activeWorkspace!
  )

  const [updateWorkspaceItemsAccessLevel] =
    useUpdateWorkspaceItemsAccessLevelMutation()

  const deleteUploadSession = async () => {
    await firestore.update(
      {
        collection: 'uploadSessions',
        doc: props.uploadSessionId,
      },
      { shouldDelete: true }
    )
    navigate(`/${activeWorkspace.url}/library/upload-events`)
  }

  const handleEditItemsAccessLevel = () => {
    const newAccessLevel = props.isPublicUpload ? 'PRIVATE' : 'PUBLIC'

    updateWorkspaceItemsAccessLevel({
      variables: {
        uploadSessionId: props.uploadSessionId,
        workspaceId: props.workspaceId,
        accessLevel: newAccessLevel,
      },
    })
    reportSuccess(
      <p>
        Making the items of your
        <br />
        Upload event {newAccessLevel === 'PRIVATE' ? 'private' : 'public'} ...
      </p>
    )
  }

  return (
    <>
      <StyledUploadSessionMenuWrapper>
        <IconButton menuNoSpace Icon={ThreeDotsVertical} menuWidth={210}>
          <PermissionWrapper
            css="display:block!important"
            feature="Create social assets"
            isAllowed={!!userIsAllowedTo.CREATE_SOCIAL_ASSETS}
          >
            <Dropdown.Item
              Icon={Instagram}
              title="Create social assets"
              onClick={() => {
                trackSocialAssetCreationOpened({
                  source: 'UPLOAD_SESSION',
                })
                dispatch(
                  setActiveExport({
                    uploadSessionId: props.uploadSessionId,
                  })
                )
                dispatch(setSocialAssetModalOpen(true))
              }}
            />
          </PermissionWrapper>
          <PermissionWrapper
            css="display:block!important"
            feature="Edit items access level"
            isAllowed={!!userIsAllowedTo.EDIT_ITEM}
          >
            <Dropdown.Item Icon={Lock} onClick={handleEditItemsAccessLevel}>
              <Tooltip
                isDisabled={!userIsAllowedTo.EDIT_ITEM || props.isPublicUpload}
                position="top"
                title="If you make items public, they will be featured in Discover and become accessible for users outside your workspace"
              >
                {`Make items ${props.isPublicUpload ? 'private' : 'public'}`}
              </Tooltip>
            </Dropdown.Item>
          </PermissionWrapper>
          <PermissionWrapper
            css="display:block!important"
            feature="Delete upload event"
            isAllowed={!!userIsAllowedTo.DELETE_ITEM}
          >
            <Dropdown.Item
              Icon={Trash}
              title="Delete"
              onClick={() => {
                setDeleteModalOpen(true)
              }}
            />
          </PermissionWrapper>
        </IconButton>
      </StyledUploadSessionMenuWrapper>

      <ConfirmDeleteModal
        heading="Are you sure?"
        text="You will permanently delete this upload event and all its content."
        isOpen={!!deleteModalOpen}
        close={() => setDeleteModalOpen(false)}
        onConfirm={deleteUploadSession}
      />
    </>
  )
}

type ConfirmDeleteModalProps = {
  isOpen: boolean
  close: () => void
  heading: string
  text: string
  onConfirm: () => void
}

const ConfirmDeleteModal = ({
  isOpen,
  close,
  heading,
  text,
  onConfirm,
}: ConfirmDeleteModalProps) => {
  return (
    <Modal isOpen={isOpen} close={close}>
      <Modal.ContentWrapper>
        <Text bold color="neutral.0" size="lg">
          {heading}
        </Text>
        <Spacer factor={1} />
        <Text color="neutral.1" size="sm">
          {text}
        </Text>
        <Spacer factor={2} />
        <Flex css="width:100%">
          <Button
            bold
            fullWidth
            variant="danger"
            onClick={() => {
              onConfirm()
              close()
            }}
          >
            Delete
          </Button>
          <Spacer factor={2} axis="x" />

          <Button fullWidth onClick={close}>
            Cancel
          </Button>
        </Flex>
      </Modal.ContentWrapper>
    </Modal>
  )
}

const StyledUploadSessionMenuWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`
