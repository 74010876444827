/* eslint-disable react-hooks/exhaustive-deps */
import { client } from 'apollo'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { logout } from './auth'

export const Logout = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    logout().then(() => {
      dispatch({ type: 'USER_LOGOUT' }) // clear all redux states
      client.resetStore() // clear apollo cache
      if (typeof window !== 'undefined') {
        const baseUrl = new URL(window.location.href)
        window.location.assign(`${baseUrl.origin}/auth/redirect`)
      }
    })
  }, [])

  return null
}
