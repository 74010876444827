import { KeyboardReturn } from '@styled-icons/material/KeyboardReturn'
import { useCallback, useEffect } from 'react'

import { useMagicBoardsQuery } from 'generated/graphql'
import { useActiveWorkspace } from 'hooks/useActiveWorkspace'

import { capitalizeFirstLetter, pathSafe } from '../../helpers/utilityFunctions'
import {
  SuggestionsContainer,
  TagSuggestion,
  TagSuggestionText,
  TagSuggestionTextDetail,
} from './ItemTagStyles'

interface TagProps {
  description: string
  id?: string
  score?: number
}

interface ItemTagSuggestionsProps {
  addTag: (tag: TagProps) => void
  setFocus: (newFocusTag: TagProps, newFocusIndex: number) => void
  focusIndex: number
  activeTagIds: string[]
  input: string
}

const ItemTagSuggestions = ({
  addTag,
  setFocus,
  focusIndex,
  activeTagIds,
  input,
}: ItemTagSuggestionsProps) => {
  const activeWorkspace = useActiveWorkspace()!

  const { data } = useMagicBoardsQuery({
    variables: {
      freeTextSearch: input,
      workspaceId: activeWorkspace.id,
      first: 5,
    },
  })
  const itemTagSuggestions = data?.magicBoards.magicBoards || []

  const setFocusedSuggestion = useCallback(
    (i: number) => {
      setFocus(itemTagSuggestions[i], i)
    },
    [itemTagSuggestions, setFocus]
  )

  useEffect(() => {
    function moveFocus(direction: number) {
      let newIndex = focusIndex + direction
      if (newIndex > itemTagSuggestions.length - 1) newIndex = 0
      if (newIndex < -1) newIndex = itemTagSuggestions.length - 1
      setFocusedSuggestion(newIndex)
    }
    function handleKeyDown(e: any) {
      if (e.key === 'ArrowDown') {
        moveFocus(1)
        e.preventDefault()
      }
      if (e.key === 'ArrowUp') {
        moveFocus(-1)
        e.preventDefault()
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [focusIndex, setFocusedSuggestion, itemTagSuggestions])

  useEffect(() => {
    const matchingSuggestionIndex = itemTagSuggestions.findIndex(
      (tag) => tag.description === input
    )
    if (matchingSuggestionIndex !== -1) {
      setFocusedSuggestion(matchingSuggestionIndex)
    }
  }, [input, itemTagSuggestions])

  if (itemTagSuggestions.length < 1) return null
  return (
    <SuggestionsContainer>
      {itemTagSuggestions.map((tag, i) => {
        const alreadyAdded = activeTagIds.includes(pathSafe(tag.id))
        return (
          <TagSuggestion
            key={tag.id}
            onClick={alreadyAdded ? undefined : () => addTag(tag)}
            hasFocus={focusIndex === i}
            alreadyAdded={alreadyAdded}
            onMouseMove={() => setFocusedSuggestion(i)}
          >
            <TagSuggestionText>
              {capitalizeFirstLetter(tag.description)}
              {alreadyAdded ? (
                <TagSuggestionTextDetail>Already added</TagSuggestionTextDetail>
              ) : (
                <TagSuggestionTextDetail>
                  {tag.count} items
                </TagSuggestionTextDetail>
              )}
            </TagSuggestionText>

            {focusIndex === i && !alreadyAdded && (
              <KeyboardReturn style={{ width: 16, height: 16 }} />
            )}
          </TagSuggestion>
        )
      })}
    </SuggestionsContainer>
  )
}

export default ItemTagSuggestions
