import { useLocation, useNavigate, useParams } from 'react-router-dom'

// helper component to replace old react router withRouter component

// eslint-disable-next-line @typescript-eslint/naming-convention
export function withRouter<T>(Component: React.ComponentType<T>) {
  function ComponentWithRouterProp(props: T) {
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()
    return (
      <Component
        {...props}
        location={location}
        navigate={navigate}
        params={params}
      />
    )
  }

  return ComponentWithRouterProp
}
