import { useCallback, useEffect, useRef, useState } from 'react'

const useSafeState = <T>(
  initialState: T
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [state, unsafeSetState] = useState(initialState)

  const unmounted = useRef(false)

  useEffect(
    () => () => {
      unmounted.current = true
    },
    []
  )

  const setState = useCallback((value: React.SetStateAction<T>) => {
    if (unmounted.current) {
      return
    }
    unsafeSetState(value)
  }, [])

  return [state, setState]
}

export default useSafeState
