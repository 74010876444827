import React from 'react'
import styled, { css } from 'styled-components'

import { Button } from '../SettingsStyles'

export const MemberListItem = ({
  name,
  photoURL = null,
  initial,
  email,
  buttonText = null,
  onClick = () => null,
  allowedToEditMember,
  ...props
}) => (
  <ListItem {...props}>
    <Profile>
      {photoURL ? (
        <ThumbImage src={photoURL} />
      ) : (
        <ThumbInitial>{initial}</ThumbInitial>
      )}
      <div>
        <Name>{name}</Name>
        <Email>{email}</Email>
      </div>
    </Profile>
    <div>
      {buttonText != null && (
        <Button
          subdued
          style={{ marginTop: 0, marginBottom: 0, minWidth: 130 }}
          onClick={onClick}
          disabled={!allowedToEditMember}
        >
          {buttonText}
        </Button>
      )}
    </div>
  </ListItem>
)

const Profile = styled.div`
  display: flex;
  align-items: center;
`

const Name = styled.span`
  ${({ theme }) => css`
    display: block;
    color: ${theme.colors.text.neutral[0]};
    font-size: ${theme.fontSizes.sm};
  `}
`
const Email = styled.span`
  ${({ theme }) => css`
    display: block;
    color: ${theme.colors.text.neutral[2]};
    font-size: ${theme.fontSizes.sm};
  `}
`

const ThumbImage = styled.img`
  border-radius: 10px;
  height: 60px;
  width: 60px;
  margin-right: 16px;
`

const ThumbInitial = styled.div`
  ${({ theme }) => css`
    height: 60px;
    width: 60px;
    margin-right: 16px;
    border: ${`${theme.border.thin} ${theme.colors.discreet}`};
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${theme.fontSizes.lg};
    font-weight: ${theme.fontWeight.bold};
    pointer-events: none;
    text-transform: uppercase;
  `}
`

const ListItem = styled.li`
  ${({ theme, withTopDivider }) => css`
    border-top: ${withTopDivider
      ? `${theme.border.thin} ${theme.colors.discreet}`
      : 'none'};
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
  `}
`

export const MemberList = styled.ul`
  margin: 0;
  padding: 0;
`

export const MemberActionList = styled.ul`
  margin: 0;
  padding: 0;
`

export const MemberActionListItem = styled.li`
  ${({ theme }) => css`
    display: block;
    list-style: none;
    color: ${theme.colors.text.neutral[2]};
    min-width: 140px;
    border-bottom: ${`${theme.border.thin} ${theme.colors.discreet}`};
    padding: 8px;
    :last-child {
      border-bottom: none;
    }
    :hover {
      color: ${(props) =>
        props.role === 'irreversible'
          ? props.theme.colors.redLighter
          : props.theme.colors.accent[2]};
    }
  `}
`
