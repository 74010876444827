import { WorkspaceItemGridFieldsFragment } from 'generated/graphql'

import type { ItemData } from '../useGetItemData'

export const convertGridItem = ({
  gridItem,
}: {
  gridItem: WorkspaceItemGridFieldsFragment
}): ItemData => {
  return {
    ...gridItem,
    userRole: 'READER',
    tags: [],
    webUrl: '',
    openCount: 0,
    commentCount: 0,
    createdBy: null,
    createdAt: '',
    updatedAt: '',
    title: '',
    colors: [],
    itemSourceInfo: {
      type: 'UPLOAD',
      videoExtractInfo: null,
      extensionImportInfo: null,
    },
  }
}
