import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useUpdateRecentTagsMutation } from 'generated/graphql'
import { RootState } from 'store'

import { ItemData } from './useGetItemData'

type UseUpdateUserRecentTagsProps = {
  itemData?: ItemData
}

const useUpdateUserRecentTags = ({
  itemData,
}: UseUpdateUserRecentTagsProps) => {
  const [updateRecentTagsMutation] = useUpdateRecentTagsMutation()
  const isLoggedIn = useSelector(
    (store: RootState) => !!store.firebase.auth.uid
  )

  useEffect(() => {
    if (itemData?.tags.length && isLoggedIn) {
      const highscoreTags = [...itemData.tags]
        .sort((a, b) => b.score - a.score)
        .slice(0, 5)
        .map(({ description, id }) => ({ id, description }))
      updateRecentTagsMutation({ variables: { tags: highscoreTags } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemData?.id, itemData?.tags]) // we rerun if the item id change or if the tags was updated (this is because on first load the tags is an empty array on itemData)
}

export default useUpdateUserRecentTags
