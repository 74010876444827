import { ArrowLeftShort } from '@styled-icons/bootstrap/ArrowLeftShort'
import { QuestionCircle } from '@styled-icons/bootstrap/QuestionCircle'
import { Dropbox as DropboxIcon } from '@styled-icons/boxicons-logos/Dropbox'
import { ChevronDown } from '@styled-icons/boxicons-regular/ChevronDown'
import { PlusCircle } from '@styled-icons/boxicons-regular/PlusCircle'
import { Upload as UploadIcon } from '@styled-icons/boxicons-regular/Upload'
import { Cross } from '@styled-icons/entypo/Cross'
import { Folder } from '@styled-icons/heroicons-outline/Folder'
import { Tag as TagIcon } from '@styled-icons/heroicons-outline/Tag'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import styled, { DefaultTheme, css, useTheme } from 'styled-components'
import { v4 as uuid } from 'uuid'

import GoogleDriveIcon from 'assets/img/GoogleDrive.png'
import { ReactComponent as DropboxLogo } from 'assets/svg/Dropbox.svg'
import Button from 'components/common/Button'
import { Collapsible } from 'components/common/Collapsible'
import Dropdown from 'components/common/Dropdown'
import { Margin } from 'components/common/Margin'
import ProgressCircle from 'components/common/ProgressCircle'
import Spinner from 'components/common/Spinner'
import { Text } from 'components/common/Text'
import { Tooltip } from 'components/common/Tooltip/Tooltip'
import useToastMessages from 'components/toast/useToastMessages'
import callCloudFunction from 'helpers/callCloudFunction'
import { getSingularPluralWord } from 'helpers/getSingularPluralWord'
import getSupportedFileExtensions from 'helpers/getSupportedFileExtensions'
import {
  trackUploadOptionSelected,
  trackUploadWithGoogleDriveInterest,
} from 'helpers/tracking/tracking'
import useIsMobile from 'hooks/useIsMobile'

import useWorkspacePermissions from '../../hooks/useWorkspacePermissions'
import useUpgradeNavigation from '../upgrade/useUpgradeNavigation'
import { AddTags } from './AddTags'
import { FullScreen } from './UploadStyles'
import {
  ConstrainInfo,
  OutputFolderAndFiles,
  buildFolderAndFiles,
  constrainFileUpload,
  getExtensionByFileName,
  getFormattedSize,
  getMappedFiles,
} from './fileHelpers'
import type {
  CustomDropboxFile,
  CustomFile,
  MappedFiles,
  UploadOptions,
  UploadSourceView,
  UploadStatus,
} from './types'
import {
  MaxBytesReachedPopup,
  MaxFileCountReachedPopup,
  WorkspaceLimitReachedPopup,
} from './uploadPopups'
import { useDropboxPicker } from './useDropboxPicker'
import useFileValidation, { MAX_FILE_SIZE } from './useFileValidation'
import { useUploadSessionsRemoteUpload } from './useUploadSessionsRemoteUpload'
import useUploadTracking from './useUploadTracking'
import useUploadWorkspaces from './useUploadWorkspaces'

interface DroppedFilesProps {
  lastDrop: CustomFile[]
  setLastDrop: (files: CustomFile[]) => void
  closeView: () => void
  setUploadIsConstrained: React.Dispatch<React.SetStateAction<boolean>>
  createNewSession: (
    files: CustomFile[],
    workspaceId: string,
    uploadOptions: UploadOptions,
    uploadFlowTrackingId: string,
    tags?: { id: string; description: string }[]
  ) => void
  uploadItemsToBoard: (itemIds: string[], workspaceId: string) => void
  boardId?: string
  projectId?: string
  openFilePicker: () => void
  uploadStatus: UploadStatus
  setUploadStatus: React.Dispatch<React.SetStateAction<UploadStatus>>
  isInSetup?: boolean
  uploadSourceView: UploadSourceView
  setUploadSourceView: React.Dispatch<React.SetStateAction<UploadSourceView>>
}

interface GroupedInvalidFiles {
  [key: string]: Array<CustomFile>
}
interface GroupedInvalidFilesCount {
  [key: string]: number
}

const DroppedFiles: React.FC<DroppedFilesProps> = ({
  lastDrop,
  setLastDrop,
  closeView,
  setUploadIsConstrained,
  createNewSession,
  uploadItemsToBoard,
  boardId,
  projectId,
  openFilePicker,
  uploadStatus,
  setUploadStatus,
  isInSetup,
  uploadSourceView,
  setUploadSourceView,
}) => {
  const { addRemoteUploadLocalState, removeRemoteUploadLocalState } =
    useUploadSessionsRemoteUpload()
  const { track, uploadFlowTrackingId } = useUploadTracking()
  const { reportSuccess, reportError } = useToastMessages()
  const [outputFolderAndFiles, setOutputFolderAndFiles] =
    useState<OutputFolderAndFiles>({})
  const [droppedFiles, setDroppedFiles] = useState<MappedFiles>({})
  const [validatedFiles, setValidatedFiles] = useState<MappedFiles>({})
  const [invalidatedFiles, setInvalidatedFiles] = useState<MappedFiles>({})
  const [isValidating, setIsValidating] = useState(false)
  const [totalUploadSize, setTotalUploadSize] = useState(0)
  const [uploadConstrainInfo, setUploadConstrainInfo] =
    useState<ConstrainInfo>(null)
  const [isWorkspacesDropdownVisible, setIsWorkspacesDropdownVisible] =
    useState(false)
  const [isPublicUpload, setIsPublicUpload] = useState(false)

  const [validationProgress, setValidationProgress] = useState(0) // from 0-1
  const [duplicatesAddedToBoard, setDuplicatesAddedToBoard] = useState(0)
  const [nbrOfInvalidFiles, setNbrOfInvalidFiles] = useState(0)
  const [existsInDbCount, setExistsInDbCount] = useState(0)
  const { open: openUpgradePanel } = useUpgradeNavigation()
  const userAllowedTo = useWorkspacePermissions(['ADD_ITEM_TAG'])
  const [tagsToAddToUploads, setTagsToAddToUploads] = useState<
    { id: string; description: string }[]
  >([])
  const [isAddTagsVisible, setIsAddTagsVisible] = useState(false)

  const theme = useTheme()
  const isMobile = useIsMobile()

  const handleDropboxPickedFiles = async (files: CustomDropboxFile[]) => {
    setUploadSourceView('dropbox')
    validateFiles(getMappedFiles(files), {
      hasNewFiles: true,
      shouldValidateDuplicates: false, // we validate duplicates by hash in our backend
    })

    track.trackUploadFlowItemsAdded({
      nbrOfItems: files.length,
      trackingSetupSessionId,
      uploadType: uploadSourceView,
    })
  }

  const handleDropboxAuthSuccess = () => {
    reportSuccess(
      'You successfully connected to Dropbox! Start importing items by clicking the "Dropbox" button ☝️',
      undefined,
      5000
    )
  }

  const {
    // isConnected: isConnectedToDropbox,
    isConnecting: isConnectingToDropbox,
    launch: launchDropbox,
  } = useDropboxPicker({
    onPickFiles: handleDropboxPickedFiles,
    onAuthSuccess: handleDropboxAuthSuccess,
    linkType: 'preview',
    folderselect: false, // TODO: Would be nice to use this? Disabled right now as we dont have metadata like nbr of files and bytes
    multiselect: true,
    extensions: getSupportedFileExtensions().map((ext: string) => `.${ext}`),
  })

  // eslint-disable-next-line
  const trackingSetupSessionId = new URLSearchParams(location.search).get(
    'sessionId'
  )

  const {
    selectedWorkspace,
    selectedWorkspaceMemberCount,
    changeSelectedWorkspace,
    availableWorkspaces,
    remainingUploads,
    workspacePlan,
    nextWorkspacePlan,
  } = useUploadWorkspaces(() => {
    // if selected workspace change, we reset the validation info and re-validate all dropped files
    resetValidationInfo()
    setTimeout(() => {
      validateFiles(droppedFiles, {
        hasNewFiles: false,
        shouldValidateDuplicates: uploadSourceView === 'desktop',
      })
    }, 200)
    track.trackUploadFlowWorkspaceChanged({
      toWorkspaceId: selectedWorkspace.id,
      uploadType: uploadSourceView,
    })
  })

  const { existsInDb, isUniqueDrop, isValidSizeAndType, batchRunFunction } =
    useFileValidation({
      workspaceId: selectedWorkspace.id,
      droppedFiles,
    })

  useEffect(() => {
    track.trackUploadFlowStarted({
      workspaceId: selectedWorkspace.id,
      boardId,
      projectId,
      trackingSetupSessionId: trackingSetupSessionId || undefined,
      uploadType: uploadSourceView,
    })
  }, [])

  const resetValidationInfo = () => {
    setOutputFolderAndFiles({})
    setValidatedFiles({})
    setValidationProgress(0)
    setExistsInDbCount(0)
    setDuplicatesAddedToBoard(0)
    setIsValidating(false)
    setTotalUploadSize(0)
    setUploadIsConstrained(false)
    setUploadConstrainInfo(null)
  }

  const reset = () => {
    resetValidationInfo()
    setNbrOfInvalidFiles(0) // We keep this outside of 'resetValidationInfo' as we do not check valid files again after a workspace change
    setInvalidatedFiles({})
    setDroppedFiles({})
    setUploadStatus('IDLE')
    setLastDrop([])
    closeView()
    setUploadSourceView(null)
    setTagsToAddToUploads([])
  }

  const getUniqueFiles = (mappedFiles: MappedFiles) => {
    return Object.entries(mappedFiles).reduce<MappedFiles>(
      (acc, [key, file]) => {
        if (!isUniqueDrop(file)) return acc
        acc[key] = file
        return acc
      },
      {}
    )
  }

  const getValidFormattedFiles = (mappedFiles: MappedFiles) => {
    const validSizeAndTypeFiles: MappedFiles = {}
    const invalidFilesRecord: MappedFiles = {}
    Object.entries(mappedFiles).reduce<MappedFiles>((acc, [key, file]) => {
      const isValid = isValidSizeAndType(file, selectedWorkspace.id)
      if (isValid) {
        acc[key] = file
        validSizeAndTypeFiles[key] = file
        return acc
      }
      invalidFilesRecord[key] = file
      return acc
    }, {})
    return {
      validSizeAndTypeFiles,
      invalidFilesRecord,
    }
  }

  const getNonDuplicatedFiles = async (
    mappedFiles: Record<string, CustomFile>
  ) => {
    setValidationProgress(0)
    const duplicateCheckedFiles = await batchRunFunction(
      mappedFiles,
      0,
      [],
      100,
      existsInDb,
      setValidationProgress
    )
    const duplicateIds: string[] = duplicateCheckedFiles
      .filter((file) => !!file.response.duplicateItemId)
      .map(({ response: { duplicateItemId } }) => duplicateItemId)

    const nonDuplicatedMappedFiles = duplicateCheckedFiles
      .filter((file) => file.response.isValid)
      .reduce<MappedFiles>((acc, duplicateFileResponse) => {
        acc[duplicateFileResponse.key] = duplicateFileResponse.file
        return acc
      }, {})

    setValidationProgress(1)

    return {
      nonDuplicatedMappedFiles,
      duplicateIds,
      nbrOfDuplicates: duplicateIds.length,
    }
  }

  const updateConstrains = (mappedFiles: MappedFiles) => {
    const { isConstrained, totalSize, constrainedResult, constrainInfo } =
      constrainFileUpload(
        mappedFiles,
        remainingUploads,
        track,
        uploadSourceView,
        workspacePlan
      )
    setTotalUploadSize(totalSize)
    setUploadIsConstrained(isConstrained)
    setUploadConstrainInfo(constrainInfo)
    return constrainedResult
  }

  const filterFilesFormats = (mappedFiles: MappedFiles) => {
    const uniqueMappedFiles = getUniqueFiles(mappedFiles)
    const { validSizeAndTypeFiles, invalidFilesRecord } =
      getValidFormattedFiles(uniqueMappedFiles)
    return { validSizeAndTypeFiles, invalidFilesRecord }
  }

  const subGroupMappedFiles = (fileRecord: MappedFiles) => {
    return Object.keys(fileRecord).reduce((grouped, fileKey) => {
      const extension = getExtensionByFileName(fileRecord[fileKey].name)
      const g: any = { ...grouped }
      // ^ needed as the key names shall be the extension
      if (extension) {
        g[extension] = g[extension] || []
        g[extension].push(fileRecord[fileKey])
      }
      return g
    }, {})
  }

  const validateFiles = async (
    mappedFiles: MappedFiles,
    {
      hasNewFiles,
      shouldValidateDuplicates,
    }: { hasNewFiles: boolean; shouldValidateDuplicates: boolean }
  ) => {
    setIsValidating(true)
    setUploadStatus('CHECKING_DUPLICATES')

    // we only filter if we there are new incoming files
    let validMappedFiles = hasNewFiles
      ? filterFilesFormats(mappedFiles).validSizeAndTypeFiles
      : mappedFiles
    const invalidMappedFilesRecord =
      filterFilesFormats(mappedFiles).invalidFilesRecord

    if (shouldValidateDuplicates) {
      const { duplicateIds, nonDuplicatedMappedFiles, nbrOfDuplicates } =
        await getNonDuplicatedFiles(
          validMappedFiles as Record<string, CustomFile>
        )
      // if we upload directly to board and user have duplicates we add them directly to board
      if (boardId) {
        uploadItemsToBoard(duplicateIds, selectedWorkspace.id)
        setDuplicatesAddedToBoard((prev) => prev + nbrOfDuplicates)
      }
      setExistsInDbCount((prev) => prev + nbrOfDuplicates)

      validMappedFiles = nonDuplicatedMappedFiles
    }

    // if we have new incoming files we update total dropped files
    if (hasNewFiles) {
      setDroppedFiles((prev) => ({ ...prev, ...validMappedFiles }))
    }

    setValidatedFiles((prev) => {
      // we have to rely on "prev" validated files for all function executions below so we trigger them inside here to keep it as pure as possible
      const allValidatedMappedFiles = {
        ...prev,
        ...validMappedFiles,
      }
      const constrainedResult = updateConstrains(allValidatedMappedFiles) // we check new constrains on every drop
      setOutputFolderAndFiles(buildFolderAndFiles(constrainedResult))
      return constrainedResult
    })

    setInvalidatedFiles((prev: MappedFiles) => {
      const uniqueInvalidFiles = Object.values({
        ...prev,
        ...invalidMappedFilesRecord,
      }).filter(
        (file, index, self) =>
          index ===
          self.findIndex(
            (possibleDuplicate) => possibleDuplicate.name === file.name
          )
      )
      setNbrOfInvalidFiles(uniqueInvalidFiles.length)
      const grouped: GroupedInvalidFiles = subGroupMappedFiles(
        getMappedFiles(uniqueInvalidFiles)
      )
      if (uniqueInvalidFiles.length) {
        track.trackUploadFlowItemsSkipped({
          nbrOfItems: uniqueInvalidFiles.length,
          trackingSetupSessionId,
          skippedFileExtensions: Object.keys(grouped).reduce(
            (acc: GroupedInvalidFilesCount, extension) => {
              acc[extension] = grouped[extension].length
              return acc
            },
            {}
          ),
        })
      }

      return getMappedFiles(uniqueInvalidFiles)
    })

    setIsValidating(false)
    setUploadStatus('IDLE')
  }

  const removeFileOrFolderEntry = (key: string) => {
    const fileKeysToRemove: string[] = []
    const entry = outputFolderAndFiles[key]

    if ('isFile' in entry) {
      fileKeysToRemove.push(key)
      track.trackUploadFlowItemsRemoved({
        nbrOfItems: 1,
        type: 'FILE',
        trackingSetupSessionId,
        uploadType: uploadSourceView,
      })
    }

    if ('isFolder' in entry) {
      entry.items.forEach((item) => fileKeysToRemove.push(item.key))
      track.trackUploadFlowItemsRemoved({
        nbrOfItems: entry.items.length,
        type: 'FOLDER',
        trackingSetupSessionId,
        uploadType: uploadSourceView,
      })
    }

    setDroppedFiles((prev) => {
      const newDroppedFiles = { ...prev }
      fileKeysToRemove.forEach((fileKey) => {
        delete newDroppedFiles[fileKey]
      })
      return newDroppedFiles
    })

    setValidatedFiles((prev) => {
      const newValidatesFiles = { ...prev }
      fileKeysToRemove.forEach((fileKey) => {
        delete newValidatesFiles[fileKey]
      })
      updateConstrains(newValidatesFiles) // if we remove files we make sure to update constrains again
      return newValidatesFiles
    })

    setOutputFolderAndFiles((prev) => {
      const newOutputFolderAndFiles = { ...prev }
      delete newOutputFolderAndFiles[key]
      return newOutputFolderAndFiles
    })
  }

  const handleSubmit = async () => {
    const uploadOptions = {
      isPublicUpload,
    }
    if (uploadSourceView === 'desktop') {
      createNewSession(
        Object.values(validatedFiles) as CustomFile[],
        selectedWorkspace.id,
        uploadOptions,
        uploadFlowTrackingId,
        tagsToAddToUploads
      )
      reset()
    }
    if (uploadSourceView === 'dropbox') {
      const localClientId = uuid() // used to temporary glue together the upload with local client uploading progress
      try {
        addRemoteUploadLocalState(localClientId)
        const promise = callCloudFunction('importFromDropbox', {
          files: Object.values(validatedFiles) as CustomDropboxFile[],
          workspaceId: selectedWorkspace.id,
          boardId,
          localClientId,
          uploadOptions,
          tags: tagsToAddToUploads,
        })
        reset()
        const { status, lastConnectedEmail } = await promise
        if (status !== 'SUCCESS') {
          removeRemoteUploadLocalState(localClientId)
        }
        if (status === 'RECONNECT_REQUIRED') {
          if (lastConnectedEmail) {
            return reportError(
              `We had authorization problems when importing from Dropbox. You might have picked files which your connected Dropbox account (${lastConnectedEmail}) doesn't have access to. You'll have to connect Kive with Dropbox again.`,
              undefined,
              10000
            )
          }
          return reportError(
            "We had authorization problems when importing from Dropbox. You'll have to connect Kive with Dropbox again.",
            undefined,
            10000
          )
        }
        if (status === 'ZERO_UPLOADS') {
          return reportError(
            "We couldn't import any of your picked files from Dropbox. You might already have identical items in your workspace.",
            undefined,
            10000
          )
        }
      } catch (error) {
        removeRemoteUploadLocalState(localClientId)
        reportError(
          'Something strange happened while importing items from Dropbox 👎 Try again...',
          new Error(
            `Error when importing from Dropbox. ErrorMsg:${error.message}`
          ),
          5000
        )
      }
    }
  }

  const handleGoogleDriveClick = () => {
    reportSuccess(
      'Thanks for your interest in Google Drive. The integration is not supported yet, but we will let you know when it is 🤝',
      undefined,
      10000
    )
    trackUploadWithGoogleDriveInterest()
  }

  const handleCloseView = () => {
    track.trackUploadFlowCanceled({
      nbrOfItems: Object.keys(validatedFiles).length,
      trackingSetupSessionId,
      uploadType: uploadSourceView,
    })
    reset()
  }

  const handleOpenPicker = () => {
    if (uploadSourceView === 'desktop') {
      return openFilePicker()
    }
    if (uploadSourceView === 'dropbox') {
      return launchDropbox()
    }
  }

  useEffect(() => {
    if (lastDrop.length === 0) return
    validateFiles(getMappedFiles(lastDrop), {
      hasNewFiles: true,
      shouldValidateDuplicates: true,
    })
    track.trackUploadFlowItemsAdded({
      nbrOfItems: lastDrop.length,
      trackingSetupSessionId,
      uploadType: uploadSourceView,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastDrop]) // we only wanna validate files when lastDrop changes

  const getSubmitButtonText = (
    workspaceHasReachedLimit: boolean,
    duplicatesWasAddedToBoard: boolean
  ) => {
    if (workspaceHasReachedLimit) {
      return `Upload first ${nbrOfValidatedFiles} ${getSingularPluralWord(
        'file',
        'files',
        nbrOfValidatedFiles
      )}`
    }
    if (duplicatesWasAddedToBoard) {
      return `Upload ${nbrOfValidatedFiles} remaining ${getSingularPluralWord(
        'file',
        'files',
        nbrOfValidatedFiles
      )}`
    }
    return 'Upload'
  }

  const nbrOfValidatedFiles = Object.keys(validatedFiles).length
  const workspaceHasReachedLimit =
    uploadConstrainInfo === 'MAX_PLAN_LIMIT_REACHED'
  const filesAreReadyToBeUploaded = nbrOfValidatedFiles > 0 && !isValidating
  const showInvalidFilesInfo = nbrOfInvalidFiles > 0
  const shouldShowDuplicateInfo =
    existsInDbCount > 0 && duplicatesAddedToBoard === 0
  const showUploadStatus = ['LOADING_FILES', 'CHECKING_DUPLICATES'].includes(
    uploadStatus
  )

  const duplicatesWasAddedToBoard = duplicatesAddedToBoard > 0
  const allFileswereAddedToBoard =
    duplicatesWasAddedToBoard && !filesAreReadyToBeUploaded
  const freezeWorkspaceDropdown = !!boardId
  const folderAndFilesToDisplay = Object.entries(outputFolderAndFiles).slice(
    0,
    200 // we only display first 200 entries for now
  )
  const groupedInvalidFiles: GroupedInvalidFiles =
    subGroupMappedFiles(invalidatedFiles)
  const supportedExtensions = getSupportedFileExtensions()
  const unSupportedMessage = (
    <p>
      We only support
      {[
        ...supportedExtensions.slice(0, supportedExtensions.length - 1),
        `and ${supportedExtensions[supportedExtensions.length - 1]}`,
      ].join(', ')}{' '}
      at the moment. <br />
      Files are limited to &lt;{MAX_FILE_SIZE / 1000000}MB
    </p>
  )

  return (
    <FullScreen
      isSemiTransparent={isInSetup}
      onClick={isInSetup ? handleCloseView : undefined}
    >
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, scale: 1.1 }}
          animate={{
            opacity: 1,
            scale: 1,
          }}
          exit={{ opacity: 0, scale: 1.1 }}
        >
          <Wrapper onClick={(e) => e.stopPropagation()}>
            {/* because we don't want the event to bubble up and trigger `handleCloseView` */}
            {!isInSetup && (
              <GoBackText
                size="base"
                color="neutral.3"
                onClick={() => {
                  if (isAddTagsVisible) {
                    return setIsAddTagsVisible(false)
                  }
                  return handleCloseView()
                }}
              >
                <ArrowLeftShort height="30px" />{' '}
                {isAddTagsVisible ? 'Back' : 'Close'}
              </GoBackText>
            )}
            {!uploadSourceView && (
              <Container>
                {isInSetup && isMobile && (
                  <Cross
                    height="24px"
                    color={theme.colors.text.neutral[2]}
                    onClick={handleCloseView}
                    css="position:absolute;right:5px;top:5px"
                  />
                )}
                <div css="display: flex;flex-direction: column;align-items: flex-start;">
                  <Text size="sm" color="neutral.3">
                    Import from:
                  </Text>
                  <Margin y={6} />
                  <UploadSourceCard
                    onClick={() => {
                      trackUploadOptionSelected({
                        uploadOption: 'COMPUTER',
                        uploadFlowId: uploadFlowTrackingId,
                      })
                      openFilePicker()
                    }}
                  >
                    <UploadIcon
                      css={`
                        height: 20px;
                        margin-bottom: 4px;
                        color: ${({
                          theme: styledTheme,
                        }: {
                          theme: DefaultTheme
                        }) => styledTheme.colors.accent[3]};
                      `}
                    />
                    <Margin x={4} />
                    {isMobile ? 'Phone' : 'Computer'}
                  </UploadSourceCard>
                  <Margin y={12} />
                  <UploadSourceCard
                    onClick={() => {
                      if (isConnectingToDropbox) return
                      trackUploadOptionSelected({
                        uploadOption: 'DROPBOX',
                        uploadFlowId: uploadFlowTrackingId,
                      })
                      return launchDropbox()
                    }}
                  >
                    {isConnectingToDropbox ? (
                      <>
                        <Spinner css="height:18px" />
                        <Margin x={8} />
                        Connecting to Dropbox...
                      </>
                    ) : (
                      <DropboxLogo css="width:auto;fill:white;height:16px" />
                    )}
                  </UploadSourceCard>
                  <Margin y={12} />
                  <UploadSourceCard
                    onClick={() => {
                      trackUploadOptionSelected({
                        uploadOption: 'GOOGLE_DRIVE',
                        uploadFlowId: uploadFlowTrackingId,
                      })
                      handleGoogleDriveClick()
                    }}
                  >
                    <img
                      height="18px"
                      src={GoogleDriveIcon}
                      alt="google-drive-logo"
                    />
                    <Margin x={4} />
                    Google Drive
                  </UploadSourceCard>
                  {!isMobile && (
                    <>
                      <Margin y={8} />
                      <Text size="sm" color="neutral.3">
                        Pro tip: drop a folder to get contents
                      </Text>
                    </>
                  )}
                </div>
              </Container>
            )}
            {uploadSourceView !== null && (
              <Container>
                {isInSetup && isMobile && (
                  <Cross
                    height="24px"
                    onClick={handleCloseView}
                    color={theme.colors.text.neutral[2]}
                    css="position:absolute;right:5px;top:5px"
                  />
                )}
                {isAddTagsVisible ? (
                  <>
                    <AddTags
                      onSubmit={(tags) => setTagsToAddToUploads(tags)}
                      onClose={() => setIsAddTagsVisible(false)}
                    />
                  </>
                ) : (
                  <>
                    <WorkspaceWrapper>
                      <Text
                        color="neutral.3"
                        size="sm"
                        css="padding-bottom: 4px;"
                      >
                        Upload to
                      </Text>
                      {isInSetup && (
                        <Text
                          color="neutral.0"
                          size="base"
                          css={`
                            margin-bottom: ${folderAndFilesToDisplay.length > 0
                              ? '-5px'
                              : '-20px'};
                          `}
                        >
                          {selectedWorkspace.title}
                        </Text>
                      )}
                      {!isInSetup && (
                        <div
                          css="position:relative;width: 100%;"
                          onClick={
                            freezeWorkspaceDropdown
                              ? undefined
                              : () =>
                                  setIsWorkspacesDropdownVisible(
                                    (prev) => !prev
                                  )
                          }
                        >
                          <PickedWorkspaceWrapper>
                            <div css="display:flex;flex-direction:column;align-items:flex-start;">
                              <Text size="sm">{selectedWorkspace.title}</Text>
                              <Text size="sm" color="neutral.3">
                                {selectedWorkspaceMemberCount === 1
                                  ? 'Only you have access'
                                  : `You and ${
                                      selectedWorkspaceMemberCount - 1
                                    } ${getSingularPluralWord(
                                      'user',
                                      'users',
                                      selectedWorkspaceMemberCount - 1
                                    )} ${getSingularPluralWord(
                                      'has',
                                      'have',
                                      selectedWorkspaceMemberCount - 1
                                    )} access`}
                              </Text>
                            </div>
                            <ChevronDown
                              height="25px"
                              color={theme.colors.accent[2]}
                            />
                          </PickedWorkspaceWrapper>

                          <Dropdown
                            isOpen={isWorkspacesDropdownVisible}
                            options={{
                              position: 'bottom-left',
                              maxHeight: 350,
                              noSpace: true,
                              stretch: true,
                            }}
                          >
                            {availableWorkspaces.map((availableWorkspace) => {
                              return (
                                <Dropdown.Item
                                  key={availableWorkspace.id}
                                  title={availableWorkspace.title}
                                  onClick={() =>
                                    changeSelectedWorkspace(availableWorkspace)
                                  }
                                />
                              )
                            })}
                          </Dropdown>
                        </div>
                      )}
                    </WorkspaceWrapper>
                    {folderAndFilesToDisplay.length > 0 && (
                      <>
                        <Text size="sm" color="neutral.3" css="display:flex">
                          Files
                        </Text>
                        <ListedFilesWrapper>
                          {folderAndFilesToDisplay.map(([key, output]) => {
                            return (
                              <ListedFileWrapper key={key}>
                                <FileContent>
                                  <FileName size="sm">{output.name}</FileName>
                                  {'isFolder' in output && (
                                    <Text size="sm" color="neutral.3">
                                      <Folder
                                        height="17px"
                                        css="margin-bottom:3px;margin-right: 2px;"
                                      />{' '}
                                      Folder{' '}
                                      <span css="margin-left:8px">
                                        {getFormattedSize(output.size)}
                                      </span>
                                      <span css="margin-left:8px">
                                        {output.items.length} items
                                      </span>
                                    </Text>
                                  )}
                                  {'isFile' in output && (
                                    <Text size="sm" color="neutral.3">
                                      {getFormattedSize(
                                        'isDropboxFile' in output.file
                                          ? output.file.bytes
                                          : output.file.size
                                      )}{' '}
                                      <span css="margin-left:8px">
                                        {output.type}
                                      </span>
                                    </Text>
                                  )}
                                </FileContent>
                                <RemoveFileIcon
                                  onClick={() => removeFileOrFolderEntry(key)}
                                >
                                  <Cross
                                    height="22px"
                                    color={theme.colors.accent[2]}
                                  />
                                </RemoveFileIcon>
                              </ListedFileWrapper>
                            )
                          })}
                        </ListedFilesWrapper>
                      </>
                    )}
                    {showUploadStatus && (
                      <div css="display:flex;align-items:center">
                        {uploadStatus === 'CHECKING_DUPLICATES' && (
                          <ProgressCircle
                            stroke={theme.colors.accent[2]}
                            size={30}
                            percents={validationProgress * 100}
                            strokeWidth={10}
                            css="margin-right:10px"
                          />
                        )}
                        <Text size="sm" color="neutral.1" css="display:flex">
                          {uploadStatus === 'LOADING_FILES'
                            ? 'Loading files...'
                            : 'Checking for duplicates in your Kive...'}
                        </Text>
                      </div>
                    )}
                    <InvalidFilesInfo>
                      {shouldShowDuplicateInfo && (
                        <Text size="sm" color="neutral.3">
                          Skipping {existsInDbCount}{' '}
                          {getSingularPluralWord(
                            'file',
                            'files',
                            existsInDbCount
                          )}{' '}
                          already in your Kive
                        </Text>
                      )}

                      {showInvalidFilesInfo && (
                        <div css="display: flex; width: 100%">
                          <div css="width: 96%">
                            <Collapsible
                              label={`Skipping ${nbrOfInvalidFiles} unsupported ${getSingularPluralWord(
                                'file',
                                'files',
                                nbrOfInvalidFiles
                              )}`}
                            >
                              <div css="padding-left: 8px;  max-height: 120px; overflow-y: auto">
                                {Object.keys(groupedInvalidFiles).map(
                                  (fileExtension) => {
                                    const numberOfTheseFiles: any =
                                      groupedInvalidFiles[fileExtension].length
                                    return (
                                      <Collapsible
                                        label={`${numberOfTheseFiles} ${fileExtension} ${getSingularPluralWord(
                                          'file',
                                          'files',
                                          numberOfTheseFiles
                                        )}`}
                                      >
                                        <div css="padding-left: 16px;">
                                          {groupedInvalidFiles[
                                            fileExtension
                                          ].map((file) => (
                                            <Text
                                              key={file.name}
                                              size="sm"
                                              color="neutral.3"
                                              css="margin-left: 8px; overflow: hidden; max-width:80%; white-space:nowrap; text-overflow: ellipsis; text-align: left;"
                                            >
                                              {file.name}
                                            </Text>
                                          ))}
                                        </div>
                                      </Collapsible>
                                    )
                                  }
                                )}
                              </div>
                            </Collapsible>
                          </div>
                          <div>
                            <Tooltip html={unSupportedMessage}>
                              <QuestionCircle
                                size="14"
                                color="neutral.2"
                                css="cursor: pointer; opacity: 0.6;"
                              />
                            </Tooltip>
                          </div>
                        </div>
                      )}
                      {duplicatesWasAddedToBoard && (
                        <Text size="sm" color="accent.3">
                          {`${duplicatesAddedToBoard} ${getSingularPluralWord(
                            'file',
                            'files',
                            duplicatesAddedToBoard
                          )} already exist and was added to your board`}
                        </Text>
                      )}
                    </InvalidFilesInfo>
                    <div css="position:relative">
                      <DropzoneWrapper onClick={handleOpenPicker}>
                        {uploadSourceView === 'dropbox' && (
                          <DropboxIcon
                            height="25px"
                            css="margin-right:12px;flex-shrink:0;color:#0061FF"
                          />
                        )}
                        {uploadSourceView === 'desktop' && (
                          <PlusCircle
                            height="25px"
                            color={theme.colors.accent[2]}
                            css="margin-right:12px;flex-shrink:0;"
                          />
                        )}
                        <div css="display:flex;flex-direction:column;align-items: flex-start;">
                          <Text size="base" bold>
                            {workspaceHasReachedLimit
                              ? 'Plan limit reached!'
                              : `Add ${
                                  nbrOfValidatedFiles > 0 ? 'more' : ''
                                } files${
                                  uploadSourceView === 'dropbox'
                                    ? ' from Dropbox'
                                    : ''
                                }`}
                          </Text>
                          <Text
                            size="sm"
                            color="neutral.3"
                            css="text-align:left;white-space:nowrap;"
                          >
                            {nbrOfValidatedFiles}{' '}
                            {getSingularPluralWord(
                              'file',
                              'files',
                              nbrOfValidatedFiles
                            )}{' '}
                            added ({getFormattedSize(totalUploadSize)}){' '}
                            {remainingUploads === Infinity ||
                            workspaceHasReachedLimit
                              ? ''
                              : `- ${remainingUploads} left on plan`}
                          </Text>
                        </div>
                      </DropzoneWrapper>
                      {uploadConstrainInfo === 'MAX_BYTES_REACHED' && (
                        <MaxBytesReachedPopup />
                      )}
                      {uploadConstrainInfo === 'MAX_FILE_COUNT_REACHED' && (
                        <MaxFileCountReachedPopup />
                      )}
                      {workspaceHasReachedLimit && workspacePlan && (
                        <WorkspaceLimitReachedPopup
                          plan={workspacePlan}
                          nextPlan={nextWorkspacePlan}
                          onUpgrade={() => openUpgradePanel('items')}
                        />
                      )}
                    </div>
                    {!isMobile && uploadSourceView === 'desktop' && (
                      <Text size="sm" color="neutral.3" css="display:flex">
                        Pro tip: drop a folder to get contents{' '}
                      </Text>
                    )}
                    <Margin y={12} />
                    <div css="display: flex;justify-content: space-between; align-items:center">
                      <div css="display:flex">
                        <Text size="sm" color="neutral.3">
                          Make uploads public
                        </Text>
                        <Margin x={12} />
                        <Tooltip
                          position="top"
                          title="If you make items public, they will be featured in Discover and become accessible for users outside your workspace"
                        >
                          <QuestionCircle height="14px" css="opacity: 0.6" />
                        </Tooltip>
                      </div>

                      <Slider
                        active={isPublicUpload}
                        onClick={() => setIsPublicUpload((prev) => !prev)}
                      >
                        <SliderBall active={isPublicUpload} />
                      </Slider>
                    </div>
                    {!!tagsToAddToUploads.length && (
                      <div>
                        <Margin y={8} />
                        <MiniTagsWrapper>
                          <div css="display:flex; flex-wrap: wrap; max-width: 90%">
                            {tagsToAddToUploads.map((tag) => (
                              <>
                                <MiniTag>
                                  <Text size="sm" color="neutral.3">
                                    {tag.description}
                                  </Text>
                                </MiniTag>
                              </>
                            ))}
                          </div>
                          <RemoveTagsIcon
                            onClick={() => setTagsToAddToUploads([])}
                          >
                            <Cross
                              height="22px"
                              color={theme.colors.accent[2]}
                            />
                          </RemoveTagsIcon>
                        </MiniTagsWrapper>
                        <Margin y={8} />
                      </div>
                    )}
                    {userAllowedTo.ADD_ITEM_TAG && (
                      <>
                        <Margin y={8} />
                        <Text size="sm" color="neutral.3" css="display:flex">
                          Optional
                        </Text>
                        <Margin y={8} />
                        <OptionalActionWrapper
                          onClick={() => setIsAddTagsVisible(true)}
                        >
                          <div css="display: flex; justify-content: space-between; width: 100%;">
                            <div css="display:flex;flex-direction:column;align-items: flex-start;">
                              <Text size="base" color="neutral.3">
                                Apply tags to upload
                              </Text>
                            </div>
                            <TagIcon
                              height="25px"
                              color={theme.colors.text.neutral[4]}
                              css="margin-right:12px;flex-shrink:0;"
                            />
                          </div>
                        </OptionalActionWrapper>
                      </>
                    )}
                    <Margin y={24} />
                    {allFileswereAddedToBoard ? (
                      <Button
                        css="margin-top:12px"
                        variant="primary"
                        fullWidth
                        onClick={() => reset()}
                      >
                        OK
                      </Button>
                    ) : (
                      <Button
                        disabled={!filesAreReadyToBeUploaded}
                        css="margin-top:12px"
                        variant="primary"
                        fullWidth
                        onClick={handleSubmit}
                      >
                        {getSubmitButtonText(
                          workspaceHasReachedLimit,
                          duplicatesWasAddedToBoard
                        )}
                      </Button>
                    )}
                  </>
                )}
              </Container>
            )}
          </Wrapper>
        </motion.div>
      </AnimatePresence>
    </FullScreen>
  )
}

const Container = styled.div`
  ${({ theme }) => css`
    padding: 16px;
    border: 1px solid rgb(32, 32, 32);
    border-radius: ${theme.borderRadius.default};
    position: relative;
  `}
`

const Wrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.background[1]};
    width: 400px;
    max-width: 97vw;
    transition: height 0.1s ease-in-out;
    @media (max-width: ${theme.breakpoints.md}px) {
      max-height: 95vh;
      overflow-y: auto;
    }
  `}
`

const WorkspaceWrapper = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const ListedFilesWrapper = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 120px;
  overflow: auto;
`
const RemoveFileIcon = styled.div`
  margin-right: 12px;
  padding: 4px;
  cursor: pointer;
  display: none;
`

const ListedFileWrapper = styled.div`
  ${({ theme }) => css`
    border-bottom: ${theme.border.thin} ${theme.colors.gray.dark[5]};
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    :hover ${RemoveFileIcon} {
      display: block;
    }
  `}
`

const RemoveTagsIcon = styled.div`
  display: none;
  cursor: pointer;
`

const MiniTagsWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    // border-bottom: ${theme.border.thin} ${theme.colors.gray.dark[5]};
    :hover ${RemoveTagsIcon} {
      display: block;
    }
  `}
`

const FileContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%; // we make remove for the remove icon
  align-items: flex-start;
  padding: 4px 8px;
`
const InvalidFilesInfo = styled.div`
  margin-bottom: 16px;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const DropzoneWrapper = styled.div`
  ${({ theme }) => css`
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 18px;
    border: ${`${theme.border.thin} ${theme.colors.discreet}`};
    border-radius: ${theme.borderRadius.default};
    :hover {
      border-color: ${theme.colors.accent[2]};
    }
  `}
`

const OptionalActionWrapper = styled.div`
  ${({ theme }) => css`
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 8px;
    border: ${`${theme.border.thin} ${theme.colors.discreet}`};
    border-radius: ${theme.borderRadius.default};
    :hover {
      border-color: ${theme.colors.accent[2]};
    }
  `}
`

const FileName = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: left;
`

const PickedWorkspaceWrapper = styled.div`
  ${({ theme }) => css`
    cursor: pointer;
    padding: 6px 12px;
    border: ${theme.border.thin} ${theme.colors.gray.dark[5]};
    border-radius: ${theme.borderRadius.default};
    width: 100%;
    line-height: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    :hover {
      border-color: ${theme.colors.accent[2]};
    }
  `}
`

const GoBackText = styled(Text)`
  ${({ theme }) => css`
    display: flex;
    margin-left: 8px;
    margin-bottom: 8px;
    align-items: center;
    cursor: pointer;
    width: 75px;
    :hover {
      color: ${theme.colors.accent[2]};
    }
  `}
`

const UploadSourceCard = styled.div`
  ${({ theme }) => css`
    padding: 16px 22px;
    cursor: pointer;
    border: ${theme.border.thin} ${theme.colors.background[4]};
    border-radius: ${theme.borderRadius.default};
    width: 100%;
    height: 54px;
    display: flex;
    align-items: center;
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.fontSizes.sm};
    :hover {
      background-color: ${theme.colors.background[4]};
    }
  `}
`

const Slider = styled.div<{ active: boolean }>`
  ${({ theme, active }) => css`
    padding: 4px 8px;
    width: 60px;
    border-radius: 20px;
    background: ${active ? theme.colors.accent[2] : theme.colors.gray.dark[5]};
  `}
`

const SliderBall = styled.div<{ active: boolean }>`
  ${({ theme, active }) => css`
    border-radius: 50%;
    transition: all ease-in-out 0.1s;
    background: ${active
      ? theme.colors.gray.light[5]
      : theme.colors.gray.medium[0]};
    height: 20px;
    width: 20px;
    transform: ${active ? 'translateX(24px)' : ''};
  `}
`

const MiniTag = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.background[4]};
    height: 44px;
    margin-bottom: 8px;
    margin-right: 8px;
    text-align: left;
    padding: 8px;
    border-radius: ${theme.borderRadius.default};
    font-size: ${theme.fontSizes.base};
    display: flex;
    align-items: center;
    position: relative;
    color: ${theme.colors.text.neutral[3]};
  `}
`

export default DroppedFiles
