import { useState } from 'react'
import { useSelector } from 'react-redux'
import { ArrowForward } from 'styled-icons/ionicons-outline'

import img200CreditPack from '../../../assets/img/ai_canvas/200_credit_pack.jpg'
import Loading from '../../../components/Loading'
import Button from '../../../components/common/Button'
import Modal from '../../../components/common/Modal'
import {
  CheckoutLineItem,
  CreditPackCheckoutInput,
  CreditsUserQuery,
  useCreateCreditPackCheckoutSessionMutation,
  useCreditPacksQuery,
} from '../../../generated/graphql'
import { reportError as reportSentryError } from '../../../helpers/logging'
import { RootState } from '../../../store'
import {
  StyledButtonText,
  StyledImage,
  StyledModalDescription,
  StyledModalHeader,
  StyledPackContainer,
  StyledPackGrid,
} from './BuyCreditsModalStyles'

type Props = {
  showBuyCreditsModal: boolean
  setShowBuyCreditsModal: (show: boolean) => void
  editSessionId: string
  userCreditData: CreditsUserQuery | undefined
  userCreditLoading: boolean
}

export const BuyCreditsModal = ({
  showBuyCreditsModal,
  setShowBuyCreditsModal,
  editSessionId,
  userCreditData,
  userCreditLoading,
}: Props) => {
  const [packToBuy, setPackToBuy] = useState<string>()
  const auth = useSelector((state: RootState) => state.firebase.auth)
  const { data: creditPacksData, loading: creditPacksLoading } =
    useCreditPacksQuery({
      fetchPolicy: 'cache-and-network',
    })
  const [
    createCreditPackCheckoutSessionMutation,
    { loading: isCreatingCheckoutSession, error: checkoutError },
  ] = useCreateCreditPackCheckoutSessionMutation()

  const createCheckoutSessionAndRedirect = async (args: {
    lineItem: CheckoutLineItem
    creditCount: number
  }) => {
    setPackToBuy(args.lineItem.priceId)
    if (isCreatingCheckoutSession || checkoutError) {
      setPackToBuy(undefined)
      return
    }
    if (!auth.email) {
      setPackToBuy(undefined)
      return
    }
    try {
      const returnUrl = new URL(
        `${window.location.origin}${window.location.pathname}`
      )
      const successUrl = new URL(
        `${returnUrl.href}?${new URLSearchParams(
          `checkout_success=true&old_count=${
            userCreditData?.user?.generationRequests?.availableCredits ?? 0
          }`
        )}`
      )
      const cancelUrl = new URL(
        `${returnUrl.href}?${new URLSearchParams('checkout_cancel=true')}`
      )
      const input: CreditPackCheckoutInput = {
        sourceUrl: window.location.href,
        customerEmail: auth.email,
        lineItems: [args.lineItem],
        redirects: {
          successUrl: `${successUrl}&session_id={CHECKOUT_SESSION_ID}`,
          cancelUrl: `${cancelUrl}`,
        },
        editSessionId,
      }
      console.log(`Creating Checkout session with the following payload`, {
        variables: {
          input,
        },
      })
      const { data: checkoutData } =
        await createCreditPackCheckoutSessionMutation({
          variables: {
            input,
          },
        })
      const url = checkoutData?.createCreditPackCheckoutSession?.url
      if (!url) {
        setPackToBuy(undefined)
        return
      }
      setPackToBuy(undefined)
      window.location.assign(url)
    } catch (error) {
      setPackToBuy(undefined)
      reportSentryError(error)
    }
  }

  const sortedPacks =
    creditPacksData?.creditPacks
      .map((pack) => ({
        ...pack,
      }))
      .sort((a, b) => a.creditCount - b.creditCount) ?? []

  if (!auth.uid) return null
  const availableCredits = Number(
    userCreditData?.user?.generationRequests?.availableCredits
  )
  return (
    <Modal
      isOpen={showBuyCreditsModal}
      close={() => setShowBuyCreditsModal(false)}
    >
      <Modal.ContentWrapper
        style={{
          textAlign: 'left',
          alignItems: 'start',
        }}
      >
        {creditPacksLoading || userCreditLoading ? <Loading /> : null}
        {!creditPacksLoading && !userCreditLoading ? (
          <>
            <StyledModalHeader>Your credit</StyledModalHeader>
            <StyledModalDescription>
              You have {availableCredits > 1 ? availableCredits : 'no'} credit
              {availableCredits === 1 ? '' : 's'}
            </StyledModalDescription>
            <StyledPackGrid>
              {sortedPacks.map((pack) => {
                return (
                  <StyledPackContainer key={pack.priceId}>
                    <StyledImage src={img200CreditPack} alt={pack.title} />
                    <Button
                      style={{ width: '100%' }}
                      type="button"
                      variant="primary"
                      isCompact
                      disabled={isCreatingCheckoutSession}
                      onClick={() =>
                        createCheckoutSessionAndRedirect({
                          lineItem: {
                            priceId: pack.priceId,
                            quantity: 1,
                          },
                          creditCount: pack.creditCount,
                        })
                      }
                    >
                      {isCreatingCheckoutSession &&
                      packToBuy === pack.priceId ? (
                        <Loading />
                      ) : null}
                      {(isCreatingCheckoutSession &&
                        packToBuy !== pack.priceId) ||
                      !isCreatingCheckoutSession ? (
                        <>
                          <StyledButtonText>
                            Buy for{' '}
                            {new Intl.NumberFormat('en-US', {
                              style: 'currency',
                              currency: pack.currency,
                              currencyDisplay: 'narrowSymbol',
                            }).format(pack.unitAmountInCents / 100)}
                          </StyledButtonText>
                          <ArrowForward width={16} />
                        </>
                      ) : null}
                    </Button>
                  </StyledPackContainer>
                )
              })}
            </StyledPackGrid>
          </>
        ) : null}
      </Modal.ContentWrapper>
    </Modal>
  )
}
