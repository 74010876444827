import { ArrowForward } from '@styled-icons/material/ArrowForward'
import React from 'react'
import styled from 'styled-components'

import IconButton from 'components/common/IconButton'
import StopPropagation from 'helpers/StopPropagation'

const Margin = styled.div`
  width: 64px;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

interface ItemActionBarRightProps {
  handleMoveItemFocus: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    direction: 'right' | 'left'
  ) => void
}

const ItemActionBarRight: React.FC<ItemActionBarRightProps> = ({
  handleMoveItemFocus,
}) => (
  <Margin style={{ justifyContent: 'center' }}>
    <StopPropagation>
      <IconButton
        Icon={ArrowForward}
        onClick={(e) => handleMoveItemFocus(e, 'right')}
      />
    </StopPropagation>
  </Margin>
)

export default ItemActionBarRight
