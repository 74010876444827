import React from 'react'
import { useTheme } from 'styled-components'

import SearchUpload from 'components/search/SearchUpload'
import useIsMobile from 'hooks/useIsMobile'

import { Container, SubTitle, Title } from './LibraryStyles'

export const LibraryEmptyView = () => {
  const theme = useTheme()
  const isMobile = useIsMobile()

  return (
    <div
      style={{
        display: 'flex',
        height: 'calc(100vh - 60px)',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      {isMobile ? (
        <Container style={{ marginBottom: '24px' }}>
          <Title color={theme.colors.text.neutral[0]}>
            Upload files now to start collecting!
          </Title>
        </Container>
      ) : (
        <Container style={{ marginBottom: '32px' }}>
          <Title color={theme.colors.accent[2]}>
            Drop something here to start collecting!
          </Title>
          <SubTitle color={theme.colors.text.neutral[3]}>
            Drag and drop some images here, or a folder of images.
          </SubTitle>
        </Container>
      )}
      <SearchUpload text="Select files" />
    </div>
  )
}
