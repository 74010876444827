import discoverImage1 from 'assets/img/guides/discover-guide/discover-1.jpg'
import discoverImage2 from 'assets/img/guides/discover-guide/discover-2.jpg'
import discoverImage3 from 'assets/img/guides/discover-guide/discover-3.jpg'
import discoverImage4 from 'assets/img/guides/discover-guide/discover-4.jpg'
import { Spacer } from 'components/common/Spacer'
import { Text } from 'components/common/Text'

import { StyledContentList } from './contentStyles'

export const contentDiscovery = {
  contentHeight: 225,
  steps: [
    {
      coverUrl: discoverImage1,
      content: (
        <div>
          <Text size="lg" color="neutral.0">
            Explore community assets
          </Text>
          <Spacer factor={1} />
          <Text size="sm" color="neutral.2">
            Discover is a shared area in Kive, where you can contribute and find
            other creatives&apos; contributions. If you find something that you
            like, you can instantly save it to your own library.
          </Text>
        </div>
      ),
    },
    {
      coverUrl: discoverImage2,
      content: (
        <div>
          <Text size="lg" color="neutral.0">
            Private by default, public by choice.
          </Text>
          <Spacer factor={1} />
          <Text size="sm" color="neutral.2">
            Everything you upload defaults to only being visible to the members
            of your workspace. To make selected items public and visible in the
            discovery feed:{' '}
          </Text>
          <StyledContentList as="ol">
            <li>Open any item in your library </li>
            <li>
              Click the down arrow next to the &quot;Copy item link&quot; button
            </li>
            <li>Select &quot;Public&quot; </li>
          </StyledContentList>
          <br />
          <Text size="sm" color="neutral.2">
            You can find all your public items in the 👤 Profile section.
          </Text>
        </div>
      ),
    },
    {
      coverUrl: discoverImage3,
      content: (
        <div>
          <Text size="lg" color="neutral.0">
            Search in Discover
          </Text>
          <Spacer factor={1} />
          <Text size="sm" color="neutral.2">
            You can search for inspiration in multiple ways by:
            <br />
            &nbsp;&nbsp;&nbsp;✍️ &nbsp; Free text <br />
            &nbsp;&nbsp;&nbsp;🤯 &nbsp; Face
            <br />
            &nbsp;&nbsp;&nbsp;🎨 &nbsp; Color
            <br />
            &nbsp;&nbsp;&nbsp;📄 &nbsp; Filename
            <br />
            &nbsp;&nbsp;&nbsp;🎥 &nbsp; GIF
            <br />
            And plenty of other categories...
          </Text>
        </div>
      ),
    },
    {
      coverUrl: discoverImage4,
      content: (
        <div>
          <Text size="lg" color="neutral.0">
            Find original source
          </Text>
          <Spacer factor={1} />
          <Text size="sm" color="neutral.2">
            Click on the source link to find out where items originate from.
          </Text>
        </div>
      ),
    },
  ],
}
