/* eslint-disable @typescript-eslint/naming-convention */
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion'
import React, { useEffect } from 'react'
import { createPortal } from 'react-dom'
import styled, { css } from 'styled-components'

const appearTransition = {
  opacity: {
    type: 'tween',
    duration: 0.1,
    ease: 'easeOut',
  },
  y: {
    type: 'tween',
    duration: 0.1,
    ease: 'easeOut',
  },
}

const Overlay = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 0.85 },
  exit: { opacity: 0 },
  transition: appearTransition,
})`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray.dark[0]};
`

const Wrapper = styled.section`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`

const ModalBox = styled(motion.dialog).attrs({
  initial: { y: 50, opacity: 0 },
  animate: { y: 0, opacity: 1 },
  exit: { y: 50, opacity: 0 },
  transition: appearTransition,
})`
  ${({
    width,
    height,
    theme,
    $noBorder,
  }: {
    width?: string
    height?: string
    theme: any
    $noBorder?: boolean
  }) => css`
    background-color: ${theme.colors.background[0]};
    max-width: calc(100% - 32px);
    max-height: calc(100% - 32px);
    ${width ? `width: ${width};` : ''}
    ${height ? `height: ${height};` : ''}
    border-radius: 16px;
    border: ${$noBorder
      ? 'transparent'
      : `${theme.border.thin} ${theme.colors.discreet}`};
    box-shadow: ${$noBorder ? 'none' : theme.shadow.minimal};
    padding: 0;
    color: ${theme.colors.text.neutral[2]};
    z-index: 2;
    display: flex;
    flex-direction: column;
    @media (max-width: 500px) {
      overflow: auto;
    }
  `}
`

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 24px;
`

interface ModalProps {
  isOpen: boolean
  close?: () => void
  width?: string
  height?: string
  motionLayout?: boolean
  noBorder?: boolean
  wrapperCss?: string
  children:
    | ((args: { close?: () => void }) => React.ReactNode)
    | React.ReactNode
  closeOnOverlayClick?: boolean
}

const Modal: React.FC<ModalProps> & {
  ContentWrapper: typeof ModalContentWrapper
} = ({
  isOpen,
  close,
  width,
  height,
  children,
  motionLayout,
  noBorder,
  wrapperCss,
  closeOnOverlayClick = true,
}) => {
  useEffect(() => {
    if (!isOpen) {
      return
    }
    const blockEventPropagation = (e: Event) => {
      e.stopPropagation()
    }
    document.addEventListener('keydown', blockEventPropagation, true)
    return () => {
      document.removeEventListener('keydown', blockEventPropagation, true)
    }
  }, [isOpen])

  const modalRoot = document.getElementById('modal-root')
  if (!modalRoot) {
    return null
  }

  return createPortal(
    <AnimatePresence>
      {isOpen && (
        <Wrapper css={wrapperCss}>
          <Overlay
            onClick={(e) => {
              e.stopPropagation()
              if (closeOnOverlayClick) {
                close?.()
              }
            }}
          />
          <LayoutGroup>
            <ModalBox
              layout={motionLayout}
              onClick={(ev) => {
                ev.stopPropagation()
              }}
              width={width}
              height={height}
              $noBorder={noBorder}
            >
              {typeof children === 'function' ? children({ close }) : children}
            </ModalBox>
          </LayoutGroup>
        </Wrapper>
      )}
    </AnimatePresence>,
    modalRoot
  )
}

Modal.ContentWrapper = ModalContentWrapper

export default Modal
