import { ApolloCache, FetchResult, Reference } from '@apollo/client'
import produce from 'immer'
import uniqBy from 'lodash/uniqBy'

import {
  AddItemReactionMutation,
  ItemReactionBasicFieldsFragmentDoc,
} from 'generated/graphql'

const onRemoveItemReactionCacheUpdate = (
  cache: ApolloCache<any>,
  itemReactionId: string,
  currentItemReactionGroup: any
) => {
  cache.modify({
    id: cache.identify(currentItemReactionGroup!),
    fields: {
      itemReactions(existingItemReactionRefs: Reference[], { readField }) {
        return existingItemReactionRefs.filter((itemReactionRef) => {
          return readField('id', itemReactionRef) !== itemReactionId
        })
      },
      hasReacted() {
        return false
      },
      count(existingCount) {
        return existingCount - 1
      },
    },
  })
}

const onAddItemReactionCacheUpdate = (
  cache: ApolloCache<any>,
  payload: Omit<
    FetchResult<
      AddItemReactionMutation,
      Record<string, any>,
      Record<string, any>
    >,
    'context'
  >,
  parentId: string
) => {
  cache.modify({
    fields: {
      itemReactions: (
        existingItemReactionGroupsRefs: Reference[],
        { storeFieldName, readField }
      ) => {
        if (storeFieldName !== `itemReactions:${parentId}`) {
          return existingItemReactionGroupsRefs
        }

        const newReaction = payload.data?.addItemReaction
        if (!newReaction) return existingItemReactionGroupsRefs

        const newItemReactionRef = cache.writeFragment({
          data: newReaction,
          fragment: ItemReactionBasicFieldsFragmentDoc,
        })
        if (!newItemReactionRef) return existingItemReactionGroupsRefs

        const existingReactionGroup = existingItemReactionGroupsRefs.find(
          (reactionGrp) =>
            readField('reaction', reactionGrp) === newReaction.reaction
        )

        // if we already have a reaction group we modify that cache entry instead if needed
        if (existingReactionGroup) {
          const alreadyUpdated = readField<Reference[]>(
            'itemReactions',
            existingReactionGroup
          )?.some(
            (itemReactionRefInExistingGroup) =>
              itemReactionRefInExistingGroup.__ref === newItemReactionRef.__ref
          )

          if (alreadyUpdated) return existingItemReactionGroupsRefs

          cache.modify({
            id: cache.identify(existingReactionGroup),
            fields: {
              itemReactions(existingItemReactionRefs: Reference[]) {
                return uniqBy(
                  [newItemReactionRef, ...existingItemReactionRefs],
                  '__ref'
                )
              },
              hasReacted() {
                return true
              },
              count(existingCount) {
                return existingCount + 1
              },
            },
          })
          return existingItemReactionGroupsRefs
        }

        return produce(existingItemReactionGroupsRefs, (next: any) => {
          next.push({
            __typename: 'ItemReactionGroup',
            id: `${parentId}-${newReaction.reaction}`,
            count: 1,
            reaction: newReaction.reaction,
            hasReacted: true,
            itemReactions: [newItemReactionRef],
          })
        })
      },
    },
  })
}

export { onRemoveItemReactionCacheUpdate, onAddItemReactionCacheUpdate }
