import FileSaver from 'file-saver'
import JSZip from 'jszip'
// @ts-ignore
import JSZipUtils from 'jszip-utils'

import { db, storage } from 'config/firebase'
import { WorkspaceItemGridFieldsFragment } from 'generated/graphql'
import { getFileExtension } from 'helpers/getFileExtension'
import { trackItemDownloaded } from 'helpers/tracking/tracking'
import { ItemRefWithId } from 'types/custom'
import { DBSearchableItem } from 'types/db'
import { TItem } from 'types/typesense'

import { getItemMediaType } from './getItemMediaType'
import { getItemMediaUrl } from './getItemMediaUrl'

const addExtensionIfNeeded = (
  title: string | undefined,
  sourceExtension: string
) => {
  if (!title) return title
  const extension = getFileExtension(title)

  // Temporarily include this check to allow .mp4
  if (title === 'Your Kive 2021 Wrapped') {
    return title
  }
  if (!extension) {
    return `${title}.${sourceExtension}`
  }
  if (extension !== sourceExtension) {
    // if the title extension is not the same as the image source extension, we replace it
    // return title.replace(`.${extension}`, `.${sourceExtension}`)
    return `${title.slice(0, title.lastIndexOf('.'))}.${sourceExtension}`
  }
  return title
}

const downloadItem = async (
  { internalPath, publicUrl }: { internalPath?: string; publicUrl?: string },
  title: string | undefined,
  trackingContext: Parameters<typeof trackItemDownloaded>[0]
) => {
  const storageRef = storage.ref()

  let url = publicUrl

  if (internalPath) {
    url = await storageRef.child(internalPath).getDownloadURL()
  }
  if (!url) {
    return console.error('Could not find a item url to download')
  }

  const sourceExtension = getFileExtension(
    internalPath || (publicUrl as string)
  )

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.responseType = 'blob'
    xhr.onload = () => {
      const blob = xhr.response as Blob
      const fileName =
        addExtensionIfNeeded(title, sourceExtension) ||
        url!.split('/').pop()?.split('?')[0]

      FileSaver.saveAs(blob, fileName)
      const trackingContextWithExtension = {
        ...trackingContext,
        sourceExtension,
      }
      trackItemDownloaded(trackingContextWithExtension)
      resolve(fileName)
    }
    xhr.onerror = (err) => {
      reject(err)
    }
    xhr.open('GET', url!)
    xhr.send()
  })
}

const getBinaryFromUrl = (url: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    JSZipUtils.getBinaryContent(url, (err: any, data: any) => {
      if (err) {
        reject(err)
      } else {
        resolve(data)
      }
    })
  })
}

const formatDate = (date: Date) => {
  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = date.getFullYear()

  return `${year}-${month}-${day}`
}

const getItemTitle = (
  item: TItem | ItemRefWithId | WorkspaceItemGridFieldsFragment
) => {
  if ('__typename' in item) {
    return item.title ?? undefined
  }
  if ('isTypesenseItem' in item) {
    return item.originName
  }
  return item.origin?.name
}

// temporary code as GIF typesense item does not include large gif urls
const getItem = async (item: TItem) => {
  // we have to fetch all item data as typesense item does not consist of all data and the correct structure
  const res = await db.collection('searchableItems').doc(item.id).get()
  return {
    ...(res.data() as DBSearchableItem),
    id: res.id,
  }
}

const getDowndloadUrl = async (
  item: TItem | ItemRefWithId | WorkspaceItemGridFieldsFragment
) => {
  const mediaType = getItemMediaType(item)

  if (mediaType === 'gif' && 'isTypesenseItem' in item) {
    const searchableItem = await getItem(item)
    return getItemMediaUrl({
      item: searchableItem as ItemRefWithId, // As itemRefs and SearchableItems are almost identical we do this type casting. But mostly because this is a temporary solution
      variant: 'large',
      mediaType,
    })
  }
  return getItemMediaUrl({ item, variant: 'large', mediaType })
}

type DownloadItemsArgs = {
  items: Array<TItem | ItemRefWithId | WorkspaceItemGridFieldsFragment>
  trackingContext: { workspaceId?: string; boardId?: string }
}

export const downloadItems = async ({
  items,
  trackingContext,
}: DownloadItemsArgs) => {
  const zip = new JSZip()
  await Promise.allSettled(
    items.map(async (item) => {
      const url = await getDowndloadUrl(item)
      if (!url) {
        console.warn('Could not find url for item', { item })
        return
      }
      const itemTitle = getItemTitle(item)
      const fileName =
        addExtensionIfNeeded(itemTitle || item.id, getFileExtension(url)) ||
        url.substring(1 + url.lastIndexOf('/'))

      const trackingContextWithExtension = {
        ...trackingContext,
        itemId: item.id,
        sourceExtension: getFileExtension(url),
      }

      trackItemDownloaded(trackingContextWithExtension)
      zip.file(fileName, getBinaryFromUrl(url), {
        binary: true,
      })
    })
  )
  const zipFile = await zip.generateAsync({ type: 'blob' })
  saveAs(zipFile, `kive-download-${formatDate(new Date())}`)
}

export default downloadItem
