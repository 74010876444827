import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { useTheme } from 'styled-components'

import { Text } from 'components/common/Text'
import { permissionInfoText } from 'components/toast/ToastMessages'
import useToastMessages from 'components/toast/useToastMessages'
import mapToPalette from 'helpers/mapToPalette'
import useWorkspacePermissions from 'hooks/useWorkspacePermissions'
import { RootState } from 'store'
import { setIsUploadVisible } from 'store/content/actions'

import { useSearchContext } from './SearchContext'
import { SearchGrid } from './SearchGrid'
import { SearchHeading } from './SearchStyles'
import { getParsedFreeText } from './helpers/getParsedFreeText'

interface SearchResultsProps {
  wrapperElement: HTMLDivElement
  isSearchPanelOpen: boolean
  displayQuery: string
}

const SearchResultsItems: React.FC<SearchResultsProps> = ({
  wrapperElement,
  isSearchPanelOpen,
  displayQuery,
}) => {
  const { reportError } = useToastMessages()
  const userAllowedTo = useWorkspacePermissions(['UPLOAD'])
  const theme = useTheme()
  const dispatch = useDispatch()
  const { freeText, tags, color, face, folders, filename, fileFormat } =
    useSearchContext()
  const workspaceId = useSelector(
    (state: RootState) => state.content.activeWorkspace?.id
  )!

  const tagIds = tags.map((tag) => tag.id)
  const colors = color ? [color] : []
  const mappedColors = colors.map((c) => mapToPalette(c))

  const isSearching = Boolean(
    freeText ||
      color ||
      tagIds.length > 0 ||
      face.length > 0 ||
      folders.length > 0 ||
      filename ||
      fileFormat
  )

  const [parsedFreeText] = getParsedFreeText(freeText)

  return (
    <StyledSearchResultsContainer>
      {isSearching && (
        <>
          {isSearchPanelOpen && <SearchHeading>Items</SearchHeading>}
          <SearchGrid
            scrollNode={wrapperElement}
            searchQuery={parsedFreeText}
            filterWorkspaces={[workspaceId]}
            filterTags={tagIds}
            filterFolders={folders}
            filterColors={mappedColors}
            filterFaceTags={face}
            filterFilename={filename}
            filterFileFormat={fileFormat}
            emptyContent={
              <>
                <Text size="base">
                  No search results for &quot;{displayQuery}&quot;, try
                  searching for something else or{' '}
                  <span
                    style={{
                      color: theme.colors.accent[1],
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      if (!userAllowedTo.UPLOAD) {
                        return reportError(permissionInfoText('Upload'))
                      }
                      dispatch(setIsUploadVisible(true))
                    }}
                  >
                    upload more items to your workspace.
                  </span>
                </Text>
              </>
            }
          />
        </>
      )}
    </StyledSearchResultsContainer>
  )
}

const StyledSearchResultsContainer = styled.div`
  padding: 0 16px;
`

export default SearchResultsItems
