import { ExtendedFirestoreInstance } from 'react-redux-firebase'

import { db } from 'config/firebase'
import {
  getNewBoardItemPositionIfNeeded,
  reInitializeBoardItemsPositionsIfNeeded,
} from 'helpers/boardItemOperations'
import { reportError } from 'helpers/logging'
import { ItemRefWithId } from 'types/custom'

const moveItemRefs = async (
  itemRefs: ItemRefWithId[],
  destinationBoardId: string,
  destinationProjectId: string,
  firestore: ExtendedFirestoreInstance
) => {
  try {
    // if the destination board has custom positioning - find a position for the new board item
    const position = await getNewBoardItemPositionIfNeeded(destinationBoardId)

    const batch = firestore.batch()

    // we keep track of different source boards count as items could be moved from different boards (with help of multiselect)
    const sourceBoardsCount: Record<string, number> = {}

    itemRefs.forEach((itemRef, index) => {
      const ref = firestore.collection('itemRefs').doc(itemRef.id)
      batch.update(ref, {
        boardId: destinationBoardId,
        projectId: destinationProjectId,
        position:
          position === undefined
            ? firestore.FieldValue.delete()
            : position + index,
      })
      const sourceBoardCount = sourceBoardsCount[itemRef.boardId]
      if (sourceBoardCount) {
        sourceBoardsCount[itemRef.boardId] = sourceBoardCount + 1
      } else {
        sourceBoardsCount[itemRef.boardId] = 1
      }
    })

    await batch.commit()

    const destinationBoardRef = firestore
      .collection('boards')
      .doc(destinationBoardId)

    const batchTwo = firestore.batch()

    Object.entries(sourceBoardsCount).forEach(([boardId, count]) => {
      const sourceBoardRef = firestore.collection('boards').doc(boardId)
      batchTwo.update(sourceBoardRef, {
        savedItemCount: firestore.FieldValue.increment(count * -1),
      })
    })

    batchTwo.update(destinationBoardRef, {
      savedItemCount: firestore.FieldValue.increment(itemRefs.length),
    })

    await Promise.all([
      batchTwo.commit(),
      // in case the sourceboards have custom positioning we re-initialize the positions
      ...Object.keys(sourceBoardsCount).map((sourceBoardId) =>
        reInitializeBoardItemsPositionsIfNeeded(sourceBoardId)
      ),
    ])
  } catch (error) {
    reportError(
      new Error(
        `Error when moving board items to new board. ItemsLength:${itemRefs.length}. DestinationId:${destinationBoardId}. ErrorMsg:${error.message}`
      )
    )
  }
}

const copyItemRefs = async (
  itemRefs: ItemRefWithId[],
  destinationBoardId: string,
  firestore: ExtendedFirestoreInstance
) => {
  try {
    const batch = firestore.batch()

    const position = await getNewBoardItemPositionIfNeeded(destinationBoardId)

    itemRefs.forEach((itemRef, index) => {
      const newItemRef = {
        ...itemRef,
        boardId: destinationBoardId,
        openCount: 0,
        commentCount: 0,
        reactionCounters: null,
        position:
          position === undefined
            ? firestore.FieldValue.delete()
            : position + index,
      }
      const newBoardItemRef = db.collection('itemRefs').doc()
      batch.set(newBoardItemRef, newItemRef, { merge: true })
    })

    await batch.commit()
  } catch (error) {
    reportError(
      new Error(
        `Error when copying board items to new board. ItemsLength:${itemRefs.length}. DestinationId:${destinationBoardId}. ErrorMsg:${error.message}`
      )
    )
  }
}

export { moveItemRefs, copyItemRefs }
