import styled from 'styled-components'

import useKeyupListener from '../../../hooks/useKeyupListener'

type GridItemOverlayMenuProps = {
  children: React.ReactNode
  onClose: () => void
  width?: number
}

export const GridItemOverlayMenu = ({
  children,
  onClose,
  width = 270,
}: GridItemOverlayMenuProps) => {
  useKeyupListener('Escape', onClose)
  return (
    <StyledGridItemOverlayMenu
      style={{ width }}
      onKeyDown={(e) => {
        if (e.key === ' ') {
          e.stopPropagation()
        }
      }}
    >
      {children}
    </StyledGridItemOverlayMenu>
  )
}

const StyledGridItemOverlayMenu = styled.div(({ theme }) => ({
  pointerEvents: 'auto',
  backgroundColor: theme.colors.background[3],
  zIndex: 1000,
  borderRadius: theme.borderRadius.default,
  maxWidth: '100%',
  border: `${theme.border.thin} ${theme.colors.discreet}`,
  boxShadow: theme.shadow.minimal,
  overflow: 'hidden',
}))
