import { motion } from 'framer-motion'
import styled, { css, keyframes } from 'styled-components'

import { Input } from '../common/Form'

const Content = styled.div<{ isMobile: boolean }>(({ theme, isMobile }) => ({
  marginTop: 8,
  position: 'relative',
  width: '100%',
  height: '100%',
  maxWidth: 600,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  color: theme.colors.text.neutral[3],
  '@media (max-width: 632px)': {
    maxWidth: isMobile ? 'calc(100% - 16px)' : 'calc(100% - 48px)',
  },
}))

const Main = styled.div<{ isMobile: boolean }>(({ theme, isMobile }) => ({
  background: theme.colors.background[1],
  minHeight: '100vh',
  textAlign: isMobile ? 'left' : 'center',
  fontFamily: theme.fontFamily.primary,
  display: 'flex',
  alignItems: isMobile ? 'left' : 'center',
  flexDirection: 'column',
  justifyContent: isMobile ? 'top' : 'center',
  overflow: 'hidden !important',
}))

const fadeFromBelow = keyframes`
  from {
    opacity: 0;
    transform: translateY(50px)
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
`

const ContentContainer = styled.div<{ isMobile: boolean }>(
  ({ isMobile }) => css`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: ${isMobile ? 'left' : 'center'};
    justify-content: ${isMobile ? 'top' : 'center'};
    animation: ${fadeFromBelow} 250ms ease-out;
    position: relative;
    padding: ${isMobile ? '32px 0 0 0' : '32px 0px 32px 0px'};
  `
)

const Description = styled.div(({ theme, style }) => ({
  fontSize: '1.125rem',
  color: theme.colors.text.neutral[0],
  fontWeight: theme.fontWeight.normal,
  ...style,
}))

const getRowMaxWidth = (
  fullWidth?: boolean,
  wide?: boolean,
  narrow?: boolean
) => {
  if (fullWidth) return '100%'
  if (wide) return 'calc(100% - 64px)'
  if (narrow) return 400
  return 540
}

const getRowMobileMaxWidth = (
  fullWidth?: boolean,
  wide?: boolean,
  narrow?: boolean
) => {
  if (fullWidth) return '100%'
  if (wide) return 'calc(100% - 64px)'
  if (narrow) return 'calc(80%)'
  return '90%'
}

type RowsArgs = {
  isMobile?: boolean
  wide?: boolean
  fullWidth?: boolean
  narrow?: boolean
}
const Row = styled.div<RowsArgs>(
  ({ fullWidth, wide, narrow, isMobile, style }) => ({
    textAlign: isMobile ? 'left' : 'center',
    marginLeft: isMobile ? '10px' : '0',
    marginBottom: 10,
    marginTop: 10,
    width: '100%',
    maxWidth: getRowMaxWidth(fullWidth, wide, narrow),
    ...style,
    '@media (max-width: 632px)': {
      maxWidth: getRowMobileMaxWidth(fullWidth, wide, narrow),
    },
  })
)

const PaginationContainer = styled.div(({ theme }) => ({
  display: 'flex',
  height: 4,
  width: '100%',
  background: theme.colors.background[5],
  marginBottom: 16,
  position: 'absolute',
  top: 0,
  '@media (max-width: 632px)': {
    height: 8,
  },
}))

type PaginationItemArgs = {
  activeIndex: number
  length: number
  delay: number
}

const PaginationItem = styled.div<PaginationItemArgs>(
  ({ theme, activeIndex, length, delay }) => {
    const width = (100 / length) * (activeIndex + 1)
    return {
      background: theme.colors.accent[2],
      width: `${width}%`,
      position: 'relative',
      transition: `ease-in-out ${delay}s`,
    }
  }
)

type ProfileImageArgs = {
  isMobile?: boolean
  imageUrl?: string
}

const ProfileImage = styled.div<ProfileImageArgs>`
  ${({ theme, isMobile, imageUrl }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-left: ${isMobile ? '20px' : 0};
    margin-bottom: 16px;
    background-color: ${theme.colors.background};
    background-size: cover;
    background-image: url(${imageUrl});
    cursor: pointer;
    border: ${`${theme.border.thin} ${theme.colors.background[5]}`};
    border-style: ${imageUrl ? 'solid' : 'dashed'};
    &:hover {
      border: ${`${theme.border.thin} ${theme.colors.accent[2]}`};
    }
  `}
`

type WorkspaceIconArgs = {
  isMobile?: boolean
  imageUrl?: string
}

const WorkspaceIcon = styled.div<WorkspaceIconArgs>`
  ${({ theme, isMobile, imageUrl }) => css`
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    margin-left: ${isMobile ? '20px' : 0};
    margin-bottom: 16px;
    background-color: ${theme.colors.background[1]};
    background-size: cover;
    background-image: url(${imageUrl});
    cursor: pointer;
    left: 50%;
    transform: translate(-50%);
    margin: 20px 0;
    border: ${`${theme.border.thin} ${theme.colors.background[5]}`};
    border-style: ${imageUrl ? 'solid' : 'dashed'};
    &:hover {
      border: ${`${theme.border.thin} ${theme.colors.accent[2]}`};
    }
  `}
`

const ProfileImageOverlay = styled(motion.div)`
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgb(14, 14, 14, 0.5);
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`

const WorkspaceIconOverlay = styled(motion.div)`
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgb(14, 14, 14, 0.5);
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Heading = styled.div<{ isMobile?: boolean }>(({ theme, isMobile }) => ({
  textAlign: isMobile ? 'left' : 'right',
  fontSize: theme.fontSizes.xl,
  margin: 24,
  marginTop: isMobile ? 0 : 24,
  marginBottom: isMobile ? 0 : 24,
  color: theme.colors.text.neutral[0],
}))

const HeadingOption = styled.div<{ isMobile: boolean }>(
  ({ theme, isMobile }) => ({
    cursor: 'pointer !important',
    fontSize: isMobile ? '1.5rem' : theme.fontSizes.xl,
    width: '100%',
    color: theme.colors.text.neutral[0],
    display: 'grid',
    justifyItems: 'left',
    alignItems: 'center',
    gridTemplateColumns: 'auto 1fr',
  })
)

const JoinableWorkspace = styled.div<{ isMobile: boolean }>(
  ({ theme, isMobile }) => ({
    background: theme.colors.background[1],
    fontSize: theme.fontSizes.base,
    color: theme.colors.text.neutral[1],
    borderRadius: theme.borderRadius.default,
    border: `${theme.border.thin} ${theme.colors.discreet}`,
    width: isMobile ? 'calc(100vw - 34px)' : 400,
    height: 48,
    margin: 4,
    overflow: 'hidden',
    padding: 8,
    display: 'inline-flex',
    alignItems: 'center',
    position: 'relative',
    cursor: 'pointer',
    ':hover': {
      color: theme.colors.text.neutral[1],
      background: theme.colors.background[4],
    },
  })
)

type JoinableWorkspaceIconArgs = {
  icon: string
}

const JoinableWorkspaceIcon = styled.div<JoinableWorkspaceIconArgs>(
  ({ theme, icon }) => ({
    display: 'grid',
    alignItems: 'center',
    backgroundColor: theme.colors.gray.medium[1],
    backgroundImage: icon.length > 1 ? `url(${icon})` : '',
    backgroundSize: 'contain',
    marginRight: 6,
    borderRadius: theme.borderRadius.sm,
    height: 26,
    width: 26,
  })
)

const ImageContainer = styled.div<{ isMobile: boolean; src?: string }>(
  ({ theme, src, style, isMobile }) => ({
    height: isMobile ? 120 : 300,
    width: isMobile ? 120 : 300,
    transform: 'rotate(65deg)',
    backgroundColor: theme.colors.background[1],
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundImage: src && `url(${src})`,
    marginTop: isMobile ? 20 : -250,
    marginBottom: -17,
    alignSelf: 'center',
    ...style,
  })
)

const Center = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const RowDescription = styled.div<{ isMobile: boolean }>(
  ({ theme, style, isMobile }) => ({
    fontSize: isMobile ? theme.fontSizes.base : theme.fontSizes.xs,
    color: theme.colors.gray.medium[0],
    textAlign: 'left',
    marginTop: 8,
    ...style,
  })
)

const Footer = styled.div<{ isMobile?: boolean; isForcedBottom?: boolean }>(
  ({ theme, isMobile }) => ({
    position: isMobile ? 'fixed' : 'initial',
    width: '100%',
    bottom: 0,
    left: 0,
    color: theme.colors.text.neutral[3],
    display: 'flex',
    alignItems: 'bottom',
    justifyContent: 'bottom',
    flexDirection: 'column',
    marginTop: isMobile ? 16 : 32,
    fontSize: theme.fontSizes.base,
  })
)

const ErrorMessage = styled.div(({ theme }) => ({
  fontSize: 13,
  fontWeight: theme.fontWeight.medium,
  color: '#f33',
  padding: 16,
}))

const InputStyled = styled(Input)(({ theme, style, prefix }) => ({
  fontSize: theme.fontSizes.base,
  border: `${theme.border.thin} ${theme.colors.background[3]}`,
  ':hover': {
    border: `${theme.border.thin} ${theme.colors.accent[2]}`,
    background: theme.colors.background[3],
  },
  '@media (max-width: 632px)': {
    width: 'calc(100% - 8px)',
  },
  ...style,
}))

const UploadButton = styled.div<{ isMobile?: boolean }>(
  ({ theme, isMobile }) => ({
    background: theme.colors.background[1],
    color: theme.colors.text.neutral[1],
    borderRadius: theme.borderRadius.default,
    border: `${theme.border.thin} ${theme.colors.discreet}`,
    margin: '8px 12px',
    overflow: 'hidden',
    padding: 12,
    alignItems: 'center',
    position: 'relative',
    cursor: 'pointer',
    ':hover': {
      border: `${theme.border.thin} ${theme.colors.accent[2]}`,
      color: theme.colors.text.neutral[0],
    },
  })
)

const StyledButton = styled.button<{ disabled: boolean }>(
  ({ theme, disabled, style }) => ({
    borderRadius: theme.borderRadius.default,
    width: '100%',
    boxSizing: 'border-box',
    height: 44,
    cursor: disabled ? 'default' : 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 16px',
    border: 'none',
    color: disabled
      ? theme.colors.text.neutral[2]
      : theme.colors.text.neutral[0],
    background: disabled ? theme.colors.background[4] : theme.colors.accent[2],
    outline: 'none',
    ...style,
    ':hover': {
      background: disabled
        ? theme.colors.background[4]
        : theme.colors.accent[2], // '#144BFF',
    },
    // ':active': {
    //   background: !disabled && theme.colors.accent[1], // '#164AAA',
    // },
  })
)

const MobileButton = styled.button(
  ({ theme, disabled }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${disabled
      ? theme.colors.background[4]
      : theme.colors.accent[2]};
    border: none;
    color: ${disabled
      ? theme.colors.text.neutral[3]
      : theme.colors.text.neutral[0]};
    margin: 0;
    width: 100%;
    height: 80px;
    cursor: pointer;
  `
)

const SkipText = styled.div<{ isMobile: boolean }>(({ theme, isMobile }) => ({
  position: 'absolute',
  bottom: 24,
  width: '100%',
  textAlign: isMobile ? 'left' : 'center',
  color: theme.colors.text.neutral[6],
  cursor: 'pointer',
  padding: '0px 22px',
  ':hover': {
    color: theme.colors.text.neutral[2],
  },
}))

export const DashedButton = styled.div<{ isMobile: boolean }>(
  ({ theme, isMobile }) => ({
    width: isMobile ? 'calc(100vw - 34px)' : 400,
    margin: 4,
    marginLeft: isMobile ? 10 : 'calc(50% - 200px)',
    border: `1px dashed ${theme.colors.discreet}`,
    borderRadius: theme.borderRadius.default,
    color: theme.colors.text.neutral[3],
    cursor: 'pointer',
    padding: isMobile ? 14 : '8px 12px',
    textAlign: 'left',
    fontSize: theme.fontSizes.base,
    ':hover': {
      color: theme.colors.text.neutral[1],
      border: `1px dashed ${theme.colors.gray.dark[7]}`,
    },
  })
)

export {
  Main,
  ContentContainer,
  PaginationContainer,
  PaginationItem,
  ProfileImage,
  WorkspaceIcon,
  ProfileImageOverlay,
  WorkspaceIconOverlay,
  Heading,
  HeadingOption,
  JoinableWorkspace,
  JoinableWorkspaceIcon,
  Description,
  Content,
  Center,
  Row,
  RowDescription,
  Footer,
  ErrorMessage,
  InputStyled,
  UploadButton,
  StyledButton,
  MobileButton,
  ImageContainer,
  SkipText,
}
