import { ArrowRightShort } from '@styled-icons/bootstrap'
import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { compose } from 'redux'

import Loading from 'components/Loading'
import { useJoinableWorkspacesQueries } from 'components/joinworkspace/useJoinableWorkspacesQueries'
import { db } from 'config/firebase'
import useCurrentUser from 'hooks/useCurrentUser'
import useIsMobile from 'hooks/useIsMobile'

import { setActiveWorkspace } from '../../store/content/actions'
import SetupGoBack from './SetupGoBack'
import { ContentContainer, HeadingOption } from './SetupStyles'
import SetupWorkspaceCreateNew from './SetupWorkspaceCreateNew'
import SetupWorkspaceJoinExisting from './SetupWorkspaceJoinExisting'

const variants = {
  joinWorkspaceTitle: {
    LOADING: {
      opacity: 0,
    },
    CREATE: {
      opacity: 0.1,
      y: 0,
    },
    JOIN: {
      opacity: 1,
      transition: { duration: 0.3 },
    },
  },
  joinWorkspaceOptions: {
    LOADING: {
      opacity: 0,
      display: 'none',
    },
    JOIN: {
      opacity: 1,
      display: 'inline',
    },
    CREATE: {
      opacity: 0,
      display: 'none',
    },
  },
  createWorkspaceTitle: {
    LOADING: {
      opacity: 0,
    },
    JOIN: { opacity: 0.1 },
    CREATE: { opacity: 1 },
  },
  createWorkspaceOptions: {
    LOADING: {
      opacity: 0,
      display: 'none',
    },
    CREATE: {
      opacity: 1,
      display: 'inline',
    },
    JOIN: {
      opacity: 0,
      display: 'none',
    },
  },
}

const SetupWorkspace = (props) => {
  const location = useLocation()
  const navigate = useNavigate()
  const currentUser = useCurrentUser()
  const { data: joinableWorkspaces, loading } = useJoinableWorkspacesQueries()

  const isMobile = useIsMobile()
  const params = new URLSearchParams(location.search)
  const trackingSetupSessionId = params.get('sessionId')
  const isFromLibrary = params.get('new') === 'true'
  const workspaceId = params.get('workspaceId')
  const fromPublicFeature = params.get('fromPublicFeature')
    ? `fromPublicFeature=${params.get(
        'fromPublicFeature'
      )}&boardId=${params.get('boardId')}`
    : null

  const shouldDefaultToCreate = isFromLibrary || workspaceId
  const [mode, setMode] = useState(shouldDefaultToCreate ? 'CREATE' : 'LOADING')

  const navigateToNextSetupStep = async (workspaceItem, withTeam) => {
    if (withTeam) {
      if (props.completedInvite) {
        // Set next setup step to unfinished in case of new workspace for registered user
        await db
          .collection('users')
          .doc(currentUser.uid)
          .collection('private')
          .doc('onboarding')
          .update({ completedInvite: false })
      }

      navigate(
        `/setup/invite?workspaceId=${
          workspaceItem.id
        }&new=${isFromLibrary}&withTeam=${withTeam}&sessionId=${trackingSetupSessionId}${
          params.get('fromPublicFeature') ? `&${fromPublicFeature}` : ''
        }`
      )
    } else {
      // we skip the invite step for non teams and mark it as completed
      await db
        .collection('users')
        .doc(currentUser.uid)
        .collection('private')
        .doc('onboarding')
        .update({ completedInvite: true })

      navigate(
        `/${workspaceItem.url}/upload?workspaceId=${
          workspaceItem.id
        }&new=${isFromLibrary}&withTeam=${withTeam}&sessionId=${trackingSetupSessionId}${
          params.get('fromPublicFeature') ? `&${fromPublicFeature}` : ''
        }`
      )
    }
  }

  const handleCreateNewWorkspace = (workspaceItem, withTeam) => {
    // Set active workspace
    props.setActiveWorkspace(workspaceItem)
    navigateToNextSetupStep(workspaceItem, withTeam)
  }

  useEffect(() => {
    if (!shouldDefaultToCreate && !loading && joinableWorkspaces.length === 0) {
      setMode('CREATE')
    }
    if (!shouldDefaultToCreate && !loading && joinableWorkspaces.length !== 0) {
      setMode('JOIN')
    }
  }, [shouldDefaultToCreate, loading, joinableWorkspaces.length])

  if (loading) {
    return <Loading />
  }

  return (
    <>
      {/* Send the value of the correspoding setup step in firestore user => private => onboarding */}
      {!isFromLibrary ? (
        <SetupGoBack uid={currentUser.uid} previousSetup="completedProfile" />
      ) : (
        <SetupGoBack custom />
      )}

      <ContentContainer isMobile={isMobile} css="padding: 70px 16px 0px 16px">
        {joinableWorkspaces.length > 0 && (
          <motion.div
            style={{ width: '100%' }}
            layout
            variants={variants.joinWorkspaceTitle}
            whileHover={{ opacity: mode === 'CREATE' ? 0.5 : 1 }}
            initial={mode}
            animate={mode}
          >
            <HeadingOption isMobile={isMobile} onClick={() => setMode('JOIN')}>
              <ArrowRightShort
                size={40}
                style={{
                  transform: mode === 'CREATE' ? '' : 'rotate(90deg)',
                  transition: 'ease-in-out 0.1s',
                }}
              />
              Join a workspace
            </HeadingOption>
          </motion.div>
        )}

        <AnimatePresence layout>
          <motion.div
            layout
            variants={variants.joinWorkspaceOptions}
            initial={mode}
            animate={mode}
          >
            <SetupWorkspaceJoinExisting
              trackingSetupSessionId={trackingSetupSessionId}
              joinableWorkspaces={joinableWorkspaces}
            />
          </motion.div>
        </AnimatePresence>
        <motion.div
          layout
          style={{ width: '100%' }}
          whileHover={{ opacity: mode === 'JOIN' ? 0.5 : 1 }}
          variants={variants.createWorkspaceTitle}
          initial={mode}
          animate={mode}
        >
          <HeadingOption isMobile={isMobile} onClick={() => setMode('CREATE')}>
            <ArrowRightShort
              size={40}
              style={{
                transform: mode === 'JOIN' ? '' : 'rotate(90deg)',
                transition: 'ease-in-out 0.5s',
              }}
            />
            Create a workspace
          </HeadingOption>
        </motion.div>
        <AnimatePresence layout>
          {mode === 'CREATE' && (
            <>
              <motion.div
                layout
                variants={variants.createWorkspaceOptions}
                initial={mode}
                animate={mode}
              >
                <SetupWorkspaceCreateNew
                  trackingSetupSessionId={trackingSetupSessionId}
                  workspaceId={workspaceId}
                  handleCreateNewWorkspace={handleCreateNewWorkspace}
                />
              </motion.div>
            </>
          )}
        </AnimatePresence>
      </ContentContainer>
    </>
  )
}

const mapStateToProps = (state, ownProps) => ({
  activeWorkspace: state.content.activeWorkspace,
  completedInvite: state.firestore.data.userPrivate?.onboarding.completedInvite,
})

const mapDispatchToProps = (dispatch) => ({
  setActiveWorkspace: (activeWorkspace) => {
    dispatch(setActiveWorkspace(activeWorkspace))
  },
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SetupWorkspace
)
