import { useDispatch, useStore } from 'react-redux'

import { RootState } from 'store'
import { selectItems } from 'store/multiSelect'
import { boardItemsSelector } from 'store/selectors'

const useSelectAllCurrentBoardItems = (boardId?: string) => {
  const store = useStore()
  const dispatch = useDispatch()

  const handleSelectAllCurrentBoardItems = () => {
    if (!boardId) {
      return null
    }

    const boardItems = boardItemsSelector(
      store.getState() as RootState,
      boardId
    )

    if (!boardItems) {
      return null
    }

    dispatch(
      selectItems(
        boardItems.map((boardItem) => ({
          id: boardItem.id,
          item: boardItem,
        }))
      )
    )
  }
  return handleSelectAllCurrentBoardItems
}

export { useSelectAllCurrentBoardItems }
