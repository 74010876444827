import { Heart } from '@styled-icons/boxicons-regular/Heart'
import { Video } from '@styled-icons/entypo/Video'
import { Chrome } from '@styled-icons/feather/Chrome'
import { UploadCloud } from '@styled-icons/feather/UploadCloud'
import { DeviceMobile } from '@styled-icons/heroicons-outline/DeviceMobile'
import { LayoutMasonry } from '@styled-icons/remix-line/LayoutMasonry'
import { StyledIcon } from '@styled-icons/styled-icon'

export type SetupTaskId =
  | 'upload'
  | 'extractFrames'
  | 'createProject'
  | 'mobileApp'
  | 'browserExtension'
  | 'referFriend'

export type SetupTaskInfo = {
  id: SetupTaskId
  // eslint-disable-next-line @typescript-eslint/naming-convention
  IconComponent: StyledIcon
  title: string
  subTitle: string
}

export const setupTasksInfo: Array<SetupTaskInfo> = [
  {
    id: 'upload',
    IconComponent: UploadCloud,
    title: 'Upload something',
    subTitle: 'Fill up your library',
  },
  {
    id: 'extractFrames',
    IconComponent: Video,
    title: 'Extract frames',
    subTitle: 'Pull shots from videos',
  },
  {
    id: 'createProject',
    IconComponent: LayoutMasonry,
    title: 'Create a project',
    subTitle: 'Collect references for presentations',
  },
  {
    id: 'mobileApp',
    IconComponent: DeviceMobile,
    title: 'Get iOS App',
    subTitle: 'Open QR code',
  },
  {
    id: 'browserExtension',
    IconComponent: Chrome,
    title: 'Get chrome extension',
    subTitle: 'Go to Chrome store',
  },
  {
    id: 'referFriend',
    IconComponent: Heart,
    title: 'Refer a friend',
    subTitle: 'Give someone access to Kive',
  },
]
