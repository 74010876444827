import { useState } from 'react'
import { Rnd } from 'react-rnd'
import { Check, X } from 'styled-icons/bootstrap'

import useToastMessages from 'components/toast/useToastMessages'
import { preloadImage } from 'helpers/preloadImage'

import { StyledButtonMedium } from './ButtonMedium'
import { QueuedImageProps } from './FileUploader'
import { uploadToStorage } from './helpers/uploadToStorage'

export const QueueImage = ({
  image,
  scale = 1,
  canvasPosition = { x: 0, y: 0 },
  addImageToCanvas,
  cancel,
  editSessionId,
  disableDrag,
}: {
  image: QueuedImageProps
  scale: number
  canvasPosition?: {
    x: number
    y: number
  }
  addImageToCanvas: (
    id: string,
    imageUrl: string | null,
    x: number,
    y: number,
    w: number,
    h: number
  ) => void
  cancel: (id: string) => void
  editSessionId: string
  disableDrag: boolean
}) => {
  const { reportError } = useToastMessages()
  const [isUploading, setIsUploading] = useState(false)
  const [position, setPosition] = useState({
    x:
      -canvasPosition.x / scale -
      image.width / 2 +
      window.innerWidth / (2 * scale),
    y:
      -canvasPosition.y / scale -
      image.height / 2 +
      window.innerHeight / (2 * scale),
  })
  const [size, setSize] = useState({ width: image.width, height: image.height })

  const handleAcceptImage = async () => {
    setIsUploading(true)
    try {
      const imageUrl = await uploadToStorage({
        dataUrl: image.src,
        editSessionId,
      })
      await preloadImage(imageUrl).catch((err) =>
        console.log('Could not preload image', err)
      ) // we preload image before adding it, else the image will be gone for a second
      setIsUploading(false)

      addImageToCanvas(
        image.id,
        imageUrl,
        position.x,
        position.y,
        size.width,
        size.height
      )
    } catch (error) {
      reportError('Error uploading image. Try again...')
      setIsUploading(false)
    }
  }

  return (
    <>
      <Rnd
        style={{
          zIndex: 6,
          border: isUploading ? 0 : '1px solid #fff',
          backgroundImage: `url(${image.src})`,
          backgroundSize: 'contain',
        }}
        resizeHandleStyles={{
          bottomRight: {
            zIndex: 20,
            width: 20,
            height: 20,
            background: 'white',
            margin: 0,
            cursor: 'nwse-resize',
            transform: 'scale(0.5)',
          },
        }}
        size={{
          width: size.width * scale,
          height: size.height * scale,
        }}
        position={{
          x: position.x * scale + canvasPosition.x,
          y: position.y * scale + canvasPosition.y,
        }}
        lockAspectRatio
        onDrag={(_, d) => {
          if (!disableDrag) {
            const { x, y } = position
            const difX = (d.x - canvasPosition.x) / scale - x
            const difY = (d.y - canvasPosition.y) / scale - y
            setPosition({ x: x + difX, y: y + difY })
          }
        }}
        enableResizing={!isUploading}
        onResize={(_, __, ref) => {
          let { width, height } = ref.style as any
          if (typeof width === 'string') {
            width = parseInt(width.substring(0, width.length - 2), 10)
          }
          if (typeof height === 'string') {
            height = parseInt(height.substring(0, height.length - 2), 10)
          }
          setSize({
            width: width / scale,
            height: height / scale,
          })
        }}
      >
        <div style={{ color: 'white', position: 'absolute', top: -24 }}>
          {`${Math.round(size.width)} x ${Math.round(size.height)}`}
        </div>
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            height: 4,
            width: isUploading ? '100%' : 0,
            opacity: isUploading ? 1 : 0,
            background: '#5930E5',
            transition: 'width 3s',
          }}
        />
      </Rnd>
      {!isUploading && (
        <div
          style={{
            position: 'absolute',
            width: 64,
            display: 'flex',
            justifyContent: 'center',
            zIndex: 6,
            left:
              position.x * scale +
              canvasPosition.x +
              (scale * size.width) / 2 -
              32,
            top:
              position.y * scale + canvasPosition.y + size.height * scale + 8,
          }}
        >
          <StyledButtonMedium
            style={{ borderRadius: '8px 0 0 8px', borderRight: 0 }}
            onClick={() => {
              cancel(image.id)
            }}
          >
            <X width={20} />
          </StyledButtonMedium>

          <StyledButtonMedium
            onClick={handleAcceptImage}
            style={{ borderRadius: '0px 8px 8px 0' }}
          >
            <Check width={20} />
          </StyledButtonMedium>
        </div>
      )}
    </>
  )
}
