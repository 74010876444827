import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { Input as CommonInput } from '../common/Form'

export const ErrorMessage = styled.div`
  ${({ theme }) => css`
    font-size: 13px;
    font-weight: ${theme.fontWeight.medium};
    color: #f33;
    padding-bottom: 16px;
  `}
`

export const SettingsContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export const Sidebar = styled.aside`
  ${({ theme }) => css`
    min-width: 120px;
    max-width: 220px;
    flex: 1;
    background-color: ${theme.colors.background[1]};
    border-right: ${`${theme.border.thin} ${theme.colors.discreet}`};
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 16px 0px 0px 16px;
  `}
`

export const SidebarLinkMenu = styled.ul`
  padding: 0;
  margin: 0;
`

const MenuItem = styled.li`
  list-style: none;
  margin: 16px 0;
`

const MenuItemLink = styled(Link)`
  ${({ theme, active }) => css`
    padding: 8px 0;
    color: ${active ? theme.colors.accent[2] : theme.colors.text.neutral[3]};
    font-size: ${theme.fontSizes.sm};
    font-weight: ${theme.fontWeight.medium};
    :hover {
      color: ${theme.colors.accent[2]};
    }
  `}
`
/** @param {React.ComponentProps<Link> & {active?: boolean }} props */
export const SidebarLink = (props) => (
  <MenuItem>
    <MenuItemLink {...props} />
  </MenuItem>
)

export const SettingsContent = ({
  children,
  afterContent = null,
  ...props
}) => (
  <Wrapper {...props}>
    <Content>{children}</Content>
    {afterContent}
  </Wrapper>
)

const Wrapper = styled.div`
  overflow: auto;
  padding: ${({ noPadding }) => (noPadding ? 0 : 24)}px;
  flex: 1;
  display: flex;
  flex-direction: column;
`
const Content = styled.main`
  min-width: 550px;
  flex: 1;
`

/** @type {AppStyledComponent<'h2', {noMargin?: boolean, marginTop?: boolean, marginBottom?: boolean}>} */
export const Heading = styled.h2`
  ${({ theme, noMargin, marginTop, marginBottom }) => css`
    color: ${theme.colors.text.neutral[0]};
    font-size: ${theme.fontSizes.base};
    font-weight: ${theme.fontWeight.bold};
    margin-top: ${noMargin ? 0 : marginTop ?? 32}px;
    margin-bottom: ${noMargin ? 0 : marginBottom ?? 32}px;
  `}
`

const HeadingSmaller = styled(Heading)`
  ${({ theme, noMargin, noMarginTop, noMarginBottom }) => css`
    font-size: ${theme.fontSizes.sm};
    margin-top: ${noMargin || noMarginTop ? 0 : 32}px;
    margin-bottom: ${noMargin || noMarginBottom ? 0 : 16}px; ;
  `}
`

export const HeadingSecondary = (props) => <HeadingSmaller as="h3" {...props} />

const getBackgroundColor = (theme, disabled, subdued) => {
  if (disabled) return theme.colors.background[4]
  if (subdued) return theme.colors.background[5]
  return theme.colors.accent[2]
}

/** @type {AppStyledComponent<'button', {noMargin?: boolean, subdued?: boolean }>} */
export const Button = styled.button`
  ${({ theme, disabled, subdued }) => css`
    text-align: center;
    display: block;
    background-color: ${getBackgroundColor(theme, disabled, subdued)};
    color: ${disabled
      ? theme.colors.text.neutral[1]
      : theme.colors.text.neutral[0]};
    padding: 16px 32px;
    border-radius: 10px;
    border: none;
    font-weight: ${theme.fontWeight.semibold};
    transition: background-color 200ms;
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
    :not([disabled]):hover {
      background-color: ${theme.colors.accent[1]};
      color: ${theme.colors.text.neutral[0]};
      outline: none;
    }
  `}
`

export const Disclaimer = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.xs};
    color: ${theme.colors.text.neutral[2]};
  `}
`

export const Input = styled(CommonInput).attrs({ align: 'left' })`
  width: 100%;
  background-color: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.text.neutral[3]};
  margin: 8px 0px;
  ::placeholder {
    color: ${(props) => props.theme.colors.text.neutral[2]};
    opacity: 0.7;
  }
`

export const InputNeutral = styled(Input)`
  ${({ theme }) => css`
    &:focus {
      border: 1px solid ${theme.colors.text.neutral[3]};
    }
  `}
`
