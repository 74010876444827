import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { useTheme } from 'styled-components'

import Modal from 'components/common/Modal'
import { Spacer } from 'components/common/Spacer'
import { Text } from 'components/common/Text'
import { HighlightRow } from 'components/highlight-row/HighlightRow'
import { ProgressBar } from 'components/nomination/NominationPanel'
import { reportError } from 'helpers/logging'
import {
  trackHomeTaskClicked,
  trackHomeTaskCompleted,
} from 'helpers/tracking/tracking'
import useWorkspacePermissions from 'hooks/useWorkspacePermissions'
import { setIsUploadVisible } from 'store/content/actions'

import { QrCodeView } from './QrCodeView'
import { SetupTaskCard } from './SetupTaskCard'
import { SetupTaskId, setupTasksInfo } from './setupTasksInfo'
import { useUserTasks } from './useUserTasks'

export const SetupTasksRow = () => {
  const [isQrCodeViewModalOpen, setIsQrCodeViewModalOpen] = useState(false)
  const navigate = useNavigate()
  const userAllowedTo = useWorkspacePermissions(['UPLOAD'])
  const dispatch = useDispatch()
  const theme = useTheme()
  const { userTasks, completeUserTask } = useUserTasks()

  const handleOpenUploadView = () => {
    dispatch(setIsUploadVisible(true))
  }

  const handleTaskClick = (id: SetupTaskId) => {
    trackHomeTaskClicked({
      taskId: id,
    })
    if (id === 'upload') {
      if (!userAllowedTo.UPLOAD) {
        // this should not happen but we add this check just to be sure
        reportError('User was not allowed to upload (home page task setup)')
        return null
      }
      return handleOpenUploadView()
    }
    if (id === 'mobileApp') {
      return setIsQrCodeViewModalOpen(true)
    }
    if (id === 'browserExtension') {
      return window.open(
        'https://chrome.google.com/webstore/detail/save-to-kive/hfkpeknbeaekjalojbgelphkikhkaicg',
        '_blank'
      )
    }
    if (id === 'referFriend') {
      return navigate({ search: 'nominationPanel=1' })
    }
    if (id === 'extractFrames') {
      return navigate('frame-extractor')
    }
    if (id === 'createProject') {
      return navigate('projects')
    }
    return null
  }

  const handleCompleteClick = async (id: SetupTaskId) => {
    completeUserTask(id)
    trackHomeTaskCompleted({
      taskId: id,
    })
  }

  if (!userTasks) return null

  const completedCount = setupTasksInfo.reduce((acc, setupTaskInfo) => {
    if (userTasks[setupTaskInfo.id]?.isCompleted) {
      return acc + 1
    }
    return acc
  }, 0)

  const isAllSetupTasksFinished = completedCount === setupTasksInfo.length

  if (isAllSetupTasksFinished) return null

  return (
    <>
      <HighlightRow
        title="Quickstart checklist"
        listItems={setupTasksInfo}
        subTitleComponent={
          <>
            <Spacer factor={1} />
            <ProgressBar
              step={(completedCount / setupTasksInfo.length) * 100}
              transparentStep={100}
              progressColor={theme.colors.accent[3]}
              css="height: 5px; width: 120px"
            />
            <Spacer factor={0.5} />
            <Text size="xs" color="neutral.3">
              {completedCount} / {setupTasksInfo.length} completed
            </Text>
          </>
        }
        renderListItem={(task) => {
          return (
            <SetupTaskCard
              taskInfo={task}
              id={task.id}
              onClick={handleTaskClick}
              onCompleteClick={handleCompleteClick}
              isCompleted={userTasks[task.id]?.isCompleted}
            />
          )
        }}
      />
      <Spacer factor={5} />
      <Modal
        isOpen={isQrCodeViewModalOpen}
        close={() => setIsQrCodeViewModalOpen(false)}
        width="550px"
      >
        <QrCodeView />
      </Modal>
    </>
  )
}
