import Skeleton from 'react-loading-skeleton'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import Button from 'components/common/Button'
import Flex from 'components/common/Flex'
import { Spacer } from 'components/common/Spacer'
import { Text } from 'components/common/Text'

import { ProfileAvatar } from './ProfileAvatar'

export const ProfileHeader = ({
  photoURL,
  displayName,
  isCurrentUserProfile,
  userIsLoading,
  userHandle,
}: {
  photoURL?: string | null
  displayName?: string | null
  isCurrentUserProfile: boolean
  userIsLoading: boolean
  userHandle?: string | null
}) => {
  const navigate = useNavigate()
  return (
    <Flex>
      <ProfileAvatar
        profilePhotoUrl={photoURL}
        profileDisplayName={displayName}
        isEditable={isCurrentUserProfile}
        isLoadingUser={userIsLoading}
      />

      <Spacer axis="x" factor={2} />
      <StyledFlex flexDirection="column" justifyContent="end">
        <Text size="lg" bold color="neutral.0" css="line-height:32px">
          {userIsLoading ? <Skeleton width={300} /> : displayName}
        </Text>
        <Text size="sm" color="neutral.3">
          {userIsLoading ? <Skeleton width={250} /> : `@${userHandle}`}
        </Text>
      </StyledFlex>
      <StyledFlex justifyContent="end" alignItems="end">
        {!userHandle && !userIsLoading && (
          <Button onClick={() => navigate({ search: 'settingsPanel=account' })}>
            Edit
          </Button>
        )}
        <Spacer factor={2} />
      </StyledFlex>
    </Flex>
  )
}

const StyledFlex = styled(Flex)`
  width: 100%;
`
