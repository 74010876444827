import React from 'react'
import { useSelector } from 'react-redux'
import styled, { useTheme } from 'styled-components'

import Loading from 'components/Loading'
import { usePublicTagsQuery } from 'generated/graphql'
import { RootState } from 'store'
import { DBSearchCategory } from 'types/db'

import { useSearchContext } from './SearchContext'
import { SearchHeading } from './SearchStyles'
import SearchTags from './SearchTags'

const SearchResultsContainer = styled.div`
  padding: 0 16px;
`

export const SearchCategoryPublicTags: React.FC = () => {
  const theme = useTheme()
  const { category: categoryId } = useSearchContext()

  const category = useSelector((state: RootState) =>
    categoryId !== null
      ? (state.firestore.data.searchCategories[categoryId] as DBSearchCategory)
      : null
  )

  const { data, loading } = usePublicTagsQuery({
    variables: {
      tagIds: category!.searchSuggestions,
    },
    skip: (category?.searchSuggestions.length ?? 0) === 0,
  })

  const publicTagsWithItems =
    data?.publicTags.filter((tag) => tag.thumbUrl) || []

  return (
    <SearchResultsContainer>
      {category !== null && (
        <>
          <SearchHeading>
            Tags in category{' '}
            <span style={{ color: theme.colors.accent[1] }}>
              {category.title}
            </span>
          </SearchHeading>
          {loading ? <Loading /> : <SearchTags tags={publicTagsWithItems} />}
        </>
      )}
    </SearchResultsContainer>
  )
}
