import { Send } from '@styled-icons/boxicons-solid/Send'
import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import Button from 'components/common/Button'
import { Margin } from 'components/common/Margin'
import { Text } from 'components/common/Text'
import UserAvatar from 'components/common/UserAvatar'
import { useItemCommentsQuery } from 'generated/graphql'
import { timeAgo } from 'helpers/utilityFunctions'
import { useCurrentUserOrGuest } from 'hooks/useCurrentUserOrGuest'
import { useGuest } from 'hooks/useGuest'
import useIsMobile from 'hooks/useIsMobile'
import { RootState } from 'store'

import InlineConfirmRemoveItemComment from './InlineConfirmRemoveItemComment'
import ItemCommentInput from './ItemCommentInput'

interface ItemCommentsProps {
  itemId: string
  createdById: string
  boardId: string | null
  totalCommentCount: number
}

const ItemComments: React.FC<ItemCommentsProps> = (props) => {
  const [shouldDisplayAllComments, setShouldDisplayAllComments] =
    useState(false)

  const inputRef = useRef<HTMLTextAreaElement>(null)
  const isMobile = useIsMobile()

  const authedUserId = useSelector(
    (state: RootState) => state.firebase.auth.uid
  )

  const currentUserIsItemCreator = authedUserId === props.createdById

  const { guest, setGuestDisplayName } = useGuest()

  const currentUserOrGuest = useCurrentUserOrGuest()

  const isGuest = !('email' in currentUserOrGuest)

  const [guestNameTextInput, setGuestNameTextInput] = useState(
    guest.displayName || ''
  )
  const first = shouldDisplayAllComments ? 500 : 1 // Right now we start with a limit of 500 messages

  const { error, data } = useItemCommentsQuery({
    variables: {
      parentId: props.itemId,
      boardId: props.boardId,
      first,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    pollInterval: 5000,
  })

  const handleToggleLoadAllComments = () => {
    setShouldDisplayAllComments((prev) => !prev)
  }

  const handleSubmitGuestName = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!guestNameTextInput) return
    setGuestDisplayName(guestNameTextInput)
    setTimeout(() => {
      inputRef.current?.focus()
    }, 100)
  }

  const itemComments = data?.itemComments.itemComments.slice(0, first)

  return (
    <StyledCommentWrapper>
      <div css="border-left: 2px solid #303030; padding-left: 12px">
        {props.totalCommentCount > 1 && (
          <>
            <Margin y={12} />
            <Text
              size="sm"
              color="neutral.3"
              css="text-decoration:underline;cursor:pointer"
              onClick={handleToggleLoadAllComments}
            >
              {shouldDisplayAllComments
                ? 'Show less'
                : `Show all${
                    props.totalCommentCount ? ` ${props.totalCommentCount}` : ''
                  } comments`}
            </Text>
          </>
        )}
        <Margin y={12} />
        <StyledCommentList>
          {error && (
            <Text size="sm" color="neutral.2">
              Oh no, we could not load the comments.
            </Text>
          )}
          {!error &&
            itemComments?.map((comment) => {
              const image =
                comment.author?.__typename === 'User'
                  ? comment.author.photoURL
                  : null
              return (
                <StyledComment key={comment.id}>
                  <Margin y={8} />
                  <div css="display: flex">
                    <UserAvatar
                      photoUrl={image}
                      displayName={comment.author?.displayName || 'Unknown'}
                      size={22}
                    />
                    <Margin x={12} />
                    <StyledCommentContent>
                      <div css="display:flex; align-items: center; height: 22px">
                        <Text size="sm" color="neutral.2" ellipsis>
                          {comment.author?.displayName || 'Unknown'}
                        </Text>
                        <Margin x={8} />
                        <Text
                          size="xs"
                          color="neutral.4"
                          css="white-space:nowrap"
                        >
                          {timeAgo(comment.createdAt)}
                        </Text>
                        {(comment.author?.id === currentUserOrGuest.uid ||
                          currentUserIsItemCreator) && (
                          <StyledInlineConfirmWrapper>
                            <InlineConfirmRemoveItemComment
                              itemCommentId={comment.id}
                              itemId={props.itemId}
                              boardId={props.boardId}
                            />
                          </StyledInlineConfirmWrapper>
                        )}
                      </div>
                      <Text
                        size="sm"
                        color="neutral.0"
                        css="white-space: pre-wrap;"
                      >
                        {comment.text}
                      </Text>
                    </StyledCommentContent>
                  </div>
                </StyledComment>
              )
            })}
        </StyledCommentList>
      </div>
      <Margin y={12} />
      {isGuest && !currentUserOrGuest.displayName ? (
        <div css="display:flex">
          <form css="width:100%" onSubmit={handleSubmitGuestName}>
            <Input
              value={guestNameTextInput}
              onChange={(event) => setGuestNameTextInput(event.target.value)}
              onKeyDown={(event) => {
                if (event.key === 'Escape') return
                // we stop propagations to ignore global shortcuts
                event.stopPropagation()
                event.nativeEvent.stopImmediatePropagation()
              }}
              placeholder="Submit your name to comment"
            />
            {isMobile && (
              <>
                <Margin x={12} />
                <Button isCompact Icon={Send} variant="primary" />
              </>
            )}
          </form>
        </div>
      ) : (
        <ItemCommentInput
          ref={inputRef}
          userOrGuest={currentUserOrGuest}
          itemId={props.itemId}
          boardId={props.boardId}
        />
      )}
    </StyledCommentWrapper>
  )
}
export default ItemComments

const Input = styled.input`
  ${({ theme }) => css`
    background-color: ${theme.colors.background[4]};
    color: ${theme.colors.text.neutral[0]};
    border-color: transparent;
    font-size: ${theme.fontSizes.sm};
    width: 100%;
    padding: 12px;
    height: 36px;
    border-radius: ${theme.borderRadius.default};
    outline: none;
    border: 1px solid transparent;
    &:hover {
      border: 1px solid ${theme.colors.accent[2]};
    }
    &:focus {
      border: 1px solid ${theme.colors.accent[2]};
    }
    ::placeholder {
      color: ${theme.colors.text.neutral[2]};
      opacity: 0.7;
    }
  `}
`

const StyledCommentList = styled.div`
  display: flex;
  flex-direction: column-reverse;
  max-height: 300px;
  overflow-y: auto;
`

const StyledCommentWrapper = styled.div`
  ${({ theme }) => css`
    @media (min-width: ${theme.breakpoints.sm}px) {
      width: 300px;
    }
  `}
`

const StyledComment = styled.div``

const StyledCommentContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;
`

const StyledInlineConfirmWrapper = styled.div`
  margin-left: auto;
  display: none;
  ${StyledComment}:hover & {
    display: block;
  }
`
