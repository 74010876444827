export function maxAwaitFn<T>({
  asyncFn,
  limit,
  limitReturn,
}: {
  asyncFn: Promise<T>
  limit: number
  limitReturn: T
}): Promise<T> {
  const sleep = new Promise<T>((resolve) =>
    setTimeout(() => resolve(limitReturn), limit)
  )
  return Promise.race([asyncFn, sleep])
}
