import PropTypes from 'prop-types'
import { useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { connect } from 'react-redux'
import { firestoreConnect, withFirestore } from 'react-redux-firebase'
import { Outlet, useOutletContext } from 'react-router'
import { withHandlers } from 'recompose'
import { compose } from 'redux'

import Loading from 'components/Loading'
import useUploadSessions from 'components/upload/useUploadSessions'
import useSafeState from 'hooks/useSafeState'

import UploadSessionCard from './UploadSessionCard'

function UploadSessions(props) {
  const { scrollNode } = useOutletContext()

  const [isLoading, setLoading] = useSafeState(false)
  const [lastItem, setLastItem] = useSafeState(0)
  const [hasMore, setHasMore] = useState(true)
  const { sessions } = useUploadSessions()

  function _load() {
    if (!isLoading) {
      setLoading(true)
      if (props.loadMore) {
        props
          .loadMore(lastItem)
          .then((snapshot) => {
            setLastItem(snapshot.docs.pop())
            setLoading(false)
          })
          .catch((e) => console.log(e))
      }
    }
  }

  // Merge batches
  const merged = []
  const uploadSessionIds = []
  Object.keys(props.uploadSessions).forEach((batchKey) => {
    const batch = props.uploadSessions[batchKey]
    if (batch) {
      Object.keys(batch).forEach((key) => {
        // If it's not in recent, add  it
        const isInRecent = props.uploadSessionsRecentIds.includes(key)

        if (!isInRecent) {
          merged.push({ ...batch[key], id: key })
          uploadSessionIds.push(key)
        }
      })
    } else if (hasMore) setHasMore(false)
  })

  // const newUploadSessions = props.uploadSessionsRecent.filter(uploadSession => !uploadSessionIds.includes(uploadSession.id))
  // If the item exists in recent (which is listening to changes in last 10 uploadSessions) use that instead

  // Sort ... this shouldn't be needed if the query is done right, but ... quick fix ...
  const uploadSessionsWithNew = [
    ...props.uploadSessionsRecent.concat(merged),
  ].sort((a, b) => (b.createdAt || Date.now()) - (a.createdAt || Date.now()))

  const filterItemsDeleting = uploadSessionsWithNew.filter(
    (item) => !item.isDeleting && !item.shouldDelete
  )

  // Filter out unfinished uploadSessions and map to cards
  const items = filterItemsDeleting.map((item, i) => {
    const localSession = sessions.find((s) => s.id === item.id)

    const bytesTransferred =
      localSession?.bytesTransferred ?? item.remoteUpload?.uploadedBytes ?? null

    const totalUploadSize =
      localSession?.totalUploadSize ?? item.remoteUpload?.totalBytes ?? null

    const completedUploads =
      localSession?.completedUploads ??
      item.remoteUpload?.uploadSuccessCount ??
      null

    const totalItemsCount =
      localSession?.droppedFileCount ??
      item.remoteUpload?.totalItemsCount ??
      null
    return (
      <UploadSessionCard
        key={item.id}
        shouldFetchCounters={i < 9} // we only fetch counters for the recent boards
        uploadProgressPercentage={
          bytesTransferred !== null
            ? Math.round((bytesTransferred / totalUploadSize) * 100)
            : undefined
        }
        uploadProgressFiles={
          completedUploads !== null
            ? `${completedUploads} / ${totalItemsCount} files`
            : undefined
        }
        {...item}
      />
    )
  })

  return (
    <>
      <InfiniteScroll
        pageStart={0}
        // eslint-disable-next-line react/jsx-no-bind
        loadMore={_load}
        hasMore={hasMore}
        useWindow={false}
        getScrollParent={() => scrollNode}
        loader={<Loading key="loading" />}
      >
        {items}
      </InfiniteScroll>
      <Outlet />
    </>
  )
}

UploadSessions.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  uploadSessions: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
  // we are grabbing all upload sessions batches
  const uploadSessions = Object.keys(state.firestore.data)
    .filter((key) =>
      key.startsWith(
        `${`uploadSessions.${state.content.activeWorkspace.id}.batches.`}`
      )
    )
    .reduce((acc, key) => {
      // eslint-disable-next-line no-param-reassign
      acc = { ...acc, [key]: state.firestore.data[key] }
      return acc
    }, {})

  return {
    activeWorkspace: state.content.activeWorkspace,
    uploadSessions,
    uploadSessionsRecent:
      state.firestore.ordered[
        `uploadSessions.${state.content.activeWorkspace.id}.recent`
      ] || [],
    uploadSessionsRecentIds: state.firestore.data[
      `uploadSessions.${state.content.activeWorkspace.id}.recent`
    ]
      ? Object.keys(
          state.firestore.data[
            `uploadSessions.${state.content.activeWorkspace.id}.recent`
          ]
        )
      : [],
  }
}

export default compose(
  connect(mapStateToProps),
  withFirestore,
  withHandlers({
    loadMore: (props) => async (startAfter) => {
      if (startAfter === undefined) return new Error('No more items')
      const batchStoreId =
        startAfter === 0 ? startAfter : startAfter.data().createdAt.toMillis()
      return props.firestore.get({
        collection: 'uploadSessions',
        where: ['workspaceId', '==', props.activeWorkspace.id],
        storeAs: `uploadSessions.${props.activeWorkspace.id}.batches.${batchStoreId}`,
        orderBy: ['createdAt', 'desc'],
        startAfter,
        limit: 10,
      })
    },
  }),
  firestoreConnect((props) => [
    // Attach a listener to the most recent uploadSessions, so that the view is updated when processing is finished
    {
      collection: 'uploadSessions',
      where: ['workspaceId', '==', props.activeWorkspace.id],
      orderBy: ['createdAt', 'desc'],
      limit: 10,
      storeAs: `uploadSessions.${props.activeWorkspace.id}.recent`,
    },
  ])
)(UploadSessions)
