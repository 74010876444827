import React, { useState } from 'react'
import styled from 'styled-components'

import useIsMobile from 'hooks/useIsMobile'

import ItemColorsScrollable from './ItemColorsScrollable'
import ItemDeleteModal from './ItemDeleteModal'
import ItemInfo from './ItemInfo'
import ItemPanelMobile from './ItemPanelMobile'
import ItemTagBadges from './ItemTagBadges'
import PublicItemActionBarTop from './PublicItemActionBarTop'
import PublicItemPanel from './PublicItemPanel'
import { ItemData } from './hooks/useGetItemData'

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 32px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 500px) {
    ::-webkit-scrollbar {
      display: none;
    }
  }
`

interface PublicItemProps {
  item: ItemData
  isCloseable?: boolean
  onCloseItemView: () => void
  inDiscover?: boolean
  trackingSessionId?: string
}

const PublicItem = React.forwardRef<HTMLDivElement, PublicItemProps>(
  (
    { item, isCloseable, onCloseItemView, inDiscover, trackingSessionId },
    ref
  ) => {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    const isMobile = useIsMobile()
    return (
      <>
        <Container ref={ref}>
          <PublicItemActionBarTop
            item={item}
            isCloseable={isCloseable}
            onCloseItemView={onCloseItemView}
            downloadable={!inDiscover}
            saveable={Boolean(inDiscover)}
            trackingSessionId={trackingSessionId}
          />
          {isMobile && (
            <>
              <ItemPanelMobile isPublic item={item} />
              <ItemColorsScrollable
                isPublic
                inDiscover={inDiscover}
                colors={item.colors}
              />
              <ItemTagBadges
                isPublic
                tags={item.tags}
                inDiscover={inDiscover}
              />
              <ItemInfo item={item} />
            </>
          )}
          {!isMobile && (
            <ItemWrapper>
              <PublicItemPanel item={item} inDiscover={inDiscover} />
              <ItemInfo item={item} />
            </ItemWrapper>
          )}
        </Container>
        {item && (
          <ItemDeleteModal
            isOpen={isDeleteModalOpen}
            item={item}
            close={() => setIsDeleteModalOpen(false)}
          />
        )}
      </>
    )
  }
)

export default PublicItem
