import { DBProject } from 'types/db'

import useProjectUserRole, { ProjectRoles } from './useProjectUserRole'

type ProjectPermissions =
  | 'DELETE_PROJECT'
  | 'EDIT_PROJECT'
  | 'ADD_BOARD'
  | 'EDIT_PROJECT_USER_ROLES'
  | 'MOVE_PROJECT'
  | 'VIEW_PROJECT' // right now only used in public boards to know if user can go back to project

export const projectPermissions: Record<ProjectRoles, ProjectPermissions[]> = {
  OWNER: [
    'DELETE_PROJECT',
    'EDIT_PROJECT',
    'ADD_BOARD',
    'EDIT_PROJECT_USER_ROLES',
    'MOVE_PROJECT',
    'VIEW_PROJECT',
  ],
  WRITER: ['EDIT_PROJECT', 'ADD_BOARD', 'VIEW_PROJECT'],
  COMMENTER: ['VIEW_PROJECT'],
  READER: ['VIEW_PROJECT'],
}

const useProjectPermissions = (
  project: DBProject | undefined,
  askedPermissions: ProjectPermissions[]
): Record<ProjectPermissions, boolean | undefined> => {
  const userRole = useProjectUserRole(project)
  const currentUserPermissions = userRole ? projectPermissions[userRole] : []
  return askedPermissions.reduce((accPermissions, askedPermission) => {
    return {
      ...accPermissions,
      [askedPermission]: currentUserPermissions.includes(askedPermission),
    }
  }, {} as Record<ProjectPermissions, boolean | undefined>)
}

export default useProjectPermissions
