import styled from 'styled-components'

type StyledButtonMediumProps = {
  hasText?: boolean
  isSelected?: boolean
}

export const StyledButtonMedium = styled.button<StyledButtonMediumProps>`
  height: 32px;
  width: ${(props) => (props.hasText ? 'auto' : '32px')};
  gap: 8px;
  cursor: pointer;
  align-items: center;
  display: flex !important;
  justify-content: center;
  color: ${({ theme, disabled, isSelected }) =>
    // eslint-disable-next-line
    disabled
      ? theme.colors.gray.dark[8]
      : isSelected
      ? 'black'
      : theme.colors.gray.light[0]};
  padding: ${(props) => (props.hasText ? '6px 10px' : '6px')};
  border: 1px solid ${({ theme }) => theme.colors.gray.dark[6]};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  background: ${({ theme, disabled, isSelected }) =>
    // eslint-disable-next-line
    disabled
      ? theme.colors.background[3]
      : isSelected
      ? 'white'
      : theme.colors.background[4]};
  user-select: none;
  &:hover {
    color: ${({ theme, disabled }) =>
      disabled ? theme.colors.gray.dark[8] : 'white'};
    background: ${({ theme, disabled }) =>
      disabled ? theme.colors.background[4] : theme.colors.accent[2]};
  }
  &:active {
    color: ${({ theme, disabled }) =>
      disabled ? theme.colors.gray.dark[8] : 'black'};
    background: ${({ theme, disabled }) =>
      disabled ? theme.colors.background[4] : theme.colors.white};
  }
`

export const StyledButtonSmall = styled(
  StyledButtonMedium
)<StyledButtonMediumProps>`
  height: 24px;
  width: ${(props) => (props.hasText ? 'auto' : '24px')};
  gap: 8px;
  padding: ${(props) => (props.hasText ? '4px 6px' : '4px')};
`
