import styled, { css } from 'styled-components'

export const Actions = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: auto auto;
    border-bottom: ${theme.border.thin} ${theme.colors.discreet};
    margin: 0 -4px 10px -4px;
  `}
`

export const ActionsItemWrapper = styled.div`
  ${({ side }) => css`
    text-align: ${side === 'right' ? 'right' : 'left'};
    padding: 4px 8px 0 8px;
  `}
`

export const ActionsOption = styled.div`
  ${({ theme }) => css`
    display: inline-block;
    font-size: ${theme.fontSizes.sm};
    font-weight: ${theme.fontWeight.normal};
    color: ${theme.colors.text.neutral[0]};
    padding: 4px 2px 8px 2px;
    border-bottom: ${theme.border.thin} ${theme.colors.text.neutral[0]};
    cursor: pointer;
  `}
`

export const ActionButton = styled.div`
  ${({ theme }) => css`
    display: inline-block;
    font-size: ${theme.fontSizes.sm};
    font-weight: ${theme.fontWeight.normal};
    color: ${theme.colors.text.neutral[3]};
    padding: 4px 8px;
    border-radius: ${theme.borderRadius.sm};
    cursor: pointer;
    &:hover {
      background-color: ${theme.colors.background[4]};
      color: ${theme.colors.text.neutral[2]};
    },
  `}
`

export const StyledPicker = styled.div`
  ${({ theme }) => css`
    width: 274px;
    font-size: ${theme.fontSizes.sm};
    position: fixed;
    z-index: 102;
    border-radius: ${theme.borderRadius.default};
    padding: 4px;
    text-align: left;
    background: ${theme.colors.background[0]};
    color: #ccc;
    border: ${theme.border.thin} ${theme.colors.discreet};
    box-shadow: ${theme.shadow.minimal};
  `}
`

export const ScrollContainer = styled.div`
  ${({ theme }) => css`
    overflow-y: scroll;
    margin-top: 8px;
    height: 300px;
  `}
`

export const Input = styled.input`
  ${({ theme }) => css`
    border: none;
    color: ${theme.colors.text.neutral[1]};
    background: transparent;
    outline: none;
    height: 32px;
    font-weight: ${theme.fontWeight.semibold};
    flex: 1 1 0;
    font-size: ${theme.fontSizes.sm};
  `}
`

export const InputContainer = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.text.neutral[1]};
    width: 100%;
    border-radius: ${theme.borderRadius.default};
    border: ${theme.border.thin} ${theme.colors.discreet};
    box-shadow: 0 0 0 1px ${theme.colors.accent[2]};
    background: ${theme.colors.background[1]};
    text-align: left;
    display: flex;
    align-items: center;
  `}
`

export const StyledCell = styled.div`
  ${({ theme }) => css`
    padding: 4px;
    border-radius: ${theme.borderRadius.sm};
    display: inline-block;
    position: relative;
    overflow: hidden;
    &:hover {
      background-color: ${theme.colors.background[4]};
    },
  `}
`
