import styled, { css } from 'styled-components'

export const StyledImage = styled.img`
  ${({ theme }) => css`
    object-fit: cover;
    height: auto;
    width: 300px;
    flex: 1;
    display: block;
    pointer-events: none;
    border-radius: ${theme.borderRadius.default};
  `}
`

export const StyledModalHeader = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    font-family: ${theme.fontFamily.primary};
    font-size: ${theme.fontSizes.base};
    font-weight: ${theme.fontWeight.bold};
    line-height: ${theme.lineHeight.tight};
    padding-bottom: 0.75rem;
  `}
`
export const StyledModalDescription = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    font-family: ${theme.fontFamily.primary};
    font-size: ${theme.fontSizes.sm};
    font-weight: ${theme.fontWeight.normal};
    line-height: ${theme.lineHeight.tight};
    padding-bottom: 1rem;
  `}
`

export const StyledPackGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
`

export const StyledPackContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    gap: 0.5rem;
    border-width: 1px;
    border-radius: 0.5rem;
    border-color: ${theme.colors.discreet};
    border-style: solid;
    padding: 0.5rem;
    border-radius: ${theme.borderRadius.lg};
  `}
`
export const StyledButtonText = styled.span`
  ${({ theme }) => css`
    margin-right: 0.25rem;
  `}
`
