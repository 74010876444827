import React from 'react'
import { Upload2 } from 'styled-icons/remix-line'

import { GuideCard } from 'components/guide-card/GuideCard'
import { Tooltip } from 'components/tooltip/Tooltip'
import { useStorage } from 'config/liveblocks.config'
import useIsMobileDevice from 'hooks/useIsMobileDevice'

import { StyledButtonMedium } from './ButtonMedium'

export interface QueuedImageProps {
  id: string
  src: string
  x: number
  y: number
  width: number
  height: number
  originalHeight: number
}

export const FileUploader = ({
  setImageQueue,
  disabled,
}: {
  setImageQueue: (props: QueuedImageProps[]) => void
  disabled: boolean
}) => {
  const isMobileDevice = useIsMobileDevice()
  const hiddenFileInput = React.useRef(null)
  const isCanvasEmpty = useStorage((root) =>
    root.shapes ? Object.keys(root.shapes).length === 0 : true
  )

  const handleClick = () => {
    // @ts-ignore
    hiddenFileInput.current.click()
  }

  async function loadImage(file: any) {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onload = (e) => {
        const image = new Image()
        // @ts-ignore
        image.src = e.target.result
        image.onload = () => {
          resolve(image)
        }
      }
      reader.readAsDataURL(file)
    })
  }

  async function handleChange(event: any) {
    const images = await Promise.all(
      Array.from(event.target.files).map(loadImage)
    )

    const queue = [] as QueuedImageProps[]
    let lastPos = 0

    const MAX_HEIGHT = 512

    images.forEach((image: any) => {
      const height = Math.min(MAX_HEIGHT, image.height)
      const width = (image.width / image.height) * height
      queue.push({
        src: image.src,
        width,
        height,
        originalHeight: image.height,
        x: lastPos,
        y: 0,
        id: `${Math.random()}`.substring(0, 5),
      })
      lastPos = queue[queue.length - 1].x + queue[queue.length - 1].width
    })
    setImageQueue(queue)
  }

  return (
    <>
      <GuideCard
        open={isCanvasEmpty}
        side="right"
        content="Upload an image to get started!"
      >
        <Tooltip tooltip="Upload an image" side="right">
          <StyledButtonMedium
            style={isMobileDevice ? { marginTop: 50 } : {}}
            disabled={disabled}
            onClick={handleClick}
            hasText={isMobileDevice}
          >
            <Upload2 width={isMobileDevice ? 25 : 20} />
          </StyledButtonMedium>
        </Tooltip>
      </GuideCard>
      <input
        multiple
        type="file"
        accept="image/*"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
    </>
  )
}
