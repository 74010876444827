import { noArgMemo } from './utilityFunctions'

const getBlockedDomains = noArgMemo(async () => {
  const text = await fetch(`${process.env.PUBLIC_URL}/public-domains.csv`).then(
    (res) => res.text()
  )
  return text.replace(/(\r\n|\n|\r)/gm, '').split(',')
})

export const validateAllowedDomain = async (value: string) => {
  if (value.length === 0) {
    return
  }
  if (!value.match(/^([\w|-]+)((\.(\w){2,})+)$/)) {
    return `Domain name must match pattern 'mycompany.com'`
  }
  const blockedDomains = await getBlockedDomains()
  if (blockedDomains.indexOf(value) !== -1) {
    return `${value} is public and cannot be used as allowed domain`
  }
}
