import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import { KiveLogo } from 'assets/svg/KiveLogo'
import Flex from 'components/common/Flex'
import useIsMobile from 'hooks/useIsMobile'
import { RootState } from 'store'

import { StyledButtonMedium } from './ButtonMedium'

type PublicBannerProps = {
  handleSignupClick: () => void
}

export const PublicBanner = ({ handleSignupClick }: PublicBannerProps) => {
  const isMobile = useIsMobile()
  const auth = useSelector((state: RootState) => state.firebase.auth)
  if (!auth.isEmpty) {
    return null
  }

  return (
    <StyledPublicBanner>
      <StyledPublicBannerContents>
        <Flex
          flexGap={isMobile ? 2 : 4}
          flexDirection={isMobile ? 'column' : 'row'}
          alignItems="center"
          css={
            isMobile
              ? {
                  textAlign: 'center',
                }
              : {}
          }
        >
          <StyledKiveLogoAndText>
            <StyledKiveLogoWrapper>
              <KiveLogo color="black" height={18} width={18} />
            </StyledKiveLogoWrapper>
            <StyledWelcomeToKive>Welcome to Kive Canvas!</StyledWelcomeToKive>
          </StyledKiveLogoAndText>
          <div>Create an account to collaborate on this canvas</div>
          <StyledButtonMedium hasText onClick={handleSignupClick}>
            Sign up
          </StyledButtonMedium>
        </Flex>
      </StyledPublicBannerContents>
    </StyledPublicBanner>
  )
}

const StyledPublicBanner = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  color: white;
  margin: 0 24px;
  top: 48px;
  width: calc(100vw - 48px);
  z-index: 10;
`

const StyledPublicBannerContents = styled.div`
  ${({ theme }) => css`
    display: flex;
    background-color: ${theme.colors.gray.dark[4]};
    border: 1px solid ${theme.colors.gray.dark[6]};
    border-radius: 4px;
    padding: 12px;
  `}
`

const StyledKiveLogoAndText = styled.div`
  align-items: center;
  display: flex;
`

const StyledKiveLogoWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.colors.white};
    border-radius: 4px;
    display: flex;
    justify-content: center;
    padding: 6px;
  `}
`

const StyledWelcomeToKive = styled.div`
  ${({ theme }) => css`
    font-weight: ${theme.fontWeight.bold};
    margin-left: 12px;
  `}
`
