import { ChevronDown } from '@styled-icons/bootstrap/ChevronDown'
import { ArrowBack } from '@styled-icons/boxicons-regular/ArrowBack'
import { useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router'
import { compose } from 'redux'
import { useTheme } from 'styled-components'

import ClickOutside from 'components/common/ClickOutside'
import Dropdown from 'components/common/Dropdown'
import { WorkspaceCard } from 'components/menu/WorkspaceSelector'
import useCurrentUser from 'hooks/useCurrentUser'
import { workspacesSelector } from 'store/selectors'

import useSettingsNavigation from '../settings/useSettingsNavigation'
import {
  BackButtonWrapper,
  DropdownTrigger,
  ExpandIndicator,
  HeaderStyled,
  Items,
  OnlineIndicator,
  StyledDropdownItem,
  StyledMenuWrapper,
  UserContainer,
  UserNameContainer,
  WorkspaceMenuItem,
  WorkspaceMenuItemText,
  WorkspaceTitle,
} from './HeaderStyles'

function Header(props) {
  const navigate = useNavigate()
  const currentUser = useCurrentUser()
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  if (!props.workspaces || !props.activeWorkspace) return null

  const isSmallHeader =
    props.showFullHeader === true ? false : props.siderSplitValue < 160
  return (
    <ClickOutside handleClickOutside={() => setIsDropdownVisible(false)}>
      <HeaderStyled>
        {props.withGoBackButton && (
          <BackButtonWrapper
            onClick={() => navigate(`/${props.activeWorkspace.url}/projects`)}
          >
            <ArrowBack style={{ width: 20 }} />
          </BackButtonWrapper>
        )}
        {isSmallHeader ? (
          <WorkspaceCard
            onClick={() => setIsDropdownVisible((state) => !state)}
          >
            {props.activeWorkspace.title.substring(0, 2).toUpperCase()}
          </WorkspaceCard>
        ) : (
          <DropdownTrigger
            css={!props.withGoBackButton && 'padding-left:16px; width: 100%;'}
            isSmall={isSmallHeader}
            onClick={() => {
              setIsDropdownVisible((state) => !state)
            }}
          >
            <Items isSmall={isSmallHeader}>
              <WorkspaceTitle>{props.activeWorkspace.title}</WorkspaceTitle>
              <UserContainer>
                <OnlineIndicator />
                <UserNameContainer>
                  {props.displayName || currentUser.displayName}
                </UserNameContainer>
              </UserContainer>
            </Items>
            <ExpandIndicator isSmall={isSmallHeader}>
              <ChevronDown size={14} />
            </ExpandIndicator>
          </DropdownTrigger>
        )}

        <Dropdown
          isOpen={isDropdownVisible}
          options={{
            width: 212,
            noSpace: true,
            ...(isSmallHeader && { position: 'bottom-left' }),
          }}
        >
          <DropdownMenu
            activeWorkspace={props.activeWorkspace}
            workspaces={props.workspaces}
            email={props.email}
            navigate={(url) => navigate(url)}
            close={() => setIsDropdownVisible(false)}
          />
        </Dropdown>
      </HeaderStyled>
    </ClickOutside>
  )
}

const Divider = () => {
  const theme = useTheme()
  return (
    <div
      style={{
        borderBottom: `1px solid ${theme.colors.gray.medium[0]}`,
      }}
    />
  )
}

const DropdownMenu = (props) => {
  const { open: openSettings } = useSettingsNavigation()
  return (
    <StyledMenuWrapper onClick={props.close}>
      {Object.keys(props.workspaces).map((workspaceKey) => {
        const workspace = props.workspaces[workspaceKey]
        const memberCount = workspace.members.length + workspace.admins.length
        return (
          <StyledDropdownItem
            key={workspaceKey}
            onClick={() => props.navigate(`/${workspace.url}`)}
          >
            <WorkspaceMenuItem>
              <WorkspaceMenuItemText bold>
                {workspace.title}
              </WorkspaceMenuItemText>
              <WorkspaceMenuItemText>
                {memberCount} member
                {memberCount > 1 && 's'}
              </WorkspaceMenuItemText>
            </WorkspaceMenuItem>
          </StyledDropdownItem>
        )
      })}
      <StyledDropdownItem
        onClick={() => props.navigate('/setup/workspace?new=true')}
        title="Create workspace"
      />
      <StyledDropdownItem
        onClick={() => props.navigate('/join/workspaces')}
        title="Join workspace"
      />
      <Divider />
      <StyledDropdownItem
        onClick={() => props.navigate(`/${props.activeWorkspace.url}/library`)}
        title="Library"
      />
      <StyledDropdownItem
        onClick={() => props.navigate(`/${props.activeWorkspace.url}/projects`)}
        title="Projects"
      />
      <StyledDropdownItem onClick={openSettings} title="Settings" />
      <StyledDropdownItem
        onClick={() => props.navigate('/privacy')}
        title="Privacy policy"
      />
      <Divider />
      <StyledDropdownItem
        onClick={() => props.navigate('/logout')}
        title={`Logout ${props.email}`}
      />
    </StyledMenuWrapper>
  )
}

const mapStateToProps = (state) => ({
  uid: state.firebase.auth.uid,
  email: state.firebase.auth.email,
  displayName: state.firebase.auth.displayName,
  workspaces: workspacesSelector(state) || {},
  activeWorkspace: state.content.activeWorkspace,
  siderSplitValue: state.ui.siderSplitValue,
})

export default compose(connect(mapStateToProps, {}))(Header)
