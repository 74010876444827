import { ArrowDropDown } from '@styled-icons/material/ArrowDropDown'
import { StyledIcon } from '@styled-icons/styled-icon'
import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import Dropdown from 'components/common/Dropdown'

import { StyledButtonBase } from './Button'

const DropdownButtonButton = styled(StyledButtonBase)`
  border-radius: 8px 0 0 8px;
  padding: 16px 32px;
`

const PickButton = styled(StyledButtonBase)`
  ${({ theme }) => css`
    height: 44px;
    width: 44px;
    padding: 0;
    background: #481cd9;
    justify-content: center;
    border-radius: 0 8px 8px 0;
    color: ${theme.colors.text.neutral[0]};

    &:hover {
      background: ${theme.colors.accent[1]};
    }
  `}
`

const DropdownButtonWrapper = styled.div`
  display: flex;
  position: relative;
`

const IconWrapper = styled.div`
  margin-right: 8px;
`

const MenuCloseArea = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`

interface DropdownButtonProps {
  title: string
  Icon?: StyledIcon
  dropdownMenuWidth?: number
}

const DropdownButton: React.FC<
  DropdownButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ title, Icon, dropdownMenuWidth, children, ...rest }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <DropdownButtonWrapper>
        <DropdownButtonButton variant="primary" {...rest}>
          <IconWrapper>
            {Icon && <Icon style={{ height: '16px' }} />}
          </IconWrapper>
          {title}
        </DropdownButtonButton>
        <PickButton onClick={() => setIsOpen((prev) => !prev)}>
          <ArrowDropDown style={{ height: 16 }} />
        </PickButton>
        <Dropdown options={{ width: dropdownMenuWidth }} isOpen={isOpen}>
          {children}
        </Dropdown>
        {isOpen && <MenuCloseArea onClick={() => setIsOpen((prev) => !prev)} />}
      </DropdownButtonWrapper>
    </>
  )
}

export default DropdownButton
