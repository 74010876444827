import loadable from '@loadable/component'
import React from 'react'

import Loading from 'components/Loading'
import { safeImport } from 'helpers/safeImport'

export const PrivacyPage = loadable(
  () =>
    safeImport(
      () =>
        import(
          /* webpackChunkName: "PrivacyPage" */ '../components/legal/Privacy'
        )
    ),
  {
    fallback: <Loading fullscreen />,
  }
)

export const TermsPage = loadable(
  () =>
    safeImport(
      () =>
        import(/* webpackChunkName: "TermsPage" */ '../components/legal/Terms')
    ),
  {
    fallback: <Loading fullscreen />,
  }
)

export const PricingPage = loadable(
  () =>
    safeImport(
      () =>
        import(
          /* webpackChunkName: "PricingPage" */ '../components/pricing/Pricing'
        )
    ),
  {
    fallback: <Loading fullscreen />,
  }
)

export const ProductPage = loadable(
  () =>
    safeImport(
      () =>
        import(
          /* webpackChunkName: "ProductPage" */ '../components/landing/Product'
        )
    ),
  {
    fallback: <Loading fullscreen />,
  }
)

export const DemoPage = loadable(
  () =>
    safeImport(
      () =>
        import(/* webpackChunkName: "DemoPage" */ '../components/landing/Demo')
    ),
  {
    fallback: <Loading fullscreen />,
  }
)

export const VideoPage = loadable(
  () =>
    safeImport(
      () =>
        import(
          /* webpackChunkName: "VideoPage" */ '../components/landing/Video'
        )
    ),
  {
    fallback: <Loading fullscreen />,
  }
)

export const PublicBoardPage = loadable(
  () =>
    safeImport(
      () =>
        import(
          /* webpackChunkName: "PublicBoardPage" */ '../components/board/PublicBoard'
        )
    ),
  {
    fallback: <Loading fullscreen />,
  }
)

export const PublicItemViewPage = loadable(
  () =>
    safeImport(
      () =>
        import(
          /* webpackChunkName: "PublicItemViewPage" */ '../components/item/PublicItemView'
        )
    ),
  {
    fallback: <Loading fullscreen />,
  }
)

export const PublicProjectPage = loadable(
  () =>
    safeImport(() =>
      import(
        /* webpackChunkName: "PublicProjectPage" */ '../components/publicProject/PublicProject'
      ).then((module) => ({
        default: module.PublicProject,
      }))
    ),
  {
    fallback: <Loading fullscreen />,
  }
)

export const LandingPage = loadable(
  () =>
    safeImport(
      () =>
        import(
          /* webpackChunkName: "LandingPage" */ '../components/landing/Landing'
        )
    ),
  {
    fallback: <Loading fullscreen />,
  }
)

export const LoginPage = loadable(
  () =>
    safeImport(
      () =>
        import(
          /* webpackChunkName: "LoginPage" */ '../components/authentication/Login'
        )
    ),
  {
    fallback: <Loading fullscreen />,
  }
)

export const SignupPage = loadable(
  () =>
    safeImport(
      () =>
        import(
          /* webpackChunkName: "SignupPage" */ '../components/authentication/Signup'
        )
    ),
  {
    fallback: <Loading fullscreen />,
  }
)

export const LoginForgotPasswordPage = loadable(
  () =>
    safeImport(
      () =>
        import(
          /* webpackChunkName: "LoginForgotPasswordPage" */ '../components/authentication/LoginForgotPassword'
        )
    ),
  { fallback: <Loading fullscreen /> }
)

export const RequestInvitePage = loadable(
  () =>
    safeImport(
      () =>
        import(
          /* webpackChunkName: "RequestInvitePage" */ '../components/request/RequestInvite'
        )
    ),
  {
    fallback: <Loading fullscreen />,
  }
)

export const WaitingListPage = loadable(
  () =>
    safeImport(
      () =>
        import(
          /* webpackChunkName: "WaitingListPage" */ '../components/WaitingList'
        )
    ),
  {
    fallback: <Loading fullscreen />,
  }
)

export const InitPage = loadable(
  () =>
    safeImport(
      () =>
        import(
          /* webpackChunkName: "InitPage" */ '../components/protected/Init'
        )
    ),
  {
    fallback: <Loading fullscreen />,
  }
)

export const AppPage = loadable(
  () =>
    safeImport(
      () =>
        import(/* webpackChunkName: "AppPage" */ '../components/protected/App')
    ),
  {
    fallback: <Loading fullscreen />,
  }
)

export const ProjectViewPage = loadable(
  () =>
    safeImport(
      () =>
        import(
          /* webpackChunkName: "ProjectViewPage" */ '../components/project/ProjectView'
        )
    ),
  {
    fallback: <Loading fullscreen />,
  }
)

export const SetupUploadsPage = loadable(
  () =>
    safeImport(
      () =>
        import(
          /* webpackChunkName: "SetupUploadsPage" */ '../components/setup/SetupUploads'
        )
    ),
  {
    fallback: <Loading fullscreen />,
  }
)
