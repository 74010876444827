import { Chrome } from '@styled-icons/feather/Chrome'
import { CalendarClear } from '@styled-icons/ionicons-outline/CalendarClear'
import { Play } from '@styled-icons/ionicons-outline/Play'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import styled, { css, useTheme } from 'styled-components'

import Flex from 'components/common/Flex'
import { Spacer } from 'components/common/Spacer'
import { Text } from 'components/common/Text'
import UserAvatar from 'components/common/UserAvatar'
import { trackItemSourceClicked } from 'helpers/tracking/tracking'
import { timeAgo } from 'helpers/utilityFunctions'
import { workspacesSelector } from 'store/selectors'

import { ItemData } from './hooks/useGetItemData'

interface ItemUserInfoProps {
  createdByUser: ItemData['createdBy']
  createdAt: string
  uploadSessionId: string
  workspaceId: string
  itemSourceInfo: ItemData['itemSourceInfo']
}

const ItemUserInfo: React.FC<ItemUserInfoProps> = ({
  createdByUser,
  createdAt,
  uploadSessionId,
  workspaceId,
  itemSourceInfo,
}) => {
  const theme = useTheme()
  const location = useLocation()

  const workpaces = useSelector(workspacesSelector)
  const itemWorkspaceUrl = workpaces?.[workspaceId]?.url

  return (
    <>
      {createdByUser && (
        <Flex flexDirection="column">
          <StyledLink
            state={{ prevPathname: `${location.pathname}${location.search}` }}
            to={{
              pathname: `/u/${createdByUser.handle}`,
            }}
          >
            <Flex alignItems="center">
              <UserAvatar
                photoUrl={createdByUser.photoURL}
                displayName={createdByUser.displayName}
                size={32}
              />
              <Spacer axis="x" factor={1} />
              <Text size="sm">{createdByUser.displayName}</Text>
            </Flex>
          </StyledLink>
          <Spacer factor={2} />
          {itemWorkspaceUrl ? (
            <StyledLink
              to={`/${itemWorkspaceUrl}/library/upload-events/${uploadSessionId}`}
            >
              <CalendarClear size="16" />
              <Spacer axis="x" factor={1.3} />
              <Text size="sm">Added {timeAgo(createdAt)}</Text>
            </StyledLink>
          ) : (
            <Flex>
              <CalendarClear
                size="18"
                style={{ color: theme.colors.text.neutral[0] }}
              />
              <Spacer axis="x" factor={1} />
              <Text size="sm" color="neutral.0">
                Added {timeAgo(createdAt)}
              </Text>
            </Flex>
          )}
          {itemSourceInfo.type === 'VIDEO_EXTRACT' && (
            <>
              <Spacer factor={1} />
              {itemSourceInfo.videoExtractInfo?.url ? (
                <StyledExternalLink
                  target="_blank"
                  rel="noreferrer"
                  href={itemSourceInfo.videoExtractInfo.url}
                  onClick={() =>
                    trackItemSourceClicked({
                      sourceUrl: itemSourceInfo.videoExtractInfo?.url ?? '',
                      sourceType:
                        itemSourceInfo.videoExtractInfo?.urlType ?? '',
                    })
                  }
                >
                  <Play size="18" />
                  <Spacer axis="x" factor={1} />
                  <Text size="sm">Extracted from video</Text>
                </StyledExternalLink>
              ) : (
                <Flex alignItems="center">
                  <Play
                    size="18"
                    style={{ color: theme.colors.text.neutral[0] }}
                  />
                  <Spacer axis="x" factor={1} />
                  <Text size="sm" color="neutral.0">
                    Extracted from video
                  </Text>
                </Flex>
              )}
            </>
          )}
          {itemSourceInfo.type === 'BROWSER_EXTENSION_IMPORT' && (
            <>
              <Spacer factor={1} />
              <StyledExternalLink
                target="_blank"
                rel="noreferrer"
                href={itemSourceInfo.extensionImportInfo?.pageUrl || undefined}
                onClick={() =>
                  trackItemSourceClicked({
                    sourceUrl:
                      itemSourceInfo.extensionImportInfo?.pageUrl ?? '',
                    sourceType: 'BROWSER_EXTENSION',
                  })
                }
              >
                <Chrome size="18" />
                <Spacer axis="x" factor={1} />
                <Text size="sm">Added with Browser Extension</Text>
              </StyledExternalLink>
            </>
          )}
        </Flex>
      )}
    </>
  )
}

export default ItemUserInfo

const StyledLink = styled(Link)(
  ({ theme }) => css`
    color: ${theme.colors.text.neutral[0]};
    transition: 0.1s color ease;
    display: flex;
    align-items: center;
    :hover {
      color: ${theme.colors.accent[2]};
    }
  `
)

const StyledExternalLink = styled.a(
  ({ theme }) => css`
    color: ${theme.colors.text.neutral[0]};
    transition: 0.1s color ease;
    display: flex;
    align-items: center;
    :hover {
      color: ${theme.colors.accent[2]};
    }
  `
)
