import { Line } from 'react-konva'

import { useStorage } from 'config/liveblocks.config'

import { KiveImage } from './KiveImage'

export const useCanvasBounds = () => {
  const items = useStorage((root) => root.shapes)

  // Calculate the top and left edge coordinates and total width of content
  const updateCanvasBounds = () => {
    const images = items
      ? Object.values(items).filter((item) => item.type === 'image')
      : []

    const { x, y } = images.reduce<{ x: number; y: number }>(
      (acc, image) => {
        if (image.x < acc.x) {
          acc.x = image.x
        }
        if (image.y < acc.y) {
          acc.y = image.y
        }
        return acc
      },
      { x: 0, y: 0 }
    )

    const { width, height } = images.reduce<{ width: number; height: number }>(
      (acc, image) => {
        if (image.x + image.width > acc.width + x) {
          acc.width = image.x + image.width - x
        }
        if (image.y + image.height > acc.height + y) {
          acc.height = image.y + image.height - y
        }
        return acc
      },
      { width: 0, height: 0 }
    )

    return { x, y, width, height }
  }

  return { updateCanvasBounds }
}

export const OutpaintShapesRenderer = () => {
  const items = useStorage((root) => root.shapes)
  const itemsss = !items
    ? []
    : Object.entries(items).sort(([, aShape], [, bShape]) => {
        return aShape.order - bShape.order
      })
  return (
    <>
      {itemsss.map(([shapeId, shape]) => {
        if (shape.type === 'image') {
          return (
            <KiveImage
              key={shapeId}
              url={shape.url}
              x={shape.x}
              y={shape.y}
              width={shape.width}
              height={shape.height}
              globalCompositeOperation={shape.globalCompositeOperation}
            />
          )
        }
        if (shape.type === 'eraser') {
          return (
            <Line
              key={shapeId}
              points={shape.points}
              stroke="#df4b26"
              strokeWidth={shape.size}
              tension={0.5}
              lineCap="round"
              lineJoin="round"
              listening={false}
              globalCompositeOperation="destination-out"
            />
          )
        }
        return null
      })}
    </>
  )
}
