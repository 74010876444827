import styled from 'styled-components'

export const Main = styled.div(({ theme }) => ({
  height: '100vh',
  // background: theme.colors.background[1],
  color: theme.colors.text.neutral[3],
  fontFamily: theme.fontFamily.primary,
  '.Resizer': {
    background: theme.colors.discreet,
    opacity: 1,
    '-moz-box-sizing': 'border-box',
    '-webkit-box-sizing': 'border-box',
    'box-sizing': 'border-box',
    '-moz-background-clip': 'padding',
    '-webkit-background-clip': 'padding',
    'background-clip': 'padding-box',
  },
  '.Resizer.vertical': {
    borderLeft: '5px solid rgba(255, 255, 255, 0)',
    borderRight: '5px solid rgba(255, 255, 255, 0)',
  },
  '.Resizer.vertical:hover': {
    borderLeft: '5px solid rgba(0, 0, 0, 0.5)',
    borderLight: '5px solid rgba(0, 0, 0, 0.5)',
  },
}))
