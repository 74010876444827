import styled from 'styled-components'

type SpacerProps = {
  factor: number
  axis?: 'x' | 'y'
}

export const Spacer = styled.span<SpacerProps>(({ factor, axis = 'y' }) => {
  const cssAxis = axis === 'y' ? 'marginTop' : 'marginLeft'
  return {
    display: 'block',
    [cssAxis]: factor * 8,
  }
})
