import { Plus } from '@styled-icons/feather/Plus'
import React, { useRef, useState } from 'react'
import styled from 'styled-components'

import useToastMessages from 'components/toast/useToastMessages'

import { wait } from '../../helpers/utilityFunctions'
import Spinner from '../common/Spinner'
import StackGrid from '../common/StackGrid'

export default function ThumbnailUpload({ src, onFilePick }) {
  const { reportError } = useToastMessages()

  /** @type {React.MutableRefObject<HTMLInputElement>} */
  const inputRef = useRef()
  const [isUploading, setIsUploading] = useState(false)
  const onFileSelect = async (ev) => {
    /** @type {File} */
    const file = ev.target.files[0]
    if (file == null) {
      return
    }
    if (!file.type.startsWith('image') || file.size > 10000000) {
      reportError('ONLY IMAGES under 10 MB!!')
      return
    }
    setIsUploading(true)
    await onFilePick(file)
    await wait(600)
    setIsUploading(false)
  }

  return (
    <>
      <input
        type="file"
        ref={inputRef}
        onChange={onFileSelect}
        style={{ display: 'none' }}
      />
      <Frame onClick={() => inputRef.current?.click()}>
        <StackGrid
          style={{
            height: '100%',
            width: '100%',
            justifyItems: 'center',
            alignItems: 'center',
          }}
        >
          {src ? (
            <ThumbImage src={src} alt="Profile" isUploading={isUploading} />
          ) : (
            !isUploading && <Plus size={40} />
          )}
          {isUploading && <Spinner size={20} />}
        </StackGrid>
      </Frame>
    </>
  )
}

const Frame = styled.figure`
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text.neutral[2]};
  border: ${({ theme }) => `${theme.border.thin} ${theme.colors.discreet}`};
  padding: 6px;
  width: 75px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 150ms color, 150ms border-color;
  :hover {
    color: ${(props) => props.theme.colors.text.neutral[0]};
    border-color: ${(props) => props.theme.colors.accent[2]};
  }
`
/** @type{ AppStyledComponent<"img",{isUploading?: boolean}> } */
const ThumbImage = styled.img`
  border-radius: 12px;
  height: 100%;
  width: 100%;
  filter: ${(props) =>
    props.isUploading ? 'blur(3px) brightness(0.7)' : 'none'};
  transition: filter 150ms;
`
