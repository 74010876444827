import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'

import callCloudFunction from '../../../helpers/callCloudFunction'
import { Label } from '../../common/Form'
import Spinner from '../../common/Spinner'
import useToastMessages from '../../toast/useToastMessages'
import {
  Button,
  Heading,
  HeadingSecondary,
  Input,
  SettingsContent,
} from '../SettingsStyles'
import ThumbnailUpload from '../ThumbnailUpload'
import useSettingNavigation from '../useSettingsNavigation'

async function updateUser({
  firstName,
  lastName,
  bio,
  uid,
  auth,
  firebase,
  db,
}) {
  const displayName = `${firstName} ${lastName}`
  if (displayName !== auth.displayName) {
    await firebase.auth().currentUser?.updateProfile({ displayName })
    await firebase.reloadAuth()
  }
  await db.collection('users').doc(uid).update({
    firstName,
    lastName,
    displayName,
    bio,
    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
  })
}

function Account({ auth, firebase, user, uid, firestore }) {
  const { reportSuccess } = useToastMessages()
  const { close: closeSettings } = useSettingNavigation()
  const uploadProfileThumb = (file) => {
    const formData = new FormData()
    formData.append('image', file, file.name)
    return callCloudFunction('addProfileThumbnail', formData).then(() =>
      firebase.reloadAuth()
    )
  }

  const { firstName: first_name, lastName: last_name, bio } = user

  const {
    register,
    reset,
    handleSubmit,
    formState: { isDirty, isSubmitting },
  } = useForm({
    mode: 'onChange',
  })

  useEffect(() => {
    reset({ first_name, last_name, bio })
  }, [first_name, last_name, reset, bio])

  const onSubmit = async (values) => {
    await updateUser({
      firstName: values.first_name,
      lastName: values.last_name,
      bio: values.bio,
      uid,
      auth,
      firebase,
      db: firestore,
    })
    // const displayName = `${values.first_name} ${values.last_name}`
    // if (displayName === auth.displayName) {
    //   return
    // }
    // await firebase.auth().currentUser?.updateProfile({ displayName })
    // await firebase.reloadAuth()
    reportSuccess('Account data updated')
    closeSettings()
  }
  return (
    <SettingsContent
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      afterContent={
        <div style={{ display: 'flex' }}>
          <Button
            type="submit"
            style={{ width: 150, marginRight: 16 }}
            disabled={!isDirty || isSubmitting}
          >
            {isSubmitting ? <Spinner size={14} /> : 'Save'}
          </Button>
          <Button
            subdued
            type="button"
            style={{ width: 150 }}
            // disabled={!isDirty || isSubmitting}
            onClick={closeSettings}
          >
            Cancel
          </Button>
        </div>
      }
    >
      <Heading noMarginTop>Account settings</Heading>
      <HeadingSecondary>Photo</HeadingSecondary>
      <ThumbnailUpload onFilePick={uploadProfileThumb} src={auth.photoURL} />
      <HeadingSecondary>Personal info</HeadingSecondary>
      <Label htmlFor="first_name">First name</Label>
      <Input
        name="first_name"
        id="first_name"
        {...register('first_name')}
        required
      />
      <Label htmlFor="last_name">Last name</Label>
      <Input
        name="last_name"
        id="last_name"
        {...register('last_name')}
        required
      />
      <Label htmlFor="bio">Bio</Label>
      <Input name="bio" id="bio" {...register('bio')} />
    </SettingsContent>
  )
}

const mapStateToProps = (state, ownProps) => ({
  auth: state.firebase.auth,
  uid: state.firebase.auth.uid,
  user: state.firestore.data.users?.[state.firebase.auth.uid] ?? {},
  firestore: ownProps.firestore,
})

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => [
    {
      collection: 'users',
      doc: props.auth.uid,
    },
  ])
)(
  // @ts-ignore
  Account
)
