import uniqBy from 'lodash/uniqBy'
import { useNavigate, useOutletContext } from 'react-router-dom'

import { getRandomEmoji } from 'components/common/emoji/EmojiPicker'
import CreateView from 'components/project/CreateView'
import useToastMessages from 'components/toast/useToastMessages'
import {
  BoardBasicFieldsFragmentDoc,
  useAddBoardMutation,
} from 'generated/graphql'

export type OutletContext = {
  projectId: string
  workspaceId: string
}

export const ProjectAddBoardView = () => {
  const navigate = useNavigate()
  const { reportError } = useToastMessages()

  const { projectId, workspaceId } = useOutletContext<OutletContext>()

  const [addBoardMutation, { loading }] = useAddBoardMutation({
    update: (cache, payload) => {
      cache.modify({
        fields: {
          [`boards:${projectId}`]: (existingBoards) => {
            const newBoard = payload.data?.addBoard
            const ref = cache.writeFragment({
              data: newBoard,
              fragment: BoardBasicFieldsFragmentDoc,
            })
            const updatedBoards = uniqBy(
              [ref, ...existingBoards.boards],
              '__ref'
            )
            return {
              ...existingBoards,
              boards: updatedBoards,
            }
          },
        },
      })
    },
  })

  const handleCreateBoard = async (title: string) => {
    try {
      const { data } = await addBoardMutation({
        variables: {
          projectId,
          workspaceId,
          title,
          iconCode: getRandomEmoji(),
        },
      })
      if (!data?.addBoard.id) return
      navigate(`../${data.addBoard.id}`)
    } catch (error) {
      reportError(`Oh, we couldn't add a board to the project`)
    }
  }

  return <CreateView handleCreate={handleCreateBoard} isLoading={loading} />
}
