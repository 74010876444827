import axios from 'axios'

import { firebaseAuth, firebaseFunctionsDomain } from '../config/firebase'

export const getApiClient = async () => {
  const idToken = await firebaseAuth().currentUser?.getIdToken()
  return axios.create({
    baseURL: firebaseFunctionsDomain,
    headers: { Authorization: idToken },
  })
}
