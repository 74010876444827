import { Link as LinkIcon } from '@styled-icons/boxicons-regular/Link'
import { Palette } from '@styled-icons/boxicons-regular/Palette'
import { PaperPlane } from '@styled-icons/entypo/PaperPlane'
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline'
import { Eye } from '@styled-icons/ionicons-solid/Eye'
import { ArrowDownward } from '@styled-icons/material/ArrowDownward'
import { Lock } from '@styled-icons/material/Lock'
import { MoreHoriz } from '@styled-icons/material/MoreHoriz'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled, { css } from 'styled-components'

import Button from 'components/common/Button'
import ButtonHoverDropdown from 'components/common/ButtonHoverDropdown'
import Dropdown from 'components/common/Dropdown'
import DropdownButton from 'components/common/DropdownButton'
import IconButton from 'components/common/IconButton'
import { Text } from 'components/common/Text'
import useToastMessages from 'components/toast/useToastMessages'
import { db } from 'config/firebase'
import {
  MediaVariants,
  useUpdateBoardItemsAccessLevelMutation,
  useUpdateWorkspaceItemsAccessLevelMutation,
} from 'generated/graphql'
import StopPropagation from 'helpers/StopPropagation'
import downloadItem from 'helpers/downloadItem'
import { getIsForbiddenError } from 'helpers/parseGraphqlErrors'
import {
  trackItemLinkCopied,
  trackItemPageOpened,
} from 'helpers/tracking/tracking'
import useIsMobile from 'hooks/useIsMobile'
import useQueryString from 'hooks/useQueryString'
import { useGrowthBookFeature } from 'hooks/useSyncGrowthbook'
import { RootState } from 'store'
import { DBItemAccessLevel } from 'types/db'

import { CreateEditSessionButton } from '../edit-sessions'
import ItemFileNameInput from './ItemFileNameInput'
import {
  getMediaVariantsForMediaType,
  getTitleFromMediaVariant,
  titleToMediaVariant,
} from './getItemVersions'
import { ItemData } from './hooks/useGetItemData'

interface CopyItemDropdownProps {
  item: ItemData
  canUpdateItemAccessLevel: boolean
}

const CopyItemDropdownButton: React.FC<CopyItemDropdownProps> = ({
  item,
  canUpdateItemAccessLevel,
}) => {
  const isMobile = useIsMobile()
  const { reportSuccess, reportError } = useToastMessages()

  const { accessLevel, id } = item
  const isBoardItem = 'boardId' in item
  const [temporaryAccessLevel, setTemporaryAccessLevel] = useState(accessLevel)

  useEffect(() => {
    setTemporaryAccessLevel(item.accessLevel)
  }, [item.accessLevel])

  const [updateWorkspaceItemsAccessLevel] =
    useUpdateWorkspaceItemsAccessLevelMutation()

  const [updateBoardItemsAccessLevel] = useUpdateBoardItemsAccessLevelMutation()

  const handleUpdateItemAccessLevel = async (
    newAccessLevel: DBItemAccessLevel
  ) => {
    if (!item) {
      throw new Error('handleUpdateItemAccessLevel: no item provided')
    }

    if (!canUpdateItemAccessLevel) return

    const previousAccessLevel = temporaryAccessLevel
    setTemporaryAccessLevel(newAccessLevel)

    if ('boardId' in item) {
      try {
        await updateBoardItemsAccessLevel({
          variables: {
            boardItemIds: [id],
            boardId: item.boardId,
            accessLevel: newAccessLevel,
          },
        })
      } catch (error) {
        setTemporaryAccessLevel(previousAccessLevel)
        if (getIsForbiddenError(error)) {
          return reportError(
            `Not allowed to edit board item access. Contact the item owner`
          )
        }
        reportError(`Couldn't update board item access, please try again`)
      }
    } else {
      try {
        await updateWorkspaceItemsAccessLevel({
          variables: {
            itemIds: [item.id],
            workspaceId: item.workspaceId,
            accessLevel: newAccessLevel,
          },
        })
      } catch (error) {
        setTemporaryAccessLevel(previousAccessLevel)
        if (getIsForbiddenError(error)) {
          return reportError(
            `Not allowed to edit item access. Contact the item owner`
          )
        }
        reportError(`Couldn't update the item access, please try again`)
      }
    }
  }

  const handleCopyLink = () => {
    if (accessLevel === 'PRIVATE') {
      handleUpdateItemAccessLevel('WITH_LINK')
    }
    const shortPath = isBoardItem ? 'bi' : 'i'
    navigator.clipboard.writeText(
      `${window.location.origin}/${shortPath}/${id}`
    )
    reportSuccess('Copied link to clipboard!')

    const context = {
      itemId: id,
      boardId: 'boardId' in item ? item.boardId : undefined,
      projectId: 'projectId' in item ? item.projectId : undefined,
      workspaceId: item.workspaceId,
    }
    trackItemLinkCopied(context)
  }

  return (
    <>
      {canUpdateItemAccessLevel ? (
        <DropdownButton
          title="Copy item link"
          Icon={PaperPlane}
          onClick={handleCopyLink}
          dropdownMenuWidth={isMobile ? 290 : undefined}
        >
          <Dropdown.Header
            title="Share your item"
            subtitle="Manage your share link's privacy"
          />
          <Dropdown.ItemLarge
            isActive={temporaryAccessLevel === 'PUBLIC'}
            onClick={() => handleUpdateItemAccessLevel('PUBLIC')}
            title="Public"
            description="Anyone on Kive can view this item. You get credit for sharing it with the community."
            Icon={Palette}
          />
          <Dropdown.ItemLarge
            isActive={temporaryAccessLevel === 'WITH_LINK'}
            onClick={() => handleUpdateItemAccessLevel('WITH_LINK')}
            title="With Link"
            description="Anyone with the link can view this item."
            Icon={LinkIcon}
          />
          <Dropdown.ItemLarge
            isActive={temporaryAccessLevel === 'PRIVATE'}
            onClick={() => handleUpdateItemAccessLevel('PRIVATE')}
            title="Private"
            description="Only you and people in your workspace can view this item."
            Icon={Lock}
          />
        </DropdownButton>
      ) : (
        <Button onClick={handleCopyLink} variant="primary" Icon={PaperPlane}>
          Copy item link
        </Button>
      )}
    </>
  )
}

const getItemSpacePath = (spaceKey: string) => {
  if (spaceKey === 'uploads') return 'searchableItems'
  if (spaceKey === 'projects') return 'itemRefs'
  return 'UNKNOWN'
}

interface ItemActionBarTopProps {
  item: ItemData
  canDeleteItem: boolean
  canUpdateItemAccessLevel: boolean
  onCloseItemView: () => void
  onOpenDeleteModal: () => void
}

const ItemActionBarTop: React.FC<ItemActionBarTopProps> = ({
  item,
  canDeleteItem,
  canUpdateItemAccessLevel,
  onCloseItemView,
  onOpenDeleteModal,
}) => {
  const aiCanvasFeature = useGrowthBookFeature('ai-canvas')
  const isMobile = useIsMobile()
  const [isDownloading, setIsDownloading] = useState(false)
  const uid = useSelector((state: RootState) => state.firebase.auth.uid)
  const activeWorkspaceId = useSelector(
    (state: RootState) => state.content.activeWorkspace?.id as string
  )
  const activeWorkspaceUrl = useSelector(
    (state: RootState) => state.content.activeWorkspace?.url
  )
  const recentDownloadVersion = useSelector(
    (state: RootState) =>
      state.firestore.data.userPrivate?.usage?.recentDownloadVersion
  )

  const navigate = useNavigate()
  const { itemSpace: spaceKey } = useQueryString<{ itemSpace: string }>()

  const isBoardItem = 'boardId' in item

  const downloadContext = {
    itemId: item.id,
    boardId: isBoardItem ? item.boardId : undefined,
    projectId: isBoardItem ? item.projectId : undefined,
    workspaceId: activeWorkspaceId,
  }

  const handleOpenItemPage = () => {
    if (!item.webUrl) return
    window.open(item.webUrl, '_blank')

    const context = {
      pageUrl: item.webUrl,
      itemId: item.id,
      itemSpacePath: getItemSpacePath(spaceKey),
      boardId: isBoardItem ? item.boardId : undefined,
      projectId: isBoardItem ? item.projectId : undefined,
      workspaceId: activeWorkspaceId,
    }
    trackItemPageOpened(context)
  }

  const handleDownloadMediaVariant = async (mediaVariant: MediaVariants) => {
    setIsDownloading(true)
    await downloadItem(
      { publicUrl: mediaVariant.url },
      item.title || 'image',
      downloadContext
    )
    setIsDownloading(false)

    const title = getTitleFromMediaVariant({
      variant: mediaVariant.variant,
      mediaType: item.mediaType,
    })

    // Migrate to graphql?
    db.collection('users')
      .doc(uid)
      .collection('private')
      .doc('usage')
      .set({ recentDownloadVersion: title }, { merge: true })
  }

  const handleDownloadOriginal = async () => {
    setIsDownloading(true)
    await downloadItem(
      { internalPath: item.original.path },
      item.title || 'image',
      downloadContext
    )
    setIsDownloading(false)
  }

  const handleDownloadDefault = () => {
    if (!recentDownloadVersion || recentDownloadVersion === 'Original') {
      return handleDownloadOriginal()
    }
    const variant = titleToMediaVariant(recentDownloadVersion)

    const mediaVariants = getMediaVariantsForMediaType({
      mediaVariants: item.mediaVariants,
      mediaType: item.mediaType,
    })
    const mediaVariantToDownload = mediaVariants.find(
      (mediaVariant) => mediaVariant.variant === variant
    )
    if (!mediaVariantToDownload) {
      return handleDownloadOriginal()
    }
    return handleDownloadMediaVariant(mediaVariantToDownload)
  }

  const handleNavigateToUploadSession = () => {
    if (!item) {
      throw new Error(
        'handleNavigateToUploadSession: tried to go to upload session when item does not exist'
      )
    }
    navigate(
      `/${activeWorkspaceUrl}/library/upload-events/${item.uploadSessionId}`
    )
  }

  return (
    <ActionBar>
      {!isMobile && <Margin />}
      <IconButton
        css="margin:0px 8px"
        Icon={CloseOutline}
        onClick={onCloseItemView}
      />
      <StopPropagation>
        <ActionMain isMobile={isMobile}>
          {!('boardId' in item) && !isMobile && (
            <ItemFileNameInput item={item} />
          )}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flex: 'right',
              whiteSpace: 'nowrap',
            }}
          >
            {!!item.openCount && (
              <TextWrapper>
                <IconWrapper>
                  <Eye style={{ height: 16 }} />
                </IconWrapper>
                <Text size="sm">
                  {item.openCount === 1 ? '1 view' : `${item.openCount} views`}
                </Text>
              </TextWrapper>
            )}

            {!isMobile && (
              <ButtonWrapper>
                <ButtonHoverDropdown
                  onClick={handleDownloadDefault}
                  title="Download"
                  Icon={ArrowDownward}
                  isLoading={isDownloading}
                >
                  {getMediaVariantsForMediaType({
                    mediaVariants: item.mediaVariants,
                    mediaType: item.mediaType,
                  }).map((mediaVariant) => {
                    const title = getTitleFromMediaVariant({
                      variant: mediaVariant.variant,
                      mediaType: item.mediaType,
                    })
                    if (!title) return null
                    return (
                      <Dropdown.Item
                        key={mediaVariant.variant}
                        title={title}
                        info={
                          recentDownloadVersion === title ? 'Your default' : ''
                        }
                        onClick={() => {
                          handleDownloadMediaVariant(mediaVariant)
                        }}
                      />
                    )
                  })}
                  <Dropdown.Item
                    key="Original"
                    title="Original"
                    info={
                      recentDownloadVersion === 'Original' ? 'Your default' : ''
                    }
                    onClick={handleDownloadOriginal}
                  />
                </ButtonHoverDropdown>
              </ButtonWrapper>
            )}
            {aiCanvasFeature.on ? (
              <ButtonWrapper>
                <CreateEditSessionButton
                  item={item}
                  workspaceId={activeWorkspaceId}
                  userId={uid}
                />
              </ButtonWrapper>
            ) : null}
            <ButtonWrapper>
              <CopyItemDropdownButton
                item={item}
                canUpdateItemAccessLevel={canUpdateItemAccessLevel}
              />
            </ButtonWrapper>
            <ButtonWrapper>
              <IconButton Icon={MoreHoriz} menuWidth={200}>
                {item.webUrl && (
                  <Dropdown.Item
                    title="Find online"
                    onClick={handleOpenItemPage}
                  />
                )}
                <Dropdown.Item
                  title="Go to upload event"
                  onClick={handleNavigateToUploadSession}
                />
                {canDeleteItem && (
                  <Dropdown.Item
                    title={isBoardItem ? 'Remove from board' : 'Delete item'}
                    onClick={onOpenDeleteModal}
                  />
                )}
              </IconButton>
            </ButtonWrapper>
          </div>
        </ActionMain>
      </StopPropagation>
      {!isMobile && <Margin />}
    </ActionBar>
  )
}

export default ItemActionBarTop

const ActionBar = styled.div`
  height: 76px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ActionMain = styled.div<{ isMobile: boolean }>`
  ${({ isMobile }) => css`
    height: 100%;
    width: ${isMobile ? '100%' : 'calc(100vw - 192px)'};
    display: flex;
    justify-content: flex-end;
  `}
`

const Margin = styled.div`
  width: 64px;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

const ButtonWrapper = styled.div`
  margin: 0 8px;
`

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.text.neutral[0]};
  margin: 0 8px;
  @media (max-width: 500px) {
    display: none;
  }
`

const IconWrapper = styled.div`
  margin: 0 8px;
`
