import { FC, ReactNode } from 'react'

import { FeatureCheckIcon } from '../FeatureCheckIcon'
import { StyledFeatureLine } from './ComparePlansStyles'

type Props = {
  children?: ReactNode
}
export const FeatureLine: FC<Props> = ({ children }) => {
  return (
    <StyledFeatureLine>
      <FeatureCheckIcon />
      {children}
    </StyledFeatureLine>
  )
}
