import { DefaultTheme } from 'styled-components'

import kiveLogoWhite from 'assets/svg/kive_logo.svg'
import kiveLogoBlack from 'assets/svg/kive_logo_black.svg'

const COLORS = {
  white: '#ffffff',
  black: '#000000',
  gray: {
    dark: {
      '-1': 'rgb(9,9,9)',
      0: 'rgb(12,12,12)',
      1: 'rgb(14,14,14)',
      2: 'rgb(16,16,16)',
      3: 'rgb(20,20,20)',
      4: 'rgb(24,24,24)',
      5: 'rgb(32,32,32)',
      6: 'rgb(40,40,40)',
      7: 'rgb(48,48,48)',
      8: 'rgb(56,56,56)',
      9: 'rgb(64,64,64)',
    },
    medium: {
      0: 'rgb(70,70,70)',
      1: 'rgb(83,83,83)',
      2: 'rgb(96,96,96)',
      3: 'rgb(109,109,109)',
      4: 'rgb(122,122,122)',
      5: 'rgb(135,135,135)',
      6: 'rgb(148,148,148)',
      7: 'rgb(161,161,161)',
      8: 'rgb(174,174,174)',
      9: 'rgb(187,187,187)',
    },
    light: {
      0: 'rgb(193,193,193)',
      1: 'rgb(201,201,201)',
      2: 'rgb(207,207,207)',
      3: 'rgb(215,215,215)',
      4: 'rgb(223,223,223)',
      5: 'rgb(231,231,231)',
      6: 'rgb(235,235,235)',
      7: 'rgb(239,239,239)',
      8: 'rgb(241,241,241)',
      9: 'rgb(243,243,243)',
    },
  },
  accent: {
    monaLisa: '#FF9494',
    heliotrope: '#B87AFF',
    royalBlue: '#5930E5',
    brightTurquoise: '#33FFD3',
  },
  pressedHelioTrope: '#4E2AC7',
  pink: '#FF9494',
  success: '#00ff9d',
  error: '#f0492b',
  red: '#ee5535',
  redLighter: '#c54e35',
  blue: '#021018',
  greenLighter: '#41c170',
}

export const BASE_THEME = {
  colors: {
    ...COLORS,
  },
  fontFamily: {
    primary: 'UniversalSans',
    heading: 'UniversalSans',
    serif: 'Fortescue',
  },
  fontSizes: {
    '2xs': '.625rem', // 10px
    xs: '.75rem', // 12px
    sm: '.8rem', // 14px
    base: '1rem', // 16px default for most people
    lg: '1.25rem', // 20px
    xl: '1.875rem', // // 30px
    '2xl': '2.5rem', // 40px
    '3xl': '3.5rem', // 56px
    '3xxl': '4rem',
    '4xl': '5.5rem', // 88px
    '4xxl': '6.25rem',
    '5xl': '7.5rem', //
  },
  fontWeight: {
    // hairline: '100', // Not supported with current font
    // thin: '200', // Not supported
    light: '300',
    normal: '400',
    medium: '500',
    semibold: '600',
    bold: '700',
    extrabold: '800',
    black: '900',
  },
  letterSpacing: {
    tighter: '-0.05em',
    tight: '-0.025em',
    normal: '0',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.1em',
  },
  lineHeight: {
    none: '1',
    tight: '1.25',
    snug: '1.375',
    normal: '1.5',
    relaxed: '1.625',
    loose: '2',
  },
  breakpoints: {
    xs: 320,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    '2xl': 1440,
  },
  borderRadius: {
    none: '0',
    sm: '4px',
    default: '8px',
    lg: '16px',
    lgx: '24px',
    xl: '32px',
    full: '9999px',
  },
  border: {
    thin: `1px solid`,
    medium: '2px solid',
    thick: `4px solid`,
  },
  shadow: {
    thin: `0px 3px 5px 0px rgb(0 0 0 / 50%)`,
    thick: `0 0 0 4px`,
  },
  width: (width: number) => `${width * 100}%`, // example: theme.width(1 / 2) === '50%'
}

export const darkTheme: DefaultTheme = {
  ...BASE_THEME,
  logo: kiveLogoWhite, // Legacy, use KiveLogo component instead
  logoInverted: kiveLogoBlack, // Legacy, use KiveLogo component instead
  colors: {
    ...BASE_THEME.colors,
    highlight: COLORS.accent.royalBlue,
    discreet: COLORS.gray.dark[5],
    danger: COLORS.redLighter,
    dangerHighlight: COLORS.red,
    accent: {
      0: COLORS.accent.monaLisa,
      1: COLORS.accent.heliotrope,
      2: COLORS.accent.royalBlue,
      3: COLORS.accent.brightTurquoise,
    },
    text: {
      discreet: COLORS.gray.medium[0],
      neutral: {
        0: COLORS.white, // previously neutralTextKey1
        1: COLORS.gray.medium[9], // neutralTextKey2
        2: COLORS.gray.medium[5], // neutralTextKey3
        3: COLORS.gray.medium[4], // neutralText
        4: COLORS.gray.dark[9], // neutralTextDarker
        5: COLORS.gray.dark[5],
        6: COLORS.gray.light[5],
      },
    },
    background: {
      0: COLORS.gray.dark[0], // maskBackground
      1: COLORS.gray.dark[1], // background
      2: COLORS.gray.dark[2],
      3: COLORS.gray.dark[3], // backgroundTinted1
      4: COLORS.gray.dark[4], // backgroundTinted2
      5: COLORS.gray.dark[5], // backgroundTinted3
      landing: '#02151C',
    },
  },
  shadow: {
    ...BASE_THEME.shadow,
    minimal: `0 0 0 1px rgba(0,0,0,0.8), 0 0 8px 0 rgba(0,0,0,0.1)`,
  },
}

export const lightTheme: DefaultTheme = {
  ...BASE_THEME,
  logo: kiveLogoWhite, // Legacy, use KiveLogo component instead
  logoInverted: kiveLogoBlack, // Legacy, use KiveLogo component instead
  colors: {
    ...BASE_THEME.colors,
    highlight: COLORS.accent.royalBlue,
    discreet: COLORS.gray.light[0],
    danger: COLORS.redLighter,
    dangerHighlight: COLORS.red,
    accent: {
      0: COLORS.accent.monaLisa,
      1: COLORS.accent.heliotrope,
      2: COLORS.accent.royalBlue,
      3: COLORS.accent.brightTurquoise,
    },
    text: {
      discreet: COLORS.gray.medium[0],
      neutral: {
        0: COLORS.gray.dark[5], // COLORS.white,  previously neutralTextKey1
        1: COLORS.gray.dark[9], // COLORS.gray.medium[9],  neutralTextKey2
        2: COLORS.gray.medium[0], // COLORS.gray.medium[5],  neutralTextKey3
        3: COLORS.gray.medium[5], // neutralText
        4: COLORS.gray.medium[9], // neutralTextDarker
        5: COLORS.gray.dark[9],
        6: COLORS.gray.dark[9],
      },
    },
    background: {
      0: COLORS.white, // maskBackground
      1: COLORS.white, // background
      2: COLORS.white,
      3: COLORS.white, // backgroundTinted1
      4: COLORS.white, // backgroundTinted2
      5: COLORS.gray.light[5], // backgroundTinted3
      landing: '#02151C',
    },
  },
  shadow: {
    ...BASE_THEME.shadow,
    minimal: `0 0 0 1px rgba(0,0,0,0.8), 0 0 8px 0 rgba(0,0,0,0.1)`,
  },
}
