import React from 'react'
import ReactProgressiveImage from 'react-progressive-image'
import styled from 'styled-components'

import Loading from 'components/Loading'

const Image = styled.img`
  object-fit: contain;
  height: auto;
  width: 100%;
  flex: 1;
  display: block;
`

interface ProgressiveImageProps extends React.HTMLAttributes<HTMLImageElement> {
  imgUrl?: string
  thumbUrl?: string
  withLoadingIndicator?: boolean
}

const ProgressiveImage: React.FC<ProgressiveImageProps> = ({
  imgUrl,
  thumbUrl,
  withLoadingIndicator,
  ...rest
}) => (
  <>
    {!imgUrl || !thumbUrl ? (
      <Loading />
    ) : (
      // @ts-ignore
      <ReactProgressiveImage src={imgUrl} placeholder={thumbUrl}>
        {(src: string, loading: boolean) => {
          return (
            <>
              {withLoadingIndicator && loading && (
                <div css="position: absolute; left: 30px; height: 100%; display: flex;">
                  <Loading />
                </div>
              )}
              <Image src={src} {...rest} />
            </>
          )
        }}
      </ReactProgressiveImage>
    )}
  </>
)

export default ProgressiveImage
