import React from 'react'
import styled, { css } from 'styled-components'

import { useGetLastUpdatedWorkspaceBoards } from 'hooks/useLastUpdatedBoards'
import { WithId } from 'store'
import { DBBoard } from 'types/db'

import SelectBoardListItem from './SelectBoardListItem'
import { useBoardSearch } from './useBoardSearch'

export type OnSelectBoardArgs = {
  board: WithId<DBBoard>
}

interface FindBoardProps {
  boardInputValue: string
  onSelectBoard: (args: OnSelectBoardArgs) => void
  accessLevel: 'writer' | 'reader'
}

const FindBoard: React.FC<FindBoardProps> = ({
  boardInputValue,
  onSelectBoard,
  accessLevel,
}) => {
  const boards = useBoardSearch({
    input: boardInputValue,
    accessLevel,
  })

  const writableRecentBoards = useGetLastUpdatedWorkspaceBoards({
    accessLevel,
  })

  return (
    <>
      <StyledFindBoardWrapper>
        {boardInputValue ? (
          <>
            {boards.length > 0 && (
              <StyledFindBoardTitle>Matching boards</StyledFindBoardTitle>
            )}
            <SelectBoardList>
              {boards.map((board) => (
                <SelectBoardListItem
                  key={board.id}
                  onAction={() => onSelectBoard({ board })}
                  board={board}
                />
              ))}
            </SelectBoardList>
          </>
        ) : (
          <>
            {writableRecentBoards.length > 0 && (
              <StyledFindBoardTitle>Recent boards</StyledFindBoardTitle>
            )}
            <SelectBoardList>
              {writableRecentBoards.map((board) => (
                <SelectBoardListItem
                  key={board.id}
                  onAction={() => onSelectBoard({ board })}
                  board={board}
                />
              ))}
            </SelectBoardList>
          </>
        )}
      </StyledFindBoardWrapper>
    </>
  )
}

export default FindBoard

const StyledFindBoardWrapper = styled.div`
  height: auto;
  margin-top: 8px;
`

const StyledFindBoardTitle = styled.div`
  ${({ theme }) => css`
    font-weight: ${theme.fontWeight.normal};
    font-size: ${theme.fontSizes.sm};
    color: ${theme.colors.text.neutral[3]};
    text-align: left;
    margin-bottom: 6px;
  `}
`
const SelectBoardList = styled.div`
  overflow-y: auto;
  max-height: 275px;
`
