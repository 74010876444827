import { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const queryParam = 'upgradePanel'

export default function useSettingNavigation() {
  const { search } = useLocation()
  const navigate = useNavigate()

  // Current panel
  const section = useMemo(() => {
    const query = new URLSearchParams(search)
    return query.get(queryParam)
  }, [search])

  // Open or close panel
  const sectionUrl = (panel) => {
    const query = new URLSearchParams(search)
    if (panel) {
      query.set(queryParam, panel)
    } else {
      query.delete(queryParam)
    }
    return `?${query}`
  }
  const gotoSection = (section_) => navigate(sectionUrl(section_))
  const open = (section_) => {
    gotoSection(section_)
  }
  const close = () => gotoSection()
  return { sectionUrl, section, open, close, gotoSection }
}
