import { KiveAccessStatus } from 'generated/graphql'

import { db, firebase, firebaseAuth } from '../config/firebase'
import { removeNullAndUndefinedFromObject } from './removeNullAndUndefinedFromObject'

type SignupContext = {
  kiveAccessStatus: KiveAccessStatus
  waitingListId?: string
  invitationId?: string
  workspaceId?: string
  fromPublicFeature?: boolean
  boardId?: string
  boardItemId?: string
  itemId?: string
}

type FromPublicFeatureData = {
  boardId?: string
  boardItemId?: string
  itemId?: string
}

interface UserUpdates {
  uid: string
  email: string
  signupContext: SignupContext
  joinedViaPublicFeature?: FromPublicFeatureData
}

async function saveUser(
  email: string,
  uid: string,
  signupContext: SignupContext
) {
  const batch = db.batch()
  const userRef = db.collection('users').doc(uid)

  const userBaseData: UserUpdates = {
    uid,
    email,
    signupContext: removeNullAndUndefinedFromObject({
      object: signupContext,
    }) as SignupContext,
  }

  // we keep this field as the backend is dependent on it
  if (signupContext.fromPublicFeature) {
    userBaseData.joinedViaPublicFeature = removeNullAndUndefinedFromObject({
      object: {
        boardId: signupContext.boardId,
        boardItemId: signupContext.boardItemId,
        itemId: signupContext.itemId,
      },
    })
  }

  // if user signed up via wating list we want to pass the waitingListId so segment to help connect with pre-signup activities
  if (signupContext.waitingListId) {
    window.analytics.identify(uid, {
      waitingListId: signupContext.waitingListId,
      email,
    })
  }

  batch.set(userRef, userBaseData, { merge: true })

  const onboardingRef = userRef.collection('private').doc('onboarding')

  // Init onboarding
  batch.set(onboardingRef, {
    completedSignup: true,
    completedWorkspace: false,
    completedPrivacy: false,
    completedProfile: false,
    completedUploads: false,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
  })

  // Return committed batch
  await batch.commit().catch((e) => console.log('Error: ', e))

  return
}

interface AuthValuesArgs {
  email: string
  password: string
}

export function auth(values: AuthValuesArgs, signupContext: SignupContext) {
  const email = values.email.trim().toLowerCase()

  return firebaseAuth()
    .createUserWithEmailAndPassword(email, values.password)
    .then(async (user) => {
      console.log('Created user ', user)

      return saveUser(email, user.user!.uid, signupContext)
    })
}

export function loginWithEmail(email: string, password: string) {
  return firebaseAuth().signInWithEmailAndPassword(email, password)
}

export function loginWithGoogle(email: string) {
  const provider = new firebase.auth.GoogleAuthProvider()

  // If email was provided, use hint
  if (email) {
    provider.setCustomParameters({
      login_hint: email,
    })
  }

  return firebase.auth().signInWithRedirect(provider)
}

export function loginWithGooglePopup() {
  const provider = new firebase.auth.GoogleAuthProvider()
  return firebase.auth().signInWithPopup(provider)
}

export function loginWithOTP({ token }: { token: string }) {
  return firebaseAuth().signInWithCustomToken(token)
}

export function logout() {
  return firebaseAuth().signOut()
}

export function resetPassword({
  email,
  returnUrl,
}: {
  email: string
  returnUrl: string
}) {
  return firebaseAuth().sendPasswordResetEmail(email, { url: returnUrl })
}
