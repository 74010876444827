import { KeyboardReturn } from '@styled-icons/material/KeyboardReturn'
import React from 'react'
import styled, { css } from 'styled-components'

import { Text } from 'components/common/Text'
// eslint-disable-next-line import/no-cycle
import { ItemSiderContainer } from 'components/item/ItemSider'
import { capitalizeFirstLetter } from 'helpers/formatting'
import { pathSafe } from 'helpers/utilityFunctions'
import useKeypress from 'hooks/useKeyPress'

import {
  TagSuggestion,
  TagSuggestionText,
  TagSuggestionTextDetail,
} from './ItemTagStyles'

interface Tag {
  description: string
  id: string
}

interface ItemTagRecentlyAddedTagsProps {
  activeTagIds: string[]
  addTag: (tag: Tag) => void
  setFocus: (newFocusTag: Tag, newFocusIndex: number) => void
  focusIndex: number
  willOverflowScroll?: boolean
  recentlyAddedTags: Tag[]
}

const ItemTagRecentlyAddedTags: React.FC<ItemTagRecentlyAddedTagsProps> = ({
  setFocus,
  focusIndex,
  activeTagIds,
  addTag,
  willOverflowScroll,
  recentlyAddedTags,
}) => {
  const moveFocus = (direction: 1 | -1) => {
    let newIndex = focusIndex + direction
    if (newIndex > recentlyAddedTags.length - 1) newIndex = 0
    if (newIndex < -1) newIndex = recentlyAddedTags.length - 1
    setFocus(recentlyAddedTags[newIndex], newIndex)
  }

  useKeypress<'ArrowDown' | 'ArrowUp'>(['ArrowDown', 'ArrowUp'], (event) => {
    const keyToDirection = {
      ArrowDown: 1,
      ArrowUp: -1,
    } as const

    moveFocus(keyToDirection[event.key])
  })

  if (recentlyAddedTags.length === 0) return null

  return (
    <RecentlyAddedTagsWrapper>
      <Text css="padding: 4px; display: flex;" size="sm" color="neutral.4">
        Recently added tags
      </Text>
      <div css={willOverflowScroll ? 'max-height:120px; overflow:scroll' : ''}>
        {recentlyAddedTags.map((recentlyAddedTag, i) => {
          const alreadyAdded = activeTagIds.includes(
            pathSafe(recentlyAddedTag.id)
          )
          return (
            <TagSuggestion
              key={recentlyAddedTag.id}
              onMouseMove={() => setFocus(recentlyAddedTag, i)}
              hasFocus={focusIndex === i}
              alreadyAdded={alreadyAdded}
              onClick={
                alreadyAdded ? undefined : () => addTag(recentlyAddedTag)
              }
            >
              <TagSuggestionText>
                {capitalizeFirstLetter(recentlyAddedTag.description)}
                {alreadyAdded && (
                  <TagSuggestionTextDetail>
                    Already added
                  </TagSuggestionTextDetail>
                )}
              </TagSuggestionText>
              {focusIndex === i && !alreadyAdded && (
                <KeyboardReturn style={{ width: 16, height: 16 }} />
              )}
            </TagSuggestion>
          )
        })}
      </div>
    </RecentlyAddedTagsWrapper>
  )
}

export default ItemTagRecentlyAddedTags

const RecentlyAddedTagsWrapper = styled.div<{ isOverflowHidden?: boolean }>`
  ${({ theme, isOverflowHidden }) => css`
    background: ${theme.colors.background[4]};
    margin-top: 8px;
    // If the component is inside the item view sider we add extra padding
    ${ItemSiderContainer} & {
      padding: 8px;
      margin-top: 0px;
    }
    ${isOverflowHidden &&
    `
    height: 120px;
    max-height: 120px;
    overflow-y: auto;
    `}
  `}
`
