import ReactProgressiveImage from 'react-progressive-image'
import styled, { css } from 'styled-components'

import Loading from 'components/Loading'
import { WorkspaceItemQuery, useWorkspaceItemQuery } from 'generated/graphql'
import { Portal } from 'helpers/Portal'

const mainMediaUrlsMapper: Record<
  'gif' | 'image',
  (
    mediaVariants: WorkspaceItemQuery['workspaceItem']['mediaVariants']
  ) => string | undefined
> = {
  image: (mediaVariants) => {
    return mediaVariants.find(({ variant }) => variant === 'large')?.url
  },
  gif: (mediaVariants) => {
    return mediaVariants.find(
      ({ variant, type }) => variant === 'large' && type === 'gif'
    )?.url
  },
}

type ItemSpoilerProps = {
  thumbUrl: string
  largeUrl?: string
  aspectRatio: number
  itemId: string
  itemVariant: 'gif' | 'image'
}

export const ItemSpoiler = (props: ItemSpoilerProps) => {
  const { data } = useWorkspaceItemQuery({
    variables: {
      workspaceItemId: props.itemId,
    },
    skip: Boolean(props.largeUrl), // if spoiler already has large url we skip the fetch
  })

  const mainMediaUrl =
    data &&
    mainMediaUrlsMapper[props.itemVariant](data.workspaceItem.mediaVariants)

  const isVertical = props.aspectRatio < 1
  const width = isVertical ? 600 : 1000
  const height = width / props.aspectRatio
  return (
    <Portal id="item-spoiler">
      {/* @ts-ignore */}
      <ReactProgressiveImage
        src={props.largeUrl || mainMediaUrl || ''}
        placeholder={props.thumbUrl}
      >
        {(src: string, loading: boolean) => {
          return (
            <>
              <StyledItemSpoiler
                style={{
                  height,
                  width: `${width}px`,
                }}
              >
                {loading && (
                  <div css="position: absolute; left: 30px; height: 100%; display: flex;">
                    <Loading />
                  </div>
                )}
                <Image src={src} />
              </StyledItemSpoiler>
              <StyledOverlay onClick={(e) => e.preventDefault()} />
            </>
          )
        }}
      </ReactProgressiveImage>
    </Portal>
  )
}

const Image = styled.img`
  object-fit: cover;
  height: auto;
  width: 100%;
  flex: 1;
  display: block;
  pointer-events: none;
`

const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #00000061;
  z-index: 399;
  pointer-events: none;
`

const StyledItemSpoiler = styled.div(
  ({ theme }) => css`
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 400;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    border-radius: ${theme.borderRadius.lg};
    box-shadow: 0px 6px 16px 5px #00000045;
    pointer-events: none;
    max-width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
  `
)
