import { ApolloQueryResult } from '@apollo/client'
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

import { BillingSubscriptionQuery } from '../../../generated/graphql'
import { wait } from '../../../helpers/utilityFunctions'
import Loading from '../../Loading'
import { ErrorMessage } from '../SettingsStyles'
import useSettingNavigation from '../useSettingsNavigation'

type Props = {
  refetchBillingSubscription: (variables?: {
    workspaceId: string
  }) => Promise<ApolloQueryResult<BillingSubscriptionQuery>>
  subscription: BillingSubscriptionQuery | undefined
}
export const CheckoutSuccess: FC<Props> = ({
  refetchBillingSubscription,
  subscription,
}) => {
  const [retries, setRetries] = useState(0)
  const { sectionUrl } = useSettingNavigation()
  const navigate = useNavigate()
  useEffect(() => {
    const asyncRefetch = async () => {
      const currentUrl = new URL(window.location.href)
      const goingToPlan = currentUrl.searchParams.get('toPlan')
      if (goingToPlan === subscription?.billingSubscription?.plan?.id) {
        const searchParamsPlans = sectionUrl('plans')
        const newSearchParams = new URLSearchParams(searchParamsPlans)
        newSearchParams.delete('session_id')
        newSearchParams.delete('toPlan')
        const newUrl = new URL(`${currentUrl.origin}${currentUrl.pathname}`)
        newUrl.search = newSearchParams.toString()
        window.location.assign(newUrl)
      } else {
        await wait(3000)
        await refetchBillingSubscription()
        if (retries < 10) setRetries(retries + 1)
      }
    }
    asyncRefetch()
  }, [refetchBillingSubscription, navigate, sectionUrl, subscription, retries])
  return (
    <>
      {retries <= 10 ? <Loading /> : null}
      {retries > 10 ? (
        <ErrorMessage>
          <p>A problem occurred!</p>
          <p>
            Please contact support and reference{' '}
            {new URL(window.location.href).searchParams.get('session_id')}
          </p>
        </ErrorMessage>
      ) : null}
    </>
  )
}
