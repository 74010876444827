import { ReduxFirestoreQuerySetting } from 'react-redux-firebase'

import { firebase } from 'config/firebase'
import { capitalizeFirstLetter } from 'helpers/formatting'
import { WorkspaceAccessLevel } from 'types/db'

type WhereArray = [string, firebase.firestore.WhereFilterOp, any]

export function getWhereUserHasAccess(
  uid: string,
  access: WorkspaceAccessLevel
) {
  // return ['createdBy', '==', userId]
  // TODO: Update firestore so that this is possible
  const where: WhereArray = [
    `aggregated${capitalizeFirstLetter(access)}Access.${uid}`,
    '==',
    true,
  ]

  return where
}

export function getMostRecentBoardQueries(
  workspaceId: string,
  userEmail: string
) {
  if (!userEmail || !workspaceId) {
    return []
  }

  const queries: ReduxFirestoreQuerySetting[] = [
    {
      collection: 'boards',
      where: [['workspaceId', '==', workspaceId]],
      orderBy: ['updatedAt', 'desc'],
      limit: 1,
      storeAs: 'mostRecentBoard',
    },
  ]

  return queries
}
