import { UploadCloud as UploadIcon } from '@styled-icons/feather/UploadCloud'
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion'
import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { StringParam, useQueryParams, withDefault } from 'use-query-params'
import { v4 as uuid } from 'uuid'

import { BetaBadgeLarge } from 'components/common/BetaBadge'
import Button from 'components/common/Button'
import { Input as CommonInput } from 'components/common/Form'
import { Indented } from 'components/common/Indented'
import { Margin } from 'components/common/Margin'
import PermissionWrapper from 'components/common/PermissionWrapper'
import Spinner from 'components/common/Spinner'
import { Text } from 'components/common/Text'
import ToSwitch from 'components/common/ToSwitch'
import ItemView from 'components/item/ItemView'
import SearchCreation from 'components/search/SearchCreation'
import useToastMessages from 'components/toast/useToastMessages'
import useUpgradeNavigation from 'components/upgrade/useUpgradeNavigation'
import { CustomFile } from 'components/upload/types'
import useUploadSessions from 'components/upload/useUploadSessions'
import { useUploadSessionsRemoteUpload } from 'components/upload/useUploadSessionsRemoteUpload'
import { useWorkspacePlan } from 'components/upload/useUploadWorkspaces'
import { useUserTasks } from 'features/home/setup-tasks-row/useUserTasks'
import callCloudFunction from 'helpers/callCloudFunction'
import { reportError as logError } from 'helpers/logging'
import {
  trackUploadPlanQuotaHit,
  trackVideoExtractionQuotaHit,
  trackVideoExtractionVideoInvalidated,
  trackVideoExtractionVideoSelected,
  trackVideoLinkExtractionFailed,
  trackVideoLinkExtractionStarted,
} from 'helpers/tracking/tracking'
import { useActiveWorkspace } from 'hooks/useActiveWorkspace'
import useIsMobile from 'hooks/useIsMobile'
import useWorkspacePermissions from 'hooks/useWorkspacePermissions'
import { RootState } from 'store'

import FrameExtractionSuggestions from './FrameExtractionSuggestions'
import VimeoVideoPreview from './VideoLinkPreview'
import VimeoUsernameForm from './VimeoUsernameForm'
import { getVideoDuration } from './getVideoDuration'
import { startVideoFrameExtractionSession } from './startVideoFrameExtractionSession'

const MAX_BYTES_LIMIT = 500000000 // 500 mb
const MAX_DURATION_LIMIT = 480 // 8 min
const MAX_DURATION_LIMIT_GIFS = 180 // 3 min
const MAX_SELECTED_FILES = 1
const SUPPORTED_VIDEO_FORMATS = ['mp4', 'quicktime']

type ValidationStatus =
  | 'MAX_BYTES_LIMIT_REACHED'
  | 'MAX_DURATION_LIMIT_REACHED'
  | 'MAX_GIFS_DURATION_LIMIT_REACHED'
  | 'MAX_SELECTED_FILES_REACHED'
  | 'UNSUPPORTED_VIDEO_FORMAT'
  | 'UNKNOWN'

const validationStatusTexts: Record<ValidationStatus, string> = {
  MAX_SELECTED_FILES_REACHED: 'You can only upload 1 video at a time',
  MAX_DURATION_LIMIT_REACHED: `Video duration limited to ${
    MAX_DURATION_LIMIT / 60
  } minutes`,
  MAX_GIFS_DURATION_LIMIT_REACHED: `Video duration for gif extraction is limited to ${
    MAX_DURATION_LIMIT_GIFS / 60
  } minutes`,
  MAX_BYTES_LIMIT_REACHED: `Video size limited to ${
    MAX_BYTES_LIMIT / 1000000
  } MB`,
  UNSUPPORTED_VIDEO_FORMAT: `The video has an unsupported format. Right now we only support ${SUPPORTED_VIDEO_FORMATS.join(
    ', '
  )} videos`,
  UNKNOWN: `We couldn't upload the selected video, try another one.`,
}

const trackValidationStatus = (
  validationStatus: ValidationStatus,
  file: CustomFile | undefined,
  workspaceId: string
) => {
  trackVideoExtractionVideoInvalidated({
    workspaceId,
    size: file?.size,
    name: file?.name,
    type: file?.type,
    validationStatus,
  })
}

const getVimeoVideoId = (url: string) => {
  const match = url.match(
    /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_-]+)?/i
  )
  return match?.[1]
}

const getYoutubeVideoId = (url: string) => {
  const regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  const match = url.match(regExp)
  return match?.[7]
}

const extractModeToItemTypeMapping: Record<string, 'image' | 'gif'> = {
  frames: 'image',
  gifs: 'gif',
}

interface Props {
  isInSetup?: boolean
  onSessionStarted?: () => void
}

const VideoFrameExtractor: React.FC<Props> = ({
  isInSetup,
  onSessionStarted,
}) => {
  const { completeUserTask } = useUserTasks()
  const isMobile = useIsMobile()
  const currentUserId = useSelector(
    (state: RootState) => state.firebase.auth.uid
  )
  const currentUserEmail = useSelector(
    (state: RootState) => state.firebase.auth.email!
  )
  const activeWorkspace = useActiveWorkspace()!
  const activeWorkspaceId = activeWorkspace.id

  const currentUser = useSelector(
    (state: RootState) => state.firestore.data.users?.[currentUserId]
  )

  const [urlQueryParams, setQueryParams] = useQueryParams({
    item: StringParam,
    mode: withDefault(StringParam, 'frames'),
  })

  const { item, mode } = urlQueryParams
  const itemType = extractModeToItemTypeMapping[mode]

  const isWorkspaceAdmin =
    'adminEmails' in activeWorkspace
      ? activeWorkspace.adminEmails.includes(currentUserEmail)
      : activeWorkspace.admins.includes(currentUserEmail)

  const [vimeoVideoId, setVimeoVideoId] = useState('')
  const [vimeoCustomLink, setVimeoCustomLink] = useState('')
  const [youtubeVideoId, setYoutubeVideoId] = useState('')
  const [videoLink, setVideoLink] = useState('')
  const [isSubmittingVimeoUsername, setIsSubmittingVimeoUsername] =
    useState(false)

  const [isValidating, setIsValidating] = useState(false)
  const [validationStatus, setValidationStatus] =
    useState<ValidationStatus | null>(null)

  const { reportError } = useToastMessages()

  const { addRemoteUploadLocalState, removeRemoteUploadLocalState } =
    useUploadSessionsRemoteUpload()

  const { updateSession } = useUploadSessions()
  const { remainingFrameExtractions, remainingUploads, workspacePlan } =
    useWorkspacePlan(activeWorkspaceId)
  const { open: openUpgradePanel } = useUpgradeNavigation()

  const userIsAllowedTo = useWorkspacePermissions(['UPLOAD'])

  const validateVideo = async (files: CustomFile[]) => {
    try {
      if (files.length === 0) return
      if (files.length > MAX_SELECTED_FILES) {
        trackValidationStatus(
          'MAX_SELECTED_FILES_REACHED',
          files[0],
          activeWorkspaceId
        )
        return setValidationStatus('MAX_SELECTED_FILES_REACHED')
      }

      const videoFile = files[0]

      if (
        !SUPPORTED_VIDEO_FORMATS.includes(videoFile.type.replace('video/', ''))
      ) {
        trackValidationStatus(
          'UNSUPPORTED_VIDEO_FORMAT',
          videoFile,
          activeWorkspaceId
        )
        return setValidationStatus('UNSUPPORTED_VIDEO_FORMAT')
      }

      const duration = await getVideoDuration(videoFile)

      if (itemType === 'gif' && duration > MAX_DURATION_LIMIT_GIFS) {
        trackValidationStatus(
          'MAX_GIFS_DURATION_LIMIT_REACHED',
          videoFile,
          activeWorkspaceId
        )
        return setValidationStatus('MAX_GIFS_DURATION_LIMIT_REACHED')
      }
      if (itemType === 'image' && duration > MAX_DURATION_LIMIT) {
        trackValidationStatus(
          'MAX_DURATION_LIMIT_REACHED',
          videoFile,
          activeWorkspaceId
        )
        return setValidationStatus('MAX_DURATION_LIMIT_REACHED')
      }

      if (videoFile.size > MAX_BYTES_LIMIT) {
        trackValidationStatus(
          'MAX_BYTES_LIMIT_REACHED',
          videoFile,
          activeWorkspaceId
        )
        return setValidationStatus('MAX_BYTES_LIMIT_REACHED')
      }

      return true
    } catch (error) {
      trackValidationStatus('UNKNOWN', files[0], activeWorkspaceId)
      logError(error)
      return setValidationStatus('UNKNOWN')
    }
  }

  const onDrop = async (files: CustomFile[]) => {
    setValidationStatus(null)
    setIsValidating(true)

    const videoFile = files[0]

    trackVideoExtractionVideoSelected({
      workspaceId: activeWorkspaceId,
      size: videoFile.size,
      name: videoFile.name,
      type: videoFile.type,
    })

    const [isValid] = await Promise.all([
      validateVideo(files),
      new Promise((resolve) => setTimeout(resolve, 2000)), // we wait minimum 2 seconds for smoother experience
    ])
    setIsValidating(false)
    const videoName = videoFile.name.split('.')[0]
    if (isValid) {
      completeUserTask('extractFrames')
      onSessionStarted?.()
      startVideoFrameExtractionSession({
        videoName,
        videoFile,
        workspaceId: activeWorkspaceId,
        currentUserId,
        updateLocalSession: updateSession,
        frameExtractionItemType: itemType,
      }).catch((error) => {
        logError(error)
        setValidationStatus('UNKNOWN')
      })
    }
  }
  const { getInputProps, open } = useDropzone({
    onDrop,
    accept: {
      'video/*': [],
    },
  })

  const validateUserAndWorkspace = () => {
    // For the Pro plan we also check the remaining item uplaods
    const uploadQuotaCriteria =
      workspacePlan?.presentation.name === 'Pro' && remainingUploads <= 0

    if (itemType === 'gif' && !workspacePlan?.limits.gifExtraction) {
      openUpgradePanel('gifExtraction')
      return false
    }

    const frameExtractionQuotaCriteria = remainingFrameExtractions <= 0
    if (uploadQuotaCriteria) {
      trackUploadPlanQuotaHit({
        uploadType: 'frameExtract',
        nbrOfItemsLeftOnPlan: remainingUploads,
        workspacePlanName: workspacePlan?.presentation?.name ?? null,
      })
      if (frameExtractionQuotaCriteria) {
        trackVideoExtractionQuotaHit({
          workspaceId: activeWorkspaceId,
          remainingUploads,
          remainingFrameExtractions,
        })
      }
      openUpgradePanel('items')
      return false
    }
    if (frameExtractionQuotaCriteria) {
      trackVideoExtractionQuotaHit({
        workspaceId: activeWorkspaceId,
        remainingUploads,
        remainingFrameExtractions,
      })
      openUpgradePanel('frameExtraction')
      return false
    }
    return true
  }

  const handleUploadVideoClick = () => {
    if (validateUserAndWorkspace()) {
      open()
    }
  }

  const updateVideoLink = (link: string) => {
    setVideoLink(link)
    setYoutubeVideoId(getYoutubeVideoId(link) || '')

    const vimeoId = getVimeoVideoId(link)
    const customVimeoLink = link.startsWith('https://vimeo.com/')

    setVimeoVideoId(vimeoId || '')

    if (!vimeoId && customVimeoLink) {
      setVimeoCustomLink(link)
    } else {
      setVimeoCustomLink('')
    }
  }

  const handleVideoLinkChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (validationStatus) {
      setValidationStatus(null)
    }
    updateVideoLink(event.target.value)
  }

  const extractFramesFromUrl = async (url: string) => {
    const localClientId = uuid() // used to temporary glue together the upload with local client uploading progress
    try {
      completeUserTask('extractFrames')

      addRemoteUploadLocalState(localClientId, 'Validating video link...')
      onSessionStarted?.()

      // status: UNSUPPORTED_URL | ALREADY_EXTRACTED | MAX_DURATION_LIMIT | SUCCESS
      const { status } = await callCloudFunction('extractFramesFromUrl', {
        url,
        workspaceId: activeWorkspaceId,
        localClientId,
        itemType,
      })

      if (status !== 'SUCCESS') {
        removeRemoteUploadLocalState(localClientId)
        trackVideoLinkExtractionFailed({
          workspaceId: activeWorkspaceId,
          url,
          reason: status,
        })
      }
      if (status === 'UNSUPPORTED_URL') {
        return reportError(
          `The URL is not supported. Try another one!`,
          undefined,
          10000
        )
      }
      if (status === 'ALREADY_EXTRACTED') {
        return reportError(
          `We have already extracted frames from the given URL. Try another one!`,
          undefined,
          10000
        )
      }
      if (status === 'MAX_DURATION_LIMIT_GIFS') {
        return reportError(
          `The video is too long. We have a duration limit of ${
            MAX_DURATION_LIMIT_GIFS / 60
          } minutes for gifs extraction.`,
          undefined,
          10000
        )
      }
      if (status === 'MAX_DURATION_LIMIT') {
        return reportError(
          `The video is too long. We have a duration limit of ${
            MAX_DURATION_LIMIT / 60
          } minutes.`,
          undefined,
          10000
        )
      }
      if (status === 'NO_FRAMES_FOUND') {
        return reportError(`Unable to extract frames`, undefined, 10000)
      }
    } catch (error) {
      trackVideoLinkExtractionFailed({
        workspaceId: activeWorkspaceId,
        url,
        reason: 'UNKNOWN',
      })
      removeRemoteUploadLocalState(localClientId)
      reportError(
        'Something strange happened while extracing from URL 👎 Try again...',
        new Error(
          `Error when extracting frames from URL. ErrorMsg:${error.message}`
        ),
        5000
      )
    }
  }

  const validateAndExtractLink = (
    link: string,
    linkIsAlreadyValidated = false,
    fromSuggestions = false
  ) => {
    if (!userIsAllowedTo.UPLOAD) {
      reportError(
        `You don't have permission to make uploads to this workspace.`,
        undefined,
        10000
      )
      return false
    }

    trackVideoLinkExtractionStarted({
      workspaceId: activeWorkspaceId,
      url: link,
      fromSuggestions,
    })

    if (
      getVimeoVideoId(link) ||
      getYoutubeVideoId(link) ||
      linkIsAlreadyValidated
    ) {
      if (validateUserAndWorkspace()) {
        extractFramesFromUrl(link)
        return true
      }
      return false
    }
    trackVideoLinkExtractionFailed({
      workspaceId: activeWorkspaceId,
      url: link,
      reason: 'UNSUPPORTED_URL',
    })
    reportError(`The URL is not supported. Try another one!`, undefined, 10000)
    return false
  }

  const handleVideoLinkSubmit = async (
    link?: string, // the be able to support custom vimeo links (without vimeo id inside)
    linkIsAlreadyValidated?: boolean
  ) => {
    const ok = validateAndExtractLink(link || videoLink, linkIsAlreadyValidated)
    if (ok) {
      updateVideoLink('')
    }
  }

  const handleOnSuggestionClick = (link: string) => {
    return validateAndExtractLink(link, true, true)
  }

  const hasUrlId = vimeoVideoId || youtubeVideoId || vimeoCustomLink

  return (
    <>
      {!isInSetup && (
        <>
          <SearchCreation
            placeholder={
              isMobile
                ? 'Search workspace items'
                : 'Type to search for items in your workspace'
            }
            shouldDisplayNotifications
          />
          {item && <ItemView />}
        </>
      )}
      <StyledView isInSetup={isInSetup}>
        <LayoutGroup>
          <VideoFrameExtractorContainer layout>
            <motion.div layout css="position:relative">
              <input {...getInputProps()} />
              <div css="display:flex;justify-content:space-between;align-items: baseline;">
                <Text size="lg" color="neutral.0">
                  Extract from video
                </Text>
                <BetaBadgeLarge />
              </div>
              <Margin y={16} />
              <Text size="sm" color="neutral.2">
                Select a short video and automatically extract frames from each
                shot, or entire shots as gifs, and add them to your library.
              </Text>
              <Margin y={16} />
              <Indented>
                {!isInSetup &&
                  remainingFrameExtractions > 0 &&
                  workspacePlan?.presentation.name === 'Free' && (
                    <Text size="sm" color="neutral.2">
                      Your current plan gives you 5 extracts per month. You
                      still have frame extracts left this month
                    </Text>
                  )}
                {remainingFrameExtractions <= 0 &&
                  workspacePlan?.presentation.name === 'Free' &&
                  isWorkspaceAdmin && (
                    <Text size="sm" color="neutral.1">
                      You have reached your plan’s monthly limit for frame
                      extracts. Wait until next month to get a free refill or{' '}
                      <Link color="accent.2" to="?settingsPanel=plans">
                        upgrade now
                      </Link>{' '}
                      to unlock unlimited frame extracts 🎞
                    </Text>
                  )}
                {remainingFrameExtractions <= 0 &&
                  workspacePlan?.presentation.name === 'Free' &&
                  !isWorkspaceAdmin && (
                    <Text size="sm" color="neutral.2">
                      You have reached your plan’s monthly limit for frame
                      extracts. Contact your workspace admin(s) and ask them to
                      upgrade now to unlock unlimited frame extracts 🎞
                    </Text>
                  )}
              </Indented>
              <Margin y={24} />
              <div>
                <Text size="sm" color="neutral.1">
                  Extract
                </Text>
                <Margin y={6} />
                <ToSwitch
                  css="height: 32px; width: 130px"
                  defaultActive={mode === 'gifs' ? 1 : 0}
                  onSwitch={({ id }: { id: 'frames' | 'gifs' }) =>
                    setQueryParams({
                      mode: id,
                    })
                  }
                  mode="searchPath"
                  links={[
                    {
                      text: 'Frames',
                      id: 'frames',
                    },
                    {
                      text: 'Gifs',
                      id: 'gifs',
                    },
                  ]}
                />
              </div>
              <Margin y={18} />
              <StyledImportOptions>
                {!hasUrlId && (
                  <PermissionWrapper
                    css="margin-right:12px;max-width:50%;"
                    feature="Extract from video"
                    isAllowed={Boolean(userIsAllowedTo.UPLOAD)}
                  >
                    {(isAllowed) => (
                      <Button
                        disabled={!isAllowed || isValidating}
                        variant="primary"
                        Icon={UploadIcon}
                        onClick={handleUploadVideoClick}
                      >
                        Upload video
                      </Button>
                    )}
                  </PermissionWrapper>
                )}
                <StyledLinkForm
                  layout
                  onSubmit={(e) => {
                    e.preventDefault()
                    if (vimeoCustomLink) return // vimeo custom links are only supported through the VimeoVideoPreview component
                    handleVideoLinkSubmit()
                  }}
                >
                  <Input
                    placeholder="Or paste a Vimeo or Youtube link"
                    onChange={handleVideoLinkChange}
                    value={videoLink}
                    autoFocus={!isMobile}
                    css="height:44px"
                  />
                </StyledLinkForm>
              </StyledImportOptions>
            </motion.div>
            <motion.div layout>
              <AnimatePresence initial={false} exitBeforeEnter>
                {isValidating && (
                  <AnimatedWrapper key="isValidating">
                    <Text
                      size="base"
                      color="accent.3"
                      css="display: flex;align-items: center;"
                    >
                      <Spinner height="18px" css="margin-right: 8px" />
                      Validating your video...
                    </Text>
                  </AnimatedWrapper>
                )}
                {!isValidating && validationStatus && (
                  <AnimatedWrapper key="validationStatus">
                    <Text
                      size="base"
                      color="accent.0"
                      css="text-align: center;"
                    >
                      ⚠️ {validationStatusTexts[validationStatus]}
                    </Text>
                  </AnimatedWrapper>
                )}
              </AnimatePresence>
            </motion.div>
            <Margin y={16} />
            {hasUrlId && (
              <motion.div layout>
                <>
                  <VimeoVideoPreview
                    vimeoVideoId={vimeoVideoId}
                    youtubeVideoId={youtubeVideoId}
                    vimeoCustomLink={vimeoCustomLink}
                    onExtract={handleVideoLinkSubmit}
                  />
                </>
              </motion.div>
            )}
            {userIsAllowedTo.UPLOAD && !hasUrlId && (
              <>
                <Margin y={16} />
                {currentUser?.vimeoUserId && !isSubmittingVimeoUsername ? (
                  <FrameExtractionSuggestions
                    onSuggestionClick={handleOnSuggestionClick}
                  />
                ) : (
                  <VimeoUsernameForm
                    onSubmitStarted={() => setIsSubmittingVimeoUsername(true)}
                    onSubmitEnded={() => setIsSubmittingVimeoUsername(false)}
                  />
                )}
              </>
            )}
          </VideoFrameExtractorContainer>
        </LayoutGroup>
      </StyledView>
    </>
  )
}

export default VideoFrameExtractor

const StyledView = styled.div<{ isInSetup?: boolean }>`
  ${({ theme, isInSetup }) => css`
    position: relative;
    ${!isInSetup &&
    css`
      height: calc(100vh - 60px);
      overflow: auto;
      padding-top: 100px;
      @media screen and (max-width: ${theme.breakpoints.md}px) {
        padding-top: 10px;
        max-width: calc(100vw - 60px);
      }
    `}
  `}
`

const VideoFrameExtractorContainer = styled(motion.div)`
  ${({ theme }) => css`
    border: ${`${theme.border.thin} ${theme.colors.discreet}`};
    box-shadow: ${theme.shadow.minimal};
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 600px;
    margin: 0 auto;
    padding: 32px;
    background: ${theme.colors.background[3]};
    border-radius: ${theme.borderRadius.default};
    @media screen and (max-width: ${theme.breakpoints.sm}px) {
      width: 100%;
      padding: 32px 16px;
    }
  `}
`

const StyledImportOptions = styled.div`
  ${({ theme }) => css`
    display: flex;
    @media screen and (max-width: ${theme.breakpoints.sm}px) {
      flex-direction: column;
    }
  `}
`

const StyledLinkForm = styled(motion.form)`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    @media screen and (max-width: ${theme.breakpoints.sm}px) {
      margin-top: 18px;
    }
  `}
`

const Input = styled(CommonInput)`
  background-color: ${(props) => props.theme.colors.background[5]};
  border-color: transparent;
  ::placeholder {
    color: ${(props) => props.theme.colors.text.neutral[2]};
    opacity: 0.7;
  }
`

const AnimatedWrapper = styled(motion.div).attrs({
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 20 },
})`
  height: 45px;
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`
