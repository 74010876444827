import {
  ApolloClient,
  FieldFunctionOptions,
  FieldMergeFunction,
  FieldReadFunction,
  InMemoryCache,
  concat,
  split,
} from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { setContext } from '@apollo/client/link/context'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import { createUploadLink } from 'apollo-upload-client'
import { createClient } from 'graphql-ws'

import { firebaseAuth } from 'config/firebase'

const GRAPHQL_URL = process.env.REACT_APP_GRAPHQL_URL as string

if (!GRAPHQL_URL) {
  console.error('Missing GraphQL URL')
}

type PaginationMergeArgs = {
  field: string
}

const paginationMerge = ({ field }: PaginationMergeArgs) => {
  const merge: FieldMergeFunction<
    any,
    any,
    FieldFunctionOptions<Record<string, any>, Record<string, any>>
  > = (existing = {}, incoming: any, { readField, args }) => {
    const { pageInfo, [field]: dynamicField, ...rest } = incoming
    const newCacheInput = { ...existing }
    newCacheInput.pageInfo = { ...incoming.pageInfo }
    newCacheInput[field] = {
      ...(args?.input.cursor ? newCacheInput[field] : {}),
    }
    incoming[field].forEach((refData: any) => {
      const id = readField('id', refData) as string
      newCacheInput[field][id] = refData
    })
    return { ...newCacheInput, ...rest }
  }
  return merge
}

type PaginationReadeArgs = {
  field: string
}

const paginationRead = ({ field }: PaginationReadeArgs) => {
  const merge: FieldReadFunction<
    any,
    any,
    FieldFunctionOptions<Record<string, any>, Record<string, any>>
  > = (existing) => {
    if (!existing) return {}
    return {
      ...existing,
      [field]: Object.values(existing[field]),
    }
  }
  return merge
}

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        itemComments: {
          keyArgs: (args) => {
            return args?.input.parentId
          },
        },
        itemReactions: {
          keyArgs: (args) => {
            return args?.input.parentId
          },
          merge: false,
        },
        boards: {
          keyArgs: (args) => {
            return args?.input.projectId
          },
        },
        workspaceItems: {
          keyArgs: (args) => {
            return args?.input.workspaceId
          },
          merge: paginationMerge({ field: 'workspaceItems' }),
          read: paginationRead({ field: 'workspaceItems' }),
        },
        similarWorkspaceItems: {
          keyArgs: (args) => {
            return args?.input.workspaceItemId + args?.input.scope
          },
          merge: paginationMerge({ field: 'similarItems' }),
          read: paginationRead({ field: 'similarItems' }),
        },
        uploadSessionItems: {
          keyArgs: (args) => {
            return args?.input.uploadSessionId
          },
          merge: paginationMerge({ field: 'uploadSessionItems' }),
          read: paginationRead({ field: 'uploadSessionItems' }),
        },
        magicBoardItems: {
          keyArgs: (args) => {
            return `${args?.input.workspaceId}:${args?.input.magicBoardId}`
          },
          merge: paginationMerge({ field: 'magicBoardItems' }),
          read: paginationRead({ field: 'magicBoardItems' }),
        },
        searchWorkspaceItems: {
          keyArgs: (args) => {
            const { first, cursor, ...searchIdObject } = args?.input ?? {}
            return JSON.stringify(searchIdObject)
          },
          merge: paginationMerge({ field: 'workspaceItems' }),
          read: paginationRead({ field: 'workspaceItems' }),
        },
        // Inspo: https://www.apollographql.com/docs/react/pagination/cursor-based/#keeping-cursors-separate-from-items
        magicBoards: {
          keyArgs: (args) => {
            if (args?.input.uploadSessionId) {
              return `${args?.input.workspaceId}:${args?.input.uploadSessionId}`
            }
            if (args?.input.freeTextSearch) {
              return `${args?.input.workspaceId}:${args?.input.freeTextSearch}`
            }
            return args?.input.workspaceId
          },
          merge: paginationMerge({ field: 'magicBoards' }),
          read: paginationRead({ field: 'magicBoards' }),
        },
        publicWorkspaceItemsByUser: {
          keyArgs: (args) => {
            return args?.input.userId
          },
          merge: paginationMerge({ field: 'workspaceItems' }),
          read: paginationRead({ field: 'workspaceItems' }),
        },
        discoverNewItems: {
          merge: paginationMerge({ field: 'items' }),
          read: paginationRead({ field: 'items' }),
        },
        discoverItemsForYou: {
          merge: paginationMerge({ field: 'items' }),
          read: paginationRead({ field: 'items' }),
        },
        publicBoards: {
          merge: paginationMerge({ field: 'boards' }),
          read: paginationRead({ field: 'boards' }),
        },
        discoverIdeasForBoard: {
          keyArgs: (args) => {
            return args?.input.boardId
          },
          merge: paginationMerge({ field: 'items' }),
          read: paginationRead({ field: 'items' }),
        },
      },
    },
  },
})

const httpLinkWithUploads = createUploadLink({
  uri: GRAPHQL_URL,
  headers: { 'Apollo-Require-Preflight': 'true' },
})
const batchHttpLink = new BatchHttpLink({ uri: GRAPHQL_URL, batchMax: 20 })

const getGuestSessionInfo = () => {
  const displayName = localStorage.getItem('GUEST_DISPLAY_NAME')
  const id = localStorage.getItem('GUEST_UID')
  // our useLocalStorage hook that is used to save the displayName stringifies all values which makes use have to parse the value
  const guestDisplayName = displayName ? JSON.parse(displayName) : displayName
  const guestId = id ? JSON.parse(id) : id
  return {
    guestId,
    guestDisplayName,
  }
}

const authMiddleware = setContext(async (_, { headers: orgHeaders }) => {
  const headers = { ...orgHeaders }
  const idToken = await firebaseAuth().currentUser?.getIdToken()

  if (idToken) {
    headers.authorization = `Bearer ${idToken}`
  } else {
    headers['guest-session'] = JSON.stringify(getGuestSessionInfo())
  }

  headers['x-tracking-context'] = JSON.stringify({
    platform: 'web',
    url: window.location.href,
  })

  return {
    headers,
  }
})

const getWsServerUri = () => {
  const parsedUrl = new URL(GRAPHQL_URL)
  parsedUrl.protocol = parsedUrl.protocol === 'https:' ? 'wss:' : 'ws:'
  return parsedUrl.toString()
}

const wsLink = new GraphQLWsLink(
  createClient({
    url: getWsServerUri(),
    lazy: true,
    shouldRetry: () => {
      return true
    },
    connectionParams: async () => {
      const token = await firebaseAuth().currentUser?.getIdToken()
      if (token) {
        return {
          Authorization: `Bearer ${token}`,
        }
      }
      return {
        'guest-session': encodeURIComponent(
          JSON.stringify(getGuestSessionInfo())
        ),
      }
    },
  })
)

const terminatingLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  concat(
    authMiddleware,
    split(
      ({ getContext }) => {
        return Boolean(getContext().batch)
      },
      batchHttpLink,
      httpLinkWithUploads as any
    )
  )
)

const client = new ApolloClient({
  cache,
  link: terminatingLink,
})

export { client }
