import React from 'react'
import styled, { DefaultTheme, css } from 'styled-components'

const DEFAULT_GAP = 16

const FlexGridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

interface Breakpoint {
  breakpoint: keyof DefaultTheme['breakpoints']
  nColumns: number
}

const getWidthString = (
  theme: DefaultTheme,
  breakpoint: Breakpoint,
  breakpoints: Breakpoint[],
  i: number
) => {
  const currentBreakpoint = theme.breakpoints[breakpoint.breakpoint]
  const nextBreakpoint =
    breakpoints[i + 1] && theme.breakpoints[breakpoints[i + 1].breakpoint] - 1

  return `(min-width: ${currentBreakpoint}px) ${
    nextBreakpoint ? `and (max-width: ${nextBreakpoint}px)` : ''
  }
  `
}

const getCardWidth = (breakpoint: Breakpoint, gap?: number) => {
  const nGaps = breakpoint.nColumns - 1
  const gapSize = gap || DEFAULT_GAP

  return `calc(${100 / breakpoint.nColumns}% - ${
    // Let the total gap size evenly affect the width all columns
    (gapSize * nGaps) / breakpoint.nColumns
  }px)
          `
}

const Card = styled.div<{
  breakpoints?: Breakpoint[]
  gap?: number // px
}>`
  ${({ theme, breakpoints, gap }) => css`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${gap || DEFAULT_GAP}px;
    margin-right: ${gap || DEFAULT_GAP}px;

    ${breakpoints?.map(
      (breakpoint, i) => css`
        @media ${getWidthString(theme, breakpoint, breakpoints, i)} {
          width: ${getCardWidth(breakpoint, gap)};
          &:nth-child(${breakpoint.nColumns}n) {
            margin-right: 0;
          }
        }
      `
    )}
  `}
`

interface FlexGridProps {
  breakpoints: Breakpoint[]
  gap?: number
  children: React.ReactNode
}

const FlexGrid: React.FC<FlexGridProps> & { Card: typeof Card } = ({
  breakpoints,
  gap,
  children,
}) => (
  <FlexGridContainer>
    {React.Children.map(children, (child) => {
      if (!React.isValidElement(child)) {
        return child
      }
      return React.cloneElement(child, { breakpoints, gap })
    })}
  </FlexGridContainer>
)

FlexGrid.Card = Card

export default FlexGrid
