import { ArrowLeftShort } from '@styled-icons/bootstrap/ArrowLeftShort'
import { Lock } from '@styled-icons/feather/Lock'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'

import Button from 'components/common/Button'
import { Margin } from 'components/common/Margin'
import Spinner from 'components/common/Spinner'
import { Text } from 'components/common/Text'
import {
  useJoinWorkspaceMutation,
  useWorkspacePublicByUrlQuery,
} from 'generated/graphql'

import {
  WorkpaceThumb,
  WorkspaceCardWrapper,
  WorkspaceIconImage,
} from './JoinWorkspaces'

const JoinWorkspace = () => {
  const params = useParams() as { workspaceUrl: string }
  const navigate = useNavigate()
  const {
    data,
    loading: queryIsLoading,
    error: errorFromQuery,
  } = useWorkspacePublicByUrlQuery({
    variables: { url: params.workspaceUrl },
    fetchPolicy: 'cache-and-network',
  })

  const [joinWorkspaceMutation, { loading: mutationIsLoading }] =
    useJoinWorkspaceMutation()

  const joinWorkspace = async (workspaceId: string) => {
    const { data: mutationResponseData } = await joinWorkspaceMutation({
      variables: { id: workspaceId },
    })
    navigate(`/${mutationResponseData?.joinWorkspace.url}`)
  }

  const navigateToHome = () => {
    navigate('/')
  }

  if (queryIsLoading) {
    return (
      <JoinWorkspaceWrapper>
        <Spinner height="40px" color="white" />
      </JoinWorkspaceWrapper>
    )
  }

  if (errorFromQuery) {
    return (
      <JoinWorkspaceWrapper>
        <Text size="lg" color="neutral.0">
          🧐 We could not find the workspace you were looking for
        </Text>
        <Margin y={10} />
        <Button variant="primary" onClick={navigateToHome}>
          Go back to my workspaces
        </Button>
      </JoinWorkspaceWrapper>
    )
  }

  const workspace = data!.workspacePublicByUrl

  const {
    title,
    id,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    __typename,
    iconUrl,
  } = workspace
  const allowedToJoin = __typename === 'Workspace'
  return (
    <JoinWorkspaceWrapper>
      <ArrowLeftShort
        css="height:45px; padding:5px; cursor: pointer;position: absolute; left: 5px; top: 5px;"
        color="#868686"
        onClick={navigateToHome}
      />
      <JoinWorkspaceContainer>
        <WorkspaceCardWrapper>
          <div css="display:flex; align-items:center">
            {iconUrl && <WorkspaceIconImage src={iconUrl} />}
            <Text
              color="neutral.0"
              size="lg"
              bold
              css="white-space: nowrap; text-overflow: ellipsis; overflow: hidden"
            >
              {title}
            </Text>
          </div>
          <Margin y={18} />
          {workspace.__typename === 'Workspace' &&
            workspace.thumbUrls.length > 0 && (
              <>
                <WorkpaceThumb thumbUrls={workspace.thumbUrls} />
                <Margin y={18} />
              </>
            )}
          {allowedToJoin ? (
            <Text color="neutral.1" size="base">
              You can join this workspace!
            </Text>
          ) : (
            <Text
              color="neutral.2"
              size="base"
              css="display:flex; align-items: center; line-height: 17px;"
            >
              This is a private workspace
              <Lock css="height:22px;margin-left:8px" />
            </Text>
          )}
          <Margin y={18} />
          {allowedToJoin ? (
            <Button
              css="width: 160px;"
              isLoading={mutationIsLoading}
              disabled={mutationIsLoading}
              variant="primary"
              onClick={() => joinWorkspace(id)}
            >
              Join workspace
            </Button>
          ) : (
            <Button
              variant="primary"
              onClick={navigateToHome}
              css="width: 200px;"
            >
              Go back to my workspaces
            </Button>
          )}
        </WorkspaceCardWrapper>
      </JoinWorkspaceContainer>
    </JoinWorkspaceWrapper>
  )
}

export default JoinWorkspace

const JoinWorkspaceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

const JoinWorkspaceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
