import styled from 'styled-components'

const Heading = styled.div(({ theme, style }) => ({
  fontSize: theme.fontSizes.sm,
  ...style,
}))

const HeadingLarge = styled.div(({ theme, style }) => ({
  fontWeight: theme.fontWeight.normal,
  fontSize: theme.fontSizes.lg,
  ...style,
}))

const Box = styled.div(({ theme }) => ({
  width: '100%',
  textAlign: 'center',
  fontSize: theme.fontSizes.lg,
  color: theme.colors.text.neutral[0],
  background: theme.colors.background[1],
  fontWeight: theme.fontWeight.normal,
  padding: 16,
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  zIndex: 10000000000000,
  bottom: 0,
  borderTop: `${theme.border.thin} ${theme.colors.discreet}`,
}))

export { Box, Heading, HeadingLarge }
