import { motion } from 'framer-motion'
import { Link, useLocation } from 'react-router-dom'
import styled, { css } from 'styled-components'

import Flex from 'components/common/Flex'
import { Spacer } from 'components/common/Spacer'
import { Text } from 'components/common/Text'
import UserAvatar from 'components/common/UserAvatar'
import { User } from 'generated/graphql'

const StyledLink = styled(Link)(
  ({ theme }) => css`
    color: ${theme.colors.text.neutral[0]};
    transition: 0.1s color ease;
    display: flex;
    align-items: center;
  `
)

const transitions = {
  inital: {
    opacity: 0.6,
  },
  hover: {
    opacity: 1,
  },
}

const transition = {
  ease: 'easeIn',
  duration: 0.2,
}

export const UserInfo = ({
  user,
  onClick,
}: {
  user: Partial<User>
  onClick?: () => void
}) => {
  const location = useLocation()

  if (!user.handle || !user.displayName) return <></>

  return (
    <motion.div
      whileHover="hover"
      initial="inital"
      variants={transitions}
      transition={transition}
    >
      <StyledLink
        state={{ prevPathname: `${location.pathname}${location.search}` }}
        to={{
          pathname: `/u/${user.handle}`,
        }}
        onClick={onClick}
      >
        <Flex alignItems="center" p={1}>
          <UserAvatar
            photoUrl={user?.photoURL}
            displayName={user.displayName}
            size={16}
          />
          <Spacer axis="x" factor={1} />
          <Text size="xs">{user.displayName}</Text>
        </Flex>
      </StyledLink>
    </motion.div>
  )
}
