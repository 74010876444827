import { FC, ReactNode } from 'react'
import styled from 'styled-components'

type StyledInfoProps = {
  title: string
  content: string
  action: ReactNode | null
}
export const StyledInfo: FC<StyledInfoProps> = ({ title, content, action }) => (
  <StyledInfoRow>
    <StyledInfoContent>{content}</StyledInfoContent>
    <StyledInfoAction>{action}</StyledInfoAction>
  </StyledInfoRow>
)

const StyledInfoRow = styled.div`
  justify-self: start;
  display: flex;
  align-items: center;
  gap: 1rem;
  @media screen and (min-width: 1800px) {
    flex-direction: row;
    justify-content: end;
    width: 100%;
  }
  @media screen and (max-width: 1800px) {
    flex-direction: row-reverse;
  }
`

const StyledInfoContent = styled.div`
  color: ${({ theme }) => theme.colors.text.neutral[2]};
`

const StyledInfoAction = styled.div``
