import { Download } from '@styled-icons/boxicons-regular/Download'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose, withHandlers } from 'recompose'
import styled, { css } from 'styled-components'

import { Margin } from 'components/common/Margin'
import { Text } from 'components/common/Text'
import { withRouter } from 'helpers/withRouter'

function DownloadButton(props) {
  const mostRecentDownload = Object.values(props.boardDownloads || {})
    .sort((a, b) => a.createdAt - b.createdAt)
    .pop()
  const isCreating = mostRecentDownload && !mostRecentDownload.isReady
  const isFresh =
    mostRecentDownload?.createdAt?.toDate() > props.board?.updatedAt?.toDate()

  if (typeof props.children === 'function') {
    return props.children({
      isCreating,
      isFresh,
      url: mostRecentDownload?.zipItem?.url,
      onCreateDownloadRequest: props.createDownloadRequest,
    })
  }

  if (isCreating) {
    return (
      <StyledDownload onClick={props.createDownloadRequest}>
        {mostRecentDownload.progress
          ? `Zipping ${mostRecentDownload.progress.entries.processed}/${mostRecentDownload.progress.entries.total}`
          : 'Collecting ...'}
      </StyledDownload>
    )
  }
  if (isFresh) {
    return (
      <a href={mostRecentDownload.zipItem.url}>
        <StyledDownload>
          <Download css="height: 18px" />
          <Margin x={12} />
          <Text>Download</Text>
        </StyledDownload>
      </a>
    )
  }
  return (
    <StyledDownload onClick={props.createDownloadRequest}>
      <Download css="height: 18px" />
      <Margin x={12} />
      <Text>Download</Text>
    </StyledDownload>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    uid: state.firebase.auth.uid,
    boardDownloads:
      state.firestore.data[`boardDownloads.${ownProps.params.boardId}`] || {},
    board: state.firestore.data[`activeBoards.${ownProps.params.boardId}`],
  }
}

const oneWeekAgo = ((d) => new Date(d.setDate(d.getDate() - 7)))(new Date())

export default compose(
  withRouter,
  connect(mapStateToProps),
  firestoreConnect((props) => {
    if (!props.params.boardId) return []
    const guestId = localStorage.getItem('GUEST_UID')
    const parsedGuestId = guestId ? JSON.parse(guestId) : guestId

    const createdByParam = props.uid || parsedGuestId
    return [
      {
        collection: 'downloadRequests',
        where: [
          ['boardId', '==', props.params.boardId],
          ['createdBy', '==', createdByParam],
          ['createdAt', '>', oneWeekAgo], // the signed url expire after 7 days
        ],
        storeAs: `boardDownloads.${props.params.boardId}`,
      },
    ]
  }),
  withHandlers({
    createDownloadRequest: (props) => () => {
      if (props.boardDownloads && props.boardDownloads.length > 0) {
        props.navigate(props.boardDownloads.zipItem.url)
      }

      const guestId = localStorage.getItem('GUEST_UID')
      const parsedGuestId = guestId ? JSON.parse(guestId) : guestId

      const downloadRequestDoc = {
        createdBy: props.uid || parsedGuestId,
        createdAt: props.firestore.FieldValue.serverTimestamp(),
        boardId: props.params.boardId,
        title: props.board.title,
        seen: false,
        userHasStartedDownload: false,
      }

      return props.firestore
        .add({ collection: 'downloadRequests' }, downloadRequestDoc)
        .then(() => console.log('Added'))
        .catch((e) => console.log('Error', e))
    },
  })
)(DownloadButton)

const StyledDownload = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    white-space: nowrap;
    color: ${theme.colors.text.neutral[0]};
    cursor: pointer;
    &:hover {
      color: ${theme.colors.accent[2]};
    }
  `}
`
