import { ThreeDotsVertical as Dots } from '@styled-icons/bootstrap/ThreeDotsVertical'
import { ChevronDown } from '@styled-icons/boxicons-regular/ChevronDown'
import { Plus } from '@styled-icons/foundation/Plus'
import { AnimatePresence, motion } from 'framer-motion'
import * as queryString from 'query-string'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import PermissionWrapper from 'components/common/PermissionWrapper'
import { Tooltip } from 'components/common/Tooltip/Tooltip'
import StopPropagation from 'helpers/StopPropagation'
import { useGetLastUpdatedWorkspaceBoards } from 'hooks/useLastUpdatedBoards'
import useSafeState from 'hooks/useSafeState'
import useWorkspacePermissions from 'hooks/useWorkspacePermissions'

import SaveToBoard from '../../itemsavetoboard/SaveToBoard'
import { saveToBoard } from '../../itemsavetoboard/saveToBoardHandlers'
import useToastMessages from '../../toast/useToastMessages'
import type { Item } from './GridItem'
import { GridItemOverlayMenu } from './GridItemOverlayMenu'
import GridItemOverlayOptions from './GridItemOverlayOptions'
import {
  ActionButton,
  ActionButtonTransparent,
  ActionRow,
  CheckMark,
  StyledLastBoardAction,
  Wrapper,
} from './GridItemOverlayStyles'

export interface GridItemOverlayProps {
  item: Item
  itemRefId: string
  workspaceId: string
  workspaceUrl: string
  onDelete?: (itemId: string) => void
  boardId?: string
  noOverlayShadow: boolean
  shouldHideTopOverlay: boolean
}

const GridItemOverlay: React.FC<GridItemOverlayProps> = ({
  item,
  itemRefId,
  workspaceId,
  workspaceUrl,
  onDelete,
  boardId,
  noOverlayShadow,
  shouldHideTopOverlay,
}) => {
  const [openMenu, setOpenMenu] = useState<
    'add-to-board' | 'copy-or-download' | null
  >(null)

  const { reportSuccess, reportError } = useToastMessages()

  const [isTemporarySavedToBoard, setIsTemporarySavedToBoard] =
    useSafeState(false)
  const params = useParams<{ boardId?: string; projectId?: string }>()
  const location = useLocation()
  const activeBoardId = useMemo(() => params.boardId, [params])
  const activeProjectId = useMemo(() => params.projectId, [params])

  const userAllowedTo = useWorkspacePermissions(['SAVE_TO_BOARD'])
  const [lastUpdatedWritableBoard] = useGetLastUpdatedWorkspaceBoards({
    accessLevel: 'writer',
  })

  const handleBoardAddClick = useCallback(() => {
    const hasSearchQuery =
      'q' in
      queryString.parse(location.search, {
        arrayFormat: 'comma',
      })

    if (hasSearchQuery && activeBoardId && activeProjectId) {
      saveToBoard(
        ['boardId' in item ? item.itemId : item.id],
        activeBoardId,
        workspaceId
      ).then(({ error }) => {
        if (error === 'ALREADY_EXISTS') {
          reportError('The item already exists in the board')
        } else {
          reportSuccess('Added to current board')
        }
      })
    } else {
      setOpenMenu('add-to-board')
    }
  }, [
    location.search,
    activeBoardId,
    item,
    activeProjectId,
    workspaceId,
    reportError,
    reportSuccess,
  ])

  const handleSaveToMostRecentBoard = async () => {
    if (!lastUpdatedWritableBoard) return null
    setIsTemporarySavedToBoard(true)

    setTimeout(() => setIsTemporarySavedToBoard(false), 1500)

    const { error } = await saveToBoard(
      ['boardId' in item ? item.itemId : item.id],
      lastUpdatedWritableBoard.id,
      workspaceId
    )

    if (error === 'ALREADY_EXISTS') {
      return reportError('The item already exists in the board')
    }

    reportSuccess(
      'Added to board:',
      {
        linkTo: `/${workspaceUrl}/p/${lastUpdatedWritableBoard.projectId}/${lastUpdatedWritableBoard.id}`,
        linkText: `${lastUpdatedWritableBoard.title}`,
      },
      5000
    )
  }

  const close = () => {
    setOpenMenu(null)
  }

  useEffect(() => {
    if (!openMenu) {
      return
    }
    const blockEventPropagation: EventListener = (e) => {
      e.stopPropagation()
    }
    document.addEventListener('keydown', blockEventPropagation, true)
    return () => {
      document.removeEventListener('keydown', blockEventPropagation, true)
    }
  }, [openMenu])

  return (
    <StopPropagation>
      <Wrapper noOverlayShadow={noOverlayShadow} clickable={!!openMenu}>
        <>
          <ActionRow>
            {!shouldHideTopOverlay && (
              <>
                <div>
                  {lastUpdatedWritableBoard && userAllowedTo.SAVE_TO_BOARD && (
                    <StyledLastBoardAction
                      size="sm"
                      onClick={() => setOpenMenu('add-to-board')}
                    >
                      <span>{lastUpdatedWritableBoard.title}</span>
                      <ChevronDown css="height:20px" />
                    </StyledLastBoardAction>
                  )}
                </div>

                <Tooltip
                  theme="raw"
                  interactive
                  trigger="manual"
                  arrow={false}
                  distance={0}
                  position="right-start"
                  open={openMenu === 'add-to-board'}
                  popperOptions={{
                    modifiers: {
                      preventOverflow: {
                        boundariesElement: 'window',
                      },
                    },
                  }}
                  html={
                    <>
                      <GridItemOverlayMenu onClose={close}>
                        <SaveToBoard
                          workspaceItemIds={[
                            'boardId' in item ? item.itemId : item.id,
                          ]}
                          onMenuClose={close}
                        />
                      </GridItemOverlayMenu>
                    </>
                  }
                >
                  <PermissionWrapper
                    feature="save item to board"
                    position="top"
                    isAllowed={Boolean(userAllowedTo.SAVE_TO_BOARD)}
                  >
                    {(isAllowed) => (
                      <motion.div whileTap={{ scale: 1.1 }}>
                        {/* We use a tooltip for users in workspaces without a writable board */}
                        <Tooltip
                          interactive
                          position="right"
                          html={
                            <>
                              {lastUpdatedWritableBoard ? (
                                <div>
                                  Save to the
                                  <b> {lastUpdatedWritableBoard.title} </b>
                                  board
                                </div>
                              ) : (
                                'Save to board'
                              )}
                            </>
                          }
                        >
                          <ActionButton
                            disabled={!isAllowed}
                            onClick={
                              lastUpdatedWritableBoard
                                ? handleSaveToMostRecentBoard
                                : handleBoardAddClick
                            }
                          >
                            <AnimatePresence initial={false}>
                              <motion.div
                                key={String(isTemporarySavedToBoard)}
                                initial={{ y: -20, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                              >
                                {isTemporarySavedToBoard ? (
                                  <CheckMark />
                                ) : (
                                  <Plus style={{ height: 12, marginTop: -2 }} />
                                )}
                              </motion.div>
                            </AnimatePresence>
                          </ActionButton>
                        </Tooltip>
                      </motion.div>
                    )}
                  </PermissionWrapper>
                </Tooltip>
              </>
            )}
          </ActionRow>

          <ActionRow justify="flex-end">
            <Tooltip
              theme="raw"
              interactive
              trigger="manual"
              open={openMenu === 'copy-or-download'}
              popperOptions={{
                modifiers: {
                  preventOverflow: {
                    boundariesElement: 'window',
                  },
                },
              }}
              html={
                <GridItemOverlayOptions
                  item={item}
                  itemRefId={itemRefId}
                  onClose={close}
                  boardId={boardId}
                  onDelete={onDelete}
                />
              }
            >
              <ActionButtonTransparent
                onClick={() => setOpenMenu('copy-or-download')}
              >
                <Dots />
              </ActionButtonTransparent>
            </Tooltip>
          </ActionRow>

          {/* We add an unvisible overlay to prevent add-to-board popup to close itself */}
          {openMenu === 'add-to-board' && (
            <div
              style={{
                width: '100vw',
                height: '100%',
                position: 'fixed',
                zIndex: 10,
                left: -16,
                top: -16,
                background: 'rgba(0,0,0,0)',
              }}
            />
          )}
        </>
      </Wrapper>
    </StopPropagation>
  )
}

export default GridItemOverlay
