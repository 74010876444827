import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router'

import Loading from 'components/Loading'
import { useJoinableWorkspacesQueries } from 'components/joinworkspace/useJoinableWorkspacesQueries'
import useToastMessages from 'components/toast/useToastMessages'
import { useJoinWorkspaceMutation } from 'generated/graphql'
import { trackSetupWorkspaceJoined } from 'helpers/tracking/tracking'
import useCurrentUser from 'hooks/useCurrentUser'
import useIsMobile from 'hooks/useIsMobile'

import { db, firebase } from '../../config/firebase'
import {
  Content,
  JoinableWorkspace,
  JoinableWorkspaceIcon,
  Row,
} from './SetupStyles'

type SetupWorkspaceJoinExistingProps = {
  joinableWorkspaces: ReturnType<typeof useJoinableWorkspacesQueries>['data']
  trackingSetupSessionId: string | null
}

const SetupWorkspaceJoinExisting = (props: SetupWorkspaceJoinExistingProps) => {
  const isMobile = useIsMobile()
  const [isJoining, setIsJoining] = useState(false)
  const currentUser = useCurrentUser()
  const navigate = useNavigate()
  const location = useLocation()
  const { reportError } = useToastMessages()
  const [joinWorkspaceMutation] = useJoinWorkspaceMutation()

  const handleJoinWorkspace = async (workspaceId: string) => {
    setIsJoining(true)

    const batch = db.batch()

    const onboardingRef = db
      .collection('users')
      .doc(currentUser.uid)
      .collection('private')
      .doc('onboarding')

    const onboardingUpdates = {
      completedWorkspace: true,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    }
    batch.update(onboardingRef, onboardingUpdates)

    try {
      const { data } = await joinWorkspaceMutation({
        variables: { id: workspaceId },
      })

      if (!data) {
        throw Error('Error joining workspace')
      }

      await batch.commit()

      // Segment Tracking – User Added to Account (Standard Segment B2B Saas)
      window.analytics.track(
        'Account Added User',
        {
          role: 'member',
        },
        {
          context: {
            groupId: workspaceId,
          },
        }
      )

      trackSetupWorkspaceJoined({
        workspaceId,
        workspaceTitle: data.joinWorkspace.title,
        workspaceUrl: data.joinWorkspace.url,
        trackingSetupSessionId: props.trackingSetupSessionId,
      })

      if (data.joinWorkspace.totalItemsCount > 10) {
        await onboardingRef.update({
          completedUploads: true,
          completedInvite: true, // we skip invite step
        })
        return navigate(`/${data.joinWorkspace.url}${location.search}`)
      }
      await onboardingRef.update({
        completedInvite: true, // we skip invite step
      })
      navigate(`/${data.joinWorkspace.url}/upload${location.search}`)
    } catch (error) {
      setIsJoining(false)
      reportError('Oh no, you could not join the workspace. Try again!')
    }
  }

  if (isJoining) return <Loading />
  return (
    <Content isMobile={isMobile} style={{ width: isMobile ? '95vw' : 400 }}>
      <Row narrow={!isMobile}>
        {props.joinableWorkspaces.map((workspace) => (
          <JoinableWorkspace
            key={workspace.id}
            isMobile={isMobile}
            onClick={() => handleJoinWorkspace(workspace.id)}
          >
            <JoinableWorkspaceIcon icon={workspace.iconUrl || ''}>
              {!workspace.iconUrl && workspace.title[0]}
            </JoinableWorkspaceIcon>
            {workspace.title}
          </JoinableWorkspace>
        ))}
      </Row>
    </Content>
  )
}

export default SetupWorkspaceJoinExisting
