import styled from 'styled-components'

import analogue_photo from '../../assets/img/lens_thumbs/analogue_photo.jpg'
import artful_scifi from '../../assets/img/lens_thumbs/artful_scifi.jpg'
import butterflyism from '../../assets/img/lens_thumbs/butterflyism.jpg'
import concept_portrait from '../../assets/img/lens_thumbs/concept_portrait.jpg'
import cybernetic_neon from '../../assets/img/lens_thumbs/cybernetic_neon.jpg'
import cyborg from '../../assets/img/lens_thumbs/cyborg.jpg'
import diesel_punk from '../../assets/img/lens_thumbs/diesel_punk.jpg'
import divine from '../../assets/img/lens_thumbs/divine.jpg'
import embroiderism from '../../assets/img/lens_thumbs/embroiderism.jpg'
import fanta_sci_fi from '../../assets/img/lens_thumbs/fanta_sci_fi.jpg'
import floralism from '../../assets/img/lens_thumbs/floralism.jpg'
import golden_skin from '../../assets/img/lens_thumbs/golden_skin.jpg'
import hairism from '../../assets/img/lens_thumbs/hairism.jpg'
import interior_design from '../../assets/img/lens_thumbs/interior_design.jpg'
import romantisism from '../../assets/img/lens_thumbs/romantisism.jpg'
import storyboard from '../../assets/img/lens_thumbs/storyboard.jpg'
import utopia from '../../assets/img/lens_thumbs/utopia.jpg'

const lenses = [
  {
    id: 'concept-portrait',
    name: 'Concept Art Portrait',
    value:
      'portrait, highly detailed, dynamic lighting, digital art, digital painting, artstation, wlop, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k ',
    thumb: concept_portrait,
  },

  {
    id: 'gold-skin',
    name: 'Golden Skin Portrait',
    value:
      'gold skin :: by Martine Johanna and Simon Stålenhag and Chie Yoshii and wlop and Guillermo del toro :: ornate, dynamic, kodak portra 400, hyperrealistic, particulate, rich colors, elegant, centered, artstation, smooth, sharp focus, octane render, 3d',
    thumb: golden_skin,
  },

  {
    id: 'floralism',
    name: 'Floralism Portrait',
    value:
      'kodak portra 400, hyperrealistic maximalist flowers on skin, flowers spilling out everywhere, stunning 3d render inspired art by greg rutkowski and xiang duan and thomas eakes, realistic, highly detailed attributes and atmosphere, dim volumetric cinematic lighting, 8 k octane detailed render, masterpiece',
    thumb: floralism,
  },

  {
    id: 'butterflyism',
    name: 'Butterflyism Portrait',
    value:
      'portrait, ultra realistic, portra 400, photoshoot, balenciaga lookbook, vogue magazine, make up by isamaya ffrench, butterfly on the face, flowers, photo in the style of tyler mitchell and, mario testino, egon schiele, design by balenciaga, modern art, baroque art, 35mm lens',
    thumb: butterflyism,
  },

  {
    id: 'hairism',
    name: 'Hairism Portrait',
    value:
      'portrait with extremely long hair curling in arabesque forms, portra 400, cooke 8 5 mm f / 1. 2 lens, beautiful, glamorous, shot by annie leibovitz for vogue magazine, cinematic studio lighting',
    thumb: hairism,
  },

  {
    id: 'embroiderism',
    name: 'Embroiderism Portrait',
    value:
      'symmetrical portrait wearing an embroidered beauty mask, kodak portra 400, soft light, biotechnology, bjork aesthetic, translucent, by rineke dijkstra, vogue magazine, intricate details, highly detailed, masterpiece, fabric, threaded, wool, texture, mario testino, egon schiele',
    thumb: embroiderism,
  },

  {
    id: 'cyborg',
    name: 'Cyborg',
    value:
      'detailed face, scifi character portrait by greg rutkowski, esuthio, craig mullins, 1 / 4 headshot, cinematic lighting, dystopian scifi gear, gloomy, profile picture, mechanical, half robot, implants, steampunk, warm colors',
    thumb: cyborg,
  },
  {
    id: 'portrait-photo',
    name: 'Analogue Portrait',
    value:
      'a vintage analogue by alex prager. vogue. closed eyes. kodak portra 8 0 0 film. shallow depth of field. ( depth of field ). whirl bokeh!!. golden hour. detailed. hq. realistic. warm light. muted colors. filmic. dreamy. lens flare. mamiya 7 ii, f / 1. 2, symmetrical balance, in - frame',
    thumb: analogue_photo,
  },
  {
    id: 'diesel-punk',
    name: 'Diesel Punk',
    value:
      'dieselpunk concept art, grimy, gritty, dieselpunk trending on artstation, award winning painting, cgi, art by john berkey and anton fadeev and john howe and simon stalenhag',
    thumb: diesel_punk,
  },
  {
    id: '70s-scifi',
    name: '70s Scifi',
    value:
      'stunningly intricate illustration, highly detailed, midnight, by Josan Gonzalez and James Gilleard, Moebius, Laurie Greasley',
    thumb: artful_scifi,
  },

  {
    id: 'fanta-sci-fi',
    name: 'Fanta-sci-fi',
    value:
      'illustration, world full of life, divine thrill of the biological tranquil sky, atoms floating, by greg rutkowski, fantasy, horror, intricate, elegant, highly detailed, digital painting',
    thumb: fanta_sci_fi,
  },

  {
    id: 'romantisism',
    name: 'Romantisism',
    value: `a beautiful and highly detailed matte painting of an epic mountain range, intricate details, epic scale, insanely complex, 8 k, sharp focus, hyperrealism, very realistic, by caspar friedrich, james gurney, albert bierstadt`,
    thumb: romantisism,
  },
  {
    id: 'cybernetic-neon',
    name: 'Cybernetic neon',
    value: `neon, altered carbon, mech suit, fibonacci, sweat drops, insane, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by artgerm and greg rutkowski and alphonse mucha`,
    thumb: cybernetic_neon,
  },

  {
    id: 'interior-design',
    name: 'Interior Design',
    value:
      'insanely detailed wide angle photograph, atmospheric, light bloom, lens flares, reflections, award winning contemporary, minimalist, interior design living room, dusk, cozy and calm, fabrics and textiles, colorful accents, brass, copper, secluded, many light sources, lamps, oiled hardwood floors, book shelf, library, couch, desk, balcony door, plants',
    thumb: interior_design,
  },
  {
    id: 'divine',
    name: 'Divine',
    value:
      'windy, dramatic, stoic, modern, shining light, light dust, magnificent, hyperdetailed, theatrical, masterpiece, from below, 9 0 mm lens, painted by jean honore fragonard and greg rutkowski',
    thumb: divine,
  },
  {
    id: 'storyboard',
    name: 'Storyboard',
    value:
      'storyboard by gabriel hardman, joe alves, chris bonura. cinematic atmosphere, detailed and intricate, perfect anatomy',
    thumb: storyboard,
  },
  {
    id: 'utopia',
    name: 'Utopia',
    value:
      'futuristic city, lush vegetation, humid, early evening, cloudy, beautiful, dull pastel colors, realistic, hyper detailed, octane render, trending on artstation by yoshitaka amano and makoto shinkai, studio ghibli style',
    thumb: utopia,
  },
]

export interface LensType {
  id: string
  name: string
  value: string
  thumb: string
}

export const Lenses = ({
  show,
  activeLens,
  setActiveLens,
  setMouseIsOverLenses,
}: {
  show: boolean
  activeLens: null | LensType
  setActiveLens: (newActive: LensType | null) => void
  setMouseIsOverLenses: (isOver: boolean) => void
}) => {
  return (
    <StyledLensContainer
      className="__ignore-gestures" // we make sure our global gestures doesn't work on the lenses
      onMouseEnter={() => setMouseIsOverLenses(true)}
      onMouseLeave={() => setMouseIsOverLenses(false)}
      show={show}
    >
      {lenses.map((lens, index) => (
        <StyledGradientBorder
          key={lens.id}
          isActive={activeLens?.id === lens.id}
        >
          <div
            style={{
              background: 'black',
              borderRadius: 8,
            }}
          >
            <StyledLensCard
              onClick={() => {
                const newActiveLens = activeLens?.id === lens.id ? null : lens
                setActiveLens(newActiveLens)
              }}
              index={index}
              thumb={lens.thumb}
              isActive={activeLens?.id === lens.id}
              hasActiveLens={activeLens !== null}
              className="__ignore-gestures" // we make sure our global gestures doesn't work on the lenses
            >
              {lens.name}
            </StyledLensCard>
          </div>
        </StyledGradientBorder>
      ))}
    </StyledLensContainer>
  )
}

interface StyledLensCardProps {
  thumb: string
  isActive: boolean
  index: number
  hasActiveLens: boolean
}

const StyledLensCard = styled.div<StyledLensCardProps>`
  padding: 8px;
  height: 80px;
  border-radius: 8px;
  width: 120px;
  background-color: ${(props) => props.theme.colors.background[3]};
  background-image: ${({ thumb }) => `url(${thumb})`};
  background-size: cover;
  color: white;
  opacity: ${({ isActive, hasActiveLens }) =>
    // eslint-disable-next-line
    isActive ? 1 : hasActiveLens ? 0.4 : 0.8};
  &:hover {
    opacity: 1;
  }
`

interface StyledLensContainerProps {
  show: boolean
}

const StyledLensContainer = styled.div<StyledLensContainerProps>`
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(12, 12, 12, 1) 100%
  );
  width: 100%;
  position: fixed;
  padding: 16px;
  bottom: ${({ show }) => `${show ? 0 : -136}px`};
  display: flex;
  gap: 16px;
  z-index: 10;
  align-items: center;
  overflow: scroll;
  transition: bottom 0.5s;
  cursor: pointer;
  overscroll-behavior-x: contain; // to prevent navigation on mac
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`

interface StyledGradientBorderProps {
  isActive: boolean
}
const StyledGradientBorder = styled.div<StyledGradientBorderProps>`
  background: ${({ isActive }) =>
    `${
      isActive
        ? 'conic-gradient(#5500ff,#ff8800,#ff0051, #ff00f2,#5500ff)'
        : '#121212'
    }`};
  border-radius: 8px;
  padding: 1px;
`
