import styled, { DefaultTheme, css } from 'styled-components'

export const Header = styled.div({
  width: '100%',
  padding: '16px 10px 0px 10px',
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

export const AddTagWrapper = styled.div`
  ${({ theme }) => css`
    border: ${theme.border.thin} ${theme.colors.background[5]};
    border-radius: ${theme.borderRadius.default};
    overflow: hidden;
    margin-top: 8px;
    ${TagEditableContainer} {
      margin: 0;
    }
  `}
`

export const TagEditableContainer = styled.div(({ theme }) => ({
  background: theme.colors.background[4],
  borderRadius: theme.borderRadius.default,
  marginTop: 10,
}))

export const Tag = styled.div<{
  isEditing?: boolean
  isLightMode?: boolean
}>`
  ${({ theme, isEditing, isLightMode }) => css`
    background: ${theme.colors.background[4]};
    width: 100%;
    height: 44px;
    margin-top: ${isEditing ? '0px' : '10px'};
    text-align: left;
    padding: 8px;
    border-radius: ${theme.borderRadius.default};
    font-size: ${theme.fontSizes.base};
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    position: relative;
    color: ${theme.colors.text.neutral[3]};
    border: ${isEditing
      ? `${theme.border.thin} ${theme.colors.accent[2]}`
      : `${theme.border.thin} transparent`};
    /* Light Theme Styling */
    ${isLightMode &&
    css`
      border: ${theme.border.thin} ${theme.colors.gray.light[0]};
    `}
    :hover {
      color: ${theme.colors.text.neutral[2]};
      background: ${theme.colors.background[5]};

      /* Light Theme Styling */
      ${isLightMode &&
      css`
        color: ${theme.colors.accent[2]};
        background ${theme.colors.background[0]};
        border: ${theme.border.thin} ${theme.colors.accent[2]};
      `}
    }

    ${IconButton} {
      display: none;
    }
    :hover ${IconButton} {
      display: flex;
    }
  `}
`

export const TagDescription = styled.div<{ style?: any }>(() => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  margin: 8,
}))

export const SuggestionsContainer = styled.div(({ theme }) => ({
  background: theme.colors.background[4],
  borderRadius: theme.borderRadius.default,
  paddingTop: 8,
}))

const getTagSuggestionColor = (
  theme: DefaultTheme,
  alreadyAdded?: boolean,
  hasFocus?: boolean
) => {
  if (alreadyAdded) return theme.colors.text.neutral[4]
  if (hasFocus) return theme.colors.accent[2]
  return theme.colors.text.neutral[3]
}

interface TagSuggestionProps {
  readonly hasFocus: boolean
  readonly alreadyAdded: boolean
}

export const TagSuggestion = styled.div<TagSuggestionProps>(
  ({ theme, hasFocus, alreadyAdded }) => ({
    width: '100%',
    overflowWrap: 'break-word',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'left',
    padding: '8px 10px',
    borderRadius: theme.borderRadius.default,
    fontSize: theme.fontSizes.base,
    alignItems: 'center',
    cursor: 'pointer',
    color: getTagSuggestionColor(theme, alreadyAdded, hasFocus),
    background: hasFocus ? theme.colors.background[3] : 'transparent',
    display: 'flex',
  })
)

export const TagSuggestionText = styled.div(() => ({
  width: 'calc(100% - 16px)',
  overflow: 'hidden',
}))

interface TagSuggestionTextDetailProps {
  readonly accent?: boolean
}

export const TagSuggestionTextDetail = styled.div<TagSuggestionTextDetailProps>(
  ({ theme, accent }) => ({
    color: accent ? theme.colors.accent[2] : theme.colors.text.neutral[3],
    fontSize: theme.fontSizes.xs,
  })
)

export const Button = styled.div(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  right: 4,
  padding: '4px 12px',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: 32,
  minWidth: 32,
  borderRadius: theme.borderRadius.sm,
  cursor: 'pointer',
  color: theme.colors.text.neutral[0],
  background: theme.colors.accent[2],
  ':hover': {
    background: theme.colors.accent[1],
  },
  ':active': {
    background: theme.colors.accent[2],
  },
}))

export const IconButton = styled.div<{
  accent?: boolean
}>(({ theme, accent, style }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: 32,
  minWidth: 32,
  borderRadius: theme.borderRadius.default,
  cursor: 'pointer',
  color: accent ? theme.colors.text.neutral[0] : theme.colors.text.neutral[3],
  background: accent ? theme.colors.accent[2] : theme.colors.background[4],
  ...style,
  ':hover': {
    color: theme.colors.text.neutral[0],
    background: theme.colors.accent[2],
  },
  ':active': {
    color: theme.colors.text.neutral[0],
    background: theme.colors.accent[1],
  },
}))

export const Input = styled.input(({ theme }) => ({
  color: theme.colors.text.neutral[0],
  background: 'transparent',
  border: 'none',
  outline: 'none',
  flex: '1 1 0',
  margin: 6,
  '::placeholder': {
    color: theme.colors.text.neutral[3],
  },
}))
