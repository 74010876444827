import React, { useEffect } from 'react'
import styled from 'styled-components'

import { setActiveExport } from 'store/content/actions'

import { CreateAssetsPanel } from './CreateAssetsPanel'

export const CreateAssetsView = () => {
  useEffect(() => {
    return () => {
      setActiveExport()
    }
  })
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <Wrapper>
        <CreateAssetsPanel />
      </Wrapper>
    </div>
  )
}

const Wrapper = styled.div`
  background-color: rgb(12, 12, 12);
  max-width: calc(100% - 32px);
  max-height: calc(100% - 32px);
  width: 800px;
  height: auto;
  border-radius: 16px;
  border: 1px solid rgb(32, 32, 32);
  box-shadow: 0 0 0 1px rgb(0 0 0 / 80%), 0 0 8px 0 rgb(0 0 0 / 10%);
  padding: 0;
  color: rgb(135, 135, 135);
  position: relative;
  @media (max-width: 500px) {
    width: 400px;
  }
`
