import * as queryString from 'query-string'
import React from 'react'
import { useLocation } from 'react-router'

import Downloads from 'components/downloads/Downloads'
import { useCurrentUserListeners } from 'hooks/useCurrentUserListeners'

import PublicItemView from '../item/PublicItemView'
import BoardView from './BoardView'

const PublicBoard: React.FC = () => {
  useCurrentUserListeners()

  const location = useLocation()

  const parsed = queryString.parse(location.search, {
    arrayFormat: 'comma',
  })
  const { item } = parsed

  return (
    <>
      <Downloads />
      <BoardView public />
      {item && <PublicItemView isBoardItem isCloseable />}
    </>
  )
}

export default PublicBoard
