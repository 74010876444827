import { useEffect } from 'react'
import { browserName, isMobile } from 'react-device-detect'
import { useSelector } from 'react-redux'

import { RootState } from 'store'

import { useSyncGrowthbook } from './useSyncGrowthbook'

type Attributes = {
  id: string
  loggedIn: boolean
  isMobile?: boolean
  browserName?: string
  jobTitle?: string
  handle?: string
  workspaceId?: string
}

const useSyncGrowthbookUser = () => {
  const growthbook = useSyncGrowthbook()

  const currentUserId = useSelector(
    (state: RootState) => state.firebase.auth.uid
  )
  const currentUser = useSelector((state: RootState) =>
    currentUserId ? state.firestore.data.users?.[currentUserId] : null
  )
  const currentWorkspace = useSelector(
    (state: RootState) => state.content.activeWorkspace
  )

  useEffect(() => {
    const currentAttributes = growthbook?.getAttributes()
    if (currentUser && currentAttributes?.id !== currentUserId) {
      growthbook?.setAttributes({
        id: currentUser.uid,
        loggedIn: true,
        isMobile,
        browserName,
        jobTitle: currentUser.jobTitle,
        handle: currentUser.handle,
        workspaceId: currentWorkspace?.id,
      })
    }
  }, [currentUser, currentUserId, currentWorkspace?.id, growthbook])

  useEffect(() => {
    const currentAttributes = growthbook?.getAttributes()
    if (
      currentWorkspace &&
      currentAttributes?.workspaceId !== currentWorkspace.id
    ) {
      growthbook?.setAttributes({
        ...currentAttributes,
        workspaceId: currentWorkspace?.id,
      })
    }
  }, [currentWorkspace, growthbook])

  return {
    currentGrowthbookAttributes: growthbook?.getAttributes(),
    setGrowthbookAttributes: (attributes: Attributes) =>
      growthbook?.setAttributes(attributes),
  }
}

export default useSyncGrowthbookUser
