import type firebase from 'firebase/compat'

import { ItemRefWithId } from 'types/custom'
import { DBItemSize } from 'types/db'

import type { ItemData } from '../useGetItemData'

export const convertItemRefToBoardItem = ({
  boardItem,
}: {
  boardItem: ItemRefWithId
}): ItemData => {
  return {
    __typename: 'BoardItem',
    id: boardItem.id,
    boardId: boardItem.boardId,
    projectId: boardItem.projectId,
    workspaceItemId: boardItem.itemId,
    accessLevel: boardItem.accessLevel,
    aspectRatio: boardItem.aspectRatio,
    commentCount: 0,
    createdAt: (boardItem.createdAt as firebase.firestore.Timestamp)
      .toDate()
      .toISOString(),
    colors: [],
    createdBy: null,
    itemSourceInfo: {
      type: 'UPLOAD',
      videoExtractInfo: null,
      extensionImportInfo: null,
    },
    mediaType: boardItem.original?.info?.type === 'gif' ? 'GIF' : 'IMAGE',
    openCount: boardItem.openCount || 0,
    mediaVariants: mapMediaVariants(boardItem.sizes, boardItem.versions) || [],
    original: {
      path: '',
      info: {
        type: boardItem.original?.info?.type || 'jpg',
      },
    },
    tags: [],
    title: boardItem.origin.name ?? '',
    uploadSessionId: boardItem.uploadSessionId,
    userRole: 'READER',
    workspaceId: boardItem.workspaceId,
    webUrl: '',
    updatedAt: '',
    itemId: boardItem.itemId,
  }
}

const mapMediaVariants = (
  sizes: ItemRefWithId['sizes'],
  versions: ItemRefWithId['versions']
) => {
  if (versions) {
    return Object.keys(versions).map((key) => ({
      type: versions[key].publicUrl.split('.').pop() || 'jpg',
      url: versions[key].publicUrl,
      variant: getVariantFromVersionKey(key),
    }))
  }
  if (sizes) {
    return sizes.map((size) => ({
      type: size.type,
      url: size.url || ((size as any).publicUrl as string),
      variant: getVariantFromSize(size),
    }))
  }
  return undefined
}

const getVariantFromVersionKey = (key: string) => {
  switch (key) {
    case 'thumb':
      return 'thumb'
    case 'jpg-200px':
      return 'thumb_small'
    case 'jpg-400px':
      return 'small'
    case 'jpg-800px':
      return 'medium'
    case 'jpg-1600px':
      return 'large'
    case 'jpg-original':
      return 'original'
    default:
      return 'unknown'
  }
}

const getVariantFromSize = (size: DBItemSize) => {
  switch (size.maxWidth) {
    case 200:
      return 'thumb_small'
    case 260:
      return 'thumb'
    case 400:
      return 'small'
    case 800:
      return 'medium'
    case 1600:
      return 'large'
    case null:
      return 'original'
    default:
      return 'unknown'
  }
}
