import { ButtonVariant } from '../../../common/Button'
import { SubscriptionInterval } from '../../types'

type GetButtonTextArgs = {
  subscriptionItems: any[] | null | undefined
  interval: any
  subscriptionInterval: SubscriptionInterval
  currentPlanId: string
  iteratedPlanId: string
  status: string
}
export const getButtonText = ({
  subscriptionItems,
  interval,
  subscriptionInterval,
  currentPlanId,
  iteratedPlanId,
  status,
}: GetButtonTextArgs): {
  text: string
  disabled: boolean
  variant: ButtonVariant
} => {
  const currentlyActivePriceIds = [...(subscriptionItems ?? [])].map(
    (si) => `${si.price.id}`
  )
  const iteratedPriceId = `${interval.priceId}`
  const planIsCurrentlyActive = currentlyActivePriceIds.includes(
    `${iteratedPriceId}`
  )
  if (planIsCurrentlyActive) {
    return {
      text: 'Current plan',
      disabled: true,
      variant: 'secondary',
    }
  }

  if (!subscriptionItems || status === 'LEGACY') {
    return {
      text: 'Choose',
      disabled: false,
      variant: 'primary',
    }
  }

  const currentPriceAmount = [...(subscriptionItems ?? [])].reduce(
    (sum, si) => sum + si.price.unitAmount,
    0
  )
  const unitAmount = Number(interval.priceActual.unitAmount)

  const currentIntervals = [...(subscriptionItems ?? [])].map(
    (si) => si.price.recurring.interval
  )
  const intervalsMatch = currentIntervals.includes(
    subscriptionInterval.replace('ly', '')
  )
  const plansMatch = currentPlanId === iteratedPlanId

  if (plansMatch && !intervalsMatch) {
    const intervalText =
      subscriptionInterval === 'yearly' ? 'annual' : 'monthly'
    return {
      text: `Switch to ${intervalText}`,
      disabled: false,
      variant: 'secondary',
    }
  }

  if (unitAmount > currentPriceAmount) {
    return {
      text: 'Upgrade',
      disabled: false,
      variant: 'primary',
    }
  }
  if (unitAmount < currentPriceAmount) {
    return {
      text: 'Downgrade',
      disabled: false,
      variant: 'secondary',
    }
  }

  return {
    text: 'Choose',
    disabled: false,
    variant: 'primary',
  }
}
