import React, { useRef } from 'react'
import Skeleton from 'react-loading-skeleton'
import styled, { css } from 'styled-components'

import Spinner from 'components/common/Spinner'
import { Tooltip } from 'components/common/Tooltip/Tooltip'
import useToastMessages from 'components/toast/useToastMessages'
import { useUploadCoverPhotoMutation } from 'generated/graphql'

const MAX_FILE_SIZE = 10000000 // bytes

type ProfileCoverProps = {
  coverImageUrl?: string | null
  isLoadingUser: boolean
  isEditable: boolean
}

export const ProfileCover = (props: ProfileCoverProps) => {
  const coverImageInputRef = useRef<HTMLInputElement>(null)
  const { reportError } = useToastMessages()

  const [uploadCoverPhoto, { loading }] = useUploadCoverPhotoMutation()

  const handleEditCoverImage = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0]
    if (!file) return
    if (file.size > MAX_FILE_SIZE) {
      return reportError(
        `Please select an image smaller than ${MAX_FILE_SIZE / 1000000} mb`
      )
    }
    uploadCoverPhoto({
      variables: {
        file,
      },
    })
  }

  return (
    <Tooltip
      isDisabled={!props.isEditable}
      title="Edit cover"
      position="bottom"
    >
      <CoverWrapper
        isClickable={props.isEditable}
        onClick={
          props.isEditable
            ? () => coverImageInputRef.current?.click()
            : undefined
        }
      >
        {props.isLoadingUser ? (
          <Skeleton style={{ height: 200 }} />
        ) : (
          <>
            {props.coverImageUrl && (
              <CoverImage
                src={props.coverImageUrl}
                css={
                  loading
                    ? css`
                        filter: blur(2px) brightness(0.7);
                      `
                    : undefined
                }
              />
            )}
            {loading && <Spinner css="position:absolute;z-index:1" size={28} />}
          </>
        )}
        <input
          type="file"
          accept="image/*"
          ref={coverImageInputRef}
          onChange={handleEditCoverImage}
          style={{ display: 'none' }}
        />
      </CoverWrapper>
    </Tooltip>
  )
}

const CoverWrapper = styled.div<{ isClickable: boolean }>`
  ${({ theme, isClickable }) => css`
    height: 200px;
    position: relative;
    background-color: ${theme.colors.background[3]};
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${theme.colors.accent[2]};
    ${isClickable &&
    css`
      cursor: pointer;
    `}
  `}
`

const CoverImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`
