import produce from 'immer'

import {
  useUpdateUserTasksMutation,
  useUserTasksQuery,
} from 'generated/graphql'

import { SetupTaskId } from './setupTasksInfo'

export const useUserTasks = () => {
  const [updateUserTasksMutation] = useUpdateUserTasksMutation()

  const { data } = useUserTasksQuery({
    context: {
      batch: true,
    },
  })
  const userTasks = data?.userTasks as undefined | Record<SetupTaskId, any>

  const completeUserTask = async (id: SetupTaskId) => {
    if (!userTasks) return null

    if (userTasks[id]?.isCompleted) return null

    const optimisticResponseData = produce(userTasks, (draft) => {
      draft[id].isCompleted = true
    })

    try {
      const { errors } = await updateUserTasksMutation({
        variables: {
          userTasksUpdate: {
            [id]: {
              isCompleted: true,
            },
          },
        },
        optimisticResponse: {
          updateUserTasks: optimisticResponseData,
        },
        update: (cache, newData) => {
          const updatedUserTasks = newData.data?.updateUserTasks
          if (!updatedUserTasks) return
          cache.modify({
            fields: {
              userTasks() {
                return updatedUserTasks
              },
            },
          })
        },
      })
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      if (errors) throw errors
    } catch (error) {
      reportError(error)
    }
  }

  return {
    userTasks,
    completeUserTask,
  }
}
