import { StyledIcon } from '@styled-icons/styled-icon'
import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import StopPropagation from 'helpers/StopPropagation'
import useIsMobile from 'hooks/useIsMobile'

const Button = styled.button<{
  floating?: boolean
  primary?: boolean
  center?: boolean
  bottom?: number
  noBackground?: boolean
  size?: number
  variant: 'squared' | 'rounded'
}>`
  ${({
    theme,
    floating,
    primary,
    center,
    bottom,
    noBackground,
    size,
    variant,
  }) => css`
    /* Positioning Styles */
    position: ${floating ? 'fixed' : ''};

    ${floating &&
    center &&
    css`
      left: 50%;
      transform: translate(-50%);
    `}

    ${floating &&
    bottom &&
    css`
      bottom: ${bottom}px;
    `}

    /* Coloring Styles */
    background: ${noBackground ? 'none' : `${theme.colors.background[4]}`};
    color: ${theme.colors.text.neutral[0]};

    ${primary &&
    css`
      background: ${theme.colors.accent[2]};
      color: ${theme.colors.text.neutral[0]};
    `}

    /* Sizing */
    height: ${size || 40}px;
    width: ${size || 40}px;

    border-radius: ${variant === 'rounded'
      ? '999px'
      : theme.borderRadius.default};
    border: none;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: ${theme.colors.background[5]};
      color: ${theme.colors.white};
      cursor: pointer;
    }
    &:focus {
      outline: none;
    }
  `}
`

const ButtonWrapper = styled.div`
  position: relative;
`

const DropdownMenu = styled.div<{
  menuWidth?: number
  fullWidth?: boolean
  menuFixedBottom?: boolean
  noSpace?: boolean
  position?: 'bottom' | 'bottom-left' | 'right' | 'top'
}>`
  ${({
    theme,
    menuWidth,
    fullWidth,
    menuFixedBottom,
    position = 'bottom',
    noSpace,
  }) => css`
    background: ${theme.colors.background[3]};
    box-shadow: 0px 2px 4px ${theme.colors.black};
    position: absolute;
    z-index: 100;
    width: ${menuWidth || 330}px;
    border-radius: 8px;
    overflow: hidden;
    ${() => {
      if (position === 'bottom') {
        return css`
          top: 100%;
          right: 0;
          padding-top: ${noSpace ? '0px' : '16px'};
        `
      }
      if (position === 'bottom-left') {
        return css`
          top: 100%;
          left: 0;
          padding-top: ${noSpace ? '0px' : '16px'};
        `
      }
      if (position === 'right') {
        return css`
          left: 100%;
          top: 0;
        `
      }
      if (position === 'top') {
        return css`
          right: 0;
          bottom: 100%;
        `
      }
    }}
    ${fullWidth &&
    css`
      width: 100%;
    `}
    ${menuFixedBottom &&
    css`
      position: fixed;
      top: auto;
      bottom: 0;
      border-radius: 0;
      border: none;
      border-top: 1px solid ${theme.colors.background[5]};
    `}
  `}
`

const MenuCloseArea = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
`

interface IconButtonProps {
  size?: number
  variant?: 'squared' | 'rounded'
  iconSize?: number
  Icon: StyledIcon
  wrapperStyles?: React.CSSProperties
  menuWidth?: number
  menuFixedBottom?: boolean
  menuNoSpace?: boolean
  floating?: boolean
  center?: boolean
  primary?: boolean
  bottom?: number
  noBackground?: boolean
  fullWidth?: boolean
  position?: 'bottom' | 'bottom-left' | 'right' | 'top'
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const IconButton: React.FC<
  IconButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({
  Icon,
  size,
  iconSize,
  children,
  menuWidth,
  menuFixedBottom,
  wrapperStyles,
  onClick,
  floating,
  center,
  primary,
  bottom,
  noBackground,
  fullWidth,
  menuNoSpace,
  variant,
  position,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const isMobile = useIsMobile()

  const listeners = {
    onClick:
      onClick ||
      ((e) => {
        e.preventDefault()
        setIsOpen((prev) => !prev)
      }),
  }

  return (
    <ButtonWrapper style={wrapperStyles}>
      <Button
        floating={floating}
        center={center}
        bottom={bottom}
        primary={primary}
        noBackground={noBackground}
        size={size}
        variant={variant || 'rounded'}
        {...{ ...rest, ...listeners }}
      >
        <Icon
          size={iconSize || 16}
          style={{ transform: isMobile ? 'scale(1.3)' : '' }}
        />
      </Button>
      {children && isOpen && (
        <>
          <DropdownMenu
            menuWidth={menuWidth}
            menuFixedBottom={menuFixedBottom}
            fullWidth={fullWidth}
            onClick={() => setIsOpen(false)}
            noSpace={menuNoSpace}
            position={position}
          >
            {children}
          </DropdownMenu>
          <StopPropagation>
            <MenuCloseArea
              onClick={(e) => {
                e.preventDefault()
                setIsOpen(false)
              }}
            />
          </StopPropagation>
        </>
      )}
    </ButtonWrapper>
  )
}

export default IconButton
