import {
  Book,
  EggCrack,
  Eye,
  FilmStrip,
  GearSix,
  GoogleChromeLogo,
  GridFour,
  Heart,
  House,
  InstagramLogo,
  Intersect,
  TrendUp,
  User,
  UserPlus,
} from 'phosphor-react'
import { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router'

import { Margin } from 'components/common/Margin'
import { useJoinableWorkspacesQueries } from 'components/joinworkspace/useJoinableWorkspacesQueries'
import { useWorkspacePlan } from 'components/upload/useUploadWorkspaces'
import { trackExtensionButtonClicked } from 'helpers/tracking/tracking'
import { useActiveWorkspace } from 'hooks/useActiveWorkspace'
import useIsMobile from 'hooks/useIsMobile'
import { useGrowthBookFeature } from 'hooks/useSyncGrowthbook'
import { RootState } from 'store'

import Header from '../header/Header'
import { NominateButton } from '../sharing/NominateButton'
import FavoritesMenu from './FavoritesMenu'
import MenuSection from './MenuSection'
import {
  MenuContainer,
  Nav,
  NavItemStyled,
  NavSubItemsContainer,
  NavText,
  SiderStyled,
} from './MenuStyles'
import UpgradeNotificationLink from './UpgradeNotificationLink'
import WorkspaceSelector from './WorkspaceSelector'

type NavItemProps = {
  title: string
  path?: string
  icon: ReactNode
  onClick?: (argument: any) => void
  siderSplitValue: number
  isSubItem?: boolean
  subItems?: ReactNode
  cssTitle?: string
  isSecondary?: boolean
}

const NavItem = ({
  path,
  onClick,
  siderSplitValue,
  icon,
  title,
  isSubItem,
  subItems,
  cssTitle,
  isSecondary,
}: NavItemProps) => {
  const location = useLocation()
  const navigate = useNavigate()
  const previousSubView = useSelector(
    (state: RootState) => state.ui.librarySubView
  )
  const match = useParams() as { workspaceUrl: string }

  const isRoot = !path && typeof path === 'string'

  const isActive = isRoot
    ? location.pathname === `/${match.workspaceUrl}`
    : `${location.pathname}${location.search}`.includes(
        `/${match.workspaceUrl}/${path}`
      )
  const shouldBeHighlited = isActive

  const handleClick = (passedPath: string) => {
    if (passedPath.startsWith('?')) {
      return navigate(`${passedPath}`)
    }

    if (passedPath === 'library') {
      return navigate(`library/${previousSubView}`)
    }

    if (isRoot) {
      return navigate(`/${match.workspaceUrl}`)
    }

    return navigate(`/${match.workspaceUrl}/${passedPath}`)
  }

  return (
    <>
      <NavItemStyled
        isSecondary={isSecondary}
        isSubItem={isSubItem}
        onClick={onClick || (() => handleClick(path ?? ''))}
        siderSplitValue={siderSplitValue}
        active={shouldBeHighlited}
      >
        {icon}
        <NavText
          css={cssTitle}
          active={shouldBeHighlited}
          siderSplitValue={siderSplitValue}
        >
          {title}
        </NavText>
      </NavItemStyled>
      <NavSubItemsContainer
        siderSplitValue={siderSplitValue}
        isVisible={isActive && !!subItems}
      >
        {subItems}
      </NavSubItemsContainer>
    </>
  )
}

function Menu() {
  const isMobile = useIsMobile()
  const navigate = useNavigate()
  const newDiscoverFeature = useGrowthBookFeature('discover-2.0')

  const { data: joinableWorkspaces } = useJoinableWorkspacesQueries()

  const homePageFeature = useGrowthBookFeature('home-page')

  const siderSplitValue = useSelector(
    (state: RootState) => state.ui.siderSplitValue
  )

  const currentUserEmail = useSelector(
    (state: RootState) => state.firebase.auth.email!
  )
  const activeWorkspace = useActiveWorkspace()!
  const activeWorkspaceId = activeWorkspace.id

  const { workspacePlan } = useWorkspacePlan(activeWorkspaceId)

  const isWorkspaceAdmin =
    'adminEmails' in activeWorkspace
      ? activeWorkspace.adminEmails.includes(currentUserEmail)
      : activeWorkspace.admins.includes(currentUserEmail)

  return (
    <MenuContainer>
      <Header siderSplitValue={siderSplitValue} />
      <Nav siderSplitValue={siderSplitValue}>
        {homePageFeature.on && (
          <NavItem
            title="Home"
            path=""
            icon={<House size={16} />}
            siderSplitValue={siderSplitValue}
          />
        )}
        <NavItem
          title="Discover"
          path="discover"
          icon={<Eye size={16} />}
          siderSplitValue={siderSplitValue}
          subItems={
            isMobile ? null : (
              <>
                <NavItem
                  isSubItem
                  title="For you"
                  path={
                    newDiscoverFeature.on
                      ? 'discover/for-you'
                      : 'discover?mode=made-for-you'
                  }
                  icon={<Heart size={16} />}
                  siderSplitValue={siderSplitValue}
                />
                <NavItem
                  isSubItem
                  title="New"
                  path={
                    newDiscoverFeature.on
                      ? 'discover/new'
                      : 'discover?mode=newest'
                  }
                  icon={<EggCrack size={16} />}
                  siderSplitValue={siderSplitValue}
                />
                {newDiscoverFeature.on && (
                  <NavItem
                    isSubItem
                    title="Boards"
                    path="discover/boards"
                    icon={<GridFour size={16} />}
                    siderSplitValue={siderSplitValue}
                  />
                )}
              </>
            )
          }
        />

        <NavItem
          title="Profile"
          path="profile"
          icon={<User size={16} />}
          siderSplitValue={siderSplitValue}
        />
        <MenuSection
          siderSplitValue={siderSplitValue}
          title={activeWorkspace.title}
        >
          <NavItem
            title="Library"
            path="library"
            icon={<Book size={16} />}
            siderSplitValue={siderSplitValue}
          />
          <NavItem
            title="Projects"
            path="projects"
            icon={<GridFour size={16} />}
            siderSplitValue={siderSplitValue}
            data-intercom-target="Menu Item Projects" // Used for Intercom product tours
          />
        </MenuSection>
        <MenuSection siderSplitValue={siderSplitValue} title="Tools">
          <NavItem
            isSecondary
            title="Extract from video"
            path="frame-extractor"
            icon={<FilmStrip size={16} />}
            siderSplitValue={siderSplitValue}
            data-intercom-target="Menu Item Extract Frames" // Used for Intercom product tours
            subItems={
              isMobile ? null : (
                <>
                  <NavItem
                    isSubItem
                    isSecondary
                    title="Frames"
                    path="frame-extractor?mode=frames"
                    icon={<FilmStrip size={16} />}
                    siderSplitValue={siderSplitValue}
                  />
                  <NavItem
                    isSubItem
                    isSecondary
                    title="Gifs"
                    path="frame-extractor?mode=gifs"
                    icon={<FilmStrip size={16} />}
                    cssTitle="margin-left:9px"
                    siderSplitValue={siderSplitValue}
                  />
                </>
              )
            }
          />

          {!isMobile && (
            <NavItem
              title="Chrome extension"
              isSecondary
              icon={<GoogleChromeLogo size={16} />}
              onClick={() => {
                trackExtensionButtonClicked({
                  extensionType: 'chrome',
                  buttonLocation: 'menu',
                })
                window
                  .open(
                    'https://chrome.google.com/webstore/detail/save-to-kive/hfkpeknbeaekjalojbgelphkikhkaicg',
                    '_blank'
                  )
                  ?.focus()
              }}
              siderSplitValue={siderSplitValue}
              data-intercom-target="Menu Item Chrome Extension" // Used for Intercom product tours
            />
          )}
          <NavItem
            title="Create social asset"
            isSecondary
            icon={<InstagramLogo size={16} />}
            siderSplitValue={siderSplitValue}
            path="create-asset"
            data-intercom-target="Menu Item Social Asset" // Used for Intercom product tours
          />
        </MenuSection>
        <Margin y={22} />
        <NavItem
          title="Add member"
          isSecondary
          path="?settingsPanel=members"
          icon={<UserPlus style={{ width: 16 }} />}
          siderSplitValue={siderSplitValue}
        />
        {isWorkspaceAdmin && workspacePlan?.presentation.name !== 'Team' && (
          <NavItem
            title="Upgrade"
            isSecondary
            path="?settingsPanel=plans"
            icon={<TrendUp size={16} />}
            siderSplitValue={siderSplitValue}
          />
        )}
        <NavItem
          title="Settings"
          isSecondary
          path="?settingsPanel=account"
          icon={<GearSix size={16} />}
          siderSplitValue={siderSplitValue}
        />
        {joinableWorkspaces?.length ? (
          <NavItem
            isSecondary
            title={`Join workspace (${joinableWorkspaces.length})`}
            icon={<Intersect size={16} />}
            siderSplitValue={siderSplitValue}
            onClick={() => navigate('/join/workspaces')}
          />
        ) : null}
        <FavoritesMenu siderSplitValue={siderSplitValue} />
      </Nav>
      <UpgradeNotificationLink />
      {/* eslint-disable-next-line */}
      <a
        href="https://www.producthunt.com/posts/ai-canvas?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-ai&#0045;canvas"
        target="_blank"
      >
        {/* eslint-disable-next-line */}
        <img
          src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=367468&theme=dark"
          alt="AI&#0032;Canvas - Multiplayer&#0032;image&#0032;generation&#0032;canvas | Product Hunt"
          style={{ width: '100%', height: '54px', padding: '8px' }}
          width="250"
          height="54"
        />
      </a>
      <NominateButton />
    </MenuContainer>
  )
}

function Sider() {
  const isMobile = useIsMobile()
  return (
    <SiderStyled>
      {!isMobile && <WorkspaceSelector />}
      <Menu />
    </SiderStyled>
  )
}

export default Sider
