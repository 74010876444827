import { useNavigate } from 'react-router'
import styled from 'styled-components'

import Button from 'components/common/Button'
import { RedirectWithParams } from 'components/common/RedirectWithParams'
import { Text } from 'components/common/Text'
import LandingHeader from 'components/landing/LandingHeader'

import { Heading, Page } from './AuthenticationStyles'

export const FrozenAccount = ({ authed }: { authed: boolean }) => {
  const navigate = useNavigate()

  const handleLogout = () => {
    navigate('/logout')
  }

  if (!authed) {
    return <RedirectWithParams to="/" />
  }

  return (
    <>
      <LandingHeader isNotFixed={false} isPure />
      <Page>
        <Heading>Uh oh 😔</Heading>
        <StyledTextWrapper>
          <Text size="lg" center>
            It looks like there is an issue with your account. Please get in
            contact with us and we will get you back up and running again
          </Text>
        </StyledTextWrapper>
        <Button variant="primary" onClick={handleLogout}>
          Log out
        </Button>
      </Page>
    </>
  )
}

const StyledTextWrapper = styled.div`
  margin: 0 0 32px;
  max-width: 512px;
`
