import { Search } from '@styled-icons/bootstrap/Search'
import * as queryString from 'query-string'
import { useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { withFirestore } from 'react-redux-firebase'
import { useLocation, useNavigate } from 'react-router'
import { compose } from 'recompose'
import styled, { css } from 'styled-components'

import Button from 'components/common/Button'
import Flex from 'components/common/Flex'
import IconButton from 'components/common/IconButton'
import Modal from 'components/common/Modal'
import PermissionWrapper from 'components/common/PermissionWrapper'
import { Spacer } from 'components/common/Spacer'
import { Text } from 'components/common/Text'
import { Tooltip } from 'components/common/Tooltip/Tooltip'
import ItemView from 'components/item/ItemView'
import SearchCreation from 'components/search/SearchCreation'
import SelectBoardAndProject from 'components/selectBoardAndProject/SelectBoardAndProject'
import { useUserTasks } from 'features/home/setup-tasks-row/useUserTasks'
import useIsMobile from 'hooks/useIsMobile'
import useWorkspacePermissions from 'hooks/useWorkspacePermissions'

import {
  useDeleteProjectMutation,
  useUserProjectsQuery,
} from '../../generated/graphql'
import callCloudFunction from '../../helpers/callCloudFunction'
import Loading from '../Loading'
import FullscreenBackground from '../common/FullscreenBackground'
import { createBoard, createProject } from '../project/helpers'
import { LearnMoreButton } from './LearnMoreButton'
import { ProjectCard } from './ProjectCard'
import { RecentBoards } from './RecentBoards'

const ExistingProjects = ({ projects, deleteProject }) => {
  if (projects.length === 0) {
    return 'No projects'
  }
  return (
    <Flex flexWrap="wrap" flexGap={1}>
      {[...projects]
        .sort(
          (a, b) =>
            new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime() // DESC
        )
        // using native sort because we don't have lodash in deps （ﾟДﾟ）
        .map((project) => (
          <ProjectCard
            project={project}
            projectId={project.id}
            key={project.id}
            deleteProject={deleteProject}
          />
        ))}
    </Flex>
  )
}

const Dashboard = (props) => {
  const isMobile = useIsMobile()
  const location = useLocation()
  const navigate = useNavigate()
  const [addProjectInProgress, setAddProjectInProgress] = useState(false)
  const [shouldShowSearchProjects, setShouldShowSearchProjects] =
    useState(false)
  const userAllowedTo = useWorkspacePermissions(['ADD_PROJECT'])

  const parsed = queryString.parse(location.search, {
    arrayFormat: 'comma',
  })

  const { completeUserTask } = useUserTasks()

  const {
    data,
    loading: isLoadingProjects,
    refetch,
  } = useUserProjectsQuery({
    variables: { workspaceId: props.activeWorkspace.id },
    fetchPolicy: 'cache-and-network',
    pollInterval: 30000,
  })

  const [deleteProjectMutation] = useDeleteProjectMutation()

  const handleCreateProject = async () => {
    completeUserTask('createProject')

    // Set in progress
    setAddProjectInProgress(true)

    // Create a project
    const { projectId } = await createProject({
      workspaceId: props.activeWorkspace.id,
      email: props.email,
      firestore: props.firestore,
      title: 'Untitled project',
      uid: props.uid,
    })

    // Create a board inside the project
    const { boardId } = await createBoard({
      email: props.email,
      projectId,
      workspaceId: props.activeWorkspace.id,
      uid: props.uid,
      firestore: props.firestore,
      title: 'Untitled board',
    })

    // Open the project/board
    navigate(`/${props.activeWorkspace.url}/p/${projectId}/${boardId}`)
  }

  const handleCreateTemplate = async ({ title, templateId }) => {
    completeUserTask('createProject')

    setAddProjectInProgress(true)
    const { projectId: templateProjectId } = await callCloudFunction(
      'createTemplateProject',
      {
        uid: props.uid,
        workspaceId: props.activeWorkspace.id,
        title,
        templateId,
      }
    )
    if (!templateProjectId) {
      setAddProjectInProgress(false)
      return
    }
    navigate(`/${props.activeWorkspace.url}/p/${templateProjectId}`)
  }

  const handleDeleteProject = async (projectId) => {
    await deleteProjectMutation({
      variables: {
        projectId,
      },
    })
    refetch({ workspaceId: props.activeWorkspace.id })
  }

  const workspaceProjects = data?.projects?.projects
  const isLoading = workspaceProjects === undefined || isLoadingProjects

  if (addProjectInProgress) {
    return (
      <>
        <FullscreenBackground>
          <Heading>Setting things up</Heading>
          This might take a minute ...
        </FullscreenBackground>
      </>
    )
  }

  return (
    <>
      <SearchCreation
        placeholder={
          isMobile
            ? 'Search workspace items'
            : 'Type to search for items in your workspace'
        }
        shouldDisplayUploadButton
        shouldDisplayNotifications
      />
      {parsed.item && <ItemView />}
      <ProjectsContainer>
        <Flex alignItems="center">
          <Text size="xl" bold color="neutral.0" nowrap ellipsis>
            Projects
          </Text>
          <Spacer factor={2} axis="x" />
          <LearnMoreButton />
        </Flex>

        <Spacer factor={5} />
        <RecentBoards workspaceUrl={props.activeWorkspace.url} />
        <Spacer factor={3} />
        <CreateProjectContainer
          allowedToAddProject={userAllowedTo.ADD_PROJECT}
          handleCreateTemplate={handleCreateTemplate}
          handleCreateProject={handleCreateProject}
        />
        <Spacer factor={3} />
        <StyledProjectHeadlineBar alignItems="center" py={1}>
          <Text size="lg" bold color="neutral.0" nowrap ellipsis>
            Open an existing project
          </Text>
          <Spacer factor={2} axis="x" />
          <Tooltip title="Search projects">
            <IconButton
              Icon={Search}
              variant="squared"
              size={32}
              onClick={() => setShouldShowSearchProjects(true)}
            />
          </Tooltip>
        </StyledProjectHeadlineBar>
        <Spacer factor={1} />
        {isLoading ? (
          <Loading />
        ) : (
          <ExistingProjects
            projects={workspaceProjects}
            deleteProject={handleDeleteProject}
          />
        )}
      </ProjectsContainer>
      <Modal isOpen={shouldShowSearchProjects}>
        <StyledSearchProjectWrapper>
          <SelectBoardAndProject
            headline="Jump to project"
            selectType="SELECT_PROJECT"
            onSelectProject={({ projectId }) =>
              navigate(`/${props.activeWorkspace.url}/p/${projectId}`)
            }
            onMenuClose={() => setShouldShowSearchProjects(false)}
            isCreateNewProjectHidden
            projectAccessLevel="reader"
          />
        </StyledSearchProjectWrapper>
      </Modal>
    </>
  )
}

const TemplateCard = ({
  allowedToAddProject,
  handleCreateTemplate,
  templateId,
  unlockedAt,
  children,
}) => {
  const workspaceItemCount = useSelector(
    (state) => state.firestore.data.workspaceItemCounter?.merged_count
  )
  return (
    <PermissionWrapper
      css="display:block!important"
      deniedMessage={
        allowedToAddProject ? `Import ${unlockedAt} items to unlock` : undefined
      }
      feature={allowedToAddProject ? 'this template' : 'create a project'}
      isAllowed={allowedToAddProject && workspaceItemCount > unlockedAt}
    >
      <Button
        variant="darkSecondary"
        onClick={() =>
          handleCreateTemplate({ templateId, title: 'Untitled project' })
        }
      >
        {children}
      </Button>
    </PermissionWrapper>
  )
}

const CreateProjectContainer = ({
  allowedToAddProject,
  handleCreateTemplate,
  handleCreateProject,
}) => {
  return (
    <Flex flexWrap="wrap" flexGap={1}>
      <Flex flexDirection="column">
        <Text size="sm" color="neutral.4">
          Start fresh
        </Text>
        <Spacer factor={1} />
        <div>
          <PermissionWrapper
            css="display:block!important"
            feature="create a project"
            isAllowed={allowedToAddProject}
          >
            <Button
              variant="primary"
              onClick={handleCreateProject}
              data-intercom-target="Projects Create Project" // Used for Intercom product tours
            >
              New project
            </Button>
          </PermissionWrapper>
        </div>
      </Flex>
      <Flex flexDirection="column">
        <Text size="sm" color="neutral.4">
          Or use a template
        </Text>
        <Spacer factor={1} />
        <div>
          <TemplateCard
            allowedToAddProject={allowedToAddProject}
            handleCreateTemplate={handleCreateTemplate}
            templateId="BASICS_1"
            unlockedAt={500}
          >
            Getting started template
          </TemplateCard>
        </div>
      </Flex>
    </Flex>
  )
}

const mapStateToProps = (state) => ({
  uid: state.firebase.auth.uid,
  email: state.firebase.auth.email,
  activeWorkspace: state.content.activeWorkspace,
})

export default compose(connect(mapStateToProps), withFirestore)(Dashboard)

const StyledSearchProjectWrapper = styled.div`
  width: 300px;
  height: 375px;
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  overflow: hidden;
`

const ProjectsContainer = styled.div(
  ({ theme }) => css`
    padding: 32px;
    height: calc(100vh - 60px); // minus navigation height
    overflow-y: scroll;
    background: ${theme.colors.background[0]};
    @media (max-width: ${theme.breakpoints.md}px) {
      padding: 16px;
    }
  `
)

const StyledProjectHeadlineBar = styled(Flex)(
  ({ theme }) => css`
    position: sticky;
    top: -32px;
    background: inherit;
    z-index: 1;
    @media (max-width: ${theme.breakpoints.md}px) {
      top: -16px;
    }
  `
)

const Heading = styled.div(({ theme }) => ({
  fontFamily: theme.fontFamily.heading,
  fontSize: theme.fontSizes.lg,
  color: theme.colors.text.neutral[0],
  fontWeight: 'bold',
  marginBottom: '16px',
}))
