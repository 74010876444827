import { ChevronDown } from '@styled-icons/boxicons-regular/ChevronDown'
import React from 'react'

import { useLocalStorage } from 'hooks/useLocalStorage'

import { NavSubTitle } from './MenuStyles'

interface MenuSectionProps {
  siderSplitValue: number
  title: string
  withChildrenToggle?: boolean
  children: React.ReactNode
}

const MenuSection: React.FC<MenuSectionProps> = ({
  siderSplitValue,
  title,
  withChildrenToggle,
  children,
}) => {
  const [isChildrenVisible, setIsChildrenVisible] = useLocalStorage<boolean>(
    `nav-section-toggle-${title}`,
    true
  )
  if (!children) return null
  return (
    <>
      {/* @ts-ignore */}
      <NavSubTitle siderSplitValue={siderSplitValue}>
        {title}
        {withChildrenToggle && (
          <ChevronDown
            onClick={() => setIsChildrenVisible((prev) => !prev)}
            css="height: 21px;cursor:pointer;"
            style={{
              transform: `rotate(${isChildrenVisible ? '0deg' : '270deg'})`,
            }}
          />
        )}
      </NavSubTitle>
      {isChildrenVisible && children}
    </>
  )
}

export default MenuSection
