import React from 'react'
import styled, { css } from 'styled-components'

const Container = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.text.neutral[0]};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    pointer-events: none;
  `}
`

const Title = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.base};
  margin-bottom: 0;
`

const Subtitle = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  margin-bottom: 0;
`

interface HeaderProps {
  title: string
  subtitle: string
}

const Header: React.FC<HeaderProps> = ({ title, subtitle }) => (
  <Container>
    <Title>{title}</Title>
    <Subtitle>{subtitle}</Subtitle>
  </Container>
)

export default Header
