import { useRef } from 'react'
import { v4 as uuid } from 'uuid'

import { UploadFlowCanceled } from 'helpers/tracking/schemas/UploadFlowCanceled'
import { UploadFlowItemsAdded } from 'helpers/tracking/schemas/UploadFlowItemsAdded'
import { UploadFlowItemsRemoved } from 'helpers/tracking/schemas/UploadFlowItemsRemoved'
import { UploadFlowItemsSkipped } from 'helpers/tracking/schemas/UploadFlowItemsSkipped'
import { UploadFlowStarted } from 'helpers/tracking/schemas/UploadFlowStarted'
import { UploadFlowWorkspaceChanged } from 'helpers/tracking/schemas/UploadFlowWorkspaceChanged'
import { UploadMaxBytesLimitHit } from 'helpers/tracking/schemas/UploadMaxBytesLimitHit'
import { UploadMaxFilesLimitHit } from 'helpers/tracking/schemas/UploadMaxFilesLimitHit'
import { UploadPlanQuotaHit } from 'helpers/tracking/schemas/UploadPlanQuotaHit'
import {
  trackUploadFlowCanceled,
  trackUploadFlowItemsAdded,
  trackUploadFlowItemsRemoved,
  trackUploadFlowItemsSkipped,
  trackUploadFlowStarted,
  trackUploadFlowWorkspaceChanged,
  trackUploadMaxBytesLimitHit,
  trackUploadMaxFilesLimitHit,
  trackUploadPlanQuotaHit,
} from 'helpers/tracking/tracking'

type TrackingInput<T> = Omit<T, 'uploadFlowId'>

const useUploadTracking = () => {
  const uuidRef = useRef(uuid())
  const track = {
    trackUploadFlowStarted: (context: TrackingInput<UploadFlowStarted>) => {
      trackUploadFlowStarted({ ...context, uploadFlowId: uuidRef.current })
    },
    trackUploadFlowCanceled: (context: TrackingInput<UploadFlowCanceled>) => {
      trackUploadFlowCanceled({ ...context, uploadFlowId: uuidRef.current })
    },
    trackUploadFlowItemsAdded: (
      context: TrackingInput<UploadFlowItemsAdded>
    ) => {
      trackUploadFlowItemsAdded({ ...context, uploadFlowId: uuidRef.current })
    },
    trackUploadFlowItemsSkipped: (
      context: TrackingInput<UploadFlowItemsSkipped>
    ) => {
      trackUploadFlowItemsSkipped({ ...context, uploadFlowId: uuidRef.current })
    },
    trackUploadFlowItemsRemoved: (
      context: TrackingInput<UploadFlowItemsRemoved>
    ) => {
      trackUploadFlowItemsRemoved({ ...context, uploadFlowId: uuidRef.current })
    },
    trackUploadPlanQuotaHit: (context: TrackingInput<UploadPlanQuotaHit>) => {
      trackUploadPlanQuotaHit({ ...context, uploadFlowId: uuidRef.current })
    },
    trackUploadMaxBytesLimitHit: (
      context: TrackingInput<UploadMaxBytesLimitHit>
    ) => {
      trackUploadMaxBytesLimitHit({ ...context, uploadFlowId: uuidRef.current })
    },
    trackUploadMaxFilesLimitHit: (
      context: TrackingInput<UploadMaxFilesLimitHit>
    ) => {
      trackUploadMaxFilesLimitHit({ ...context, uploadFlowId: uuidRef.current })
    },
    trackUploadFlowWorkspaceChanged: (
      context: TrackingInput<UploadFlowWorkspaceChanged>
    ) => {
      trackUploadFlowWorkspaceChanged({
        ...context,
        uploadFlowId: uuidRef.current,
      })
    },
  }

  return { track, uploadFlowTrackingId: uuidRef.current }
}

export default useUploadTracking
