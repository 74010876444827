import { useSelector } from 'react-redux'
import { useOutletContext } from 'react-router-dom'
import styled from 'styled-components'

import { DiscoveryNewest } from 'components/discover/DiscoveryNewest'
import { RootState } from 'store'

import { SubPageHeader } from './components/SubPageHeader'

const StyledContainer = styled.div`
  padding: 0 16px 0;
`

export const DiscoverNewItems = () => {
  const currentUserId = useSelector(
    (store: RootState) => store.firebase.auth.uid
  )
  const { wrapperRef, trackingSessionId = '' } = useOutletContext<{
    trackingSessionId?: string
    wrapperRef?: HTMLDivElement | null
  }>()

  return (
    <StyledContainer>
      <SubPageHeader
        title="New items"
        option="NEW"
        trackingSessionId={trackingSessionId}
      />
      {wrapperRef && (
        <DiscoveryNewest
          scrollElement={wrapperRef}
          currentUserId={currentUserId}
          trackingSessionId={trackingSessionId}
        />
      )}
    </StyledContainer>
  )
}
