type EraseCursorProps = {
  eraserSize: number
  scale: number
  isErasing: boolean
}

export const EraseCursor = ({
  eraserSize,
  scale,
  isErasing,
}: EraseCursorProps) => {
  return (
    <div
      className="erase-cursor"
      style={{
        width: eraserSize * scale,
        height: eraserSize * scale,
        display: isErasing ? 'block' : 'none',
      }}
      css="z-index: 2;transform: translate(-50%, -50%); pointer-events: none; position: fixed; top: 0; left: 0; background: #ff000022; border: 2px solid #ff313121; border-radius: 50%;"
    />
  )
}
