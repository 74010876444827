import { Lightning } from 'styled-icons/bootstrap'
import { Diamond } from 'styled-icons/ionicons-outline'

import UserAvatar from '../../../components/common/UserAvatar'
import { useOthers } from '../../../config/liveblocks.config'
import { CreditsUserQuery, EditSessionQuery } from '../../../generated/graphql'
import useIsMobileDevice from '../../../hooks/useIsMobileDevice'
import { StyledButtonMedium } from '../ButtonMedium'
import { cursorColors } from '../CursorsOthers'
import { StyledGradientButton } from './PowerUpComboButtonStyles'

type Props = {
  showBuyCreditsModal: boolean
  setShowBuyCreditsModal: (show: boolean) => void
  showPowerUpModal: boolean
  setShowPowerUpModal: (show: boolean) => void
  userCreditData: CreditsUserQuery | undefined
  editSession: EditSessionQuery | undefined
  disableControls: boolean
  isLoading: boolean
}

export const PowerUpComboButton = ({
  showBuyCreditsModal,
  setShowBuyCreditsModal,
  setShowPowerUpModal,
  showPowerUpModal,
  userCreditData,
  disableControls,
  isLoading,
  editSession,
}: Props) => {
  const others = useOthers()
  const isMobileDevice = useIsMobileDevice()
  if (others.length > 0 && editSession?.editSession?.powerUp?.isPowered) {
    const members = [
      ...(editSession.editSession.powerUp.enrichedPowerUpMembers ?? []),
    ]
    return (
      <StyledGradientButton
        onClick={() => setShowPowerUpModal(!showPowerUpModal)}
        disabled={disableControls || isLoading}
        hasText
      >
        <Lightning width={16} /> Powered by{' '}
        <span style={{ display: 'flex' }}>
          {members.map(({ imageUrl, name }, i) => (
            <span
              key={imageUrl}
              style={{
                width: 12,
              }}
            >
              <UserAvatar
                photoUrl={
                  imageUrl && imageUrl !== 'undefined' ? imageUrl : null
                }
                displayName={name}
                size={16}
                color={cursorColors[i % cursorColors.length]}
              />
            </span>
          ))}
        </span>
      </StyledGradientButton>
    )
  }

  const numCredits =
    userCreditData?.user.generationRequests?.availableCredits ?? 0

  if (
    others.length > 0 &&
    !editSession?.editSession?.powerUp?.isPowered &&
    numCredits > 0
  ) {
    return (
      <StyledButtonMedium
        onClick={() => setShowPowerUpModal(!showPowerUpModal)}
        disabled={disableControls || isLoading}
        hasText
      >
        <Lightning width={16} />
        {isMobileDevice ? 'Power up' : 'Power this room'}
      </StyledButtonMedium>
    )
  }

  return (
    <StyledButtonMedium
      onClick={() => setShowBuyCreditsModal(!showBuyCreditsModal)}
      disabled={disableControls || isLoading}
      hasText
    >
      <Diamond width={16} />
      {numCredits} credits left
    </StyledButtonMedium>
  )
}
