import { useState } from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { Spacer } from 'components/common/Spacer'
import { Text } from 'components/common/Text'
import { HighlightRow } from 'components/highlight-row/HighlightRow'
import { useDiscoverIdeasForRecentBoardsQuery } from 'generated/graphql'
import { useActiveWorkspace } from 'hooks/useActiveWorkspace'
import useCurrentUser from 'hooks/useCurrentUser'

import { IdeaForBoardCard } from '../components/IdeaForBoardCard'

const StyledLink = styled(Link)`
  ${({ theme }) => css`
    color: ${theme.colors.text.neutral[0]};
    &:hover {
      color: ${theme.colors.accent[2]};
    }
  `}
`

type SavedItems = {
  [key: string]: string[]
}

export const IdeasForBoardsRow = ({
  trackingSessionId,
}: {
  trackingSessionId: string
}) => {
  const activeWorkspace = useActiveWorkspace()!
  const currentUser = useCurrentUser()
  const [savedItems, setSavedItems] = useState<SavedItems>({})
  const { data, loading } = useDiscoverIdeasForRecentBoardsQuery({
    variables: {
      first: 10,
      workspaceId: activeWorkspace.id,
    },
    fetchPolicy: 'cache-and-network',
    context: {
      batch: false,
    },
  })

  const boards = data?.discoverIdeasForRecentBoards || []

  const onClick = (itemId: string, boardId: string) => {
    setSavedItems((prevState) => ({
      ...prevState,
      [boardId]: [...(prevState[boardId] ? prevState[boardId] : []), itemId],
    }))
  }

  const items = boards
    .map((board) => ({
      ...board,
      items: board.items.filter((item) =>
        savedItems[board.boardId]
          ? !savedItems[board.boardId].find((itemId) => item.id === itemId)
          : true
      ),
    }))
    .map((board) => {
      return (
        <div key={board.boardId}>
          <HighlightRow
            key={board.boardId}
            title={
              <Text bold size="lg" color="neutral.0" ellipsis>
                <StyledLink
                  to={`/${activeWorkspace.url}/p/${board?.projectId}/${board?.boardId}`}
                >
                  {`Ideas for your "${board.title}" board`}
                </StyledLink>
              </Text>
            }
            link={{
              path: `board-ideas/${board.boardId}`,
              text: `More ideas for ${board.title}`,
            }}
            listItems={board.items}
            isLoading={loading && !board.items.length}
            renderListItem={(item, index) => {
              const gridItemProps = {
                item,
                index,
                height: 140,
                currentUserId: currentUser.uid,
                boardId: board.boardId,
                boardTitle: board.title,
                projectId: board.projectId,
                trackingSessionId,
              }
              return (
                <IdeaForBoardCard
                  {...gridItemProps}
                  onClick={() => onClick(item.id, board.boardId)}
                />
              )
            }}
          />
          <Spacer factor={5} />
        </div>
      )
    })

  return <>{items}</>
}
