import { ChevronDown } from '@styled-icons/bootstrap/ChevronDown'
import { FilterList } from '@styled-icons/material/FilterList'
import { motion } from 'framer-motion'
import React, { Fragment, useState } from 'react'
import styled, { css } from 'styled-components'
import {
  DelimitedArrayParam,
  useQueryParam,
  withDefault,
} from 'use-query-params'

import Flex from 'components/common/Flex'
import { Margin } from 'components/common/Margin'
import { Text } from 'components/common/Text'
import {
  EmojiButton,
  allReactionsEmojis,
} from 'components/item/itemReactions/ItemReactionsBox'

import { trackBoardFilterChanged } from '../../helpers/tracking/tracking'

type BoardFilterOptionsProps = {
  boardId: string
  workspaceId: string
}

const BoardFilterOptions = (props: BoardFilterOptionsProps) => {
  const [reactionFilters, setReactionFilters] = useQueryParam(
    'reactionFilters',
    withDefault(DelimitedArrayParam, [] as string[])
  )
  const [isFiltersOpen, setIsFiltersOpen] = useState(reactionFilters.length > 0)

  const handleToggleFilters = () => {
    setIsFiltersOpen((prev) => !prev)
  }

  const updateBoardItemsReactionFilter = (reaction: string) => {
    if (reactionFilters.includes(reaction)) {
      setReactionFilters((existingReactions) => {
        const newReactionFilters = (existingReactions || []).filter(
          (existingReaction) => existingReaction !== reaction
        )
        if (newReactionFilters.length === 0) return undefined
        return newReactionFilters
      })
      trackBoardFilterChanged({
        boardId: props.boardId,
        workspaceId: props.workspaceId,
        changeType: 'remove',
      })
    } else {
      setReactionFilters((existingReactions) => [
        ...(existingReactions || []),
        reaction,
      ])
      trackBoardFilterChanged({
        boardId: props.boardId,
        workspaceId: props.workspaceId,
        changeType: 'add',
        isFirst: reactionFilters.length === 0,
      })
    }
  }

  return (
    <StyledFilterOptions>
      <StyledFilterOptionsHeadline onClick={handleToggleFilters}>
        <Text size="base" css="flex-shrink:0;font-weight:400">
          Filter
        </Text>
        <Margin x={6} />
        {isFiltersOpen ? (
          <ChevronDown css="flex-shrink:0" style={{ height: 12 }} />
        ) : (
          <FilterList css="flex-shrink:0;" style={{ height: 18 }} />
        )}
      </StyledFilterOptionsHeadline>
      {isFiltersOpen && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
        >
          <Margin y={2} />
          <Flex css="font-size:14px;">
            {allReactionsEmojis.map((emoji) => (
              <Fragment key={emoji}>
                <EmojiButton
                  onClick={() => updateBoardItemsReactionFilter(emoji)}
                  isSelected={reactionFilters.includes(emoji)}
                  css={css`
                    border-radius: ${({ theme }) => theme.borderRadius.default};
                    width: 28px;
                  `}
                >
                  {emoji}
                </EmojiButton>
                <Margin x={6} />
              </Fragment>
            ))}
          </Flex>
        </motion.div>
      )}
    </StyledFilterOptions>
  )
}

export { BoardFilterOptions }

const StyledFilterOptions = styled.div`
  position: absolute;
  bottom: 0;
  right: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const StyledFilterOptionsHeadline = styled(motion.div)`
  ${({ theme }) => css`
    display: flex;
    align-self: flex-end;
    align-items: center;
    cursor: pointer;
    color: ${theme.colors.text.neutral[0]};
    &:hover {
      color: ${theme.colors.accent[2]};
    }
  `}
`
