import React from 'react'

import useIsMobile from 'hooks/useIsMobile'

import NominationPanel from './NominationPanel'

const NominationView = () => {
  const isMobile = useIsMobile()
  return (
    <div
      css={`
        margin: 4rem auto;
        width: ${isMobile ? '600px' : '800px'};
        max-width: calc(100% - 32px);
      `}
    >
      <NominationPanel />
    </div>
  )
}

export default NominationView
