import { useEffect, useRef } from 'react'

export default function useKeyupListener(key, callback) {
  /** @type {React.MutableRefObject<Function>} */
  const callbackRef = useRef()
  useEffect(() => {
    callbackRef.current = callback
  })
  useEffect(() => {
    const handleKeyup = (e) => {
      if (e.key === key && callbackRef.current) {
        callbackRef.current(e)
      }
    }
    document.addEventListener('keyup', handleKeyup)
    return () => document.removeEventListener('keyup', handleKeyup)
  }, [key])
}
