import { Eye } from '@styled-icons/bootstrap/Eye'
import { Copy } from '@styled-icons/feather/Copy'
import { Download } from '@styled-icons/feather/Download'
import { Search } from '@styled-icons/feather/Search'
import { TagFaces } from '@styled-icons/material-rounded/TagFaces'
import React from 'react'

import { copyItemLink, download, openItem } from '../item/itemActions'
import { submitSearch } from '../search/searchActions'

export function generateCommands(props) {
  return [
    {
      text: 'Download item',
      icon: <Download />,
      conditions: [props.focusedItem !== undefined],
      action: (props_) => download(props_.focusedItem),
      shortcut: {
        key: 'D',
        modifier: 'alt',
      },
    },
    {
      text: 'Copy item link',
      icon: <Copy />,
      conditions: [props.focusedItem !== undefined],
      action: (props_) => copyItemLink(props_.focusedItem),
      shortcut: {
        key: 'C',
        modifier: 'alt',
      },
    },
    {
      text: 'Open item',
      conditions: [props.focusedItem !== undefined],
      icon: <Eye />,
      action: (props_) =>
        openItem(props_.focusedItem.id, props_.location, props_.navigate),
      shortcut: {
        key: 'space',
      },
    },
    {
      text: 'Face search',
      icon: <TagFaces />,
      action: (props_) => {
        // const searchInputNode = document.getElementById('searchInput')
        // searchInputNode.value = '/face'
        props_.navigate(
          `/${props_.match.params.workspaceUrl}/library?search=true`
        )
        props_.setSearchInput('/face')
        props_.setSearchActive(true)
        // return document.getElementById('searchInput')
      },
    },
    {
      text: props.input ? `Search for '${props.input}'` : 'Search',
      icon: <Search />,
      action: () => {
        if (props.input) {
          submitSearch({
            searchPhrase: props.input,
            searchSpaceKey: 'uploads',
            ...props,
          })
        }
        return document.getElementById('searchInput')
      },
    },

    // {
    //   text: 'Save item to board',
    //   icon: <PlusCircle/>,
    //   action: () => {}
    // },

    // {
    //   text: 'Send item to ...',
    //   icon: <Send/>,
    //   action: () => {}
    // }
  ].filter((cmd) => {
    if (
      cmd.conditions &&
      !cmd.conditions.every((condition) => condition === true)
    ) {
      return false
    }
    if (props.input.length === 0) return true
    return cmd.text.toLowerCase().includes(props.input)
  })
}
