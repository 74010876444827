import { v4 as uuid } from 'uuid'

import type { DBItem, DBPlan } from 'types/db'

import { persistFileIds } from '../../clientPersistence/uploadSessionsDb'
import { db, firebase, storage } from '../../config/firebase'
import { debugLog } from '../../helpers/utilityFunctions'
import type {
  CustomDropboxFile,
  CustomFile,
  MappedFiles,
  UploadSession,
} from './types'
import { UploadOptions } from './types'
import useUploadTracking from './useUploadTracking'

// Set REACT_APP_UPLOAD_PERSISTANCE to "true" in .env to enable persistent and resumable uploads.
export const enableUploadPersistance =
  process.env.REACT_APP_UPLOAD_PERSISTANCE?.toLowerCase() === 'true'

export const uploadLimitBytes = 7500000000 // 7.5 GB
export const uploadLimitFileCount = 8000

export type ConstrainInfo =
  | 'MAX_BYTES_REACHED'
  | 'MAX_FILE_COUNT_REACHED'
  | 'MAX_PLAN_LIMIT_REACHED'
  | null

interface ConstrainFileUploadOutput {
  constrainedResult: MappedFiles
  totalSize: number
  isConstrained: boolean
  constrainInfo: ConstrainInfo
}

export const constrainFileUpload = (
  mappedFiles: MappedFiles,
  remainingUploads: number,
  track: ReturnType<typeof useUploadTracking>['track'],
  uploadType: string | null,
  workspacePlan: DBPlan | undefined
): ConstrainFileUploadOutput => {
  const constrainedResult: MappedFiles = {}
  let totalSize = 0
  let isConstrained = false
  let constrainInfo: ConstrainInfo = null

  const files = Object.entries(mappedFiles)
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, file] of files) {
    const newTotal =
      totalSize + ('isDropboxFile' in file ? file.bytes : file.size)
    if (newTotal > uploadLimitBytes) {
      isConstrained = true
      constrainInfo = 'MAX_BYTES_REACHED'
      track.trackUploadMaxBytesLimitHit({
        limit: uploadLimitBytes,
        uploadType,
      })
      break
    }
    if (Object.keys(constrainedResult).length >= uploadLimitFileCount) {
      isConstrained = true
      constrainInfo = 'MAX_FILE_COUNT_REACHED'
      track.trackUploadMaxFilesLimitHit({
        nbrOfItems: files.length,
        limit: uploadLimitFileCount,
        uploadType,
      })
      break
    }
    if (Object.keys(constrainedResult).length >= remainingUploads) {
      isConstrained = true
      constrainInfo = 'MAX_PLAN_LIMIT_REACHED'
      track.trackUploadPlanQuotaHit({
        nbrOfItems: files.length,
        nbrOfItemsLeftOnPlan: remainingUploads,
        uploadType,
        workspacePlanName: workspacePlan?.presentation?.name ?? null,
      })
      break
    }
    totalSize = newTotal
    constrainedResult[key] = file
  }
  return { constrainedResult, totalSize, isConstrained, constrainInfo }
}

export const getFormattedSize = (size: number) => {
  const mb = size / 1000000
  if (mb >= 1000) return `${(mb / 1000).toFixed(2)} GB`
  if (mb >= 1) return `${mb.toFixed(2)} MB`
  return `${(mb * 1000).toFixed(0)} KB`
}

const extensionRegexp = /(?:\.([^.]+))?$/
export const getExtensionByFileName = (fileName: string) => {
  return extensionRegexp.exec(fileName)?.[1]
}

export function createFileUploader(
  workspaceId: string,
  boardId: string | undefined,
  projectId: string | undefined,
  updateSession: (
    sessionId: string,
    data:
      | Partial<UploadSession>
      | ((uploadSession: UploadSession) => Partial<UploadSession>)
  ) => void,
  uid: string,
  uploadOptions: UploadOptions,
  tags?: { id: string; description: string }[]
) {
  return function uploadFile(
    file: CustomFile,
    sessionRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
  ): Promise<void> {
    const typeCategory = file.type.split('/')[0]
    const extension = file.type.split('/')[1]

    if (typeCategory !== 'image' || !extension) {
      return Promise.reject(new Error('Upload type is not image'))
    }
    if (!workspaceId) return Promise.reject(new Error('No workspace id'))

    const storageRef = storage.ref()
    const docRef = db
      .collection('workspaces')
      .doc(workspaceId)
      .collection('items')
      .doc()

    const uploadRef = storageRef.child(`originals/${docRef.id}.${extension}`)

    const metadata = {
      contentType: 'image/jpeg',
      customMetadata: {
        workspaceAccess: 'reader',
        workspaceId, // this is what we will use in firebase security rules
        owner: uid,
      },
    }

    const uploadTask = uploadRef.put(file, metadata)

    uploadTask.on('state_changed', (snapshot) => {
      const transferred = snapshot.bytesTransferred
      updateSession(sessionRef.id, (session) => ({
        bytesTransferredByFile: {
          ...session.bytesTransferredByFile,
          [docRef.id]: transferred,
        },
      }))
    })

    return uploadTask
      .then(async () => {
        const item: Partial<DBItem> = {
          workspaceId,
          uploadSessionId: sessionRef.id,
          workspaceAccess: 'reader',
          createdBy: uid,
          updatedBy: uid,
          finalized: false,
          shouldProcess: true,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          accessLevel: uploadOptions.isPublicUpload ? 'PUBLIC' : 'PRIVATE',
          original: {
            bucketName: storage.ref().bucket.toString(),
            path: `originals/${docRef.id}.${extension}`,
          },
          origin: {
            name: file.name,
            path: file.path ?? file.name,
            lastModified: file.lastModified ? file.lastModified : null,
            size: file.size ? file.size : null,
            type: 'desktop',
          },
          manuallyCreatedTags: tags || [],
        }

        /*
              ItemRef is stupidly named, but it's a copy of an item specifically tied to a board.
              Originally it was only intended to hold a reference to the searchableItem, but to enable filtering inside
              boards, it grew to include more information.
            */

        // Batching item and itemRef write is important, as we will check in backend for duplicates and move existing itemRefs to point to existing item
        const batch = db.batch()

        // If the upload process is tied to a board or project, make sure an itemRef is created
        if (boardId || projectId) {
          const itemRef = {
            aspectRatio: 1.5,
            itemId: docRef.id,
            projectId: projectId || null,
            boardId: boardId || null,
            favourited: false,
            ...item,
          }
          const itemRefRef = db.collection('itemRefs').doc()
          // Set the item ref
          batch.set(itemRefRef, itemRef)
          item.uploadItemRefId = itemRefRef.id
        }

        // Set the item
        batch.set(docRef, item)
        await batch.commit().then(() =>
          updateSession(sessionRef.id, (session) => ({
            completedUploads: session.completedUploads + 1,
          }))
        )
        if (enableUploadPersistance) {
          return persistFileIds(file, docRef.id)
        }
        return
      })
      .then(() => {
        debugLog('Uploaded file!')
      })
      .catch((e) => {
        debugLog('Error uploading files', e)
      })
  }
}

interface OutputFile {
  isFile: true
  name: string
  type: string
  file: CustomFile | CustomDropboxFile
  key: string
}

interface OutputFolder {
  isFolder: true
  name: string
  size: number
  items: { key: string; file: CustomFile }[]
}

export type OutputFolderAndFiles = Record<string, OutputFolder | OutputFile>

export const buildFolderAndFiles = (mappedFiles: MappedFiles) => {
  const output: OutputFolderAndFiles = {}

  Object.entries(mappedFiles).forEach(([key, file]) => {
    if ('isDropboxFile' in file) {
      output[key] = {
        isFile: true,
        name: file.name.split('.').slice(0, -1).join('.'),
        type: getExtensionByFileName(file.name) || '',
        file,
        key,
      }
      return
    }
    // if name and path is the same we beleive it is file, else it is a folder
    if (file.name === file.path) {
      output[key] = {
        isFile: true,
        name: file.name.split('.').slice(0, -1).join('.'),
        type: file.type.replace('image/', ''),
        file,
        key,
      }
    } else {
      const folderName = file.path!.split('/')?.[1]
      const folderEntry = output[folderName]

      if (!folderEntry) {
        output[folderName] = {
          isFolder: true,
          name: folderName,
          size: file.size,
          items: [{ key, file }],
        }
      } else {
        if (!('isFolder' in folderEntry)) return
        output[folderName] = {
          ...folderEntry,
          size: folderEntry.size + file.size,
          items: [...folderEntry.items, { key, file }],
        }
      }
    }
  })

  return output
}

export const getMappedFiles = (files: (CustomFile | CustomDropboxFile)[]) => {
  return files.reduce<MappedFiles>((acc, file) => {
    acc[uuid()] = file
    return acc
  }, {})
}
