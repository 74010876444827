import { QuestionCircle } from '@styled-icons/bootstrap/QuestionCircle'
import { Refresh } from '@styled-icons/material/Refresh'
import * as queryString from 'query-string'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import styled, { DefaultTheme, css } from 'styled-components'
import { v4 as uuid } from 'uuid'

import { Margin } from 'components/common/Margin'
import { Text } from 'components/common/Text'
import { TextSelect, TextSelectOption } from 'components/common/TextSelect'
import { Tooltip } from 'components/common/Tooltip/Tooltip'
import PublicItemView from 'components/item/PublicItemView'
import SearchCreation from 'components/search/SearchCreation'
import {
  trackDiscoverModeChanged,
  trackDiscoveryFeedRefreshed,
  trackDiscoveryOpened,
} from 'helpers/tracking/tracking'
import useIsMobile from 'hooks/useIsMobile'
import useQueryString from 'hooks/useQueryString'
import { RootState } from 'store'

import { DiscoveryMadeForYou } from './DiscoveryMadeForYou'
import { DiscoveryNewest } from './DiscoveryNewest'

const options: TextSelectOption[] = [
  {
    key: 'MADE_FOR_YOU',
    text: 'Made for you',
    link: 'made-for-you',
  },
  {
    key: 'BY_DATE',
    text: 'Newest',
    link: 'newest',
  },
]

const getModeKey = (modeLink: string): 'MADE_FOR_YOU' | 'BY_DATE' => {
  const modeOption = options.find((option) => option.link === modeLink)
  return (modeOption ? modeOption.key : 'MADE_FOR_YOU') as
    | 'MADE_FOR_YOU'
    | 'BY_DATE'
}

const DiscoverView = () => {
  const navigate = useNavigate()
  const [wrapperRef, setWrapperRef] = useState<HTMLDivElement | null>()
  const location = useLocation()
  const isMobile = useIsMobile()
  const { workspaceUrl } = useParams() as { workspaceUrl: string }
  const { mode } = useQueryString<{ mode: string }>()

  const trackingSessionId = useRef('')

  const currentUserId = useSelector(
    (store: RootState) => store.firebase.auth.uid
  )

  const [refreshMadeForYouCounter, setRefreshMadeForYouCounter] = useState(1)

  const discoveryMode = getModeKey(mode)

  useEffect(() => {
    const id = uuid()
    trackingSessionId.current = id
    trackDiscoveryOpened({
      trackingSessionId: id,
    })
  }, [])

  const parsed = queryString.parse(location.search, {
    arrayFormat: 'comma',
  })

  const handleRefresh = () => {
    setRefreshMadeForYouCounter((prev) => prev + 1)
    trackDiscoveryFeedRefreshed({
      trackingSessionId: trackingSessionId.current,
    })
  }

  const handleDiscoveryModeChange = (option: TextSelectOption) => {
    trackDiscoverModeChanged({
      trackingSessionId: trackingSessionId.current,
      discoveryMode: option.key,
    })
    navigate({
      search: `?mode=${option.link}`,
    })
  }

  return (
    <>
      <SearchCreation
        placeholder="Search public items on Kive"
        shouldDisplayNotifications
        searchPublic
      />
      <StyledWrapper ref={(ref) => setWrapperRef(ref)}>
        <StyledDiscoverInfoWrapper>
          {!isMobile && (
            <div css="display:flex">
              <Text size="sm" color="neutral.1">
                <b
                  css={`
                    ${({ theme }: { theme: DefaultTheme }) =>
                      css`
                        color: ${theme.colors.text.neutral[0]};
                      `}
                  `}
                >
                  Discover
                </b>{' '}
                inspiration from the Kive-community
              </Text>
              <Margin x={12} />

              <Tooltip
                interactive
                position="bottom"
                html={
                  <StyledDiscoverInfoPopup>
                    <Text size="sm" color="neutral.0">
                      Do you want to contribute? Try making your items public.{' '}
                      <br />
                      View{' '}
                      <Link
                        css="text-decoration:underline;color:currentColor"
                        to={`/${workspaceUrl}/profile`}
                      >
                        your public items
                      </Link>{' '}
                      or{' '}
                      <a
                        css="text-decoration:underline;color:currentColor"
                        href="http://help.kive.ai/en/articles/5681828-making-items-public"
                        target="_blank"
                        rel="noreferrer"
                      >
                        read documentation
                      </a>
                      .
                    </Text>
                  </StyledDiscoverInfoPopup>
                }
              >
                <QuestionCircle height="20px" />
              </Tooltip>
            </div>
          )}

          <div css="display:flex;align-items:center">
            <TextSelect
              css={`
                ${({ theme }: { theme: DefaultTheme }) =>
                  css`
                    color: ${theme.colors.text.neutral[0]};
                    width: 140px;
                    user-select: none;
                  `}
              `}
              options={options}
              activeOption={options.find((o) => o.key === discoveryMode)!}
              onOptionClick={handleDiscoveryModeChange}
            />{' '}
            {discoveryMode === 'MADE_FOR_YOU' && (
              <StyledRefreshIcon onClick={handleRefresh} />
            )}
          </div>
        </StyledDiscoverInfoWrapper>
        {parsed.item && (
          <PublicItemView
            isCloseable
            inDiscover
            trackingSessionId={trackingSessionId.current}
          />
        )}
        {wrapperRef && (
          <div>
            {discoveryMode === 'MADE_FOR_YOU' ? (
              <DiscoveryMadeForYou
                scrollElement={wrapperRef}
                currentUserId={currentUserId}
                trackingSessionId={trackingSessionId.current}
                key={refreshMadeForYouCounter}
              />
            ) : (
              <DiscoveryNewest
                scrollElement={wrapperRef}
                currentUserId={currentUserId}
                trackingSessionId={trackingSessionId.current}
              />
            )}
          </div>
        )}
      </StyledWrapper>
    </>
  )
}

const StyledWrapper = styled.div`
  overflow-y: scroll;
  max-height: calc(100vh - 60px);
  min-height: calc(100vh - 60px);
  padding: 0 16px 0;
`

const StyledDiscoverInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 56px;
  justify-content: space-between;
  top: 0;
  position: sticky;
  z-index: 1;
  background: ${({ theme }) => theme.colors.background[1]};
`

const StyledDiscoverInfoPopup = styled.div`
  display: flex;
  width: 400px;
  max-width: 90vw;
`

const StyledRefreshIcon = styled(Refresh)`
  ${({ theme }) => css`
    height: 25px;
    color: white;
    margin-left: 8px;
    cursor: pointer;
    background: ${theme.colors.background[5]};
    border-radius: ${theme.borderRadius.default};
    padding: 4px;
    &:hover {
      background: ${theme.colors.accent[2]};
    }
  `}
`

export default DiscoverView
