import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import { Outlet, useLocation, useMatch, useNavigate } from 'react-router-dom'

import { RedirectWithParams } from 'components/common/RedirectWithParams'
import useIsMobile from 'hooks/useIsMobile'
import { useMount } from 'hooks/useMount'
import { RootState } from 'store'

import { Pagination } from './SetupCommon'
import { SetupMobileStartView } from './SetupMobileStartView'
import { Main } from './SetupStyles'

const setupSteps = {
  profile: (onboarding: any) => onboarding.completedProfile === true,
  workspace: (onboarding: any) => onboarding.completedWorkspace === true,
  invite: (onboarding: any) => onboarding.completedInvite === true,
}

const Setup = () => {
  const navigate = useNavigate()
  const { pathname, search } = useLocation()
  const isExactMatch = !!useMatch('/setup')
  const isMobile = useIsMobile()

  const userId = useSelector((state: RootState) => state.firebase.auth.uid)
  const onboarding = useSelector(
    (state: RootState) => state.firestore.data.userPrivate?.onboarding
  )

  const newWorkspaceParams = new URLSearchParams(search).get('new')

  useFirestoreConnect([
    {
      collection: 'users',
      doc: userId,
      subcollections: [{ collection: 'private' }],
      storeAs: 'userPrivate',
    },
  ])

  const allSetupStepsAreCompleted =
    onboarding && !Object.values(onboarding).includes(false)

  useMount(() => {
    if (allSetupStepsAreCompleted && newWorkspaceParams !== 'true') {
      navigate('/')
    }
  })

  if (!onboarding) return null // Wait until onboarding is loaded

  if (isExactMatch && isMobile && !allSetupStepsAreCompleted) {
    return <SetupMobileStartView />
  }

  if (isExactMatch) {
    // if it is exact match (/setup) we check onboarding progress and redirect to correct step
    const toPath =
      Object.entries(setupSteps).find(
        ([, passCondition]) => passCondition(onboarding) === false
      )?.[0] ?? '/app'

    return <RedirectWithParams to={toPath} />
  }

  return (
    <Main isMobile={false}>
      <Pagination
        activeIndex={Object.keys(setupSteps).indexOf(
          pathname.replace('/setup/', '')
        )}
        length={Object.keys(setupSteps).length}
        delay={1}
      />
      <Outlet />
    </Main>
  )
}

export default Setup
