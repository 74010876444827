import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Spacer } from 'components/common/Spacer'
import { HighlightRow } from 'components/highlight-row/HighlightRow'
import { MagicBoardCard } from 'components/library/MagicBoards/MagicBoardCard'
import { useMagicBoardsQuery } from 'generated/graphql'
import {
  trackHomeMagicBoardClicked,
  trackMagicBoardClicked,
} from 'helpers/tracking/tracking'
import useIsMobile from 'hooks/useIsMobile'

type MagicBoardsRowProps = {
  workspaceId: string
  workspaceTitle: string
}

export const MagicBoardsRow = (props: MagicBoardsRowProps) => {
  const isMobile = useIsMobile()

  const { data } = useMagicBoardsQuery({
    variables: {
      workspaceId: props.workspaceId,
      first: 10,
    },
    fetchPolicy: 'cache-and-network',
    context: {
      batch: true,
    },
  })

  const magicBoards = data?.magicBoards.magicBoards || []

  if (magicBoards.length === 0) return null

  return (
    <>
      <HighlightRow
        title={
          isMobile ? 'Magic boards' : `Magic boards in ${props.workspaceTitle}`
        }
        link={{
          path: 'library/magic-boards',
          text: isMobile ? 'View all' : 'View all magic boards',
        }}
        listItems={magicBoards}
        renderListItem={(magicBoard) => {
          return (
            <Link
              to={`library/magic-boards/${magicBoard.id}`}
              onClick={trackHomeMagicBoardClicked}
            >
              <StyledMagicBoardCard
                thumbUrl={magicBoard.thumbUrl}
                description={magicBoard.description}
                count={magicBoard.count}
                onClick={() => {
                  trackMagicBoardClicked({
                    tagId: magicBoard.tagId,
                    tagDescription: magicBoard.description,
                    tagCount: magicBoard.count,
                    tagScore: magicBoard.score,
                  })
                }}
              />
            </Link>
          )
        }}
      />
      <Spacer factor={5} />
    </>
  )
}

const StyledMagicBoardCard = styled(MagicBoardCard)`
  width: 220px;
`
