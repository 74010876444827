import React from 'react'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { trackItemColorClicked } from 'helpers/tracking'
import { useActiveWorkspace } from 'hooks/useActiveWorkspace'

import HorizontalScroll from './HorizontalScroll'
import { ItemData } from './hooks/useGetItemData'

const ColorsContainer = styled.div`
  display: flex;
`

const ColorDot = styled.div(({ color }) => ({
  width: 24,
  height: 24,
  margin: '0 8px',
  borderRadius: '50%',
  display: 'inline-block',
  background: color,
  transition: 'transform ease 0.1s',
}))

const Strip = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 4px;
  cursor: pointer;
`

interface ItemColorsProps {
  colors: ItemData['colors']
  isPublic?: boolean
  inDiscover?: boolean
}

const ItemColors: React.FC<ItemColorsProps> = ({
  colors,
  isPublic,
  inDiscover,
}) => {
  const navigate = useNavigate()
  const activeWorkspace = useActiveWorkspace()

  function handleDotClick(color: string) {
    if (!activeWorkspace?.url) {
      return
    }
    if (isPublic && !inDiscover) {
      return
    }
    const color_ = encodeURIComponent(color)
    trackItemColorClicked()
    navigate(
      `/${activeWorkspace.url}/${
        inDiscover ? 'discover' : 'library'
      }?color=${color_}&search=true`
    )
  }
  return (
    <HorizontalScroll>
      <Strip onClick={(e) => e.stopPropagation()}>
        <ColorsContainer>
          {colors.map((color) => (
            <ColorDot
              key={color.id}
              color={color.hex}
              onClick={() => handleDotClick(color.hex)}
            />
          ))}
        </ColorsContainer>
      </Strip>
    </HorizontalScroll>
  )
}

export default ItemColors
