import { client } from 'apollo'
import { useCallback, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { useOutletContext } from 'react-router'
import styled from 'styled-components'

import Loading from 'components/Loading'
import Flex from 'components/common/Flex'
import { Spacer } from 'components/common/Spacer'
import { Text } from 'components/common/Text'
import Grid from 'components/grid/Grid'
import { GridColumnAdditioner } from 'components/grid/GridColumnAdditioner'
import SearchUpload from 'components/search/SearchUpload'
import { useWorkspaceItemsQuery } from 'generated/graphql'
import { useActiveWorkspace } from 'hooks/useActiveWorkspace'

export const AllItems = () => {
  const { scrollNode } = useOutletContext<{ scrollNode: HTMLDivElement }>()
  const [, setRerender] = useState(false)

  const activeWorkspace = useActiveWorkspace()!

  const { data, loading, fetchMore } = useWorkspaceItemsQuery({
    variables: {
      workspaceId: activeWorkspace.id,
      first: 30,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  })

  const pageInfo = data?.workspaceItems.pageInfo
  const nextCursor = pageInfo?.nextCursor
  const workspaceItems = data?.workspaceItems.workspaceItems ?? []
  const hasMore = Boolean(nextCursor) && workspaceItems.length > 0 && !loading

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        cursor: nextCursor,
      },
    })
  }

  useEffect(() => {
    // There is a conclict with our Masonory grid and Inifnite scroll
    // which makes the inifinite scroll to NOT fetch more if the grid content is small enough to not trigger the "fetch more threshold"
    // forcing a rerender solves it. Don't ask me why
    setTimeout(() => {
      setRerender((prev) => !prev)
    }, 0)
  }, [workspaceItems.length])

  const onDeleteItem = useCallback((itemId: string) => {
    client.cache.evict({ id: `WorkspaceItem:${itemId}` })
    client.cache.gc()
  }, [])

  if (loading && !data) {
    return <Loading />
  }

  if (workspaceItems.length === 0 && !loading) {
    return (
      <StyledGridWrapper>
        <Spacer factor={6} />
        <Flex flexDirection="column" alignItems="center">
          <Text color="neutral.0" size="xl">
            You don&apos;t have any items in this workspace yet.
          </Text>
          <Spacer factor={2} />
          <SearchUpload text="Select files" />
        </Flex>
      </StyledGridWrapper>
    )
  }

  return (
    <StyledGridWrapper>
      <div style={{ width: '100%' }}>
        <InfiniteScroll
          pageStart={0}
          threshold={1000}
          useWindow={false}
          getScrollParent={() => scrollNode}
          loadMore={handleLoadMore}
          hasMore={hasMore}
        >
          <GridColumnAdditioner parentWrapper={scrollNode}>
            {(gridColumnAdditionOption) => {
              return (
                <Grid
                  gridColumnAdditionOption={gridColumnAdditionOption}
                  scrollElement={scrollNode}
                  gridItems={workspaceItems}
                  onDelete={onDeleteItem}
                />
              )
            }}
          </GridColumnAdditioner>
          {loading && (
            <div className="loader" key={0}>
              <Loading fullscreen />
            </div>
          )}
        </InfiniteScroll>
      </div>
    </StyledGridWrapper>
  )
}

const StyledGridWrapper = styled.div`
  padding: 16px 16px 0;
`
