import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useFirestore } from 'react-redux-firebase'

import { createBoard, createProject } from 'components/project/helpers'
import useToastMessages from 'components/toast/useToastMessages'
import { RootState } from 'store'

type CreateBoardOnlyArgs = {
  projectId: string
  workspaceId: string
}

type CreateBoardOnlyPayload =
  | { error: string }
  | { boardId: string; boardTitle: string; projectId: string }

type CreateProjectAndBoardArgs = {
  shouldCreateBoard: boolean
}

type CreateProjectAndBoardPayload =
  | { error: string }
  | {
      boardId?: string
      boardTitle?: string
      projectId: string
      projectTitle: string
    }

type UseCreateBoardAndProject = {
  projectInputValue: string
  boardInputValue: string
}

const useCreateBoardAndProject = (props: UseCreateBoardAndProject) => {
  const firestore = useFirestore()

  const [isLoading, setIsLoading] = useState(false)
  const { reportError } = useToastMessages()

  const uid = useSelector((state: RootState) => state.firebase.auth.uid!)
  const email = useSelector((state: RootState) => state.firebase.auth.email!)
  const activeWorkspaceId = useSelector(
    (state: RootState) => state.content.activeWorkspace?.id
  )

  const handleBoardError = (thrownError: any) => {
    if (thrownError === 'DUPLICATE_BOARD_NAME') {
      reportError('You already have a board with that title')
    } else {
      reportError('We could not create a board, try again...')
    }
  }

  const createBoardOnly = async ({
    projectId,
    workspaceId,
  }: CreateBoardOnlyArgs): Promise<CreateBoardOnlyPayload> => {
    setIsLoading(true)
    const { boardId, boardTitle, error } = await createBoard({
      email,
      projectId,
      workspaceId,
      uid,
      firestore,
      title: props.boardInputValue,
    })
    setIsLoading(false)
    if (error) {
      handleBoardError(error)
      return { error }
    }
    return { boardId, boardTitle, projectId }
  }

  const createProjectAndBoard = async ({
    shouldCreateBoard,
  }: CreateProjectAndBoardArgs): Promise<CreateProjectAndBoardPayload> => {
    setIsLoading(true)
    const { projectId, projectTitle } = await createProject({
      email,
      workspaceId: activeWorkspaceId,
      uid,
      firestore,
      title: props.projectInputValue,
    })
    let boardId
    let boardTitle
    if (shouldCreateBoard) {
      const createBoardResponse = await createBoard({
        email,
        projectId,
        workspaceId: activeWorkspaceId,
        uid,
        firestore,
        title: props.boardInputValue,
      })
      if (createBoardResponse.error) {
        handleBoardError(createBoardResponse.error)
        return { error: createBoardResponse.error }
      }
      boardId = createBoardResponse.boardId
      boardTitle = createBoardResponse.boardTitle
    }
    setIsLoading(false)
    return { boardId, boardTitle, projectId, projectTitle }
  }

  return { isLoading, createBoardOnly, createProjectAndBoard }
}

export { useCreateBoardAndProject }
