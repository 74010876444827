import { ArrowBack } from '@styled-icons/boxicons-regular/ArrowBack'
import { useRef } from 'react'
import { useLocation, useNavigate } from 'react-router'

import Button from 'components/common/Button'
import Flex from 'components/common/Flex'
import SearchUpload from 'components/search/SearchUpload'

type ProfileNavbarProps = {
  shouldShowBackButton: boolean
  shouldShowUploadButton: boolean
}

export const ProfileNavbar = (props: ProfileNavbarProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const locationState = location.state as { prevPathname?: string }
  const prevPathnameRef = useRef(locationState?.prevPathname)
  return (
    <Flex css="height: 60px" p={1} justifyContent="space-between">
      <div>
        {props.shouldShowBackButton && (
          <Button
            Icon={ArrowBack}
            onClick={() => {
              if (prevPathnameRef.current) {
                return navigate(prevPathnameRef.current, { replace: true })
              }
              navigate('/')
            }}
          >
            Back
          </Button>
        )}
      </div>
      <div>{props.shouldShowUploadButton && <SearchUpload />}</div>
    </Flex>
  )
}
