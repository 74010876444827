import { useSelector } from 'react-redux'

import { RootState } from 'store'
import { DBWorkspace } from 'types/db'

export type WorkspaceRoles = 'ADMIN' | 'MEMBER' | 'GUEST'

const useWorkspaceUserRole = (
  optionalWorkspace?: DBWorkspace
): WorkspaceRoles | null => {
  const activeWorkspace = useSelector(
    (state: RootState) => state.content.activeWorkspace
  )
  const workspace = optionalWorkspace || activeWorkspace // if no workspace specified we use active workspace
  const currentUserEmail = useSelector(
    (state: RootState) => state.firebase.auth.email ?? ''
  )

  if (!workspace) return null

  if (
    'adminEmails' in workspace
      ? workspace.adminEmails.includes(currentUserEmail)
      : workspace.admins.includes(currentUserEmail)
  ) {
    return 'ADMIN'
  }

  if (
    'memberEmails' in workspace
      ? workspace.memberEmails.includes(currentUserEmail)
      : workspace.members.includes(currentUserEmail)
  ) {
    return 'MEMBER'
  }

  if (
    'guestEmails' in workspace
      ? workspace.guestEmails.includes(currentUserEmail)
      : workspace.guests.includes(currentUserEmail)
  ) {
    return 'GUEST'
  }

  return null as never
}

export default useWorkspaceUserRole
