import { ArrowUpward } from '@styled-icons/material/ArrowUpward'
import { Extension } from '@styled-icons/material/Extension'
import React from 'react'

import logo from 'assets/svg/kive_logo_black_on_white_medium.svg'

import {
  FloatingBox,
  FloatingBoxHeading,
  FloatingBoxRow,
} from './ChromeExtensionQuickStartStyles'

const ChromeExtensionQuickStartBox: React.FC = () => {
  return (
    <FloatingBox>
      <FloatingBoxRow fill align="right">
        <img width={16} alt="Logo" src={logo} />
        <ArrowUpward height={24} style={{ marginTop: -8 }} />
      </FloatingBoxRow>
      <FloatingBoxHeading>Pin me for easy access</FloatingBoxHeading>
      You can pin me from the
      <Extension width={16} style={{ marginLeft: 4 }} /> menu
    </FloatingBox>
  )
}

export default ChromeExtensionQuickStartBox
