import { motion } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'

interface ProgressCircleProps {
  percents: number
  counter?: boolean
  stroke?: string
  emptyStroke?: string
  emptyStrokeOpacity?: number
  duration?: number
  delay?: number
  size?: number
  strokeWidth?: number
  caption?: string
  className?: string
}

const ProgressCircle: React.FC<ProgressCircleProps> = ({
  percents,
  counter = true,
  stroke = 'blue',
  emptyStroke = stroke,
  emptyStrokeOpacity = 0.25,
  duration = 0,
  delay = 0,
  size = 100,
  strokeWidth = 6,
  caption,
  className,
}) => {
  const radius = 45
  const circumference = Math.ceil(2 * Math.PI * radius)
  const fillPercents = Math.abs(
    Math.ceil((circumference / 100) * (percents - 100))
  )

  const transition = {
    duration,
    delay,
    ease: 'easeIn',
  }

  const variants = {
    hidden: {
      strokeDashoffset: circumference,
      transition,
    },
    show: {
      strokeDashoffset: fillPercents,
      transition,
    },
  }

  return (
    <>
      <div
        className={className}
        css="display: flex;justify-content: center;align-items: center;"
      >
        {counter && (
          <div css="position:absolute;font-size:9px;height: 14px;">
            <Counter valueTo={percents} totalDuration={duration + delay} />%
          </div>
        )}
        <div
          css={`
            height: ${size}px;
          `}
        >
          <svg
            viewBox="0 0 100 100"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
          >
            <circle
              cx="50"
              cy="50"
              r={radius}
              className="circle"
              strokeWidth={strokeWidth}
              stroke={emptyStroke}
              strokeOpacity={emptyStrokeOpacity}
              fill="transparent"
            />
          </svg>
          <svg
            viewBox="0 0 100 100"
            width={size}
            height={size}
            style={{
              position: 'absolute',
              transform: 'rotate(-90deg)',
              overflow: 'visible',
              marginLeft: -size,
            }}
          >
            <motion.circle
              cx="50"
              cy="50"
              r={radius}
              strokeWidth={strokeWidth}
              stroke={stroke}
              fill="transparent"
              strokeDashoffset={fillPercents}
              strokeDasharray={circumference}
              variants={variants}
              initial="hidden"
              animate="show"
            />
          </svg>
        </div>
      </div>
      {caption && <div>{caption}</div>}
    </>
  )
}

export default ProgressCircle

function useInterval(callback: () => void, delay: number) {
  const savedCallback = useRef<() => void>()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current!()
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

const Counter = ({ valueFrom = 0, valueTo = 100, totalDuration = 1 }: any) => {
  const [count, setCount] = useState(valueFrom)

  useInterval(() => {
    if (count < valueTo) {
      setCount(count + 1)
    }
  }, (totalDuration / valueTo) * 1000)

  return count
}
