import { Direction, Range as ReactRange } from 'react-range'
import styled, { css } from 'styled-components'

const SLIDER_HEIGHT = 140

type SliderProps = {
  isHidden: boolean
  setEraserSize: (value: number) => void
  eraserSize: number
}

export const EraserSlider = ({
  isHidden,
  setEraserSize,
  eraserSize,
}: SliderProps) => {
  return (
    <>
      <StyledEraserContainer
        isHidden={isHidden}
        yPosition={window.innerHeight / 2 - SLIDER_HEIGHT / 2}
      >
        <StyledEraserSlider>
          <StyledSliderSizeIndicator />
          <ReactRange
            step={10}
            direction={Direction.Down}
            min={10}
            max={100}
            values={[eraserSize]}
            onChange={([newValue]) => setEraserSize(newValue)}
            renderTrack={(renderTrackArgs) => (
              <StyledSliderTrack {...renderTrackArgs.props}>
                {renderTrackArgs.children}
              </StyledSliderTrack>
            )}
            renderThumb={(renderThumbArgs) => (
              <StyledSliderThumbOuter {...renderThumbArgs.props}>
                <StyledSliderThumbInner />
              </StyledSliderThumbOuter>
            )}
          />
          <StyledSliderSizeIndicator size={12} />
        </StyledEraserSlider>
      </StyledEraserContainer>
    </>
  )
}

const StyledEraserContainer = styled.div<{
  isHidden: boolean
  yPosition: number
}>(
  ({ isHidden, yPosition }) => css`
    position: fixed;
    cursor: pointer;
    z-index: 10;
    top: ${yPosition}px;
    left: 64px;
    opacity: 1;
    transition: 0.2s opacity ease;
    ${isHidden &&
    css`
      opacity: 0;
    `}
  `
)

const StyledSliderSizeIndicator = styled.div<{ size?: number }>(
  ({ theme, size = 6 }) => css`
    height: ${size}px;
    width: ${size}px;
    border-radius: ${theme.borderRadius.full};
    background: ${theme.colors.gray.medium[2]};
    margin: 8px 0px;
  `
)

const StyledSliderThumbOuter = styled.div`
  height: 12px;
  width: 12px;
  outline: 0;
  transition: 0.05s transform ease;
`

const StyledSliderThumbInner = styled.div(
  ({ theme }) => css`
    height: 12px;
    width: 12px;
    border-radius: ${theme.borderRadius.full};
    background: ${theme.colors.gray.medium[7]};
    transition: 0.1s transform ease;
  `
)

const StyledEraserSlider = styled.div(
  ({ theme }) => css`
    padding: 4px 8px;
    background: ${theme.colors.gray.dark[4]};
    border-radius: ${theme.borderRadius.full};
    border: 1px solid ${theme.colors.gray.medium[0]};
    height: ${SLIDER_HEIGHT}px;
    box-shadow: 0px 1px 5px #00000066;
    display: flex;
    align-items: center;
    justify-contents: center;
    width: 100%;
    flex-direction: column;
    :hover ${StyledSliderThumbInner} {
      background: ${theme.colors.white};
      transform: scale(1.3);
    }
  `
)

const StyledSliderTrack = styled.div(
  ({ theme }) => css`
    width: 8px;
    flex: 1;
    border-radius: ${theme.borderRadius.full};
    background: ${theme.colors.gray.medium[1]};
  `
)
