import { ChevronRight } from '@styled-icons/bootstrap/ChevronRight'
import { AnimatePresence, motion } from 'framer-motion'
import React, { FC, useState } from 'react'
import styled from 'styled-components'

import { PropsFriendlyTextColors, Text } from '../Text'

type CollapsibleProps = {
  label: string
  isInitiallyOpen?: boolean
  actionIconColor?: PropsFriendlyTextColors
  children: React.ReactNode
}

const ActionIcon = styled(ChevronRight)<{ isPointingDown: boolean }>`
  transform: ${(props) =>
    props.isPointingDown ? 'rotate(90deg)' : 'rotate(0deg)'};
  transition: transform 0.1s ease-in-out;
  transform-origin: center;
`

const Interaction = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  cursor: pointer;
  align-items: center;
  opacity: 0.6;
  margin-bottom: 4px;
  :hover {
    opacity: 1;
  }
`

/**
 * A pretty basic component which collapses it's children. It's wired to take up 100% of the width of it's parent
 * But the styles can also be overridden.
 *
 */
export const Collapsible: FC<CollapsibleProps> = (props) => {
  const { label, children, actionIconColor } = props
  const [isOpen, setIsOpen] = useState(!!props.isInitiallyOpen)

  const toggleOpenState = () => setIsOpen((prev) => !prev)

  return (
    <div css="width: 100%">
      <Interaction className="interaction" onClick={toggleOpenState}>
        <ActionIcon
          size="10"
          color={actionIconColor}
          isPointingDown={isOpen}
          className="icon"
        />
        <Text
          size="sm"
          color="neutral.1"
          className="label"
          css="padding-left: 4px;"
        >
          {label}
        </Text>
      </Interaction>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, marginTop: '-4px' }}
            animate={{ opacity: 1, marginTop: 0 }}
            transition={{ duration: 0.1 }}
            exit={{ opacity: 0, marginTop: '-4px' }}
          >
            <div>{children}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

Collapsible.defaultProps = {
  actionIconColor: 'neutral.6',
}
