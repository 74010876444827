import { Download } from '@styled-icons/boxicons-regular/Download'
import { ImageAdd as Background } from '@styled-icons/boxicons-regular/ImageAdd'
import { Link as LinkIcon } from '@styled-icons/boxicons-regular/Link'
import { Copy } from '@styled-icons/feather/Copy'
import { Delete } from '@styled-icons/feather/Delete'
import React, { useCallback, useMemo, useState } from 'react'
import { isFirefox, isSafari } from 'react-device-detect'
import { useFirestore } from 'react-redux-firebase'
import { useParams } from 'react-router'
import styled, { css } from 'styled-components'

import Button from 'components/common/Button'
import { permissionInfoText } from 'components/toast/ToastMessages'
import { deleteBoardItem } from 'helpers/boardItemOperations'
import getItemSizeUrl from 'helpers/getItemSizeUrl'
import {
  trackBoardItemRemoved,
  trackItemCopied,
  trackItemLinkCopied,
} from 'helpers/tracking/tracking'
import useBoardPermissionsById from 'hooks/useBoardPermissionById'
import useKeyupListener from 'hooks/useKeyupListener'
import useWorkspacePermissions from 'hooks/useWorkspacePermissions'

import { copyImageToClipboard } from '../../../helpers/ClipboardHelper'
import useClickOutside from '../../../hooks/useClickOutside'
import useTrailingValue from '../../../hooks/useTrailingValue'
import { DropdownList, DropdownListItem } from '../../common/DropdownOld'
import Modal from '../../common/Modal'
import { download } from '../../item/itemActions'
import useToastMessages from '../../toast/useToastMessages'

function GridItemOverlayOptions({
  item,
  itemRefId,
  boardId,
  onClose,
  onDelete,
}) {
  useKeyupListener('Escape', onClose)
  const { reportSuccess, reportError } = useToastMessages()
  const firestore = useFirestore()
  const params = useParams()
  const userAllowedTo = useBoardPermissionsById(boardId || '', [
    'EDIT_BOARD',
    'REMOVE_BOARD_ITEM',
  ])
  const { DELETE_ITEM: userAllowedToDeleteItem } = useWorkspacePermissions([
    'DELETE_ITEM',
  ])

  const [deleteConfirm, setDeleteConfirm] = useState(false)

  // we don't wanna listen to click outside if deleteConfirm modal is open
  const dropDownRef = useClickOutside(deleteConfirm ? null : onClose)

  const handleCopyLink = useCallback(() => {
    const isBoardItem = 'boardId' in item
    const shortPath = isBoardItem ? 'bi' : 'i'
    navigator.clipboard.writeText(
      `${window.location.origin}/${shortPath}/${item.id}`
    )
    reportSuccess('Copied link to clipboard!')

    const context = {
      itemId: item.id,
      boardId: 'boardId' in item ? item.boardId : undefined,
      projectId: 'projectId' in item ? item.projectId : undefined,
      workspaceId: item.workspaceId,
      location: 'GRID',
    }
    trackItemLinkCopied(context)
    onClose()
  }, [item, onClose, reportSuccess])

  const handleDownload = useCallback(async () => {
    onClose()
    const downloadContext = {
      itemId: item.id,
      boardId: params.boardId,
      projectId: params.projectId,
      workspaceId: item.workspaceId,
    }
    await download(item, undefined, downloadContext)
  }, [item, onClose, params.boardId, params.projectId])

  // const { boardId } = useParams()
  const handleDeleteItem = useCallback(() => {
    if (boardId) {
      deleteBoardItem(itemRefId, boardId)
        .then(() => {
          onDelete?.(itemRefId)
          // Segment Event –– Board Item Removed
          trackBoardItemRemoved({
            itemRef: itemRefId,
            boardId,
          })
        })
        .catch((err) => {
          reportError('Could not remove board item', err)
        })
    } else {
      setDeleteConfirm(true)
    }
  }, [boardId, firestore, itemRefId, onDelete, reportError])

  const confirmDelete = useCallback(() => {
    const itemId = item.itemId ?? item.id
    firestore
      .update(
        {
          collection: `workspaces/${item.workspaceId}/items`,
          doc: itemId,
        },
        { shouldDelete: true, shouldDeleteTags: true }
      )
      .then(() => {
        onDelete?.(itemId)
      })
      .catch((err) => {
        reportError('Could not delete item', err)
      })
    setDeleteConfirm(false)
  }, [firestore, item.workspaceId, item.id, item.itemId, onDelete, reportError])

  const setAsCover = useMemo(
    () =>
      boardId &&
      (() => {
        if (!userAllowedTo.EDIT_BOARD) {
          return reportError(permissionInfoText('Edit board'))
        }
        firestore.update(
          { collection: 'boards', doc: boardId },
          {
            cover: {
              src: getItemSizeUrl(item, 1600),
            },
          }
        )
      }),
    [boardId, firestore, item.versions, userAllowedTo.EDIT_BOARD, reportError]
  )

  const handleCopyImageToClipboard = useCallback(async () => {
    trackItemCopied({ itemId: item.id, location: 'GRID' })
    try {
      await copyImageToClipboard(item)
      reportSuccess('Image copied to clipboard!')
    } catch (err) {
      if (isFirefox) {
        reportError(
          'To enable clipboard support in Firefox, go to about:config and set "dom.events.asyncClipboard.clipboardItem"'
        )
      } else if (isSafari) {
        reportError('Copy image to clipboard is not supported on Safari')
      } else {
        reportError('Image could not be copied')
      }
    }
  }, [item.imgUrl, item.versions])

  const canDeleteOrRemoveItem = boardId
    ? userAllowedTo.REMOVE_BOARD_ITEM
    : userAllowedToDeleteItem

  const menuItems = useTrailingValue(
    useMemo(
      () =>
        [
          ['Download', <Download size={16} />, () => handleDownload()],
          ['Copy Link', <LinkIcon size={16} />, () => handleCopyLink()],
          [
            'Copy Image',
            <Copy size={16} />,
            () => handleCopyImageToClipboard(),
          ],
          setAsCover && [
            'Set board cover',
            <Background size={16} />,
            setAsCover,
          ],

          canDeleteOrRemoveItem && [
            boardId ? 'Remove' : 'Delete',
            <Delete size={16} />,
            handleDeleteItem,
          ],
        ].filter((item_) => item_),
      [
        handleCopyLink,
        handleDeleteItem,
        handleDownload,
        setAsCover,
        canDeleteOrRemoveItem,
        boardId,
        handleCopyImageToClipboard,
      ]
    ),
    50
  )
  return (
    <>
      <Modal
        isOpen={deleteConfirm}
        close={() => setDeleteConfirm(false)}
        height="200px"
        width="450px"
        wrapperCss="z-index: 99999" // to make sure it is in front of tooltip menu
      >
        <Modal.ContentWrapper>
          <StyledModalHeading>
            Are you sure you want to delete this item?
          </StyledModalHeading>
          <StyledModalText>You can&apos;t undo this!</StyledModalText>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="danger"
              style={{ margin: 12 }}
              onClick={confirmDelete}
            >
              Delete
            </Button>
            <Button
              style={{ margin: 12 }}
              onClick={() => setDeleteConfirm(false)}
            >
              Cancel
            </Button>
          </div>
        </Modal.ContentWrapper>
      </Modal>
      <DropdownList
        ref={dropDownRef}
        style={{ padding: 6, minWidth: 'auto', width: 160 }}
      >
        {menuItems.map(([name, icon, action]) => (
          <DropdownListItem key={name} onClick={action}>
            <div>{name}</div> <div>{icon}</div>
          </DropdownListItem>
        ))}
      </DropdownList>
    </>
  )
}

export default GridItemOverlayOptions

const StyledModalHeading = styled.h1`
  ${({ theme }) => css`
    font-weight: ${theme.fontWeight.bold};
    margin-bottom: 12px;
    font-size: 1.25rem;
    color: ${theme.colors.text.neutral[1]};
  `}
`

const StyledModalText = styled.p`
  color: ${({ theme }) => theme.colors.text.neutral[1]};
  margin-bottom: 12px;
`
