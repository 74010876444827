import uploadImage1 from 'assets/img/guides/upload-guide/upload-1.jpg'
import uploadImage2 from 'assets/img/guides/upload-guide/upload-2.jpg'
import uploadImage3 from 'assets/img/guides/upload-guide/upload-3.jpg'
import uploadImage4 from 'assets/img/guides/upload-guide/upload-4.jpg'
import uploadImage5 from 'assets/img/guides/upload-guide/upload-5.jpg'
import { Spacer } from 'components/common/Spacer'
import { Text } from 'components/common/Text'

import { StyledContentList } from './contentStyles'

export const contentUpload = {
  contentHeight: 225,
  steps: [
    {
      coverUrl: uploadImage1,
      content: (
        <div>
          <Text size="lg" color="neutral.0">
            Import your items
          </Text>
          <Spacer factor={1} />
          <Text size="sm" color="neutral.2">
            Chose between a variety of options to start filling up your Kive
            library.{' '}
          </Text>
          <StyledContentList>
            <li>
              All items imported to your library are automatically tagged and
              categorised.
            </li>
            <li>Duplicates are recognized and removed.</li>
            <li>You can apply manual tags to your upload.</li>
            <li>All uploads end up in your private library.</li>
            <li>Supported formats: Stills and GIFs.</li>
          </StyledContentList>
        </div>
      ),
    },
    {
      coverUrl: uploadImage2,
      content: (
        <div>
          <Text size="lg" color="neutral.0">
            Drag & Drop
          </Text>
          <Spacer factor={1} />
          <Text size="sm" color="neutral.2">
            Easily add items to your library with drag & drop. You can drop a
            file or a folder full of files, or even paste images directly into
            Kive.
          </Text>
        </div>
      ),
    },
    {
      coverUrl: uploadImage3,
      content: (
        <div>
          <Text size="lg" color="neutral.0">
            Extract from video
          </Text>
          <Spacer factor={1} />
          <Text size="sm" color="neutral.2">
            Automatically pull stills or GIFs from any video and have them saved
            straight to your Kive. Once the extraction is completed, you can
            find the new collection as an &quot;Upload Event&quot; in your
            library.
          </Text>
        </div>
      ),
    },
    {
      coverUrl: uploadImage4,
      content: (
        <div>
          <Text size="lg" color="neutral.0">
            Chrome Extension
          </Text>
          <Spacer factor={1} />
          <Text size="sm" color="neutral.2">
            The Kive Chrome Extension will enable you to save any image from the
            web straight to your Kive with just one click.{' '}
          </Text>
          <StyledContentList>
            <li>
              With the extension installed and authenticated, simply hover an
              image and click “Save to Kive”.
            </li>
            <li>
              You can save multiple images at a time by clicking the Kive
              extension icon in the upper right corner of the browser toolbar.
            </li>
            <li>Works with Instagram, Shotdeck, Unsplash, Pinterest etc.</li>
          </StyledContentList>
          <br />
          <a
            href="https://chrome.google.com/webstore/detail/save-to-kive/hfkpeknbeaekjalojbgelphkikhkaicg"
            target="_blank"
            rel="noreferrer"
          >
            Install it here
          </a>
        </div>
      ),
    },
    {
      coverUrl: uploadImage5,
      content: (
        <div>
          <Text size="lg" color="neutral.0">
            Save from Discover
          </Text>
          <Spacer factor={1} />
          <Text size="sm" color="neutral.2">
            Simply add inspiration you like by saving items to your workspace or
            directly into a board.
          </Text>
        </div>
      ),
    },
  ],
}
