import { Cross } from '@styled-icons/entypo/Cross'
import styled, { css } from 'styled-components'

import { HoverSection, WorkspaceSelectorStyled } from './WorkspaceSelector'

export const SiderStyled = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.background[2]};
    height: 100vh;
    z-index: 10;
    display: flex;
    ${HoverSection}:hover ${WorkspaceSelectorStyled} {
      transform: translateX(0);
    }
  `}
`

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Nav = styled.div<{
  siderSplitValue: number
}>`
  ${({ theme, siderSplitValue }) => css`
    padding: ${siderSplitValue < 160 ? '0px' : '8px'};
    margin-top: 0px;
    overflow-x: hidden;
    overflow-y: scroll;
    gap: 1px;
    display: flex;
    flex-direction: column;
    flex: 1;
    font-size: ${theme.fontSizes.sm};
    ::-webkit-scrollbar {
      display: none;
    }
  `}
`

export const NavText = styled.div<{
  active: boolean
  siderSplitValue: number
}>(({ theme, active, siderSplitValue }) => ({
  marginLeft: 8,
  whiteSpace: 'nowrap',
  ...(siderSplitValue < 160 && { display: 'none' }),
}))

export const NavSubTitle = styled.div<{ siderSplitValue: number }>(
  ({ theme, siderSplitValue }) => ({
    margin: '0px 5px 8px 8px',
    marginTop: siderSplitValue < 160 ? 0 : 16,
    fontSize: theme.fontSizes.xs,
    display: siderSplitValue < 160 ? 'none' : 'flex',
    alignItems: 'center',
    userSelect: 'none',
    justifyContent: 'space-between',
  })
)

export const NavItemStyled = styled.div<{
  active: boolean
  siderSplitValue: number
  isSubItem?: boolean
  isSecondary?: boolean
}>(({ theme, siderSplitValue, active, isSecondary, isSubItem }) => ({
  textDecoration: 'none',
  borderRadius: siderSplitValue < 160 ? 0 : 8,
  padding: 8,
  height: siderSplitValue < 160 ? 60 : 32,
  display: 'flex',
  alignItems: 'center',
  ...(siderSplitValue < 160 && { justifyContent: 'center' }),
  userSelect: 'none',
  // eslint-disable-next-line
  color: active
    ? theme.colors.text.neutral[0]
    : isSecondary
    ? theme.colors.text.neutral[3]
    : theme.colors.text.neutral[1],
  background: active ? theme.colors.background[4] : theme.colors.background[2],
  cursor: 'pointer',
  ':hover': {
    background: theme.colors.background[4],
    color: active ? theme.colors.text.neutral[0] : theme.colors.text.neutral[0],
  },
  ':active': {
    background: theme.colors.background[5],
    color: theme.colors.text.neutral[2],
  },
}))

export const NavSubItemsContainer = styled.div<{
  isVisible: boolean
  siderSplitValue: number
}>(
  ({ isVisible, siderSplitValue }) => css`
    margin-left: ${siderSplitValue > 160 ? '12px' : 0};
    opacity: ${isVisible ? 1 : 0};
    visibility: ${isVisible ? 'visible' : 'hidden'};
    height: ${isVisible ? 'auto' : 0};
    transition: opacity 0.2s ease-in-out, height 0.4s ease-in-out;
    transform: translateY(${isVisible ? '0%' : '-1%'});
  `
)

export const FavoriteNavItemStyled = styled.div`
  display: flex;
  align-items: center;
`

export const StyledCross = styled(Cross)`
  height: 32px;
  width: 32px;
  border-radius: 8px;
  padding: 7px;
  display: none;
  cursor: pointer;
  flex-shrink: 0;
  ${FavoriteNavItemStyled}:hover & {
    display: block;
  }
  &:hover {
    background: ${({ theme }) => theme.colors.background[4]};
  }
`
