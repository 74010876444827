import styled, { css } from 'styled-components'

export const Main = styled.div(({ theme, fullHeight }) => ({
  overflow: 'hidden auto',
  maxHeight: fullHeight ? '100vh' : 'calc(100vh - 60px)',
  minHeight: fullHeight ? '100vh' : 'calc(100vh - 60px)',
  /* TODO: figure out if we need this:   position: 'relative', */
  color: theme.colors.text.neutral[3],
  background: theme.colors.background[1],
  '@media (max-width: 768px)': {
    padding: 0,
  },
}))

// Board title
export const TitleContainer = styled.div(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'left',
  fontSize: theme.fontSizes.xl,
}))

export const DescriptionContainer = styled.div(({ theme }) => ({
  width: '100%',
  display: 'flex',
  textAlign: 'left',
  justifyContent: 'left',
  fontSize: theme.fontSizes.base,
  fontWeight: theme.fontWeight.normal,
  minHeight: '30px',
}))

export const AddDescriptionText = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    cursor: pointer;
    color: ${theme.colors.text.neutral[2]};
    font-size: ${theme.fontSizes.base};
    font-weight: ${theme.fontWeight.normal};
  `}
`

export const HoverButton = styled.div`
  ${({ theme }) => css`
    white-space: nowrap;
    font-size: ${theme.fontSizes.sm};
    font-weight: ${theme.fontWeight.normal};
    color: ${theme.colors.text.neutral[2]};
    background-color: ${theme.colors.background[1]};
    padding: 2px 6px;
    border-radius: ${theme.borderRadius.sm};
    cursor: pointer;
    &:hover {
      background-color: ${theme.colors.background[4]};
      color: ${theme.colors.text.neutral[2]};
    }
  `}
`
