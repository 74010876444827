import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import styled, { css, keyframes } from 'styled-components'

import CommonSpinner from '../common/Spinner'

const marginUnit = 8
const sizeUnit = 60

const fadeFromBelow = keyframes` 
from {
  opacity: 0;
  transform: translateY(40px)
}
to {
  opacity: 1;
  transform: translateY(0px);
}
`

export const FadeFromBelow = styled.div`
  animation: ${fadeFromBelow} 100ms;
`

export const ItemListContainer = styled.section<{ left?: number }>`
  pointer-events: none;
  position: fixed;
  bottom: 0px;
  left: ${(props) => props.left ?? 0}px;
  z-index: 101;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

export const ItemList = styled(motion.ul).attrs((props) => ({
  children: props.children && (
    <AnimatePresence>{props.children}</AnimatePresence>
  ),
}))`
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  list-style: none;
  padding: 0;
  margin: ${marginUnit}px ${marginUnit * 2}px;
`
const verticalMove = sizeUnit + marginUnit

export const ItemContainer = styled(motion.li).attrs({
  initial: { opacity: 0, y: verticalMove, zIndex: -1 },
  animate: { opacity: 1, y: 0, zIndex: 0 },
  exit: { opacity: 0, y: verticalMove, zIndex: -1 },
})`
  ${({ theme }) => css`
    color: ${theme.colors.accent[1]};
    border: 1px solid ${theme.colors.background[5]};
    display: flex;
    flex-direction: row;
    align-items: center;
    width: ${sizeUnit * 5}px;
    max-width: 100%;
    transition: color 200ms, border 200ms;
    background-color: ${theme.colors.background[3]};
    padding: 0 ${marginUnit * 3}px;
    height: ${sizeUnit}px;
    border-radius: 10px;
    margin: 8px 0;
    font-size: 0.875rem;
    pointer-events: auto;
  `}
`

export const ItemAction = styled.a`
  ${({ theme }) => css`
    color: white;
    padding: ${marginUnit}px;
    cursor: pointer;
    :hover {
      color: ${theme.colors.accent[1]};
    }
  `}
`

const spinnerSize = 32
const Spinner = styled(CommonSpinner)`
  position: absolute;
  width: ${spinnerSize}px;
  height: ${spinnerSize}px;
  left: -10px;
  top: -5px;
`

export const Loading: React.FC = () => (
  <div
    style={{
      position: 'relative',
      width: spinnerSize,
      marginRight: 5,
      height: 22,
    }}
  >
    <Spinner />
    <div style={{ position: 'absolute', left: 0, top: 0 }} />
  </div>
)
