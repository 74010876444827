import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useFirestore } from 'react-redux-firebase'
import { Link } from 'react-router-dom'
import { ArrowLeftShort } from 'styled-icons/bootstrap'

import Button from 'components/common/Button'
import IconButton from 'components/common/IconButton'
import { LinkWithParams } from 'components/common/LinkWithParams'
import { reportError } from 'helpers/logging'
import useIsMobile from 'hooks/useIsMobile'
import useProjectPermissions from 'hooks/useProjectPermissions'
import useWorkspacePermissions from 'hooks/useWorkspacePermissions'
import { RootState } from 'store'
import { workspacesSelector } from 'store/selectors'
import { DBWorkspace } from 'types/db'

interface BackToProjectButtonProps {
  projectId: string
  workspaceId: string
  boardId: string
  theme: 'darkTheme' | 'lightTheme'
}

const BackToProjectButton: React.FC<BackToProjectButtonProps> = ({
  projectId,
  workspaceId,
  boardId,
  theme,
}) => {
  const firestore = useFirestore()
  const isMobile = useIsMobile()

  const globalProject = useSelector(
    (state: RootState) => state.firestore.data.projects?.[projectId]
  )

  const workspaces = useSelector(workspacesSelector)
  const globalWorkspace = workspaces?.[workspaceId]

  const [isLoadingProject, setIsLoadingProject] = useState(true)
  const [isLoadingWorkspace, setIsLoadingWorkspace] = useState(true)
  const [project, setProject] = useState(globalProject)
  const [workspace, setWorkspace] = useState<DBWorkspace | undefined>(
    globalWorkspace
  )

  useEffect(() => {
    if (!project) {
      firestore
        .collection('projects')
        .doc(projectId)
        .get()
        .then((doc) => {
          if (!doc.exists) return
          setProject(doc.data())
          setIsLoadingProject(false)
        })
        .catch((error) => {
          if (error.code !== 'permission-denied') {
            reportError(error)
          }
          setIsLoadingProject(false)
        })
    } else {
      setIsLoadingProject(false)
    }

    if (!workspace) {
      firestore
        .collection('workspaces')
        .doc(workspaceId)
        .get()
        .then((doc) => {
          if (!doc.exists) return
          setWorkspace(doc.data() as DBWorkspace)
          setIsLoadingWorkspace(false)
        })
        .catch((error) => {
          if (error.code !== 'permission-denied') {
            reportError(error)
          }
          setIsLoadingWorkspace(false)
        })
    } else {
      setIsLoadingWorkspace(false)
    }
  }, [projectId, workspaceId])

  const { VIEW_PROJECT: allowedToViewProject } = useProjectPermissions(
    project,
    ['VIEW_PROJECT']
  )
  const { VIEW_WORKSPACE: allowedToViewWorkspace } = useWorkspacePermissions(
    ['VIEW_WORKSPACE'],
    workspace
  )

  if (isLoadingProject || isLoadingWorkspace) return null

  if (workspace && project && allowedToViewProject && allowedToViewWorkspace) {
    return (
      <LinkWithParams to={`/${workspace.url}/p/${projectId}/${boardId}`}>
        {!isMobile && (
          <Button
            size={24}
            Icon={ArrowLeftShort}
            variant={theme === 'darkTheme' ? 'secondary' : 'darkSecondary'}
          >
            Back to project
          </Button>
        )}
        {isMobile && (
          <IconButton Icon={ArrowLeftShort} noBackground iconSize={34} />
        )}
      </LinkWithParams>
    )
  }
  return (
    <Link to="/">
      {!isMobile && (
        <Button
          size={24}
          Icon={ArrowLeftShort}
          variant={theme === 'darkTheme' ? 'secondary' : 'darkSecondary'}
        >
          Home
        </Button>
      )}
      {isMobile && (
        <IconButton Icon={ArrowLeftShort} noBackground iconSize={90} />
      )}
    </Link>
  )
}

export default BackToProjectButton
