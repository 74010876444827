import { Link } from 'react-router-dom'

import { BoardPreviewCard } from 'components/board-preview-card/BoardPreviewCard'
import { Spacer } from 'components/common/Spacer'
import { HighlightRow } from 'components/highlight-row/HighlightRow'
import { useRecentBoardsQuery } from 'generated/graphql'
import { trackHomeRecentBoardClicked } from 'helpers/tracking/tracking'

type RecentBoardsRowProps = {
  workspaceId: string
}

export const RecentBoardsRow = (props: RecentBoardsRowProps) => {
  const { data } = useRecentBoardsQuery({
    variables: {
      workspaceId: props.workspaceId,
    },
    fetchPolicy: 'cache-and-network',
    context: {
      batch: true,
    },
  })

  const recentBoards = data?.recentBoards || []

  if (recentBoards.length === 0) return null

  return (
    <>
      <HighlightRow
        title="Recent boards"
        link={{ path: 'projects', text: 'Go to projects' }}
        listItems={recentBoards.slice(0, 10)}
        renderListItem={(board) => {
          return (
            <Link
              to={`p/${board.project.id}/${board.id}`}
              onClick={trackHomeRecentBoardClicked}
            >
              <BoardPreviewCard
                title={board.title}
                projectTitle={board.project.title}
                count={board.itemCount}
                thumbUrls={board.thumbUrls}
              />
            </Link>
          )
        }}
      />
      <Spacer factor={5} />
    </>
  )
}
