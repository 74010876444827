import { FC, ReactNode } from 'react'

import { StyledFeatureSubline } from './ComparePlansStyles'

type Props = {
  children?: ReactNode
}
export const FeatureSubline: FC<Props> = ({ children }) => {
  return <StyledFeatureSubline>{children}</StyledFeatureSubline>
}
