import { ArrowLeftShort } from '@styled-icons/bootstrap'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import styled, { css } from 'styled-components'

import { db } from 'config/firebase'
import useIsMobile from 'hooks/useIsMobile'

const StyledGoBackButton = styled.div<{ isMobile: boolean }>`
  ${({ theme, isMobile }) => css`
     {
      color: ${theme.colors.text.neutral[6]};
      display: flex;
      flex: auto;
      align-items: center;
      top: 24px;
      left: ${isMobile ? '40px' : ''};
      position: absolute;
      cursor: pointer;
      z-index: 1;
    }
    &:hover {
      color: ${theme.colors.text.neutral[1]};
    }
  `}
`

type OnboardingUpdatesTypes = {
  [key: string]: boolean
}

interface ButtonProps {
  previousSetup?: 'completedProfile' | 'completedWorkspace' | 'completedInvite'
  uid?: string
}

const previousSetupToPage = {
  completedProfile: 'profile',
  completedWorkspace: 'workspace',
  completedInvite: 'invite',
}

const SetupGoBack: React.FC<ButtonProps> = ({ previousSetup, uid }) => {
  const [arrowIsShown, setArrowIsShown] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const isMobile = useIsMobile()

  const navigateToPreviousStep = () => {
    if (!previousSetup) return

    const page = previousSetupToPage[previousSetup]
    navigate(`/setup/${page}${location.search}`)
  }

  const handleGoBack = () => {
    if (previousSetup) {
      const onboardingUpdates: OnboardingUpdatesTypes = {}
      onboardingUpdates[previousSetup] = false

      navigateToPreviousStep()

      if (
        previousSetup === 'completedProfile' ||
        previousSetup === 'completedWorkspace'
      ) {
        db.collection('users')
          .doc(uid)
          .collection('private')
          .doc('onboarding')
          .update(onboardingUpdates)
      }
    } else {
      navigate(-1)
    }
  }

  return (
    <StyledGoBackButton
      isMobile={isMobile}
      onClick={handleGoBack}
      onMouseEnter={() => setArrowIsShown(true)}
      onMouseLeave={() => setArrowIsShown(false)}
    >
      <div style={{ position: 'absolute', left: -24, top: -2 }}>
        <AnimatePresence>
          {(arrowIsShown || isMobile) && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                x: [10, 0],
                transition: { duration: 0.1 },
              }}
              exit={{ opacity: 0, x: [0, 10], transition: { duration: 0.1 } }}
            >
              <ArrowLeftShort size={isMobile ? 40 : 24} />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <motion.div layout>{!isMobile && 'Go back'}</motion.div>
    </StyledGoBackButton>
  )
}

export default SetupGoBack
