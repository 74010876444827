import type { Item } from 'components/grid/griditem/GridItem'

import getTypeCategory from './getTypeCategory'

// temp solution to find mediaType depending on datasource
export const getItemMediaType = (item: Item) => {
  if ('__typename' in item) {
    return item.mediaType.toLowerCase() as Lowercase<typeof item.mediaType>
  }

  if ('isTypesenseItem' in item) {
    return item.originalType ?? 'image'
  }

  return getTypeCategory(item.original?.info?.type || 'jpg')
}
