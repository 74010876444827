import styled, { css } from 'styled-components'

import KiveLogo from 'assets/img/kive_logo_inverted.png'

export const KiveInvertedLogo = styled.span`
  ${({ size }) => css`
    width: ${`${size}px`};
    height: ${`${size}px`};
    background-image: url(${KiveLogo});
    background-repeat: no-repeat;
    background-size: ${`${size}px`};
  `}
`

export const Page = styled.div(({ theme, style }) => ({
  fontSmoothing: 'auto',
  textRendering: 'optimizeLegibility',
  fontFamily: theme.fontFamily.primary,
  paddingTop: 32,
  color: theme.colors.text.neutral[3],
  backgroundColor: theme.colors.gray.dark['-1'],
  fontWeight: theme.fontWeight.normal,
  fontSize: theme.fontSizes.base,
  ...style,
}))

export const GuideVertical = styled.div`
  padding-right: 10%;
  padding-left: 10%;
  //overflow: hidden;
  //max-width: 1260px;
  @media only screen and (max-width: 600px) {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  @media only screen and (min-width: 1260px) {
    max-width: 1260px;
    box-sizing: content-box;
    margin: auto;
    padding-right: 0;
    padding-left: 0;
  }
`
export const GuideHorizontal = styled.section(
  ({ gapRem }) => css`
    padding-top: ${gapRem}rem;
    padding-bottom: ${gapRem}rem;
  `
)

export const Block = styled.div`
  ${({ theme }) => css`
    padding: 24px;
    width: 100%;
    max-width: 1200px;
    margin: 32px auto 0 auto;
    border-radius: ${theme.borderRadius.xl};
    @media (max-width: 800px) {
      padding: 16px;
    }
  `}
`

export const FooterContainer = styled.div(({ theme }) => ({
  margin: 'auto',
  paddingBottom: 64,
  borderRadius: theme.borderRadius.xl,
}))

export const SocialContainer = styled.div(({ theme }) => ({
  display: 'flex',
  marginLeft: '12px',
  // background: theme.colors.background[5],
  ':hover': {
    color: theme.colors.accent[2],
  },
}))

export const Center = styled.div((props) => ({
  height: '100%',
  width: '100%',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  ...props.style,
}))

export const Heading = styled.div`
  ${({ theme, style, primary }) => css`
    font-family: ${theme.fontFamily.primary};
    font-size: ${theme.fontSizes['4xl']};
    font-weight: ${theme.fontWeight.bold};
    line-height: ${theme.lineHeight.tight};
    color: ${primary ? theme.colors.accent[2] : theme.colors.white};
    ...style;
    @media (max-width: 1300px) {
      font-size: ${theme.fontSizes['3xl']};
    }
    @media (max-width: 800px) {
      font-size: ${theme.fontSizes['2xl']};
      margin-top: 36px;
    }
  `}
`

export const HeadingLarge = styled(Heading)`
  ${({ theme, primary }) => css`
    font-size: ${theme.fontSizes['5xl']};
    color: ${primary ? theme.colors.accent[2] : theme.colors.white};
    background: -webkit-linear-gradient(
      ${theme.colors.accent[3]},
      ${theme.colors.accent[2]}
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @media (max-width: 800px) {
      font-size: ${theme.fontSizes['3xl']};
      margin-top: 36px;
    }
  `}
`

export const Description = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.xl};
    font-weight: ${theme.fontWeight.light};
    max-width: 25em;
    margin-top: 16px;
    @media (max-width: 1300px) {
      font-size: ${theme.fontSizes.lg};
    }
    @media (max-width: 800px) {
      font-size: ${theme.fontSizes.base};
    }
  `}
`

export const DescriptionSmall = styled.div`
  ${({ theme, primary }) => css`
    font-size: ${theme.fontSizes.lg};
    font-weight: ${theme.fontWeight.light};
    max-width: 32em;
    margin-top: 32px;
    @media (max-width: 1300px) {
      font-size: ${theme.fontSizes.lg};
    }
    @media (max-width: 800px) {
      font-size: ${theme.fontSizes.base};
    }
  `}
`

// export const Description = styled.div(({ theme, style }) => ({
//   fontSize: theme.fontSizes.xl,
//   fontWeight: theme.fontWeight.light,
//   maxWidth: '26em',
//   marginTop: 16,
//   '@media (max-width: 1300px)': {
//     fontSize: theme.fontSizes.lg,
//   },
//   '@media (max-width: 800px)': {
//     fontSize: theme.fontSizes.base,
//     marginTop: 16,
//   },
//   ...style,
// }))

export const SmallText = styled.div(({ theme, style }) => ({
  display: 'flex',
  flexDirection: 'column',
  fontSize: theme.fontSizes.base,
  fontWeight: theme.fontWeight.base,
  color: theme.colors.text.neutral[0],
  // maxWidth: '26em',
  '@media (max-width: 1300px)': {
    fontSize: theme.fontSizes.sm,
  },
  '@media (max-width: 800px)': {
    fontSize: theme.fontSizes.sm,
  },
  ...style,
}))

export const Highlight = styled.span((props) => ({
  color: props.color,
  ...props.style,
}))

export const VisualContainer = styled.div`
  ${({ theme, height, image, style }) => css`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: -16px;
    margin-top: 32px;
    @media (max-width: 800px) {
      margin: -8px;
      margin-top: 36px;
    }
  `}
`

export const VisualBlock = styled.div`
  ${({ theme, height, image, style }) => css`
    border-radius: ${theme.borderRadius.lg};
    overflow: hidden;
    position: relative;
    margin: 16px;
    flex: 1 1 0;
    max-height: 60vw;
    height: ${height || 400}px;
    background-image: url(${image});
    background-size: cover;
    background-position: center center;
    @media (max-width: 800px) {
      margin: 8px;
    }
  `}
`

// export const VisualBlock = styled.div(({ theme, height, image, style }) => ({
//   borderRadius: theme.borderRadius.lg,
//   overflow: 'hidden',
//   positon: 'relative',
//   margin: 16,
//   flex: '1 1 0',
//   maxHeight: '60vw',
//   height: height || '30vw',
//   backgroundImage: `url(${image})`,
//   backgroundSize: 'cover',
//   backgroundPosition: 'center center',
//   '@media (max-width: 800px)': {
//     margin: 8,
//   },
//   ...style,
// }))

export const Button = styled.button(({ theme, disabled, style }) => ({
  borderRadius: theme.borderRadius.xl,
  height: 60,
  fontSize: theme.fontSizes.base,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0px 32px',
  border: 'none',
  color: theme.colors.text.neutral[0],
  background: theme.colors.accent[2],
  outline: 'none',
  ...style,
  ':hover': {
    background: disabled ? theme.colors.accent[2] : theme.colors.accent[1],
  },
  ':active': {
    background: disabled ? theme.colors.accent[2] : theme.colors.accent[1],
  },
}))

export const HeroText = styled.h1(
  ({ theme, isLeft }) => css`
    margin: 0;
    background: linear-gradient(
      90deg,
      rgba(51, 255, 211, 1) 0%,
      rgba(89, 48, 229, 1) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 120px;
    line-height: 1;
    font-weight: 600;
    letter-spacing: -0.015rem;

    text-align: ${isLeft ? 'left' : 'center'};
    font-family: ${theme.fontFamily.primary};
    color: #7738ff;
    font-weight: ${theme.fontWeight.black};
    white-space: nowrap;

    @media only screen and (max-width: 600px) {
      font-size: ${theme.fontSizes['2xl']};
      line-height: ${theme.fontSizes['2xl']};
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {
      font-size: ${theme.fontSizes['2xl']};
      line-height: ${theme.fontSizes['2xl']};
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
      font-size: ${theme.fontSizes['3xl']};
      line-height: ${theme.fontSizes['3xl']};
    }

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {
      font-size: ${theme.fontSizes['4xl']};
      line-height: ${theme.fontSizes['4xl']};
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
      font-size: ${theme.fontSizes['4xxl']};
      line-height: ${theme.fontSizes['4xxl']};
    }
  `
)

export const HeroSubtext = styled.h2(
  ({ isMobile, theme }) => css`
    font-size: ${isMobile ? '20px' : '32px'};
    line-height: 1.1428571429;
    letter-spacing: 0.007rem;
    text-align: center;
    font-family: ${theme.fontFamily.primary};
    color: ${theme.colors.text.neutral['0']};
    font-weight: ${theme.fontWeight.normal};
    opacity: 0.6;
    max-width: ${isMobile ? '340px' : '759px'};
    margin-left: auto;
    margin-right: auto;
  `
)

export const CTADiv = styled.button(
  ({ theme, isSmall, isAlternate }) => css`
    width: 100%;
    display: flex;
    justify-items: center;
    font-family: ${theme.fontFamily.primary};
    //font-weight: ${theme.fontWeight.bold};
    letter-spacing: ${theme.letterSpacing.wide};
    border-radius: ${isSmall ? '20px' : '32px'};
    color: ${isAlternate ? '#6242FF' : theme.colors.text.neutral[0]};
    background-color: ${isAlternate
      ? theme.colors.white
      : theme.colors.accent[2]};
    transition: all 0.1s ease-in-out;
    outline: none;
    border: none;
    padding: ${isSmall ? '12px 21px' : '16px 32px'};
    cursor: pointer;
    font-size: ${isSmall ? '16px' : '20px'};
    :hover {
      background-color: ${isAlternate
        ? theme.colors.white
        : theme.colors.accent[1]};
      color: ${isAlternate
        ? theme.colors.accent[1]
        : theme.colors.text.neutral[0]};
    }
    :active {
      background-color: ${theme.colors.pressedHelioTrope};
    }
  `
)

export const BlackCTA = styled.button(
  ({ theme, isAlternate }) => css`
    width: 100%;
    font-family: ${theme.fontFamily.primary};
    //font-weight: ${theme.fontWeight.bold};
    letter-spacing: ${theme.letterSpacing.wide};
    border-radius: 28px;
    color: ${isAlternate ? '#6242FF' : theme.colors.text.neutral[0]};
    background-color: black;
    transition: all 0.1s ease-in-out;
    outline: none;
    border: none;
    padding: 21px 50px;
    cursor: pointer;
    font-size: 22px;
    :hover {
      background-color: ${theme.colors.accent[2]};
      color: ${theme.colors.text.neutral[0]};
    }
    :active {
      background-color: ${theme.colors.acc};
    }
  `
)

export const VideoCanvas = styled.div(
  ({ theme, height }) => css`
    width: 100%;
    height: ${height};
    overflow: hidden;
    border-radius: ${theme.borderRadius.lgx};
  `
)

export const SocialProofBox = styled.div(
  ({ theme }) => css`
    display: flex;
    padding: 24px;
    color: ${theme.colors.gray.medium[9]};
    border: 1px solid ${theme.colors.gray.dark[5]};
    border-radius: ${theme.borderRadius.lgx};
    align-items: center;
    justify-content: space-between;
    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (max-width: 992px) {
      flex-direction: column;
    }
    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
      flex-direction: row;
    }
  `
)

export const SocialProofText = styled.div(
  ({ theme }) => css`
    font-size: 17px;
    line-height: 1.23536;
    margin-top: 0;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
    overflow: hidden;
    letter-spacing: 0;

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (max-width: 992px) {
      text-align: center;
    }
    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
      text-align: left;
      max-width: 400px;
    }
  `
)

export const BrandLogos = styled.div(
  ({ justifyContent, isScaledDown }) => css`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: ${justifyContent || 'center'};
    margin-top: ${isScaledDown ? '16px' : 0};

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
      justify-content: ${justifyContent || 'flex-end'};
    }
  `
)

export const BrandLogoContainer = styled.div(
  ({ isMobileVariant, isScaledDown }) => css`
    width: ${isScaledDown ? '39px' : '79px'};
    height: 30px;
    display: flex;
    justify-items: center;
    align-items: center;
    justify-content: center;
    margin: ${isScaledDown ? 'auto' : '0 24px 0 0'};
    :last-child {
      ${!isScaledDown &&
      `
        margin-right: 0;
      `}
    }
  `
)

export const BrandLogoImage = styled.img(
  ({ isMobileVariant }) => css`
    max-width: 100%;
    height: auto;
  `
)

export const TileTextContainer = styled.div(
  ({ theme, isMobileVariant }) => css`
    padding-right: 32px;
    width: 100%;
    max-width: ${isMobileVariant ? '100%' : '700px'};
    color: inherit;
  `
)

export const TileSubheading = styled.p(
  ({ color, theme, textAlign, isMobileVariant, isHeading }) => css`
    font-size: ${isMobileVariant ? '1.75rem' : '40px'};
    padding-bottom: 10px;
    line-height: 1.1428571429;
    font-weight: ${isMobileVariant ? 700 : 600};
    letter-spacing: 0.009rem;
    font-family: ${theme.fontFamily.primary};
    color: ${color || (isMobileVariant ? 'white' : 'grey')};
    text-align: ${textAlign || 'left'};
  `
)

export const PinnedMediaContainer = styled.div(
  ({ theme, isVisible }) => css`
    border-radius: ${theme.borderRadius.lgx};
    overflow: hidden;
    position: absolute;
    right: 0;
    height: 500px;
    width: 500px;
    display: ${isVisible ? 'block' : 'none'};
  `
)

export const TileImageContainer = styled.div(
  ({ theme, isMobileVariant, isVisible }) => css`
    margin-top: ${isMobileVariant ? '16px' : '0'};
    width: 100%;
    ${!isVisible &&
    `
      height: 0;
    `}
    ${isMobileVariant &&
    `
      display: flex;
    `}
    //width: auto;
    //height: auto;
    border-radius: ${theme.borderRadius.lgx};
    overflow: hidden;
  `
)

export const Tile = styled.div(
  ({ theme, isMobileVariant, isLastChild }) => css`
    display: flex;
    height: ${isMobileVariant ? 'auto' : '500px'};
    flex-direction: ${isMobileVariant ? 'column' : 'row'};
    align-items: ${isMobileVariant ? 'flex-start' : 'flex-end'};
    justify-content: ${isMobileVariant ? 'center' : ' flex-start'};
    //justify-content: space-between;
    ${isLastChild &&
    ` 
    height: ${isMobileVariant ? 'auto' : '750px'};
    align-items: ${isMobileVariant ? 'flex-start' : 'center'};
    `}
  `
)

export const SectionLead = styled.div(
  ({ theme, isVariantOne, isVariantTwo, isVariantThree, isVariantFour }) => css`
    margin: 0;
    ${isVariantOne &&
    `
    background: linear-gradient(90deg, ${theme.colors.accent[3]}, ${theme.colors.accent[2]});
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    `}

    ${isVariantTwo &&
    `
    background: linear-gradient(90deg, rgba(255,174,87,1) 0%, rgba(230,60,60,1) 61%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    `}

    ${isVariantThree &&
    `
    background: linear-gradient(90deg, rgba(89,48,229,1) 0%, rgba(245,122,173,1) 61%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    `}
    ${isVariantFour &&
    `
    background: linear-gradient(90deg, rgba(255,77,77,1) 0%, rgba(230,227,71,1) 42%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    `}
    text-align: left;
    line-height: 1.0625;
    letter-spacing: -0.009em;
    font-family: ${theme.fontFamily.primary};
    color: ${theme.colors.text.neutral['0']};
    font-weight: ${theme.fontWeight.extrabold};

    @media only screen and (max-width: 600px) {
      font-size: ${theme.fontSizes['2xl']};
      line-height: 3.5rem;
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {
      font-size: ${theme.fontSizes['2xl']};
      line-height: 3.5rem;
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
      font-size: ${theme.fontSizes['3xl']};
      line-height: 4.5rem;
    }

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {
      font-size: ${theme.fontSizes['4xl']};
      line-height: 6.5rem;
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
      font-size: ${theme.fontSizes['4xl']};
      line-height: 6.5rem;
    }
  `
)

export const DarkCards = styled.div(
  ({ isMobileVariant }) => css`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${isMobileVariant ? '16px' : '32px'};
    :last-child {
      margin-bottom: 0;
    }
  `
)

export const DarkCard = styled.div(
  ({ isMobileVariant }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    height: ${isMobileVariant ? '192px' : '338px'};
    background-color: #121212;
    border: 1px solid #222222;
    border-radius: 24px;
    padding: ${isMobileVariant ? '16px' : '0 32px'};
    margin-right: ${isMobileVariant ? '16px' : '32px'};
    :last-child {
      margin-right: 0;
    }
  `
)

export const DarkCardIconContainer = styled.div`
  margin-left: -8px;
`
export const DarkCardHeading = styled.p(
  ({ color, theme, textAlign, isMobileVariant }) => css`
    font-size: ${isMobileVariant ? '14px' : '24px'};
    line-height: 1.1428571429;
    font-weight: 600;
    letter-spacing: 0.009rem;
    font-family: ${theme.fontFamily.primary};
    color: ${color || 'white'};
    text-align: ${textAlign || 'left'};
    margin-bottom: ${isMobileVariant ? '8px' : '16px'};
  `
)

export const DarkCardDescription = styled.p(
  ({ color, theme, textAlign, isMobileVariant }) => css`
    font-size: ${isMobileVariant ? '12px' : '24px'};
    line-height: 1.2105263158;
    letter-spacing: 0.012rem;
    opacity: 0.6;
    max-width: 400px;
    font-family: ${theme.fontFamily.primary};
    color: #ffffff;
    font-weight: ${theme.fontWeight.normal};
    text-align: ${textAlign || 'left'};
  `
)

export const Outro = styled.div(
  ({ isMobileVariant }) => css`
    // background-color: #6242ff;
    background: rgb(253, 121, 53);
    background: radial-gradient(
      circle,
      rgba(253, 121, 53, 1) 0%,
      rgba(243, 173, 58, 1) 54%,
      rgba(179, 199, 210, 1) 100%
    );
    padding: ${isMobileVariant ? '64px 0' : '64px 0'};
  `
)

export const OutroLead = styled.div(
  ({ theme, isMobileVariant }) => css`
    font-size: ${isMobileVariant ? '48px' : '74px'};
    margin-bottom: 32px;
    text-align: left;
    line-height: 1.0625;
    letter-spacing: -0.009em;
    font-family: ${theme.fontFamily.primary};
    color: ${theme.colors.text.neutral['0']};
    font-weight: ${theme.fontWeight.bold};
  `
)

export const OutroSubtext = styled.h2(
  ({ isMobileVariant, theme }) => css`
    font-size: ${isMobileVariant ? '16px' : ''};
    width: 100%;
    max-width: 753px;
    margin-bottom: 32px;
    line-height: 1.2;
    letter-spacing: 0.007rem;
    font-family: ${theme.fontFamily.primary};
    color: ${theme.colors.text.neutral['0']};
    font-weight: ${theme.fontWeight.normal};
  `
)
