import { FC } from 'react'

import { SubscriptionInterval } from '../../types'
import { SwitchToggle } from './SwitchToggle'

type Props = {
  subscriptionInterval: SubscriptionInterval
  setSubscriptionInterval: (interval: SubscriptionInterval) => void
}
export const AnnualUpsellToggle: FC<Props> = ({
  setSubscriptionInterval,
  subscriptionInterval,
}) => {
  return (
    <SwitchToggle
      isOn={subscriptionInterval === 'yearly'}
      onToggle={() => {
        if (subscriptionInterval === 'yearly') {
          setSubscriptionInterval('monthly')
        } else {
          setSubscriptionInterval('yearly')
        }
      }}
    />
  )
}
