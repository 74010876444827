import React from 'react'
import Emoji from 'react-emoji-render'
import styled from 'styled-components'

import { RequireOnlyOne } from 'types/custom'

const Container = styled.div<{ size: number }>(({ size }) => ({
  width: size,
  height: size,
  fontSize: size,
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

Container.defaultProps = {
  size: 24,
}

interface BaseEmojiProps {
  code?: string
  large: boolean
  size: number
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

type EmojiProps = RequireOnlyOne<BaseEmojiProps, 'large' | 'size'>

const EmojiContainer: React.FC<EmojiProps> = ({
  code = ':bulb:',
  large,
  size,
  onClick,
}) => (
  <Container size={large ? 44 : size!} onClick={onClick}>
    <Emoji text={code || ':bulb:'} />
  </Container>
)

export default EmojiContainer
