import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

type LinkWithParamsProps = {
  to: string
}

const RedirectWithParams: React.FC<LinkWithParamsProps> = (props) => {
  const { search } = useLocation()
  return <Navigate replace to={`${props.to}${search}`} />
}

export { RedirectWithParams }
