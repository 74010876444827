import { MutableRefObject } from 'react'

import { useOthers } from '../../config/liveblocks.config'
import { Cursor } from './Cursor'

export const cursorColors = [
  '#ff8c90',
  '#ffdb8c',
  '#8c8cff',
  '#3d9bde',
  '#8cffa7',
  '#ff8cdc',
  '#5bb2ff',
  '#c55a5a',
  '#c2ff8c',
  '#52df69',
  '#e9ff5b',
  '#ff5bef',
]

type CursorsOthersProps = {
  stageRef: MutableRefObject<any>
  scale: number
}

export const CursorsOthers = ({ stageRef, scale }: CursorsOthersProps) => {
  const others = useOthers()

  return (
    <>
      {others.map(({ connectionId, presence }, i) => {
        if (!presence.cursor) return null
        if (!stageRef?.current) return null
        return (
          <Cursor
            color={cursorColors[i % cursorColors.length]}
            key={connectionId}
            name={presence.name}
            x={presence.cursor.x * scale + stageRef.current.x()}
            y={presence.cursor.y * scale + stageRef.current.y()}
            coreX={presence.cursor.x}
            coreY={presence.cursor.y}
          />
        )
      })}
    </>
  )
}
