import { getEncodedTag } from 'components/search/SearchContext'
import {
  SearchCombo,
  SearchComboColor,
  SearchComboFace,
  SearchComboMediaType,
  SearchComboTag,
} from 'generated/graphql'

type GetSearchUrlParamsFromSearchCombosArgs = {
  searchCombos: Array<SearchCombo>
}

export const getSearchUrlParamsFromSearchCombos = ({
  searchCombos,
}: GetSearchUrlParamsFromSearchCombosArgs) => {
  const searchParams = new URLSearchParams()

  searchParams.append('search', 'true')

  const tagCombos = searchCombos.filter(
    (combo) => 'tagId' in combo
  ) as SearchComboTag[]

  const colorCombos = searchCombos.filter(
    (combo) => 'color' in combo
  ) as SearchComboColor[]

  const mediaTypeCombos = searchCombos.filter(
    (combo) => 'mediaType' in combo
  ) as SearchComboMediaType[]

  const faceCombos = searchCombos.filter(
    (combo) => 'face' in combo
  ) as SearchComboFace[]

  if (tagCombos.length > 0) {
    searchParams.append(
      'tags',
      tagCombos
        .map((tagCombo) =>
          getEncodedTag({ label: tagCombo.description, id: tagCombo.tagId })
        )
        .join(',')
    )
  }

  if (faceCombos.length > 0) {
    searchParams.append(
      'face',
      faceCombos.map((tagCombo) => tagCombo.face).join(',')
    )
  }

  // search only supports one color
  if (colorCombos[0]) {
    searchParams.append('color', colorCombos[0].color)
  }

  // search only supports one media type
  if (mediaTypeCombos[0]) {
    searchParams.append(
      'fileFormat',
      mediaTypeCombos[0].mediaType.toLowerCase()
    )
  }

  return searchParams.toString()
}
