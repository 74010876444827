import { useEffect } from 'react'

import {
  useIncrementBoardItemViewsMutation,
  useIncrementWorkspaceItemViewsMutation,
} from 'generated/graphql'

import { ItemData } from './useGetItemData'

type UseIncrementItemViewsProps = {
  itemData?: ItemData
}

export const useIncrementItemViews = ({
  itemData,
}: UseIncrementItemViewsProps) => {
  const [incrementBoardItemViews] = useIncrementBoardItemViewsMutation()
  const [incrementWorkspaceItemViews] = useIncrementWorkspaceItemViewsMutation()

  useEffect(() => {
    if (!itemData?.id) {
      return
    }
    const isBoardItem = 'boardId' in itemData
    if (isBoardItem) {
      incrementBoardItemViews({
        variables: {
          boardItemId: itemData.id,
        },
      })
    } else {
      incrementWorkspaceItemViews({
        variables: {
          workspaceItemId: itemData.id,
        },
      })
    }
  }, [itemData?.id])
}
