import styled, { css } from 'styled-components'

import Flex from 'components/common/Flex'
import { Text } from 'components/common/Text'
import { DiscoverSearchComboPayload } from 'generated/graphql'

type SearchComboProps = {
  searchCombo: DiscoverSearchComboPayload
  onClick: () => void
}

export const SearchCombo = (props: SearchComboProps) => {
  return (
    <StyledSearchComboWrapper px={0.5} py={0.5} onClick={props.onClick}>
      {props.searchCombo.searchCombo.map((combo) => {
        return (
          <StyledCombo px={1} py={0.3} key={combo.description}>
            <Text color="neutral.0" size="sm" nowrap ellipsis>
              {combo.description}
            </Text>
          </StyledCombo>
        )
      })}
    </StyledSearchComboWrapper>
  )
}

const StyledSearchComboWrapper = styled(Flex)(
  ({ theme }) => css`
    background-color: ${theme.colors.background[5]};
    border: ${theme.border.thin} ${theme.colors.gray.dark[7]};
    border-radius: ${theme.borderRadius.full};
    cursor: pointer;
    transition: 0.2s border ease;
    :hover {
      border: ${theme.border.thin} ${theme.colors.gray.light[6]};
    }
  `
)

const StyledCombo = styled(Flex)(
  ({ theme }) => css`
    border: ${theme.border.thin} ${theme.colors.gray.medium[6]};
    border-radius: ${theme.borderRadius.full};
    margin-right: 8px;
    :last-child {
      margin-right: 0px;
    }
  `
)
