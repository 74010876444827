import styled from 'styled-components'

export const Heading = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 16,
  fontSize: theme.fontSizes.base,
  color: theme.colors.text.neutral[0],
  fontWeight: 'bold',
}))

export const Container = styled.div(({ theme }) => ({
  textAlign: 'left',
  width: 258,
  overflow: 'hidden',
  height: 'auto',
  background: theme.colors.background[3],
  borderRadius: theme.borderRadius.default,
  marginTop: 8,
  marginLeft: 16,
  boxShadow: theme.shadow.minimal,
  border: `${theme.border.thin} ${theme.colors.discreet}`,
  zIndex: 100,
}))

export const OptionsContainer = styled.div({
  padding: 16,
  maxHeight: 360,
  overflowY: 'scroll',
})

export const OptionGroupItem = styled.div(({ theme }) => ({
  color: theme.colors.text.neutral[0],
  padding: '2px 6px',
  borderRadius: theme.borderRadius.default,
  cursor: 'pointer',
  display: 'inline-block',
  fontSize: 24,
  ':hover': {
    background: theme.colors.background[4],
  },
  ':active': {
    background: theme.colors.background[5],
  },
}))

export const ItemDetail = styled.div(({ theme }) => ({
  color: theme.colors.text.neutral[0],
  display: 'flex',
  alignItems: 'center',
  padding: 8,
  background: theme.colors.background[1],
  borderTop: `${theme.border.thin} ${theme.colors.discreet}`,
  boxShadow: '0 -8px 24px 0 rgba(0,0,0,0.1)',
}))

export const ItemDetailIcon = styled.div(({ theme }) => ({
  marginLeft: 12,
  fontSize: theme.fontSizes.xl,
  display: 'inline-block',
}))

export const ItemDetailText = styled.div(({ theme }) => ({
  fontSize: theme.fontSizes.sm,
  display: 'inline-block',
  fontWeight: parseInt(theme.fontWeight.bold, 10),
  marginLeft: 16,
}))
