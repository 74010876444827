import { ApolloError } from '@apollo/client'
import { useEffect } from 'react'
import styled from 'styled-components'

import Button from 'components/common/Button'
import { Spacer } from 'components/common/Spacer'
import { Text } from 'components/common/Text'
import { reportError } from 'helpers/logging'
import { getGraphqlErrorCode } from 'helpers/parseGraphqlErrors'

const errorCodeToMessage: Record<string, string> = {
  '404': 'Looks like the item has been deleted',
  FORBIDDEN: 'You do not have permission to view this item',
}

type ItemErrorProps = {
  error: ApolloError
  onGoBack?: () => void
}

export const ItemError = ({ error, onGoBack }: ItemErrorProps) => {
  const code = getGraphqlErrorCode(error)

  useEffect(() => {
    if (!errorCodeToMessage[code]) {
      reportError(`Unknown error when loading Item view. Error code: ${code}`)
    }
  }, [code])

  return (
    <Container>
      <Text size="xl" as="h1" center color="neutral.0">
        Oh no!
      </Text>
      <Text size="lg" center color="neutral.0">
        {errorCodeToMessage[code] ||
          'We could not show you the item, try reload the page...'}
      </Text>
      <Spacer axis="y" factor={2} />
      {onGoBack ? (
        <Button variant="primary" onClick={onGoBack}>
          Take me back
        </Button>
      ) : (
        <Button variant="primary" as="a" href="/">
          Home
        </Button>
      )}
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 50;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.background[0]};
`
