import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  width: 100vw;
  display: flex;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`

const HorizontalScroll = ({ children }: { children: React.ReactNode }) => (
  <Container>{children}</Container>
)

export default HorizontalScroll
