import { motion } from 'framer-motion'
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'

import useMeasure from 'hooks/useMeasure'

const SwitchContainer = styled.div(({ theme }) => ({
  width: '100%',
  maxWidth: 540,
  overflow: 'hidden',
  textAlign: 'center',
  display: 'inline-flex',
  position: 'relative',
  borderRadius: theme.borderRadius.xl,
  fontSize: theme.fontSizes.sm,
  '@media (max-width: 800px)': {
    fontSize: theme.fontSizes.xs,
  },
}))

const Background = () => {
  const theme = useTheme()

  return (
    <motion.div
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
      }}
      animate={{
        backgroundColor: theme.colors.background[5],
      }}
      transition={{
        duration: 0.2,
        ease: 'easeOut',
      }}
    />
  )
}

const Case = styled.div<{ active: boolean }>(({ theme, active }) => ({
  padding: 8,
  borderRadius: theme.borderRadius.xl,
  flex: 1,
  whiteSpace: 'nowrap',
  color: active ? theme.colors.white : theme.colors.text.neutral[2],
  position: 'relative',
  cursor: 'pointer',
  webkitUserSelect: 'none' /* Safari */,
  mozUserSelect: 'none' /* Firefox */,
  msUserSelect: 'none' /* IE10+/Edge */,
  userSelect: 'none' /* Standard */,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  ':hover': {
    color: theme.colors.white,
  },
}))

const Bubble = (props: { xPosition: number; width: number }) => {
  const theme = useTheme()

  return (
    <motion.div
      style={{
        width: props.width,
        position: 'absolute',
        height: '100%',
        borderRadius: theme.borderRadius.xl,
        backgroundColor: theme.colors.accent[2],
      }}
      animate={{
        x: Math.max(0, props.xPosition),
      }}
      transition={{
        duration: 0.2,
        ease: 'backInOut',
      }}
    />
  )
}

interface LinkType {
  id: string
  text: string
  path?: string
}

interface ToSwitchProps {
  links: LinkType[]
  defaultActive?: number
  mode?: 'url' | 'searchPath'
  onSwitch?: any
  className?: string
}

const ToSwitch: React.FC<ToSwitchProps> = ({
  defaultActive,
  links,
  mode,
  onSwitch,
  className,
}) => {
  const [activeCaseState, setActiveCaseState] = useState(defaultActive || 0)
  const [bind, { width }] = useMeasure()
  const navigate = useNavigate()
  const location = useLocation()

  const getActiveUrlCaseIndex = () => {
    return links.findIndex((link) => {
      return location.pathname.includes(link.path!)
    })
  }

  const getUrlSearchCaseIndex = () => {
    if (!location.search) return defaultActive || 0
    return links.findIndex((link) => location.search.indexOf(link.id) !== -1)
  }

  const handleSwitch = (link: LinkType, index: number) => {
    switch (true) {
      case mode === 'url':
        if (link.path) return navigate(link.path)
        break
      default:
        setActiveCaseState(index)
        onSwitch?.(link)
    }
  }

  const getActiveCase = () => {
    switch (true) {
      case mode === 'url':
        return getActiveUrlCaseIndex()
      case mode === 'searchPath':
        return getUrlSearchCaseIndex()
      default:
        return activeCaseState
    }
  }

  const activeCase = getActiveCase()

  return (
    <SwitchContainer ref={bind.ref} className={className}>
      <Background />
      <Bubble
        xPosition={(width / links.length) * activeCase}
        width={width / links.length}
      />
      {links.map((link, index) => (
        <Case
          key={link.id}
          active={activeCase === index}
          onClick={() => handleSwitch(link, index)}
        >
          {link.text}
        </Case>
      ))}
    </SwitchContainer>
  )
}

export default ToSwitch
