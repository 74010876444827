import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import { RootState } from 'store'
import { toggleItem } from 'store/multiSelect'
import { isInMultiSelectModeSelector } from 'store/selectors'

import type { Item } from './GridItem'

interface MultiSelectOverlayProps {
  item: Item
  isDisabled?: boolean
  children: (
    isInMultiSelectMode: boolean,
    isSelected: boolean
  ) => React.ReactNode
}

const MuliSelectOverlay: React.FC<MultiSelectOverlayProps> = ({
  item,
  children,
  isDisabled,
}) => {
  const dispatch = useDispatch()
  const isSelected = useSelector(
    (state: RootState) => !!state.multiSelect.selectedItems[item.id]
  )
  const isFocused = useSelector(
    (state: RootState) => state.content.focusedItem?.id === item.id
  )
  const isInMultiSelectMode = useSelector(isInMultiSelectModeSelector)
  const showSelectBox = isFocused || isInMultiSelectMode

  const selectItem = (e: React.MouseEvent) => {
    e.stopPropagation()
    dispatch(toggleItem({ id: item.id, item }))
  }
  return (
    <SelectionWrapper
      isFocused={isFocused}
      isInMultiSelectMode={isInMultiSelectMode}
      onClick={isInMultiSelectMode ? selectItem : undefined}
    >
      {showSelectBox && !isDisabled && (
        <SelectionMarkerOuter isSelected={isSelected} onClick={selectItem}>
          <SelectionMarkerInner isSelected={isSelected} />
        </SelectionMarkerOuter>
      )}
      {typeof children === 'function'
        ? children(isInMultiSelectMode, isSelected)
        : children}
    </SelectionWrapper>
  )
}

export default MuliSelectOverlay

const SelectionWrapper = styled.div<{
  isFocused: boolean
  isInMultiSelectMode: boolean
}>`
  ${({ isFocused, isInMultiSelectMode }) => css`
    position: relative;
    ${isFocused &&
    isInMultiSelectMode &&
    css`
      ${SelectionMarkerInner} {
        transform: scale(0.5);
      }
    `}
  `}
`

const SelectionMarkerInner = styled.div<{ isSelected: boolean }>`
  ${({ theme, isSelected }) => css`
    background: ${theme.colors.accent[2]};
    border-radius: ${theme.borderRadius.full};
    width: 100%;
    height: 100%;
    transform: ${isSelected ? 'scale(1)' : 'scale(0)'};
    transition: 0.1s transform ease;
  `}
`

const SelectionMarkerOuter = styled.div<{ isSelected: boolean }>`
  ${({ theme, isSelected }) => css`
    position: absolute;
    left: 15px;
    bottom: 15px;
    z-index: 1;
    height: 20px;
    width: 20px;
    transition: 0.1s background ease;
    background: ${isSelected ? theme.colors.accent[2] : 'transparent'};
    border-radius: ${theme.borderRadius.full};
    border: 2px solid
      ${isSelected ? theme.colors.accent[2] : theme.colors.gray.light[4]};
    cursor: pointer;
    :hover ${SelectionMarkerInner} {
      transform: ${!isSelected && 'scale(0.5)'};
    }
  `}
`
