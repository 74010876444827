import { Refresh } from '@styled-icons/material/Refresh'
import { motion } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import { useFirestore } from 'react-redux-firebase'
import styled, { css } from 'styled-components'

import Button from 'components/common/Button'
import { Margin } from 'components/common/Margin'
import { Text } from 'components/common/Text'
import { shuffleArray } from 'helpers/shuffleArray'

interface SuggestedVimeoContacts {
  email: string
  img: string
  name: string
  vimeoId: string
}

interface VimeoSuggestionProps {
  userId: string
  onNominateVimeoSuggestion: (vimeoNominationArgs: {
    email: string
    vimeoId: string
  }) => void
  onVimeoSuggestionsLoaded: (nbrOfVimeoSuggestions: number) => void
}

const VimeoSuggestions: React.FC<VimeoSuggestionProps> = ({
  userId,
  onNominateVimeoSuggestion,
  onVimeoSuggestionsLoaded,
}) => {
  const [allVimeoSuggestions, setAllVimeoSuggestions] = useState<
    SuggestedVimeoContacts[]
  >([])

  const [vimeoSuggestionsToDisplay, setVimeoSuggestionsToDisplay] = useState<
    SuggestedVimeoContacts[]
  >([])

  const [alreadyNominatedEmails, setAlreadyNominatedEmails] = useState<
    string[]
  >([])

  const pointer = useRef(0)

  const firestore = useFirestore()

  useEffect(() => {
    const setVimeoSuggestions = async () => {
      const vimeoUser = await firestore
        .collection('vimeoUsers')
        .doc(userId)
        .get()

      if (!vimeoUser.exists) return

      const { suggestedContacts = [] } = vimeoUser.data() as {
        suggestedContacts: SuggestedVimeoContacts[]
      }
      const shuffledArray = shuffleArray(suggestedContacts) // we shuffle the array to make it more attractive for the user
      setAllVimeoSuggestions(shuffledArray)
      addNextVimeoSuggestions(shuffledArray)
      if (shuffledArray.length > 0) {
        onVimeoSuggestionsLoaded(shuffledArray.length)
      }
    }
    setVimeoSuggestions()
  }, [])

  const removeVimeoSuggestion = async (email: string) => {
    await firestore.runTransaction(async (t) => {
      const ref = firestore.collection('vimeoUsers').doc(userId)
      const vimeoUserDoc = await t.get(ref)
      const { suggestedContacts = [] } = vimeoUserDoc.data() as {
        suggestedContacts: SuggestedVimeoContacts[]
      }
      const filteredSuggestedContacts = suggestedContacts.filter(
        (suggestedContact) => {
          return suggestedContact.email !== email
        }
      )
      t.update(ref, { suggestedContacts: filteredSuggestedContacts })
    })
  }

  const addNextVimeoSuggestions = (
    suggestedContacts: SuggestedVimeoContacts[]
  ) => {
    const nextVimeoSuggestions = suggestedContacts.slice(
      pointer.current,
      pointer.current + 5
    )

    setVimeoSuggestionsToDisplay(nextVimeoSuggestions)

    const hasMore = suggestedContacts.length > pointer.current + 5

    if (hasMore) {
      pointer.current += 5
    } else {
      pointer.current = 0
    }
  }

  const refreshSuggestions = () => {
    addNextVimeoSuggestions(allVimeoSuggestions)
  }

  const onNominateClick = async ({
    vimeoId,
    email,
  }: {
    vimeoId: string
    email: string
  }) => {
    onNominateVimeoSuggestion({ email, vimeoId })
    setAlreadyNominatedEmails((prev) => [...prev, email])
    removeVimeoSuggestion(email)
  }

  if (allVimeoSuggestions.length === 0) return null

  const showRefreshIcon = allVimeoSuggestions.length > 5
  return (
    <>
      <Text size="base" css="user-select: none;">
        People you might know
        {showRefreshIcon && (
          <RefreshIcon onClick={refreshSuggestions}>Refresh</RefreshIcon>
        )}
      </Text>
      <Margin y={6} />
      <VimeoSuggestionsList>
        {vimeoSuggestionsToDisplay.map(({ img, name, email, vimeoId }) => {
          const alreadyNominated = alreadyNominatedEmails.includes(email)
          return (
            <VimeoSuggestionCard
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              alreadyNominated={alreadyNominated}
              key={email}
            >
              <VimeoAvatar src={img} />
              <Margin y={12} />
              <VimeoName color="neutral.0" size="xs" bold center>
                {name}
              </VimeoName>
              <Margin y={18} />
              {alreadyNominated ? (
                <Text size="sm" color="accent.2">
                  Nominated
                </Text>
              ) : (
                <Button
                  onClick={() => onNominateClick({ email, vimeoId })}
                  css="width: 100px;height: 35px;"
                  variant="primary"
                >
                  Nominate
                </Button>
              )}
            </VimeoSuggestionCard>
          )
        })}
      </VimeoSuggestionsList>
      <Margin y={18} />
      <Text size="base">Or nominate by email</Text>
      <Margin y={12} />
    </>
  )
}

export default VimeoSuggestions

const VimeoSuggestionsList = styled.div`
  display: flex;
`

const VimeoSuggestionCard = styled(motion.div)<{ alreadyNominated: boolean }>`
  ${({ theme, alreadyNominated }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${theme.colors.background[4]};
    border-radius: ${theme.borderRadius.default};
    padding: 18px;
    margin-right: 8px;
    width: 140px;
    height: 170px;
    ${alreadyNominated &&
    css`
      opacity: 0.6;
    `}
  `}
`

const VimeoAvatar = styled.img`
  ${({ theme }) => css`
    width: 50px;
    height: 50px;
    border-radius: ${theme.borderRadius.lg};
  `}
`

const VimeoName = styled(Text)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
`

const RefreshIcon = styled(Refresh)`
  ${({ theme }) => css`
    height: 25px;
    color: white;
    margin-left: 8px;
    cursor: pointer;
    background: ${theme.colors.background[5]};
    border-radius: ${theme.borderRadius.default};
    padding: 4px;
    &:hover {
      background: ${theme.colors.accent[2]};
    }
  `}
`
