import styled, { css } from 'styled-components'

export const StyledRoomTypeTagRow = styled.div`
  padding: 0 0 1rem 0;
  display: flex;
  justify-content: center;
`
export const StyledRoomTypeTagContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 2rem;
    flex-direction: row;
    @media (max-width: ${theme.breakpoints.sm}px) {
      gap: 0.5rem;
      flex-direction: column;
    }
  `}
`
export const StyledRoomTypeTag = styled.button<{ active: boolean }>`
  ${({ theme, active }) => css`
    color: ${active ? theme.colors.white : theme.colors.discreet};
    font-family: ${theme.fontFamily.primary};
    font-size: ${theme.fontSizes['2xl']};
    font-weight: ${theme.fontWeight.medium};
    line-height: ${theme.lineHeight.tight};

    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    outline: inherit;
  `}
`

export const StyledRoomCardGrid = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 2rem;
    padding: 4rem;
    @media (max-width: ${theme.breakpoints.lg}px) {
      grid-template-columns: 1fr 1fr 1fr;
      padding: 0.5rem 2rem;
      gap: 1.5rem;
    }
    @media (max-width: ${theme.breakpoints.md}px) {
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: ${theme.breakpoints.sm}px) {
      grid-template-columns: 1fr;
      padding: 0.25rem 2rem;
      gap: 1rem;
    }
  `}
`

export const StyledRoomCard = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.background[1]};
    color: ${theme.colors.text.neutral[0]};
    border: ${theme.border.thin} ${theme.colors.discreet};
    display: inline-block;
    position: relative;
    padding: 2;
    border-radius: ${theme.borderRadius.default};
    font-size: ${theme.fontSizes.sm};
    line-height: ${theme.lineHeight.tight};
    margin-top: 8px;
    letter-spacing: ${theme.letterSpacing.normal};
    cursor: pointer;
    width: 100%;
    &:hover {
      background-color: ${theme.colors.background[3]};
      color: ${theme.colors.text.neutral[0]};
      cursor: pointer;
    }
    &:active {
      background-color: ${theme.colors.background[4]};
      color: ${theme.colors.accent[2]};
    }
  `}
`
export const StyledRoomCardContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledRoomImage = styled.img`
  ${({ theme }) => css`
    object-fit: cover;
    width: 100%;
    height: 200px;
    flex: 1;
    display: block;
    pointer-events: none;
    border-top-left-radius: ${theme.borderRadius.default};
    border-top-right-radius: ${theme.borderRadius.default};
    opacity: 1;
    transition: 0.5s ease;
    backface-visibility: hidden;
  `}
`

export const StyledRoomCardContent = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.discreet};
    padding: 1rem 0.75rem;
    display: flex;
    justify-content: space-between;
  `}
`
export const StyledRoomCardContentText = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    font-family: ${theme.fontFamily.primary};
    font-size: ${theme.fontSizes.base};
    font-weight: ${theme.fontWeight.medium};
    line-height: ${theme.lineHeight.tight};
  `}
`
export const StyledRoomCardContentTimestamp = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    font-family: ${theme.fontFamily.primary};
    font-size: ${theme.fontSizes.base};
    font-weight: ${theme.fontWeight.medium};
    line-height: ${theme.lineHeight.tight};
  `}
`

export const StyledRoomJoinButtonContainer = styled.div`
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`
export const StyledRoomImageContainer = styled.div`
  position: relative;
  &:hover {
    ${StyledRoomImage} {
      opacity: 0.3;
    }
    ${StyledRoomJoinButtonContainer} {
      opacity: 1;
    }
  }
`

type StyledRoomJoinButtonProps = {
  isSelected?: boolean
}
export const StyledRoomJoinButton = styled.button<StyledRoomJoinButtonProps>`
  font-family: ${({ theme }) => theme.fontFamily.primary};
  font-size: ${({ theme }) => theme.fontSizes['2xl']};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: ${({ theme }) => theme.lineHeight.tight};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  height: 32px;
  width: auto;
  gap: 8px;
  cursor: pointer;
  align-items: center;
  display: flex !important;
  justify-content: center;
  padding: 1.25rem 2rem;
  color: white;

  user-select: none;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
`

export const StyledShowMoreButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 2rem 0;
`

export const StyledBackButtonContainer = styled.div`
  left: 0;
  top: 0;
  padding-left: 0.5rem;
  padding-top: 0.5rem;
`
