import { useState } from 'react'
import GoogleButton from 'react-google-button'
import { useForm } from 'react-hook-form'
import styled, { useTheme } from 'styled-components'

import { InfoParagraph } from 'components/authentication/AuthenticationStyles'
import Button from 'components/common/Button'
import Flex from 'components/common/Flex'
import { Field } from 'components/common/Form'
import { Spacer } from 'components/common/Spacer'
import { Text } from 'components/common/Text'
import { firebaseAuth } from 'config/firebase'
import { useAddUserMutation } from 'generated/graphql'
import { loginWithGooglePopup } from 'helpers/auth'

type SignupModalProps = {
  onSwitch: () => void
  onSignup: () => void
  onLogin: () => void
}

const StyledLineBreak = styled.div`
  width: 100%;
  text-align: center;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 10px;
    background: red;
    background: ${({ theme }) => theme.colors.gray.dark[6]};
    height: 1px;
  }
`

const StyledLineBreakText = styled.span`
  position: relative;
  background: ${({ theme }) => theme.colors.background[0]};
  color: ${({ theme }) => theme.colors.text.neutral[2]};
  padding: 0 15px;
`

const StyledText = styled(Text)`
  &:hover {
    cursor: pointer;
  }
`

export const Signup = ({ onSwitch, onSignup, onLogin }: SignupModalProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [registerError, setRegisterError] = useState(null)
  const [addUserMutation] = useAddUserMutation()
  const theme = useTheme()

  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const createUser = async () => {
    try {
      await addUserMutation({
        variables: {
          fromPublicFeatureData: {
            itemId: '',
            boardId: '',
            boardItemId: '',
            canvas: true,
          },
        },
      })
      onSignup()
    } catch (e) {
      setRegisterError(e)
    } finally {
      setIsLoading(false)
    }
  }

  const onSubmitSignup = (values: any, ev: any) => {
    ev.preventDefault()
    setIsLoading(true)

    const formErrors = []

    if (values.password?.length < 6) {
      formErrors.push({
        field: 'password',
        error: { message: 'Password is too short' },
      })
    }

    if (values.email?.length === 0) {
      formErrors.push({ field: 'email', error: { message: 'Enter email' } })
    }

    if (formErrors.length) {
      formErrors.map(({ field, error }) =>
        setError(field as 'email' | 'password', error)
      )
      return setIsLoading(false)
    }

    const email = values.email.trim().toLowerCase()
    return firebaseAuth()
      .createUserWithEmailAndPassword(email, values.password)
      .then(createUser)
  }

  const handleGoogleSignUp = () => {
    loginWithGooglePopup().then((user) => {
      if (user.additionalUserInfo?.isNewUser) {
        createUser()
      } else {
        onLogin()
      }
    })
  }

  return (
    <>
      <Flex justifyContent="center">
        <Text size="2xl" bold color="neutral.0">
          Sign up
        </Text>
      </Flex>
      <Spacer factor={2} />
      <GoogleButton
        label="Sign up with Google"
        style={{
          width: '100%',
          borderRadius: theme.borderRadius.default,
          overflow: 'hidden',
          background: theme.colors.accent[2],
          fontFamily: theme.fontFamily.primary,
          fontWeight: theme.fontWeight.bold,
        }}
        onClick={handleGoogleSignUp}
      />
      <Spacer factor={3} />
      <StyledLineBreak>
        <StyledLineBreakText>Or use email</StyledLineBreakText>
      </StyledLineBreak>
      <Spacer factor={2} />
      <form onSubmit={handleSubmit(onSubmitSignup)}>
        <Field
          name="email"
          register={register}
          placeholder="Email"
          type="email"
          centered={false}
          errors={errors}
          required
        />
        <Field
          name="password"
          register={register}
          placeholder="Choose a password"
          type="password"
          centered={false}
          errors={errors}
          required
        />
        {registerError && <InfoParagraph>{registerError}</InfoParagraph>}
        <Button isLoading={isLoading} variant="primary" type="submit" fullWidth>
          Sign up with email
        </Button>
        <Spacer factor={2} />
        <Flex>
          <Text size="sm">Already have an account? </Text>
          <Spacer factor={0.5} axis="x" />
          <StyledText size="sm" color="accent.2" onClick={onSwitch}>
            Log in
          </StyledText>
        </Flex>
        <Spacer factor={2} />
      </form>
    </>
  )
}
