import { Close as CloseIcon } from '@styled-icons/material-rounded/Close'
import React, { useState } from 'react'
import styled, { DefaultTheme, css, useTheme } from 'styled-components'

const TagComponent = styled.div<{ color: string }>`
  ${({ color }) => css`
    display: inline-block;
    padding: 6px 16px 6px 12px;
    color: ${color};
  `}
`

const Close = styled.div<{ mouseOver: boolean }>(({ theme, mouseOver }) => ({
  display: mouseOver ? 'inline-flex' : 'none',
  position: 'absolute',
  right: 0,
  margin: 4,
  top: 0,
  width: 26,
  height: 26,
  padding: 4,
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  cursor: 'pointer',
  backgroundColor: theme.colors.background[4],
  ':hover': {
    color: theme.colors.text.neutral[0],
    backgroundColor: theme.colors.accent[1],
  },
}))

const Tag = styled.div<{ color: string }>`
  ${({ theme, color }) => css`
    position: relative;
    min-width: 52px;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    border: ${`${theme.border.thin} ${color}`};
    color: ${color};
    margin-left: 4px;
    border-radius: ${theme.borderRadius.lg};
    background-color: ${theme.colors.background[3]};
    font-size: ${theme.fontSizes.sm};
    cursor: text;
    &:hover {
      min-width: 64px;
      background: ${theme.colors.background[4]};
      color: ${theme.colors.text.neutral[0]};
    }
  `}
`

const getTagColor = (id: string, label: string, theme: DefaultTheme) => {
  if (label.startsWith('#')) return label
  if (label.startsWith('Face')) return theme.colors.accent[0]
  if (id !== label) return theme.colors.accent[1]
  return theme.colors.text.neutral[3]
}

interface SearchTagProps {
  label: string
  id: string
  onClose: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const SearchTag: React.FC<SearchTagProps> = ({
  label,
  id,
  onClick,
  onClose,
}) => {
  const theme = useTheme()
  const [mouseOver, setMouseOver] = useState(false)
  const color = getTagColor(id, label, theme)
  return (
    <Tag
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
      color={color}
      onClick={onClick}
    >
      {label && <TagComponent color={color}>{label}</TagComponent>}
      <Close mouseOver={mouseOver} onClick={onClose}>
        <CloseIcon style={{ height: '100%' }} />
      </Close>
    </Tag>
  )
}

export default SearchTag
