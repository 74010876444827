export const AnnualUpsellText = {
  en: {
    argument: 'Save with annual billing',
    discount: {
      template: '$[[value]] off',
      target: '[[value]]',
    },
    price: {
      template: '$[[value]]/year',
      target: '[[value]]',
    },
  },
}
