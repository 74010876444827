import { client } from 'apollo'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled, { useTheme } from 'styled-components'

import Loading from 'components/Loading'
import {
  MagicBoardSimpleDocument,
  MagicBoardSimpleQuery,
  MagicBoardSimpleQueryVariables,
} from 'generated/graphql'
import { typedBoolean } from 'helpers/typedBoolen'
import { RootState } from 'store'
import { DBSearchCategory } from 'types/db'

import { useSearchContext } from './SearchContext'
import { SearchHeading } from './SearchStyles'
import SearchTags from './SearchTags'

const SearchResultsContainer = styled.div`
  padding: 0 16px;
`

const SearchCategoryTags: React.FC = () => {
  const theme = useTheme()
  const { category: categoryId } = useSearchContext()

  const [isLoading, setIsLoading] = useState(false)
  const [categoryTags, setCategoryTags] = useState<
    MagicBoardSimpleQuery['magicBoard'][]
  >([])

  const activeWorkspaceId = useSelector(
    (store: RootState) => store.content.activeWorkspace!.id
  )

  const category = useSelector((state: RootState) =>
    categoryId !== null
      ? (state.firestore.data.searchCategories[categoryId] as DBSearchCategory)
      : null
  )

  useEffect(() => {
    const getCategoryTags = async () => {
      if (category === null) {
        return
      }
      setIsLoading(true)
      const magicBoards = await Promise.all(
        category.searchSuggestions.map(async (id) => {
          try {
            const { data } = await client.query<
              MagicBoardSimpleQuery,
              MagicBoardSimpleQueryVariables
            >({
              query: MagicBoardSimpleDocument,
              variables: {
                workspaceId: activeWorkspaceId,
                magicBoardId: id,
              },
              context: {
                batch: true,
              },
            })
            return data?.magicBoard
          } catch (error) {
            return null
          }
        })
      )
      setCategoryTags(magicBoards.filter(typedBoolean))
      setIsLoading(true)
    }
    getCategoryTags()
  }, [activeWorkspaceId, category])

  return (
    <SearchResultsContainer>
      {category !== null && (
        <>
          <SearchHeading>
            Tags in category{' '}
            <span style={{ color: theme.colors.accent[1] }}>
              {category.title}
            </span>
          </SearchHeading>
          {isLoading && categoryTags.length === 0 ? (
            <Loading />
          ) : (
            <SearchTags tags={categoryTags} />
          )}
        </>
      )}
    </SearchResultsContainer>
  )
}

export default SearchCategoryTags
