import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { RootState } from 'store'

import { Editor } from '../../../features/editor/Editor'
import { useEditSessionQuery } from '../../../generated/graphql'
import Loading from '../../Loading'

export const EditSession = () => {
  const { sessionId } = useParams()
  const [editorKey, setEditorKey] = useState(0)

  const { data: editSessionData, loading } = useEditSessionQuery({
    variables: {
      id: `${sessionId}`,
    },
    fetchPolicy: 'cache-and-network',
    skip: !sessionId,
  })

  const { uid } = useSelector((state: RootState) => state.firebase.auth)

  useEffect(() => {
    try {
      if (uid) window?.analytics?.identify(uid)
    } catch (err) {
      console.warn('failed to do identify call')
    }
  }, [uid])

  if (!sessionId) return null

  if (loading) {
    return <Loading />
  }

  if (!editSessionData?.editSession) return null

  const inProduction = process.env.REACT_APP_ENV === 'PRODUCTION'

  const { editSession } = editSessionData
  const ogImageQuery = editSession.updatedAt
    ? `?v=${editSession.updatedAt}`
    : ''

  return (
    <>
      <Helmet>
        <meta property="og:title" content="Kive AI Canvas" />
        <meta
          property="og:description"
          content="Create with AI in multiplayer."
        />
        {inProduction && (
          <meta
            property="og:image"
            content={`https://kive.ai/og-images/canvas/${editSession.id}${ogImageQuery}`}
          />
        )}
      </Helmet>
      <Editor
        key={editorKey}
        roomId={editSession.id}
        startImageUrl={editSession.startValues?.baseImageUrl}
        remountEditor={() => setEditorKey((prev) => prev + 1)}
      />
    </>
  )
}
