import React from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import ItemTags from 'components/itemtags/ItemTags'
import { RootState } from 'store'

import type { ItemData } from './hooks/useGetItemData'

const Container = styled.div<{ isLightMode?: boolean }>`
  ${({ theme, isLightMode }) => css`
    width: 320px;
    border-radius: 0 16px 16px 0;
    background: ${theme.colors.background[3]};
    ${isLightMode &&
    css`
      border-left: 1px solid ${theme.colors.gray.light[0]};
    `}
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
  `}
`

const Padder = styled.div`
  padding: 12px;
`

const ScrollContainer = styled.div`
  position: absolute;
  width: 320px;
  padding-right: 6px;
`

interface PublicItemSiderProps {
  item: ItemData
  inDiscover?: boolean
}

const PublicItemSider: React.FC<PublicItemSiderProps> = ({
  item,
  inDiscover,
}) => {
  const isLightMode = useSelector(
    (state: RootState) => state.content.theme === 'lightTheme'
  )
  const workspaceItemId =
    'workspaceItemId' in item ? item.workspaceItemId : item.id
  return (
    <>
      <Container isLightMode={isLightMode}>
        <ScrollContainer>
          <Padder>
            <ItemTags
              itemId={item.id}
              itemType={item.__typename}
              allowedToEditTags={['OWNER', 'WRITER'].includes(item.userRole)}
              workspaceItemId={workspaceItemId}
              tags={item.tags}
              isEditable={false}
              isClickable={Boolean(inDiscover)}
              inDiscover={inDiscover}
            />
          </Padder>
        </ScrollContainer>
      </Container>
    </>
  )
}

export default PublicItemSider
