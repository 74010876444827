import { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { Link, useLocation, useOutletContext } from 'react-router-dom'
import styled from 'styled-components'

import Loading from 'components/Loading'
import { BoardPreviewCard } from 'components/board-preview-card/BoardPreviewCard'
import Flex from 'components/common/Flex'
import { Text } from 'components/common/Text'
import { usePublicBoardsQuery } from 'generated/graphql'
import { trackDiscoveryPublicBoardClicked } from 'helpers/tracking/tracking'

import { UserInfo } from './components/UserInfo'

const StyledContainer = styled.div`
  padding: 0 16px 0;
`

export const DiscoverBoards = () => {
  const [, setRerender] = useState(false)
  const location = useLocation()
  const { wrapperRef, trackingSessionId = '' } = useOutletContext<{
    trackingSessionId?: string
    wrapperRef?: HTMLDivElement | null
  }>()

  const { data, loading, fetchMore } = usePublicBoardsQuery({
    variables: {
      first: 30,
    },
    fetchPolicy: 'cache-and-network',
    context: {
      batch: true,
    },
  })

  const boards = data?.publicBoards.boards || []
  const pageInfo = data?.publicBoards.pageInfo
  const nextCursor = pageInfo?.nextCursor

  const hasMore = Boolean(nextCursor) && boards.length > 0 && !loading
  const handleLoadMore = () => {
    fetchMore({
      variables: {
        cursor: nextCursor,
      },
    })
  }

  useEffect(() => {
    // There is a conclict with our Masonory grid and Inifnite scroll
    // which makes the inifinite scroll to NOT fetch more if the grid content is small enough to not trigger the "fetch more threshold"
    // forcing a rerender solves it. Don't ask me why
    setTimeout(() => {
      setRerender((prev) => !prev)
    }, 0)
  }, [boards.length])

  return (
    <StyledContainer>
      <Flex py={2}>
        <Text size="base" color="neutral.0" bold>
          Public boards
        </Text>
      </Flex>
      {wrapperRef && (
        <InfiniteScroll
          pageStart={0}
          threshold={1000}
          useWindow={false}
          getScrollParent={() => wrapperRef}
          loadMore={handleLoadMore}
          hasMore={!loading && hasMore}
          loader={
            <div className="loader" key={0}>
              <Loading fullscreen />
            </div>
          }
        >
          <Flex flexWrap="wrap" flexGap={3}>
            {boards.map((board) => {
              return (
                <Link
                  to={`${board.id}`}
                  state={{ prevPathname: location.pathname }}
                  onClick={() =>
                    trackDiscoveryPublicBoardClicked({ trackingSessionId })
                  }
                >
                  <BoardPreviewCard
                    title={board.title}
                    projectTitle={board.project.title}
                    count={board.itemCount}
                    thumbUrls={board.thumbUrls}
                    extra={<UserInfo user={board.createdBy} />}
                  />
                </Link>
              )
            })}
          </Flex>
        </InfiniteScroll>
      )}
    </StyledContainer>
  )
}
