import { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'

import Loading from 'components/Loading'
import Grid from 'components/grid/Grid'
import { GridColumnAdditioner } from 'components/grid/GridColumnAdditioner'
import { GridItemProps } from 'components/grid/griditem/GridItem'
import { useDiscoverNewItemsQuery } from 'generated/graphql'

import DiscoverGridItem from './DiscoverGridItem'

type DiscoveryNewestProps = {
  scrollElement: HTMLDivElement
  currentUserId: string
  trackingSessionId: string
}

export const DiscoveryNewest = (props: DiscoveryNewestProps) => {
  const [, setRerender] = useState(false)
  const { data, loading, fetchMore } = useDiscoverNewItemsQuery({
    variables: { first: 30, withCreatedBy: false },
    fetchPolicy: 'cache-and-network',
  })

  const pageInfo = data?.discoverNewItems.pageInfo
  const nextCursor = pageInfo?.nextCursor
  const items = data?.discoverNewItems.items ?? []
  const hasMore = Boolean(nextCursor) && items.length > 0 && !loading
  const handleLoadMore = () => {
    fetchMore({
      variables: {
        cursor: nextCursor,
      },
    })
  }

  useEffect(() => {
    // There is a conclict with our Masonory grid and Inifnite scroll
    // which makes the inifinite scroll to NOT fetch more if the grid content is small enough to not trigger the "fetch more threshold"
    // forcing a rerender solves it. Don't ask me why
    setTimeout(() => {
      setRerender((prev) => !prev)
    }, 0)
  }, [items.length])

  return (
    <>
      <InfiniteScroll
        pageStart={0}
        threshold={1000}
        useWindow={false}
        getScrollParent={() => props.scrollElement}
        loadMore={handleLoadMore}
        hasMore={!loading && hasMore}
        loader={
          <div className="loader" key={0}>
            <Loading fullscreen />
          </div>
        }
      >
        <GridColumnAdditioner
          parentWrapper={props.scrollElement}
          isHidden={items.length === 0}
        >
          {(gridColumnAdditionOption) => (
            <Grid
              scrollElement={props.scrollElement}
              isMultiSelectDisabled
              withFadeInAnimation
              gridColumnAdditionOption={gridColumnAdditionOption}
              // as we change queries and merge results we have to filter out duplicates manually
              gridItems={items}
              customGridItem={(gridItemProps: GridItemProps) => (
                <DiscoverGridItem
                  {...gridItemProps}
                  currentUserId={props.currentUserId}
                  trackingSessionId={props.trackingSessionId}
                />
              )}
            />
          )}
        </GridColumnAdditioner>
      </InfiniteScroll>
      {loading && !items.length && (
        <div className="loader" key={0}>
          <Loading fullscreen />
        </div>
      )}
    </>
  )
}
