import { KeyboardReturn } from '@styled-icons/material/KeyboardReturn'
import React from 'react'
import styled, { css } from 'styled-components'

import Emoji from 'components/common/emoji/Emoji'
import {
  Navigatable,
  useFocus,
} from 'components/keyboardnav/useKeyboardNavigation'

import { SelectBoardAndProjectSubmitButton } from './SelectBoardAndProjectSubmitButton'

interface MenuItemInnerProps {
  title?: string
  subtitle?: string
  iconCode?: string
  thumbUrl?: string
  onClick: () => void
}

const MenuItemInner: React.FC<MenuItemInnerProps> = ({
  title,
  subtitle,
  iconCode,
  thumbUrl,
  onClick,
}) => {
  const isFocused = useFocus()

  return (
    <StyledCommonListItemContainer isFocused={isFocused} onClick={onClick}>
      <StyledCommonListItemImage thumbUrl={thumbUrl}>
        {!thumbUrl && <Emoji size={15} code={iconCode} />}
      </StyledCommonListItemImage>
      <StyledCommonListItemInfo>
        <StyledCommonListItemInfoTitle>{title}</StyledCommonListItemInfoTitle>
        <StyledCommonListItemInfoSubTitle>
          {subtitle}
        </StyledCommonListItemInfoSubTitle>
      </StyledCommonListItemInfo>

      {isFocused && (
        <SelectBoardAndProjectSubmitButton>
          <KeyboardReturn />
        </SelectBoardAndProjectSubmitButton>
      )}
    </StyledCommonListItemContainer>
  )
}

interface SelectCommonListItemProps {
  id: string
  title: string
  subtitle?: string
  iconCode?: string
  thumbUrl?: string
  onAction: ({ id, title }: { id: string; title: string }) => void
}

const SelectCommonListItem: React.FC<SelectCommonListItemProps> = ({
  id,
  title,
  subtitle,
  iconCode,
  thumbUrl,
  onAction,
}) => {
  const commands = [
    {
      conditions: {
        key: 'Enter',
      },
      action: () => {
        onAction({ id, title })
      },
    },
  ]

  return (
    <Navigatable style={{ width: '100%' }} commands={commands}>
      <MenuItemInner
        onClick={() => onAction({ id, title })}
        title={title}
        subtitle={subtitle}
        thumbUrl={thumbUrl}
        iconCode={iconCode}
      />
    </Navigatable>
  )
}

export default SelectCommonListItem

const StyledCommonListItemContainer = styled.div<{ isFocused: boolean }>(
  ({ theme, isFocused }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: '6px',
    position: 'relative',
    cursor: 'pointer',
    borderRadius: theme.borderRadius.default,
    background: isFocused ? theme.colors.background[1] : 'inherit',
    minHeight: '40px',
    ':active': {
      background: theme.colors.background[4],
    },
  })
)

const StyledCommonListItemImage = styled.div<{ thumbUrl?: string }>`
  ${({ thumbUrl }) => css`
    width: 22px;
    height: 22px;
    margin-right: 12px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${thumbUrl ? `background-image: url(${thumbUrl})` : ''};
    background-size: cover;
    background-position: center;
  `}
`

const StyledCommonListItemInfo = styled.div`
  ${({ theme }) => css`
    text-align: left;
    font-weight: ${theme.fontWeight.normal};
    line-height: 1.2;
    flex: 1 1 0;
    overflow: hidden;
  `}
`

const StyledCommonListItemInfoTitle = styled.div(({ theme }) => ({
  color: theme.colors.text.neutral[0],
  fontSize: theme.fontSizes.sm,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}))

const StyledCommonListItemInfoSubTitle = styled(StyledCommonListItemInfoTitle)(
  ({ theme }) => ({
    color: theme.colors.text.discreet,
  })
)
