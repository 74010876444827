export const parseFreeText = (freeText: string) => {
  const folderStringMatch = 'folder:'
  const fileStringMatch = 'file:'
  const fileFormatStringMatch = 'format:'
  if (freeText.startsWith(folderStringMatch)) {
    return {
      folder: freeText.replace(folderStringMatch, ''),
      match: folderStringMatch,
    }
  }
  if (freeText.startsWith(fileStringMatch)) {
    return {
      file: freeText.replace(fileStringMatch, ''),
      match: fileStringMatch,
    }
  }

  if (freeText.startsWith(fileFormatStringMatch)) {
    return {
      format: freeText.replace(fileFormatStringMatch, ''),
      match: fileFormatStringMatch,
    }
  }

  return { match: '' }
}

type FreeTextSearchingState = 'all' | 'folder' | 'file' | 'format'

type GetParsedFreeTextResponse = [string, FreeTextSearchingState]

export const getParsedFreeText = (
  freeText: string
): GetParsedFreeTextResponse => {
  const { folder, file, format, match } = parseFreeText(freeText)

  let searchingState: FreeTextSearchingState = 'all'
  let newFreeText = freeText

  if (folder) {
    searchingState = 'folder'
    newFreeText = freeText.replace(match, '')
  }

  if (file) {
    searchingState = 'file'
    newFreeText = freeText.replace(match, '')
  }

  if (format) {
    searchingState = 'format'
    newFreeText = freeText.replace(match, '')
  }

  return [newFreeText || '*', searchingState]
}
