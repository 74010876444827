export const emojisAll = [
  {
    text: '😀',
    name: 'grinning face',
    tags: ['face', 'grin', 'grinning face'],
  },
  {
    text: '😃',
    name: 'grinning face with big eyes',
    tags: ['face', 'grinning face with big eyes', 'mouth', 'open', 'smile'],
  },
  {
    text: '😄',
    name: 'grinning face with smiling eyes',
    tags: [
      'eye',
      'face',
      'grinning face with smiling eyes',
      'mouth',
      'open',
      'smile',
    ],
  },
  {
    text: '😁',
    name: 'beaming face with smiling eyes',
    tags: ['beaming face with smiling eyes', 'eye', 'face', 'grin', 'smile'],
  },
  {
    text: '😆',
    name: 'grinning squinting face',
    tags: [
      'face',
      'grinning squinting face',
      'laugh',
      'mouth',
      'satisfied',
      'smile',
    ],
  },
  {
    text: '😅',
    name: 'grinning face with sweat',
    tags: [
      'cold',
      'face',
      'grinning face with sweat',
      'open',
      'smile',
      'sweat',
    ],
  },
  {
    text: '🤣',
    name: 'rolling on the floor laughing',
    tags: [
      'face',
      'floor',
      'laugh',
      'rolling',
      'rolling on the floor laughing',
    ],
  },
  {
    text: '😂',
    name: 'face with tears of joy',
    tags: ['face', 'face with tears of joy', 'joy', 'laugh', 'tear'],
  },
  {
    text: '🙂',
    name: 'slightly smiling face',
    tags: ['face', 'slightly smiling face', 'smile'],
  },
  {
    text: '🙃',
    name: 'upside-down face',
    tags: ['face', 'upside-down'],
  },
  {
    text: '😉',
    name: 'winking face',
    tags: ['face', 'wink', 'winking face'],
  },
  {
    text: '😊',
    name: 'smiling face with smiling eyes',
    tags: ['blush', 'eye', 'face', 'smile', 'smiling face with smiling eyes'],
  },
  {
    text: '😇',
    name: 'smiling face with halo',
    tags: [
      'angel',
      'face',
      'fantasy',
      'halo',
      'innocent',
      'smiling face with halo',
    ],
  },
  {
    text: '🥰',
    name: 'smiling face with hearts',
    tags: ['adore', 'crush', 'hearts', 'in love', 'smiling face with hearts'],
  },
  {
    text: '😍',
    name: 'smiling face with heart-eyes',
    tags: ['eye', 'face', 'love', 'smile', 'smiling face with heart-eyes'],
  },
  {
    text: '🤩',
    name: 'star-struck',
    tags: ['eyes', 'face', 'grinning', 'star', 'star-struck', 'starry-eyed'],
  },
  {
    text: '😘',
    name: 'face blowing a kiss',
    tags: ['face', 'face blowing a kiss', 'kiss'],
  },
  {
    text: '😗',
    name: 'kissing face',
    tags: ['face', 'kiss', 'kissing face'],
  },
  {
    text: '☺',
    name: 'smiling face',
    tags: ['face', 'outlined', 'relaxed', 'smile', 'smiling face'],
  },
  {
    text: '😚',
    name: 'kissing face with closed eyes',
    tags: ['closed', 'eye', 'face', 'kiss', 'kissing face with closed eyes'],
  },
  {
    text: '😙',
    name: 'kissing face with smiling eyes',
    tags: ['eye', 'face', 'kiss', 'kissing face with smiling eyes', 'smile'],
  },
  {
    text: '😋',
    name: 'face savoring food',
    tags: [
      'delicious',
      'face',
      'face savoring food',
      'savouring',
      'smile',
      'yum',
    ],
  },
  {
    text: '😛',
    name: 'face with tongue',
    tags: ['face', 'face with tongue', 'tongue'],
  },
  {
    text: '😜',
    name: 'winking face with tongue',
    tags: ['eye', 'face', 'joke', 'tongue', 'wink', 'winking face with tongue'],
  },
  {
    text: '🤪',
    name: 'zany face',
    tags: ['eye', 'goofy', 'large', 'small', 'zany face'],
  },
  {
    text: '😝',
    name: 'squinting face with tongue',
    tags: [
      'eye',
      'face',
      'horrible',
      'squinting face with tongue',
      'taste',
      'tongue',
    ],
  },
  {
    text: '🤑',
    name: 'money-mouth face',
    tags: ['face', 'money', 'money-mouth face', 'mouth'],
  },
  {
    text: '🤗',
    name: 'hugging face',
    tags: ['face', 'hug', 'hugging'],
  },
  {
    text: '🤭',
    name: 'face with hand over mouth',
    tags: [
      'face with hand over mouth',
      'whoops',
      'shock',
      'sudden realization',
      'surprise',
    ],
  },
  {
    text: '🤫',
    name: 'shushing face',
    tags: ['quiet', 'shush', 'shushing face'],
  },
  {
    text: '🤔',
    name: 'thinking face',
    tags: ['face', 'thinking'],
  },
  {
    text: '🤐',
    name: 'zipper-mouth face',
    tags: ['face', 'mouth', 'zipper', 'zipper-mouth face'],
  },
  {
    text: '🤨',
    name: 'face with raised eyebrow',
    tags: [
      'distrust',
      'face with raised eyebrow',
      'skeptic',
      'disapproval',
      'disbelief',
      'mild surprise',
      'scepticism',
    ],
  },
  {
    text: '😐',
    name: 'neutral face',
    tags: ['deadpan', 'face', 'meh', 'neutral'],
  },
  {
    text: '😑',
    name: 'expressionless face',
    tags: ['expressionless', 'face', 'inexpressive', 'meh', 'unexpressive'],
  },
  {
    text: '😶',
    name: 'face without mouth',
    tags: ['face', 'face without mouth', 'mouth', 'quiet', 'silent'],
  },
  {
    text: '😏',
    name: 'smirking face',
    tags: ['face', 'smirk', 'smirking face'],
  },
  {
    text: '😒',
    name: 'unamused face',
    tags: ['face', 'unamused', 'unhappy'],
  },
  {
    text: '🙄',
    name: 'face with rolling eyes',
    tags: ['eyeroll', 'eyes', 'face', 'face with rolling eyes', 'rolling'],
  },
  {
    text: '😬',
    name: 'grimacing face',
    tags: ['face', 'grimace', 'grimacing face'],
  },
  {
    text: '🤥',
    name: 'lying face',
    tags: ['face', 'lie', 'lying face', 'pinocchio'],
  },
  {
    text: '😌',
    name: 'relieved face',
    tags: ['face', 'relieved'],
  },
  {
    text: '😔',
    name: 'pensive face',
    tags: ['dejected', 'face', 'pensive'],
  },
  {
    text: '😪',
    name: 'sleepy face',
    tags: ['face', 'sleep', 'sleepy face'],
  },
  {
    text: '🤤',
    name: 'drooling face',
    tags: ['drooling', 'face'],
  },
  {
    text: '😴',
    name: 'sleeping face',
    tags: ['face', 'sleep', 'sleeping face', 'zzz'],
  },
  {
    text: '😷',
    name: 'face with medical mask',
    tags: ['cold', 'doctor', 'face', 'face with medical mask', 'mask', 'sick'],
  },
  {
    text: '🤒',
    name: 'face with thermometer',
    tags: ['face', 'face with thermometer', 'ill', 'sick', 'thermometer'],
  },
  {
    text: '🤕',
    name: 'face with head-bandage',
    tags: ['bandage', 'face', 'face with head-bandage', 'hurt', 'injury'],
  },
  {
    text: '🤢',
    name: 'nauseated face',
    tags: ['face', 'nauseated', 'vomit'],
  },
  {
    text: '🤮',
    name: 'face vomiting',
    tags: ['face vomiting', 'sick', 'vomit'],
  },
  {
    text: '🤧',
    name: 'sneezing face',
    tags: ['face', 'gesundheit', 'sneeze', 'sneezing face'],
  },
  {
    text: '🥵',
    name: 'hot face',
    tags: [
      'feverish',
      'heat stroke',
      'hot',
      'hot face',
      'red-faced',
      'sweating',
    ],
  },
  {
    text: '🥶',
    name: 'cold face',
    tags: [
      'blue-faced',
      'cold',
      'cold face',
      'freezing',
      'frostbite',
      'icicles',
    ],
  },
  {
    text: '🥴',
    name: 'woozy face',
    tags: [
      'dizzy',
      'intoxicated',
      'tipsy',
      'uneven eyes',
      'wavy mouth',
      'woozy face',
    ],
  },
  {
    text: '😵',
    name: 'dizzy face',
    tags: ['dizzy', 'face'],
  },
  {
    text: '🤯',
    name: 'exploding head',
    tags: ['exploding head', 'mind blown', 'shocked'],
  },
  {
    text: '🤠',
    name: 'cowboy hat face',
    tags: ['cowboy', 'cowgirl', 'face', 'hat'],
  },
  {
    text: '🥳',
    name: 'partying face',
    tags: ['celebration', 'hat', 'horn', 'party', 'partying face'],
  },
  {
    text: '😎',
    name: 'smiling face with sunglasses',
    tags: [
      'bright',
      'cool',
      'face',
      'smiling face with sunglasses',
      'sun',
      'sunglasses',
    ],
  },
  {
    text: '🤓',
    name: 'nerd face',
    tags: ['face', 'geek', 'nerd'],
  },
  {
    text: '🧐',
    name: 'face with monocle',
    tags: ['face with monocle', 'stuffy', 'wealthy'],
  },
  {
    text: '😕',
    name: 'confused face',
    tags: ['confused', 'face', 'meh'],
  },
  {
    text: '😟',
    name: 'worried face',
    tags: ['face', 'worried'],
  },
  {
    text: '🙁',
    name: 'slightly frowning face',
    tags: ['face', 'frown', 'slightly frowning face'],
  },
  {
    text: '☹',
    name: 'frowning face',
    tags: ['face', 'frown', 'frowning face'],
  },
  {
    text: '😮',
    name: 'face with open mouth',
    tags: ['face', 'face with open mouth', 'mouth', 'open', 'sympathy'],
  },
  {
    text: '😯',
    name: 'hushed face',
    tags: ['face', 'hushed', 'stunned', 'surprised'],
  },
  {
    text: '😲',
    name: 'astonished face',
    tags: ['astonished', 'face', 'shocked', 'totally'],
  },
  {
    text: '😳',
    name: 'flushed face',
    tags: ['dazed', 'face', 'flushed'],
  },
  {
    text: '🥺',
    name: 'pleading face',
    tags: ['begging', 'mercy', 'pleading face', 'puppy eyes'],
  },
  {
    text: '😦',
    name: 'frowning face with open mouth',
    tags: ['face', 'frown', 'frowning face with open mouth', 'mouth', 'open'],
  },
  {
    text: '😧',
    name: 'anguished face',
    tags: ['anguished', 'face'],
  },
  {
    text: '😨',
    name: 'fearful face',
    tags: ['face', 'fear', 'fearful', 'scared'],
  },
  {
    text: '😰',
    name: 'anxious face with sweat',
    tags: [
      'anxious face with sweat',
      'blue',
      'cold',
      'face',
      'rushed',
      'sweat',
    ],
  },
  {
    text: '😥',
    name: 'sad but relieved face',
    tags: ['disappointed', 'face', 'relieved', 'sad but relieved face', 'whew'],
  },
  {
    text: '😢',
    name: 'crying face',
    tags: ['cry', 'crying face', 'face', 'sad', 'tear'],
  },
  {
    text: '😭',
    name: 'loudly crying face',
    tags: ['cry', 'face', 'loudly crying face', 'sad', 'sob', 'tear'],
  },
  {
    text: '😱',
    name: 'face screaming in fear',
    tags: [
      'face',
      'face screaming in fear',
      'fear',
      'munch',
      'scared',
      'scream',
    ],
  },
  {
    text: '😖',
    name: 'confounded face',
    tags: ['confounded', 'face'],
  },
  {
    text: '😣',
    name: 'persevering face',
    tags: ['face', 'persevere', 'persevering face'],
  },
  {
    text: '😞',
    name: 'disappointed face',
    tags: ['disappointed', 'face'],
  },
  {
    text: '😓',
    name: 'downcast face with sweat',
    tags: ['cold', 'downcast face with sweat', 'face', 'sweat'],
  },
  {
    text: '😩',
    name: 'weary face',
    tags: ['face', 'tired', 'weary'],
  },
  {
    text: '😫',
    name: 'tired face',
    tags: ['face', 'tired'],
  },
  {
    text: '🥱',
    name: 'yawning face',
    tags: ['bored', 'tired', 'yawn', 'yawning face'],
  },
  {
    text: '😤',
    name: 'face with steam from nose',
    tags: ['face', 'face with steam from nose', 'triumph', 'won'],
  },
  {
    text: '😡',
    name: 'pouting face',
    tags: ['angry', 'face', 'mad', 'pouting', 'rage', 'red'],
  },
  {
    text: '😠',
    name: 'angry face',
    tags: ['angry', 'face', 'mad'],
  },
  {
    text: '🤬',
    name: 'face with symbols on mouth',
    tags: ['face with symbols on mouth', 'swearing', 'cursing'],
  },
  {
    text: '😈',
    name: 'smiling face with horns',
    tags: [
      'face',
      'fairy tale',
      'fantasy',
      'horns',
      'smile',
      'smiling face with horns',
    ],
  },
  {
    text: '👿',
    name: 'angry face with horns',
    tags: ['angry face with horns', 'demon', 'devil', 'face', 'fantasy', 'imp'],
  },
  {
    text: '💀',
    name: 'skull',
    tags: ['death', 'face', 'fairy tale', 'monster', 'skull'],
  },
  {
    text: '☠',
    name: 'skull and crossbones',
    tags: [
      'crossbones',
      'death',
      'face',
      'monster',
      'skull',
      'skull and crossbones',
    ],
  },
  {
    text: '💩',
    name: 'pile of poo',
    tags: ['dung', 'face', 'monster', 'pile of poo', 'poo', 'poop'],
  },
  {
    text: '🤡',
    name: 'clown face',
    tags: ['clown', 'face'],
  },
  {
    text: '👹',
    name: 'ogre',
    tags: [
      'creature',
      'face',
      'fairy tale',
      'fantasy',
      'monster',
      'ogre',
      'troll',
    ],
  },
  {
    text: '👺',
    name: 'goblin',
    tags: ['creature', 'face', 'fairy tale', 'fantasy', 'goblin', 'monster'],
  },
  {
    text: '👻',
    name: 'ghost',
    tags: ['creature', 'face', 'fairy tale', 'fantasy', 'ghost', 'monster'],
  },
  {
    text: '👽',
    name: 'alien',
    tags: ['alien', 'creature', 'extraterrestrial', 'face', 'fantasy', 'ufo'],
  },
  {
    text: '👾',
    name: 'alien monster',
    tags: ['alien', 'creature', 'extraterrestrial', 'face', 'monster', 'ufo'],
  },
  {
    text: '🤖',
    name: 'robot',
    tags: ['face', 'monster', 'robot'],
  },
  {
    text: '😺',
    name: 'grinning cat',
    tags: ['cat', 'face', 'grinning', 'mouth', 'open', 'smile'],
  },
  {
    text: '😸',
    name: 'grinning cat with smiling eyes',
    tags: [
      'cat',
      'eye',
      'face',
      'grin',
      'grinning cat with smiling eyes',
      'smile',
    ],
  },
  {
    text: '😹',
    name: 'cat with tears of joy',
    tags: ['cat', 'cat with tears of joy', 'face', 'joy', 'tear'],
  },
  {
    text: '😻',
    name: 'smiling cat with heart-eyes',
    tags: [
      'cat',
      'eye',
      'face',
      'heart',
      'love',
      'smile',
      'smiling cat with heart-eyes',
    ],
  },
  {
    text: '😼',
    name: 'cat with wry smile',
    tags: ['cat', 'cat with wry smile', 'face', 'ironic', 'smile', 'wry'],
  },
  {
    text: '😽',
    name: 'kissing cat',
    tags: ['cat', 'eye', 'face', 'kiss', 'kissing cat'],
  },
  {
    text: '🙀',
    name: 'weary cat',
    tags: ['cat', 'face', 'oh', 'surprised', 'weary'],
  },
  {
    text: '😿',
    name: 'crying cat',
    tags: ['cat', 'cry', 'crying cat', 'face', 'sad', 'tear'],
  },
  {
    text: '😾',
    name: 'pouting cat',
    tags: ['cat', 'face', 'pouting'],
  },
  {
    text: '🙈',
    name: 'see-no-evil monkey',
    tags: ['evil', 'face', 'forbidden', 'monkey', 'see', 'see-no-evil monkey'],
  },
  {
    text: '🙉',
    name: 'hear-no-evil monkey',
    tags: [
      'evil',
      'face',
      'forbidden',
      'hear',
      'hear-no-evil monkey',
      'monkey',
    ],
  },
  {
    text: '🙊',
    name: 'speak-no-evil monkey',
    tags: [
      'evil',
      'face',
      'forbidden',
      'monkey',
      'speak',
      'speak-no-evil monkey',
    ],
  },
  {
    text: '💋',
    name: 'kiss mark',
    tags: ['kiss', 'kiss mark', 'lips'],
  },
  {
    text: '💌',
    name: 'love letter',
    tags: ['heart', 'letter', 'love', 'mail'],
  },
  {
    text: '💘',
    name: 'heart with arrow',
    tags: ['arrow', 'cupid', 'heart with arrow'],
  },
  {
    text: '💝',
    name: 'heart with ribbon',
    tags: ['heart with ribbon', 'ribbon', 'valentine'],
  },
  {
    text: '💖',
    name: 'sparkling heart',
    tags: ['excited', 'sparkle', 'sparkling heart'],
  },
  {
    text: '💗',
    name: 'growing heart',
    tags: ['excited', 'growing', 'growing heart', 'nervous', 'pulse'],
  },
  {
    text: '💓',
    name: 'beating heart',
    tags: ['beating', 'beating heart', 'heartbeat', 'pulsating'],
  },
  {
    text: '💞',
    name: 'revolving hearts',
    tags: ['revolving', 'revolving hearts'],
  },
  {
    text: '💕',
    name: 'two hearts',
    tags: ['love', 'two hearts'],
  },
  {
    text: '💟',
    name: 'heart decoration',
    tags: ['heart', 'heart decoration'],
  },
  {
    text: '❣',
    name: 'heart exclamation',
    tags: ['exclamation', 'heart exclamation', 'mark', 'punctuation'],
  },
  {
    text: '💔',
    name: 'broken heart',
    tags: ['break', 'broken', 'broken heart'],
  },
  {
    text: '❤',
    name: 'red heart',
    tags: ['heart', 'red heart'],
  },
  {
    text: '🧡',
    name: 'orange heart',
    tags: ['orange', 'orange heart'],
  },
  {
    text: '💛',
    name: 'yellow heart',
    tags: ['yellow', 'yellow heart'],
  },
  {
    text: '💚',
    name: 'green heart',
    tags: ['green', 'green heart'],
  },
  {
    text: '💙',
    name: 'blue heart',
    tags: ['blue', 'blue heart'],
  },
  {
    text: '💜',
    name: 'purple heart',
    tags: ['purple', 'purple heart'],
  },
  {
    text: '🤎',
    name: 'brown heart',
    tags: ['brown', 'heart'],
  },
  {
    text: '🖤',
    name: 'black heart',
    tags: ['black', 'black heart', 'evil', 'wicked'],
  },
  {
    text: '🤍',
    name: 'white heart',
    tags: ['heart', 'white'],
  },
  {
    text: '💯',
    name: 'hundred points',
    tags: ['100', 'full', 'hundred', 'hundred points', 'score'],
  },
  {
    text: '💢',
    name: 'anger symbol',
    tags: ['anger symbol', 'angry', 'comic', 'mad'],
  },
  {
    text: '💥',
    name: 'collision',
    tags: ['boom', 'collision', 'comic'],
  },
  {
    text: '💫',
    name: 'dizzy',
    tags: ['comic', 'dizzy', 'star'],
  },
  {
    text: '💦',
    name: 'sweat droplets',
    tags: ['comic', 'splashing', 'sweat', 'sweat droplets'],
  },
  {
    text: '💨',
    name: 'dashing away',
    tags: ['comic', 'dash', 'dashing away', 'running'],
  },
  {
    text: '🕳',
    name: 'hole',
    tags: ['hole'],
  },
  {
    text: '💣',
    name: 'bomb',
    tags: ['bomb', 'comic'],
  },
  {
    text: '💬',
    name: 'speech balloon',
    tags: ['balloon', 'bubble', 'comic', 'dialog', 'speech'],
  },
  {
    text: '👁️‍🗨️',
    name: 'eye in speech bubble',
    tags: ['eye', 'eye in speech bubble', 'speech bubble', 'witness'],
  },
  {
    text: '🗨',
    name: 'left speech bubble',
    tags: ['dialog', 'left speech bubble', 'speech'],
  },
  {
    text: '🗯',
    name: 'right anger bubble',
    tags: ['angry', 'balloon', 'bubble', 'mad', 'right anger bubble'],
  },
  {
    text: '💭',
    name: 'thought balloon',
    tags: ['balloon', 'bubble', 'comic', 'thought'],
  },
  {
    text: '💤',
    name: 'zzz',
    tags: ['comic', 'sleep', 'zzz'],
  },
  {
    text: '👋',
    name: 'waving hand',
    tags: ['hand', 'wave', 'waving'],
  },
  {
    text: '🤚',
    name: 'raised back of hand',
    tags: ['backhand', 'raised', 'raised back of hand'],
  },
  {
    text: '🖐',
    name: 'hand with fingers splayed',
    tags: ['finger', 'hand', 'hand with fingers splayed', 'splayed'],
  },
  {
    text: '✋',
    name: 'raised hand',
    tags: ['hand', 'raised hand'],
  },
  {
    text: '🖖',
    name: 'vulcan salute',
    tags: ['finger', 'hand', 'spock', 'vulcan', 'vulcan salute'],
  },
  {
    text: '👌',
    name: 'OK hand',
    tags: ['hand', 'ok'],
  },
  {
    text: '🤏',
    name: 'pinching hand',
    tags: ['pinching hand', 'small amount'],
  },
  {
    text: '✌',
    name: 'victory hand',
    tags: ['hand', 'v', 'victory'],
  },
  {
    text: '🤞',
    name: 'crossed fingers',
    tags: ['cross', 'crossed fingers', 'finger', 'hand', 'luck'],
  },
  {
    text: '🤟',
    name: 'love-you gesture',
    tags: ['hand', 'ily', 'love-you gesture'],
  },
  {
    text: '🤘',
    name: 'sign of the horns',
    tags: ['finger', 'hand', 'horns', 'rock-on', 'sign of the horns'],
  },
  {
    text: '🤙',
    name: 'call me hand',
    tags: ['call', 'call me hand', 'hand'],
  },
  {
    text: '👈',
    name: 'backhand index pointing left',
    tags: [
      'backhand',
      'backhand index pointing left',
      'finger',
      'hand',
      'index',
      'point',
    ],
  },
  {
    text: '👉',
    name: 'backhand index pointing right',
    tags: [
      'backhand',
      'backhand index pointing right',
      'finger',
      'hand',
      'index',
      'point',
    ],
  },
  {
    text: '👆',
    name: 'backhand index pointing up',
    tags: [
      'backhand',
      'backhand index pointing up',
      'finger',
      'hand',
      'point',
      'up',
    ],
  },
  {
    text: '🖕',
    name: 'middle finger',
    tags: ['finger', 'hand', 'middle finger'],
  },
  {
    text: '👇',
    name: 'backhand index pointing down',
    tags: [
      'backhand',
      'backhand index pointing down',
      'down',
      'finger',
      'hand',
      'point',
    ],
  },
  {
    text: '☝',
    name: 'index pointing up',
    tags: ['finger', 'hand', 'index', 'index pointing up', 'point', 'up'],
  },
  {
    text: '👍',
    name: 'thumbs up',
    tags: ['+1', 'hand', 'thumb', 'thumbs up', 'up'],
  },
  {
    text: '👎',
    name: 'thumbs down',
    tags: ['-1', 'down', 'hand', 'thumb', 'thumbs down'],
  },
  {
    text: '✊',
    name: 'raised fist',
    tags: ['clenched', 'fist', 'hand', 'punch', 'raised fist'],
  },
  {
    text: '👊',
    name: 'oncoming fist',
    tags: ['clenched', 'fist', 'hand', 'oncoming fist', 'punch'],
  },
  {
    text: '🤛',
    name: 'left-facing fist',
    tags: ['fist', 'left-facing fist', 'leftwards'],
  },
  {
    text: '🤜',
    name: 'right-facing fist',
    tags: ['fist', 'right-facing fist', 'rightwards'],
  },
  {
    text: '👏',
    name: 'clapping hands',
    tags: ['clap', 'clapping hands', 'hand'],
  },
  {
    text: '🙌',
    name: 'raising hands',
    tags: [
      'celebration',
      'gesture',
      'hand',
      'hooray',
      'raised',
      'raising hands',
    ],
  },
  {
    text: '👐',
    name: 'open hands',
    tags: ['hand', 'open', 'open hands'],
  },
  {
    text: '🤲',
    name: 'palms up together',
    tags: ['palms up together', 'prayer', 'cupped hands'],
  },
  {
    text: '🤝',
    name: 'handshake',
    tags: ['agreement', 'hand', 'handshake', 'meeting', 'shake'],
  },
  {
    text: '🙏',
    name: 'folded hands',
    tags: ['ask', 'folded hands', 'hand', 'please', 'pray', 'thanks'],
  },
  {
    text: '✍',
    name: 'writing hand',
    tags: ['hand', 'write', 'writing hand'],
  },
  {
    text: '💅',
    name: 'nail polish',
    tags: ['care', 'cosmetics', 'manicure', 'nail', 'polish'],
  },
  {
    text: '🤳',
    name: 'selfie',
    tags: ['camera', 'phone', 'selfie'],
  },
  {
    text: '💪',
    name: 'flexed biceps',
    tags: ['biceps', 'comic', 'flex', 'flexed biceps', 'muscle'],
  },
  {
    text: '🦾',
    name: 'mechanical arm',
    tags: ['accessibility', 'mechanical arm', 'prosthetic'],
  },
  {
    text: '🦿',
    name: 'mechanical leg',
    tags: ['accessibility', 'mechanical leg', 'prosthetic'],
  },
  {
    text: '🦵',
    name: 'leg',
    tags: ['kick', 'leg', 'limb'],
  },
  {
    text: '🦶',
    name: 'foot',
    tags: ['foot', 'kick', 'stomp'],
  },
  {
    text: '👂',
    name: 'ear',
    tags: ['body', 'ear'],
  },
  {
    text: '🦻',
    name: 'ear with hearing aid',
    tags: ['accessibility', 'ear with hearing aid', 'hard of hearing'],
  },
  {
    text: '👃',
    name: 'nose',
    tags: ['body', 'nose'],
  },
  {
    text: '🧠',
    name: 'brain',
    tags: ['brain', 'intelligent'],
  },
  {
    text: '🦷',
    name: 'tooth',
    tags: ['dentist', 'tooth'],
  },
  {
    text: '🦴',
    name: 'bone',
    tags: ['bone', 'skeleton'],
  },
  {
    text: '👀',
    name: 'eyes',
    tags: ['eye', 'eyes', 'face'],
  },
  {
    text: '👁',
    name: 'eye',
    tags: ['body', 'eye'],
  },
  {
    text: '👅',
    name: 'tongue',
    tags: ['body', 'tongue'],
  },
  {
    text: '👄',
    name: 'mouth',
    tags: ['lips', 'mouth'],
  },
  {
    text: '👶',
    name: 'baby',
    tags: ['baby', 'young'],
  },
  {
    text: '🧒',
    name: 'child',
    tags: ['child', 'gender-neutral', 'unspecified gender', 'young'],
  },
  {
    text: '👦',
    name: 'boy',
    tags: ['boy', 'young'],
  },
  {
    text: '👧',
    name: 'girl',
    tags: ['girl', 'virgo', 'young', 'zodiac'],
  },
  {
    text: '🧑',
    name: 'person',
    tags: ['adult', 'gender-neutral', 'person', 'unspecified gender'],
  },
  {
    text: '👱',
    name: 'person: blond hair',
    tags: ['blond', 'blond-haired person', 'hair', 'person: blond hair'],
  },
  {
    text: '👨',
    name: 'man',
    tags: ['adult', 'man'],
  },
  {
    text: '🧔',
    name: 'man: beard',
    tags: ['beard', 'man', 'man: beard', 'person', 'bewhiskered'],
  },
  {
    text: '👨‍🦰',
    name: 'man: red hair',
    tags: ['adult', 'man', 'red hair'],
  },
  {
    text: '👨‍🦱',
    name: 'man: curly hair',
    tags: ['adult', 'curly hair', 'man'],
  },
  {
    text: '👨‍🦳',
    name: 'man: white hair',
    tags: ['adult', 'man', 'white hair'],
  },
  {
    text: '👨‍🦲',
    name: 'man: bald',
    tags: ['adult', 'bald', 'man'],
  },
  {
    text: '👩',
    name: 'woman',
    tags: ['adult', 'woman'],
  },
  {
    text: '👩‍🦰',
    name: 'woman: red hair',
    tags: ['adult', 'red hair', 'woman'],
  },
  {
    text: '🧑‍🦰',
    name: 'person: red hair',
    tags: [
      'adult',
      'gender-neutral',
      'person',
      'red hair',
      'unspecified gender',
    ],
  },
  {
    text: '👩‍🦱',
    name: 'woman: curly hair',
    tags: ['adult', 'curly hair', 'woman'],
  },
  {
    text: '🧑‍🦱',
    name: 'person: curly hair',
    tags: [
      'adult',
      'curly hair',
      'gender-neutral',
      'person',
      'unspecified gender',
    ],
  },
  {
    text: '👩‍🦳',
    name: 'woman: white hair',
    tags: ['adult', 'white hair', 'woman'],
  },
  {
    text: '🧑‍🦳',
    name: 'person: white hair',
    tags: [
      'adult',
      'gender-neutral',
      'person',
      'unspecified gender',
      'white hair',
    ],
  },
  {
    text: '👩‍🦲',
    name: 'woman: bald',
    tags: ['adult', 'bald', 'woman'],
  },
  {
    text: '🧑‍🦲',
    name: 'person: bald',
    tags: ['adult', 'bald', 'gender-neutral', 'person', 'unspecified gender'],
  },
  {
    text: '👱‍♀️',
    name: 'woman: blond hair',
    tags: [
      'blond-haired woman',
      'blonde',
      'hair',
      'woman',
      'woman: blond hair',
    ],
  },
  {
    text: '👱‍♂️',
    name: 'man: blond hair',
    tags: ['blond', 'blond-haired man', 'hair', 'man', 'man: blond hair'],
  },
  {
    text: '🧓',
    name: 'older person',
    tags: [
      'adult',
      'gender-neutral',
      'old',
      'older person',
      'unspecified gender',
    ],
  },
  {
    text: '👴',
    name: 'old man',
    tags: ['adult', 'man', 'old'],
  },
  {
    text: '👵',
    name: 'old woman',
    tags: ['adult', 'old', 'woman'],
  },
  {
    text: '🙍',
    name: 'person frowning',
    tags: ['frown', 'gesture', 'person frowning'],
  },
  {
    text: '🙍‍♂️',
    name: 'man frowning',
    tags: ['frowning', 'gesture', 'man'],
  },
  {
    text: '🙍‍♀️',
    name: 'woman frowning',
    tags: ['frowning', 'gesture', 'woman'],
  },
  {
    text: '🙎',
    name: 'person pouting',
    tags: ['gesture', 'person pouting', 'pouting'],
  },
  {
    text: '🙎‍♂️',
    name: 'man pouting',
    tags: ['gesture', 'man', 'pouting'],
  },
  {
    text: '🙎‍♀️',
    name: 'woman pouting',
    tags: ['gesture', 'pouting', 'woman'],
  },
  {
    text: '🙅',
    name: 'person gesturing NO',
    tags: ['forbidden', 'gesture', 'hand', 'person gesturing no', 'prohibited'],
  },
  {
    text: '🙅‍♂️',
    name: 'man gesturing NO',
    tags: [
      'forbidden',
      'gesture',
      'hand',
      'man',
      'man gesturing no',
      'prohibited',
    ],
  },
  {
    text: '🙅‍♀️',
    name: 'woman gesturing NO',
    tags: [
      'forbidden',
      'gesture',
      'hand',
      'prohibited',
      'woman',
      'woman gesturing no',
    ],
  },
  {
    text: '🙆',
    name: 'person gesturing OK',
    tags: ['gesture', 'hand', 'ok', 'person gesturing ok'],
  },
  {
    text: '🙆‍♂️',
    name: 'man gesturing OK',
    tags: ['gesture', 'hand', 'man', 'man gesturing ok', 'ok'],
  },
  {
    text: '🙆‍♀️',
    name: 'woman gesturing OK',
    tags: ['gesture', 'hand', 'ok', 'woman', 'woman gesturing ok'],
  },
  {
    text: '💁',
    name: 'person tipping hand',
    tags: [
      'hand',
      'help',
      'information',
      'person tipping hand',
      'sassy',
      'tipping',
    ],
  },
  {
    text: '💁‍♂️',
    name: 'man tipping hand',
    tags: ['man', 'man tipping hand', 'sassy', 'tipping hand'],
  },
  {
    text: '💁‍♀️',
    name: 'woman tipping hand',
    tags: ['sassy', 'tipping hand', 'woman', 'woman tipping hand'],
  },
  {
    text: '🙋',
    name: 'person raising hand',
    tags: ['gesture', 'hand', 'happy', 'person raising hand', 'raised'],
  },
  {
    text: '🙋‍♂️',
    name: 'man raising hand',
    tags: ['gesture', 'man', 'man raising hand', 'raising hand'],
  },
  {
    text: '🙋‍♀️',
    name: 'woman raising hand',
    tags: ['gesture', 'raising hand', 'woman', 'woman raising hand'],
  },
  {
    text: '🧏',
    name: 'deaf person',
    tags: ['accessibility', 'deaf', 'deaf person', 'ear', 'hear'],
  },
  {
    text: '🧏‍♂️',
    name: 'deaf man',
    tags: ['deaf', 'man'],
  },
  {
    text: '🧏‍♀️',
    name: 'deaf woman',
    tags: ['deaf', 'woman'],
  },
  {
    text: '🙇',
    name: 'person bowing',
    tags: ['apology', 'bow', 'gesture', 'person bowing', 'sorry'],
  },
  {
    text: '🙇‍♂️',
    name: 'man bowing',
    tags: ['apology', 'bowing', 'favor', 'gesture', 'man', 'sorry'],
  },
  {
    text: '🙇‍♀️',
    name: 'woman bowing',
    tags: ['apology', 'bowing', 'favor', 'gesture', 'sorry', 'woman'],
  },
  {
    text: '🤦',
    name: 'person facepalming',
    tags: ['disbelief', 'exasperation', 'face', 'palm', 'person facepalming'],
  },
  {
    text: '🤦‍♂️',
    name: 'man facepalming',
    tags: ['disbelief', 'exasperation', 'facepalm', 'man', 'man facepalming'],
  },
  {
    text: '🤦‍♀️',
    name: 'woman facepalming',
    tags: [
      'disbelief',
      'exasperation',
      'facepalm',
      'woman',
      'woman facepalming',
    ],
  },
  {
    text: '🤷',
    name: 'person shrugging',
    tags: ['doubt', 'ignorance', 'indifference', 'person shrugging', 'shrug'],
  },
  {
    text: '🤷‍♂️',
    name: 'man shrugging',
    tags: [
      'doubt',
      'ignorance',
      'indifference',
      'man',
      'man shrugging',
      'shrug',
    ],
  },
  {
    text: '🤷‍♀️',
    name: 'woman shrugging',
    tags: [
      'doubt',
      'ignorance',
      'indifference',
      'shrug',
      'woman',
      'woman shrugging',
    ],
  },
  {
    text: '🧑‍⚕️',
    name: 'health worker',
    tags: ['doctor', 'health worker', 'healthcare', 'nurse', 'therapist'],
  },
  {
    text: '👨‍⚕️',
    name: 'man health worker',
    tags: [
      'doctor',
      'healthcare',
      'man',
      'man health worker',
      'nurse',
      'therapist',
    ],
  },
  {
    text: '👩‍⚕️',
    name: 'woman health worker',
    tags: [
      'doctor',
      'healthcare',
      'nurse',
      'therapist',
      'woman',
      'woman health worker',
    ],
  },
  {
    text: '🧑‍🎓',
    name: 'student',
    tags: ['graduate', 'student'],
  },
  {
    text: '👨‍🎓',
    name: 'man student',
    tags: ['graduate', 'man', 'student'],
  },
  {
    text: '👩‍🎓',
    name: 'woman student',
    tags: ['graduate', 'student', 'woman'],
  },
  {
    text: '🧑‍🏫',
    name: 'teacher',
    tags: ['instructor', 'professor', 'teacher'],
  },
  {
    text: '👨‍🏫',
    name: 'man teacher',
    tags: ['instructor', 'man', 'professor', 'teacher'],
  },
  {
    text: '👩‍🏫',
    name: 'woman teacher',
    tags: ['instructor', 'professor', 'teacher', 'woman'],
  },
  {
    text: '🧑‍⚖️',
    name: 'judge',
    tags: ['judge', 'scales'],
  },
  {
    text: '👨‍⚖️',
    name: 'man judge',
    tags: ['justice', 'man', 'man judge', 'scales'],
  },
  {
    text: '👩‍⚖️',
    name: 'woman judge',
    tags: ['judge', 'scales', 'woman'],
  },
  {
    text: '🧑‍🌾',
    name: 'farmer',
    tags: ['farmer', 'gardener', 'rancher'],
  },
  {
    text: '👨‍🌾',
    name: 'man farmer',
    tags: ['farmer', 'gardener', 'man', 'rancher'],
  },
  {
    text: '👩‍🌾',
    name: 'woman farmer',
    tags: ['farmer', 'gardener', 'rancher', 'woman'],
  },
  {
    text: '🧑‍🍳',
    name: 'cook',
    tags: ['chef', 'cook'],
  },
  {
    text: '👨‍🍳',
    name: 'man cook',
    tags: ['chef', 'cook', 'man'],
  },
  {
    text: '👩‍🍳',
    name: 'woman cook',
    tags: ['chef', 'cook', 'woman'],
  },
  {
    text: '🧑‍🔧',
    name: 'mechanic',
    tags: ['electrician', 'mechanic', 'plumber', 'tradesperson'],
  },
  {
    text: '👨‍🔧',
    name: 'man mechanic',
    tags: ['electrician', 'man', 'mechanic', 'plumber', 'tradesperson'],
  },
  {
    text: '👩‍🔧',
    name: 'woman mechanic',
    tags: ['electrician', 'mechanic', 'plumber', 'tradesperson', 'woman'],
  },
  {
    text: '🧑‍🏭',
    name: 'factory worker',
    tags: ['assembly', 'factory', 'industrial', 'worker'],
  },
  {
    text: '👨‍🏭',
    name: 'man factory worker',
    tags: ['assembly', 'factory', 'industrial', 'man', 'worker'],
  },
  {
    text: '👩‍🏭',
    name: 'woman factory worker',
    tags: ['assembly', 'factory', 'industrial', 'woman', 'worker'],
  },
  {
    text: '🧑‍💼',
    name: 'office worker',
    tags: ['architect', 'business', 'manager', 'office worker', 'white-collar'],
  },
  {
    text: '👨‍💼',
    name: 'man office worker',
    tags: [
      'architect',
      'business',
      'man',
      'man office worker',
      'manager',
      'white-collar',
    ],
  },
  {
    text: '👩‍💼',
    name: 'woman office worker',
    tags: [
      'architect',
      'business',
      'manager',
      'white-collar',
      'woman',
      'woman office worker',
    ],
  },
  {
    text: '🧑‍🔬',
    name: 'scientist',
    tags: ['biologist', 'chemist', 'engineer', 'physicist', 'scientist'],
  },
  {
    text: '👨‍🔬',
    name: 'man scientist',
    tags: ['biologist', 'chemist', 'engineer', 'man', 'physicist', 'scientist'],
  },
  {
    text: '👩‍🔬',
    name: 'woman scientist',
    tags: [
      'biologist',
      'chemist',
      'engineer',
      'physicist',
      'scientist',
      'woman',
    ],
  },
  {
    text: '🧑‍💻',
    name: 'technologist',
    tags: ['coder', 'developer', 'inventor', 'software', 'technologist'],
  },
  {
    text: '👨‍💻',
    name: 'man technologist',
    tags: ['coder', 'developer', 'inventor', 'man', 'software', 'technologist'],
  },
  {
    text: '👩‍💻',
    name: 'woman technologist',
    tags: [
      'coder',
      'developer',
      'inventor',
      'software',
      'technologist',
      'woman',
    ],
  },
  {
    text: '🧑‍🎤',
    name: 'singer',
    tags: ['actor', 'entertainer', 'rock', 'singer', 'star'],
  },
  {
    text: '👨‍🎤',
    name: 'man singer',
    tags: ['actor', 'entertainer', 'man', 'rock', 'singer', 'star'],
  },
  {
    text: '👩‍🎤',
    name: 'woman singer',
    tags: ['actor', 'entertainer', 'rock', 'singer', 'star', 'woman'],
  },
  {
    text: '🧑‍🎨',
    name: 'artist',
    tags: ['artist', 'palette'],
  },
  {
    text: '👨‍🎨',
    name: 'man artist',
    tags: ['artist', 'man', 'palette'],
  },
  {
    text: '👩‍🎨',
    name: 'woman artist',
    tags: ['artist', 'palette', 'woman'],
  },
  {
    text: '🧑‍✈️',
    name: 'pilot',
    tags: ['pilot', 'plane'],
  },
  {
    text: '👨‍✈️',
    name: 'man pilot',
    tags: ['man', 'pilot', 'plane'],
  },
  {
    text: '👩‍✈️',
    name: 'woman pilot',
    tags: ['pilot', 'plane', 'woman'],
  },
  {
    text: '🧑‍🚀',
    name: 'astronaut',
    tags: ['astronaut', 'rocket'],
  },
  {
    text: '👨‍🚀',
    name: 'man astronaut',
    tags: ['astronaut', 'man', 'rocket'],
  },
  {
    text: '👩‍🚀',
    name: 'woman astronaut',
    tags: ['astronaut', 'rocket', 'woman'],
  },
  {
    text: '🧑‍🚒',
    name: 'firefighter',
    tags: ['firefighter', 'firetruck'],
  },
  {
    text: '👨‍🚒',
    name: 'man firefighter',
    tags: ['firefighter', 'firetruck', 'man'],
  },
  {
    text: '👩‍🚒',
    name: 'woman firefighter',
    tags: ['firefighter', 'firetruck', 'woman'],
  },
  {
    text: '👮',
    name: 'police officer',
    tags: ['cop', 'officer', 'police'],
  },
  {
    text: '👮‍♂️',
    name: 'man police officer',
    tags: ['cop', 'man', 'officer', 'police'],
  },
  {
    text: '👮‍♀️',
    name: 'woman police officer',
    tags: ['cop', 'officer', 'police', 'woman'],
  },
  {
    text: '🕵',
    name: 'detective',
    tags: ['detective', 'sleuth', 'spy'],
  },
  {
    text: '🕵️‍♂️',
    name: 'man detective',
    tags: ['detective', 'man', 'sleuth', 'spy'],
  },
  {
    text: '🕵️‍♀️',
    name: 'woman detective',
    tags: ['detective', 'sleuth', 'spy', 'woman'],
  },
  {
    text: '💂',
    name: 'guard',
    tags: ['guard'],
  },
  {
    text: '💂‍♂️',
    name: 'man guard',
    tags: ['guard', 'man'],
  },
  {
    text: '💂‍♀️',
    name: 'woman guard',
    tags: ['guard', 'woman'],
  },
  {
    text: '👷',
    name: 'construction worker',
    tags: ['construction', 'hat', 'worker'],
  },
  {
    text: '👷‍♂️',
    name: 'man construction worker',
    tags: ['construction', 'man', 'worker'],
  },
  {
    text: '👷‍♀️',
    name: 'woman construction worker',
    tags: ['construction', 'woman', 'worker'],
  },
  {
    text: '🤴',
    name: 'prince',
    tags: ['prince'],
  },
  {
    text: '👸',
    name: 'princess',
    tags: ['fairy tale', 'fantasy', 'princess'],
  },
  {
    text: '👳',
    name: 'person wearing turban',
    tags: ['person wearing turban', 'turban'],
  },
  {
    text: '👳‍♂️',
    name: 'man wearing turban',
    tags: ['man', 'man wearing turban', 'turban'],
  },
  {
    text: '👳‍♀️',
    name: 'woman wearing turban',
    tags: ['turban', 'woman', 'woman wearing turban'],
  },
  {
    text: '👲',
    name: 'man with skullcap',
    tags: ['cap', 'gua pi mao', 'hat', 'man', 'man with skullcap', 'skullcap'],
  },
  {
    text: '🧕',
    name: 'woman with headscarf',
    tags: [
      'headscarf',
      'hijab',
      'mantilla',
      'tichel',
      'woman with headscarf',
      'bandana',
      'head kerchief',
    ],
  },
  {
    text: '🤵',
    name: 'man in tuxedo',
    tags: ['groom', 'man', 'man in tuxedo', 'tuxedo'],
  },
  {
    text: '👰',
    name: 'bride with veil',
    tags: ['bride', 'bride with veil', 'veil', 'wedding'],
  },
  {
    text: '🤰',
    name: 'pregnant woman',
    tags: ['pregnant', 'woman'],
  },
  {
    text: '🤱',
    name: 'breast-feeding',
    tags: ['baby', 'breast', 'breast-feeding', 'nursing'],
  },
  {
    text: '👼',
    name: 'baby angel',
    tags: ['angel', 'baby', 'face', 'fairy tale', 'fantasy'],
  },
  {
    text: '🎅',
    name: 'Santa Claus',
    tags: [
      'celebration',
      'christmas',
      'claus',
      'father',
      'santa',
      'santa claus',
    ],
  },
  {
    text: '🤶',
    name: 'Mrs. Claus',
    tags: ['celebration', 'christmas', 'claus', 'mother', 'mrs.', 'mrs. claus'],
  },
  {
    text: '🦸',
    name: 'superhero',
    tags: ['good', 'hero', 'heroine', 'superhero', 'superpower'],
  },
  {
    text: '🦸‍♂️',
    name: 'man superhero',
    tags: ['good', 'hero', 'man', 'man superhero', 'superpower'],
  },
  {
    text: '🦸‍♀️',
    name: 'woman superhero',
    tags: ['good', 'hero', 'heroine', 'superpower', 'woman', 'woman superhero'],
  },
  {
    text: '🦹',
    name: 'supervillain',
    tags: ['criminal', 'evil', 'superpower', 'supervillain', 'villain'],
  },
  {
    text: '🦹‍♂️',
    name: 'man supervillain',
    tags: [
      'criminal',
      'evil',
      'man',
      'man supervillain',
      'superpower',
      'villain',
    ],
  },
  {
    text: '🦹‍♀️',
    name: 'woman supervillain',
    tags: [
      'criminal',
      'evil',
      'superpower',
      'villain',
      'woman',
      'woman supervillain',
    ],
  },
  {
    text: '🧙',
    name: 'mage',
    tags: ['mage', 'sorcerer', 'sorceress', 'witch', 'wizard'],
  },
  {
    text: '🧙‍♂️',
    name: 'man mage',
    tags: ['man mage', 'sorcerer', 'wizard'],
  },
  {
    text: '🧙‍♀️',
    name: 'woman mage',
    tags: ['sorceress', 'witch', 'woman mage'],
  },
  {
    text: '🧚',
    name: 'fairy',
    tags: ['fairy', 'oberon', 'puck', 'titania'],
  },
  {
    text: '🧚‍♂️',
    name: 'man fairy',
    tags: ['man fairy', 'oberon', 'puck'],
  },
  {
    text: '🧚‍♀️',
    name: 'woman fairy',
    tags: ['titania', 'woman fairy'],
  },
  {
    text: '🧛',
    name: 'vampire',
    tags: ['dracula', 'undead', 'vampire'],
  },
  {
    text: '🧛‍♂️',
    name: 'man vampire',
    tags: ['dracula', 'man vampire', 'undead'],
  },
  {
    text: '🧛‍♀️',
    name: 'woman vampire',
    tags: ['undead', 'woman vampire'],
  },
  {
    text: '🧜',
    name: 'merperson',
    tags: ['mermaid', 'merman', 'merperson', 'merwoman'],
  },
  {
    text: '🧜‍♂️',
    name: 'merman',
    tags: ['merman', 'triton'],
  },
  {
    text: '🧜‍♀️',
    name: 'mermaid',
    tags: ['mermaid', 'merwoman'],
  },
  {
    text: '🧝',
    name: 'elf',
    tags: ['elf', 'magical', 'lotr style'],
  },
  {
    text: '🧝‍♂️',
    name: 'man elf',
    tags: ['magical', 'man elf'],
  },
  {
    text: '🧝‍♀️',
    name: 'woman elf',
    tags: ['magical', 'woman elf'],
  },
  {
    text: '🧞',
    name: 'genie',
    tags: ['djinn', 'genie', 'non-human color'],
  },
  {
    text: '🧞‍♂️',
    name: 'man genie',
    tags: ['djinn', 'man genie'],
  },
  {
    text: '🧞‍♀️',
    name: 'woman genie',
    tags: ['djinn', 'woman genie'],
  },
  {
    text: '🧟',
    name: 'zombie',
    tags: ['undead', 'walking dead', 'zombie', 'non-human color'],
  },
  {
    text: '🧟‍♂️',
    name: 'man zombie',
    tags: ['man zombie', 'undead', 'walking dead'],
  },
  {
    text: '🧟‍♀️',
    name: 'woman zombie',
    tags: ['undead', 'walking dead', 'woman zombie'],
  },
  {
    text: '💆',
    name: 'person getting massage',
    tags: ['face', 'massage', 'person getting massage', 'salon'],
  },
  {
    text: '💆‍♂️',
    name: 'man getting massage',
    tags: ['face', 'man', 'man getting massage', 'massage'],
  },
  {
    text: '💆‍♀️',
    name: 'woman getting massage',
    tags: ['face', 'massage', 'woman', 'woman getting massage'],
  },
  {
    text: '💇',
    name: 'person getting haircut',
    tags: ['barber', 'beauty', 'haircut', 'parlor', 'person getting haircut'],
  },
  {
    text: '💇‍♂️',
    name: 'man getting haircut',
    tags: ['haircut', 'man', 'man getting haircut'],
  },
  {
    text: '💇‍♀️',
    name: 'woman getting haircut',
    tags: ['haircut', 'woman', 'woman getting haircut'],
  },
  {
    text: '🚶',
    name: 'person walking',
    tags: ['hike', 'person walking', 'walk', 'walking'],
  },
  {
    text: '🚶‍♂️',
    name: 'man walking',
    tags: ['hike', 'man', 'man walking', 'walk'],
  },
  {
    text: '🚶‍♀️',
    name: 'woman walking',
    tags: ['hike', 'walk', 'woman', 'woman walking'],
  },
  {
    text: '🧍',
    name: 'person standing',
    tags: ['person standing', 'stand', 'standing'],
  },
  {
    text: '🧍‍♂️',
    name: 'man standing',
    tags: ['man', 'standing'],
  },
  {
    text: '🧍‍♀️',
    name: 'woman standing',
    tags: ['standing', 'woman'],
  },
  {
    text: '🧎',
    name: 'person kneeling',
    tags: ['kneel', 'kneeling', 'person kneeling'],
  },
  {
    text: '🧎‍♂️',
    name: 'man kneeling',
    tags: ['kneeling', 'man'],
  },
  {
    text: '🧎‍♀️',
    name: 'woman kneeling',
    tags: ['kneeling', 'woman'],
  },
  {
    text: '🧑‍🦯',
    name: 'person with probing cane',
    tags: ['accessibility', 'blind', 'person with probing cane'],
  },
  {
    text: '👨‍🦯',
    name: 'man with probing cane',
    tags: ['accessibility', 'blind', 'man', 'man with probing cane'],
  },
  {
    text: '👩‍🦯',
    name: 'woman with probing cane',
    tags: ['accessibility', 'blind', 'woman', 'woman with probing cane'],
  },
  {
    text: '🧑‍🦼',
    name: 'person in motorized wheelchair',
    tags: ['accessibility', 'person in motorized wheelchair', 'wheelchair'],
  },
  {
    text: '👨‍🦼',
    name: 'man in motorized wheelchair',
    tags: ['accessibility', 'man', 'man in motorized wheelchair', 'wheelchair'],
  },
  {
    text: '👩‍🦼',
    name: 'woman in motorized wheelchair',
    tags: [
      'accessibility',
      'wheelchair',
      'woman',
      'woman in motorized wheelchair',
    ],
  },
  {
    text: '🧑‍🦽',
    name: 'person in manual wheelchair',
    tags: ['accessibility', 'person in manual wheelchair', 'wheelchair'],
  },
  {
    text: '👨‍🦽',
    name: 'man in manual wheelchair',
    tags: ['accessibility', 'man', 'man in manual wheelchair', 'wheelchair'],
  },
  {
    text: '👩‍🦽',
    name: 'woman in manual wheelchair',
    tags: [
      'accessibility',
      'wheelchair',
      'woman',
      'woman in manual wheelchair',
    ],
  },
  {
    text: '🏃',
    name: 'person running',
    tags: ['marathon', 'person running', 'running'],
  },
  {
    text: '🏃‍♂️',
    name: 'man running',
    tags: ['man', 'marathon', 'racing', 'running'],
  },
  {
    text: '🏃‍♀️',
    name: 'woman running',
    tags: ['marathon', 'racing', 'running', 'woman'],
  },
  {
    text: '💃',
    name: 'woman dancing',
    tags: ['dancing', 'woman'],
  },
  {
    text: '🕺',
    name: 'man dancing',
    tags: ['dance', 'man', 'man dancing'],
  },
  {
    text: '🕴',
    name: 'man in suit levitating',
    tags: ['business', 'man', 'man in suit levitating', 'suit'],
  },
  {
    text: '👯',
    name: 'people with bunny ears',
    tags: ['bunny ear', 'dancer', 'partying', 'people with bunny ears'],
  },
  {
    text: '👯‍♂️',
    name: 'men with bunny ears',
    tags: ['bunny ear', 'dancer', 'men', 'men with bunny ears', 'partying'],
  },
  {
    text: '👯‍♀️',
    name: 'women with bunny ears',
    tags: ['bunny ear', 'dancer', 'partying', 'women', 'women with bunny ears'],
  },
  {
    text: '🧖',
    name: 'person in steamy room',
    tags: [
      'person in steamy room',
      'sauna',
      'steam room',
      'hamam',
      'steambath',
    ],
  },
  {
    text: '🧖‍♂️',
    name: 'man in steamy room',
    tags: ['man in steamy room', 'sauna', 'steam room'],
  },
  {
    text: '🧖‍♀️',
    name: 'woman in steamy room',
    tags: ['sauna', 'steam room', 'woman in steamy room'],
  },
  {
    text: '🧗',
    name: 'person climbing',
    tags: ['climber', 'person climbing'],
  },
  {
    text: '🧗‍♂️',
    name: 'man climbing',
    tags: ['climber', 'man climbing'],
  },
  {
    text: '🧗‍♀️',
    name: 'woman climbing',
    tags: ['climber', 'woman climbing'],
  },
  {
    text: '🤺',
    name: 'person fencing',
    tags: ['fencer', 'fencing', 'person fencing', 'sword'],
  },
  {
    text: '🏇',
    name: 'horse racing',
    tags: ['horse', 'jockey', 'racehorse', 'racing'],
  },
  {
    text: '⛷',
    name: 'skier',
    tags: ['ski', 'skier', 'snow'],
  },
  {
    text: '🏂',
    name: 'snowboarder',
    tags: ['ski', 'snow', 'snowboard', 'snowboarder'],
  },
  {
    text: '🏌',
    name: 'person golfing',
    tags: ['ball', 'golf', 'person golfing'],
  },
  {
    text: '🏌️‍♂️',
    name: 'man golfing',
    tags: ['golf', 'man', 'man golfing'],
  },
  {
    text: '🏌️‍♀️',
    name: 'woman golfing',
    tags: ['golf', 'woman', 'woman golfing'],
  },
  {
    text: '🏄',
    name: 'person surfing',
    tags: ['person surfing', 'surfing'],
  },
  {
    text: '🏄‍♂️',
    name: 'man surfing',
    tags: ['man', 'surfing'],
  },
  {
    text: '🏄‍♀️',
    name: 'woman surfing',
    tags: ['surfing', 'woman'],
  },
  {
    text: '🚣',
    name: 'person rowing boat',
    tags: ['boat', 'person rowing boat', 'rowboat'],
  },
  {
    text: '🚣‍♂️',
    name: 'man rowing boat',
    tags: ['boat', 'man', 'man rowing boat', 'rowboat'],
  },
  {
    text: '🚣‍♀️',
    name: 'woman rowing boat',
    tags: ['boat', 'rowboat', 'woman', 'woman rowing boat'],
  },
  {
    text: '🏊',
    name: 'person swimming',
    tags: ['person swimming', 'swim'],
  },
  {
    text: '🏊‍♂️',
    name: 'man swimming',
    tags: ['man', 'man swimming', 'swim'],
  },
  {
    text: '🏊‍♀️',
    name: 'woman swimming',
    tags: ['swim', 'woman', 'woman swimming'],
  },
  {
    text: '⛹',
    name: 'person bouncing ball',
    tags: ['ball', 'person bouncing ball'],
  },
  {
    text: '⛹️‍♂️',
    name: 'man bouncing ball',
    tags: ['ball', 'man', 'man bouncing ball'],
  },
  {
    text: '⛹️‍♀️',
    name: 'woman bouncing ball',
    tags: ['ball', 'woman', 'woman bouncing ball'],
  },
  {
    text: '🏋',
    name: 'person lifting weights',
    tags: ['lifter', 'person lifting weights', 'weight'],
  },
  {
    text: '🏋️‍♂️',
    name: 'man lifting weights',
    tags: ['man', 'man lifting weights', 'weight lifter'],
  },
  {
    text: '🏋️‍♀️',
    name: 'woman lifting weights',
    tags: ['weight lifter', 'woman', 'woman lifting weights'],
  },
  {
    text: '🚴',
    name: 'person biking',
    tags: ['bicycle', 'biking', 'cyclist', 'person biking'],
  },
  {
    text: '🚴‍♂️',
    name: 'man biking',
    tags: ['bicycle', 'biking', 'cyclist', 'man'],
  },
  {
    text: '🚴‍♀️',
    name: 'woman biking',
    tags: ['bicycle', 'biking', 'cyclist', 'woman'],
  },
  {
    text: '🚵',
    name: 'person mountain biking',
    tags: [
      'bicycle',
      'bicyclist',
      'bike',
      'cyclist',
      'mountain',
      'person mountain biking',
    ],
  },
  {
    text: '🚵‍♂️',
    name: 'man mountain biking',
    tags: [
      'bicycle',
      'bike',
      'cyclist',
      'man',
      'man mountain biking',
      'mountain',
    ],
  },
  {
    text: '🚵‍♀️',
    name: 'woman mountain biking',
    tags: ['bicycle', 'bike', 'biking', 'cyclist', 'mountain', 'woman'],
  },
  {
    text: '🤸',
    name: 'person cartwheeling',
    tags: ['cartwheel', 'gymnastics', 'person cartwheeling'],
  },
  {
    text: '🤸‍♂️',
    name: 'man cartwheeling',
    tags: ['cartwheel', 'gymnastics', 'man', 'man cartwheeling'],
  },
  {
    text: '🤸‍♀️',
    name: 'woman cartwheeling',
    tags: ['cartwheel', 'gymnastics', 'woman', 'woman cartwheeling'],
  },
  {
    text: '🤼',
    name: 'people wrestling',
    tags: ['people wrestling', 'wrestle', 'wrestler'],
  },
  {
    text: '🤼‍♂️',
    name: 'men wrestling',
    tags: ['men', 'men wrestling', 'wrestle'],
  },
  {
    text: '🤼‍♀️',
    name: 'women wrestling',
    tags: ['women', 'women wrestling', 'wrestle'],
  },
  {
    text: '🤽',
    name: 'person playing water polo',
    tags: ['person playing water polo', 'polo', 'water'],
  },
  {
    text: '🤽‍♂️',
    name: 'man playing water polo',
    tags: ['man', 'man playing water polo', 'water polo'],
  },
  {
    text: '🤽‍♀️',
    name: 'woman playing water polo',
    tags: ['water polo', 'woman', 'woman playing water polo'],
  },
  {
    text: '🤾',
    name: 'person playing handball',
    tags: ['ball', 'handball', 'person playing handball'],
  },
  {
    text: '🤾‍♂️',
    name: 'man playing handball',
    tags: ['handball', 'man', 'man playing handball'],
  },
  {
    text: '🤾‍♀️',
    name: 'woman playing handball',
    tags: ['handball', 'woman', 'woman playing handball'],
  },
  {
    text: '🤹',
    name: 'person juggling',
    tags: ['balance', 'juggle', 'multitask', 'person juggling', 'skill'],
  },
  {
    text: '🤹‍♂️',
    name: 'man juggling',
    tags: ['juggling', 'man', 'multitask'],
  },
  {
    text: '🤹‍♀️',
    name: 'woman juggling',
    tags: ['juggling', 'multitask', 'woman'],
  },
  {
    text: '🧘',
    name: 'person in lotus position',
    tags: ['meditation', 'person in lotus position', 'yoga', 'serenity'],
  },
  {
    text: '🧘‍♂️',
    name: 'man in lotus position',
    tags: ['man in lotus position', 'meditation', 'yoga'],
  },
  {
    text: '🧘‍♀️',
    name: 'woman in lotus position',
    tags: ['meditation', 'woman in lotus position', 'yoga'],
  },
  {
    text: '🛀',
    name: 'person taking bath',
    tags: ['bath', 'bathtub', 'person taking bath'],
  },
  {
    text: '🛌',
    name: 'person in bed',
    tags: ['hotel', 'person in bed', 'sleep'],
  },
  {
    text: '🧑‍🤝‍🧑',
    name: 'people holding hands',
    tags: [
      'couple',
      'hand',
      'hold',
      'holding hands',
      'people holding hands',
      'person',
    ],
  },
  {
    text: '👭',
    name: 'women holding hands',
    tags: ['couple', 'hand', 'holding hands', 'women', 'women holding hands'],
  },
  {
    text: '👫',
    name: 'woman and man holding hands',
    tags: [
      'couple',
      'hand',
      'hold',
      'holding hands',
      'man',
      'woman',
      'woman and man holding hands',
    ],
  },
  {
    text: '👬',
    name: 'men holding hands',
    tags: [
      'couple',
      'gemini',
      'holding hands',
      'man',
      'men',
      'men holding hands',
      'twins',
      'zodiac',
    ],
  },
  {
    text: '💏',
    name: 'kiss',
    tags: ['couple', 'kiss'],
  },
  {
    text: '👩‍❤️‍💋‍👨',
    name: 'kiss: woman, man',
    tags: ['couple', 'kiss', 'man', 'woman'],
  },
  {
    text: '👨‍❤️‍💋‍👨',
    name: 'kiss: man, man',
    tags: ['couple', 'kiss', 'man'],
  },
  {
    text: '👩‍❤️‍💋‍👩',
    name: 'kiss: woman, woman',
    tags: ['couple', 'kiss', 'woman'],
  },
  {
    text: '💑',
    name: 'couple with heart',
    tags: ['couple', 'couple with heart', 'love'],
  },
  {
    text: '👩‍❤️‍👨',
    name: 'couple with heart: woman, man',
    tags: ['couple', 'couple with heart', 'love', 'man', 'woman'],
  },
  {
    text: '👨‍❤️‍👨',
    name: 'couple with heart: man, man',
    tags: ['couple', 'couple with heart', 'love', 'man'],
  },
  {
    text: '👩‍❤️‍👩',
    name: 'couple with heart: woman, woman',
    tags: ['couple', 'couple with heart', 'love', 'woman'],
  },
  {
    text: '👪',
    name: 'family',
    tags: ['family'],
  },
  {
    text: '👨‍👩‍👦',
    name: 'family: man, woman, boy',
    tags: ['boy', 'family', 'man', 'woman'],
  },
  {
    text: '👨‍👩‍👧',
    name: 'family: man, woman, girl',
    tags: ['family', 'girl', 'man', 'woman'],
  },
  {
    text: '👨‍👩‍👧‍👦',
    name: 'family: man, woman, girl, boy',
    tags: ['boy', 'family', 'girl', 'man', 'woman'],
  },
  {
    text: '👨‍👩‍👦‍👦',
    name: 'family: man, woman, boy, boy',
    tags: ['boy', 'family', 'man', 'woman'],
  },
  {
    text: '👨‍👩‍👧‍👧',
    name: 'family: man, woman, girl, girl',
    tags: ['family', 'girl', 'man', 'woman'],
  },
  {
    text: '👨‍👨‍👦',
    name: 'family: man, man, boy',
    tags: ['boy', 'family', 'man'],
  },
  {
    text: '👨‍👨‍👧',
    name: 'family: man, man, girl',
    tags: ['family', 'girl', 'man'],
  },
  {
    text: '👨‍👨‍👧‍👦',
    name: 'family: man, man, girl, boy',
    tags: ['boy', 'family', 'girl', 'man'],
  },
  {
    text: '👨‍👨‍👦‍👦',
    name: 'family: man, man, boy, boy',
    tags: ['boy', 'family', 'man'],
  },
  {
    text: '👨‍👨‍👧‍👧',
    name: 'family: man, man, girl, girl',
    tags: ['family', 'girl', 'man'],
  },
  {
    text: '👩‍👩‍👦',
    name: 'family: woman, woman, boy',
    tags: ['boy', 'family', 'woman'],
  },
  {
    text: '👩‍👩‍👧',
    name: 'family: woman, woman, girl',
    tags: ['family', 'girl', 'woman'],
  },
  {
    text: '👩‍👩‍👧‍👦',
    name: 'family: woman, woman, girl, boy',
    tags: ['boy', 'family', 'girl', 'woman'],
  },
  {
    text: '👩‍👩‍👦‍👦',
    name: 'family: woman, woman, boy, boy',
    tags: ['boy', 'family', 'woman'],
  },
  {
    text: '👩‍👩‍👧‍👧',
    name: 'family: woman, woman, girl, girl',
    tags: ['family', 'girl', 'woman'],
  },
  {
    text: '👨‍👦',
    name: 'family: man, boy',
    tags: ['boy', 'family', 'man'],
  },
  {
    text: '👨‍👦‍👦',
    name: 'family: man, boy, boy',
    tags: ['boy', 'family', 'man'],
  },
  {
    text: '👨‍👧',
    name: 'family: man, girl',
    tags: ['family', 'girl', 'man'],
  },
  {
    text: '👨‍👧‍👦',
    name: 'family: man, girl, boy',
    tags: ['boy', 'family', 'girl', 'man'],
  },
  {
    text: '👨‍👧‍👧',
    name: 'family: man, girl, girl',
    tags: ['family', 'girl', 'man'],
  },
  {
    text: '👩‍👦',
    name: 'family: woman, boy',
    tags: ['boy', 'family', 'woman'],
  },
  {
    text: '👩‍👦‍👦',
    name: 'family: woman, boy, boy',
    tags: ['boy', 'family', 'woman'],
  },
  {
    text: '👩‍👧',
    name: 'family: woman, girl',
    tags: ['family', 'girl', 'woman'],
  },
  {
    text: '👩‍👧‍👦',
    name: 'family: woman, girl, boy',
    tags: ['boy', 'family', 'girl', 'woman'],
  },
  {
    text: '👩‍👧‍👧',
    name: 'family: woman, girl, girl',
    tags: ['family', 'girl', 'woman'],
  },
  {
    text: '🗣',
    name: 'speaking head',
    tags: ['face', 'head', 'silhouette', 'speak', 'speaking'],
  },
  {
    text: '👤',
    name: 'bust in silhouette',
    tags: ['bust', 'bust in silhouette', 'silhouette'],
  },
  {
    text: '👥',
    name: 'busts in silhouette',
    tags: ['bust', 'busts in silhouette', 'silhouette'],
  },
  {
    text: '👣',
    name: 'footprints',
    tags: ['clothing', 'footprint', 'footprints', 'print'],
  },
  {
    text: '🦰',
    name: 'red hair',
    tags: ['ginger', 'red hair', 'redhead'],
  },
  {
    text: '🦱',
    name: 'curly hair',
    tags: ['afro', 'curly', 'curly hair', 'ringlets'],
  },
  {
    text: '🦳',
    name: 'white hair',
    tags: ['gray', 'hair', 'old', 'white'],
  },
  {
    text: '🦲',
    name: 'bald',
    tags: ['bald', 'chemotherapy', 'hairless', 'no hair', 'shaven'],
  },
  {
    text: '🐵',
    name: 'monkey face',
    tags: ['face', 'monkey'],
  },
  {
    text: '🐒',
    name: 'monkey',
    tags: ['monkey'],
  },
  {
    text: '🦍',
    name: 'gorilla',
    tags: ['gorilla'],
  },
  {
    text: '🦧',
    name: 'orangutan',
    tags: ['ape', 'orangutan'],
  },
  {
    text: '🐶',
    name: 'dog face',
    tags: ['dog', 'face', 'pet'],
  },
  {
    text: '🐕',
    name: 'dog',
    tags: ['dog', 'pet'],
  },
  {
    text: '🦮',
    name: 'guide dog',
    tags: ['accessibility', 'blind', 'guide', 'guide dog'],
  },
  {
    text: '🐕‍🦺',
    name: 'service dog',
    tags: ['accessibility', 'assistance', 'dog', 'service'],
  },
  {
    text: '🐩',
    name: 'poodle',
    tags: ['dog', 'poodle'],
  },
  {
    text: '🐺',
    name: 'wolf',
    tags: ['face', 'wolf'],
  },
  {
    text: '🦊',
    name: 'fox',
    tags: ['face', 'fox'],
  },
  {
    text: '🦝',
    name: 'raccoon',
    tags: ['curious', 'raccoon', 'sly'],
  },
  {
    text: '🐱',
    name: 'cat face',
    tags: ['cat', 'face', 'pet'],
  },
  {
    text: '🐈',
    name: 'cat',
    tags: ['cat', 'pet'],
  },
  {
    text: '🦁',
    name: 'lion',
    tags: ['face', 'leo', 'lion', 'zodiac'],
  },
  {
    text: '🐯',
    name: 'tiger face',
    tags: ['face', 'tiger'],
  },
  {
    text: '🐅',
    name: 'tiger',
    tags: ['tiger'],
  },
  {
    text: '🐆',
    name: 'leopard',
    tags: ['leopard'],
  },
  {
    text: '🐴',
    name: 'horse face',
    tags: ['face', 'horse'],
  },
  {
    text: '🐎',
    name: 'horse',
    tags: ['equestrian', 'horse', 'racehorse', 'racing'],
  },
  {
    text: '🦄',
    name: 'unicorn',
    tags: ['face', 'unicorn'],
  },
  {
    text: '🦓',
    name: 'zebra',
    tags: ['stripe', 'zebra'],
  },
  {
    text: '🦌',
    name: 'deer',
    tags: ['deer'],
  },
  {
    text: '🐮',
    name: 'cow face',
    tags: ['cow', 'face'],
  },
  {
    text: '🐂',
    name: 'ox',
    tags: ['bull', 'ox', 'taurus', 'zodiac'],
  },
  {
    text: '🐃',
    name: 'water buffalo',
    tags: ['buffalo', 'water'],
  },
  {
    text: '🐄',
    name: 'cow',
    tags: ['cow'],
  },
  {
    text: '🐷',
    name: 'pig face',
    tags: ['face', 'pig'],
  },
  {
    text: '🐖',
    name: 'pig',
    tags: ['pig', 'sow'],
  },
  {
    text: '🐗',
    name: 'boar',
    tags: ['boar', 'pig'],
  },
  {
    text: '🐽',
    name: 'pig nose',
    tags: ['face', 'nose', 'pig'],
  },
  {
    text: '🐏',
    name: 'ram',
    tags: ['aries', 'male', 'ram', 'sheep', 'zodiac'],
  },
  {
    text: '🐑',
    name: 'ewe',
    tags: ['ewe', 'female', 'sheep'],
  },
  {
    text: '🐐',
    name: 'goat',
    tags: ['capricorn', 'goat', 'zodiac'],
  },
  {
    text: '🐪',
    name: 'camel',
    tags: ['camel', 'dromedary', 'hump'],
  },
  {
    text: '🐫',
    name: 'two-hump camel',
    tags: ['bactrian', 'camel', 'hump', 'two-hump camel'],
  },
  {
    text: '🦙',
    name: 'llama',
    tags: ['alpaca', 'guanaco', 'llama', 'vicuña', 'wool'],
  },
  {
    text: '🦒',
    name: 'giraffe',
    tags: ['giraffe', 'spots'],
  },
  {
    text: '🐘',
    name: 'elephant',
    tags: ['elephant'],
  },
  {
    text: '🦏',
    name: 'rhinoceros',
    tags: ['rhinoceros'],
  },
  {
    text: '🦛',
    name: 'hippopotamus',
    tags: ['hippo', 'hippopotamus'],
  },
  {
    text: '🐭',
    name: 'mouse face',
    tags: ['face', 'mouse'],
  },
  {
    text: '🐁',
    name: 'mouse',
    tags: ['mouse'],
  },
  {
    text: '🐀',
    name: 'rat',
    tags: ['rat'],
  },
  {
    text: '🐹',
    name: 'hamster',
    tags: ['face', 'hamster', 'pet'],
  },
  {
    text: '🐰',
    name: 'rabbit face',
    tags: ['bunny', 'face', 'pet', 'rabbit'],
  },
  {
    text: '🐇',
    name: 'rabbit',
    tags: ['bunny', 'pet', 'rabbit'],
  },
  {
    text: '🐿',
    name: 'chipmunk',
    tags: ['chipmunk', 'squirrel'],
  },
  {
    text: '🦔',
    name: 'hedgehog',
    tags: ['hedgehog', 'spiny'],
  },
  {
    text: '🦇',
    name: 'bat',
    tags: ['bat', 'vampire'],
  },
  {
    text: '🐻',
    name: 'bear',
    tags: ['bear', 'face'],
  },
  {
    text: '🐨',
    name: 'koala',
    tags: ['bear', 'koala'],
  },
  {
    text: '🐼',
    name: 'panda',
    tags: ['face', 'panda'],
  },
  {
    text: '🦥',
    name: 'sloth',
    tags: ['lazy', 'sloth', 'slow'],
  },
  {
    text: '🦦',
    name: 'otter',
    tags: ['fishing', 'otter', 'playful'],
  },
  {
    text: '🦨',
    name: 'skunk',
    tags: ['skunk', 'stink'],
  },
  {
    text: '🦘',
    name: 'kangaroo',
    tags: ['australia', 'joey', 'jump', 'kangaroo', 'marsupial'],
  },
  {
    text: '🦡',
    name: 'badger',
    tags: ['badger', 'honey badger', 'pester'],
  },
  {
    text: '🐾',
    name: 'paw prints',
    tags: ['feet', 'paw', 'paw prints', 'print'],
  },
  {
    text: '🦃',
    name: 'turkey',
    tags: ['bird', 'turkey'],
  },
  {
    text: '🐔',
    name: 'chicken',
    tags: ['bird', 'chicken'],
  },
  {
    text: '🐓',
    name: 'rooster',
    tags: ['bird', 'rooster'],
  },
  {
    text: '🐣',
    name: 'hatching chick',
    tags: ['baby', 'bird', 'chick', 'hatching'],
  },
  {
    text: '🐤',
    name: 'baby chick',
    tags: ['baby', 'bird', 'chick'],
  },
  {
    text: '🐥',
    name: 'front-facing baby chick',
    tags: ['baby', 'bird', 'chick', 'front-facing baby chick'],
  },
  {
    text: '🐦',
    name: 'bird',
    tags: ['bird'],
  },
  {
    text: '🐧',
    name: 'penguin',
    tags: ['bird', 'penguin'],
  },
  {
    text: '🕊',
    name: 'dove',
    tags: ['bird', 'dove', 'fly', 'peace'],
  },
  {
    text: '🦅',
    name: 'eagle',
    tags: ['bird', 'eagle'],
  },
  {
    text: '🦆',
    name: 'duck',
    tags: ['bird', 'duck'],
  },
  {
    text: '🦢',
    name: 'swan',
    tags: ['bird', 'cygnet', 'swan', 'ugly duckling'],
  },
  {
    text: '🦉',
    name: 'owl',
    tags: ['bird', 'owl', 'wise'],
  },
  {
    text: '🦩',
    name: 'flamingo',
    tags: ['flamboyant', 'flamingo', 'tropical'],
  },
  {
    text: '🦚',
    name: 'peacock',
    tags: ['bird', 'ostentatious', 'peacock', 'peahen', 'proud'],
  },
  {
    text: '🦜',
    name: 'parrot',
    tags: ['bird', 'parrot', 'pirate', 'talk'],
  },
  {
    text: '🐸',
    name: 'frog',
    tags: ['face', 'frog'],
  },
  {
    text: '🐊',
    name: 'crocodile',
    tags: ['crocodile'],
  },
  {
    text: '🐢',
    name: 'turtle',
    tags: ['terrapin', 'tortoise', 'turtle'],
  },
  {
    text: '🦎',
    name: 'lizard',
    tags: ['lizard', 'reptile'],
  },
  {
    text: '🐍',
    name: 'snake',
    tags: ['bearer', 'ophiuchus', 'serpent', 'snake', 'zodiac'],
  },
  {
    text: '🐲',
    name: 'dragon face',
    tags: ['dragon', 'face', 'fairy tale'],
  },
  {
    text: '🐉',
    name: 'dragon',
    tags: ['dragon', 'fairy tale'],
  },
  {
    text: '🦕',
    name: 'sauropod',
    tags: ['brachiosaurus', 'brontosaurus', 'diplodocus', 'sauropod'],
  },
  {
    text: '🦖',
    name: 'T-Rex',
    tags: ['t-rex', 'tyrannosaurus rex'],
  },
  {
    text: '🐳',
    name: 'spouting whale',
    tags: ['face', 'spouting', 'whale'],
  },
  {
    text: '🐋',
    name: 'whale',
    tags: ['whale'],
  },
  {
    text: '🐬',
    name: 'dolphin',
    tags: ['dolphin', 'flipper'],
  },
  {
    text: '🐟',
    name: 'fish',
    tags: ['fish', 'pisces', 'zodiac'],
  },
  {
    text: '🐠',
    name: 'tropical fish',
    tags: ['fish', 'tropical'],
  },
  {
    text: '🐡',
    name: 'blowfish',
    tags: ['blowfish', 'fish'],
  },
  {
    text: '🦈',
    name: 'shark',
    tags: ['fish', 'shark'],
  },
  {
    text: '🐙',
    name: 'octopus',
    tags: ['octopus'],
  },
  {
    text: '🐚',
    name: 'spiral shell',
    tags: ['shell', 'spiral'],
  },
  {
    text: '🐌',
    name: 'snail',
    tags: ['snail'],
  },
  {
    text: '🦋',
    name: 'butterfly',
    tags: ['butterfly', 'insect', 'pretty'],
  },
  {
    text: '🐛',
    name: 'bug',
    tags: ['bug', 'insect'],
  },
  {
    text: '🐜',
    name: 'ant',
    tags: ['ant', 'insect'],
  },
  {
    text: '🐝',
    name: 'honeybee',
    tags: ['bee', 'honeybee', 'insect'],
  },
  {
    text: '🐞',
    name: 'lady beetle',
    tags: ['beetle', 'insect', 'lady beetle', 'ladybird', 'ladybug'],
  },
  {
    text: '🦗',
    name: 'cricket',
    tags: ['cricket', 'grasshopper', 'orthoptera'],
  },
  {
    text: '🕷',
    name: 'spider',
    tags: ['insect', 'spider'],
  },
  {
    text: '🕸',
    name: 'spider web',
    tags: ['spider', 'web'],
  },
  {
    text: '🦂',
    name: 'scorpion',
    tags: ['scorpio', 'scorpio', 'scorpion', 'zodiac'],
  },
  {
    text: '🦟',
    name: 'mosquito',
    tags: ['disease', 'fever', 'malaria', 'mosquito', 'pest', 'virus'],
  },
  {
    text: '🦠',
    name: 'microbe',
    tags: ['amoeba', 'bacteria', 'microbe', 'virus'],
  },
  {
    text: '💐',
    name: 'bouquet',
    tags: ['bouquet', 'flower'],
  },
  {
    text: '🌸',
    name: 'cherry blossom',
    tags: ['blossom', 'cherry', 'flower'],
  },
  {
    text: '💮',
    name: 'white flower',
    tags: ['flower', 'white flower'],
  },
  {
    text: '🏵',
    name: 'rosette',
    tags: ['plant', 'rosette'],
  },
  {
    text: '🌹',
    name: 'rose',
    tags: ['flower', 'rose'],
  },
  {
    text: '🥀',
    name: 'wilted flower',
    tags: ['flower', 'wilted'],
  },
  {
    text: '🌺',
    name: 'hibiscus',
    tags: ['flower', 'hibiscus'],
  },
  {
    text: '🌻',
    name: 'sunflower',
    tags: ['flower', 'sun', 'sunflower'],
  },
  {
    text: '🌼',
    name: 'blossom',
    tags: ['blossom', 'flower'],
  },
  {
    text: '🌷',
    name: 'tulip',
    tags: ['flower', 'tulip'],
  },
  {
    text: '🌱',
    name: 'seedling',
    tags: ['seedling', 'young'],
  },
  {
    text: '🌲',
    name: 'evergreen tree',
    tags: ['evergreen tree', 'tree'],
  },
  {
    text: '🌳',
    name: 'deciduous tree',
    tags: ['deciduous', 'shedding', 'tree'],
  },
  {
    text: '🌴',
    name: 'palm tree',
    tags: ['palm', 'tree'],
  },
  {
    text: '🌵',
    name: 'cactus',
    tags: ['cactus', 'plant'],
  },
  {
    text: '🌾',
    name: 'sheaf of rice',
    tags: ['ear', 'grain', 'rice', 'sheaf of rice'],
  },
  {
    text: '🌿',
    name: 'herb',
    tags: ['herb', 'leaf'],
  },
  {
    text: '☘',
    name: 'shamrock',
    tags: ['plant', 'shamrock'],
  },
  {
    text: '🍀',
    name: 'four leaf clover',
    tags: ['4', 'clover', 'four', 'four-leaf clover', 'leaf'],
  },
  {
    text: '🍁',
    name: 'maple leaf',
    tags: ['falling', 'leaf', 'maple'],
  },
  {
    text: '🍂',
    name: 'fallen leaf',
    tags: ['fallen leaf', 'falling', 'leaf'],
  },
  {
    text: '🍃',
    name: 'leaf fluttering in wind',
    tags: ['blow', 'flutter', 'leaf', 'leaf fluttering in wind', 'wind'],
  },
  {
    text: '🍇',
    name: 'grapes',
    tags: ['fruit', 'grape', 'grapes'],
  },
  {
    text: '🍈',
    name: 'melon',
    tags: ['fruit', 'melon'],
  },
  {
    text: '🍉',
    name: 'watermelon',
    tags: ['fruit', 'watermelon'],
  },
  {
    text: '🍊',
    name: 'tangerine',
    tags: ['fruit', 'orange', 'tangerine'],
  },
  {
    text: '🍋',
    name: 'lemon',
    tags: ['citrus', 'fruit', 'lemon'],
  },
  {
    text: '🍌',
    name: 'banana',
    tags: ['banana', 'fruit'],
  },
  {
    text: '🍍',
    name: 'pineapple',
    tags: ['fruit', 'pineapple'],
  },
  {
    text: '🥭',
    name: 'mango',
    tags: ['fruit', 'mango', 'tropical'],
  },
  {
    text: '🍎',
    name: 'red apple',
    tags: ['apple', 'fruit', 'red'],
  },
  {
    text: '🍏',
    name: 'green apple',
    tags: ['apple', 'fruit', 'green'],
  },
  {
    text: '🍐',
    name: 'pear',
    tags: ['fruit', 'pear'],
  },
  {
    text: '🍑',
    name: 'peach',
    tags: ['fruit', 'peach'],
  },
  {
    text: '🍒',
    name: 'cherries',
    tags: ['berries', 'cherries', 'cherry', 'fruit', 'red'],
  },
  {
    text: '🍓',
    name: 'strawberry',
    tags: ['berry', 'fruit', 'strawberry'],
  },
  {
    text: '🥝',
    name: 'kiwi fruit',
    tags: ['food', 'fruit', 'kiwi'],
  },
  {
    text: '🍅',
    name: 'tomato',
    tags: ['fruit', 'tomato', 'vegetable'],
  },
  {
    text: '🥥',
    name: 'coconut',
    tags: ['coconut', 'palm', 'piña colada'],
  },
  {
    text: '🥑',
    name: 'avocado',
    tags: ['avocado', 'food', 'fruit'],
  },
  {
    text: '🍆',
    name: 'eggplant',
    tags: ['aubergine', 'eggplant', 'vegetable'],
  },
  {
    text: '🥔',
    name: 'potato',
    tags: ['food', 'potato', 'vegetable'],
  },
  {
    text: '🥕',
    name: 'carrot',
    tags: ['carrot', 'food', 'vegetable'],
  },
  {
    text: '🌽',
    name: 'ear of corn',
    tags: ['corn', 'ear', 'ear of corn', 'maize', 'maze'],
  },
  {
    text: '🌶',
    name: 'hot pepper',
    tags: ['hot', 'pepper'],
  },
  {
    text: '🥒',
    name: 'cucumber',
    tags: ['cucumber', 'food', 'pickle', 'vegetable'],
  },
  {
    text: '🥬',
    name: 'leafy green',
    tags: ['bok choy', 'cabbage', 'kale', 'leafy green', 'lettuce'],
  },
  {
    text: '🥦',
    name: 'broccoli',
    tags: ['broccoli', 'wild cabbage'],
  },
  {
    text: '🧄',
    name: 'garlic',
    tags: ['flavoring', 'garlic'],
  },
  {
    text: '🧅',
    name: 'onion',
    tags: ['flavoring', 'onion'],
  },
  {
    text: '🍄',
    name: 'mushroom',
    tags: ['mushroom', 'toadstool'],
  },
  {
    text: '🥜',
    name: 'peanuts',
    tags: ['food', 'nut', 'peanut', 'peanuts', 'vegetable'],
  },
  {
    text: '🌰',
    name: 'chestnut',
    tags: ['chestnut', 'plant'],
  },
  {
    text: '🍞',
    name: 'bread',
    tags: ['bread', 'loaf'],
  },
  {
    text: '🥐',
    name: 'croissant',
    tags: ['bread', 'breakfast', 'croissant', 'food', 'french', 'roll'],
  },
  {
    text: '🥖',
    name: 'baguette bread',
    tags: ['baguette', 'bread', 'food', 'french'],
  },
  {
    text: '🥨',
    name: 'pretzel',
    tags: ['pretzel', 'twisted', 'convoluted'],
  },
  {
    text: '🥯',
    name: 'bagel',
    tags: ['bagel', 'bakery', 'breakfast', 'schmear'],
  },
  {
    text: '🥞',
    name: 'pancakes',
    tags: ['breakfast', 'crêpe', 'food', 'hotcake', 'pancake', 'pancakes'],
  },
  {
    text: '🧇',
    name: 'waffle',
    tags: ['breakfast', 'indecisive', 'iron', 'waffle'],
  },
  {
    text: '🧀',
    name: 'cheese wedge',
    tags: ['cheese', 'cheese wedge'],
  },
  {
    text: '🍖',
    name: 'meat on bone',
    tags: ['bone', 'meat', 'meat on bone'],
  },
  {
    text: '🍗',
    name: 'poultry leg',
    tags: ['bone', 'chicken', 'drumstick', 'leg', 'poultry'],
  },
  {
    text: '🥩',
    name: 'cut of meat',
    tags: ['chop', 'cut of meat', 'lambchop', 'porkchop', 'steak'],
  },
  {
    text: '🥓',
    name: 'bacon',
    tags: ['bacon', 'breakfast', 'food', 'meat'],
  },
  {
    text: '🍔',
    name: 'hamburger',
    tags: ['burger', 'hamburger'],
  },
  {
    text: '🍟',
    name: 'french fries',
    tags: ['french', 'fries'],
  },
  {
    text: '🍕',
    name: 'pizza',
    tags: ['cheese', 'pizza', 'slice'],
  },
  {
    text: '🌭',
    name: 'hot dog',
    tags: ['frankfurter', 'hot dog', 'hotdog', 'sausage'],
  },
  {
    text: '🥪',
    name: 'sandwich',
    tags: ['bread', 'sandwich'],
  },
  {
    text: '🌮',
    name: 'taco',
    tags: ['mexican', 'taco'],
  },
  {
    text: '🌯',
    name: 'burrito',
    tags: ['burrito', 'mexican', 'wrap'],
  },
  {
    text: '🥙',
    name: 'stuffed flatbread',
    tags: ['falafel', 'flatbread', 'food', 'gyro', 'kebab', 'stuffed'],
  },
  {
    text: '🧆',
    name: 'falafel',
    tags: ['chickpea', 'falafel', 'meatball'],
  },
  {
    text: '🥚',
    name: 'egg',
    tags: ['breakfast', 'egg', 'food'],
  },
  {
    text: '🍳',
    name: 'cooking',
    tags: ['breakfast', 'cooking', 'egg', 'frying', 'pan'],
  },
  {
    text: '🥘',
    name: 'shallow pan of food',
    tags: [
      'casserole',
      'food',
      'paella',
      'pan',
      'shallow',
      'shallow pan of food',
    ],
  },
  {
    text: '🍲',
    name: 'pot of food',
    tags: ['pot', 'pot of food', 'stew'],
  },
  {
    text: '🥣',
    name: 'bowl with spoon',
    tags: [
      'bowl with spoon',
      'breakfast',
      'cereal',
      'congee',
      'oatmeal',
      'porridge',
    ],
  },
  {
    text: '🥗',
    name: 'green salad',
    tags: ['food', 'green', 'salad'],
  },
  {
    text: '🍿',
    name: 'popcorn',
    tags: ['popcorn'],
  },
  {
    text: '🧈',
    name: 'butter',
    tags: ['butter', 'dairy'],
  },
  {
    text: '🧂',
    name: 'salt',
    tags: ['condiment', 'salt', 'shaker'],
  },
  {
    text: '🥫',
    name: 'canned food',
    tags: ['can', 'canned food'],
  },
  {
    text: '🍱',
    name: 'bento box',
    tags: ['bento', 'box'],
  },
  {
    text: '🍘',
    name: 'rice cracker',
    tags: ['cracker', 'rice'],
  },
  {
    text: '🍙',
    name: 'rice ball',
    tags: ['ball', 'japanese', 'rice'],
  },
  {
    text: '🍚',
    name: 'cooked rice',
    tags: ['cooked', 'rice'],
  },
  {
    text: '🍛',
    name: 'curry rice',
    tags: ['curry', 'rice'],
  },
  {
    text: '🍜',
    name: 'steaming bowl',
    tags: ['bowl', 'noodle', 'ramen', 'steaming'],
  },
  {
    text: '🍝',
    name: 'spaghetti',
    tags: ['pasta', 'spaghetti'],
  },
  {
    text: '🍠',
    name: 'roasted sweet potato',
    tags: ['potato', 'roasted', 'sweet'],
  },
  {
    text: '🍢',
    name: 'oden',
    tags: ['kebab', 'oden', 'seafood', 'skewer', 'stick'],
  },
  {
    text: '🍣',
    name: 'sushi',
    tags: ['sushi'],
  },
  {
    text: '🍤',
    name: 'fried shrimp',
    tags: ['fried', 'prawn', 'shrimp', 'tempura'],
  },
  {
    text: '🍥',
    name: 'fish cake with swirl',
    tags: ['cake', 'fish', 'fish cake with swirl', 'pastry', 'swirl'],
  },
  {
    text: '🥮',
    name: 'moon cake',
    tags: ['autumn', 'festival', 'moon cake', 'yuèbǐng'],
  },
  {
    text: '🍡',
    name: 'dango',
    tags: ['dango', 'dessert', 'japanese', 'skewer', 'stick', 'sweet'],
  },
  {
    text: '🥟',
    name: 'dumpling',
    tags: ['dumpling', 'empanada', 'gyōza', 'jiaozi', 'pierogi', 'potsticker'],
  },
  {
    text: '🥠',
    name: 'fortune cookie',
    tags: ['fortune cookie', 'prophecy'],
  },
  {
    text: '🥡',
    name: 'takeout box',
    tags: ['oyster pail', 'takeout box'],
  },
  {
    text: '🦀',
    name: 'crab',
    tags: ['cancer', 'crab', 'zodiac'],
  },
  {
    text: '🦞',
    name: 'lobster',
    tags: ['bisque', 'claws', 'lobster', 'seafood'],
  },
  {
    text: '🦐',
    name: 'shrimp',
    tags: ['food', 'shellfish', 'shrimp', 'small'],
  },
  {
    text: '🦑',
    name: 'squid',
    tags: ['food', 'molusc', 'squid'],
  },
  {
    text: '🦪',
    name: 'oyster',
    tags: ['diving', 'oyster', 'pearl'],
  },
  {
    text: '🍦',
    name: 'soft ice cream',
    tags: ['cream', 'dessert', 'ice', 'icecream', 'soft', 'sweet'],
  },
  {
    text: '🍧',
    name: 'shaved ice',
    tags: ['dessert', 'ice', 'shaved', 'sweet'],
  },
  {
    text: '🍨',
    name: 'ice cream',
    tags: ['cream', 'dessert', 'ice', 'sweet'],
  },
  {
    text: '🍩',
    name: 'doughnut',
    tags: ['breakfast', 'dessert', 'donut', 'doughnut', 'sweet'],
  },
  {
    text: '🍪',
    name: 'cookie',
    tags: ['cookie', 'dessert', 'sweet'],
  },
  {
    text: '🎂',
    name: 'birthday cake',
    tags: ['birthday', 'cake', 'celebration', 'dessert', 'pastry', 'sweet'],
  },
  {
    text: '🍰',
    name: 'shortcake',
    tags: ['cake', 'dessert', 'pastry', 'shortcake', 'slice', 'sweet'],
  },
  {
    text: '🧁',
    name: 'cupcake',
    tags: ['bakery', 'cupcake', 'sweet'],
  },
  {
    text: '🥧',
    name: 'pie',
    tags: ['filling', 'pastry', 'pie', 'fruit', 'meat'],
  },
  {
    text: '🍫',
    name: 'chocolate bar',
    tags: ['bar', 'chocolate', 'dessert', 'sweet'],
  },
  {
    text: '🍬',
    name: 'candy',
    tags: ['candy', 'dessert', 'sweet'],
  },
  {
    text: '🍭',
    name: 'lollipop',
    tags: ['candy', 'dessert', 'lollipop', 'sweet'],
  },
  {
    text: '🍮',
    name: 'custard',
    tags: ['custard', 'dessert', 'pudding', 'sweet'],
  },
  {
    text: '🍯',
    name: 'honey pot',
    tags: ['honey', 'honeypot', 'pot', 'sweet'],
  },
  {
    text: '🍼',
    name: 'baby bottle',
    tags: ['baby', 'bottle', 'drink', 'milk'],
  },
  {
    text: '🥛',
    name: 'glass of milk',
    tags: ['drink', 'glass', 'glass of milk', 'milk'],
  },
  {
    text: '☕',
    name: 'hot beverage',
    tags: ['beverage', 'coffee', 'drink', 'hot', 'steaming', 'tea'],
  },
  {
    text: '🍵',
    name: 'teacup without handle',
    tags: [
      'beverage',
      'cup',
      'drink',
      'tea',
      'teacup',
      'teacup without handle',
    ],
  },
  {
    text: '🍶',
    name: 'sake',
    tags: ['bar', 'beverage', 'bottle', 'cup', 'drink', 'sake'],
  },
  {
    text: '🍾',
    name: 'bottle with popping cork',
    tags: [
      'bar',
      'bottle',
      'bottle with popping cork',
      'cork',
      'drink',
      'popping',
    ],
  },
  {
    text: '🍷',
    name: 'wine glass',
    tags: ['bar', 'beverage', 'drink', 'glass', 'wine'],
  },
  {
    text: '🍸',
    name: 'cocktail glass',
    tags: ['bar', 'cocktail', 'drink', 'glass'],
  },
  {
    text: '🍹',
    name: 'tropical drink',
    tags: ['bar', 'drink', 'tropical'],
  },
  {
    text: '🍺',
    name: 'beer mug',
    tags: ['bar', 'beer', 'drink', 'mug'],
  },
  {
    text: '🍻',
    name: 'clinking beer mugs',
    tags: ['bar', 'beer', 'clink', 'clinking beer mugs', 'drink', 'mug'],
  },
  {
    text: '🥂',
    name: 'clinking glasses',
    tags: ['celebrate', 'clink', 'clinking glasses', 'drink', 'glass'],
  },
  {
    text: '🥃',
    name: 'tumbler glass',
    tags: ['glass', 'liquor', 'shot', 'tumbler', 'whisky'],
  },
  {
    text: '🥤',
    name: 'cup with straw',
    tags: ['cup with straw', 'juice', 'soda', 'malt', 'soft drink', 'water'],
  },
  {
    text: '🧃',
    name: 'beverage box',
    tags: ['beverage', 'box', 'juice', 'straw', 'sweet'],
  },
  {
    text: '🧉',
    name: 'mate',
    tags: ['drink', 'mate'],
  },
  {
    text: '🧊',
    name: 'ice',
    tags: ['cold', 'ice', 'ice cube', 'iceberg'],
  },
  {
    text: '🥢',
    name: 'chopsticks',
    tags: ['chopsticks', 'hashi', 'jeotgarak', 'kuaizi'],
  },
  {
    text: '🍽',
    name: 'fork and knife with plate',
    tags: ['cooking', 'fork', 'fork and knife with plate', 'knife', 'plate'],
  },
  {
    text: '🍴',
    name: 'fork and knife',
    tags: ['cooking', 'cutlery', 'fork', 'fork and knife', 'knife'],
  },
  {
    text: '🥄',
    name: 'spoon',
    tags: ['spoon', 'tableware'],
  },
  {
    text: '🔪',
    name: 'kitchen knife',
    tags: ['cooking', 'hocho', 'kitchen knife', 'knife', 'tool', 'weapon'],
  },
  {
    text: '🏺',
    name: 'amphora',
    tags: ['amphora', 'aquarius', 'cooking', 'drink', 'jug', 'zodiac'],
  },
  {
    text: '🌍',
    name: 'globe showing Europe-Africa',
    tags: [
      'africa',
      'earth',
      'europe',
      'globe',
      'globe showing europe-africa',
      'world',
    ],
  },
  {
    text: '🌎',
    name: 'globe showing Americas',
    tags: ['americas', 'earth', 'globe', 'globe showing americas', 'world'],
  },
  {
    text: '🌏',
    name: 'globe showing Asia-Australia',
    tags: [
      'asia',
      'australia',
      'earth',
      'globe',
      'globe showing asia-australia',
      'world',
    ],
  },
  {
    text: '🌐',
    name: 'globe with meridians',
    tags: ['earth', 'globe', 'globe with meridians', 'meridians', 'world'],
  },
  {
    text: '🗺',
    name: 'world map',
    tags: ['map', 'world'],
  },
  {
    text: '🗾',
    name: 'map of Japan',
    tags: ['japan', 'map', 'map of japan'],
  },
  {
    text: '🧭',
    name: 'compass',
    tags: ['compass', 'magnetic', 'navigation', 'orienteering'],
  },
  {
    text: '🏔',
    name: 'snow-capped mountain',
    tags: ['cold', 'mountain', 'snow', 'snow-capped mountain'],
  },
  {
    text: '⛰',
    name: 'mountain',
    tags: ['mountain'],
  },
  {
    text: '🌋',
    name: 'volcano',
    tags: ['eruption', 'mountain', 'volcano'],
  },
  {
    text: '🗻',
    name: 'mount fuji',
    tags: ['fuji', 'mount fuji', 'mountain'],
  },
  {
    text: '🏕',
    name: 'camping',
    tags: ['camping'],
  },
  {
    text: '🏖',
    name: 'beach with umbrella',
    tags: ['beach', 'beach with umbrella', 'umbrella'],
  },
  {
    text: '🏜',
    name: 'desert',
    tags: ['desert'],
  },
  {
    text: '🏝',
    name: 'desert island',
    tags: ['desert', 'island'],
  },
  {
    text: '🏞',
    name: 'national park',
    tags: ['national park', 'park'],
  },
  {
    text: '🏟',
    name: 'stadium',
    tags: ['stadium'],
  },
  {
    text: '🏛',
    name: 'classical building',
    tags: ['classical', 'classical building'],
  },
  {
    text: '🏗',
    name: 'building construction',
    tags: ['building construction', 'construction'],
  },
  {
    text: '🧱',
    name: 'brick',
    tags: ['brick', 'bricks', 'clay', 'mortar', 'wall'],
  },
  {
    text: '🏘',
    name: 'houses',
    tags: ['houses'],
  },
  {
    text: '🏚',
    name: 'derelict house',
    tags: ['derelict', 'house'],
  },
  {
    text: '🏠',
    name: 'house',
    tags: ['home', 'house'],
  },
  {
    text: '🏡',
    name: 'house with garden',
    tags: ['garden', 'home', 'house', 'house with garden'],
  },
  {
    text: '🏢',
    name: 'office building',
    tags: ['building', 'office building'],
  },
  {
    text: '🏣',
    name: 'Japanese post office',
    tags: ['japanese', 'japanese post office', 'post'],
  },
  {
    text: '🏤',
    name: 'post office',
    tags: ['european', 'post', 'post office'],
  },
  {
    text: '🏥',
    name: 'hospital',
    tags: ['doctor', 'hospital', 'medicine'],
  },
  {
    text: '🏦',
    name: 'bank',
    tags: ['bank', 'building'],
  },
  {
    text: '🏨',
    name: 'hotel',
    tags: ['building', 'hotel'],
  },
  {
    text: '🏩',
    name: 'love hotel',
    tags: ['hotel', 'love'],
  },
  {
    text: '🏪',
    name: 'convenience store',
    tags: ['convenience', 'store'],
  },
  {
    text: '🏫',
    name: 'school',
    tags: ['building', 'school'],
  },
  {
    text: '🏬',
    name: 'department store',
    tags: ['department', 'store'],
  },
  {
    text: '🏭',
    name: 'factory',
    tags: ['building', 'factory'],
  },
  {
    text: '🏯',
    name: 'Japanese castle',
    tags: ['castle', 'japanese'],
  },
  {
    text: '🏰',
    name: 'castle',
    tags: ['castle', 'european'],
  },
  {
    text: '💒',
    name: 'wedding',
    tags: ['chapel', 'romance', 'wedding'],
  },
  {
    text: '🗼',
    name: 'Tokyo tower',
    tags: ['tokyo', 'tower'],
  },
  {
    text: '🗽',
    name: 'Statue of Liberty',
    tags: ['liberty', 'statue', 'statue of liberty'],
  },
  {
    text: '⛪',
    name: 'church',
    tags: ['christian', 'church', 'cross', 'religion'],
  },
  {
    text: '🕌',
    name: 'mosque',
    tags: ['islam', 'mosque', 'muslim', 'religion'],
  },
  {
    text: '🛕',
    name: 'hindu temple',
    tags: ['hindu', 'temple'],
  },
  {
    text: '🕍',
    name: 'synagogue',
    tags: ['jew', 'jewish', 'religion', 'synagogue', 'temple'],
  },
  {
    text: '⛩',
    name: 'shinto shrine',
    tags: ['religion', 'shinto', 'shrine'],
  },
  {
    text: '🕋',
    name: 'kaaba',
    tags: ['islam', 'kaaba', 'muslim', 'religion'],
  },
  {
    text: '⛲',
    name: 'fountain',
    tags: ['fountain'],
  },
  {
    text: '⛺',
    name: 'tent',
    tags: ['camping', 'tent'],
  },
  {
    text: '🌁',
    name: 'foggy',
    tags: ['fog', 'foggy'],
  },
  {
    text: '🌃',
    name: 'night with stars',
    tags: ['night', 'night with stars', 'star'],
  },
  {
    text: '🏙',
    name: 'cityscape',
    tags: ['city', 'cityscape'],
  },
  {
    text: '🌄',
    name: 'sunrise over mountains',
    tags: ['morning', 'mountain', 'sun', 'sunrise', 'sunrise over mountains'],
  },
  {
    text: '🌅',
    name: 'sunrise',
    tags: ['morning', 'sun', 'sunrise'],
  },
  {
    text: '🌆',
    name: 'cityscape at dusk',
    tags: [
      'city',
      'cityscape at dusk',
      'dusk',
      'evening',
      'landscape',
      'sunset',
    ],
  },
  {
    text: '🌇',
    name: 'sunset',
    tags: ['dusk', 'sun', 'sunset'],
  },
  {
    text: '🌉',
    name: 'bridge at night',
    tags: ['bridge', 'bridge at night', 'night'],
  },
  {
    text: '♨',
    name: 'hot springs',
    tags: ['hot', 'hotsprings', 'springs', 'steaming'],
  },
  {
    text: '🎠',
    name: 'carousel horse',
    tags: ['carousel', 'horse'],
  },
  {
    text: '🎡',
    name: 'ferris wheel',
    tags: ['amusement park', 'ferris', 'wheel'],
  },
  {
    text: '🎢',
    name: 'roller coaster',
    tags: ['amusement park', 'coaster', 'roller'],
  },
  {
    text: '💈',
    name: 'barber pole',
    tags: ['barber', 'haircut', 'pole'],
  },
  {
    text: '🎪',
    name: 'circus tent',
    tags: ['circus', 'tent'],
  },
  {
    text: '🚂',
    name: 'locomotive',
    tags: ['engine', 'locomotive', 'railway', 'steam', 'train'],
  },
  {
    text: '🚃',
    name: 'railway car',
    tags: ['car', 'electric', 'railway', 'train', 'tram', 'trolleybus'],
  },
  {
    text: '🚄',
    name: 'high-speed train',
    tags: ['high-speed train', 'railway', 'shinkansen', 'speed', 'train'],
  },
  {
    text: '🚅',
    name: 'bullet train',
    tags: ['bullet', 'railway', 'shinkansen', 'speed', 'train'],
  },
  {
    text: '🚆',
    name: 'train',
    tags: ['railway', 'train'],
  },
  {
    text: '🚇',
    name: 'metro',
    tags: ['metro', 'subway'],
  },
  {
    text: '🚈',
    name: 'light rail',
    tags: ['light rail', 'railway'],
  },
  {
    text: '🚉',
    name: 'station',
    tags: ['railway', 'station', 'train'],
  },
  {
    text: '🚊',
    name: 'tram',
    tags: ['tram', 'trolleybus'],
  },
  {
    text: '🚝',
    name: 'monorail',
    tags: ['monorail', 'vehicle'],
  },
  {
    text: '🚞',
    name: 'mountain railway',
    tags: ['car', 'mountain', 'railway'],
  },
  {
    text: '🚋',
    name: 'tram car',
    tags: ['car', 'tram', 'trolleybus'],
  },
  {
    text: '🚌',
    name: 'bus',
    tags: ['bus', 'vehicle'],
  },
  {
    text: '🚍',
    name: 'oncoming bus',
    tags: ['bus', 'oncoming'],
  },
  {
    text: '🚎',
    name: 'trolleybus',
    tags: ['bus', 'tram', 'trolley', 'trolleybus'],
  },
  {
    text: '🚐',
    name: 'minibus',
    tags: ['bus', 'minibus'],
  },
  {
    text: '🚑',
    name: 'ambulance',
    tags: ['ambulance', 'vehicle'],
  },
  {
    text: '🚒',
    name: 'fire engine',
    tags: ['engine', 'fire', 'truck'],
  },
  {
    text: '🚓',
    name: 'police car',
    tags: ['car', 'patrol', 'police'],
  },
  {
    text: '🚔',
    name: 'oncoming police car',
    tags: ['car', 'oncoming', 'police'],
  },
  {
    text: '🚕',
    name: 'taxi',
    tags: ['taxi', 'vehicle'],
  },
  {
    text: '🚖',
    name: 'oncoming taxi',
    tags: ['oncoming', 'taxi'],
  },
  {
    text: '🚗',
    name: 'automobile',
    tags: ['automobile', 'car'],
  },
  {
    text: '🚘',
    name: 'oncoming automobile',
    tags: ['automobile', 'car', 'oncoming'],
  },
  {
    text: '🚙',
    name: 'sport utility vehicle',
    tags: ['recreational', 'sport utility', 'sport utility vehicle'],
  },
  {
    text: '🚚',
    name: 'delivery truck',
    tags: ['delivery', 'truck'],
  },
  {
    text: '🚛',
    name: 'articulated lorry',
    tags: ['articulated lorry', 'lorry', 'semi', 'truck'],
  },
  {
    text: '🚜',
    name: 'tractor',
    tags: ['tractor', 'vehicle'],
  },
  {
    text: '🏎',
    name: 'racing car',
    tags: ['car', 'racing'],
  },
  {
    text: '🏍',
    name: 'motorcycle',
    tags: ['motorcycle', 'racing'],
  },
  {
    text: '🛵',
    name: 'motor scooter',
    tags: ['motor', 'scooter'],
  },
  {
    text: '🦽',
    name: 'manual wheelchair',
    tags: ['accessibility', 'manual wheelchair'],
  },
  {
    text: '🦼',
    name: 'motorized wheelchair',
    tags: ['accessibility', 'motorized wheelchair'],
  },
  {
    text: '🛺',
    name: 'auto rickshaw',
    tags: ['auto rickshaw', 'tuk tuk'],
  },
  {
    text: '🚲',
    name: 'bicycle',
    tags: ['bicycle', 'bike'],
  },
  {
    text: '🛴',
    name: 'kick scooter',
    tags: ['kick', 'scooter'],
  },
  {
    text: '🛹',
    name: 'skateboard',
    tags: ['board', 'skateboard'],
  },
  {
    text: '🚏',
    name: 'bus stop',
    tags: ['bus', 'busstop', 'stop'],
  },
  {
    text: '🛣',
    name: 'motorway',
    tags: ['highway', 'motorway', 'road'],
  },
  {
    text: '🛤',
    name: 'railway track',
    tags: ['railway', 'railway track', 'train'],
  },
  {
    text: '🛢',
    name: 'oil drum',
    tags: ['drum', 'oil'],
  },
  {
    text: '⛽',
    name: 'fuel pump',
    tags: ['diesel', 'fuel', 'fuelpump', 'gas', 'pump', 'station'],
  },
  {
    text: '🚨',
    name: 'police car light',
    tags: ['beacon', 'car', 'light', 'police', 'revolving'],
  },
  {
    text: '🚥',
    name: 'horizontal traffic light',
    tags: ['horizontal traffic light', 'light', 'signal', 'traffic'],
  },
  {
    text: '🚦',
    name: 'vertical traffic light',
    tags: ['light', 'signal', 'traffic', 'vertical traffic light'],
  },
  {
    text: '🛑',
    name: 'stop sign',
    tags: ['octagonal', 'sign', 'stop'],
  },
  {
    text: '🚧',
    name: 'construction',
    tags: ['barrier', 'construction'],
  },
  {
    text: '⚓',
    name: 'anchor',
    tags: ['anchor', 'ship', 'tool'],
  },
  {
    text: '⛵',
    name: 'sailboat',
    tags: ['boat', 'resort', 'sailboat', 'sea', 'yacht'],
  },
  {
    text: '🛶',
    name: 'canoe',
    tags: ['boat', 'canoe'],
  },
  {
    text: '🚤',
    name: 'speedboat',
    tags: ['boat', 'speedboat'],
  },
  {
    text: '🛳',
    name: 'passenger ship',
    tags: ['passenger', 'ship'],
  },
  {
    text: '⛴',
    name: 'ferry',
    tags: ['boat', 'ferry', 'passenger'],
  },
  {
    text: '🛥',
    name: 'motor boat',
    tags: ['boat', 'motor boat', 'motorboat'],
  },
  {
    text: '🚢',
    name: 'ship',
    tags: ['boat', 'passenger', 'ship'],
  },
  {
    text: '✈',
    name: 'airplane',
    tags: ['aeroplane', 'airplane'],
  },
  {
    text: '🛩',
    name: 'small airplane',
    tags: ['aeroplane', 'airplane', 'small airplane'],
  },
  {
    text: '🛫',
    name: 'airplane departure',
    tags: ['aeroplane', 'airplane', 'check-in', 'departure', 'departures'],
  },
  {
    text: '🛬',
    name: 'airplane arrival',
    tags: [
      'aeroplane',
      'airplane',
      'airplane arrival',
      'arrivals',
      'arriving',
      'landing',
    ],
  },
  {
    text: '🪂',
    name: 'parachute',
    tags: ['hang-glide', 'parachute', 'parasail', 'skydive'],
  },
  {
    text: '💺',
    name: 'seat',
    tags: ['chair', 'seat'],
  },
  {
    text: '🚁',
    name: 'helicopter',
    tags: ['helicopter', 'vehicle'],
  },
  {
    text: '🚟',
    name: 'suspension railway',
    tags: ['railway', 'suspension'],
  },
  {
    text: '🚠',
    name: 'mountain cableway',
    tags: ['cable', 'gondola', 'mountain', 'mountain cableway'],
  },
  {
    text: '🚡',
    name: 'aerial tramway',
    tags: ['aerial', 'cable', 'car', 'gondola', 'tramway'],
  },
  {
    text: '🛰',
    name: 'satellite',
    tags: ['satellite', 'space'],
  },
  {
    text: '🚀',
    name: 'rocket',
    tags: ['rocket', 'space'],
  },
  {
    text: '🛸',
    name: 'flying saucer',
    tags: ['flying saucer', 'ufo'],
  },
  {
    text: '🛎',
    name: 'bellhop bell',
    tags: ['bell', 'bellhop', 'hotel'],
  },
  {
    text: '🧳',
    name: 'luggage',
    tags: ['luggage', 'packing', 'travel'],
  },
  {
    text: '⌛',
    name: 'hourglass done',
    tags: ['hourglass done', 'sand', 'timer'],
  },
  {
    text: '⏳',
    name: 'hourglass not done',
    tags: ['hourglass', 'hourglass not done', 'sand', 'timer'],
  },
  {
    text: '⌚',
    name: 'watch',
    tags: ['clock', 'watch'],
  },
  {
    text: '⏰',
    name: 'alarm clock',
    tags: ['alarm', 'clock'],
  },
  {
    text: '⏱',
    name: 'stopwatch',
    tags: ['clock', 'stopwatch'],
  },
  {
    text: '⏲',
    name: 'timer clock',
    tags: ['clock', 'timer'],
  },
  {
    text: '🕰',
    name: 'mantelpiece clock',
    tags: ['clock', 'mantelpiece clock'],
  },
  {
    text: '🕛',
    name: 'twelve o’clock',
    tags: ['00', '12', '12:00', 'clock', 'o’clock', 'twelve'],
  },
  {
    text: '🕧',
    name: 'twelve-thirty',
    tags: ['12', '12:30', 'clock', 'thirty', 'twelve', 'twelve-thirty'],
  },
  {
    text: '🕐',
    name: 'one o’clock',
    tags: ['00', '1', '1:00', 'clock', 'o’clock', 'one'],
  },
  {
    text: '🕜',
    name: 'one-thirty',
    tags: ['1', '1:30', 'clock', 'one', 'one-thirty', 'thirty'],
  },
  {
    text: '🕑',
    name: 'two o’clock',
    tags: ['00', '2', '2:00', 'clock', 'o’clock', 'two'],
  },
  {
    text: '🕝',
    name: 'two-thirty',
    tags: ['2', '2:30', 'clock', 'thirty', 'two', 'two-thirty'],
  },
  {
    text: '🕒',
    name: 'three o’clock',
    tags: ['00', '3', '3:00', 'clock', 'o’clock', 'three'],
  },
  {
    text: '🕞',
    name: 'three-thirty',
    tags: ['3', '3:30', 'clock', 'thirty', 'three', 'three-thirty'],
  },
  {
    text: '🕓',
    name: 'four o’clock',
    tags: ['00', '4', '4:00', 'clock', 'four', 'o’clock'],
  },
  {
    text: '🕟',
    name: 'four-thirty',
    tags: ['4', '4:30', 'clock', 'four', 'four-thirty', 'thirty'],
  },
  {
    text: '🕔',
    name: 'five o’clock',
    tags: ['00', '5', '5:00', 'clock', 'five', 'o’clock'],
  },
  {
    text: '🕠',
    name: 'five-thirty',
    tags: ['5', '5:30', 'clock', 'five', 'five-thirty', 'thirty'],
  },
  {
    text: '🕕',
    name: 'six o’clock',
    tags: ['00', '6', '6:00', 'clock', 'o’clock', 'six'],
  },
  {
    text: '🕡',
    name: 'six-thirty',
    tags: ['6', '6:30', 'clock', 'six', 'six-thirty', 'thirty'],
  },
  {
    text: '🕖',
    name: 'seven o’clock',
    tags: ['00', '7', '7:00', 'clock', 'o’clock', 'seven'],
  },
  {
    text: '🕢',
    name: 'seven-thirty',
    tags: ['7', '7:30', 'clock', 'seven', 'seven-thirty', 'thirty'],
  },
  {
    text: '🕗',
    name: 'eight o’clock',
    tags: ['00', '8', '8:00', 'clock', 'eight', 'o’clock'],
  },
  {
    text: '🕣',
    name: 'eight-thirty',
    tags: ['8', '8:30', 'clock', 'eight', 'eight-thirty', 'thirty'],
  },
  {
    text: '🕘',
    name: 'nine o’clock',
    tags: ['00', '9', '9:00', 'clock', 'nine', 'o’clock'],
  },
  {
    text: '🕤',
    name: 'nine-thirty',
    tags: ['9', '9:30', 'clock', 'nine', 'nine-thirty', 'thirty'],
  },
  {
    text: '🕙',
    name: 'ten o’clock',
    tags: ['00', '10', '10:00', 'clock', 'o’clock', 'ten'],
  },
  {
    text: '🕥',
    name: 'ten-thirty',
    tags: ['10', '10:30', 'clock', 'ten', 'ten-thirty', 'thirty'],
  },
  {
    text: '🕚',
    name: 'eleven o’clock',
    tags: ['00', '11', '11:00', 'clock', 'eleven', 'o’clock'],
  },
  {
    text: '🕦',
    name: 'eleven-thirty',
    tags: ['11', '11:30', 'clock', 'eleven', 'eleven-thirty', 'thirty'],
  },
  {
    text: '🌑',
    name: 'new moon',
    tags: ['dark', 'moon', 'new moon'],
  },
  {
    text: '🌒',
    name: 'waxing crescent moon',
    tags: ['crescent', 'moon', 'waxing'],
  },
  {
    text: '🌓',
    name: 'first quarter moon',
    tags: ['first quarter moon', 'moon', 'quarter'],
  },
  {
    text: '🌔',
    name: 'waxing gibbous moon',
    tags: ['gibbous', 'moon', 'waxing'],
  },
  {
    text: '🌕',
    name: 'full moon',
    tags: ['full', 'moon'],
  },
  {
    text: '🌖',
    name: 'waning gibbous moon',
    tags: ['gibbous', 'moon', 'waning'],
  },
  {
    text: '🌗',
    name: 'last quarter moon',
    tags: ['last quarter moon', 'moon', 'quarter'],
  },
  {
    text: '🌘',
    name: 'waning crescent moon',
    tags: ['crescent', 'moon', 'waning'],
  },
  {
    text: '🌙',
    name: 'crescent moon',
    tags: ['crescent', 'moon'],
  },
  {
    text: '🌚',
    name: 'new moon face',
    tags: ['face', 'moon', 'new moon face'],
  },
  {
    text: '🌛',
    name: 'first quarter moon face',
    tags: ['face', 'first quarter moon face', 'moon', 'quarter'],
  },
  {
    text: '🌜',
    name: 'last quarter moon face',
    tags: ['face', 'last quarter moon face', 'moon', 'quarter'],
  },
  {
    text: '🌡',
    name: 'thermometer',
    tags: ['thermometer', 'weather'],
  },
  {
    text: '☀',
    name: 'sun',
    tags: ['bright', 'rays', 'sun', 'sunny'],
  },
  {
    text: '🌝',
    name: 'full moon face',
    tags: ['bright', 'face', 'full', 'moon'],
  },
  {
    text: '🌞',
    name: 'sun with face',
    tags: ['bright', 'face', 'sun', 'sun with face'],
  },
  {
    text: '🪐',
    name: 'ringed planet',
    tags: ['ringed planet', 'saturn', 'saturnine'],
  },
  {
    text: '⭐',
    name: 'star',
    tags: ['star'],
  },
  {
    text: '🌟',
    name: 'glowing star',
    tags: ['glittery', 'glow', 'glowing star', 'shining', 'sparkle', 'star'],
  },
  {
    text: '🌠',
    name: 'shooting star',
    tags: ['falling', 'shooting', 'star'],
  },
  {
    text: '🌌',
    name: 'milky way',
    tags: ['milky way', 'space'],
  },
  {
    text: '☁',
    name: 'cloud',
    tags: ['cloud', 'weather'],
  },
  {
    text: '⛅',
    name: 'sun behind cloud',
    tags: ['cloud', 'sun', 'sun behind cloud'],
  },
  {
    text: '⛈',
    name: 'cloud with lightning and rain',
    tags: ['cloud', 'cloud with lightning and rain', 'rain', 'thunder'],
  },
  {
    text: '🌤',
    name: 'sun behind small cloud',
    tags: ['cloud', 'sun', 'sun behind small cloud'],
  },
  {
    text: '🌥',
    name: 'sun behind large cloud',
    tags: ['cloud', 'sun', 'sun behind large cloud'],
  },
  {
    text: '🌦',
    name: 'sun behind rain cloud',
    tags: ['cloud', 'rain', 'sun', 'sun behind rain cloud'],
  },
  {
    text: '🌧',
    name: 'cloud with rain',
    tags: ['cloud', 'cloud with rain', 'rain'],
  },
  {
    text: '🌨',
    name: 'cloud with snow',
    tags: ['cloud', 'cloud with snow', 'cold', 'snow'],
  },
  {
    text: '🌩',
    name: 'cloud with lightning',
    tags: ['cloud', 'cloud with lightning', 'lightning'],
  },
  {
    text: '🌪',
    name: 'tornado',
    tags: ['cloud', 'tornado', 'whirlwind'],
  },
  {
    text: '🌫',
    name: 'fog',
    tags: ['cloud', 'fog'],
  },
  {
    text: '🌬',
    name: 'wind face',
    tags: ['blow', 'cloud', 'face', 'wind'],
  },
  {
    text: '🌀',
    name: 'cyclone',
    tags: ['cyclone', 'dizzy', 'hurricane', 'twister', 'typhoon'],
  },
  {
    text: '🌈',
    name: 'rainbow',
    tags: ['rain', 'rainbow'],
  },
  {
    text: '🌂',
    name: 'closed umbrella',
    tags: ['closed umbrella', 'clothing', 'rain', 'umbrella'],
  },
  {
    text: '☂',
    name: 'umbrella',
    tags: ['clothing', 'rain', 'umbrella'],
  },
  {
    text: '☔',
    name: 'umbrella with rain drops',
    tags: ['clothing', 'drop', 'rain', 'umbrella', 'umbrella with rain drops'],
  },
  {
    text: '⛱',
    name: 'umbrella on ground',
    tags: ['rain', 'sun', 'umbrella', 'umbrella on ground'],
  },
  {
    text: '⚡',
    name: 'high voltage',
    tags: ['danger', 'electric', 'high voltage', 'lightning', 'voltage', 'zap'],
  },
  {
    text: '❄',
    name: 'snowflake',
    tags: ['cold', 'snow', 'snowflake'],
  },
  {
    text: '☃',
    name: 'snowman',
    tags: ['cold', 'snow', 'snowman'],
  },
  {
    text: '⛄',
    name: 'snowman without snow',
    tags: ['cold', 'snow', 'snowman', 'snowman without snow'],
  },
  {
    text: '☄',
    name: 'comet',
    tags: ['comet', 'space'],
  },
  {
    text: '🔥',
    name: 'fire',
    tags: ['fire', 'flame', 'tool'],
  },
  {
    text: '💧',
    name: 'droplet',
    tags: ['cold', 'comic', 'drop', 'droplet', 'sweat'],
  },
  {
    text: '🌊',
    name: 'water wave',
    tags: ['ocean', 'water', 'wave'],
  },
  {
    text: '🎃',
    name: 'jack-o-lantern',
    tags: ['celebration', 'halloween', 'jack', 'jack-o-lantern', 'lantern'],
  },
  {
    text: '🎄',
    name: 'Christmas tree',
    tags: ['celebration', 'christmas', 'tree'],
  },
  {
    text: '🎆',
    name: 'fireworks',
    tags: ['celebration', 'fireworks'],
  },
  {
    text: '🎇',
    name: 'sparkler',
    tags: ['celebration', 'fireworks', 'sparkle', 'sparkler'],
  },
  {
    text: '🧨',
    name: 'firecracker',
    tags: ['dynamite', 'explosive', 'firecracker', 'fireworks'],
  },
  {
    text: '✨',
    name: 'sparkles',
    tags: ['*', 'sparkle', 'sparkles', 'star'],
  },
  {
    text: '🎈',
    name: 'balloon',
    tags: ['balloon', 'celebration'],
  },
  {
    text: '🎉',
    name: 'party popper',
    tags: ['celebration', 'party', 'popper', 'tada'],
  },
  {
    text: '🎊',
    name: 'confetti ball',
    tags: ['ball', 'celebration', 'confetti'],
  },
  {
    text: '🎋',
    name: 'tanabata tree',
    tags: ['banner', 'celebration', 'japanese', 'tanabata tree', 'tree'],
  },
  {
    text: '🎍',
    name: 'pine decoration',
    tags: ['bamboo', 'celebration', 'japanese', 'pine', 'pine decoration'],
  },
  {
    text: '🎎',
    name: 'Japanese dolls',
    tags: ['celebration', 'doll', 'festival', 'japanese', 'japanese dolls'],
  },
  {
    text: '🎏',
    name: 'carp streamer',
    tags: ['carp', 'celebration', 'streamer'],
  },
  {
    text: '🎐',
    name: 'wind chime',
    tags: ['bell', 'celebration', 'chime', 'wind'],
  },
  {
    text: '🎑',
    name: 'moon viewing ceremony',
    tags: ['celebration', 'ceremony', 'moon', 'moon viewing ceremony'],
  },
  {
    text: '🧧',
    name: 'red envelope',
    tags: ['gift', 'good luck', 'hóngbāo', 'lai see', 'money', 'red envelope'],
  },
  {
    text: '🎀',
    name: 'ribbon',
    tags: ['celebration', 'ribbon'],
  },
  {
    text: '🎁',
    name: 'wrapped gift',
    tags: ['box', 'celebration', 'gift', 'present', 'wrapped'],
  },
  {
    text: '🎗',
    name: 'reminder ribbon',
    tags: ['celebration', 'reminder', 'ribbon'],
  },
  {
    text: '🎟',
    name: 'admission tickets',
    tags: ['admission', 'admission tickets', 'ticket'],
  },
  {
    text: '🎫',
    name: 'ticket',
    tags: ['admission', 'ticket'],
  },
  {
    text: '🎖',
    name: 'military medal',
    tags: ['celebration', 'medal', 'military'],
  },
  {
    text: '🏆',
    name: 'trophy',
    tags: ['prize', 'trophy'],
  },
  {
    text: '🏅',
    name: 'sports medal',
    tags: ['medal', 'sports medal'],
  },
  {
    text: '🥇',
    name: '1st place medal',
    tags: ['1st place medal', 'first', 'gold', 'medal'],
  },
  {
    text: '🥈',
    name: '2nd place medal',
    tags: ['2nd place medal', 'medal', 'second', 'silver'],
  },
  {
    text: '🥉',
    name: '3rd place medal',
    tags: ['3rd place medal', 'bronze', 'medal', 'third'],
  },
  {
    text: '⚽',
    name: 'soccer ball',
    tags: ['ball', 'football', 'soccer'],
  },
  {
    text: '⚾',
    name: 'baseball',
    tags: ['ball', 'baseball'],
  },
  {
    text: '🥎',
    name: 'softball',
    tags: ['ball', 'glove', 'softball', 'underarm'],
  },
  {
    text: '🏀',
    name: 'basketball',
    tags: ['ball', 'basketball', 'hoop'],
  },
  {
    text: '🏐',
    name: 'volleyball',
    tags: ['ball', 'game', 'volleyball'],
  },
  {
    text: '🏈',
    name: 'american football',
    tags: ['american', 'ball', 'football'],
  },
  {
    text: '🏉',
    name: 'rugby football',
    tags: ['ball', 'football', 'rugby'],
  },
  {
    text: '🎾',
    name: 'tennis',
    tags: ['ball', 'racquet', 'tennis'],
  },
  {
    text: '🥏',
    name: 'flying disc',
    tags: ['flying disc', 'ultimate'],
  },
  {
    text: '🎳',
    name: 'bowling',
    tags: ['ball', 'bowling', 'game'],
  },
  {
    text: '🏏',
    name: 'cricket game',
    tags: ['ball', 'bat', 'cricket game', 'game'],
  },
  {
    text: '🏑',
    name: 'field hockey',
    tags: ['ball', 'field', 'game', 'hockey', 'stick'],
  },
  {
    text: '🏒',
    name: 'ice hockey',
    tags: ['game', 'hockey', 'ice', 'puck', 'stick'],
  },
  {
    text: '🥍',
    name: 'lacrosse',
    tags: ['ball', 'goal', 'lacrosse', 'stick'],
  },
  {
    text: '🏓',
    name: 'ping pong',
    tags: ['ball', 'bat', 'game', 'paddle', 'ping pong', 'table tennis'],
  },
  {
    text: '🏸',
    name: 'badminton',
    tags: ['badminton', 'birdie', 'game', 'racquet', 'shuttlecock'],
  },
  {
    text: '🥊',
    name: 'boxing glove',
    tags: ['boxing', 'glove'],
  },
  {
    text: '🥋',
    name: 'martial arts uniform',
    tags: [
      'judo',
      'karate',
      'martial arts',
      'martial arts uniform',
      'taekwondo',
      'uniform',
    ],
  },
  {
    text: '🥅',
    name: 'goal net',
    tags: ['goal', 'net'],
  },
  {
    text: '⛳',
    name: 'flag in hole',
    tags: ['flag in hole', 'golf', 'hole'],
  },
  {
    text: '⛸',
    name: 'ice skate',
    tags: ['ice', 'skate'],
  },
  {
    text: '🎣',
    name: 'fishing pole',
    tags: ['fish', 'fishing pole', 'pole'],
  },
  {
    text: '🤿',
    name: 'diving mask',
    tags: ['diving', 'diving mask', 'scuba', 'snorkeling'],
  },
  {
    text: '🎽',
    name: 'running shirt',
    tags: ['athletics', 'running', 'sash', 'shirt'],
  },
  {
    text: '🎿',
    name: 'skis',
    tags: ['ski', 'skis', 'snow'],
  },
  {
    text: '🛷',
    name: 'sled',
    tags: ['sled', 'sledge', 'sleigh', 'luge', 'toboggan'],
  },
  {
    text: '🥌',
    name: 'curling stone',
    tags: ['curling stone', 'game', 'rock'],
  },
  {
    text: '🎯',
    name: 'direct hit',
    tags: ['bullseye', 'dart', 'direct hit', 'game', 'hit', 'target'],
  },
  {
    text: '🪀',
    name: 'yo-yo',
    tags: ['fluctuate', 'toy', 'yo-yo'],
  },
  {
    text: '🪁',
    name: 'kite',
    tags: ['fly', 'kite', 'soar'],
  },
  {
    text: '🎱',
    name: 'pool 8 ball',
    tags: ['8', 'ball', 'billiard', 'eight', 'game', 'pool 8 ball'],
  },
  {
    text: '🔮',
    name: 'crystal ball',
    tags: ['ball', 'crystal', 'fairy tale', 'fantasy', 'fortune', 'tool'],
  },
  {
    text: '🧿',
    name: 'nazar amulet',
    tags: ['bead', 'charm', 'evil-eye', 'nazar', 'nazar amulet', 'talisman'],
  },
  {
    text: '🎮',
    name: 'video game',
    tags: ['controller', 'game', 'video game'],
  },
  {
    text: '🕹',
    name: 'joystick',
    tags: ['game', 'joystick', 'video game'],
  },
  {
    text: '🎰',
    name: 'slot machine',
    tags: ['game', 'slot', 'slot machine'],
  },
  {
    text: '🎲',
    name: 'game die',
    tags: ['dice', 'die', 'game'],
  },
  {
    text: '🧩',
    name: 'puzzle piece',
    tags: ['clue', 'interlocking', 'jigsaw', 'piece', 'puzzle'],
  },
  {
    text: '🧸',
    name: 'teddy bear',
    tags: ['plaything', 'plush', 'stuffed', 'teddy bear', 'toy'],
  },
  {
    text: '♠',
    name: 'spade suit',
    tags: ['card', 'game', 'spade suit'],
  },
  {
    text: '♥',
    name: 'heart suit',
    tags: ['card', 'game', 'heart suit'],
  },
  {
    text: '♦',
    name: 'diamond suit',
    tags: ['card', 'diamond suit', 'game'],
  },
  {
    text: '♣',
    name: 'club suit',
    tags: ['card', 'club suit', 'game'],
  },
  {
    text: '♟',
    name: 'chess pawn',
    tags: ['chess', 'chess pawn', 'dupe', 'expendable'],
  },
  {
    text: '🃏',
    name: 'joker',
    tags: ['card', 'game', 'joker', 'wildcard'],
  },
  {
    text: '🀄',
    name: 'mahjong red dragon',
    tags: ['game', 'mahjong', 'mahjong red dragon', 'red'],
  },
  {
    text: '🎴',
    name: 'flower playing cards',
    tags: [
      'card',
      'flower',
      'flower playing cards',
      'game',
      'japanese',
      'playing',
    ],
  },
  {
    text: '🎭',
    name: 'performing arts',
    tags: [
      'art',
      'mask',
      'performing',
      'performing arts',
      'theater',
      'theatre',
    ],
  },
  {
    text: '🖼',
    name: 'framed picture',
    tags: ['art', 'frame', 'framed picture', 'museum', 'painting', 'picture'],
  },
  {
    text: '🎨',
    name: 'artist palette',
    tags: ['art', 'artist palette', 'museum', 'painting', 'palette'],
  },
  {
    text: '🧵',
    name: 'thread',
    tags: ['needle', 'sewing', 'spool', 'string', 'thread'],
  },
  {
    text: '🧶',
    name: 'yarn',
    tags: ['ball', 'crochet', 'knit', 'yarn'],
  },
  {
    text: '👓',
    name: 'glasses',
    tags: ['clothing', 'eye', 'eyeglasses', 'eyewear', 'glasses'],
  },
  {
    text: '🕶',
    name: 'sunglasses',
    tags: ['dark', 'eye', 'eyewear', 'glasses', 'sunglasses'],
  },
  {
    text: '🥽',
    name: 'goggles',
    tags: ['eye protection', 'goggles', 'swimming', 'welding'],
  },
  {
    text: '🥼',
    name: 'lab coat',
    tags: ['doctor', 'experiment', 'lab coat', 'scientist'],
  },
  {
    text: '🦺',
    name: 'safety vest',
    tags: ['emergency', 'safety', 'vest'],
  },
  {
    text: '👔',
    name: 'necktie',
    tags: ['clothing', 'necktie', 'tie'],
  },
  {
    text: '👕',
    name: 't-shirt',
    tags: ['clothing', 'shirt', 't-shirt', 'tshirt'],
  },
  {
    text: '👖',
    name: 'jeans',
    tags: ['clothing', 'jeans', 'pants', 'trousers'],
  },
  {
    text: '🧣',
    name: 'scarf',
    tags: ['neck', 'scarf'],
  },
  {
    text: '🧤',
    name: 'gloves',
    tags: ['gloves', 'hand'],
  },
  {
    text: '🧥',
    name: 'coat',
    tags: ['coat', 'jacket'],
  },
  {
    text: '🧦',
    name: 'socks',
    tags: ['socks', 'stocking'],
  },
  {
    text: '👗',
    name: 'dress',
    tags: ['clothing', 'dress'],
  },
  {
    text: '👘',
    name: 'kimono',
    tags: ['clothing', 'kimono'],
  },
  {
    text: '🥻',
    name: 'sari',
    tags: ['clothing', 'dress', 'sari'],
  },
  {
    text: '🩱',
    name: 'one-piece swimsuit',
    tags: ['bathing suit', 'one-piece swimsuit'],
  },
  {
    text: '🩲',
    name: 'briefs',
    tags: ['bathing suit', 'briefs', 'one-piece', 'swimsuit', 'underwear'],
  },
  {
    text: '🩳',
    name: 'shorts',
    tags: ['bathing suit', 'pants', 'shorts', 'underwear'],
  },
  {
    text: '👙',
    name: 'bikini',
    tags: ['bikini', 'clothing', 'swim'],
  },
  {
    text: '👚',
    name: 'woman’s clothes',
    tags: ['clothing', 'woman', 'woman’s clothes'],
  },
  {
    text: '👛',
    name: 'purse',
    tags: ['clothing', 'coin', 'purse'],
  },
  {
    text: '👜',
    name: 'handbag',
    tags: ['bag', 'clothing', 'handbag', 'purse'],
  },
  {
    text: '👝',
    name: 'clutch bag',
    tags: ['bag', 'clothing', 'clutch bag', 'pouch'],
  },
  {
    text: '🛍',
    name: 'shopping bags',
    tags: ['bag', 'hotel', 'shopping', 'shopping bags'],
  },
  {
    text: '🎒',
    name: 'backpack',
    tags: ['backpack', 'bag', 'rucksack', 'satchel', 'school'],
  },
  {
    text: '👞',
    name: 'man’s shoe',
    tags: ['clothing', 'man', 'man’s shoe', 'shoe'],
  },
  {
    text: '👟',
    name: 'running shoe',
    tags: ['athletic', 'clothing', 'running shoe', 'shoe', 'sneaker'],
  },
  {
    text: '🥾',
    name: 'hiking boot',
    tags: ['backpacking', 'boot', 'camping', 'hiking'],
  },
  {
    text: '🥿',
    name: 'flat shoe',
    tags: ['ballet flat', 'flat shoe', 'slip-on', 'slipper'],
  },
  {
    text: '👠',
    name: 'high-heeled shoe',
    tags: ['clothing', 'heel', 'high-heeled shoe', 'shoe', 'woman'],
  },
  {
    text: '👡',
    name: 'woman’s sandal',
    tags: ['clothing', 'sandal', 'shoe', 'woman', 'woman’s sandal'],
  },
  {
    text: '🩰',
    name: 'ballet shoes',
    tags: ['ballet', 'ballet shoes', 'dance'],
  },
  {
    text: '👢',
    name: 'woman’s boot',
    tags: ['boot', 'clothing', 'shoe', 'woman', 'woman’s boot'],
  },
  {
    text: '👑',
    name: 'crown',
    tags: ['clothing', 'crown', 'king', 'queen'],
  },
  {
    text: '👒',
    name: 'woman’s hat',
    tags: ['clothing', 'hat', 'woman', 'woman’s hat'],
  },
  {
    text: '🎩',
    name: 'top hat',
    tags: ['clothing', 'hat', 'top', 'tophat'],
  },
  {
    text: '🎓',
    name: 'graduation cap',
    tags: ['cap', 'celebration', 'clothing', 'graduation', 'hat'],
  },
  {
    text: '🧢',
    name: 'billed cap',
    tags: ['baseball cap', 'billed cap'],
  },
  {
    text: '⛑',
    name: 'rescue worker’s helmet',
    tags: ['aid', 'cross', 'face', 'hat', 'helmet', 'rescue worker’s helmet'],
  },
  {
    text: '📿',
    name: 'prayer beads',
    tags: ['beads', 'clothing', 'necklace', 'prayer', 'religion'],
  },
  {
    text: '💄',
    name: 'lipstick',
    tags: ['cosmetics', 'lipstick', 'makeup'],
  },
  {
    text: '💍',
    name: 'ring',
    tags: ['diamond', 'ring'],
  },
  {
    text: '💎',
    name: 'gem stone',
    tags: ['diamond', 'gem', 'gem stone', 'jewel'],
  },
  {
    text: '🔇',
    name: 'muted speaker',
    tags: ['mute', 'muted speaker', 'quiet', 'silent', 'speaker'],
  },
  {
    text: '🔈',
    name: 'speaker low volume',
    tags: ['soft', 'speaker low volume'],
  },
  {
    text: '🔉',
    name: 'speaker medium volume',
    tags: ['medium', 'speaker medium volume'],
  },
  {
    text: '🔊',
    name: 'speaker high volume',
    tags: ['loud', 'speaker high volume'],
  },
  {
    text: '📢',
    name: 'loudspeaker',
    tags: ['loud', 'loudspeaker', 'public address'],
  },
  {
    text: '📣',
    name: 'megaphone',
    tags: ['cheering', 'megaphone'],
  },
  {
    text: '📯',
    name: 'postal horn',
    tags: ['horn', 'post', 'postal'],
  },
  {
    text: '🔔',
    name: 'bell',
    tags: ['bell'],
  },
  {
    text: '🔕',
    name: 'bell with slash',
    tags: ['bell', 'bell with slash', 'forbidden', 'mute', 'quiet', 'silent'],
  },
  {
    text: '🎼',
    name: 'musical score',
    tags: ['music', 'musical score', 'score'],
  },
  {
    text: '🎵',
    name: 'musical note',
    tags: ['music', 'musical note', 'note'],
  },
  {
    text: '🎶',
    name: 'musical notes',
    tags: ['music', 'musical notes', 'note', 'notes'],
  },
  {
    text: '🎙',
    name: 'studio microphone',
    tags: ['mic', 'microphone', 'music', 'studio'],
  },
  {
    text: '🎚',
    name: 'level slider',
    tags: ['level', 'music', 'slider'],
  },
  {
    text: '🎛',
    name: 'control knobs',
    tags: ['control', 'knobs', 'music'],
  },
  {
    text: '🎤',
    name: 'microphone',
    tags: ['karaoke', 'mic', 'microphone'],
  },
  {
    text: '🎧',
    name: 'headphone',
    tags: ['earbud', 'headphone'],
  },
  {
    text: '📻',
    name: 'radio',
    tags: ['radio', 'video'],
  },
  {
    text: '🎷',
    name: 'saxophone',
    tags: ['instrument', 'music', 'sax', 'saxophone'],
  },
  {
    text: '🎸',
    name: 'guitar',
    tags: ['guitar', 'instrument', 'music'],
  },
  {
    text: '🎹',
    name: 'musical keyboard',
    tags: ['instrument', 'keyboard', 'music', 'musical keyboard', 'piano'],
  },
  {
    text: '🎺',
    name: 'trumpet',
    tags: ['instrument', 'music', 'trumpet'],
  },
  {
    text: '🎻',
    name: 'violin',
    tags: ['instrument', 'music', 'violin'],
  },
  {
    text: '🪕',
    name: 'banjo',
    tags: ['banjo', 'music', 'stringed'],
  },
  {
    text: '🥁',
    name: 'drum',
    tags: ['drum', 'drumsticks', 'music'],
  },
  {
    text: '📱',
    name: 'mobile phone',
    tags: ['cell', 'mobile', 'phone', 'telephone'],
  },
  {
    text: '📲',
    name: 'mobile phone with arrow',
    tags: [
      'arrow',
      'cell',
      'mobile',
      'mobile phone with arrow',
      'phone',
      'receive',
    ],
  },
  {
    text: '☎',
    name: 'telephone',
    tags: ['phone', 'telephone'],
  },
  {
    text: '📞',
    name: 'telephone receiver',
    tags: ['phone', 'receiver', 'telephone'],
  },
  {
    text: '📟',
    name: 'pager',
    tags: ['pager'],
  },
  {
    text: '📠',
    name: 'fax machine',
    tags: ['fax', 'fax machine'],
  },
  {
    text: '🔋',
    name: 'battery',
    tags: ['battery'],
  },
  {
    text: '🔌',
    name: 'electric plug',
    tags: ['electric', 'electricity', 'plug'],
  },
  {
    text: '💻',
    name: 'laptop',
    tags: ['computer', 'laptop', 'pc', 'personal'],
  },
  {
    text: '🖥',
    name: 'desktop computer',
    tags: ['computer', 'desktop'],
  },
  {
    text: '🖨',
    name: 'printer',
    tags: ['computer', 'printer'],
  },
  {
    text: '⌨',
    name: 'keyboard',
    tags: ['computer', 'keyboard'],
  },
  {
    text: '🖱',
    name: 'computer mouse',
    tags: ['computer', 'computer mouse'],
  },
  {
    text: '🖲',
    name: 'trackball',
    tags: ['computer', 'trackball'],
  },
  {
    text: '💽',
    name: 'computer disk',
    tags: ['computer', 'disk', 'minidisk', 'optical'],
  },
  {
    text: '💾',
    name: 'floppy disk',
    tags: ['computer', 'disk', 'floppy'],
  },
  {
    text: '💿',
    name: 'optical disk',
    tags: ['cd', 'computer', 'disk', 'optical'],
  },
  {
    text: '📀',
    name: 'dvd',
    tags: ['blu-ray', 'computer', 'disk', 'dvd', 'optical'],
  },
  {
    text: '🧮',
    name: 'abacus',
    tags: ['abacus', 'calculation'],
  },
  {
    text: '🎥',
    name: 'movie camera',
    tags: ['camera', 'cinema', 'movie'],
  },
  {
    text: '🎞',
    name: 'film frames',
    tags: ['cinema', 'film', 'frames', 'movie'],
  },
  {
    text: '📽',
    name: 'film projector',
    tags: ['cinema', 'film', 'movie', 'projector', 'video'],
  },
  {
    text: '🎬',
    name: 'clapper board',
    tags: ['clapper', 'clapper board', 'movie'],
  },
  {
    text: '📺',
    name: 'television',
    tags: ['television', 'tv', 'video'],
  },
  {
    text: '📷',
    name: 'camera',
    tags: ['camera', 'video'],
  },
  {
    text: '📸',
    name: 'camera with flash',
    tags: ['camera', 'camera with flash', 'flash', 'video'],
  },
  {
    text: '📹',
    name: 'video camera',
    tags: ['camera', 'video'],
  },
  {
    text: '📼',
    name: 'videocassette',
    tags: ['tape', 'vhs', 'video', 'videocassette'],
  },
  {
    text: '🔍',
    name: 'magnifying glass tilted left',
    tags: [
      'glass',
      'magnifying',
      'magnifying glass tilted left',
      'search',
      'tool',
    ],
  },
  {
    text: '🔎',
    name: 'magnifying glass tilted right',
    tags: [
      'glass',
      'magnifying',
      'magnifying glass tilted right',
      'search',
      'tool',
    ],
  },
  {
    text: '🕯',
    name: 'candle',
    tags: ['candle', 'light'],
  },
  {
    text: '💡',
    name: 'light bulb',
    tags: ['bulb', 'comic', 'electric', 'idea', 'light'],
  },
  {
    text: '🔦',
    name: 'flashlight',
    tags: ['electric', 'flashlight', 'light', 'tool', 'torch'],
  },
  {
    text: '🏮',
    name: 'red paper lantern',
    tags: ['bar', 'lantern', 'light', 'red', 'red paper lantern'],
  },
  {
    text: '🪔',
    name: 'diya lamp',
    tags: ['diya', 'lamp', 'oil'],
  },
  {
    text: '📔',
    name: 'notebook with decorative cover',
    tags: [
      'book',
      'cover',
      'decorated',
      'notebook',
      'notebook with decorative cover',
    ],
  },
  {
    text: '📕',
    name: 'closed book',
    tags: ['book', 'closed'],
  },
  {
    text: '📖',
    name: 'open book',
    tags: ['book', 'open'],
  },
  {
    text: '📗',
    name: 'green book',
    tags: ['book', 'green'],
  },
  {
    text: '📘',
    name: 'blue book',
    tags: ['blue', 'book'],
  },
  {
    text: '📙',
    name: 'orange book',
    tags: ['book', 'orange'],
  },
  {
    text: '📚',
    name: 'books',
    tags: ['book', 'books'],
  },
  {
    text: '📓',
    name: 'notebook',
    tags: ['notebook'],
  },
  {
    text: '📒',
    name: 'ledger',
    tags: ['ledger', 'notebook'],
  },
  {
    text: '📃',
    name: 'page with curl',
    tags: ['curl', 'document', 'page', 'page with curl'],
  },
  {
    text: '📜',
    name: 'scroll',
    tags: ['paper', 'scroll'],
  },
  {
    text: '📄',
    name: 'page facing up',
    tags: ['document', 'page', 'page facing up'],
  },
  {
    text: '📰',
    name: 'newspaper',
    tags: ['news', 'newspaper', 'paper'],
  },
  {
    text: '🗞',
    name: 'rolled-up newspaper',
    tags: ['news', 'newspaper', 'paper', 'rolled', 'rolled-up newspaper'],
  },
  {
    text: '📑',
    name: 'bookmark tabs',
    tags: ['bookmark', 'mark', 'marker', 'tabs'],
  },
  {
    text: '🔖',
    name: 'bookmark',
    tags: ['bookmark', 'mark'],
  },
  {
    text: '🏷',
    name: 'label',
    tags: ['label'],
  },
  {
    text: '💰',
    name: 'money bag',
    tags: ['bag', 'dollar', 'money', 'moneybag'],
  },
  {
    text: '💴',
    name: 'yen banknote',
    tags: ['banknote', 'bill', 'currency', 'money', 'note', 'yen'],
  },
  {
    text: '💵',
    name: 'dollar banknote',
    tags: ['banknote', 'bill', 'currency', 'dollar', 'money', 'note'],
  },
  {
    text: '💶',
    name: 'euro banknote',
    tags: ['banknote', 'bill', 'currency', 'euro', 'money', 'note'],
  },
  {
    text: '💷',
    name: 'pound banknote',
    tags: ['banknote', 'bill', 'currency', 'money', 'note', 'pound'],
  },
  {
    text: '💸',
    name: 'money with wings',
    tags: ['banknote', 'bill', 'fly', 'money', 'money with wings', 'wings'],
  },
  {
    text: '💳',
    name: 'credit card',
    tags: ['card', 'credit', 'money'],
  },
  {
    text: '🧾',
    name: 'receipt',
    tags: ['accounting', 'bookkeeping', 'evidence', 'proof', 'receipt'],
  },
  {
    text: '💹',
    name: 'chart increasing with yen',
    tags: [
      'chart',
      'chart increasing with yen',
      'graph',
      'growth',
      'money',
      'yen',
    ],
  },
  {
    text: '💱',
    name: 'currency exchange',
    tags: ['bank', 'currency', 'exchange', 'money'],
  },
  {
    text: '💲',
    name: 'heavy dollar sign',
    tags: ['currency', 'dollar', 'heavy dollar sign', 'money'],
  },
  {
    text: '✉',
    name: 'envelope',
    tags: ['email', 'envelope', 'letter'],
  },
  {
    text: '📧',
    name: 'e-mail',
    tags: ['e-mail', 'email', 'letter', 'mail'],
  },
  {
    text: '📨',
    name: 'incoming envelope',
    tags: ['e-mail', 'email', 'envelope', 'incoming', 'letter', 'receive'],
  },
  {
    text: '📩',
    name: 'envelope with arrow',
    tags: [
      'arrow',
      'e-mail',
      'email',
      'envelope',
      'envelope with arrow',
      'outgoing',
    ],
  },
  {
    text: '📤',
    name: 'outbox tray',
    tags: ['box', 'letter', 'mail', 'outbox', 'sent', 'tray'],
  },
  {
    text: '📥',
    name: 'inbox tray',
    tags: ['box', 'inbox', 'letter', 'mail', 'receive', 'tray'],
  },
  {
    text: '📦',
    name: 'package',
    tags: ['box', 'package', 'parcel'],
  },
  {
    text: '📫',
    name: 'closed mailbox with raised flag',
    tags: [
      'closed',
      'closed mailbox with raised flag',
      'mail',
      'mailbox',
      'postbox',
    ],
  },
  {
    text: '📪',
    name: 'closed mailbox with lowered flag',
    tags: [
      'closed',
      'closed mailbox with lowered flag',
      'lowered',
      'mail',
      'mailbox',
      'postbox',
    ],
  },
  {
    text: '📬',
    name: 'open mailbox with raised flag',
    tags: [
      'mail',
      'mailbox',
      'open',
      'open mailbox with raised flag',
      'postbox',
    ],
  },
  {
    text: '📭',
    name: 'open mailbox with lowered flag',
    tags: [
      'lowered',
      'mail',
      'mailbox',
      'open',
      'open mailbox with lowered flag',
      'postbox',
    ],
  },
  {
    text: '📮',
    name: 'postbox',
    tags: ['mail', 'mailbox', 'postbox'],
  },
  {
    text: '🗳',
    name: 'ballot box with ballot',
    tags: ['ballot', 'ballot box with ballot', 'box'],
  },
  {
    text: '✏',
    name: 'pencil',
    tags: ['pencil'],
  },
  {
    text: '✒',
    name: 'black nib',
    tags: ['black nib', 'nib', 'pen'],
  },
  {
    text: '🖋',
    name: 'fountain pen',
    tags: ['fountain', 'pen'],
  },
  {
    text: '🖊',
    name: 'pen',
    tags: ['ballpoint', 'pen'],
  },
  {
    text: '🖌',
    name: 'paintbrush',
    tags: ['paintbrush', 'painting'],
  },
  {
    text: '🖍',
    name: 'crayon',
    tags: ['crayon'],
  },
  {
    text: '📝',
    name: 'memo',
    tags: ['memo', 'pencil'],
  },
  {
    text: '💼',
    name: 'briefcase',
    tags: ['briefcase'],
  },
  {
    text: '📁',
    name: 'file folder',
    tags: ['file', 'folder'],
  },
  {
    text: '📂',
    name: 'open file folder',
    tags: ['file', 'folder', 'open'],
  },
  {
    text: '🗂',
    name: 'card index dividers',
    tags: ['card', 'dividers', 'index'],
  },
  {
    text: '📅',
    name: 'calendar',
    tags: ['calendar', 'date'],
  },
  {
    text: '📆',
    name: 'tear-off calendar',
    tags: ['calendar', 'tear-off calendar'],
  },
  {
    text: '🗒',
    name: 'spiral notepad',
    tags: ['note', 'pad', 'spiral', 'spiral notepad'],
  },
  {
    text: '🗓',
    name: 'spiral calendar',
    tags: ['calendar', 'pad', 'spiral'],
  },
  {
    text: '📇',
    name: 'card index',
    tags: ['card', 'index', 'rolodex'],
  },
  {
    text: '📈',
    name: 'chart increasing',
    tags: ['chart', 'chart increasing', 'graph', 'growth', 'trend', 'upward'],
  },
  {
    text: '📉',
    name: 'chart decreasing',
    tags: ['chart', 'chart decreasing', 'down', 'graph', 'trend'],
  },
  {
    text: '📊',
    name: 'bar chart',
    tags: ['bar', 'chart', 'graph'],
  },
  {
    text: '📋',
    name: 'clipboard',
    tags: ['clipboard'],
  },
  {
    text: '📌',
    name: 'pushpin',
    tags: ['pin', 'pushpin'],
  },
  {
    text: '📍',
    name: 'round pushpin',
    tags: ['pin', 'pushpin', 'round pushpin'],
  },
  {
    text: '📎',
    name: 'paperclip',
    tags: ['paperclip'],
  },
  {
    text: '🖇',
    name: 'linked paperclips',
    tags: ['link', 'linked paperclips', 'paperclip'],
  },
  {
    text: '📏',
    name: 'straight ruler',
    tags: ['ruler', 'straight edge', 'straight ruler'],
  },
  {
    text: '📐',
    name: 'triangular ruler',
    tags: ['ruler', 'set', 'triangle', 'triangular ruler'],
  },
  {
    text: '✂',
    name: 'scissors',
    tags: ['cutting', 'scissors', 'tool'],
  },
  {
    text: '🗃',
    name: 'card file box',
    tags: ['box', 'card', 'file'],
  },
  {
    text: '🗄',
    name: 'file cabinet',
    tags: ['cabinet', 'file', 'filing'],
  },
  {
    text: '🗑',
    name: 'wastebasket',
    tags: ['wastebasket'],
  },
  {
    text: '🔒',
    name: 'locked',
    tags: ['closed', 'locked'],
  },
  {
    text: '🔓',
    name: 'unlocked',
    tags: ['lock', 'open', 'unlock', 'unlocked'],
  },
  {
    text: '🔏',
    name: 'locked with pen',
    tags: ['ink', 'lock', 'locked with pen', 'nib', 'pen', 'privacy'],
  },
  {
    text: '🔐',
    name: 'locked with key',
    tags: ['closed', 'key', 'lock', 'locked with key', 'secure'],
  },
  {
    text: '🔑',
    name: 'key',
    tags: ['key', 'lock', 'password'],
  },
  {
    text: '🗝',
    name: 'old key',
    tags: ['clue', 'key', 'lock', 'old'],
  },
  {
    text: '🔨',
    name: 'hammer',
    tags: ['hammer', 'tool'],
  },
  {
    text: '🪓',
    name: 'axe',
    tags: ['axe', 'chop', 'hatchet', 'split', 'wood'],
  },
  {
    text: '⛏',
    name: 'pick',
    tags: ['mining', 'pick', 'tool'],
  },
  {
    text: '⚒',
    name: 'hammer and pick',
    tags: ['hammer', 'hammer and pick', 'pick', 'tool'],
  },
  {
    text: '🛠',
    name: 'hammer and wrench',
    tags: ['hammer', 'hammer and wrench', 'spanner', 'tool', 'wrench'],
  },
  {
    text: '🗡',
    name: 'dagger',
    tags: ['dagger', 'knife', 'weapon'],
  },
  {
    text: '⚔',
    name: 'crossed swords',
    tags: ['crossed', 'swords', 'weapon'],
  },
  {
    text: '🔫',
    name: 'pistol',
    tags: ['gun', 'handgun', 'pistol', 'revolver', 'tool', 'weapon'],
  },
  {
    text: '🏹',
    name: 'bow and arrow',
    tags: ['archer', 'arrow', 'bow', 'bow and arrow', 'sagittarius', 'zodiac'],
  },
  {
    text: '🛡',
    name: 'shield',
    tags: ['shield', 'weapon'],
  },
  {
    text: '🔧',
    name: 'wrench',
    tags: ['spanner', 'tool', 'wrench'],
  },
  {
    text: '🔩',
    name: 'nut and bolt',
    tags: ['bolt', 'nut', 'nut and bolt', 'tool'],
  },
  {
    text: '⚙',
    name: 'gear',
    tags: ['cog', 'cogwheel', 'gear', 'tool'],
  },
  {
    text: '🗜',
    name: 'clamp',
    tags: ['clamp', 'compress', 'tool', 'vice'],
  },
  {
    text: '⚖',
    name: 'balance scale',
    tags: ['balance', 'justice', 'libra', 'scale', 'zodiac'],
  },
  {
    text: '🦯',
    name: 'probing cane',
    tags: ['accessibility', 'blind', 'probing cane'],
  },
  {
    text: '🔗',
    name: 'link',
    tags: ['link'],
  },
  {
    text: '⛓',
    name: 'chains',
    tags: ['chain', 'chains'],
  },
  {
    text: '🧰',
    name: 'toolbox',
    tags: ['chest', 'mechanic', 'tool', 'toolbox'],
  },
  {
    text: '🧲',
    name: 'magnet',
    tags: ['attraction', 'horseshoe', 'magnet', 'magnetic'],
  },
  {
    text: '⚗',
    name: 'alembic',
    tags: ['alembic', 'chemistry', 'tool'],
  },
  {
    text: '🧪',
    name: 'test tube',
    tags: ['chemist', 'chemistry', 'experiment', 'lab', 'science', 'test tube'],
  },
  {
    text: '🧫',
    name: 'petri dish',
    tags: ['bacteria', 'biologist', 'biology', 'culture', 'lab', 'petri dish'],
  },
  {
    text: '🧬',
    name: 'dna',
    tags: ['biologist', 'dna', 'evolution', 'gene', 'genetics', 'life'],
  },
  {
    text: '🔬',
    name: 'microscope',
    tags: ['microscope', 'science', 'tool'],
  },
  {
    text: '🔭',
    name: 'telescope',
    tags: ['science', 'telescope', 'tool'],
  },
  {
    text: '📡',
    name: 'satellite antenna',
    tags: ['antenna', 'dish', 'satellite'],
  },
  {
    text: '💉',
    name: 'syringe',
    tags: ['medicine', 'needle', 'shot', 'sick', 'syringe'],
  },
  {
    text: '🩸',
    name: 'drop of blood',
    tags: [
      'bleed',
      'blood donation',
      'drop of blood',
      'injury',
      'medicine',
      'menstruation',
    ],
  },
  {
    text: '💊',
    name: 'pill',
    tags: ['doctor', 'medicine', 'pill', 'sick'],
  },
  {
    text: '🩹',
    name: 'adhesive bandage',
    tags: ['adhesive bandage', 'bandage'],
  },
  {
    text: '🩺',
    name: 'stethoscope',
    tags: ['doctor', 'heart', 'medicine', 'stethoscope'],
  },
  {
    text: '🚪',
    name: 'door',
    tags: ['door'],
  },
  {
    text: '🛏',
    name: 'bed',
    tags: ['bed', 'hotel', 'sleep'],
  },
  {
    text: '🛋',
    name: 'couch and lamp',
    tags: ['couch', 'couch and lamp', 'hotel', 'lamp'],
  },
  {
    text: '🪑',
    name: 'chair',
    tags: ['chair', 'seat', 'sit'],
  },
  {
    text: '🚽',
    name: 'toilet',
    tags: ['toilet'],
  },
  {
    text: '🚿',
    name: 'shower',
    tags: ['shower', 'water'],
  },
  {
    text: '🛁',
    name: 'bathtub',
    tags: ['bath', 'bathtub'],
  },
  {
    text: '🪒',
    name: 'razor',
    tags: ['razor', 'sharp', 'shave'],
  },
  {
    text: '🧴',
    name: 'lotion bottle',
    tags: ['lotion', 'lotion bottle', 'moisturizer', 'shampoo', 'sunscreen'],
  },
  {
    text: '🧷',
    name: 'safety pin',
    tags: ['diaper', 'punk rock', 'safety pin'],
  },
  {
    text: '🧹',
    name: 'broom',
    tags: ['broom', 'cleaning', 'sweeping', 'witch'],
  },
  {
    text: '🧺',
    name: 'basket',
    tags: ['basket', 'farming', 'laundry', 'picnic'],
  },
  {
    text: '🧻',
    name: 'roll of paper',
    tags: ['paper towels', 'roll of paper', 'toilet paper'],
  },
  {
    text: '🧼',
    name: 'soap',
    tags: ['bar', 'bathing', 'cleaning', 'lather', 'soap', 'soapdish'],
  },
  {
    text: '🧽',
    name: 'sponge',
    tags: ['absorbing', 'cleaning', 'porous', 'sponge'],
  },
  {
    text: '🧯',
    name: 'fire extinguisher',
    tags: ['extinguish', 'fire', 'fire extinguisher', 'quench'],
  },
  {
    text: '🛒',
    name: 'shopping cart',
    tags: ['cart', 'shopping', 'trolley'],
  },
  {
    text: '🚬',
    name: 'cigarette',
    tags: ['cigarette', 'smoking'],
  },
  {
    text: '⚰',
    name: 'coffin',
    tags: ['coffin', 'death'],
  },
  {
    text: '⚱',
    name: 'funeral urn',
    tags: ['ashes', 'death', 'funeral', 'urn'],
  },
  {
    text: '🗿',
    name: 'moai',
    tags: ['face', 'moai', 'moyai', 'statue'],
  },
  {
    text: '🏧',
    name: 'ATM sign',
    tags: ['atm', 'atm sign', 'automated', 'bank', 'teller'],
  },
  {
    text: '🚮',
    name: 'litter in bin sign',
    tags: ['litter', 'litter bin', 'litter in bin sign'],
  },
  {
    text: '🚰',
    name: 'potable water',
    tags: ['drinking', 'potable', 'water'],
  },
  {
    text: '♿',
    name: 'wheelchair symbol',
    tags: ['access', 'wheelchair symbol'],
  },
  {
    text: '🚹',
    name: 'men’s room',
    tags: ['lavatory', 'man', 'men’s room', 'restroom', 'wc'],
  },
  {
    text: '🚺',
    name: 'women’s room',
    tags: ['lavatory', 'restroom', 'wc', 'woman', 'women’s room'],
  },
  {
    text: '🚻',
    name: 'restroom',
    tags: ['lavatory', 'restroom', 'wc'],
  },
  {
    text: '🚼',
    name: 'baby symbol',
    tags: ['baby', 'baby symbol', 'changing'],
  },
  {
    text: '🚾',
    name: 'water closet',
    tags: ['closet', 'lavatory', 'restroom', 'water', 'wc'],
  },
  {
    text: '🛂',
    name: 'passport control',
    tags: ['control', 'passport'],
  },
  {
    text: '🛃',
    name: 'customs',
    tags: ['customs'],
  },
  {
    text: '🛄',
    name: 'baggage claim',
    tags: ['baggage', 'claim'],
  },
  {
    text: '🛅',
    name: 'left luggage',
    tags: ['baggage', 'left luggage', 'locker', 'luggage'],
  },
  {
    text: '⚠',
    name: 'warning',
    tags: ['warning'],
  },
  {
    text: '🚸',
    name: 'children crossing',
    tags: ['child', 'children crossing', 'crossing', 'pedestrian', 'traffic'],
  },
  {
    text: '⛔',
    name: 'no entry',
    tags: ['entry', 'forbidden', 'no', 'not', 'prohibited', 'traffic'],
  },
  {
    text: '🚫',
    name: 'prohibited',
    tags: ['entry', 'forbidden', 'no', 'not', 'prohibited'],
  },
  {
    text: '🚳',
    name: 'no bicycles',
    tags: ['bicycle', 'bike', 'forbidden', 'no', 'no bicycles', 'prohibited'],
  },
  {
    text: '🚭',
    name: 'no smoking',
    tags: ['forbidden', 'no', 'not', 'prohibited', 'smoking'],
  },
  {
    text: '🚯',
    name: 'no littering',
    tags: ['forbidden', 'litter', 'no', 'no littering', 'not', 'prohibited'],
  },
  {
    text: '🚱',
    name: 'non-potable water',
    tags: ['non-drinking', 'non-potable', 'water'],
  },
  {
    text: '🚷',
    name: 'no pedestrians',
    tags: [
      'forbidden',
      'no',
      'no pedestrians',
      'not',
      'pedestrian',
      'prohibited',
    ],
  },
  {
    text: '📵',
    name: 'no mobile phones',
    tags: ['cell', 'forbidden', 'mobile', 'no', 'no mobile phones', 'phone'],
  },
  {
    text: '🔞',
    name: 'no one under eighteen',
    tags: [
      '18',
      'age restriction',
      'eighteen',
      'no one under eighteen',
      'prohibited',
      'underage',
    ],
  },
  {
    text: '☢',
    name: 'radioactive',
    tags: ['radioactive', 'sign'],
  },
  {
    text: '☣',
    name: 'biohazard',
    tags: ['biohazard', 'sign'],
  },
  {
    text: '⬆',
    name: 'up arrow',
    tags: ['arrow', 'cardinal', 'direction', 'north', 'up arrow'],
  },
  {
    text: '↗',
    name: 'up-right arrow',
    tags: [
      'arrow',
      'direction',
      'intercardinal',
      'northeast',
      'up-right arrow',
    ],
  },
  {
    text: '➡',
    name: 'right arrow',
    tags: ['arrow', 'cardinal', 'direction', 'east', 'right arrow'],
  },
  {
    text: '↘',
    name: 'down-right arrow',
    tags: [
      'arrow',
      'direction',
      'down-right arrow',
      'intercardinal',
      'southeast',
    ],
  },
  {
    text: '⬇',
    name: 'down arrow',
    tags: ['arrow', 'cardinal', 'direction', 'down', 'south'],
  },
  {
    text: '↙',
    name: 'down-left arrow',
    tags: [
      'arrow',
      'direction',
      'down-left arrow',
      'intercardinal',
      'southwest',
    ],
  },
  {
    text: '⬅',
    name: 'left arrow',
    tags: ['arrow', 'cardinal', 'direction', 'left arrow', 'west'],
  },
  {
    text: '↖',
    name: 'up-left arrow',
    tags: ['arrow', 'direction', 'intercardinal', 'northwest', 'up-left arrow'],
  },
  {
    text: '↕',
    name: 'up-down arrow',
    tags: ['arrow', 'up-down arrow'],
  },
  {
    text: '↔',
    name: 'left-right arrow',
    tags: ['arrow', 'left-right arrow'],
  },
  {
    text: '↩',
    name: 'right arrow curving left',
    tags: ['arrow', 'right arrow curving left'],
  },
  {
    text: '↪',
    name: 'left arrow curving right',
    tags: ['arrow', 'left arrow curving right'],
  },
  {
    text: '⤴',
    name: 'right arrow curving up',
    tags: ['arrow', 'right arrow curving up'],
  },
  {
    text: '⤵',
    name: 'right arrow curving down',
    tags: ['arrow', 'down', 'right arrow curving down'],
  },
  {
    text: '🔃',
    name: 'clockwise vertical arrows',
    tags: ['arrow', 'clockwise', 'clockwise vertical arrows', 'reload'],
  },
  {
    text: '🔄',
    name: 'counterclockwise arrows button',
    tags: [
      'anticlockwise',
      'arrow',
      'counterclockwise',
      'counterclockwise arrows button',
      'withershins',
    ],
  },
  {
    text: '🔙',
    name: 'BACK arrow',
    tags: ['arrow', 'back', 'back arrow'],
  },
  {
    text: '🔚',
    name: 'END arrow',
    tags: ['arrow', 'end', 'end arrow'],
  },
  {
    text: '🔛',
    name: 'ON! arrow',
    tags: ['arrow', 'mark', 'on', 'on! arrow'],
  },
  {
    text: '🔜',
    name: 'SOON arrow',
    tags: ['arrow', 'soon', 'soon arrow'],
  },
  {
    text: '🔝',
    name: 'TOP arrow',
    tags: ['arrow', 'top', 'top arrow', 'up'],
  },
  {
    text: '🛐',
    name: 'place of worship',
    tags: ['place of worship', 'religion', 'worship'],
  },
  {
    text: '⚛',
    name: 'atom symbol',
    tags: ['atheist', 'atom', 'atom symbol'],
  },
  {
    text: '🕉',
    name: 'om',
    tags: ['hindu', 'om', 'religion'],
  },
  {
    text: '✡',
    name: 'star of David',
    tags: ['david', 'jew', 'jewish', 'religion', 'star', 'star of david'],
  },
  {
    text: '☸',
    name: 'wheel of dharma',
    tags: ['buddhist', 'dharma', 'religion', 'wheel', 'wheel of dharma'],
  },
  {
    text: '☯',
    name: 'yin yang',
    tags: ['religion', 'tao', 'taoist', 'yang', 'yin'],
  },
  {
    text: '✝',
    name: 'latin cross',
    tags: ['christian', 'cross', 'latin cross', 'religion'],
  },
  {
    text: '☦',
    name: 'orthodox cross',
    tags: ['christian', 'cross', 'orthodox cross', 'religion'],
  },
  {
    text: '☪',
    name: 'star and crescent',
    tags: ['islam', 'muslim', 'religion', 'star and crescent'],
  },
  {
    text: '☮',
    name: 'peace symbol',
    tags: ['peace', 'peace symbol'],
  },
  {
    text: '🕎',
    name: 'menorah',
    tags: ['candelabrum', 'candlestick', 'menorah', 'religion'],
  },
  {
    text: '🔯',
    name: 'dotted six-pointed star',
    tags: ['dotted six-pointed star', 'fortune', 'star'],
  },
  {
    text: '♈',
    name: 'Aries',
    tags: ['aries', 'ram', 'zodiac'],
  },
  {
    text: '♉',
    name: 'Taurus',
    tags: ['bull', 'ox', 'taurus', 'zodiac'],
  },
  {
    text: '♊',
    name: 'Gemini',
    tags: ['gemini', 'twins', 'zodiac'],
  },
  {
    text: '♋',
    name: 'Cancer',
    tags: ['cancer', 'crab', 'zodiac'],
  },
  {
    text: '♌',
    name: 'Leo',
    tags: ['leo', 'lion', 'zodiac'],
  },
  {
    text: '♍',
    name: 'Virgo',
    tags: ['virgo', 'zodiac'],
  },
  {
    text: '♎',
    name: 'Libra',
    tags: ['balance', 'justice', 'libra', 'scales', 'zodiac'],
  },
  {
    text: '♏',
    name: 'Scorpio',
    tags: ['scorpio', 'scorpion', 'scorpius', 'zodiac'],
  },
  {
    text: '♐',
    name: 'Sagittarius',
    tags: ['archer', 'sagittarius', 'zodiac'],
  },
  {
    text: '♑',
    name: 'Capricorn',
    tags: ['capricorn', 'goat', 'zodiac'],
  },
  {
    text: '♒',
    name: 'Aquarius',
    tags: ['aquarius', 'bearer', 'water', 'zodiac'],
  },
  {
    text: '♓',
    name: 'Pisces',
    tags: ['fish', 'pisces', 'zodiac'],
  },
  {
    text: '⛎',
    name: 'Ophiuchus',
    tags: ['bearer', 'ophiuchus', 'serpent', 'snake', 'zodiac'],
  },
  {
    text: '🔀',
    name: 'shuffle tracks button',
    tags: ['arrow', 'crossed', 'shuffle tracks button'],
  },
  {
    text: '🔁',
    name: 'repeat button',
    tags: ['arrow', 'clockwise', 'repeat', 'repeat button'],
  },
  {
    text: '🔂',
    name: 'repeat single button',
    tags: ['arrow', 'clockwise', 'once', 'repeat single button'],
  },
  {
    text: '▶',
    name: 'play button',
    tags: ['arrow', 'play', 'play button', 'right', 'triangle'],
  },
  {
    text: '⏩',
    name: 'fast-forward button',
    tags: ['arrow', 'double', 'fast', 'fast-forward button', 'forward'],
  },
  {
    text: '⏭',
    name: 'next track button',
    tags: [
      'arrow',
      'next scene',
      'next track',
      'next track button',
      'triangle',
    ],
  },
  {
    text: '⏯',
    name: 'play or pause button',
    tags: [
      'arrow',
      'pause',
      'play',
      'play or pause button',
      'right',
      'triangle',
    ],
  },
  {
    text: '◀',
    name: 'reverse button',
    tags: ['arrow', 'left', 'reverse', 'reverse button', 'triangle'],
  },
  {
    text: '⏪',
    name: 'fast reverse button',
    tags: ['arrow', 'double', 'fast reverse button', 'rewind'],
  },
  {
    text: '⏮',
    name: 'last track button',
    tags: [
      'arrow',
      'last track button',
      'previous scene',
      'previous track',
      'triangle',
    ],
  },
  {
    text: '🔼',
    name: 'upwards button',
    tags: ['arrow', 'button', 'red', 'upwards button'],
  },
  {
    text: '⏫',
    name: 'fast up button',
    tags: ['arrow', 'double', 'fast up button'],
  },
  {
    text: '🔽',
    name: 'downwards button',
    tags: ['arrow', 'button', 'down', 'downwards button', 'red'],
  },
  {
    text: '⏬',
    name: 'fast down button',
    tags: ['arrow', 'double', 'down', 'fast down button'],
  },
  {
    text: '⏸',
    name: 'pause button',
    tags: ['bar', 'double', 'pause', 'pause button', 'vertical'],
  },
  {
    text: '⏹',
    name: 'stop button',
    tags: ['square', 'stop', 'stop button'],
  },
  {
    text: '⏺',
    name: 'record button',
    tags: ['circle', 'record', 'record button'],
  },
  {
    text: '⏏',
    name: 'eject button',
    tags: ['eject', 'eject button'],
  },
  {
    text: '🎦',
    name: 'cinema',
    tags: ['camera', 'cinema', 'film', 'movie'],
  },
  {
    text: '🔅',
    name: 'dim button',
    tags: ['brightness', 'dim', 'dim button', 'low'],
  },
  {
    text: '🔆',
    name: 'bright button',
    tags: ['bright', 'bright button', 'brightness'],
  },
  {
    text: '📶',
    name: 'antenna bars',
    tags: ['antenna', 'antenna bars', 'bar', 'cell', 'mobile', 'phone'],
  },
  {
    text: '📳',
    name: 'vibration mode',
    tags: ['cell', 'mobile', 'mode', 'phone', 'telephone', 'vibration'],
  },
  {
    text: '📴',
    name: 'mobile phone off',
    tags: ['cell', 'mobile', 'off', 'phone', 'telephone'],
  },
  {
    text: '♾',
    name: 'infinity',
    tags: ['forever', 'infinity', 'unbounded', 'universal'],
  },
  {
    text: '♻',
    name: 'recycling symbol',
    tags: ['recycle', 'recycling symbol'],
  },
  {
    text: '⚜',
    name: 'fleur-de-lis',
    tags: ['fleur-de-lis'],
  },
  {
    text: '🔱',
    name: 'trident emblem',
    tags: ['anchor', 'emblem', 'ship', 'tool', 'trident'],
  },
  {
    text: '📛',
    name: 'name badge',
    tags: ['badge', 'name'],
  },
  {
    text: '🔰',
    name: 'Japanese symbol for beginner',
    tags: [
      'beginner',
      'chevron',
      'japanese',
      'japanese symbol for beginner',
      'leaf',
    ],
  },
  {
    text: '⭕',
    name: 'hollow red circle',
    tags: ['circle', 'hollow red circle', 'large', 'o', 'red'],
  },
  {
    text: '✅',
    name: 'check mark button',
    tags: ['✓', 'button', 'check', 'mark'],
  },
  {
    text: '☑',
    name: 'check box with check',
    tags: ['✓', 'box', 'check', 'check box with check'],
  },
  {
    text: '✔',
    name: 'check mark',
    tags: ['✓', 'check', 'mark'],
  },
  {
    text: '✖',
    name: 'multiplication sign',
    tags: ['×', 'cancel', 'multiplication', 'multiply', 'sign', 'x'],
  },
  {
    text: '❌',
    name: 'cross mark',
    tags: ['×', 'cancel', 'cross', 'mark', 'multiplication', 'multiply', 'x'],
  },
  {
    text: '❎',
    name: 'cross mark button',
    tags: ['×', 'cross mark button', 'mark', 'square', 'x'],
  },
  {
    text: '➕',
    name: 'plus sign',
    tags: ['+', 'math', 'plus', 'sign'],
  },
  {
    text: '➖',
    name: 'minus sign',
    tags: ['-', '−', 'math', 'minus', 'sign'],
  },
  {
    text: '➗',
    name: 'division sign',
    tags: ['÷', 'division', 'math', 'sign'],
  },
  {
    text: '➰',
    name: 'curly loop',
    tags: ['curl', 'curly loop', 'loop'],
  },
  {
    text: '➿',
    name: 'double curly loop',
    tags: ['curl', 'double', 'double curly loop', 'loop'],
  },
  {
    text: '〽',
    name: 'part alternation mark',
    tags: ['mark', 'part', 'part alternation mark'],
  },
  {
    text: '✳',
    name: 'eight-spoked asterisk',
    tags: ['*', 'asterisk', 'eight-spoked asterisk'],
  },
  {
    text: '✴',
    name: 'eight-pointed star',
    tags: ['*', 'eight-pointed star', 'star'],
  },
  {
    text: '❇',
    name: 'sparkle',
    tags: ['*', 'sparkle'],
  },
  {
    text: '‼',
    name: 'double exclamation mark',
    tags: ['bangbang', 'double exclamation mark', 'exclamation', 'mark'],
  },
  {
    text: '⁉',
    name: 'exclamation question mark',
    tags: [
      '?',
      '?',
      'exclamation',
      'interrobang',
      'mark',
      'punctuation',
      'question',
    ],
  },
  {
    text: '❓',
    name: 'question mark',
    tags: ['?', 'mark', 'punctuation', 'question'],
  },
  {
    text: '❔',
    name: 'white question mark',
    tags: [
      '?',
      'mark',
      'outlined',
      'punctuation',
      'question',
      'white question mark',
    ],
  },
  {
    text: '❕',
    name: 'white exclamation mark',
    tags: [
      'exclamation',
      'mark',
      'outlined',
      'punctuation',
      'white exclamation mark',
    ],
  },
  {
    text: '❗',
    name: 'exclamation mark',
    tags: ['exclamation', 'mark', 'punctuation'],
  },
  {
    text: '〰',
    name: 'wavy dash',
    tags: ['dash', 'punctuation', 'wavy'],
  },
  {
    text: '©',
    name: 'copyright',
    tags: ['c', 'copyright'],
  },
  {
    text: '®',
    name: 'registered',
    tags: ['r', 'registered'],
  },
  {
    text: '™',
    name: 'trade mark',
    tags: ['mark', 'tm', 'trade mark', 'trademark'],
  },
  {
    text: '#️⃣',
    name: 'keycap: #',
    tags: ['keycap'],
  },
  {
    text: '*️⃣',
    name: 'keycap: *',
    tags: ['keycap'],
  },
  {
    text: '0️⃣',
    name: 'keycap: 0',
    tags: ['keycap'],
  },
  {
    text: '1️⃣',
    name: 'keycap: 1',
    tags: ['keycap'],
  },
  {
    text: '2️⃣',
    name: 'keycap: 2',
    tags: ['keycap'],
  },
  {
    text: '3️⃣',
    name: 'keycap: 3',
    tags: ['keycap'],
  },
  {
    text: '4️⃣',
    name: 'keycap: 4',
    tags: ['keycap'],
  },
  {
    text: '5️⃣',
    name: 'keycap: 5',
    tags: ['keycap'],
  },
  {
    text: '6️⃣',
    name: 'keycap: 6',
    tags: ['keycap'],
  },
  {
    text: '7️⃣',
    name: 'keycap: 7',
    tags: ['keycap'],
  },
  {
    text: '8️⃣',
    name: 'keycap: 8',
    tags: ['keycap'],
  },
  {
    text: '9️⃣',
    name: 'keycap: 9',
    tags: ['keycap'],
  },
  {
    text: '🔟',
    name: 'keycap: 10',
    tags: ['keycap'],
  },
  {
    text: '🔠',
    name: 'input latin uppercase',
    tags: ['abcd', 'input', 'latin', 'letters', 'uppercase'],
  },
  {
    text: '🔡',
    name: 'input latin lowercase',
    tags: ['abcd', 'input', 'latin', 'letters', 'lowercase'],
  },
  {
    text: '🔢',
    name: 'input numbers',
    tags: ['1234', 'input', 'numbers'],
  },
  {
    text: '🔣',
    name: 'input symbols',
    tags: ['〒♪%', 'input', 'input symbols'],
  },
  {
    text: '🔤',
    name: 'input latin letters',
    tags: ['abc', 'alphabet', 'input', 'latin', 'letters'],
  },
  {
    text: '🅰',
    name: 'A button (blood type)',
    tags: ['a', 'a button blood type', 'blood type'],
  },
  {
    text: '🆎',
    name: 'AB button (blood type)',
    tags: ['ab', 'ab button blood type', 'blood type'],
  },
  {
    text: '🅱',
    name: 'B button (blood type)',
    tags: ['b', 'b button blood type', 'blood type'],
  },
  {
    text: '🆑',
    name: 'CL button',
    tags: ['cl', 'cl button'],
  },
  {
    text: '🆒',
    name: 'COOL button',
    tags: ['cool', 'cool button'],
  },
  {
    text: '🆓',
    name: 'FREE button',
    tags: ['free', 'free button'],
  },
  {
    text: 'ℹ',
    name: 'information',
    tags: ['i', 'information'],
  },
  {
    text: '🆔',
    name: 'ID button',
    tags: ['id', 'id button', 'identity'],
  },
  {
    text: 'Ⓜ',
    name: 'circled M',
    tags: ['circle', 'circled m', 'm'],
  },
  {
    text: '🆕',
    name: 'NEW button',
    tags: ['new', 'new button'],
  },
  {
    text: '🆖',
    name: 'NG button',
    tags: ['ng', 'ng button'],
  },
  {
    text: '🅾',
    name: 'O button (blood type)',
    tags: ['blood type', 'o', 'o button blood type'],
  },
  {
    text: '🆗',
    name: 'OK button',
    tags: ['ok', 'ok button'],
  },
  {
    text: '🅿',
    name: 'P button',
    tags: ['p button', 'parking'],
  },
  {
    text: '🆘',
    name: 'SOS button',
    tags: ['help', 'sos', 'sos button'],
  },
  {
    text: '🆙',
    name: 'UP! button',
    tags: ['mark', 'up', 'up! button'],
  },
  {
    text: '🆚',
    name: 'VS button',
    tags: ['versus', 'vs', 'vs button'],
  },
  {
    text: '🈁',
    name: 'Japanese “here” button',
    tags: ['here', 'japanese', 'japanese “here” button', 'katakana', 'ココ'],
  },
  {
    text: '🈂',
    name: 'Japanese “service charge” button',
    tags: [
      'service charge',
      'japanese',
      'japanese “service charge” button',
      'katakana',
      'サ',
    ],
  },
  {
    text: '🈷',
    name: 'Japanese “monthly amount” button',
    tags: [
      'monthly amount',
      'ideograph',
      'japanese',
      'japanese “monthly amount” button',
      '月',
    ],
  },
  {
    text: '🈶',
    name: 'Japanese “not free of charge” button',
    tags: [
      'not free of charge',
      'ideograph',
      'japanese',
      'japanese “not free of charge” button',
      '有',
    ],
  },
  {
    text: '🈯',
    name: 'Japanese “reserved” button',
    tags: [
      'reserved',
      'ideograph',
      'japanese',
      'japanese “reserved” button',
      '指',
    ],
  },
  {
    text: '🉐',
    name: 'Japanese “bargain” button',
    tags: [
      'bargain',
      'ideograph',
      'japanese',
      'japanese “bargain” button',
      '得',
    ],
  },
  {
    text: '🈹',
    name: 'Japanese “discount” button',
    tags: [
      'discount',
      'ideograph',
      'japanese',
      'japanese “discount” button',
      '割',
    ],
  },
  {
    text: '🈚',
    name: 'Japanese “free of charge” button',
    tags: [
      'free of charge',
      'ideograph',
      'japanese',
      'japanese “free of charge” button',
      '無',
    ],
  },
  {
    text: '🈲',
    name: 'Japanese “prohibited” button',
    tags: [
      'prohibited',
      'ideograph',
      'japanese',
      'japanese “prohibited” button',
      '禁',
    ],
  },
  {
    text: '🉑',
    name: 'Japanese “acceptable” button',
    tags: [
      'acceptable',
      'ideograph',
      'japanese',
      'japanese “acceptable” button',
      '可',
    ],
  },
  {
    text: '🈸',
    name: 'Japanese “application” button',
    tags: [
      'application',
      'ideograph',
      'japanese',
      'japanese “application” button',
      '申',
    ],
  },
  {
    text: '🈴',
    name: 'Japanese “passing grade” button',
    tags: [
      'passing grade',
      'ideograph',
      'japanese',
      'japanese “passing grade” button',
      '合',
    ],
  },
  {
    text: '🈳',
    name: 'Japanese “vacancy” button',
    tags: [
      'vacancy',
      'ideograph',
      'japanese',
      'japanese “vacancy” button',
      '空',
    ],
  },
  {
    text: '㊗',
    name: 'Japanese “congratulations” button',
    tags: [
      'congratulations',
      'ideograph',
      'japanese',
      'japanese “congratulations” button',
      '祝',
    ],
  },
  {
    text: '㊙',
    name: 'Japanese “secret” button',
    tags: ['secret', 'ideograph', 'japanese', 'japanese “secret” button', '秘'],
  },
  {
    text: '🈺',
    name: 'Japanese “open for business” button',
    tags: [
      'open for business',
      'ideograph',
      'japanese',
      'japanese “open for business” button',
      '営',
    ],
  },
  {
    text: '🈵',
    name: 'Japanese “no vacancy” button',
    tags: [
      'no vacancy',
      'ideograph',
      'japanese',
      'japanese “no vacancy” button',
      '満',
    ],
  },
  {
    text: '🔴',
    name: 'red circle',
    tags: ['circle', 'geometric', 'red'],
  },
  {
    text: '🟠',
    name: 'orange circle',
    tags: ['circle', 'orange'],
  },
  {
    text: '🟡',
    name: 'yellow circle',
    tags: ['circle', 'yellow'],
  },
  {
    text: '🟢',
    name: 'green circle',
    tags: ['circle', 'green'],
  },
  {
    text: '🔵',
    name: 'blue circle',
    tags: ['blue', 'circle', 'geometric'],
  },
  {
    text: '🟣',
    name: 'purple circle',
    tags: ['circle', 'purple'],
  },
  {
    text: '🟤',
    name: 'brown circle',
    tags: ['brown', 'circle'],
  },
  {
    text: '⚫',
    name: 'black circle',
    tags: ['black circle', 'circle', 'geometric'],
  },
  {
    text: '⚪',
    name: 'white circle',
    tags: ['circle', 'geometric', 'white circle'],
  },
  {
    text: '🟥',
    name: 'red square',
    tags: ['red', 'square'],
  },
  {
    text: '🟧',
    name: 'orange square',
    tags: ['orange', 'square'],
  },
  {
    text: '🟨',
    name: 'yellow square',
    tags: ['square', 'yellow'],
  },
  {
    text: '🟩',
    name: 'green square',
    tags: ['green', 'square'],
  },
  {
    text: '🟦',
    name: 'blue square',
    tags: ['blue', 'square'],
  },
  {
    text: '🟪',
    name: 'purple square',
    tags: ['purple', 'square'],
  },
  {
    text: '🟫',
    name: 'brown square',
    tags: ['brown', 'square'],
  },
  {
    text: '⬛',
    name: 'black large square',
    tags: ['black large square', 'geometric', 'square'],
  },
  {
    text: '⬜',
    name: 'white large square',
    tags: ['geometric', 'square', 'white large square'],
  },
  {
    text: '◼',
    name: 'black medium square',
    tags: ['black medium square', 'geometric', 'square'],
  },
  {
    text: '◻',
    name: 'white medium square',
    tags: ['geometric', 'square', 'white medium square'],
  },
  {
    text: '◾',
    name: 'black medium-small square',
    tags: ['black medium-small square', 'geometric', 'square'],
  },
  {
    text: '◽',
    name: 'white medium-small square',
    tags: ['geometric', 'square', 'white medium-small square'],
  },
  {
    text: '▪',
    name: 'black small square',
    tags: ['black small square', 'geometric', 'square'],
  },
  {
    text: '▫',
    name: 'white small square',
    tags: ['geometric', 'square', 'white small square'],
  },
  {
    text: '🔶',
    name: 'large orange diamond',
    tags: ['diamond', 'geometric', 'large orange diamond', 'orange'],
  },
  {
    text: '🔷',
    name: 'large blue diamond',
    tags: ['blue', 'diamond', 'geometric', 'large blue diamond'],
  },
  {
    text: '🔸',
    name: 'small orange diamond',
    tags: ['diamond', 'geometric', 'orange', 'small orange diamond'],
  },
  {
    text: '🔹',
    name: 'small blue diamond',
    tags: ['blue', 'diamond', 'geometric', 'small blue diamond'],
  },
  {
    text: '🔺',
    name: 'red triangle pointed up',
    tags: ['geometric', 'red', 'red triangle pointed up'],
  },
  {
    text: '🔻',
    name: 'red triangle pointed down',
    tags: ['down', 'geometric', 'red', 'red triangle pointed down'],
  },
  {
    text: '💠',
    name: 'diamond with a dot',
    tags: ['comic', 'diamond', 'diamond with a dot', 'geometric', 'inside'],
  },
  {
    text: '🔘',
    name: 'radio button',
    tags: ['button', 'geometric', 'radio'],
  },
  {
    text: '🔳',
    name: 'white square button',
    tags: ['button', 'geometric', 'outlined', 'square', 'white square button'],
  },
  {
    text: '🔲',
    name: 'black square button',
    tags: ['black square button', 'button', 'geometric', 'square'],
  },
  {
    text: '🏁',
    name: 'chequered flag',
    tags: ['checkered', 'chequered', 'chequered flag', 'racing'],
  },
  {
    text: '🚩',
    name: 'triangular flag',
    tags: ['post', 'triangular flag'],
  },
  {
    text: '🎌',
    name: 'crossed flags',
    tags: ['celebration', 'cross', 'crossed', 'crossed flags', 'japanese'],
  },
  {
    text: '🏴',
    name: 'black flag',
    tags: ['black flag', 'waving'],
  },
  {
    text: '🏳',
    name: 'white flag',
    tags: ['waving', 'white flag'],
  },
  {
    text: '🏳️‍🌈',
    name: 'rainbow flag',
    tags: ['pride', 'rainbow', 'rainbow flag'],
  },
  {
    text: '🏴‍☠️',
    name: 'pirate flag',
    tags: ['jolly roger', 'pirate', 'pirate flag', 'plunder', 'treasure'],
  },
  {
    text: '🇦🇨',
    name: 'flag: Ascension Island',
    tags: ['flag'],
  },
  {
    text: '🇦🇩',
    name: 'flag: Andorra',
    tags: ['flag'],
  },
  {
    text: '🇦🇪',
    name: 'flag: United Arab Emirates',
    tags: ['flag'],
  },
  {
    text: '🇦🇫',
    name: 'flag: Afghanistan',
    tags: ['flag'],
  },
  {
    text: '🇦🇬',
    name: 'flag: Antigua &amp; Barbuda',
    tags: ['flag'],
  },
  {
    text: '🇦🇮',
    name: 'flag: Anguilla',
    tags: ['flag'],
  },
  {
    text: '🇦🇱',
    name: 'flag: Albania',
    tags: ['flag'],
  },
  {
    text: '🇦🇲',
    name: 'flag: Armenia',
    tags: ['flag'],
  },
  {
    text: '🇦🇴',
    name: 'flag: Angola',
    tags: ['flag'],
  },
  {
    text: '🇦🇶',
    name: 'flag: Antarctica',
    tags: ['flag'],
  },
  {
    text: '🇦🇷',
    name: 'flag: Argentina',
    tags: ['flag'],
  },
  {
    text: '🇦🇸',
    name: 'flag: American Samoa',
    tags: ['flag'],
  },
  {
    text: '🇦🇹',
    name: 'flag: Austria',
    tags: ['flag'],
  },
  {
    text: '🇦🇺',
    name: 'flag: Australia',
    tags: ['flag'],
  },
  {
    text: '🇦🇼',
    name: 'flag: Aruba',
    tags: ['flag'],
  },
  {
    text: '🇦🇽',
    name: 'flag: Åland Islands',
    tags: ['flag'],
  },
  {
    text: '🇦🇿',
    name: 'flag: Azerbaijan',
    tags: ['flag'],
  },
  {
    text: '🇧🇦',
    name: 'flag: Bosnia &amp; Herzegovina',
    tags: ['flag'],
  },
  {
    text: '🇧🇧',
    name: 'flag: Barbados',
    tags: ['flag'],
  },
  {
    text: '🇧🇩',
    name: 'flag: Bangladesh',
    tags: ['flag'],
  },
  {
    text: '🇧🇪',
    name: 'flag: Belgium',
    tags: ['flag'],
  },
  {
    text: '🇧🇫',
    name: 'flag: Burkina Faso',
    tags: ['flag'],
  },
  {
    text: '🇧🇬',
    name: 'flag: Bulgaria',
    tags: ['flag'],
  },
  {
    text: '🇧🇭',
    name: 'flag: Bahrain',
    tags: ['flag'],
  },
  {
    text: '🇧🇮',
    name: 'flag: Burundi',
    tags: ['flag'],
  },
  {
    text: '🇧🇯',
    name: 'flag: Benin',
    tags: ['flag'],
  },
  {
    text: '🇧🇱',
    name: 'flag: St. Barthélemy',
    tags: ['flag'],
  },
  {
    text: '🇧🇲',
    name: 'flag: Bermuda',
    tags: ['flag'],
  },
  {
    text: '🇧🇳',
    name: 'flag: Brunei',
    tags: ['flag'],
  },
  {
    text: '🇧🇴',
    name: 'flag: Bolivia',
    tags: ['flag'],
  },
  {
    text: '🇧🇶',
    name: 'flag: Caribbean Netherlands',
    tags: ['flag'],
  },
  {
    text: '🇧🇷',
    name: 'flag: Brazil',
    tags: ['flag'],
  },
  {
    text: '🇧🇸',
    name: 'flag: Bahamas',
    tags: ['flag'],
  },
  {
    text: '🇧🇹',
    name: 'flag: Bhutan',
    tags: ['flag'],
  },
  {
    text: '🇧🇻',
    name: 'flag: Bouvet Island',
    tags: ['flag'],
  },
  {
    text: '🇧🇼',
    name: 'flag: Botswana',
    tags: ['flag'],
  },
  {
    text: '🇧🇾',
    name: 'flag: Belarus',
    tags: ['flag'],
  },
  {
    text: '🇧🇿',
    name: 'flag: Belize',
    tags: ['flag'],
  },
  {
    text: '🇨🇦',
    name: 'flag: Canada',
    tags: ['flag'],
  },
  {
    text: '🇨🇨',
    name: 'flag: Cocos (Keeling) Islands',
    tags: ['flag'],
  },
  {
    text: '🇨🇩',
    name: 'flag: Congo - Kinshasa',
    tags: ['flag'],
  },
  {
    text: '🇨🇫',
    name: 'flag: Central African Republic',
    tags: ['flag'],
  },
  {
    text: '🇨🇬',
    name: 'flag: Congo - Brazzaville',
    tags: ['flag'],
  },
  {
    text: '🇨🇭',
    name: 'flag: Switzerland',
    tags: ['flag'],
  },
  {
    text: '🇨🇮',
    name: 'flag: Côte d’Ivoire',
    tags: ['flag'],
  },
  {
    text: '🇨🇰',
    name: 'flag: Cook Islands',
    tags: ['flag'],
  },
  {
    text: '🇨🇱',
    name: 'flag: Chile',
    tags: ['flag'],
  },
  {
    text: '🇨🇲',
    name: 'flag: Cameroon',
    tags: ['flag'],
  },
  {
    text: '🇨🇳',
    name: 'flag: China',
    tags: ['flag'],
  },
  {
    text: '🇨🇴',
    name: 'flag: Colombia',
    tags: ['flag'],
  },
  {
    text: '🇨🇵',
    name: 'flag: Clipperton Island',
    tags: ['flag'],
  },
  {
    text: '🇨🇷',
    name: 'flag: Costa Rica',
    tags: ['flag'],
  },
  {
    text: '🇨🇺',
    name: 'flag: Cuba',
    tags: ['flag'],
  },
  {
    text: '🇨🇻',
    name: 'flag: Cape Verde',
    tags: ['flag'],
  },
  {
    text: '🇨🇼',
    name: 'flag: Curaçao',
    tags: ['flag'],
  },
  {
    text: '🇨🇽',
    name: 'flag: Christmas Island',
    tags: ['flag'],
  },
  {
    text: '🇨🇾',
    name: 'flag: Cyprus',
    tags: ['flag'],
  },
  {
    text: '🇨🇿',
    name: 'flag: Czechia',
    tags: ['flag'],
  },
  {
    text: '🇩🇪',
    name: 'flag: Germany',
    tags: ['flag'],
  },
  {
    text: '🇩🇬',
    name: 'flag: Diego Garcia',
    tags: ['flag'],
  },
  {
    text: '🇩🇯',
    name: 'flag: Djibouti',
    tags: ['flag'],
  },
  {
    text: '🇩🇰',
    name: 'flag: Denmark',
    tags: ['flag'],
  },
  {
    text: '🇩🇲',
    name: 'flag: Dominica',
    tags: ['flag'],
  },
  {
    text: '🇩🇴',
    name: 'flag: Dominican Republic',
    tags: ['flag'],
  },
  {
    text: '🇩🇿',
    name: 'flag: Algeria',
    tags: ['flag'],
  },
  {
    text: '🇪🇦',
    name: 'flag: Ceuta &amp; Melilla',
    tags: ['flag'],
  },
  {
    text: '🇪🇨',
    name: 'flag: Ecuador',
    tags: ['flag'],
  },
  {
    text: '🇪🇪',
    name: 'flag: Estonia',
    tags: ['flag'],
  },
  {
    text: '🇪🇬',
    name: 'flag: Egypt',
    tags: ['flag'],
  },
  {
    text: '🇪🇭',
    name: 'flag: Western Sahara',
    tags: ['flag'],
  },
  {
    text: '🇪🇷',
    name: 'flag: Eritrea',
    tags: ['flag'],
  },
  {
    text: '🇪🇸',
    name: 'flag: Spain',
    tags: ['flag'],
  },
  {
    text: '🇪🇹',
    name: 'flag: Ethiopia',
    tags: ['flag'],
  },
  {
    text: '🇪🇺',
    name: 'flag: European Union',
    tags: ['flag'],
  },
  {
    text: '🇫🇮',
    name: 'flag: Finland',
    tags: ['flag'],
  },
  {
    text: '🇫🇯',
    name: 'flag: Fiji',
    tags: ['flag'],
  },
  {
    text: '🇫🇰',
    name: 'flag: Falkland Islands',
    tags: ['flag'],
  },
  {
    text: '🇫🇲',
    name: 'flag: Micronesia',
    tags: ['flag'],
  },
  {
    text: '🇫🇴',
    name: 'flag: Faroe Islands',
    tags: ['flag'],
  },
  {
    text: '🇫🇷',
    name: 'flag: France',
    tags: ['flag'],
  },
  {
    text: '🇬🇦',
    name: 'flag: Gabon',
    tags: ['flag'],
  },
  {
    text: '🇬🇧',
    name: 'flag: United Kingdom',
    tags: ['flag'],
  },
  {
    text: '🇬🇩',
    name: 'flag: Grenada',
    tags: ['flag'],
  },
  {
    text: '🇬🇪',
    name: 'flag: Georgia',
    tags: ['flag'],
  },
  {
    text: '🇬🇫',
    name: 'flag: French Guiana',
    tags: ['flag'],
  },
  {
    text: '🇬🇬',
    name: 'flag: Guernsey',
    tags: ['flag'],
  },
  {
    text: '🇬🇭',
    name: 'flag: Ghana',
    tags: ['flag'],
  },
  {
    text: '🇬🇮',
    name: 'flag: Gibraltar',
    tags: ['flag'],
  },
  {
    text: '🇬🇱',
    name: 'flag: Greenland',
    tags: ['flag'],
  },
  {
    text: '🇬🇲',
    name: 'flag: Gambia',
    tags: ['flag'],
  },
  {
    text: '🇬🇳',
    name: 'flag: Guinea',
    tags: ['flag'],
  },
  {
    text: '🇬🇵',
    name: 'flag: Guadeloupe',
    tags: ['flag'],
  },
  {
    text: '🇬🇶',
    name: 'flag: Equatorial Guinea',
    tags: ['flag'],
  },
  {
    text: '🇬🇷',
    name: 'flag: Greece',
    tags: ['flag'],
  },
  {
    text: '🇬🇸',
    name: 'flag: South Georgia &amp; South Sandwich Islands',
    tags: ['flag'],
  },
  {
    text: '🇬🇹',
    name: 'flag: Guatemala',
    tags: ['flag'],
  },
  {
    text: '🇬🇺',
    name: 'flag: Guam',
    tags: ['flag'],
  },
  {
    text: '🇬🇼',
    name: 'flag: Guinea-Bissau',
    tags: ['flag'],
  },
  {
    text: '🇬🇾',
    name: 'flag: Guyana',
    tags: ['flag'],
  },
  {
    text: '🇭🇰',
    name: 'flag: Hong Kong SAR China',
    tags: ['flag'],
  },
  {
    text: '🇭🇲',
    name: 'flag: Heard &amp; McDonald Islands',
    tags: ['flag'],
  },
  {
    text: '🇭🇳',
    name: 'flag: Honduras',
    tags: ['flag'],
  },
  {
    text: '🇭🇷',
    name: 'flag: Croatia',
    tags: ['flag'],
  },
  {
    text: '🇭🇹',
    name: 'flag: Haiti',
    tags: ['flag'],
  },
  {
    text: '🇭🇺',
    name: 'flag: Hungary',
    tags: ['flag'],
  },
  {
    text: '🇮🇨',
    name: 'flag: Canary Islands',
    tags: ['flag'],
  },
  {
    text: '🇮🇩',
    name: 'flag: Indonesia',
    tags: ['flag'],
  },
  {
    text: '🇮🇪',
    name: 'flag: Ireland',
    tags: ['flag'],
  },
  {
    text: '🇮🇱',
    name: 'flag: Israel',
    tags: ['flag'],
  },
  {
    text: '🇮🇲',
    name: 'flag: Isle of Man',
    tags: ['flag'],
  },
  {
    text: '🇮🇳',
    name: 'flag: India',
    tags: ['flag'],
  },
  {
    text: '🇮🇴',
    name: 'flag: British Indian Ocean Territory',
    tags: ['flag'],
  },
  {
    text: '🇮🇶',
    name: 'flag: Iraq',
    tags: ['flag'],
  },
  {
    text: '🇮🇷',
    name: 'flag: Iran',
    tags: ['flag'],
  },
  {
    text: '🇮🇸',
    name: 'flag: Iceland',
    tags: ['flag'],
  },
  {
    text: '🇮🇹',
    name: 'flag: Italy',
    tags: ['flag'],
  },
  {
    text: '🇯🇪',
    name: 'flag: Jersey',
    tags: ['flag'],
  },
  {
    text: '🇯🇲',
    name: 'flag: Jamaica',
    tags: ['flag'],
  },
  {
    text: '🇯🇴',
    name: 'flag: Jordan',
    tags: ['flag'],
  },
  {
    text: '🇯🇵',
    name: 'flag: Japan',
    tags: ['flag'],
  },
  {
    text: '🇰🇪',
    name: 'flag: Kenya',
    tags: ['flag'],
  },
  {
    text: '🇰🇬',
    name: 'flag: Kyrgyzstan',
    tags: ['flag'],
  },
  {
    text: '🇰🇭',
    name: 'flag: Cambodia',
    tags: ['flag'],
  },
  {
    text: '🇰🇮',
    name: 'flag: Kiribati',
    tags: ['flag'],
  },
  {
    text: '🇰🇲',
    name: 'flag: Comoros',
    tags: ['flag'],
  },
  {
    text: '🇰🇳',
    name: 'flag: St. Kitts &amp; Nevis',
    tags: ['flag'],
  },
  {
    text: '🇰🇵',
    name: 'flag: North Korea',
    tags: ['flag'],
  },
  {
    text: '🇰🇷',
    name: 'flag: South Korea',
    tags: ['flag'],
  },
  {
    text: '🇰🇼',
    name: 'flag: Kuwait',
    tags: ['flag'],
  },
  {
    text: '🇰🇾',
    name: 'flag: Cayman Islands',
    tags: ['flag'],
  },
  {
    text: '🇰🇿',
    name: 'flag: Kazakhstan',
    tags: ['flag'],
  },
  {
    text: '🇱🇦',
    name: 'flag: Laos',
    tags: ['flag'],
  },
  {
    text: '🇱🇧',
    name: 'flag: Lebanon',
    tags: ['flag'],
  },
  {
    text: '🇱🇨',
    name: 'flag: St. Lucia',
    tags: ['flag'],
  },
  {
    text: '🇱🇮',
    name: 'flag: Liechtenstein',
    tags: ['flag'],
  },
  {
    text: '🇱🇰',
    name: 'flag: Sri Lanka',
    tags: ['flag'],
  },
  {
    text: '🇱🇷',
    name: 'flag: Liberia',
    tags: ['flag'],
  },
  {
    text: '🇱🇸',
    name: 'flag: Lesotho',
    tags: ['flag'],
  },
  {
    text: '🇱🇹',
    name: 'flag: Lithuania',
    tags: ['flag'],
  },
  {
    text: '🇱🇺',
    name: 'flag: Luxembourg',
    tags: ['flag'],
  },
  {
    text: '🇱🇻',
    name: 'flag: Latvia',
    tags: ['flag'],
  },
  {
    text: '🇱🇾',
    name: 'flag: Libya',
    tags: ['flag'],
  },
  {
    text: '🇲🇦',
    name: 'flag: Morocco',
    tags: ['flag'],
  },
  {
    text: '🇲🇨',
    name: 'flag: Monaco',
    tags: ['flag'],
  },
  {
    text: '🇲🇩',
    name: 'flag: Moldova',
    tags: ['flag'],
  },
  {
    text: '🇲🇪',
    name: 'flag: Montenegro',
    tags: ['flag'],
  },
  {
    text: '🇲🇫',
    name: 'flag: St. Martin',
    tags: ['flag'],
  },
  {
    text: '🇲🇬',
    name: 'flag: Madagascar',
    tags: ['flag'],
  },
  {
    text: '🇲🇭',
    name: 'flag: Marshall Islands',
    tags: ['flag'],
  },
  {
    text: '🇲🇰',
    name: 'flag: North Macedonia',
    tags: ['flag'],
  },
  {
    text: '🇲🇱',
    name: 'flag: Mali',
    tags: ['flag'],
  },
  {
    text: '🇲🇲',
    name: 'flag: Myanmar (Burma)',
    tags: ['flag'],
  },
  {
    text: '🇲🇳',
    name: 'flag: Mongolia',
    tags: ['flag'],
  },
  {
    text: '🇲🇴',
    name: 'flag: Macao SAR China',
    tags: ['flag'],
  },
  {
    text: '🇲🇵',
    name: 'flag: Northern Mariana Islands',
    tags: ['flag'],
  },
  {
    text: '🇲🇶',
    name: 'flag: Martinique',
    tags: ['flag'],
  },
  {
    text: '🇲🇷',
    name: 'flag: Mauritania',
    tags: ['flag'],
  },
  {
    text: '🇲🇸',
    name: 'flag: Montserrat',
    tags: ['flag'],
  },
  {
    text: '🇲🇹',
    name: 'flag: Malta',
    tags: ['flag'],
  },
  {
    text: '🇲🇺',
    name: 'flag: Mauritius',
    tags: ['flag'],
  },
  {
    text: '🇲🇻',
    name: 'flag: Maldives',
    tags: ['flag'],
  },
  {
    text: '🇲🇼',
    name: 'flag: Malawi',
    tags: ['flag'],
  },
  {
    text: '🇲🇽',
    name: 'flag: Mexico',
    tags: ['flag'],
  },
  {
    text: '🇲🇾',
    name: 'flag: Malaysia',
    tags: ['flag'],
  },
  {
    text: '🇲🇿',
    name: 'flag: Mozambique',
    tags: ['flag'],
  },
  {
    text: '🇳🇦',
    name: 'flag: Namibia',
    tags: ['flag'],
  },
  {
    text: '🇳🇨',
    name: 'flag: New Caledonia',
    tags: ['flag'],
  },
  {
    text: '🇳🇪',
    name: 'flag: Niger',
    tags: ['flag'],
  },
  {
    text: '🇳🇫',
    name: 'flag: Norfolk Island',
    tags: ['flag'],
  },
  {
    text: '🇳🇬',
    name: 'flag: Nigeria',
    tags: ['flag'],
  },
  {
    text: '🇳🇮',
    name: 'flag: Nicaragua',
    tags: ['flag'],
  },
  {
    text: '🇳🇱',
    name: 'flag: Netherlands',
    tags: ['flag'],
  },
  {
    text: '🇳🇴',
    name: 'flag: Norway',
    tags: ['flag'],
  },
  {
    text: '🇳🇵',
    name: 'flag: Nepal',
    tags: ['flag'],
  },
  {
    text: '🇳🇷',
    name: 'flag: Nauru',
    tags: ['flag'],
  },
  {
    text: '🇳🇺',
    name: 'flag: Niue',
    tags: ['flag'],
  },
  {
    text: '🇳🇿',
    name: 'flag: New Zealand',
    tags: ['flag'],
  },
  {
    text: '🇴🇲',
    name: 'flag: Oman',
    tags: ['flag'],
  },
  {
    text: '🇵🇦',
    name: 'flag: Panama',
    tags: ['flag'],
  },
  {
    text: '🇵🇪',
    name: 'flag: Peru',
    tags: ['flag'],
  },
  {
    text: '🇵🇫',
    name: 'flag: French Polynesia',
    tags: ['flag'],
  },
  {
    text: '🇵🇬',
    name: 'flag: Papua New Guinea',
    tags: ['flag'],
  },
  {
    text: '🇵🇭',
    name: 'flag: Philippines',
    tags: ['flag'],
  },
  {
    text: '🇵🇰',
    name: 'flag: Pakistan',
    tags: ['flag'],
  },
  {
    text: '🇵🇱',
    name: 'flag: Poland',
    tags: ['flag'],
  },
  {
    text: '🇵🇲',
    name: 'flag: St. Pierre &amp; Miquelon',
    tags: ['flag'],
  },
  {
    text: '🇵🇳',
    name: 'flag: Pitcairn Islands',
    tags: ['flag'],
  },
  {
    text: '🇵🇷',
    name: 'flag: Puerto Rico',
    tags: ['flag'],
  },
  {
    text: '🇵🇸',
    name: 'flag: Palestinian Territories',
    tags: ['flag'],
  },
  {
    text: '🇵🇹',
    name: 'flag: Portugal',
    tags: ['flag'],
  },
  {
    text: '🇵🇼',
    name: 'flag: Palau',
    tags: ['flag'],
  },
  {
    text: '🇵🇾',
    name: 'flag: Paraguay',
    tags: ['flag'],
  },
  {
    text: '🇶🇦',
    name: 'flag: Qatar',
    tags: ['flag'],
  },
  {
    text: '🇷🇪',
    name: 'flag: Réunion',
    tags: ['flag'],
  },
  {
    text: '🇷🇴',
    name: 'flag: Romania',
    tags: ['flag'],
  },
  {
    text: '🇷🇸',
    name: 'flag: Serbia',
    tags: ['flag'],
  },
  {
    text: '🇷🇺',
    name: 'flag: Russia',
    tags: ['flag'],
  },
  {
    text: '🇷🇼',
    name: 'flag: Rwanda',
    tags: ['flag'],
  },
  {
    text: '🇸🇦',
    name: 'flag: Saudi Arabia',
    tags: ['flag'],
  },
  {
    text: '🇸🇧',
    name: 'flag: Solomon Islands',
    tags: ['flag'],
  },
  {
    text: '🇸🇨',
    name: 'flag: Seychelles',
    tags: ['flag'],
  },
  {
    text: '🇸🇩',
    name: 'flag: Sudan',
    tags: ['flag'],
  },
  {
    text: '🇸🇪',
    name: 'flag: Sweden',
    tags: ['flag'],
  },
  {
    text: '🇸🇬',
    name: 'flag: Singapore',
    tags: ['flag'],
  },
  {
    text: '🇸🇭',
    name: 'flag: St. Helena',
    tags: ['flag'],
  },
  {
    text: '🇸🇮',
    name: 'flag: Slovenia',
    tags: ['flag'],
  },
  {
    text: '🇸🇯',
    name: 'flag: Svalbard &amp; Jan Mayen',
    tags: ['flag'],
  },
  {
    text: '🇸🇰',
    name: 'flag: Slovakia',
    tags: ['flag'],
  },
  {
    text: '🇸🇱',
    name: 'flag: Sierra Leone',
    tags: ['flag'],
  },
  {
    text: '🇸🇲',
    name: 'flag: San Marino',
    tags: ['flag'],
  },
  {
    text: '🇸🇳',
    name: 'flag: Senegal',
    tags: ['flag'],
  },
  {
    text: '🇸🇴',
    name: 'flag: Somalia',
    tags: ['flag'],
  },
  {
    text: '🇸🇷',
    name: 'flag: Suriname',
    tags: ['flag'],
  },
  {
    text: '🇸🇸',
    name: 'flag: South Sudan',
    tags: ['flag'],
  },
  {
    text: '🇸🇹',
    name: 'flag: São Tomé &amp; Príncipe',
    tags: ['flag'],
  },
  {
    text: '🇸🇻',
    name: 'flag: El Salvador',
    tags: ['flag'],
  },
  {
    text: '🇸🇽',
    name: 'flag: Sint Maarten',
    tags: ['flag'],
  },
  {
    text: '🇸🇾',
    name: 'flag: Syria',
    tags: ['flag'],
  },
  {
    text: '🇸🇿',
    name: 'flag: Eswatini',
    tags: ['flag'],
  },
  {
    text: '🇹🇦',
    name: 'flag: Tristan da Cunha',
    tags: ['flag'],
  },
  {
    text: '🇹🇨',
    name: 'flag: Turks &amp; Caicos Islands',
    tags: ['flag'],
  },
  {
    text: '🇹🇩',
    name: 'flag: Chad',
    tags: ['flag'],
  },
  {
    text: '🇹🇫',
    name: 'flag: French Southern Territories',
    tags: ['flag'],
  },
  {
    text: '🇹🇬',
    name: 'flag: Togo',
    tags: ['flag'],
  },
  {
    text: '🇹🇭',
    name: 'flag: Thailand',
    tags: ['flag'],
  },
  {
    text: '🇹🇯',
    name: 'flag: Tajikistan',
    tags: ['flag'],
  },
  {
    text: '🇹🇰',
    name: 'flag: Tokelau',
    tags: ['flag'],
  },
  {
    text: '🇹🇱',
    name: 'flag: Timor-Leste',
    tags: ['flag'],
  },
  {
    text: '🇹🇲',
    name: 'flag: Turkmenistan',
    tags: ['flag'],
  },
  {
    text: '🇹🇳',
    name: 'flag: Tunisia',
    tags: ['flag'],
  },
  {
    text: '🇹🇴',
    name: 'flag: Tonga',
    tags: ['flag'],
  },
  {
    text: '🇹🇷',
    name: 'flag: Turkey',
    tags: ['flag'],
  },
  {
    text: '🇹🇹',
    name: 'flag: Trinidad &amp; Tobago',
    tags: ['flag'],
  },
  {
    text: '🇹🇻',
    name: 'flag: Tuvalu',
    tags: ['flag'],
  },
  {
    text: '🇹🇼',
    name: 'flag: Taiwan',
    tags: ['flag'],
  },
  {
    text: '🇹🇿',
    name: 'flag: Tanzania',
    tags: ['flag'],
  },
  {
    text: '🇺🇦',
    name: 'flag: Ukraine',
    tags: ['flag'],
  },
  {
    text: '🇺🇬',
    name: 'flag: Uganda',
    tags: ['flag'],
  },
  {
    text: '🇺🇲',
    name: 'flag: U.S. Outlying Islands',
    tags: ['flag'],
  },
  {
    text: '🇺🇳',
    name: 'flag: United Nations',
    tags: ['flag'],
  },
  {
    text: '🇺🇸',
    name: 'flag: United States',
    tags: ['flag'],
  },
  {
    text: '🇺🇾',
    name: 'flag: Uruguay',
    tags: ['flag'],
  },
  {
    text: '🇺🇿',
    name: 'flag: Uzbekistan',
    tags: ['flag'],
  },
  {
    text: '🇻🇦',
    name: 'flag: Vatican City',
    tags: ['flag'],
  },
  {
    text: '🇻🇨',
    name: 'flag: St. Vincent &amp; Grenadines',
    tags: ['flag'],
  },
  {
    text: '🇻🇪',
    name: 'flag: Venezuela',
    tags: ['flag'],
  },
  {
    text: '🇻🇬',
    name: 'flag: British Virgin Islands',
    tags: ['flag'],
  },
  {
    text: '🇻🇮',
    name: 'flag: U.S. Virgin Islands',
    tags: ['flag'],
  },
  {
    text: '🇻🇳',
    name: 'flag: Vietnam',
    tags: ['flag'],
  },
  {
    text: '🇻🇺',
    name: 'flag: Vanuatu',
    tags: ['flag'],
  },
  {
    text: '🇼🇫',
    name: 'flag: Wallis &amp; Futuna',
    tags: ['flag'],
  },
  {
    text: '🇼🇸',
    name: 'flag: Samoa',
    tags: ['flag'],
  },
  {
    text: '🇽🇰',
    name: 'flag: Kosovo',
    tags: ['flag'],
  },
  {
    text: '🇾🇪',
    name: 'flag: Yemen',
    tags: ['flag'],
  },
  {
    text: '🇾🇹',
    name: 'flag: Mayotte',
    tags: ['flag'],
  },
  {
    text: '🇿🇦',
    name: 'flag: South Africa',
    tags: ['flag'],
  },
  {
    text: '🇿🇲',
    name: 'flag: Zambia',
    tags: ['flag'],
  },
  {
    text: '🇿🇼',
    name: 'flag: Zimbabwe',
    tags: ['flag'],
  },
  {
    text: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
    name: 'flag: England',
    tags: ['flag'],
  },
  {
    text: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
    name: 'flag: Scotland',
    tags: ['flag'],
  },
  {
    text: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
    name: 'flag: Wales',
    tags: ['flag'],
  },
]
