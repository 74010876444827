import { client } from 'apollo'
import { useCallback, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import styled from 'styled-components'

import Loading from 'components/Loading'
import Grid from 'components/grid/Grid'
import { GridColumnAdditioner } from 'components/grid/GridColumnAdditioner'
import { useUploadSessionItemsQuery } from 'generated/graphql'
import { useActiveWorkspace } from 'hooks/useActiveWorkspace'

type UploadSessionItemsProps = {
  uploadSessionId: string
  scrollNode: HTMLDivElement
}

export const UploadSessionItems = (props: UploadSessionItemsProps) => {
  const [, setRerender] = useState(false)

  const activeWorkspace = useActiveWorkspace()!

  const { data, loading, fetchMore } = useUploadSessionItemsQuery({
    variables: {
      workspaceId: activeWorkspace.id,
      uploadSessionId: props.uploadSessionId,
      first: 30,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  })

  const pageInfo = data?.uploadSessionItems.pageInfo
  const nextCursor = pageInfo?.nextCursor
  const workspaceItems = data?.uploadSessionItems.uploadSessionItems ?? []
  const hasMore = Boolean(nextCursor) && workspaceItems.length > 0 && !loading

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        cursor: nextCursor,
      },
    })
  }

  useEffect(() => {
    // There is a conclict with our Masonory grid and Inifnite scroll
    // which makes the inifinite scroll to NOT fetch more if the grid content is small enough to not trigger the "fetch more threshold"
    // forcing a rerender solves it. Don't ask me why
    setTimeout(() => {
      setRerender((prev) => !prev)
    }, 0)
  }, [workspaceItems.length])

  const onDeleteItem = useCallback((itemId: string) => {
    client.cache.evict({ id: `WorkspaceItem:${itemId}` })
    client.cache.gc()
  }, [])

  if (loading && !data) {
    return <Loading />
  }

  if (workspaceItems.length === 0 && !loading) return null

  return (
    <GridWrapper>
      <div style={{ width: '100%' }}>
        <InfiniteScroll
          pageStart={0}
          threshold={1000}
          useWindow={false}
          getScrollParent={() => props.scrollNode}
          loadMore={handleLoadMore}
          hasMore={hasMore}
        >
          <GridColumnAdditioner parentWrapper={props.scrollNode}>
            {(gridColumnAdditionOption) => {
              return (
                <Grid
                  gridColumnAdditionOption={gridColumnAdditionOption}
                  scrollElement={props.scrollNode}
                  gridItems={workspaceItems}
                  onDelete={onDeleteItem}
                />
              )
            }}
          </GridColumnAdditioner>
          {loading && (
            <div className="loader" key={0}>
              <Loading fullscreen />
            </div>
          )}
        </InfiniteScroll>
      </div>
    </GridWrapper>
  )
}

const GridWrapper = styled.div`
  padding: 16px 16px 0;
`
