import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'

import { RootState } from 'store'
import { DBUser } from 'types/db'

const useGetUser = (uid?: string) => {
  useFirestoreConnect(
    uid
      ? [
          {
            collection: 'users',
            where: ['uid', '==', uid],
            limit: 1,
            storeAs: `user_${uid}`,
          },
        ]
      : []
  )

  const user: DBUser = useSelector((state: RootState) =>
    uid ? state.firestore.data[`user_${uid}`]?.[uid] || {} : {}
  )

  return user
}

export default useGetUser
