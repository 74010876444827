import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import { useParams } from 'react-router'
import styled from 'styled-components'

import { Text } from 'components/common/Text'
import { useMagicBoardQuery } from 'generated/graphql'
import { useActiveWorkspace } from 'hooks/useActiveWorkspace'
import { RootState } from 'store'

import { Main } from '../LibraryStyles'
import { MagicBoardCover } from './MagicBoardCover'
import { MagicBoardItems } from './MagicBoardItems'

export function MagicBoard() {
  const { tagId } = useParams() as { tagId: string }
  const [scrollNode, setScrollNode] = useState<HTMLDivElement | null>(null)

  useFirestoreConnect([
    {
      collection: 'knowledgeGraph',
      doc: tagId,
    },
  ])

  const activeWorkspace = useActiveWorkspace()!

  const siderSplitValue = useSelector(
    (state: RootState) => state.ui.siderSplitValue
  )

  const { data, loading } = useMagicBoardQuery({
    variables: {
      magicBoardId: tagId,
      workspaceId: activeWorkspace.id,
    },
    fetchPolicy: 'cache-and-network',
  })

  const magicBoardData = data?.magicBoard

  const userHasKnowledgeAccess = useSelector((state: RootState) =>
    Boolean(state.firestore.data.userPrivate?.featureAccess?.knowledgeGraph)
  )

  const knowledge = useSelector(
    (state: RootState) => state.firestore.data.knowledgeGraph?.[tagId]
  )

  const knowledgeData = userHasKnowledgeAccess ? knowledge : null
  const magicBoardName = knowledgeData?.name || magicBoardData?.description
  const noMagicBoardFound = !magicBoardData && !loading

  return (
    <StyledMagicBoardPopup siderSplitValue={siderSplitValue}>
      <Main ref={(ref: any) => setScrollNode(ref)}>
        <MagicBoardCover
          name={magicBoardName}
          coverUrl={magicBoardData?.coverUrl}
          knowledge={knowledgeData || {}}
        />
        {scrollNode && (
          <MagicBoardItems scrollNode={scrollNode} magicBoardId={tagId} />
        )}
        <GridWrapper>
          {noMagicBoardFound && knowledgeData && (
            <Text css="margin-top:32px" color="neutral.0" bold size="lg">
              🤷‍♂️ No content found for {knowledgeData.name}.
            </Text>
          )}
        </GridWrapper>
      </Main>
    </StyledMagicBoardPopup>
  )
}

const GridWrapper = styled.div`
  padding: 16px 16px 0;
`

const StyledMagicBoardPopup = styled.div<{ siderSplitValue: number }>`
  position: fixed;
  right: 0px;
  left: ${({ siderSplitValue }) => `${siderSplitValue}px`};
  top: 60px;
  background: ${({ theme }) => theme.colors.background[1]};
  z-index: 2;
`
