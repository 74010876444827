import React, { Component } from 'react'
import styled from 'styled-components'

import AirButton from './AirButton'

const Mask = styled.div({
  zIndex: 100,
  position: 'fixed',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
})

const Modal = styled.div(({ theme, style }) => ({
  position: 'fixed',
  background: theme.colors.background[0],
  color: theme.colors.text.neutral[3],
  width: 500,
  height: 'auto',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
  borderRadius: theme.borderRadius.default,
  boxShadow: theme.shadow.minimal,
  border: `${theme.border.thin} ${theme.colors.discreet}`,
  maxWidth: '100%',
  ...style,
}))

const ButtonGroup = styled.div({
  float: 'right',
  margin: 16,
  marginTop: 32,
})

const Heading = styled.div(({ theme }) => ({
  fontSize: theme.fontSizes.base,
  color: theme.colors.text.neutral[0],
  margin: 32,
  marginBottom: 16,
}))

const Detail = styled.div(({ theme }) => ({
  fontSize: theme.fontSizes.sm,
  marginLeft: 32,
  marginRight: 32,
}))

const Content = styled.div({
  marginLeft: 32,
  marginRight: 32,
  marginTop: 32,
})

class AirModal extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      visible: false,
    }
  }

  toggleVisibility = () => {
    if (this.props.toggleVisibility) this.props.toggleVisibility()
    else this.setState((prev) => ({ visible: !prev.visible }))
  }

  handleClickOk = () => {
    this.toggleVisibility()
    this.props.onOk()
    // if(!this.props.visible) this.toggleVisibility()
  }

  render() {
    return (
      <>
        {this.props.openButton && (
          <div style={{ display: 'inline-block' }}>
            <div onClick={this.toggleVisibility}>{this.props.openButton}</div>
          </div>
        )}
        {(this.state.visible || this.props.visible) && (
          <Mask
            onClick={
              this.props.onCancel ? this.props.onCancel : this.toggleVisibility
            }
          >
            <Modal
              style={this.props.style}
              onClick={(e) => e.stopPropagation()}
            >
              <Heading>{this.props.headingText}</Heading>
              {this.props.detailText && (
                <Detail>{this.props.detailText}</Detail>
              )}
              {this.props.children && <Content>{this.props.children}</Content>}
              {this.props.noButtons ? (
                <div style={{ height: 30 }} />
              ) : (
                <ButtonGroup>
                  <AirButton
                    onClick={
                      this.props.onCancel
                        ? this.props.onCancel
                        : this.toggleVisibility
                    }
                    style={{ margin: 4 }}
                  >
                    Cancel
                  </AirButton>
                  <AirButton
                    onClick={
                      this.props.onOk ? this.props.onOk : this.handleClickOk
                    }
                    style={{ margin: 4 }}
                  >
                    {this.props.okText}
                  </AirButton>
                </ButtonGroup>
              )}
            </Modal>
          </Mask>
        )}
      </>
    )
  }
}

export default AirModal
