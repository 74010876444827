/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useRef, useState } from 'react'
import ResizeObserver from 'resize-observer-polyfill'

export default function useMeasure(): [
  { ref: React.MutableRefObject<any> },
  {
    left: number
    top: number
    width: number
    height: number
  }
] {
  const ref = useRef(null)
  const [bounds, set] = useState({ left: 0, top: 0, width: 0, height: 0 })
  const [ro] = useState(
    // @ts-ignore
    () => new ResizeObserver(([entry]) => set(entry.contentRect))
  )
  useEffect(() => {
    if (ref.current !== null) ro.observe(ref.current as unknown as Element)
    return () => ro.disconnect()
  }, [ro])
  return [{ ref }, bounds]
}
