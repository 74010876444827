import { SubscriptionInterval } from '../../types'
import { AnnualUpsellToggle } from '../AnnualUpsellToggle'
import {
  StyledAllTextContainer,
  StyledAnnualUpsellContainer,
  StyledArgument,
  StyledDiscountPill,
  StyledPrice,
  StyledTopRow,
} from './AnnualUpsellSmallScreensStyles'
import { AnnualUpsellText } from './AnnualUpsellSmallScreensText'

type Props = {
  subscriptionInterval: SubscriptionInterval
  setSubscriptionInterval: (interval: SubscriptionInterval) => void
  discount: number
  price: number
}

export const AnnualUpsellSmallScreens = ({
  subscriptionInterval,
  setSubscriptionInterval,
  discount,
  price,
}: Props) => {
  const texts = AnnualUpsellText.en
  if (price === 0) return null
  return (
    <StyledAnnualUpsellContainer>
      <StyledTopRow>
        <AnnualUpsellToggle
          subscriptionInterval={subscriptionInterval}
          setSubscriptionInterval={setSubscriptionInterval}
        />
        <StyledArgument>{texts.argument}</StyledArgument>
      </StyledTopRow>
      <StyledAllTextContainer>
        <StyledPrice>
          {texts.price.template.replace(texts.price.target, `${price}`)}
        </StyledPrice>
        <StyledDiscountPill>
          {texts.discount.template.replace(
            texts.discount.target,
            `${discount}`
          )}
        </StyledDiscountPill>
      </StyledAllTextContainer>
    </StyledAnnualUpsellContainer>
  )
}
