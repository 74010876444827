import { useSelector } from 'react-redux'

import { RootState } from 'store'
import { DBBoard } from 'types/db'

export type BoardRoles = 'OWNER' | 'WRITER' | 'READER' | 'COMMENTER'

const useBoardUserRole = (board?: DBBoard): BoardRoles | null => {
  const currentUserEmail = useSelector(
    (state: RootState) => state.firebase.auth.email ?? ''
  )

  const currentUserId = useSelector(
    (state: RootState) => state.firebase.auth.uid
  )

  if (!board) {
    return null
  }

  if (board.owners.includes(currentUserEmail)) {
    return 'OWNER'
  }

  if (board.aggregatedOwnerAccess?.[currentUserId]) {
    return 'OWNER'
  }

  if (board.linkAccess === 'owner') {
    return 'OWNER'
  }

  if (board.writers.includes(currentUserEmail)) {
    return 'WRITER'
  }

  if (board.aggregatedWriterAccess?.[currentUserId]) {
    return 'WRITER'
  }

  if (board.linkAccess === 'writer') {
    return 'WRITER'
  }

  if (board.commenters.includes(currentUserEmail)) {
    return 'COMMENTER'
  }

  if (board.aggregatedCommenterAccess?.[currentUserId]) {
    return 'COMMENTER'
  }

  if (board.linkAccess === 'commenter') {
    return 'COMMENTER'
  }

  if (board.readers.includes(currentUserEmail)) {
    return 'READER'
  }

  if (board.aggregatedReaderAccess?.[currentUserId]) {
    return 'READER'
  }

  if (board.linkAccess === 'reader') {
    return 'READER'
  }

  return null as never
}

export default useBoardUserRole
