import { ArrowRightShort } from '@styled-icons/bootstrap/ArrowRightShort'
import { motion } from 'framer-motion'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Link } from 'react-router-dom'
import styled, { CSSProperties, css } from 'styled-components'

import Flex from 'components/common/Flex'
import { Spacer } from 'components/common/Spacer'
import { Text } from 'components/common/Text'

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.08,
    },
  },
}

const item = {
  hidden: { opacity: 0, y: -10 },
  show: { opacity: 1, y: 0 },
}

type HighlightRowProps<ListItemType> = {
  title: string | React.ReactNode
  subTitleComponent?: React.ReactNode
  link?: {
    path: string
    text: string
  }
  listItems: ListItemType[]
  renderListItem: (listItem: ListItemType, index: number) => React.ReactNode
  listStyle?: CSSProperties
  isLoading?: boolean
  onLinkClick?: () => void
}

export const HighlightRow = <ListItemType extends { id: string }>(
  props: HighlightRowProps<ListItemType>
) => {
  if (!props.listItems.length && !props.isLoading) {
    return <></>
  }

  return (
    <SkeletonTheme baseColor="#141414" highlightColor="#252525">
      <Flex flexDirection="column">
        {props.isLoading ? (
          <Skeleton />
        ) : (
          <Flex alignItems="center">
            <Flex flexDirection="column" css="flex: 1">
              <Text bold size="lg" color="neutral.0" ellipsis>
                {props.title}
              </Text>
              {props.subTitleComponent}
            </Flex>

            {props.link && (
              <StyledLink
                css="padding-right: 12px"
                to={props.link.path}
                onClick={props.onLinkClick}
              >
                {props.link.text} <ArrowRightShort size="18" />
              </StyledLink>
            )}
          </Flex>
        )}
        <Spacer factor={2} />
        {props.isLoading ? (
          <Skeleton height={100} />
        ) : (
          <StyledScrollableList>
            <StyledAnimatedList
              variants={container}
              initial="hidden"
              animate="show"
              transition={{
                ease: 'easeIn',
                duration: 0.1,
              }}
              style={props.listStyle}
            >
              {props.listItems.map((listItem, index) => {
                return (
                  <motion.div layout variants={item} key={listItem.id}>
                    {props.renderListItem(listItem, index)}
                  </motion.div>
                )
              })}
            </StyledAnimatedList>
          </StyledScrollableList>
        )}
      </Flex>
    </SkeletonTheme>
  )
}

const StyledAnimatedList = styled(motion.div)`
  display: flex;
  > * {
    margin-right: 24px;
  }
`

const StyledScrollableList = styled.div`
  overflow-x: scroll;
  display: flex;
  ::-webkit-scrollbar {
    display: none;
  }
`

const StyledLink = styled(Link)(
  ({ theme }) => css`
    color: ${theme.colors.text.neutral[1]};
    transition: 0.1s color ease;
    :hover {
      color: ${theme.colors.accent[2]};
    }
  `
)
