import { RefreshCcw } from '@styled-icons/feather/RefreshCcw'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useOutletContext } from 'react-router-dom'
import styled from 'styled-components'

import { DiscoveryMadeForYou } from 'components/discover/DiscoveryMadeForYou'
import { trackDiscoveryFeedRefreshed } from 'helpers/tracking/tracking'
import { RootState } from 'store'

import { SubPageHeader } from './components/SubPageHeader'

const StyledIconContainer = styled.div`
  height: 32px;
  width: 32px;
  padding: 4px;
  margin-left: 8px;
  border-width: 1px;
  border-color: rgba(51, 51, 51, 1);
  border-style: solid;
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: ${({ theme }) => theme.borderRadius.default};
  background: ${({ theme }) => theme.colors.background[5]};
  &:hover {
    background: ${({ theme }) => theme.colors.accent[2]};
  }
`

const StyledRefreshIcon = styled(RefreshCcw)`
  height: 16px;
  color: white;
`

const StyledContainer = styled.div`
  padding: 0 16px 0;
`

export const DiscoverItemsForYou = () => {
  const currentUserId = useSelector(
    (store: RootState) => store.firebase.auth.uid
  )
  const [refreshMadeForYouCounter, setRefreshMadeForYouCounter] = useState(1)
  const { wrapperRef, trackingSessionId = '' } = useOutletContext<{
    trackingSessionId?: string
    wrapperRef?: HTMLDivElement | null
  }>()

  const handleRefresh = () => {
    setRefreshMadeForYouCounter((prev) => prev + 1)
    trackDiscoveryFeedRefreshed({ trackingSessionId })
  }

  return (
    <StyledContainer>
      <SubPageHeader
        title="Items for you"
        option="FOR_YOU"
        trackingSessionId={trackingSessionId}
      >
        <StyledIconContainer>
          <StyledRefreshIcon onClick={handleRefresh} />
        </StyledIconContainer>
      </SubPageHeader>
      {wrapperRef && (
        <DiscoveryMadeForYou
          scrollElement={wrapperRef}
          currentUserId={currentUserId}
          trackingSessionId={trackingSessionId}
          key={refreshMadeForYouCounter}
        />
      )}
    </StyledContainer>
  )
}
