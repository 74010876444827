import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useSelector } from 'react-redux'

import { db } from 'config/firebase'
import { RootState, WithId } from 'store'
import { DBUploadSession } from 'types/db'

export interface UploadSessionsRemoteUploadState
  extends WithId<DBUploadSession> {
  isCompleted: boolean
  dismissed: boolean
}

// we use a temporay "local" state before the correct remote upload session comes in
interface RemoteUploadLocal {
  id: string
  status: string
  isLocal: true
  dismissed: boolean
}

export type UploadSessionsRemoteUploadPayload =
  | UploadSessionsRemoteUploadState
  | RemoteUploadLocal

type RemoteUploadSessionsState = Record<
  string,
  UploadSessionsRemoteUploadPayload
>

interface UploadSessionsRemoteUploadContextValue {
  remoteUploadSessions: RemoteUploadSessionsState
  handleRemoteUploadSessionDismiss: (id: string) => void
  addRemoteUploadLocalState: (id: string, status?: string) => void
  removeRemoteUploadLocalState: (id: string) => void
}

const initialState: UploadSessionsRemoteUploadContextValue = {
  remoteUploadSessions: {},
  addRemoteUploadLocalState: (id: string) => undefined,
  handleRemoteUploadSessionDismiss: (id: string) => undefined,
  removeRemoteUploadLocalState: (id: string) => undefined,
}

const UploadSessionsRemoteUploadContext = createContext(initialState)

type UploadSessionsRemoteUploadProviderProps = {
  children: React.ReactNode
}

// Hook used to track upload session with remote uploads, eg dropbox imports
const UploadSessionsRemoteUploadProvider = ({
  children,
}: UploadSessionsRemoteUploadProviderProps) => {
  const [remoteUploadSessions, setRemoteUploadSessions] =
    useState<RemoteUploadSessionsState>({})

  const currentUserId = useSelector(
    (state: RootState) => state.firebase.auth.uid
  )

  const activeWorkspaceId = useSelector(
    (state: RootState) => state.content.activeWorkspace?.id
  )

  useEffect(() => {
    if (!currentUserId || !activeWorkspaceId) return

    const unsubcribe = db
      .collection('uploadSessions')
      .where('isRemoteUpload', '==', true)
      .where('workspaceId', '==', activeWorkspaceId)
      .where('createdBy', '==', currentUserId)
      .where('hasFinishedAnalyzing', '!=', true)
      .orderBy('hasFinishedAnalyzing', 'desc')
      .orderBy('createdAt', 'desc')
      .onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const uploadSessionData = change.doc.data() as DBUploadSession
          const key =
            uploadSessionData.remoteUpload?.localClientId || change.doc.id

          if (change.type === 'added') {
            setRemoteUploadSessions((prev) => {
              return {
                ...prev,
                [key]: {
                  id: change.doc.id,
                  dismissed: false,
                  isCompleted: false,
                  ...uploadSessionData,
                },
              }
            })
          }
          if (change.type === 'modified') {
            setRemoteUploadSessions((prev) => {
              const copy = { ...prev }
              const entry = copy[key]
              if (!entry || 'isLocal' in entry) return copy
              copy[key] = {
                id: change.doc.id,
                dismissed: entry.dismissed,
                isCompleted: entry.isCompleted,
                ...(change.doc.data() as DBUploadSession),
              }
              return copy
            })
          }
          // if the upload sessions is "removed" we assume hasFinishedAnalyzing was switched to true
          if (change.type === 'removed') {
            setRemoteUploadSessions((prev) => {
              const copy = { ...prev }
              const entry = copy[key]
              if (!entry || 'isLocal' in entry) return copy
              if (entry) {
                copy[key] = {
                  ...entry,
                  isCompleted: true,
                }
              }
              return copy
            })
          }
        })
      })
    return () => {
      unsubcribe()
      setRemoteUploadSessions({})
    }
  }, [activeWorkspaceId, currentUserId])

  const handleRemoteUploadSessionDismiss = useCallback((key: string) => {
    setRemoteUploadSessions((prev) => {
      const copy = { ...prev }
      copy[key].dismissed = true
      return copy
    })
  }, [])

  const addRemoteUploadLocalState = useCallback(
    (localId: string, status?: string) => {
      setRemoteUploadSessions((prev) => {
        const copy = { ...prev }
        copy[localId] = {
          id: localId,
          isLocal: true,
          dismissed: false,
          status: status || 'Preparing to upload files...',
        }
        return copy
      })
    },
    []
  )

  const removeRemoteUploadLocalState = useCallback((localId: string) => {
    setRemoteUploadSessions((prev) => {
      const copy = { ...prev }
      delete copy[localId]
      return copy
    })
  }, [])

  const contextValue = useMemo(
    () => ({
      remoteUploadSessions,
      addRemoteUploadLocalState,
      handleRemoteUploadSessionDismiss,
      removeRemoteUploadLocalState,
    }),
    [
      remoteUploadSessions,
      handleRemoteUploadSessionDismiss,
      addRemoteUploadLocalState,
      removeRemoteUploadLocalState,
    ]
  )

  return (
    <UploadSessionsRemoteUploadContext.Provider value={contextValue}>
      {children}
    </UploadSessionsRemoteUploadContext.Provider>
  )
}

const useUploadSessionsRemoteUpload = () =>
  useContext(UploadSessionsRemoteUploadContext)

export { useUploadSessionsRemoteUpload, UploadSessionsRemoteUploadProvider }
